import { ApiDate } from "@laba/ts-common";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { ModelId } from "model/primitives/model/model";
import { Schedule } from "../schedule";

export enum CalendarWindowType {
  Week = "Week",
  Month = "Month"
}

export interface CalendarItemAppointmentData {
  appointmentId?: ModelId;
  patientFirstName?: string;
  patientSurname?: string;
  patientOtherName?: string;
  patientId?: ModelId;
}

export interface CalendarItem {
  startDate?: ApiDate;
  endDate?: ApiDate;
  availableScheduleList?: ModelId[];
  overbookedScheduleList?: ModelId[];
  unavailableScheduleList?: ModelId[];
  appointmentList?: CalendarItemAppointmentData[];
}

export interface Calendar {
  startDate?: ApiDate;
  endDate?: ApiDate;
  calendarItemList?: CalendarItem[];
  scheduleList?: ModelReference<Schedule>[];
}
