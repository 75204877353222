import {
  createStyle,
  FlexAlignItems,
  flexRowMixin,
  TextVariant
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { StyleVariant } from "model/themeVariant";

interface IndicatorStylesProps {
  iconColor: string;
}

export const useIndicatorStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexRowMixin({ alignItems: FlexAlignItems.Center, gap: 4 })
    },
    text: {
      ...themeTextMixin(theme, TextVariant.Body2, 1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    icon: (props: IndicatorStylesProps) => ({
      color: props.iconColor
    })
  }),
  "Indicator"
);
