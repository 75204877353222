import React from "react";
import { FC, getClassName } from "@laba/react-common";
import { useFormErrorStyles } from "components/messages/FormError/useFormErrorStyles";

interface Props {
  className?: string;
  error?: string;
  defaultError: string;
  fullWidth?: boolean;
  show?: boolean;
}
export const FormError: FC<Props> = ({
  className,
  error,
  defaultError,
  fullWidth = true,
  show = true
}) => {
  const classes = useFormErrorStyles({ fullWidth });
  return show ? (
    <p className={getClassName(className, classes.error)}>
      {error || defaultError}
    </p>
  ) : null;
};
