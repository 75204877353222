import { MedicalRequestDefinition } from "model/resource/prescription/medicalRequestDefinition/medicalRequestDefinition";
import { ApiRequestResponse } from "request/types";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { HttpMethod } from "@laba/ts-common";
import { PrescriptionRequest } from "model/resource/prescription/prescriptionRequest";

const prescriptionRequestBasePath = "/prescription-request";

export interface SharePrescriptionData {
  mailReceivers: string[];
  phoneReceivers: string[];
  prescriptionRequestList: PrescriptionRequest[];
}

export const sharePrescriptionRequestFile = (
  data: SharePrescriptionData
): Promise<ApiRequestResponse<MedicalRequestDefinition>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(`${prescriptionRequestBasePath}/send-file`),
    data
  });
