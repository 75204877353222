import i18n, { Resource } from "i18next";
// eslint-disable-next-line no-restricted-imports
import { initReactI18next } from "react-i18next";
import { GenericObject } from "@laba/ts-common";
import { getI18nTFromTFunction, I18nT } from "./i18nT";

export const initI18n = async <
  TInterpolationMap extends GenericObject = GenericObject
>(
  resources: Resource,
  lng = "es",
  fallbackLng = "en"
): Promise<I18nT<TInterpolationMap>> => {
  const tFunction = await i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng,
      fallbackLng,
      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });
  return getI18nTFromTFunction(tFunction);
};
