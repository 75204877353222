import React from "react";
import { FC } from "@laba/react-common";
import { ThemeProvider } from "@material-ui/core/styles";
import { StyleVariant } from "model/themeVariant";
import { LinearProgress as MuiLinearProgress } from "@material-ui/core";
import { useMuiTheme } from "model/useMuiTheme";
import { useLinearProgressStyle } from "./LinearProgressStyle";

export interface LinearProgressProps {
  className?: string;
  max?: number;
  min?: number;
  value?: number;
}

export const LinearProgress: FC<LinearProgressProps> = ({
  className,
  value,
  max = 100,
  min = 0
}) => {
  const theme = useMuiTheme(StyleVariant.Primary);
  const linearProgressClasses = useLinearProgressStyle();
  const realValue = value ? ((value - min) / (max - min)) * 100 : undefined;
  return (
    <ThemeProvider theme={theme}>
      <MuiLinearProgress
        classes={linearProgressClasses}
        className={className}
        value={realValue}
        variant={value ? "determinate" : "indeterminate"}
      />
    </ThemeProvider>
  );
};
