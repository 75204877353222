import {
  ApiDate,
  applyFormatToDate,
  DateFormat,
  joinText,
  Optional
} from "@laba/ts-common";

export interface ResourceDateAndPractitioner {
  date?: ApiDate;
  practitionerName: string;
  label: string;
}

export const getDateText = (
  data?: ResourceDateAndPractitioner,
  onlyEffectiveData = false
): Optional<string> => {
  const day = applyFormatToDate(data?.date, DateFormat.Spanish);
  const hour = applyFormatToDate(data?.date, DateFormat.HourMinuteAndText);
  const labelAndPractitioner = joinText(
    [onlyEffectiveData ? undefined : data?.label, data?.practitionerName],
    " "
  );
  return data?.practitionerName
    ? joinText(
        [
          joinText([labelAndPractitioner, day], " | "),
          !onlyEffectiveData ? hour : undefined
        ],
        " "
      )
    : undefined;
};
