import { Optional } from "@laba/ts-common";
import { isEmpty, isNil, size } from "lodash-es";
import { useCallback } from "react";

export type ValidateArrayFn<T, FormValues = unknown> = (
  value?: T[],
  allValues?: FormValues
) => Optional<string>;

export type ValidateFn<T, FormValues> = (
  value?: T,
  allValues?: FormValues
) => Optional<string>;

export const notUndefinedValidatorGetter =
  <T>(errorMessage?: string) =>
  (value?: T): Optional<string> =>
    !isNil(value) ? undefined : errorMessage ?? " ";

export const useNotUndefinedValidatorGetter = <T>(
  message?: string,
  hide?: boolean
): ((value?: T) => Optional<string>) =>
  useCallback(
    (value?: T) =>
      hide ? undefined : notUndefinedValidatorGetter<T>(message)(value),
    [message, hide]
  );

export const notUndefinedArrayValidatorGetter =
  <T>(message: string) =>
  (value?: T[]): Optional<string> => {
    return !isEmpty(value) ? undefined : message;
  };

export const useValidatorWithRequiredValidatorGetter = <T, FormValues>(
  required?: boolean,
  validate?: (value?: T, allValues?: FormValues) => Optional<string>,
  requiredMessage?: string
): ((value?: T, allValues?: FormValues) => Optional<string>) => {
  const requiredValidate = useCallback(
    (value?: T): Optional<string> => {
      if (Array.isArray(value)) {
        return notUndefinedArrayValidatorGetter<T>(requiredMessage ?? "")(
          value
        );
      }
      return notUndefinedValidatorGetter<T>(requiredMessage ?? "")(value);
    },
    [requiredMessage]
  );
  return useCallback(
    (value?: T, allValues?: FormValues): Optional<string> => {
      const error = validate?.(value, allValues);
      if (error) return error;
      if (required) return requiredValidate(value);
    },
    [validate, required, requiredValidate]
  );
};

export const getPath = (...pathSegment: Optional<string>[]): string =>
  pathSegment.filter(Boolean).join(".");

interface UseValidatorWithRequiredValidatorGetterProps {
  required?: boolean;
  requiredMessage?: string;
  minElements?: number;
  maxElements?: number;
  minElementText?: string;
  maxElementText?: string;
}

export const useMinMaxElementsValidatorWithRequiredValidatorGetter =
  <T>({
    required,
    requiredMessage,
    minElements,
    maxElements,
    minElementText,
    maxElementText
  }: UseValidatorWithRequiredValidatorGetterProps) =>
  (value?: T[]): Optional<string> => {
    const valueAmount = size(value);

    if (required && valueAmount === 0) return requiredMessage;

    if (minElements !== undefined && valueAmount < minElements)
      return minElementText;

    if (maxElements !== undefined && valueAmount > maxElements)
      return maxElementText;

    return undefined;
  };
