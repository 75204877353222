import React from "react";
import {
  getDefaultJsonToModel,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";
import { Notification } from "@laba/nexup-api";
import { ResourceTransform } from "components/generic/Create/Create";
import {
  cleanEmptyString,
  ResourceForm
} from "components/generic/ResourceForm/ResourceForm";
import { tkCP } from "translation/i18n";
import { FC, useI18n } from "@laba/react-common";
import { ExternalResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { NotificationMainContent } from "./NotificationMainContent";
import { NotificationWhatsappListContent } from "./NotificationWhatsappListContent";

export const notificationJsonToModel: JsonToModel<Notification> =
  getDefaultJsonToModel();
export const notificationTransform: ResourceTransform<
  Notification
> = resource => {
  return cleanEmptyString(resource);
};

export const tk = tkCP.adminPage[ExternalResourceType.Notification];

export const NotificationForm: FC = () => {
  const { t } = useI18n();

  return (
    <ResourceForm
      tabsConfig={[
        {
          label: t(tk.tabs.main),
          content: <NotificationMainContent />
        },
        {
          label: t(tk.tabs.whatsapps),
          content: <NotificationWhatsappListContent />
        }
      ]}
    />
  );
};
