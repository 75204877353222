import { FC, getClassName, IconC, SizeVariant } from "@laba/react-common";
import { useFilePreviewArrowIconClassNames } from "components/file/FileListPreviewPopup/FilePreviewArrowIcon/useFilePreviewArrowIconStyles";
import { TypeInvertedVariant } from "components/utils/getGenericButtonColors";
import { BaseIconButton } from "components/buttons/BaseIconButton/BaseIconButton";
import React from "react";
import { ButtonBase } from "components/buttons/ButtonBase/ButtonBase";
import { Noop } from "@laba/ts-common";

interface Props {
  ArrowIcon: IconC;
  className?: string;
  onClick?: Noop;
  isMobile?: boolean;
}

export const FilePreviewArrowIcon: FC<Props> = ({
  className,
  ArrowIcon,
  onClick,
  isMobile
}) => {
  const classes = useFilePreviewArrowIconClassNames({ isMobile });
  return (
    <ButtonBase
      className={getClassName(className, classes.root)}
      onClick={onClick}
    >
      <BaseIconButton
        className={classes.icon}
        Icon={ArrowIcon}
        type={TypeInvertedVariant.Normal}
        size={SizeVariant.Large}
        withoutPadding
      />
    </ButtonBase>
  );
};
