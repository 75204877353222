import {
  Code,
  CodeSystemDesignationCode,
  Concept,
  ConceptDesignation,
  ConceptProperty,
  getConceptDesignationValue,
  getConceptDesignationValueList,
  getConceptPropertyCode,
  getConceptPropertyCodeList,
  getConceptPropertyValueBoolean,
  KnownConceptPropertyUse,
  ResourceConcept
} from "model/resource/entities/codeSystem";
import { getKeyObj, notUndefined, Optional } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { isEmpty } from "lodash-es";
import {
  createBaseIdentifier,
  Identifier,
  IdentifierPropertyType,
  IdentifierSystem,
  KnownIdentifierSystem
} from "model/primitives";

export enum KnownProcedureCodeNomenclature {
  NationalNomenclature = "Nomenclatura nacional",
  NBU = "NBU",
  HPGD = "HPGD",
  Snomed = "Snomed"
}

const mapKnownProcedureCodeNomenclatureToKnownCodeIdentifierSystem = (
  system?: IdentifierSystem
): Optional<KnownProcedureCodeNomenclature> => {
  if (system === KnownIdentifierSystem.NationalNomenclature)
    return KnownProcedureCodeNomenclature.NationalNomenclature;
  if (system === KnownIdentifierSystem.NBUNomenclature)
    return KnownProcedureCodeNomenclature.NBU;
  if (system === KnownIdentifierSystem.HPGDNomenclature)
    return KnownProcedureCodeNomenclature.HPGD;
  if (system === KnownIdentifierSystem.SnomedNomenclature)
    return KnownProcedureCodeNomenclature.Snomed;
};

const mapKnownIdentifierSystemToProcedureCodeNomenclature = (
  nomenclature?: KnownProcedureCodeNomenclature
): Optional<IdentifierSystem> => {
  if (nomenclature === KnownProcedureCodeNomenclature.NationalNomenclature)
    return KnownIdentifierSystem.NationalNomenclature;
  if (nomenclature === KnownProcedureCodeNomenclature.NBU)
    return KnownIdentifierSystem.NBUNomenclature;
  if (nomenclature === KnownProcedureCodeNomenclature.HPGD)
    return KnownIdentifierSystem.HPGDNomenclature;
  if (nomenclature === KnownProcedureCodeNomenclature.Snomed)
    return KnownIdentifierSystem.SnomedNomenclature;
};

export interface ProcedureCodeConceptNomenclature {
  code?: Code;
  nomenclature?: KnownProcedureCodeNomenclature;
  book?: Code;
}

export const ProcedureCodeConceptNomenclatureKey =
  getKeyObj<ProcedureCodeConceptNomenclature>(
    createHydratedMock<ProcedureCodeConceptNomenclature>()
  );

export interface ProcedureCodeConcept extends ResourceConcept {
  medicalArea?: Code;
  tag?: Code[];
  active?: boolean;
  preferredTerm?: string;
  synonyms?: string[];
  comment?: string[];
  bodyRegion?: Code;
  subBodyRegion?: Code;
  specimen?: Code;
  speciality?: Code[];
  nomenclature?: ProcedureCodeConceptNomenclature[];
}

export const ProcedureCodeConceptKey = getKeyObj<ProcedureCodeConcept>(
  createHydratedMock<ProcedureCodeConcept>()
);

const getIdentifierNomenclatureValues = (
  concept: Concept
): ProcedureCodeConceptNomenclature[] => {
  const nomenclatureIdentifiers = concept.identifier?.filter(
    identifier =>
      mapKnownProcedureCodeNomenclatureToKnownCodeIdentifierSystem(
        identifier.system
      ) !== undefined
  );
  return (
    nomenclatureIdentifiers?.map(ni => {
      return {
        code: ni.value,
        nomenclature:
          mapKnownProcedureCodeNomenclatureToKnownCodeIdentifierSystem(
            ni.system
          ),
        book: ni.property?.find(
          p => p.type === IdentifierPropertyType.NomenclatureBook
        )?.valueCode
      };
    }) ?? []
  );
};

export const procedureCodeConceptMapper = (
  concept: Concept
): ProcedureCodeConcept => {
  return {
    id: concept.code,
    display: concept.display ?? "",
    medicalArea: getConceptPropertyCode(
      KnownConceptPropertyUse.MedicalArea,
      concept
    ),
    preferredTerm: getConceptDesignationValue(
      CodeSystemDesignationCode.PreferredTerm,
      concept
    ),
    specimen: getConceptPropertyCode(KnownConceptPropertyUse.Specimen, concept),
    bodyRegion: getConceptPropertyCode(
      KnownConceptPropertyUse.BodyRegion,
      concept
    ),
    subBodyRegion: getConceptPropertyCode(
      KnownConceptPropertyUse.SubBodyRegion,
      concept
    ),
    active: getConceptPropertyValueBoolean(
      KnownConceptPropertyUse.Active,
      concept
    ),
    speciality: getConceptPropertyCodeList(
      KnownConceptPropertyUse.Speciality,
      concept
    ),
    tag: getConceptPropertyCodeList(
      KnownConceptPropertyUse.ProcedureCodeTag,
      concept
    ),
    synonyms: getConceptDesignationValueList(
      CodeSystemDesignationCode.Synonymous,
      concept
    ),
    nomenclature: getIdentifierNomenclatureValues(concept)
  };
};

const getProcedureCodeConceptPropertyCodeValue = (
  use: KnownConceptPropertyUse,
  value?: Code
): Optional<ConceptProperty> => {
  if (value === undefined) return;
  return {
    use,
    code: value
  };
};

const getProcedureCodeConceptPropertyBooleanValue = (
  use: KnownConceptPropertyUse,
  value?: boolean
): Optional<ConceptProperty> => {
  if (value === undefined) return;
  return {
    use,
    valueBoolean: value
  };
};

const getProcedureCodeConceptPropertyCodeValueList = (
  use: KnownConceptPropertyUse,
  value?: Code[]
): ConceptProperty[] => {
  if (value === undefined || isEmpty(value)) return [];

  return value
    .map(v => {
      return v
        ? {
            use,
            code: v
          }
        : undefined;
    })
    .filter(notUndefined);
};

const getProcedureCodeDesignationValueList = (
  use: CodeSystemDesignationCode,
  value?: string[]
): ConceptDesignation[] => {
  if (value === undefined || isEmpty(value)) return [];

  return value
    .map(v => {
      return v
        ? {
            use,
            value: v
          }
        : undefined;
    })
    .filter(notUndefined);
};

const getProcedureCodeConceptDesignationValue = (
  use: CodeSystemDesignationCode,
  value?: string
): Optional<ConceptDesignation> => {
  if (value === undefined) return;
  return {
    use,
    value
  };
};

const getProcedureCodeConceptDesignationValueList = (
  procedureCodeConcept: ProcedureCodeConcept
): ConceptDesignation[] => {
  return [
    getProcedureCodeConceptDesignationValue(
      CodeSystemDesignationCode.PreferredTerm,
      procedureCodeConcept.preferredTerm
    ),
    ...getProcedureCodeDesignationValueList(
      CodeSystemDesignationCode.Synonymous,
      procedureCodeConcept.synonyms
    )
  ].filter(notUndefined);
};

const getConceptProcedureCodeConceptList = (
  procedureCodeConcept: ProcedureCodeConcept
): ConceptProperty[] => {
  return [
    getProcedureCodeConceptPropertyCodeValue(
      KnownConceptPropertyUse.MedicalArea,
      procedureCodeConcept.medicalArea
    ),
    ...getProcedureCodeConceptPropertyCodeValueList(
      KnownConceptPropertyUse.ProcedureCodeTag,
      procedureCodeConcept.tag
    ),
    getProcedureCodeConceptPropertyBooleanValue(
      KnownConceptPropertyUse.Active,
      procedureCodeConcept.active
    ),
    getProcedureCodeConceptPropertyCodeValue(
      KnownConceptPropertyUse.BodyRegion,
      procedureCodeConcept.bodyRegion
    ),
    getProcedureCodeConceptPropertyCodeValue(
      KnownConceptPropertyUse.SubBodyRegion,
      procedureCodeConcept.subBodyRegion
    ),
    getProcedureCodeConceptPropertyCodeValue(
      KnownConceptPropertyUse.Specimen,
      procedureCodeConcept.specimen
    ),
    ...getProcedureCodeConceptPropertyCodeValueList(
      KnownConceptPropertyUse.Speciality,
      procedureCodeConcept.speciality
    )
  ].filter(notUndefined);
};

export const getConceptProcedureCodeIdentifierList = (
  procedureCodeConcept: ProcedureCodeConcept
): Identifier[] => {
  const { nomenclature } = procedureCodeConcept;
  if (nomenclature === undefined) return [];
  return nomenclature.map(v => {
    return createBaseIdentifier(
      mapKnownIdentifierSystemToProcedureCodeNomenclature(v.nomenclature) ?? "",
      v.code
    );
  });
};

export const conceptProcedureCodeConceptMapper = (
  procedureCodeConcept: ProcedureCodeConcept
): Concept => {
  return {
    display: procedureCodeConcept.display,
    code: procedureCodeConcept.id ?? "",
    property: getConceptProcedureCodeConceptList(procedureCodeConcept),
    identifier: getConceptProcedureCodeIdentifierList(procedureCodeConcept),
    designation:
      getProcedureCodeConceptDesignationValueList(procedureCodeConcept)
  };
};

export interface ProcedureCodeConceptFilter {
  content?: string;
  medicalArea?: string;
  active?: boolean;
}

export const ProcedureCodeConceptFilterKey =
  getKeyObj<ProcedureCodeConceptFilter>(
    createHydratedMock<ProcedureCodeConceptFilter>()
  );
