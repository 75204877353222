import { User } from "@laba/nexup-api";
import { Server } from "models/server/server";
import { GenericReducer } from "@laba/react-common";
import { createSlice } from "@reduxjs/toolkit";
import { SliceName } from "store/sliceNames";

export interface SessionSlice {
  accessToken?: string;
  refreshToken?: string;
  loggingIn: boolean;
  user?: User;
  server: Server;
  localUrl?: string;
}

const initState: SessionSlice = {
  loggingIn: false,
  server: Server.Develop
};

type Reducer<T = void> = GenericReducer<SessionSlice, T>;

const clean: Reducer = () => initState;

const updateToken: Reducer<{ accessToken: string; refreshToken: string }> = (
  draftState,
  { payload }
) => {
  draftState.accessToken = payload.accessToken;
  draftState.refreshToken = payload.refreshToken;
};

const updateUser: Reducer<{ user: User }> = (draftState, { payload }) => {
  draftState.user = payload.user;
};

const initLogin: Reducer<{ server: Server; localUrl?: string }> = (
  draftState,
  { payload }
) => {
  draftState.localUrl = payload.localUrl;
  draftState.server = payload.server;
  draftState.loggingIn = true;
};

const loginSuccess: Reducer = draftState => {
  draftState.loggingIn = false;
};

const loginError: Reducer = draftState => {
  draftState.loggingIn = false;
};

export const sessionSlice = createSlice({
  name: SliceName.Session,
  initialState: initState,
  reducers: {
    clean,
    updateToken,
    updateUser,
    initLogin,
    loginSuccess,
    loginError
  }
});
