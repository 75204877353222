import { HistoryAction } from "routing/models/routerDomTypes";
import { ReplaceQueryParamsAction } from "routing/models/replaceQueryParamsAction";
import { RouterAction } from "routing/models/routerAction";
import {
  RouterLocation,
  RouterLocationDescriptor
} from "routing/models/routerLocation";
import { applyReplaceQueryParamsAction } from "routing/utils/replaceQueryParams";
import { BaseEvent } from "store";
import { isExternalLocation } from "routing/utils";
import { getNavigatorSelectors } from "./selectors";
import { navigationSlice, NavigatorCompatibleState } from "./slice";

const { actions } = navigationSlice;

export interface NavigatorEvents<S extends NavigatorCompatibleState> {
  onChangeLocation: (
    location: RouterLocation,
    action: HistoryAction
  ) => BaseEvent<void, S>;
  onSucceedAction: (action: RouterAction) => BaseEvent<void, S>;
  goToLocation: (
    location: RouterLocationDescriptor,
    replace?: boolean,
    reload?: boolean
  ) => BaseEvent<void, S>;
  goToRootLocation: (replace?: boolean, reload?: boolean) => BaseEvent<void, S>;
  goToHash: (hash: string) => BaseEvent<void, S>;
  replaceLocationQueryParams: (
    params: ReplaceQueryParamsAction
  ) => BaseEvent<void, S>;
  onLocationClose: (
    defaultCloseLocation: RouterLocationDescriptor
  ) => BaseEvent<void, S>;
  onLocationError: () => BaseEvent<void, S>;
  goToExternalLocation: (path: string, newTab?: boolean) => BaseEvent<void, S>;
  goToInternalOrExternalLocation: (
    path: string,
    newTab?: boolean
  ) => BaseEvent<void, S>;
  reloadCurrentLocation: () => BaseEvent<void, S>;
}

export enum RouterHistoryAction {
  POP = "POP",
  REPLACE = "REPLACE",
  PUSH = "PUSH"
}

export const getNavigatorEvents = <
  S extends NavigatorCompatibleState = NavigatorCompatibleState
>(
  rootLocation: string
): NavigatorEvents<S> => {
  type Event = BaseEvent<void, S>;

  const { currentLocationSelector, goBackToPreviousPathCount } =
    getNavigatorSelectors<S>();

  const onChangeLocation =
    (location: RouterLocation, action: HistoryAction): Event =>
    async dispatch => {
      switch (action) {
        case RouterHistoryAction.PUSH:
          dispatch(actions.onPushLocation({ location }));
          break;
        case RouterHistoryAction.REPLACE:
          dispatch(actions.onReplaceLocation({ location }));
          break;
        case RouterHistoryAction.POP:
          dispatch(actions.onPopLocation({ location }));
          break;
        default:
          break;
      }
    };

  const onSucceedAction =
    (action: RouterAction): Event =>
    async dispatch => {
      dispatch(actions.succeedAction({ action }));
    };

  const goBackLocation = (): Event => async dispatch => {
    dispatch(actions.goBack());
  };

  const scrollToElement =
    (elementId: string): Event =>
    async dispatch => {
      dispatch(actions.scrollToElement({ elementId }));
    };

  const pushLocation =
    (location: RouterLocationDescriptor, reload?: boolean): Event =>
    async dispatch => {
      dispatch(actions.push({ location, reload }));
    };

  const replaceLocation =
    (location: RouterLocationDescriptor, reload?: boolean): Event =>
    async dispatch => {
      dispatch(actions.replace({ location, reload }));
    };

  /**
   * Change the current location of the navigator.
   * This is intended to use to change the app screen, not to change que query
   * params or hash {@link replaceLocationQueryParams}
   *
   * @param location the new location descriptor.
   * @param replace if true replace the current route instead of pushing a new one.
   * @param reload if true reloads the whole page.
   */
  const goToLocation =
    (
      location: RouterLocationDescriptor,
      replace = false,
      reload = false
    ): Event =>
    async dispatch => {
      if (replace) {
        await dispatch(replaceLocation(location, reload));
      } else {
        await dispatch(pushLocation(location, reload));
      }
    };

  /**
   * Change the current location to the app root.
   *
   * @param replace if true replace the current route instead of pushing a new one.
   * @param reload if true reloads the whole page.
   */
  const goToRootLocation =
    (replace?: boolean, reload?: boolean): Event =>
    async dispatch => {
      await dispatch(goToLocation(rootLocation, replace, reload));
    };

  /**
   * Change the current location query params and hash.
   * The hash change don't trigger a page scroll. To do that see {@link goToHash}
   *
   * @param params the query params change action description.
   */
  const replaceLocationQueryParams =
    (params: ReplaceQueryParamsAction): Event =>
    async (dispatch, getState) => {
      const currentLocation = currentLocationSelector(getState());
      if (currentLocation != null) {
        await dispatch(
          goToLocation(
            applyReplaceQueryParamsAction(params, currentLocation),
            true
          )
        );
      }
    };

  /**
   * Change the current location hash and scroll to it.
   *
   * @param hash the new hash of the route.
   */
  const goToHash =
    (hash: string): Event =>
    async dispatch => {
      await dispatch(replaceLocationQueryParams({ hash }));
      await dispatch(scrollToElement(hash));
    };

  /**
   * Close the current app page. This behave like a navigator go back action unless there is no previous in app route.
   * In that case the default route is used.
   *
   * @param defaultCloseLocation the default route to use when there isn't a go back location.
   *
   */
  const onLocationClose =
    (defaultCloseLocation: RouterLocationDescriptor): Event =>
    async (dispatch, getState) => {
      const goBackCount = goBackToPreviousPathCount(getState());
      if (goBackCount !== undefined) {
        for (let i = 0; i < goBackCount; i += 1) {
          dispatch(goBackLocation());
        }
      } else {
        await dispatch(goToLocation(defaultCloseLocation, true));
      }
    };

  /**
   * Event to be thrown when an irrecoverable error occur in a app page.
   * This event change the route to recover app stability.
   *
   */
  const onLocationError = (): Event => async dispatch => {
    await dispatch(onLocationClose(rootLocation));
  };

  const goToExternalLocation =
    (path: string, newTab?: boolean): Event =>
    async _dispatch => {
      if (newTab) {
        window.open(path);
      } else {
        // eslint-disable-next-line no-restricted-properties
        window.location.assign(path);
      }
    };

  const goToInternalOrExternalLocation =
    (path: string, newTab?: boolean): Event =>
    async dispatch => {
      if (isExternalLocation(path))
        return dispatch(goToExternalLocation(path, newTab));
      return dispatch(goToLocation(path));
    };

  const reloadCurrentLocation = (): Event => async dispatch => {
    dispatch(actions.reload());
  };

  return {
    onChangeLocation,
    onSucceedAction,
    goToLocation,
    goToRootLocation,
    goToHash,
    replaceLocationQueryParams,
    onLocationClose,
    onLocationError,
    goToExternalLocation,
    goToInternalOrExternalLocation,
    reloadCurrentLocation
  };
};
