import React from "react";
import { ReactElement, saveJsonToDevice, useI18n } from "@laba/react-common";
import {
  Button,
  CreateButton,
  ExportButton,
  FilterButton,
  TopToolbar,
  useDataProvider,
  useRecordContext,
  useRedirect
} from "react-admin";
import { ArrowDownward, UploadArrow } from "@laba/nexup-components";
import { tkCC } from "translation/i18n";
import {
  deepMapObject,
  DeepPartial,
  isArray,
  isObject,
  logger,
  notUndefined,
  Optional
} from "@laba/ts-common";
import { Model } from "@laba/nexup-api";
import produce from "immer";
import { noop } from "lodash-es";

const tk = tkCC.resourceAction;

export enum ActionMode {
  List = "List",
  Creation = "Creation",
  Edition = "Edition"
}
export type JsonToModel<T extends Model> = (
  json?: DeepPartial<T>
) => DeepPartial<T>;
export type ModelToJson<T extends Model> = (model: T) => DeepPartial<T>;
export type ExportedFileNameFunction<T extends Model> = (
  element?: T | DeepPartial<T>
) => string;
export type ImportElementPreprocess<T extends Model> = (
  model?: DeepPartial<T>
) => Optional<DeepPartial<T>>;
export interface ResourceActionsProps<T extends Model> {
  mode: ActionMode;
  resource: string;
  jsonToModel?: JsonToModel<T>;
  modelToJson?: ModelToJson<T>;
  exportedFileName?: string | ExportedFileNameFunction<T>;
  extraButtons?: ReactElement;
  importElementPreprocess?: ImportElementPreprocess<T>;
}

const cleanIdsAndCreationDate = <T extends Model>(
  model?: DeepPartial<T>
): DeepPartial<T> =>
  deepMapObject(model, (value, path) =>
    path === "id" ||
    path.endsWith(".id") ||
    path === "creationDate" ||
    path.endsWith(".creationDate")
      ? undefined
      : value
  );
export const getDefaultJsonToModel =
  <T extends Model>(
    extraJsonToModel?: (draft: DeepPartial<T>) => void
  ): JsonToModel<T> =>
  json =>
    produce(cleanIdsAndCreationDate(json), extraJsonToModel ?? noop);

export const ResourceActions = <T extends Model>({
  mode,
  resource,
  jsonToModel,
  modelToJson,
  exportedFileName,
  extraButtons,
  importElementPreprocess
}: ResourceActionsProps<T>): ReactElement => {
  const { t } = useI18n();
  const redirect = useRedirect();
  const record: T = useRecordContext();
  const dataProvider = useDataProvider();
  const listExporter = (data: T[]) => {
    const result = data.map(value => modelToJson?.(value) ?? value);
    saveJsonToDevice(
      result,
      (exportedFileName && typeof exportedFileName !== "string"
        ? exportedFileName()
        : exportedFileName) ?? `${resource}.json`
    );
  };
  const elementExporter = () => {
    const result = modelToJson?.(record) ?? record;
    saveJsonToDevice(
      result,
      (exportedFileName && typeof exportedFileName !== "string"
        ? exportedFileName(result)
        : exportedFileName) ?? `${resource}-${record.id}.json`
    );
  };
  const elementImporter = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = e.target.files?.[0];
      if (file) {
        const fileContent = await file.text();
        const modelJson = JSON.parse(fileContent) as unknown;
        if (isArray(modelJson)) {
          const modelList = modelJson
            .map(jsonElement => {
              const baseModel = jsonToModel?.(jsonElement as DeepPartial<T>);
              return importElementPreprocess?.(baseModel);
            })
            .filter(notUndefined);
          for (let i = 0; i < modelList.length; i += 1) {
            const data = modelList[i];
            if (data) {
              // eslint-disable-next-line no-await-in-loop
              await dataProvider.create(resource, { data });
            }
          }
        }
        if (isObject(modelJson)) {
          const baseModel = jsonToModel?.(modelJson as DeepPartial<T>);
          const model =
            importElementPreprocess?.(baseModel) ?? baseModel ?? modelJson;
          redirect("create", resource, undefined, {}, { record: model });
        }
      }
    } catch (error) {
      logger.error(error);
    }
  };
  return (
    <TopToolbar>
      {mode === ActionMode.List && <FilterButton />}
      {mode !== ActionMode.Creation && <CreateButton />}
      {mode === ActionMode.List && (
        <ExportButton label={t(tk.exportJson)} exporter={listExporter} />
      )}
      {mode === ActionMode.Edition && (
        <Button
          label={t(tk.exportJson)}
          component="span"
          onClick={elementExporter}
        >
          <ArrowDownward />
        </Button>
      )}
      <label htmlFor="upload-button">
        <input
          id="upload-button"
          type="file"
          accept=".json"
          style={{ display: "none" }}
          onChange={elementImporter}
        />
        <Button label={t(tk.importJson)} component="span">
          <UploadArrow />
        </Button>
      </label>
      {extraButtons}
    </TopToolbar>
  );
};
