import React from "react";
import { FC } from "@laba/react-common";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

export const Mesh04: FC<SvgIconProps> = props => {
  return (
    <svg
      {...props}
      id="Capa_2"
      data-name="Capa 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 322 102.47"
      opacity="0.4"
    >
      <defs>
        <clipPath id="clippath">
          <rect fill="none" x="0" width="322" height="102.47" />
        </clipPath>
      </defs>
      <g id="_Õ__7_x5F___2" data-name="Õº≤„_7_x5F_∏¥÷∆_2">
        <g clipPath="url(#clippath)">
          <path
            fill="#fff"
            d="m274.02,1.1c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m265.45,1.87c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m271.94,4.88c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m250.43.02c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m257.02,2.78c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="262.84" cy="5.56" r=".73" />
          <path
            fill="#fff"
            d="m270.05,8.67c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m155.14,1.13c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m162,1.63c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m168.83.76c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m175.59-.51c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m242.14,1.17c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m248.77,3.83c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m255.36,6.6c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m261.89,9.5c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m268.38,12.5c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m147.34,1.9c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m153.81,4.24c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m160.67,4.86c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m167.52,4.11c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m174.32,2.96c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m181.12,1.38c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m227.34.18c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m234.06,2.52c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m240.74,5.02c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m247.36,7.7c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m253.94,10.48c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m260.47,13.38c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m266.96,16.38c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="139.7" cy="1.21" r=".73" />
          <path
            fill="#fff"
            d="m146.32,4.97c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m152.76,7.43c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m159.62,8.17c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m166.49,7.54c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m173.32,6.52c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m180.15,5.04c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m186.84,2.66c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="211.97" cy="-.28" r=".73" />
          <path
            fill="#fff"
            d="m219.49,1.81c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="225.51" cy="3.92" r=".73" />
          <path
            fill="#fff"
            d="m232.94,6.41c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="238.89" cy="8.84" r=".73" />
          <path
            fill="#fff"
            d="m246.22,11.65c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m252.8,14.44c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m259.33,17.35c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m265.82,20.34c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m134.4-.08c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="139.04" cy="4.27" r=".73" />
          <path
            fill="#fff"
            d="m145.61,8.13c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m152.01,10.72c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m158.87,11.58c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m165.77,11.08c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m172.63,10.18c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m179.49,8.79c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m186.18,6.43c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m191.4,1.98c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m198.15.71c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m205.08,1.95c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m211.93,3.66c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m218.71,5.71c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m225.44,7.98c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m232.13,10.41c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m238.78,12.98c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m245.39,15.7c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m251.96,18.51c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m258.49,21.42c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m264.99,24.41c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m134.13,3.02c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="138.7" cy="7.46" r=".73" />
          <path
            fill="#fff"
            d="m145.22,11.43c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m151.59,14.13c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m158.45,15.12c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m165.38,14.74c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m172.27,13.96c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m179.15,12.68c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m185.86,10.32c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m191,5.77c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m197.73,4.5c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m204.66,5.83c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m211.49,7.61c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m218.26,9.73c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="224.25" cy="11.95" r=".73" />
          <path
            fill="#fff"
            d="m231.65,14.53c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m238.29,17.13c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m244.89,19.88c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m251.46,22.7c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m257.98,25.61c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m264.48,28.6c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="128.59" cy="1.37" r=".73" />
          <path
            fill="#fff"
            d="m134.18,6.5c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m139.41,11.15c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m145.16,15.11c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="150.78" cy="17.83" r=".73" />
          <path
            fill="#fff"
            d="m158.36,19.05c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m165.31,18.79c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m172.23,18.14c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m179.15,16.95c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m185.85,14.61c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="190.2" cy="9.84" r=".73" />
          <path
            fill="#fff"
            d="m197.64,8.69c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m204.56,10.09c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m211.38,11.96c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="217.41" cy="14.03" r=".73" />
          <circle fill="#fff" cx="224.11" cy="16.42" r=".73" />
          <path
            fill="#fff"
            d="m231.49,19.04c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m238.13,21.68c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m244.71,24.45c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m251.27,27.28c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m257.8,30.2c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m264.3,33.18c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m125.17.15c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m129.77,5.44c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m134.56,10.55c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m139.73,15.29c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m145.42,19.36c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m151.73,22.3c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m158.59,23.54c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m165.57,23.4c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m172.52,22.87c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m179.47,21.79c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m186.18,19.46c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m191.17,14.7c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m197.88,13.43c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m204.79,14.92c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m211.6,16.86c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m218.33,19.12c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m225.01,21.56c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m231.67,24.12c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="237.56" cy="26.68" r=".73" />
          <path
            fill="#fff"
            d="m244.86,29.57c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m251.42,32.42c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m257.94,35.35c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m264.44,38.33c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m126.1,4.26c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m130.62,9.63c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m135.35,14.84c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m140.46,19.67c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m146.1,23.85c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m152.38,26.91c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m159.23,28.27c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="165.51" cy="28.15" r=".73" />
          <path
            fill="#fff"
            d="m173.22,27.85c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m180.2,26.86c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m186.91,24.55c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m191.83,19.68c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m198.53,18.42c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m205.43,19.99c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m212.22,22.01c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m218.94,24.33c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m225.61,26.83c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m232.25,29.43c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m238.85,32.14c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m245.42,34.95c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m251.98,37.81c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m258.5,40.74c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m265,43.71c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m123.15,3.04c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m127.48,8.58c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m131.93,14.05c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m136.58,19.34c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="140.9" cy="24.16" r=".73" />
          <path
            fill="#fff"
            d="m147.22,28.55c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m153.46,31.74c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m160.32,33.22c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m167.34,33.33c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m174.36,33.05c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m181.37,32.16c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m188.08,29.86c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m192.93,24.88c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m199.62,23.62c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="205.79" cy="25.16" r=".73" />
          <path
            fill="#fff"
            d="m213.29,27.38c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m219.99,29.77c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m226.65,32.32c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m233.27,34.97c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m239.86,37.7c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m246.43,40.53c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m252.97,43.41c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m259.49,46.34c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m266,49.32c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m120.85,1.85c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m125.07,7.51c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m129.33,13.15c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="132.98" cy="18.59" r=".73" />
          <circle fill="#fff" cx="137.56" cy="23.98" r=".73" />
          <path
            fill="#fff"
            d="m143.27,29.11c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m148.81,33.49c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m155.02,36.8c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m161.88,38.41c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m168.93,38.64c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m175.98,38.48c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m183.01,37.69c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m189.73,35.4c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m194.5,30.32c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m201.18,29.06c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="207.35" cy="30.68" r=".73" />
          <path
            fill="#fff"
            d="m214.83,32.98c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m221.52,35.44c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m228.15,38.04c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m234.76,40.74c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m241.35,43.51c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="247.18" cy="46.25" r=".73" />
          <circle fill="#fff" cx="253.72" cy="49.14" r=".73" />
          <path
            fill="#fff"
            d="m260.96,52.19c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m267.46,55.16c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m276.37,1.19s-.04.07-.08.06-.07-.04-.06-.08.04-.07.08-.06.07.04.06.08Z"
          />
          <path
            fill="#fff"
            d="m119.19.76c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m123.36,6.49c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m127.5,12.24c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m131.68,17.97c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m135.97,23.62c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m140.48,29.1c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m145.41,34.21c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m150.89,38.7c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m157.08,42.13c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m163.93,43.86c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m171.01,44.21c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="177.37" cy="44.07" r=".73" />
          <path
            fill="#fff"
            d="m185.15,43.49c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="191.15" cy="41.1" r=".73" />
          <path
            fill="#fff"
            d="m196.58,36.02c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="202.52" cy="34.65" r=".73" />
          <circle fill="#fff" cx="209.4" cy="36.47" r=".73" />
          <path
            fill="#fff"
            d="m216.87,38.85c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m223.54,41.37c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m230.16,44.03c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m236.76,46.77c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m243.33,49.57c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m249.87,52.45c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m256.41,55.35c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="262.2" cy="58.18" r=".73" />
          <path
            fill="#fff"
            d="m269.43,61.26c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="272.35" cy="1.68" r=".05" />
          <circle fill="#fff" cx="278.16" cy="5.1" r=".05" />
          <path
            fill="#fff"
            d="m122.36,4.43c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m126.45,10.26c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m130.51,16.11c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m134.61,21.93c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m138.83,27.66c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m143.27,33.23c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m148.13,38.44c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m153.57,43.03c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="159" cy="46.48" r=".73" />
          <circle fill="#fff" cx="165.85" cy="48.32" r=".73" />
          <path
            fill="#fff"
            d="m173.67,48.91c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m180.79,49c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m187.88,48.41c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m194.6,46.15c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m199.24,40.86c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m205.88,39.59c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m212.76,41.49c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m219.49,43.85c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m226.15,46.44c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m232.75,49.15c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m239.34,51.93c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m245.9,54.77c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m252.43,57.67c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m258.96,60.58c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m265.48,63.53c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m271.99,66.49c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m274.84,4.75s-.01.02-.03.02-.02-.01-.02-.03.01-.02.03-.02.02.01.02.03Z"
          />
          <path
            fill="#fff"
            d="m280.63,8.17s-.01.02-.03.02-.02-.01-.02-.03.01-.02.03-.02.02.01.02.03Z"
          />
          <path
            fill="#fff"
            d="m26.78-.05c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m33.67,1.86c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m40.75,2.75c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m47.88,2.52c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m54.87,1.18c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m121.81,2.55c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m125.99,8.35c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m130,14.27c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="133.25" cy="20.11" r=".73" />
          <path
            fill="#fff"
            d="m137.99,26.13c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m142.14,31.95c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m146.51,37.61c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m151.31,42.91c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m156.69,47.61c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m162.81,51.28c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m169.67,53.26c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m176.79,53.86c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m183.93,54.07c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m191.06,53.58c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m197.79,51.33c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m202.35,45.93c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m208.98,44.67c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m215.85,46.65c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m222.57,49.09c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m229.21,51.74c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="235.08" cy="54.4" r=".73" />
          <circle fill="#fff" cx="241.64" cy="57.23" r=".73" />
          <path
            fill="#fff"
            d="m248.92,60.21c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m255.44,63.13c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="261.25" cy="65.94" r=".73" />
          <path
            fill="#fff"
            d="m268.48,69.01c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m274.99,71.97c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m30.53.97c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m37.43,2.82c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m44.51,3.72c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m51.62,3.55c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m58.61,2.34c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m65.35.27c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m121.02-.52c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m125.55,4.98c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m129.81,10.67c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m133.9,16.49c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m137.97,22.31c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m142.08,28.1c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m146.32,33.8c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m150.77,39.33c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m155.64,44.51c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m161.08,49.1c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m167.23,52.69c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m174.09,54.62c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m181.21,55.23c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m188.36,55.45c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m195.48,54.98c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m199.47,53.35c-.06.39-.42.65-.8.59s-.65-.42-.59-.8.42-.65.8-.59.65.42.59.8Z"
          />
          <path
            fill="#fff"
            d="m204.02,47.95c-.06.39-.42.65-.8.59s-.65-.42-.59-.8.42-.65.8-.59.65.42.59.8Z"
          />
          <path
            fill="#fff"
            d="m210.64,46.68c-.06.39-.42.65-.8.59s-.65-.42-.59-.8.42-.65.8-.59.65.42.59.8Z"
          />
          <path
            fill="#fff"
            d="m217.5,48.66c-.06.39-.42.65-.8.59s-.65-.42-.59-.8.42-.65.8-.59.65.42.59.8Z"
          />
          <path
            fill="#fff"
            d="m224.2,51.09c-.06.39-.42.65-.8.59s-.65-.42-.59-.8.42-.65.8-.59.65.42.59.8Z"
          />
          <path
            fill="#fff"
            d="m230.82,53.75c-.06.39-.42.65-.8.59s-.65-.42-.59-.8.42-.65.8-.59.65.42.59.8Z"
          />
          <path
            fill="#fff"
            d="m237.4,56.52c-.06.39-.42.65-.8.59s-.65-.42-.59-.8.42-.65.8-.59.65.42.59.8Z"
          />
          <path
            fill="#fff"
            d="m243.95,59.35c-.06.39-.42.65-.8.59s-.65-.42-.59-.8.42-.65.8-.59.65.42.59.8Z"
          />
          <circle fill="#fff" cx="249.79" cy="62.11" r=".71" />
          <path
            fill="#fff"
            d="m257,65.13c-.06.39-.42.65-.8.59s-.65-.42-.59-.8.42-.65.8-.59.65.42.59.8Z"
          />
          <path
            fill="#fff"
            d="m263.51,68.06c-.06.39-.42.65-.8.59s-.65-.42-.59-.8.42-.65.8-.59.65.42.59.8Z"
          />
          <path
            fill="#fff"
            d="m270.01,71.02c-.06.39-.42.65-.8.59s-.65-.42-.59-.8.42-.65.8-.59.65.42.59.8Z"
          />
          <path
            fill="#fff"
            d="m276.5,73.97c-.06.39-.42.65-.8.59s-.65-.42-.59-.8.42-.65.8-.59.65.42.59.8Z"
          />
          <path
            fill="#fff"
            d="m27.53-.35c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="33.45" cy="2.01" r=".73" />
          <path
            fill="#fff"
            d="m41.08,3.91c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m48.16,4.82c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m55.27,4.72c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m62.24,3.64c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m68.97,1.75c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m124.59,2.12c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m129.18,7.54c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m133.53,13.13c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m137.71,18.83c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m141.87,24.54c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m146.07,30.2c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="149.67" cy="35.66" r=".73" />
          <path
            fill="#fff"
            d="m154.93,41.17c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="159.15" cy="46.12" r=".73" />
          <path
            fill="#fff"
            d="m165.36,50.71c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m171.54,54.22c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m178.41,56.12c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m185.53,56.73c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m192.68,56.96c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m199.8,56.51c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m201.05,55.5c-.06.37-.41.63-.78.57s-.63-.41-.57-.78.41-.63.78-.57.63.41.57.78Z"
          />
          <path
            fill="#fff"
            d="m205.6,50.09c-.06.37-.41.63-.78.57s-.63-.41-.57-.78.41-.63.78-.57.63.41.57.78Z"
          />
          <path
            fill="#fff"
            d="m212.2,48.82c-.06.37-.41.63-.78.57s-.63-.41-.57-.78.41-.63.78-.57.63.41.57.78Z"
          />
          <path
            fill="#fff"
            d="m219.04,50.8c-.06.37-.41.63-.78.57s-.63-.41-.57-.78.41-.63.78-.57.63.41.57.78Z"
          />
          <circle fill="#fff" cx="225.05" cy="53.13" r=".68" />
          <path
            fill="#fff"
            d="m232.34,55.89c-.06.37-.41.63-.78.57s-.63-.41-.57-.78.41-.63.78-.57.63.41.57.78Z"
          />
          <circle fill="#fff" cx="238.23" cy="58.55" r=".68" />
          <circle fill="#fff" cx="244.76" cy="61.38" r=".68" />
          <path
            fill="#fff"
            d="m251.96,64.36c-.06.37-.41.63-.78.57s-.63-.41-.57-.78.41-.63.78-.57.63.41.57.78Z"
          />
          <path
            fill="#fff"
            d="m258.45,67.27c-.06.37-.41.63-.78.57s-.63-.41-.57-.78.41-.63.78-.57.63.41.57.78Z"
          />
          <path
            fill="#fff"
            d="m264.95,70.2c-.06.37-.41.63-.78.57s-.63-.41-.57-.78.41-.63.78-.57.63.41.57.78Z"
          />
          <path
            fill="#fff"
            d="m271.43,73.15c-.06.37-.41.63-.78.57s-.63-.41-.57-.78.41-.63.78-.57.63.41.57.78Z"
          />
          <circle fill="#fff" cx="277.23" cy="76.01" r=".68" />
          <path
            fill="#fff"
            d="m31.06,1.03c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m37.72,3.39c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m44.63,5.12c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m51.71,6.03c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="58.09" cy="5.89" r=".73" />
          <path
            fill="#fff"
            d="m65.77,5.05c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="71.76" cy="3.23" r=".73" />
          <path
            fill="#fff"
            d="m78.94,1.04c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m123-.12c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m128.06,4.88c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="132" cy="10.1" r=".73" />
          <path
            fill="#fff"
            d="m137.15,15.7c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m141.41,21.29c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m145.66,26.87c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m149.95,32.42c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m154.37,37.86c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m158.99,43.13c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m164,48.07c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m169.54,52.44c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m175.75,55.86c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m182.63,57.73c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m189.75,58.34c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m196.9,58.58c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m204.02,58.16c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m202.53,57.77c-.05.36-.39.61-.75.55s-.61-.39-.55-.75.39-.61.75-.55.61.39.55.75Z"
          />
          <path
            fill="#fff"
            d="m207.07,52.35c-.05.36-.39.61-.75.55s-.61-.39-.55-.75.39-.61.75-.55.61.39.55.75Z"
          />
          <path
            fill="#fff"
            d="m213.67,51.07c-.05.36-.39.61-.75.55s-.61-.39-.55-.75.39-.61.75-.55.61.39.55.75Z"
          />
          <path
            fill="#fff"
            d="m220.49,53.05c-.05.36-.39.61-.75.55s-.61-.39-.55-.75.39-.61.75-.55.61.39.55.75Z"
          />
          <path
            fill="#fff"
            d="m227.16,55.48c-.05.36-.39.61-.75.55s-.61-.39-.55-.75.39-.61.75-.55.61.39.55.75Z"
          />
          <circle fill="#fff" cx="233.1" cy="58.04" r=".66" />
          <path
            fill="#fff"
            d="m240.3,60.91c-.05.36-.39.61-.75.55s-.61-.39-.55-.75.39-.61.75-.55.61.39.55.75Z"
          />
          <circle fill="#fff" cx="246.17" cy="63.63" r=".66" />
          <path
            fill="#fff"
            d="m253.33,66.6c-.05.36-.39.61-.75.55s-.61-.39-.55-.75.39-.61.75-.55.61.39.55.75Z"
          />
          <path
            fill="#fff"
            d="m259.81,69.52c-.05.36-.39.61-.75.55s-.61-.39-.55-.75.39-.61.75-.55.61.39.55.75Z"
          />
          <path
            fill="#fff"
            d="m266.29,72.44c-.05.36-.39.61-.75.55s-.61-.39-.55-.75.39-.61.75-.55.61.39.55.75Z"
          />
          <path
            fill="#fff"
            d="m272.75,75.4c-.05.36-.39.61-.75.55s-.61-.39-.55-.75.39-.61.75-.55.61.39.55.75Z"
          />
          <path
            fill="#fff"
            d="m279.22,78.36c-.05.36-.39.61-.75.55s-.61-.39-.55-.75.39-.61.75-.55.61.39.55.75Z"
          />
          <path
            fill="#fff"
            d="m28.08-.17c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m34.5,2.5c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="40.45" cy="4.63" r=".73" />
          <path
            fill="#fff"
            d="m48.09,6.41c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m55.17,7.33c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m62.26,7.37c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m69.21,6.55c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m75.91,5.02c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m82.35,2.94c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m88.32.09c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m126.34,2.8c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m131.44,7.72c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m136.17,12.97c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m140.68,18.35c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="144.31" cy="23.73" r=".73" />
          <circle fill="#fff" cx="148.65" cy="29.19" r=".73" />
          <path
            fill="#fff"
            d="m153.75,34.72c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m158.25,40.03c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m162.96,45.18c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="167.32" cy="49.88" r=".73" />
          <path
            fill="#fff"
            d="m173.63,54.26c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m179.88,57.59c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m186.76,59.43c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m193.89,60.04c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m201.03,60.29c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m208.16,59.89c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="203.3" cy="60.03" r=".64" />
          <circle fill="#fff" cx="207.83" cy="54.6" r=".64" />
          <circle fill="#fff" cx="214.41" cy="53.32" r=".64" />
          <circle fill="#fff" cx="221.21" cy="55.29" r=".64" />
          <circle fill="#fff" cx="227.87" cy="57.73" r=".64" />
          <path
            fill="#fff"
            d="m235.08,60.48c-.05.35-.38.59-.72.53s-.59-.38-.53-.72.38-.59.72-.53.59.38.53.72Z"
          />
          <circle fill="#fff" cx="240.98" cy="63.15" r=".64" />
          <circle fill="#fff" cx="247.49" cy="65.98" r=".64" />
          <path
            fill="#fff"
            d="m254.61,68.94c-.05.35-.38.59-.72.53s-.59-.38-.53-.72.38-.59.72-.53.59.38.53.72Z"
          />
          <circle fill="#fff" cx="260.45" cy="71.76" r=".64" />
          <circle fill="#fff" cx="266.91" cy="74.69" r=".64" />
          <path
            fill="#fff"
            d="m273.99,77.74c-.05.35-.38.59-.72.53s-.59-.38-.53-.72.38-.59.72-.53.59.38.53.72Z"
          />
          <circle fill="#fff" cx="279.81" cy="80.6" r=".64" />
          <path
            fill="#fff"
            d="m31.42,1.53c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m37.86,4.04c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m44.55,6.17c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m51.48,7.78c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m58.55,8.7c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m65.64,8.81c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m72.58,8.11c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="78.53" cy="6.66" r=".73" />
          <path
            fill="#fff"
            d="m85.68,4.91c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m91.64,2.33c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m97.64-.22c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m123.89,1.52c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m129.59,5.78c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m134.75,10.64c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m139.54,15.8c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m144.13,21.08c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m148.56,26.46c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m153,31.8c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m157.47,37.1c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m162.06,42.28c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m166.85,47.3c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m172,51.99c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m177.65,56.15c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m183.92,59.4c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="190.09" cy="61.09" r=".73" />
          <path
            fill="#fff"
            d="m197.94,61.82c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m205.08,62.08c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m212.21,61.7c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m205.24,62.55c-.05.33-.36.56-.7.51s-.56-.36-.51-.7.36-.56.7-.51.56.36.51.7Z"
          />
          <path
            fill="#fff"
            d="m209.77,57.11c-.05.33-.36.56-.7.51s-.56-.36-.51-.7.36-.56.7-.51.56.36.51.7Z"
          />
          <path
            fill="#fff"
            d="m216.33,55.83c-.05.33-.36.56-.7.51s-.56-.36-.51-.7.36-.56.7-.51.56.36.51.7Z"
          />
          <path
            fill="#fff"
            d="m223.12,57.8c-.05.33-.36.56-.7.51s-.56-.36-.51-.7.36-.56.7-.51.56.36.51.7Z"
          />
          <path
            fill="#fff"
            d="m229.77,60.24c-.05.33-.36.56-.7.51s-.56-.36-.51-.7.36-.56.7-.51.56.36.51.7Z"
          />
          <path
            fill="#fff"
            d="m236.33,62.89c-.05.33-.36.56-.7.51s-.56-.36-.51-.7.36-.56.7-.51.56.36.51.7Z"
          />
          <circle fill="#fff" cx="242.24" cy="65.56" r=".61" />
          <path
            fill="#fff"
            d="m249.34,68.48c-.05.33-.36.56-.7.51s-.56-.36-.51-.7.36-.56.7-.51.56.36.51.7Z"
          />
          <path
            fill="#fff"
            d="m255.81,71.35c-.05.33-.36.56-.7.51s-.56-.36-.51-.7.36-.56.7-.51.56.36.51.7Z"
          />
          <path
            fill="#fff"
            d="m262.26,74.27c-.05.33-.36.56-.7.51s-.56-.36-.51-.7.36-.56.7-.51.56.36.51.7Z"
          />
          <path
            fill="#fff"
            d="m268.71,77.19c-.05.33-.36.56-.7.51s-.56-.36-.51-.7.36-.56.7-.51.56.36.51.7Z"
          />
          <path
            fill="#fff"
            d="m275.15,80.15c-.05.33-.36.56-.7.51s-.56-.36-.51-.7.36-.56.7-.51.56.36.51.7Z"
          />
          <path
            fill="#fff"
            d="m281.58,83.11c-.05.33-.36.56-.7.51s-.56-.36-.51-.7.36-.56.7-.51.56.36.51.7Z"
          />
          <path
            fill="#fff"
            d="m28.37.84c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m34.69,3.29c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m41.15,5.63c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m47.86,7.65c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m54.79,9.2c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m61.87,10.12c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m68.94,10.3c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m75.87,9.73c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m82.53,8.58c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m88.94,6.94c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m94.9,4.61c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m100.88,2.33c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m107.08.46c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m113.8.05c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m120.66,1.46c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m127.05,4.59c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m132.78,8.82c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m137.98,13.61c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m142.84,18.69c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m147.51,23.86c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m152.03,29.13c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m156.56,34.35c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m161.12,39.52c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m165.8,44.58c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m170.67,49.47c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m175.89,54.04c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m181.59,58.09c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m187.9,61.25c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m194.8,63.02c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m201.92,63.64c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m209.06,63.91c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m216.19,63.56c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="205.9" cy="64.94" r=".59" />
          <circle fill="#fff" cx="210.42" cy="59.49" r=".59" />
          <circle fill="#fff" cx="216.97" cy="58.21" r=".59" />
          <circle fill="#fff" cx="223.75" cy="60.18" r=".59" />
          <circle fill="#fff" cx="230.38" cy="62.61" r=".59" />
          <circle fill="#fff" cx="236.92" cy="65.26" r=".59" />
          <circle fill="#fff" cx="243.43" cy="68.03" r=".59" />
          <circle fill="#fff" cx="249.9" cy="70.86" r=".59" />
          <circle fill="#fff" cx="256.36" cy="73.73" r=".59" />
          <circle fill="#fff" cx="262.8" cy="76.64" r=".59" />
          <circle fill="#fff" cx="269.23" cy="79.57" r=".59" />
          <circle fill="#fff" cx="275.65" cy="82.52" r=".59" />
          <circle fill="#fff" cx="282.07" cy="85.48" r=".59" />
          <path
            fill="#fff"
            d="m25.3.55c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m31.57,2.81c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m37.9,5.07c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="43.66" cy="7.14" r=".73" />
          <path
            fill="#fff"
            d="m51.1,9.15c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m58.04,10.65c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m65.12,11.58c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m72.19,11.83c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="78.38" cy="11.27" r=".73" />
          <path
            fill="#fff"
            d="m85.75,10.41c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="91.42" cy="8.89" r=".73" />
          <circle fill="#fff" cx="97.37" cy="6.82" r=".73" />
          <circle fill="#fff" cx="103.35" cy="4.79" r=".73" />
          <path
            fill="#fff"
            d="m110.25,3.28c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m116.94,3.05c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m123.77,4.55c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m130.15,7.7c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m135.9,11.89c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m141.16,16.6c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m146.08,21.6c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m150.83,26.67c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m155.44,31.83c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m160.05,36.93c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m164.71,41.98c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m169.48,46.91c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m174.43,51.67c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m179.72,56.11c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m185.48,60.06c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m191.81,63.14c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m198.72,64.87c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m205.84,65.5c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="212.26" cy="65.67" r=".73" />
          <path
            fill="#fff"
            d="m220.11,65.44c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m207.67,67.53c-.05.31-.34.52-.64.47s-.52-.34-.47-.64.34-.52.64-.47.52.34.47.64Z"
          />
          <path
            fill="#fff"
            d="m212.18,62.08c-.05.31-.34.52-.64.47s-.52-.34-.47-.64.34-.52.64-.47.52.34.47.64Z"
          />
          <path
            fill="#fff"
            d="m218.72,60.79c-.05.31-.34.52-.64.47s-.52-.34-.47-.64.34-.52.64-.47.52.34.47.64Z"
          />
          <path
            fill="#fff"
            d="m225.48,62.76c-.05.31-.34.52-.64.47s-.52-.34-.47-.64.34-.52.64-.47.52.34.47.64Z"
          />
          <path
            fill="#fff"
            d="m232.09,65.19c-.05.31-.34.52-.64.47s-.52-.34-.47-.64.34-.52.64-.47.52.34.47.64Z"
          />
          <path
            fill="#fff"
            d="m238.62,67.85c-.05.31-.34.52-.64.47s-.52-.34-.47-.64.34-.52.64-.47.52.34.47.64Z"
          />
          <path
            fill="#fff"
            d="m245.11,70.61c-.05.31-.34.52-.64.47s-.52-.34-.47-.64.34-.52.64-.47.52.34.47.64Z"
          />
          <path
            fill="#fff"
            d="m251.57,73.44c-.05.31-.34.52-.64.47s-.52-.34-.47-.64.34-.52.64-.47.52.34.47.64Z"
          />
          <path
            fill="#fff"
            d="m258.01,76.31c-.05.31-.34.52-.64.47s-.52-.34-.47-.64.34-.52.64-.47.52.34.47.64Z"
          />
          <path
            fill="#fff"
            d="m264.43,79.22c-.05.31-.34.52-.64.47s-.52-.34-.47-.64.34-.52.64-.47.52.34.47.64Z"
          />
          <path
            fill="#fff"
            d="m270.85,82.15c-.05.31-.34.52-.64.47s-.52-.34-.47-.64.34-.52.64-.47.52.34.47.64Z"
          />
          <path
            fill="#fff"
            d="m277.26,85.1c-.05.31-.34.52-.64.47s-.52-.34-.47-.64.34-.52.64-.47.52.34.47.64Z"
          />
          <path
            fill="#fff"
            d="m283.66,88.06c-.05.31-.34.52-.64.47s-.52-.34-.47-.64.34-.52.64-.47.52.34.47.64Z"
          />
          <path
            fill="#fff"
            d="m22.12.73c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m28.42,2.74c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m34.7,4.8c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m41.06,6.87c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m47.56,8.89c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="53.57" cy="10.57" r=".73" />
          <path
            fill="#fff"
            d="m61.25,12.11c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m68.32,13.05c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m75.38,13.37c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="81.56" cy="12.94" r=".73" />
          <path
            fill="#fff"
            d="m88.92,12.26c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m95.29,11.07c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m101.23,9.26c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m107.2,7.49c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m113.37,6.12c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m120.02,6.06c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m126.82,7.65c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m133.19,10.82c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m138.97,14.97c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m144.28,19.62c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m149.27,24.53c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m154.1,29.49c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m158.79,34.55c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m163.5,39.52c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m168.25,44.45c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m173.1,49.25c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="177.42" cy="53.78" r=".73" />
          <circle fill="#fff" cx="182.78" cy="58.1" r=".73" />
          <circle fill="#fff" cx="188.59" cy="61.93" r=".73" />
          <path
            fill="#fff"
            d="m195.68,65.04c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m202.59,66.74c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m209.71,67.37c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m216.85,67.66c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m223.98,67.35c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="208.27" cy="69.98" r=".54" />
          <path
            fill="#fff"
            d="m213.3,64.6c-.05.3-.32.5-.62.45s-.5-.32-.45-.62.32-.5.62-.45.5.32.45.62Z"
          />
          <path
            fill="#fff"
            d="m219.83,63.3c-.05.3-.32.5-.62.45s-.5-.32-.45-.62.32-.5.62-.45.5.32.45.62Z"
          />
          <circle fill="#fff" cx="226.04" cy="65.19" r=".54" />
          <path
            fill="#fff"
            d="m233.17,67.7c-.05.3-.32.5-.62.45s-.5-.32-.45-.62.32-.5.62-.45.5.32.45.62Z"
          />
          <circle fill="#fff" cx="239.15" cy="70.27" r=".54" />
          <circle fill="#fff" cx="245.62" cy="73.04" r=".54" />
          <circle fill="#fff" cx="252.07" cy="75.87" r=".54" />
          <circle fill="#fff" cx="258.5" cy="78.73" r=".54" />
          <circle fill="#fff" cx="264.9" cy="81.65" r=".54" />
          <circle fill="#fff" cx="271.31" cy="84.57" r=".54" />
          <path
            fill="#fff"
            d="m278.23,87.61c-.05.3-.32.5-.62.45s-.5-.32-.45-.62.32-.5.62-.45.5.32.45.62Z"
          />
          <circle fill="#fff" cx="284.08" cy="90.48" r=".54" />
          <path
            fill="#fff"
            d="m4.79.3c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m11.78.96c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m18.63,1.72c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m25.18,3.14c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="30.77" cy="4.84" r=".73" />
          <path
            fill="#fff"
            d="m37.79,6.81c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m44.17,8.7c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m50.69,10.56c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m57.44,12.23c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m64.4,13.6c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m71.47,14.54c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m78.52,14.93c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m85.42,14.75c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m92.03,14.14c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="97.67" cy="13.06" r=".73" />
          <path
            fill="#fff"
            d="m104.33,11.62c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="109.57" cy="10" r=".73" />
          <circle fill="#fff" cx="115.72" cy="8.87" r=".73" />
          <circle fill="#fff" cx="122.33" cy="8.99" r=".73" />
          <path
            fill="#fff"
            d="m129.83,10.78c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m136.19,13.97c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m142,18.08c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m147.35,22.66c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m152.41,27.49c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="156.59" cy="32.24" r=".73" />
          <path
            fill="#fff"
            d="m162.1,37.3c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m166.9,42.15c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m171.73,46.96c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m176.68,51.63c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m181.8,56.13c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m187.23,60.33c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m193.09,64.06c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m199.49,66.97c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m206.41,68.64c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m213.53,69.27c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m220.68,69.57c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="227.08" cy="69.17" r=".73" />
          <path
            fill="#fff"
            d="m209.88,72.61c-.04.28-.31.48-.59.43s-.48-.31-.43-.59.31-.48.59-.43.48.31.43.59Z"
          />
          <path
            fill="#fff"
            d="m214.38,67.14c-.04.28-.31.48-.59.43s-.48-.31-.43-.59.31-.48.59-.43.48.31.43.59Z"
          />
          <path
            fill="#fff"
            d="m220.89,65.84c-.04.28-.31.48-.59.43s-.48-.31-.43-.59.31-.48.59-.43.48.31.43.59Z"
          />
          <path
            fill="#fff"
            d="m227.62,67.81c-.04.28-.31.48-.59.43s-.48-.31-.43-.59.31-.48.59-.43.48.31.43.59Z"
          />
          <path
            fill="#fff"
            d="m234.2,70.24c-.04.28-.31.48-.59.43s-.48-.31-.43-.59.31-.48.59-.43.48.31.43.59Z"
          />
          <path
            fill="#fff"
            d="m240.7,72.89c-.04.28-.31.48-.59.43s-.48-.31-.43-.59.31-.48.59-.43.48.31.43.59Z"
          />
          <path
            fill="#fff"
            d="m247.16,75.66c-.04.28-.31.48-.59.43s-.48-.31-.43-.59.31-.48.59-.43.48.31.43.59Z"
          />
          <path
            fill="#fff"
            d="m253.59,78.48c-.04.28-.31.48-.59.43s-.48-.31-.43-.59.31-.48.59-.43.48.31.43.59Z"
          />
          <path
            fill="#fff"
            d="m260,81.35c-.04.28-.31.48-.59.43s-.48-.31-.43-.59.31-.48.59-.43.48.31.43.59Z"
          />
          <path
            fill="#fff"
            d="m266.39,84.27c-.04.28-.31.48-.59.43s-.48-.31-.43-.59.31-.48.59-.43.48.31.43.59Z"
          />
          <path
            fill="#fff"
            d="m272.78,87.19c-.04.28-.31.48-.59.43s-.48-.31-.43-.59.31-.48.59-.43.48.31.43.59Z"
          />
          <circle fill="#fff" cx="278.64" cy="90.07" r=".52" />
          <path
            fill="#fff"
            d="m285.53,93.1c-.04.28-.31.48-.59.43s-.48-.31-.43-.59.31-.48.59-.43.48.31.43.59Z"
          />
          <path
            fill="#fff"
            d="m1.01,1.54c-.06.4-.43.67-.83.61s-.67-.43-.61-.83S0,.65.4.71s.67.43.61.83Z"
          />
          <circle fill="#fff" cx="7.09" cy="2.98" r=".73" />
          <path
            fill="#fff"
            d="m14.8,3.65c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m21.65,4.28c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m28.2,5.53c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m34.53,7.13c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m40.85,8.8c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="46.52" cy="10.38" r=".73" />
          <circle fill="#fff" cx="53.06" cy="12.09" r=".73" />
          <path
            fill="#fff"
            d="m60.55,13.75c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m67.52,15.07c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m74.59,16.01c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="80.91" cy="16.36" r=".73" />
          <path
            fill="#fff"
            d="m88.52,16.41c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m95.12,15.99c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m101.45,15.24c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m107.39,13.95c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m113.34,12.7c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m119.47,11.81c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m126.06,12.12c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m132.8,13.88c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m139.16,17.09c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m144.99,21.16c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m150.39,25.67c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m155.52,30.42c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m160.5,35.17c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m165.37,40.01c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m170.26,44.74c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m175.19,49.43c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m180.22,53.97c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m185.43,58.35c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m190.93,62.42c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m196.84,66.04c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m203.27,68.87c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m210.2,70.5c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="216.6" cy="71.03" r=".73" />
          <path
            fill="#fff"
            d="m224.46,71.45c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m231.59,71.18c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="210.44" cy="75.06" r=".49" />
          <path
            fill="#fff"
            d="m215.42,69.66c-.04.27-.29.46-.56.41s-.46-.29-.41-.56.29-.46.56-.41.46.29.41.56Z"
          />
          <circle fill="#fff" cx="221.43" cy="68.28" r=".49" />
          <path
            fill="#fff"
            d="m228.63,70.32c-.04.27-.29.46-.56.41s-.46-.29-.41-.56.29-.46.56-.41.46.29.41.56Z"
          />
          <circle fill="#fff" cx="234.71" cy="72.67" r=".49" />
          <circle fill="#fff" cx="241.2" cy="75.33" r=".49" />
          <circle fill="#fff" cx="247.64" cy="78.09" r=".49" />
          <path
            fill="#fff"
            d="m254.54,80.99c-.04.27-.29.46-.56.41s-.46-.29-.41-.56.29-.46.56-.41.46.29.41.56Z"
          />
          <path
            fill="#fff"
            d="m260.94,83.86c-.04.27-.29.46-.56.41s-.46-.29-.41-.56.29-.46.56-.41.46.29.41.56Z"
          />
          <circle fill="#fff" cx="266.83" cy="86.7" r=".49" />
          <path
            fill="#fff"
            d="m273.69,89.7c-.04.27-.29.46-.56.41s-.46-.29-.41-.56.29-.46.56-.41.46.29.41.56Z"
          />
          <path
            fill="#fff"
            d="m280.05,92.65c-.04.27-.29.46-.56.41s-.46-.29-.41-.56.29-.46.56-.41.46.29.41.56Z"
          />
          <circle fill="#fff" cx="285.92" cy="95.53" r=".49" />
          <path
            fill="#fff"
            d="m3.99,4.38c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m10.8,5.83c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m17.79,6.29c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m24.64,6.79c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m31.2,7.86c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="36.82" cy="9.16" r=".73" />
          <path
            fill="#fff"
            d="m43.89,10.73c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m50.3,12.24c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m56.86,13.79c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="62.92" cy="15.12" r=".73" />
          <path
            fill="#fff"
            d="m70.61,16.48c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m77.69,17.43c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m84.72,17.96c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m91.59,18.03c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="97.45" cy="17.68" r=".73" />
          <path
            fill="#fff"
            d="m104.5,17.26c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m110.42,16.24c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m116.37,15.24c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m122.48,14.59c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m129.03,15.08c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m135.75,16.93c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="141.38" cy="20.05" r=".73" />
          <path
            fill="#fff"
            d="m147.95,24.19c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m153.41,28.64c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m158.6,33.3c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m163.66,37.95c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m168.62,42.68c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m173.6,47.29c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m178.62,51.85c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m183.74,56.27c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="188.31" cy="60.41" r=".73" />
          <path
            fill="#fff"
            d="m194.6,64.47c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m200.57,67.98c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m207.03,70.73c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m213.97,72.32c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="220.36" cy="72.85" r=".73" />
          <path
            fill="#fff"
            d="m228.23,73.28c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m235.36,73.03c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m211.96,77.61c-.04.26-.28.43-.54.39s-.43-.28-.39-.54.28-.43.54-.39.43.28.39.54Z"
          />
          <path
            fill="#fff"
            d="m216.44,72.13c-.04.26-.28.43-.54.39s-.43-.28-.39-.54.28-.43.54-.39.43.28.39.54Z"
          />
          <path
            fill="#fff"
            d="m222.93,70.82c-.04.26-.28.43-.54.39s-.43-.28-.39-.54.28-.43.54-.39.43.28.39.54Z"
          />
          <circle fill="#fff" cx="229.16" cy="72.71" r=".47" />
          <path
            fill="#fff"
            d="m236.18,75.21c-.04.26-.28.43-.54.39s-.43-.28-.39-.54.28-.43.54-.39.43.28.39.54Z"
          />
          <circle fill="#fff" cx="242.18" cy="77.79" r=".47" />
          <path
            fill="#fff"
            d="m249.07,80.62c-.04.26-.28.43-.54.39s-.43-.28-.39-.54.28-.43.54-.39.43.28.39.54Z"
          />
          <path
            fill="#fff"
            d="m255.47,83.45c-.04.26-.28.43-.54.39s-.43-.28-.39-.54.28-.43.54-.39.43.28.39.54Z"
          />
          <circle fill="#fff" cx="261.39" cy="86.25" r=".47" />
          <path
            fill="#fff"
            d="m268.21,89.23c-.04.26-.28.43-.54.39s-.43-.28-.39-.54.28-.43.54-.39.43.28.39.54Z"
          />
          <path
            fill="#fff"
            d="m274.57,92.15c-.04.26-.28.43-.54.39s-.43-.28-.39-.54.28-.43.54-.39.43.28.39.54Z"
          />
          <circle fill="#fff" cx="280.45" cy="95.04" r=".47" />
          <circle fill="#fff" cx="286.79" cy="97.99" r=".47" />
          <path
            fill="#fff"
            d="m.48,4.64c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m6.95,7.15c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m13.78,8.49c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m20.77,8.86c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m27.62,9.23c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m34.19,10.13c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m40.55,11.33c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m46.9,12.6c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m53.34,13.92c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m59.91,15.31c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="65.99" cy="16.52" r=".73" />
          <path
            fill="#fff"
            d="m73.69,17.82c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m80.77,18.78c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m87.79,19.38c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m94.65,19.57c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m101.22,19.52c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m107.52,19.21c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m113.44,18.45c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m119.38,17.72c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m125.48,17.31c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m132,17.97c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m138.69,19.91c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m145.03,23.16c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m150.9,27.16c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m156.41,31.53c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m161.66,36.11c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m166.81,40.65c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m171.85,45.28c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m176.92,49.77c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m182.03,54.2c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m187.25,58.49c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m192.62,62.61c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m198.26,66.44c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m204.28,69.85c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m210.77,72.51c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m217.71,74.07c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m224.83,74.72c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m231.97,75.04c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m239.11,74.82c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m212.97,80.01c-.04.24-.27.41-.51.37s-.41-.27-.37-.51.27-.41.51-.37.41.27.37.51Z"
          />
          <path
            fill="#fff"
            d="m217.44,74.52c-.04.24-.27.41-.51.37s-.41-.27-.37-.51.27-.41.51-.37.41.27.37.51Z"
          />
          <path
            fill="#fff"
            d="m223.92,73.21c-.04.24-.27.41-.51.37s-.41-.27-.37-.51.27-.41.51-.37.41.27.37.51Z"
          />
          <path
            fill="#fff"
            d="m230.6,75.17c-.04.24-.27.41-.51.37s-.41-.27-.37-.51.27-.41.51-.37.41.27.37.51Z"
          />
          <path
            fill="#fff"
            d="m237.13,77.6c-.04.24-.27.41-.51.37s-.41-.27-.37-.51.27-.41.51-.37.41.27.37.51Z"
          />
          <path
            fill="#fff"
            d="m243.59,80.25c-.04.24-.27.41-.51.37s-.41-.27-.37-.51.27-.41.51-.37.41.27.37.51Z"
          />
          <path
            fill="#fff"
            d="m250,83.01c-.04.24-.27.41-.51.37s-.41-.27-.37-.51.27-.41.51-.37.41.27.37.51Z"
          />
          <path
            fill="#fff"
            d="m256.38,85.84c-.04.24-.27.41-.51.37s-.41-.27-.37-.51.27-.41.51-.37.41.27.37.51Z"
          />
          <path
            fill="#fff"
            d="m262.75,88.71c-.04.24-.27.41-.51.37s-.41-.27-.37-.51.27-.41.51-.37.41.27.37.51Z"
          />
          <path
            fill="#fff"
            d="m269.1,91.62c-.04.24-.27.41-.51.37s-.41-.27-.37-.51.27-.41.51-.37.41.27.37.51Z"
          />
          <path
            fill="#fff"
            d="m275.44,94.54c-.04.24-.27.41-.51.37s-.41-.27-.37-.51.27-.41.51-.37.41.27.37.51Z"
          />
          <circle fill="#fff" cx="281.32" cy="97.43" r=".45" />
          <path
            fill="#fff"
            d="m288.1,100.45c-.04.24-.27.41-.51.37s-.41-.27-.37-.51.27-.41.51-.37.41.27.37.51Z"
          />
          <path
            fill="#fff"
            d="m3.4,7.43c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m9.91,9.83c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m16.75,11.07c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m23.74,11.35c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m30.59,11.59c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m37.17,12.31c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m43.54,13.31c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m49.91,14.37c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m56.37,15.51c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m62.97,16.74c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m69.78,17.94c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m76.77,19.08c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m83.84,20.04c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m90.86,20.71c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m97.7,21.03c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m104.26,21.17c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m110.54,21.07c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="115.74" cy="20.46" r=".73" />
          <circle fill="#fff" cx="121.67" cy="19.99" r=".73" />
          <path
            fill="#fff"
            d="m128.47,19.93c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m134.95,20.78c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m141.62,22.8c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m147.95,26.07c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m153.85,30.03c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m159.41,34.34c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m164.73,38.83c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m169.95,43.27c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m175.08,47.78c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m180.24,52.15c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m185.44,56.47c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m190.74,60.63c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m196.2,64.62c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m201.91,68.33c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m207.99,71.62c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m214.5,74.2c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m221.46,75.73c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m228.58,76.38c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m235.72,76.71c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m242.85,76.51c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="213.55" cy="82.26" r=".42" />
          <path
            fill="#fff"
            d="m218.44,76.83c-.04.23-.25.39-.48.36s-.39-.25-.36-.48.25-.39.48-.36.39.25.36.48Z"
          />
          <circle fill="#fff" cx="224.48" cy="75.45" r=".42" />
          <circle fill="#fff" cx="231.15" cy="77.41" r=".42" />
          <circle fill="#fff" cx="237.67" cy="79.84" r=".42" />
          <path
            fill="#fff"
            d="m244.53,82.55c-.04.23-.25.39-.48.36s-.39-.25-.36-.48.25-.39.48-.36.39.25.36.48Z"
          />
          <circle fill="#fff" cx="250.5" cy="85.25" r=".42" />
          <circle fill="#fff" cx="256.87" cy="88.07" r=".42" />
          <circle fill="#fff" cx="263.22" cy="90.94" r=".42" />
          <path
            fill="#fff"
            d="m269.97,93.92c-.04.23-.25.39-.48.36s-.39-.25-.36-.48.25-.39.48-.36.39.25.36.48Z"
          />
          <circle fill="#fff" cx="275.88" cy="96.78" r=".42" />
          <circle fill="#fff" cx="282.19" cy="99.73" r=".42" />
          <circle fill="#fff" cx="288.51" cy="102.69" r=".42" />
          <path
            fill="#fff"
            d="m.14,6.92c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m6.33,10.11c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m12.87,12.4c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m19.72,13.55c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m26.72,13.72c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m33.57,13.83c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m40.15,14.37c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m46.54,15.17c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m52.93,16.04c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m59.4,16.99c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m66.02,18.06c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m72.85,19.15c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m79.85,20.23c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m86.92,21.19c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m93.92,21.93c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m100.76,22.38c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m107.3,22.7c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m113.57,22.83c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m119.48,22.59c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m125.4,22.37c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m131.47,22.45c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m137.91,23.47c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m144.55,25.59c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m150.87,28.88c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m156.8,32.8c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m162.41,37.03c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m167.79,41.44c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m173.1,45.78c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m178.31,50.18c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m183.56,54.43c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m188.86,58.62c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m194.25,62.66c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m199.78,66.52c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m205.57,70.11c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m211.69,73.29c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m218.24,75.79c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m225.2,77.28c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m232.32,77.93c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m239.46,78.27c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m246.6,78.1c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m214.98,84.53c-.03.22-.24.37-.46.34s-.37-.24-.34-.46.24-.37.46-.34.37.24.34.46Z"
          />
          <path
            fill="#fff"
            d="m219.44,79.03c-.03.22-.24.37-.46.34s-.37-.24-.34-.46.24-.37.46-.34.37.24.34.46Z"
          />
          <path
            fill="#fff"
            d="m225.89,77.7c-.03.22-.24.37-.46.34s-.37-.24-.34-.46.24-.37.46-.34.37.24.34.46Z"
          />
          <path
            fill="#fff"
            d="m232.54,79.66c-.03.22-.24.37-.46.34s-.37-.24-.34-.46.24-.37.46-.34.37.24.34.46Z"
          />
          <path
            fill="#fff"
            d="m239.04,82.09c-.03.22-.24.37-.46.34s-.37-.24-.34-.46.24-.37.46-.34.37.24.34.46Z"
          />
          <path
            fill="#fff"
            d="m245.47,84.74c-.03.22-.24.37-.46.34s-.37-.24-.34-.46.24-.37.46-.34.37.24.34.46Z"
          />
          <path
            fill="#fff"
            d="m251.85,87.5c-.03.22-.24.37-.46.34s-.37-.24-.34-.46.24-.37.46-.34.37.24.34.46Z"
          />
          <path
            fill="#fff"
            d="m258.2,90.33c-.03.22-.24.37-.46.34s-.37-.24-.34-.46.24-.37.46-.34.37.24.34.46Z"
          />
          <path
            fill="#fff"
            d="m264.54,93.19c-.03.22-.24.37-.46.34s-.37-.24-.34-.46.24-.37.46-.34.37.24.34.46Z"
          />
          <path
            fill="#fff"
            d="m270.85,96.11c-.03.22-.24.37-.46.34s-.37-.24-.34-.46.24-.37.46-.34.37.24.34.46Z"
          />
          <path
            fill="#fff"
            d="m277.17,99.03c-.03.22-.24.37-.46.34s-.37-.24-.34-.46.24-.37.46-.34.37.24.34.46Z"
          />
          <path
            fill="#fff"
            d="m283.46,101.99c-.03.22-.24.37-.46.34s-.37-.24-.34-.46.24-.37.46-.34.37.24.34.46Z"
          />
          <circle fill="#fff" cx="2.31" cy="9.45" r=".73" />
          <path
            fill="#fff"
            d="m9.26,12.65c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m15.84,14.85c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m22.71,15.89c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m29.7,15.97c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m36.55,15.95c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m43.14,16.31c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m49.55,16.91c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m55.96,17.58c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m62.45,18.35c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m69.09,19.25c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m75.93,20.23c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m82.94,21.24c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m90.01,22.21c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m97.01,23.02c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m103.83,23.6c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m110.35,24.1c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m116.6,24.45c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="121.78" cy="24.36" r=".73" />
          <path
            fill="#fff"
            d="m128.42,24.52c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m134.47,24.83c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m140.88,26.04c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="146.77" cy="28.13" r=".73" />
          <circle fill="#fff" cx="153.08" cy="31.44" r=".73" />
          <path
            fill="#fff"
            d="m159.76,35.43c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m165.42,39.6c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m170.87,43.92c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m176.25,48.16c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m181.55,52.45c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="186.18" cy="56.47" r=".73" />
          <path
            fill="#fff"
            d="m192.28,60.65c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m197.76,64.55c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="202.66" cy="68.18" r=".73" />
          <path
            fill="#fff"
            d="m209.24,71.75c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m215.41,74.83c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m221.99,77.24c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m228.96,78.7c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m236.08,79.36c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m243.22,79.71c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m250.36,79.56c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="215.63" cy="86.55" r=".38" />
          <path
            fill="#fff"
            d="m220.45,81.1c-.03.21-.22.35-.43.32s-.35-.22-.32-.43.22-.35.43-.32.35.22.32.43Z"
          />
          <circle fill="#fff" cx="226.51" cy="79.71" r=".38" />
          <circle fill="#fff" cx="233.15" cy="81.67" r=".38" />
          <circle fill="#fff" cx="239.64" cy="84.1" r=".38" />
          <circle fill="#fff" cx="246.05" cy="86.74" r=".38" />
          <circle fill="#fff" cx="252.41" cy="89.51" r=".38" />
          <circle fill="#fff" cx="258.75" cy="92.33" r=".38" />
          <circle fill="#fff" cx="265.07" cy="95.2" r=".38" />
          <circle fill="#fff" cx="271.37" cy="98.11" r=".38" />
          <circle fill="#fff" cx="277.67" cy="101.03" r=".38" />
          <path
            fill="#fff"
            d="m5.94,12.06c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m12.22,15.05c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m18.83,17.14c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m25.72,18.08c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m32.71,18.06c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m39.56,17.91c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m46.16,18.1c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m52.58,18.5c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m59.01,18.96c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m65.52,19.55c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m72.17,20.29c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m79.03,21.15c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m86.05,22.11c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m93.12,23.08c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m100.11,23.96c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m106.92,24.67c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m113.43,25.35c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m119.66,25.93c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m125.56,26.21c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m131.47,26.51c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m137.5,27.07c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m143.88,28.46c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m150.46,30.75c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m156.76,34.07c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m162.74,37.92c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m168.45,42.01c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="173.24" cy="46.15" r=".73" />
          <path
            fill="#fff"
            d="m179.43,50.39c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m184.82,54.57c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m190.25,58.58c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m195.73,62.53c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m201.29,66.3c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m207,69.91c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m212.93,73.25c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m219.16,76.22c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m225.77,78.55c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m232.74,79.97c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="239.14" cy="80.52" r=".73" />
          <path
            fill="#fff"
            d="m247,80.99c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m254.14,80.86c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m217.04,88.54c-.03.19-.21.33-.4.3s-.33-.21-.3-.4.21-.33.4-.3.33.21.3.4Z"
          />
          <path
            fill="#fff"
            d="m221.49,83.02c-.03.19-.21.33-.4.3s-.33-.21-.3-.4.21-.33.4-.3.33.21.3.4Z"
          />
          <path
            fill="#fff"
            d="m227.91,81.68c-.03.19-.21.33-.4.3s-.33-.21-.3-.4.21-.33.4-.3.33.21.3.4Z"
          />
          <path
            fill="#fff"
            d="m234.53,83.64c-.03.19-.21.33-.4.3s-.33-.21-.3-.4.21-.33.4-.3.33.21.3.4Z"
          />
          <path
            fill="#fff"
            d="m241,86.06c-.03.19-.21.33-.4.3s-.33-.21-.3-.4.21-.33.4-.3.33.21.3.4Z"
          />
          <path
            fill="#fff"
            d="m247.4,88.71c-.03.19-.21.33-.4.3s-.33-.21-.3-.4.21-.33.4-.3.33.21.3.4Z"
          />
          <path
            fill="#fff"
            d="m253.74,91.48c-.03.19-.21.33-.4.3s-.33-.21-.3-.4.21-.33.4-.3.33.21.3.4Z"
          />
          <path
            fill="#fff"
            d="m260.07,94.3c-.03.19-.21.33-.4.3s-.33-.21-.3-.4.21-.33.4-.3.33.21.3.4Z"
          />
          <path
            fill="#fff"
            d="m266.37,97.17c-.03.19-.21.33-.4.3s-.33-.21-.3-.4.21-.33.4-.3.33.21.3.4Z"
          />
          <path
            fill="#fff"
            d="m272.66,100.08c-.03.19-.21.33-.4.3s-.33-.21-.3-.4.21-.33.4-.3.33.21.3.4Z"
          />
          <path
            fill="#fff"
            d="m2.85,10.82c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m8.88,14.38c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m15.21,17.28c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m21.85,19.26c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m28.76,20.1c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m35.75,19.99c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m42.6,19.71c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m49.21,19.72c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m55.64,19.92c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="61.36" cy="20.07" r=".73" />
          <path
            fill="#fff"
            d="m68.62,20.58c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m75.29,21.17c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m82.17,21.91c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m89.19,22.81c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m96.26,23.78c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m103.24,24.73c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m110.04,25.57c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m116.53,26.44c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m122.75,27.23c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m128.64,27.77c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m134.54,28.33c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="139.84" cy="29.03" r=".73" />
          <circle fill="#fff" cx="146.18" cy="30.59" r=".73" />
          <path
            fill="#fff"
            d="m153.46,33.08c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="159.03" cy="36.32" r=".73" />
          <path
            fill="#fff"
            d="m165.75,40.23c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m171.51,44.26c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m177.09,48.42c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="181.92" cy="52.34" r=".73" />
          <circle fill="#fff" cx="187.39" cy="56.41" r=".73" />
          <path
            fill="#fff"
            d="m193.64,60.41c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m199.2,64.23c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="204.14" cy="67.77" r=".73" />
          <path
            fill="#fff"
            d="m210.64,71.36c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m216.64,74.58c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m222.93,77.44c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m229.57,79.68c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m236.55,81.07c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m243.67,81.74c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m250.81,82.11c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m257.95,82c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m218.11,90.29c-.03.18-.2.3-.38.28s-.3-.2-.28-.38.2-.3.38-.28.3.2.28.38Z"
          />
          <circle fill="#fff" cx="222.23" cy="84.72" r=".33" />
          <path
            fill="#fff"
            d="m228.96,83.43c-.03.18-.2.3-.38.28s-.3-.2-.28-.38.2-.3.38-.28.3.2.28.38Z"
          />
          <path
            fill="#fff"
            d="m235.56,85.38c-.03.18-.2.3-.38.28s-.3-.2-.28-.38.2-.3.38-.28.3.2.28.38Z"
          />
          <path
            fill="#fff"
            d="m242.02,87.81c-.03.18-.2.3-.38.28s-.3-.2-.28-.38.2-.3.38-.28.3.2.28.38Z"
          />
          <path
            fill="#fff"
            d="m248.4,90.46c-.03.18-.2.3-.38.28s-.3-.2-.28-.38.2-.3.38-.28.3.2.28.38Z"
          />
          <circle fill="#fff" cx="254.41" cy="93.17" r=".33" />
          <path
            fill="#fff"
            d="m261.04,96.04c-.03.18-.2.3-.38.28s-.3-.2-.28-.38.2-.3.38-.28.3.2.28.38Z"
          />
          <path
            fill="#fff"
            d="m267.33,98.91c-.03.18-.2.3-.38.28s-.3-.2-.28-.38.2-.3.38-.28.3.2.28.38Z"
          />
          <path
            fill="#fff"
            d="m273.6,101.82c-.03.18-.2.3-.38.28s-.3-.2-.28-.38.2-.3.38-.28.3.2.28.38Z"
          />
          <path
            fill="#fff"
            d="m5.78,13.02c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m11.87,16.51c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m18.24,19.32c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m24.92,21.2c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m31.84,21.93c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m38.83,21.73c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m45.68,21.32c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m52.29,21.15c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="58.02" cy="21.04" r=".73" />
          <path
            fill="#fff"
            d="m65.21,21.21c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m71.76,21.42c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m78.45,21.85c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m85.34,22.48c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="91.65" cy="23.21" r=".73" />
          <path
            fill="#fff"
            d="m99.44,24.3c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m106.41,25.32c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m113.2,26.28c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m119.68,27.33c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m125.88,28.35c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="131.04" cy="29.04" r=".73" />
          <path
            fill="#fff"
            d="m137.65,29.97c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m143.66,31.02c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m149.97,32.76c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m156.49,35.23c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m162.78,38.59c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m168.81,42.36c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m174.61,46.32c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m180.26,50.4c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m185.89,54.32c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m191.45,58.29c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m197.07,62.05c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m202.72,65.75c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m208.47,69.27c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m214.33,72.63c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m220.4,75.72c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m226.74,78.47c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m233.41,80.63c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="239.68" cy="81.88" r=".73" />
          <path
            fill="#fff"
            d="m247.52,82.65c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m254.66,83.03c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m261.8,82.95c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m219.23,91.86c-.03.17-.18.28-.35.26s-.28-.18-.26-.35.18-.28.35-.26.28.18.26.35Z"
          />
          <path
            fill="#fff"
            d="m223.66,86.33c-.03.17-.18.28-.35.26s-.28-.18-.26-.35.18-.28.35-.26.28.18.26.35Z"
          />
          <circle fill="#fff" cx="229.75" cy="84.94" r=".31" />
          <path
            fill="#fff"
            d="m236.64,86.94c-.03.17-.18.28-.35.26s-.28-.18-.26-.35.18-.28.35-.26.28.18.26.35Z"
          />
          <path
            fill="#fff"
            d="m243.08,89.36c-.03.17-.18.28-.35.26s-.28-.18-.26-.35.18-.28.35-.26.28.18.26.35Z"
          />
          <path
            fill="#fff"
            d="m249.45,92.01c-.03.17-.18.28-.35.26s-.28-.18-.26-.35.18-.28.35-.26.28.18.26.35Z"
          />
          <path
            fill="#fff"
            d="m255.76,94.77c-.03.17-.18.28-.35.26s-.28-.18-.26-.35.18-.28.35-.26.28.18.26.35Z"
          />
          <path
            fill="#fff"
            d="m262.06,97.59c-.03.17-.18.28-.35.26s-.28-.18-.26-.35.18-.28.35-.26.28.18.26.35Z"
          />
          <path
            fill="#fff"
            d="m268.33,100.46c-.03.17-.18.28-.35.26s-.28-.18-.26-.35.18-.28.35-.26.28.18.26.35Z"
          />
          <path
            fill="#fff"
            d="m2.84,11.17c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m8.77,15.02c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="14.18" cy="18.33" r=".73" />
          <path
            fill="#fff"
            d="m21.32,21.15c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m28.03,22.92c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m34.97,23.56c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m41.96,23.25c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m48.81,22.71c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="54.71" cy="22.26" r=".73" />
          <path
            fill="#fff"
            d="m61.89,22.16c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="67.65" cy="21.92" r=".73" />
          <path
            fill="#fff"
            d="m74.94,22.06c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="80.94" cy="22.22" r=".73" />
          <path
            fill="#fff"
            d="m88.57,22.84c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m95.6,23.62c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m102.67,24.6c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m109.63,25.69c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m116.41,26.78c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m122.87,28.02c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m129.06,29.26c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m134.93,30.32c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m140.82,31.4c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m146.8,32.68c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m153.08,34.61c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m159.58,37.16c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m165.86,40.55c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m171.91,44.28c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m177.77,48.17c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m183.48,52.16c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m189.19,55.98c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m194.83,59.84c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m200.54,63.48c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m206.29,67.06c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m212.12,70.45c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m218.07,73.68c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m224.21,76.65c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m230.6,79.29c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m237.3,81.37c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m244.3,82.69c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m251.42,83.36c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m258.56,83.75c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m265.7,83.69c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m220.39,93.22c-.02.15-.17.26-.32.24s-.26-.17-.24-.32.17-.26.32-.24.26.17.24.32Z"
          />
          <path
            fill="#fff"
            d="m224.81,87.68c-.02.15-.17.26-.32.24s-.26-.17-.24-.32.17-.26.32-.24.26.17.24.32Z"
          />
          <path
            fill="#fff"
            d="m231.19,86.33c-.02.15-.17.26-.32.24s-.26-.17-.24-.32.17-.26.32-.24.26.17.24.32Z"
          />
          <path
            fill="#fff"
            d="m237.76,88.28c-.02.15-.17.26-.32.24s-.26-.17-.24-.32.17-.26.32-.24.26.17.24.32Z"
          />
          <path
            fill="#fff"
            d="m244.19,90.71c-.02.15-.17.26-.32.24s-.26-.17-.24-.32.17-.26.32-.24.26.17.24.32Z"
          />
          <path
            fill="#fff"
            d="m250.54,93.35c-.02.15-.17.26-.32.24s-.26-.17-.24-.32.17-.26.32-.24.26.17.24.32Z"
          />
          <path
            fill="#fff"
            d="m256.84,96.11c-.02.15-.17.26-.32.24s-.26-.17-.24-.32.17-.26.32-.24.26.17.24.32Z"
          />
          <path
            fill="#fff"
            d="m263.12,98.94c-.02.15-.17.26-.32.24s-.26-.17-.24-.32.17-.26.32-.24.26.17.24.32Z"
          />
          <path
            fill="#fff"
            d="m269.38,101.8c-.02.15-.17.26-.32.24s-.26-.17-.24-.32.17-.26.32-.24.26.17.24.32Z"
          />
          <path
            fill="#fff"
            d="m5.85,13c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m11.81,16.79c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m17.99,20.13c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m24.45,22.75c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m31.2,24.42c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m38.15,24.95c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m45.15,24.55c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m51.99,23.88c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m58.62,23.36c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m65.1,22.95c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="70.88" cy="22.51" r=".73" />
          <path
            fill="#fff"
            d="m78.19,22.46c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m84.93,22.57c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m91.85,22.97c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m98.89,23.68c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m105.96,24.68c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m112.91,25.83c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m119.68,27.05c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m126.12,28.48c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m132.29,29.93c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m138.16,31.25c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m144.04,32.59c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m150.01,34.12c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m156.25,36.23c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="162" cy="38.76" r=".73" />
          <path
            fill="#fff"
            d="m168.99,42.27c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m175.07,45.97c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m180.98,49.79c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m186.75,53.7c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m192.54,57.41c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m198.27,61.16c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m204.07,64.68c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m209.91,68.14c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m215.83,71.4c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m221.87,74.5c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m228.08,77.35c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m234.52,79.88c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m241.25,81.87c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m248.26,83.16c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m255.38,83.84c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m262.52,84.23c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m269.65,84.2c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="221.35" cy="94.31" r=".26" />
          <circle fill="#fff" cx="225.77" cy="88.76" r=".26" />
          <path
            fill="#fff"
            d="m232.39,87.45c-.02.14-.15.24-.3.22s-.24-.15-.22-.3.15-.24.3-.22.24.15.22.3Z"
          />
          <circle fill="#fff" cx="238.69" cy="89.36" r=".26" />
          <circle fill="#fff" cx="245.1" cy="91.78" r=".26" />
          <path
            fill="#fff"
            d="m251.69,94.47c-.02.14-.15.24-.3.22s-.24-.15-.22-.3.15-.24.3-.22.24.15.22.3Z"
          />
          <path
            fill="#fff"
            d="m257.98,97.23c-.02.14-.15.24-.3.22s-.24-.15-.22-.3.15-.24.3-.22.24.15.22.3Z"
          />
          <path
            fill="#fff"
            d="m264.24,100.05c-.02.14-.15.24-.3.22s-.24-.15-.22-.3.15-.24.3-.22.24.15.22.3Z"
          />
          <path
            fill="#fff"
            d="m3.02,10.57c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m8.92,14.57c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m14.93,18.31c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m21.15,21.58c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m27.66,24.1c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m34.44,25.66c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m41.41,26.1c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="47.68" cy="25.49" r=".73" />
          <path
            fill="#fff"
            d="m55.25,24.8c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m61.88,24.1c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m68.38,23.49c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="74.17" cy="22.85" r=".73" />
          <path
            fill="#fff"
            d="m81.5,22.62c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m88.26,22.57c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m95.19,22.85c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m102.25,23.5c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m109.31,24.5c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="115.54" cy="25.62" r=".73" />
          <circle fill="#fff" cx="122.3" cy="26.97" r=".73" />
          <path
            fill="#fff"
            d="m129.44,28.68c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m135.6,30.36c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m141.46,31.94c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m147.33,33.54c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m153.28,35.31c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m159.49,37.6c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m165.93,40.33c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m172.19,43.75c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m178.29,47.4c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m184.25,51.16c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m190.09,54.98c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m195.97,58.59c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m201.78,62.23c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m207.67,65.63c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m213.6,68.97c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m219.61,72.1c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m225.73,75.07c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m232.01,77.8c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m238.51,80.23c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m245.27,82.13c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m252.29,83.39c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="258.68" cy="83.96" r=".73" />
          <path
            fill="#fff"
            d="m266.54,84.47c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m273.68,84.45c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m222.89,95.23c-.02.13-.14.22-.27.2s-.22-.14-.2-.27.14-.22.27-.2.22.14.2.27Z"
          />
          <path
            fill="#fff"
            d="m227.3,89.67c-.02.13-.14.22-.27.2s-.22-.14-.2-.27.14-.22.27-.2.22.14.2.27Z"
          />
          <path
            fill="#fff"
            d="m233.66,88.31c-.02.13-.14.22-.27.2s-.22-.14-.2-.27.14-.22.27-.2.22.14.2.27Z"
          />
          <path
            fill="#fff"
            d="m240.2,90.27c-.02.13-.14.22-.27.2s-.22-.14-.2-.27.14-.22.27-.2.22.14.2.27Z"
          />
          <path
            fill="#fff"
            d="m246.6,92.69c-.02.13-.14.22-.27.2s-.22-.14-.2-.27.14-.22.27-.2.22.14.2.27Z"
          />
          <path
            fill="#fff"
            d="m252.91,95.33c-.02.13-.14.22-.27.2s-.22-.14-.2-.27.14-.22.27-.2.22.14.2.27Z"
          />
          <path
            fill="#fff"
            d="m259.19,98.09c-.02.13-.14.22-.27.2s-.22-.14-.2-.27.14-.22.27-.2.22.14.2.27Z"
          />
          <path
            fill="#fff"
            d="m265.43,100.92c-.02.13-.14.22-.27.2s-.22-.14-.2-.27.14-.22.27-.2.22.14.2.27Z"
          />
          <path
            fill="#fff"
            d="m.07,8.16c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m6.14,11.93c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m12.07,15.9c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m18.11,19.59c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m24.38,22.78c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m30.93,25.21c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="37.03" cy="26.55" r=".73" />
          <path
            fill="#fff"
            d="m44.73,27c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m51.73,26.4c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m58.57,25.48c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="64.49" cy="24.49" r=".73" />
          <path
            fill="#fff"
            d="m71.73,23.79c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="77.54" cy="22.95" r=".73" />
          <path
            fill="#fff"
            d="m84.89,22.53c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="90.94" cy="22.21" r=".73" />
          <path
            fill="#fff"
            d="m98.62,22.48c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m105.68,23.08c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="112.02" cy="23.97" r=".73" />
          <path
            fill="#fff"
            d="m119.68,25.38c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m126.43,26.85c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m132.84,28.64c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m138.97,30.54c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m144.83,32.38c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m150.69,34.24c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m156.63,36.26c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m162.8,38.72c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m169.22,41.54c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m175.47,44.98c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="180.87" cy="48.49" r=".73" />
          <path
            fill="#fff"
            d="m187.6,52.28c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m193.51,56.02c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m199.46,59.52c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m205.36,63.06c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m211.35,66.34c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m217.36,69.55c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m223.47,72.56c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="228.94" cy="75.29" r=".73" />
          <circle fill="#fff" cx="235.3" cy="77.89" r=".73" />
          <path
            fill="#fff"
            d="m242.57,80.32c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m249.36,82.15c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="255.66" cy="83.26" r=".73" />
          <path
            fill="#fff"
            d="m263.5,84.05c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m270.64,84.46c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m277.78,84.47c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="224.04" cy="95.83" r=".21" />
          <circle fill="#fff" cx="228.45" cy="90.27" r=".21" />
          <circle fill="#fff" cx="234.78" cy="88.91" r=".21" />
          <circle fill="#fff" cx="241.31" cy="90.86" r=".21" />
          <circle fill="#fff" cx="247.69" cy="93.28" r=".21" />
          <circle fill="#fff" cx="254" cy="95.92" r=".21" />
          <circle fill="#fff" cx="260.25" cy="98.68" r=".21" />
          <circle fill="#fff" cx="266.48" cy="101.5" r=".21" />
          <path
            fill="#fff"
            d="m3.28,9.27c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m9.35,13.04c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m15.3,16.98c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m21.38,20.61c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m27.69,23.73c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m34.29,26.07c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m41.14,27.42c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m48.14,27.65c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m55.14,26.96c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="61.26" cy="25.79" r=".73" />
          <path
            fill="#fff"
            d="m68.63,24.84c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m75.16,23.84c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="80.99" cy="22.8" r=".73" />
          <path
            fill="#fff"
            d="m88.35,22.19c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m95.15,21.82c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m102.12,21.87c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m109.19,22.4c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m116.25,23.41c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m123.18,24.78c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="129.19" cy="26.27" r=".73" />
          <path
            fill="#fff"
            d="m136.31,28.35c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m142.43,30.48c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="147.55" cy="32.47" r=".73" />
          <path
            fill="#fff"
            d="m154.13,34.7c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m160.05,36.95c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m166.19,39.6c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m172.58,42.5c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m178.82,45.96c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m184.97,49.54c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m191.03,53.16c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m197,56.81c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m203.04,60.21c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m209.02,63.64c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m215.1,66.8c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="220.49" cy="69.77" r=".73" />
          <circle fill="#fff" cx="226.68" cy="72.65" r=".73" />
          <path
            fill="#fff"
            d="m233.68,75.48c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m240.11,77.96c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m246.71,80.17c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m253.53,81.91c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m260.56,83.09c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m267.68,83.78c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="274.09" cy="84.09" r=".73" />
          <path
            fill="#fff"
            d="m281.95,84.23c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m225.69,96.25c-.02.1-.11.17-.21.16s-.17-.11-.16-.21.11-.17.21-.16.17.11.16.21Z"
          />
          <path
            fill="#fff"
            d="m230.09,90.67c-.02.1-.11.17-.21.16s-.17-.11-.16-.21.11-.17.21-.16.17.11.16.21Z"
          />
          <path
            fill="#fff"
            d="m236.41,89.31c-.02.1-.11.17-.21.16s-.17-.11-.16-.21.11-.17.21-.16.17.11.16.21Z"
          />
          <path
            fill="#fff"
            d="m242.92,91.26c-.02.1-.11.17-.21.16s-.17-.11-.16-.21.11-.17.21-.16.17.11.16.21Z"
          />
          <path
            fill="#fff"
            d="m249.29,93.68c-.02.1-.11.17-.21.16s-.17-.11-.16-.21.11-.17.21-.16.17.11.16.21Z"
          />
          <path
            fill="#fff"
            d="m255.58,96.32c-.02.1-.11.17-.21.16s-.17-.11-.16-.21.11-.17.21-.16.17.11.16.21Z"
          />
          <path
            fill="#fff"
            d="m261.82,99.08c-.02.1-.11.17-.21.16s-.17-.11-.16-.21.11-.17.21-.16.17.11.16.21Z"
          />
          <path
            fill="#fff"
            d="m268.04,101.9c-.02.1-.11.17-.21.16s-.17-.11-.16-.21.11-.17.21-.16.17.11.16.21Z"
          />
          <path
            fill="#fff"
            d="m.18,7.26c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m6.51,10.5c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m12.59,14.27c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m18.55,18.18c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m24.67,21.76c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m31.03,24.8c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m37.67,27.05c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m44.56,28.29c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m51.58,28.42c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m58.57,27.63c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m65.41,26.45c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m72.07,25.21c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m78.62,24c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m85.18,22.87c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m91.85,21.97c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="97.94" cy="21.33" r=".73" />
          <path
            fill="#fff"
            d="m105.65,21.37c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m112.73,21.85c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m119.79,22.86c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m126.71,24.3c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m133.43,26.03c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m139.81,28.19c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m145.91,30.53c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m151.75,32.89c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m157.6,35.27c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m163.51,37.77c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m169.61,40.59c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m175.97,43.59c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m182.21,47.07c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="187.66" cy="50.5" r=".73" />
          <path
            fill="#fff"
            d="m194.49,54.15c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m200.53,57.73c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m206.64,61.02c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m212.71,64.34c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m218.88,67.38c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m225.08,70.34c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m231.36,73.09c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m237.73,75.68c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m244.22,78.03c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="250.15" cy="80.03" r=".73" />
          <path
            fill="#fff"
            d="m257.73,81.79c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m264.77,82.95c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="271.16" cy="83.53" r=".73" />
          <circle fill="#fff" cx="278.3" cy="83.96" r=".73" />
          <circle fill="#fff" cx="285.44" cy="84.01" r=".73" />
          <path
            fill="#fff"
            d="m227.16,96.76c-.01.09-.1.15-.19.14s-.15-.1-.14-.19.1-.15.19-.14.15.1.14.19Z"
          />
          <circle fill="#fff" cx="231.38" cy="91.15" r=".16" />
          <circle fill="#fff" cx="237.7" cy="89.78" r=".16" />
          <path
            fill="#fff"
            d="m244.35,91.75c-.01.09-.1.15-.19.14s-.15-.1-.14-.19.1-.15.19-.14.15.1.14.19Z"
          />
          <circle fill="#fff" cx="250.54" cy="94.15" r=".16" />
          <circle fill="#fff" cx="256.81" cy="96.79" r=".16" />
          <circle fill="#fff" cx="263.04" cy="99.55" r=".16" />
          <circle fill="#fff" cx="269.24" cy="102.37" r=".16" />
          <circle fill="#fff" cx="2.76" cy="8.35" r=".73" />
          <path
            fill="#fff"
            d="m9.8,11.73c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m15.88,15.5c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m21.86,19.38c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m28.02,22.91c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m34.42,25.88c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m41.11,28.03c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m48.03,29.17c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m55.06,29.19c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m62.06,28.31c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m68.9,27c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m75.56,25.58c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m82.13,24.17c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="87.99" cy="22.74" r=".73" />
          <path
            fill="#fff"
            d="m95.4,21.76c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m102.23,21.07c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="108.51" cy="20.77" r=".73" />
          <path
            fill="#fff"
            d="m116.32,21.3c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m123.38,22.32c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m130.29,23.82c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m137,25.69c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m143.36,28.03c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="148.73" cy="30.48" r=".73" />
          <path
            fill="#fff"
            d="m155.28,33.21c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="160.4" cy="35.74" r=".73" />
          <circle fill="#fff" cx="166.29" cy="38.48" r=".73" />
          <path
            fill="#fff"
            d="m173.08,41.59c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m179.42,44.68c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m185.64,48.18c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m191.84,51.68c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m198,55.16c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="203.38" cy="58.53" r=".73" />
          <path
            fill="#fff"
            d="m210.3,61.83c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m216.46,65.04c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m222.72,67.96c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m229.01,70.8c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m235.37,73.42c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m241.82,75.88c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m248.39,78.12c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m255.1,80.11c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m261.98,81.68c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m269.03,82.8c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="275.42" cy="83.39" r=".73" />
          <path
            fill="#fff"
            d="m283.28,83.94c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m290.42,84.01c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m228.68,97.27c-.01.08-.08.13-.16.12s-.13-.08-.12-.16.08-.13.16-.12.13.08.12.16Z"
          />
          <path
            fill="#fff"
            d="m233.06,91.68c-.01.08-.08.13-.16.12s-.13-.08-.12-.16.08-.13.16-.12.13.08.12.16Z"
          />
          <path
            fill="#fff"
            d="m239.36,90.3c-.01.08-.08.13-.16.12s-.13-.08-.12-.16.08-.13.16-.12.13.08.12.16Z"
          />
          <path
            fill="#fff"
            d="m245.84,92.25c-.01.08-.08.13-.16.12s-.13-.08-.12-.16.08-.13.16-.12.13.08.12.16Z"
          />
          <path
            fill="#fff"
            d="m252.18,94.67c-.01.08-.08.13-.16.12s-.13-.08-.12-.16.08-.13.16-.12.13.08.12.16Z"
          />
          <path
            fill="#fff"
            d="m258.43,97.31c-.01.08-.08.13-.16.12s-.13-.08-.12-.16.08-.13.16-.12.13.08.12.16Z"
          />
          <path
            fill="#fff"
            d="m264.64,100.07c-.01.08-.08.13-.16.12s-.13-.08-.12-.16.08-.13.16-.12.13.08.12.16Z"
          />
          <path
            fill="#fff"
            d="m.32,6.71c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m6.9,9.3c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m13.2,12.62c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m19.28,16.38c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m25.29,20.24c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m31.47,23.71c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m37.92,26.61c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m44.66,28.66c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m51.62,29.69c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m58.66,29.62c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m65.66,28.64c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m72.5,27.2c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m79.17,25.61c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m85.75,24c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="91.63" cy="22.36" r=".73" />
          <path
            fill="#fff"
            d="m99.06,21.19c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m105.91,20.34c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m112.92,20.05c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m120.02,20.41c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m127.08,21.42c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m133.98,23c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m140.68,24.99c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m147.03,27.51c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m153.1,30.3c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m158.93,33.18c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m164.76,36.08c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m170.63,39.06c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="175.95" cy="42.14" r=".73" />
          <path
            fill="#fff"
            d="m182.98,45.42c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m189.19,48.93c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m195.42,52.4c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m201.63,55.81c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m207.79,59.21c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m214.07,62.3c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m220.31,65.4c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m226.66,68.19c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m233.04,70.91c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m239.5,73.41c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m246.03,75.74c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m252.67,77.85c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m259.43,79.74c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m266.35,81.22c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m273.4,82.31c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m280.51,83.01c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m287.65,83.45c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="294.07" cy="83.44" r=".73" />
          <circle fill="#fff" cx="230.19" cy="97.41" r=".12" />
          <path
            fill="#fff"
            d="m234.69,91.83c0,.06-.07.11-.13.1s-.11-.07-.1-.13.07-.11.13-.1.11.07.1.13Z"
          />
          <circle fill="#fff" cx="240.86" cy="90.44" r=".12" />
          <circle fill="#fff" cx="247.32" cy="92.38" r=".12" />
          <circle fill="#fff" cx="253.64" cy="94.8" r=".12" />
          <path
            fill="#fff"
            d="m260,97.46c0,.06-.07.11-.13.1s-.11-.07-.1-.13.07-.11.13-.1.11.07.1.13Z"
          />
          <path
            fill="#fff"
            d="m266.19,100.22c0,.06-.07.11-.13.1s-.11-.07-.1-.13.07-.11.13-.1.11.07.1.13Z"
          />
          <circle fill="#fff" cx="3.15" cy="7" r=".73" />
          <path
            fill="#fff"
            d="m10.44,9.78c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m16.73,13.13c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="22.08" cy="16.79" r=".73" />
          <path
            fill="#fff"
            d="m28.83,20.72c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m35.06,24.15c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m41.55,26.96c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="47.61" cy="28.82" r=".73" />
          <path
            fill="#fff"
            d="m55.32,29.85c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m62.39,29.68c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m69.39,28.6c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="75.5" cy="26.92" r=".73" />
          <path
            fill="#fff"
            d="m82.9,25.26c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m89.5,23.45c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m96.12,21.72c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m102.84,20.26c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m109.71,19.25c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m116.74,18.84c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m123.85,19.14c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m130.9,20.16c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m137.8,21.81c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m144.49,23.92c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="150.1" cy="26.52" r=".73" />
          <path
            fill="#fff"
            d="m156.87,29.64c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m162.69,32.78c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m168.52,35.94c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m174.38,39.16c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m180.38,42.53c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m186.66,45.79c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m192.86,49.32c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="198.39" cy="52.64" r=".73" />
          <path
            fill="#fff"
            d="m205.37,56.09c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m211.61,59.41c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m217.96,62.39c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m224.29,65.39c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m230.73,68.06c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m237.2,70.65c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m243.75,73.02c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="249.65" cy="75.11" r=".73" />
          <path
            fill="#fff"
            d="m257.07,77.21c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m263.89,78.99c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m270.83,80.39c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m277.89,81.44c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="284.29" cy="82.04" r=".73" />
          <path
            fill="#fff"
            d="m292.14,82.6c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m299.29,82.72c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m232.07,97.22c0,.05-.06.09-.11.08s-.09-.06-.08-.11.06-.09.11-.08.09.06.08.11Z"
          />
          <path
            fill="#fff"
            d="m236.44,91.62c0,.05-.06.09-.11.08s-.09-.06-.08-.11.06-.09.11-.08.09.06.08.11Z"
          />
          <path
            fill="#fff"
            d="m242.71,90.23c0,.05-.06.09-.11.08s-.09-.06-.08-.11.06-.09.11-.08.09.06.08.11Z"
          />
          <circle fill="#fff" cx="249.06" cy="92.16" r=".09" />
          <path
            fill="#fff"
            d="m255.46,94.59c0,.05-.06.09-.11.08s-.09-.06-.08-.11.06-.09.11-.08.09.06.08.11Z"
          />
          <path
            fill="#fff"
            d="m261.69,97.24c0,.05-.06.09-.11.08s-.09-.06-.08-.11.06-.09.11-.08.09.06.08.11Z"
          />
          <circle fill="#fff" cx="267.78" cy="99.98" r=".09" />
          <path
            fill="#fff"
            d="m.77,5.24c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m7.57,7.11c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m14.11,9.87c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m20.38,13.26c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m26.46,17.02c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="31.79" cy="20.71" r=".73" />
          <path
            fill="#fff"
            d="m38.77,24.19c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="44.59" cy="26.82" r=".73" />
          <path
            fill="#fff"
            d="m52.14,28.8c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m59.16,29.62c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m66.24,29.34c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m73.24,28.17c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m80.08,26.47c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m86.76,24.52c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m93.37,22.51c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m100.01,20.59c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m106.75,18.94c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m113.65,17.77c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="119.97" cy="17.13" r=".73" />
          <path
            fill="#fff"
            d="m127.8,17.48c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m134.86,18.51c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m141.75,20.22c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m148.43,22.47c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m154.74,25.36c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m160.77,28.59c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m166.59,31.99c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m172.41,35.41c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m178.25,38.87c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m184.22,42.42c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m190.47,45.77c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m196.67,49.32c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m202.94,52.71c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m209.25,55.98c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="214.83" cy="59.11" r=".73" />
          <path
            fill="#fff"
            d="m221.99,62.09c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m228.4,64.98c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m234.93,67.53c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m241.5,70.01c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m248.13,72.24c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m254.83,74.32c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m261.61,76.18c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m268.47,77.86c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m275.45,79.17c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m282.52,80.19c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m289.63,80.9c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m296.77,81.36c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m303.91,81.51c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m233.95,96.62s-.04.07-.08.06-.07-.04-.06-.08.04-.07.08-.06.07.04.06.08Z"
          />
          <circle fill="#fff" cx="238.25" cy="91" r=".07" />
          <circle fill="#fff" cx="244.5" cy="89.61" r=".07" />
          <path
            fill="#fff"
            d="m251.01,91.56s-.04.07-.08.06-.07-.04-.06-.08.04-.07.08-.06.07.04.06.08Z"
          />
          <path
            fill="#fff"
            d="m257.3,93.98s-.04.07-.08.06-.07-.04-.06-.08.04-.07.08-.06.07.04.06.08Z"
          />
          <path
            fill="#fff"
            d="m263.51,96.62s-.04.07-.08.06-.07-.04-.06-.08.04-.07.08-.06.07.04.06.08Z"
          />
          <path
            fill="#fff"
            d="m269.67,99.38s-.04.07-.08.06-.07-.04-.06-.08.04-.07.08-.06.07.04.06.08Z"
          />
          <path
            fill="#fff"
            d="m275.81,102.2s-.04.07-.08.06-.07-.04-.06-.08.04-.07.08-.06.07.04.06.08Z"
          />
          <path
            fill="#fff"
            d="m4.61,4.67c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m11.4,6.67c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m17.93,9.51c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m24.18,12.94c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m30.26,16.7c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m36.33,20.47c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="41.91" cy="23.68" r=".73" />
          <path
            fill="#fff"
            d="m49.21,26.45c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m56.09,28.23c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m63.15,28.94c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m70.24,28.57c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m77.24,27.3c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m84.08,25.46c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m90.77,23.34c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m97.4,21.13c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m104.05,19c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m110.81,17.17c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m117.73,15.84c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m124.79,15.19c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m131.91,15.38c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m138.96,16.41c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="145.12" cy="18.08" r=".73" />
          <path
            fill="#fff"
            d="m152.51,20.57c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m158.81,23.64c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m164.82,27.09c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m170.64,30.76c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m176.44,34.43c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m182.27,38.14c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="187.48" cy="41.75" r=".73" />
          <path
            fill="#fff"
            d="m194.43,45.3c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="199.89" cy="48.76" r=".73" />
          <path
            fill="#fff"
            d="m206.92,52.22c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m213.28,55.43c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m219.64,58.58c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m226.16,61.35c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m232.66,64.13c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m239.28,66.56c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m245.93,68.91c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m252.66,71.02c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m259.44,72.97c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m266.29,74.71c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m273.21,76.27c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m280.22,77.51c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m287.29,78.49c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="293.68" cy="79.09" r=".73" />
          <path
            fill="#fff"
            d="m301.54,79.67c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m308.69,79.84c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="235.94" cy="95.57" r=".05" />
          <circle fill="#fff" cx="240.3" cy="89.95" r=".05" />
          <circle fill="#fff" cx="246.54" cy="88.56" r=".05" />
          <circle fill="#fff" cx="252.96" cy="90.5" r=".05" />
          <circle fill="#fff" cx="259.23" cy="92.91" r=".05" />
          <path
            fill="#fff"
            d="m265.47,95.56s-.03.04-.05.04-.04-.03-.04-.05.03-.04.05-.04.04.03.04.05Z"
          />
          <path
            fill="#fff"
            d="m271.63,98.32s-.03.04-.05.04-.04-.03-.04-.05.03-.04.05-.04.04.03.04.05Z"
          />
          <path
            fill="#fff"
            d="m277.75,101.14s-.03.04-.05.04-.04-.03-.04-.05.03-.04.05-.04.04.03.04.05Z"
          />
          <path
            fill="#fff"
            d="m1.71,1.98c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m8.62,3.54c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m15.41,5.66c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m21.91,8.59c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m28.15,12.05c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m34.23,15.82c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m40.32,19.56c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m46.66,22.82c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m53.29,25.41c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m60.2,27.09c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m67.3,27.7c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m74.41,27.23c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m81.41,25.86c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m88.25,23.9c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m94.95,21.6c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m101.59,19.18c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="107.54" cy="16.75" r=".73" />
          <path
            fill="#fff"
            d="m115.04,14.84c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m121.98,13.35c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m129.05,12.59c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m136.18,12.71c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m143.23,13.75c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m150.11,15.6c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="156.04" cy="18" r=".73" />
          <path
            fill="#fff"
            d="m163.04,21.37c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m169.04,25.04c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m174.85,28.96c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="179.93" cy="32.78" r=".73" />
          <path
            fill="#fff"
            d="m186.46,36.84c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="191.63" cy="40.64" r=".73" />
          <circle fill="#fff" cx="197.83" cy="44.16" r=".73" />
          <path
            fill="#fff"
            d="m204.73,47.87c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m211.06,51.18c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m217.47,54.31c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="223.18" cy="57.27" r=".73" />
          <path
            fill="#fff"
            d="m230.49,60.05c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m237.08,62.72c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m243.8,65.03c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m250.54,67.26c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m257.36,69.24c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m264.22,71.06c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m271.14,72.67c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m278.11,74.13c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m285.15,75.28c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m292.23,76.23c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m299.35,76.94c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m306.48,77.43c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m313.63,77.62c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m238.19,93.98s-.01.02-.03.02-.02-.01-.02-.03.01-.02.03-.02.02.01.02.03Z"
          />
          <path
            fill="#fff"
            d="m242.54,88.35s-.01.02-.03.02-.02-.01-.02-.03.01-.02.03-.02.02.01.02.03Z"
          />
          <path
            fill="#fff"
            d="m248.77,86.95s-.01.02-.03.02-.02-.01-.02-.03.01-.02.03-.02.02.01.02.03Z"
          />
          <path
            fill="#fff"
            d="m255.17,88.89s-.01.02-.03.02-.02-.01-.02-.03.01-.02.03-.02.02.01.02.03Z"
          />
          <path
            fill="#fff"
            d="m261.43,91.3s-.01.02-.03.02-.02-.01-.02-.03.01-.02.03-.02.02.01.02.03Z"
          />
          <path
            fill="#fff"
            d="m267.61,93.94s-.01.02-.03.02-.02-.01-.02-.03.01-.02.03-.02.02.01.02.03Z"
          />
          <path
            fill="#fff"
            d="m273.75,96.7s-.01.02-.03.02-.02-.01-.02-.03.01-.02.03-.02.02.01.02.03Z"
          />
          <path
            fill="#fff"
            d="m279.86,99.52s-.01.02-.03.02-.02-.01-.02-.03.01-.02.03-.02.02.01.02.03Z"
          />
          <path
            fill="#fff"
            d="m285.95,102.38s-.01.02-.03.02-.02-.01-.02-.03.01-.02.03-.02.02.01.02.03Z"
          />
          <path
            fill="#fff"
            d="m5.53.19c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m12.46,1.9c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="18.52" cy="4.05" r=".73" />
          <path
            fill="#fff"
            d="m25.72,7.17c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m31.95,10.67c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m38.03,14.44c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m44.14,18.15c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m50.51,21.36c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m57.18,23.87c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m64.15,25.46c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m71.28,25.97c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m78.41,25.38c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m85.41,23.92c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m92.24,21.83c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m98.95,19.35c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m105.6,16.74c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m112.29,14.21c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m119.1,12.01c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="125.33" cy="10.25" r=".73" />
          <path
            fill="#fff"
            d="m133.14,9.48c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m140.28,9.55c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m147.33,10.59c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m154.19,12.51c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m160.84,15.14c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m167.1,18.59c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m173.09,22.48c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="178.16" cy="26.55" r=".73" />
          <circle fill="#fff" cx="183.96" cy="30.74" r=".73" />
          <path
            fill="#fff"
            d="m190.47,35.05c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m196.33,39.13c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m202.5,42.74c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m208.68,46.36c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m215.03,49.63c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m221.49,52.7c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m227.98,55.68c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m234.66,58.24c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m241.33,60.81c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m248.14,62.99c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m254.97,65.1c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <circle fill="#fff" cx="261.15" cy="66.84" r=".73" />
          <path
            fill="#fff"
            d="m268.82,68.65c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m275.81,70.14c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m282.84,71.49c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m289.91,72.55c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m297,73.47c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m304.11,74.19c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m311.24,74.68c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
          <path
            fill="#fff"
            d="m318.39,74.89c-.06.4-.43.67-.83.61s-.67-.43-.61-.83.43-.67.83-.61.67.43.61.83Z"
          />
        </g>
      </g>
    </svg>
  );
};
