import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  Admin,
  CustomRoutes,
  Resource,
  TranslationMessages
} from "react-admin";
import { nexupDataProvider } from "providers/dataProvider/dataProvider";
import { nexupAuthProvider } from "providers/authProvider/authProvider";
import { LoginPage } from "components/pages/LoginPage/LoginPage";
import { tkCP } from "translation/i18n";
import {
  configList,
  customConfigList
} from "components/pages/NexupAdmin/resourceConfig";
import { get, isEmpty, merge } from "lodash-es";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { Dashboard } from "components/pages/Dashboard/Dashboard";
import { DeepPartial, getAsArray, notUndefined } from "@laba/ts-common";
import {
  AdminPanelPermissions,
  AdminPanelSpecialPermissions,
  hasPermission
} from "models/permissions/adminPanelPermissions";
import { spanishMessages } from "translation/spanishMessages";
import { Route } from "react-router-dom";
import { CustomLayout } from "components/pages/NexupAdmin/Layout";

const tk = tkCP.adminPage;
const messagesOverride: DeepPartial<TranslationMessages> = {
  ra: {
    page: {
      dashboard: "Home"
    }
  }
};
const messages = merge(spanishMessages, messagesOverride) as never;
const i18nProvider = polyglotI18nProvider(() => messages, "es");

export const NexupAdmin: FC = () => {
  const { t } = useI18n();
  // theme provider commented because nexup theme on react admin is ugly
  // const muiTheme = useMuiTheme(StyleVariant.Primary);
  return (
    <Admin
      dataProvider={nexupDataProvider}
      authProvider={nexupAuthProvider}
      title={t(tk.title)}
      loginPage={LoginPage}
      // theme={muiTheme}
      i18nProvider={i18nProvider}
      dashboard={Dashboard}
      layout={CustomLayout}
    >
      {rawPermissions => {
        const permissions = getAsArray<AdminPanelPermissions>(rawPermissions);
        const showAllResource =
          hasPermission(AdminPanelSpecialPermissions.Admin, permissions) ||
          hasPermission(AdminPanelSpecialPermissions.AllResources, permissions);
        const resourceList = configList
          .filter(
            value =>
              showAllResource ||
              hasPermission(value.resource, permissions) ||
              value.permissionList?.some(resourcePermission =>
                hasPermission(resourcePermission, permissions)
              )
          )
          .map(config => {
            return (
              <Resource
                key={config.resource}
                name={config.resource}
                icon={config.icon}
                list={config.list}
                edit={config.edit}
                create={config.create}
                options={{
                  label: t(get(tk, `${config.resource}.title`) ?? "")
                }}
              />
            );
          });
        const customList = customConfigList.filter(
          value =>
            showAllResource ||
            value.permissionList.some(resourcePermission =>
              hasPermission(resourcePermission, permissions)
            )
        );
        const customRoute = isEmpty(customList) ? undefined : (
          <CustomRoutes>
            {customList.map(config => {
              const path = `/${config.path}`;
              return (
                <Route key={path} path={path} element={<config.component />} />
              );
            })}
          </CustomRoutes>
        );
        return [customRoute, ...resourceList].filter(notUndefined);
      }}
    </Admin>
  );
};
