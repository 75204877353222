import React from "react";
import { useDesktopOdontogramGridStyles } from "components/odontogram/OdontogramGrid/DesktopOdontogramGrid/useDesktopOdontogramGridStyles";
import { FC, getClassName, ThemeStyleColorVariant } from "@laba/react-common";
import { Divider, DividerOrientation } from "components/divider/Divider";
import { OdontogramGridItemConfig } from "components/odontogram/OdontogramGrid/utils";
import { gridItemMapper } from "../gridItemMapper";

export interface DesktopOdontogramGridProps {
  className?: string;
  onClickItem?: (tooth: string) => void;
  topLeft: OdontogramGridItemConfig[];
  topRight: OdontogramGridItemConfig[];
  bottomLeft: OdontogramGridItemConfig[];
  bottomRight: OdontogramGridItemConfig[];
}

export const DesktopOdontogramGrid: FC<DesktopOdontogramGridProps> = ({
  className,
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  onClickItem
}) => {
  const classes = useDesktopOdontogramGridStyles();
  return (
    <div className={getClassName(className, classes.root)}>
      <div className={classes.itemRow}>
        {topLeft.map(item => gridItemMapper(item, onClickItem))}
        <Divider
          className={classes.verticalDivider}
          orientation={DividerOrientation.Vertical}
          colorVariant={ThemeStyleColorVariant.GrayDark}
          dashed
        />
        {topRight.map(item => gridItemMapper(item, onClickItem))}
      </div>
      <Divider
        className={classes.horizontalDivider}
        colorVariant={ThemeStyleColorVariant.GrayDark}
        orientation={DividerOrientation.Horizontal}
      />
      <div className={classes.itemRow}>
        {bottomLeft.map(item => gridItemMapper(item, onClickItem))}
        <Divider
          className={classes.verticalDivider}
          orientation={DividerOrientation.Vertical}
          colorVariant={ThemeStyleColorVariant.GrayDark}
          dashed
        />
        {bottomRight.map(item => gridItemMapper(item, onClickItem))}
      </div>
    </div>
  );
};
