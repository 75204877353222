import {
  createStyle,
  GridAlignContent,
  gridContainerMixin,
  GridJustifyContent,
  marginMixin,
  paddingMixin,
  pxToRem,
  Theme
} from "@laba/react-common";

export const usePatientTagsStyles = createStyle(
  (_theme: Theme) => ({
    tagsContainer: {
      ...paddingMixin({ left: 16, right: 20 }),
      ...gridContainerMixin({
        gap: 8,
        templateColumn: ["repeat(2, 1fr)"],
        templateRow: [pxToRem(24)],
        alignContent: GridAlignContent.Start,
        justifyContent: GridJustifyContent.Start
      }),
      width: "100%"
    },
    patientTag: {
      ...marginMixin({ top: 0 }),
      width: "100%"
    }
  }),
  "PatientTags"
);
