import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  MeasurementConfig,
  MeasurementGroupDefinition,
  MeasurementGroupDefinitionKey,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { MeasurementGroupDefinitionMeasurementFields } from "components/pages/NexupAdmin/resources/measurementGroupDefinition/MeasurementGroupDefinitionMeasurementFields";
import { FormDataConsumer } from "components/generic/FormDataConsumer/FormDataConsumer";

export const tk = tkCP.adminPage[ResourceType.MeasurementGroupDefinition];
export const MeasurementGroupDefinitionMeasurementListContent: FC = () => {
  const { t } = useI18n();
  return (
    <ArrayInput
      source={MeasurementGroupDefinitionKey.measurements}
      label={t(tk.fields.measurements)}
    >
      <FormDataConsumer<MeasurementGroupDefinition, MeasurementConfig>>
        {({ getSource: getMeasurementSource }) => {
          return (
            <MeasurementGroupDefinitionMeasurementFields
              withProps={false}
              getSource={getMeasurementSource}
              withLinkId
            />
          );
        }}
      </FormDataConsumer>
    </ArrayInput>
  );
};
