import { notUndefined, Optional } from "@laba/ts-common";
import { isEmpty } from "lodash-es";

export interface LocationAddress {
  street?: string;
  streetNumber?: string;
  directions?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
}

const gmapsLink = "https://www.google.com/maps/search/?api=1&query=";

const buildQueryLocation = (
  locationAddress?: LocationAddress
): Optional<string> => {
  const street = locationAddress?.street;
  if (street === undefined) return;
  const streetNumber = locationAddress?.streetNumber;
  const address =
    streetNumber !== undefined ? `${street} ${streetNumber}` : street;

  const listParams = [
    address,
    locationAddress?.postalCode,
    locationAddress?.city,
    locationAddress?.state,
    locationAddress?.country
  ].filter(notUndefined);
  if (isEmpty(listParams)) return;

  return listParams.join(", ");
};

const buildGeoLocation = (
  latitude?: string,
  longitude?: string
): Optional<string> => {
  const hasPosition = latitude !== undefined && longitude !== undefined;
  return hasPosition ? `${latitude},${longitude}` : undefined;
};

// developer.apple.com/library/archive/featuredarticles/iPhoneURLScheme_Reference/MapLinks/MapLinks.html
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getiOSLink = (
  latitude?: string,
  longitude?: string,
  locationAddress?: LocationAddress
): Optional<string> => {
  const query = buildQueryLocation(locationAddress);
  const q = query !== undefined ? `q=${query}` : undefined;
  const p = buildGeoLocation(latitude, longitude);
  const position = p !== undefined ? `ll=${p}` : undefined;
  const listParams = [position, q].filter(notUndefined);

  if (isEmpty(listParams)) return;

  return `https://maps.apple.com/?${listParams.join("&")}`;
};

const getGMapsLink = (locationAddress?: LocationAddress): Optional<string> => {
  const query = buildQueryLocation(locationAddress);
  if (query !== undefined) return `${gmapsLink}${query}`;
};

export const getRedirectMapLink = (
  locationAddress?: LocationAddress
): Optional<string> => {
  // now we redirect to the same gmap link to iphone and android devices.
  // to differentiate iphone devices we can use isiOS helper and choose to
  // redirect to another link
  return getGMapsLink(locationAddress);
};
