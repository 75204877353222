import React from "react";
import { FC } from "@laba/react-common";
import { Create } from "components/generic/Create/Create";
import {
  AppointmentDefinitionForm,
  appointmentDefinitionJsonToModel,
  appointmentDefinitionTransform
} from "components/pages/NexupAdmin/resources/appointmentDefinition/AppointmentDefinitionForm";
import { ResourceType } from "@laba/nexup-api";

export const AppointmentDefinitionCreate: FC = () => {
  return (
    <Create
      resourceTransform={appointmentDefinitionTransform}
      resource={ResourceType.AppointmentDefinition}
      jsonToModel={appointmentDefinitionJsonToModel}
    >
      <AppointmentDefinitionForm />
    </Create>
  );
};
