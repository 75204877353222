import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  CodeSystemKey,
  ResourceType,
  CodeSystemConceptKey,
  CodeSystemConceptPropertyKey,
  KnownConceptPropertyUse
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { CodeSystemPropertyCodeInput } from "components/pages/NexupAdmin/resources/codeSystem/CodeSystemPropertyCodeInput";

export const tk = tkCP.adminPage[ResourceType.CodeSystem];
export const CodeSystemConceptPropsContent: FC = () => {
  const { t } = useI18n();
  return (
    <ArrayInput source={CodeSystemKey.concept} label={t(tk.fields.conceptList)}>
      <TextInput
        source={CodeSystemConceptKey.code}
        label={t(tk.fields.conceptCode)}
      />
      <TextInput
        source={CodeSystemConceptKey.display}
        label={t(tk.fields.conceptDisplay)}
      />
      <ArrayInput
        source={CodeSystemConceptKey.property}
        label={t(tk.fields.conceptProperties)}
      >
        <EnumSelectorInput
          source={CodeSystemConceptPropertyKey.use}
          label={t(tk.fields.conceptPropertyUse)}
          enumValue={KnownConceptPropertyUse}
          withCreate
        />
        <CodeSystemPropertyCodeInput />
      </ArrayInput>
    </ArrayInput>
  );
};
