import React from "react";
import { FC } from "@laba/react-common";
import { Create } from "components/generic/Create/Create";
import { ExternalResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { WhatsappNotificationForm } from "./WhatsappNotificationForm";
import {
  whatsappNotificationJsonToModel,
  whatsappNotificationTransform
} from "./utils";

export const WhatsappNotificationCreate: FC = () => {
  return (
    <Create
      resourceTransform={whatsappNotificationTransform}
      resource={ExternalResourceType.WhatsappNotification}
      jsonToModel={whatsappNotificationJsonToModel}
    >
      <WhatsappNotificationForm />
    </Create>
  );
};
