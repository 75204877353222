import { FC, IconC, OnClickEvent, RadiusVariant } from "@laba/react-common";
import { StyleVariant } from "model/themeVariant";
import { useMuiTheme } from "model/useMuiTheme";
import { ThemeProvider } from "@material-ui/core/styles";
import { Fab } from "@material-ui/core";
import React from "react";
import {
  IconButtonSizeVariant,
  useFloatingIconButtonStyle
} from "components/buttons/FAB/FloatingIconButton/FloatingIconButtonStyle";
import { TypeInvertedVariant } from "components/utils/getGenericButtonColors";

// reexport enum defined in style to fix storybook circular import error.
export { IconButtonSizeVariant };

export interface FloatingIconButtonProps {
  className?: string;
  disabled?: boolean;
  title?: string;
  Icon: IconC;
  onClick?: OnClickEvent;
  size?: IconButtonSizeVariant;
  style?: StyleVariant;
  type?: TypeInvertedVariant;
  radiusVariant?: RadiusVariant;
}

export const FloatingIconButton: FC<FloatingIconButtonProps> = ({
  className,
  title,
  Icon,
  onClick,
  style = StyleVariant.Primary,
  type = TypeInvertedVariant.Normal,
  size = IconButtonSizeVariant.Large,
  disabled = false,
  radiusVariant
}) => {
  const muiTheme = useMuiTheme(style);
  const classes = useFloatingIconButtonStyle({
    size,
    style,
    type,
    disabled,
    radiusVariant
  });
  return (
    <ThemeProvider theme={muiTheme}>
      <Fab
        classes={classes}
        className={className}
        title={title}
        color="primary"
        disabled={disabled}
        variant="circular"
        onClick={onClick}
      >
        <Icon />
      </Fab>
    </ThemeProvider>
  );
};
