import { some } from "lodash-es";

export enum WhatsappNotificationStatus {
  Pending = "Pending",
  PendingSessionOpen = "PendingSessionOpen",
  Queued = "Queued",
  Sent = "Sent",
  Read = "Read",
  Failed = "Failed",
  Timeout = "Timeout",
  SessionOpenFailed = "SessionOpenFailed"
}

const getValidTransitionStatus = (
  status: WhatsappNotificationStatus
): WhatsappNotificationStatus[] => {
  switch (status) {
    case WhatsappNotificationStatus.Pending:
      return [
        WhatsappNotificationStatus.Queued,
        WhatsappNotificationStatus.Sent,
        WhatsappNotificationStatus.Read,
        WhatsappNotificationStatus.Failed,
        WhatsappNotificationStatus.Timeout
      ];
    case WhatsappNotificationStatus.Queued:
      return [
        WhatsappNotificationStatus.Sent,
        WhatsappNotificationStatus.Read,
        WhatsappNotificationStatus.PendingSessionOpen,
        WhatsappNotificationStatus.Failed,
        WhatsappNotificationStatus.Timeout
      ];
    case WhatsappNotificationStatus.Sent:
      return [WhatsappNotificationStatus.Read];
    case WhatsappNotificationStatus.Failed:
    case WhatsappNotificationStatus.Read:
    case WhatsappNotificationStatus.SessionOpenFailed:
    case WhatsappNotificationStatus.Timeout:
      return [];
    case WhatsappNotificationStatus.PendingSessionOpen:
      return [
        WhatsappNotificationStatus.Pending,
        WhatsappNotificationStatus.Failed,
        WhatsappNotificationStatus.SessionOpenFailed,
        WhatsappNotificationStatus.Timeout
      ];
  }
};

export const isValidWhatsappNotificationStatusTransition = (
  currentStatus: WhatsappNotificationStatus,
  newStatus: WhatsappNotificationStatus
): boolean => {
  const validTransitionList = getValidTransitionStatus(currentStatus);
  return some(
    [...validTransitionList, currentStatus],
    status => status === newStatus
  );
};
