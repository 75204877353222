import React from "react";
import {
  FC,
  getClassName,
  useCurrentDateTime,
  withMemo
} from "@laba/react-common";
import { applyFormatToDate, DateFormat } from "@laba/ts-common";
import { useCurrentTimeStyle } from "components/headers/AppHeader/UserButton/currentDate/CurrentTimeStyle";

interface Props {
  className?: string;
}

const CurrentTimeInt: FC<Props> = ({ className }) => {
  const currentDate = useCurrentDateTime(1);
  const classes = useCurrentTimeStyle();

  const dayMonthText = applyFormatToDate(currentDate, DateFormat.DayMonthAbb);
  const timeText = applyFormatToDate(currentDate, DateFormat.NoSecTime);
  const title = applyFormatToDate(currentDate, DateFormat.DateTimeLong);

  return (
    <div
      className={getClassName(classes.currentTimeContainer, className)}
      title={title}
    >
      <p className={classes.currentTime}>{dayMonthText}</p>
      <p className={classes.currentTime}>{timeText}</p>
    </div>
  );
};

export const CurrentTime = withMemo(CurrentTimeInt);
