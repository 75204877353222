import React from "react";
import { FC } from "@laba/react-common";
import { NexupColor } from "model/nexupColor";
import { OdontogramIconProps } from "components/icons/OdontogramIcons/utils";

export const RootCanalIcon: FC<OdontogramIconProps> = props => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill={props.iconColor ?? NexupColor.Black}
    >
      <path d="M7.68036 5.68717V19.3128H5.35014V5.68717H7.68036ZM11.9571 5.68717V7.55882H1.11084V5.68717H11.9571Z" />
      <path d="M21.54 14.877H23.8796C23.8047 15.7692 23.5551 16.5646 23.1309 17.2634C22.7066 17.9559 22.1108 18.5018 21.3435 18.9011C20.5761 19.3004 19.6434 19.5 18.5453 19.5C17.7031 19.5 16.9451 19.3503 16.2713 19.0508C15.5975 18.7451 15.0204 18.3146 14.54 17.7594C14.0596 17.1979 13.6915 16.5209 13.4357 15.7286C13.1862 14.9363 13.0614 14.0504 13.0614 13.0709V11.9385C13.0614 10.959 13.1893 10.0731 13.4451 9.28075C13.7071 8.48841 14.0814 7.8115 14.5681 7.25C15.0547 6.68226 15.638 6.24866 16.3181 5.9492C17.0043 5.64973 17.7748 5.5 18.6296 5.5C19.7151 5.5 20.6322 5.69964 21.3809 6.09893C22.1296 6.49822 22.7098 7.05036 23.1215 7.75535C23.5395 8.46034 23.7953 9.26827 23.8889 10.1791H21.5493C21.487 9.59269 21.3497 9.09046 21.1376 8.67246C20.9317 8.25446 20.626 7.93627 20.2205 7.71791C19.8149 7.49332 19.2846 7.38102 18.6296 7.38102C18.093 7.38102 17.6251 7.48084 17.2258 7.68048C16.8265 7.88012 16.4927 8.17335 16.2245 8.56016C15.9562 8.94697 15.7534 9.42424 15.6162 9.99198C15.4852 10.5535 15.4197 11.1961 15.4197 11.9198V13.0709C15.4197 13.7571 15.4789 14.381 15.5975 14.9425C15.7222 15.4978 15.9094 15.975 16.159 16.3743C16.4148 16.7736 16.7392 17.0824 17.1322 17.3008C17.5253 17.5192 17.9963 17.6283 18.5453 17.6283C19.2129 17.6283 19.7526 17.5223 20.1643 17.3102C20.5823 17.098 20.8974 16.7892 21.1095 16.3837C21.3279 15.9719 21.4714 15.4697 21.54 14.877Z" />
    </svg>
  );
};
