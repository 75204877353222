import { FC, OnBlurEvent } from "@laba/react-common";
import React from "react";
import { DefaultButton } from "components/buttons/DefaultButton/DefaultButton";
import { Add, SearchIcon } from "components/icons";
import { ToAddProblemList } from "components/inputs/ProblemInput/ToAddProblemList/ToAddProblemList";
import { TextInput } from "components/inputs/TextInput/TextInput";
import { SelectedProblemList } from "components/inputs/ProblemInput/SelectedProblemsList/SelectedProblemList";
import { TagTypeVariant } from "components/tags/Tag/Tag";
import { ProblemCreationPopup } from "components/inputs/ProblemInput/ProblemCreationPopup/ProblemCreationPopup";
import { OptionsConfig } from "components/inputs/SelectInput/SelectInput";
import { Optional, Noop } from "@laba/ts-common";
import { useProblemInputStyles } from "components/inputs/ProblemInput/useProblemInputStyles";

export enum ProblemType {
  CreatedProblem = "CreatedProblem",
  SolvedProblem = "SolvedProblem",
  RelatedProblem = "RelatedProblem",
  RelatedSolvedProblem = "RelatedSolvedProblem"
}

export enum ProblemStatus {
  Active = "Active",
  Inactive = "Inactive",
  Resolved = "Resolved"
}

export interface ProblemConfig {
  name: string;
  code: string;
  status: ProblemStatus;
  type?: ProblemType;
}

export interface ProblemInputProps {
  searchText: string;
  onChangeSearchText?: (val: string) => void;
  createButtonText?: string;
  disableSearchbar?: boolean;
  searchbarPlaceholder?: string;
  disableCreateButton?: boolean;
  onClickCreateProblemButton?: Noop;
  visibleActiveProblems: ProblemConfig[];
  onClickAddActiveProblem?: (problem: ProblemConfig) => void;
  disabledAddActiveProblem?: boolean;
  noResultsText: string;
  showNoResult: boolean;
  showMoreProblemsTextGetter?: (count: number) => string;
  selectedProblemsTitle: string;
  getSelectedProblemTagText: (problem: ProblemConfig) => Optional<string>;
  getSelectedProblemTagType: (problem: ProblemConfig) => TagTypeVariant;
  onClickSelectedTagGetter: (problem: ProblemConfig) => Optional<Noop>;
  onRemoveInputSelectedProblem: (problem: ProblemConfig) => void;
  isOpen: boolean;
  onSubmit?: (newProblems: ProblemConfig[]) => void;
  onCancel?: Noop;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  historyProblems: ProblemConfig[];
  valueProblems: ProblemConfig[];
  activeProblems: ProblemConfig[];
  title?: string;
  searchbarOptions: OptionsConfig<ProblemConfig>[];
  clearText: string;
  closeText: string;
  loadingText: string;
  noOptionsText: string;
  openText: string;
  historyTitle: string;
  historyTagText: string;
  inputTitle: string;
  errorText?: string;
  helperText?: string;
  showError?: boolean;
  showHelperOrErrorText?: boolean;
  onBlur?: OnBlurEvent;
}

const getProblemsByType = (problems: ProblemConfig[], type: ProblemType) => {
  return problems.filter(p => p.type === type);
};

export const ProblemInput: FC<ProblemInputProps> = ({
  searchText,
  onChangeSearchText,
  createButtonText,
  disableSearchbar,
  searchbarPlaceholder,
  onClickCreateProblemButton,
  visibleActiveProblems,
  onClickAddActiveProblem,
  noResultsText,
  showNoResult,
  showMoreProblemsTextGetter,
  selectedProblemsTitle,
  onClickSelectedTagGetter,
  onRemoveInputSelectedProblem,
  isOpen,
  onSubmit,
  onCancel,
  primaryButtonText,
  secondaryButtonText,
  searchbarOptions,
  openText,
  noOptionsText,
  loadingText,
  closeText,
  clearText,
  title,
  historyProblems,
  activeProblems,
  valueProblems,
  historyTitle,
  historyTagText,
  getSelectedProblemTagType,
  getSelectedProblemTagText,
  inputTitle,
  errorText,
  helperText,
  disableCreateButton = false,
  disabledAddActiveProblem = false,
  showHelperOrErrorText = true,
  showError = true,
  onBlur
}) => {
  const hasError = showError && Boolean(errorText);
  const showableHelperText = (hasError ? errorText : helperText) || "";
  const classes = useProblemInputStyles({ hasError });
  const createdProblems = getProblemsByType(
    valueProblems,
    ProblemType.CreatedProblem
  );
  const relatedProblems = [
    ...getProblemsByType(valueProblems, ProblemType.RelatedProblem),
    ...getProblemsByType(valueProblems, ProblemType.RelatedSolvedProblem)
  ];
  const resolvedProblems = getProblemsByType(
    valueProblems,
    ProblemType.SolvedProblem
  );

  return (
    <div className={classes.root}>
      <p className={classes.inputTitle}> {inputTitle}</p>
      <div className={classes.inputsContainer}>
        <TextInput
          value={searchText}
          onChange={onChangeSearchText}
          EndIcon={SearchIcon}
          fullWidth
          disabled={disableSearchbar}
          placeholder={searchbarPlaceholder}
          onBlur={onBlur}
        />
        <DefaultButton
          className={classes.createButton}
          disabled={disableCreateButton}
          text={createButtonText}
          StartIcon={Add}
          onClick={onClickCreateProblemButton}
        />
      </div>
      <ToAddProblemList
        problems={visibleActiveProblems}
        onClickAddProblem={onClickAddActiveProblem}
        disabledAddProblem={disabledAddActiveProblem}
        noResultsText={showNoResult ? noResultsText : undefined}
        showMoreProblemsTextGetter={showMoreProblemsTextGetter}
      />
      <SelectedProblemList
        problems={[...createdProblems, ...relatedProblems, ...resolvedProblems]}
        title={selectedProblemsTitle}
        getSelectedProblemTagType={getSelectedProblemTagType}
        getSelectedProblemTagText={getSelectedProblemTagText}
        onClickSelectedTagGetter={onClickSelectedTagGetter}
        onRemoveProblem={onRemoveInputSelectedProblem}
      />
      {showHelperOrErrorText && (
        <p className={classes.errorText}> {showableHelperText} </p>
      )}
      <ProblemCreationPopup
        isOpen={isOpen}
        onSubmit={onSubmit}
        onCancel={onCancel}
        primaryButtonText={primaryButtonText}
        secondaryButtonText={secondaryButtonText}
        searchbarOptions={searchbarOptions}
        openText={openText}
        noOptionsText={noOptionsText}
        loadingText={loadingText}
        closeText={closeText}
        clearText={clearText}
        title={title}
        existingProblems={[
          ...activeProblems,
          ...createdProblems,
          ...relatedProblems,
          ...resolvedProblems
        ]}
        historyProblems={historyProblems}
        historyTitle={historyTitle}
        historyTagText={historyTagText}
        searchbarPlaceholder={searchbarPlaceholder}
      />
    </div>
  );
};
