import { ApiRequestResponse } from "request/types";
import { getApiUrl, requestAnon } from "request/nexupRequest";
import { getKeyObj, HttpMethod } from "@laba/ts-common";
import { Gender } from "model/resource/person/person";
import { createHydratedMock } from "ts-auto-mock";

export enum KnownRequestSource {
  His = "His",
  Patient = "Patient"
}

export interface PractitionerRegisterData {
  email: string;
  source: KnownRequestSource;
  baseUrl: string;
  name: string;
  surname: string;
  password: string;
  gender?: Gender;
  phone?: string;
  invitation?: string;
}

export interface PractitionerRegisterResponse {
  id: string;
  username: string;
  email: string;
  enabled?: string;
  name?: string;
  surname?: string;
  assignedTo?: string[];
  autogenerated?: boolean;
}

export interface RegisterFormData {
  name?: string;
  surname?: string;
  email?: string;
  password?: string;
  passwordAgain?: string;
}

export const registerFormDataParamsKey = getKeyObj(
  createHydratedMock<RegisterFormData>()
);

export const practitionerRegisterRequest = async (
  data: PractitionerRegisterData
): Promise<ApiRequestResponse<PractitionerRegisterResponse>> =>
  requestAnon({
    method: HttpMethod.POST,
    url: getApiUrl("/user/register/practitioner"),
    data
  });
