import React from "react";
import { ReactElement, useI18n } from "@laba/react-common";
import { tkCC } from "translation/i18n";
import { NotificationConfigActionCode, PhoneConfigKey } from "@laba/nexup-api";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { TextInput } from "components/generic/TextInput/TextInput";
import { ThymeleafInput } from "components/generic/ThymeleafInput/ThymeleafInput";
import { NumberInput } from "components/generic/NumberInput/TextInput";

export interface PhoneConfigInputProps {
  source: string;
  label: string;
}

const tk = tkCC.inputs.phoneConfigInput;
export const PhoneConfigInput = ({
  source,
  label
}: PhoneConfigInputProps): ReactElement => {
  const { t } = useI18n();

  return (
    <ArrayInput source={source} label={label}>
      <ArrayInput
        source={PhoneConfigKey.actionCode}
        label={t(tk.phoneConfigCode)}
      >
        <EnumSelectorInput
          source=""
          label={t(tk.phoneConfigCode)}
          enumValue={NotificationConfigActionCode}
          withCreate
        />
      </ArrayInput>
      <TextInput
        source={PhoneConfigKey.phoneId}
        label={t(tk.phoneConfigPhoneId)}
      />
      <ThymeleafInput
        source={PhoneConfigKey.bodyTemplate}
        label={t(tk.phoneConfigBodyTemplate)}
      />
      <NumberInput
        source={PhoneConfigKey.waitDelay}
        label={t(tk.phoneConfigWaitDelay)}
        min={0}
        step={1}
      />
    </ArrayInput>
  );
};
