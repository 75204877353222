import { ReactElement } from "@laba/react-common";
import { SimpleForm, useCreateSuggestionContext } from "react-admin";
import React, { useState } from "react";
import { Model } from "@laba/nexup-api";
import { DeepPartial, Optional } from "@laba/ts-common";
import { Create, ResourceTransform } from "components/generic/Create/Create";
import { Dialog, DialogTitle } from "@mui/material";
import { ResourceFormToolbar } from "components/generic/ResourceForm/ResourceFormToolbar";
import { RaResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";

export interface ReferenceResourceInputCreateProps<T extends Model = Model> {
  resourceType: RaResourceType;
  createInputList?: ReactElement[];
  createResourceTransformer?: ResourceTransform<T>;
  defaultCreateValue?: (filter: Optional<string>) => DeepPartial<T>;
  createPopupTitle?: string;
}

export const ReferenceResourceCreateInput = <T extends Model>({
  resourceType,
  createInputList,
  createResourceTransformer,
  defaultCreateValue,
  createPopupTitle
}: ReferenceResourceInputCreateProps<T>): ReactElement => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [initialValue] = useState(defaultCreateValue?.(filter));

  const CreateEdit = Create;

  return (
    <Dialog open fullWidth onClose={onCancel}>
      {createPopupTitle && <DialogTitle>{createPopupTitle}</DialogTitle>}
      <CreateEdit
        redirect={false}
        resourceTransform={createResourceTransformer}
        resource={resourceType}
        actions={false}
        mutationOptions={{
          onSuccess: data => {
            onCreate(data);
          }
        }}
      >
        <SimpleForm
          defaultValues={initialValue}
          toolbar={<ResourceFormToolbar onCancelButtonClicked={onCancel} />}
        >
          {createInputList}
        </SimpleForm>
      </CreateEdit>
    </Dialog>
  );
};
