import { createStyle, Theme } from "@laba/react-common";
import { MapType } from "components/maps/gmap/Map";

export interface MapStyleProps {
  type: MapType;
}

export const useMapComponentStyles = createStyle(
  (_theme: Theme) => ({
    root: (props: MapStyleProps) => ({
      height: "100%",
      width: "100%",
      opacity: props.type === MapType.Disabled ? 0.4 : undefined
    })
  }),
  "MapComponent"
);
