import React from "react";
import {
  Close as CloseIcon,
  CheckCircleOutlined as CheckCircleOutlinedIcon,
  ErrorOutlineOutlined as ErrorOutlineOutlinedIcon,
  ReportProblemOutlined as ReportProblemOutlinedIcon
} from "@material-ui/icons";
import { Snackbar as MuiSnackbar, IconButton } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";

import { FC, ReactElement, SnackbarStyleVariant } from "@laba/react-common";
import { useMuiTheme } from "model/useMuiTheme";
import { StyleVariant } from "model/themeVariant";
import { useSnackbarStyle } from "components/snackbar/Snackbar/SnackbarStyle";

export type { SnackbarStyleVariant };

export interface SnackbarProps {
  autoHideDuration?: number;
  className?: string;
  isOpen?: boolean;
  key?: string;
  onClose: () => void | Promise<void>;
  style?: SnackbarStyleVariant;
  text?: string;
  isMobile?: boolean;
}

const getIconByStyleVariant = (style: SnackbarStyleVariant): ReactElement => {
  switch (style) {
    case StyleVariant.Success:
      return <CheckCircleOutlinedIcon />;
    case StyleVariant.Danger:
      return <ReportProblemOutlinedIcon />;
    default:
      return <ErrorOutlineOutlinedIcon />;
  }
};

export const Snackbar: FC<SnackbarProps> = ({
  className,
  key,
  onClose,
  text,
  isMobile,
  autoHideDuration = 6000,
  isOpen = false,
  style = StyleVariant.Info
}) => {
  const snackbarClasses = useSnackbarStyle({ style });
  const theme = useMuiTheme(style);
  return (
    <ThemeProvider theme={theme}>
      <MuiSnackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: !isMobile ? "right" : "center"
        }}
        autoHideDuration={autoHideDuration}
        className={className}
        key={key}
        onClose={async (_, reason) => {
          if (reason === "clickaway") return;
          await onClose();
        }}
        open={isOpen}
      >
        <div className={snackbarClasses.container}>
          <div className={snackbarClasses.leftIcon}>
            {getIconByStyleVariant(style)}
          </div>
          <p className={snackbarClasses.message}>{text}</p>
          <IconButton
            className={snackbarClasses.closeIconButton}
            onClick={onClose}
            size="small"
          >
            <CloseIcon className={snackbarClasses.closeIcon} />
          </IconButton>
        </div>
      </MuiSnackbar>
    </ThemeProvider>
  );
};
