export const medicalRequestDefinition = {
  title: "Indicaciones",
  filters: {
    organization: "Organización",
    category: "Categoria",
    name: "Nombre",
    status: "Estado"
  },
  fields: {
    id: "Id",
    organization: "Organización",
    name: "Nombre",
    category: "Categoría",
    type: "Tipo",
    status: "Estado",
    order: "Orden",
    color: "Color",

    renewPeriod: "Periodo de renovacion (seg)",
    administrationTimeframeType: "Tipo de administracion de enfermeria",
    administrationEditPeriod: "Ventana de administracion de enfermeria (seg)",
    prescriptionDatePeriod: "Separacion por defecto de recetas (dias)",
    templateList: "Listado de Formatos",
    templateListCode: "Codigo",
    templateListTemplate: "Formato",
    mainTextTemplate: "Formato texto principal",
    supplementaryTextTemplate: "Formato texto secundario",
    reportTextTemplate: "Formato texto reporte",

    fields: "campos",
    fieldType: "Tipo",
    fieldColumn: "Columna",
    fieldProperties: "Propiedades",
    fieldPropertyType: "Tipo",
    fieldPropertyValue: "Valor",
    fieldPropertyText: "Texto",

    device: "Dispositivos",
    deviceDevice: "Dispositivo",
    deviceQuantity: "Cantidad",
    deviceUnit: "Unidad",
    deviceCondition: "Condiciones",
    deviceConditionElement: "Condicion",
    deviceUse: "Uso",

    emailConfig: "Emails",
    phoneConfig: "Whatsapp",
    procedureReportDefinition: "Definición de Procedimiento"
  },
  tabs: {
    main: "Indicacion",
    medicalConfiguration: "Configuracion Medica",
    device: "Dispositivos",
    email: "Emails",
    phone: "Whatsapp",
    fields: "Campos",
    fieldDetail: "Detalle Campo",
    fieldsComplete: "Campos Completos"
  },
  autocompleteClearText: "Limpiar",
  autocompleteCloseText: "Cerrar",
  autocompleteLoadingText: "Cargando",
  autocompleteNoOptionsText: "Sin opciones",
  autocompleteOpenText: "Abrir",
  autocompleteTitle: "Campo a Editar"
};
