import { StyleVariant } from "model/themeVariant";
import {
  getThemeRadius,
  getThemeStyleColor,
  themeTextMixin
} from "model/themeUtils";
import { Theme, RadiusVariant, ThemeStyleColorVariant } from "model/theme";
import {
  borderMixin,
  borderMixinRadiusAll,
  createStyle,
  flexRowMixin,
  marginMixin,
  paddingMixin,
  pxToRem,
  TextVariant
} from "@laba/react-common";

export interface SearchBarStyleProps {
  fullWidth: boolean;
}

export const useSearchBarFormStyle = createStyle(
  (_theme: Theme) => ({
    form: (props: SearchBarStyleProps) => ({
      ...marginMixin({ all: 0 }),
      width: props.fullWidth ? "100%" : pxToRem(323)
    })
  }),
  "SearchBarForm"
);

export const useSearchBarFormControlStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...borderMixinRadiusAll(getThemeRadius(theme, RadiusVariant.Small)),
      ...flexRowMixin({}),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      ),
      width: "100%"
    }
  }),
  "SearchBarFormControl"
);

export const useSearchBarStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...borderMixinRadiusAll(getThemeRadius(theme, RadiusVariant.Small)),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      ),
      width: "100%"
    }
  }),
  "SearchBar"
);

export const useSearchBarOutlinedInputStyle = createStyle(
  (theme: Theme) => ({
    root: {
      boxSizing: "border-box",
      height: pxToRem(40)
    },
    input: {
      ...paddingMixin({ leftRight: 16 }),
      ...themeTextMixin(theme, TextVariant.Body2, 1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    notchedOutline: {
      ...borderMixin({ side: { all: 0 }, style: "none" })
    }
  }),
  "SearchBarOutlinedInput"
);
