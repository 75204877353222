import { FC, useI18n } from "@laba/react-common";
import React from "react";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import { procedureReportDefinitionOptionText } from "components/generic/ReferenceResourceInput/optionText";
import { NumberInput } from "components/generic/NumberInput/TextInput";
import { ColorInput } from "components/generic/ColorInput/ColorInput";
import {
  getModelReferenceId,
  KnownCodeSystemSystem,
  ModelReference,
  Organization,
  ProcedureReportDefinition,
  ProcedureReportDefinitionKey,
  ProcedureReportDefinitionListQueryParamsKey,
  ResourceType
} from "@laba/nexup-api";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { useSelector } from "react-redux";
import { tkCP } from "translation/i18n";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { ActivityDefinitionCommonInput } from "components/generic/ActivityDefinition/ActivityDefinitionCommonInput/ActivityDefinitionCommonInput";

export const tk = tkCP.adminPage[ResourceType.ProcedureReportDefinition];

export const ProcedureReportDefinitionMainContent: FC = () => {
  const { t } = useI18n();

  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organization = useWatchResourceForm<ModelReference<Organization>>(
    ProcedureReportDefinitionKey.organization
  );
  const organizationId =
    getModelReferenceId(organization) || workspaceOrganizationId;

  const locationCategorySystem = useCodeSystemGroup(
    KnownCodeSystemSystem.LocationCategory,
    organizationId
  );
  return (
    <>
      <ActivityDefinitionCommonInput organizationId={organizationId} />
      <ColorInput
        source={ProcedureReportDefinitionKey.color}
        label={t(tk.fields.color)}
      />
      <NumberInput
        source={ProcedureReportDefinitionKey.order}
        label={t(tk.fields.order)}
        min={0}
        step={1}
      />
      <ArrayInput
        source={ProcedureReportDefinitionKey.locationCategory}
        label={t(tk.fields.locationCategory)}
      >
        <CodeSystemSelectorInput
          source=""
          label={t(tk.fields.locationCategory)}
          withCreate
          codeSystem={locationCategorySystem}
          organizationId={organizationId}
        />
      </ArrayInput>
      <ReferenceResourceInput<ProcedureReportDefinition>
        source={ProcedureReportDefinitionKey.measurementGroupDefinition}
        label={t(tk.fields.measurementGroupDefinition)}
        resourceType={ResourceType.MeasurementGroupDefinition}
        optionTextKey={procedureReportDefinitionOptionText}
        extraFilters={{
          [ProcedureReportDefinitionListQueryParamsKey.organization]:
            organizationId
        }}
      />
    </>
  );
};
