import React from "react";
import { FC, useI18n } from "@laba/react-common";
import { HealthcareService, ResourceType } from "@laba/nexup-api";
import {
  getDefaultResourceTransform,
  ResourceForm
} from "components/generic/ResourceForm/ResourceForm";
import { tkCP } from "translation/i18n";
import {
  getDefaultJsonToModel,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";
import { HealthcareServiceContent } from "components/pages/NexupAdmin/resources/healthcareService/HealthcareServiceContent";

export const healthcareServiceTransform =
  getDefaultResourceTransform<HealthcareService>(
    ResourceType.HealthcareService
  );
export const healthcareServiceJsonToModel: JsonToModel<HealthcareService> =
  getDefaultJsonToModel(draft => {
    draft.organization = undefined;
    draft.location = undefined;
    draft.group = undefined;
  });
export const tk = tkCP.adminPage[ResourceType.HealthcareService];

export const HealthcareServiceForm: FC = () => {
  const { t } = useI18n();
  return (
    <ResourceForm
      tabsConfig={[
        {
          label: t(tk.tabs.healthcareService),
          content: <HealthcareServiceContent />
        }
      ]}
    />
  );
};
