import { ReactElement } from "@laba/react-common";
import React, { useState } from "react";
import { useInput, required as requiredValidate } from "react-admin";
import { SketchPicker } from "react-color";
import { Grid, Popover } from "@mui/material";
import { TextInput } from "components/generic/TextInput/TextInput";
import { useColorInputStyle } from "components/generic/ColorInput/colorInputStyle";
import { Optional } from "@laba/ts-common";
import { NexupColor } from "@laba/nexup-components";

export interface ColorInputProps {
  source: string;
  label?: string;
  required?: boolean;
}

export const ColorInput = ({
  source,
  label,
  required
}: ColorInputProps): ReactElement => {
  const {
    field: { value, onChange }
  } = useInput({
    source,
    validate: required ? requiredValidate() : undefined
  });
  const classes = useColorInputStyle({ color: value });
  const [anchorEl, setAnchorEl] =
    useState<Optional<HTMLButtonElement>>(undefined);
  const open = Boolean(anchorEl);
  const id = open ? "color-input-popover" : undefined;
  const isImage = typeof value === "string" && value.startsWith("http");

  return (
    <Grid container columnSpacing={2}>
      <Grid item sm={8} alignSelf="center">
        <TextInput label={label} source={source} />
      </Grid>
      <Grid item sm={4}>
        {isImage ? (
          <img src={value} alt={value} className={classes.image} />
        ) : (
          <>
            <button
              type="button"
              aria-describedby={id}
              className={classes.swatch}
              onClick={event => setAnchorEl(event.currentTarget)}
            >
              <div className={classes.color} />
            </button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(undefined)}
              anchorOrigin={{
                vertical: "center",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "center"
              }}
            >
              <SketchPicker
                onChangeComplete={color => onChange(color.hex)}
                color={value}
                presetColors={Object.entries(NexupColor).map(
                  nexupColorEntry => ({
                    title: nexupColorEntry[0],
                    color: nexupColorEntry[1]
                  })
                )}
              />
            </Popover>
          </>
        )}
      </Grid>
    </Grid>
  );
};
