import React from "react";
import {
  FunctionField as ReactAdminFunctionField,
  FunctionFieldProps as ReactAdminFunctionFieldProps
} from "react-admin";
import { ReactElement } from "@laba/react-common";
import { RaResource } from "providers/dataProvider/resourceProvider/utils/resourceProviderTypes";
import { Model } from "@laba/nexup-api";

export type FunctionFieldProps<T extends Model> = ReactAdminFunctionFieldProps<
  RaResource<T>
>;

export const FunctionField = <T extends Model>(
  props: FunctionFieldProps<T>
): ReactElement => {
  return <ReactAdminFunctionField {...props} />;
};

// this is a bad practice but needed for react-admin datagrid to don't show sort arrow by default.
FunctionField.defaultProps = {
  sortable: false
};
