import { useFormState as useFinalFormState } from "react-final-form";
import { CustomFormState, TouchedObj } from "forms/types/CustomFormRenderProps";
import { ValidationErrors } from "final-form";
import { flattenObj, notUndefined } from "@laba/ts-common";

const areErrorsNotTouched = (
  touched?: TouchedObj,
  errors?: ValidationErrors
) => {
  if (touched && errors) {
    const flattenErrorObj = flattenObj(errors);
    return Object.keys(flattenErrorObj)
      .filter(key => notUndefined(flattenErrorObj[key]))
      .some(key => (key in touched ? !touched[key] : true));
  }
  return false;
};

// beware of saved browser values set the form dirty but fields remain untouched

export const useFormState = <
  FormValues,
  InitialFormValues,
  E = unknown
>(config?: {
  isDirty?: (initialValues: InitialFormValues, values: FormValues) => boolean;
}): CustomFormState<FormValues, InitialFormValues, E> => {
  const {
    error,
    submitError,
    submitErrors,
    hasSubmitErrors,
    hasValidationErrors,
    touched,
    errors,
    submitFailed,
    initialValues,
    values,
    dirty: finalFormDirty,
    ...otherProps
  } = useFinalFormState<FormValues, InitialFormValues>();
  const hasError = hasSubmitErrors || hasValidationErrors;
  const dirty = config?.isDirty
    ? config.isDirty(initialValues, values)
    : finalFormDirty;
  return {
    error: error || submitError,
    validationError: error,
    hasError,
    showGlobalError:
      submitFailed && hasValidationErrors
        ? areErrorsNotTouched(touched, errors)
        : areErrorsNotTouched(touched, submitErrors),
    hasValidationErrors,
    submitError,
    submitErrors,
    hasSubmitErrors,
    errors,
    submitFailed,
    initialValues,
    values,
    dirty,
    touched,
    ...otherProps
  };
};
