import {
  createStyle,
  marginMixin,
  textMixinWithoutFont,
  TextVariant,
  Theme
} from "@laba/react-common";
import { themeTextMixinWithoutMaxLine } from "model/themeUtils";

interface NexupMarkdownStylesProps {
  maxLines?: number;
}

const generateStyles = (theme: Theme) =>
  Object.fromEntries(
    Object.values(TextVariant).map(variant => [
      variant,
      {
        ...themeTextMixinWithoutMaxLine(theme, variant),
        ...marginMixin({ all: 0 })
      }
    ])
  );

export const useNexupMarkdownStyles = createStyle<
  Theme,
  NexupMarkdownStylesProps,
  string
>(
  (theme: Theme) => ({
    maxLines: (props: NexupMarkdownStylesProps) => ({
      ...textMixinWithoutFont(props.maxLines)
    }),
    ...generateStyles(theme)
  }),
  "NexupMarkdown"
);
