import React from "react";
import { FC } from "@laba/react-common";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export const PinIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <rect width="24" height="24" fill="white" stroke="white" />
        <g transform="matrix(0.788, 0.616, -0.616, 0.788, 9.754, -1.452)">
          <path d="M12.939,7.052V3.443h.722a.724.724,0,0,0,.722-.722h0A.724.724,0,0,0,13.66,2H6.443a.724.724,0,0,0-.722.722h0a.724.724,0,0,0,.722.722h.722V7.052A2.162,2.162,0,0,1,5,9.217H5V10.66H9.309v5.052l.722.722.722-.722V10.66H15.1V9.217h0A2.162,2.162,0,0,1,12.939,7.052Z" />
        </g>
      </svg>
    </SvgIcon>
  );
};
