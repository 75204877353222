import {
  createStyle,
  FlexAlignItems,
  flexRowMixin,
  hoverMixin,
  pxToRem
} from "@laba/react-common";
import { Theme } from "model/theme";
import { getThemeStyleColor } from "model/themeUtils";
import { TextVariant, StyleVariant } from "model/themeVariant";
import { useUrlLinkTextStyle } from "./useUrlLinkTextStyle";

export enum UrlLinkIconSize {
  Medium = "Medium",
  Small = "Small"
}

interface UrlLinkDynamicStylesProps {
  textVariant: TextVariant;
  disabled: boolean;
  multiline: boolean;
  iconSize: UrlLinkIconSize;
  textUnderline: boolean;
}

interface UrlLinkClassNames {
  root: string;
  text: string;
  underlinedOnHover: string;
  icon: string;
}

const useUrlLinkStaticStyles = createStyle(
  (_theme: Theme) => ({
    underlinedOnHover: {
      ...hoverMixin({
        textDecoration: "underline"
      })
    },
    iconMedium: {
      height: pxToRem(24),
      width: pxToRem(24)
    },
    iconSmall: {
      height: pxToRem(18),
      width: pxToRem(18)
    },
    textUnderline: {
      textDecoration: "underline"
    }
  }),
  "UrlLinkStatic"
);

const useUrlLinkDynamicStyles = createStyle(
  (theme: Theme) => ({
    root: (props: UrlLinkDynamicStylesProps) => ({
      ...flexRowMixin({
        gap: 4,
        alignItems: props.multiline
          ? FlexAlignItems.FlexStart
          : FlexAlignItems.Center
      }),
      maxWidth: "100%",
      color: getThemeStyleColor(theme, StyleVariant.Primary),
      cursor: "pointer",
      textAlign: "left"
    })
  }),
  "UrlLinkDynamic"
);

export const useUrlLinkStyle = (
  props: UrlLinkDynamicStylesProps
): UrlLinkClassNames => {
  const staticClasses = useUrlLinkStaticStyles();
  const dynamicClasses = useUrlLinkDynamicStyles(props);
  const textClasses = useUrlLinkTextStyle({
    textVariant: props.textVariant,
    disabled: props.disabled,
    multiline: props.multiline,
    textUnderline: props.textUnderline
  });

  return {
    root: dynamicClasses.root,
    underlinedOnHover: staticClasses.underlinedOnHover,
    text: textClasses.text,
    icon:
      props.iconSize === UrlLinkIconSize.Medium
        ? staticClasses.iconMedium
        : staticClasses.iconSmall
  };
};
