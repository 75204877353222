import { get } from "lodash-es";
import { notUndefined } from "model/types";
import { PhoneValidationError } from "./PhoneValidationError";
import { hasPhoneNumberPlusPrefix } from "./utils";

const argentinianCodeRegex = /^(?:\+?549?|54)/; // Test if it's an argentinian phone
const intAreaCodeRegex = /^(?:\+?549?|54)?(9)\d+/; // Test if it has a 9 after country code
const bsAsInvalidAreaCodeRegex = /^(?:\+?(?:54)?9?15|\+?54915|01115)(\d+)/; // Test if it starts with 15
const bsAsInvalidAdditionalZeroRegex = /^(?:\+?549?|54)?(011)\d+/; // Test if it starts with 011
const phoneWithoutAreaCodeRegex = /^(?:\+?549?|54|011)(\d+)$/; // Matches de digits after +549

const hasInternalAreaCode = (phoneNumber: string) =>
  intAreaCodeRegex.test(phoneNumber);
const hasInvalidBsAsAreaCode = (phoneNumber: string) =>
  bsAsInvalidAreaCodeRegex.test(phoneNumber);
const hasInvalidBsAsAdditionalZeroAreaCode = (phoneNumber: string) =>
  bsAsInvalidAdditionalZeroRegex.test(phoneNumber);
const getPhoneWithoutAreaCode = (phoneNumber: string) => {
  const match = phoneNumber.match(phoneWithoutAreaCodeRegex);
  return get(match, 1);
};
const getPhoneWithoutAreaCodeLength = (phoneNumber: string) => {
  const phone = getPhoneWithoutAreaCode(phoneNumber);
  return phone?.length ?? 0;
};

export const isArgentinianPhone = (phoneNumber: string): boolean =>
  argentinianCodeRegex.test(phoneNumber);

export const validateArgentinianPhoneNumber = (
  phoneNumber: string
): PhoneValidationError[] => {
  const hasPrefix = hasPhoneNumberPlusPrefix(phoneNumber);
  const hasIntAreaCode = hasInternalAreaCode(phoneNumber);
  const invalidBsAsAreaCode = hasInvalidBsAsAreaCode(phoneNumber);
  const invalidBsAsAdditionalZeroAreaCode =
    hasInvalidBsAsAdditionalZeroAreaCode(phoneNumber);
  const phoneWithoutAreaCodeLength = getPhoneWithoutAreaCodeLength(phoneNumber);

  return [
    !hasPrefix ? PhoneValidationError.MissingPrefix : undefined,
    !hasIntAreaCode
      ? PhoneValidationError.InvalidArgentinaInternalAreaCode
      : undefined,
    invalidBsAsAreaCode
      ? PhoneValidationError.InvalidArgentinaBsAsAreaCode
      : undefined,
    invalidBsAsAdditionalZeroAreaCode
      ? PhoneValidationError.InvalidArgentinaBsAsAdditionZeroAreaCode
      : undefined,
    phoneWithoutAreaCodeLength > 10
      ? PhoneValidationError.TooManyDigits
      : undefined,
    phoneWithoutAreaCodeLength < 10
      ? PhoneValidationError.MissingDigits
      : undefined
  ].filter(notUndefined);
};
