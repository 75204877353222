import { ReactElement, useI18n } from "@laba/react-common";
import React from "react";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import {
  getConceptPropertyCode,
  KnownCodeSystemSystem,
  KnownConceptPropertyUse,
  OrganizationCombinedType,
  OrganizationHierarchySearchMode,
  OrganizationListQueryParamsKey,
  PayerConfigurationParamsKey,
  PayerConfigurationType,
  PayerPlanConfigurationParamsKey,
  ScheduleParamsKey
} from "@laba/nexup-api";
import { tkCC } from "translation/i18n";
import { Typography } from "@mui/material";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { BooleanInput } from "../BooleanInput/BooleanInput";
import { OrganizationReferenceInput } from "../OrganizationReferenceInput/OrganizationReferenceInput";
import { CodeSystemSelectorInput } from "../CodeSystemSelectorInput/CodeSystemSelectorInput";

export interface ConfigurationCodeSystemInputProps {
  payerTypeSource: string;
  payerConfigurationSource: string;
  organizationId?: string;
  showOverrideOrganizationPayerRulesConfigurationInput?: boolean;
}

const tk = tkCC.inputs.payerRulesConfigurationInput;

export const PayerRulesConfigurationInput = ({
  payerTypeSource,
  payerConfigurationSource,
  organizationId,
  showOverrideOrganizationPayerRulesConfigurationInput = false
}: ConfigurationCodeSystemInputProps): ReactElement => {
  const { t } = useI18n();

  const organizationPayerPlanSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.OrganizationPayerPlan,
    organizationId
  );

  return (
    <>
      <Typography>{t(tk.payerRulesConfigurationTitle)}</Typography>
      {showOverrideOrganizationPayerRulesConfigurationInput && (
        <BooleanInput
          source={ScheduleParamsKey.overrideOrganizationPayerRulesConfiguration}
          label={t(tk.overrideOrganizationPayerRulesConfiguration)}
        />
      )}
      <EnumSelectorInput
        source={payerTypeSource}
        label={t(tk.payerRulesConfigurationType)}
        enumValue={PayerConfigurationType}
      />
      <ArrayInput
        source={payerConfigurationSource}
        label={t(tk.payerRulesConfigurationPayerConfiguration)}
      >
        <OrganizationReferenceInput
          source={PayerConfigurationParamsKey.payer}
          label={t(tk.payerRulesConfigurationPayerConfigurationPayer)}
          type={OrganizationCombinedType.Payer}
          extraFilters={{
            [OrganizationListQueryParamsKey.partOf]: organizationId,
            [OrganizationListQueryParamsKey.active]: true,
            [OrganizationListQueryParamsKey.organizationHierarchyMode]:
              OrganizationHierarchySearchMode.OwnAndParent
          }}
        />
        <ArrayInput
          source={PayerConfigurationParamsKey.planConfiguration}
          label={t(
            tk.payerRulesConfigurationPayerConfigurationPlanConfiguration
          )}
        >
          <CodeSystemSelectorInput
            source={PayerPlanConfigurationParamsKey.plan}
            label={t(tk.payerRulesConfigurationPayerConfigurationPlan)}
            codeSystem={organizationPayerPlanSystem}
            organizationId={organizationId}
            choices={
              organizationPayerPlanSystem?.concept?.map(concept => {
                const conceptPayerCode = getConceptPropertyCode(
                  KnownConceptPropertyUse.OrganizationPayerCode,
                  concept
                );
                const conceptPayerName = getConceptPropertyCode(
                  KnownConceptPropertyUse.OrganizationPayerName,
                  concept
                );
                const planText = concept.display ?? concept.code;
                const payerText = conceptPayerName ?? conceptPayerCode;
                return {
                  id: concept.code,
                  name: t(
                    tk.payerRulesConfigurationPayerConfigurationPlanOption,
                    { plan: planText, payer: payerText }
                  )
                };
              }) ?? []
            }
          />
        </ArrayInput>
      </ArrayInput>
    </>
  );
};
