import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export const MedicalIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="24.0312" cy="24.9141" r="24" fill="#3D81DB" />
      <g clipPath="url(#clip0)">
        <path
          d="M35.1683 17.6957C36.2616 16.6024 36.2616 14.8298 35.1683 13.7365C34.075 12.6432 32.3024 12.6432 31.2091 13.7365C30.1158 14.8298 30.1158 16.6024 31.2091 17.6957C32.3024 18.789 34.075 18.789 35.1683 17.6957Z"
          fill="#FE9696"
        />
        <path
          d="M33.1911 12.9141C33.0009 12.9141 32.8153 12.9332 32.6357 12.9693C33.9161 13.227 34.8804 14.3578 34.8804 15.714C34.8804 17.0703 33.9161 18.2011 32.6357 18.4588C32.8153 18.4949 33.0009 18.514 33.1911 18.514C34.7375 18.514 35.9911 17.2605 35.9911 15.714C35.9911 14.1676 34.7375 12.9141 33.1911 12.9141V12.9141Z"
          fill="#FE9696"
        />
        <path
          d="M33.1911 19.4727L32.3911 19.7141V33.7808C32.3911 34.6263 31.7033 35.3141 30.8578 35.3141C30.0124 35.3141 29.3245 34.6263 29.3245 33.7808V30.5725C29.3245 28.8447 27.9189 27.4391 26.1912 27.4391C24.4634 27.4391 23.0578 28.8447 23.0578 30.5725L23.0578 33.7808C23.0578 34.6263 22.37 35.3141 21.5245 35.3141C20.679 35.3141 19.9911 34.6263 19.9911 33.7808V32.1141L19.1911 31.7068L18.3911 32.1141V33.7807C18.3911 35.5084 19.7967 36.9141 21.5244 36.9141C23.2521 36.9141 24.6577 35.5085 24.6577 33.7807V30.5724C24.6577 29.7269 25.3456 29.039 26.1911 29.039C27.0365 29.039 27.7244 29.7269 27.7244 30.5724V33.7807C27.7244 35.5084 29.13 36.9141 30.8577 36.9141C32.5854 36.9141 33.9911 35.5085 33.9911 33.7807V19.7141L33.1911 19.4727Z"
          fill="white"
        />
        <path
          d="M18.3911 28.5679H19.9911V32.114H18.3911V28.5679Z"
          fill="white"
        />
        <path d="M32.3911 16.063H33.9911V19.714H32.3911V16.063Z" fill="white" />
        <path
          d="M12.6352 20.914L13.4387 21.1554L14.2422 20.914L13.7543 15.7898C13.7096 15.3202 14.079 14.914 14.5508 14.914H16.5361V13.314H13.671C12.7274 13.314 11.9887 14.1263 12.0781 15.0656L12.6352 20.914Z"
          fill="white"
        />
        <path
          d="M24.6278 15.7898L24.1399 20.914L24.9435 21.1554L25.7471 20.914L26.3041 15.0656C26.3935 14.1263 25.6549 13.314 24.7113 13.314H21.8462V14.9139H23.8314C24.3032 14.914 24.6726 15.3202 24.6278 15.7898Z"
          fill="white"
        />
        <path
          d="M25.5951 22.514L24.7915 22.6589L23.9878 22.514L24.1402 20.9141H25.7475L25.5951 22.514Z"
          fill="white"
        />
        <path
          d="M17.1911 15.3141C17.8461 15.3141 18.3786 14.7893 18.3909 14.1373C18.4036 13.4646 17.8368 12.9141 17.1641 12.9141H16.7911C16.5702 12.9141 16.3911 13.0931 16.3911 13.314V14.914C16.3911 15.135 16.5702 15.314 16.7911 15.314H17.1911V15.3141Z"
          fill="#FE9696"
        />
        <path
          d="M21.1912 12.9141C20.5362 12.9141 20.0038 13.4388 19.9914 14.0909C19.9787 14.7635 20.5455 15.3141 21.2182 15.3141H21.5912C21.8121 15.3141 21.9912 15.135 21.9912 14.9141V13.314C21.9912 13.0931 21.8121 12.9141 21.5912 12.9141H21.1912Z"
          fill="#FE9696"
        />
        <path
          d="M14.2423 20.9141H12.6353L12.7876 22.514L13.5148 22.6589L14.3944 22.514L14.2423 20.9141Z"
          fill="white"
        />
        <path
          d="M23.9876 22.5142C23.9589 22.7721 23.8085 23.8679 23.2361 24.9364C22.3908 26.5141 21.03 27.3142 19.1912 27.3142C15.0196 27.3142 14.4518 23.0843 14.3944 22.5142H12.7876L12.7942 22.5832C12.8068 22.7415 12.9435 24.2125 13.736 25.692C14.5235 27.1619 16.1091 28.9142 19.1912 28.9142C22.2732 28.9142 23.8589 27.162 24.6464 25.692C25.4389 24.2125 25.5756 22.7415 25.5883 22.5832L25.5948 22.5142H23.9876Z"
          fill="white"
        />
        <path
          d="M21.5914 12.9141H21.1913C21.0293 12.9141 20.8749 12.9463 20.7339 13.0045C20.8235 13.0778 20.8807 13.1893 20.8807 13.3141V14.9141C20.8807 15.0355 20.8264 15.1441 20.7409 15.2175C20.8885 15.2796 21.0501 15.3141 21.2184 15.3141H21.5914C21.8123 15.3141 21.9914 15.135 21.9914 14.9141V13.314C21.9914 13.0931 21.8123 12.9141 21.5914 12.9141V12.9141Z"
          fill="#FE9696"
        />
        <path
          d="M17.1644 12.9141H16.7915C16.692 12.9141 16.6012 12.9506 16.5312 13.0107C16.9738 13.1971 17.2902 13.6328 17.2806 14.1373C17.2713 14.628 16.9673 15.0466 16.5383 15.2237C16.6072 15.2801 16.6954 15.3141 16.7915 15.3141H17.1915C17.8465 15.3141 18.3789 14.7893 18.3913 14.1373C18.404 13.4646 17.8372 12.9141 17.1644 12.9141V12.9141Z"
          fill="#FE9696"
        />
        <path
          d="M32.3911 20.9519H33.9911V21.6763H32.3911V20.9519Z"
          fill="#474F54"
        />
        <path d="M32.8804 16.063H33.991V19.714H32.8804V16.063Z" fill="white" />
        <path
          d="M34.3667 15.9162C34.476 15.2627 34.0349 14.6443 33.3814 14.535C32.728 14.4256 32.1096 14.8667 32.0002 15.5202C31.8909 16.1736 32.332 16.792 32.9854 16.9014C33.6389 17.0107 34.2573 16.5696 34.3667 15.9162Z"
          fill="#474F54"
        />
        <path
          d="M33.1911 14.5139C32.9906 14.5139 32.8018 14.5633 32.6357 14.6502C33.0189 14.8507 33.2804 15.2516 33.2804 15.7139C33.2804 16.1762 33.0189 16.5772 32.6357 16.7776C32.8018 16.8645 32.9906 16.9139 33.1911 16.9139C33.8538 16.9139 34.3911 16.3767 34.3911 15.7139C34.3911 15.0512 33.8538 14.5139 33.1911 14.5139Z"
          fill="#474F54"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(12.0312 12.9141)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
