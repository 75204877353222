import React from "react";
import { FC, IconC } from "@laba/react-common";
import { notUndefined } from "@laba/ts-common";
import { some } from "lodash-es";
import { useAutocompleteOptionTextItemStyles } from "./AutocompleteOptionTextItemStyle";

interface Props {
  title?: string;
  titleText?: string;
  primaryText?: string;
  secondaryText?: string;
  Icon?: IconC;
}

export const AutocompleteOptionTextItem: FC<Props> = ({
  title,
  titleText,
  primaryText,
  secondaryText,
  Icon
}) => {
  const withSubtext = some([primaryText, secondaryText], notUndefined);
  const classes = useAutocompleteOptionTextItemStyles({
    withSubtext
  });

  const pText = primaryText
    ? secondaryText
      ? primaryText.concat(" | ")
      : primaryText
    : undefined;
  return (
    <div className={classes.rootContainer}>
      {/* HIS-15021: armar una variante especifica para las opciones que tienen icono */}
      {Icon && <Icon />}
      <div className={classes.root} title={title}>
        <p className={classes.title}>{titleText}</p>
        {withSubtext && (
          <p className={classes.subtext}>
            {pText && <span className={classes.primarySubtext}>{pText}</span>}
            {secondaryText}
          </p>
        )}
      </div>
    </div>
  );
};
