import { ApiDate, getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { Attachment } from "model/primitives/attachment/attachment";
import { Code } from "model/resource/entities/codeSystem";
import {
  createBaseIdentifier,
  Identifier,
  KnownIdentifierSystem
} from "model/primitives";

export enum QualificationValidationStatus {
  Pending = "Pending",
  Validated = "Validated",
  Denied = "Denied"
}

export interface PractitionerQualification {
  type?: Code;
  name?: string;
  issuer?: string;
  identifier?: Identifier;
  startDate?: ApiDate;
  endDate?: ApiDate;
  category?: string;
  place?: string;
  signature?: Attachment;
  validationStatus?: QualificationValidationStatus;
  default?: boolean;
}

export const PractitionerQualificationKey = getKeyObj(
  createHydratedMock<PractitionerQualification>()
);

export const createBasePractitionerQualification = (
  isDefault?: boolean,
  place?: string,
  value?: string,
  type?: Code
): PractitionerQualification => {
  return {
    type,
    validationStatus: QualificationValidationStatus.Pending,
    default: isDefault,
    identifier: createBaseIdentifier(
      KnownIdentifierSystem.PractitionerQualificationRegistration,
      value
    ),
    place
  };
};
