import React from "react";
import {
  FC,
  FontConfigCase,
  TextVariant,
  useI18n,
  TextWeight
} from "@laba/react-common";
import { OrganizationKey, ResourceType } from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { Labeled } from "react-admin";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { TextInput } from "components/generic/TextInput/TextInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { NumberInput } from "components/generic/NumberInput/TextInput";

export const tk = tkCP.adminPage[ResourceType.Organization];
export interface OrganizationWhiteLabelThemeTextContentProps {
  textVariant: TextVariant;
  label: string;
}
export const OrganizationWhiteLabelThemeTextContent: FC<
  OrganizationWhiteLabelThemeTextContentProps
> = ({ textVariant, label }) => {
  const { t } = useI18n();
  return (
    <Labeled label={label} fullWidth>
      <>
        <ArrayInput
          source={
            OrganizationKey.whiteLabelConfig.theme.text[textVariant].fontFamily
          }
          label={t(tk.fields.whiteLabelConfigThemeTextFontFamily)}
        >
          <TextInput
            source=""
            label={t(tk.fields.whiteLabelConfigThemeTextFontFamilyElement)}
          />
        </ArrayInput>
        <EnumSelectorInput
          source={
            OrganizationKey.whiteLabelConfig.theme.text[textVariant].fontWeight
          }
          label={t(tk.fields.whiteLabelConfigThemeTextFontWeight)}
          enumValue={TextWeight}
        />
        <NumberInput
          source={
            OrganizationKey.whiteLabelConfig.theme.text[textVariant].fontSize
          }
          label={t(tk.fields.whiteLabelConfigThemeTextFontSize)}
          min={0}
          step={1}
        />
        <EnumSelectorInput
          source={OrganizationKey.whiteLabelConfig.theme.text[textVariant].case}
          label={t(tk.fields.whiteLabelConfigThemeTextFontCase)}
          enumValue={FontConfigCase}
        />
        <NumberInput
          source={
            OrganizationKey.whiteLabelConfig.theme.text[textVariant]
              .letterSpacing
          }
          label={t(tk.fields.whiteLabelConfigThemeTextFontSpacing)}
          step={0.1}
        />
      </>
    </Labeled>
  );
};
