import React from "react";
import { FC } from "@laba/react-common";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export const UpArrowIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props}>
      <path d="M5.2,16V9.25H0L4,4.62,8,0l4,4.62,4,4.63H10.91V16Z" />
    </SvgIcon>
  );
};
