import React from "react";
import { FC } from "@laba/react-common";
import { Create } from "components/generic/Create/Create";
import {
  MedicationSubstanceForm,
  medicationSubstanceJsonToModel,
  medicationSubstanceTransform
} from "components/pages/NexupAdmin/resources/medicationSubstance/MedicationSubstanceForm";
import { ResourceType } from "@laba/nexup-api";

export const MedicationSubstanceCreate: FC = () => {
  return (
    <Create
      resourceTransform={medicationSubstanceTransform}
      resource={ResourceType.MedicationSubstance}
      jsonToModel={medicationSubstanceJsonToModel}
    >
      <MedicationSubstanceForm />
    </Create>
  );
};
