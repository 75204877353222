import { getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";

export interface WhatsappClientSessionData {
  id: string;
  qr?: string;
  phone?: string;
  isAuthenticated: boolean;
  isReady?: boolean;
  whatsappWebVersion?: string;
  statusCallbackUrlList?: string[];
  responseUrlList?: string[];
}

export const WhatsappClientSessionDataParamsKey =
  getKeyObj<WhatsappClientSessionData>(
    createHydratedMock<WhatsappClientSessionData>()
  );
