import {
  borderMixin,
  createStyle,
  destyledButtonMixin,
  flexItemMixin,
  flexRowMixin,
  hoverMixin,
  paddingMixin,
  pxToRem,
  StyleVariant,
  TextVariant,
  Theme,
  TypeVariant
} from "@laba/react-common";
import {
  getThemeRadius,
  getThemeStyleColor,
  themeTextMixin
} from "model/themeUtils";
import { RadiusVariant, ThemeStyleColorVariant } from "model/theme";
import {
  getGenericButtonBackgroundColor,
  getGenericButtonColor,
  getTypeVariantGenericButtonHoverBackgroundColor
} from "components/utils/getGenericButtonColors";

export interface SwitchButtonInputStylesProps {
  style: StyleVariant;
  fullWidth: boolean;
  radius?: RadiusVariant;
  textVariant?: TextVariant;
}

export const SwitchButtonInputStyles = createStyle(
  (theme: Theme) => ({
    root: (props: SwitchButtonInputStylesProps) => ({
      ...borderMixin({
        radius: {
          all: getThemeRadius(
            theme,
            props.radius ? props.radius : RadiusVariant.Large
          )
        },
        side: { all: 0 }
      }),
      outlineWidth: pxToRem(1),
      outlineColor: getThemeStyleColor(
        theme,
        props.style,
        ThemeStyleColorVariant.Main
      ),
      outlineStyle: "solid",
      ...flexRowMixin({}),
      width: props.fullWidth ? "100%" : "min-content",
      height: pxToRem(40),
      backgroundColor: getThemeStyleColor(
        theme,
        props.style,
        ThemeStyleColorVariant.Contrast
      ),
      overflow: "hidden"
    }),
    button: {
      ...destyledButtonMixin(),
      ...flexItemMixin({ grow: 1 }),
      ...paddingMixin({ leftRight: 8 })
    },
    firstButton: (props: SwitchButtonInputStylesProps) => ({
      ...borderMixin({
        radius: {
          right: getThemeRadius(
            theme,
            props.radius ? props.radius : RadiusVariant.Large
          )
        }
      })
    }),
    secondButton: (props: SwitchButtonInputStylesProps) => ({
      ...borderMixin({
        radius: {
          left: getThemeRadius(
            theme,
            props.radius ? props.radius : RadiusVariant.Large
          )
        }
      })
    }),
    active: (props: SwitchButtonInputStylesProps) => ({
      ...hoverMixin({
        backgroundColor: getTypeVariantGenericButtonHoverBackgroundColor(
          theme,
          props.style,
          TypeVariant.Contained
        )
      }),
      backgroundColor: getGenericButtonBackgroundColor(theme, {
        style: props.style,
        type: TypeVariant.Contained
      }),
      color: getGenericButtonColor(theme, {
        style: props.style,
        type: TypeVariant.Contained
      })
    }),
    inactive: (props: SwitchButtonInputStylesProps) => ({
      ...hoverMixin({
        backgroundColor: getTypeVariantGenericButtonHoverBackgroundColor(
          theme,
          props.style,
          TypeVariant.Text
        )
      }),
      backgroundColor: getGenericButtonBackgroundColor(theme, {
        style: props.style,
        type: TypeVariant.Text
      }),
      color: getGenericButtonColor(theme, {
        style: props.style,
        type: TypeVariant.Text
      })
    }),
    text: (props: SwitchButtonInputStylesProps) => ({
      ...themeTextMixin(
        theme,
        props.textVariant ? props.textVariant : TextVariant.Body2,
        1
      )
    })
  }),
  "SwitchButtonInput"
);
