import { FC, getClassName, StyleVariant, useI18n } from "@laba/react-common";
import React, { useState } from "react";
import {
  IconButtonType,
  NexupIconButton
} from "components/buttons/IconButton/IconButton";
import { Noop } from "@laba/ts-common";
import {
  CallEndIcon,
  MicIcon,
  MicOffIcon,
  Sms,
  VideocamIcon,
  VideocamOffIcon,
  VolumeOffIcon,
  VolumeUpIcon
} from "components/icons";
import { BadgedComponent } from "components/containers/BadgedComponent/BadgedComponent";
import { useVideoControlsOverlayStyles } from "components/videoControlsOverlay/useVideoControlsOverlayStyles";
import { VolumeControlButton } from "components/videoControlsOverlay/VolumeControlButton/VolumeControlButton";
import { ShareScreenIcon } from "components/icons/ShareScreenIcon";
import { StopShareScreenIcon } from "components/icons/StopShareScreenIcon";

interface ButtonTitle {
  onText: string;
  offText: string;
}

interface Props {
  className?: string;
  isMicOn: boolean;
  isCamOn: boolean;
  isAudioOn: boolean;
  isChatVisible: boolean;
  onToggleMic: Noop;
  onToggleCam: Noop;
  onToggleMute: Noop;
  onToggleViewChat: Noop;
  onEndCall: Noop;
  showChat?: boolean;
  hasUnreadMessage?: boolean;
  isMobile?: boolean;
  micTitle: ButtonTitle;
  camTitle: ButtonTitle;
  audioTitle: ButtonTitle;
  chatTitle: ButtonTitle;
  shareTitle: ButtonTitle;
  hangUpText?: string;
  showChatButton: boolean;
  currentVolume?: number;
  maxVolume?: number;
  isMuted?: boolean;
  onChangeVolume?: (newVol: number) => void;
  withVolume?: boolean;
  isSharingScreen?: boolean;
  canScreenShare?: boolean;
  onToggleScreenShare?: Noop;
}

const transitionMsDuration = 1000;

export const VideoControlsOverlay: FC<Props> = ({
  className,
  isMicOn,
  isCamOn,
  isAudioOn,
  isChatVisible,
  onToggleMic,
  onToggleCam,
  onToggleMute,
  onToggleViewChat,
  onEndCall,
  showChat,
  hasUnreadMessage = false,
  isMobile,
  micTitle,
  camTitle,
  audioTitle,
  chatTitle,
  shareTitle,
  hangUpText,
  showChatButton,
  currentVolume,
  maxVolume,
  isMuted,
  onChangeVolume,
  withVolume,
  isSharingScreen,
  canScreenShare,
  onToggleScreenShare
}) => {
  const { t } = useI18n();
  const [isVisible, setIsVisible] = useState(true);
  const onTouch = () => setIsVisible(!isVisible);
  const showUnreadMessagesButton = hasUnreadMessage && !showChat;
  const showOverlay = isMobile
    ? isVisible || showUnreadMessagesButton
    : showUnreadMessagesButton;

  const classes = useVideoControlsOverlayStyles({
    isVisible: showOverlay,
    transitionMsDuration
  });
  const areButtonsDisabled = isMobile && !showOverlay;
  return (
    <div
      className={getClassName(
        className,
        classes.root,
        isMobile ? undefined : classes.desktopRoot
      )}
      role="menu"
      tabIndex={0}
      onClick={isMobile ? onTouch : undefined}
      onKeyDown={isMobile ? onTouch : undefined}
    >
      <div
        className={getClassName(
          classes.buttonsContainer,
          isMobile
            ? classes.buttonsContainerMobile
            : classes.buttonsContainerDesktop
        )}
      >
        <NexupIconButton
          buttonType={IconButtonType.Button}
          onClick={onToggleMic}
          title={t(isMicOn ? micTitle.offText : micTitle.onText)}
          Icon={isMicOn ? MicIcon : MicOffIcon}
          disabled={areButtonsDisabled}
        />
        <NexupIconButton
          buttonType={IconButtonType.Button}
          onClick={onToggleCam}
          title={t(isCamOn ? camTitle.offText : camTitle.onText)}
          Icon={isCamOn ? VideocamIcon : VideocamOffIcon}
          disabled={areButtonsDisabled}
        />
        {withVolume ? (
          onChangeVolume && (
            <VolumeControlButton
              onToggleMute={onToggleMute}
              onChangeVolume={onChangeVolume}
              isMuted={isMuted}
              volume={currentVolume}
              muteText={audioTitle.offText}
              unmuteText={audioTitle.onText}
              maxVolume={maxVolume}
            />
          )
        ) : (
          <NexupIconButton
            onClick={onToggleMute}
            title={isAudioOn ? audioTitle.offText : audioTitle.onText}
            Icon={isAudioOn ? VolumeUpIcon : VolumeOffIcon}
            disabled={areButtonsDisabled}
          />
        )}
        {canScreenShare && (
          <NexupIconButton
            buttonType={IconButtonType.Button}
            onClick={onToggleScreenShare}
            title={isSharingScreen ? shareTitle.offText : shareTitle.onText}
            Icon={isSharingScreen ? StopShareScreenIcon : ShareScreenIcon}
            disabled={areButtonsDisabled}
          />
        )}
        {showChatButton && (
          <BadgedComponent
            style={StyleVariant.Danger}
            isVisible={hasUnreadMessage}
          >
            <NexupIconButton
              buttonType={IconButtonType.Button}
              onClick={onToggleViewChat}
              title={t(isChatVisible ? chatTitle.offText : chatTitle.onText)}
              Icon={Sms}
              disabled={areButtonsDisabled}
            />
          </BadgedComponent>
        )}

        <NexupIconButton
          buttonType={IconButtonType.Button}
          style={StyleVariant.Danger}
          onClick={onEndCall}
          title={hangUpText}
          Icon={CallEndIcon}
          disabled={areButtonsDisabled}
        />
      </div>
    </div>
  );
};
