import React from "react";
import {
  Datagrid as ReactAdminDatagrid,
  DatagridProps as ReactAdminDatagridProps
} from "react-admin";
import { ReactElement } from "@laba/react-common";

export type DatagridProps = ReactAdminDatagridProps;

export const Datagrid = (props: DatagridProps): ReactElement => {
  return (
    <ReactAdminDatagrid bulkActionButtons={false} rowClick="edit" {...props} />
  );
};
