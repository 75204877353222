import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export const PopupMenuOpenIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <line
        data-name="Line 280"
        y2="22"
        transform="translate(0.5)"
        fill="none"
        stroke="#4f4f4f"
        strokeWidth="1"
      />
      <g data-name="Group 1899" transform="translate(-977 -395.5)">
        <g
          data-name="Rectangle 696"
          transform="translate(977 398)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="18" height="18" stroke="none" />
          <rect x="0.5" y="0.5" width="17" height="17" fill="none" />
        </g>
        <path
          id="icon"
          d="M5.557,1.058,4.5,0,0,4.5,4.5,9,5.557,7.943,2.122,4.5Z"
          transform="translate(988.558 411.5) rotate(180)"
          fill="#4f4f4f"
        />
      </g>
    </SvgIcon>
  );
};
