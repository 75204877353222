import { OpenCode } from "model/primitives/model/model";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { ApiDate } from "@laba/ts-common";
import { ResourceModel, ResourceType } from "model/primitives/resourceModel";
import { Practitioner } from "model/resource/person/practitioner/practitioner";
import { Patient } from "model/resource/person/patient/patient";
import { Encounter } from "model/resource/entities/encounter/encounter";

export enum ObservationCategory {
  Measure = "Measure",
  Tag = "Tag",
  MeasurementGroup = "MeasurementGroup",
  Odontogram = "Odontogram"
}

export enum KnownMeasureObservationCode {
  Weight = "Weight",
  Height = "Height",
  Bmi = "Bmi",
  CreatinineClearance = "CreatinineClearance",
  Unknown = "Unknown"
}

export enum KnownMeasurementGroupObservationCode {
  VitalSigns = "VitalSigns",
  FluidBalance = "FluidBalance",
  Procedure = "Procedure"
}

export enum KnownPatientTagCodes {
  Isolated = "Aislado",
  Traqueostomy = "Traqueostomy",
  Arm = "Arm",
  Gastrostomy = "Gastrostomy",
  Oral = "Oral"
}

export enum KnownPatientTagObservationCode {
  Tag = "Tag"
}

export enum KnownOdontogramTypeCodes {
  AdultOdontogram = "AdultOdontogram",
  ChildOdontogram = "ChildOdontogram",
  MixedOdontogram = "MixedOdontogram"
}

export type KnownObservationCode =
  | KnownMeasureObservationCode
  | KnownMeasurementGroupObservationCode
  | KnownPatientTagObservationCode
  | KnownOdontogramTypeCodes;

export type MeasureObservationCode = OpenCode<KnownMeasureObservationCode>;

export type MeasurementGroupObservationCode =
  OpenCode<KnownMeasurementGroupObservationCode>;

export type PatientTagCodes = OpenCode<KnownPatientTagCodes>;

export type OdontogramTypeCodes = OpenCode<KnownOdontogramTypeCodes>;

export type ObservationCode =
  | MeasureObservationCode
  | MeasurementGroupObservationCode
  | KnownPatientTagObservationCode
  | OdontogramTypeCodes;

export enum ObservationStatus {
  Final = "final",
  Preliminary = "preliminary",
  Cancelled = "cancelled"
}

export interface Observation<
  RType extends ResourceType,
  Category extends ObservationCategory = ObservationCategory,
  OCode extends ObservationCode = ObservationCode
> extends ResourceModel<RType> {
  patient?: ModelReference<Patient>;
  encounter?: ModelReference<Encounter>;
  practitioner?: ModelReference<Practitioner>;
  type: OCode;
  lastEditDate?: ApiDate;
  creationDate?: ApiDate;
  category?: Category;
  hisCode?: string;
  status: ObservationStatus;
  originalPractitioner?: ModelReference<Practitioner>;
  observation?: string;
  closeDate?: ApiDate;
  effectiveDateTime?: ApiDate;
}
