import React, { useEffect, useRef } from "react";
import { FCC, getClassName } from "@laba/react-common";
import {
  MapPosition,
  MapObject,
  ControlPermission,
  PositionType,
  POIsCategory,
  GestureHandlingValue,
  InfoWindowObject
} from "components/maps/gmap/helpers/types";
import { getMap } from "components/maps/gmap/helpers/getters";
import { useMapComponentStyles } from "components/maps/gmap/helpers/components/MapComponent/useMapStyles";
import {
  getPositionFromLatLngPosition,
  getMapCustomStyles,
  getStaticMapConfig
} from "components/maps/gmap/helpers/utils";
import { MapType } from "components/maps/gmap/Map";

// reference: https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions.gestureHandling
export type GestureHandlingValues = "none" | "greedy" | "cooperative" | "auto";

export interface MapComponentProps {
  center: MapPosition;
  className?: string;
  zoom: number;
  controls: ControlPermission;
  onChangeCenterOrZoom?: (
    zoom?: number,
    currentLocation?: PositionType
  ) => void;
  filterPOIs?: POIsCategory[];
  gestureHandling: GestureHandlingValue;
  map?: MapObject;
  setMap: (map: MapObject) => void;
  type: MapType;
  infoWindow?: InfoWindowObject;
}

export const MapComponent: FCC<MapComponentProps> = ({
  center,
  zoom,
  controls,
  onChangeCenterOrZoom,
  filterPOIs,
  gestureHandling,
  map,
  setMap,
  children,
  className,
  type,
  infoWindow
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const classes = useMapComponentStyles({ type });
  const staticMap = type === MapType.Static || type === MapType.Disabled;

  useEffect(() => {
    if (ref.current) {
      setMap(getMap(ref.current));
    }
  }, [setMap]);

  useEffect(() => {
    if (map) {
      map.setOptions({
        center,
        zoom,
        ...controls,
        ...getStaticMapConfig(staticMap, gestureHandling),
        styles: getMapCustomStyles(filterPOIs)
      });
      const handler = map.addListener("bounds_changed", () => {
        const currentCenter = getPositionFromLatLngPosition(map.getCenter());
        const currentZoom = map.getZoom();

        if (
          currentCenter?.longitude !== center.lng ||
          currentCenter.latitude !== center.lat ||
          currentZoom !== zoom
        ) {
          onChangeCenterOrZoom?.(currentZoom, currentCenter);
        }
      });
      map.addListener("click", () => {
        if (infoWindow) {
          infoWindow.close();
        }
      });
      return () => {
        handler.remove();
      };
    }
    return () => {};
  }, [
    map,
    center,
    zoom,
    controls,
    staticMap,
    gestureHandling,
    onChangeCenterOrZoom,
    filterPOIs,
    infoWindow
  ]);
  return (
    <div className={getClassName(classes.root, className)} ref={ref}>
      {children}
    </div>
  );
};
