import {
  borderMixin,
  Color,
  createStyle,
  destyledButtonMixin,
  FlexAlignItems,
  FlexJustifyContent,
  flexRowMixin,
  getClassName,
  hoverMixin,
  paddingMixin,
  pxToRem
} from "@laba/react-common";
import { RadiusVariant, Theme, ThemeStyleColorVariant } from "model/theme";
import { StyleVariant, TextVariant } from "model/themeVariant";
import {
  getThemeRadius,
  getThemeStyleColor,
  getThemeStyleState,
  themeTextMixin
} from "model/themeUtils";

export interface HorizontalTabStyleProps {
  isActive: boolean;
  disabled: boolean;
  style: StyleVariant;
  isMobile?: boolean;
  showText?: boolean;
}

const getTabBackgroundColor = (
  theme: Theme,
  props: HorizontalTabStyleProps,
  hover: boolean
): Color => {
  if (props.disabled) {
    return getThemeStyleState(theme, props.style).disabledBackground;
  }
  if (hover && !props.isActive) {
    return getThemeStyleState(theme, props.style).hover;
  }

  return getThemeStyleColor(
    theme,
    props.style,
    ThemeStyleColorVariant.Contrast
  );
};

const getTabColor = (theme: Theme, props: HorizontalTabStyleProps): Color => {
  if (props.disabled) {
    return getThemeStyleState(theme, props.style).disabled;
  }

  if (props.isActive) {
    return getThemeStyleColor(theme, props.style, ThemeStyleColorVariant.Main);
  }

  return getThemeStyleColor(
    theme,
    props.style,
    ThemeStyleColorVariant.GrayDark
  );
};

export interface HorizontalTabStyleClassNames {
  container: string;
  icon: string;
  text: string;
}

const useTabStyleStatic = createStyle(
  (theme: Theme) => ({
    container: {
      ...destyledButtonMixin(),
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.Center,
        gap: 8
      }),
      minWidth: "min-content",
      height: pxToRem(48)
    },
    containerActive: {
      ...borderMixin({
        radius: { top: getThemeRadius(theme, RadiusVariant.Small) },
        style: "solid",
        side: { top: 0, leftRight: 0, bottom: 0 },
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.Background
        )
      })
    },
    containerInactive: {
      ...borderMixin({
        radius: { top: getThemeRadius(theme, RadiusVariant.Small) },
        style: "solid",
        side: { top: 0, leftRight: 0, bottom: 1 },
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.Background
        )
      })
    },
    cursorDefault: {
      cursor: "default"
    },
    cursorPointer: {
      cursor: "pointer"
    },
    paddingMobileWithText: {
      ...paddingMixin({
        leftRight: pxToRem(16)
      })
    },
    paddingMobileWithoutText: {
      ...paddingMixin({
        leftRight: pxToRem(12)
      })
    },
    paddingDesktop: {
      ...paddingMixin({
        leftRight: pxToRem(24)
      })
    },
    icon: {
      height: pxToRem(24),
      width: pxToRem(24)
    },
    textActive: {
      ...themeTextMixin(theme, TextVariant.Subtitle2, 1)
    },
    textInactive: {
      ...themeTextMixin(theme, TextVariant.Body2, 1)
    }
  }),
  "TabStatic"
);

const useTabStyleDynamic = createStyle(
  (theme: Theme) => ({
    container: (props: HorizontalTabStyleProps) => ({
      ...hoverMixin({
        backgroundColor: getTabBackgroundColor(theme, props, true)
      }),
      backgroundColor: getTabBackgroundColor(theme, props, false)
    }),
    icon: (props: HorizontalTabStyleProps) => ({
      color: getTabColor(theme, props)
    }),
    text: (props: HorizontalTabStyleProps) => ({
      color: getTabColor(theme, props)
    })
  }),
  "TabDynamic"
);

export const useTabStyle = (
  props: HorizontalTabStyleProps
): HorizontalTabStyleClassNames => {
  const staticClasses = useTabStyleStatic();
  const dynamicClasses = useTabStyleDynamic(props);

  const { isActive, disabled, showText, isMobile } = props;
  return {
    container: getClassName(
      staticClasses.container,
      isActive
        ? staticClasses.containerActive
        : staticClasses.containerInactive,
      isActive || disabled
        ? staticClasses.cursorDefault
        : staticClasses.cursorPointer,
      dynamicClasses.container,
      isMobile
        ? showText
          ? staticClasses.paddingMobileWithText
          : staticClasses.paddingMobileWithoutText
        : staticClasses.paddingDesktop
    ),
    icon: getClassName(staticClasses.icon, dynamicClasses.icon),
    text: getClassName(
      isActive ? staticClasses.textActive : staticClasses.textInactive,
      dynamicClasses.text
    )
  };
};
