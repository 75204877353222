import { Model } from "model/primitives/model/model";
import { getKeyObj, KeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { Building } from "./building";
import { Room } from "./room";
import { Section } from "./section";
import { Bed } from "./bed";

export type HospitalizationLocation = Bed | Section | Room | Building;

export interface LocationContainer extends Model {
  location?: ModelReference<HospitalizationLocation>;
}

export const LocationContainerKey: KeyObj<LocationContainer> = getKeyObj(
  createHydratedMock<LocationContainer>()
);
