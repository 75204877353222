import { FC, useI18n } from "@laba/react-common";
import { useAppDispatch } from "store/store";
import React, { useCallback, useState } from "react";
import { DefaultButton, Popup, TextInput } from "@laba/nexup-components";
import { Grid, Typography } from "@mui/material";
import { tkCP } from "translation/i18n";
import { onCreatePrescriptionDocument } from "store/dashboard/event";
import { isFinite, trim } from "lodash-es";

const tk = tkCP.adminPage.dashboard.createPrescriptionDocument;

export const CreatePrescriptionDocument: FC = () => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const [prescriptionIdList, setPrescriptionIdList] = useState<string>("");

  const onClick = useCallback(() => setShowPopup(true), [setShowPopup]);
  const onClose = useCallback(() => setShowPopup(false), [setShowPopup]);

  const onConfirmClicked = useCallback(async () => {
    setLoading(true);
    const idList = prescriptionIdList
      .split(",")
      .map(x => trim(x))
      .filter(x => isFinite(Number(x)));
    await dispatch(onCreatePrescriptionDocument(idList));
    setPrescriptionIdList(idList.join(", "));
    setLoading(false);
  }, [prescriptionIdList, dispatch]);

  return (
    <>
      <DefaultButton
        title={t(tk.openButton)}
        text={t(tk.openButton)}
        onClick={onClick}
      />
      {showPopup && (
        <Popup
          open={showPopup}
          closableHeader
          headerTitle={t(tk.title)}
          loading={loading}
          onClose={onClose}
          primaryButtonText={t(tk.confirmButton)}
          onPrimaryButtonClick={onConfirmClicked}
        >
          <Grid container columnSpacing={2} rowSpacing={4}>
            <Grid item>
              <Typography variant="h5">{t(tk.idList)}</Typography>
            </Grid>
            <Grid item container sm={12} rowSpacing={4}>
              <TextInput
                value={prescriptionIdList}
                onChange={setPrescriptionIdList}
                label={t(tk.idList)}
                fullWidth
              />
            </Grid>
          </Grid>
        </Popup>
      )}
    </>
  );
};
