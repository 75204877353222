import { FCC } from "@laba/react-common";
import React from "react";
import { PopupVariant } from "components/popup/BasePopup/BasePopupStyle";
import { BasePopup, BasePopupProps } from "../BasePopup/BasePopup";

export { PopupVariant };

export interface PopupProps
  extends Omit<BasePopupProps, "popupVariant" | "fullwidth"> {
  isMobile?: boolean;
  isDialog?: boolean;
}

export const Popup: FCC<PopupProps> = ({
  isMobile,
  children,
  isDialog = false,
  ...basePopupProps
}) => {
  return (
    <BasePopup
      {...basePopupProps}
      fullWidth={!isDialog && isMobile}
      popupVariant={isMobile ? PopupVariant.Vertical : PopupVariant.Horizontal}
    >
      {children}
    </BasePopup>
  );
};
