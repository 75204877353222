import { Color, FC, StyleVariant, TypeVariant } from "@laba/react-common";
import React from "react";
import { Tag } from "components/tags/Tag/Tag";
import { RadiusVariant } from "model/theme";

export interface ColorTagItem {
  text?: string;
  title?: string;
  backgroundColor?: Color;
}

export interface ColorTagProps {
  className?: string;
  tagItem?: ColorTagItem;
}

export const ColorTag: FC<ColorTagProps> = ({ tagItem, className }) => {
  return (
    <Tag
      className={className}
      backgroundColor={tagItem?.backgroundColor}
      style={StyleVariant.Primary}
      text={tagItem?.text}
      title={tagItem?.title}
      type={TypeVariant.Contained}
      radius={RadiusVariant.Medium}
      isClickable={false}
      disabled={false}
    />
  );
};
