import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { ModelId } from "model/primitives/model/model";
import { EncounterStatus } from "model/resource/entities/encounter/encounter";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { VirtualGuardEncounter } from "model/resource/entities/encounter/virtualGuardEncounter";
import { HttpMethod } from "@laba/ts-common";

export enum VirtualGuardEncounterRequestOrder {
  CreationDate = "creationDate",
  CreationDateDescent = "creationDateDescent"
}

export interface VirtualGuardEncounterQueryParams {
  withHealthcareService?: boolean;
  withPatient?: boolean;
  withHealthCarePayerProvider?: boolean;
}

export interface VirtualGuardEncounterListQueryParams
  extends ListQueryParams,
    VirtualGuardEncounterQueryParams {
  id?: ModelId[];
  content?: string;
  healthcareService?: ModelId[];
  order?: VirtualGuardEncounterRequestOrder;
  organization?: ModelId;
  status?: EncounterStatus;
}

export const virtualGuardEncounterBasePath = "/virtual-guard-encounter";

export const getVirtualGuardEncounterList = (
  params?: VirtualGuardEncounterListQueryParams
): Promise<ApiPageRequestResponse<VirtualGuardEncounter>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(virtualGuardEncounterBasePath),
    params
  });

export const getVirtualGuardEncounter = (
  id: ModelId,
  params?: VirtualGuardEncounterQueryParams
): Promise<ApiRequestResponse<VirtualGuardEncounter>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${virtualGuardEncounterBasePath}/${id}`),
    params
  });

export const createVirtualGuardEncounter = (
  data: VirtualGuardEncounter
): Promise<ApiRequestResponse<VirtualGuardEncounter>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(virtualGuardEncounterBasePath),
    data
  });

export const updateVirtualGuardEncounter = (
  data: VirtualGuardEncounter
): Promise<ApiRequestResponse<VirtualGuardEncounter>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${virtualGuardEncounterBasePath}/${data.id}`),
    data
  });
