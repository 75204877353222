import { Day } from "model/primitives/time/time";
import { dayToIsoWeekday } from "model/primitives/time/utils";
import {
  ApiHour,
  DateTime,
  dateTimeIsBetweenApiHours,
  getKeyObj
} from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";

export interface HoursOfOperation {
  allDay?: boolean;
  openingTime?: ApiHour;
  closingTime?: ApiHour;
  daysOfWeek?: Day[];
}

export const HoursOfOperationKey = getKeyObj<HoursOfOperation>(
  createHydratedMock<HoursOfOperation>()
);

export const currentHoursOfOperationIsAvailable = (
  currentDateTime: DateTime,
  time?: HoursOfOperation
): boolean => {
  if (
    time?.daysOfWeek?.some(
      day => dayToIsoWeekday[day] === currentDateTime.weekday
    ) === true
  ) {
    if (time.allDay) return true;

    return dateTimeIsBetweenApiHours(
      currentDateTime,
      time.openingTime,
      time.closingTime
    );
  }

  return false;
};
