import React from "react";
import {
  getDefaultJsonToModel,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";
import { ProcedureReportDefinition, ResourceType } from "@laba/nexup-api";
import {
  getDefaultResourceTransform,
  ResourceForm
} from "components/generic/ResourceForm/ResourceForm";
import { tkCP } from "translation/i18n";
import { FC, useI18n } from "@laba/react-common";
import { ProcedureReportDefinitionMainContent } from "components/pages/NexupAdmin/resources/procedureReportDefinition/ProcedureReportDefinitionMainContent";

export const procedureReportDefinitionJsonToModel: JsonToModel<ProcedureReportDefinition> =
  getDefaultJsonToModel(draft => {
    draft.organization = undefined;
  });

export const procedureReportDefinitionTransform =
  getDefaultResourceTransform<ProcedureReportDefinition>(
    ResourceType.ProcedureReportDefinition
  );

export const tk = tkCP.adminPage[ResourceType.ProcedureReportDefinition];

export const ProcedureReportDefinitionForm: FC = () => {
  const { t } = useI18n();

  return (
    <ResourceForm
      tabsConfig={[
        {
          content: <ProcedureReportDefinitionMainContent />,
          label: t(tk.tabs.procedureReportDefinition)
        }
      ]}
    />
  );
};
