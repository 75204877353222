import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  UserForm,
  userJsonToModel,
  userTransform
} from "components/pages/NexupAdmin/resources/user/UserForm";
import { Edit } from "components/generic/Edit/Edit";
import { UserResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { tkCP } from "translation/i18n";
import { useElementExportedFileName } from "components/hook/useExportedFileName";
import { User } from "@laba/nexup-api";

const tk = tkCP.adminPage[UserResourceType];

export const UserEdit: FC = () => {
  const { t } = useI18n();
  const exportedFileName = useElementExportedFileName<User>(() => t(tk.title));
  return (
    <Edit
      resourceTransform={userTransform}
      resource={UserResourceType}
      jsonToModel={userJsonToModel}
      exportedFileName={exportedFileName}
    >
      <UserForm />
    </Edit>
  );
};
