import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import { FC } from "@laba/react-common";

export const CollaboratorIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <circle cx="3" cy="3" r="3" transform="translate(15 8.49)" />
      <circle cx="3" cy="3" r="3" transform="translate(3 2)" />
      <circle cx="3" cy="3" r="3" transform="translate(4.786 14.053)" />
      <g fill="none" transform="translate(3 3)">
        <path d="M8,0A8.026,8.026,0,1,1,0,8.026,8.013,8.013,0,0,1,8,0Z" />
      </g>
      <g transform="translate(3 3)">
        <path d="M 8 1.999995231628418 C 4.691590309143066 1.999995231628418 2 4.703395843505859 2 8.026315689086914 C 2 11.34923553466797 4.691590309143066 14.05263519287109 8 14.05263519287109 C 11.30841064453125 14.05263519287109 14 11.34923553466797 14 8.026315689086914 C 14 4.703395843505859 11.30841064453125 1.999995231628418 8 1.999995231628418 M 8 -3.814697265625e-06 C 12.41827964782715 -3.814697265625e-06 16 3.593505859375 16 8.026315689086914 C 16 12.45912551879883 12.41827964782715 16.05263519287109 8 16.05263519287109 C 3.581720352172852 16.05263519287109 0 12.45912551879883 0 8.026315689086914 C 0 3.593505859375 3.581720352172852 -3.814697265625e-06 8 -3.814697265625e-06 Z" />
      </g>
    </SvgIcon>
  );
};
