import {
  ApiRequestResponse,
  createFailureMockApiRequestResponsePromise,
  createSuccessMockApiRequestResponsePromise,
  getWhatsappNotificationHistoryDetail,
  getWhatsappNotificationHistoryList,
  getWhatsappNotificationLastVersionDetail,
  Page,
  WhatsappNotification,
  WhatsappNotificationLastVersion,
  WhatsappNotificationListQueryParams,
  WhatsappNotificationListQueryParamsKey,
  WhatsappNotificationParamsKey
} from "@laba/nexup-api";
import { isArray, RequestFailureStatus } from "@laba/ts-common";
import { HttpError } from "react-admin";
import { head, omit } from "lodash-es";
import {
  ProviderGetMany,
  ResourceProviderTypes
} from "./utils/resourceProviderTypes";
import {
  createGetter,
  deleteGetter,
  deleteManyGetter,
  ExternalResourceType,
  getKeyTargetParam,
  getListGetter,
  getManyReferenceGetter,
  getOneGetter,
  updateGetter,
  updateManyGetter
} from "./utils/resourceProviderGetter";

const getList = (
  params: WhatsappNotificationListQueryParams
): Promise<ApiRequestResponse<Page<WhatsappNotificationLastVersion>>> => {
  const id = isArray(params.id) ? head(params.id) : params.id;
  if (!id) return createFailureMockApiRequestResponsePromise(404, "Not found");
  return getWhatsappNotificationHistoryList(
    id,
    omit(params, WhatsappNotificationParamsKey.id)
  );
};
const getOne = (
  id: string,
  params: WhatsappNotificationListQueryParams
): Promise<ApiRequestResponse<WhatsappNotificationLastVersion>> => {
  const { version } = params;
  if (!version)
    return createFailureMockApiRequestResponsePromise(404, "Not found");
  return getWhatsappNotificationHistoryDetail(
    id,
    version,
    omit(
      params,
      WhatsappNotificationParamsKey.id,
      WhatsappNotificationParamsKey.version
    )
  );
};

const update = (data: WhatsappNotification) =>
  createSuccessMockApiRequestResponsePromise(data);
const create = (data: WhatsappNotification) =>
  createSuccessMockApiRequestResponsePromise(data);
const getOrderParam = () => undefined;

const getTargetParams = getKeyTargetParam<WhatsappNotificationListQueryParams>(
  WhatsappNotificationListQueryParamsKey
);

const getManyWhatsappNotificationHistory: ProviderGetMany<
  WhatsappNotificationLastVersion
> = async params => {
  const result: WhatsappNotificationLastVersion[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const id of params.ids.map(value => value.toString())) {
    // eslint-disable-next-line no-await-in-loop
    const response = await getWhatsappNotificationLastVersionDetail(id);
    if (response.failureStatus === RequestFailureStatus.Failure) {
      throw new HttpError(response.errorMsg, response.status, response.data);
    }
    result.push(response.data);
  }
  return {
    data: result
  };
};

export const whatsappNotificationHistoryProvider: ResourceProviderTypes<
  WhatsappNotification,
  WhatsappNotificationListQueryParams
> = {
  resourceType: ExternalResourceType.WhatsappNotificationHistory,
  getList: getListGetter(getList, getOrderParam),
  getOne: getOneGetter(getOne),
  getMany: getManyWhatsappNotificationHistory,
  getManyReference: getManyReferenceGetter(
    getList,
    getOrderParam,
    getTargetParams
  ),
  update: updateGetter(update),
  updateMany: updateManyGetter(update),
  create: createGetter(create),
  delete: deleteGetter(),
  deleteMany: deleteManyGetter()
};
