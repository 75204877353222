import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { BillingContract, BillingContractStatus } from "model/resource";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { ApiDate, HttpMethod, ModelId } from "@laba/ts-common";
import { commonFileUploadRequestHandler } from "api/utils";
import { urlJoin } from "url-join-ts";

const BillingContractBasePath = "billing-contract";

export enum ProductTermSource {
  OnlyBillingContract = "OnlyBillingContract",
  AddMissing = "AddMissing"
}

export interface BillingContractExportQueryParams {
  id: ModelId;
  productTermSource: ProductTermSource;
}

export interface BillingContractQueryParams {
  withAccount?: boolean;
  withBillingContract?: boolean;
  withLocation?: boolean;
  withProduct?: boolean;
  withPayer?: boolean;
  withMedication?: boolean;
  withMedicalRequestDefinition?: boolean;
  withDevice?: boolean;
}

export interface BillingContractListQueryParams
  extends BillingContractQueryParams,
    ListQueryParams {
  account?: ModelId[];
  content?: string;
  endDate?: ApiDate;
  organization?: ModelId[];
  startDate?: ApiDate;
  endDateLessThan?: ApiDate;
  endDateGreaterThan?: ApiDate;
  status?: BillingContractStatus[];
  isMacro?: boolean;
  exclude?: ModelId[];
}

export const getBillingContractList = (
  params: BillingContractListQueryParams
): Promise<ApiPageRequestResponse<BillingContract>> => {
  return request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(BillingContractBasePath),
    params
  });
};

export const createBillingContract = (
  data: BillingContract
): Promise<ApiRequestResponse<BillingContract>> => {
  return request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(BillingContractBasePath),
    data
  });
};

export const editBillingContract = (
  data: BillingContract
): Promise<ApiRequestResponse<BillingContract>> => {
  return request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${BillingContractBasePath}/${data.id}`),
    data
  });
};

export const cancelBillingContract = (
  billingContractId: ModelId
): Promise<ApiRequestResponse<BillingContract>> => {
  return request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(
      `${BillingContractBasePath}/${billingContractId}/cancel`
    )
  });
};

export const getBillingContract = (
  id: ModelId,
  params?: BillingContractQueryParams
): Promise<ApiRequestResponse<BillingContract>> => {
  return request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${BillingContractBasePath}/${id}`),
    params
  });
};

export const importBillingContract = (
  billingContractId: ModelId,
  organization: ModelId,
  file: File
): Promise<ApiRequestResponse<BillingContract>> => {
  return commonFileUploadRequestHandler<BillingContract>(
    urlJoin(BillingContractBasePath, billingContractId),
    organization,
    file
  );
};

export const exportBillingContract = (
  params?: BillingContractExportQueryParams
): Promise<ApiRequestResponse<Blob>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(`${BillingContractBasePath}/${params?.id}/export`),
    data: {
      productTermSource: params?.productTermSource
    },
    responseType: "blob"
  });
