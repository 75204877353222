import { FCC } from "components/types";
import React, { useMemo } from "react";
import {
  DeviceType,
  DeviceTypeContext,
  useWindowIsNarrowerOrEqualThan
} from "./utils";

interface Props {
  context: DeviceTypeContext;
  mobileMaxSizeBreakpoint: number;
}

export const DeviceTypeProvider: FCC<Props> = ({
  children,
  context,
  mobileMaxSizeBreakpoint
}) => {
  const narrowerOrEqualThanBreakpoint = useWindowIsNarrowerOrEqualThan(
    mobileMaxSizeBreakpoint
  );
  const value = useMemo(
    () => ({
      deviceType: narrowerOrEqualThanBreakpoint
        ? DeviceType.Mobile
        : DeviceType.Desktop
    }),
    [narrowerOrEqualThanBreakpoint]
  );
  return <context.Provider value={value}>{children}</context.Provider>;
};
