import { tkCP } from "translation/i18n";
import { FC, TypeVariant, useField, useI18n } from "@laba/react-common";
import { Grid, Typography } from "@mui/material";
import React, { useCallback } from "react";
import {
  AutocompleteOptionConfig,
  ModelReferenceAutocomplete,
  OptionsConfig,
  SearchIcon,
  SelectInput,
  UploadArrow
} from "@laba/nexup-components";
import { useDataProvider } from "react-admin";
import {
  AlfabetaRequestBodyParamsKey,
  getModelReferenceId,
  Organization,
  OrganizationListQueryParamsKey,
  OrganizationType,
  PriceMode,
  ResourceType
} from "@laba/nexup-api";
import { UploadFileButton } from "components/generic/UploadFileButton/UploadFileButton";
import { head, isEmpty } from "lodash-es";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";

const tk = tkCP.adminPage.dashboard.alfabetaSync;
const getOrganizationOptionFromValue = (
  organization: Organization
): AutocompleteOptionConfig<Organization> => ({
  text: organization.name,
  value: organization,
  title: organization.name
});
const minInputForSearch = 3;

const priceModeOptions: OptionsConfig<PriceMode>[] = [
  {
    value: PriceMode.PerUnit,
    itemId: PriceMode.PerUnit,
    text: "Precio por unidad"
  },
  {
    value: PriceMode.PerProduct,
    itemId: PriceMode.PerProduct,
    text: "Precio por caja/paquete"
  }
];

export const AlfabetaProductSyncInputFormContent: FC = () => {
  const { t } = useI18n();
  const {
    input: {
      value: organizationValue,
      onChange: organizationOnChange,
      onBlur: organizationOnBlur
    },
    meta: { error: organizationError, touched: organizationTouched }
  } = useField<string, string>(AlfabetaRequestBodyParamsKey.organizationId);
  const {
    input: {
      value: priceModeValue,
      onChange: priceModeOnChange,
      onBlur: priceModeOnBlur
    },
    meta: { error: priceModeError, touched: priceModeTouched }
  } = useField<string, string>(AlfabetaRequestBodyParamsKey.priceMode);
  const {
    input: { value: codeFileValue, onChange: codeFileOnChange },
    meta: { error: codeFileError, touched: codeFileTouched }
  } = useField<File, string>(AlfabetaRequestBodyParamsKey.codeFile);

  const dataProvider = useDataProvider();

  const onChangeOrganizationHandler = useCallback(
    (value?: Organization) => {
      organizationOnChange(getModelReferenceId(value));
    },
    [organizationOnChange]
  );
  const getOrganizationValueHandler = useCallback(
    async (valueId: string) => {
      const result = await dataProvider.getOne(ResourceType.Organization, {
        id: valueId
      });
      return result.data;
    },
    [dataProvider]
  );
  const getOrganizationOptions = useCallback(
    async (searchText?: string) => {
      const result = await dataProvider.getList(ResourceType.Organization, {
        filter: {
          [OrganizationListQueryParamsKey.type]: OrganizationType.Provider,
          [OrganizationListQueryParamsKey.content]: searchText
        },
        pagination: {
          page: 1,
          perPage: 30
        },
        sort: {
          field: "",
          order: ""
        }
      });
      return result.data;
    },
    [dataProvider]
  );
  const onChangeCodeFileHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      codeFileOnChange(head(e.target.files));
    },
    [codeFileOnChange]
  );
  const uploadCodeFileButtonText =
    !isEmpty(codeFileError) && codeFileTouched
      ? t(tk.fileInputWithError, {
          error: codeFileError
        })
      : codeFileValue
      ? t(tk.fileInputWithFile, {
          fileName: codeFileValue.name
        })
      : t(tk.fileInputEmpty);

  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);

  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      <Grid item sm={12}>
        <Typography title={t(tk.organization)}>{t(tk.organization)}</Typography>
        <ModelReferenceAutocomplete<Organization>
          valueReference={organizationValue}
          onChange={onChangeOrganizationHandler}
          onBlur={organizationOnBlur}
          errorText={organizationError}
          showError={organizationTouched ?? true}
          getValue={getOrganizationValueHandler}
          getValues={getOrganizationOptions}
          getOptionFromValue={getOrganizationOptionFromValue}
          compareValues={(v1, v2) => v1.id === v2.id}
          fullWidth
          clearText={t(tk.clearText)}
          closeText={t(tk.closeText)}
          loadingText={t(tk.loadingText)}
          noOptionsText={t(tk.noOptionsText)}
          openText={t(tk.openText)}
          EndIcon={SearchIcon}
          endIconShouldNotRotate
          variant={TypeVariant.Outlined}
          minInputForSearch={minInputForSearch}
          minCharPromptText={t(tk.minCharPromptText, {
            minInputForSearch
          })}
          clearable
          disabled={workspaceOrganizationId !== undefined}
        />
      </Grid>
      <Grid item sm={12}>
        <Typography title={t(tk.priceMode)}>{t(tk.priceMode)}</Typography>
        <SelectInput
          value={priceModeValue}
          onChange={priceModeOnChange}
          onBlur={priceModeOnBlur}
          errorText={priceModeError}
          showError={priceModeTouched ?? true}
          placeholder={t(tk.priceMode)}
          options={priceModeOptions}
          fullWidth
        />
      </Grid>
      <Grid item sm={12}>
        <Typography title={t(tk.codefile)}>{t(tk.codefile)}</Typography>
        <UploadFileButton
          label={uploadCodeFileButtonText}
          fileType=""
          onFileAdded={onChangeCodeFileHandler}
          Icon={UploadArrow}
        />
      </Grid>
    </Grid>
  );
};
