import { FileSize30MB } from "@laba/ts-common";

export enum AttachmentLoadingState {
  LOADING = "loading",
  UPLOADED = "uploaded",
  FAILED = "failed"
}

export interface AttachmentConfig {
  filename?: string;
  url?: string;
  state?: AttachmentLoadingState;
  error?: string;
}

export interface AttachmentError {
  filename?: string;
  error?: string;
}

export const DefaultMaxFileSize = FileSize30MB; // 30 mb in bytes

export const mapLoadingFilesToAttachmentConfigOption = (
  files: File[],
  loadingState: AttachmentLoadingState = AttachmentLoadingState.LOADING
): AttachmentConfig[] => {
  return files.map(f => {
    return {
      filename: f.name,
      state: loadingState
    };
  });
};
