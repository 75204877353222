import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  MedicationSubstanceKey,
  MedicationSubstanceListQueryParamsKey,
  OrganizationCombinedType,
  OrganizationKey,
  OrganizationListQueryParamsKey,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { TextField } from "components/generic/TextField/TextField";
import { ReferenceResourceField } from "components/generic/ReferenceResourceField/ReferenceResourceField";
import { List } from "components/generic/List/List";
import { Datagrid } from "components/generic/Datagrid/Datagrid";
import { medicationSubstanceJsonToModel } from "components/pages/NexupAdmin/resources/medicationSubstance/MedicationSubstanceForm";
import { TextInput } from "components/generic/TextInput/TextInput";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useListExportedFileName } from "components/hook/useExportedFileName";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

const tk = tkCP.adminPage[ResourceType.MedicationSubstance];

export const MedicationSubstanceList: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const exportedFileName = useListExportedFileName(t(tk.title));

  return (
    <List
      resource={ResourceType.MedicationSubstance}
      exportedFileName={exportedFileName}
      jsonToModel={medicationSubstanceJsonToModel}
      extraFilters={[
        workspaceOrganizationId === undefined ? (
          <OrganizationReferenceInput
            label={t(tk.filters.organization)}
            source={MedicationSubstanceListQueryParamsKey.organization}
            alwaysOn
            type={OrganizationCombinedType.ProviderAndGroup}
            extraFilters={{
              [OrganizationListQueryParamsKey.active]: true
            }}
          />
        ) : undefined,
        <TextInput
          source={MedicationSubstanceListQueryParamsKey.code}
          label={t(tk.filters.code)}
          alwaysOn
          resettable
        />
      ]}
    >
      <Datagrid>
        <TextField source={MedicationSubstanceKey.id} label={t(tk.fields.id)} />
        <TextField
          source={MedicationSubstanceKey.name}
          label={t(tk.fields.name)}
        />
        <ReferenceResourceField
          resourceType={ResourceType.Organization}
          label={t(tk.fields.organization)}
          source={MedicationSubstanceKey.organization}
          optionTextKey={OrganizationKey.name}
        />
        <TextField
          source={MedicationSubstanceKey.code}
          label={t(tk.fields.code)}
        />
      </Datagrid>
    </List>
  );
};
