import { notUndefined, Optional } from "@laba/ts-common";
import { flatMap } from "lodash-es";
import { Quantity } from "model/primitives/quantity";
import {
  KnownOdontogramTypeCodes,
  ObservationCategory,
  ObservationStatus
} from "model/resource/medical/observation/observation";
import { PatientMeasure } from "model/resource/medical/observation/patientMeasure";
import { PatientContainerWithExtraData } from "model/resource/person/patient/patientContainerWithExtraData";
import { ProcedureReport } from "model/resource/procedures/procedureReport/procedureReport";
import {
  AllergyListResponsePropertyValue,
  Odontogram,
  QuestionnaireFieldValue,
  QuestionnaireFieldValueProperty,
  QuestionnaireFieldValuePropertyType,
  QuestionnaireResponseCard,
  QuestionnaireResponseField,
  QuestionnaireResponseWithExtraData,
  SearchableListField
} from "model/resource";
import { ResourceType } from "model/primitives/resourceModel";

export const createBaseAllergyFieldResponseField = (
  patientContainer: PatientContainerWithExtraData,
  linkId: string
): QuestionnaireResponseField => ({
  linkId,
  answer:
    patientContainer.allergyIntoleranceList?.filter(notUndefined).map(
      (allergy): QuestionnaireFieldValue => ({
        valueReferenceAllergy: allergy,
        property: [
          {
            type: QuestionnaireFieldValuePropertyType.AllergyType,
            valueString: AllergyListResponsePropertyValue.AllergyRelated
          }
        ]
      })
    ) || []
});

export const generateEmptyPatientMeasure = (type: string): PatientMeasure => ({
  category: ObservationCategory.Measure,
  resourceType: ResourceType.PatientMeasure,
  type,
  status: ObservationStatus.Preliminary,
  value: undefined
});

export const createBaseMeasurementFieldResponseField = (
  linkId: string,
  type: string
): QuestionnaireResponseField => ({
  linkId,
  answer: [
    { valueReferenceMeasure: generateEmptyPatientMeasure(type), property: [] }
  ]
});

export const createBaseProcedureReportFieldResponseField = (
  linkId: string,
  selectedProcedureReport?: ProcedureReport
): QuestionnaireResponseField => ({
  linkId,
  answer: [
    { valueReferenceProcedureReport: selectedProcedureReport, property: [] }
  ]
});

export const createBaseSearchableListFieldResponseField = (
  field: SearchableListField
): QuestionnaireResponseField => ({
  linkId: field.linkId,
  answer: field.initialValue.map(v => ({
    valueCoding: v.valueCoding,
    property: v.property
  }))
});

const getProperty = (
  props: QuestionnaireFieldValueProperty[],
  propType: QuestionnaireFieldValuePropertyType
) => props.find(_prop => _prop.type === propType);

export const getPropertyValue = (
  props: QuestionnaireFieldValueProperty[],
  propType: QuestionnaireFieldValuePropertyType
): Optional<string> => {
  const prop = getProperty(props, propType);
  switch (prop?.type) {
    case QuestionnaireFieldValuePropertyType.AllergyType:
      return prop.valueString;
    case QuestionnaireFieldValuePropertyType.ProblemType:
      return prop.valueString;
    case QuestionnaireFieldValuePropertyType.Text:
      return prop.valueString;
    default:
      return undefined;
  }
};

export const getPropertyValueQuantity = (
  props: QuestionnaireFieldValueProperty[],
  propType: QuestionnaireFieldValuePropertyType
): Optional<Quantity> => {
  const prop = getProperty(props, propType);
  switch (prop?.type) {
    case QuestionnaireFieldValuePropertyType.Quantity:
      return prop.valueQuantity;
    default:
      return undefined;
  }
};

export const getQuestionnaireResponseFieldByLinkId = (
  fieldLinkId: string,
  questionnaireResponse: QuestionnaireResponseWithExtraData
): Optional<QuestionnaireResponseField> => {
  const fieldList: QuestionnaireResponseField[] = flatMap(
    questionnaireResponse.questionnaireResponse.cards,
    (card: QuestionnaireResponseCard) => card.fields
  );
  return fieldList.find(field => field.linkId === fieldLinkId);
};

export const createBaseOdontogram = (): Odontogram => {
  return {
    resourceType: ResourceType.Odontogram,
    type: KnownOdontogramTypeCodes.AdultOdontogram,
    status: ObservationStatus.Preliminary
  };
};
export const createBaseOdontogramFieldResponseField = (
  fieldLinkId: string
): QuestionnaireResponseField => {
  return {
    linkId: fieldLinkId,
    answer: [{ valueReferenceOdontogram: createBaseOdontogram(), property: [] }]
  };
};
