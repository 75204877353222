import { getThemeStyleState } from "model/themeUtils";
import { Theme } from "model/theme";
import { createStyle } from "@laba/react-common";
import { StyleVariant } from "model/themeVariant";

export const useRadioStyle = createStyle(
  (theme: Theme) => ({
    root: {
      disabled: {
        color: getThemeStyleState(theme, StyleVariant.Primary).disabled
      }
    }
  }),
  "Radio"
);
