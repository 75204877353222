import { useState, useMemo, useEffect } from "react";
import {
  defaultLocationOptions,
  errorMapper,
  ErrorPosition,
  PositionResult,
  onGetCurrentPosition
} from "hooks/location/helpers";
import { Optional, Position } from "@laba/ts-common";

export const useCurrentDevicePosition = (
  options?: PositionOptions
): PositionResult => {
  const [currentPosition, setCurrentPosition] = useState<Optional<Position>>();
  const [error, setError] = useState<Optional<ErrorPosition>>();

  const positionOptions = useMemo(
    () => ({
      ...defaultLocationOptions,
      ...options
    }),
    [options]
  );

  const locationGetter = () => {
    onGetCurrentPosition(
      position => {
        if (
          currentPosition?.longitude !== position.coords.longitude ||
          currentPosition.latitude !== position.coords.latitude
        )
          setCurrentPosition({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
      },
      positionError => {
        setCurrentPosition(undefined);
        setError(errorMapper(positionError));
      },
      positionOptions
    );
  };

  useEffect(() => {
    locationGetter();
  });

  return { currentPosition, error };
};
