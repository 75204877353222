/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import {
  getClassName,
  ReactElement,
  ReactElementOrNull
} from "@laba/react-common";
import { notNull } from "@laba/ts-common";
import { isEmpty, size, times } from "lodash-es";
import { useInfiniteListStyle } from "./InfiniteListStyle";

export interface InfiniteListProps<T> {
  children: (item: T, index: number) => ReactElementOrNull | false;
  items: T[];
  loadMore: () => Promise<void> | void;
  totalSize?: number;
  maxLoaderItems?: number;
  LoaderComponent?: ReactElement;
  className?: string;
  gap?: number;
  hasNoResult?: boolean;
  NoResultsPlaceholder?: ReactElement;
  hasErrorLoadingList?: boolean;
  errorPlaceholder?: ReactElement;
}

export const InfiniteList = <T,>({
  className,
  children,
  items,
  totalSize,
  maxLoaderItems = 5,
  LoaderComponent,
  loadMore,
  hasNoResult,
  NoResultsPlaceholder,
  gap = 0,
  hasErrorLoadingList,
  errorPlaceholder
}: InfiniteListProps<T>): ReactElementOrNull => {
  const currentListSize = size(items);

  const hasMore = totalSize !== undefined && currentListSize < totalSize;
  const placeholderItemsSize =
    hasMore || totalSize === undefined ? maxLoaderItems : 0;
  const classes = useInfiniteListStyle({ gap });
  const [showingLastElement, setShowingLastElement] = useState(false);
  useEffect(() => {
    const downloadMore = async () => {
      if (hasMore && showingLastElement && currentListSize !== 0) {
        await loadMore();
      }
    };
    downloadMore();
  }, [loadMore, hasMore, showingLastElement, currentListSize]);
  const hasOnlyNullItems =
    totalSize !== undefined && isEmpty(items.filter(notNull));

  return (
    <>
      {(hasNoResult || hasOnlyNullItems) && NoResultsPlaceholder}
      {hasErrorLoadingList && errorPlaceholder}
      {!hasNoResult && !hasErrorLoadingList && (
        <ul className={getClassName(classes.list, className)}>
          {items.map((item, index) => {
            const childrenNode = children(item, index);
            if (!childrenNode) return null;
            return <li key={index}>{childrenNode}</li>;
          })}
          {hasMore ? (
            <VisibilitySensor
              active
              partialVisibility
              onChange={setShowingLastElement}
            >
              {LoaderComponent ? (
                <>
                  {times(placeholderItemsSize).map(index => (
                    <li key={index}>{LoaderComponent}</li>
                  ))}
                </>
              ) : (
                <div>&nbsp;</div>
              )}
            </VisibilitySensor>
          ) : (
            times(placeholderItemsSize).map(index => (
              <li key={index}>{LoaderComponent}</li>
            ))
          )}
        </ul>
      )}
    </>
  );
};
