import {
  createStyle,
  FlexAlignItems,
  flexRowMixin,
  pxToRem,
  StyleVariant,
  TextVariant,
  Theme
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { ThemeStyleColorVariant } from "model/theme";

export const useDefaultCustomOptionWithIconPropsStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexRowMixin({ alignItems: FlexAlignItems.Center, gap: 4 }),
      maxHeight: pxToRem(48)
    },
    addIcon: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Main
      )
    },
    text: {
      ...themeTextMixin(theme, TextVariant.Subtitle2, 1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Main
      )
    }
  }),
  "OptionalCustomOption"
);
