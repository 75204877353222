import { MedicationStatus } from "model/resource/prescription/medication/medication";
import {
  FrequencyUnit,
  Meals,
  RequestFrequencyType
} from "model/resource/prescription/medicalRequest/medicalRequestFrequency";
import {
  MedicalAdministrationStatus,
  MedicalAdministrationStatusReason
} from "model/resource/prescription/medicalRequest/medicalAdministration";
import { ResourceType } from "model/primitives/resourceModel";
import { Gender, ValidationStatus } from "model/resource/person/person";
import { MedicalRequestWithExtraData } from "model/resource/prescription/medicalRequest/medicalRequestWithExtraData";
import { MedicalRequestStatus } from "model/resource/prescription/medicationRequest/medicationRequest";

export const medicalRequestWithExtraData: MedicalRequestWithExtraData = {
  medicalRequest: {
    id: "352",
    resourceType: ResourceType.MedicalRequest,
    creationDate: "2021-10-13T10:05:06.651-03",
    versionId: "1",
    patient: "27",
    encounter: "32",
    statusReason: MedicalRequestStatus.Active,
    lastRenewDate: "2021-10-13T10:05:06.000-03",
    expirationDate: "2021-10-13T10:05:30.000-03",
    mainText: "<p>Reposición de electrolitos: 100.0 ml por vía oral</p>",
    supplementaryText: "<p>A 100.0 ml (Gotero). Primera observacion </p>",
    reportText:
      "<p>Reposición de electrolitos: 100.0 ml por vía oral A 100.0 ml (Gotero). Primera observacion </p>",
    requestData: {
      dose: {
        quantity: 100,
        unit: "ml"
      },
      rate: {
        device: "Gotero",
        quantity: 100,
        unit: "ml"
      },
      dilution: {},
      medicationStructure: [],
      route: "oral",
      observation: "Primera observacion ",
      frequency: {
        startDate: "2021-11-12",
        endDate: "2021-11-30",
        type: RequestFrequencyType.FixedHours,
        timeList: ["08:00:00", "16:00:00"],
        meals: [],
        extraDoseNow: true,
        weekDayList: []
      },
      extraData: []
    },
    medicalDevice: [],
    definition: "70",
    practitioner: "7",
    originalPractitioner: "7"
  },
  medicalAdministrationList: [
    {
      resourceType: ResourceType.MedicalAdministration,
      id: "355",
      creationDate: "2021-10-13T10:14:36.945-03",
      patient: "27",
      hospitalization: "32",
      practitioner: "7",
      editPractitioner: "7",
      administrationStartDate: "2021-11-13T10:00:00.000-03",
      administrationEndDate: "2021-11-13T11:00:00.000-03",
      lastEditDate: "2021-10-13T10:14:36.000-03",
      status: MedicalAdministrationStatus.InProgress,
      statusReason: MedicalAdministrationStatusReason.Applied,
      medication: "56",
      medicalRequest: "352",
      medicalRequestSummary: {
        versionId: "1",
        mainText: "<p>Reposición de electrolitos: 100.0 ml por vía oral</p>",
        supplementaryText: "<p>A 100.0 ml (Gotero). Primera observacion </p>",
        reportText:
          "<p>Reposición de electrolitos: 100.0 ml por vía oral A 100.0 ml (Gotero). Primera observacion </p>",
        frequency: {
          startDate: "2021-11-12",
          endDate: "2021-11-30",
          type: RequestFrequencyType.FixedHours,
          timeList: ["08:00:00", "16:00:00"],
          meals: [],
          extraDoseNow: true,
          weekDayList: []
        }
      },
      observation: "Obs. n1",
      deviceList: [
        {
          device: "58",
          quantity: {
            quantity: 100,
            unit: "ml"
          }
        },
        {
          device: "58",
          quantity: {
            quantity: 250,
            unit: "ml"
          }
        }
      ],
      dose: {
        quantity: 220,
        unit: "g"
      },
      doseCondition: "Cond. n1"
    },
    {
      resourceType: ResourceType.MedicalAdministration,
      id: "353",
      creationDate: "2021-10-13T10:14:19.613-03",
      patient: "27",
      hospitalization: "32",
      practitioner: "7",
      editPractitioner: "7",
      administrationStartDate: "2021-11-13T18:00:00.000-03",
      administrationEndDate: "2021-11-13T19:00:00.000-03",
      lastEditDate: "2021-10-13T10:14:19.000-03",
      status: MedicalAdministrationStatus.InProgress,
      statusReason: MedicalAdministrationStatusReason.Discarded,
      medication: "56",
      medicalRequest: "352",
      medicalRequestSummary: {
        versionId: "1",
        mainText: "<p>Reposición de electrolitos: 100.0 ml por vía oral</p>",
        supplementaryText: "<p>A 100.0 ml (Gotero). Primera observacion </p>",
        reportText:
          "<p>Reposición de electrolitos: 100.0 ml por vía oral A 100.0 ml (Gotero). Primera observacion </p>",
        frequency: {
          startDate: "2021-11-12",
          endDate: "2021-11-30",
          type: RequestFrequencyType.FixedHours,
          timeList: ["08:00:00", "16:00:00"],
          meals: [],
          extraDoseNow: true,
          weekDayList: []
        }
      },
      observation: "Obs. n1",
      deviceList: [
        {
          device: "58",
          quantity: {
            quantity: 100,
            unit: "ml"
          }
        },
        {
          device: "58",
          quantity: {
            quantity: 250,
            unit: "ml"
          }
        }
      ],
      dose: {
        quantity: 220,
        unit: "g"
      },
      doseCondition: "Cond. n1"
    }
  ]
};

export const medicalRequestWithExtraDataWithMeals: MedicalRequestWithExtraData =
  {
    medicalRequest: {
      resourceType: ResourceType.MedicalRequest,
      id: "288",
      patient: "63",
      practitioner: {
        resourceType: ResourceType.Practitioner,
        id: "32",
        federationId: "e61c0075-a750-48f0-93a7-a3319b5a0233",
        personalData: {
          firstName: "ivan",
          otherName: "",
          lastName: "di vito",
          gender: Gender.Unknown,
          identifierList: []
        },
        contactData: {
          phoneList: [],
          emailList: [],
          addressList: []
        },
        validationStatus: ValidationStatus.Temporal,
        organization: ["24"],
        creationDate: "2021-10-12T18:39:35.195Z"
      },
      originalPractitioner: {
        resourceType: ResourceType.Practitioner,
        id: "32",
        federationId: "e61c0075-a750-48f0-93a7-a3319b5a0233",
        personalData: {
          firstName: "ivan",
          otherName: "",
          lastName: "di vito",
          gender: Gender.Unknown,
          identifierList: []
        },
        contactData: {
          phoneList: [],
          emailList: [],
          addressList: []
        },
        validationStatus: ValidationStatus.Temporal,
        organization: ["24"],
        creationDate: "2021-10-12T18:39:35.195Z"
      },
      encounter: "68",
      statusReason: MedicalRequestStatus.Active,
      expirationDate: "2021-10-22T17:25:17.000Z",
      lastRenewDate: "2021-10-22T17:24:53.000Z",
      mainText:
        "<p>Medicación 3 (Comprimido multidroga) comprimido 0.5 comprimido por vía oral</p>",
      supplementaryText: "<p></p>",
      reportText:
        "<p>Medicación 3 (Comprimido multidroga) comprimido 0.5 comprimido por vía oral </p>",
      requestData: {
        mainMedication: {
          resourceType: ResourceType.Medication,
          id: "85",
          name: "Medicación 3 (Comprimido multidroga)",
          alternativeName: [],
          description: "Medicación 3 (Comprimido multidroga) comprimido",
          status: MedicationStatus.Active,
          form: "comprimido",
          presentationUnit: "comprimido",
          presentationUnitFractions: 0.25,
          healthcareUnits: [
            {
              value: 1,
              unit: "comprimido"
            }
          ],
          ingredient: [],
          routeList: ["oral", "gastrostomia", "rectal"],
          organization: "24",
          functionCode: "ABC1234",
          creationDate: "2021-10-12T18:40:31.310Z",
          presentationList: []
        },
        dose: {
          quantity: 0.5,
          unit: "comprimido"
        },
        rate: {},
        dilution: {},
        medicationStructure: [],
        route: "oral",
        frequency: {
          startDate: "2021-10-22",
          endDate: "2021-10-29",
          type: RequestFrequencyType.WithMeals,
          timeList: [],
          meals: [Meals.BreakFast, Meals.Lunch, Meals.Snack, Meals.Dinner],
          extraDoseNow: false,
          weekDayList: []
        },
        providedByPatient: false,
        extraData: []
      },
      medicalDevice: [],
      definition: "101",
      creationDate: "2021-10-22T17:24:56.244Z"
    },
    medicalAdministrationList: [
      {
        resourceType: ResourceType.MedicalAdministration,
        id: "618",
        creationDate: "2021-10-22T15:08:20.837-03",
        patient: "63",
        hospitalization: "68",
        practitioner: "7",
        editPractitioner: "7",
        administrationStartDate: "2021-11-13T15:18:44.000-03",
        administrationEndDate: "2021-11-18T15:18:44.000-03",
        lastEditDate: "2021-10-22T15:08:20.000-03",
        status: MedicalAdministrationStatus.InProgress,
        statusReason: MedicalAdministrationStatusReason.Applied,
        medicalRequest: "617",
        medicalRequestSummary: {
          versionId: "1",
          mainText:
            "<p>Medicación 3 (Comprimido multidroga) comprimido 0.5 comprimido por vía oral</p>",
          supplementaryText: "<p></p>",
          reportText:
            "<p>Medicación 3 (Comprimido multidroga) comprimido 0.5 comprimido por vía oral </p>",
          frequency: {
            startDate: "2021-10-22",
            endDate: "2021-10-29",
            type: RequestFrequencyType.WithMeals,
            timeList: [],
            meals: [Meals.BreakFast, Meals.Lunch, Meals.Snack, Meals.Dinner],
            extraDoseNow: false,
            weekDayList: []
          }
        },
        observation: "Obs. n1",
        deviceList: [],
        dose: {
          quantity: 220.0,
          unit: "g"
        },
        doseCondition: "Cond. n1"
      }
    ]
  };

export const medicalRequestWithExtraDataTimeInterval: MedicalRequestWithExtraData =
  {
    medicalRequest: {
      resourceType: ResourceType.MedicalRequest,
      id: "289",
      patient: "63",
      practitioner: {
        resourceType: ResourceType.Practitioner,
        id: "32",
        federationId: "e61c0075-a750-48f0-93a7-a3319b5a0233",
        personalData: {
          firstName: "ivan",
          otherName: "",
          lastName: "di vito",
          gender: Gender.Unknown,
          identifierList: []
        },
        contactData: {
          phoneList: [],
          emailList: [],
          addressList: []
        },
        validationStatus: ValidationStatus.Temporal,
        organization: ["24"],
        creationDate: "2021-10-12T18:39:35.195Z"
      },
      originalPractitioner: {
        resourceType: ResourceType.Practitioner,
        id: "32",
        federationId: "e61c0075-a750-48f0-93a7-a3319b5a0233",
        personalData: {
          firstName: "ivan",
          otherName: "",
          lastName: "di vito",
          gender: Gender.Unknown,
          identifierList: []
        },
        contactData: {
          phoneList: [],
          emailList: [],
          addressList: []
        },
        validationStatus: ValidationStatus.Temporal,
        organization: ["24"],
        creationDate: "2021-10-12T18:39:35.195Z"
      },
      encounter: "68",
      statusReason: MedicalRequestStatus.Active,
      expirationDate: "2021-10-22T17:28:47.000Z",
      lastRenewDate: "2021-10-22T17:28:23.000Z",
      mainText:
        "<p>Medicación 12 (Curación de escaras) comprimido 1 comprimido por vía oral</p>",
      supplementaryText: "<p></p>",
      reportText:
        "<p>Medicación 12 (Curación de escaras) comprimido 1 comprimido por vía oral </p>",
      requestData: {
        mainMedication: {
          resourceType: ResourceType.Medication,
          id: "94",
          name: "Medicación 12 (Curación de escaras)",
          alternativeName: [],
          description: "Medicación 12 (Curación de escaras) comprimido",
          status: MedicationStatus.Active,
          form: "comprimido",
          presentationUnit: "comprimido",
          presentationUnitFractions: 0.25,
          healthcareUnits: [
            {
              value: 1.0,
              unit: "comprimido"
            },
            {
              value: 400.0,
              unit: "mg"
            }
          ],
          ingredient: [],
          routeList: ["oral", "gastrostomia", "endovenosa"],
          characteristic: ["skinLesionUsage"],
          organization: "24",
          functionCode: "ABC1234",
          creationDate: "2021-10-12T18:40:40.281Z",
          presentationList: []
        },
        dose: {
          quantity: 1,
          unit: "comprimido"
        },
        rate: {},
        dilution: {},
        medicationStructure: [],
        route: "oral",
        frequency: {
          startDate: "2021-10-22",
          endDate: "2021-11-22",
          type: RequestFrequencyType.TimeInterval,
          timeList: ["09:00:00"],
          period: 4,
          periodUnit: FrequencyUnit.Day,
          meals: [],
          extraDoseNow: false,
          weekDayList: []
        },
        providedByPatient: false,
        extraData: []
      },
      medicalDevice: [],
      definition: "101",
      creationDate: "2021-10-22T17:28:24.415Z"
    },
    medicalAdministrationList: [
      {
        resourceType: ResourceType.MedicalAdministration,
        id: "616",
        creationDate: "2021-10-22T15:03:42.843-03",
        patient: "63",
        hospitalization: "68",
        practitioner: "7",
        editPractitioner: "7",
        administrationStartDate: "2021-11-13T15:18:44.000-03",
        administrationEndDate: "2021-11-18T15:18:44.000-03",
        lastEditDate: "2021-10-22T15:03:42.000-03",
        status: MedicalAdministrationStatus.InProgress,
        statusReason: MedicalAdministrationStatusReason.Applied,
        medicalRequest: "615",
        medicalRequestSummary: {
          versionId: "1",
          mainText:
            "<p>Medicación 12 (Curación de escaras) comprimido 1 comprimido por vía oral</p>",
          supplementaryText: "<p></p>",
          reportText:
            "<p>Medicación 12 (Curación de escaras) comprimido 1 comprimido por vía oral </p>",
          frequency: {
            startDate: "2021-10-22",
            endDate: "2021-11-22",
            type: RequestFrequencyType.TimeInterval,
            timeList: ["09:00:00"],
            period: 4,
            periodUnit: FrequencyUnit.Day,
            meals: [],
            extraDoseNow: false,
            weekDayList: []
          }
        },
        observation: "Obs. n1",
        deviceList: [],
        dose: {
          quantity: 220.0,
          unit: "g"
        },
        doseCondition: "Cond. n1"
      }
    ]
  };
