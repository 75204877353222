import { FC, getClassName, ThemeStyleColorVariant } from "@laba/react-common";
import { Divider as MaterialUiDivider } from "@material-ui/core";
import React from "react";
import {
  useDividerStyle,
  DividerOrientation,
  DividerSize
} from "./useDividerStyles";

export { DividerSize, DividerOrientation };

export enum DividerVariant {
  Inset = "inset",
  Middle = "middle",
  FullWidth = "fullWidth"
}

export interface DividerProps {
  orientation?: DividerOrientation;
  variant?: DividerVariant;
  size?: DividerSize;
  className?: string;
  colorVariant?: ThemeStyleColorVariant;
  dashed?: boolean;
}

export const Divider: FC<DividerProps> = ({
  orientation = DividerOrientation.Horizontal,
  variant = DividerVariant.FullWidth,
  size = DividerSize.Small,
  className,
  colorVariant = ThemeStyleColorVariant.Background,
  dashed = false
}) => {
  const classes = useDividerStyle({ size, orientation, colorVariant, dashed });
  return (
    <MaterialUiDivider
      orientation={orientation}
      variant={variant}
      className={getClassName(classes.root, className)}
    />
  );
};
