import {
  borderMixin,
  Color,
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  FlexJustifyContent,
  flexRowMixin,
  getClassName,
  marginMixin,
  paddingMixin,
  pxToRem
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { StyleVariant, TextVariant } from "model/themeVariant";

export interface BasePopupHeaderTitleStyle {
  style: StyleVariant;
  leftIconStyle: StyleVariant;
  color: Color;
  isEmptyHeader?: boolean;
}

interface BasePopupHeaderTitleClassNames {
  headerTexts: string;
  container: string;
  color: string;
  headerTitle: string;
  headerSubtitle: string;
  leftHeaderIconButton: string;
  headerIconButton: string;
  headerIcon: string;
  rightIconContainer: string;
}

const useBasePopupHeaderTitleStaticStyles = createStyle(
  (theme: Theme) => ({
    baseContainer: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.FlexStart
      }),
      width: "100%"
    },
    container: {
      ...borderMixin({
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.Background
        ),
        style: "solid",
        side: { bottom: 1, top: 0, leftRight: 0 }
      }),
      ...paddingMixin({ left: 24, right: 12, topBottom: 12 }),
      ...marginMixin({ bottom: 8 }),
      minHeight: pxToRem(56)
    },
    emptyContainer: {
      minHeight: pxToRem(40),
      ...paddingMixin({ left: 24, right: 10, top: 10 })
    },
    headerIcon: {
      width: "100%",
      height: "100%"
    },
    headerTexts: {
      ...flexColumnMixin({ gap: 4 })
    },
    rightIconContainer: {
      ...flexRowMixin({
        gap: 4
      }),
      ...marginMixin({
        left: "auto"
      })
    }
  }),
  "BasePopupHeaderTitleStatic"
);

const useBasePopupHeaderTitleDynamicStyles = createStyle(
  (theme: Theme) => ({
    color: (props: BasePopupHeaderTitleStyle) => ({
      backgroundColor: props.color,
      height: "100%",
      width: pxToRem(16)
    }),
    headerTitle: (props: BasePopupHeaderTitleStyle) => ({
      ...themeTextMixin(theme, TextVariant.H6, 1),
      color: getThemeStyleColor(
        theme,
        props.style,
        ThemeStyleColorVariant.GrayDark
      )
    }),
    headerSubtitle: (props: BasePopupHeaderTitleStyle) => ({
      ...themeTextMixin(theme, TextVariant.Body2, 1),
      color: getThemeStyleColor(
        theme,
        props.style,
        ThemeStyleColorVariant.GrayDark
      )
    }),
    headerIconButton: (props: BasePopupHeaderTitleStyle) => ({
      ...paddingMixin({
        all: pxToRem(2)
      }),
      width: pxToRem(28),
      height: pxToRem(28),
      color: getThemeStyleColor(
        theme,
        props.style,
        ThemeStyleColorVariant.GrayDark
      )
    }),
    leftHeaderIconButton: (props: BasePopupHeaderTitleStyle) => ({
      ...marginMixin({ left: 16 }),
      ...paddingMixin({
        all: pxToRem(4)
      }),
      width: pxToRem(28),
      height: pxToRem(28),
      color: getThemeStyleColor(
        theme,
        props.leftIconStyle,
        ThemeStyleColorVariant.Main
      )
    })
  }),
  "BasePopupHeaderTitleDynamic"
);

export const useBasePopupHeaderTitleStyle = (
  props: BasePopupHeaderTitleStyle
): BasePopupHeaderTitleClassNames => {
  const staticClasses = useBasePopupHeaderTitleStaticStyles();
  const dynamicClasses = useBasePopupHeaderTitleDynamicStyles(props);

  return {
    color: dynamicClasses.color,
    headerTitle: dynamicClasses.headerTitle,
    headerIconButton: dynamicClasses.headerIconButton,
    headerSubtitle: dynamicClasses.headerSubtitle,
    leftHeaderIconButton: dynamicClasses.leftHeaderIconButton,
    headerTexts: staticClasses.headerTexts,
    container: getClassName(
      staticClasses.baseContainer,
      props.isEmptyHeader
        ? staticClasses.emptyContainer
        : staticClasses.container
    ),
    headerIcon: staticClasses.headerIcon,
    rightIconContainer: staticClasses.rightIconContainer
  };
};
