import { ReactElement, useI18n } from "@laba/react-common";
import { Validator, required as requiredValidate, RaRecord } from "react-admin";
import {
  SelectInput,
  SelectInputVariant
} from "components/generic/SelectInput/SelectInput";
import React, { ChangeEvent, useState } from "react";
import { useEnumSelectorInputStyle } from "components/generic/EnumSelectorInput/enumSelectorInputStyle";
import { getAsArray, notNull, Optional } from "@laba/ts-common";
import { tkCC } from "translation/i18n";
import { useWatchResourceForm } from "components/hook/useResourceContext";

export interface EnumSelectorInputProps<T, R> {
  source: string;
  label: string;
  enumValue: T;
  alwaysOn?: boolean;
  fullWidth?: boolean;
  resettable?: boolean;
  withCreate?: boolean;
  defaultValue?: T[keyof T];
  optionValue?: T[keyof T][];
  validate?: Validator | Validator[];
  required?: boolean;
  parse?: (selectValue: Optional<string>) => Optional<R>;
  format?: (formValue: Optional<R>) => Optional<string>;
  disabled?: boolean;
  getOptionNameFromValue?: (option: string) => Optional<string>;
  onChange?: (event: ChangeEvent<HTMLInputElement> | RaRecord) => void;
}

const tk = tkCC.inputs.enumSelectorInput;
export const EnumSelectorInput = <T, R>({
  source,
  label,
  enumValue,
  alwaysOn = false,
  fullWidth = true,
  resettable = false,
  withCreate = false,
  defaultValue,
  validate,
  required,
  parse,
  format,
  disabled,
  optionValue = Object.values(enumValue),
  getOptionNameFromValue,
  onChange
}: EnumSelectorInputProps<T, R>): ReactElement => {
  const classes = useEnumSelectorInputStyle();
  const currentValue = useWatchResourceForm<R>(source);
  const formatedCurrentValue = format?.(currentValue) ?? String(currentValue);
  const [extraOption, setExtraOption] =
    useState<Optional<{ id: string; name: string }>>(undefined);
  const { t } = useI18n();
  const onCreate = withCreate
    ? () => {
        // eslint-disable-next-line no-alert
        const newValue = prompt(t(tk.createPrompt));
        const newOption = newValue
          ? {
              id: newValue,
              name: newValue
            }
          : undefined;
        setExtraOption(newOption);
        return newOption;
      }
    : undefined;
  const validateArray = getAsArray(validate).filter(notNull);
  if (required) validateArray.push(requiredValidate());

  const choices = (optionValue as unknown as string[]).map(value => ({
    id: value,
    name: getOptionNameFromValue?.(value) ?? String(value)
  }));
  if (extraOption) {
    choices.push(extraOption);
  }
  if (choices.find(value => value.id === formatedCurrentValue) === undefined) {
    choices.push({ id: formatedCurrentValue, name: formatedCurrentValue });
  }

  return (
    <SelectInput
      variant={SelectInputVariant.Outlined}
      fullWidth={fullWidth}
      source={source}
      label={label}
      choices={choices}
      alwaysOn={alwaysOn}
      className={classes.root}
      resettable={resettable}
      onCreate={onCreate}
      createLabel={t(tk.createLabel)}
      defaultValue={defaultValue}
      validate={validateArray}
      format={format}
      parse={parse}
      disabled={disabled}
      onChange={onChange}
    />
  );
};
