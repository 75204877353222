import {
  createStyle,
  flexColumnMixin,
  flexRowMixin,
  marginMixin
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { StyleVariant, TextVariant } from "model/themeVariant";

export const SearchableListModelInputStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexColumnMixin({ gap: 8 })
    },
    errorText: {
      ...themeTextMixin(theme, TextVariant.Caption),
      ...marginMixin({ leftRight: 14, top: 4 })
    },
    errorTextColor: {
      color: getThemeStyleColor(theme, StyleVariant.Danger)
    },
    helperTextColor: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    autocompleteAndButtonContainer: {
      ...flexRowMixin({ gap: 8 })
    }
  }),
  "SearchableListModelInput"
);
