import { sliceSelector } from "@laba/react-common";
import { sessionSlice } from "store/session/slice";
import { RootState } from "store/store";
import {
  provisionalServerUrlMap,
  Server,
  serverUrlMap
} from "models/server/server";
import { User } from "@laba/nexup-api";

const slice = sliceSelector(sessionSlice);

export const accessTokenSelector = (state: RootState): string | undefined =>
  slice(state).accessToken;

export const refreshTokenSelector = (state: RootState): string | undefined =>
  slice(state).refreshToken;

export const userSelector = (state: RootState): User | undefined =>
  slice(state).user;

export const isLoggingIn = (state: RootState): boolean =>
  slice(state).loggingIn;

export const isLogIn = (state: RootState): boolean =>
  slice(state).accessToken !== undefined;

export const serverSelector = (state: RootState): Server => slice(state).server;

export const localServerUrlSelector = (state: RootState): string =>
  slice(state).localUrl || serverUrlMap[Server.Local];

export const serverUrlSelector = (state: RootState): string => {
  const server = serverSelector(state);
  if (server === Server.Local) {
    return localServerUrlSelector(state);
  }
  return serverUrlMap[server];
};

export const provisionalServerUrlSelector = (state: RootState): string => {
  const server = serverSelector(state);
  if (server === Server.Local) {
    return localServerUrlSelector(state);
  }
  return provisionalServerUrlMap[server];
};
