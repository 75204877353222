import {
  SearchableListInputProps,
  SearchableListInput
} from "components/inputs/SearchableListInput/SearchableListInput";
import { AutocompleteOptionConfig } from "components/autocomplete/Autocomplete/Autocomplete";
import { ReactElement, withMemo } from "@laba/react-common";
import React, { useCallback, useMemo, useState } from "react";
import { notUndefined, Optional } from "@laba/ts-common";
import { differenceBy } from "lodash-es";

export type SearchableListSimpleInputProps<T> = Omit<
  SearchableListInputProps<T>,
  | "onInputChange"
  | "inputValue"
  | "selectedOptions"
  | "options"
  | "onSelectOption"
  | "onRemoveSelectedOption"
  | "getOptionFromValue"
> & {
  getOptionFromValue: (value: T) => AutocompleteOptionConfig<T>;
  valueList?: T[];
  optionList?: T[];
  setOptionList?: (option: T[]) => void;
  push?: (option: T) => void;
  remove?: (option: T, compare: (v1: T, v2: T) => boolean) => Optional<T>;
};

const SearchableListSimpleInputInt = <T,>({
  getOptionFromValue,
  valueList,
  optionList,
  setOptionList,
  push,
  remove,
  ...rest
}: SearchableListSimpleInputProps<T>): ReactElement => {
  const [inputValue, setInputValue] = useState("");
  const onSelectOptionHandler = useCallback(
    (value?: T): void => {
      push != null
        ? value && push(value)
        : setOptionList?.([...(valueList ?? []), value].filter(notUndefined));
      setInputValue("");
    },
    [valueList, setOptionList, push]
  );
  const onRemoveSelectedOptionHandler = useCallback(
    (value: T) => {
      remove != null
        ? remove(value, (v1, v2) => v1 === v2)
        : setOptionList?.(valueList?.filter(x => x !== value) ?? []);
    },
    [valueList, setOptionList, remove]
  );
  const inputOptions = useMemo(
    () => optionList?.map(getOptionFromValue) ?? [],
    [optionList, getOptionFromValue]
  );
  const selectedInputOptions = useMemo(
    () => valueList?.map(getOptionFromValue) ?? [],
    [valueList, getOptionFromValue]
  );
  const filteredInputOptions = useMemo(
    () => differenceBy(inputOptions, selectedInputOptions, x => x.value),
    [inputOptions, selectedInputOptions]
  );

  return (
    <SearchableListInput
      onInputChange={setInputValue}
      inputValue={inputValue}
      selectedOptions={selectedInputOptions}
      options={filteredInputOptions}
      onSelectOption={onSelectOptionHandler}
      onRemoveSelectedOption={onRemoveSelectedOptionHandler}
      getOptionFromValue={getOptionFromValue}
      {...rest}
    />
  );
};

export const SearchableListSimpleInput = withMemo(
  SearchableListSimpleInputInt
) as typeof SearchableListSimpleInputInt;
