import React from "react";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  StylesProvider
} from "@material-ui/core/styles";
import { StylesProviderProps } from "@material-ui/styles";
import { PropsWithChildren, ReactElement } from "components/types";
import { JssProvider, ThemeProvider } from "react-jss";
import { getJss, SheetsRegistry } from "./jss";

// eslint-disable-next-line @typescript-eslint/ban-types
export type JssDefaultTheme = object;

export interface StyleProviderProps<
  T extends JssDefaultTheme = JssDefaultTheme
> {
  theme: T;
  registry?: SheetsRegistry;
}

const defaultMuiTheme = createTheme();
export const StyleProvider = <T extends JssDefaultTheme = JssDefaultTheme>({
  theme,
  children,
  registry
}: PropsWithChildren<StyleProviderProps<T>>): ReactElement => {
  return (
    <StylesProvider
      jss={getJss() as unknown as StylesProviderProps["jss"]}
      sheetsRegistry={registry}
    >
      <MuiThemeProvider theme={defaultMuiTheme}>
        <JssProvider jss={getJss()} registry={registry}>
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </JssProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
};
