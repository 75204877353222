import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { FCC, getClassName, IconC } from "@laba/react-common";
import { useMuiTheme } from "model/useMuiTheme";
import { StyleVariant, SizeVariant, TypeVariant } from "model/themeVariant";
import {
  ButtonType,
  DefaultButton
} from "components/buttons/DefaultButton/DefaultButton";
import { Noop } from "@laba/ts-common";
import {
  ScrollableContainer,
  ScrollDirection
} from "components/containers/ScrollableContainer/ScrollableContainer";
import { useFullScreenCardStyle } from "./FullScreenCardStyle";

export interface FullScreenCardProps {
  className?: string;
  title?: string;
  onPrimaryButtonClick?: Noop;
  onSecondaryButtonClick?: Noop;
  primaryButtonText?: string;
  primaryStyle?: StyleVariant;
  PrimaryButtonIcon?: IconC;
  secondaryButtonText?: string;
  secondaryStyle?: StyleVariant;
  SecondaryButtonIcon?: IconC;
  formId?: string;
}

export const FullScreenCard: FCC<FullScreenCardProps> = ({
  children,
  className,
  title,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonText,
  secondaryButtonText,
  PrimaryButtonIcon,
  SecondaryButtonIcon,
  primaryStyle = StyleVariant.Primary,
  secondaryStyle = StyleVariant.Primary,
  formId
}) => {
  const showPrimaryButton =
    Boolean(primaryButtonText) &&
    (Boolean(onPrimaryButtonClick) || Boolean(formId));
  const showSecondaryButton =
    Boolean(secondaryButtonText) && Boolean(onSecondaryButtonClick);
  const showButtons = showPrimaryButton || showSecondaryButton;
  const theme = useMuiTheme(StyleVariant.Primary);
  const FullScreenCardClasses = useFullScreenCardStyle();
  return (
    <ThemeProvider theme={theme}>
      <ScrollableContainer scrollDirection={ScrollDirection.Vertical}>
        <div
          className={getClassName(className, FullScreenCardClasses.container)}
        >
          <p className={FullScreenCardClasses.title}>{title}</p>
          <div className={FullScreenCardClasses.childrenContainer}>
            {children}
          </div>
          {showButtons && (
            <div className={FullScreenCardClasses.buttonsContainer}>
              {showPrimaryButton && (
                <DefaultButton
                  fullWidth
                  onClick={onPrimaryButtonClick}
                  text={primaryButtonText}
                  title={primaryButtonText}
                  size={SizeVariant.Small}
                  style={primaryStyle}
                  formId={formId}
                  buttonType={formId ? ButtonType.Submit : ButtonType.Button}
                  StartIcon={PrimaryButtonIcon}
                />
              )}
              {showSecondaryButton && (
                <DefaultButton
                  fullWidth
                  onClick={onSecondaryButtonClick}
                  text={secondaryButtonText}
                  title={secondaryButtonText}
                  type={TypeVariant.Outlined}
                  size={SizeVariant.Small}
                  style={secondaryStyle}
                  StartIcon={SecondaryButtonIcon}
                />
              )}
            </div>
          )}
        </div>
      </ScrollableContainer>
    </ThemeProvider>
  );
};
