export const measurementGroupDefinition = {
  title: "Grupos de mediciones",
  filters: {
    organization: "Organizacion",
    category: "Categoria",
    status: "Estado"
  },
  fields: {
    id: "Id",
    name: "Nombre",
    organization: "Organizacion",
    status: "Estado",
    category: "Categoria",
    color: "Color",
    editPeriod: "Tiempo de edicion (seg)",

    measurements: "Mediciones",
    measurementsLinkId: "link Id",
    measurementsTitle: "Titulo",
    measurementsType: "Tipo",
    measurementsUnits: "Unidades",
    measurementsUnitsElement: "Unidad",
    measurementsMaxValue: "Valor maximo",
    measurementsMinValue: "Valor minimo",
    measurementsStep: "Paso",
    measurementsRange: "Rango",
    measurementsRequired: "Requerido",
    measurementsIsComponent: "Es interno (medicion sin entidad propia)",
    measurementsFluidBalanceCategory: "Categoria de balance hidrico"
  },
  tabs: {
    main: "Grupo de mediciones",
    measurementList: "Mediciones",
    measurementDetail: "Medicion detalle",
    measurementCompleteList: "Mediciones completas"
  },
  autocompleteClearText: "Limpiar",
  autocompleteCloseText: "Cerrar",
  autocompleteLoadingText: "Cargando",
  autocompleteNoOptionsText: "Sin opciones",
  autocompleteOpenText: "Abrir",
  autocompleteTitle: "Codigo a Editar"
};
