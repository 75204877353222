import { startsWith } from "lodash-es";
import { CountryAreaPhoneCode } from "phone/countryAreaPhoneCode";
import { removePhoneCountryCode } from "phone/validation/utils";

const uruguayanPhone = (plainPhone: string) => {
  return `+598${plainPhone}`;
};

const removeZeroAfterCountryCode = (
  phoneNumber: string,
  countryCode: CountryAreaPhoneCode
): string => {
  const phoneNumberWithoutCountryCode = removePhoneCountryCode(
    phoneNumber,
    countryCode
  );

  if (startsWith(phoneNumberWithoutCountryCode, "0")) {
    return uruguayanPhone(phoneNumberWithoutCountryCode.slice(1));
  }

  return phoneNumber;
};

export const fixUruguayanPhoneNumber = (phoneNumber: string): string => {
  const phoneWithoutAdditionalZero = removeZeroAfterCountryCode(
    phoneNumber,
    CountryAreaPhoneCode.Uruguay
  );

  return phoneWithoutAdditionalZero;
};
