import { Header, RequestSuccessResponse } from "./models";

const contentDispositionFileNameRegex = /filename="(.+)"/;

export const getResponseFilename = <T>(
  response: RequestSuccessResponse<T>,
  defaultName = ""
): string => {
  const contentDisposition = response.headers?.[Header.ContentDisposition];
  return (
    contentDisposition?.match(contentDispositionFileNameRegex)?.[1] ??
    defaultName
  );
};
