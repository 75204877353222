export const procedureReportDefinition = {
  title: "Estudios y Procedimientos",
  filters: {
    organization: "Organizacion",
    status: "Estado"
  },
  fields: {
    id: "Id",
    name: "Nombre",
    organization: "Organizacion",
    status: "Estado",
    locationCategory: "Categoria lugar",
    color: "Color",
    order: "Orden",
    measurementGroupDefinition: "Definición de Grupo de mediciones"
  },
  tabs: {
    procedureReportDefinition: "Estudio y procedimiento"
  }
};
