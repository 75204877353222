import {
  borderMixinRadiusAll,
  createStyle,
  ElevationVariant,
  flexColumnMixin,
  flexItemMixin,
  paddingMixin,
  StyleVariant,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { RadiusVariant, Theme } from "model/theme";
import {
  getThemeRadius,
  getThemeStyleColor,
  themeElevationMixin
} from "model/themeUtils";

export const useLocationCardStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexColumnMixin({ gap: 8 }),
      ...themeElevationMixin(theme, ElevationVariant.Elevation8),
      ...borderMixinRadiusAll(getThemeRadius(theme, RadiusVariant.Large)),
      ...paddingMixin({ all: 16 }),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      ),
      overflow: "hidden"
    },
    buttons: {
      ...flexItemMixin({ grow: 1, shrink: 1, basis: "0%" })
    }
  }),
  "LocationCard"
);
