import React from "react";
import { ResizableBox } from "react-resizable";
import { createStyle } from "style/createUseStyles";
import { FCC } from "components/types";
import { StoryDecorator } from "storybook/decorator/types";
import { Theme } from "@laba/ts-common";

export const useResizableBoxStyle = createStyle(
  (_theme: Theme) => ({
    "@global": {
      ".react-resizable": {
        position: "relative",
        "border-bottom": "1px solid black",
        "border-right": "1px solid black"
      },
      ".react-resizable-handle": {
        position: "absolute",
        width: "20px",
        height: "20px",
        "background-repeat": "no-repeat",
        "background-origin": "content-box",
        "box-sizing": "border-box",
        "background-image":
          "url('data:image/svg+xml,base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+')",
        "background-position": "bottom right",
        padding: "0 3px 3px 0"
      },
      ".react-resizable-handle-sw": {
        bottom: "0",
        left: "0",
        cursor: "sw-resize",
        transform: "rotate(90deg)"
      },
      ".react-resizable-handle-se": {
        bottom: "0",
        right: "0",
        cursor: "se-resize"
      },
      ".react-resizable-handle-nw": {
        top: "0",
        left: "0",
        cursor: "nw-resize",
        transform: "rotate(180deg)"
      },
      ".react-resizable-handle-ne": {
        top: "0",
        right: "0",
        cursor: "ne-resize",
        transform: "rotate(270deg)"
      },
      ".react-resizable-handle-w": {
        top: "50%",
        "margin-top": "-10px",
        cursor: "ew-resize",
        left: "0",
        transform: "rotate(135deg)"
      },
      ".react-resizable-handle-e": {
        top: "50%",
        "margin-top": "-10px",
        cursor: "ew-resize",
        right: "0",
        transform: "rotate(315deg)"
      },
      ".react-resizable-handle-n": {
        left: "50%",
        "margin-left": "-10px",
        cursor: "ns-resize",
        top: "0",
        transform: "rotate(225deg)"
      },
      ".react-resizable-handle-s": {
        left: "50%",
        "margin-left": "-10px",
        cursor: "ns-resize",
        bottom: "0",
        transform: "rotate(45deg)"
      }
    }
  }),
  "ResizableBox"
);

export interface ResizableBoxContainerProps {
  width?: number;
  height?: number;
}

export const ResizableBoxContainer: FCC<ResizableBoxContainerProps> = ({
  width = 500,
  height = 300,
  children
}) => {
  useResizableBoxStyle();
  return (
    <ResizableBox width={width} height={height}>
      {children}
    </ResizableBox>
  );
};

export const getResizableBoxDecorator = (
  width = 500,
  height = 300
): StoryDecorator => {
  return story => {
    return (
      <ResizableBoxContainer width={width} height={height}>
        {story()}
      </ResizableBoxContainer>
    );
  };
};
