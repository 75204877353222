import { Button } from "react-admin";
import React, { useState } from "react";
import { FC, IconC } from "@laba/react-common";
import { v4 as uuidV4 } from "uuid";

export interface UploadFileButtonProps {
  id?: string;
  fileType?: string;
  label?: string;
  Icon?: IconC;
  multiple?: boolean;
  onFileAdded: (e: React.ChangeEvent<HTMLInputElement>) => void | Promise<void>;
}
export const UploadFileButton: FC<UploadFileButtonProps> = ({
  id,
  fileType,
  label,
  Icon,
  onFileAdded,
  multiple = false
}) => {
  const [inputId] = useState(id ?? uuidV4());
  return (
    <label htmlFor={inputId}>
      <input
        id={inputId}
        type="file"
        accept={fileType}
        style={{ display: "none" }}
        onChange={onFileAdded}
        multiple={multiple}
      />
      <Button label={label} component="span">
        {Icon && <Icon />}
      </Button>
    </label>
  );
};
