import { DateTime, getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { Model, OpenCode } from "./model/model";
import { Attachment } from "./attachment/attachment";

export enum ReportType {
  NursingSheet = "NursingSheet",
  MedicalRequestSheet = "MedicalRequestSheet",
  EvolutionSheet = "EvolutionSheet",
  MedicalRequestNursingSheet = "MedicalRequestNursingSheet",
  PrescriptionRequestDocument = "PrescriptionRequestDocument",
  InvoiceDocument = "InvoiceDocument"
}

export type PatientReportType =
  | ReportType.NursingSheet
  | ReportType.MedicalRequestSheet;

export type EvolutionReportType = ReportType.EvolutionSheet;

export enum ReportPropertyKnownType {
  Picture = "Picture",
  Prefix = "Prefix",
  NursingStartTime = "NursingStartTime",
  PrescriptionRequestProvider = "PrescriptionRequestProvider",
  Title = "Title",
  Logo = "Logo",
  HeaderSection = "HeaderSection",
  Country = "Country"
}

export enum ReportPropertyKnownPrescriptionRequestProvider {
  Nexup = "Nexup",
  NexupQbi2 = "NexupQbi2",
  Test = "Test"
}

export enum ReportPropertyKnownPrescriptionRequestProviderCountry {
  AR = "AR",
  UY = "UY"
}

export enum ReportPropertyKnownHeaderSection {
  Age = "Age",
  Allergies = "Allergies",
  EncounterDuration = "EncounterDuration",
  EncounterLocation = "EncounterLocation",
  HealthcareProvider = "HealthcareProvider"
}

export type ReportPropertyType = OpenCode<ReportPropertyKnownType>;

export interface ReportProperty {
  type: ReportPropertyType;
  value: string;
}

export const ReportPropertyKey = getKeyObj<ReportProperty>(
  createHydratedMock<ReportProperty>()
);

export interface Report extends Model {
  reportType?: ReportType;
  prefix?: string;
  picture?: Attachment;
  properties?: ReportProperty[];
}

export const ReportKey = getKeyObj<Report>(createHydratedMock<Report>());

export interface PatientReportDownloadConfig {
  date: DateTime;
  reportToDownload: PatientReportType;
}
