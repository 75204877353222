import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";
import { FC } from "@laba/react-common";

export const NexupLandingIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.90405 14.8791C6.02221 13.0149 8.56931 11.7047 11.3176 11.0655C9.25928 9.95833 8.10134 7.6775 8.10134 2.96868L8.17667 0.884033C8.17667 0.884033 0.148187 0.884033 0.191982 0.884033C0.152492 1.45603 0.146652 2.02986 0.174474 2.60255C0.172722 3.69567 -0.0427528 10.2106 3.90405 14.8791Z"
        fill="#4F4F4F"
      />
      <path
        d="M25.5937 17.0083C23.344 18.9021 20.5996 20.1129 17.6843 20.4979C20.3506 21.838 21.2807 24.7197 21.2807 29.4321L21.2597 31.1208C21.2597 31.1208 29.4424 31.0595 29.4932 31.0805C29.736 28.5226 29.523 25.9419 28.8643 23.4584C28.192 21.1208 27.0822 18.932 25.5937 17.0083Z"
        fill="#4F4F4F"
      />
      <path
        d="M21.4266 2.26458H21.4371L21.509 4.44557C21.509 9.32958 18.7744 11.4528 14.1812 11.726C10.5007 11.9485 7.01322 13.4484 4.32031 15.9671C2.58161 17.8755 1.34666 20.1877 0.727387 22.694C0.0804221 25.4364 -0.110169 28.2667 0.163298 31.0711C0.217604 31.0501 8.39674 31.1114 8.39674 31.1114V29.3298C8.39674 22.6029 10.3483 19.9402 15.9295 19.6266C17.7726 19.5946 19.5905 19.1924 21.2752 18.4441C22.9598 17.6957 24.4769 16.6164 25.7363 15.2703C26.9957 13.9241 27.9717 12.3386 28.6063 10.6079C29.2409 8.87714 29.5212 7.03652 29.4306 5.19534L29.5339 2.26283C29.5688 1.7754 29.7138 1.30223 29.9579 0.878906H21.5352C21.4703 1.3381 21.4341 1.80088 21.4266 2.26458Z"
        fill="#6B77E0"
      />
    </SvgIcon>
  );
};
