import {
  ResourceType,
  getQuestionnaireList,
  getQuestionnaire,
  updateQuestionnaire,
  createQuestionnaire,
  QuestionnaireListQueryParams,
  QuestionnaireListQueryParamsKey,
  getModelReferenceId
} from "@laba/nexup-api";
import {
  getEnumOrderParam,
  getKeyTargetParam,
  resourceProviderGetter
} from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { workspaceOrganizationSelector } from "store/workspace/selectors";
import { store } from "store/store";

export const questionnaireProvider = resourceProviderGetter(
  ResourceType.Questionnaire,
  getEnumOrderParam({}),
  getKeyTargetParam<QuestionnaireListQueryParams>(
    QuestionnaireListQueryParamsKey
  ),
  getQuestionnaireList,
  getQuestionnaire,
  updateQuestionnaire,
  createQuestionnaire,
  undefined,
  () => ({
    organization: getModelReferenceId(
      workspaceOrganizationSelector(store.getState())
    )
  })
);
