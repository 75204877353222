import React from "react";
import { FC, useI18n, ElevationVariant } from "@laba/react-common";
import { OrganizationKey, ResourceType } from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { Labeled } from "react-admin";
import { NumberInput } from "components/generic/NumberInput/TextInput";
import { ColorInput } from "components/generic/ColorInput/ColorInput";

export const tk = tkCP.adminPage[ResourceType.Organization];
export interface OrganizationWhiteLabelThemeElevationContentProps {
  elevationVariant: ElevationVariant;
  label: string;
}
export const OrganizationWhiteLabelThemeElevationContent: FC<
  OrganizationWhiteLabelThemeElevationContentProps
> = ({ elevationVariant, label }) => {
  const { t } = useI18n();
  return (
    <Labeled label={label} fullWidth>
      <>
        <NumberInput
          source={
            OrganizationKey.whiteLabelConfig.theme.elevation[elevationVariant]
              .offsetX
          }
          label={t(tk.fields.whiteLabelConfigThemeElevationOffsetX)}
          min={0}
          step={0.01}
        />
        <NumberInput
          source={
            OrganizationKey.whiteLabelConfig.theme.elevation[elevationVariant]
              .offsetY
          }
          label={t(tk.fields.whiteLabelConfigThemeElevationOffsetY)}
          min={0}
          step={0.01}
        />
        <NumberInput
          source={
            OrganizationKey.whiteLabelConfig.theme.elevation[elevationVariant]
              .blurRadius
          }
          label={t(tk.fields.whiteLabelConfigThemeElevationBlurRadius)}
          min={0}
          step={0.01}
        />
        <NumberInput
          source={
            OrganizationKey.whiteLabelConfig.theme.elevation[elevationVariant]
              .transitionS
          }
          label={t(tk.fields.whiteLabelConfigThemeElevationTransitionS)}
          min={0}
          step={0.01}
        />
        <ColorInput
          source={
            OrganizationKey.whiteLabelConfig.theme.elevation[elevationVariant]
              .color
          }
          label={t(tk.fields.whiteLabelConfigThemeElevationColor)}
        />
      </>
    </Labeled>
  );
};
