import React from "react";
import {
  FC,
  getClassName,
  SizeVariant,
  stopPropagationWrapper
} from "@laba/react-common";
import { BaseIconButton } from "components/buttons/BaseIconButton/BaseIconButton";
import { Noop } from "@laba/ts-common";
import { EditIcon, KeyboardArrowUp, KeyboardArrowDown } from "components/icons";
import { useFloatingButtonsStyle } from "./FloatingButtonsStyle";

export interface FloatingButtonsProps {
  className?: string;
  isExpanded?: boolean;
  isExpansible?: boolean;
  isEditable?: boolean;
  onEditButtonClick?: Noop;
  onExpandCollapseButtonClick?: Noop;
}

export const FloatingButtons: FC<FloatingButtonsProps> = ({
  className,
  isExpanded = false,
  isExpansible = false,
  isEditable = false,
  onEditButtonClick,
  onExpandCollapseButtonClick
}) => {
  const classes = useFloatingButtonsStyle();

  return (
    <div className={getClassName(classes.floatingButtonsContainer, className)}>
      {isEditable && (
        <BaseIconButton
          Icon={EditIcon}
          className={classes.actionButton}
          iconClassName={classes.editIcon}
          onClick={stopPropagationWrapper(onEditButtonClick)}
          size={SizeVariant.ExtraSmall}
        />
      )}
      {isExpansible &&
        (isExpanded ? (
          <BaseIconButton
            Icon={KeyboardArrowUp}
            className={classes.actionButton}
            onClick={stopPropagationWrapper(onExpandCollapseButtonClick)}
            size={SizeVariant.ExtraSmall}
          />
        ) : (
          <BaseIconButton
            Icon={KeyboardArrowDown}
            className={classes.actionButton}
            onClick={stopPropagationWrapper(onExpandCollapseButtonClick)}
            size={SizeVariant.ExtraSmall}
          />
        ))}
    </div>
  );
};
