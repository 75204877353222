import {
  FlexAlignItems,
  createStyle,
  flexRowMixin,
  paddingMixin
} from "@laba/react-common";

export const UserButtonStyles = createStyle(
  _theme => ({
    root: {
      ...flexRowMixin({ gap: 16, alignItems: FlexAlignItems.Center }),
      ...paddingMixin({ topBottom: 8 }),
      height: "100%"
    },
    verticalSeparator: {
      height: "80%"
    }
  }),
  "UserButton"
);
