import React from "react";
import { FC, getClassName } from "@laba/react-common";
import { Noop } from "@laba/ts-common";
import { Button } from "@material-ui/core";
import { useGridItemStyles } from "./useGridItemStyles";

export interface GridItemProps {
  className?: string;
  text: string;
  active: boolean;
  onClick?: Noop;
  disabled?: boolean;
}

export const GridItem: FC<GridItemProps> = ({
  className,
  text,
  active,
  onClick,
  disabled = false
}) => {
  const classes = useGridItemStyles({ active });
  return (
    <Button
      className={getClassName(className, classes.root)}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};
