export const dashboardConfigMock = [
  {
    title: "Simple",
    icon: "https://cdn.iconscout.com/icon/free/png-256/gitlab-282507.png",
    url: "https://datastudio.google.com/embed/reporting/4fe03105-e31d-453d-a52c-fd1900d7c550/page/Ci8BD"
  },
  {
    title: "Simple",
    icon: "https://cdn-icons-png.flaticon.com/128/287/287221.png",
    url: "https://datastudio.google.com/embed/reporting/4fe03105-e31d-453d-a52c-fd1900d7c550/page/Ci8BD"
  },
  {
    title: "Simple",
    icon: "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_256,w_256,f_auto,q_auto:eco,dpr_1/v1475248336/qmsen1jtcy8xgsghzc8z.jpg",
    url: "https://datastudio.google.com/embed/reporting/4fe03105-e31d-453d-a52c-fd1900d7c550/page/Ci8BD"
  },
  {
    title: "Simple",
    icon: "https://cdn.iconscout.com/icon/free/png-256/gitlab-282507.png",
    url: "https://datastudio.google.com/embed/reporting/4fe03105-e31d-453d-a52c-fd1900d7c550/page/Ci8BD"
  },
  {
    title: "Simple",
    icon: "https://cdn.iconscout.com/icon/free/png-256/gitlab-282507.png",
    url: "https://datastudio.google.com/embed/reporting/4fe03105-e31d-453d-a52c-fd1900d7c550/page/Ci8BD"
  }
];
