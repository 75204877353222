import {
  createStyle,
  flexColumnMixin,
  FlexJustifyContent,
  flexRowMixin,
  paddingMixin,
  Theme
} from "@laba/react-common";

export const useTagListInputStyles = createStyle(
  (_theme: Theme) => ({
    root: {
      ...flexColumnMixin({ gap: 8 })
    },
    listBoxComponent: {
      ...flexRowMixin({
        wrapContent: true,
        gap: 8,
        justifyContent: FlexJustifyContent.FlexStart
      }),
      ...paddingMixin({ leftRight: 16, topBottom: 16 }),
      width: "100%"
    },
    selectedOptionsContainer: {
      ...flexRowMixin({ gap: 8, wrapContent: true }),
      width: "100%"
    },
    customClickableOption: {
      ...paddingMixin({ leftRight: 0 }),
      width: "fit-content"
    }
  }),
  "TagListInput"
);
