import {
  createStyle,
  FlexAlignItems,
  flexRowMixin,
  marginMixin,
  paddingMixin,
  pxToRem,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { StyleVariant } from "model/themeVariant";
import { ProblemStatus } from "components/inputs/ProblemInput/ProblemInput";

export interface StyleProps {
  status: ProblemStatus;
}

const getStatusIconColor = (theme: Theme, status: ProblemStatus): string => {
  if (status === ProblemStatus.Active) {
    return getThemeStyleColor(theme, StyleVariant.Success);
  }
  return getThemeStyleColor(
    theme,
    StyleVariant.Primary,
    ThemeStyleColorVariant.GrayMedium
  );
};

export const useProblemItemStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        gap: 12
      }),
      ...paddingMixin({
        left: 8,
        right: 20,
        topBottom: 8
      }),
      width: "100%",
      maxHeight: pxToRem(40)
    },
    text: {
      ...themeTextMixin(theme, TextVariant.Body2, 1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    statusIcon: (props: StyleProps) => ({
      color: getStatusIconColor(theme, props.status)
    }),
    endContainer: {
      ...flexRowMixin({ alignItems: FlexAlignItems.Center, gap: 12 }),
      ...marginMixin({ left: "auto" })
    }
  }),
  "AddProblemItem"
);
