import React from "react";
import {
  ElevationVariant,
  FC,
  GradientVariant,
  StyleVariant,
  TextVariant,
  useI18n
} from "@laba/react-common";
import { OrganizationKey, ResourceType } from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { OrganizationWhiteLabelThemeStyleContent } from "components/pages/NexupAdmin/resources/organization/OrganizationWhiteLabelThemeStyleContent";
import { OrganizationWhiteLabelThemeTextContent } from "components/pages/NexupAdmin/resources/organization/OrganizationWhiteLabelThemeTextContent";
import { NumberInput } from "components/generic/NumberInput/TextInput";
import { OrganizationWhiteLabelThemeElevationContent } from "components/pages/NexupAdmin/resources/organization/OrganizationWhiteLabelThemeElevationContent";
import { OrganizationWhiteLabelThemeGradientContent } from "./OrganizationWhiteLabelThemeGradientContent";

export const tk = tkCP.adminPage[ResourceType.Organization];
export const OrganizationWhiteLabelThemeContent: FC = () => {
  const { t } = useI18n();
  return (
    <>
      <BooleanInput
        source={OrganizationKey.whiteLabelConfig.theme.isNexupComponentTheme}
        label={t(tk.fields.whiteLabelConfigTheme)}
        defaultValue
        disabled
      />
      <OrganizationWhiteLabelThemeStyleContent
        styleVariant={StyleVariant.Primary}
        label={t(tk.fields.whiteLabelConfigThemeStylePrimary)}
      />
      <OrganizationWhiteLabelThemeStyleContent
        styleVariant={StyleVariant.Primary}
        label={t(tk.fields.whiteLabelConfigThemeStyleSecondary)}
      />
      <OrganizationWhiteLabelThemeStyleContent
        styleVariant={StyleVariant.Danger}
        label={t(tk.fields.whiteLabelConfigThemeStyleDanger)}
      />
      <OrganizationWhiteLabelThemeStyleContent
        styleVariant={StyleVariant.Warning}
        label={t(tk.fields.whiteLabelConfigThemeStyleWarning)}
      />
      <OrganizationWhiteLabelThemeStyleContent
        styleVariant={StyleVariant.Success}
        label={t(tk.fields.whiteLabelConfigThemeStyleSuccess)}
      />
      <OrganizationWhiteLabelThemeStyleContent
        styleVariant={StyleVariant.Info}
        label={t(tk.fields.whiteLabelConfigThemeStyleInfo)}
      />
      <OrganizationWhiteLabelThemeStyleContent
        styleVariant={StyleVariant.BlackWhite}
        label={t(tk.fields.whiteLabelConfigThemeStyleBlackWhite)}
      />
      <OrganizationWhiteLabelThemeTextContent
        textVariant={TextVariant.H1}
        label={t(tk.fields.whiteLabelConfigThemeTextH1)}
      />
      <OrganizationWhiteLabelThemeTextContent
        textVariant={TextVariant.H2}
        label={t(tk.fields.whiteLabelConfigThemeTextH2)}
      />
      <OrganizationWhiteLabelThemeTextContent
        textVariant={TextVariant.H3}
        label={t(tk.fields.whiteLabelConfigThemeTextH3)}
      />
      <OrganizationWhiteLabelThemeTextContent
        textVariant={TextVariant.H4}
        label={t(tk.fields.whiteLabelConfigThemeTextH4)}
      />
      <OrganizationWhiteLabelThemeTextContent
        textVariant={TextVariant.H5}
        label={t(tk.fields.whiteLabelConfigThemeTextH5)}
      />
      <OrganizationWhiteLabelThemeTextContent
        textVariant={TextVariant.H6}
        label={t(tk.fields.whiteLabelConfigThemeTextH6)}
      />
      <OrganizationWhiteLabelThemeTextContent
        textVariant={TextVariant.Subtitle1}
        label={t(tk.fields.whiteLabelConfigThemeTextSubtitle1)}
      />
      <OrganizationWhiteLabelThemeTextContent
        textVariant={TextVariant.Subtitle2}
        label={t(tk.fields.whiteLabelConfigThemeTextSubtitle2)}
      />
      <OrganizationWhiteLabelThemeTextContent
        textVariant={TextVariant.Body1}
        label={t(tk.fields.whiteLabelConfigThemeTextBody1)}
      />
      <OrganizationWhiteLabelThemeTextContent
        textVariant={TextVariant.Body2}
        label={t(tk.fields.whiteLabelConfigThemeTextBody2)}
      />
      <OrganizationWhiteLabelThemeTextContent
        textVariant={TextVariant.Button}
        label={t(tk.fields.whiteLabelConfigThemeTextButton)}
      />
      <OrganizationWhiteLabelThemeTextContent
        textVariant={TextVariant.Caption}
        label={t(tk.fields.whiteLabelConfigThemeTextCaption)}
      />
      <OrganizationWhiteLabelThemeTextContent
        textVariant={TextVariant.Overline}
        label={t(tk.fields.whiteLabelConfigThemeTextOverline)}
      />
      <NumberInput
        source={OrganizationKey.whiteLabelConfig.theme.radius.Large}
        label={t(tk.fields.whiteLabelConfigThemeRadiusLarge)}
      />
      <NumberInput
        source={OrganizationKey.whiteLabelConfig.theme.radius.Medium}
        label={t(tk.fields.whiteLabelConfigThemeRadiusMedium)}
      />
      <NumberInput
        source={OrganizationKey.whiteLabelConfig.theme.radius.Small}
        label={t(tk.fields.whiteLabelConfigThemeRadiusSmall)}
      />
      <OrganizationWhiteLabelThemeElevationContent
        elevationVariant={ElevationVariant.Elevation0}
        label={t(tk.fields.whiteLabelConfigThemeElevation0)}
      />
      <OrganizationWhiteLabelThemeElevationContent
        elevationVariant={ElevationVariant.Elevation1}
        label={t(tk.fields.whiteLabelConfigThemeElevation1)}
      />
      <OrganizationWhiteLabelThemeElevationContent
        elevationVariant={ElevationVariant.Elevation2}
        label={t(tk.fields.whiteLabelConfigThemeElevation2)}
      />
      <OrganizationWhiteLabelThemeElevationContent
        elevationVariant={ElevationVariant.Elevation3}
        label={t(tk.fields.whiteLabelConfigThemeElevation3)}
      />
      <OrganizationWhiteLabelThemeElevationContent
        elevationVariant={ElevationVariant.Elevation4}
        label={t(tk.fields.whiteLabelConfigThemeElevation4)}
      />
      <OrganizationWhiteLabelThemeElevationContent
        elevationVariant={ElevationVariant.Elevation6}
        label={t(tk.fields.whiteLabelConfigThemeElevation6)}
      />
      <OrganizationWhiteLabelThemeElevationContent
        elevationVariant={ElevationVariant.Elevation8}
        label={t(tk.fields.whiteLabelConfigThemeElevation8)}
      />
      <OrganizationWhiteLabelThemeElevationContent
        elevationVariant={ElevationVariant.Elevation9}
        label={t(tk.fields.whiteLabelConfigThemeElevation9)}
      />
      <OrganizationWhiteLabelThemeElevationContent
        elevationVariant={ElevationVariant.Elevation12}
        label={t(tk.fields.whiteLabelConfigThemeElevation12)}
      />
      <OrganizationWhiteLabelThemeElevationContent
        elevationVariant={ElevationVariant.Elevation16}
        label={t(tk.fields.whiteLabelConfigThemeElevation16)}
      />
      <OrganizationWhiteLabelThemeElevationContent
        elevationVariant={ElevationVariant.Elevation24}
        label={t(tk.fields.whiteLabelConfigThemeElevation24)}
      />
      <OrganizationWhiteLabelThemeGradientContent
        gradientVariant={GradientVariant.MainGradient}
        label={t(tk.fields.whiteLabelConfigThemeMainGradient)}
      />
      <OrganizationWhiteLabelThemeGradientContent
        gradientVariant={GradientVariant.Gradient1}
        label={t(tk.fields.whiteLabelConfigThemeGradient1)}
      />
      <OrganizationWhiteLabelThemeGradientContent
        gradientVariant={GradientVariant.Gradient2}
        label={t(tk.fields.whiteLabelConfigThemeGradient2)}
      />
      <OrganizationWhiteLabelThemeGradientContent
        gradientVariant={GradientVariant.Gradient3}
        label={t(tk.fields.whiteLabelConfigThemeGradient3)}
      />
      <OrganizationWhiteLabelThemeGradientContent
        gradientVariant={GradientVariant.Gradient4}
        label={t(tk.fields.whiteLabelConfigThemeGradient4)}
      />
    </>
  );
};
