import React from "react";
import { SliceCaseReducers } from "store/types";
import { Children, ReactElement } from "components/types";
import { GenericSliceType, SliceContextType } from "./types";

export interface GenericSliceProviderProps<S, A extends SliceCaseReducers<S>> {
  slice: GenericSliceType<S, A>;
  context: SliceContextType<S, A>;
  children: Children;
}

export const GenericSliceProvider = <S, A extends SliceCaseReducers<S>>({
  slice,
  children,
  context
}: GenericSliceProviderProps<S, A>): ReactElement => {
  return <context.Provider value={slice}>{children}</context.Provider>;
};
