import { FC, getClassName, IconC } from "@laba/react-common";
import React from "react";
import { DefaultButton } from "components/buttons/DefaultButton/DefaultButton";
import { Popup } from "components/popup/Popup/Popup";
import { IFrame } from "components/iframe/IFrame/IFrame";
import { TypeVariant } from "model/themeVariant";
import { useIFrameButtonStyles } from "components/iframe/IFrameButton/useIFrameButtonStyles";
import { Noop } from "@laba/ts-common";

export interface IFrameButtonProps {
  className?: string;
  iframeClassName?: string;
  open?: boolean;
  id?: string;
  title?: string;
  headerTitle?: string;
  StartIcon?: IconC;
  buttonText?: string;
  onClick?: Noop;
  source?: string;
  frameBorder?: boolean;
  scrolling?: boolean;
  allowFullScreen?: boolean;
  isMobile?: boolean;
}

export const IFrameButton: FC<IFrameButtonProps> = ({
  className,
  iframeClassName,
  id,
  title,
  StartIcon,
  buttonText,
  headerTitle,
  onClick,
  open,
  source,
  isMobile,
  frameBorder = true,
  scrolling = true,
  allowFullScreen = true
}) => {
  const classes = useIFrameButtonStyles({ isMobile });
  return (
    <>
      <DefaultButton
        className={className}
        text={buttonText}
        StartIcon={StartIcon}
        onClick={onClick}
        type={TypeVariant.Text}
      />
      <Popup
        headerTitle={headerTitle}
        onClose={onClick}
        open={open}
        closableHeader
        childrenClassName={classes.popupContent}
        isMobile={isMobile}
      >
        <IFrame
          className={getClassName(classes.iframe, iframeClassName)}
          id={id}
          title={title}
          source={source}
          frameBorder={frameBorder}
          scrolling={scrolling}
          allowFullScreen={allowFullScreen}
        />
      </Popup>
    </>
  );
};
