import {
  FC,
  getClassName,
  TextVariant,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { ProfilePicture } from "components/profile/ProfilePicture/ProfilePicture";
import {
  TitleContentData,
  TitleContentDataTextItem
} from "components/text/TitleContentData";
import React from "react";
import { usePatientIdentificationDataContainerStyles } from "components/headers/PatientHeader/PatientIdentificationDataContainer/usePatientIdentificationDataContainerStyles";
import { notUndefined, Optional } from "@laba/ts-common";
import { ProfilePictureSizeVariant } from "components/profile/ProfilePicture";

interface Props {
  isMobile?: boolean;
  className?: string;
  firstName?: string;
  lastName?: string;
  profilePictureUrl?: string;
  patientID?: string;
  ageText?: string;
  birthDateText?: string;
  multiline?: boolean;
}

const getNameData = (
  firstName?: string,
  lastName?: string
): TitleContentDataTextItem[] => {
  if (!firstName && !lastName) return [];
  return [
    {
      principalText: firstName ? `${lastName},` : lastName,
      secondaryText: firstName
    }
  ];
};

const getLabel = (
  patientId?: string,
  firstName?: string,
  lastName?: string
): Optional<string> => {
  const name = [lastName, firstName].filter(notUndefined).join(", ");
  return [patientId, name].filter(notUndefined).join("\n");
};

const getAgeAndBirthDateText = (ageText?: string, birthDateText?: string) => {
  return [ageText, birthDateText].filter(notUndefined).join(" | ");
};

export const PatientIdentificationDataContainer: FC<Props> = ({
  isMobile,
  className,
  firstName,
  lastName,
  profilePictureUrl,
  patientID,
  ageText,
  birthDateText,
  multiline = false
}) => {
  const classes = usePatientIdentificationDataContainerStyles();
  return (
    <div
      className={getClassName(
        className,
        isMobile ? classes.mobileRoot : classes.desktopRoot
      )}
    >
      <ProfilePicture
        className={classes.profilePicture}
        imageSource={profilePictureUrl}
        firstName={firstName}
        lastName={lastName}
        size={ProfilePictureSizeVariant.Small}
      />
      <TitleContentData
        title={
          isMobile ? getAgeAndBirthDateText(ageText, birthDateText) : patientID
        }
        className={
          isMobile ? classes.mobileTitleContentData : classes.titleContentData
        }
        reverseOrder={isMobile}
        titleColorVariant={ThemeStyleColorVariant.GrayDark}
        textList={getNameData(firstName, lastName)}
        titleVariant={TextVariant.Caption}
        principalTextVariant={TextVariant.Subtitle2}
        secondaryTextVariant={TextVariant.Body2}
        maxLines={multiline ? undefined : 1}
        label={getLabel(patientID, firstName, lastName)}
      />
    </div>
  );
};
