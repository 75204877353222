import React from "react";
import { Children, getClassName } from "@laba/react-common";
import { useChatBubbleStyle } from "components/chat/Chat/ChatMessages/ChatMessage/ChatBubble/useChatBubbleStyle";
import { NexupColor } from "model/nexupColor";
import { ChatBubbleTail } from "components/chat/Chat/ChatMessages/ChatMessage/ChatBubble/ChatBubbleTail/ChatBubbleTail";
import { MessageOrigin } from "components/chat/Chat/ChatMessages/ChatMessage/ChatMessageComponent";

export enum TailPosition {
  Left = "left",
  Right = "right"
}

interface Props {
  className?: string;
  children: Children;
  messageOrigin?: MessageOrigin;
}

export const ChatBubble: React.FC<Props> = ({
  className,
  children,
  messageOrigin = MessageOrigin.Sent
}) => {
  const tailPosition =
    messageOrigin === MessageOrigin.Sent
      ? TailPosition.Right
      : TailPosition.Left;
  const classes = useChatBubbleStyle({ tailPosition, messageOrigin });
  return (
    <div className={getClassName(className, classes.root)}>
      {children}
      <ChatBubbleTail
        // TODO: background color must come from defaultTheme
        color={
          messageOrigin === MessageOrigin.Sent
            ? NexupColor.LightPurple
            : NexupColor.Background
        }
        className={getClassName(
          classes.tail,
          tailPosition === TailPosition.Left
            ? classes.tailLeft
            : classes.tailRight
        )}
      />
    </div>
  );
};
