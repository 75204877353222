import { Noop, Optional } from "@laba/ts-common";
import { useEffectSelective } from "hooks/useEffectSelective";
import { findIndex, size } from "lodash-es";
import { FieldValidator } from "forms/types/FieldValidator";
import { CustomFieldRenderProps } from "./types/CustomFieldRenderProps";
import { useField } from "./useField";

export const useFieldElement = <T, V, E = string>(
  listFormKey: string,
  condition: (element: T) => boolean,
  defaultValue: T,
  listElementFormKey?: string,
  validate?: FieldValidator<Optional<V>, E>,
  beforeSubmit?: Noop
): CustomFieldRenderProps<Optional<V>, E> => {
  const {
    input: { value: elementList, onChange: setElement }
  } = useField<T[]>(listFormKey);
  const elementIndex = findIndex(elementList, condition);
  const hasData = elementIndex !== -1;
  useEffectSelective(
    () => {
      if (!hasData) {
        setElement([...(elementList ?? []), defaultValue]);
      }
    },
    [hasData],
    [setElement, defaultValue, elementList]
  );
  const index = elementIndex !== -1 ? elementIndex : size(elementList);
  return useField<V, E>(
    `${listFormKey}[${index}]${
      listElementFormKey ? `.${listElementFormKey}` : ""
    }`,
    validate,
    beforeSubmit
  );
};
