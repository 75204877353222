import {
  isArray as lodashIsArray,
  isBoolean,
  isEmpty,
  isNil,
  isPlainObject,
  isString as lodashIsString
} from "lodash-es";

export type Optional<T> = T | undefined;

export type ElementOrArray<T> = T | T[];

export type GenericObject = Record<string, unknown>;

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export type DeepRequired<T> = T extends object
  ? {
      [P in keyof T]-?: NonNullable<DeepRequired<T[P]>>;
    }
  : T;

export const notUndefined = <T>(x: Optional<T>): x is T => x !== undefined;

export const notEmpty = <T>(x: Optional<T>): x is T => !isEmpty(x);

export const notNull = <T>(x: Optional<T>): x is T => x !== null;

export const notFalsy = <T>(x: Optional<T>): x is T => Boolean(x);

export const isInteger = (n: number, epsilon = 1e-6): boolean =>
  Math.abs(Math.round(n) - n) < epsilon;

export const isObject = (value: unknown): value is GenericObject =>
  isPlainObject(value);

export const isArray = (value: unknown): value is unknown[] =>
  lodashIsArray(value);

export const isString = (value: unknown): value is string =>
  lodashIsString(value);

export const getAsArray = <T>(elementOrArray?: ElementOrArray<T>): T[] =>
  isNil(elementOrArray)
    ? []
    : isArray(elementOrArray)
    ? elementOrArray
    : [elementOrArray];

export const getAsArrayOrUndefined = <T>(
  elementOrArray?: ElementOrArray<T>
): Optional<T[]> =>
  isNil(elementOrArray) ? undefined : getAsArray<T>(elementOrArray);

export const getBooleanFromStr = (s: string): boolean => s === "true";
export const getBooleanFromStrOrUndefined = (
  s?: string | boolean
): Optional<boolean> => {
  if (isBoolean(s)) return Boolean(s);
  if (s === undefined || isEmpty(s)) return undefined;
  return getBooleanFromStr(s);
};

export const getTernaryFromStr = (value?: string): Optional<boolean> =>
  value === undefined || value === "undefined"
    ? undefined
    : getBooleanFromStr(value);

export type PromiseArg<T> = T extends PromiseLike<infer U> ? U : T;

export type ArrayArg<T> = T extends (infer U)[] ? U : T;

export const isArrayOfType = <T>(
  array: unknown[],
  check: (x: unknown) => x is T
): array is T[] => array.every(check);

// see caveat: https://stackoverflow.com/questions/30469261/checking-for-typeof-error-in-js
export const isExceptionError = (value: unknown): value is Error =>
  value instanceof Error;

export const isNumberZeroOrUndefined = (v?: number): v is 0 | undefined =>
  v === undefined || v === 0;
