import { FC, IconC, OnClickEvent } from "@laba/react-common";
import { Button } from "@material-ui/core";
import React from "react";
import { SizeVariant, StyleVariant, TypeVariant } from "model/themeVariant";
import { getMapValueBuilder } from "@laba/ts-common";
import { ThemeProvider } from "@material-ui/core/styles";
import { useMuiTheme } from "model/useMuiTheme";
import { useDefaultButtonStylesClasses } from "./DefaultButtonStyle";

export enum ButtonType {
  Submit = "submit",
  Reset = "reset",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Button = "button"
}

export interface DefaultButtonProps {
  className?: string;
  disabled?: boolean;
  title?: string;
  text?: string;
  StartIcon?: IconC;
  onClick?: OnClickEvent;
  size?: SizeVariant;
  style?: StyleVariant;
  type?: TypeVariant;
  fullWidth?: boolean;
  buttonType?: ButtonType;
  buttonRef?: React.Ref<HTMLButtonElement>;
  formId?: string;
  hide?: boolean;
}

const getMaterialType = getMapValueBuilder<
  TypeVariant,
  "text" | "outlined" | "contained"
>({
  [TypeVariant.Contained]: "contained",
  [TypeVariant.Outlined]: "outlined",
  [TypeVariant.Text]: "text",
  [TypeVariant.Inverted]: "text"
});

export const DefaultButton: FC<DefaultButtonProps> = ({
  className,
  title,
  text,
  StartIcon,
  onClick,
  buttonRef,
  disabled = false,
  type = TypeVariant.Contained,
  size = SizeVariant.Medium,
  style = StyleVariant.Primary,
  fullWidth = false,
  buttonType = ButtonType.Button,
  formId,
  hide = false
}) => {
  const classes = useDefaultButtonStylesClasses({
    style,
    size,
    type,
    fullWidth,
    disabled
  });
  const muiTheme = useMuiTheme(style);
  return !hide ? (
    <ThemeProvider theme={muiTheme}>
      <Button
        classes={classes}
        disabled={disabled}
        className={className}
        title={title}
        startIcon={StartIcon && <StartIcon />}
        onClick={onClick}
        fullWidth={fullWidth}
        variant={getMaterialType(type)}
        type={buttonType}
        color="primary"
        ref={buttonRef}
        form={formId}
      >
        {text}
      </Button>
    </ThemeProvider>
  ) : null;
};
