import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  Theme
} from "@laba/react-common";

interface Props {
  gap?: number;
  centerText?: boolean;
}

export const useLinkFieldDetailDynamicStyles = createStyle(
  (_theme: Theme) => ({
    root: (props: Props) => ({
      ...flexColumnMixin({ gap: props.gap ?? 4 })
    }),
    rootCentered: (props: Props) => ({
      ...flexColumnMixin({
        gap: props.gap ?? 4,
        alignItems: FlexAlignItems.Center
      })
    })
  }),
  "LinkFieldDetailStyles"
);

interface ClassNames {
  root: string;
}

export const useLinkFieldDetailStyles = (props: Props): ClassNames => {
  const classes = useLinkFieldDetailDynamicStyles(props);
  return {
    root: props.centerText ? classes.rootCentered : classes.root
  };
};
