import { Theme, ThemeStyleColorVariant } from "model/theme";
import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  flexItemMixin,
  marginMixin,
  paddingMixin
} from "@laba/react-common";

import { StyleVariant, TextVariant } from "model/themeVariant";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

export const useFullScreenCardStyle = createStyle(
  (theme: Theme) => ({
    container: {
      ...paddingMixin({ leftRight: 24, topBottom: 48 }),
      ...flexColumnMixin({ alignItems: FlexAlignItems.Stretch, gap: 24 }),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    },
    title: {
      ...themeTextMixin(theme, TextVariant.Subtitle2, 1),
      ...flexItemMixin({ shrink: 0 }),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    buttonsContainer: {
      ...flexColumnMixin({ gap: 12 }),
      ...marginMixin({ top: "auto" })
    },
    childrenContainer: {
      width: "100%"
    }
  }),
  "FullScreenCard"
);
