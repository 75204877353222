import { includes, isEmpty } from "lodash-es";
import { getEnumOrUndefined, Optional } from "@laba/ts-common";
import { Odontogram } from "./odontogram";
import {
  AdultOdontogramToothCode,
  ChildrenOdontogramToothCode,
  OdontogramToothCodeType,
  OdontogramToothSurface
} from "./codes";
import { KnownOdontogramTypeCodes } from "../observation";

export interface OdontogramToothGroup {
  first: OdontogramToothCodeType[];
  second: OdontogramToothCodeType[];
  third: OdontogramToothCodeType[];
  fourth: OdontogramToothCodeType[];
}

export const AdultToothGroup: OdontogramToothGroup = {
  first: [
    AdultOdontogramToothCode.T1_8,
    AdultOdontogramToothCode.T1_7,
    AdultOdontogramToothCode.T1_6,
    AdultOdontogramToothCode.T1_5,
    AdultOdontogramToothCode.T1_4,
    AdultOdontogramToothCode.T1_3,
    AdultOdontogramToothCode.T1_2,
    AdultOdontogramToothCode.T1_1
  ],
  second: [
    AdultOdontogramToothCode.T2_1,
    AdultOdontogramToothCode.T2_2,
    AdultOdontogramToothCode.T2_3,
    AdultOdontogramToothCode.T2_4,
    AdultOdontogramToothCode.T2_5,
    AdultOdontogramToothCode.T2_6,
    AdultOdontogramToothCode.T2_7,
    AdultOdontogramToothCode.T2_8
  ],
  third: [
    AdultOdontogramToothCode.T3_1,
    AdultOdontogramToothCode.T3_2,
    AdultOdontogramToothCode.T3_3,
    AdultOdontogramToothCode.T3_4,
    AdultOdontogramToothCode.T3_5,
    AdultOdontogramToothCode.T3_6,
    AdultOdontogramToothCode.T3_7,
    AdultOdontogramToothCode.T3_8
  ],
  fourth: [
    AdultOdontogramToothCode.T4_8,
    AdultOdontogramToothCode.T4_7,
    AdultOdontogramToothCode.T4_6,
    AdultOdontogramToothCode.T4_5,
    AdultOdontogramToothCode.T4_4,
    AdultOdontogramToothCode.T4_3,
    AdultOdontogramToothCode.T4_2,
    AdultOdontogramToothCode.T4_1
  ]
};

export const ChildToothGroup: OdontogramToothGroup = {
  first: [
    ChildrenOdontogramToothCode.T5_5,
    ChildrenOdontogramToothCode.T5_4,
    ChildrenOdontogramToothCode.T5_3,
    ChildrenOdontogramToothCode.T5_2,
    ChildrenOdontogramToothCode.T5_1
  ],
  second: [
    ChildrenOdontogramToothCode.T6_1,
    ChildrenOdontogramToothCode.T6_2,
    ChildrenOdontogramToothCode.T6_3,
    ChildrenOdontogramToothCode.T6_4,
    ChildrenOdontogramToothCode.T6_5
  ],
  third: [
    ChildrenOdontogramToothCode.T7_1,
    ChildrenOdontogramToothCode.T7_2,
    ChildrenOdontogramToothCode.T7_3,
    ChildrenOdontogramToothCode.T7_4,
    ChildrenOdontogramToothCode.T7_5
  ],
  fourth: [
    ChildrenOdontogramToothCode.T8_5,
    ChildrenOdontogramToothCode.T8_4,
    ChildrenOdontogramToothCode.T8_3,
    ChildrenOdontogramToothCode.T8_2,
    ChildrenOdontogramToothCode.T8_1
  ]
};

const AdultOdontogramAnteriorToothGroup = [
  AdultOdontogramToothCode.T1_1,
  AdultOdontogramToothCode.T1_2,
  AdultOdontogramToothCode.T1_3,
  AdultOdontogramToothCode.T2_1,
  AdultOdontogramToothCode.T2_2,
  AdultOdontogramToothCode.T2_3,
  AdultOdontogramToothCode.T3_1,
  AdultOdontogramToothCode.T3_2,
  AdultOdontogramToothCode.T3_3,
  AdultOdontogramToothCode.T4_1,
  AdultOdontogramToothCode.T4_2,
  AdultOdontogramToothCode.T4_3
];

const ChildrenOdontogramAnteriorToothGroup = [
  ChildrenOdontogramToothCode.T5_1,
  ChildrenOdontogramToothCode.T5_2,
  ChildrenOdontogramToothCode.T5_3,
  ChildrenOdontogramToothCode.T6_1,
  ChildrenOdontogramToothCode.T6_2,
  ChildrenOdontogramToothCode.T6_3,
  ChildrenOdontogramToothCode.T7_1,
  ChildrenOdontogramToothCode.T7_2,
  ChildrenOdontogramToothCode.T7_3,
  ChildrenOdontogramToothCode.T8_1,
  ChildrenOdontogramToothCode.T8_2,
  ChildrenOdontogramToothCode.T8_3
];

const AdultOdontogramPosteriorToothGroup = [
  AdultOdontogramToothCode.T1_4,
  AdultOdontogramToothCode.T1_5,
  AdultOdontogramToothCode.T1_6,
  AdultOdontogramToothCode.T1_7,
  AdultOdontogramToothCode.T1_8,
  AdultOdontogramToothCode.T2_4,
  AdultOdontogramToothCode.T2_5,
  AdultOdontogramToothCode.T2_6,
  AdultOdontogramToothCode.T2_7,
  AdultOdontogramToothCode.T2_8,
  AdultOdontogramToothCode.T3_4,
  AdultOdontogramToothCode.T3_5,
  AdultOdontogramToothCode.T3_6,
  AdultOdontogramToothCode.T3_7,
  AdultOdontogramToothCode.T3_8,
  AdultOdontogramToothCode.T4_4,
  AdultOdontogramToothCode.T4_5,
  AdultOdontogramToothCode.T4_6,
  AdultOdontogramToothCode.T4_7,
  AdultOdontogramToothCode.T4_8
];

const ChildrenOdontogramPosteriorToothGroup = [
  ChildrenOdontogramToothCode.T5_4,
  ChildrenOdontogramToothCode.T5_5,
  ChildrenOdontogramToothCode.T6_4,
  ChildrenOdontogramToothCode.T6_5,
  ChildrenOdontogramToothCode.T7_4,
  ChildrenOdontogramToothCode.T7_5,
  ChildrenOdontogramToothCode.T8_4,
  ChildrenOdontogramToothCode.T8_5
];

export const isChildToothCode = (tooth: OdontogramToothCodeType): boolean => {
  return includes(ChildrenOdontogramToothCode, tooth);
};

const isToothPosterior = (tooth: OdontogramToothCodeType): boolean => {
  return (
    AdultOdontogramPosteriorToothGroup.some(
      posteriorTooth => posteriorTooth === tooth
    ) ||
    ChildrenOdontogramPosteriorToothGroup.some(
      posteriorTooth => posteriorTooth === tooth
    )
  );
};

const isToothAnterior = (tooth: OdontogramToothCodeType): boolean => {
  return (
    AdultOdontogramAnteriorToothGroup.some(
      anteriorTooth => anteriorTooth === tooth
    ) ||
    ChildrenOdontogramAnteriorToothGroup.some(
      anteriorTooth => anteriorTooth === tooth
    )
  );
};

export const isToothListPosterior = (
  toothList?: OdontogramToothCodeType[]
): boolean =>
  (isEmpty(toothList) ? false : toothList?.every(isToothPosterior)) ?? false;

export const isToothListAnterior = (
  toothList?: OdontogramToothCodeType[]
): boolean =>
  isEmpty(toothList) ? false : toothList?.every(isToothAnterior) ?? false;

export const getOdontogramType = (
  odontogram?: Odontogram
): Optional<KnownOdontogramTypeCodes> =>
  getEnumOrUndefined(KnownOdontogramTypeCodes)(odontogram?.type);

const PositionSurfaceList11 = [
  OdontogramToothSurface.Buccal,
  OdontogramToothSurface.Palatine,
  OdontogramToothSurface.Distal,
  OdontogramToothSurface.Mesial,
  OdontogramToothSurface.Incisal
];

const PositionSurfaceList14 = [
  OdontogramToothSurface.Buccal,
  OdontogramToothSurface.Palatine,
  OdontogramToothSurface.Distal,
  OdontogramToothSurface.Mesial,
  OdontogramToothSurface.Occlusal
];

const PositionSurfaceList21 = [
  OdontogramToothSurface.Buccal,
  OdontogramToothSurface.Palatine,
  OdontogramToothSurface.Distal,
  OdontogramToothSurface.Mesial,
  OdontogramToothSurface.Incisal
];

const PositionSurfaceList24 = [
  OdontogramToothSurface.Buccal,
  OdontogramToothSurface.Palatine,
  OdontogramToothSurface.Distal,
  OdontogramToothSurface.Mesial,
  OdontogramToothSurface.Occlusal
];

const PositionSurfaceList31 = [
  OdontogramToothSurface.Buccal,
  OdontogramToothSurface.Lingual,
  OdontogramToothSurface.Distal,
  OdontogramToothSurface.Mesial,
  OdontogramToothSurface.Incisal
];

const PositionSurfaceList34 = [
  OdontogramToothSurface.Buccal,
  OdontogramToothSurface.Lingual,
  OdontogramToothSurface.Distal,
  OdontogramToothSurface.Mesial,
  OdontogramToothSurface.Occlusal
];

const PositionSurfaceList41 = [
  OdontogramToothSurface.Buccal,
  OdontogramToothSurface.Lingual,
  OdontogramToothSurface.Distal,
  OdontogramToothSurface.Mesial,
  OdontogramToothSurface.Incisal
];

const PositionSurfaceList44 = [
  OdontogramToothSurface.Buccal,
  OdontogramToothSurface.Lingual,
  OdontogramToothSurface.Distal,
  OdontogramToothSurface.Mesial,
  OdontogramToothSurface.Occlusal
];

const ToothSurfaceMap: Record<
  OdontogramToothCodeType,
  OdontogramToothSurface[]
> = {
  // Adult surfaces
  [AdultOdontogramToothCode.T1_1]: PositionSurfaceList11,
  [AdultOdontogramToothCode.T1_2]: PositionSurfaceList11,
  [AdultOdontogramToothCode.T1_3]: PositionSurfaceList11,
  [AdultOdontogramToothCode.T1_4]: PositionSurfaceList14,
  [AdultOdontogramToothCode.T1_5]: PositionSurfaceList14,
  [AdultOdontogramToothCode.T1_6]: PositionSurfaceList14,
  [AdultOdontogramToothCode.T1_7]: PositionSurfaceList14,
  [AdultOdontogramToothCode.T1_8]: PositionSurfaceList14,
  [AdultOdontogramToothCode.T2_1]: PositionSurfaceList21,
  [AdultOdontogramToothCode.T2_2]: PositionSurfaceList21,
  [AdultOdontogramToothCode.T2_3]: PositionSurfaceList21,
  [AdultOdontogramToothCode.T2_4]: PositionSurfaceList24,
  [AdultOdontogramToothCode.T2_5]: PositionSurfaceList24,
  [AdultOdontogramToothCode.T2_6]: PositionSurfaceList24,
  [AdultOdontogramToothCode.T2_7]: PositionSurfaceList24,
  [AdultOdontogramToothCode.T2_8]: PositionSurfaceList24,
  [AdultOdontogramToothCode.T3_1]: PositionSurfaceList31,
  [AdultOdontogramToothCode.T3_2]: PositionSurfaceList31,
  [AdultOdontogramToothCode.T3_3]: PositionSurfaceList31,
  [AdultOdontogramToothCode.T3_4]: PositionSurfaceList34,
  [AdultOdontogramToothCode.T3_5]: PositionSurfaceList34,
  [AdultOdontogramToothCode.T3_6]: PositionSurfaceList34,
  [AdultOdontogramToothCode.T3_7]: PositionSurfaceList34,
  [AdultOdontogramToothCode.T3_8]: PositionSurfaceList34,
  [AdultOdontogramToothCode.T4_1]: PositionSurfaceList41,
  [AdultOdontogramToothCode.T4_2]: PositionSurfaceList41,
  [AdultOdontogramToothCode.T4_3]: PositionSurfaceList41,
  [AdultOdontogramToothCode.T4_4]: PositionSurfaceList44,
  [AdultOdontogramToothCode.T4_5]: PositionSurfaceList44,
  [AdultOdontogramToothCode.T4_6]: PositionSurfaceList44,
  [AdultOdontogramToothCode.T4_7]: PositionSurfaceList44,
  [AdultOdontogramToothCode.T4_8]: PositionSurfaceList44,

  // Children surfaces
  [ChildrenOdontogramToothCode.T5_1]: PositionSurfaceList11,
  [ChildrenOdontogramToothCode.T5_2]: PositionSurfaceList11,
  [ChildrenOdontogramToothCode.T5_3]: PositionSurfaceList11,
  [ChildrenOdontogramToothCode.T5_4]: PositionSurfaceList14,
  [ChildrenOdontogramToothCode.T5_5]: PositionSurfaceList14,
  [ChildrenOdontogramToothCode.T6_1]: PositionSurfaceList21,
  [ChildrenOdontogramToothCode.T6_2]: PositionSurfaceList21,
  [ChildrenOdontogramToothCode.T6_3]: PositionSurfaceList21,
  [ChildrenOdontogramToothCode.T6_4]: PositionSurfaceList24,
  [ChildrenOdontogramToothCode.T6_5]: PositionSurfaceList24,
  [ChildrenOdontogramToothCode.T7_1]: PositionSurfaceList31,
  [ChildrenOdontogramToothCode.T7_2]: PositionSurfaceList31,
  [ChildrenOdontogramToothCode.T7_3]: PositionSurfaceList31,
  [ChildrenOdontogramToothCode.T7_4]: PositionSurfaceList34,
  [ChildrenOdontogramToothCode.T7_5]: PositionSurfaceList34,
  [ChildrenOdontogramToothCode.T8_1]: PositionSurfaceList41,
  [ChildrenOdontogramToothCode.T8_2]: PositionSurfaceList41,
  [ChildrenOdontogramToothCode.T8_3]: PositionSurfaceList41,
  [ChildrenOdontogramToothCode.T8_4]: PositionSurfaceList44,
  [ChildrenOdontogramToothCode.T8_5]: PositionSurfaceList44
};

export const getSurfaceListFromToothCode = (
  tooth: OdontogramToothCodeType
): OdontogramToothSurface[] => {
  return ToothSurfaceMap[tooth];
};
