import {
  createStyle,
  flexColumnMixin,
  FlexJustifyContent,
  flexRowMixin,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

interface TagWithPeriodListStylesProps {
  emptyListTextVariant?: TextVariant;
  emptyListTextColorVariant?: ThemeStyleColorVariant;
}

const useTagWithPeriodListStaticStyles = createStyle(
  (_theme: Theme) => ({
    root: {
      ...flexColumnMixin({
        gap: 8,
        justifyContent: FlexJustifyContent.FlexStart
      })
    },
    tagListWrapper: {
      ...flexRowMixin({ gap: 8, wrapContent: true })
    }
  }),
  "TagWithPeriodListStatic"
);

const useTagWithPeriodListDynamicStyles = createStyle(
  (theme: Theme) => ({
    emptyListText: (props: TagWithPeriodListStylesProps) => ({
      ...themeTextMixin(
        theme,
        props.emptyListTextVariant ?? TextVariant.Caption,
        1
      ),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        props.emptyListTextColorVariant ?? ThemeStyleColorVariant.GrayDark
      )
    })
  }),
  "TagWithPeriodListDynamic"
);

interface ClassNames {
  root: string;
  tagListWrapper: string;
  emptyListText: string;
}

export const useTagWithPeriodListStyles = (
  props: TagWithPeriodListStylesProps
): ClassNames => {
  const { root, tagListWrapper } = useTagWithPeriodListStaticStyles();
  const { emptyListText } = useTagWithPeriodListDynamicStyles(props);

  return {
    root,
    tagListWrapper,
    emptyListText
  };
};
