import { FileExtension } from "file-type/core";
import { getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { ResourceModel, ResourceType } from "../resourceModel";

export interface Attachment extends ResourceModel<ResourceType.Attachment> {
  url: string;
  name: string;
  external?: boolean;
}

export enum SpecialAttachmentType {
  Audio = "audio/*",
  Image = "image/*",
  Video = "video/*"
}

export type ValidAttachmentType = SpecialAttachmentType | FileExtension;

export const AttachmentKey = getKeyObj(createHydratedMock<Attachment>());
