import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  getModelReferenceId,
  LocationKey,
  ModelReference,
  Organization,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { ReportConfigInput } from "components/generic/ReportConfigInput/ReportConfigInput";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useSelector } from "react-redux";

export const tk = tkCP.adminPage[ResourceType.Location];
export const LocationReportContent: FC = () => {
  const { t } = useI18n();
  const locationOrganization = useWatchResourceForm<
    ModelReference<Organization>
  >(LocationKey.organization);
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organizationId =
    getModelReferenceId(locationOrganization) || workspaceOrganizationId;
  return (
    <ReportConfigInput
      source={LocationKey.reportConfig}
      label={t(tk.fields.reportConfig)}
      organization={organizationId}
    />
  );
};
