import { Optional } from "model/types";
import { getPhoneCode } from "libphonenumber-js";
import { CountryAreaPhoneCode } from "phone/countryAreaPhoneCode";
import { PhoneLibCountryCode } from "phone/types";

export const getCountryPhonePrefix = (
  countryCode?: CountryAreaPhoneCode
): Optional<string> => {
  if (!countryCode) return undefined;
  return getPhoneCode(countryCode as PhoneLibCountryCode);
};
