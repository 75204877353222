import {
  getEnumOrderParam,
  getKeyTargetParam,
  resourceProviderGetter
} from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { workspaceOrganizationSelector } from "store/workspace/selectors";
import { store } from "store/store";
import {
  getModelReferenceId,
  ResourceType,
  createScheduleDefinition,
  editScheduleDefinition,
  getScheduleDefinition,
  getScheduleDefinitionList,
  ScheduleDefinitionListQueryParams,
  ScheduleListDefinitionQueryParamsKey
} from "@laba/nexup-api";

export const scheduleDefinitionProvider = resourceProviderGetter(
  ResourceType.ScheduleDefinition,
  getEnumOrderParam({}),
  getKeyTargetParam<ScheduleDefinitionListQueryParams>(
    ScheduleListDefinitionQueryParamsKey
  ),
  getScheduleDefinitionList,
  getScheduleDefinition,
  editScheduleDefinition,
  createScheduleDefinition,
  undefined,
  () => ({
    organization: getModelReferenceId(
      workspaceOrganizationSelector(store.getState())
    )
  })
);
