import {
  Appointment,
  AppointmentCancellationReasonCode,
  AppointmentStatus
} from "model/resource/appointment/appointment";
import { ResourceType } from "model/primitives/resourceModel";
import { Gender, ValidationStatus } from "model/resource/person/person";
import { DateTime } from "@laba/ts-common";
import { OrganizationType } from "model/resource/entities/organization/organization";
import { LocationStatus } from "model/resource/entities/location/location";
import { KnownScheduleType } from "model/resource/schedule/schedule";

export const getAppointmentMock = (
  firstName: string,
  lastName: string,
  service: string,
  reason: string,
  provider: string,
  date: DateTime,
  overbooked: boolean,
  status: AppointmentStatus
): Appointment => ({
  resourceType: ResourceType.Appointment,
  id: "10",
  creationDate: "2021-10-13T10:05:06.651-03",
  status,
  appointmentDefinition: "10",
  healthcareService: {
    organization: "1",
    location: [],
    templateList: [],
    resourceType: ResourceType.HealthcareService,
    name: service
  },
  consultationReason: {
    name: reason,
    code: reason,
    system: "mockedConsultationReasonSystem"
  },
  patient: {
    resourceType: ResourceType.Patient,
    organization: "1",
    personalData: {
      firstName,
      lastName,
      gender: Gender.Male
    },
    id: "1833",
    patientData: {
      healthcarePayer: {
        provider: {
          resourceType: ResourceType.Organization,
          type: OrganizationType.Payer,
          name: provider
        }
      }
    },
    validationStatus: ValidationStatus.Temporal
  },
  practitioner: {
    resourceType: ResourceType.Practitioner,
    id: "32",
    federationId: "e61c0075-a750-48f0-93a7-a3319b5a0233",
    personalData: {
      firstName: "Ivan",
      otherName: "",
      lastName: "Di vito",
      gender: Gender.Unknown,
      identifierList: []
    },
    contactData: {
      phoneList: [],
      emailList: [],
      addressList: []
    },
    validationStatus: ValidationStatus.Temporal,
    organization: ["24"],
    creationDate: "2021-10-12T18:39:35.195Z"
  },
  startDate: date.toISO(),
  endDate: date.plus({ minute: 30 }).toISO(),
  minutesDuration: 30,
  overbooked,
  notificationChannelList: [
    { contactPoint: { resourceType: ResourceType.Phone, value: "1155005500" } },
    {
      contactPoint: {
        resourceType: ResourceType.Email,
        value: "mock@appointment.com"
      }
    }
  ],
  patientAccessInfo: { link: "https://meet.google.com/", hash: "123" },
  location: {
    resourceType: ResourceType.Location,
    name: "Amenabar",
    status: LocationStatus.Active
  },
  schedule: {
    resourceType: ResourceType.Schedule,
    active: true,
    identifier: [],
    organization: "10",
    name: "Nombre de la Agenda Juan Carlos - Santa Catalina",
    type: KnownScheduleType.Presential,
    speciality: ["Cardiología"]
  },
  speciality: ["Prueba"],
  practitionerTeam: [
    {
      practitioner: {
        resourceType: ResourceType.Practitioner,
        organization: ["10"],
        personalData: {
          firstName: "Ivan",
          otherName: "",
          lastName: "Di vito",
          gender: Gender.Unknown,
          identifierList: []
        },
        validationStatus: ValidationStatus.Temporal
      }
    },
    {
      practitioner: {
        resourceType: ResourceType.Practitioner,
        organization: ["10"],
        personalData: {
          firstName: "Nicolas",
          otherName: "",
          lastName: "Zorzano",
          gender: Gender.Unknown,
          identifierList: []
        },
        validationStatus: ValidationStatus.Temporal
      }
    }
  ],
  recurrenceTemplate: {
    lastOccurrenceDate: "2023-10-12T18:39:35.195Z",
    occurrenceCount: 6,
    weekInterval: 2
  },
  attachment: [
    {
      resourceType: ResourceType.Attachment,
      name: "zordon.jpg",
      url: "https://development.nexup.com.ar/api/files/71aecd1a624a443e8d90e1c0be9fe36d"
    },
    {
      resourceType: ResourceType.Attachment,
      name: "zordon.jpg",
      url: "https://development.nexup.com.ar/api/files/71aecd1a624a443e8d90e1c0be9fe36d"
    }
  ],
  cancellationReason: AppointmentCancellationReasonCode.PractitionerCancelled,
  tagList: [
    {
      code: "Test code",
      system:
        "http://platform.lab-a.com.ar/fhir/StructureDefinition/appointment-tag"
    },
    {
      code: "carlose",
      system:
        "http://platform.lab-a.com.ar/fhir/StructureDefinition/appointment-tag"
    },
    {
      code: "Eduardo",
      system:
        "http://platform.lab-a.com.ar/fhir/StructureDefinition/appointment-tag"
    }
  ],
  comment:
    "Nuestro sistema de Salud Pública sigue un programa promocional de salud, preventivo y rehabilitatorio totalmente gratuito y en constante desarrollo, cuyas primeras vías de contacto son el médico y la enfermera de la familia, el policlínico y el centro comunitario de salud mental, que asumen la orientación y terapia integral de los pacientes con el respaldo, según la evolución, de servicios hospitalarios y centros especializados, y después están los grupos de autoayuda GAMA, del Ministerio de Salud Pública, y otros igualmente prestigiosos y valiosos como Alcohólicos Anónimos.",
  patientInstruction:
    "Informar si se ha recibido trasplante de médula ósea o transfusión sanguínea en los últimos 6 meses."
});

export const getAppointmentMockList = (date: DateTime): Appointment[] => [
  getAppointmentMock(
    "Juan Ignacio",
    "Ramon Peralta",
    "Clínica Médica",
    "Pedido de recetas",
    "PAMI",
    date.startOf("day").set({ hour: 14, minute: 30 }),
    false,
    AppointmentStatus.Booked
  ),
  getAppointmentMock(
    "Valentina",
    "Macia",
    "Cardiología",
    "Consulta general",
    "OSDE",
    date.startOf("day").set({ hour: 15, minute: 30 }),
    false,
    AppointmentStatus.Booked
  ),
  getAppointmentMock(
    "Andres",
    "Rocca",
    "Clínica Médica",
    "Fiebre",
    "Particular",
    date.startOf("day").set({ hour: 16, minute: 0 }),
    false,
    AppointmentStatus.Booked
  ),
  getAppointmentMock(
    "Lauro",
    "Pavon",
    "Pediatría",
    "Dolor de garganta",
    "PAMI",
    date.startOf("day").set({ hour: 16, minute: 15 }),
    true,
    AppointmentStatus.Booked
  ),
  getAppointmentMock(
    "Cinthia",
    "Reyes",
    "Cardiología",
    "Hipertensión",
    "OSDE",
    date.startOf("day").set({ hour: 20, minute: 0 }),
    false,
    AppointmentStatus.Booked
  ),
  getAppointmentMock(
    "Valentina",
    "Suarez",
    "Cardiología",
    "Dolor de pecho",
    "Particular",
    date.startOf("day").set({ hour: 7, minute: 0 }),
    false,
    AppointmentStatus.Fulfilled
  ),
  getAppointmentMock(
    "Lucio",
    "Aranda",
    "Clínica Médica",
    "Vómitos",
    "PAMI",
    date.startOf("day").set({ hour: 8, minute: 30 }),
    false,
    AppointmentStatus.Fulfilled
  ),
  getAppointmentMock(
    "Daniel",
    "Orieta",
    "Pediatría",
    "Tos",
    "OSDE",
    date.startOf("day").set({ hour: 9, minute: 0 }),
    false,
    AppointmentStatus.Fulfilled
  ),
  getAppointmentMock(
    "Edith",
    "Lugones",
    "Pediatría",
    "Consulta general",
    "PAMI",
    date.startOf("day").set({ hour: 17, minute: 30 }),
    false,
    AppointmentStatus.Cancelled
  ),
  getAppointmentMock(
    "Armando",
    "Landriel",
    "Clínica Médica",
    "Pedido de recetas",
    "Particular",
    date.startOf("day").set({ hour: 19, minute: 0 }),
    false,
    AppointmentStatus.Cancelled
  )
];

export const bookedAppointmentMock = getAppointmentMock(
  "Cinthia",
  "Reyes",
  "Cardiología",
  "Hipertensión",
  "OSDE",
  DateTime.fromISO("2023-05-20").set({ hour: 20, minute: 0 }),
  false,
  AppointmentStatus.Booked
);

export const cancelledAppointmentMock = getAppointmentMock(
  "Armando",
  "Landriel",
  "Clínica Médica",
  "Pedido de recetas",
  "Particular",
  DateTime.fromISO("2020-10-10").set({ hour: 19, minute: 0 }),
  false,
  AppointmentStatus.Cancelled
);

export const fulfilledAppointmentMock = getAppointmentMock(
  "Daniel",
  "Orieta",
  "Pediatría",
  "Tos",
  "OSDE",
  DateTime.fromISO("2020-10-10").set({ hour: 9, minute: 0 }),
  false,
  AppointmentStatus.Fulfilled
);
