import { Model, OpenCode } from "model/primitives/model/model";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { ApiDate, getKeyObj } from "@laba/ts-common";
import { ResourceModel, ResourceType } from "model/primitives/resourceModel";
import { Practitioner } from "model/resource/person/practitioner/practitioner";
import { HealthcareService } from "model/resource/entities/healthcareService/healthcareService";
import { Appointment } from "model/resource/appointment/appointment";
import { createHydratedMock } from "ts-auto-mock";
import { DiagnosisContainer } from "model/resource/medical/condition/diagnosis";
import { Organization } from "model/resource/entities/organization/organization";
import {
  Code,
  CodeSystemCode,
  KnownCodeSystemSystem
} from "model/resource/entities/codeSystem";
import { Patient } from "model/resource/person/patient/patient";
import { Period } from "model/resource/utils";
import { Identifier } from "model/primitives/identifier";
import { Attachment } from "model/primitives";
import { Location, LocationType } from "../location/location";

export enum EncounterClass {
  Ambulatory = "AMB",
  Inpatient = "IMP",
  VirtualGuard = "VRG",
  VideoAppointment = "VA",
  InPersonEncounter = "IPE"
}

export enum EncounterStatus {
  Planned = "planned",
  InProgress = "in-progress", // el único estado que corresponde a que esté internado
  Finished = "finished",
  Cancelled = "cancelled",
  Arrived = "arrived",
  Triaged = "triaged"
}

export enum KnownEncounterService {
  Acute = "Acute",
  Pediatric = "Pediatric",
  Rehabilitation = "Rehabilitation"
}

export type EncounterService = OpenCode<KnownEncounterService>;

export enum RoleCodes {
  GeneralPractitioner = "GeneralPractitioner",
  Physiatrist = "Physiatrist",
  Other = "Other",
  VirtualGuardPractitioner = "VirtualGuardPractitioner"
}

export enum EncounterLocationStatus {
  Planned = "planned",
  Active = "active",
  Reserved = "reserved",
  Completed = "completed"
}

export type EncounterParticipantRole = CodeSystemCode<
  KnownCodeSystemSystem.EncounterPractitionerType,
  OpenCode<RoleCodes>
>;

export interface EncounterParticipant extends Model {
  practitioner: ModelReference<Practitioner>;
  role: EncounterParticipantRole;
}

export interface EncounterLocation extends Model {
  location: ModelReference<Location>;
  status: EncounterLocationStatus;
  physicalType: LocationType;
  period?: Period;
}

export const EncounterParticipantKey = getKeyObj<EncounterParticipant>(
  createHydratedMock<EncounterParticipant>()
);

export enum CancellationReason {
  PatientCancelled = "PatientCancelled",
  PatientTriageCancelled = "PatientTriageCancelled",
  PractitionerCancelled = "PractitionerCancelled",
  PractitionerTriageCancelled = "PractitionerTriageCancelled"
}

export interface Encounter<
  RType extends ResourceType = ResourceType.Encounter,
  Class extends EncounterClass = EncounterClass
> extends ResourceModel<RType> {
  patient?: ModelReference<Patient>;
  service?: EncounterService;
  startDate?: ApiDate;
  finishDate?: ApiDate;
  status: EncounterStatus;
  practitionerTeam?: EncounterParticipant[];
  organization?: ModelReference<Organization>;
  class?: Class;
  hisCode?: string;
  hisType?: string;
  hisOrigin?: string;
  healthcareService?: ModelReference<HealthcareService>;
  cancelDate?: ApiDate;
  arrivedDate?: ApiDate;
  triageDate?: ApiDate;
  cancellationReason?: CodeSystemCode;
  consultationReason?: CodeSystemCode<KnownCodeSystemSystem.EncounterConsultationReason>;
  appointment?: ModelReference<Appointment>;
  diagnosis?: DiagnosisContainer[];
  location?: EncounterLocation[];
  identifier?: Identifier[];
  payer?: ModelReference<Organization>;
  plan?: string;
  observation?: string;
  file?: Attachment[];
  type?: string;
  originalPractitioner?: ModelReference<Practitioner>;
  lastEditor?: ModelReference<Practitioner>;
  statusReason?: Code;
}
