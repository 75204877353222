import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  getModelReferenceId,
  KnownCodeSystemSystem,
  ModelReference,
  Organization,
  OrganizationCombinedType,
  OrganizationListQueryParamsKey,
  AccountKey,
  ResourceType,
  Patient,
  PatientListQueryParamsKey,
  AccountStatus,
  AccountType
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import { patientOptionText } from "components/generic/ReferenceResourceInput/optionText";
import { IdentifierInput } from "components/generic/IdentifierInput/IdentifierInput";

export const tk = tkCP.adminPage[ResourceType.Account];
export const AccountMainContent: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organization = useWatchResourceForm<ModelReference<Organization>>(
    AccountKey.organization
  );
  const organizationId =
    getModelReferenceId(organization) || workspaceOrganizationId;

  return (
    <>
      <TextInput disabled source={AccountKey.id} label={t(tk.fields.id)} />
      <OrganizationReferenceInput
        source={AccountKey.organization}
        label={t(tk.fields.organization)}
        type={OrganizationCombinedType.ProviderAndGroup}
        extraFilters={{
          [OrganizationListQueryParamsKey.active]: true
        }}
        required
        defaultValue={workspaceOrganizationId}
        disabled={workspaceOrganizationId !== undefined}
      />
      <ReferenceResourceInput<Patient>
        resourceType={ResourceType.Patient}
        label={t(tk.fields.patient)}
        source={AccountKey.patient}
        optionTextKey={patientOptionText}
        extraFilters={{
          [PatientListQueryParamsKey.organization]: workspaceOrganizationId
        }}
      />
      <OrganizationReferenceInput
        source={AccountKey.payer}
        label={t(tk.fields.payer)}
        type={OrganizationCombinedType.Payer}
        extraFilters={{
          [OrganizationListQueryParamsKey.partOf]: organizationId,
          [OrganizationListQueryParamsKey.active]: true
        }}
      />
      <EnumSelectorInput
        source={AccountKey.status}
        label={t(tk.fields.status)}
        enumValue={AccountStatus}
        required
      />
      <EnumSelectorInput
        source={AccountKey.type}
        label={t(tk.fields.type)}
        enumValue={AccountType}
        required
      />
      <IdentifierInput
        source={AccountKey.identifier}
        organization={organizationId}
        system={KnownCodeSystemSystem.Identifier}
      />
      <TextInput source={AccountKey.name} label={t(tk.fields.name)} />
    </>
  );
};
