import { Sections } from "translation/constants";
import { loginPage } from "translation/common/page/login";
import { adminPage } from "translation/common/page/admin";
import { listFilters } from "translation/common/component/listFilters";
import { resourceAction } from "translation/common/component/resourceAction";
import { inputs } from "translation/common/component/inputs";

export const commonEs = {
  [Sections.Component]: {
    resourceAction,
    listFilters,
    inputs
  },
  [Sections.Page]: {
    loginPage,
    adminPage
  }
};
