import {
  FC,
  getClassName,
  IconC,
  ThemeStyleColorVariant
} from "@laba/react-common";
import React from "react";
import { useIconOrImgStyles } from "components/helpers/useIconOrImgStyles";

export type ImgSrc = string;

export type IconOrImgType = IconC | ImgSrc;

export const isImgSrc = (value: IconOrImgType): value is string => {
  return typeof value === "string";
};

export interface IconOrImgProps {
  className?: string;
  Logo: IconOrImgType;
  alt?: string;
  fullSize?: boolean;
  iconColorVariant?: ThemeStyleColorVariant;
}

export const IconOrImg: FC<IconOrImgProps> = ({
  Logo,
  alt,
  className,
  fullSize,
  iconColorVariant
}) => {
  const classes = useIconOrImgStyles({ fullSize, iconColorVariant });
  return isImgSrc(Logo) ? (
    <img
      className={getClassName(className, classes.default)}
      src={Logo}
      alt={alt}
    />
  ) : (
    <Logo
      titleAccess={alt}
      className={getClassName(className, classes.default)}
    />
  );
};
