import { FC, getClassName } from "@laba/react-common";
import React from "react";
import { StyleVariant } from "model/themeVariant";
import { useVerticalTabStyle } from "components/tabs/VerticalTab/VerticalTabStyle";
import { Noop } from "@laba/ts-common";

export interface VerticalTabProps {
  className?: string;
  title?: string;
  text?: string;
  isActive?: boolean;
  disabled?: boolean;
  onSelect?: Noop;
}

export const VerticalTab: FC<VerticalTabProps> = ({
  className,
  title,
  text,
  onSelect,
  isActive = false,
  disabled = false
}) => {
  const style = StyleVariant.Primary;
  const classes = useVerticalTabStyle({
    isActive,
    disabled,
    style
  });
  return (
    <button
      type="button"
      className={getClassName(classes.container, className)}
      title={title}
      onClick={() => !isActive && !disabled && onSelect?.()}
    >
      <div className={classes.verticalRectangle} />
      <span className={classes.text}>{text}</span>
    </button>
  );
};
