import React from "react";
import { FCC } from "components/types";
import { Noop } from "@laba/ts-common";
import { InstallEventContext, useGetBeforeInstallPromptValue } from "./utils";

interface Props {
  context: InstallEventContext;
  showInstallAppButton?: boolean;
  closeInstallAppButton?: Noop;
}

export const BeforeInstallPromptProvider: FCC<Props> = ({
  children,
  context,
  showInstallAppButton,
  closeInstallAppButton
}) => {
  const value = useGetBeforeInstallPromptValue(
    showInstallAppButton,
    closeInstallAppButton
  );

  return <context.Provider value={value}>{children}</context.Provider>;
};
