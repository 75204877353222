import { BaseEvent, configureStore, ThunkDispatch } from "@laba/react-common";
import { Action, Selector, ThunkAction } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { reducers } from "store/reducers";
import { sessionSlice } from "store/session/slice";
import { initNexupRequest } from "@laba/nexup-api";
import {
  accessTokenSelector,
  provisionalServerUrlSelector,
  serverUrlSelector
} from "store/session/selectors";
import { EnvVar, getEnvVar } from "models/envVar/envVar";
import { parseInt } from "lodash-es";
import { refreshToken } from "store/session/events";
import { workspaceSlice } from "store/workspace/slice";

export const { store, persistor } = configureStore(reducers, [
  sessionSlice.name,
  workspaceSlice.name
]);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<RootState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type Event<R = void> = BaseEvent<R, RootState, AppDispatch>;
export type RootSelector<R> = Selector<RootState, R>;

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();

initNexupRequest({
  getAccessToken: () => {
    return accessTokenSelector(store.getState());
  },
  getApiRootUrl: () => {
    return serverUrlSelector(store.getState());
  },
  getApiProvisionalRootUrl: () => {
    return provisionalServerUrlSelector(store.getState());
  },
  tryRefreshToken: () => {
    return store.dispatch(refreshToken());
  },
  getAppBaseUrl: () => {
    return getEnvVar(EnvVar.REACT_APP_BASE_URL);
  },
  timeout: parseInt(getEnvVar(EnvVar.REACT_APP_API_TIMEOUT))
});
