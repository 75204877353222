import {
  createNotification,
  editNotification,
  getNotificationLastVersionDetail,
  getNotificationLastVersionList,
  Notification,
  NotificationLastVersion,
  NotificationListQueryParams,
  NotificationListQueryParamsKey
} from "@laba/nexup-api";
import { RequestFailureStatus } from "@laba/ts-common";
import { HttpError } from "react-admin";
import {
  ProviderGetMany,
  ResourceProviderTypes
} from "./utils/resourceProviderTypes";
import {
  createGetter,
  deleteGetter,
  deleteManyGetter,
  ExternalResourceType,
  getKeyTargetParam,
  getListGetter,
  getManyReferenceGetter,
  getOneGetter,
  updateGetter,
  updateManyGetter
} from "./utils/resourceProviderGetter";

const getList = getNotificationLastVersionList;
const getOne = getNotificationLastVersionDetail;
const update = editNotification;
const create = createNotification;
const getOrderParam = () => undefined;

const getTargetParams = getKeyTargetParam<NotificationListQueryParams>(
  NotificationListQueryParamsKey
);

const getManyNotification: ProviderGetMany<
  NotificationLastVersion
> = async params => {
  const result: NotificationLastVersion[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const id of params.ids.map(value => value.toString())) {
    // eslint-disable-next-line no-await-in-loop
    const response = await getNotificationLastVersionDetail(id);
    if (response.failureStatus === RequestFailureStatus.Failure) {
      throw new HttpError(response.errorMsg, response.status, response.data);
    }
    result.push(response.data);
  }
  return {
    data: result
  };
};

export const notificationProvider: ResourceProviderTypes<
  Notification,
  NotificationListQueryParams
> = {
  resourceType: ExternalResourceType.Notification,
  getList: getListGetter(getList, getOrderParam),
  getOne: getOneGetter(getOne),
  getMany: getManyNotification,
  getManyReference: getManyReferenceGetter(
    getList,
    getOrderParam,
    getTargetParams
  ),
  update: updateGetter(update),
  updateMany: updateManyGetter(update),
  create: createGetter(create),
  delete: deleteGetter(),
  deleteMany: deleteManyGetter()
};
