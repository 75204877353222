import React from "react";
import { FC, getClassName } from "@laba/react-common";
import { useOdontogramGridStylesClassNames } from "components/odontogram/OdontogramGrid/useOdontogramGridStyles";
import { OdontogramGridItemConfig } from "components/odontogram/OdontogramGrid/utils";
import { DesktopOdontogramGrid } from "components/odontogram/OdontogramGrid/DesktopOdontogramGrid/DesktopOdontogramGrid";
import { MobileOdontogramGrid } from "components/odontogram/OdontogramGrid/MobileOdontogramGrid/MobileOdontogramGrid";

export interface OdontogramGridGroupConfig {
  group1: OdontogramGridItemConfig[];
  group2: OdontogramGridItemConfig[];
  group3: OdontogramGridItemConfig[];
  group4: OdontogramGridItemConfig[];
}

export interface OdontogramGridProps {
  className?: string;
  adultOdontogramConfig?: OdontogramGridGroupConfig;
  childrenOdontogramConfig?: OdontogramGridGroupConfig;
  isMobile?: boolean;
  onClickItem?: (tooth: string) => void;
}

export const OdontogramGrid: FC<OdontogramGridProps> = ({
  className,
  isMobile,
  adultOdontogramConfig,
  childrenOdontogramConfig,
  onClickItem
}) => {
  const classes = useOdontogramGridStylesClassNames({ isMobile });
  if (isMobile) {
    return (
      <div className={getClassName(className, classes.root)}>
        {adultOdontogramConfig && (
          <MobileOdontogramGrid
            isChild={false}
            firstGroup={adultOdontogramConfig.group1}
            secondGroup={adultOdontogramConfig.group2}
            thirdGroup={adultOdontogramConfig.group3}
            fourthGroup={adultOdontogramConfig.group4}
            onClickItem={onClickItem}
          />
        )}
        {childrenOdontogramConfig && (
          <MobileOdontogramGrid
            isChild
            firstGroup={childrenOdontogramConfig.group1}
            secondGroup={childrenOdontogramConfig.group2}
            thirdGroup={childrenOdontogramConfig.group3}
            fourthGroup={childrenOdontogramConfig.group4}
            onClickItem={onClickItem}
          />
        )}
      </div>
    );
  }
  return (
    <div className={getClassName(className, classes.root)}>
      {adultOdontogramConfig && (
        <DesktopOdontogramGrid
          topLeft={adultOdontogramConfig.group1}
          topRight={adultOdontogramConfig.group2}
          bottomLeft={adultOdontogramConfig.group4}
          bottomRight={adultOdontogramConfig.group3}
          onClickItem={onClickItem}
        />
      )}
      {childrenOdontogramConfig && (
        <DesktopOdontogramGrid
          topLeft={childrenOdontogramConfig.group1}
          topRight={childrenOdontogramConfig.group2}
          bottomLeft={childrenOdontogramConfig.group4}
          bottomRight={childrenOdontogramConfig.group3}
          onClickItem={onClickItem}
        />
      )}
    </div>
  );
};
