import { ReactElement } from "@laba/react-common";
import React from "react";
import {
  DateInput as ReactAdminDateInput,
  DateInputProps as ReactAdminDateInputProps,
  required as requiredValidate
} from "react-admin";
import {
  applyFormatToDate,
  DateFormat,
  DateTime,
  getAsArray,
  notNull
} from "@laba/ts-common";

export type DateInputProps = ReactAdminDateInputProps & {
  dateFormat?: DateFormat;
  required?: boolean;
};
const inputFormat = "yyyy-LL-dd";
export const DateInput = ({
  dateFormat = DateFormat.ReportDate,
  validate,
  required,
  ...otherProps
}: DateInputProps): ReactElement => {
  const validateArray = getAsArray(validate).filter(notNull);
  if (required) validateArray.push(requiredValidate());
  return (
    <ReactAdminDateInput
      fullWidth
      variant="outlined"
      {...otherProps}
      format={value => {
        let date = DateTime.fromFormat(value ?? "", dateFormat);
        if (!date.isValid) date = DateTime.fromApiDate(value ?? "", true);
        return applyFormatToDate(date, inputFormat) ?? "";
      }}
      parse={value => {
        const date = DateTime.fromFormat(value ?? "", inputFormat);
        if (date.isValid) return applyFormatToDate(date, dateFormat);
        return value ?? "";
      }}
      validate={validateArray}
    />
  );
};
