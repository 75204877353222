import {
  AccountListQueryParams,
  AccountListQueryParamsKey,
  createAccount,
  editAccount,
  getAccount,
  getAccountList,
  getModelReferenceId,
  ResourceType
} from "@laba/nexup-api";
import {
  getEnumOrderParam,
  getKeyTargetParam,
  resourceProviderGetter
} from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { workspaceOrganizationSelector } from "store/workspace/selectors";
import { store } from "store/store";
import { notUndefined } from "@laba/ts-common";

export const accountProvider = resourceProviderGetter(
  ResourceType.Account,
  getEnumOrderParam({}),
  getKeyTargetParam<AccountListQueryParams>(AccountListQueryParamsKey),
  getAccountList,
  getAccount,
  editAccount,
  createAccount,
  undefined,
  () => ({
    organization: [
      getModelReferenceId(workspaceOrganizationSelector(store.getState()))
    ].filter(notUndefined)
  })
);
