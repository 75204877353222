import React, { useMemo } from "react";
import { ReactElement } from "@laba/react-common";
import { Template, ValidationError } from "jsoneditor";
import {
  JsonEditor,
  JsonEditorMode
} from "components/generic/JsonEditor/JsonEditor";
import "jsoneditor/dist/jsoneditor.css";
import { useFormContext, useWatch } from "react-hook-form";
import { debounce } from "lodash-es";

export interface JsonInputProps {
  onValidate?: (value: unknown) => ValidationError[];
  mode?: JsonEditorMode;
  templates?: Template[];
}
export const JsonInput = ({
  onValidate,
  mode = JsonEditorMode.Tree,
  templates
}: JsonInputProps): ReactElement => {
  const { reset } = useFormContext();
  const onChangeObj = useMemo(
    () =>
      debounce((value: unknown) => {
        if (typeof value === "object" && value !== null) {
          reset(value, {
            keepErrors: true,
            keepDirty: true,
            keepDefaultValues: true,
            keepIsSubmitted: true,
            keepTouched: true,
            keepIsValid: true,
            keepSubmitCount: true
          });
        }
      }, 1000),
    [reset]
  );
  const data = useWatch();
  return (
    <JsonEditor
      value={data}
      mode={mode}
      onValidate={onValidate}
      templates={templates}
      onChangeObj={onChangeObj}
    />
  );
};
