import {
  FC,
  getClassName,
  StyleVariant,
  Theme,
  ThemeStyleColorVariant,
  useTheme
} from "@laba/react-common";
import React from "react";
import { Indicator } from "components/card/LocationCard/LocationCardIndicators/Indicator/Indicator";
import { DirectionsIcon, InfoIcon } from "components/icons";
import { notUndefined } from "@laba/ts-common";
import { getThemeStyleColor } from "model/themeUtils";
import { isEmpty } from "lodash-es";
import { useLocationCardIndicatorsStyle } from "./useLocationCardIndicatorsStyle";

interface LocationCardIndicatorsProps {
  className?: string;
  specialityList: string[];
  distance?: number;
  distanceUnit?: string;
  tag?: string;
}

const getDistanceText = (distance?: number, distanceUnit?: string) =>
  [distance ?? "-", distanceUnit].filter(notUndefined).join(" ");

const getSpecialitiesText = (specialityList: string[]) => {
  if (specialityList.length > 2) {
    return `${specialityList[0]}, ${specialityList[1]} + ${
      specialityList.length - 2
    }`;
  }
  return specialityList.join(", ");
};

export const LocationCardIndicators: FC<LocationCardIndicatorsProps> = ({
  className,
  distance,
  distanceUnit = "km",
  specialityList = [],
  tag = ""
}) => {
  const classes = useLocationCardIndicatorsStyle();
  const theme = useTheme<Theme>();
  const iconColor = getThemeStyleColor(
    theme,
    StyleVariant.Primary,
    ThemeStyleColorVariant.GrayDark
  );

  return (
    <div className={getClassName(className, classes.root)}>
      <Indicator
        Icon={InfoIcon}
        text={
          !isEmpty(specialityList) ? getSpecialitiesText(specialityList) : tag
        }
        label={!isEmpty(specialityList) ? specialityList.join(", ") : tag}
        iconColor={iconColor}
        className={classes.indicator}
      />
      <Indicator
        Icon={DirectionsIcon}
        text={getDistanceText(distance, distanceUnit)}
        iconColor={iconColor}
        className={classes.indicator}
      />
    </div>
  );
};
