import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  MedicalRequestDefinitionKey,
  ResourceType,
  MedicationSubstanceListQueryParamsKey,
  getModelReferenceId,
  MedicalRequestDefinitionDeviceKey,
  ModelReference,
  Organization,
  KnownCodeSystemSystem,
  MedicalRequestDefinitionDeviceUse,
  MedicalRequestDefinitionDeviceCondition,
  MedicalDevice
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { NumberInput } from "components/generic/NumberInput/TextInput";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { medicalDeviceOptionText } from "components/generic/ReferenceResourceInput/optionText";

export const tk = tkCP.adminPage[ResourceType.MedicalRequestDefinition];
export const MedicalRequestDefinitionDeviceContent: FC = () => {
  const organization = useWatchResourceForm<ModelReference<Organization>>(
    MedicalRequestDefinitionKey.organization
  );
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organizationId =
    getModelReferenceId(organization) || workspaceOrganizationId;
  const unitSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicationUnit,
    organizationId
  );
  const { t } = useI18n();
  return (
    <ArrayInput
      source={MedicalRequestDefinitionKey.medicalDevice}
      label={t(tk.fields.device)}
    >
      <ReferenceResourceInput<MedicalDevice>
        resourceType={ResourceType.MedicalDevice}
        label={t(tk.fields.deviceDevice)}
        source={MedicalRequestDefinitionDeviceKey.device}
        optionTextKey={medicalDeviceOptionText}
        alwaysOn
        extraFilters={{
          [MedicationSubstanceListQueryParamsKey.organization]: organizationId
        }}
        required
      />
      <NumberInput
        source={MedicalRequestDefinitionDeviceKey.quantity}
        label={t(tk.fields.deviceQuantity)}
        min={0}
        step={1}
      />
      <CodeSystemSelectorInput
        source={MedicalRequestDefinitionDeviceKey.unit}
        label={t(tk.fields.deviceUnit)}
        codeSystem={unitSystem}
        organizationId={organizationId}
        withCreate
      />
      <ArrayInput
        source={MedicalRequestDefinitionDeviceKey.condition}
        label={t(tk.fields.deviceCondition)}
      >
        <EnumSelectorInput
          source=""
          label={t(tk.fields.deviceConditionElement)}
          enumValue={MedicalRequestDefinitionDeviceCondition}
          required
        />
      </ArrayInput>
      <EnumSelectorInput
        source={MedicalRequestDefinitionDeviceKey.use}
        label={t(tk.fields.deviceUse)}
        enumValue={MedicalRequestDefinitionDeviceUse}
      />
    </ArrayInput>
  );
};
