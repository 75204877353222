import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  ResourceType,
  AppointmentKey,
  NotificationChannelKey,
  AppointmentDefinitionNotificationChannel,
  ContactPointRole,
  ContactPointType
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";

import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";

export const tk = tkCP.adminPage[ResourceType.Appointment];
export const AppointmentNotificationChannelContent: FC = () => {
  const { t } = useI18n();
  return (
    <ArrayInput
      source={AppointmentKey.notificationChannelList}
      label={t(tk.fields.notificationChannelList)}
      disableAdd
      disableRemove
    >
      <EnumSelectorInput
        source={NotificationChannelKey.code}
        label={t(tk.fields.notificationChannelListCode)}
        enumValue={AppointmentDefinitionNotificationChannel}
        disabled
      />
      <TextInput
        source={NotificationChannelKey.contactPoint.value}
        label={t(tk.fields.notificationChannelListContactPointValue)}
        disabled
      />
      <EnumSelectorInput
        source={NotificationChannelKey.contactPoint.role}
        label={t(tk.fields.notificationChannelListContactPointRole)}
        enumValue={ContactPointRole}
        disabled
      />
      <EnumSelectorInput
        source={NotificationChannelKey.contactPoint.type}
        label={t(tk.fields.notificationChannelListContactPointType)}
        enumValue={ContactPointType}
        disabled
      />
    </ArrayInput>
  );
};
