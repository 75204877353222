import { ResourceType } from "model/primitives/resourceModel";
import { ScheduleNotificationConfigObj } from "model/resource/schedule/schedule";
import { getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import {
  ActivityDefinition,
  ActivityDefinitionKind
} from "../activity/activityDefinition";

export interface ScheduleDefinition
  extends ActivityDefinition<
    ResourceType.ScheduleDefinition,
    ActivityDefinitionKind.Schedule
  > {
  notificationConfig?: ScheduleNotificationConfigObj;
}

export const ScheduleDefinitionKey = getKeyObj(
  createHydratedMock<ScheduleDefinition>()
);
