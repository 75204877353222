import { createStyle, pxToRem, Theme } from "@laba/react-common";

export const useLoginPageStyle = createStyle(
  (_theme: Theme) => ({
    root: {
      minHeight: "100Vh",
      width: "100vw",
      position: "relative"
    },
    backgroundImg: {
      position: "absolute",
      objectFit: "cover",
      height: "100%",
      width: "100%"
    },
    loginCard: {
      position: "absolute",
      top: "50%",
      left: "50%",
      width: "50%",
      minWidth: pxToRem(250),
      maxWidth: pxToRem(500),
      transform: "translate(-50%, -50%)"
    }
  }),
  "useLoginPageStyle"
);
