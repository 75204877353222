import React from "react";
import { FC, StyleVariant } from "@laba/react-common";
import { useTagWithPeriodStyles } from "./useTagWithPeriodStyles";
import { Tag, TagProps } from "../Tag/Tag";

interface Props extends TagProps {
  startDateText?: string;
  endDateText?: string;
}

export const TagWithPeriod: FC<Props> = ({
  className,
  text,
  title,
  disabled = false,
  style = StyleVariant.Primary,
  backgroundColor,
  type,
  startDateText,
  endDateText
}) => {
  const classes = useTagWithPeriodStyles();

  return (
    <div className={classes.root}>
      <Tag
        className={className}
        disabled={disabled}
        style={style}
        text={text}
        title={title}
        type={type}
        backgroundColor={backgroundColor}
      />
      {(startDateText || endDateText) && (
        <div className={classes.dateContainer}>
          {startDateText && <p className={classes.dateText}>{startDateText}</p>}
          {endDateText && <p className={classes.dateText}>{endDateText}</p>}
        </div>
      )}
    </div>
  );
};
