import { ReactElement, useI18n } from "@laba/react-common";
import React from "react";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import {
  ActivityDefinitionConfigurationCodeSystemCode,
  ActivityDefinitionConfigurationCodeSystemKey,
  KnownCodeSystemSystem
} from "@laba/nexup-api";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { tkCC } from "translation/i18n";
import { useSelector } from "react-redux";
import { defaultWorkspaceOrganizationIdSelector } from "store/workspace/selectors";
import { CodeSystemSelectorInput } from "../CodeSystemSelectorInput/CodeSystemSelectorInput";

export interface ConfigurationCodeSystemInputProps {
  source: string;
  label: string;
  organizationId?: string;
}

const tk = tkCC.inputs.configurationCodeSystemInput;

export const ConfigurationCodeSystemInput = ({
  source,
  label,
  organizationId
}: ConfigurationCodeSystemInputProps): ReactElement => {
  const { t } = useI18n();

  const codeSystemSystemSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.CodeSystemSystem,
    organizationId
  );
  const defaultOrganizationId = useSelector(
    defaultWorkspaceOrganizationIdSelector
  );

  return (
    <ArrayInput source={source} label={label}>
      <EnumSelectorInput
        source={ActivityDefinitionConfigurationCodeSystemKey.code}
        enumValue={ActivityDefinitionConfigurationCodeSystemCode}
        withCreate
        label={t(tk.code)}
      />
      <CodeSystemSelectorInput
        source={ActivityDefinitionConfigurationCodeSystemKey.url}
        label={t(tk.url)}
        codeSystem={codeSystemSystemSystem}
        withCreate
        organizationId={organizationId || defaultOrganizationId}
      />
    </ArrayInput>
  );
};
