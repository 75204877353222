import React from "react";
import { FC, useI18n } from "@laba/react-common";
import { OrganizationKey, ResourceType } from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { PhoneConfigInput } from "components/generic/PhoneConfigInput/PhoneConfigInput";

export const tk = tkCP.adminPage[ResourceType.Organization];
export const OrganizationPhoneContent: FC = () => {
  const { t } = useI18n();
  return (
    <PhoneConfigInput
      source={OrganizationKey.phoneConfig}
      label={t(tk.fields.phoneConfig)}
    />
  );
};
