import { ModelReference, ResourceModel, ResourceType } from "model/primitives";
import { ApiDate, getKeyObj } from "@laba/ts-common";
import {
  AppointmentStatus,
  KnownAppointmentType
} from "model/resource/appointment";
import { Code } from "model/resource/entities";
import { createHydratedMock } from "ts-auto-mock";
import { PublicPatientData } from "../publicPatient/publicPatient";
import { PublicScheduleData } from "../publicSchedule/publicSchedule";
import { PublicLocationData } from "../publicLocation/publicLocation";
import { OrganizationPublicData } from "../publicOrganization/publicOrganization";
import { PublicPractitionerData } from "../publicPractitioner/publicPractitioner";

export interface PublicAppointmentData
  extends ResourceModel<ResourceType.PublicAppointment> {
  startDate?: ApiDate;
  endDate?: ApiDate;
  minutesDuration?: number;
  patient?: ModelReference<PublicPatientData>;
  schedule?: ModelReference<PublicScheduleData>;
  speciality?: Code[];
  location?: ModelReference<PublicLocationData>;
  type?: KnownAppointmentType;
  status?: AppointmentStatus;
  payer?: ModelReference<OrganizationPublicData>;
  plan?: string;
  practitionerTeam?: ModelReference<PublicPractitionerData>[];
  patientInstruction?: string;
  patientPhone?: string;
  patientEmail?: string;
  addToCalendarUrl?: string;
}

export const PublicAppointmentDataKey = getKeyObj(
  createHydratedMock<PublicAppointmentData>()
);
