import { joinText, Optional } from "@laba/ts-common";
import { PublicPractitionerData } from "./publicPractitioner";

export const getPublicPractitionerFirstName = (
  publicPractitioner?: PublicPractitionerData
): Optional<string> => publicPractitioner?.firstName;

export const getPublicPractitionerLastName = (
  publicPractitioner?: PublicPractitionerData
): Optional<string> => publicPractitioner?.lastName;

export const getPublicPractitionerProfilePictureUrl = (
  publicPractitioner?: PublicPractitionerData
): Optional<string> => publicPractitioner?.profilePictureUrl;

export const getPublicPractitionerFullName = (
  publicPractitioner?: PublicPractitionerData
): Optional<string> =>
  joinText(
    [
      publicPractitioner?.firstName,
      publicPractitioner?.otherName,
      publicPractitioner?.lastName
    ],
    " "
  );
