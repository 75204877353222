import { ResourceType } from "model/primitives/resourceModel";
import {
  getCurrentDateAsString,
  HttpCode,
  RequestFailureResponse,
  RequestFailureStatus
} from "@laba/ts-common";
import { BackendError } from "model/resource/error/error";

export const errorResponseMock: RequestFailureResponse<BackendError> = {
  errorMsg: "Hubo un error",
  failureStatus: RequestFailureStatus.Failure,
  data: {
    issue: [],
    resourceType: ResourceType.Error,
    creationDate: getCurrentDateAsString(),
    id: "1",
    lastEditDate: getCurrentDateAsString(),
    status: HttpCode.BadRequest
  },
  status: 400
};
