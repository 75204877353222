import {
  DateTime,
  DurationUnitValues,
  getCurrentDateTime,
  isMultiple,
  notUndefined,
  Optional
} from "@laba/ts-common";
import {
  difference,
  every,
  isArray,
  isEmpty,
  mergeWith,
  some,
  times,
  zipObject
} from "lodash-es";
import { AvailableTime } from "model/resource/utils/availableTime";
import {
  KnownScheduleDuration,
  Schedule
} from "model/resource/schedule/schedule";

export const slotDurationModified = (
  schedule1: Schedule,
  schedule2: Schedule
): boolean => {
  const slotDuration1 = schedule1.availability?.slotDuration;
  const slotDuration2 = schedule2.availability?.slotDuration;
  if (!slotDuration1 || !slotDuration2) return false;
  if (slotDuration1 === slotDuration2) return false;

  // If the new slot is bigger than the previous, the appointments might not fit
  if (slotDuration1 < slotDuration2) return true;

  // Test if the durations are divisible by the two posible minimum durations
  if (
    (slotDuration1 % KnownScheduleDuration.Quarter === 0 &&
      slotDuration2 % KnownScheduleDuration.Quarter === 0) ||
    (slotDuration1 % KnownScheduleDuration.OneThird === 0 &&
      slotDuration2 % KnownScheduleDuration.OneThird === 0)
  )
    return false;

  // The new duration is not compatible with the old duration
  return true;
};

const groupAvailableTimeByDayOfWeek = (
  availableTime: AvailableTime
): Record<string, AvailableTime> => {
  const dayList = availableTime.daysOfWeek ?? [];
  return zipObject(
    dayList,
    times(dayList.length, () => availableTime)
  );
};

type AvailableTimeRecord = Record<string, AvailableTime[]>;
const groupAvailableTimeListByDayOfWeek = (
  availableTimeList: AvailableTime[]
): AvailableTimeRecord => {
  const recordList = availableTimeList.map(groupAvailableTimeByDayOfWeek);
  return mergeWith(
    {},
    ...recordList,
    (objValue?: AvailableTime[], srcValue?: AvailableTime[]) => {
      const newObjValue = !objValue
        ? []
        : !isArray(objValue)
        ? [objValue]
        : objValue;
      const newSrcValue = !srcValue
        ? []
        : !isArray(srcValue)
        ? [srcValue]
        : srcValue;

      return newObjValue.concat(newSrcValue);
    }
  );
};

const daysOfTheWeekModified = (
  record1: AvailableTimeRecord,
  record2: AvailableTimeRecord
) => {
  const keys1 = Object.keys(record1);
  const keys2 = Object.keys(record2);

  // Return true if a day was deleted
  return !isEmpty(difference(keys1, keys2));
};

interface HourRange {
  start: DateTime;
  end: DateTime;
}

const availableTimeToHourRange = (
  availableTime: AvailableTime
): Optional<HourRange> => {
  const date = getCurrentDateTime().startOf("day");
  if (availableTime.allDay) {
    return {
      start: date.startOf("day"),
      end: date.endOf("day")
    };
  }
  if (availableTime.startTime && availableTime.endTime) {
    return {
      start: DateTime.fromApiHour(availableTime.startTime, date),
      end:
        availableTime.endTime === "00:00:00"
          ? date.endOf("day")
          : DateTime.fromApiHour(availableTime.endTime, date)
    };
  }
  return undefined;
};

const isHourRangeContained = (
  range1?: HourRange,
  range2?: HourRange
): boolean => {
  const start1 = range1?.start;
  const end1 = range1?.end;
  const start2 = range2?.start;
  const end2 = range2?.end;

  if (!start1 || !start2 || !end1 || !end2) return false;
  return start1 >= start2 && end1 <= end2;
};

const isHourRangeOverlapped = (
  range1?: HourRange,
  range2?: HourRange
): boolean => {
  const start1 = range1?.start;
  const end1 = range1?.end;
  const start2 = range2?.start;
  const end2 = range2?.end;

  if (!start1 || !start2 || !end1 || !end2) return false;
  return (
    isHourRangeContained(range1, range2) || (start2 >= start1 && start2 <= end1)
  );
};

const mergeHourRangeList = (availableList: AvailableTime[]): HourRange[] => {
  if (isEmpty(availableList)) return [];
  // All items in this list have start and end time because the mapping fn makes them mandatory
  const sortedList = availableList
    .map(item => availableTimeToHourRange(item))
    .filter(notUndefined)
    .sort((d1, d2) => {
      return d1.start.diff(d2.start, DurationUnitValues.Minutes).minutes;
    });

  if (sortedList.length === 1) {
    return sortedList;
  }

  let testRange = sortedList[0];
  const newRangeList: HourRange[] = [];
  for (let i = 1; i < sortedList.length; i += 1) {
    const newRange = sortedList[i];
    if (!testRange || !newRange) break;
    if (isHourRangeOverlapped(testRange, newRange)) {
      testRange = {
        start: testRange.start,
        end: testRange.end > newRange.end ? testRange.end : newRange.end
      };
      if (i >= sortedList.length - 1) {
        newRangeList.push(testRange);
      }
    } else {
      newRangeList.push(testRange);
      testRange = newRange;
    }
  }

  return newRangeList;
};

const availableTimeModified = (
  day: string,
  availableList1?: AvailableTime[],
  availableList2?: AvailableTime[]
): boolean => {
  // A day was added, no need for validation
  if (
    (availableList1 === undefined && availableList2 !== undefined) ||
    (availableList1 === undefined && availableList2 === undefined)
  )
    return false;
  // A day was deleted, must check appointments
  if (availableList2 === undefined && availableList1 !== undefined) return true;

  // If both list has an item that lasts all day, then no need for validation
  if (
    some(availableList1, time => time.allDay === true) &&
    some(availableList2, time => time.allDay === true)
  )
    return false;

  // Merge the ranges in case they overlap
  const mergedRangeList1 = mergeHourRangeList(availableList1 ?? []);
  const mergedRangeList2 = mergeHourRangeList(availableList2 ?? []);

  // Every range in the first list must be contained in a range in the second list
  return !every(mergedRangeList1, range1 =>
    some(mergedRangeList2, range2 => isHourRangeContained(range1, range2))
  );
};

export const availabilityModified = (
  schedule1: Schedule,
  schedule2: Schedule
): boolean => {
  const availabilityRecord1 = groupAvailableTimeListByDayOfWeek(
    schedule1.availability?.availableTime ?? []
  );
  const availabilityRecord2 = groupAvailableTimeListByDayOfWeek(
    schedule2.availability?.availableTime ?? []
  );

  // If a day was deleted, return true
  if (daysOfTheWeekModified(availabilityRecord1, availabilityRecord2))
    return true;

  // Check if hours changed
  const keyList1 = Object.keys(availabilityRecord1);
  return some(keyList1, day => {
    return availableTimeModified(
      day,
      availabilityRecord1[day],
      availabilityRecord2[day]
    );
  });
};

export const scheduleAvailabilityModifiedWithConflicts = (
  oldSchedule: Schedule,
  newSchedule: Schedule
): boolean => {
  return (
    slotDurationModified(oldSchedule, newSchedule) ||
    availabilityModified(oldSchedule, newSchedule)
  );
};

const areAllDurationsMultiplesOf = (
  slotDurationList: number[],
  knownScheduleDuration: KnownScheduleDuration
): boolean =>
  every(slotDurationList, duration =>
    isMultiple(duration, knownScheduleDuration)
  );

export const slotDurationFromSlotDurationList = (
  slotDurationList: number[]
): Optional<KnownScheduleDuration> => {
  if (isEmpty(slotDurationList)) return undefined;
  if (
    areAllDurationsMultiplesOf(slotDurationList, KnownScheduleDuration.Whole)
  ) {
    return KnownScheduleDuration.Whole;
  }
  if (
    areAllDurationsMultiplesOf(slotDurationList, KnownScheduleDuration.Half)
  ) {
    return KnownScheduleDuration.Half;
  }
  if (
    areAllDurationsMultiplesOf(slotDurationList, KnownScheduleDuration.OneThird)
  ) {
    return KnownScheduleDuration.OneThird;
  }
  if (
    areAllDurationsMultiplesOf(slotDurationList, KnownScheduleDuration.Quarter)
  ) {
    return KnownScheduleDuration.Quarter;
  }
};
