import {
  borderMixin,
  createStyle,
  hoverMixin,
  pxToRem
} from "@laba/react-common";
import { RadiusVariant, Theme } from "model/theme";
import { getMapValueBuilder } from "@laba/ts-common";
import { ElevationVariant, StyleVariant } from "model/themeVariant";
import {
  getBorderColorByType,
  getGenericButtonBackgroundColor,
  getGenericButtonColor,
  getTypeFromInvertedType,
  getTypeInvertedVariantHoverBackgroundColor,
  TypeInvertedVariant
} from "components/utils/getGenericButtonColors";
import {
  getThemeRadius,
  getThemeStyleState,
  themeElevationMixin
} from "model/themeUtils";

export enum IconButtonSizeVariant {
  Small = "Small",
  Large = "Large"
}

export interface FloatingIconButtonStyleProps {
  size: IconButtonSizeVariant;
  style: StyleVariant;
  type: TypeInvertedVariant;
  disabled: boolean;
  radiusVariant?: RadiusVariant;
}

const getSize = getMapValueBuilder({
  [IconButtonSizeVariant.Large]: pxToRem(48),
  [IconButtonSizeVariant.Small]: pxToRem(40)
});

export const useFloatingIconButtonStyle = createStyle(
  (theme: Theme) => ({
    root: (props: FloatingIconButtonStyleProps) => ({
      ...themeElevationMixin(
        theme,
        props.type === TypeInvertedVariant.Outlined
          ? ElevationVariant.Elevation0
          : ElevationVariant.Elevation24
      ),
      ...borderMixin({
        style:
          props.type === TypeInvertedVariant.Outlined ? "solid" : undefined,
        color: getBorderColorByType(
          props.type,
          props.style,
          props.disabled,
          theme
        ),
        side: { all: 1 },
        radius: {
          all: props.radiusVariant && getThemeRadius(theme, props.radiusVariant)
        }
      }),
      ...hoverMixin({
        ...borderMixin({
          color: getGenericButtonColor(theme, {
            style: props.style,
            type: getTypeFromInvertedType(props.type)
          })
        }),
        // TODO HIS-7465: make mixin to "desactivate" hover on mobile
        backgroundColor: getTypeInvertedVariantHoverBackgroundColor(
          theme,
          props.style,
          props.type
        ),
        "@media (hover: none)": {
          backgroundColor: getGenericButtonBackgroundColor(theme, {
            style: props.style,
            typeInvertedVariant: props.type,
            type: getTypeFromInvertedType(props.type),
            disabled: props.disabled
          })
        }
      }),
      "&:disabled": {
        ...borderMixin({
          side: {
            all: props.type === TypeInvertedVariant.Outlined ? pxToRem(2) : 0
          },
          color: getThemeStyleState(theme, StyleVariant.Primary).disabled
        }),
        backgroundColor: getGenericButtonBackgroundColor(theme, {
          style: props.style,
          type: getTypeFromInvertedType(props.type),
          disabled: props.disabled
        }),
        color: getGenericButtonColor(theme, {
          style: props.style,
          type: getTypeFromInvertedType(props.type),
          disabled: props.disabled
        })
      },
      backgroundColor: getGenericButtonBackgroundColor(theme, {
        style: props.style,
        type: getTypeFromInvertedType(props.type)
      }),
      color: getGenericButtonColor(theme, {
        style: props.style,
        type: getTypeFromInvertedType(props.type)
      }),
      height: getSize(props.size),
      width: getSize(props.size),

      position: "fixed",
      bottom: pxToRem(32),
      right: pxToRem(32)
    })
  }),
  "FloatingIconButton"
);
