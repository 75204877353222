import { Optional, Noop } from "@laba/ts-common";
import React from "react";
import { FC, getClassName } from "@laba/react-common";
import { useOrToggleSelectedItem } from "components/menu/useOrToggleSelectedItem";
import { MenuParentNode, MenuType } from "components/menu/menuTreeTypes";
import { useMenuDropdownStyles } from "./useMenuDropdownStyle";
import { MenuDropdownColumn } from "./MenuDropdownColumn/MenuDropdownColumn";

export interface MenuDropdownProps {
  className?: string;
  categories: MenuParentNode[];
  searchText: string;
  onChangeSearchText?: (s: string) => void;
  menuHeight: number;
  columnWidth: number;
  showSearchBar?: boolean;
  onClose?: Noop;
}

export const MenuDropdown: FC<MenuDropdownProps> = ({
  className,
  categories,
  searchText,
  onChangeSearchText,
  menuHeight,
  columnWidth,
  showSearchBar = true,
  onClose
}: MenuDropdownProps) => {
  const classes = useMenuDropdownStyles();
  const [selectedElement, setSelectedElement] =
    useOrToggleSelectedItem<Optional<MenuParentNode>>(undefined);
  return (
    <div className={getClassName(classes.root, className)}>
      <MenuDropdownColumn
        categories={categories}
        showSearchbar={showSearchBar}
        searchText={searchText}
        onChangeSearchText={search => {
          setSelectedElement(undefined);
          onChangeSearchText?.(search);
        }}
        selectedElement={selectedElement}
        setSelectedElement={item => {
          setSelectedElement(item);
          onChangeSearchText?.("");
        }}
        menuHeight={menuHeight}
        columnWidth={columnWidth}
        onClose={onClose}
      />
      {selectedElement && !searchText && (
        <MenuDropdownColumn
          secondary
          categories={[
            {
              type: MenuType.Parent,
              children: selectedElement.children,
              title: selectedElement.title
            }
          ]}
          onChangeSearchText={onChangeSearchText}
          setSelectedElement={setSelectedElement}
          menuHeight={menuHeight}
          columnWidth={columnWidth}
          onClose={onClose}
        />
      )}
    </div>
  );
};
