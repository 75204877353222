import { ApiDate, notUndefined, Optional } from "@laba/ts-common";
import { MedicalAppConfigPermissionCode } from "model/resource/app/appConfig";
import {
  validateCreateByResourcePermissions,
  validateEditByResourcePermissions
} from "model/primitives/resorcePermissions/utils";
import { groupBy, maxBy, values } from "lodash-es";
import {
  Questionnaire,
  QuestionnaireFieldBase,
  QuestionnaireFieldPropertyBase,
  QuestionnaireFieldPropertyType,
  QuestionnaireFieldType
} from "model/resource";

const getQuestionnaireCardFieldProperty = <
  T extends QuestionnaireFieldType,
  P extends QuestionnaireFieldPropertyType = QuestionnaireFieldPropertyType
>(
  field: QuestionnaireFieldBase<T, P>,
  propertyType: P
): Optional<QuestionnaireFieldPropertyBase<P>> => {
  return field.property.find(p => p.type === propertyType);
};
const getQuestionnaireCardMultipleFieldProperty = <
  T extends QuestionnaireFieldType,
  P extends QuestionnaireFieldPropertyType = QuestionnaireFieldPropertyType
>(
  field: QuestionnaireFieldBase<T, P>,
  propertyType: P
): QuestionnaireFieldPropertyBase<P>[] => {
  return field.property.filter(p => p.type === propertyType);
};
export const getQuestionnaireCardBooleanFieldPropertyValue = <
  T extends QuestionnaireFieldType,
  P extends QuestionnaireFieldPropertyType = QuestionnaireFieldPropertyType
>(
  field: QuestionnaireFieldBase<T, P>,
  propertyType: P,
  fallback?: boolean
): Optional<boolean> => {
  const valueStr = getQuestionnaireCardFieldProperty(
    field,
    propertyType
  )?.value;

  return valueStr !== undefined ? valueStr.toLowerCase() === "true" : fallback;
};
export const getQuestionnaireCardStringFieldPropertyValue = <
  T extends QuestionnaireFieldType,
  P extends QuestionnaireFieldPropertyType = QuestionnaireFieldPropertyType
>(
  field: QuestionnaireFieldBase<T, P>,
  propertyType: P,
  fallback?: string
): Optional<string> =>
  getQuestionnaireCardFieldProperty(field, propertyType)?.value ?? fallback;
export const getQuestionnaireCardStringArrayFieldPropertyValues = <
  T extends QuestionnaireFieldType,
  P extends QuestionnaireFieldPropertyType = QuestionnaireFieldPropertyType
>(
  field: QuestionnaireFieldBase<T, P>,
  propertyType: P
): string[] =>
  getQuestionnaireCardMultipleFieldProperty(field, propertyType)
    .map(({ value }) => value)
    .filter(notUndefined);
export const getQuestionnaireCardNumberArrayFieldPropertyValues = <
  T extends QuestionnaireFieldType,
  P extends QuestionnaireFieldPropertyType = QuestionnaireFieldPropertyType
>(
  field: QuestionnaireFieldBase<T, P>,
  propertyType: P
): number[] =>
  getQuestionnaireCardMultipleFieldProperty(field, propertyType)
    .map(({ value }) => (value ? Number(value) : undefined))
    .filter(notUndefined);
export const getQuestionnaireCardNumberFieldPropertyValue = <
  T extends QuestionnaireFieldType,
  P extends QuestionnaireFieldPropertyType = QuestionnaireFieldPropertyType
>(
  field: QuestionnaireFieldBase<T, P>,
  propertyType: P,
  fallback?: number
): Optional<number> => {
  const value =
    getQuestionnaireCardFieldProperty(field, propertyType)?.value ?? fallback;

  return value !== undefined ? Number(value) : undefined;
};
export const getQuestionnaireCardDateFieldPropertyValue = <
  T extends QuestionnaireFieldType,
  P extends QuestionnaireFieldPropertyType = QuestionnaireFieldPropertyType
>(
  field: QuestionnaireFieldBase<T, P>,
  propertyType: P,
  fallback?: ApiDate
): Optional<ApiDate> => {
  const value =
    getQuestionnaireCardFieldProperty(field, propertyType)?.value ?? fallback;

  return value !== undefined ? value : undefined;
};

export const filterQRCreationByQuestionnairePermissionConfig = (
  questionnaires: Questionnaire[],
  permissionList?: MedicalAppConfigPermissionCode[]
): Questionnaire[] => {
  return questionnaires.filter(def =>
    validateCreateByResourcePermissions(
      def.permissionConfig?.createAppConfig,
      permissionList
    )
  );
};

export const validateQuestionnaireEditPermission = (
  questionnaire?: Questionnaire,
  availablePermissions?: MedicalAppConfigPermissionCode[]
): boolean => {
  return validateEditByResourcePermissions(
    questionnaire?.permissionConfig?.editAppConfig,
    availablePermissions
  );
};

export const filterQuestionnairesByPriority = (
  questionnaires: Questionnaire[]
): Questionnaire[] => {
  const groupedQuestionnaires = groupBy(questionnaires, q => q.name);
  return values(groupedQuestionnaires)
    .map(group => maxBy(group, gr => gr.order ?? 0))
    .filter(notUndefined);
};
