import { Optional } from "@laba/ts-common";
import { useFormState } from "forms/useFormState";
import { get } from "lodash-es";

export const useReadField = <V>(fieldName: string): Optional<V> => {
  const { values } = useFormState();

  return get(values, fieldName);
};

export const useReadFieldOrFormValues = <V>(fieldName?: string): V => {
  const { values } = useFormState<V, V>();

  const readField = useReadField<V>(fieldName ?? "");

  return fieldName ? readField ?? values : values;
};
