import { FC } from "@laba/react-common";
import React from "react";
import { useDefaultCustomOptionWithIconPropsStyles } from "components/autocomplete/Autocomplete/DefaultCustomOptionWithIcon/useOptionalCustomOptionStyles";
import { AddCircleIcon } from "components/icons";

export interface DefaultCustomOptionWithIconProps {
  text?: string;
}

export const DefaultCustomOptionWithIcon: FC<
  DefaultCustomOptionWithIconProps
> = ({ text }) => {
  const classes = useDefaultCustomOptionWithIconPropsStyles();
  return (
    <div className={classes.root}>
      <AddCircleIcon className={classes.addIcon} />
      <p className={classes.text}>{text} </p>
    </div>
  );
};
