import React from "react";

import { ResourceForm } from "components/generic/ResourceForm/ResourceForm";
import { tkCP } from "translation/i18n";
import { FC, useI18n } from "@laba/react-common";
import { ExternalResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { WhatsappNotificationMainContent } from "./WhatsappNotificationMainContent";
import { WhatsappNotificationHistoryListContent } from "./WhatsappNotificationHistoryListContent";

export const tk = tkCP.adminPage[ExternalResourceType.WhatsappNotification];

export const WhatsappNotificationForm: FC = () => {
  const { t } = useI18n();

  return (
    <ResourceForm
      tabsConfig={[
        {
          label: t(tk.tabs.main),
          content: <WhatsappNotificationMainContent />
        },
        {
          label: t(tk.tabs.history),
          content: <WhatsappNotificationHistoryListContent />
        }
      ]}
    />
  );
};
