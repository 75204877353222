export const isValidUrl = (url: string): boolean => {
  const urlRegex =
    // eslint-disable-next-line no-useless-escape
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
  return urlRegex.test(url);
};

export const addUrlProtocol = (url: string): string => {
  return /^(https?:\/\/|mailto:)/i.test(url) ? url : `https://${url}`;
};
