import { isEmpty } from "lodash-es";
import { ElementOrArray, isString } from "@laba/ts-common";
import { TitleContentDataTextItem } from "../types";

export const createTitleContentDataItemList = (
  value?: ElementOrArray<string>,
  placeholder?: string
): TitleContentDataTextItem[] => {
  if (isEmpty(value)) {
    return placeholder ? [{ principalText: placeholder }] : [];
  }
  if (isString(value)) {
    return [{ principalText: value }];
  }
  return value?.map(val => ({ principalText: val })) ?? [];
};
