import { isSomeEnum, Optional } from "@laba/ts-common";
import { PatientMeasure } from "model/resource/medical/observation/patientMeasure";
import { head } from "lodash-es";
import {
  KnownMeasureObservationCode,
  KnownPatientTagCodes,
  ObservationCode
} from "./observation";

export const isKnownTag: (tag: string) => tag is KnownPatientTagCodes =
  isSomeEnum(KnownPatientTagCodes);

export const getPatientMeasureByType = (
  type: ObservationCode,
  patientMeasures?: PatientMeasure[]
): PatientMeasure[] => {
  return patientMeasures?.filter(pm => pm.type === type) ?? [];
};

export const getCreatinineClearanceMeasures = (
  patientMeasures?: PatientMeasure[]
): PatientMeasure[] => {
  return getPatientMeasureByType(
    KnownMeasureObservationCode.CreatinineClearance,
    patientMeasures
  );
};

export const getFirstCreatinineClearanceMeasures = (
  patientMeasures?: PatientMeasure[]
): Optional<PatientMeasure> => {
  return head(getCreatinineClearanceMeasures(patientMeasures));
};
