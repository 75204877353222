import { ReactElement } from "@laba/react-common";
import { FormDataConsumer as ReactAdminFormDataConsumer } from "react-admin";
import React from "react";

export type FormDataConsumerContent<T, P = undefined> = (props: {
  formData: T;
  scopedFormData?: P;
  getSource: (source: string) => string;
}) => ReactElement;

export interface FormDataConsumerProps<T, P = undefined> {
  children: FormDataConsumerContent<T, P>;
}

export const FormDataConsumer = <T, P = undefined>({
  children,
  ...props
}: FormDataConsumerProps<T, P>): ReactElement => {
  return (
    <ReactAdminFormDataConsumer<T> {...props}>
      {({ scopedFormData, formData, getSource }) => {
        return children({
          formData,
          scopedFormData,
          getSource: source => getSource?.(source) ?? source
        });
      }}
    </ReactAdminFormDataConsumer>
  );
};
