import { ThemeProvider } from "@material-ui/core/styles";
import { useMuiTheme } from "model/useMuiTheme";
import {
  FC,
  getClassName,
  IconC,
  OnBlurEvent,
  stopPropagationWrapper,
  TypeVariant
} from "@laba/react-common";
import React from "react";
import { StyleVariant } from "model/themeVariant";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import {
  applyFormatToDate,
  currentLocale,
  DateFormat,
  DateTime
} from "@laba/ts-common";
import { FormControl, FormHelperText } from "@material-ui/core";
import { AccessAlarmIcon, CloseIcon } from "components/icons";
import {
  useDateEndIconStyle,
  useDateInputEndButtonStyles,
  useDateInputStyles,
  useDateOutlinedInputStyles,
  useInputLabelStyle
} from "components/inputs/DateInput/DateInputStyle";

type TimeInputTypeVariant = TypeVariant.Outlined | TypeVariant.Contained;

export interface TimeInputProps {
  style?: StyleVariant;
  className?: string;
  value?: DateTime;
  onChange?: (d?: DateTime) => void;
  onBlur?: OnBlurEvent<HTMLInputElement | HTMLTextAreaElement>;
  disabled?: boolean;
  errorText?: string;
  helperText?: string;
  showError?: boolean;
  showHelperOrErrorText?: boolean;
  fullWidth?: boolean;
  label?: string;
  EndIcon?: IconC;
  placeholder?: string;
  variant?: TimeInputTypeVariant;
  clearable?: boolean;
  clearText?: string;
  timeFormat?: DateFormat;
}

export const TimeInput: FC<TimeInputProps> = ({
  onChange,
  onBlur,
  className,
  errorText,
  helperText,
  value,
  label,
  placeholder,
  EndIcon = AccessAlarmIcon,
  fullWidth = false,
  disabled = false,
  style = StyleVariant.Primary,
  showError = true,
  showHelperOrErrorText = true,
  variant = TypeVariant.Outlined,
  clearable = false,
  clearText,
  timeFormat = DateFormat.NoSecTime
}) => {
  const muiTheme = useMuiTheme(style);
  const hasError = showError && Boolean(errorText);
  const showableHelperText = (hasError ? errorText : helperText) || "";
  const classes = useDateInputStyles({ hasError, fullWidth });
  const labelClasses = useInputLabelStyle();
  const outlinedInputClasses = useDateOutlinedInputStyles({
    disabled,
    variant
  });
  const endIconClasses = useDateEndIconStyle({ style });
  const endButtonClasses = useDateInputEndButtonStyles({ disabled, hasError });
  return (
    <ThemeProvider theme={muiTheme}>
      <MuiPickersUtilsProvider locale={currentLocale} utils={LuxonUtils}>
        <FormControl className={getClassName(classes.container, className)}>
          <KeyboardTimePicker
            color="primary"
            disabled={disabled}
            variant="inline"
            inputVariant="outlined"
            label={label}
            InputLabelProps={{
              classes: labelClasses
            }}
            InputProps={{
              classes: outlinedInputClasses
            }}
            keyboardIcon={
              <>
                {clearable && value && !disabled && (
                  <CloseIcon
                    onClick={stopPropagationWrapper(() =>
                      onChange?.(undefined)
                    )}
                    titleAccess={clearText}
                    className={endButtonClasses.closeIcon}
                  />
                )}
                <EndIcon className={endButtonClasses.endButton} />
              </>
            }
            KeyboardButtonProps={{ edge: "end", classes: endIconClasses }}
            format={timeFormat}
            value={value ?? null}
            onChange={newValue => {
              onChange?.(newValue ?? undefined);
            }}
            onBlur={onBlur}
            disableToolbar
            ampm={false}
            autoOk
            size="small"
            error={hasError}
            helperText={null}
            fullWidth={fullWidth}
            labelFunc={(date, invalidLabel) => {
              return (
                applyFormatToDate(
                  date ?? undefined,
                  `${timeFormat} 'HS'`,
                  invalidLabel
                ) || ""
              );
            }}
            placeholder={placeholder}
            title={applyFormatToDate(value, `${timeFormat} 'HS'`, "-")}
          />
          {showHelperOrErrorText && (
            <FormHelperText className={classes.errorText} margin="dense">
              {showableHelperText}
            </FormHelperText>
          )}
        </FormControl>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};
