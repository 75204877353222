import { FC, useI18n } from "@laba/react-common";
import { ReferenceManyResourceInput } from "components/generic/ReferenceManyResourceInput/ReferenceManyResourceInput";
import {
  ModelId,
  NotificationParamsKey,
  WhatsappMessageType,
  WhatsappNotification,
  WhatsappNotificationLastVersion,
  WhatsappNotificationLastVersionParamsKey,
  WhatsappNotificationParamsKey,
  WhatsappNotificationStatus
} from "@laba/nexup-api";
import { TextField } from "components/generic/TextField/TextField";
import { DateField } from "components/generic/DateField/DateField";
import React, { useMemo } from "react";
import { tkCP } from "translation/i18n";
import { ExternalResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { TextInput } from "components/generic/TextInput/TextInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { DateTimeInput } from "components/generic/DateTimeInput/DateTimeInput";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { DeepPartial } from "@laba/ts-common";
import { isEmpty } from "lodash-es";
import { FunctionField } from "components/generic/FunctionField/FunctionField";
import { whatsappNotificationTransform } from "../whatsappNotification/utils";

export const tk = tkCP.adminPage[ExternalResourceType.Notification];

export const NotificationWhatsappListContent: FC = () => {
  const { t } = useI18n();

  const notificationId = useWatchResourceForm<ModelId>(
    NotificationParamsKey.id
  );

  const defaultValue = useMemo<DeepPartial<WhatsappNotification>>(
    () => ({
      notification: notificationId,
      status: WhatsappNotificationStatus.Pending,
      messageType: WhatsappMessageType.Text
    }),
    [notificationId]
  );

  return (
    <ReferenceManyResourceInput<WhatsappNotificationLastVersion>
      resourceType={ExternalResourceType.WhatsappNotification}
      resourceSource={WhatsappNotificationParamsKey.notification}
      label={t(tk.fields.whatsappNotificationList)}
      resourceTransformer={whatsappNotificationTransform}
      canCreate={!isEmpty(notificationId)}
      defaultCreateValue={defaultValue}
      listFieldList={[
        <FunctionField<WhatsappNotificationLastVersion>
          label={t(tk.fields.whatsappNotificationId)}
          render={record => `${record?.id} (version: ${record?.version})`}
        />,
        <TextField
          key={WhatsappNotificationParamsKey.message}
          source={WhatsappNotificationParamsKey.message}
          label={t(tk.fields.whatsappNotificationMessage)}
        />,
        <TextField
          key={WhatsappNotificationParamsKey.status}
          source={WhatsappNotificationParamsKey.status}
          label={t(tk.fields.whatsappNotificationStatus)}
        />,
        <TextField
          key={WhatsappNotificationParamsKey.externalId}
          source={WhatsappNotificationParamsKey.externalId}
          label={t(tk.fields.whatsappNotificationExternalId)}
        />,
        <DateField
          key={WhatsappNotificationParamsKey.lastUpdatedDate}
          source={WhatsappNotificationParamsKey.lastUpdatedDate}
          label={t(tk.fields.whatsappNotificationLastUpdated)}
          showTime
        />
      ]}
      editInputList={[
        <TextInput
          disabled
          key={WhatsappNotificationParamsKey.id}
          source={WhatsappNotificationParamsKey.id}
          label={t(tk.fields.whatsappNotificationId)}
        />,
        <TextInput
          disabled
          key={WhatsappNotificationParamsKey.version}
          source={WhatsappNotificationParamsKey.version}
          label={t(tk.fields.whatsappNotificationVersion)}
        />,
        <TextInput
          required
          key={WhatsappNotificationParamsKey.message}
          source={WhatsappNotificationParamsKey.message}
          label={t(tk.fields.whatsappNotificationMessage)}
          multiline
        />,
        <EnumSelectorInput
          required
          key={WhatsappNotificationParamsKey.messageType}
          source={WhatsappNotificationParamsKey.messageType}
          label={t(tk.fields.whatsappNotificationMessageType)}
          enumValue={WhatsappMessageType}
        />,
        <EnumSelectorInput
          required
          key={WhatsappNotificationParamsKey.status}
          source={WhatsappNotificationParamsKey.status}
          label={t(tk.fields.whatsappNotificationStatus)}
          enumValue={WhatsappNotificationStatus}
        />,
        <TextInput
          key={WhatsappNotificationParamsKey.externalId}
          source={WhatsappNotificationParamsKey.externalId}
          label={t(tk.fields.whatsappNotificationExternalId)}
        />,
        <DateTimeInput
          disabled
          key={WhatsappNotificationParamsKey.lastUpdatedDate}
          source={WhatsappNotificationParamsKey.lastUpdatedDate}
          label={t(tk.fields.whatsappNotificationLastUpdated)}
        />,
        <DateTimeInput
          disabled
          key={WhatsappNotificationLastVersionParamsKey.creationDate}
          source={WhatsappNotificationLastVersionParamsKey.creationDate}
          label={t(tk.fields.whatsappNotificationCreationUpdated)}
        />
      ]}
    />
  );
};
