import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { StyleVariant, TextVariant } from "model/themeVariant";
import { Theme } from "model/theme";
import { createStyle, marginMixin } from "@laba/react-common";

interface Props {
  fullWidth?: boolean;
}
export const useFormErrorStyles = createStyle(
  (theme: Theme) => ({
    error: (props: Props) => ({
      width: props.fullWidth ? "100%" : "auto",
      color: getThemeStyleColor(theme, StyleVariant.Danger),
      ...themeTextMixin(theme, TextVariant.Body2),
      ...marginMixin({ bottom: 8, top: 8 })
    })
  }),
  "FormError"
);
