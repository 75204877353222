import {
  getDefaultJsonToModel,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";
import { User } from "@laba/nexup-api";
import {
  cleanEmptyString,
  ResourceForm
} from "components/generic/ResourceForm/ResourceForm";
import { tkCP } from "translation/i18n";
import { FC, useI18n } from "@laba/react-common";
import { UserMainContent } from "components/pages/NexupAdmin/resources/user/UserMainContent";
import React from "react";
import { ResourceTransform } from "components/generic/Create/Create";
import { UserResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";

export const userJsonToModel: JsonToModel<User> = getDefaultJsonToModel();
export const userTransform: ResourceTransform<User> = resource => {
  return cleanEmptyString(resource);
};

export const tk = tkCP.adminPage[UserResourceType];

export const UserForm: FC = () => {
  const { t } = useI18n();

  return (
    <ResourceForm
      tabsConfig={[
        {
          label: t(tk.tabs.main),
          content: <UserMainContent />
        }
      ]}
    />
  );
};
