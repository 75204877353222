import React from "react";
import { FC, useI18n } from "@laba/react-common";
import { MedicalRequestDefinitionKey, ResourceType } from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { EmailConfigInput } from "components/generic/EmailConfigInput/EmailConfigInput";

export const tk = tkCP.adminPage[ResourceType.MedicalRequestDefinition];
export const MedicalRequestDefinitionEmailContent: FC = () => {
  const { t } = useI18n();
  return (
    <EmailConfigInput
      source={MedicalRequestDefinitionKey.emailConfig}
      label={t(tk.fields.emailConfig)}
    />
  );
};
