import { getTk, I18nT, initI18n } from "@laba/react-common";
import { es } from "./es";
import { Languages, Modules, Namespaces, Sections } from "./constants";

const resources = {
  [Languages.Es]: {
    [Namespaces.Translation]: {
      ...es
    }
  }
};

const tPromise = initI18n(resources);

export const getT = (): Promise<I18nT> => {
  return tPromise;
};

const tk = getTk(es);

export const tkC = tk[Modules.Common];

export const tkCP = tkC[Sections.Page];
export const tkCC = tkC[Sections.Component];
