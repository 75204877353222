import { ModelId } from "model/primitives/model/model";
import { HttpMethod, RequestFailureStatus } from "@laba/ts-common";
import { AppConfig } from "model/resource/app/appConfig";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { ApiRequestResponse } from "request/types";
import { fixParseOrganization } from "api/entities/organization";
import { produce } from "immer";

interface AppConfigQueryParams {
  organization?: ModelId;
  location?: ModelId;
}

export const getAppConfig = async (
  params?: AppConfigQueryParams
): Promise<ApiRequestResponse<AppConfig>> => {
  const response: ApiRequestResponse<AppConfig> = await request({
    method: HttpMethod.GET,
    url: getFrontApiUrl("/app-config"),
    params
  });
  if (response.failureStatus === RequestFailureStatus.Failure) return response;
  return produce(response, draftResponse => {
    const parsedOrganization = fixParseOrganization(
      draftResponse.data.organization
    );
    draftResponse.data.organization = parsedOrganization;
  });
};
