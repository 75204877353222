import React from "react";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import { Slider } from "@material-ui/core";
import { Noop } from "@laba/ts-common";
import { FC, getClassName } from "@laba/react-common";
import {
  useSliderStyles,
  useVolumeControlButtonStyles
} from "components/videoControlsOverlay/VolumeControlButton/useVolumeControlButtonStyles";
import { isNaN } from "lodash-es";
import { useOpenSliderTransition } from "components/videoControlsOverlay/VolumeControlButton/useOpenSliderTransition";
import { NexupIconButton } from "components/buttons/IconButton/IconButton";

interface Props {
  className?: string;
  onToggleMute: Noop;
  volume?: number;
  maxVolume?: number;
  isMuted?: boolean;
  onChangeVolume: (newVol: number) => void;
  muteText: string;
  unmuteText: string;
}
export const VolumeControlButton: FC<Props> = ({
  className,
  onToggleMute,
  isMuted,
  volume,
  maxVolume = 100,
  onChangeVolume,
  muteText,
  unmuteText
}) => {
  const classes = useVolumeControlButtonStyles();
  const sliderClasses = useSliderStyles();
  const getSliderContainerClassName = ({ isOpen }: { isOpen: boolean }) =>
    getClassName(classes.sliderContainer, isOpen ? classes.open : undefined);
  const handleVolumeChange = (
    _e: React.ChangeEvent<unknown>,
    n: number | number[]
  ) => {
    isMuted && onToggleMute();
    if (typeof n === "number") {
      isNaN(n) ? onChangeVolume(0) : onChangeVolume(n);
    } else {
      onChangeVolume(n[0] ?? 0);
    }
  };

  const [sliderIsOpen, setSliderIsOpen, delayedSliderIsOpen] =
    useOpenSliderTransition(500);

  return (
    <div
      className={getClassName(className, classes.root)}
      onMouseEnter={() => setSliderIsOpen(true)}
      onMouseLeave={() => setSliderIsOpen(false)}
    >
      <NexupIconButton
        onClick={onToggleMute}
        title={isMuted ? unmuteText : muteText}
        Icon={isMuted ? VolumeOffIcon : VolumeUpIcon}
        className={delayedSliderIsOpen ? classes.sliderIsOpen : undefined}
      />
      <div className={getSliderContainerClassName({ isOpen: sliderIsOpen })}>
        <Slider
          orientation="vertical"
          min={0}
          max={maxVolume}
          onChange={handleVolumeChange}
          value={isMuted ? 0 : volume}
          classes={sliderClasses}
        />
      </div>
    </div>
  );
};
