import React from "react";
import { FC, useI18n } from "@laba/react-common";
import { Practitioner, ResourceType, UserKey } from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { UserResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import { practitionerOptionText } from "components/generic/ReferenceResourceInput/optionText";

export const tk = tkCP.adminPage[UserResourceType];
export const UserMainContent: FC = () => {
  const { t } = useI18n();

  return (
    <>
      <TextInput disabled source={UserKey.id} label={t(tk.fields.id)} />
      <TextInput source={UserKey.username} label={t(tk.fields.username)} />
      <TextInput source={UserKey.email} label={t(tk.fields.email)} />
      <TextInput source={UserKey.password} label={t(tk.fields.password)} />
      <BooleanInput
        source={UserKey.enabled}
        label={t(tk.fields.enabled)}
        defaultValue
      />
      <TextInput source={UserKey.name} label={t(tk.fields.name)} />
      <TextInput source={UserKey.surname} label={t(tk.fields.surname)} />
      <BooleanInput
        source={UserKey.autogenerated}
        label={t(tk.fields.autogenerated)}
      />
      <ArrayInput
        source={UserKey.assignedTo}
        label={t(tk.fields.assignedTo)}
        disableAdd
        disableRemove
        disableReordering
      >
        <ReferenceResourceInput<Practitioner>
          source=""
          label={t(tk.fields.assignedToElement)}
          resourceType={ResourceType.Practitioner}
          optionTextKey={practitionerOptionText}
          disabled
        />
      </ArrayInput>
    </>
  );
};
