import { useRecordContext } from "react-admin";
import { ResourceModel, ResourceType } from "@laba/nexup-api";
import { Optional } from "@laba/ts-common";
import { useWatch } from "react-hook-form";

export const useResourceContext = <
  T extends ResourceModel<ResourceType>
>(): Optional<T> => useRecordContext<T>();

export const useWatchResourceForm = <T>(key: string): Optional<T> =>
  useWatch({
    name: key
  });
