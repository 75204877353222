import { MutableRefObject, useCallback, useMemo, useState } from "react";
import { HTMLDivElementOrNull, useIsDivOverflow } from "@laba/react-common";
import { Optional } from "@laba/ts-common";
import { NexupColor } from "model/nexupColor";
import { isEmpty, isEqual, isUndefined, size } from "lodash-es";
import { ColorTagItem } from "components/tags/ColorTag/ColorTag";

const getPlusTag = (
  tags?: ColorTagItem[],
  visibleTags?: ColorTagItem[]
): Optional<ColorTagItem> => {
  const visibleTagsSize = size(visibleTags);
  const notVisibleTags = tags?.slice(visibleTagsSize);
  const notVisibleTagsSize = size(notVisibleTags);

  if (isUndefined(notVisibleTagsSize) || isEqual(notVisibleTagsSize, 0))
    return undefined;

  const tagPlusText = ["+", notVisibleTagsSize].join("");

  return {
    text: tagPlusText,
    backgroundColor: NexupColor.MediumBlue,
    title: notVisibleTags?.map(tag => tag.title).join(", ")
  };
};

interface DynamicTagList {
  ref: MutableRefObject<HTMLDivElementOrNull>;
  tagsList: ColorTagItem[];
}

export const useDynamicTagList = (
  tags: ColorTagItem[],
  keepIfSingle = false
): DynamicTagList => {
  const [visibleTags, setVisibleTags] = useState<ColorTagItem[]>(tags);
  const [hadOverflow, setHadOverflow] = useState(false);

  const plusTag = getPlusTag(tags, visibleTags);

  const onTagsOverflow = useCallback(
    (isOverflow: boolean) => {
      if (isOverflow && !isEmpty(visibleTags)) {
        setHadOverflow(true);
        setVisibleTags(tagsList => tags.slice(0, size(tagsList) - 1));
      }
      if (!isOverflow && isEmpty(visibleTags) && !hadOverflow) {
        setVisibleTags(tags);
      }
    },
    [tags, visibleTags, hadOverflow, setHadOverflow]
  );

  const { ref } = useIsDivOverflow(onTagsOverflow);

  const dynamicTagList = useMemo(() => {
    return plusTag ? [...visibleTags, plusTag] : visibleTags;
  }, [plusTag, visibleTags]);

  const tagListSize = tags.length;
  const tagsList = keepIfSingle && tagListSize <= 1 ? tags : dynamicTagList;

  return useMemo(() => {
    return { ref, tagsList };
  }, [ref, tagsList]);
};
