import {
  Code,
  Concept,
  ConceptProperty,
  getConceptPropertyCodeList,
  KnownConceptPropertyUse,
  ResourceConcept
} from "model/resource/entities/codeSystem";
import { getKeyObj, notUndefined } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { isEmpty } from "lodash-es";

export interface ProcedureBatteryConcept extends ResourceConcept {
  procedure: Code[];
  tag: Code[];
}

export const ProcedureBatteryConceptKey = getKeyObj<ProcedureBatteryConcept>(
  createHydratedMock<ProcedureBatteryConcept>()
);

export const procedureBatteryConceptMapper = (
  concept: Concept
): ProcedureBatteryConcept => {
  return {
    id: concept.code,
    display: concept.display ?? "",
    procedure: getConceptPropertyCodeList(
      KnownConceptPropertyUse.ProcedureCode,
      concept
    ),
    tag: getConceptPropertyCodeList(
      KnownConceptPropertyUse.ProcedureCodeTag,
      concept
    )
  };
};

const getProcedureCodeConceptPropertyCodeValueList = (
  use: KnownConceptPropertyUse,
  value?: Code[]
): ConceptProperty[] => {
  if (value === undefined || isEmpty(value)) return [];

  return value
    .map(v => {
      return v
        ? {
            use,
            code: v
          }
        : undefined;
    })
    .filter(notUndefined);
};

const getConceptProcedureCodeConceptList = (
  procedureBatteryConcept: ProcedureBatteryConcept
): ConceptProperty[] => {
  return [
    ...getProcedureCodeConceptPropertyCodeValueList(
      KnownConceptPropertyUse.ProcedureCode,
      procedureBatteryConcept.procedure
    ),
    ...getProcedureCodeConceptPropertyCodeValueList(
      KnownConceptPropertyUse.ProcedureCodeTag,
      procedureBatteryConcept.tag
    )
  ].filter(notUndefined);
};

export const conceptProcedureBatteryConceptMapper = (
  procedureBatteryConcept: ProcedureBatteryConcept
): Concept => {
  return {
    display: procedureBatteryConcept.display,
    code: procedureBatteryConcept.id ?? "",
    property: getConceptProcedureCodeConceptList(procedureBatteryConcept)
  };
};
