import { ResourceType } from "model/primitives/resourceModel";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { ApiDate, getKeyObj } from "@laba/ts-common";
import { MedicalRequest } from "model/resource/prescription/medicalRequest/medicalRequest";
import { MedicationRequest } from "model/resource/prescription/medicationRequest/medicationRequest";
import { createHydratedMock } from "ts-auto-mock";
import { Attachment } from "model/primitives/attachment/attachment";

export interface PrescriptionRequestFooter {
  leftText?: string;
  centerText?: string;
}

export interface PrescriptionRequest
  extends MedicationRequest<ResourceType.PrescriptionRequest> {
  prescriptionDate?: ApiDate;
  medicalRequest?: ModelReference<MedicalRequest>;
  text?: string;
  medicalRequestVersionId?: number;
  patientInstruction?: string;
  file?: Attachment;
  footer?: PrescriptionRequestFooter;
}

export const PrescriptionRequestDataFieldValueKey =
  getKeyObj<PrescriptionRequest>(createHydratedMock<PrescriptionRequest>());
