import { isEmpty } from "lodash-es";
import { Optional } from "model/types";

export enum Header {
  ContentType = "content-type",
  ContentDisposition = "content-disposition",
  ContentLength = "content-length",
  Authorization = "authorization",
  AcceptLanguage = "accept-language",
  AppVersion = "X-App-Version",
  DeviceType = "X-Device-Type"
}
export enum ContentTypeHeader {
  Pdf = "application/pdf",
  Json = "application/json",
  FormUrlencoded = "application/x-www-form-urlencoded",
  OctetStream = "application/octet-stream",
  Multipart = "multipart/form-data",
  Jpg = "image/jpg",
  Jpeg = "image/jpeg",
  Png = "image/png",
  Xml = "text/xml; charset=utf-8"
}

export const getFileContentDisposition = (filename?: string): string =>
  filename ? `attachment; filename="${filename}"` : "attachment";

export const getAuthorizationBearer = (
  token?: string,
  bearerKey?: string
): Optional<string> =>
  isEmpty(token) ? "" : `${bearerKey ?? "Bearer"} ${token}`;
