import { BaseVersionedModel } from "model/baseVersionedModel";
import { ModelId } from "model/model";
import { Optional } from "model/types";

export type VersionedModelOrReference<T extends BaseVersionedModel> =
  | ModelId
  | T;

export const isVersionedModel = <T extends BaseVersionedModel>(
  model: unknown
): model is T => {
  return typeof model === "object";
};

export const getVersionedModelReferenceId = <T extends BaseVersionedModel>(
  modelReference?: VersionedModelOrReference<T>
): ModelId | undefined => {
  if (!modelReference) return undefined;
  if (isVersionedModel(modelReference)) {
    return modelReference.id;
  }
  return modelReference;
};

export const getVersionedModelOrUndefined = <T extends BaseVersionedModel>(
  modelReference?: VersionedModelOrReference<T>
): Optional<T> => {
  return isVersionedModel(modelReference) ? modelReference : undefined;
};

export const versionedModelReferenceListAreModel = <
  T extends BaseVersionedModel
>(
  modelReferenceArray: VersionedModelOrReference<T>[]
): modelReferenceArray is T[] => modelReferenceArray.every(isVersionedModel);
