import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  Day,
  HoursOfOperationKey,
  LocationKey,
  ResourceType
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";

export const tk = tkCP.adminPage[ResourceType.Location];
export const LocationDirectoryHoursContent: FC = () => {
  const { t } = useI18n();
  return (
    <>
      <ArrayInput
        source={LocationKey.hoursOfOperation}
        label={t(tk.fields.hoursOfOperation)}
      >
        <ArrayInput
          source={HoursOfOperationKey.daysOfWeek}
          label={t(tk.fields.hoursOfOperationDaysOfWeek)}
        >
          <EnumSelectorInput
            source=""
            label={t(tk.fields.hoursOfOperationDaysOfWeekElement)}
            enumValue={Day}
          />
        </ArrayInput>
        <BooleanInput
          source={HoursOfOperationKey.allDay}
          label={t(tk.fields.hoursOfOperationAllDay)}
        />
        <TextInput
          source={HoursOfOperationKey.openingTime}
          label={t(tk.fields.hoursOfOperationOpeningTime)}
        />
        <TextInput
          source={HoursOfOperationKey.closingTime}
          label={t(tk.fields.hoursOfOperationClosingTime)}
        />
      </ArrayInput>
      <TextInput
        source={LocationKey.availabilityExceptions}
        label={t(tk.fields.availabilityExceptions)}
      />
    </>
  );
};
