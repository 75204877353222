import { FC, useI18n } from "@laba/react-common";
import React from "react";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { TextInput } from "components/generic/TextInput/TextInput";
import { AttachmentKey, ModelId } from "@laba/nexup-api";
import { tkCC } from "translation/i18n";
import { UploadFileImageInput } from "components/generic/UploadFileImageInput/UploadFileImageInput";

const tk = tkCC.inputs.identifierFilesInput;

export interface IdentifierFilesInputProps {
  source: string;
  organization?: ModelId;
  titleLabel?: string;
  attachmentNameLabel?: string;
  attachmentURLLabel?: string;
}
export const IdentifierFilesInput: FC<IdentifierFilesInputProps> = ({
  source,
  titleLabel,
  attachmentNameLabel,
  attachmentURLLabel,
  organization
}) => {
  const { t } = useI18n();
  return (
    <ArrayInput source={source} label={titleLabel ?? t(tk.title)}>
      <TextInput
        source={AttachmentKey.name}
        label={attachmentNameLabel ?? t(tk.attachmentName)}
        required
      />
      <UploadFileImageInput
        source={AttachmentKey.url}
        label={attachmentURLLabel ?? t(tk.attachmentURL)}
        organization={organization}
        required
      />
    </ArrayInput>
  );
};
