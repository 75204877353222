import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  paddingMixin,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

export const useTagWithPeriodStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexColumnMixin({
        gap: 4,
        alignItems: FlexAlignItems.FlexStart
      }),
      ...paddingMixin({ right: 8 })
    },
    dateContainer: {
      ...flexColumnMixin({ gap: 2 })
    },
    dateText: {
      ...themeTextMixin(theme, TextVariant.Caption, 1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayLight
      )
    }
  }),
  "TagWithPeriod"
);
