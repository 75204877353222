const timeoutPromise = <T>(ms: number, timeoutError: Error): Promise<T> =>
  new Promise<T>((_, reject) => {
    setTimeout(() => reject(timeoutError), ms);
  });

export const withTimeout = <T>(
  asyncFn: () => Promise<T>,
  ms: number,
  timeoutError: Error = new Error(`Timeout of ${ms}ms exceeded`)
): Promise<T> => Promise.race([asyncFn(), timeoutPromise<T>(ms, timeoutError)]);
