import { notUndefined, Optional } from "@laba/ts-common";
import { Email, Phone } from "model/primitives";
import { OrganizationPublicData } from "./publicOrganization";

export const getPublicOrganizationName = (
  organizationPublicData?: OrganizationPublicData
): Optional<string> => organizationPublicData?.name;

const getFirstNotUndefinedEmailObjectFromOrganization = (
  organizationPublicData?: OrganizationPublicData
): Optional<Email> => organizationPublicData?.email?.find(notUndefined);

const getFirstNotUndefinedPhoneObjectFromOrganization = (
  organizationPublicData?: OrganizationPublicData
): Optional<Phone> => organizationPublicData?.phone?.find(notUndefined);

export const getPublicOrganizationEmail = (
  organizationPublicData?: OrganizationPublicData
): Optional<string> => {
  return getFirstNotUndefinedEmailObjectFromOrganization(organizationPublicData)
    ?.value;
};

export const getPublicOrganizationPhone = (
  organizationPublicData?: OrganizationPublicData
): Optional<string> => {
  return getFirstNotUndefinedPhoneObjectFromOrganization(organizationPublicData)
    ?.value;
};
