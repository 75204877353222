import {
  getDefaultJsonToModel,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";
import { MedicalRequestDefinition, ResourceType } from "@laba/nexup-api";
import {
  getDefaultResourceTransform,
  ResourceForm
} from "components/generic/ResourceForm/ResourceForm";
import { tkCP } from "translation/i18n";
import { FC, useI18n } from "@laba/react-common";
import React from "react";
import { MedicalRequestDefinitionMainContent } from "components/pages/NexupAdmin/resources/medicalRequestDefinition/MedicalRequestDefinitionMainContent";
import { MedicalRequestDefinitionMedicalConfigurationContent } from "components/pages/NexupAdmin/resources/medicalRequestDefinition/MedicalRequestDefinitionMedicalConfigurationContent";
import { MedicalRequestDefinitionDeviceContent } from "components/pages/NexupAdmin/resources/medicalRequestDefinition/MedicalRequestDefinitionDeviceContent";
import { MedicalRequestDefinitionEmailContent } from "components/pages/NexupAdmin/resources/medicalRequestDefinition/MedicalRequestDefinitionEmailContent";
import { MedicalRequestDefinitionFieldsContent } from "components/pages/NexupAdmin/resources/medicalRequestDefinition/MedicalRequestDefinitionFieldsContent";
import { MedicalRequestDefinitionFieldContent } from "components/pages/NexupAdmin/resources/medicalRequestDefinition/MedicalRequestDefinitionFieldContent";
import { MedicalRequestDefinitionFieldsCompleteContent } from "components/pages/NexupAdmin/resources/medicalRequestDefinition/MedicalRequestDefinitionFieldsCompleteContent";
import { MedicalRequestDefinitionPhoneContent } from "./MedicalRequestDefinitionPhoneContent";

export const medicalRequestDefinitionJsonToModel: JsonToModel<MedicalRequestDefinition> =
  getDefaultJsonToModel(draft => {
    draft.organization = undefined;
    draft.medicalDevice = [];
    draft.procedureReportDefinition = undefined;
  });

export const medicalRequestDefinitionTransform =
  getDefaultResourceTransform<MedicalRequestDefinition>(
    ResourceType.MedicalRequestDefinition
  );

export const tk = tkCP.adminPage[ResourceType.MedicalRequestDefinition];

export const MedicalRequestDefinitionForm: FC = () => {
  const { t } = useI18n();

  return (
    <ResourceForm
      tabsConfig={[
        {
          label: t(tk.tabs.main),
          content: <MedicalRequestDefinitionMainContent />
        },
        {
          label: t(tk.tabs.medicalConfiguration),
          content: <MedicalRequestDefinitionMedicalConfigurationContent />
        },
        {
          label: t(tk.tabs.device),
          content: <MedicalRequestDefinitionDeviceContent />
        },
        {
          label: t(tk.tabs.email),
          content: <MedicalRequestDefinitionEmailContent />
        },
        {
          label: t(tk.tabs.phone),
          content: <MedicalRequestDefinitionPhoneContent />
        },
        {
          label: t(tk.tabs.fields),
          content: <MedicalRequestDefinitionFieldsContent />
        },
        {
          label: t(tk.tabs.fieldDetail),
          content: <MedicalRequestDefinitionFieldContent />
        },
        {
          label: t(tk.tabs.fieldsComplete),
          content: <MedicalRequestDefinitionFieldsCompleteContent />
        }
      ]}
    />
  );
};
