import { FC, getClassName, OnClickEvent } from "@laba/react-common";
import React from "react";
import { ButtonBase } from "components/buttons/ButtonBase/ButtonBase";
import { useAccordionItemStyles } from "./AccordionItemStyle";

interface Props {
  className?: string;
  itemName?: string;
  onClick?: OnClickEvent;
}

export const AccordionItem: FC<Props> = ({ className, itemName, onClick }) => {
  const classes = useAccordionItemStyles();

  return (
    <ButtonBase
      onClick={onClick}
      className={getClassName(classes.button, className)}
    >
      <p className={classes.item}>{itemName}</p>
    </ButtonBase>
  );
};
