import { useCallback, useEffect, useMemo, useState } from "react";
import {
  CountryAreaPhoneCode,
  getEnumOrUndefined,
  Optional
} from "@laba/ts-common";
import { isEmpty } from "lodash-es";
import {
  getCountryCodeFromCoordinates,
  getCountryCodeFromIPAddress,
  onGetCurrentPosition
} from "./helpers";

export const useGetUserCountryCode = (
  ipProviderApiKey?: string,
  onlyIp?: boolean
): Optional<CountryAreaPhoneCode> => {
  const [countryCode, setCountryCode] = useState<Optional<string>>();

  const getCountryCodeFromIp = useCallback(async () => {
    const code =
      ipProviderApiKey !== undefined && !isEmpty(ipProviderApiKey)
        ? await getCountryCodeFromIPAddress(ipProviderApiKey)
        : undefined;
    code && setCountryCode(code);
  }, [setCountryCode, ipProviderApiKey]);

  useEffect(() => {
    const init = async () => {
      if (onlyIp) {
        await getCountryCodeFromIp();
      } else {
        onGetCurrentPosition(
          async (position: GeolocationPosition) => {
            const code = await getCountryCodeFromCoordinates(position.coords);
            code && setCountryCode(code);
          },
          async () => {
            await getCountryCodeFromIp();
          }
        );
      }
    };
    init();
  }, [onlyIp, getCountryCodeFromIp]);

  return useMemo(
    () => getEnumOrUndefined(CountryAreaPhoneCode)(countryCode),
    [countryCode]
  );
};
