import { useState, useMemo } from "react";
import { useEffectSelective } from "hooks/useEffectSelective";
import { debounce } from "lodash-es";
import { Optional } from "@laba/ts-common";

export interface UseDebounceProps<T> {
  value?: T;
  onChange?: (val: T) => void;
  searchDebounceMs?: number;
}

export type UseDebounceReturn<T> = Omit<
  UseDebounceProps<T>,
  "searchDebounceMs"
>;

export const useDebounce = <T>({
  value,
  onChange,
  searchDebounceMs = 500
}: UseDebounceProps<T>): UseDebounceReturn<T> => {
  const [internalSearchText, setInternalSearchText] =
    useState<Optional<T>>(value);

  const debouncedFunction = useMemo(
    () => debounce((val: T) => onChange?.(val), searchDebounceMs),
    [onChange, searchDebounceMs]
  );

  useEffectSelective(
    () => {
      value !== internalSearchText && setInternalSearchText(value);
    },
    [value],
    [internalSearchText, setInternalSearchText]
  );

  const debouncedOnChange = (val: T) => {
    setInternalSearchText(val);
    debouncedFunction(val);
  };

  return { onChange: debouncedOnChange, value: internalSearchText };
};
