import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  KnownCodeSystemSystem,
  MedicalDeviceKey,
  MedicalDeviceListQueryParamsKey,
  MedicalDeviceStatus,
  MedicalDeviceTagKey,
  OrganizationCombinedType,
  OrganizationKey,
  OrganizationListQueryParamsKey,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { TextField } from "components/generic/TextField/TextField";
import { ReferenceResourceField } from "components/generic/ReferenceResourceField/ReferenceResourceField";
import { List } from "components/generic/List/List";
import { Datagrid } from "components/generic/Datagrid/Datagrid";
import { medicalDeviceJsonToModel } from "components/pages/NexupAdmin/resources/medicalDevice/MedicalDeviceForm";
import { ArrayField, ChipField, SingleFieldList } from "react-admin";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useListExportedFileName } from "components/hook/useExportedFileName";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

const tk = tkCP.adminPage[ResourceType.MedicalDevice];

export const MedicalDeviceList: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const typeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicalDeviceType,
    workspaceOrganizationId
  );
  const tagSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicalDeviceTag,
    workspaceOrganizationId
  );
  const exportedFileName = useListExportedFileName(t(tk.title));

  return (
    <List
      resource={ResourceType.MedicalDevice}
      exportedFileName={exportedFileName}
      jsonToModel={medicalDeviceJsonToModel}
      extraFilters={[
        workspaceOrganizationId === undefined ? (
          <OrganizationReferenceInput
            label={t(tk.filters.organization)}
            source={MedicalDeviceListQueryParamsKey.organization}
            alwaysOn
            type={OrganizationCombinedType.ProviderAndGroup}
            extraFilters={{
              [OrganizationListQueryParamsKey.active]: true
            }}
          />
        ) : undefined,
        <EnumSelectorInput
          source={MedicalDeviceListQueryParamsKey.status}
          label={t(tk.filters.status)}
          enumValue={MedicalDeviceStatus}
          alwaysOn
          resettable
        />,
        <CodeSystemSelectorInput
          source={MedicalDeviceListQueryParamsKey.type}
          label={t(tk.filters.type)}
          codeSystem={typeSystem}
          organizationId={workspaceOrganizationId}
          withCreate
          alwaysOn
          resettable
        />,
        <CodeSystemSelectorInput
          source={MedicalDeviceListQueryParamsKey.tags}
          label={t(tk.filters.tags)}
          codeSystem={tagSystem}
          organizationId={workspaceOrganizationId}
          alwaysOn
          resettable
          withCreate
        />
      ]}
    >
      <Datagrid>
        <TextField source={MedicalDeviceKey.id} label={t(tk.fields.id)} />
        <TextField source={MedicalDeviceKey.name} label={t(tk.fields.name)} />
        <ReferenceResourceField
          resourceType={ResourceType.Organization}
          label={t(tk.fields.organization)}
          source={MedicalDeviceKey.organization}
          optionTextKey={OrganizationKey.name}
        />
        <TextField
          source={MedicalDeviceKey.status}
          label={t(tk.fields.status)}
        />
        <TextField source={MedicalDeviceKey.type} label={t(tk.fields.type)} />
        <TextField
          source={MedicalDeviceKey.hisCode}
          label={t(tk.fields.hisCode)}
        />
        <ArrayField source={MedicalDeviceKey.tags} label={t(tk.fields.tags)}>
          <SingleFieldList>
            <ChipField source={MedicalDeviceTagKey.tag} />
          </SingleFieldList>
        </ArrayField>
      </Datagrid>
    </List>
  );
};
