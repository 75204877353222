import { Server } from "models/server/server";

export const loginPage = {
  validation: {
    required: "este campo es requerido",
    defaultError: "Existio un error en el pedido",
    invalidCredentials: "Existe un error en el usuario y/o contraseña",
    timeoutError: "Ocurrió un error, revisá tu conexión a internet"
  },
  backgroundAlt: "imagen de fondo",
  ingress: "Ingresar",
  usernameLabel: "Usuario",
  passwordLabel: "Contraseña",
  localUrlLabel: "Url Local",
  serverLabel: "Servidor",
  serverOptions: {
    [Server.Develop]: "Desarrollo",
    [Server.Staging]: "Staging / QA",
    [Server.Production]: "Produccion",
    [Server.Local]: "Local"
  },
  selectWorkspacePopUp: {
    title: "Seleccionar espacio de trabajo",
    select: "Seleccionar",
    selectAll: "Todos",
    workspacePlaceholder: "Seleccionar un espacio de trabajo o todos"
  }
};
