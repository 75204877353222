import {
  getDefaultJsonToModel,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";
import { Medication, ResourceType } from "@laba/nexup-api";
import {
  getDefaultResourceTransform,
  ResourceForm
} from "components/generic/ResourceForm/ResourceForm";
import { tkCP } from "translation/i18n";
import { FC, useI18n } from "@laba/react-common";
import { MedicationMainContent } from "components/pages/NexupAdmin/resources/medication/MedicationMainContent";
import React from "react";
import { MedicationMedicalInformationContent } from "components/pages/NexupAdmin/resources/medication/MedicationMedicalInformationContent";
import { MedicationIngredientContent } from "components/pages/NexupAdmin/resources/medication/MedicationIngredientContent";

export const medicationJsonToModel: JsonToModel<Medication> =
  getDefaultJsonToModel(draft => {
    draft.organization = undefined;
    draft.ingredient?.forEach(draftIngredient => {
      if (draftIngredient) draftIngredient.substance = undefined;
    });
  });

export const medicationTransform = getDefaultResourceTransform<Medication>(
  ResourceType.Medication
);

export const tk = tkCP.adminPage[ResourceType.Medication];

export const MedicationForm: FC = () => {
  const { t } = useI18n();

  return (
    <ResourceForm
      tabsConfig={[
        {
          label: t(tk.tabs.main),
          content: <MedicationMainContent />
        },
        {
          label: t(tk.tabs.medicalInformation),
          content: <MedicationMedicalInformationContent />
        },
        {
          label: t(tk.tabs.ingredient),
          content: <MedicationIngredientContent />
        }
      ]}
    />
  );
};
