import React from "react";
import { FC } from "@laba/react-common";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const StopCircleOutlinedIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm4-4H8V8h8v8z" />
    </SvgIcon>
  );
};
