import React from "react";
import { FC, useI18n } from "@laba/react-common";
import { Location, ResourceType } from "@laba/nexup-api";
import {
  getDefaultResourceTransform,
  ResourceForm
} from "components/generic/ResourceForm/ResourceForm";
import { tkCP } from "translation/i18n";
import { LocationMainContent } from "components/pages/NexupAdmin/resources/location/LocationMainContent";
import { LocationReportContent } from "components/pages/NexupAdmin/resources/location/LocationReportContent";
import { LocationDirectoryContent } from "components/pages/NexupAdmin/resources/location/LocationDirectoryContent";
import { LocationDirectoryHoursContent } from "components/pages/NexupAdmin/resources/location/LocationDirectoryHoursContent";
import { LocationDirectoryPositionContent } from "components/pages/NexupAdmin/resources/location/LocationDirectorPositionContent";
import {
  getDefaultJsonToModel,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";

export const locationTransform = getDefaultResourceTransform<Location>(
  ResourceType.Location
);
export const locationJsonToModel: JsonToModel<Location> = getDefaultJsonToModel(
  draft => {
    draft.partOf = undefined;
    draft.organization = undefined;
    draft.children = undefined;
  }
);
export const tk = tkCP.adminPage[ResourceType.Location];

export const LocationForm: FC = () => {
  const { t } = useI18n();
  return (
    <ResourceForm
      tabsConfig={[
        {
          label: t(tk.tabs.location),
          content: <LocationMainContent />
        },
        {
          label: t(tk.tabs.reports),
          content: <LocationReportContent />
        },
        {
          label: t(tk.tabs.directory),
          content: <LocationDirectoryContent />
        },
        {
          label: t(tk.tabs.directoryHours),
          content: <LocationDirectoryHoursContent />
        },
        {
          label: t(tk.tabs.directoryPosition),
          content: <LocationDirectoryPositionContent />
        }
      ]}
    />
  );
};
