import React from "react";
import { FC } from "@laba/react-common";
import { Create } from "components/generic/Create/Create";
import {
  PractitionerForm,
  practitionerJsonToModel,
  practitionerTransform
} from "components/pages/NexupAdmin/resources/practitioner/PractitionerForm";
import { ResourceType } from "@laba/nexup-api";

export const PractitionerCreate: FC = () => {
  return (
    <Create
      resourceTransform={practitionerTransform}
      resource={ResourceType.Practitioner}
      jsonToModel={practitionerJsonToModel}
    >
      <PractitionerForm />
    </Create>
  );
};
