import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  Organization,
  OrganizationCombinedType,
  OrganizationKey,
  OrganizationListQueryParamsKey,
  OrganizationType,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { TextField } from "components/generic/TextField/TextField";
import { FunctionField } from "components/generic/FunctionField/FunctionField";
import { ReferenceResourceField } from "components/generic/ReferenceResourceField/ReferenceResourceField";
import { isEmpty } from "lodash-es";
import { List } from "components/generic/List/List";
import { Datagrid } from "components/generic/Datagrid/Datagrid";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import {
  getOrganizationImportElementPreprocess,
  organizationJsonToModel
} from "components/pages/NexupAdmin/resources/organization/OrganizationForm";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useListExportedFileName } from "components/hook/useExportedFileName";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

const tk = tkCP.adminPage[ResourceType.Organization];

export const OrganizationList: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const exportedFileName = useListExportedFileName(t(tk.title));
  return (
    <List
      resource={ResourceType.Organization}
      exportedFileName={exportedFileName}
      jsonToModel={organizationJsonToModel}
      filter={{ workspaceOrganizationId }}
      importElementPreprocess={getOrganizationImportElementPreprocess(
        workspaceOrganizationId
      )}
      filterDefaultValues={{
        [OrganizationListQueryParamsKey.active]: true
      }}
      extraFilters={[
        workspaceOrganizationId === undefined ? (
          <OrganizationReferenceInput
            label={t(tk.filters.partOf)}
            source={OrganizationListQueryParamsKey.partOf}
            type={OrganizationCombinedType.ProviderAndGroup}
            extraFilters={{
              [OrganizationListQueryParamsKey.active]: true
            }}
            alwaysOn
          />
        ) : undefined,
        <EnumSelectorInput
          source={OrganizationListQueryParamsKey.type}
          label={t(tk.filters.type)}
          enumValue={OrganizationType}
          alwaysOn
          resettable
        />,
        <BooleanInput
          source={OrganizationListQueryParamsKey.active}
          label={t(tk.filters.active)}
          alwaysOn
        />,
        <BooleanInput
          source={OrganizationListQueryParamsKey.public}
          label={t(tk.filters.public)}
        />
      ]}
    >
      <Datagrid>
        <TextField source={OrganizationKey.id} label={t(tk.fields.id)} />
        <TextField source={OrganizationKey.name} label={t(tk.fields.name)} />
        <TextField source={OrganizationKey.type} label={t(tk.fields.type)} />
        <TextField
          source={OrganizationKey.hospitalizationType}
          label={t(tk.fields.hospitalizationType)}
        />
        <ReferenceResourceField
          resourceType={ResourceType.Organization}
          label={t(tk.fields.partOf)}
          source={OrganizationKey.partOf}
          optionTextKey={OrganizationKey.name}
        />
        <FunctionField<Organization>
          label={t(tk.fields.domainUrlList)}
          render={record => {
            return record?.domainUrlList
              ?.filter(value => !isEmpty(value))
              .join(" ");
          }}
        />
      </Datagrid>
    </List>
  );
};
