import { removePhoneCountryCode } from "phone/validation/utils";
import { CountryAreaPhoneCode } from "phone/countryAreaPhoneCode";
import { startsWith } from "lodash-es";

const argentinianPhone = (plainPhone: string) => {
  return `+549${plainPhone}`;
};

const argentinianPhoneWithoutInternalCode = (plainPhone: string) => {
  return `+54${plainPhone}`;
};

const removeCountryCodeAndInternalAreaCode = (phoneNumber: string) => {
  const phoneWithoutCountryCode = removePhoneCountryCode(
    phoneNumber,
    CountryAreaPhoneCode.Argentina
  );
  return startsWith(phoneWithoutCountryCode, "9")
    ? phoneWithoutCountryCode.slice(1)
    : phoneWithoutCountryCode;
};

const fixInvalidBsArAreaCode = (phoneWithoutCountryCode: string) => {
  if (startsWith(phoneWithoutCountryCode, "15")) {
    const plainPhone = phoneWithoutCountryCode.slice(2);
    return `11${plainPhone}`;
  }
  return phoneWithoutCountryCode;
};

const fixInvalidAdditionalZeroBsArAreaCode = (
  phoneWithoutCountryCode: string
) => {
  if (startsWith(phoneWithoutCountryCode, "011")) {
    const plainPhone = phoneWithoutCountryCode.slice(3);
    if (plainPhone.length >= 10) {
      return plainPhone;
    }
    return `11${plainPhone}`;
  }
  return phoneWithoutCountryCode;
};

export const removeArgentinianPhoneInternalAreaCode = (
  phoneNumber: string
): string => {
  const phoneWithoutCountryCode = removePhoneCountryCode(
    phoneNumber,
    CountryAreaPhoneCode.Argentina
  );
  const fixedNumber = startsWith(phoneWithoutCountryCode, "9")
    ? phoneWithoutCountryCode.slice(1)
    : phoneWithoutCountryCode;
  return argentinianPhoneWithoutInternalCode(fixedNumber);
};

export const fixArgentinianPhoneNumber = (
  phoneNumber: string,
  ignoreInternalCode = false
): string => {
  const phoneWithoutInternalCode =
    removeCountryCodeAndInternalAreaCode(phoneNumber);

  const phoneWithoutAdditionalZero = fixInvalidAdditionalZeroBsArAreaCode(
    phoneWithoutInternalCode
  );
  const phoneWithoutInvalidBsAsAreaCode = fixInvalidBsArAreaCode(
    phoneWithoutAdditionalZero
  );
  const fixedPhone = argentinianPhone(phoneWithoutInvalidBsAsAreaCode);

  return ignoreInternalCode
    ? removeArgentinianPhoneInternalAreaCode(fixedPhone)
    : fixedPhone;
};
