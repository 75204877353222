import { FC, ReactElement } from "@laba/react-common";
import { useEffect, useState, useCallback, useRef } from "react";
import {
  InfoWindowObject,
  MarkerObject,
  MarkerOptions
} from "components/maps/gmap/helpers/types";
import { Noop } from "@laba/ts-common";
import {
  getAnchorPoint,
  getMarker
} from "components/maps/gmap/helpers/getters";
import { GetDOMComponent } from "components/maps/gmap/helpers/getDOMComponent";

export interface MarkerProps extends MarkerOptions {
  onClick?: Noop;
  infoWindow?: InfoWindowObject;
  componentOnOpen?: ReactElement;
  isCurrentLocationMarker?: boolean;
}

export const Marker: FC<MarkerProps> = ({
  onClick,
  map,
  infoWindow,
  componentOnOpen,
  isCurrentLocationMarker,
  ...options
}) => {
  const [marker, setMarker] = useState<MarkerObject>();
  const refContainer = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!marker) {
      setMarker(getMarker());
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  const wrappedOnClick = useCallback(() => {
    if (
      infoWindow !== undefined &&
      componentOnOpen !== undefined &&
      refContainer.current != null
    ) {
      infoWindow.setContent(refContainer.current);
      infoWindow.open({
        anchor: marker,
        map
      });
    }
    onClick?.();
  }, [onClick, refContainer, map, marker, infoWindow, componentOnOpen]);

  useEffect(() => {
    if (marker) {
      const markerIsClickable =
        onClick !== undefined || componentOnOpen !== undefined;

      const icon = isCurrentLocationMarker
        ? {
            path: "M7.5 20.9986C6.23931 19.5636 5.07032 18.0506 4 16.4686C2.4 14.0986 0.5 10.7106 0.5 7.9986C0.499505 6.61377 0.909779 5.2599 1.67891 4.1083C2.44805 2.95669 3.54149 2.0591 4.82088 1.52908C6.10027 0.999061 7.50812 0.860433 8.86632 1.13074C10.2245 1.40104 11.472 2.06813 12.451 3.0476C13.1029 3.69641 13.6196 4.46806 13.9713 5.31789C14.3231 6.16773 14.5027 7.07887 14.5 7.9986C14.5 10.7106 12.6 14.0986 11 16.4676C9.92971 18.05 8.76073 19.5633 7.5 20.9986ZM7.5 5.4986C7.00555 5.4986 6.5222 5.64523 6.11107 5.91993C5.69995 6.19463 5.37952 6.58508 5.1903 7.04189C5.00108 7.49871 4.95157 8.00138 5.04804 8.48633C5.1445 8.97128 5.3826 9.41674 5.73223 9.76637C6.08186 10.116 6.52732 10.3541 7.01227 10.4506C7.49723 10.547 7.99989 10.4975 8.45671 10.3083C8.91352 10.1191 9.30397 9.79865 9.57867 9.38753C9.85338 8.97641 10 8.49306 10 7.9986C10 7.33556 9.73661 6.69968 9.26777 6.23084C8.79893 5.76199 8.16304 5.4986 7.5 5.4986Z",
            fillColor: "#165AB5",
            fillOpacity: 1,
            strokeOpacity: 0,
            scale: 1.5,
            anchor: getAnchorPoint(10, 30)
          }
        : undefined;

      marker.setOptions({
        ...options,
        map,
        clickable: markerIsClickable,
        icon
      });
      if (markerIsClickable) {
        marker.addListener("click", wrappedOnClick);
      }
    }
  }, [
    marker,
    map,
    options,
    onClick,
    wrappedOnClick,
    componentOnOpen,
    isCurrentLocationMarker
  ]);

  return GetDOMComponent({ refContainer, component: componentOnOpen });
};
