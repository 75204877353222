import {
  ResourceType,
  getMedicationList,
  getMedication,
  updateMedication,
  createMedication,
  MedicationListQueryParams,
  MedicationListQueryParamsKey,
  getModelReferenceId
} from "@laba/nexup-api";
import {
  getEnumOrderParam,
  getKeyTargetParam,
  resourceProviderGetter
} from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { workspaceOrganizationSelector } from "store/workspace/selectors";
import { store } from "store/store";

export const medicationProvider = resourceProviderGetter(
  ResourceType.Medication,
  getEnumOrderParam({}),
  getKeyTargetParam<MedicationListQueryParams>(MedicationListQueryParamsKey),
  getMedicationList,
  getMedication,
  updateMedication,
  createMedication,
  undefined,
  () => ({
    organization: getModelReferenceId(
      workspaceOrganizationSelector(store.getState())
    )
  })
);
