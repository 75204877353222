import React from "react";
import _ from "lodash-es";
import { OnBlurEvent, ReactElement } from "@laba/react-common";
import { SearchIcon } from "components/icons";
import {
  ModelAutocomplete,
  ModelAutocompleteOptionConfig
} from "components/autocomplete/ModelAutocomplete/ModelAutocomplete";
import { useSearchInputWithSuggestionsStyle } from "./SearchInputWithSuggestionsStyle";
import { SuggestedList } from "./SuggestedList/SuggestedList";
import { SuggestedItemConfig } from "./SuggestedList/SuggestedItem/config";

export type SearchInputWithSuggestionsOptionConfig<V> =
  ModelAutocompleteOptionConfig<V>;

export interface SearchInputWithSuggestionsProps<V> {
  className?: string;
  clearText: string;
  closeText: string;
  disabled?: boolean;
  errorText?: string;
  getOptionFromValue: (value: V) => SearchInputWithSuggestionsOptionConfig<V>;
  getValues: (searchText?: string) => Promise<V[]>;
  helperText?: string;
  id?: string;
  label?: string;
  loadingText: string;
  minInputForSearch?: number;
  noOptionsText: string;
  onBlur?: OnBlurEvent;
  onChange?: (val?: V) => void;
  openText: string;
  searchDebounceMs?: number;
  searchInputTitle?: string;
  showError?: boolean;
  suggestedListTitle?: string;
  value?: V;
  suggestedItems: SuggestedItemConfig<V>[];
  numberOfSuggestedItemsToShow: number;
  onSuggestedItemClick?: (itemValue?: SuggestedItemConfig<V>) => void;
  showLessText: string;
  showMoreText: string;
  minCharPromptText: string;
}

export const SearchInputWithSuggestions = <V,>({
  className,
  clearText,
  closeText,
  disabled,
  errorText,
  getOptionFromValue,
  getValues,
  helperText,
  id,
  label,
  loadingText,
  minInputForSearch,
  noOptionsText,
  onBlur,
  onChange,
  openText,
  searchInputTitle,
  searchDebounceMs,
  showError,
  suggestedListTitle,
  value,
  suggestedItems,
  numberOfSuggestedItemsToShow,
  onSuggestedItemClick,
  showLessText,
  showMoreText,
  minCharPromptText
}: SearchInputWithSuggestionsProps<V>): ReactElement => {
  const classes = useSearchInputWithSuggestionsStyle();
  return (
    <div className={className}>
      <p className={classes.searchInputTitle}>{searchInputTitle}</p>
      <ModelAutocomplete<V>
        clearText={clearText}
        closeText={closeText}
        disabled={disabled}
        EndIcon={SearchIcon}
        endIconShouldNotRotate
        errorText={errorText}
        fullWidth
        getOptionFromValue={getOptionFromValue}
        getValues={getValues}
        helperText={helperText}
        id={id}
        label={label}
        loadingText={loadingText}
        minInputForSearch={minInputForSearch}
        noOptionsText={noOptionsText}
        onBlur={onBlur}
        onChange={onChange}
        openText={openText}
        searchDebounceMs={searchDebounceMs}
        showError={showError}
        showHelperOrErrorText={false}
        value={value}
        showSelectedValueOnInput={false}
        minCharPromptText={minCharPromptText}
      />
      {!_.isEmpty(suggestedItems) && (
        <div className={classes.suggestedListContainer}>
          <p className={classes.suggestedListTitle}>{suggestedListTitle}</p>
          <SuggestedList<V>
            className={classes.suggestedList}
            items={suggestedItems}
            numberOfItemsToShow={numberOfSuggestedItemsToShow}
            onClick={onSuggestedItemClick}
            showLessText={showLessText}
            showMoreText={showMoreText}
          />
        </div>
      )}
    </div>
  );
};
