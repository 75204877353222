import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { ModelId } from "model/primitives/model/model";
import { PublicationStatus } from "model/resource/activity/activityDefinition";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { getKeyObj, HttpMethod, KeyObj } from "@laba/ts-common";
import { ProcedureReportDefinition } from "model/resource/procedures/procedureReportDefinition/procedureReportDefinition";
import { createHydratedMock } from "ts-auto-mock";

export interface ProcedureReportDefinitionListQueryParams
  extends ListQueryParams {
  organization?: ModelId;
  status?: PublicationStatus;
}

export const ProcedureReportDefinitionListQueryParamsKey: KeyObj<ProcedureReportDefinitionListQueryParams> =
  getKeyObj(createHydratedMock<ProcedureReportDefinitionListQueryParams>());

const ProcedureReportDefinitionBasePath = "/procedure-report-definition";

export const getProcedureReportDefinitionList = (
  params?: ProcedureReportDefinitionListQueryParams
): Promise<ApiPageRequestResponse<ProcedureReportDefinition>> => {
  return request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(ProcedureReportDefinitionBasePath),
    params
  });
};

export const createProcedureReportDefinition = (
  data: ProcedureReportDefinition
): Promise<ApiRequestResponse<ProcedureReportDefinition>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(ProcedureReportDefinitionBasePath),
    data
  });

export const updateProcedureReportDefinition = (
  data: ProcedureReportDefinition
): Promise<ApiRequestResponse<ProcedureReportDefinition>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${ProcedureReportDefinitionBasePath}/${data.id}`),
    data
  });

export const getProcedureReportDefinition = (
  id: ModelId
): Promise<ApiRequestResponse<ProcedureReportDefinition>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${ProcedureReportDefinitionBasePath}/${id}`)
  });
