import {
  borderMixin,
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  FlexJustifyContent,
  pxToRem,
  RadiusVariant,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import {
  getThemeRadius,
  getThemeStyleColor,
  themeTextMixin
} from "model/themeUtils";

export const useDetailIndicatorBoxStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...borderMixin({
        side: { all: 1 },
        radius: { all: getThemeRadius(theme, RadiusVariant.Small) },
        style: "solid",
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.GrayDark
        )
      }),
      ...flexColumnMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.Center
      }),
      height: pxToRem(20),
      width: pxToRem(20)
    },
    content: {
      ...themeTextMixin(theme, TextVariant.Overline),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "DetailIndicatorBox"
);
