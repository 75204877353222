import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";
import { FC } from "@laba/react-common";

export const LogOutIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      width="28.095"
      height="28.095"
      viewBox="0 0 28.095 28.095"
      {...props}
    >
      <g transform="translate(28.095 28.095) rotate(180)">
        <path
          d="M4.59,19.936a.962.962,0,0,0,1.356,0l4.315-4.315A.956.956,0,1,0,8.91,14.267L6.228,16.949V.958a.958.958,0,0,0-1.915,0V16.949L1.631,14.267A.957.957,0,0,0,.278,15.621Z"
          transform="translate(26.411 9.71) rotate(90)"
          strokeWidth="0.5"
        />
        <path
          data-name="OpenPath"
          d="M23.841,0a.958.958,0,0,0-.958.958V6.368a.571.571,0,0,1-.57.57H2.483a.571.571,0,0,1-.57-.57V.958A.956.956,0,1,0,0,.958V7.127A1.72,1.72,0,0,0,1.719,8.846H23.08A1.72,1.72,0,0,0,24.8,7.127V.958A.957.957,0,0,0,23.841,0Z"
          transform="translate(10.461 1.771) rotate(90)"
          strokeWidth="0.5"
        />
      </g>
    </SvgIcon>
  );
};
