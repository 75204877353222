import { FC, SizeVariant } from "@laba/react-common";
import React from "react";
import { Popup } from "components/popup/Popup/Popup";
import { Noop } from "@laba/ts-common";
import { isEmpty } from "lodash-es";
import { AttachmentConfig, DefaultMaxFileSize } from "./helpers";
import {
  AttachmentCardContent,
  BaseAttachmentProps
} from "./AttachmentCardContent/AttachmentCardContent";

export interface PopupState {
  loadingFiles: AttachmentConfig[];
  toSubmitFiles: AttachmentConfig[];
  failedFiles: File[];
}

export interface AttachmentPopupProps extends BaseAttachmentProps {
  open?: boolean;
  popupFiles?: PopupState;
  onPrimaryButtonClick?: Noop;
  disablePrimaryButton?: boolean;
  onSecondaryButtonClick?: Noop;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  headerTitle?: string;
  toSubmitFiles: AttachmentConfig[];
  setToSubmitFiles?: (files: AttachmentConfig[]) => void;
  deleteToSubmitFile: (fileToDelete: AttachmentConfig) => void;
  isLoading?: boolean;
}

export const AttachmentPopup: FC<AttachmentPopupProps> = ({
  open,
  popupFiles,
  setToSubmitFiles,
  toSubmitFiles,
  uploadFiles,
  onPrimaryButtonClick,
  disablePrimaryButton,
  onSecondaryButtonClick,
  deleteToSubmitFile,

  headerTitle,
  primaryButtonText,
  secondaryButtonText,
  dropButtonText,
  dropPromptText,
  orText,
  dropRejectedText,
  acceptedFileExtensions,
  acceptedFileExtensionsText,
  fileValues = [],
  onDropError,
  errorMessageMapper,
  maxFiles,
  maxFileSize = DefaultMaxFileSize,
  retryDownloadText,
  downloadFiles,
  downloadFilesButtonText,
  downloadFilesText,
  showDownloadFiles,
  fileErrors,
  isMobile,
  isLoading
}) => {
  const disableSubmit =
    !isEmpty(popupFiles?.loadingFiles) || disablePrimaryButton;

  return (
    <Popup
      loading={isLoading}
      headerTitle={headerTitle}
      closableHeader
      open={open}
      disablePrimaryButton={disableSubmit}
      onPrimaryButtonClick={onPrimaryButtonClick}
      primaryButtonText={primaryButtonText}
      onSecondaryButtonClick={onSecondaryButtonClick}
      secondaryButtonText={secondaryButtonText}
      onClose={onSecondaryButtonClick}
      disableBackdropClick
      isMobile={isMobile}
      popupSizeVariant={SizeVariant.Small}
    >
      <AttachmentCardContent
        isMobile={isMobile}
        uploadFiles={uploadFiles}
        toSubmitFiles={toSubmitFiles}
        failedFiles={!isEmpty(popupFiles) ? popupFiles.failedFiles : []}
        setToSubmitFiles={setToSubmitFiles}
        fileValues={fileValues}
        deleteToSubmitFile={deleteToSubmitFile}
        dropButtonText={dropButtonText}
        dropPromptText={dropPromptText}
        orText={orText}
        dropRejectedText={dropRejectedText}
        acceptedFileExtensions={acceptedFileExtensions}
        acceptedFileExtensionsText={acceptedFileExtensionsText}
        onDropError={onDropError}
        errorMessageMapper={errorMessageMapper}
        maxFiles={maxFiles}
        maxFileSize={maxFileSize}
        retryDownloadText={retryDownloadText}
        downloadFilesButtonText={downloadFilesButtonText}
        downloadFilesText={downloadFilesText}
        showDownloadFiles={showDownloadFiles}
        fileErrors={fileErrors}
        downloadFiles={downloadFiles}
      />
    </Popup>
  );
};
