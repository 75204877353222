import {
  createStyle,
  FlexAlignContent,
  flexColumnMixin,
  FlexJustifyContent,
  StyleVariant,
  TextVariant,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { Theme } from "model/theme";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

interface PatientDetailDataItemDynamicStylesProps {
  textVariant: TextVariant;
}

interface PatientDetailDataItemClassNames {
  root: string;
  text: string;
}

const usePatientDetailDataItemStaticStyles = createStyle(
  (_theme: Theme) => ({
    root: {
      ...flexColumnMixin({
        gap: 4,
        justifyContent: FlexJustifyContent.FlexStart,
        alignContent: FlexAlignContent.FlexStart
      })
    }
  }),
  "PatientDetailDataItemStatic"
);

const usePatientDetailDataItemDynamicStyles = createStyle(
  (theme: Theme) => ({
    text: (props: PatientDetailDataItemDynamicStylesProps) => ({
      ...themeTextMixin(theme, props.textVariant, 1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      ),
      textAlign: "start"
    })
  }),
  "PatientDetailDataItemDynamic"
);

export const usePatientDetailDataItemStyles = (
  props: PatientDetailDataItemDynamicStylesProps
): PatientDetailDataItemClassNames => {
  const staticClasses = usePatientDetailDataItemStaticStyles();
  const dynamicClasses = usePatientDetailDataItemDynamicStyles(props);

  return {
    root: staticClasses.root,
    text: dynamicClasses.text
  };
};
