import { KnownCodeSystemSystem, ModelId } from "@laba/nexup-api";
import { ReactElement, useI18n } from "@laba/react-common";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import React from "react";
import { tkCC } from "translation/i18n";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";

export interface PermissionConfigInputProps {
  source: string;
  label: string;
  organizationId?: ModelId;
}

const tk = tkCC.inputs.permissionConfigInput;

export const AppConfigListInput = ({
  source,
  label,
  organizationId
}: PermissionConfigInputProps): ReactElement => {
  const { t } = useI18n();
  const appConfigCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.CodeSystemAppConfigProperty,
    organizationId
  );
  return (
    <ArrayInput source={source} label={label}>
      <CodeSystemSelectorInput
        source=""
        label={t(tk.appConfigPermission)}
        codeSystem={appConfigCodeSystem}
        organizationId={organizationId}
        withCreate
      />
    </ArrayInput>
  );
};
