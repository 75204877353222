import { OpenCode } from "model/primitives/model/model";

export enum MedicalRequestKnownType {
  Drug = "Drug",
  AerosolTherapy = "AerosolTherapy",
  InsulinCorrection = "InsulinCorrection",
  SkinCare = "SkinCare",
  FoodOral = "FoodOral",
  FoodEnteral = "FoodEnteral",
  FoodParenteral = "FoodParenteral",
  FoodFasted = "FoodFasted",
  ParenteralPHP = "ParenteralPHP",
  ParenteralContinuousInfusion = "ParenteralContinuousInfusion",
  ParenteralVolumeExpansion = "ParenteralVolumeExpansion",
  ParenteralElectrolytes = "ParenteralElectrolytes"
}

export type MedicalRequestType = OpenCode<MedicalRequestKnownType>;
