import { FC } from "@laba/react-common";
import { AttachFileIcon } from "components/icons";
import React from "react";
import { useFileListItemStyles } from "components/file/FileListItem/useFileListItemStyles";
import { ButtonBase } from "components/buttons/ButtonBase/ButtonBase";
import { FileBlobItem } from "components/file";

interface Props {
  file: FileBlobItem;
  onClick?: (item: FileBlobItem) => void;
}

export const FileListItem: FC<Props> = ({ file, onClick }) => {
  const classes = useFileListItemStyles();

  const { fileName } = file;

  return (
    <ButtonBase className={classes.root} onClick={() => onClick?.(file)}>
      <AttachFileIcon className={classes.icon} />
      <p className={classes.filename} title={fileName}>
        {fileName}
      </p>
    </ButtonBase>
  );
};
