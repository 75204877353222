import React from "react";
import { FC } from "@laba/react-common";
import { Create } from "components/generic/Create/Create";
import { ResourceType } from "@laba/nexup-api";
import {
  AccountForm,
  accountJsonToModel,
  accountTransform
} from "components/pages/NexupAdmin/resources/account/AccountForm";

export const AccountCreate: FC = () => {
  return (
    <Create
      resourceTransform={accountTransform}
      resource={ResourceType.Product}
      jsonToModel={accountJsonToModel}
    >
      <AccountForm />
    </Create>
  );
};
