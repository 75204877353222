import { Theme, ThemeStyleColorVariant } from "model/theme";
import { Color, colorChangeAlpha, Style } from "@laba/react-common";
import { getThemeStyleColor, getThemeStyleState } from "model/themeUtils";
import { StyleVariant, TypeVariant } from "model/themeVariant";
import { NexupColor } from "model/nexupColor";
import { Optional } from "@laba/ts-common";

export interface GenericProps {
  style: StyleVariant;
  type: TypeVariant;
  disabled?: boolean;
  typeInvertedVariant?: TypeInvertedVariant;
  backgroundColor?: string;
}

export enum TypeInvertedVariant {
  Normal = "Normal",
  Inverted = "Inverted",
  Outlined = "Outlined"
}

export type ClassNameDynamicGeneratedRecord = Record<
  string,
  Style<unknown, string>
>;

export const getGenericButtonBackgroundColor = (
  theme: Theme,
  { style, type, disabled, backgroundColor }: GenericProps
): Color => {
  const colorWhenTypeIsContained = getThemeStyleColor(theme, style);
  const colorWhenTypeIsNotContained = getThemeStyleColor(
    theme,
    style,
    ThemeStyleColorVariant.Contrast
  );
  const colorOutlined = NexupColor.Transparent;
  const color =
    type === TypeVariant.Contained
      ? backgroundColor ?? colorWhenTypeIsContained
      : type === TypeVariant.Text
      ? colorWhenTypeIsNotContained
      : colorOutlined;
  if (disabled) {
    const disabledColorWhenContained = getThemeStyleState(
      theme,
      StyleVariant.Primary
    ).disabled;

    const { disabledOpacity } = getThemeStyleState(theme, style);

    return type === TypeVariant.Contained
      ? colorChangeAlpha(disabledColorWhenContained, disabledOpacity)
      : type === TypeVariant.Text
      ? colorWhenTypeIsNotContained
      : colorOutlined;
  }
  return color;
};

export const getDefaultButtonBackgroundColor = (
  theme: Theme,
  { style, type, disabled, backgroundColor }: GenericProps
): Color => {
  const colorWhenTypeIsContained = getThemeStyleColor(theme, style);
  const colorWhenTypeIsInverted = getThemeStyleColor(
    theme,
    style,
    ThemeStyleColorVariant.Contrast
  );
  const colorWhenTypeIsText = NexupColor.Transparent;
  const baseColor =
    type === TypeVariant.Contained
      ? colorWhenTypeIsContained
      : type === TypeVariant.Inverted
      ? colorWhenTypeIsInverted
      : colorWhenTypeIsText;
  const color = backgroundColor ?? baseColor;
  if (disabled) {
    const { disabledOpacity } = getThemeStyleState(theme, style);
    return colorChangeAlpha(color, disabledOpacity);
  }
  return color;
};

export const getDefaultButtonHoverBackgroundColor = (
  theme: Theme,
  style: StyleVariant,
  type: TypeVariant
): Color => {
  if (type === TypeVariant.Inverted) {
    return getThemeStyleColor(theme, style, ThemeStyleColorVariant.GrayLight);
  }
  if (type === TypeVariant.Contained) {
    return getThemeStyleColor(theme, style, ThemeStyleColorVariant.Dark);
  }
  return getThemeStyleState(theme, style).hover;
};

export const getGenericButtonColor = (
  theme: Theme,
  { style, type, disabled, backgroundColor }: GenericProps
): Color => {
  const colorWhenTypeIsContained = getThemeStyleColor(
    theme,
    style,
    ThemeStyleColorVariant.Contrast
  );
  const colorWhenTypeIsNotContained =
    backgroundColor ?? getThemeStyleColor(theme, style);
  const baseColor =
    type === TypeVariant.Contained
      ? colorWhenTypeIsContained
      : colorWhenTypeIsNotContained;

  if (disabled) {
    const { disabledOpacity } = getThemeStyleState(theme, style);
    const disabledColor = getThemeStyleColor(theme, StyleVariant.BlackWhite);
    const changedColor = colorChangeAlpha(
      disabledColor,
      type === TypeVariant.Contained
        ? disabledOpacity + 0.3
        : disabledOpacity - 0.1
    );
    return changedColor;
  }
  return baseColor;
};

export const getTypeVariantGenericButtonHoverBackgroundColor = (
  theme: Theme,
  style: StyleVariant,
  typeVariant: TypeVariant,
  backgroundColor?: Color
): Color => {
  if (typeVariant === TypeVariant.Inverted) {
    return getThemeStyleColor(theme, style, ThemeStyleColorVariant.GrayLight);
  }
  if (typeVariant === TypeVariant.Contained) {
    return (
      backgroundColor ??
      getThemeStyleColor(theme, style, ThemeStyleColorVariant.Dark)
    );
  }
  return backgroundColor
    ? colorChangeAlpha(backgroundColor, 0.08)
    : getThemeStyleState(theme, style).hover;
};

export const getTypeInvertedVariantHoverBackgroundColor = (
  theme: Theme,
  style: StyleVariant,
  typeInvertedVariant: TypeInvertedVariant
): Color => {
  if (typeInvertedVariant === TypeInvertedVariant.Inverted) {
    return getThemeStyleColor(theme, style, ThemeStyleColorVariant.GrayLight);
  }
  if (typeInvertedVariant === TypeInvertedVariant.Normal) {
    return getThemeStyleColor(theme, style, ThemeStyleColorVariant.Dark);
  }
  return getThemeStyleState(theme, style).hover;
};

export const getBorderColorByType = (
  type: TypeInvertedVariant,
  style: StyleVariant,
  disabled: boolean,
  theme: Theme
): Optional<Color> => {
  switch (type) {
    case TypeInvertedVariant.Normal:
      return undefined;
    case TypeInvertedVariant.Inverted:
      return undefined;
    case TypeInvertedVariant.Outlined:
      return getGenericButtonColor(theme, {
        style,
        type: TypeVariant.Outlined,
        disabled
      });
    default:
      return undefined;
  }
};

export const getTypeFromInvertedType = (
  type: TypeInvertedVariant
): TypeVariant => {
  switch (type) {
    case TypeInvertedVariant.Normal:
      return TypeVariant.Contained;
    case TypeInvertedVariant.Inverted:
      return TypeVariant.Text;
    case TypeInvertedVariant.Outlined:
      return TypeVariant.Outlined;
    default:
      return TypeVariant.Contained;
  }
};
