import {
  borderMixin,
  createStyle,
  FlexAlignItems,
  flexRowMixin,
  marginMixin,
  pxToRem
} from "@laba/react-common";
import { RadiusVariant, Theme, ThemeStyleColorVariant } from "model/theme";
import {
  getThemeRadius,
  getThemeStyleColor,
  themeTextMixin
} from "model/themeUtils";
import { StyleVariant, TextVariant } from "model/themeVariant";

export const MuiButtonClasses = createStyle(
  (theme: Theme) => ({
    root: {
      ...borderMixin({
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.GrayDark
        ),
        side: {
          all: 1
        },
        radius: { all: getThemeRadius(theme, RadiusVariant.Small) }
      }),
      height: pxToRem(40)
    },
    label: {
      ...themeTextMixin(theme, TextVariant.Subtitle2, 1),
      ...flexRowMixin({ alignItems: FlexAlignItems.Center }),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    endIcon: {
      ...marginMixin({
        left: "auto"
      }),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "AttachmentInput"
);
