export const patient = {
  title: "Pacientes",
  filters: {
    organization: "Organizacion",
    currentLocation: "Lugar",
    hospitalizationStatus: "Hospitalizado",
    validationStatus: "Estado de validacion"
  },
  fields: {
    id: "Id",
    organization: "Organizacion",
    firstName: "Nombre",
    otherName: "Segundo nombre",
    lastName: "Apellido",
    motherLastName: "Apellido materno",
    birthDate: "Fecha de nacimiento",
    gender: "Genero",
    identifier: "Identificadores",

    healthcarePayerProvider: "Obra social",
    healthcarePayerProviderId: "Credencial obra social",
    healthcarePayerProviderPlan: "Plan obra social",

    phone: "Telefonos",
    phoneValue: "Numero",
    phoneType: "Typo",
    phoneRole: "Uso",
    email: "Email",
    emailValue: "Direccion",
    emailType: "Tipo",
    emailRole: "Uso",
    active: "Activo",
    managedBy: "Administrado por",
    managedByPatient: "Paciente",

    validationStatus: "Validacion",
    federationId: "Id federacion",
    medicalRecordId: "Nº historia clínica",
    hisCode: "Codigo HIS",

    user: "Usuario",
    userId: "Id Usuario",
    userName: "Usuario",
    userEmail: "Email Usuario",

    role: "Roles",
    roleId: "Id",
    roleCode: "Rol",
    taxType: "Tipo de impuesto",
    roleOrganization: "Organizacion",
    roleActive: "Activo",

    name: "Nombre",
    identifierDni: "Dni: {{value}}",
    identifierPassport: "Pasaporte: {{value}}",
    identifierNull: "-",
    contact: "Contacto",
    healthcarePayer: "Obra Social",
    healthcarePayerId: "Credencial"
  },
  tabs: {
    personal: "Paciente",
    rol: "Roles",
    user: "Usuario"
  }
};
