import { ResourceType } from "model/primitives/resourceModel";
import { Concept } from "model/resource/entities/codeSystem";
import {
  AllergyIntolerance,
  AllergyStatus,
  KnownAllergyIntoleranceElements
} from "model/resource/medical/allergyIntolerance/allergyIntolerance";
import { ModelId } from "model/primitives/model/model";

export const createNoKnownAllergy = (
  elementName: string,
  encounterId?: ModelId
): AllergyIntolerance => {
  return {
    status: AllergyStatus.Active,
    resourceType: ResourceType.AllergyIntolerance,
    element: KnownAllergyIntoleranceElements.NoKnownAllergy,
    hospitalization: encounterId,
    elementName
  };
};
export const getAllergyName = (
  allergy: AllergyIntolerance,
  allergyConcepts: Concept[]
): string =>
  allergy.elementName ||
  allergyConcepts.find(concept => concept.code === allergy.element)?.display ||
  allergy.element ||
  "";
export const isNoAllergiesMarker = (allergy: AllergyIntolerance): boolean =>
  allergy.element === KnownAllergyIntoleranceElements.NoKnownAllergy;
export const isAllergyMarker = (allergy: AllergyIntolerance): boolean =>
  !isNoAllergiesMarker(allergy);
