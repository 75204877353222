import React from "react";
import { FC, getClassName } from "@laba/react-common";
import { AttachFileIcon, AttachmentIcon } from "components/icons";
import { useFileAttachmentStyle } from "./useFileAttachmentStyle";

interface FileAttachmentProps {
  fileName: string;
  underlined: boolean;
  sidewaysIcon?: boolean;
}

export const FileAttachment: FC<FileAttachmentProps> = ({
  fileName,
  underlined,
  sidewaysIcon = false
}) => {
  const classes = useFileAttachmentStyle();
  const filenameClassname = getClassName(
    classes.filename,
    underlined ? classes.underlined : undefined
  );
  return (
    <div className={classes.root}>
      {sidewaysIcon ? (
        <AttachmentIcon className={classes.icon} />
      ) : (
        <AttachFileIcon className={classes.icon} />
      )}
      <h4 className={filenameClassname} title={fileName}>
        {fileName}
      </h4>
    </div>
  );
};
