import { FC, getClassName, SizeVariant } from "@laba/react-common";
import React, { useState } from "react";
import { useLinkEntriesStyles } from "components/inputs/LinkInput/useLinkEntriesStyles";
import { CloseIcon } from "components/icons";
import { ButtonBase } from "components/buttons/ButtonBase/ButtonBase";
import { BaseIconButton } from "components/buttons/BaseIconButton/BaseIconButton";
import { isEmpty } from "lodash-es";
import { isImgUrl } from "@laba/ts-common";
import {
  LinkEntry,
  LinkPreviewPopup
} from "./LinkPreviewPopup/LinkPreviewPopup";

interface Props {
  isMobile?: boolean;
  urls: string[];
  onRemoveClick?: (url: string) => void;
  itemClassName?: string;
}

const initialLinkEntryState = { url: "", isImage: false };

export const LinkEntries: FC<Props> = ({
  isMobile,
  urls,
  onRemoveClick,
  itemClassName
}) => {
  const classes = useLinkEntriesStyles();
  const [linkEntry, setLinkEntry] = useState<LinkEntry>(initialLinkEntryState);

  const onClose = () => {
    setLinkEntry(initialLinkEntryState);
  };

  // TODO HIS-10378 mejorar esta logica que no esta andando correctamente
  const handleSetLinkEntry = async (itemURL: string) => {
    if (isMobile) return window.open(itemURL);
    const isImage = await isImgUrl(itemURL);
    setLinkEntry({ url: itemURL, isImage });
  };

  return (
    <>
      {urls.map(itemURL => (
        <ButtonBase
          className={getClassName(itemClassName, classes.root)}
          onClick={() => handleSetLinkEntry(itemURL)}
          key={itemURL}
        >
          <p className={classes.text} title={itemURL}>
            {itemURL}
          </p>
          {onRemoveClick && (
            <BaseIconButton
              className={classes.closeIcon}
              Icon={CloseIcon}
              onClick={() => onRemoveClick(itemURL)}
              size={SizeVariant.ExtraSmall}
              withoutPadding
            />
          )}
        </ButtonBase>
      ))}
      <LinkPreviewPopup
        isOpen={!isEmpty(linkEntry.url)}
        onClose={onClose}
        linkEntry={linkEntry}
      />
    </>
  );
};
