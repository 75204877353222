import React from "react";
import {
  Create as ReactAdminCreate,
  CreateProps as ReactAdminCreateProps
} from "react-admin";
import { ReactElement, ReactElementOrNull } from "@laba/react-common";
import {
  ActionMode,
  ResourceActions,
  ResourceActionsProps
} from "components/generic/ResourceAction/ResourceAction";
import { BackendError, Model } from "@laba/nexup-api";
import { DeepPartial } from "@laba/ts-common";
import { get } from "lodash-es";
import { RaResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { DefaultMeta } from "providers/dataProvider/resourceProvider/utils/resourceProviderTypes";

export type ResourceTransform<T extends Model> = (resource: T) => T;
export type CreateProps<T extends Model> = Omit<
  ReactAdminCreateProps,
  "transform"
> & {
  resourceTransform?: ResourceTransform<T>;
  children: ReactElement;
  resource: RaResourceType;
  extraButtons?: ReactElement;
  meta?: DefaultMeta;
} & Omit<ResourceActionsProps<T>, "resource" | "mode">;

export const showOnErrorAlert = (error: unknown): void => {
  const backendError: DeepPartial<BackendError> = get(error, "body", {});
  const errorList = backendError.issue?.map(issue => {
    return `${issue?.type}: ${issue?.parameter
      ?.map(parameter =>
        Object.entries(parameter ?? {})
          .map(value => value[1])
          .join(" ")
      )
      .join(" | ")}`;
  });
  const errorText = `Error ${
    backendError.status ?? "-"
  }. Error List:\n\n${errorList?.join("\n\n")}`;
  // eslint-disable-next-line no-alert
  alert(errorText);
};

const getMutationOptions = <T extends Model = Model>(
  mutationOption?: CreateProps<T>["mutationOptions"],
  meta?: DefaultMeta
): CreateProps<T>["mutationOptions"] => ({
  ...mutationOption,
  meta: { ...mutationOption?.meta, ...meta },
  onError: (error: unknown, ...rest): void => {
    showOnErrorAlert(error);
    mutationOption?.onError?.(error, ...rest);
  }
});

export const Create = <T extends Model>(
  props: CreateProps<T>
): ReactElementOrNull => {
  const {
    jsonToModel,
    modelToJson,
    exportedFileName,
    resourceTransform,
    mutationOptions,
    extraButtons,
    meta,
    importElementPreprocess,
    ...createProps
  } = props;
  const actionsProps = {
    jsonToModel,
    modelToJson,
    exportedFileName,
    extraButtons,
    importElementPreprocess,
    mode: ActionMode.Creation,
    resource: props.resource
  };
  return (
    <ReactAdminCreate
      actions={<ResourceActions<T> {...actionsProps} />}
      transform={resourceTransform}
      mutationOptions={getMutationOptions<T>(mutationOptions, meta)}
      {...createProps}
    />
  );
};
