import React, { useCallback, useMemo, useState } from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  ResourceType,
  MedicalRequestDefinitionKey,
  MedicalRequestDefinitionField,
  MRDFieldPropertyType,
  getModelReferenceId,
  ModelReference,
  Organization,
  KnownCodeSystemSystem,
  MRDFieldPropertyBase
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { Autocomplete, AutocompleteOptionConfig } from "@laba/nexup-components";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { MedicalRequestDefinitionFieldFields } from "components/pages/NexupAdmin/resources/medicalRequestDefinition/MedicalRequestDefinitionFieldFields";
import { Optional } from "@laba/ts-common";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";

export const tk = tkCP.adminPage[ResourceType.MedicalRequestDefinition];

export const MedicalRequestDefinitionFieldContent: FC = () => {
  const { t } = useI18n();
  const fieldList = useWatchResourceForm<MedicalRequestDefinitionField[]>(
    MedicalRequestDefinitionKey.fields
  );
  const organization = useWatchResourceForm<ModelReference<Organization>>(
    MedicalRequestDefinitionKey.organization
  );
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organizationId =
    getModelReferenceId(organization) || workspaceOrganizationId;
  const medicationCharacteristicCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicationCharacteristic,
    organizationId
  );
  const requestAsNeededEventCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicalRequestAsNeededEvent,
    organizationId
  );
  const routeCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicationRoute,
    organizationId
  );
  const frequencyTypeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicalRequestFrequencyType,
    organizationId
  );
  const medicalDeviceTagSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicalDeviceTag,
    organizationId
  );
  const unitSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicationUnit,
    organizationId
  );
  const systemSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.CodeSystemSystem,
    organizationId
  );

  const [toEditElementIndex, setToEditElementIndex] =
    useState<Optional<number>>(undefined);
  const fieldOptionList = useMemo<AutocompleteOptionConfig<number>[]>(
    () =>
      fieldList?.map((value, index) => {
        const properties = value.properties as Optional<
          MRDFieldPropertyBase<string>[]
        >;
        const title = properties?.find(
          prop => prop.type === MRDFieldPropertyType.Title
        )?.text;
        const text = `${title || "sin titulo"} (tipo: ${
          value.type
        } / columna: ${value.column})`;
        return {
          value: index,
          text,
          title: text
        };
      }) || [],
    [fieldList]
  );
  const getElementSource = useCallback(
    (source: string) =>
      [
        MedicalRequestDefinitionKey.fields,
        String(toEditElementIndex),
        source
      ].join("."),
    [toEditElementIndex]
  );
  return (
    <>
      <Autocomplete<number>
        fullWidth
        value={toEditElementIndex}
        onChange={async value => {
          // set value to undefined for 1 render (await) to dismount and remount properties array input
          await setToEditElementIndex(undefined);
          setToEditElementIndex(value);
        }}
        options={fieldOptionList}
        compareValues={(v1, v2) => v1 === v2}
        clearText={t(tk.autocompleteClearText)}
        closeText={t(tk.autocompleteCloseText)}
        loadingText={t(tk.autocompleteLoadingText)}
        noOptionsText={t(tk.autocompleteNoOptionsText)}
        openText={t(tk.autocompleteOpenText)}
        label={t(tk.autocompleteTitle)}
      />
      {toEditElementIndex !== undefined && (
        <MedicalRequestDefinitionFieldFields
          fieldType={fieldList?.[toEditElementIndex]?.type}
          getSource={getElementSource}
          systemSystem={systemSystem}
          unitSystem={unitSystem}
          routeCodeSystem={routeCodeSystem}
          requestAsNeededEventCodeSystem={requestAsNeededEventCodeSystem}
          medicationCharacteristicCodeSystem={
            medicationCharacteristicCodeSystem
          }
          medicalDeviceTagSystem={medicalDeviceTagSystem}
          frequencyTypeSystem={frequencyTypeSystem}
          withProps
          organizationId={organizationId}
        />
      )}
    </>
  );
};
