import {
  getThemeRadius,
  getThemeStyleColor,
  themeTextMixin
} from "model/themeUtils";
import { RadiusVariant, Theme, ThemeStyleColorVariant } from "model/theme";
import {
  borderMixin,
  createStyle,
  FlexAlignItems,
  flexRowMixin,
  marginMixin,
  paddingMixin,
  pxToRem,
  SnackbarStyleVariant
} from "@laba/react-common";
import { StyleVariant, TextVariant } from "model/themeVariant";

export interface SnackbarStyleProps {
  style: SnackbarStyleVariant;
}

export const useSnackbarStyle = createStyle(
  (theme: Theme) => ({
    container: (props: SnackbarStyleProps) => ({
      ...borderMixin({
        side: { left: 4, right: 0, topBottom: 0 },
        color: getThemeStyleColor(theme, props.style),
        style: "solid",
        radius: { all: getThemeRadius(theme, RadiusVariant.Small) }
      }),
      ...flexRowMixin({ alignItems: FlexAlignItems.Center }),
      ...paddingMixin({ all: 16 }),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      ),
      minHeight: pxToRem(52),
      width: pxToRem(365)
    }),
    leftIcon: {
      ...marginMixin({ right: 12 }),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      ),
      height: pxToRem(24),
      width: pxToRem(24)
    },
    message: {
      ...marginMixin({ topBottom: "auto" }),
      ...themeTextMixin(theme, TextVariant.Body2, 2),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      ),
      width: "100%"
    },
    closeIconButton: {
      ...paddingMixin({ all: 0 })
    },
    closeIcon: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    }
  }),
  "Snackbar"
);
