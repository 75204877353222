import {
  Code,
  ContactPointRole,
  createBaseIdentifier,
  Gender,
  getModelOrUndefined,
  getModelReferenceId,
  IdentifierPropertyType,
  IdentifierSystem,
  KnownIdentifierSystem,
  LocationStatus,
  LocationType,
  ModelId,
  ModelReference,
  Organization,
  OrganizationType,
  QualificationValidationStatus,
  ResourceType,
  ValidationStatus
} from "@laba/nexup-api";
import { OrganizationConfiguration } from "models/organization/organizationConfiguration";
import { ApiDate, notUndefined } from "@laba/ts-common";
import produce from "immer";
import { head } from "lodash-es";

export interface WorkspacePractitionerConfigData {
  id?: ModelId;
  firstName?: string;
  lastName?: string;
  gender?: Gender;
  birthDate?: string;
  identifierSystem?: IdentifierSystem;
  identifierValue?: string;
  phone?: string;
  email?: string;
  registration?: string;
  registrationType?: Code;
  registrationPlace?: Code;
  registrationValidation?: QualificationValidationStatus;
  signatureFile?: string;
  userId?: string;
  userPassword?: string;
  rolList?: Code[];
}

export interface WorkspaceSpecialityConfigData {
  name?: string;
}

export interface WorkspaceLocationConfigData {
  id?: ModelId;
  name?: string;
}

export interface WorkspaceConfigData {
  currentOrganizationConfiguration?: OrganizationConfiguration;

  organizationId?: ModelId;
  parentOrganization?: ModelReference<Organization>;
  active?: boolean;
  organizationName?: string;
  organizationCrmId?: string;
  organizationPictureUrl?: string;
  organizationPhone?: string;
  organizationEmail?: string;
  organizationAddress?: string;
  organizationPlan?: Code;
  organizationPaymentStatus?: Code;
  organizationPaymentExpiration?: ApiDate;
  organizationSpeciality?: WorkspaceSpecialityConfigData[];
  practitionerConfigList?: WorkspacePractitionerConfigData[];
  locationList?: WorkspaceLocationConfigData[];
}

export const workspaceConfigDataFromOrganizationConfiguration = (
  config: OrganizationConfiguration
): WorkspaceConfigData => {
  const organizationCRMIdentifier = config.organization?.identifier?.find(
    x => x.system === KnownIdentifierSystem.OrganizationCRMId
  );
  const notIdentifierList: string[] = [
    KnownIdentifierSystem.PractitionerSignature,
    KnownIdentifierSystem.HisCode,
    KnownIdentifierSystem.Nexup
  ];
  return {
    currentOrganizationConfiguration: config,
    parentOrganization: config.organization?.partOf,
    active: config.organization?.active ?? true,
    organizationId: config.organization?.id,
    organizationName: config.organization?.name,
    organizationCrmId: organizationCRMIdentifier?.value,
    organizationPictureUrl: config.organization?.photo?.url,
    organizationPlan: organizationCRMIdentifier?.property?.find(
      x => x.type === IdentifierPropertyType.Plan
    )?.valueCode,
    organizationPhone: config.organization?.phone?.[0]?.value,
    organizationEmail: config.organization?.email?.[0]?.value,
    organizationAddress: config.organization?.address?.[0]?.street,
    organizationPaymentStatus: organizationCRMIdentifier?.property?.find(
      x => x.type === IdentifierPropertyType.SubscriptionStatus
    )?.valueCode,
    organizationPaymentExpiration: organizationCRMIdentifier?.property?.find(
      x => x.type === IdentifierPropertyType.SubscriptionExpiration
    )?.valueCode,
    organizationSpeciality: config.organization?.speciality?.map(x => ({
      name: x
    })),
    practitionerConfigList: config.practitionerWithExtraDataList
      ?.filter(
        practitionerWithExtraData =>
          practitionerWithExtraData.practitioner.user != null
      )
      ?.map(practitionerWithExtraData => {
        const identifier =
          practitionerWithExtraData.practitioner.personalData.identifierList?.find(
            x => !notIdentifierList.includes(x.system)
          );
        const mainRegistration =
          practitionerWithExtraData.practitioner.healthcareProfessionalData?.registrationQualification?.find(
            x => x.default === true
          ) ??
          head(
            practitionerWithExtraData.practitioner.healthcareProfessionalData
              ?.registrationQualification
          );
        return {
          id: getModelReferenceId(practitionerWithExtraData.practitioner),
          firstName:
            practitionerWithExtraData.practitioner.personalData.firstName,
          lastName:
            practitionerWithExtraData.practitioner.personalData.lastName,
          gender: practitionerWithExtraData.practitioner.personalData.gender,
          birthDate:
            practitionerWithExtraData.practitioner.personalData.birthDate,
          identifierValue: identifier?.value,
          identifierSystem: identifier?.system,
          phone:
            practitionerWithExtraData.practitioner.contactData?.phoneList?.[0]
              ?.value,
          email:
            practitionerWithExtraData.practitioner.contactData?.emailList?.[0]
              ?.value,
          rolList: (practitionerWithExtraData.roleList ?? [])
            .filter(x => x.active)
            .map(x => x.role)
            .filter(notUndefined),
          registration: mainRegistration?.identifier?.value,
          registrationType: mainRegistration?.type,
          registrationPlace: mainRegistration?.place,
          registrationValidation: mainRegistration?.validationStatus,
          signatureFile: mainRegistration?.signature?.url,
          userId: getModelReferenceId(
            practitionerWithExtraData.practitioner.user
          )
        };
      }),
    locationList: config.locationList
      ?.filter(x => x.status === LocationStatus.Active)
      .map(x => ({
        id: x.id,
        name: x.name
      }))
  };
};

export const organizationConfigurationFromWorkspaceConfigData = (
  config: WorkspaceConfigData
): OrganizationConfiguration => {
  return produce<OrganizationConfiguration>(
    config.currentOrganizationConfiguration ?? {},
    draft => {
      draft.parentOrganizationName =
        getModelOrUndefined(config.parentOrganization)?.name ??
        config.currentOrganizationConfiguration?.parentOrganizationName;

      if (draft.organization == null) {
        draft.organization = {
          resourceType: ResourceType.Organization,
          type: OrganizationType.Provider,
          name: ""
        };
      }
      draft.organization.name = config.organizationName ?? "";
      draft.organization.partOf = getModelReferenceId(
        config.parentOrganization
      );
      draft.organization.country = getModelOrUndefined(
        config.parentOrganization
      )?.country;
      draft.organization.active = config.active;

      const pictureUrl = config.organizationPictureUrl;
      if (pictureUrl) {
        let organizationPhoto = draft.organization.photo;
        if (organizationPhoto == null) {
          organizationPhoto = {
            resourceType: ResourceType.Attachment,
            url: "",
            name: "Photo"
          };
          draft.organization.photo = organizationPhoto;
        }
        organizationPhoto.url = pictureUrl;
      } else {
        draft.organization.photo = undefined;
      }

      if (draft.organization.identifier == null) {
        draft.organization.identifier = [];
      }
      let organizationCRMIdentifier = draft.organization.identifier.find(
        x => x.system === KnownIdentifierSystem.OrganizationCRMId
      );
      if (organizationCRMIdentifier == null) {
        organizationCRMIdentifier = createBaseIdentifier(
          KnownIdentifierSystem.OrganizationCRMId,
          ""
        );
        draft.organization.identifier.push(organizationCRMIdentifier);
      }
      organizationCRMIdentifier.value = config.organizationCrmId ?? "";

      if (organizationCRMIdentifier.property == null) {
        organizationCRMIdentifier.property = [];
      }

      let organizationCRMIdentifierPlanProperty =
        organizationCRMIdentifier.property.find(
          x => x.type === IdentifierPropertyType.Plan
        );
      if (
        organizationCRMIdentifierPlanProperty == null &&
        config.organizationPlan
      ) {
        organizationCRMIdentifierPlanProperty = {
          type: IdentifierPropertyType.Plan
        };
        organizationCRMIdentifier.property.push(
          organizationCRMIdentifierPlanProperty
        );
      }
      if (organizationCRMIdentifierPlanProperty)
        organizationCRMIdentifierPlanProperty.valueCode =
          config.organizationPlan;

      let organizationCRMIdentifierPaymentStatusProperty =
        organizationCRMIdentifier.property.find(
          x => x.type === IdentifierPropertyType.SubscriptionStatus
        );
      if (
        organizationCRMIdentifierPaymentStatusProperty == null &&
        config.organizationPaymentStatus
      ) {
        organizationCRMIdentifierPaymentStatusProperty = {
          type: IdentifierPropertyType.SubscriptionStatus
        };
        organizationCRMIdentifier.property.push(
          organizationCRMIdentifierPaymentStatusProperty
        );
      }
      if (organizationCRMIdentifierPaymentStatusProperty)
        organizationCRMIdentifierPaymentStatusProperty.valueCode =
          config.organizationPaymentStatus;

      let organizationCRMIdentifierPaymentExpirationProperty =
        organizationCRMIdentifier.property.find(
          x => x.type === IdentifierPropertyType.SubscriptionExpiration
        );
      if (
        organizationCRMIdentifierPaymentExpirationProperty == null &&
        config.organizationPaymentExpiration
      ) {
        organizationCRMIdentifierPaymentExpirationProperty = {
          type: IdentifierPropertyType.SubscriptionExpiration
        };
        organizationCRMIdentifier.property.push(
          organizationCRMIdentifierPaymentExpirationProperty
        );
      }
      if (organizationCRMIdentifierPaymentExpirationProperty) {
        organizationCRMIdentifierPaymentExpirationProperty.valueCode =
          config.organizationPaymentExpiration;
      }

      draft.organization.speciality = config.organizationSpeciality
        ?.map(x => x.name)
        .filter(notUndefined);

      if (draft.organization.phone == null) {
        draft.organization.phone = [];
      }
      let phone = draft.organization.phone[0];
      if (phone == null && config.organizationPhone) {
        phone = {
          resourceType: ResourceType.Phone
        };
        draft.organization.phone.push(phone);
      }
      if (phone) phone.value = config.organizationPhone;

      if (draft.organization.email == null) {
        draft.organization.email = [];
      }
      let email = draft.organization.email[0];
      if (email == null && config.organizationEmail) {
        email = {
          resourceType: ResourceType.Email
        };
        draft.organization.email.push(email);
      }
      if (email) email.value = config.organizationEmail;

      if (draft.organization.address == null) {
        draft.organization.address = [];
      }
      let address = draft.organization.address[0];
      if (address == null && config.organizationAddress) {
        address = {
          resourceType: ResourceType.Address
        };
        draft.organization.address.push(address);
      }
      if (address) address.street = config.organizationAddress;

      if (draft.practitionerWithExtraDataList == null) {
        draft.practitionerWithExtraDataList = [];
      }

      draft.practitionerWithExtraDataList.forEach(
        draftPractitionerWithExtraData => {
          const practitionerConfig = config.practitionerConfigList?.find(
            x =>
              x.id != null &&
              x.id === draftPractitionerWithExtraData.practitioner.id
          );
          if (practitionerConfig == null) {
            draftPractitionerWithExtraData.practitioner.user = undefined;
          }
        }
      );
      config.practitionerConfigList?.forEach(practitionerConfig => {
        let draftPractitionerWithExtraData =
          draft.practitionerWithExtraDataList?.find(
            x =>
              x.practitioner.id != null &&
              x.practitioner.id === practitionerConfig.id
          );
        if (draftPractitionerWithExtraData == null) {
          draftPractitionerWithExtraData = {
            practitioner: {
              resourceType: ResourceType.Practitioner,
              personalData: {
                firstName: "",
                lastName: "",
                gender: Gender.Unknown
              },
              validationStatus: ValidationStatus.Temporal,
              organization: [getModelReferenceId(draft.organization)].filter(
                notUndefined
              ),
              selfAdministered: true
            }
          };
          draft.practitionerWithExtraDataList?.push(
            draftPractitionerWithExtraData
          );
        }

        draftPractitionerWithExtraData.practitioner.personalData.firstName =
          practitionerConfig.firstName ?? "";
        draftPractitionerWithExtraData.practitioner.personalData.lastName =
          practitionerConfig.lastName ?? "";
        draftPractitionerWithExtraData.practitioner.personalData.gender =
          practitionerConfig.gender ?? Gender.Unknown;
        draftPractitionerWithExtraData.practitioner.personalData.birthDate =
          practitionerConfig.birthDate;

        if (
          draftPractitionerWithExtraData.practitioner.personalData
            .identifierList == null
        ) {
          draftPractitionerWithExtraData.practitioner.personalData.identifierList =
            [];
        }
        let identifier =
          draftPractitionerWithExtraData.practitioner.personalData.identifierList.find(
            x => x.system === practitionerConfig.identifierSystem
          );
        if (identifier == null && practitionerConfig.identifierSystem) {
          identifier = createBaseIdentifier(
            practitionerConfig.identifierSystem,
            ""
          );
          draftPractitionerWithExtraData.practitioner.personalData.identifierList.push(
            identifier
          );
        }
        if (identifier)
          identifier.value = practitionerConfig.identifierValue ?? "";

        if (draftPractitionerWithExtraData.practitioner.contactData == null) {
          draftPractitionerWithExtraData.practitioner.contactData = {};
        }
        if (
          draftPractitionerWithExtraData.practitioner.contactData.phoneList ==
          null
        ) {
          draftPractitionerWithExtraData.practitioner.contactData.phoneList =
            [];
        }
        let practitionerPhone =
          draftPractitionerWithExtraData.practitioner.contactData.phoneList[0];
        if (practitionerPhone == null && practitionerConfig.phone) {
          practitionerPhone = {
            resourceType: ResourceType.Phone
          };
          draftPractitionerWithExtraData.practitioner.contactData.phoneList.push(
            practitionerPhone
          );
        }
        if (practitionerPhone) {
          practitionerPhone.value = practitionerConfig.phone;
          practitionerPhone.role = ContactPointRole.NotificationPhone;
        }

        if (
          draftPractitionerWithExtraData.practitioner.contactData.emailList ==
          null
        ) {
          draftPractitionerWithExtraData.practitioner.contactData.emailList =
            [];
        }
        let practitionerEmail =
          draftPractitionerWithExtraData.practitioner.contactData.emailList[0];
        if (practitionerEmail == null && practitionerConfig.email) {
          practitionerEmail = {
            resourceType: ResourceType.Email
          };
          draftPractitionerWithExtraData.practitioner.contactData.emailList.push(
            practitionerEmail
          );
        }
        if (practitionerEmail) {
          practitionerEmail.value = practitionerConfig.email;
          practitionerEmail.role = ContactPointRole.NotificationEmail;
        }

        if (
          draftPractitionerWithExtraData.practitioner
            .healthcareProfessionalData == null
        ) {
          draftPractitionerWithExtraData.practitioner.healthcareProfessionalData =
            {
              registrationQualification: []
            };
        }
        let registration =
          draftPractitionerWithExtraData.practitioner.healthcareProfessionalData.registrationQualification?.find(
            x => x.default
          ) ??
          head(
            draftPractitionerWithExtraData.practitioner
              .healthcareProfessionalData.registrationQualification
          );
        if (registration == null && practitionerConfig.registration) {
          registration = { default: true };
          draftPractitionerWithExtraData.practitioner.healthcareProfessionalData.registrationQualification?.push(
            registration
          );
        }
        if (registration) {
          let registrationIdentifier = registration.identifier;
          if (registrationIdentifier == null) {
            registrationIdentifier = createBaseIdentifier(
              KnownIdentifierSystem.PractitionerQualificationRegistration,
              ""
            );
            registration.identifier = registrationIdentifier;
          }
          registrationIdentifier.value = practitionerConfig.registration ?? "";

          registration.place = practitionerConfig.registrationPlace;
          registration.type = practitionerConfig.registrationType;
          registration.validationStatus =
            practitionerConfig.registrationValidation ??
            QualificationValidationStatus.Validated;

          let signatureAttachment = registration.signature;
          if (signatureAttachment == null && practitionerConfig.signatureFile) {
            signatureAttachment = {
              resourceType: ResourceType.Attachment,
              name: "Firma",
              url: ""
            };
            registration.signature = signatureAttachment;
          }
          if (signatureAttachment && practitionerConfig.signatureFile) {
            signatureAttachment.url = practitionerConfig.signatureFile;
          } else {
            registration.signature = undefined;
          }
        }

        if (practitionerConfig.userId == null) {
          if (practitionerConfig.email)
            draftPractitionerWithExtraData.practitioner.user = {
              enabled: true,
              email: practitionerConfig.email,
              name: practitionerConfig.firstName,
              surname: practitionerConfig.lastName,
              username: practitionerConfig.email,
              password: practitionerConfig.userPassword
            };
        } else {
          draftPractitionerWithExtraData.practitioner.user =
            practitionerConfig.userId;
        }

        const draftRolList = draftPractitionerWithExtraData.roleList ?? [];
        if (draftPractitionerWithExtraData.roleList == null) {
          draftPractitionerWithExtraData.roleList = draftRolList;
        }
        practitionerConfig.rolList?.forEach(rolCode => {
          const rol = draftRolList.find(x => x.role === rolCode);
          if (rol == null) {
            draftRolList.push({
              resourceType: ResourceType.PractitionerRole,
              role: rolCode,
              organization: getModelReferenceId(
                head(draftPractitionerWithExtraData?.practitioner.organization)
              ),
              practitioner: getModelReferenceId(
                draftPractitionerWithExtraData?.practitioner.id
              ),
              active: true
            });
          }
        });
        draftRolList
          .filter(draftRol => draftRol.active)
          .forEach(draftRol => {
            if (
              draftRol.role == null ||
              !practitionerConfig.rolList?.includes(draftRol.role)
            ) {
              draftRol.active = false;
            }
          });
      });

      const draftLocationList = draft.locationList ?? [];
      if (draft.locationList == null) {
        draft.locationList = draftLocationList;
      }
      draftLocationList
        .filter(draftLocation => draftLocation.status === LocationStatus.Active)
        .forEach(draftLocation => {
          const locationConfig = config.locationList?.find(
            x => x.id != null && x.id === draftLocation.id
          );
          if (locationConfig == null) {
            draftLocation.status = LocationStatus.Inactive;
          }
        });
      config.locationList?.forEach(locationConfig => {
        const draftLocation = draftLocationList.find(
          x => x.id != null && x.id === locationConfig.id
        );
        if (draftLocation == null) {
          draftLocationList.push({
            resourceType: ResourceType.Location,
            name: locationConfig.name ?? "",
            status: LocationStatus.Active,
            type: LocationType.Building
          });
        } else {
          draftLocation.name = locationConfig.name ?? "";
        }
      });
    }
  );
};
