import {
  borderMixinRadiusAll,
  createStyle,
  flexColumnMixin,
  FlexJustifyContent,
  flexRowMixin,
  paddingMixin,
  pxToRem,
  RadiusVariant,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import {
  getThemeRadius,
  getThemeStyleColor,
  themeTextMixin
} from "model/themeUtils";

export interface CredentialCardStyleProps {
  cardBackground?: string;
  isUrl: boolean;
}

export const useCredentialCardStyle = createStyle(
  (theme: Theme) => ({
    cardContainer: (props: CredentialCardStyleProps) => ({
      ...paddingMixin({
        all: 20
      }),
      ...borderMixinRadiusAll(getThemeRadius(theme, RadiusVariant.Large)),
      background: props.isUrl
        ? `url(${props.cardBackground}) no-repeat`
        : props.cardBackground ??
          getThemeStyleColor(
            theme,
            StyleVariant.Primary,
            ThemeStyleColorVariant.Main
          ),
      backgroundPosition: "center",
      backgroundSize: "cover",
      position: "relative"
    }),
    icon: {
      width: pxToRem(46),
      position: "absolute",
      top: pxToRem(20),
      right: pxToRem(16)
    },
    credentialData: {
      ...flexColumnMixin({
        gap: 24,
        justifyContent: FlexJustifyContent.SpaceBetween
      }),
      height: "100%"
    },
    header: {
      ...flexColumnMixin({
        gap: 4
      })
    },
    logo: {
      width: pxToRem(100)
    },
    plan: {
      ...themeTextMixin(theme, TextVariant.Caption, 1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    },
    fullName: {
      ...themeTextMixin(theme, TextVariant.Subtitle2, 1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    },
    identifiers: {
      ...flexRowMixin({
        justifyContent: FlexJustifyContent.SpaceBetween
      })
    },
    identifier: {
      ...themeTextMixin(theme, TextVariant.Caption, 1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    },
    rightIdentifier: {
      textAlign: "end"
    }
  }),
  "CredentialCard"
);
