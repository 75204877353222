import { getEnumOrUndefined, Optional } from "@laba/ts-common";
import { find, flatMap, minBy } from "lodash-es";
import { ErrorIssue, ErrorIssueSeverity } from "./errorIssue";
import { BackendError } from "./error";
import { ErrorIssueType } from "./errorIssueType";
import {
  BadRequestReason,
  BadRequestReasonErrorIssueParameter,
  ErrorIssueParameter,
  ErrorIssueParameterAll,
  ErrorIssueParameterType,
  FieldErrorIssueParameter,
  InvalidFieldReasonErrorIssueParameter
} from "./errorIssueParameter";

export const errorIssueSeverityByLevel = [
  ErrorIssueSeverity.Fatal,
  ErrorIssueSeverity.Error,
  ErrorIssueSeverity.Warning,
  ErrorIssueSeverity.Information
];
export const getBackendErrorMainIssue = (
  error: BackendError
): Optional<ErrorIssue> => {
  return minBy(error.issue, issue => {
    const index = issue.severity
      ? errorIssueSeverityByLevel.indexOf(issue.severity)
      : -1;
    return index >= 0 ? index : errorIssueSeverityByLevel.length;
  });
};
export const isFieldErrorIssueParameter = (
  issue: ErrorIssueParameter<ErrorIssueType>
): issue is FieldErrorIssueParameter =>
  issue.type === ErrorIssueParameterType.Field;
export const isInvalidFieldReasonErrorIssueParameter = (
  issue: ErrorIssueParameter<ErrorIssueType>
): issue is InvalidFieldReasonErrorIssueParameter =>
  issue.type === ErrorIssueParameterType.InvalidFieldReason;

const isBadRequestReasonErrorIssueParameter = (
  issue: ErrorIssueParameterAll
): issue is BadRequestReasonErrorIssueParameter =>
  issue.type === ErrorIssueParameterType.BadRequestReason;

export const getFirstBadRequestReasonIssueParameterFromErrorIssue = (
  errorList?: ErrorIssue[]
): Optional<BadRequestReason> => {
  const reason = find(
    flatMap(
      errorList,
      e => e.parameter as BadRequestReasonErrorIssueParameter[]
    ),
    p => isBadRequestReasonErrorIssueParameter(p)
  )?.valueString;
  return getEnumOrUndefined(BadRequestReason)(reason);
};
