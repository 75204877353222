import React from "react";
import { FC, useI18n } from "@laba/react-common";
import { UserKey, User } from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { TextField } from "components/generic/TextField/TextField";
import { List } from "components/generic/List/List";
import { Datagrid } from "components/generic/Datagrid/Datagrid";
import { userJsonToModel } from "components/pages/NexupAdmin/resources/user/UserForm";
import { UserResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { FunctionField } from "components/generic/FunctionField/FunctionField";
import { useListExportedFileName } from "components/hook/useExportedFileName";

const tk = tkCP.adminPage[UserResourceType];

export const UserList: FC = () => {
  const { t } = useI18n();
  const exportedFileName = useListExportedFileName(t(tk.title));

  return (
    <List
      resource={UserResourceType}
      exportedFileName={exportedFileName}
      jsonToModel={userJsonToModel}
      extraFilters={[]}
    >
      <Datagrid>
        <TextField source={UserKey.id} label={t(tk.fields.id)} />
        <TextField source={UserKey.username} label={t(tk.fields.username)} />
        <TextField source={UserKey.email} label={t(tk.fields.email)} />
        <FunctionField<User>
          label={t(tk.fields.enabled)}
          render={user => (user?.enabled ? t(tk.fields.enabled) : "")}
        />
      </Datagrid>
    </List>
  );
};
