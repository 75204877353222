import React from "react";
import { FC, getClassName } from "@laba/react-common";
import { isEmpty } from "lodash-es";
import { Step } from "./step/Step";
import { useStepperStyles } from "./StepperStyle";

export interface StepItem {
  isActive: boolean;
  text: string;
}
export interface StepperProps {
  steps: StepItem[];
  isMobile: boolean;
  className?: string;
}

export const Stepper: FC<StepperProps> = ({ steps, isMobile, className }) => {
  const classes = useStepperStyles({ isMobile });

  if (isEmpty(steps)) return null;

  return (
    <div className={getClassName(className, classes.container)}>
      {steps.map((step, index) => (
        <Step
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={classes.step}
          text={step.text}
          stepNumber={index + 1}
          isActive={step.isActive}
        />
      ))}
    </div>
  );
};
