import {
  borderMixinRadiusAll,
  createStyle,
  flexColumnMixin,
  paddingMixin,
  ElevationVariant,
  pxToRem,
  flexRowMixin,
  marginMixin,
  FlexAlignItems
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant, RadiusVariant } from "model/theme";
import {
  getThemeStyleColor,
  themeElevationMixin,
  getThemeRadius
} from "model/themeUtils";
import { StyleVariant } from "model/themeVariant";

export const useLocationDetailsCardStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...borderMixinRadiusAll(getThemeRadius(theme, RadiusVariant.Large)),
      ...themeElevationMixin(theme, ElevationVariant.Elevation8),
      ...paddingMixin({ top: 24, right: 20, bottom: 56, left: 20 }),
      ...flexColumnMixin({ gap: 16 }),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      ),
      maxWidth: pxToRem(732)
    },
    closeContainer: {
      width: "100%",
      ...flexRowMixin({ alignItems: FlexAlignItems.Center })
    },
    closeIcon: {
      ...marginMixin({ left: "auto" })
    }
  }),
  "LocationDetailsCardStyle"
);
