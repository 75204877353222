import React from "react";
import { useElementExportedFileName } from "components/hook/useExportedFileName";
import { Edit } from "components/generic/Edit/Edit";
import { ConceptResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { FC } from "@laba/react-common";
import { ProcedureCodeConcept } from "@laba/nexup-api";
import {
  ProcedureCodeConceptForm,
  ProcedureCodeConceptJsonToModel,
  procedureCodeConceptTransform
} from "components/pages/NexupAdmin/resources/concept/procedureCodeConceptList/ProcedureCodeConceptForm";

export const ProcedureCodeConceptEdit: FC = () => {
  const exportedFileName = useElementExportedFileName<ProcedureCodeConcept>(
    resource => resource.display
  );
  return (
    <Edit
      resourceTransform={procedureCodeConceptTransform}
      resource={ConceptResourceType.ProcedureCodeConcept}
      jsonToModel={ProcedureCodeConceptJsonToModel}
      exportedFileName={exportedFileName}
    >
      <ProcedureCodeConceptForm />
    </Edit>
  );
};
