import { GenericObject, Optional } from "@laba/ts-common";
import { TOptions } from "i18next";
// eslint-disable-next-line no-restricted-imports
import { TFunction } from "react-i18next";

export type I18nT<TInterpolationMap extends GenericObject = GenericObject> = (
  key: string,
  optionsOrDefault?: TOptions<TInterpolationMap> | string
) => string;

export const getI18nTFromTFunction =
  <TInterpolationMap extends GenericObject = GenericObject>(
    tFunction: TFunction
  ): I18nT<TInterpolationMap> =>
  (key: Optional<string>, optionsOrDefault) => {
    return tFunction(key ?? "", optionsOrDefault);
  };
