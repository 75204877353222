import { useHistory } from "react-router";
import { useState } from "react";
import { useEffectSelective } from "hooks/useEffectSelective";
import { Noop, Optional, sleep } from "@laba/ts-common";
import { RouterHistoryAction } from "routing/state/events";
import { History } from "history";

export const useDisableNavigationEvents = (onBackPressed?: Noop): void => {
  const history = useHistory() as Optional<History>;
  const [isBackAction, setIsBackAction] = useState(false);
  useEffectSelective(
    () => {
      const fn = async () => {
        await sleep(100);
        onBackPressed?.();
      };
      if (isBackAction) {
        fn();
        setIsBackAction(false);
      }
    },
    [isBackAction],
    [onBackPressed, setIsBackAction]
  );
  useEffectSelective(
    () => {
      const unblock = history
        ? history.block((_location, action) => {
            if (action === RouterHistoryAction.POP) {
              setIsBackAction(true);
              return false;
            }
            unblock?.();
          })
        : undefined;
      return () => {
        unblock?.();
      };
    },
    [],
    [history, onBackPressed, setIsBackAction]
  );
};
