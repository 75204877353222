import { ReactElement, useI18n } from "@laba/react-common";
import React, { useCallback, useMemo, useState } from "react";
import { Labeled, useInput } from "react-admin";
import { Grid } from "@mui/material";
import { tkCC } from "translation/i18n";
import { DefaultButton, Map, POIsCategory } from "@laba/nexup-components";
import { Position } from "@laba/ts-common";
import { debounce } from "lodash-es";
import { EnvVar, getEnvVar } from "models/envVar/envVar";
import { NumberInput } from "components/generic/NumberInput/TextInput";
import { useLatLngInputStyle } from "components/generic/LatLngInput/latLngInputStyle";

export interface LatLngInputProps {
  latSrc: string;
  lngSrc: string;
  label?: string;
  required?: boolean;
  defaultLat?: number;
  defaultLng?: number;
}

const tk = tkCC.inputs.latLngInput;
export const LatLngInput = ({
  latSrc,
  lngSrc,
  label,
  required,
  defaultLat = -34.609247,
  defaultLng = -58.363974
}: LatLngInputProps): ReactElement => {
  const { t } = useI18n();
  const apiKey = getEnvVar(EnvVar.REACT_APP_GMAP_API_KEY);

  const classes = useLatLngInputStyle();

  const {
    field: { value: latValue, onChange: latOnChange }
  } = useInput({
    source: latSrc
  });
  const {
    field: { value: lngValue, onChange: lngOnChange }
  } = useInput({
    source: lngSrc
  });

  const [center, setCenter] = useState<Required<Position>>({
    latitude: Number(latValue) || defaultLat,
    longitude: Number(lngValue) || defaultLng
  });
  const [zoom, setZoom] = useState(12);

  const onChangeCenterOrZoom = useCallback(
    (currentZoom?: number, currentLocation?: Required<Position>) => {
      currentZoom && setZoom(currentZoom);
      currentLocation && setCenter(currentLocation);
    },
    [setCenter, setZoom]
  );

  const onChangeCenterOrZoomDebounced = useMemo(
    () => debounce(onChangeCenterOrZoom, 400),
    [onChangeCenterOrZoom]
  );

  const handleSearchInThisArea = () => {
    latOnChange(center.latitude);
    lngOnChange(center.longitude);
  };

  return (
    <Labeled label={label} fullWidth>
      <Grid container columnSpacing={2}>
        <Grid item sm={6}>
          <NumberInput source={latSrc} label={t(tk.lat)} required={required} />
        </Grid>
        <Grid item sm={6}>
          <NumberInput source={lngSrc} label={t(tk.lng)} required={required} />
        </Grid>
        <Grid item sm={12}>
          <Map
            className={classes.map}
            apiKey={apiKey}
            center={center}
            zoom={zoom}
            onChangeCenterOrZoom={onChangeCenterOrZoomDebounced}
            SearchInThisArea={
              <DefaultButton
                text={t(tk.set)}
                onClick={handleSearchInThisArea}
              />
            }
            markers={
              latValue && lngValue
                ? [
                    {
                      position: {
                        latitude: Number(latValue),
                        longitude: Number(lngValue)
                      }
                    }
                  ]
                : undefined
            }
            controls={{
              zoomControl: true,
              scaleControl: true,
              rotateControl: true
            }}
            filterPOIs={Object.values(POIsCategory)}
          />
        </Grid>
      </Grid>
    </Labeled>
  );
};
