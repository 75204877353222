import { getEnumOrUndefined, Optional } from "@laba/ts-common";

export enum AdultOdontogramToothCode {
  T1_1 = "1.1",
  T1_2 = "1.2",
  T1_3 = "1.3",
  T1_4 = "1.4",
  T1_5 = "1.5",
  T1_6 = "1.6",
  T1_7 = "1.7",
  T1_8 = "1.8",
  T2_1 = "2.1",
  T2_2 = "2.2",
  T2_3 = "2.3",
  T2_4 = "2.4",
  T2_5 = "2.5",
  T2_6 = "2.6",
  T2_7 = "2.7",
  T2_8 = "2.8",
  T3_1 = "3.1",
  T3_2 = "3.2",
  T3_3 = "3.3",
  T3_4 = "3.4",
  T3_5 = "3.5",
  T3_6 = "3.6",
  T3_7 = "3.7",
  T3_8 = "3.8",
  T4_1 = "4.1",
  T4_2 = "4.2",
  T4_3 = "4.3",
  T4_4 = "4.4",
  T4_5 = "4.5",
  T4_6 = "4.6",
  T4_7 = "4.7",
  T4_8 = "4.8"
}

export enum ChildrenOdontogramToothCode {
  T5_1 = "5.1",
  T5_2 = "5.2",
  T5_3 = "5.3",
  T5_4 = "5.4",
  T5_5 = "5.5",
  T6_1 = "6.1",
  T6_2 = "6.2",
  T6_3 = "6.3",
  T6_4 = "6.4",
  T6_5 = "6.5",
  T7_1 = "7.1",
  T7_2 = "7.2",
  T7_3 = "7.3",
  T7_4 = "7.4",
  T7_5 = "7.5",
  T8_1 = "8.1",
  T8_2 = "8.2",
  T8_3 = "8.3",
  T8_4 = "8.4",
  T8_5 = "8.5"
}

export enum OdontogramToothSurface {
  Mesial = "Mesial",
  Distal = "Distal",
  Buccal = "Buccal",
  Lingual = "Lingual",
  Incisal = "Incisal",
  Occlusal = "Occlusal",
  Palatine = "Palatine",
  WholeTooth = "WholeTooth"
}

export type OdontogramToothCodeType =
  | AdultOdontogramToothCode
  | ChildrenOdontogramToothCode;

export const OdontogramToothCodeList: OdontogramToothCodeType[] = [
  ...Object.values(AdultOdontogramToothCode),
  ...Object.values(ChildrenOdontogramToothCode)
];

export const isOdontogramAdultToothCode = (
  tooth: OdontogramToothCodeType
): tooth is AdultOdontogramToothCode => {
  return getEnumOrUndefined(AdultOdontogramToothCode)(tooth) !== undefined;
};

export const isOdontogramChildrenToothCode = (
  tooth: OdontogramToothCodeType
): tooth is ChildrenOdontogramToothCode => {
  return getEnumOrUndefined(ChildrenOdontogramToothCode)(tooth) !== undefined;
};

export const getToothCodeFromType = (
  tooth: OdontogramToothCodeType
): Optional<AdultOdontogramToothCode | ChildrenOdontogramToothCode> => {
  if (isOdontogramAdultToothCode(tooth)) return tooth;
  if (isOdontogramChildrenToothCode(tooth)) return tooth;
  return undefined;
};
