import React from "react";
import { FC } from "@laba/react-common";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

export const Mesh01: FC<SvgIconProps> = props => {
  return (
    <svg
      {...props}
      id="Capa_2"
      data-name="Capa 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 322 55"
      opacity="0.4"
    >
      <defs>
        <clipPath id="clippath">
          <rect fill="none" x="0" width="322" height="55" />
        </clipPath>
      </defs>
      <g id="_Õ__7_x5F___2" data-name="Õº≤„_7_x5F_∏¥÷∆_2">
        <g clipPath="url(#clippath)">
          <path
            fill="#fff"
            d="m228.02-.01c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m235.58,1.2c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="242.57" cy="1.73" r=".81" />
          <path
            fill="#fff"
            d="m251.28,1.46c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m259.07.2c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m14.13-.36c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m21.91,1.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m29.77,1.95c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m37.66,1.82c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="44.67" cy=".78" r=".81" />
          <circle fill="#fff" cx="216.52" cy="-.2" r=".81" />
          <path
            fill="#fff"
            d="m224.6,2.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m231.98,4.15c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m239.59,5.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m247.41,6.25c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m255.32,6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m263.1,4.68c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m270.58,2.26c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m10.5-.32c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m18.15,1.75c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m25.92,3.32c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m33.79,4.18c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m41.7,4.14c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="48.73" cy="3.2" r=".81" />
          <path
            fill="#fff"
            d="m57.24,1.43c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m214.37-.33c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m221.67,2.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m229,4.97c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="235.63" cy="7.27" r=".81" />
          <path
            fill="#fff"
            d="m244.1,8.92c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m251.95,9.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="259.05" cy="9.5" r=".81" />
          <path
            fill="#fff"
            d="m267.64,8.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m275.1,5.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m282.16,2.09c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m7.47-.34c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m15.06,1.95c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m22.71,4.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m30.47,5.67c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="37.53" cy="6.6" r=".81" />
          <path
            fill="#fff"
            d="m46.26,6.65c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m54.14,5.81c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m61.88,4.14c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m69.43,1.76c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m219.2,2.17c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m226.54,5.15c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m233.94,8.01c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m241.44,10.58c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m249.15,12.44c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m257.01,13.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m264.93,13.19c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m272.7,11.74c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m280.15,9.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m287.18,5.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m293.82,1.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="10.62" cy="1.21" r=".81" />
          <path
            fill="#fff"
            d="m19.03,3.45c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m26.68,5.51c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m34.45,7.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="41.51" cy="7.95" r=".81" />
          <path
            fill="#fff"
            d="m50.24,7.98c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m58.12,7.14c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m65.87,5.49c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m73.43,3.14c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m80.8.25c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m216.15.69c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="222.7" cy="3.4" r=".81" />
          <path
            fill="#fff"
            d="m230.84,6.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m238.22,8.82c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="244.91" cy="11.2" r=".81" />
          <path
            fill="#fff"
            d="m253.43,12.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m261.29,13.81c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m269.21,13.62c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m276.98,12.25c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m284.44,9.76c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m291.49,6.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m298.14,2.26c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m7.68.7c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m15.29,2.92c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="22.09" cy="5.11" r=".81" />
          <path
            fill="#fff"
            d="m30.56,7.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m38.34,8.61c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="45.4" cy="9.47" r=".81" />
          <path
            fill="#fff"
            d="m54.13,9.47c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m62.01,8.63c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="68.95" cy="7" r=".81" />
          <circle fill="#fff" cx="76.52" cy="4.68" r=".81" />
          <path
            fill="#fff"
            d="m84.71,1.84c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m197.14-.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="204.21" cy="-.22" r=".81" />
          <path
            fill="#fff"
            d="m212.82.46c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m220.39,2.31c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m227.72,4.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m235.05,7.33c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m242.42,9.78c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m249.91,11.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="256.8" cy="13.59" r=".81" />
          <path
            fill="#fff"
            d="m265.48,14.4c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m273.39,14.22c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m281.17,12.92c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m288.64,10.57c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m295.7,7.37c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m302.37,3.53c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="2.9" cy=".88" r=".81" />
          <path
            fill="#fff"
            d="m11.45,2.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m19.06,4.77c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m26.69,6.9c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m34.35,8.86c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m42.14,10.3c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m50.01,11.12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m57.93,11.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m65.81,10.26c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="72.75" cy="8.64" r=".81" />
          <path
            fill="#fff"
            d="m81.14,6.36c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m88.54,3.57c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m95.68.18c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m193.64.32c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m201.34,1.68c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="208.4" cy="1.87" r=".81" />
          <path
            fill="#fff"
            d="m216.99,2.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m224.53,4.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m231.85,6.32c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m239.16,8.64c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m246.52,10.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m254.01,12.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m261.71,14.39c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m269.57,15.13c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m277.49,14.95c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="284.46" cy="13.73" r=".81" />
          <path
            fill="#fff"
            d="m292.74,11.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="299.01" cy="8.53" r=".81" />
          <path
            fill="#fff"
            d="m306.51,4.93c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m312.55.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m7.39,2.9c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m15.13,4.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m22.76,6.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m30.39,8.81c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m38.06,10.71c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="45.04" cy="12.11" r=".81" />
          <path
            fill="#fff"
            d="m53.73,12.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m61.65,12.85c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m69.52,12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m77.28,10.4c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="84.06" cy="8.16" r=".81" />
          <path
            fill="#fff"
            d="m92.28,5.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m99.44,2.09c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m190.41.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m197.76,2.75c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="204.66" cy="3.99" r=".81" />
          <path
            fill="#fff"
            d="m213.32,4.07c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m221.07,4.47c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m228.6,5.93c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="235.09" cy="7.97" r=".81" />
          <path
            fill="#fff"
            d="m243.19,10.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m250.54,12.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m258.03,13.95c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="264.91" cy="15.3" r=".81" />
          <path
            fill="#fff"
            d="m273.59,15.97c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m281.5,15.8c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m289.28,14.66c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m296.77,12.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m303.85,9.8c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m310.57,6.45c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m316.64,2.33c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m3.1,4.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m10.99,5.01c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m18.74,6.68c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m26.37,8.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m34.02,10.81c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m41.7,12.66c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m49.49,14c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m57.37,14.74c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m65.29,14.69c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m73.17,13.84c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="80.12" cy="12.25" r=".81" />
          <path
            fill="#fff"
            d="m88.52,10.04c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m95.95,7.34c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m103.14,4.09c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m110.13.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="186.62" cy="-.71" r=".81" />
          <path
            fill="#fff"
            d="m194.44,2.74c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m201.81,5.26c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m209.51,6.4c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m217.35,6.37c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m225.08,6.63c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m232.58,7.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m239.87,9.7c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m247.15,11.58c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m254.49,13.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m261.97,15.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m269.66,16.3c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m277.53,16.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m285.44,16.74c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m293.23,15.68c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m300.72,13.75c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="307.01" cy="11.17" r=".81" />
          <path
            fill="#fff"
            d="m314.55,8.05c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m320.66,4.22c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m6.64,6.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m14.53,7.19c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m22.28,8.84c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m29.92,10.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="36.76" cy="12.88" r=".81" />
          <circle fill="#fff" cx="44.46" cy="14.68" r=".81" />
          <path
            fill="#fff"
            d="m53.06,15.96c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m60.94,16.67c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m68.86,16.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="75.93" cy="15.74" r=".81" />
          <circle fill="#fff" cx="83.69" cy="14.17" r=".81" />
          <path
            fill="#fff"
            d="m92.1,11.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m99.55,9.34c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m106.76,6.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m113.78,2.67c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m191.35,2.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m198.4,5.45c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="204.98" cy="7.85" r=".81" />
          <circle fill="#fff" cx="212.68" cy="8.87" r=".81" />
          <path
            fill="#fff"
            d="m221.31,8.74c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m229.02,8.85c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m236.5,9.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m243.77,11.51c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m251.04,13.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m258.37,14.78c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m265.85,16.27c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m273.54,17.36c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m281.4,17.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m289.31,17.74c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m297.1,16.76c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m304.6,14.98c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m311.71,12.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m318.46,9.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m2.26,9.25c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m10.11,8.76c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m18,9.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m25.76,11.04c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m33.41,13.07c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m41.07,14.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m48.77,16.74c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m56.58,17.97c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m64.46,18.64c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m72.38,18.54c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m80.25,17.68c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m88.02,16.14c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m95.63,13.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m103.09,11.39c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m110.32,8.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m117.37,4.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m124.33,1.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m188.37,1.17c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m195.22,4.97c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m202.29,8.2c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m209.7,10.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m217.41,11.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m225.22,11.14c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="232.09" cy="11.12" r=".81" />
          <path
            fill="#fff"
            d="m240.36,11.98c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m247.62,13.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m254.87,14.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m262.19,16.2c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="268.85" cy="17.52" r=".81" />
          <path
            fill="#fff"
            d="m277.35,18.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m285.21,18.95c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m293.12,18.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m300.91,17.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="307.61" cy="16.25" r=".81" />
          <path
            fill="#fff"
            d="m315.55,14.07c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="321.51" cy="11.45" r=".81" />
          <path
            fill="#fff"
            d="m5.68,11.42c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m13.53,10.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="20.61" cy="11.66" r=".81" />
          <path
            fill="#fff"
            d="m29.18,13.27c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m36.84,15.27c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m44.51,17.12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="51.41" cy="18.83" r=".81" />
          <path
            fill="#fff"
            d="m60.03,20c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m67.91,20.64c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="75.02" cy="20.51" r=".81" />
          <path
            fill="#fff"
            d="m83.71,19.66c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m91.48,18.13c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m99.1,16.01c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m106.57,13.46c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m113.83,10.42c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m120.91,7.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m127.91,3.68c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m134.66.03c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m185.34.2c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m192.14,4.15c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m199.02,7.85c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m206.13,10.97c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m213.57,13.13c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m221.28,13.92c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m229.06,13.58c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m236.73,13.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m244.16,14.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m251.4,15.23c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m258.64,16.44c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m265.95,17.65c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m273.42,18.78c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="280.29" cy="19.61" r=".81" />
          <path
            fill="#fff"
            d="m288.96,20.02c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m296.87,19.87c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m304.67,19.04c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="311.38" cy="17.54" r=".81" />
          <path
            fill="#fff"
            d="m319.33,15.57c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m1.44,15.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m9.06,13.62c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m16.91,13.26c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m24.79,13.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="31.75" cy="15.53" r=".81" />
          <path
            fill="#fff"
            d="m40.22,17.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m47.91,19.3c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="54.82" cy="20.95" r=".81" />
          <path
            fill="#fff"
            d="m63.44,22.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m71.32,22.67c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="78.43" cy="22.53" r=".81" />
          <path
            fill="#fff"
            d="m87.12,21.67c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m94.89,20.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m102.52,18.07c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m110.01,15.57c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m117.29,12.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m124.4,9.37c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m131.44,6.05c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m138.24,2.53c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m182.1-.53c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m189.03,3.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m195.87,7.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m202.78,10.78c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m209.93,13.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m217.38,15.82c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m225.09,16.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m232.86,16.05c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m240.51,15.74c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m247.92,16.22c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="254.33" cy="17.14" r=".81" />
          <path
            fill="#fff"
            d="m262.36,18.13c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m269.67,19.13c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m277.13,20.09c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m284.81,20.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m292.67,21.13c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m300.58,20.98c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m308.38,20.23c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m315.9,18.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m323.06,17.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m4.75,17.37c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m12.39,15.8c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m20.24,15.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m28.13,16.2c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m35.89,17.77c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m43.57,19.7c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m51.26,21.45c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m58.99,23.05c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m66.81,24.12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m74.69,24.68c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m82.61,24.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m90.49,23.65c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m98.26,22.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m105.9,20.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m113.4,17.65c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m120.7,14.75c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m127.85,11.61c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m134.93,8.4c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m141.78,5.02c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="147.8" cy="1.8" r=".81" />
          <circle fill="#fff" cx="177.65" cy="-.56" r=".81" />
          <path
            fill="#fff"
            d="m185.73,2.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m192.68,6.34c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m199.55,10.15c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m206.5,13.68c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m213.68,16.58c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m221.15,18.49c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="228.05" cy="19.05" r=".81" />
          <path
            fill="#fff"
            d="m236.62,18.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m244.24,18.05c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m251.63,18.33c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m258.84,19.03c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m266.05,19.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="272.53" cy="20.59" r=".81" />
          <circle fill="#fff" cx="279.99" cy="21.37" r=".81" />
          <path
            fill="#fff"
            d="m288.48,21.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m296.33,22.22c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m304.25,22.07c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="311.23" cy="21.4" r=".81" />
          <path
            fill="#fff"
            d="m319.58,20.19c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx=".11" cy="22.23" r=".81" />
          <circle fill="#fff" cx="7.23" cy="19.41" r=".81" />
          <path
            fill="#fff"
            d="m15.68,17.93c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m23.54,17.69c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m31.42,18.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m39.19,19.97c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m46.87,21.86c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m54.58,23.55c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m62.31,25.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m70.14,26.12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m78.03,26.64c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m85.94,26.45c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m93.82,25.58c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m101.6,24.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m109.24,22.09c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m116.76,19.68c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m124.09,16.86c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m131.27,13.8c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="137.57" cy="10.69" r=".81" />
          <path
            fill="#fff"
            d="m145.28,7.45c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m152.17,4.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="158.38" cy="1.75" r=".81" />
          <path
            fill="#fff"
            d="m166.66.23c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m174.42.53c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m182.05,2.47c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m189.33,5.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m196.3,9.34c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="202.38" cy="13.09" r=".81" />
          <path
            fill="#fff"
            d="m210.18,16.53c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m217.4,19.32c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m224.89,21.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m232.6,21.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m240.34,20.9c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m247.94,20.3c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m255.31,20.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="261.69" cy="20.86" r=".81" />
          <path
            fill="#fff"
            d="m269.7,21.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m276.98,22c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m284.43,22.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="291.3" cy="23.04" r=".81" />
          <path
            fill="#fff"
            d="m299.96,23.25c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m307.88,23.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m315.68,22.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m323.22,21.45c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m4.14,24.07c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m11.29,21.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m18.95,19.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="26" cy="19.81" r=".81" />
          <path
            fill="#fff"
            d="m34.69,20.55c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="41.65" cy="22.08" r=".81" />
          <path
            fill="#fff"
            d="m50.15,23.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m57.87,25.58c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m65.61,27.07c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="72.63" cy="28.04" r=".81" />
          <path
            fill="#fff"
            d="m81.33,28.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m89.25,28.32c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m97.12,27.45c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m104.91,25.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m112.56,24.01c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m120.09,21.64c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m127.44,18.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m134.66,15.92c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m141.8,12.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m148.75,9.81c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m155.69,6.9c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="161.95" cy="4.43" r=".81" />
          <path
            fill="#fff"
            d="m170.24,3.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m178,3.45c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="184.81" cy="5.44" r=".81" />
          <path
            fill="#fff"
            d="m192.9,8.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m199.88,12.27c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m206.81,15.95c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m213.84,19.3c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m221.09,21.98c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m228.6,23.65c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m236.31,23.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m244.04,23.22c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m251.61,22.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m258.96,22.37c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m266.14,22.63c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m273.32,22.95c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m280.59,23.33c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m288.04,23.75c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="294.9" cy="24.07" r=".81" />
          <path
            fill="#fff"
            d="m303.57,24.21c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m311.48,24.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m319.28,23.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m1.01,29.61c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m7.33,25.81c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="13.71" cy="23.25" r=".81" />
          <path
            fill="#fff"
            d="m22.2,21.95c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m30.05,21.83c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m37.93,22.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m45.71,24.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m53.41,25.92c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m61.13,27.51c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m68.89,28.95c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m76.72,29.86c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="83.81" cy="30.31" r=".81" />
          <path
            fill="#fff"
            d="m92.53,30.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m100.41,29.21c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m108.19,27.77c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m115.86,25.82c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m123.4,23.51c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m130.78,20.82c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m138.02,17.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m145.2,15.04c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m152.21,12.07c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m159.2,9.32c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m166.3,7.02c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m173.81,5.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="180.75" cy="6.28" r=".81" />
          <circle fill="#fff" cx="188.36" cy="8.31" r=".81" />
          <path
            fill="#fff"
            d="m196.45,11.43c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m203.45,15.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m210.41,18.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m217.47,21.98c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m224.76,24.55c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m232.29,26.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m240,26.32c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m247.71,25.45c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m255.26,24.57c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m262.59,24.26c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m269.75,24.29c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m276.92,24.4c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m284.18,24.57c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m291.62,24.81c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m299.29,25c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m307.15,25.07c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m315.06,24.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m322.86,24.51c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m4.1,31.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m10.51,27.43c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m17.74,25c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m25.43,23.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m33.29,23.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m41.17,24.51c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m48.95,26c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m56.65,27.78c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m64.39,29.32c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m72.15,30.71c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="79.18" cy="31.57" r=".81" />
          <path
            fill="#fff"
            d="m87.88,31.98c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m95.8,31.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m103.68,30.85c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m111.47,29.43c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m119.14,27.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m126.7,25.25c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m134.09,22.63c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m141.37,19.84c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m148.59,17.04c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m155.65,14.21c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m162.69,11.62c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m169.84,9.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="176.55" cy="8.39" r=".81" />
          <path
            fill="#fff"
            d="m185.1,8.98c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m192.7,11.05c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m199.98,14.17c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m207,17.81c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m213.99,21.37c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m221.09,24.54c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m228.41,27c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m235.97,28.42c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m243.68,28.53c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m251.37,27.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m258.9,26.53c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m266.21,26.03c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m273.35,25.84c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m280.5,25.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m287.76,25.69c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m295.19,25.75c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m302.86,25.81c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m310.71,25.82c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m318.62,25.69c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m1.35,36.64c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m7.18,32.37c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m13.68,28.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m20.95,26.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m28.66,25.49c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m36.51,25.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m44.39,26.29c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m52.18,27.76c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m59.89,29.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m67.63,30.98c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m75.41,32.33c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m83.25,33.13c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m91.15,33.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m99.06,33.23c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m106.94,32.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m114.73,30.95c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m122.41,29.07c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m129.98,26.85c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m137.4,24.3c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m144.71,21.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m151.97,18.9c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m159.08,16.2c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m166.17,13.77c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m173.36,11.8c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m180.9,10.86c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m188.64,11.54c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m196.22,13.66c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m203.51,16.78c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="209.73" cy="20.38" r=".81" />
          <path
            fill="#fff"
            d="m217.56,23.87c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="223.89" cy="26.95" r=".81" />
          <path
            fill="#fff"
            d="m232.06,29.3c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m239.63,30.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m247.34,30.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m255.02,29.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m262.53,28.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m269.81,27.65c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m276.94,27.24c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m284.08,26.9c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m291.32,26.66c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m298.75,26.55c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m306.42,26.47c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m314.27,26.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m322.18,26.3c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m4.31,37.61c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m10.27,33.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m16.85,30.22c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m24.16,28.04c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m31.88,27.02c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m39.74,27.07c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m47.61,27.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m55.4,29.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m63.12,31.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m70.88,32.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m78.66,33.77c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m86.51,34.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m94.41,34.86c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m102.32,34.57c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="109.39" cy="33.69" r=".81" />
          <circle fill="#fff" cx="117.18" cy="32.3" r=".81" />
          <path
            fill="#fff"
            d="m125.68,30.45c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m133.27,28.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m140.71,25.8c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m148.05,23.18c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m155.35,20.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="161.7" cy="18.03" r=".81" />
          <path
            fill="#fff"
            d="m169.65,15.76c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m176.89,13.96c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m184.44,13.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m192.17,13.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m199.75,16.09c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="206.22" cy="19.21" r=".81" />
          <path
            fill="#fff"
            d="m214.08,22.78c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m221.14,26.21c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m228.31,29.2c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m235.7,31.44c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m243.3,32.62c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m251.01,32.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m258.67,31.31c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m266.16,30.01c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m273.42,29.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m280.53,28.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m287.65,27.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m294.89,27.47c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m302.31,27.17c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m309.98,26.97c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m317.83,26.85c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx=".9" cy="43.02" r=".81" />
          <circle fill="#fff" cx="6.47" cy="38.39" r=".81" />
          <path
            fill="#fff"
            d="m13.35,34.47c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m20.02,31.34c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m27.38,29.29c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="34.3" cy="28.36" r=".81" />
          <path
            fill="#fff"
            d="m42.97,28.47c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m50.84,29.31c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m58.63,30.75c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m66.36,32.42c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m74.13,33.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m81.92,35.03c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m89.77,35.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m97.67,36.02c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m105.59,35.71c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m113.46,34.83c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m121.26,33.46c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m128.96,31.64c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m136.56,29.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m144.03,27.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m151.4,24.58c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m158.74,22.09c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m165.94,19.67c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m173.14,17.55c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m180.42,15.92c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="187.18" cy="15.26" r=".81" />
          <path
            fill="#fff"
            d="m195.71,16.14c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m203.27,18.34c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m210.56,21.46c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m217.63,24.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m224.71,28.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m231.93,31.25c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m239.35,33.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m246.97,34.44c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m254.68,34.21c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m262.33,32.92c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m269.79,31.47c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m277.03,30.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m284.12,29.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="290.42" cy="28.74" r=".81" />
          <path
            fill="#fff"
            d="m298.46,28.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m305.88,27.61c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m313.54,27.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m321.39,27.09c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m4.55,43.43c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m10.26,38.96c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m16.45,35.21c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m23.21,32.24c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m30.6,30.32c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m38.35,29.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="45.4" cy="29.65" r=".81" />
          <path
            fill="#fff"
            d="m54.08,30.51c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m61.88,31.93c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m69.61,33.57c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m77.39,34.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m85.19,36.07c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m93.05,36.71c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m100.95,36.97c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m108.86,36.64c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m116.74,35.75c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m124.54,34.4c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="131.44" cy="32.62" r=".81" />
          <circle fill="#fff" cx="139.05" cy="30.55" r=".81" />
          <path
            fill="#fff"
            d="m147.35,28.21c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m154.76,25.77c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m162.13,23.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="168.58" cy="21.1" r=".81" />
          <path
            fill="#fff"
            d="m176.64,19.14c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="183.15" cy="17.67" r=".81" />
          <path
            fill="#fff"
            d="m191.55,17.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m199.26,18.13c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m206.81,20.37c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m214.1,23.49c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m221.19,26.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m228.3,30.29c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m235.56,33.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m243.01,35.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m250.65,36.05c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m258.36,35.7c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m265.99,34.31c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m273.43,32.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m280.65,31.43c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="286.92" cy="30.35" r=".81" />
          <path
            fill="#fff"
            d="m294.82,29.34c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m302.04,28.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m309.45,27.83c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m317.11,27.37c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="1.01" cy="48.4" r=".81" />
          <path
            fill="#fff"
            d="m7.4,43.61c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m13.25,39.29c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m19.57,35.71c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="25.61" cy="32.91" r=".81" />
          <path
            fill="#fff"
            d="m33.85,31.12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="40.8" cy="30.37" r=".81" />
          <path
            fill="#fff"
            d="m49.47,30.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m57.34,31.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m65.14,32.87c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m72.88,34.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m80.67,35.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m88.48,36.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m96.34,37.46c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m104.24,37.69c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m112.16,37.33c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m120.03,36.44c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m127.84,35.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m135.55,33.36c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m143.18,31.33c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m150.7,29.07c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m158.14,26.71c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="164.74" cy="24.43" r=".81" />
          <path
            fill="#fff"
            d="m172.85,22.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m180.16,20.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m187.52,19.18c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m195.13,18.81c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m202.83,19.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m210.37,22.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m217.66,25.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m224.76,28.75c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m231.91,31.98c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="238.39" cy="34.7" r=".81" />
          <path
            fill="#fff"
            d="m246.69,36.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m254.35,37.42c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m262.06,36.96c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m269.68,35.46c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m277.1,33.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="283.48" cy="32.24" r=".81" />
          <path
            fill="#fff"
            d="m291.35,30.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="297.63" cy="29.71" r=".81" />
          <path
            fill="#fff"
            d="m305.64,28.64c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="312.24" cy="27.82" r=".81" />
          <path
            fill="#fff"
            d="m320.71,27.22c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m4.56,48.18c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m10.28,43.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m16.28,39.36c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="21.91" cy="35.95" r=".81" />
          <path
            fill="#fff"
            d="m29.65,33.32c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m37.12,31.65c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m44.89,30.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m52.75,31.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m60.62,32.18c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="67.62" cy="33.56" r=".81" />
          <circle fill="#fff" cx="75.37" cy="35.12" r=".81" />
          <path
            fill="#fff"
            d="m83.97,36.33c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m91.79,37.42c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m99.66,37.95c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="106.76" cy="38.14" r=".81" />
          <path
            fill="#fff"
            d="m115.48,37.76c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m123.35,36.87c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m131.17,35.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m138.89,33.84c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m146.53,31.86c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m154.07,29.66c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="160.73" cy="27.4" r=".81" />
          <path
            fill="#fff"
            d="m168.99,25.22c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m176.34,23.21c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m183.7,21.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m191.11,20.43c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m198.73,20.2c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m206.43,21.37c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m213.96,23.7c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m221.24,26.81c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m228.37,30.24c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m235.55,33.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m242.87,36.04c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m250.4,37.84c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m258.07,38.53c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m265.79,37.96c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m273.39,36.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m280.78,34.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m287.96,32.8c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m295,31.27c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="301.26" cy="29.82" r=".81" />
          <path
            fill="#fff"
            d="m309.27,28.55c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m316.67,27.54c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m1.52,52.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m7.34,47.67c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m13.2,43.15c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m19.33,39.14c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m25.89,35.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m32.91,33.44c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m40.42,31.9c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m48.21,31.33c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m56.07,31.68c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m63.94,32.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m71.74,33.96c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m79.51,35.49c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m87.31,36.64c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m95.14,37.68c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="102.2" cy="38.16" r=".81" />
          <path
            fill="#fff"
            d="m110.92,38.31c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m118.83,37.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m126.71,37.01c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m134.52,35.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m142.25,34.03c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m149.91,32.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m157.47,29.98c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m164.97,27.8c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m172.46,25.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m179.87,23.85c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m187.27,22.36c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m194.72,21.4c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m202.37,21.31c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m210.05,22.57c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m217.57,24.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m224.86,28.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m232,31.46c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m239.21,34.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m246.58,37.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m254.13,38.78c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m261.83,39.36c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m269.55,38.67c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="276.32" cy="36.96" r=".81" />
          <path
            fill="#fff"
            d="m284.5,34.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m291.66,33.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m298.69,31.31c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="304.93" cy="29.65" r=".81" />
          <path
            fill="#fff"
            d="m312.93,28.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m320.33,26.98c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="3.41" cy="51.66" r=".81" />
          <path
            fill="#fff"
            d="m10.15,46.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m16.15,42.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m22.42,38.64c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m29.11,35.58c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m36.21,33.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m43.76,31.87c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m51.56,31.39c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="58.62" cy="31.8" r=".81" />
          <path
            fill="#fff"
            d="m67.29,32.74c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m75.1,34.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m82.87,35.57c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m90.69,36.66c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m98.52,37.65c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="105.6" cy="38.08" r=".81" />
          <path
            fill="#fff"
            d="m114.31,38.19c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="121.41" cy="37.78" r=".81" />
          <circle fill="#fff" cx="129.29" cy="36.88" r=".81" />
          <path
            fill="#fff"
            d="m137.92,35.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m145.66,33.95c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="152.52" cy="32.06" r=".81" />
          <path
            fill="#fff"
            d="m160.91,30.01c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m168.45,27.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m175.97,25.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="182.62" cy="24.21" r=".81" />
          <path
            fill="#fff"
            d="m190.89,22.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m198.38,22.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m206.04,22.14c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m213.72,23.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m221.23,25.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m228.52,29.03c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m235.68,32.39c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m242.92,35.43c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m250.32,37.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m257.91,39.45c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="264.82" cy="39.91" r=".81" />
          <path
            fill="#fff"
            d="m273.34,39.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m280.91,37.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m288.26,35.12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="294.59" cy="33.05" r=".81" />
          <path
            fill="#fff"
            d="m302.41,31.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="308.64" cy="29.19" r=".81" />
          <path
            fill="#fff"
            d="m316.62,27.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m.65,55.17c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m6.97,50.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m13.01,45.81c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m19.15,41.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m25.56,37.86c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m32.37,34.97c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m39.56,32.83c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m47.15,31.55c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m54.97,31.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m62.83,31.63c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m70.69,32.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m78.51,33.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m86.29,35.36c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m94.11,36.4c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m101.95,37.34c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m109.84,37.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m117.75,37.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="124.85" cy="37.35" r=".81" />
          <circle fill="#fff" cx="132.73" cy="36.45" r=".81" />
          <path
            fill="#fff"
            d="m141.36,35.19c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m149.1,33.57c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m156.79,31.74c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m164.4,29.75c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m171.97,27.75c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m179.53,25.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m187.04,24.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m194.54,23.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m202.08,22.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="208.95" cy="22.68" r=".81" />
          <path
            fill="#fff"
            d="m217.43,24.13c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m224.93,26.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m232.22,29.7c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m239.4,33.03c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m246.67,36c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m254.11,38.37c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m261.73,39.83c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m269.47,40.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m277.19,39.25c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m284.74,37.32c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="291.26" cy="35.02" r=".81" />
          <path
            fill="#fff"
            d="m299.18,32.75c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m306.17,30.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="312.39" cy="28.44" r=".81" />
          <path
            fill="#fff"
            d="m320.37,26.55c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m3.41,54.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m9.76,49.43c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m15.92,44.87c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m22.2,40.75c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m28.75,37.2c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m35.68,34.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m42.96,32.51c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m50.59,31.36c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m58.42,31.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m66.28,31.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m74.14,32.57c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="81.15" cy="33.87" r=".81" />
          <path
            fill="#fff"
            d="m89.75,35.29c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m97.58,36.27c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m105.44,37.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m113.33,37.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m121.24,37.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m129.15,37.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m137.03,36.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="144.04" cy="34.91" r=".81" />
          <path
            fill="#fff"
            d="m152.61,33.33c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m160.3,31.54c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m167.94,29.63c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m175.54,27.71c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m183.14,25.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m190.7,24.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m198.26,23.47c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="205.03" cy="23.01" r=".81" />
          <path
            fill="#fff"
            d="m213.53,23.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m221.2,24.9c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m228.68,27.4c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m235.97,30.51c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m243.17,33.8c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m250.47,36.71c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m257.95,38.98c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m265.61,40.34c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m273.37,40.55c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m281.08,39.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m288.62,37.49c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m295.92,35.05c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m303.02,32.58c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m309.99,30.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m317,27.83c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m6.23,52.98c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m12.62,48.37c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m18.89,43.92c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="24.5" cy="39.94" r=".81" />
          <path
            fill="#fff"
            d="m32,36.54c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m39.05,34c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m46.41,32.19c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m54.09,31.17c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m61.93,30.96c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="68.98" cy="31.54" r=".81" />
          <path
            fill="#fff"
            d="m77.65,32.55c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m85.48,33.83c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m93.27,35.21c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m101.12,36.14c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m108.98,36.98c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m116.88,37.24c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="123.98" cy="37.24" r=".81" />
          <path
            fill="#fff"
            d="m132.7,36.76c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m140.57,35.86c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m148.4,34.63c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m156.17,33.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m163.88,31.34c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m171.54,29.49c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m179.17,27.66c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="185.99" cy="26" r=".81" />
          <path
            fill="#fff"
            d="m194.41,24.68c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m202.03,23.82c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m209.65,23.53c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m217.36,24.01c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="224.21" cy="25.66" r=".81" />
          <path
            fill="#fff"
            d="m232.49,28.2c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m239.78,31.32c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m247,34.57c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m254.33,37.42c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m261.84,39.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m269.54,40.84c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m277.32,40.93c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m285.04,39.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m292.56,37.65c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m299.84,35.07c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m306.91,32.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m313.87,29.77c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="320.06" cy="27.21" r=".81" />
          <path
            fill="#fff"
            d="m2.56,55.75c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="8.3" cy="51.48" r=".81" />
          <path
            fill="#fff"
            d="m15.54,46.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m21.92,42.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="27.67" cy="38.72" r=".81" />
          <path
            fill="#fff"
            d="m35.32,35.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m42.49,33.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m49.94,31.47c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m57.65,30.57c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m65.51,30.45c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m73.37,31.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m81.23,32.12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="88.25" cy="33.38" r=".81" />
          <path
            fill="#fff"
            d="m96.86,34.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m104.72,35.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m112.58,36.39c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="119.68" cy="36.6" r=".81" />
          <path
            fill="#fff"
            d="m128.4,36.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m136.31,36.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m144.19,35.15c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m152.02,33.95c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="158.98" cy="32.43" r=".81" />
          <path
            fill="#fff"
            d="m167.52,30.74c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m175.2,28.96c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m182.86,27.22c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m190.54,25.66c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m198.2,24.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m205.86,23.77c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m213.53,23.65c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m221.26,24.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m228.91,26.02c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m236.37,28.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m243.66,31.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m250.89,34.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m258.26,37.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m265.81,39.81c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m273.54,40.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m281.34,40.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m289.06,39.65c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m296.56,37.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m303.82,34.68c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m310.87,31.83c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m317.81,28.97c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m5.56,53.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m12.07,49.55c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m18.54,45.03c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m25.03,40.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m31.73,37.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m38.71,33.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m46,31.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m53.53,30.32c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m61.29,29.55c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m69.16,29.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m77.02,30.23c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m84.88,31.27c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m92.71,32.51c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m100.52,33.82c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m108.39,34.64c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m116.26,35.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m124.17,35.53c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m132.09,35.46c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m140,34.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="147.06" cy="34.03" r=".81" />
          <path
            fill="#fff"
            d="m155.71,32.84c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m163.49,31.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m171.23,29.71c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m178.94,28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m186.63,26.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m194.34,24.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m202.05,23.85c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m209.77,23.3c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m217.48,23.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="224.41" cy="24.12" r=".81" />
          <path
            fill="#fff"
            d="m232.87,25.95c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m240.32,28.58c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="246.8" cy="31.69" r=".81" />
          <path
            fill="#fff"
            d="m254.86,34.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m262.26,37.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m269.85,39.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="276.8" cy="40.62" r=".81" />
          <path
            fill="#fff"
            d="m285.43,40.47c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m293.15,39.09c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m300.64,36.75c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="307.07" cy="33.88" r=".81" />
          <path
            fill="#fff"
            d="m314.9,30.83c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m321.83,27.74c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m8.63,51.63c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m15.1,47.17c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m21.61,42.7c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m28.22,38.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m35.06,34.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m42.17,32.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="48.78" cy="30.02" r=".81" />
          <path
            fill="#fff"
            d="m57.21,28.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m65,28.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m72.89,28.14c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m80.75,28.9c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m88.61,29.97c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m96.44,31.19c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m104.26,32.46c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m112.14,33.23c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m120.02,33.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="127.13" cy="34.02" r=".81" />
          <path
            fill="#fff"
            d="m135.86,33.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m143.76,33.36c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m151.64,32.45c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m159.48,31.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m167.27,29.82c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m175.02,28.23c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m182.75,26.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m190.48,25.02c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m198.23,23.67c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m205.99,22.77c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m213.76,22.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m221.51,22.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m229.27,23.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m236.91,25.44c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m244.35,28.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m251.64,31.22c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="258.1" cy="34.37" r=".81" />
          <path
            fill="#fff"
            d="m266.33,37.02c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="273.15" cy="38.93" r=".81" />
          <path
            fill="#fff"
            d="m281.76,39.84c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m289.6,39.57c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m297.32,38.09c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m304.8,35.63c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m312.01,32.62c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m319.01,29.37c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m5.52,53.42c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m11.79,48.83c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m18.22,44.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m24.77,39.85c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m31.49,35.82c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m38.47,32.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m45.72,29.61c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m53.26,27.75c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m60.97,26.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m68.8,26.09c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m76.7,26.25c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m84.57,27.07c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m92.42,28.15c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m100.26,29.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m108.09,30.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m115.97,31.3c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m123.87,31.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m131.79,31.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m139.71,31.84c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m147.62,31.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m155.49,30.36c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m163.33,29.2c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m171.13,27.78c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m178.9,26.24c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m186.65,24.67c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m194.41,23.19c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m202.2,21.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m210.01,21.17c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m217.83,20.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m225.63,21.32c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m233.4,22.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m241.03,24.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m248.46,27.12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m255.76,30.23c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m263.04,33.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="269.69" cy="35.93" r=".81" />
          <path
            fill="#fff"
            d="m278.16,37.76c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m285.99,38.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m293.86,38.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m301.57,36.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m309.04,34.01c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m316.23,30.85c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m323.21,27.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m2.72,55.03c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m8.83,50.18c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m15.04,45.39c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m21.43,40.75c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m28.02,36.36c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m34.85,32.43c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m41.97,29.13c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m49.37,26.51c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m57.03,24.82c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="64.01" cy="23.85" r=".81" />
          <path
            fill="#fff"
            d="m72.7,23.46c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m80.61,23.71c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m88.47,24.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m96.33,25.69c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m104.17,26.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m112,28.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m119.9,28.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m127.8,29.32c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m135.73,29.32c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m143.65,29.13c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m151.56,28.54c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m159.43,27.62c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="166.47" cy="26.49" r=".81" />
          <path
            fill="#fff"
            d="m175.08,25.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m182.87,23.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m190.65,22.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m198.43,20.71c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="205.45" cy="19.57" r=".81" />
          <path
            fill="#fff"
            d="m214.12,18.93c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m221.99,18.86c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m229.83,19.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m237.63,20.61c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m245.25,22.74c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m252.67,25.49c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m259.96,28.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m267.27,31.68c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m274.75,34.2c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m282.46,35.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m290.32,36.63c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m298.21,36.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m305.92,34.4c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m313.37,31.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m320.54,28.44c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m5.78,51.53c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m11.85,46.42c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m18.01,41.43c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m24.37,36.69c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m30.99,32.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m37.94,28.53c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m45.2,25.36c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m52.73,22.9c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m60.52,21.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="67.59" cy="20.57" r=".81" />
          <path
            fill="#fff"
            d="m76.31,20.31c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m84.23,20.65c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m92.1,21.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m99.95,22.71c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m107.8,23.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m115.64,25.04c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m123.55,25.65c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m131.46,26.18c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m139.39,26.12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="146.51" cy="25.9" r=".81" />
          <path
            fill="#fff"
            d="m155.22,25.29c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m163.1,24.37c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="170.14" cy="23.25" r=".81" />
          <path
            fill="#fff"
            d="m178.76,21.9c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="185.75" cy="20.45" r=".81" />
          <path
            fill="#fff"
            d="m194.36,19.02c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m202.18,17.71c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m210.04,16.68c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m217.96,16.18c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m225.88,16.26c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m233.76,16.98c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="240.77" cy="18.32" r=".81" />
          <path
            fill="#fff"
            d="m249.19,20.54c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m256.6,23.34c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m263.89,26.45c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m271.21,29.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m278.73,31.95c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m286.47,33.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m294.37,34.18c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m302.28,33.54c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m309.99,31.71c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m317.42,28.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
        </g>
      </g>
    </svg>
  );
};
