import { Model, OpenCode } from "model/primitives/model/model";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { Condition } from "./condition";

export enum KnownDiagnosisRole {
  AD = "AD",
  SantaCatalinaClassification = "SantaCatalinaClassification",
  MedicalAdmissionDiagnosis = "MedicalAdmissionDiagnosis",
  DD = "DD"
}

export type DiagnosisRole = OpenCode<KnownDiagnosisRole>;

export type Diagnosis = ModelReference<Condition>;

export interface DiagnosisContainer extends Model {
  condition: Diagnosis;
  use?: DiagnosisRole;
}

export const DiagnosisContainerKey = getKeyObj<DiagnosisContainer>(
  createHydratedMock<DiagnosisContainer>()
);
