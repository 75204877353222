import {
  createStyle,
  FlexAlignSelf,
  flexColumnMixin,
  flexItemMixin
} from "@laba/react-common";
import { Theme } from "model/theme";

export const useChatMessagesStyle = createStyle(
  (_theme: Theme) => ({
    container: {
      ...flexColumnMixin({
        gap: 8
      })
    },
    send: {
      ...flexItemMixin({
        align: FlexAlignSelf.FlexEnd
      }),
      maxWidth: "70%"
    },
    received: {
      ...flexItemMixin({
        align: FlexAlignSelf.FlexStart
      }),
      maxWidth: "70%"
    }
  }),
  "ChatMessages"
);
