import { PublicAppointmentData } from "model/resource";
import { ResourceType } from "model/primitives";

export const PublicAppointmentDataMock: PublicAppointmentData = {
  resourceType: ResourceType.PublicAppointment,
  practitionerTeam: [
    {
      firstName: "Lucila",
      lastName: "Arcasti",
      resourceType: ResourceType.PublicPractitioner
    }
  ],
  patientInstruction:
    "Instrucciones y aclaraciones para el paciente que se hayan configurado en la creación de la agenda.",
  location: {
    resourceType: ResourceType.PublicLocation,
    name: "",
    organization: {
      resourceType: ResourceType.PublicOrganization,
      address: {
        resourceType: ResourceType.Address,
        street: "Av.Puerreydon",
        streetNumber: "1192",
        state: "CABA"
      }
    }
  },
  schedule: {
    resourceType: ResourceType.PublicSchedule,
    id: "90411",
    performerList: [
      {
        firstName: "Lucila",
        lastName: "Arcasti",
        resourceType: ResourceType.PublicPractitioner
      },
      {
        firstName: "otroPRactitioner",
        lastName: "otroPRactitioner",
        resourceType: ResourceType.PublicPractitioner
      }
    ],
    speciality: [{ code: "Psicologia", display: "Psicologia", property: [] }]
  },
  patient: "4704",
  patientPhone: "1137794231",
  patientEmail: "asdasda@gmail.com",
  minutesDuration: 15,
  startDate: "2024-10-22T10:00:00.000Z"
};
