import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  getModelReferenceId,
  HospitalizationKey,
  KnownCodeSystemSystem,
  KnownIdentifierSystem,
  LocationType,
  ModelReference,
  Organization,
  OrganizationCombinedType,
  OrganizationListQueryParamsKey,
  Patient,
  PatientListQueryParamsKey,
  ResourceType
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { patientOptionText } from "components/generic/ReferenceResourceInput/optionText";
import { DateTimeInput } from "components/generic/DateTimeInput/DateTimeInput";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";
import { SingleIdentifierTextInput } from "components/generic/SingleIdentifierTextInput/SingleIdentifierTextInput";
import { LocationResourceInput } from "components/generic/LocationResourceInput/LocationResourceInput";

const tk = tkCP.adminPage[ResourceType.Hospitalization];
export const HospitalizationMainContent: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const hospitalizationOrganization = useWatchResourceForm<
    ModelReference<Organization>
  >(HospitalizationKey.organization);
  const organizationId =
    getModelReferenceId(hospitalizationOrganization) || workspaceOrganizationId;
  const serviceTypeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.ServiceType,
    organizationId
  );

  const statusReasonCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.HospitalizationStatusReason,
    organizationId
  );

  return (
    <>
      <TextInput
        disabled
        source={HospitalizationKey.id}
        label={t(tk.fields.id)}
      />
      <OrganizationReferenceInput
        source={HospitalizationKey.organization}
        label={t(tk.fields.organization)}
        type={OrganizationCombinedType.ProviderAndGroup}
        extraFilters={{
          [OrganizationListQueryParamsKey.active]: true
        }}
        defaultValue={workspaceOrganizationId}
        disabled={workspaceOrganizationId !== undefined}
      />
      <ReferenceResourceInput<Patient>
        source={HospitalizationKey.patient}
        label={t(tk.fields.patient)}
        resourceType={ResourceType.Patient}
        optionTextKey={patientOptionText}
        extraFilters={{
          [PatientListQueryParamsKey.organization]: organizationId
        }}
      />
      <CodeSystemSelectorInput
        source={HospitalizationKey.statusReason}
        label={t(tk.fields.status)}
        codeSystem={statusReasonCodeSystem}
        organizationId={organizationId}
        withCreate
      />
      <DateTimeInput
        source={HospitalizationKey.startDate}
        label={t(tk.fields.startDate)}
      />
      <DateTimeInput
        source={HospitalizationKey.finishDate}
        label={t(tk.fields.finishDate)}
      />
      <SingleIdentifierTextInput
        identifierListPath={HospitalizationKey.identifier}
        system={KnownIdentifierSystem.HospitalizationAuthorization}
        label={t(tk.fields.authorizationCode)}
      />
      <TextInput
        source={HospitalizationKey.hisCode}
        label={t(tk.fields.hisCode)}
      />
      <TextInput
        source={HospitalizationKey.hisType}
        label={t(tk.fields.hisType)}
      />
      <TextInput
        source={HospitalizationKey.hisOrigin}
        label={t(tk.fields.hisOrigin)}
      />
      <CodeSystemSelectorInput
        source={HospitalizationKey.service}
        label={t(tk.fields.service)}
        codeSystem={serviceTypeSystem}
        organizationId={organizationId}
        withCreate
      />
      <LocationResourceInput
        locationListPath={HospitalizationKey.location}
        physicalType={LocationType.Bed}
        label={t(tk.fields.currentBed)}
        organizationId={organizationId}
      />
      <LocationResourceInput
        locationListPath={HospitalizationKey.location}
        physicalType={LocationType.Building}
        label={t(tk.fields.currentBuilding)}
        organizationId={organizationId}
      />
    </>
  );
};
