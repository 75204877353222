import { ResourceType } from "model/primitives/resourceModel";
import {
  ActivityDefinition,
  ActivityDefinitionKind
} from "model/resource/activity/activityDefinition";
import { getKeyObj, KeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { ObservationCode } from "./observation";

export enum MeasurementGroupCategory {
  VitalSigns = "VitalSigns",
  FluidBalance = "FluidBalance",
  Procedure = "Procedure"
}

export enum FluidBalanceCategory {
  Input = "Input",
  Output = "Output",
  Diuresis = "Diuresis"
}

export interface MeasurementConfig {
  linkId: string;
  title: string;
  type: ObservationCode;
  units?: string[];
  maxValue?: number;
  minValue?: number;
  step?: number;
  required?: boolean;
  isComponent?: boolean;
  fluidBalanceCategory?: FluidBalanceCategory;
  range?: string;
}

export const MeasurementConfigKey: KeyObj<MeasurementConfig> = getKeyObj(
  createHydratedMock<MeasurementConfig>()
);

export interface MeasurementGroupDefinition
  extends ActivityDefinition<
    ResourceType.MeasurementGroupDefinition,
    ActivityDefinitionKind.Observation
  > {
  category: MeasurementGroupCategory;
  color?: string;
  editPeriod?: number;
  measurements: MeasurementConfig[];
}

export const MeasurementGroupDefinitionKey: KeyObj<MeasurementGroupDefinition> =
  getKeyObj(createHydratedMock<MeasurementGroupDefinition>());
