import { FC, getClassName } from "@laba/react-common";
import React from "react";
import { Noop, Optional } from "@laba/ts-common";
import { OdontogramIconType, OdontogramItemIcon } from "components/icons";
import { useOdontogramItemStyles } from "components/odontogram/OdontogramItem/useOdontogramItemStyles";
import { ButtonBase } from "components/buttons/ButtonBase/ButtonBase";

export enum OdontogramItemDiagnosticPosition {
  Top = "Top",
  Bottom = "Bottom",
  Left = "Left",
  Right = "Right",
  Center = "Center",
  Whole = "Whole"
}

export interface OdontogramItemToothDiagnosticType {
  Icon: OdontogramIconType;
  diagnosticColor: string;
  surface: OdontogramItemDiagnosticPosition[];
}

export interface OdontogramItemProps {
  onClick: Noop;
  tooth: string;
  diagnosticList: OdontogramItemToothDiagnosticType[];
  disabled?: boolean;
  clickable?: boolean;
  key?: string;
}

export const OdontogramItem: FC<OdontogramItemProps> = ({
  tooth,
  diagnosticList,
  onClick,
  disabled = false,
  clickable = true,
  key
}) => {
  const classes = useOdontogramItemStyles({ disabled });

  const getDiagnosticIconClassName = (
    toothCode: string,
    position: OdontogramItemDiagnosticPosition
  ): Optional<string> => {
    switch (position) {
      case OdontogramItemDiagnosticPosition.Bottom:
        return getClassName(
          classes.positionBottom,
          classes.surfaceDiagnosticIcon
        );
      case OdontogramItemDiagnosticPosition.Top:
        return getClassName(classes.positionTop, classes.surfaceDiagnosticIcon);
      case OdontogramItemDiagnosticPosition.Left:
        return getClassName(
          classes.positionLeft,
          classes.surfaceDiagnosticIcon
        );
      case OdontogramItemDiagnosticPosition.Right:
        return getClassName(
          classes.positionRight,
          classes.surfaceDiagnosticIcon
        );
      case OdontogramItemDiagnosticPosition.Center:
        return getClassName(
          classes.positionCenter,
          classes.surfaceDiagnosticIcon
        );
      case OdontogramItemDiagnosticPosition.Whole:
        return getClassName(
          classes.wholeToothDiagnosticIcon,
          classes.positionCenter
        );
    }
  };
  return (
    <ButtonBase
      className={getClassName(
        classes.root,
        !clickable ? classes.defaultCursor : undefined
      )}
      onClick={onClick}
      disableRipple
      disabled={disabled}
      key={key}
    >
      <div className={classes.toothContainer}>
        <OdontogramItemIcon className={classes.toothIcon} />
        {diagnosticList.map(d => {
          const { Icon, diagnosticColor } = d;
          return d.surface.map(s => {
            const diagnosticIconClassName = getDiagnosticIconClassName(
              tooth,
              s
            );
            return (
              diagnosticIconClassName && (
                <Icon
                  className={diagnosticIconClassName}
                  iconColor={diagnosticColor}
                />
              )
            );
          });
        })}
      </div>
      <p className={classes.name}>{tooth}</p>
    </ButtonBase>
  );
};
