import { FC, getClassName, SizeVariant, TextAlign } from "@laba/react-common";
import { BaseIconButton } from "components/buttons/BaseIconButton/BaseIconButton";
import { Add, DeleteIcon, RemoveIcon } from "components/icons";
import { isEmpty, isUndefined } from "lodash-es";
import { Noop } from "@laba/ts-common";
import React from "react";
import { InputType, TextInput } from "../TextInput/TextInput";
import { useQuantitySelectorStyle } from "./QuantitySelectorStyle";

export interface QuantitySelectorProps {
  className?: string;

  decreaseQuantityButtonTitle?: string;
  increaseQuantityButtonTitle?: string;
  onChangeQuantity?: (quantity?: number) => void;
  onDeleteTrashIcon?: Noop;

  showTrashIcon?: boolean;
  quantity?: number;
  maxValue?: number;
  minValue?: number;
}

export const QuantitySelector: FC<QuantitySelectorProps> = ({
  className,
  onChangeQuantity,
  onDeleteTrashIcon,
  increaseQuantityButtonTitle,
  decreaseQuantityButtonTitle,
  quantity,
  showTrashIcon = false,
  maxValue = 9999,
  minValue
}) => {
  const classes = useQuantitySelectorStyle();

  const enabledTrashIcon =
    showTrashIcon && (isUndefined(quantity) || quantity <= 1);

  return (
    <div className={getClassName(className, classes.quantityContainer)}>
      <BaseIconButton
        title={decreaseQuantityButtonTitle}
        onClick={() => {
          if (enabledTrashIcon) return onDeleteTrashIcon?.();
          onChangeQuantity?.(isUndefined(quantity) ? undefined : quantity - 1);
        }}
        className={classes.quantityButton}
        size={SizeVariant.ExtraSmall}
        Icon={enabledTrashIcon ? DeleteIcon : RemoveIcon}
        disabled={
          !showTrashIcon &&
          !isUndefined(minValue) &&
          minValue > Number(quantity)
        }
      />
      <TextInput
        className={classes.input}
        value={quantity?.toString()}
        onChange={value => {
          if (isEmpty(value)) return onChangeQuantity?.(undefined);
          return onChangeQuantity?.(Number(value));
        }}
        type={InputType.Number}
        disableAllOutlines
        showNumberArrows={false}
        fullWidth
        noPadding
        maxValue={maxValue}
        minValue={minValue}
        textAlign={TextAlign.Center}
      />
      <BaseIconButton
        title={increaseQuantityButtonTitle}
        onClick={() => {
          onChangeQuantity?.(isUndefined(quantity) ? 1 : quantity + 1);
        }}
        Icon={Add}
        size={SizeVariant.ExtraSmall}
        className={classes.quantityButton}
        disabled={maxValue < Number(quantity) + 1}
      />
    </div>
  );
};
