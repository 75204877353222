import { ApiRequestResponse } from "request/types";
import {
  OrganizationWorkspaces,
  Workspace
} from "model/resource/app/workspace";
import { HttpMethod, RequestFailureStatus } from "@laba/ts-common";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { produce } from "immer";
import {
  fixFormatOrganization,
  fixParseOrganization
} from "api/entities/organization";

const fixGetWorkspace = (workspace: Workspace): Workspace => {
  return produce(workspace, draft => {
    draft.organization = fixParseOrganization(draft.organization);
  });
};

const fixPostWorkspace = (workspace: Workspace): Workspace => {
  return produce(workspace, draft => {
    if (draft.organization) {
      draft.organization = fixFormatOrganization(draft.organization);
    }
  });
};

export const getWorkspaceList = async (): Promise<
  ApiRequestResponse<OrganizationWorkspaces>
> => {
  const response: ApiRequestResponse<OrganizationWorkspaces> = await request({
    method: HttpMethod.GET,
    url: getFrontApiUrl("/workspace")
  });
  if (response.failureStatus === RequestFailureStatus.Failure) return response;
  return produce(response, draftResponse => {
    draftResponse.data.workspace =
      draftResponse.data.workspace.map(fixGetWorkspace);
  });
};

export const createWorkspace = async (
  data: Workspace
): Promise<ApiRequestResponse<Workspace>> => {
  const response: ApiRequestResponse<Workspace> = await request({
    method: HttpMethod.POST,
    url: getFrontApiUrl("/workspace"),
    data: fixPostWorkspace(data)
  });
  if (response.failureStatus === RequestFailureStatus.Failure) return response;
  return produce(response, draftResponse => {
    draftResponse.data = fixGetWorkspace(draftResponse.data);
  });
};
