import {
  getDefaultJsonToModel,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";
import { Product, ResourceType } from "@laba/nexup-api";
import {
  getDefaultResourceTransform,
  ResourceForm
} from "components/generic/ResourceForm/ResourceForm";
import { tkCP } from "translation/i18n";
import { FC, useI18n } from "@laba/react-common";
import React from "react";
import { ProductMainContent } from "./ProductMainContent";

export const productJsonToModel: JsonToModel<Product> = getDefaultJsonToModel(
  draft => {
    draft.organization = undefined;
  }
);
export const productTransform = getDefaultResourceTransform<Product>(
  ResourceType.Product
);

export const tk = tkCP.adminPage[ResourceType.Product];

export const ProductForm: FC = () => {
  const { t } = useI18n();

  return (
    <ResourceForm
      tabsConfig={[
        {
          label: t(tk.tabs.main),
          content: <ProductMainContent />
        }
      ]}
    />
  );
};
