import React from "react";
import {
  DefaultButton,
  DefaultButtonProps
} from "components/buttons/DefaultButton/DefaultButton";
import { FC, TypeVariant } from "@laba/react-common";

export interface StateDefaultButtonProps extends DefaultButtonProps {
  isActive: boolean;
}

export const StateDefaultButton: FC<StateDefaultButtonProps> = ({
  isActive = false,
  ...rest
}) => {
  return (
    <DefaultButton
      {...rest}
      type={isActive ? TypeVariant.Contained : TypeVariant.Outlined}
    />
  );
};
