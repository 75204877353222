import { generatePath } from "react-router-dom";
import qs, { ParsedQs } from "qs";
import { notUndefined, Optional, sentryLogError } from "@laba/ts-common";
import { isEqual, isUndefined } from "lodash-es";

/* define GetRoutePathParams as param parameter of generatePath internal react-router function to ensure type compatibility */
export type GetRoutePathParams = Parameters<typeof generatePath>[1];

const pathParamsRegex = /(:[a-zA-Z]+)/g;

/* TODO: Dont allow just any string into basePath but a value from an enum. */
export const getRouterDefinitionPath = (
  basePath: string,
  route: string
): string => [basePath, route].join("");

const getRouteWithParamsAndHash = (
  route: string,
  queryParams?: ParsedQs,
  hash?: string
): string => {
  const queryParamsStr = queryParams ? `?${qs.stringify(queryParams)}` : "";

  const hashStr = hash ? `#${hash}` : "";

  return `${route}${queryParamsStr}${hashStr}`;
};

const getPathParams = (path: string): string[] => {
  const pathParams = path.match(pathParamsRegex);
  if (!pathParams) return [];
  return pathParams.map(param => param.replace(":", ""));
};

const paramsExistOnPath = (
  path: string,
  params: GetRoutePathParams
): boolean => {
  const pathParams = getPathParams(path);
  const paramsPropertiesNames = Object.entries(params ?? {})
    .map(([key, value]) => (!isUndefined(value) ? key : undefined))
    .filter(notUndefined);
  return isEqual(paramsPropertiesNames, pathParams);
};

export const getRoutePath = (
  basePath: string,
  routePath: string,
  params?: GetRoutePathParams,
  hash?: string,
  queryParams?: ParsedQs
): Optional<string> => {
  const path = getRouterDefinitionPath(basePath, routePath);
  try {
    if (params && !paramsExistOnPath(path, params)) {
      return undefined;
    }
    return getRouteWithParamsAndHash(
      generatePath(path, params),
      queryParams,
      hash
    );
  } catch (error) {
    sentryLogError({
      error,
      errorContext: { basePath, routePath, params, hash, queryParams, path }
    });
    return undefined;
  }
};
