import { Color, FC, getClassName, TextVariant } from "@laba/react-common";
import React from "react";
import { CircleIcon } from "components/icons";
import { NexupColor } from "model/nexupColor";
import { useCircleWithTextStyles } from "components/CircleWithText/useCircleWithTextStyles";

export interface CircleProps {
  className?: string;
  color?: Color;
  text?: string;
  reverseOrder?: boolean;
  textVariant?: TextVariant;
  title?: string;
  notShrinkText?: boolean;
}

export const CircleWithText: FC<CircleProps> = ({
  color = NexupColor.DarkGray,
  className,
  text,
  reverseOrder = false,
  textVariant = TextVariant.Caption,
  title,
  notShrinkText = false
}) => {
  const classes = useCircleWithTextStyles({
    reverseOrder,
    textVariant,
    notShrinkText
  });
  return (
    <div className={getClassName(className, classes.container)} title={title}>
      <CircleIcon style={{ color }} titleAccess={title} />
      {text && (
        <p className={classes.text} title={title ?? text}>
          {text}
        </p>
      )}
    </div>
  );
};
