import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  PractitionerForm,
  practitionerJsonToModel,
  practitionerTransform
} from "components/pages/NexupAdmin/resources/practitioner/PractitionerForm";
import { Edit } from "components/generic/Edit/Edit";
import { Practitioner, ResourceType } from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { useElementExportedFileName } from "components/hook/useExportedFileName";

const tk = tkCP.adminPage[ResourceType.Practitioner];

export const PractitionerEdit: FC = () => {
  const { t } = useI18n();
  const exportedFileName = useElementExportedFileName<Practitioner>(() =>
    t(tk.title)
  );
  return (
    <Edit
      resourceTransform={practitionerTransform}
      resource={ResourceType.Practitioner}
      jsonToModel={practitionerJsonToModel}
      exportedFileName={exportedFileName}
    >
      <PractitionerForm />
    </Edit>
  );
};
