import { ApiDate, getKeyObj, HttpMethod } from "@laba/ts-common";
import { ModelId } from "model/primitives/model/model";
import {
  MedicalAdministration,
  MedicalAdministrationStatus
} from "model/resource/prescription/medicalRequest/medicalAdministration";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { ApiPageRequestResponse, ApiRequestResponse } from "request/types";
import { createHydratedMock } from "ts-auto-mock";

const baseAdministrationPath = "/medical-administration";

export const updateMedicalAdministration = (
  data: MedicalAdministration,
  id: ModelId // Separate param to enforce its existence
): Promise<ApiRequestResponse<MedicalAdministration>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${baseAdministrationPath}/${id}`),
    data
  });

export interface MedicalAdministrationQueryParams {
  withPatient?: boolean;
  withPractitioner?: boolean;
  withHospitalization?: boolean;
  withMedicalDevice?: boolean;
}

export const MedicalAdministrationFormDataParamsKey = getKeyObj(
  createHydratedMock<MedicalAdministration>()
);

export const getMedicalAdministration = (
  id: ModelId,
  params?: MedicalAdministrationQueryParams
): Promise<ApiRequestResponse<MedicalAdministration>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${baseAdministrationPath}/${id}`),
    params
  });

export const createMedicalAdministration = (
  data: MedicalAdministration
): Promise<ApiRequestResponse<MedicalAdministration>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(`${baseAdministrationPath}`),
    data
  });

interface MedicalAdministrationListQueryParams
  extends MedicalAdministrationQueryParams {
  startDate?: ApiDate;
  endDate?: ApiDate;
  medicalRequest?: ModelId;
  status?: MedicalAdministrationStatus;
  statusReason?: string;
}

export const getMedicalAdministrationList = (
  params?: MedicalAdministrationListQueryParams
): Promise<ApiPageRequestResponse<MedicalAdministration>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${baseAdministrationPath}`),
    params
  });
