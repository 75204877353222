import {
  borderMixin,
  createStyle,
  StyleVariant,
  TextVariant,
  ThemeStyleColorVariant,
  TypeVariant,
  RadiusVariant,
  pxToRem,
  flexRowMixin,
  FlexAlignItems,
  FlexJustifyContent,
  hoverMixin,
  disabledMixin
} from "@laba/react-common";
import {
  getGenericButtonBackgroundColor,
  getTypeVariantGenericButtonHoverBackgroundColor
} from "components/utils/getGenericButtonColors";
import { Theme } from "model/theme";
import {
  getThemeRadius,
  getThemeStyleColor,
  themeTextMixin
} from "model/themeUtils";

interface GridItemStylesProps {
  active: boolean;
}

export const useGridItemStyles = createStyle(
  (theme: Theme) => ({
    root: (props: GridItemStylesProps) => ({
      ...borderMixin({
        radius: { all: getThemeRadius(theme, RadiusVariant.Small) },
        side: { all: !props.active ? 1 : undefined },
        style: "solid",
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.GrayDark
        )
      }),
      ...hoverMixin({
        backgroundColor: props.active
          ? getTypeVariantGenericButtonHoverBackgroundColor(
              theme,
              StyleVariant.Primary,
              TypeVariant.Contained
            )
          : getTypeVariantGenericButtonHoverBackgroundColor(
              theme,
              StyleVariant.Primary,
              TypeVariant.Text
            )
      }),
      ...themeTextMixin(theme, TextVariant.Overline, 1),
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.Center
      }),
      ...disabledMixin({
        backgroundColor: props.active
          ? getThemeStyleColor(
              theme,
              StyleVariant.Primary,
              ThemeStyleColorVariant.GrayLight
            )
          : getThemeStyleColor(
              theme,
              StyleVariant.Primary,
              ThemeStyleColorVariant.Contrast
            ),
        ...borderMixin({
          radius: { all: getThemeRadius(theme, RadiusVariant.Small) },
          side: { all: !props.active ? 1 : undefined },
          style: "solid",
          color: getThemeStyleColor(
            theme,
            StyleVariant.Primary,
            ThemeStyleColorVariant.GrayLight
          )
        }),
        color: props.active
          ? getThemeStyleColor(
              theme,
              StyleVariant.Primary,
              ThemeStyleColorVariant.Contrast
            )
          : getThemeStyleColor(
              theme,
              StyleVariant.Primary,
              ThemeStyleColorVariant.GrayLight
            )
      }),
      backgroundColor: props.active
        ? getGenericButtonBackgroundColor(theme, {
            style: StyleVariant.Primary,
            type: TypeVariant.Contained
          })
        : getGenericButtonBackgroundColor(theme, {
            style: StyleVariant.Primary,
            type: TypeVariant.Text
          }),
      color: props.active
        ? getThemeStyleColor(
            theme,
            StyleVariant.Primary,
            ThemeStyleColorVariant.Contrast
          )
        : getThemeStyleColor(
            theme,
            StyleVariant.Primary,
            ThemeStyleColorVariant.GrayDark
          ),
      width: pxToRem(20),
      height: pxToRem(20)
    })
  }),
  "GridItem"
);
