import { FC } from "@laba/react-common";
import React, { useState } from "react";
import { ProblemConfig } from "components/inputs/ProblemInput/ProblemInput";
import { ProblemItem } from "components/inputs/ProblemInput/ProblemItem/ProblemItem";
import { BaseIconButton } from "components/buttons/BaseIconButton/BaseIconButton";
import { ExpandMore } from "components/icons";
import { useToAddProblemListStyles } from "components/inputs/ProblemInput/ToAddProblemList/useToAddProblemListStyles";

export interface ToAddProblemListProps {
  problems: ProblemConfig[];
  onClickAddProblem?: (problem: ProblemConfig) => void;
  disabledAddProblem: boolean;
  noResultsText?: string;
  showAll?: boolean;
  disabledShowMore?: boolean;
  showMoreProblemsTextGetter?: (count: number) => string;
  visibleProblemCountIncrement?: number;
}

export const ToAddProblemList: FC<ToAddProblemListProps> = ({
  problems,
  onClickAddProblem,
  disabledAddProblem,
  noResultsText,
  showAll,
  showMoreProblemsTextGetter,
  disabledShowMore,
  visibleProblemCountIncrement = 10
}) => {
  const classes = useToAddProblemListStyles();

  const [visibleProblemCount, setVisibleProblemCount] = useState(3);
  const hiddenProblemCount = Math.max(problems.length - visibleProblemCount, 0);
  const visibleProblems = showAll
    ? problems
    : problems.slice(0, visibleProblemCount);

  return (
    <div className={classes.root}>
      {noResultsText && (
        <p className={classes.noResultsText}>{noResultsText}</p>
      )}
      {visibleProblems.map(p => (
        <ProblemItem
          key={p.name}
          text={p.name}
          status={p.status}
          disabledAddProblem={disabledAddProblem}
          onClickAdd={() => onClickAddProblem?.(p)}
        />
      ))}
      {!showAll && hiddenProblemCount > 0 ? (
        <div className={classes.showMoreProblems}>
          <BaseIconButton
            disabled={disabledShowMore}
            onClick={() =>
              setVisibleProblemCount(
                visibleProblemCount + visibleProblemCountIncrement
              )
            }
            Icon={ExpandMore}
          />
          <p className={classes.showMoreProblemsCount}>
            {showMoreProblemsTextGetter?.(hiddenProblemCount)}
          </p>
        </div>
      ) : null}
    </div>
  );
};
