export const isImage = (url: string): boolean =>
  /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);

export const isImgUrl = (url: string): Promise<boolean> => {
  const img = new Image();
  img.src = url;
  return new Promise(resolve => {
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });
};
