import {
  ResourceType,
  getMedicalDeviceList,
  getMedicalDevice,
  updateMedicalDevice,
  createMedicalDevice,
  MedicalDeviceListQueryParams,
  MedicalDeviceListQueryParamsKey,
  getModelReferenceId
} from "@laba/nexup-api";
import {
  getEnumOrderParam,
  getKeyTargetParam,
  resourceProviderGetter
} from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { workspaceOrganizationSelector } from "store/workspace/selectors";
import { store } from "store/store";

export const medicalDeviceProvider = resourceProviderGetter(
  ResourceType.MedicalDevice,
  getEnumOrderParam({}),
  getKeyTargetParam<MedicalDeviceListQueryParams>(
    MedicalDeviceListQueryParamsKey
  ),
  getMedicalDeviceList,
  getMedicalDevice,
  updateMedicalDevice,
  createMedicalDevice,
  undefined,
  () => ({
    organization: getModelReferenceId(
      workspaceOrganizationSelector(store.getState())
    )
  })
);
