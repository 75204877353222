import { createStyle } from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { StyleVariant } from "model/themeVariant";
import { getThemeStyleColor } from "model/themeUtils";

export const useDialogViewerStyles = createStyle(
  (theme: Theme) => ({
    fixedTopLeft: { position: "fixed", left: 0, top: 0 },
    fixedTopRight: { position: "fixed", right: 0, top: 0 },
    icon: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    }
  }),
  "DialogViewer"
);
