import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";
import { FC } from "@laba/react-common";

export const HospitalizationBed: FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11 7H16L23 14V20H21V17H3V20H1V5H3V14H11V7Z" />
      <path d="M9.12132 12.1213C8.55871 12.6839 7.79565 13 7 13C6.20435 13 5.44129 12.6839 4.87868 12.1213C4.31607 11.5587 4 10.7956 4 10C4 9.20435 4.31607 8.44129 4.87868 7.87868C5.44129 7.31607 6.20435 7 7 7C7.79565 7 8.55871 7.31607 9.12132 7.87868C9.68393 8.44129 10 9.20435 10 10C10 10.7956 9.68393 11.5587 9.12132 12.1213Z" />
      <path d="M21.4 6.27778L23 6.27778L20.2 9L17.4 6.27778L19.04 6.27778C19.04 4.33333 18.4 2.97222 17 2C19 2.38889 21 3.55556 21.4 6.27778Z" />
    </SvgIcon>
  );
};
