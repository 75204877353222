import {
  createStyle,
  flexColumnMixin,
  GridAutoFlow,
  gridContainerMixin,
  marginMixin,
  StyleVariant,
  TextVariant
} from "@laba/react-common";
import { Theme } from "model/theme";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

interface CheckboxGridStylesProps {
  gridColumns: number;
  gridGap?: number;
}

interface CheckboxGridStylesClassNames {
  root: string;
  checkboxGridError: string;
  gridContainer: string;
}

export const useCheckboxGridStylesStatic = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexColumnMixin({ gap: 4 })
    },
    checkboxGridError: {
      ...themeTextMixin(theme, TextVariant.Caption),
      ...marginMixin({ top: 4, leftRight: 14 }),
      color: getThemeStyleColor(theme, StyleVariant.Danger)
    }
  }),
  "CheckboxGridStatic"
);

const useCheckboxGridStylesDynamic = createStyle(
  (_theme: Theme) => ({
    gridContainer: (props: CheckboxGridStylesProps) => ({
      ...gridContainerMixin({
        gap: props.gridGap ?? 16,
        templateColumn: [`repeat(${props.gridColumns},min-content)`],
        autoFlow: GridAutoFlow.Row
      })
    })
  }),
  "CheckboxGridDynamic"
);

export const useCheckboxGridStyles = (
  props: CheckboxGridStylesProps
): CheckboxGridStylesClassNames => {
  const staticClasses = useCheckboxGridStylesStatic();
  const dynamicClasses = useCheckboxGridStylesDynamic(props);

  return {
    root: staticClasses.root,
    checkboxGridError: staticClasses.checkboxGridError,
    gridContainer: dynamicClasses.gridContainer
  };
};
