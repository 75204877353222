import { FCC, getClassName, IconC } from "@laba/react-common";
import React from "react";
import { StyleVariant } from "model/themeVariant";
import { IconButton } from "@material-ui/core";
import {
  useLeftIconMuiIconClasses,
  useRightIconMuiIconClasses,
  useVerticalContainerStyle
} from "components/containers/VerticalContainer/useVerticalContainerStyle";
import { Noop } from "@laba/ts-common";

export interface VerticalContainerProps {
  className?: string;
  LeftIconHeader?: IconC;
  onLeftIconClick?: Noop;
  headerTitle?: string;
  RightIconHeader?: IconC;
  onRightIconClick?: Noop;
  style?: StyleVariant;
  rightIconHidden?: boolean;
}

// TODO: check if is going to be part of nexup library and analize ways to make it generic

export const VerticalContainer: FCC<VerticalContainerProps> = ({
  className,
  children,
  headerTitle,
  LeftIconHeader,
  RightIconHeader,
  onRightIconClick,
  onLeftIconClick,
  style = StyleVariant.Primary,
  rightIconHidden
}) => {
  const showLeftIcon = Boolean(LeftIconHeader) && Boolean(onLeftIconClick);
  const showTitle = Boolean(headerTitle);
  const showRightIcon =
    Boolean(RightIconHeader) && Boolean(onRightIconClick) && !rightIconHidden;

  const showHeader = showLeftIcon || showTitle || showRightIcon;

  const classes = useVerticalContainerStyle({
    style,
    showLeftIcon,
    showRightIcon
  });

  const leftIconClasses = useLeftIconMuiIconClasses({ style });
  const rightIconClasses = useRightIconMuiIconClasses({ style });

  return (
    <div className={getClassName(classes.root, className)}>
      {showHeader && (
        <div className={classes.header}>
          {showLeftIcon && LeftIconHeader && (
            <IconButton classes={leftIconClasses} onClick={onRightIconClick}>
              <LeftIconHeader />
            </IconButton>
          )}
          {showTitle && (
            <div className={classes.headerTitle}>{headerTitle}</div>
          )}
          {showRightIcon && RightIconHeader && (
            <IconButton classes={rightIconClasses} onClick={onRightIconClick}>
              <RightIconHeader />
            </IconButton>
          )}
        </div>
      )}
      <div className={classes.mainContent}>{children}</div>
    </div>
  );
};
