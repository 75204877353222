import {
  Color,
  createStyle,
  destyledButtonMixin,
  FlexAlignItems,
  FlexJustifyContent,
  flexRowMixin,
  hoverMixin,
  marginMixin,
  pxToRem
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { StyleVariant, TextVariant } from "model/themeVariant";
import {
  getThemeStyleColor,
  getThemeStyleState,
  themeTextMixin
} from "model/themeUtils";
import { NexupColor } from "model/nexupColor";

export interface VerticalTabStyleProps {
  isActive: boolean;
  disabled: boolean;
  style: StyleVariant;
}

const getTabBackgroundColor = (
  theme: Theme,
  props: VerticalTabStyleProps,
  hover: boolean
): Color => {
  if (props.disabled) {
    return getThemeStyleState(theme, props.style).disabledBackground;
  }
  if (hover && !props.isActive) {
    return getThemeStyleState(theme, props.style).hover;
  }

  return getThemeStyleColor(
    theme,
    props.style,
    ThemeStyleColorVariant.Contrast
  );
};

const getTabColor = (theme: Theme, props: VerticalTabStyleProps): Color => {
  if (props.disabled) {
    return getThemeStyleState(theme, props.style).disabled;
  }

  if (props.isActive) {
    return getThemeStyleColor(theme, props.style, ThemeStyleColorVariant.Main);
  }

  return getThemeStyleColor(
    theme,
    props.style,
    ThemeStyleColorVariant.GrayDark
  );
};

const getVerticalRectangleBackground = (
  theme: Theme,
  props: VerticalTabStyleProps
): Color => {
  if (props.isActive) {
    return getThemeStyleColor(theme, props.style, ThemeStyleColorVariant.Main);
  }
  return NexupColor.Transparent;
};

export const useVerticalTabStyle = createStyle(
  (theme: Theme) => ({
    container: (props: VerticalTabStyleProps) => ({
      ...destyledButtonMixin(),
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.FlexStart
      }),
      ...hoverMixin({
        backgroundColor: getTabBackgroundColor(theme, props, true)
      }),
      cursor: props.isActive || props.disabled ? "default" : "pointer",
      backgroundColor: getTabBackgroundColor(theme, props, false),
      width: "100%",
      height: pxToRem(48),
      overflow: "hidden"
    }),
    verticalRectangle: (props: VerticalTabStyleProps) => ({
      width: pxToRem(8),
      height: "100%",
      backgroundColor: getVerticalRectangleBackground(theme, props)
    }),
    text: (props: VerticalTabStyleProps) => ({
      ...marginMixin({ left: 8 }),
      ...themeTextMixin(
        theme,
        props.isActive ? TextVariant.Subtitle2 : TextVariant.Body2,
        1
      ),
      color: getTabColor(theme, props)
    })
  }),
  "VerticalTab"
);
