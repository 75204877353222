import {
  createStyle,
  FlexAlignItems,
  flexRowMixin,
  marginMixin,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

export const useFileListItemStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center
      }),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    icon: { ...marginMixin({ right: 4 }) },
    filename: { ...themeTextMixin(theme, TextVariant.Subtitle2, 1) }
  }),
  "FileListItem"
);
