import { ApiRequestResponse, ListQueryParams } from "request/types";
import { ModelId } from "model/primitives/model/model";
import { HttpMethod } from "@laba/ts-common";
import { CurrentConferenceEncounter } from "model/resource/entities/encounter/conference/conferenceEncounter";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { ConferenceJoinData } from "model/resource/entities/encounter/conference/conferenceJoinData";
import { EncounterStatus } from "model/resource/entities/encounter/encounter";
import { encounterBasePath } from "api/entities/encounter/encounter";

export enum ConferenceEncounterRequestOrder {
  CreationDate = "creationDate",
  CreationDateDescent = "creationDateDescent"
}

export enum ConferenceStatusUpdateActions {
  PractitionerArrived = "PractitionerArrived",
  EncounterStarted = "EncounterStarted",
  EncounterFinished = "EncounterFinished",
  PatientCancelled = "PatientCancelled",
  PractitionerCancelled = "PractitionerCancelled"
}

export interface ConferenceEncounterQueryParams extends ListQueryParams {
  content?: string;
  healthcareService?: ModelId[];
  order?: ConferenceEncounterRequestOrder;
  organization?: ModelId;
  status?: EncounterStatus;
  withHealthcareService?: boolean;
  withPatientHealthcarePayer?: boolean;
  withPatient?: boolean;
  withAppointment?: boolean;
  withPractitionerTeam?: boolean;
  withOrganization?: boolean;
}

const conferenceEncounterBasePath = `${encounterBasePath}/conference`;

const buildJoinDataUrl = (id: ModelId): string =>
  getFrontApiUrl(`${conferenceEncounterBasePath}/${id}/join`);

export const getCurrentConferenceEncounter = (
  params?: ConferenceEncounterQueryParams
): Promise<ApiRequestResponse<CurrentConferenceEncounter>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${conferenceEncounterBasePath}/current`),
    params
  });

export const joinConferenceEncounter = (
  encounterId: ModelId
): Promise<ApiRequestResponse<ConferenceJoinData>> =>
  request({
    method: HttpMethod.POST,
    url: buildJoinDataUrl(encounterId)
  });
