import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  getModelReferenceId,
  KnownCodeSystemSystem,
  LocationKey,
  ModelReference,
  Organization,
  ResourceType
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { LatLngInput } from "components/generic/LatLngInput/LatLngInput";

export const tk = tkCP.adminPage[ResourceType.Location];
export const LocationDirectoryPositionContent: FC = () => {
  const { t } = useI18n();
  const locationOrganization = useWatchResourceForm<
    ModelReference<Organization>
  >(LocationKey.organization);
  const directoryCitySystem = useCodeSystemGroup(
    KnownCodeSystemSystem.OrganizationDirectoryCity,
    locationOrganization
  );
  const directoryStateSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.OrganizationDirectoryState,
    locationOrganization
  );
  const directoryCountrySystem = useCodeSystemGroup(
    KnownCodeSystemSystem.OrganizationDirectoryCountry,
    locationOrganization
  );
  const organizationId = getModelReferenceId(locationOrganization);
  return (
    <>
      <LatLngInput
        latSrc={LocationKey.latitude}
        lngSrc={LocationKey.longitude}
        label={t(tk.fields.latitudeLongitude)}
      />
      <TextInput
        source={LocationKey.address.street}
        label={t(tk.fields.addressStreet)}
      />
      <TextInput
        source={LocationKey.address.streetNumber}
        label={t(tk.fields.addressStreetNumber)}
      />
      <TextInput
        source={LocationKey.address.directions}
        label={t(tk.fields.addressDirections)}
      />
      <CodeSystemSelectorInput
        source={LocationKey.address.city}
        label={t(tk.fields.addressCity)}
        codeSystem={directoryCitySystem}
        organizationId={organizationId}
        withCreate
      />
      <CodeSystemSelectorInput
        source={LocationKey.address.state}
        label={t(tk.fields.addressState)}
        codeSystem={directoryStateSystem}
        organizationId={organizationId}
        withCreate
      />
      <CodeSystemSelectorInput
        source={LocationKey.address.country}
        label={t(tk.fields.addressCountry)}
        codeSystem={directoryCountrySystem}
        organizationId={organizationId}
        withCreate
      />
      <TextInput
        source={LocationKey.address.postalCode}
        label={t(tk.fields.addressPostalCode)}
      />
    </>
  );
};
