import { HttpCode } from "@laba/ts-common";
import { flatMap, some } from "lodash-es";
import { ErrorIssueType } from "model/resource/error/errorIssueType";
import {
  ErrorMessageErrorIssueParameter,
  FieldErrorIssueParameter
} from "model/resource/error/errorIssueParameter";
import {
  ErrorIssue,
  getErrorMessageParameterList,
  getInvalidFieldParameterList
} from "./errorIssue";
import { ResourceModel, ResourceType } from "../../primitives/resourceModel";

export interface BackendError extends ResourceModel<ResourceType.Error> {
  timestamp?: string;
  status?: HttpCode | number;
  issue: ErrorIssue[];
}

export enum AxiosErrorMessages {
  Timeout = "Request Timeout",
  NetworkError = "Network Error",
  RequestAborted = "Request Aborted"
}

export const filterIssueByType = (
  error: BackendError,
  type: ErrorIssueType
): ErrorIssue[] => error.issue.filter(issue => issue.type === type);

export const getParameterWithErrorMessage = (
  error: BackendError,
  type?: ErrorIssueType
): ErrorMessageErrorIssueParameter[] => {
  const issueList = type ? filterIssueByType(error, type) : error.issue;
  return flatMap(issueList, issue => getErrorMessageParameterList(issue));
};

export const getParameterWithFieldError = (
  error: BackendError,
  type?: ErrorIssueType
): FieldErrorIssueParameter[] => {
  const issueList = type ? filterIssueByType(error, type) : error.issue;
  return flatMap(issueList, issue => getInvalidFieldParameterList(issue));
};

export const hasParameterWithErrorMessage = (
  error: BackendError,
  message: string,
  type?: ErrorIssueType
): boolean => {
  return some(
    getParameterWithErrorMessage(error, type),
    parameter => parameter.valueString === message
  );
};

export const hasParameterWithFieldError = (
  error: BackendError,
  field: string,
  type?: ErrorIssueType
): boolean => {
  return some(
    getParameterWithFieldError(error, type),
    parameter => parameter.valueString === field
  );
};
