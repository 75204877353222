import { tkCP } from "translation/i18n";
import { FC, useI18n } from "@laba/react-common";
import { OptionsConfig, Popup, SelectInput } from "@laba/nexup-components";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { workspaceListSelector } from "store/workspace/selectors";
import { Optional } from "@laba/ts-common";
import { Workspace } from "@laba/nexup-api";
import { head, sortBy } from "lodash-es";
import { useAppDispatch } from "store/store";
import { userHasAdminPermission } from "store/workspace/events";

export interface WorkspaceSelectorPopUpProps {
  isOpen: boolean;
  onWorkspaceSelected: (workspace?: Workspace) => void;
}

const tk = tkCP.loginPage.selectWorkspacePopUp;
export const WorkspaceSelectorPopUp: FC<WorkspaceSelectorPopUpProps> = ({
  isOpen,
  onWorkspaceSelected
}: WorkspaceSelectorPopUpProps) => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const auxFn = async () => {
      const result = await dispatch(userHasAdminPermission());

      setIsAdmin(result);
    };
    auxFn();
  }, [dispatch, isAdmin, setIsAdmin]);

  const workspaceList = useSelector(workspaceListSelector);
  const sortedWorkspaceList = sortBy(
    workspaceList,
    value => value.organization?.name
  );
  const [workspace, setWorkspace] = useState<Optional<Workspace>>(
    head(sortedWorkspaceList)
  );
  const workspaceOptions: OptionsConfig<Optional<Workspace>>[] =
    sortedWorkspaceList.map(value => {
      return {
        value,
        itemId: value.organization?.id ?? "",
        title: value.organization?.name ?? "",
        text: value.organization?.name ?? ""
      };
    });

  return (
    <Popup
      open={isOpen}
      closableHeader
      headerTitle={t(tk.title)}
      primaryButtonText={t(tk.select)}
      secondaryButtonText={isAdmin ? t(tk.selectAll) : undefined}
      disablePrimaryButton={workspace === undefined}
      onPrimaryButtonClick={() => workspace && onWorkspaceSelected(workspace)}
      onSecondaryButtonClick={() => isAdmin && onWorkspaceSelected()}
    >
      <SelectInput<Optional<Workspace>>
        value={workspace}
        onChange={setWorkspace}
        placeholder={t(tk.workspacePlaceholder)}
        options={workspaceOptions}
        fullWidth
      />
    </Popup>
  );
};
