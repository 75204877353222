import {
  CurrentVirtualGuardEncounter,
  CurrentVirtualGuardEncounterQueryParams,
  VirtualGuardEncounter
} from "model/resource/entities/encounter/virtualGuardEncounter";
import { ApiRequestResponse } from "request/types";
import { getFrontPatientPortalApiUrl, request } from "request/nexupRequest";
import { getKeyObj, HttpMethod } from "@laba/ts-common";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { HealthcareService } from "model/resource/entities/healthcareService/healthcareService";
import { ModelId } from "model/primitives/model/model";
import { VirtualGuardJoinData } from "model/resource/virtualGuard/virtualGuardJoinData";
import { createHydratedMock } from "ts-auto-mock";
import { CodeSystemCode } from "model/resource/entities/codeSystem";

export interface CreateVirtualGuardEncounterData {
  consultationReason: CodeSystemCode;
  healthcareService?: ModelReference<HealthcareService>;
}

enum VirtualGuardStatusUpdatePatientActions {
  EncounterStarted = "EncounterStarted",
  EncounterFinished = "EncounterFinished",
  PatientCancelled = "PatientCancelled"
}

export const createVirtualGuardParamsKey = getKeyObj(
  createHydratedMock<CreateVirtualGuardEncounterData>()
);

export const patientVirtualGuardEncounterBasePath = "/virtual-guard-encounter";

export const createPatientVirtualGuardEncounter = (
  data: CreateVirtualGuardEncounterData
): Promise<ApiRequestResponse<VirtualGuardEncounter>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontPatientPortalApiUrl("/create-virtual-guard-encounter"),
    data
  });

const buildJoinDataUrl = (id: ModelId): string =>
  getFrontPatientPortalApiUrl(
    `${patientVirtualGuardEncounterBasePath}/${id}/join`
  );

export const patientJoinVirtualGuardEncounter = (
  encounterId: ModelId
): Promise<ApiRequestResponse<VirtualGuardJoinData>> => {
  return request({
    method: HttpMethod.POST,
    url: buildJoinDataUrl(encounterId)
  });
};

const buildVGEStatusUpdateUrl = (id: ModelId): string =>
  getFrontPatientPortalApiUrl(
    `${patientVirtualGuardEncounterBasePath}/${id}/status-update`
  );

const updatePatientVirtualGuardEncounterStatus = (
  encounterId: ModelId,
  action: VirtualGuardStatusUpdatePatientActions
): Promise<ApiRequestResponse<VirtualGuardEncounter>> =>
  request({
    method: HttpMethod.POST,
    url: buildVGEStatusUpdateUrl(encounterId),
    data: { action }
  });

export const cancelPatientVirtualGuardEncounter = (
  encounterId: ModelId
): Promise<ApiRequestResponse<VirtualGuardEncounter>> =>
  updatePatientVirtualGuardEncounterStatus(
    encounterId,
    VirtualGuardStatusUpdatePatientActions.PatientCancelled
  );

export const startPatientVirtualGuardEncounter = (
  encounterId: ModelId
): Promise<ApiRequestResponse<VirtualGuardEncounter>> =>
  updatePatientVirtualGuardEncounterStatus(
    encounterId,
    VirtualGuardStatusUpdatePatientActions.EncounterStarted
  );

export const finishPatientVirtualGuardEncounter = (
  encounterId: ModelId
): Promise<ApiRequestResponse<VirtualGuardEncounter>> =>
  updatePatientVirtualGuardEncounterStatus(
    encounterId,
    VirtualGuardStatusUpdatePatientActions.EncounterFinished
  );

export const getCurrentPatientVirtualGuardEncounter = async (
  params?: CurrentVirtualGuardEncounterQueryParams
): Promise<ApiRequestResponse<CurrentVirtualGuardEncounter>> => {
  return request({
    method: HttpMethod.GET,
    url: getFrontPatientPortalApiUrl(
      `${patientVirtualGuardEncounterBasePath}/current`
    ),
    params
  });
};
