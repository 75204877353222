import { axiosRequest, Optional, Position } from "@laba/ts-common";
import { NominatimResponse, reverseGeocode } from "nominatim-browser";

export interface PositionResult {
  currentPosition: Optional<Position>;
  error: Optional<ErrorPosition>;
}

export enum ErrorPosition {
  PermissionDenied = "PermissionDenied",
  PositionUnavailable = "PositionUnavailable",
  Timeout = "Timeout"
}

export interface PositionOptions {
  maximumAge?: number;
  timeout?: number;
  enableHighAccuracy?: boolean;
}

export type SuccessCallback = (position: GeolocationPosition) => void;

export type ErrorCallback = (positionError: GeolocationPositionError) => void;

export const errorMapper = (
  geolocationPositionError: GeolocationPositionError
): Optional<ErrorPosition> => {
  switch (geolocationPositionError.code) {
    case GeolocationPositionError.POSITION_UNAVAILABLE:
      return ErrorPosition.PositionUnavailable;
    case GeolocationPositionError.PERMISSION_DENIED:
      return ErrorPosition.PermissionDenied;
    case GeolocationPositionError.TIMEOUT:
      return ErrorPosition.Timeout;
  }
};

export const defaultLocationOptions: PositionOptions = {
  maximumAge: 0,
  timeout: 5 * 1000,
  enableHighAccuracy: true
};

export const getGeoLocation = (): Optional<Geolocation> => {
  // this api may not be available on old browsers
  return navigator.geolocation as Optional<Geolocation>;
};

export const onGetCurrentPosition = (
  onSuccess: SuccessCallback,
  onError?: ErrorCallback,
  options?: PositionOptions
): void => {
  const geoLocation = getGeoLocation();
  if (geoLocation !== undefined) {
    geoLocation.getCurrentPosition(onSuccess, onError, options);
  }
};

export const onWatchPosition = (
  onSuccess: SuccessCallback,
  onError: ErrorCallback,
  options: PositionOptions
): Optional<number> => {
  const geoLocation = getGeoLocation();
  if (geoLocation !== undefined) {
    return geoLocation.watchPosition(onSuccess, onError, options);
  }
};

export const onClearWatchPosition = (watchId?: number): void => {
  const geoLocation = getGeoLocation();
  if (geoLocation !== undefined && watchId !== undefined) {
    geoLocation.clearWatch(watchId);
  }
};

export const getCountryCodeFromCoordinates = async (
  position: Position
): Promise<Optional<string>> => {
  const { latitude, longitude } = position;

  if (!latitude || !longitude) return undefined;

  try {
    const response: NominatimResponse = await reverseGeocode({
      lat: latitude.toString(),
      lon: longitude.toString()
    });
    return response.address.country_code.toUpperCase();
  } catch {
    return undefined;
  }
};

enum IpInfoResponseDataStatus {
  Success = "success",
  Fail = "fail"
}
enum IpInfoResponseDataMessage {
  PrivateRange = "private range",
  ReservedRange = "reserved range",
  InvalidQuery = "invalid query"
}

// returned data types comes from https://ip-api.com/docs/api:json
interface IpInfoResponseData {
  status: IpInfoResponseDataStatus;
  message?: IpInfoResponseDataMessage;
  continent?: string;
  continentCode?: string;
  country: string;
  countryCode: string;
  region: string;
  regionName: string;
  city: string;
  district?: string;
  zip: string;
  lat: number;
  lon: number;
  timezone: string;
  offset?: number;
  currency?: number;
  isp: string;
  org: string;
  as: string;
  asname?: string;
  reverse?: string;
  mobile?: boolean;
  proxy?: boolean;
  hosting?: boolean;
  query: string;
}

const ipApiUrl = "https://pro.ip-api.com/json/";
const getUrlWithKey = (key: string) => {
  return `${ipApiUrl}?key=${key}`;
};

export const getCountryCodeFromIPAddress = async (
  key: string
): Promise<Optional<string>> => {
  try {
    const { data } = await axiosRequest<IpInfoResponseData>({
      url: getUrlWithKey(key)
    });
    return data.countryCode.toUpperCase();
  } catch {
    return undefined;
  }
};
