import React from "react";
import { FC } from "@laba/react-common";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const MedicalServicesIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M20 6H16V4C16 2.9 15.1 2 14 2H10C8.9 2 8 2.9 8 4V6H4C2.9 6 2 6.9 2 8V20C2 21.1 2.9 22 4 22H20C21.1 22 22 21.1 22 20V8C22 6.9 21.1 6 20 6ZM10 4H14V6H10V4ZM16 15H13V18H11V15H8V13H11V10H13V13H16V15Z" />
    </SvgIcon>
  );
};
