import { ModelReference } from "model/primitives/modelReference/modelReference";
import { ApiDate } from "@laba/ts-common";
import { ResourceModel, ResourceType } from "model/primitives/resourceModel";
import { Practitioner } from "model/resource/person/practitioner/practitioner";
import { Encounter } from "model/resource/entities/encounter/encounter";
import { Questionnaire } from "../questionnaire/questionnaire";
import { QuestionnaireFieldValue } from "../questionnaire/questionnaireField";
import { Patient } from "../../person/patient/patient";

export enum QuestionnaireResponseStatus {
  InProgress = "in-progress",
  Completed = "completed"
}

export interface QuestionnaireResponseField {
  linkId: string;
  answer: QuestionnaireFieldValue[];
}

export interface QuestionnaireResponseCard {
  linkId: string;
  fields: QuestionnaireResponseField[];
}

export interface QuestionnaireResponse
  extends ResourceModel<ResourceType.QuestionnaireResponse> {
  status: QuestionnaireResponseStatus;
  encounter?: ModelReference<Encounter>;
  author: ModelReference<Practitioner>;
  authored?: ApiDate;
  creationDate?: ApiDate;
  closeDate?: ApiDate;
  questionnaire?: ModelReference<Questionnaire>;
  title?: string;
  featured?: boolean;
  cards: QuestionnaireResponseCard[];
  subject: ModelReference<Patient>;
  description: string;
  originalPractitioner: ModelReference<Practitioner>;
  effectiveDate?: ApiDate;
}

export interface QuestionnaireResponseIndexes {
  card: number;
  field: number;
  answer: number;
}
