import preset from "jss-preset-default";
import { create, Jss, SheetsRegistry as _SheetsRegistry } from "jss";

const jssInsertionPointId = "jss-insertion";
let jss: Jss | null;
export const initJss = (): void => {
  if (jss == null) {
    let jssInsertionPoint;
    if (typeof document !== "undefined") {
      const root = document.getElementById("root");
      jssInsertionPoint = document.getElementById(jssInsertionPointId);
      if (jssInsertionPoint == null) {
        jssInsertionPoint = document.createElement("div");
        jssInsertionPoint.setAttribute("id", jssInsertionPointId);
        root?.parentNode?.insertBefore(jssInsertionPoint, root);
      }
    }
    jss = create({
      ...preset(),
      insertionPoint: jssInsertionPoint || jssInsertionPointId
    });
  }
};
export const getJss = (): Jss => {
  initJss();
  return jss as Jss;
};

export type { Jss };

export class SheetsRegistry extends _SheetsRegistry {}
