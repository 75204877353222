import {
  getClassName,
  IconC,
  ReactElement,
  useEffectSelective
} from "@laba/react-common";
import React, { useRef, useState, useCallback, useEffect } from "react";
import { HorizontalTab } from "components/tabs/HorizontalTab/HorizontalTab";
import { useContainerTabStyle } from "components/tabs/HorizontalTabContainer/HorizontalTabContainerStyle";
import {
  ScrollableContainer,
  ScrollDirection
} from "components/containers/ScrollableContainer/ScrollableContainer";
import { toNumber, toString, size, debounce } from "lodash-es";
import { NavigateBefore, NavigateNext } from "components/icons";
import { ArrowTabButton } from "components/tabs/HorizontalTabContainer/ArrowTabButton/ArrowTabButton";

export enum TabsScrollLevel {
  SINGLE = 1,
  DOUBLE = 2,
  TRIPLE = 3
}

export interface TabConfig<V> {
  tabId: V;
  title?: string;
  text?: string;
  Icon?: IconC | string;
  disabled?: boolean;
  classname?: string;
}

export interface HorizontalTabContainerProps<V> {
  isMobile?: boolean;
  className?: string;
  tabs?: TabConfig<V>[];
  currentTabId?: V;
  onChangeCurrentTab?: (tabId: V) => void;
  tabsScrollLevel?: TabsScrollLevel;
}

interface ArrowDisplay {
  show: boolean;
  scrollTimer?: number;
}

export const HorizontalTabContainer = <V,>({
  isMobile,
  className,
  tabs = [],
  onChangeCurrentTab,
  currentTabId,
  tabsScrollLevel = TabsScrollLevel.SINGLE
}: HorizontalTabContainerProps<V>): ReactElement => {
  const classes = useContainerTabStyle();
  const containerTabsRef = useRef<HTMLElement | null>(null);
  const tabsRef = useRef<Record<string, HTMLButtonElement | null>>({});

  const [tabsRefSetted, setTabsRefSetted] = useState(false);

  const [arrowsDisplay, setArrowsDisplay] = useState<{
    start: ArrowDisplay;
    end: ArrowDisplay;
  }>({
    start: { show: false },
    end: { show: false }
  });

  const tabsScrollAmount = tabsScrollLevel * 100;

  const updateNavBtnsState = useCallback(() => {
    if (containerTabsRef.current == null) return;
    const { scrollWidth, clientWidth, scrollLeft } = containerTabsRef.current;

    const isScrollable = scrollWidth > clientWidth;

    const showStartScroll =
      isScrollable &&
      !isMobile &&
      Math.floor(toNumber(scrollLeft.toFixed(2))) > 1;

    const showEndScroll =
      isScrollable &&
      !isMobile &&
      Math.ceil(toNumber(scrollLeft.toFixed(2))) <
        scrollWidth - clientWidth - 1;

    if (
      showStartScroll !== arrowsDisplay.start.show ||
      showEndScroll !== arrowsDisplay.end.show
    ) {
      setArrowsDisplay(oldValue => ({
        start: { ...oldValue.start, show: showStartScroll },
        end: { ...oldValue.end, show: showEndScroll }
      }));
    }
  }, [arrowsDisplay.start, arrowsDisplay.end, isMobile]);

  const onRightBtnClick = () => {
    const tabsContainer = containerTabsRef.current;
    if (tabsContainer == null) return;

    const { scrollLeft } = tabsContainer;
    tabsContainer.scroll({
      left: scrollLeft + tabsScrollAmount,
      behavior: "smooth"
    });
  };

  const startRightBtnClick = () => {
    const timerId = window.setInterval(onRightBtnClick, 200);
    setArrowsDisplay(oldValue => ({
      start: { ...oldValue.start },
      end: { ...oldValue.end, scrollTimer: timerId }
    }));
  };

  const cleanRightBtnClick = () => {
    window.clearInterval(arrowsDisplay.end.scrollTimer);
  };

  const onLeftBtnClick = () => {
    const tabsContainer = containerTabsRef.current;
    if (tabsContainer == null) return;
    const { scrollLeft } = tabsContainer;

    tabsContainer.scroll({
      left: scrollLeft - tabsScrollAmount,
      behavior: "smooth"
    });
  };

  const startLeftBtnClick = () => {
    const timerId = window.setInterval(onLeftBtnClick, 200);
    setArrowsDisplay(oldValue => ({
      start: { ...oldValue.start, scrollTimer: timerId },
      end: { ...oldValue.end }
    }));
  };

  const cleanLeftBtnClick = () => {
    window.clearInterval(arrowsDisplay.start.scrollTimer);
  };

  useEffect(() => {
    updateNavBtnsState();
  }, [updateNavBtnsState]);

  useEffectSelective(
    () => {
      const currentTabRef = tabsRef.current[toString(currentTabId)];
      if (
        tabsRefSetted &&
        currentTabRef != null &&
        containerTabsRef.current != null
      ) {
        const offsetCurrentTab = currentTabRef.offsetLeft;
        const visibleElementOffset =
          offsetCurrentTab + currentTabRef.offsetWidth;

        if (visibleElementOffset > containerTabsRef.current.offsetWidth) {
          containerTabsRef.current.scroll({
            left: offsetCurrentTab,
            behavior: "smooth"
          });
        }

        setTabsRefSetted(false);
      }
    },
    [tabsRefSetted],
    [currentTabId]
  );

  useEffect(() => {
    // TODO HIS-11069 : esto no está funcionando correctamente, es por que updateNavBtnsState no está actualizado

    const handleResize = debounce(() => {
      updateNavBtnsState();
    }, 160);

    window.addEventListener("resize", handleResize);

    return () => {
      handleResize.cancel();
      window.removeEventListener("resize", handleResize);
    };
  }, [updateNavBtnsState]);

  const handleTabsContainerRef = useCallback((node: HTMLDivElement | null) => {
    containerTabsRef.current = node;
  }, []);

  useEffect(() => {
    if (!arrowsDisplay.start.show) {
      window.clearInterval(arrowsDisplay.start.scrollTimer);
    }
    if (!arrowsDisplay.end.show) {
      window.clearInterval(arrowsDisplay.end.scrollTimer);
    }
  }, [
    arrowsDisplay.end.scrollTimer,
    arrowsDisplay.end.show,
    arrowsDisplay.start.scrollTimer,
    arrowsDisplay.start.show
  ]);

  return (
    <div className={getClassName(classes.root, className)}>
      {arrowsDisplay.start.show && (
        <div className={classes.leftScrollContainer}>
          <ArrowTabButton
            className={classes.leftScrollButton}
            Icon={NavigateBefore}
            onClick={onLeftBtnClick}
            onMouseDown={startLeftBtnClick}
            onMouseUp={cleanLeftBtnClick}
            disabled={!arrowsDisplay.start}
          />
          <div className={classes.gapElement} />
        </div>
      )}
      <ScrollableContainer
        className={classes.container}
        showScrollBar={false}
        scrollDirection={ScrollDirection.Horizontal}
        withoutScrollPadding
        refContainer={handleTabsContainerRef}
        onScroll={updateNavBtnsState}
      >
        {tabs.map((t, i) => {
          const tabIdString = toString(t.tabId);
          const isActive = t.tabId === currentTabId;
          const showText = isMobile ? isActive : true;
          return (
            <HorizontalTab
              isMobile={isMobile}
              key={toString(t.tabId)}
              className={t.classname}
              title={t.title}
              text={t.text}
              onSelect={() => {
                onChangeCurrentTab &&
                  !t.disabled &&
                  onChangeCurrentTab(t.tabId);

                // TODO: HIS-11295 see if this line can be preserved
                // tabsRef.current[tabIdString]?.scrollIntoView({
                //   behavior: "smooth"
                // });
              }}
              Icon={t.Icon}
              isActive={isActive}
              showText={showText}
              disabled={t.disabled}
              tabRef={ref => {
                if (tabsRef.current[tabIdString] == null) {
                  tabsRef.current[tabIdString] = ref;
                  if (size(tabs) - 1 === i && !tabsRefSetted) {
                    setTabsRefSetted(true);
                  }
                }
              }}
            />
          );
        })}
      </ScrollableContainer>
      {arrowsDisplay.end.show && (
        <div className={classes.rightScrollContainer}>
          <div className={classes.gapElement} />
          <ArrowTabButton
            className={classes.rightScrollButton}
            Icon={NavigateNext}
            disabled={!arrowsDisplay.end}
            onMouseDown={startRightBtnClick}
            onMouseUp={cleanRightBtnClick}
            onClick={onRightBtnClick}
          />
        </div>
      )}
    </div>
  );
};
