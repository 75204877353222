import { Property } from "csstype";
import {
  BoxSidePropertyConfig,
  BoxSidePropertyKey,
  getBoxPropertyMixinBuilder
} from "model/style/box/boxSide";
import {
  BoxCornerElementPropertyConfig,
  BoxCornerPropertyConfig,
  BoxCornerPropertyKey,
  getBoxCornerPropertyMixinBuilder
} from "model/style/box/boxCorner";

const borderRadiusMixin = getBoxCornerPropertyMixinBuilder({
  [BoxCornerPropertyKey.TopLeft]: "border-top-left-radius",
  [BoxCornerPropertyKey.TopRight]: "border-top-right-radius",
  [BoxCornerPropertyKey.BottomLeft]: "border-bottom-left-radius",
  [BoxCornerPropertyKey.BottomRight]: "border-bottom-right-radius"
});

const borderSideMixin = getBoxPropertyMixinBuilder({
  [BoxSidePropertyKey.Top]: "border-top-width",
  [BoxSidePropertyKey.Right]: "border-right-width",
  [BoxSidePropertyKey.Left]: "border-left-width",
  [BoxSidePropertyKey.Bottom]: "border-bottom-width"
});

export interface BorderMixinConfig extends BoxSidePropertyConfig {
  color?: Property.Color;
  style?: Property.BorderStyle;
  side?: BoxSidePropertyConfig;
  radius?: BoxCornerPropertyConfig;
  collapse?: Property.BorderCollapse;
  spacing?: Property.BorderSpacing;
}
type BorderMixinReturnType = ReturnType<typeof borderRadiusMixin> &
  ReturnType<typeof borderSideMixin> & {
    "border-color"?: Property.Color;
    "border-style"?: Property.BorderStyle;
    "box-sizing"?: Property.BoxSizing;
    "border-collapse"?: Property.BorderCollapse;
    "border-spacing"?: string;
  };
export const borderMixin = (
  config: BorderMixinConfig
): BorderMixinReturnType => {
  return {
    ...borderRadiusMixin(config.radius ?? {}),
    ...borderSideMixin(config.side ?? {}),
    "border-color": config.color,
    "border-style": config.style,
    "box-sizing": "border-box",
    "border-collapse": config.collapse,
    "border-spacing": String(config.spacing)
  };
};

export const borderMixinRadiusAll = (
  radius: BoxCornerElementPropertyConfig
): BorderMixinReturnType =>
  borderMixin({
    radius: { all: radius }
  });

export const borderMixinCircular = (): BorderMixinReturnType =>
  borderMixin({
    radius: { all: "50%" }
  });
