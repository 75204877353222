import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { ModelId } from "model/primitives/model/model";
import {
  MedicalDevice,
  MedicalDeviceStatus
} from "model/resource/prescription/medicalDevice";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { getKeyObj, HttpMethod, KeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";

export interface MedicalDeviceListQueryParams extends ListQueryParams {
  organization?: ModelId;
  content?: string;
  status?: MedicalDeviceStatus;
  tags?: string[];
  type?: string;
}

export const MedicalDeviceListQueryParamsKey: KeyObj<MedicalDeviceListQueryParams> =
  getKeyObj(createHydratedMock<MedicalDeviceListQueryParams>());

const medicalDeviceBasePath = "/medical-device";

export const getMedicalDeviceList = (
  params: MedicalDeviceListQueryParams
): Promise<ApiPageRequestResponse<MedicalDevice>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(medicalDeviceBasePath),
    params
  });

export const getMedicalDevice = (
  id: ModelId
): Promise<ApiRequestResponse<MedicalDevice>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${medicalDeviceBasePath}/${id}`)
  });

export const createMedicalDevice = (
  data: MedicalDevice
): Promise<ApiRequestResponse<MedicalDevice>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(medicalDeviceBasePath),
    data
  });

export const updateMedicalDevice = (
  data: MedicalDevice
): Promise<ApiRequestResponse<MedicalDevice>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${medicalDeviceBasePath}/${data.id}`),
    data
  });
