import { ModelReference } from "model/primitives/modelReference/modelReference";
import { Attachment } from "model/primitives/attachment/attachment";
import { ResourceModel, ResourceType } from "model/primitives/resourceModel";
import { AvailableTime } from "model/resource/utils/availableTime";
import { getKeyObj, KeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { Organization } from "../organization/organization";
import { Group } from "../group";
import { CodeSystemSystem } from "../codeSystem";
import { LocationContainer } from "../location/hospitalizationLocation";

export enum HealthcareServiceType {
  Nursing = "Nursing",
  VirtualGuard = "VirtualGuard"
}

export type ServiceCategory = string;

export enum TemplateCode {
  PatientArrived = "PatientArrived",
  PractitionerTriage = "PractitionerTriage",
  EncounterTriageCancelled = "EncounterTriageCancelled",
  EncounterCancelled = "EncounterCancelled"
}

export interface Template {
  code?: TemplateCode;
  template?: string;
}

export const HealthcareServiceTemplateKey: KeyObj<Template> = getKeyObj(
  createHydratedMock<Template>()
);

export interface HealthcareService
  extends ResourceModel<ResourceType.HealthcareService> {
  name?: string;
  description?: string;
  category?: ServiceCategory;
  type?: HealthcareServiceType;
  photo?: Attachment;
  organization: ModelReference<Organization>;
  location: LocationContainer[];
  availableTime?: AvailableTime;
  organizationDefault?: boolean;
  group?: ModelReference<Group>;
  consultationReasonSystem?: CodeSystemSystem;
  feedbackUrl?: string;
  templateList: Template[];
  active?: boolean;
}

export const HealthcareServiceKey: KeyObj<HealthcareService> = getKeyObj(
  createHydratedMock<HealthcareService>()
);
