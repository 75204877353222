import { TextVariant, StyleVariant } from "model/themeVariant";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import {
  createStyle,
  FlexAlignItems,
  flexRowMixin,
  marginMixin,
  pxToRem
} from "@laba/react-common";
import { themeTextMixin, getThemeStyleColor } from "model/themeUtils";

export const useSuggestedItemStyle = createStyle(
  (theme: Theme) => ({
    container: {
      ...flexRowMixin({ alignItems: FlexAlignItems.Center, gap: 10 })
    },
    button: {
      height: pxToRem(24),
      width: pxToRem(24)
    },
    text: {
      ...marginMixin({ all: 0 }),
      ...themeTextMixin(theme, TextVariant.Body2, 1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "SuggestedItem"
);
