import {
  createStyle,
  FlexAlignItems,
  flexItemMixin,
  FlexJustifyContent,
  flexRowMixin,
  paddingMixin,
  StyleVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor } from "model/themeUtils";

export const usePatientDetailMobilePopupHeaderStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.SpaceBetween
      }),
      ...paddingMixin({ left: 16, right: 8, topBottom: 12 }),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    },
    patientIdentificationDataContainer: {
      ...flexItemMixin({ grow: 1, shrink: 1, basis: "0%" })
    },
    buttonsContainer: {
      ...flexRowMixin({
        gap: 8
      })
    }
  }),
  "PatientDetailMobilePopupHeader"
);
