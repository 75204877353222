import { FC, getClassName } from "@laba/react-common";
import React from "react";
import { useDetailIndicatorBoxStyle } from "./DetailIndicatorBoxStyle";

export interface DetailIndicatorBoxProps {
  className?: string;
  content: string;
}

export const DetailIndicatorBox: FC<DetailIndicatorBoxProps> = ({
  className,
  content
}) => {
  const classes = useDetailIndicatorBoxStyle();
  return (
    <div className={getClassName(classes.root, className)}>
      <p className={classes.content}>{content}</p>
    </div>
  );
};
