import React, { useState } from "react";
import { ReactElement, useI18n } from "@laba/react-common";
import { ModelId } from "@laba/ts-common";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { onUploadFileRequest } from "store/organization/event";
import { useAppDispatch } from "store/store";
import { UploadFileButton } from "components/generic/UploadFileButton/UploadFileButton";
import { SpinnerDialog, UploadArrow, Video } from "@laba/nexup-components";
import { useFormContext } from "react-hook-form";
import { Grid } from "@mui/material";
import { TextInput } from "components/generic/TextInput/TextInput";
import { useUploadFileImageInputStyles } from "components/generic/UploadFileImageInput/useUploadFileImageInputStyles";
import { tkCC } from "translation/i18n";

export interface UploadFileImageInputProps {
  source: string;
  label?: string;
  uploadLabel?: string;
  required?: boolean;
  isVideo?: boolean;
  organization?: ModelId;
}

const tk = tkCC.inputs.uploadFileImageInput;

export const UploadFileImageInput = ({
  source,
  label,
  required,
  uploadLabel,
  isVideo,
  organization
}: UploadFileImageInputProps): ReactElement => {
  const { t } = useI18n();
  const classes = useUploadFileImageInputStyles();
  const [loading, setLoading] = useState(false);
  const { setValue } = useFormContext();
  const imgUrl = useWatchResourceForm<string>(source);
  const dispatch = useAppDispatch();

  return (
    <Grid container columnSpacing={2}>
      <Grid item sm={imgUrl ? 8 : 12} alignSelf="center">
        <div className={classes.inputContainer}>
          <TextInput source={source} label={label} required={required} />
          <UploadFileButton
            fileType=""
            label={uploadLabel ?? t(tk.uploadLabel)}
            onFileAdded={async e => {
              setLoading(true);
              const { files } = e.target;
              const result = await dispatch(
                onUploadFileRequest(files ?? undefined, {
                  organization
                })
              );
              result && setValue(source, result.url);
              setLoading(false);
            }}
            Icon={UploadArrow}
          />
        </div>
      </Grid>
      {imgUrl && (
        <Grid item sm={4}>
          {isVideo ? (
            <Video src={imgUrl} className={classes.video} controls={false} />
          ) : (
            <img src={imgUrl} className={classes.image} alt={imgUrl} />
          )}
        </Grid>
      )}
      <SpinnerDialog open={loading} />
    </Grid>
  );
};
