import { ApiRequestResponse } from "request/types";
import { getFrontPatientPortalApiUrl, request } from "request/nexupRequest";
import { HttpMethod } from "@laba/ts-common";
import { ModelId } from "model/primitives/model/model";
import { PatientContainerWithExtraData } from "model/resource/person/patient/patientContainerWithExtraData";
import { Patient } from "model/resource/person/patient/patient";
import { patientBasePath } from "./patient";

export interface CurrentPatientQueryParams {
  organization?: ModelId;
  withUser?: boolean;
  withHealthCarePayerProvider?: boolean;
  withManagedGroup?: boolean;
}
export const getCurrentPatient = async (
  params?: CurrentPatientQueryParams
): Promise<ApiRequestResponse<PatientContainerWithExtraData>> => {
  return request({
    method: HttpMethod.GET,
    url: getFrontPatientPortalApiUrl(`${patientBasePath}/current`),
    params
  });
};

export const editPatientProfile = (
  data: Patient
): Promise<ApiRequestResponse<Patient>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontPatientPortalApiUrl("patient/profile"),
    data
  });
