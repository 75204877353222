import {
  ApiPageRequestResponseBase,
  DefaultError,
  Optional,
  RequestFailureResponse,
  RequestFailureStatus,
  RequestResponse
} from "@laba/ts-common";
import { BackendError } from "model/resource/error/error";

export type { ListQueryParams } from "@laba/ts-common";

export type GetAccessToken = () => Optional<string>;
export type GetApiRootUrl = () => string;
export type GetAppBaseUrl = () => string;
export type TryRefreshToken = () => Promise<boolean>;

export interface NexupRequestConfig {
  getAccessToken: GetAccessToken;
  getApiRootUrl: GetApiRootUrl;
  tryRefreshToken: TryRefreshToken;
  getAppBaseUrl?: GetAppBaseUrl;
  timeout?: number;
  longTimeout?: number;
  getApiProvisionalRootUrl?: GetApiRootUrl;
  version?: string;
  deviceType?: string;
}

export type ApiRequestResponse<R> = RequestResponse<R, BackendError>;

export type ApiRequestFileResponse = ApiRequestResponse<Blob>;

export type ApiPageRequestResponse<R> = ApiPageRequestResponseBase<
  R,
  BackendError
>;

export type ApiErrorResponse = RequestFailureResponse<BackendError>;

export const createSuccessMockApiRequestResponsePromise = <T>(
  data: T
): Promise<ApiRequestResponse<T>> => {
  return Promise.resolve({
    failureStatus: RequestFailureStatus.Success,
    data,
    status: 200
  });
};

export const createSuccessMockApiPageRequestResponsePromise = <T>(
  data: T[]
): Promise<ApiPageRequestResponse<T>> => {
  return Promise.resolve({
    failureStatus: RequestFailureStatus.Success,
    data: {
      entries: data,
      page: {
        page: 1,
        pageEntries: 2,
        pageSize: 20,
        totalEntries: 2
      }
    },
    status: 200
  });
};

export const createFailureMockApiRequestResponsePromise = <E = DefaultError>(
  status: number,
  message: string
): Promise<ApiRequestResponse<E>> => {
  return Promise.resolve({
    failureStatus: RequestFailureStatus.Failure,
    status,
    errorMsg: message
  });
};
