import { FCC } from "components/types";
import React from "react";

interface Props {
  id?: string;
  fullWidthAndHeight?: boolean;
}

export const VideoPlayerContainer: FCC<Props> = ({
  children,
  id,
  fullWidthAndHeight
}) => {
  return (
    <video-player-container
      id={id}
      style={{
        width: fullWidthAndHeight ? "100%" : undefined,
        height: fullWidthAndHeight ? "100%" : undefined,
        position: "absolute",
        pointerEvents: "none"
      }}
    >
      {children}
    </video-player-container>
  );
};
