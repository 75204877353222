export const product = {
  title: "Productos",
  filters: {
    organization: "Organizacion",
    status: "Estado",
    category: "Categoria",
    subcategory: "Sub categoria",
    tags: "Tag"
  },
  fields: {
    id: "Id",
    organization: "Organizacion",
    title: "Nombre",
    status: "Estado",
    category: "Categoria",
    subcategory: "Sub categoria",
    tags: "Tags",
    tagElement: "Tag"
  },
  tabs: {
    main: "Producto"
  }
};
