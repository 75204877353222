import React from "react";
import {
  DateField as ReactAdminDateField,
  DateFieldProps as ReactAdminDateFieldProps
} from "react-admin";
import { ReactElement } from "@laba/react-common";

export type DateFieldProps = ReactAdminDateFieldProps;

export const DateField = (props: DateFieldProps): ReactElement => {
  return <ReactAdminDateField {...props} />;
};

// this is a bad practice but needed for react-admin datagrid to don't show sort arrow by default.
DateField.defaultProps = {
  sortable: false
};
