import { FC, TypeVariant } from "@laba/react-common";
import {
  InputType,
  TextInput,
  TextInputTypeVariant
} from "components/inputs/TextInput/TextInput";
import React, { useCallback, useEffect, useState } from "react";
import { Noop, Optional } from "@laba/ts-common";
import { endsWith, isEmpty, startsWith } from "lodash-es";
import {
  numberValueToString,
  sanitizeStringNumberWithDecimals,
  stringNumberToNumber
} from "utils";

export interface PercentageInputProps {
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  value?: number;
  onChange?: (value?: number) => void;
  onBlur?: Noop;
  errorText?: string;
  helperText?: string;
  fullWidth?: boolean;
  variant?: TextInputTypeVariant;
  decimalDigits?: number;
}

const percentageRegex = /^-?(?:[0-9][0-9.,]{0,15})?%?$/;

const formatPercentage = (str?: string): Optional<string> => {
  return str ? `${str}%` : undefined;
};
const valueToPercentage = (value?: number): Optional<string> => {
  if (!value) return undefined;
  const stringValue = numberValueToString(value);
  return formatPercentage(stringValue);
};

const percentageToValue = (percentage?: string): Optional<number> => {
  if (!percentage) return undefined;
  const value = percentage.replace("%", "");
  if (isEmpty(value)) return undefined;
  return stringNumberToNumber(value);
};

const detectLastCharDeleted = (newStr: string, oldStr: string): boolean =>
  startsWith(oldStr, newStr) &&
  newStr.length === oldStr.length - 1 &&
  endsWith(oldStr, "%");

export const PercentageInput: FC<PercentageInputProps> = ({
  className,
  value,
  onChange,
  disabled,
  placeholder,
  onBlur,
  errorText,
  helperText,
  fullWidth,
  variant = TypeVariant.Outlined,
  decimalDigits = 2
}) => {
  const [percentage, setPercentage] = useState(valueToPercentage(value));
  useEffect(() => {
    const numberValue = percentageToValue(percentage);
    if (numberValue !== value) {
      const percentageString = valueToPercentage(value);
      setPercentage(percentageString);
    }
  }, [value, percentage]);

  const onChangePercentage = useCallback(
    (percentageString?: string) => {
      if (!percentageString) {
        onChange?.(undefined);
        return;
      }

      // If the last character is being erased, ignore de '%' and delete the last number
      const percentageDeleted = percentage
        ? detectLastCharDeleted(percentageString, percentage)
        : false;
      const percentageWithoutSymbol = percentageString.replace("%", "");
      const sanitized = percentageDeleted
        ? percentageWithoutSymbol.slice(0, -1)
        : percentageWithoutSymbol;

      // Test if its a valid percentage string
      if (!percentageRegex.test(sanitized)) return;
      const fixedStringNumber = sanitizeStringNumberWithDecimals(
        sanitized,
        decimalDigits
      );
      const newValue = percentageToValue(fixedStringNumber);

      if (newValue === undefined) {
        onChange?.(undefined);
        setPercentage(undefined);
        return;
      }
      // Detect if the number is outside the limits
      if (newValue < 0 || newValue > 100) return;

      // Update values
      onChange?.(newValue);
      setPercentage(formatPercentage(fixedStringNumber));
    },
    [onChange, percentage, setPercentage, decimalDigits]
  );

  return (
    <TextInput
      multiline={false}
      className={className}
      value={percentage}
      onChange={onChangePercentage}
      placeholder={placeholder}
      errorText={errorText}
      helperText={helperText}
      onBlur={onBlur}
      type={InputType.Text}
      fullWidth={fullWidth}
      variant={variant}
      disabled={disabled}
    />
  );
};
