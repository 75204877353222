import { ReactElement, useI18n } from "@laba/react-common";
import React from "react";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { tkCC } from "translation/i18n";
import { EnumSelectorInput } from "../EnumSelectorInput/EnumSelectorInput";

const tk = tkCC.inputs.flowConfigInput;

interface FlowConfigInputProps<T> {
  source: string;
  enumOptions: T;
}

export const FlowConfigInput = <T extends Record<string, string>>({
  source,
  enumOptions
}: FlowConfigInputProps<T>): ReactElement => {
  const { t } = useI18n();
  return (
    <ArrayInput source={source} label={t(tk.flowConfigSelect)}>
      <EnumSelectorInput
        source=""
        label={t(tk.flowConfigElement)}
        enumValue={enumOptions}
        withCreate
      />
    </ArrayInput>
  );
};
