import { getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { QuestionnaireResponseAnswer } from "model/resource";

export enum QuestionnaireEnableWhenBehavior {
  All = "all",
  Any = "any"
}

export enum QuestionnaireEnableWhenOperator {
  Exists = "exists",
  Equals = "=",
  NotEquals = "!=",
  GreaterThan = ">",
  LessThan = "<",
  GreaterOrEquals = ">=",
  LessOrEquals = "<="
}

export interface QuestionnaireItemEnableWhen {
  linkId: string;
  operator: QuestionnaireEnableWhenOperator;
  answer: QuestionnaireResponseAnswer;
}

export const QuestionnaireItemEnableWhenValueKey =
  getKeyObj<QuestionnaireItemEnableWhen>(
    createHydratedMock<QuestionnaireItemEnableWhen>()
  );
