import React from "react";
import { FC } from "@laba/react-common";
import { Edit } from "components/generic/Edit/Edit";
import { ProcedureReportDefinition, ResourceType } from "@laba/nexup-api";
import {
  ProcedureReportDefinitionForm,
  procedureReportDefinitionJsonToModel,
  procedureReportDefinitionTransform
} from "components/pages/NexupAdmin/resources/procedureReportDefinition/ProcedureReportDefinitionForm";
import { useElementExportedFileName } from "components/hook/useExportedFileName";

export const ProcedureReportDefinitionEdit: FC = () => {
  const exportedFileName =
    useElementExportedFileName<ProcedureReportDefinition>(
      resource => resource.name
    );
  return (
    <Edit
      resourceTransform={procedureReportDefinitionTransform}
      resource={ResourceType.ProcedureReportDefinition}
      jsonToModel={procedureReportDefinitionJsonToModel}
      exportedFileName={exportedFileName}
    >
      <ProcedureReportDefinitionForm />
    </Edit>
  );
};
