import { FC, IconC, RadiusVariant } from "@laba/react-common";
import { StyleVariant } from "model/themeVariant";
import { useMuiTheme } from "model/useMuiTheme";
import { ThemeProvider } from "@material-ui/core/styles";
import { Fab } from "@material-ui/core";
import React from "react";
import { useFloatingActionButtonStyle } from "components/buttons/FAB/FloatingActionButton/FloatingActionButtonStyle";
import { TypeInvertedVariant } from "components/utils/getGenericButtonColors";
import { ButtonType } from "components/buttons/DefaultButton/DefaultButton";
import { Noop } from "@laba/ts-common";

export enum FabSizeVariant {
  Small = "small",
  Medium = "medium",
  Large = "large"
}

export interface FloatingActionButtonProps {
  className?: string;
  disabled?: boolean;
  title?: string;
  Icon: IconC;
  text: string;
  onClick?: Noop;
  style?: StyleVariant;
  variantType?: TypeInvertedVariant;
  type?: ButtonType;
  stackingOrder?: number;
  fixedStyleDisabled?: boolean;
  size?: FabSizeVariant;
  radiusVariant?: RadiusVariant;
}

export const FloatingActionButton: FC<FloatingActionButtonProps> = ({
  className,
  title,
  Icon,
  text,
  onClick,
  type,
  stackingOrder = 0,
  style = StyleVariant.Primary,
  variantType = TypeInvertedVariant.Normal,
  disabled = false,
  fixedStyleDisabled = false,
  size = FabSizeVariant.Large,
  radiusVariant
}) => {
  const muiTheme = useMuiTheme(style);
  const { icon, ...classes } = useFloatingActionButtonStyle({
    style,
    type: variantType,
    stackingOrder,
    disabled,
    fixedStyleDisabled,
    radiusVariant
  });
  return (
    <ThemeProvider theme={muiTheme}>
      <Fab
        classes={classes}
        className={className}
        title={title}
        color="primary"
        disabled={disabled}
        variant="extended"
        onClick={onClick}
        type={type}
        size={size}
      >
        <Icon className={icon} />
        {text}
      </Fab>
    </ThemeProvider>
  );
};
