import React from "react";
import {
  ReactElement,
  useGetModelReference,
  withMemo
} from "@laba/react-common";
import { Optional } from "@laba/ts-common";

import {
  ModelAutocomplete,
  ModelAutocompleteProps
} from "../ModelAutocomplete/ModelAutocomplete";

export type ModelId = string;
export interface ModelReferenceAutocompleteProps<T>
  extends Omit<ModelAutocompleteProps<T>, "value"> {
  getValue: (modelId: ModelId) => Promise<Optional<T>>;
  valueReference?: T | ModelId;
}

const ModelReferenceAutocompleteInt = <T,>({
  getValue,
  valueReference,
  ...rest
}: ModelReferenceAutocompleteProps<T>): ReactElement => {
  const value = useGetModelReference({ valueReference, getValue });
  return <ModelAutocomplete<T> {...rest} value={value} />;
};

export const ModelReferenceAutocomplete = withMemo(
  ModelReferenceAutocompleteInt
) as typeof ModelReferenceAutocompleteInt;
