import { AuthProvider, HttpError, UserIdentity } from "react-admin";
import { Server } from "models/server/server";
import { store } from "store/store";
import { login, logout } from "store/session/events";
import { isLogIn, serverSelector, userSelector } from "store/session/selectors";
import { ModelId } from "@laba/nexup-api";
import { logger, Optional } from "@laba/ts-common";
import { workspaceOrganizationOrDefaultSelector } from "store/workspace/selectors";
import { getAdminPanelPermissions } from "store/workspace/events";

export interface LoginProviderParams {
  alreadyLogged?: boolean;
  username?: string;
  password?: string;
  server?: Server;
  organizationId?: ModelId;
  localUrl?: string;
}

const loginProvider = async ({
  alreadyLogged,
  username,
  password,
  server,
  organizationId,
  localUrl
}: LoginProviderParams): Promise<void> => {
  if (!alreadyLogged) {
    if (username && password && server) {
      const result = await store.dispatch(
        login(username, password, server, organizationId, localUrl)
      );
      if (!result.result)
        throw new HttpError("Login Error", result.error?.status, result.error);
    }
    throw new HttpError(
      "Login Error: login attempt without username, password or server",
      0
    );
  }
};

const logoutProvider = async (): Promise<void> => {
  logger.error("logoutProvider");
  await store.dispatch(logout());
};

const checkAuthProvider = (): Promise<void> => {
  if (isLogIn(store.getState())) {
    return Promise.resolve();
  }
  logger.error("checkAuthProvider reject");
  return Promise.reject();
};

const checkErrorProvider = (error: HttpError): Promise<void> => {
  logger.error("checkErrorProvider", error);
  const { status } = error;
  if (status === 401 || status === 403) {
    return Promise.reject();
  }
  return Promise.resolve();
};

const getPermissionsProvider = async (): Promise<Optional<string[]>> => {
  const roles = await store.dispatch(getAdminPanelPermissions());

  return roles;
};

const getIdentityProvider = (): Promise<UserIdentity> => {
  const user = userSelector(store.getState());
  if (user === undefined) return Promise.reject();
  const serverName = serverSelector(store.getState());
  const workspaceOrganization = workspaceOrganizationOrDefaultSelector(
    store.getState()
  );
  return Promise.resolve({
    id: user.id || "",
    fullName: `${user.username} | ${workspaceOrganization?.name} | ${serverName}`
  });
};

export const nexupAuthProvider: AuthProvider = {
  login: loginProvider,
  logout: logoutProvider,
  checkAuth: checkAuthProvider,
  checkError: checkErrorProvider,
  getPermissions: getPermissionsProvider,
  getIdentity: getIdentityProvider
};
