import React from "react";
import { FC, getClassName } from "@laba/react-common";
import { useLocationMarkerCardStyles } from "components/card/LocationMarkerCard/useLocationMarkerCardStyles";
import { LocationCardButtons } from "components/card/LocationCard/LocationCardButtons/LocationCardButtons";
import { isEmpty } from "lodash-es";
import { TitleContentData } from "components/text/TitleContentData/TitleContentData";
import { TextVariant } from "model/themeVariant";
import { ButtonBase } from "components/buttons/ButtonBase/ButtonBase";
import { Noop } from "@laba/ts-common";

export interface LocationMarkerCardProps {
  className?: string;
  name?: string;
  address?: string;
  schedule?: string[];
  availability?: string;
  phone?: string;
  website?: string;
  onCardClicked?: Noop;
  onPhoneClicked?: Noop;
  onWebsiteClicked?: Noop;
  onAddressClicked?: Noop;
}

export const LocationMarkerCard: FC<LocationMarkerCardProps> = ({
  className,
  name,
  address,
  phone,
  website,
  onCardClicked,
  onPhoneClicked,
  onWebsiteClicked,
  onAddressClicked,
  availability,
  schedule = []
}) => {
  const classes = useLocationMarkerCardStyles();
  const hasSchedule = !isEmpty(schedule);

  const getContentData = (text: string, key?: number) => {
    return (
      <TitleContentData
        // eslint-disable-next-line react/no-array-index-key
        key={key}
        titleVariant={TextVariant.Subtitle2}
        principalTextVariant={TextVariant.Subtitle2}
        textList={[{ principalText: text }]}
        fullWidth
      />
    );
  };
  return (
    <ButtonBase
      disableRipple
      className={getClassName(className, classes.root)}
      onClick={onCardClicked}
    >
      <p className={classes.name}>{name} </p>
      <p className={classes.address}>{address} </p>
      <div className={classes.scheduleContainer}>
        {hasSchedule && schedule.map((s, index) => getContentData(s, index))}
        {availability && getContentData(availability)}
      </div>
      <LocationCardButtons
        address={address}
        phone={phone}
        website={website}
        onAddressClicked={onAddressClicked}
        onWebsiteClicked={onWebsiteClicked}
        onPhoneClicked={onPhoneClicked}
      />
    </ButtonBase>
  );
};
