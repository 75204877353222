import React from "react";
import { FC } from "@laba/react-common";
import { Create } from "components/generic/Create/Create";
import {
  PatientForm,
  patientJsonToModel,
  patientTransform
} from "components/pages/NexupAdmin/resources/patient/PatientForm";
import { ResourceType } from "@laba/nexup-api";

export const PatientCreate: FC = () => {
  return (
    <Create
      resourceTransform={patientTransform}
      resource={ResourceType.Patient}
      jsonToModel={patientJsonToModel}
    >
      <PatientForm />
    </Create>
  );
};
