import { Children, getClassName, ReactElement } from "@laba/react-common";
import React from "react";
import { NexupLogo } from "components/svg/NexupLogo";
import { MenuItemConfig } from "components/menu/Menu/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import { StyleVariant } from "model/themeVariant";
import { UserButton } from "components/headers/AppHeader/UserButton/UserButton";
import { LogoutArrowIcon } from "components/icons";
import { TypeInvertedVariant } from "components/utils/getGenericButtonColors";
import { IconOrImg, IconOrImgType } from "components/helpers/IconOrImg";
import { useHeaderStyle } from "components/headers/AppHeader/HeaderStyle";
import { ButtonBase } from "components/buttons/ButtonBase/ButtonBase";
import { BaseIconButton } from "components/buttons/BaseIconButton/BaseIconButton";
import { UserMenuButtonConfig } from "components/buttons/UserMenuButton/UserMenuButton";
import { Noop } from "@laba/ts-common";

export interface HeaderProps<V> {
  style?: StyleVariant;
  className?: string;
  logoClassName?: string;
  title?: string;
  isMobile?: boolean;
  user?: UserMenuButtonConfig;
  userExtraData?: string;
  userOptions?: MenuItemConfig<V>[];
  onMenuButtonClicked?: Noop;
  onUserMenuOptionSelected?: (option: V) => void;
  onLogoClicked?: Noop;
  onRightLogoClicked?: Noop;
  isLoggedIn?: boolean;
  onClickNotLoggedInIcon?: Noop;
  Logo?: IconOrImgType;
  RightLogo?: IconOrImgType;
  alt?: string;
  rightLogoAlt?: string;
  disableLogin?: boolean;
  isRightLogoClickable?: boolean;
  children?: Children;
  userPendingActions?: number;
  onUserMenuDataClick?: Noop;
}

export const Header = <V,>({
  className,
  logoClassName,
  title,
  user,
  userExtraData,
  onMenuButtonClicked,
  onUserMenuOptionSelected,
  onLogoClicked,
  onRightLogoClicked,
  userOptions = [],
  onClickNotLoggedInIcon,
  alt,
  rightLogoAlt,
  isLoggedIn = false,
  isMobile = false,
  style = StyleVariant.Primary,
  Logo = NexupLogo,
  RightLogo,
  disableLogin = false,
  isRightLogoClickable,
  children,
  userPendingActions,
  onUserMenuDataClick
}: HeaderProps<V>): ReactElement => {
  const classes = useHeaderStyle({ isMobile, style, isLoggedIn });
  return (
    <header
      title={title}
      className={getClassName(classes.container, className)}
    >
      <div className={classes.leftContainer}>
        {isLoggedIn && onMenuButtonClicked && (
          <BaseIconButton
            className={classes.menuButton}
            Icon={MenuIcon}
            type={TypeInvertedVariant.Inverted}
            style={StyleVariant.BlackWhite}
            onClick={onMenuButtonClicked}
          />
        )}
        {!isMobile && (
          <ButtonBase className={classes.logoButton} onClick={onLogoClicked}>
            <IconOrImg
              alt={alt}
              Logo={Logo}
              className={getClassName(classes.Logo, logoClassName)}
            />
          </ButtonBase>
        )}
      </div>
      <div className={classes.centerContainer}>
        {title && <p className={classes.text}>{title}</p>}
        {children}
      </div>
      <div className={classes.endContainer}>
        {RightLogo !== undefined && (
          <ButtonBase
            className={isRightLogoClickable ? undefined : classes.defaultCursor}
            onClick={onRightLogoClicked}
          >
            <IconOrImg
              alt={rightLogoAlt}
              Logo={RightLogo}
              className={classes.Logo}
            />
          </ButtonBase>
        )}
        {!disableLogin && (
          <div className={classes.headerButton}>
            {isLoggedIn && user ? (
              <UserButton
                isMobile={isMobile}
                style={style}
                user={user}
                userExtraData={userExtraData}
                onUserMenuOptionSelected={onUserMenuOptionSelected}
                userOptions={userOptions}
                userPendingActions={userPendingActions}
                onUserMenuDataClick={onUserMenuDataClick}
              />
            ) : (
              <BaseIconButton
                Icon={LogoutArrowIcon}
                type={TypeInvertedVariant.Inverted}
                style={StyleVariant.BlackWhite}
                onClick={onClickNotLoggedInIcon}
              />
            )}
          </div>
        )}
      </div>
    </header>
  );
};
