import React, { useState } from "react";
import { FC, OnBlurEvent, TypeVariant } from "@laba/react-common";
import {
  Autocomplete,
  ListBoxComponentMuiType
} from "components/autocomplete/Autocomplete/Autocomplete";
import { TagIcon } from "components/icons";
import { TagInput } from "components/tags/TagInput/TagInput";
import {
  TagListAutocompleteOptionConfig,
  TagListAutocompleteOptionProps
} from "components/tags";
import { useTagListAutocompleteStyles } from "components/tags/TagListAutocomplete/useTagListAutocompleteStyles";
import { isEmpty } from "lodash-es";
import { notUndefined, Noop } from "@laba/ts-common";

export interface TagListAutocompleteProps {
  className?: string;
  onChange?: (val?: string) => void;
  onClearClicked?: Noop;
  selectedOptions: TagListAutocompleteOptionConfig<string>[];
  options?: TagListAutocompleteOptionConfig<string>[];
  openText: string;
  closeText: string;
  clearText: string;
  loadingText: string;
  noOptionsText: string;
  inputValue?: string;
  setInputValue: (val: string) => void;
  placeholder?: string;
  excludedValues?: string[];
  onClickCustomClickableOption?: Noop;
  customOptionText?: string;
  showCustomOption?: boolean;
  showTagIcon?: boolean;
  clearable?: boolean;
  onBlur?: OnBlurEvent;
  selectedTagListMapper?: (
    selected: TagListAutocompleteOptionConfig<string>[]
  ) => string;
  moreCharsText?: string;
}

export const TagListAutocomplete: FC<TagListAutocompleteProps> = ({
  className,
  onChange,
  selectedOptions,
  options = [],
  openText,
  closeText,
  clearText,
  loadingText,
  noOptionsText,
  inputValue,
  setInputValue,
  placeholder,
  excludedValues,
  customOptionText,
  onClickCustomClickableOption,
  showCustomOption,
  showTagIcon = true,
  onBlur,
  selectedTagListMapper,
  clearable,
  onClearClicked,
  moreCharsText
}) => {
  const classes = useTagListAutocompleteStyles();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomOption: FC<TagListAutocompleteOptionProps<string>> = ({
    option
  }) => {
    return (
      <TagInput
        text={option.text}
        checked={selectedOptions.some(v => v.value === option.value)}
        backgroundColor={option.color}
      />
    );
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const ListBoxComponent: ListBoxComponentMuiType = props => {
    return <div {...props} className={classes.listBoxComponent} />;
  };

  const textBoxValue = isPopoverOpen
    ? inputValue
    : selectedTagListMapper?.(selectedOptions) ?? "";
  const value = selectedOptions
    .map(s => s.value)
    .filter(notUndefined)
    .join(",");
  return (
    <Autocomplete<string>
      className={className}
      value={isEmpty(value) ? undefined : `value:${value}`}
      variant={TypeVariant.Contained}
      onOpenOptionList={() => {
        setIsPopoverOpen(true);
        setInputValue("");
      }}
      onCloseOptionList={() => setIsPopoverOpen(false)}
      options={options}
      openText={openText}
      closeText={closeText}
      loadingText={loadingText}
      noOptionsText={noOptionsText}
      AutocompleteOptionC={CustomOption}
      inputValue={textBoxValue}
      showError={false}
      showHelperOrErrorText={false}
      onInputChange={(val: string, optionChanged: boolean) =>
        !optionChanged && setInputValue(val)
      }
      ListBoxComponentC={ListBoxComponent}
      disableCloseOnSelect
      withoutOptionClassName
      onChange={(v?: string) => {
        if (v === undefined) {
          return onClearClicked?.();
        }
        onChange?.(v);
      }}
      EndIcon={showTagIcon ? TagIcon : undefined}
      endIconShouldNotRotate={showTagIcon}
      label={placeholder}
      fullWidth
      excludedValues={excludedValues}
      showCustomOption={showCustomOption}
      customOptionText={customOptionText}
      onClickCustomClickableOption={onClickCustomClickableOption}
      customClickableOptionClassName={classes.customClickableOption}
      onBlur={onBlur}
      clearable={clearable}
      clearText={clearText}
      onlyClickEvent
      textInputReadOnly={!isPopoverOpen}
      moreCharsText={moreCharsText}
    />
  );
};
