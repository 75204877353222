import {
  createStyle,
  flexColumnMixin,
  marginMixin,
  paddingMixin,
  pxToRem,
  TextVariant,
  Theme
} from "@laba/react-common";
import { themeTextMixin } from "model/themeUtils";

export const useProblemCreationPopupStyles = createStyle(
  (theme: Theme) => ({
    contentRoot: {
      ...flexColumnMixin({}),
      width: "100%",
      minHeight: pxToRem(600)
    },
    content: {
      ...paddingMixin({ leftRight: 56 })
    },
    title: {
      ...themeTextMixin(theme, TextVariant.Subtitle2, 1),
      ...marginMixin({ bottom: 24 }),
      textTransform: "uppercase"
    },
    addProblem: {
      ...marginMixin({ bottom: 32 })
    }
  }),
  "ProblemCreationPopup"
);
