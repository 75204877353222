import {
  getDefaultJsonToModel,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";
import { Account, ResourceType } from "@laba/nexup-api";
import {
  getDefaultResourceTransform,
  ResourceForm
} from "components/generic/ResourceForm/ResourceForm";
import { tkCP } from "translation/i18n";
import { FC, useI18n } from "@laba/react-common";
import React from "react";
import { AccountMainContent } from "components/pages/NexupAdmin/resources/account/AccountMainContent";

export const accountJsonToModel: JsonToModel<Account> = getDefaultJsonToModel(
  draft => {
    draft.organization = undefined;
    draft.payer = undefined;
    draft.patient = undefined;
  }
);
export const accountTransform = getDefaultResourceTransform<Account>(
  ResourceType.Account
);

export const tk = tkCP.adminPage[ResourceType.Account];

export const AccountForm: FC = () => {
  const { t } = useI18n();

  return (
    <ResourceForm
      tabsConfig={[
        {
          label: t(tk.tabs.main),
          content: <AccountMainContent />
        }
      ]}
    />
  );
};
