import { FC } from "@laba/react-common";
import { Noop, Optional } from "@laba/ts-common";
import React from "react";
import { DownloadLink } from "../DownloadLink/DownloadLink";
import { FileAttachment } from "./FileAttachment/FileAttachment";

interface FileAttachmentDownloadProps {
  name: string;
  underlined?: boolean;
  sidewaysIcon?: boolean;
  onDownload?: () => Promise<Optional<Blob>>;
  withPreview?: boolean;
  onSuccess?: Noop;
  onFailure?: (e: Error) => void;
  className?: string;
}

export const FileAttachmentDownload: FC<FileAttachmentDownloadProps> = ({
  name,
  onDownload,
  underlined = true,
  withPreview = false,
  sidewaysIcon = false,
  onFailure,
  onSuccess,
  className
}) => (
  <DownloadLink
    name={name}
    onDownload={onDownload}
    withPreview={withPreview}
    onFailure={onFailure}
    onSuccess={onSuccess}
    className={className}
  >
    <FileAttachment
      fileName={name}
      underlined={underlined}
      sidewaysIcon={sidewaysIcon}
    />
  </DownloadLink>
);
