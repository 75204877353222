import { Context, createContext, useContext, useEffect, useState } from "react";
import { Noop } from "@laba/ts-common";

export interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<void>;
}

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}

interface InstallEventData {
  beforeInstallPromptEvent?: BeforeInstallPromptEvent;
  showInstallAppButton?: boolean;
  closeInstallAppButton?: Noop;
}

export type InstallEventContext = Context<InstallEventData>;

export const useGetBeforeInstallPromptValue = (
  showButton?: boolean,
  onDismissedClicked?: Noop
): InstallEventData => {
  const [installEvent, setInstallEvent] = useState<BeforeInstallPromptEvent>();

  const isStandalone = window.matchMedia("(display-mode: standalone)").matches;

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      setInstallEvent(e);
    });
  }, []);

  return {
    beforeInstallPromptEvent: installEvent,
    showInstallAppButton: !isStandalone && showButton,
    closeInstallAppButton: onDismissedClicked
  };
};

export const installEventContext = createContext<InstallEventData>({});

export const useInstallAppButtonEventContext = (): InstallEventData => {
  return useContext(installEventContext);
};

export interface InstallEventReturnData {
  installEvent?: BeforeInstallPromptEvent;
  context: InstallEventContext;
  showInstallAppButton?: boolean;
  closeInstallAppButton?: Noop;
}

export const useInstallEvent = (): InstallEventReturnData => {
  const {
    beforeInstallPromptEvent,
    showInstallAppButton,
    closeInstallAppButton
  } = useInstallAppButtonEventContext();

  return {
    showInstallAppButton,
    installEvent: beforeInstallPromptEvent,
    context: installEventContext,
    closeInstallAppButton
  };
};
