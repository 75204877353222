import React, { useEffect, useState } from "react";
import { FC, ModelId } from "@laba/react-common";
import {
  createBaseEncounterLocation,
  EncounterLocation,
  HospitalizationKey,
  KnownLocationCategory,
  Location,
  LocationCategory,
  LocationListQueryParamsKey,
  LocationStatus,
  LocationType,
  ResourceType
} from "@laba/nexup-api";
import { notUndefined } from "@laba/ts-common";
import { useReactHookFormInput } from "components/hook/useReactHookFormInput";
import { ReferenceResourceInput } from "../ReferenceResourceInput/ReferenceResourceInput";
import { locationOptionText } from "../ReferenceResourceInput/optionText";

interface Props {
  locationListPath: string;
  label: string;
  physicalType: LocationType;
  organizationId?: ModelId;
  locationCategory?: LocationCategory;
}

export const LocationResourceInput: FC<Props> = ({
  locationListPath,
  physicalType,
  label,
  organizationId,
  locationCategory = KnownLocationCategory.Hospitalization
}) => {
  const [index, setIndex] = useState<number>(-1);

  const locationValuePath = [
    locationListPath,
    index,
    HospitalizationKey.location
  ].join(".");

  const { onChange: onChangeLocationList, value: locationList } =
    useReactHookFormInput<EncounterLocation[]>({
      source: locationListPath
    });

  useEffect(() => {
    const idx = locationList?.findIndex(i => i.physicalType === physicalType);
    if (idx !== -1 && notUndefined(idx)) {
      setIndex(idx);
    } else {
      const newlocationList = [
        ...(locationList ?? []),
        createBaseEncounterLocation("", physicalType)
      ];
      onChangeLocationList(newlocationList);
      setIndex(newlocationList.length - 1);
    }
  }, [locationList, physicalType, setIndex, onChangeLocationList]);

  if (index === -1) {
    return null;
  }

  return (
    <ReferenceResourceInput<Location>
      source={locationValuePath}
      label={label}
      resourceType={ResourceType.Location}
      optionTextKey={locationOptionText}
      extraFilters={{
        [LocationListQueryParamsKey.organization]: organizationId,
        [LocationListQueryParamsKey.category]: locationCategory,
        [LocationListQueryParamsKey.status]: LocationStatus.Active,
        [LocationListQueryParamsKey.physicalType]: physicalType
      }}
    />
  );
};
