import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { persistReducer, persistStore } from "redux-persist";
import {
  ActionFromReducersMapObject,
  combineReducers,
  configureStore as _configureStore,
  createAction,
  ReducersMapObject,
  StateFromReducersMapObject
} from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import { PersistedState, PersistMigrate } from "redux-persist/es/types";
import { BaseStoreState, EnhancedStore, Persistor, SliceName } from "./types";

export const cleanAppData = createAction(
  "CLEAN_APP_DATA",
  (keepWhitelist?: boolean) => {
    return {
      payload: {
        keepWhitelist: keepWhitelist ?? false
      }
    };
  }
);

interface ConfigureStoreReturn<M> {
  store: EnhancedStore<
    StateFromReducersMapObject<M>,
    ActionFromReducersMapObject<M>
  >;
  persistor: Persistor;
}

/**
 * @param reducers - Map that associates reducer name with reducer
 * @param whitelist - Name of state slices that will be persisted between page reloads
 * @param onStart - Callback called after the store has been rehydrated
 * @param stateVersion - Version de estado del store
 * @returns \{ store: Redux store, PersistedStore: ReactComponent with configured store for its children \}
 */
export const configureStore = <M extends ReducersMapObject>(
  reducers: M,
  whitelist: SliceName[],
  onStart?: () => Promise<void>,
  stateVersion?: number
): ConfigureStoreReturn<M> => {
  const migrate: PersistMigrate = async (
    state: PersistedState,
    currentVersion: number
  ) => {
    // eslint-disable-next-line no-underscore-dangle
    const previousVersion = state?._persist.version;
    if (currentVersion === previousVersion) return state;
    return { _persist: { rehydrated: false, version: stateVersion ?? -1 } };
  };
  const persistConfig = {
    key: "root",
    storage,
    whitelist,
    version: stateVersion,
    migrate
  };
  const baseReducer = combineReducers<M>(reducers);

  const appReducer: typeof baseReducer = (state, action) => {
    if (cleanAppData.match(action) && state !== undefined) {
      const newState: BaseStoreState = {};
      if (action.payload.keepWhitelist) {
        whitelist.forEach(sliceName => {
          newState[sliceName] = state[sliceName];
        });
      }
      return baseReducer(newState as StateFromReducersMapObject<M>, action);
    }
    return baseReducer(state, action);
  };

  const persistedReducer = persistReducer(persistConfig, appReducer);

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
  });

  const store = _configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false
      }),
    enhancers: [sentryReduxEnhancer]
  });

  const persistor = persistStore(store, null, onStart);

  return {
    store,
    persistor
  };
};
