import {
  getDefaultJsonToModel,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";
import { Appointment, ResourceType } from "@laba/nexup-api";
import {
  getDefaultResourceTransform,
  ResourceForm
} from "components/generic/ResourceForm/ResourceForm";
import { tkCP } from "translation/i18n";
import { FC, useI18n } from "@laba/react-common";
import { AppointmentMainContent } from "components/pages/NexupAdmin/resources/appointment/AppointmentMainContent";
import React from "react";
import { AppointmentNotificationChannelContent } from "components/pages/NexupAdmin/resources/appointment/AppointmentNotificationChannelContent";

export const appointmentJsonToModel: JsonToModel<Appointment> =
  getDefaultJsonToModel(draft => {
    draft.patient = undefined;
    draft.practitioner = undefined;
    draft.appointmentDefinition = undefined;
    draft.healthcareService = undefined;
  });

export const appointmentTransform = getDefaultResourceTransform<Appointment>(
  ResourceType.Appointment
);

export const tk = tkCP.adminPage[ResourceType.Appointment];

export const AppointmentForm: FC = () => {
  const { t } = useI18n();

  return (
    <ResourceForm
      tabsConfig={[
        {
          label: t(tk.tabs.main),
          content: <AppointmentMainContent />
        },
        {
          label: t(tk.tabs.notificationChannel),
          content: <AppointmentNotificationChannelContent />
        }
      ]}
    />
  );
};
