import {
  borderMixin,
  createStyle,
  FlexAlignItems,
  flexItemMixin,
  FlexJustifyContent,
  flexRowMixin,
  paddingMixin,
  pxToRem
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { NexupColor } from "model/nexupColor";
import {
  getThemeButtonRadius,
  getThemeStyleColor,
  themeTextMixin
} from "model/themeUtils";
import { StyleVariant, TextVariant } from "model/themeVariant";

export const useChatInputStyle = createStyle(
  (theme: Theme) => ({
    root: {
      backgroundColor: NexupColor.Background,
      ...flexRowMixin({
        justifyContent: FlexJustifyContent.FlexEnd,
        alignItems: FlexAlignItems.FlexEnd
      }),
      ...paddingMixin({
        topBottom: 8,
        leftRight: 16
      }),
      ...borderMixin({ side: { all: 0 }, radius: { all: 4 } })
    },
    // this class exists to override mui specifity.
    overrideMui: {
      ...flexItemMixin({
        grow: 1
      }),
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent"
      }
    },
    endContainer: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.FlexStart,
        gap: 8
      }),
      ...flexItemMixin({
        shrink: 0
      })
    },
    attachmentIcon: {
      ...paddingMixin({ leftRight: 8, topBottom: 0 }),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      ),
      "& .MuiSvgIcon-root": {
        width: pxToRem(30),
        height: pxToRem(40)
      }
    },
    sendIcon: {
      ...borderMixin({
        radius: { all: getThemeButtonRadius(theme) }
      }),
      "& span": {
        "& svg": {
          ...paddingMixin({ left: 6, bottom: 4 }),
          transform: "rotate(-30deg)",
          width: pxToRem(30),
          height: pxToRem(30)
        }
      }
    }
  }),
  "ChatInput"
);

export const useChatInputLabelStyle = createStyle(
  (theme: Theme) => ({
    input: {
      "&::placeholder": {
        ...themeTextMixin(theme, TextVariant.Body2, 1),
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.GrayDark
        ),
        opacity: 1
      }
    },
    multiline: {
      ...paddingMixin({ all: 0 })
    }
  }),
  "ChatInputLabel"
);
