import {
  ResourceType,
  getMedicationSubstanceList,
  getMedicationSubstance,
  updateMedicationSubstance,
  createMedicationSubstance,
  MedicationSubstanceListQueryParams,
  MedicationSubstanceListQueryParamsKey,
  getModelReferenceId
} from "@laba/nexup-api";
import {
  getEnumOrderParam,
  getKeyTargetParam,
  resourceProviderGetter
} from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { workspaceOrganizationSelector } from "store/workspace/selectors";
import { store } from "store/store";

export const medicationSubstanceProvider = resourceProviderGetter(
  ResourceType.MedicationSubstance,
  getEnumOrderParam({}),
  getKeyTargetParam<MedicationSubstanceListQueryParams>(
    MedicationSubstanceListQueryParamsKey
  ),
  getMedicationSubstanceList,
  getMedicationSubstance,
  updateMedicationSubstance,
  createMedicationSubstance,
  undefined,
  () => ({
    organization: getModelReferenceId(
      workspaceOrganizationSelector(store.getState())
    )
  })
);
