import React from "react";
import { FC } from "@laba/react-common";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

export const Mesh02: FC<SvgIconProps> = props => {
  return (
    <svg
      {...props}
      id="Capa_2"
      data-name="Capa 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 322 55"
      opacity="0.4"
    >
      <defs>
        <clipPath id="clippath">
          <rect fill="none" x="0" width="322" height="55" />
        </clipPath>
      </defs>
      <g id="_Õ__7_x5F___2" data-name="Õº≤„_7_x5F_∏¥÷∆_2">
        <g clipPath="url(#clippath)">
          <path
            fill="#fff"
            d="m279.87,1.03c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m287.31,2.93c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m294.99,2.87c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m302.52,1.3c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m309.95-.7c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m273.45,1.7c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m280.41,5.09c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="287.03" cy="7.12" r=".81" />
          <path
            fill="#fff"
            d="m295.54,7.2c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m303.12,5.75c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m310.6,3.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m317.99,1.43c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m268.03,1.85c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m274.49,6.19c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m281.41,9.7c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m288.83,11.87c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m296.55,12.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m304.18,10.77c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m311.71,9.03c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m319.15,6.67c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m263.56,1.51c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m269.61,6.42c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m276.02,10.87c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m282.9,14.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m290.3,16.82c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m298.04,17.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="304.91" cy="15.98" r=".81" />
          <path
            fill="#fff"
            d="m313.31,14.37c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m320.79,12.12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m259.94.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m265.73,6.13c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m271.71,11.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="277.26" cy="15.72" r=".81" />
          <path
            fill="#fff"
            d="m284.91,19.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m292.3,21.93c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m300.06,22.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m307.78,21.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m315.43,19.87c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m322.96,17.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m257.12.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m262.73,5.58c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="267.64" cy="10.94" r=".81" />
          <circle fill="#fff" cx="273.56" cy="16.08" r=".81" />
          <path
            fill="#fff"
            d="m280.68,20.75c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m287.48,24.63c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m294.85,27.22c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m302.63,27.84c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m310.4,26.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m318.1,25.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m130.93-.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m138.54,1.37c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m146.36,2.04c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m154.27,1.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m162.05.46c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m255.07-.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m260.57,4.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m266.11,10.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m271.76,15.95c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m277.62,21.2c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m283.87,25.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m290.64,29.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m297.99,32.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m305.8,33.47c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m313.61,32.67c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="320.56" cy="31.46" r=".81" />
          <path
            fill="#fff"
            d="m127.95.75c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="134.58" cy="3.05" r=".81" />
          <path
            fill="#fff"
            d="m143.05,4.7c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m150.89,5.51c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="157.99" cy="5.28" r=".81" />
          <path
            fill="#fff"
            d="m166.58,3.9c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m174.04,1.37c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m259.09,2.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m264.52,8.62c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m269.99,14.32c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m275.57,19.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m281.37,25.27c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m287.57,30.17c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m294.3,34.3c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="300.82" cy="37.16" r=".81" />
          <circle fill="#fff" cx="308.65" cy="38.05" r=".81" />
          <path
            fill="#fff"
            d="m317.32,37.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m125.49.93c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m132.88,3.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m140.39,6.36c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m148.09,8.22c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m155.96,9.17c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m163.88,8.97c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m171.65,7.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m179.1,4.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m186.13,1.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m258.27.87c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m263.65,6.7c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="268.19" cy="12.55" r=".81" />
          <path
            fill="#fff"
            d="m274.4,18.36c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m279.91,24.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m285.65,29.53c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m291.8,34.54c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m298.49,38.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m305.81,41.8c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m313.65,42.82c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m321.57,42.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m129.79,1.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m137.17,4.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="143.86" cy="6.98" r=".81" />
          <path
            fill="#fff"
            d="m152.37,8.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m160.24,9.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m168.15,9.4c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m175.93,8.03c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m183.39,5.54c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m190.43,2.14c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m262.85,2.78c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m268.3,8.47c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m273.74,14.18c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m279.21,19.84c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m284.81,25.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m290.61,30.7c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m296.82,35.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m303.54,39.69c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m310.89,42.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m318.73,43.58c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m126.67.57c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m133.99,3.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m141.36,5.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m148.86,7.77c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="155.75" cy="9.38" r=".81" />
          <path
            fill="#fff"
            d="m164.42,10.19c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m172.34,10c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m180.12,8.7c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m187.58,6.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m194.64,3.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m201.32-.69c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m261.64-.55c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m267.33,4.85c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m272.86,10.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m278.38,15.97c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m283.93,21.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="288.79" cy="26.86" r=".81" />
          <path
            fill="#fff"
            d="m295.48,32.03c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="300.93" cy="36.75" r=".81" />
          <path
            fill="#fff"
            d="m308.51,40.75c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m315.87,43.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m123.48-.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m130.8,2.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m138.11,4.42c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m145.47,6.66c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m152.96,8.69c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m160.65,10.17c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m168.52,10.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m176.43,10.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="183.41" cy="9.52" r=".81" />
          <path
            fill="#fff"
            d="m191.69,7.31c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="197.95" cy="4.31" r=".81" />
          <path
            fill="#fff"
            d="m205.46.71c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="265.15" cy="1.79" r=".81" />
          <path
            fill="#fff"
            d="m271.73,7.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m277.34,12.49c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m282.93,17.9c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m288.56,23.26c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m294.31,28.49c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m300.26,33.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m306.57,38.07c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m313.38,41.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m320.76,44.66c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="103.6" cy="-.23" r=".81" />
          <path
            fill="#fff"
            d="m112.27-.14c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m120.02.25c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m127.54,1.71c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="134.04" cy="3.75" r=".81" />
          <path
            fill="#fff"
            d="m142.14,5.84c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m149.49,7.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m156.98,9.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="163.86" cy="11.08" r=".81" />
          <path
            fill="#fff"
            d="m172.53,11.76c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m180.45,11.58c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m188.23,10.44c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m195.71,8.37c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m202.8,5.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m209.51,2.23c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m264.14-.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m270.2,4.24c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m276.04,9.39c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="280.92" cy="14.68" r=".81" />
          <circle fill="#fff" cx="286.59" cy="19.94" r=".81" />
          <path
            fill="#fff"
            d="m293.11,25.15c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m298.93,30.22c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m304.95,35.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="310.51" cy="39.51" r=".81" />
          <path
            fill="#fff"
            d="m318.17,43.25c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m2.08-.12c0,.45-.36.81-.81.81S.46.32.46-.12s.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m100.75,1.05c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m108.46,2.18c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m116.3,2.15c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m124.03,2.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m131.53,3.67c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m138.82,5.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m146.1,7.36c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m153.44,9.19c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m160.92,10.86c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m168.61,12.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m176.47,12.69c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m184.39,12.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m192.17,11.46c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m199.66,9.53c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="205.95" cy="6.95" r=".81" />
          <path
            fill="#fff"
            d="m213.5,3.84c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m219.6,0c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m268.25,1.92c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m274.37,6.78c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m280.28,11.8c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m286.04,16.96c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m291.8,22.07c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m297.59,27.13c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m303.48,32.05c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m309.57,36.76c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m316,41.04c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m322.89,44.65c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m5.71,1.95c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m97.34,1.23c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="103.92" cy="3.63" r=".81" />
          <circle fill="#fff" cx="111.63" cy="4.65" r=".81" />
          <path
            fill="#fff"
            d="m120.26,4.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m127.97,4.63c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m135.45,5.69c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m142.72,7.29c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m149.99,8.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m157.32,10.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m164.79,12.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m172.48,13.15c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m180.34,13.69c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m188.26,13.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m196.05,12.54c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m203.55,10.76c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m210.66,8.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m217.41,5.51c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m223.55,1.97c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m265.79.25c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m272.3,4.63c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m278.47,9.39c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m284.45,14.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m290.29,19.31c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m296.12,24.27c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m301.99,29.19c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m307.97,33.95c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m314.12,38.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m320.61,42.63c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m2.03,7.17c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m9.27,4.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m16.32.66c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m94.16.75c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m101.24,3.98c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m108.65,6.26c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m116.36,7.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m124.16,6.93c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="131.04" cy="6.9" r=".81" />
          <path
            fill="#fff"
            d="m139.31,7.77c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m146.56,9.14c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m153.81,10.57c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m161.14,11.98c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="167.8" cy="13.3" r=".81" />
          <path
            fill="#fff"
            d="m176.29,14.26c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m184.15,14.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m192.07,14.57c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m199.86,13.67c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="206.56" cy="12.03" r=".81" />
          <path
            fill="#fff"
            d="m214.49,9.85c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="220.45" cy="7.23" r=".81" />
          <circle fill="#fff" cx="226.63" cy="3.97" r=".81" />
          <circle fill="#fff" cx="232.85" cy=".75" r=".81" />
          <path
            fill="#fff"
            d="m262.72-.53c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m269.73,3.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m276.28,7.39c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m282.51,12.04c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m288.56,16.81c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m294.48,21.7c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m300.39,26.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m306.34,31.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m312.39,35.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m318.61,40.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m5.52,9.24c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m12.77,6.2c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m19.86,2.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m26.86-.54c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m91.09-.07c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m97.97,3.64c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m105.08,6.76c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m112.51,8.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m120.22,9.7c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m128.01,9.36c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m135.68,9.19c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m143.11,9.86c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m150.35,11.01c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m157.59,12.22c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m164.9,13.43c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m172.36,14.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="179.24" cy="15.4" r=".81" />
          <path
            fill="#fff"
            d="m187.91,15.81c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m195.82,15.65c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m203.61,14.82c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="210.32" cy="13.32" r=".81" />
          <path
            fill="#fff"
            d="m218.27,11.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m225.06,8.98c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m231.28,6.01c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m237.53,3.07c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m244.06.53c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m251.34-.64c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m259.06-.04c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m266.58,2.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m273.61,5.96c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m280.2,10.17c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m286.5,14.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m292.62,19.36c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m298.61,24.12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m304.6,28.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m310.63,33.4c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m316.75,37.85c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="322.24" cy="42.1" r=".81" />
          <path
            fill="#fff"
            d="m1.47,13.86c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m8.95,11.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m16.23,8.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m23.35,5.15c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m30.38,1.83c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m94.81,2.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m101.73,6.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m108.88,9.57c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m116.33,11.61c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m124.04,12.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m131.81,11.83c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m139.45,11.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m146.87,12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="153.28" cy="12.92" r=".81" />
          <path
            fill="#fff"
            d="m161.31,13.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m168.62,14.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m176.07,15.87c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m183.76,16.57c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m191.61,16.92c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m199.53,16.77c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m207.32,16.02c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m214.85,14.66c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m222,12.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="228" cy="10.76" r=".81" />
          <path
            fill="#fff"
            d="m235.06,8.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="240.54" cy="5.43" r=".81" />
          <circle fill="#fff" cx="247.09" cy="3.16" r=".81" />
          <circle fill="#fff" cx="254.37" cy="2.19" r=".81" />
          <path
            fill="#fff"
            d="m262.88,2.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m270.39,5.33c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m277.44,8.87c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m284.08,12.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m290.43,17.44c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="295.81" cy="21.95" r=".81" />
          <path
            fill="#fff"
            d="m302.69,26.58c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m308.76,31.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m314.87,35.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m321.07,39.86c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m4.85,15.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m12.35,13.43c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m19.65,10.54c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m26.8,7.39c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m33.87,4.18c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m40.72.8c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m91.63,2.12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m98.49,5.93c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m105.45,9.46c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m112.63,12.36c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m120.1,14.27c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="127" cy="14.83" r=".81" />
          <path
            fill="#fff"
            d="m135.57,14.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m143.19,13.83c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m150.58,14.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m157.79,14.81c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m164.99,15.58c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="171.48" cy="16.37" r=".81" />
          <circle fill="#fff" cx="178.93" cy="17.15" r=".81" />
          <path
            fill="#fff"
            d="m187.42,17.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m195.28,18c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m203.19,17.85c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="210.18" cy="17.18" r=".81" />
          <path
            fill="#fff"
            d="m218.53,15.97c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m225.69,14.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m232.52,12.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m238.81,10.12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m245.13,7.76c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m251.7,5.76c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m258.97,4.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m266.66,5.8c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m274.16,8.26c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m281.24,11.75c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m287.92,15.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m294.33,20.14c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m300.59,24.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m306.73,29.01c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m312.88,33.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m319.07,37.7c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m.55,19.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m8.19,17.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m15.71,15.47c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m23.03,12.64c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m30.22,9.58c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="36.51" cy="6.47" r=".81" />
          <path
            fill="#fff"
            d="m44.23,3.23c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m51.11.17c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m88.28,1.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m95.24,5.12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="101.33" cy="8.87" r=".81" />
          <path
            fill="#fff"
            d="m109.13,12.31c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m116.35,15.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m123.84,16.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m131.55,17.34c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m139.29,16.68c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m146.89,16.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m154.26,16.17c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="160.64" cy="16.65" r=".81" />
          <path
            fill="#fff"
            d="m168.64,17.19c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m175.93,17.78c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m183.38,18.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="190.24" cy="18.82" r=".81" />
          <path
            fill="#fff"
            d="m198.91,19.03c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m206.82,18.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m214.62,18.3c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m222.17,17.23c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="228.54" cy="15.87" r=".81" />
          <path
            fill="#fff"
            d="m236.19,14.23c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m242.52,12.12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m248.87,10.04c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m255.47,8.3c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m262.73,7.74c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m270.41,8.65c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="277.09" cy="11.13" r=".81" />
          <path
            fill="#fff"
            d="m284.99,14.58c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m291.72,18.54c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m298.19,22.78c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m304.52,27.03c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m310.74,31.39c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m316.97,35.62c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m323.24,39.78c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m3.85,21.77c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m11.51,19.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m19.04,17.43c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m26.39,14.67c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m33.6,11.7c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m40.75,8.69c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m47.7,5.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m54.64,2.68c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="60.89" cy=".21" r=".81" />
          <path
            fill="#fff"
            d="m76.95-.77c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="83.76" cy="1.22" r=".81" />
          <path
            fill="#fff"
            d="m91.85,4.34c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m98.83,8.05c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m105.76,11.74c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m112.79,15.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m120.04,17.76c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m127.55,19.43c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m135.26,19.77c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m142.99,19c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m150.56,18.27c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m157.91,18.15c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m165.08,18.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m172.26,18.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m179.54,19.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m186.98,19.54c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="193.85" cy="19.85" r=".81" />
          <path
            fill="#fff"
            d="m202.51,19.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m210.42,19.86c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m218.23,19.34c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m225.78,18.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m232.97,17.26c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m239.83,15.87c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m246.2,14.04c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m252.59,12.24c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m259.21,10.78c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m266.46,10.42c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m274.12,11.43c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m281.61,13.93c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m288.73,17.33c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m295.49,21.21c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m302.02,25.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m308.42,29.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m314.72,33.7c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m321.03,37.78c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m7.14,23.55c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m14.8,21.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m22.35,19.29c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m29.72,16.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m36.97,13.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m44.15,10.82c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m51.15,7.85c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m58.15,5.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m65.25,2.8c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m72.76,1.57c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="79.7" cy="2.06" r=".81" />
          <circle fill="#fff" cx="87.3" cy="4.09" r=".81" />
          <path
            fill="#fff"
            d="m95.39,7.21c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m102.39,10.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m109.35,14.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m116.42,17.76c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m123.7,20.33c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m131.24,21.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m138.95,22.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m146.66,21.23c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m154.21,20.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m161.54,20.04c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m168.7,20.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m175.86,20.18c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m183.13,20.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m190.57,20.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m198.24,20.78c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m206.09,20.86c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m214,20.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m221.81,20.29c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m229.37,19.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m236.58,18.55c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m243.46,17.4c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="249.05" cy="15.86" r=".81" />
          <circle fill="#fff" cx="255.47" cy="14.35" r=".81" />
          <path
            fill="#fff"
            d="m262.92,13.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m270.17,13c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m277.82,14.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m285.3,16.64c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m292.43,19.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m299.25,23.78c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m305.83,27.82c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m312.3,31.82c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m318.68,35.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m2.62,26.64c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m10.41,25.21c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m18.08,23.3c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m25.64,21.03c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m33.04,18.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m40.32,15.62c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m47.54,12.82c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m54.59,9.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m61.64,7.4c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m68.78,5.26c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="75.5" cy="4.18" r=".81" />
          <path
            fill="#fff"
            d="m84.05,4.77c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m91.65,6.84c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m98.93,9.95c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m105.94,13.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m112.94,17.15c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m120.04,20.32c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m127.36,22.78c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m134.91,24.2c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m142.62,24.31c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m150.32,23.34c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m157.85,22.31c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m165.15,21.81c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m172.29,21.62c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m179.45,21.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m186.7,21.47c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m194.14,21.53c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m201.81,21.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m209.66,21.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m217.57,21.47c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m225.38,21.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m232.95,20.47c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m240.17,19.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m247.07,18.82c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m253.5,17.57c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m259.96,16.34c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m266.63,15.42c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m273.87,15.46c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m281.5,16.67c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m288.97,19.22c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m296.13,22.53c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m302.98,26.24c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m309.62,30.18c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m316.17,34.05c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m322.62,38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m5.88,28.14c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m13.68,26.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m21.36,24.85c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m28.93,22.63c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m36.35,20.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m43.66,17.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m50.92,14.68c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m58.02,11.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m65.12,9.55c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m72.31,7.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m79.85,6.64c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m87.59,7.33c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m95.17,9.44c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m102.45,12.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="108.68" cy="16.16" r=".81" />
          <path
            fill="#fff"
            d="m116.51,19.66c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="122.84" cy="22.73" r=".81" />
          <path
            fill="#fff"
            d="m131,25.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m138.58,26.39c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m146.29,26.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m153.97,25.3c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m161.48,24.14c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m168.76,23.43c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m175.88,23.02c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m183.02,22.68c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m190.27,22.44c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m197.7,22.33c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m205.36,22.25c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m213.22,22.2c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m221.13,22.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m228.94,21.8c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m236.52,21.29c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m243.75,20.75c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m250.67,20.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="256.33" cy="19.13" r=".81" />
          <path
            fill="#fff"
            d="m263.63,18.19c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m270.32,17.53c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m277.55,17.78c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="284.36" cy="19.09" r=".81" />
          <circle fill="#fff" cx="291.83" cy="21.66" r=".81" />
          <path
            fill="#fff"
            d="m299.81,24.93c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m306.71,28.55c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m313.41,32.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m320.03,36.13c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m1.27,30.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="8.33" cy="29.47" r=".81" />
          <circle fill="#fff" cx="16.13" cy="28.08" r=".81" />
          <path
            fill="#fff"
            d="m24.63,26.23c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m32.21,24.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m39.66,21.58c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m47,18.97c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m54.3,16.37c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="60.64" cy="13.82" r=".81" />
          <path
            fill="#fff"
            d="m68.6,11.54c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m75.83,9.74c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m83.39,8.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m91.12,9.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m98.69,11.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="105.17" cy="14.99" r=".81" />
          <path
            fill="#fff"
            d="m113.03,18.57c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m120.08,21.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m127.26,24.98c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m134.65,27.22c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m142.24,28.4c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m149.95,28.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m157.62,27.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m165.1,25.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m172.37,24.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m179.47,24.26c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m186.6,23.7c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m193.83,23.25c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m201.26,22.96c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m208.92,22.75c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m216.77,22.63c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m224.68,22.51c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m232.5,22.31c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m240.09,21.95c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m247.33,21.61c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m254.27,21.2c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m260.78,20.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m267.3,19.87c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m274.01,19.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m281.24,19.93c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m288.84,21.34c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m296.3,23.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m303.5,27.15c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m310.44,30.69c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="316.39" cy="34.42" r=".81" />
          <path
            fill="#fff"
            d="m4.53,31.49c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m12.41,30.61c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m20.21,29.24c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m27.9,27.42c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m35.5,25.3c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m42.97,22.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m50.35,20.36c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m57.68,17.87c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m64.89,15.45c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m72.09,13.34c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m79.36,11.7c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="86.13" cy="11.05" r=".81" />
          <path
            fill="#fff"
            d="m94.66,11.92c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m102.22,14.12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m109.5,17.24c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m116.58,20.78c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m123.66,24.14c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m130.88,27.04c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m138.3,29.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m145.91,30.22c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m153.62,29.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m161.27,28.7c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m168.73,27.25c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m175.98,26.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m183.07,25.3c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="189.37" cy="24.52" r=".81" />
          <path
            fill="#fff"
            d="m197.4,23.86c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m204.82,23.39c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m212.48,23.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m220.33,22.87c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m228.25,22.76c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m236.06,22.63c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m243.66,22.42c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m250.92,22.29c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m257.87,22.12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m264.42,21.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m270.98,21.36c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="276.9" cy="21.23" r=".81" />
          <circle fill="#fff" cx="284.12" cy="21.89" r=".81" />
          <path
            fill="#fff"
            d="m292.51,23.4c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="299.16" cy="26.02" r=".81" />
          <path
            fill="#fff"
            d="m307.19,29.19c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m314.18,32.65c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m320.99,36.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m7.81,32.42c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m15.68,31.54c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m23.49,30.19c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="30.38" cy="28.4" r=".81" />
          <circle fill="#fff" cx="38" cy="26.33" r=".81" />
          <path
            fill="#fff"
            d="m46.3,23.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m53.71,21.55c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m61.08,19.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="67.53" cy="16.88" r=".81" />
          <path
            fill="#fff"
            d="m75.58,14.92c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="82.1" cy="13.45" r=".81" />
          <path
            fill="#fff"
            d="m90.5,12.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m98.21,13.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m105.76,16.15c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m113.05,19.27c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m120.13,22.77c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m127.25,26.07c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m134.5,28.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m141.96,30.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m149.59,31.83c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m157.31,31.49c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m164.94,30.09c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m172.38,28.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m179.6,27.21c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="185.86" cy="26.13" r=".81" />
          <path
            fill="#fff"
            d="m193.77,25.13c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m200.99,24.26c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m208.4,23.61c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m216.06,23.15c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="223.1" cy="22.89" r=".81" />
          <path
            fill="#fff"
            d="m231.82,22.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m239.64,22.74c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m247.24,22.67c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m254.52,22.74c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m261.49,22.82c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="267.26" cy="22.71" r=".81" />
          <path
            fill="#fff"
            d="m274.67,22.63c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m281.42,22.77c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m288.63,23.63c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m296.2,25.25c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m303.65,27.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m310.89,31.01c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m317.92,34.39c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m3.19,33.47c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m11.1,33.12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m18.98,32.23c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m26.79,30.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m34.5,29.14c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m42.13,27.12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m49.65,24.85c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m57.08,22.49c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="63.68" cy="20.21" r=".81" />
          <path
            fill="#fff"
            d="m71.8,18.07c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m79.1,16.26c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m86.47,14.97c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m94.07,14.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m101.78,15.66c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m109.32,17.95c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m116.6,21.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m123.71,24.53c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m130.86,27.76c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="137.34" cy="30.48" r=".81" />
          <path
            fill="#fff"
            d="m145.64,32.39c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m153.3,33.21c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m161.01,32.74c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m168.63,31.24c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m176.04,29.51c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="182.43" cy="28.03" r=".81" />
          <path
            fill="#fff"
            d="m190.3,26.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="196.57" cy="25.49" r=".81" />
          <path
            fill="#fff"
            d="m204.59,24.43c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="211.19" cy="23.6" r=".81" />
          <path
            fill="#fff"
            d="m219.65,23.01c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m227.5,22.68c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m235.41,22.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m243.23,22.61c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m250.85,22.68c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m258.13,22.96c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m265.12,23.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m271.74,23.46c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m278.37,23.67c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m285.15,24.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m292.35,25.14c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m299.91,26.86c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m307.35,29.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m314.61,32.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m321.69,35.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="5.7" cy="33.92" r=".81" />
          <path
            fill="#fff"
            d="m14.43,33.55c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m22.3,32.65c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m30.11,31.34c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m37.83,29.62c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m45.48,27.64c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m53.02,25.45c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="59.68" cy="23.18" r=".81" />
          <path
            fill="#fff"
            d="m67.93,21c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m75.29,18.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m82.64,17.34c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m90.05,16.22c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m97.68,15.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m105.37,17.15c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m112.9,19.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m120.19,22.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m127.31,26.03c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m134.49,29.19c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m141.82,31.82c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m149.34,33.62c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m157.02,34.32c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m164.73,33.74c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m172.34,32.13c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m179.73,30.26c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m186.91,28.58c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m193.95,27.05c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="200.21" cy="25.6" r=".81" />
          <path
            fill="#fff"
            d="m208.21,24.33c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m215.62,23.32c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m223.27,22.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m231.12,22.21c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m239.03,22.12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m246.85,22.23c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m254.48,22.43c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m261.77,22.92c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m268.78,23.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m275.44,23.95c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m282.11,24.44c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m288.91,25.12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m296.1,26.39c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="302.83" cy="28.2" r=".81" />
          <path
            fill="#fff"
            d="m311.08,30.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m318.36,33.92c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="1.15" cy="33.94" r=".81" />
          <path
            fill="#fff"
            d="m9.87,34.09c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m17.78,33.69c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m25.65,32.8c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m33.47,31.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m41.2,29.81c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m48.85,27.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m56.42,25.76c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m63.92,23.58c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m71.41,21.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m78.81,19.63c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m86.22,18.14c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m93.67,17.18c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m101.31,17.09c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m109,18.36c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m116.52,20.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m123.81,23.84c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m130.95,27.24c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m138.16,30.34c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m145.52,32.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m153.08,34.57c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m160.78,35.14c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m168.49,34.45c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="175.27" cy="32.74" r=".81" />
          <path
            fill="#fff"
            d="m183.45,30.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m190.61,28.85c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m197.63,27.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="203.88" cy="25.43" r=".81" />
          <path
            fill="#fff"
            d="m211.87,23.95c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m219.27,22.76c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m226.92,21.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m234.77,21.45c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m242.68,21.37c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="249.69" cy="21.55" r=".81" />
          <circle fill="#fff" cx="257.33" cy="21.9" r=".81" />
          <path
            fill="#fff"
            d="m265.45,22.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m272.48,23.4c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m279.17,24.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m285.87,24.93c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m292.69,25.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m299.88,27.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m307.4,29.27c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m314.84,31.97c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m322.14,34.96c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="4.54" cy="33.86" r=".81" />
          <path
            fill="#fff"
            d="m13.26,33.97c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="20.36" cy="33.56" r=".81" />
          <circle fill="#fff" cx="28.24" cy="32.66" r=".81" />
          <path
            fill="#fff"
            d="m36.86,31.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m44.6,29.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="51.46" cy="27.85" r=".81" />
          <path
            fill="#fff"
            d="m59.86,25.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m67.39,23.7c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m74.92,21.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="81.57" cy="19.99" r=".81" />
          <path
            fill="#fff"
            d="m89.83,18.66c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m97.33,17.87c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m104.99,17.92c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m112.67,19.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m120.17,21.69c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m127.46,24.81c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m134.62,28.17c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m141.86,31.21c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m149.27,33.66c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m156.86,35.23c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="163.77" cy="35.69" r=".81" />
          <path
            fill="#fff"
            d="m172.29,34.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m179.86,33.07c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m187.21,30.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="193.54" cy="28.83" r=".81" />
          <path
            fill="#fff"
            d="m201.35,26.86c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="207.58" cy="24.97" r=".81" />
          <path
            fill="#fff"
            d="m215.57,23.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="222.16" cy="21.92" r=".81" />
          <path
            fill="#fff"
            d="m230.61,20.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m238.46,20.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="245.56" cy="20.33" r=".81" />
          <path
            fill="#fff"
            d="m254.19,20.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m261.84,21.09c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m269.16,21.98c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m276.21,23.04c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m282.94,24.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m289.67,25.14c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m296.52,26.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m303.7,28.03c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m311.21,30.05c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m318.63,32.78c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m.9,33.13c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m8.79,33.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m16.7,33.57c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="23.8" cy="33.14" r=".81" />
          <circle fill="#fff" cx="31.67" cy="32.23" r=".81" />
          <path
            fill="#fff"
            d="m40.3,30.97c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m48.05,29.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m55.74,27.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m63.35,25.53c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m70.91,23.53c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m78.47,21.66c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m85.98,20.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m93.49,18.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m101.03,18.26c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="107.9" cy="18.46" r=".81" />
          <path
            fill="#fff"
            d="m116.38,19.92c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m123.88,22.37c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m131.16,25.49c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m138.34,28.81c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m145.61,31.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m153.05,34.15c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m160.68,35.61c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m168.42,35.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m176.13,35.03c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m183.69,33.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="190.2" cy="30.8" r=".81" />
          <path
            fill="#fff"
            d="m198.13,28.53c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m205.12,26.34c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="211.34" cy="24.23" r=".81" />
          <path
            fill="#fff"
            d="m219.31,22.33c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m226.7,20.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m234.35,19.68c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m242.19,19.09c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m250.1,19.01c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m257.93,19.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="264.77" cy="19.99" r=".81" />
          <path
            fill="#fff"
            d="m272.92,21.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m279.98,22.39c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="285.94" cy="23.71" r=".81" />
          <path
            fill="#fff"
            d="m293.52,25.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m300.39,26.54c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m307.56,28.42c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m315.06,30.54c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m322.48,33.29c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m4.38,32.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m12.28,33.26c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m20.19,33.3c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m28.1,32.84c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m35.97,31.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="42.99" cy="30.69" r=".81" />
          <path
            fill="#fff"
            d="m51.55,29.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m59.25,27.32c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m66.89,25.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m74.48,23.49c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m82.08,21.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m89.64,20.27c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m97.2,19.25c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="103.97" cy="18.79" r=".81" />
          <path
            fill="#fff"
            d="m112.48,19.13c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m120.14,20.68c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m127.63,23.18c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m134.92,26.29c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m142.11,29.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m149.42,32.49c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m156.89,34.77c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m164.55,36.12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m172.31,36.33c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m180.03,35.3c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m187.57,33.27c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m194.87,30.83c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m201.96,28.36c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m208.94,25.95c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m215.95,23.61c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m223.11,21.51c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="229.68" cy="19.79" r=".81" />
          <path
            fill="#fff"
            d="m238.14,18.55c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m245.98,17.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m253.89,17.82c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m261.72,18.24c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m269.38,19.02c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m276.73,20.32c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m283.81,21.87c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m290.61,23.48c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m297.42,25.11c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m304.31,26.86c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m311.48,28.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m318.96,31.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m.06,31.92c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m7.92,32.76c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m15.82,33.02c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="22.93" cy="33.03" r=".81" />
          <path
            fill="#fff"
            d="m31.65,32.55c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m39.52,31.64c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m47.35,30.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m55.11,28.86c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m62.83,27.12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m70.49,25.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m78.11,23.44c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="84.94" cy="21.78" r=".81" />
          <path
            fill="#fff"
            d="m93.36,20.46c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m100.97,19.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m108.6,19.31c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m116.31,19.8c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="123.16" cy="21.44" r=".81" />
          <path
            fill="#fff"
            d="m131.44,23.98c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m138.73,27.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m145.94,30.36c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m153.28,33.2c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m160.79,35.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m168.49,36.62c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m176.27,36.71c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m183.98,35.57c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m191.51,33.43c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m198.79,30.85c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m205.86,28.19c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m212.82,25.55c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="219.01" cy="22.99" r=".81" />
          <path
            fill="#fff"
            d="m226.96,20.68c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m234.34,18.78c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="241.17" cy="17.41" r=".81" />
          <path
            fill="#fff"
            d="m249.82,16.69c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m257.73,16.62c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m265.57,17.12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m273.24,18.04c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m280.6,19.55c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="286.89" cy="21.34" r=".81" />
          <path
            fill="#fff"
            d="m294.54,23.24c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="300.57" cy="25.16" r=".81" />
          <circle fill="#fff" cx="307.49" cy="27.18" r=".81" />
          <path
            fill="#fff"
            d="m315.45,29.46c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m322.91,31.78c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m3.66,31.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m11.53,32.17c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="18.63" cy="32.38" r=".81" />
          <path
            fill="#fff"
            d="m27.35,32.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m35.26,31.85c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m43.13,30.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m50.97,29.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="57.93" cy="28.21" r=".81" />
          <path
            fill="#fff"
            d="m66.47,26.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m74.15,24.74c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m81.81,23c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m89.48,21.44c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m97.14,20.26c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m104.81,19.55c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m112.48,19.43c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m120.2,20.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m127.85,21.8c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m135.32,24.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m142.61,27.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m149.84,30.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m157.2,33.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m164.76,35.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m172.49,36.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m180.29,36.69c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m188,35.44c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m195.51,33.19c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m202.77,30.47c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m209.82,27.61c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m216.76,24.75c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="222.93" cy="21.97" r=".81" />
          <path
            fill="#fff"
            d="m230.88,19.45c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m238.26,17.37c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m245.9,15.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m253.74,15.09c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m261.64,15.03c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m269.48,15.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m277.16,16.66c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m284.54,18.37c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m291.66,20.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m298.53,22.6c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m305.41,24.8c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m312.34,27.09c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="318.68" cy="29.57" r=".81" />
          <path
            fill="#fff"
            d="m7.33,30.42c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m15.21,31.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m23.12,31.32c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m31.04,31.24c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m38.95,30.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="46.01" cy="29.81" r=".81" />
          <path
            fill="#fff"
            d="m54.66,28.62c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m62.44,27.13c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m70.18,25.49c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m77.88,23.78c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m85.58,22.13c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m93.29,20.67c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m101,19.63c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m108.72,19.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m116.43,19.13c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="123.36" cy="19.9" r=".81" />
          <path
            fill="#fff"
            d="m131.81,21.74c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m139.27,24.36c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="145.75" cy="27.47" r=".81" />
          <path
            fill="#fff"
            d="m153.81,30.66c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m161.2,33.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m168.79,35.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="175.75" cy="36.4" r=".81" />
          <path
            fill="#fff"
            d="m184.38,36.25c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m192.09,34.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m199.59,32.53c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="206.01" cy="29.66" r=".81" />
          <path
            fill="#fff"
            d="m213.85,26.61c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m220.78,23.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m227.75,20.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m234.87,17.8c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m242.24,15.54c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m249.88,13.92c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m257.72,13.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m265.63,13c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m273.47,13.66c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m281.15,14.86c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="287.73" cy="16.77" r=".81" />
          <path
            fill="#fff"
            d="m295.68,19.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m302.59,21.53c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m309.5,24.02c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m316.46,26.58c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m3.21,28.24c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m11.08,29.01c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m18.97,29.7c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="26.07" cy="29.8" r=".81" />
          <path
            fill="#fff"
            d="m34.8,29.69c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m42.71,29.15c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m50.59,28.23c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m58.43,27.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m66.21,25.61c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m73.97,24.01c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m81.7,22.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m89.42,20.81c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m97.17,19.46c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m104.94,18.55c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m112.7,18.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m120.46,18.37c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m128.22,19.29c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m135.85,21.22c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m143.29,23.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m150.59,27c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="157.05" cy="30.15" r=".81" />
          <path
            fill="#fff"
            d="m165.28,32.8c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="172.1" cy="34.72" r=".81" />
          <path
            fill="#fff"
            d="m180.71,35.63c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m188.55,35.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m196.26,33.87c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m203.74,31.42c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m210.95,28.4c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m217.96,25.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m224.87,21.85c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m231.83,18.63c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m238.95,15.7c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m246.31,13.26c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="253.13" cy="11.5" r=".81" />
          <path
            fill="#fff"
            d="m261.78,10.58c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m269.69,10.53c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m277.53,11.26c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m285.23,12.61c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m292.63,14.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m299.79,17.26c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m306.73,20.02c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m313.68,22.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m320.66,25.61c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m7.03,26.37c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m14.92,27.09c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m22.81,27.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m30.73,27.77c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m38.65,27.62c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m46.56,27.06c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m54.44,26.14c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m62.28,24.98c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m70.08,23.56c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m77.85,22.02c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m85.6,20.45c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m93.36,18.97c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m101.14,17.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m108.96,16.95c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m116.77,16.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m124.57,17.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m132.35,18.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m139.98,20.19c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m147.41,22.9c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m154.7,26.01c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m161.99,29.13c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="168.64" cy="31.72" r=".81" />
          <path
            fill="#fff"
            d="m177.11,33.54c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m184.94,34.34c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m192.8,33.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m200.52,32.35c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m207.98,29.79c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m215.17,26.63c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m222.16,23.19c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m229.05,19.66c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m235.99,16.22c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m243.1,13.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m250.46,10.46c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m258.09,8.58c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m265.93,7.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m273.84,7.54c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="280.87" cy="8.35" r=".81" />
          <path
            fill="#fff"
            d="m289.38,9.84c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m296.8,12.16c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m303.98,14.94c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m310.96,17.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m317.94,21.04c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m3.11,22.66c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m10.95,23.86c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m18.85,24.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m26.75,25.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m34.67,25.1c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m42.6,24.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m50.5,24.33c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m58.38,23.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="65.42" cy="22.27" r=".81" />
          <path
            fill="#fff"
            d="m74.03,20.88c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m81.81,19.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m89.59,17.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m97.38,16.49c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="104.4" cy="15.35" r=".81" />
          <path
            fill="#fff"
            d="m113.07,14.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m120.94,14.64c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m128.78,15.19c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m136.58,16.39c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m144.2,18.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m151.62,21.27c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m158.91,24.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m166.21,27.47c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m173.7,29.99c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m181.4,31.72c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m189.27,32.41c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m197.15,31.89c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m204.87,30.18c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m212.32,27.52c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m219.48,24.22c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m226.45,20.58c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m233.32,16.83c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="239.44" cy="13.16" r=".81" />
          <path
            fill="#fff"
            d="m247.35,9.82c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m254.7,7.02c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m262.33,5.01c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m270.17,3.95c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m278.08,3.91c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m285.92,4.8c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="292.83" cy="6.43" r=".81" />
          <path
            fill="#fff"
            d="m301.07,8.96c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m308.26,11.98c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m315.28,15.31c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="321.49" cy="18.66" r=".81" />
          <path
            fill="#fff"
            d="m6.74,19.66c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m14.59,20.83c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m22.5,21.43c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m30.41,21.96c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m38.34,21.9c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="45.45" cy="21.68" r=".81" />
          <path
            fill="#fff"
            d="m54.17,21.08c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m62.04,20.15c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="69.08" cy="19.03" r=".81" />
          <path
            fill="#fff"
            d="m77.71,17.68c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="84.7" cy="16.23" r=".81" />
          <path
            fill="#fff"
            d="m93.31,14.8c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m101.13,13.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m108.99,12.46c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m116.9,11.96c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m124.83,12.04c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m132.71,12.76c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="139.71" cy="14.1" r=".81" />
          <path
            fill="#fff"
            d="m148.13,16.33c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m155.54,19.12c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m162.84,22.23c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m170.16,25.28c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m177.68,27.74c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m185.42,29.38c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m193.32,29.96c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m201.22,29.32c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m208.94,27.49c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m216.37,24.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m223.52,21.29c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m230.46,17.45c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m237.32,13.47c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m244.23,9.59c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m251.32,6.04c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="257.86" cy="3.07" r=".81" />
          <path
            fill="#fff"
            d="m266.3.92c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m274.13-.2c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m282.04-.24c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m289.89.73c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m297.61,2.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m305.05,5.23c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <path
            fill="#fff"
            d="m312.26,8.5c0,.45-.36.81-.81.81s-.81-.36-.81-.81.36-.81.81-.81.81.36.81.81Z"
          />
          <circle fill="#fff" cx="318.51" cy="12.12" r=".81" />
        </g>
      </g>
    </svg>
  );
};
