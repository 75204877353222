import React from "react";
import { FC } from "@laba/react-common";
import { OdontogramIconProps } from "components/icons/OdontogramIcons/utils";
import { NexupColor } from "model/nexupColor";

export const OdontogramDefaultDiagnosticIcon: FC<
  OdontogramIconProps
> = props => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill={props.iconColor ?? NexupColor.Black}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 5.5L4.5 19.5H20.5L12.5 5.5ZM12.5 9.53113L7.94636 17.5H17.0536L12.5 9.53113Z"
      />
    </svg>
  );
};
