import React from "react";
import { FC } from "@laba/react-common";
import { Create } from "components/generic/Create/Create";
import { ResourceType } from "@laba/nexup-api";
import {
  ProductForm,
  productJsonToModel,
  productTransform
} from "./ProductForm";

export const ProductCreate: FC = () => {
  return (
    <Create
      resourceTransform={productTransform}
      resource={ResourceType.Product}
      jsonToModel={productJsonToModel}
    >
      <ProductForm />
    </Create>
  );
};
