import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  getModelReferenceId,
  KnownCodeSystemSystem,
  MedicalRequestDefinition,
  MedicalRequestDefinitionFieldType,
  MedicalRequestDefinitionKey,
  ModelReference,
  MRDFieldPropertyBase,
  Organization,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { FormDataConsumer } from "components/generic/FormDataConsumer/FormDataConsumer";
import { MedicalRequestDefinitionFieldFields } from "components/pages/NexupAdmin/resources/medicalRequestDefinition/MedicalRequestDefinitionFieldFields";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";

export const tk = tkCP.adminPage[ResourceType.MedicalRequestDefinition];
export const MedicalRequestDefinitionFieldsCompleteContent: FC = () => {
  const { t } = useI18n();
  const organization = useWatchResourceForm<ModelReference<Organization>>(
    MedicalRequestDefinitionKey.organization
  );
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organizationId =
    getModelReferenceId(organization) || workspaceOrganizationId;
  const medicationCharacteristicCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicationCharacteristic,
    organizationId
  );
  const requestAsNeededEventCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicalRequestAsNeededEvent,
    organizationId
  );
  const routeCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicationRoute,
    organizationId
  );
  const frequencyTypeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicalRequestFrequencyType,
    organizationId
  );
  const medicalDeviceTagSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicalDeviceTag,
    organizationId
  );
  const procedureReportCategorySystem = useCodeSystemGroup(
    KnownCodeSystemSystem.ProcedureReportCategory,
    organizationId
  );
  const unitSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicationUnit,
    organizationId
  );
  const systemSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.CodeSystemSystem,
    organizationId
  );

  return (
    <ArrayInput
      source={MedicalRequestDefinitionKey.fields}
      label={t(tk.fields.fields)}
    >
      <FormDataConsumer<
        MedicalRequestDefinition,
        MRDFieldPropertyBase<MedicalRequestDefinitionFieldType>
      >>
        {({ getSource: getFieldSource, scopedFormData: field }) => {
          return (
            <MedicalRequestDefinitionFieldFields
              fieldType={field?.type}
              systemSystem={systemSystem}
              unitSystem={unitSystem}
              procedureReportCategorySystem={procedureReportCategorySystem}
              routeCodeSystem={routeCodeSystem}
              requestAsNeededEventCodeSystem={requestAsNeededEventCodeSystem}
              medicationCharacteristicCodeSystem={
                medicationCharacteristicCodeSystem
              }
              medicalDeviceTagSystem={medicalDeviceTagSystem}
              frequencyTypeSystem={frequencyTypeSystem}
              getSource={getFieldSource}
              withProps
            />
          );
        }}
      </FormDataConsumer>
    </ArrayInput>
  );
};
