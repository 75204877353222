import { FC } from "@laba/react-common";
import React from "react";
import QRCode from "react-qr-code";

export interface NexupQRProps {
  className?: string;
  qr: string;
  size?: number;
}

export const NexupQR: FC<NexupQRProps> = ({ qr, size = 128, className }) => {
  return <QRCode value={qr} size={size} className={className} />;
};
