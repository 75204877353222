import React from "react";
import arrayMutators from "final-form-arrays";
import { ReactElement } from "components/types";
import { AnyObject, Form as FinalForm, FormProps } from "react-final-form";
import { produce } from "immer";

interface FinalFormProps<T> extends FormProps<T> {
  noValidate?: boolean;
  postProcessValues?: (model: T) => T;
  id?: string;
}
export type FinalFormAnyObject = AnyObject;

export const VirtualFormField = "virtualField";

const removeVirtualField = <T,>(model: T) => {
  return produce(model, draft => {
    // @ts-expect-error Indexing dynamic fields not part of the FormState type
    draft[VirtualFormField] = undefined;
  });
};

export const Form = <T,>({
  postProcessValues,
  onSubmit,
  children,
  className,
  initialValues,
  mutators,
  noValidate = true,
  validate,
  validateOnBlur,
  id,
  initialValuesEqual
}: FinalFormProps<T>): ReactElement => {
  const wrappedOnSubmit: typeof onSubmit = (model, ...args) => {
    const modelWithoutVirtual = removeVirtualField(model);
    if (postProcessValues !== undefined) {
      return onSubmit(postProcessValues(modelWithoutVirtual), ...args);
    }

    return onSubmit(modelWithoutVirtual, ...args);
  };
  return (
    <FinalForm<T>
      initialValues={initialValues}
      initialValuesEqual={initialValuesEqual}
      mutators={mutators ?? { ...arrayMutators }}
      onSubmit={wrappedOnSubmit}
      validate={validate}
      validateOnBlur={validateOnBlur}
    >
      {props => (
        <form
          onSubmit={props.handleSubmit}
          className={className}
          noValidate={noValidate}
          id={id}
        >
          {children}
        </form>
      )}
    </FinalForm>
  );
};
