import React from "react";
import {
  FC,
  getClassName,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant,
  useTheme
} from "@laba/react-common";
import { isEmpty } from "lodash-es";
import { TitleContentData } from "components/text/TitleContentData/TitleContentData";
import { Indicator } from "components/card/LocationCard/LocationCardIndicators/Indicator/Indicator";
import { CircleIcon } from "components/icons";
import { getThemeStyleColor } from "model/themeUtils";
import { useLocationCardAddressAndAvailabilityStyles } from "./useLocationCardAddressAndAvailabilityStyles";

interface LocationCardAdressAndAvailabilityProps {
  className?: string;
  fullAddress?: string;
  availability?: string;
  schedule?: string[];
  addressTitle?: string;
  active: boolean;
  statusText: string;
}

const getActiveColor = (theme: Theme, active: boolean): string => {
  if (active) {
    return getThemeStyleColor(
      theme,
      StyleVariant.Success,
      ThemeStyleColorVariant.Main
    );
  }
  return getThemeStyleColor(
    theme,
    StyleVariant.Primary,
    ThemeStyleColorVariant.GrayDark
  );
};

export const LocationCardAddressAndAvailability: FC<
  LocationCardAdressAndAvailabilityProps
> = ({
  className,
  fullAddress,
  availability,
  addressTitle,
  active,
  statusText,
  schedule = []
}) => {
  const classes = useLocationCardAddressAndAvailabilityStyles();
  const theme = useTheme<Theme>();

  if (!fullAddress && !availability) {
    return null;
  }

  const getAdressTitle = () => (addressTitle ? `${addressTitle}: ` : " ");

  const activeColor = getActiveColor(theme, active);

  const hasSchedule = !isEmpty(schedule);

  return (
    <div className={getClassName(className, classes.root)}>
      <div className={classes.adressWrapper}>
        <p className={classes.adressTitle}>
          {getAdressTitle()}
          <span className={classes.addressText} title={fullAddress}>
            {fullAddress}
          </span>
        </p>
      </div>
      {hasSchedule && (
        <div className={classes.scheduleContainer}>
          {schedule.map((s, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className={classes.textContainer} key={index}>
              {index === 0 && (
                <Indicator
                  className={classes.indicator}
                  Icon={CircleIcon}
                  text={statusText}
                  iconColor={activeColor}
                />
              )}
              <TitleContentData
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                titleVariant={TextVariant.Body2}
                principalTextVariant={TextVariant.Body2}
                textList={[{ principalText: s }]}
                fullWidth
              />
            </div>
          ))}
        </div>
      )}
      {availability && (
        <p className={classes.availabilityText} title={availability}>
          {availability}
        </p>
      )}
    </div>
  );
};
