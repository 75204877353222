import {
  StyleVariant,
  TextVariant,
  createStyle,
  flexColumnMixin,
  paddingMixin
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

export const usePopupMenuDropdownStyles = createStyle(
  (theme: Theme) => ({
    popupContent: {
      ...flexColumnMixin({ gap: 16 })
    },
    searchbarContainer: {
      ...paddingMixin({ leftRight: 24, top: 24 })
    },
    subcategory: {
      ...themeTextMixin(theme, TextVariant.Body2),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    noCategoriesText: {
      ...paddingMixin({ leftRight: 24, top: 16, bottom: 24 }),
      ...themeTextMixin(theme, TextVariant.Body2, 2),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "PopupMenuDropdown"
);
