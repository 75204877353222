import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  flexItemMixin,
  flexRowMixin,
  pxToRem,
  Theme
} from "@laba/react-common";
import { gapBetweenFiltersHeaderComponents } from "./gapBetweenFiltersHeaderComponents";

export const useFiltersHeaderStyle = createStyle(
  (_theme: Theme) => ({
    container: {
      ...flexColumnMixin({ gap: gapBetweenFiltersHeaderComponents }),
      width: "100%"
    },
    row: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        gap: gapBetweenFiltersHeaderComponents
      })
    },
    filtersButton: {
      width: pxToRem(144)
    },
    bottomFiltersContainer: {
      ...flexItemMixin({ grow: 1, shrink: 1, basis: "0%" }),
      ...flexColumnMixin({
        gap: gapBetweenFiltersHeaderComponents
      })
    }
  }),
  "FiltersHeader"
);

export const useFiltersHeaderInputsGrowStyle = createStyle(
  (_theme: Theme) => ({
    grow1: { ...flexItemMixin({ grow: 1, shrink: 1, basis: "0%" }) },
    grow2: { ...flexItemMixin({ grow: 2, shrink: 1, basis: "0%" }) },
    grow3: { ...flexItemMixin({ grow: 3, shrink: 1, basis: "0%" }) },
    grow4: { ...flexItemMixin({ grow: 4, shrink: 1, basis: "0%" }) },
    grow5: { ...flexItemMixin({ grow: 5, shrink: 1, basis: "0%" }) }
  }),
  "FiltersHeaderInputsGrow"
);
