import React from "react";
import { FC, useI18n } from "@laba/react-common";
import { AppointmentDefinitionKey, ResourceType } from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { PhoneConfigInput } from "components/generic/PhoneConfigInput/PhoneConfigInput";

export const tk = tkCP.adminPage[ResourceType.AppointmentDefinition];
export const AppointmentDefinitionPhoneContent: FC = () => {
  const { t } = useI18n();
  return (
    <PhoneConfigInput
      source={AppointmentDefinitionKey.phoneConfig}
      label={t(tk.fields.phoneConfig)}
    />
  );
};
