import { Theme } from "model/theme";
import {
  createStyle,
  destyledListMixin,
  flexColumnMixin
} from "@laba/react-common";

export interface InfiniteListStyleProps {
  gap: number;
}

export const useInfiniteListStyle = createStyle(
  (_theme: Theme) => ({
    list: (props: InfiniteListStyleProps) => ({
      ...destyledListMixin(),
      ...flexColumnMixin({ gap: props.gap })
    })
  }),
  "InfiniteList"
);
