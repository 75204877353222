export enum HttpMethod {
  POST = "POST",
  GET = "GET",
  PUT = "PUT"
}

export enum HttpCode {
  OK = 200,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  InternalServerError = 500
}
