import { ApiRequestResponse } from "request/types";
import { VideoAppointmentEncounter } from "model/resource/entities/encounter/appointmentEncounter";
import { getFrontPatientPortalApiUrl, request } from "request/nexupRequest";
import { HttpMethod } from "@laba/ts-common";
import { AppointmentWithExtraData } from "model/resource";
import { AppointmentStatusAction } from "api/appointment";
import { urlJoin } from "url-join-ts";

const appointmentBasePath = "/appointment";
const appointmentWithExtraDataBasePath = "/extra";

export interface AppointmentWithExtraDataByHashQueryParams {
  withEncounter?: boolean;
  withLocation?: boolean;
  withPatient?: boolean;
  withPayer?: boolean;
  withPractitioner?: boolean;
  withSchedule?: boolean;
  withScheduleOrganization?: boolean;
}

export interface AppointmentStatusUpdate {
  action?: AppointmentStatusAction;
  cancellationReasonText?: string;
}

export const attendPatientAppointment = (
  hash: string // HIS-6871: change param to receive id: ModelId
): Promise<ApiRequestResponse<VideoAppointmentEncounter>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontPatientPortalApiUrl(`${appointmentBasePath}/attend`),
    data: { hash }
  });

export const getPatientPortalAppointmentWithExtraDataByHash = (
  hash: string,
  params?: AppointmentWithExtraDataByHashQueryParams
): Promise<ApiRequestResponse<AppointmentWithExtraData>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontPatientPortalApiUrl(
      urlJoin(appointmentBasePath, appointmentWithExtraDataBasePath, hash)
    ),
    params
  });

export const updatePatientPortalAppointmentStatus = (
  id: string,
  data: AppointmentStatusUpdate
): Promise<ApiRequestResponse<AppointmentWithExtraData>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontPatientPortalApiUrl(
      `${appointmentBasePath}/${id}/status-update`
    ),
    data
  });
