import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  getModelReferenceId,
  ModelReference,
  Organization,
  Product,
  ProductKey,
  PublicationStatus,
  Questionnaire,
  QuestionnaireConsumptionConfigItemKey,
  QuestionnaireConsumptionConfigItemProductKey,
  QuestionnaireEnableWhenBehavior,
  QuestionnaireItemEnableWhen,
  QuestionnaireKey,
  ResourceType
} from "@laba/nexup-api";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { tkCP } from "translation/i18n";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { productOptionText } from "components/generic/ReferenceResourceInput/optionText";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import { NumberInput } from "components/generic/NumberInput/TextInput";
import { FormDataConsumer } from "components/generic/FormDataConsumer/FormDataConsumer";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { QuestionnaireItemEnableWhenComponent } from "../QuestionnaireItemEnableWhenComponent/QuestionnaireItemEnableWhenComponent";

export const tk = tkCP.adminPage[ResourceType.Questionnaire];

export const QuestionnaireConsumptionConfigComponent: FC = () => {
  const { t } = useI18n();
  const organization = useWatchResourceForm<ModelReference<Organization>>(
    QuestionnaireKey.organization
  );
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);

  const organizationId =
    getModelReferenceId(organization) || workspaceOrganizationId;
  return (
    <>
      <BooleanInput
        source={QuestionnaireKey.consumptionsConfig.ignorePayer}
        label={t(tk.fields.consumptionConfig.ignorePayer)}
      />
      <ArrayInput
        source={QuestionnaireKey.consumptionsConfig.consumptionConfig}
        label={t(tk.fields.consumptionConfig.configList)}
      >
        <ArrayInput
          source={QuestionnaireConsumptionConfigItemKey.enableWhen.thisKey}
          label={t(tk.fields.consumptionConfig.enableWhen)}
        >
          <FormDataConsumer<Questionnaire, QuestionnaireItemEnableWhen>>
            {({
              getSource: getItemEnableWhenSource,
              formData,
              scopedFormData
            }) => {
              return (
                <QuestionnaireItemEnableWhenComponent
                  getSource={getItemEnableWhenSource}
                  questionnaire={formData}
                  qItemEnableWhen={scopedFormData}
                />
              );
            }}
          </FormDataConsumer>
        </ArrayInput>
        <EnumSelectorInput
          source={QuestionnaireConsumptionConfigItemKey.enableBehavior}
          label={t(tk.fields.consumptionConfig.enableBehavior)}
          enumValue={QuestionnaireEnableWhenBehavior}
        />
        <ArrayInput
          source={QuestionnaireConsumptionConfigItemKey.products}
          label={t(tk.fields.consumptionConfig.productList.title)}
        >
          <ReferenceResourceInput<Product>
            source={QuestionnaireConsumptionConfigItemProductKey.product}
            label={t(tk.fields.consumptionConfig.productList.product)}
            resourceType={ResourceType.Product}
            optionTextKey={productOptionText}
            required
            extraFilters={{
              [ProductKey.organization]: organizationId,
              [ProductKey.status]: PublicationStatus.Active
            }}
          />
          <NumberInput
            source={QuestionnaireConsumptionConfigItemProductKey.quantity}
            label={t(tk.fields.consumptionConfig.productList.quantity)}
            min={0}
            step={1}
          />
        </ArrayInput>
      </ArrayInput>
    </>
  );
};
