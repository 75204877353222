import React from "react";
import { FC } from "@laba/react-common";
import { Create } from "components/generic/Create/Create";
import { ResourceType } from "@laba/nexup-api";
import {
  ProcedureReportDefinitionForm,
  procedureReportDefinitionJsonToModel,
  procedureReportDefinitionTransform
} from "components/pages/NexupAdmin/resources/procedureReportDefinition/ProcedureReportDefinitionForm";

export const ProcedureReportDefinitionCreate: FC = () => {
  return (
    <Create
      resourceTransform={procedureReportDefinitionTransform}
      resource={ResourceType.ProcedureReportDefinition}
      jsonToModel={procedureReportDefinitionJsonToModel}
    >
      <ProcedureReportDefinitionForm />
    </Create>
  );
};
