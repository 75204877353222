import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { getKeyObj, HttpMethod, ModelId } from "@laba/ts-common";
import {
  Account,
  AccountStatus,
  AccountType
} from "model/resource/finance/account/account";
import { createHydratedMock } from "ts-auto-mock";

const AccountPath = "/account";

export interface AccountQueryParams {
  withPayer?: boolean;
  withPatient?: boolean;
}

export interface AccountListQueryParams
  extends AccountQueryParams,
    ListQueryParams {
  content?: string;
  organization?: ModelId[];
  patient?: ModelId[];
  payer?: ModelId[];
  status?: AccountStatus;
  type?: AccountType[];
}

export const AccountListQueryParamsKey = getKeyObj(
  createHydratedMock<AccountListQueryParams>()
);

export const getAccount = (
  id: ModelId,
  params?: AccountQueryParams
): Promise<ApiRequestResponse<Account>> => {
  return request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${AccountPath}/${id}`),
    params
  });
};

export const getAccountList = (
  params?: AccountListQueryParams
): Promise<ApiPageRequestResponse<Account>> => {
  return request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${AccountPath}`),
    params
  });
};

export const createAccount = (
  data: Account
): Promise<ApiRequestResponse<Account>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(`${AccountPath}`),
    data
  });

export const editAccount = (
  data: Account
): Promise<ApiRequestResponse<Account>> => {
  return request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${AccountPath}/${data.id}`),
    data
  });
};
