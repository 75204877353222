import { ResourceModel, ResourceType } from "model/primitives/resourceModel";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { Practitioner } from "model/resource/person/practitioner/practitioner";
import { Organization } from "model/resource/entities/organization/organization";
import { Identifier } from "model/primitives/identifier";
import { Patient as PatientModel } from "model/resource/person/patient/patient";
import { getKeyObj, KeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";

export enum AccountStatus {
  Active = "active",
  Inactive = "inactive"
}

export enum AccountType {
  Patient = "Patient",
  Payer = "Payer",
  PrivatePatient = "PrivatePatient"
}

export const PrivateAccount = "Paciente Particular";

export interface Account extends ResourceModel<ResourceType.Account> {
  originalPractitioner?: ModelReference<Practitioner>;
  lastEditor?: ModelReference<Practitioner>;
  status?: AccountStatus;
  organization?: ModelReference<Organization>;
  identifier?: Identifier[];
  payer?: ModelReference<Organization>;
  patient?: ModelReference<PatientModel>;
  type?: AccountType;
  name?: string;
}

export const AccountKey = getKeyObj<Account>(createHydratedMock<Account>());

interface CreateBaseAccountProps {
  type?: AccountType;
  organization?: Organization;
  name?: string;
  payer?: Organization;
}

export const createBaseAccount = ({
  name,
  organization,
  type,
  payer
}: CreateBaseAccountProps): Account => ({
  resourceType: ResourceType.Account,
  status: AccountStatus.Active,
  name,
  organization,
  payer,
  type
});

export const AccountParamsKey: KeyObj<Account> = getKeyObj(
  createHydratedMock<Account>()
);

export const isAccountPrivateAccount = (account?: Account): boolean => {
  return account?.name === PrivateAccount;
};
