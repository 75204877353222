import { findLastIndex, first, isEmpty, last } from "lodash-es";
import { notUndefined, Optional } from "@laba/ts-common";

const replaceOccurrenceChar = (
  inputString: string,
  char: string,
  index: number
): string => {
  const newString = `${inputString.substr(0, index)}*${inputString.substr(
    index + 1
  )}`;
  const newString2 = newString.replace(".", "").replace(",", "");
  return newString2.replace("*", char);
};

const removeExtraOccurrencesIfNecessaryFromStringNumber = (
  inputString: string
): string => {
  const lastIndexOfDot = findLastIndex(inputString, v => v === ".");
  const lastIndexOfComma = findLastIndex(inputString, v => v === ",");
  if (lastIndexOfDot === -1 && lastIndexOfComma === -1) return inputString;
  if (lastIndexOfComma > lastIndexOfDot) {
    return replaceOccurrenceChar(inputString, ",", lastIndexOfComma);
  }
  return replaceOccurrenceChar(inputString, ".", lastIndexOfDot);
};

type DecimalSeparator = "." | ",";
const getStringNumberDecimalCharacterSeparator = (
  stringNumber: string
): Optional<DecimalSeparator> => {
  if (stringNumber.includes(".")) return ".";
  if (stringNumber.includes(",")) return ",";
  return undefined;
};

const fixStringNumberDecimalPrecision = (
  stringNumber: string,
  decimalDigits?: number
): string => {
  const separator = getStringNumberDecimalCharacterSeparator(stringNumber);
  if (!separator || !decimalDigits) return stringNumber;
  const splitNumber = stringNumber.split(separator);
  const decimalPart = last(splitNumber);
  if (!decimalPart) return stringNumber;
  const fixedDecimalPart = decimalPart.slice(0, decimalDigits);
  return [first(splitNumber), fixedDecimalPart]
    .filter(notUndefined)
    .join(separator);
};

const fixStringNumberRepeatingZeros = (stringNumber: string): string => {
  if (stringNumber.length <= 1) return stringNumber;
  const isNegative = stringNumber.startsWith("-");
  const sanitizedStringNumber = stringNumber.replace("-", "");
  if (!sanitizedStringNumber.startsWith("0")) {
    return stringNumber;
  }
  const stringNumberWithoutFirstCharacter = sanitizedStringNumber.slice(1);
  const sanitizedNumber =
    stringNumberWithoutFirstCharacter.startsWith(".") ||
    stringNumberWithoutFirstCharacter.startsWith(",") ||
    isEmpty(stringNumberWithoutFirstCharacter)
      ? sanitizedStringNumber
      : stringNumberWithoutFirstCharacter;
  return isNegative ? ["-", sanitizedNumber].join("") : sanitizedNumber;
};

export const numberValueToString = (
  quantityValue?: number
): Optional<string> => {
  return quantityValue?.toLocaleString(undefined, {
    useGrouping: false
  });
};

export const stringNumberToNumber = (text?: string): Optional<number> => {
  if (text === undefined) return undefined;
  const transformedValueWithoutRepeatedOccurrences =
    removeExtraOccurrencesIfNecessaryFromStringNumber(text);
  const transformedValue = transformedValueWithoutRepeatedOccurrences.replace(
    ",",
    "."
  );

  return Number(transformedValue);
};

export const sanitizeStringNumberWithDecimals = (
  stringNumber: string,
  decimalDigits?: number
): string => {
  const percentageStringNumber =
    removeExtraOccurrencesIfNecessaryFromStringNumber(stringNumber);
  const fixedDecimalDigitsPercentageStringNumber =
    fixStringNumberDecimalPrecision(percentageStringNumber, decimalDigits);
  return fixStringNumberRepeatingZeros(
    fixedDecimalDigitsPercentageStringNumber
  );
};
