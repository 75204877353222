import React from "react";
import {
  Children,
  FC,
  IconC,
  ReactElement,
  ReactElementOrNull
} from "@laba/react-common";
import {
  Autocomplete,
  AutocompleteOptionConfig
} from "components/autocomplete/Autocomplete/Autocomplete";
import { SearchableListInputStyle } from "components/inputs/SearchableListInput/SearchableListInputStyle";
import { SearchIcon } from "components/icons";
import { Noop } from "@laba/ts-common";
import { StyleVariant } from "model/themeVariant";
import { SearchableListSelectedOptions } from "components/inputs/SearchableListInput/SearchableListSelectedOptions/SearchableListSelectedOptions";
import { ErrorText } from "components/text";
import { AutocompleteOptionProps } from "components/autocomplete/Autocomplete/AutocompleteOption/AutocompleteOption";

export interface SearchableListInputProps<T> {
  onInputChange: (searchText: string) => void;
  inputValue?: string;
  selectedOptions?: AutocompleteOptionConfig<T>[];
  options: AutocompleteOptionConfig<T>[];
  onSelectOption: (option?: T) => void;
  onRemoveSelectedOption?: (option: T) => void;
  onBlur?: Noop;
  disableSearchBar?: boolean;
  showError?: boolean;
  errorText?: string;
  helperText?: string;
  showHelperOrErrorText?: boolean;
  noOptionsText: string;
  closeText?: string;
  clearText?: string;
  loadingText?: string;
  label?: string;
  openText?: string;
  fullWidth?: boolean;
  EndIcon?: IconC;
  endIconShouldNotRotate?: boolean;
  excludedValues?: T[];
  compareValues?: (v1: T, v2: T) => boolean;
  getOptionFromValue?: (v: T) => AutocompleteOptionConfig<T>;
  removeIconStyle?: StyleVariant;
  maxOptionsLimit?: number;
  showCustomOption?: boolean;
  onClickCustomClickableOption?: Noop;
  customOptionText?: string;
  isMobile?: boolean;
  children?: Children;
  getLowerComponent?: (
    index: number,
    option: AutocompleteOptionConfig<T>
  ) => ReactElementOrNull;
  AutocompleteOptionC?: FC<AutocompleteOptionProps<T>>;
  excludeValuesWithCompareValues?: boolean;
  filterOptions?: (options: T[]) => T[];
  moreCharsText?: string;
  customOptionOptionsSizeMaxLimit?: number;
}

export const SearchableListInput = <T,>({
  options,
  selectedOptions,
  errorText,
  helperText,
  onRemoveSelectedOption,
  noOptionsText,
  onSelectOption,
  disableSearchBar,
  inputValue,
  onInputChange,
  onBlur,
  compareValues,
  getOptionFromValue,
  excludedValues = [],
  showError = true,
  showHelperOrErrorText = true,
  fullWidth = true,
  closeText = "",
  openText = "",
  clearText = "",
  loadingText = "",
  label = "",
  EndIcon = SearchIcon,
  endIconShouldNotRotate = true,
  removeIconStyle = StyleVariant.BlackWhite,
  maxOptionsLimit,
  showCustomOption = false,
  customOptionText,
  isMobile = false,
  onClickCustomClickableOption,
  getLowerComponent,
  AutocompleteOptionC,
  children,
  excludeValuesWithCompareValues,
  filterOptions,
  moreCharsText,
  customOptionOptionsSizeMaxLimit
}: SearchableListInputProps<T>): ReactElement => {
  const hasError = showError && Boolean(errorText);
  const showableHelperText = (hasError ? errorText : helperText) || "";
  const classes = SearchableListInputStyle();
  return (
    <div className={classes.root}>
      <Autocomplete
        filterOptions={filterOptions}
        options={options}
        onChange={onSelectOption}
        noOptionsText={noOptionsText}
        closeText={closeText}
        clearText={clearText}
        loadingText={loadingText}
        openText={openText}
        disabled={disableSearchBar}
        inputValue={inputValue}
        onInputChange={onInputChange}
        fullWidth={fullWidth}
        EndIcon={EndIcon}
        endIconShouldNotRotate={endIconShouldNotRotate}
        onBlur={onBlur}
        label={label}
        excludedValues={excludedValues}
        compareValues={compareValues}
        getOptionFromValue={getOptionFromValue}
        showHelperOrErrorText={false}
        errorText={errorText}
        maxOptionsLimit={maxOptionsLimit}
        showCustomOption={showCustomOption}
        customOptionText={customOptionText}
        onClickCustomClickableOption={onClickCustomClickableOption}
        AutocompleteOptionC={AutocompleteOptionC}
        excludeValuesWithCompareValues={excludeValuesWithCompareValues}
        moreCharsText={moreCharsText}
        customOptionOptionsSizeMaxLimit={customOptionOptionsSizeMaxLimit}
      />
      {children ||
        (onRemoveSelectedOption && selectedOptions && (
          <SearchableListSelectedOptions
            fullWidth={fullWidth}
            selectedOptions={selectedOptions}
            onRemoveSelectedOption={onRemoveSelectedOption}
            removeIconStyle={removeIconStyle}
            isMobile={isMobile}
            getLowerComponent={getLowerComponent}
          />
        ))}
      <ErrorText
        error={showableHelperText}
        showError={showHelperOrErrorText}
        className={hasError ? classes.errorTextColor : classes.helperTextColor}
      />
    </div>
  );
};
