import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  FlexJustifyContent,
  pxToRem,
  StyleVariant,
  Theme
} from "@laba/react-common";
import { getThemeStyleColor } from "model/themeUtils";
import { ThemeStyleColorVariant } from "model/theme";

interface ArrowTabButtonProps {
  isActive: boolean;
}

export const useArrowTabButtonStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexColumnMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.Center
      }),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      ),
      width: pxToRem(24)
    },
    icon: (props: ArrowTabButtonProps) => ({
      color: props.isActive
        ? getThemeStyleColor(
            theme,
            StyleVariant.Primary,
            ThemeStyleColorVariant.Main
          )
        : getThemeStyleColor(
            theme,
            StyleVariant.Primary,
            ThemeStyleColorVariant.GrayDark
          )
    })
  }),
  "ArrowTabButton"
);
