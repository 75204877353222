import {
  FC,
  getClassName,
  TextVariant,
  ThemeStyleColorVariant
} from "@laba/react-common";
import React from "react";
import { isEmpty } from "lodash-es";
import { Noop, StyleVariant } from "@laba/ts-common";
import { OnClickUrlAction, UrlLink } from "components/link";
import { useGenericFieldWrapperTitleComponentStyles } from "./useGenericFieldWrapperTitleComponentStyles";

export enum FieldWrapperTitleComponentPosition {
  Top = "Top",
  Bottom = "Bottom"
}

export enum FieldWrapperTitleComponentType {
  Link = "Link",
  Text = "Text"
}

export interface GenericFieldWrapperTitleComponentProps {
  type?: FieldWrapperTitleComponentType;
  text?: string;
  onClick?: Noop;
  clickType?: OnClickUrlAction;
  disabled?: boolean;
  link?: string;
  hide?: boolean;
  textVariant?: TextVariant;
  colorVariant?: ThemeStyleColorVariant;
  styleVariant?: StyleVariant;
}

interface Props extends GenericFieldWrapperTitleComponentProps {
  className?: string;
}

const showComponent = (
  hideHeaderTitleComponent?: boolean,
  text?: string,
  url?: string
) => {
  return !hideHeaderTitleComponent && (!isEmpty(text) || !isEmpty(url));
};

export const GenericFieldWrapperTitleComponent: FC<Props> = ({
  hide = false,
  type = FieldWrapperTitleComponentType.Link,
  disabled = false,
  text,
  onClick,
  link,
  clickType = OnClickUrlAction.CustomClick,
  textVariant = TextVariant.Body2,
  colorVariant = ThemeStyleColorVariant.Main,
  styleVariant = StyleVariant.Danger,
  className
}) => {
  const classes = useGenericFieldWrapperTitleComponentStyles({
    textVariant,
    colorVariant,
    styleVariant
  });

  const showHeaderTitleComponent = showComponent(hide, text, link);

  if (!showHeaderTitleComponent) return null;

  switch (type) {
    case FieldWrapperTitleComponentType.Link:
      return (
        <UrlLink
          displayText={text}
          onClickUrlAction={clickType}
          onClick={onClick}
          className={className}
          disabled={disabled}
          url={link}
        />
      );
    case FieldWrapperTitleComponentType.Text:
      return <p className={getClassName(className, classes.text)}>{text}</p>;
    default:
      return null;
  }
};
