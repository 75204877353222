import {
  createStyle,
  FlexJustifyContent,
  flexRowMixin,
  pxToRem,
  StyleVariant,
  Theme
} from "@laba/react-common";
import { getThemeStyleColor } from "model/themeUtils";
import { ThemeStyleColorVariant } from "model/theme";

export const useContainerTabStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexRowMixin({
        justifyContent: FlexJustifyContent.FlexStart
      }),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Background
      ),
      position: "relative"
    },
    container: {
      ...flexRowMixin({
        justifyContent: FlexJustifyContent.FlexStart
      }),
      columnGap: pxToRem(1)
    },
    rightScrollButton: {
      height: "100%"
    },
    rightScrollContainer: {
      ...flexRowMixin({}),
      height: "100%",
      position: "absolute",
      right: 0
    },
    leftScrollButton: {
      height: "100%"
    },
    leftScrollContainer: {
      ...flexRowMixin({}),
      height: "100%",
      position: "absolute",
      left: 0
    },
    gapElement: {
      width: pxToRem(1),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Background
      )
    }
  }),
  "ContainerTab"
);
