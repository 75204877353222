import { ResourceType } from "model/primitives/resourceModel";
import {
  KnownMeasureObservationCode,
  ObservationCategory,
  ObservationStatus
} from "model/resource/medical/observation/observation";
import { KnownMeasurementGroupObservationCode } from "../observation";
import { MeasurementGroup } from "../measurementGroup";
import { KnownPatientMeasureUnits } from "../patientMeasure";

export const measurementGroupMockList: MeasurementGroup[] = [
  {
    resourceType: ResourceType.MeasurementGroup,
    patient: "65",
    practitioner: "1",
    type: KnownMeasurementGroupObservationCode.VitalSigns,
    lastEditDate: "2022-02-08T15:14:36.000-03",
    category: ObservationCategory.MeasurementGroup,
    hisCode: "3565865",
    status: ObservationStatus.Preliminary,
    originalPractitioner: "1",
    observation: "observation",
    closeDate: "2022-02-08T10:14:36.000-03",
    effectiveDateTime: "2022-02-08T10:14:36.000-03",
    definition: "1234",
    members: [
      {
        linkId: "1110",
        measurement: {
          resourceType: ResourceType.PatientMeasure,
          type: KnownMeasureObservationCode.Weight,
          status: ObservationStatus.Preliminary,
          value: {
            quantity: 78,
            unit: KnownPatientMeasureUnits.kg
          }
        }
      }
    ],
    components: [
      {
        linkId: "1111",
        type: "Temperature",
        value: {
          quantity: 36,
          unit: "°C"
        }
      },
      {
        linkId: "1112",
        type: "Sistole",
        value: {
          quantity: 120,
          unit: "mmHg"
        }
      },
      {
        linkId: "1113",
        type: "Diastole.",
        value: {
          quantity: 70,
          unit: "mmHg"
        }
      }
    ]
  },
  {
    resourceType: ResourceType.MeasurementGroup,
    patient: "65",
    practitioner: "1",
    type: KnownMeasurementGroupObservationCode.VitalSigns,
    lastEditDate: "2022-02-08T15:17:36.000-03",
    category: ObservationCategory.MeasurementGroup,
    hisCode: "3565865",
    status: ObservationStatus.Preliminary,
    originalPractitioner: "1",
    observation: "observation",
    closeDate: "2022-02-08T10:14:36.000-03",
    effectiveDateTime: "2022-02-08T16:14:36.000-03",
    definition: "1234",
    members: [
      {
        linkId: "1110",
        measurement: {
          resourceType: ResourceType.PatientMeasure,
          type: KnownMeasureObservationCode.Weight,
          status: ObservationStatus.Preliminary,
          value: {
            quantity: 78,
            unit: KnownPatientMeasureUnits.kg
          }
        }
      }
    ],
    components: [
      {
        linkId: "1111",
        type: "Temperature",
        value: {
          quantity: 38,
          unit: "°C"
        }
      },
      {
        linkId: "1112",
        type: "SIST.",
        value: {
          quantity: 120,
          unit: "mmHg"
        }
      },
      {
        linkId: "1113",
        type: "DIAS.",
        value: {
          quantity: 70,
          unit: "mmHg"
        }
      }
    ]
  }
];
