import React from "react";
import {
  getDefaultResourceTransform,
  ResourceForm
} from "components/generic/ResourceForm/ResourceForm";
import { ResourceType, Schedule } from "@laba/nexup-api";
import {
  getDefaultJsonToModel,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";
import { FC, useI18n } from "@laba/react-common";
import { tkCP } from "translation/i18n";
import { ScheduleMainContent } from "components/pages/NexupAdmin/resources/schedule/ScheduleMainContent";
import { ScheduleWindowContent } from "./ScheduleWindowContent";
import { ScheduleNotificationConfig } from "./ScheduleNotificationConfig";
import { ScheduleOnlineBookingContent } from "./ScheduleOnlineBookingContent";

export const scheduleJsonToModel: JsonToModel<Schedule> = getDefaultJsonToModel(
  draft => {
    draft.organization = undefined;
    draft.location = undefined;
    draft.performer = [];
    draft.practitioner = undefined;
    draft.originalPractitioner = undefined;
    draft.definition = undefined;
  }
);

export const scheduleTransform = getDefaultResourceTransform<Schedule>(
  ResourceType.Schedule
);

export const tk = tkCP.adminPage[ResourceType.Schedule];

export const ScheduleForm: FC = () => {
  const { t } = useI18n();

  return (
    <ResourceForm
      tabsConfig={[
        {
          content: <ScheduleMainContent />,
          label: t(tk.tabs.schedule)
        },
        {
          label: t(tk.tabs.scheduleOnlineBooking),
          content: <ScheduleOnlineBookingContent />
        },
        {
          label: t(tk.tabs.scheduleWindows),
          content: <ScheduleWindowContent />
        },
        {
          label: t(tk.tabs.scheduleNotificationConfig),
          content: <ScheduleNotificationConfig />
        }
      ]}
    />
  );
};
