import { FC } from "@laba/react-common";
import React from "react";
import { useSelectedProblemListStyles } from "components/inputs/ProblemInput/SelectedProblemsList/useSelectedProblemListStyles";
import { ProblemConfig } from "components/inputs/ProblemInput/ProblemInput";
import { ProblemItem } from "components/inputs/ProblemInput/ProblemItem/ProblemItem";
import { TagTypeVariant } from "components/tags/Tag/Tag";
import { Optional, Noop } from "@laba/ts-common";
import { sortBy } from "lodash-es";

export interface SelectedProblemListProps {
  title: string;
  getSelectedProblemTagText: (problem: ProblemConfig) => Optional<string>;
  getSelectedProblemTagType: (problem: ProblemConfig) => TagTypeVariant;
  problems: ProblemConfig[];
  onRemoveProblem: (problem: ProblemConfig) => void;
  onClickSelectedTagGetter: (problem: ProblemConfig) => Optional<Noop>;
}

export const SelectedProblemList: FC<SelectedProblemListProps> = ({
  problems,
  title,
  onRemoveProblem,
  onClickSelectedTagGetter,
  getSelectedProblemTagText,
  getSelectedProblemTagType
}) => {
  const classes = useSelectedProblemListStyles();
  const orderedProblems = sortBy(problems, "name");
  return (
    <div className={classes.root}>
      <h6 className={classes.title}>{title}</h6>
      <div>
        {orderedProblems.map(p => {
          return (
            <ProblemItem
              key={p.name}
              text={p.name}
              status={p.status}
              onClose={() => onRemoveProblem(p)}
              onClickTag={onClickSelectedTagGetter(p)}
              tagText={getSelectedProblemTagText(p)}
              tagType={getSelectedProblemTagType(p)}
            />
          );
        })}
      </div>
    </div>
  );
};
