import { sessionSlice } from "store/session/slice";
import { Event } from "store/store";
import {
  isLoggingIn,
  refreshTokenSelector,
  serverSelector
} from "store/session/selectors";
import {
  BackendError,
  getCurrentUser,
  loginRequest,
  ModelId,
  refreshTokenRequest,
  revokeTokenRequest
} from "@laba/nexup-api";
import { Server } from "models/server/server";
import { RequestFailureResponse, RequestFailureStatus } from "@laba/ts-common";
import {
  cleanWorkspaceData,
  onSelectOrganizationWorkspace,
  updateWorkspaceList
} from "store/workspace/events";

const { actions } = sessionSlice;

export const logout = (): Event => async (dispatch, getState) => {
  const refreshToken = refreshTokenSelector(getState());
  if (refreshToken !== undefined) {
    await revokeTokenRequest(refreshToken);
  }
  await dispatch(cleanWorkspaceData());
  await dispatch(actions.clean());
};

export const login =
  (
    username: string,
    password: string,
    server: Server,
    organizationId?: ModelId,
    localUrl?: string
  ): Event<{ result: boolean; error?: RequestFailureResponse<BackendError> }> =>
  async dispatch => {
    await dispatch(actions.initLogin({ server, localUrl }));

    const tokenResponse = await loginRequest(username, password);
    if (tokenResponse.failureStatus === RequestFailureStatus.Failure) {
      await dispatch(actions.loginError());
      return { result: false, error: tokenResponse };
    }
    await dispatch(actions.updateToken(tokenResponse.data));

    const userResponse = await getCurrentUser();
    if (userResponse.failureStatus === RequestFailureStatus.Failure) {
      await dispatch(actions.loginError());
      return { result: false, error: userResponse };
    }
    await dispatch(actions.updateUser({ user: userResponse.data }));

    await dispatch(updateWorkspaceList());
    await dispatch(onSelectOrganizationWorkspace(organizationId));

    await dispatch(actions.loginSuccess());
    return { result: true };
  };

export const refreshToken =
  (): Event<boolean> => async (dispatch, getState) => {
    const token = refreshTokenSelector(getState());
    if (isLoggingIn(getState()) || token === undefined) return false;
    await dispatch(actions.initLogin({ server: serverSelector(getState()) }));

    const tokenResponse = await refreshTokenRequest(token);
    if (tokenResponse.failureStatus === RequestFailureStatus.Success) {
      await dispatch(actions.updateToken(tokenResponse.data));
      await dispatch(actions.loginSuccess());
      return true;
    }

    const provisionalTokenResponse = await refreshTokenRequest(token, true);
    if (
      provisionalTokenResponse.failureStatus === RequestFailureStatus.Success
    ) {
      await dispatch(actions.updateToken(provisionalTokenResponse.data));
      await dispatch(actions.loginSuccess());
      return true;
    }

    await dispatch(logout());
    return false;
  };
