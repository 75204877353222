import {
  createStyle,
  FlexAlignItems,
  flexRowMixin,
  marginMixin,
  StyleVariant,
  TextVariant,
  Theme
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

export const useLinkEntriesStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center
      })
    },
    text: {
      ...themeTextMixin(theme, TextVariant.Body2, 1),
      color: getThemeStyleColor(theme, StyleVariant.Primary)
    },
    closeIcon: {
      ...marginMixin({ left: "auto" })
    }
  }),
  "LinkEntries"
);
