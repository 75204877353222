import { FC, useI18n } from "@laba/react-common";
import React from "react";
import { ResourceType, ScheduleParamsKey } from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { ScheduleNotificationConfigInput } from "components/generic/ScheduleNotificationConfigInput/ScheduleNotificationConfigInput";

export const tk = tkCP.adminPage[ResourceType.Schedule];

export const ScheduleNotificationConfig: FC = () => {
  const { t } = useI18n();

  return (
    <>
      <ScheduleNotificationConfigInput
        title={t(tk.fields.practitionerScheduleNotificationConfigInput)}
        source={ScheduleParamsKey.notificationConfig.practitioner.thisKey}
      />
      <ScheduleNotificationConfigInput
        title={t(tk.fields.patientScheduleNotificationConfigInput)}
        source={ScheduleParamsKey.notificationConfig.patient.thisKey}
      />
    </>
  );
};
