import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { ModelId } from "model/primitives/model/model";
import {
  Location,
  LocationCategory,
  LocationStatus,
  LocationType
} from "model/resource/entities/location/location";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { getKeyObj, HttpMethod, KeyObj } from "@laba/ts-common";
import { Bed } from "model/resource/entities/location/bed";
import { createHydratedMock } from "ts-auto-mock";
import { Code } from "model/resource/entities/codeSystem";

export enum LocationFilterOrder {
  Name = "name",
  OrderAndHierarchy = "orderAndHierarchy",
  CreationDate = "creationDate"
}

export interface LocationListQueryParams extends ListQueryParams {
  id?: ModelId[];
  organization?: ModelId;
  partOf?: ModelId;
  content?: string;
  status?: LocationStatus;
  physicalType?: LocationType[];
  category?: LocationCategory[];
  directoryType?: Code;
  hisCode?: string;
  order?: LocationFilterOrder;
}

export const LocationListQueryParamsKey: KeyObj<LocationListQueryParams> =
  getKeyObj(createHydratedMock<LocationListQueryParams>());

export const locationBasePath = "/location";

export const getLocationList = (
  params: LocationListQueryParams
): Promise<ApiPageRequestResponse<Location>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(locationBasePath),
    params
  });

export const getLocation = (
  id: ModelId
): Promise<ApiRequestResponse<Location>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${locationBasePath}/${id}`)
  });

export const createLocation = (
  data: Location
): Promise<ApiRequestResponse<Location>> => {
  return request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(locationBasePath),
    data
  });
};

export const updateLocation = (
  data: Location
): Promise<ApiRequestResponse<Location>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${locationBasePath}/${data.id}`),
    data
  });

const bedBasePath = "/bed";

export const getBedList = (
  params: LocationListQueryParams
): Promise<ApiPageRequestResponse<Bed>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(bedBasePath),
    params
  });

export const getBed = (id: ModelId): Promise<ApiRequestResponse<Bed>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${bedBasePath}/${id}`)
  });
