import React from "react";
import { FCC, getClassName, TypeVariant } from "@laba/react-common";
import { NexupIconButton } from "components/buttons/IconButton/IconButton";
import { TypeInvertedVariant } from "components/utils/getGenericButtonColors";
import { Add, RemoveIcon } from "components/icons";
import { Noop } from "@laba/ts-common";
import { DefaultButton } from "components/buttons/DefaultButton/DefaultButton";
import { useAddRemoveButtonsContainerStyles } from "./useAddRemoveButtonsContainerStyles";

export interface AddRemoveButtonsContainerProps {
  className?: string;
  showAddIconButton?: boolean;
  showRemoveIconButton?: boolean;
  onAddIconButtonClick?: Noop;
  onRemoveIconButtonClick?: Noop;
  addIconButtonTitle?: string;
  removeIconButtonTitle?: string;
  addIconDisabled?: boolean;
  removeIconDisabled?: boolean;
  addButtonText?: string;
}

export const AddRemoveButtonsContainer: FCC<AddRemoveButtonsContainerProps> = ({
  className,
  showAddIconButton,
  showRemoveIconButton,
  onAddIconButtonClick,
  onRemoveIconButtonClick,
  addIconButtonTitle,
  removeIconButtonTitle,
  addIconDisabled,
  removeIconDisabled,
  addButtonText,
  children
}) => {
  const classes = useAddRemoveButtonsContainerStyles();

  return (
    <div className={getClassName(className, classes.root)}>
      <div className={classes.inputRow}>
        <div className={classes.children}>{children}</div>
        {showRemoveIconButton && (
          <NexupIconButton
            Icon={RemoveIcon}
            type={TypeInvertedVariant.Outlined}
            onClick={onRemoveIconButtonClick}
            title={removeIconButtonTitle}
            disabled={removeIconDisabled}
          />
        )}
      </div>
      {showAddIconButton && (
        <DefaultButton
          StartIcon={Add}
          type={TypeVariant.Outlined}
          onClick={onAddIconButtonClick}
          title={addIconButtonTitle}
          disabled={addIconDisabled}
          text={addButtonText}
        />
      )}
    </div>
  );
};
