import { FC, getClassName } from "@laba/react-common";
import React from "react";
import { AccessTime } from "components/icons";
import { TextOrLink } from "components/text/TextOrLink/TextOrLink";
import { ChatBubble } from "components/chat/Chat/ChatMessages/ChatMessage/ChatBubble/ChatBubble";
import { useChatMessageComponentStyle } from "components/chat/Chat/ChatMessages/ChatMessage/useChatMessageComponentStyle";
import { Optional } from "@laba/ts-common";
import { AttachmentButton } from "./AttatchmentButton/AttachmentButton";

export enum MessageOrigin {
  Sent = "sent",
  Received = "received"
}

export interface ChatMessageProps {
  messageOrigin?: MessageOrigin;
  message?: string;
  attatchmentName?: string;
  onDownload?: () => Promise<Optional<Blob>>;
  className?: string;
  unsent?: boolean;
  withPreview?: boolean;
}

export const ChatMessageComponent: FC<ChatMessageProps> = ({
  message,
  className,
  messageOrigin = MessageOrigin.Sent,
  unsent = false,
  onDownload,
  attatchmentName,
  withPreview
}) => {
  const classes = useChatMessageComponentStyle({ messageOrigin });
  return (
    <ChatBubble
      className={getClassName(className, classes.root)}
      messageOrigin={messageOrigin}
    >
      {attatchmentName && (
        <AttachmentButton
          onDownload={onDownload}
          attatchmentName={attatchmentName}
          withPreview={withPreview}
          disabled={!onDownload}
          withSpinner={!onDownload}
        />
      )}
      <TextOrLink message={message} className={classes.messageText} />
      {unsent && message && <AccessTime className={classes.icon} />}
    </ChatBubble>
  );
};
