import {
  getDefaultJsonToModel,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";
import { MeasurementGroupDefinition, ResourceType } from "@laba/nexup-api";
import {
  getDefaultResourceTransform,
  ResourceForm
} from "components/generic/ResourceForm/ResourceForm";
import { tkCP } from "translation/i18n";
import { FC, useI18n } from "@laba/react-common";
import React from "react";
import { MeasurementGroupDefinitionMainContent } from "components/pages/NexupAdmin/resources/measurementGroupDefinition/MeasurementGroupDefinitionMainContent";
import { MeasurementGroupDefinitionMeasurementListCompleteContent } from "components/pages/NexupAdmin/resources/measurementGroupDefinition/MeasurementGroupDefinitionMeasurementListCompleteContent";
import { MeasurementGroupDefinitionMeasurementListContent } from "components/pages/NexupAdmin/resources/measurementGroupDefinition/MeasurementGroupDefinitionMeasurementListContent";
import { MeasurementGroupDefinitionMeasurementContent } from "components/pages/NexupAdmin/resources/measurementGroupDefinition/MeasurementGroupDefinitionMeasurementContent";

export const measurementGroupDefinitionJsonToModel: JsonToModel<MeasurementGroupDefinition> =
  getDefaultJsonToModel(draft => {
    draft.organization = undefined;
  });

export const measurementGroupDefinitionTransform =
  getDefaultResourceTransform<MeasurementGroupDefinition>(
    ResourceType.MeasurementGroupDefinition
  );

export const tk = tkCP.adminPage[ResourceType.MeasurementGroupDefinition];

export const MeasurementGroupDefinitionForm: FC = () => {
  const { t } = useI18n();

  return (
    <ResourceForm
      tabsConfig={[
        {
          label: t(tk.tabs.main),
          content: <MeasurementGroupDefinitionMainContent />
        },
        {
          label: t(tk.tabs.measurementList),
          content: <MeasurementGroupDefinitionMeasurementListContent />
        },
        {
          label: t(tk.tabs.measurementDetail),
          content: <MeasurementGroupDefinitionMeasurementContent />
        },
        {
          label: t(tk.tabs.measurementCompleteList),
          content: <MeasurementGroupDefinitionMeasurementListCompleteContent />
        }
      ]}
    />
  );
};
