import React from "react";
import { FC, useI18n } from "@laba/react-common";
import { ProcedureBatteryConcept } from "@laba/nexup-api";
import {
  cleanEmptyString,
  ResourceForm
} from "components/generic/ResourceForm/ResourceForm";
import { tkCP } from "translation/i18n";
import {
  getDefaultJsonToModel,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";
import { ConceptResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { ResourceTransform } from "components/generic/Create/Create";
import { ProcedureBatteryConceptContent } from "components/pages/NexupAdmin/resources/concept/batteryProcedureCode/ProcedureBatteryConceptContent";

export const ProcedureBatteryConceptTransform: ResourceTransform<
  ProcedureBatteryConcept
> = resource => {
  return cleanEmptyString(resource);
};
export const ProcedureBatteryConceptJsonToModel: JsonToModel<ProcedureBatteryConcept> =
  getDefaultJsonToModel();

export const tk = tkCP.adminPage[ConceptResourceType.ProcedureBatteryConcept];

export const ProcedureBatteryConceptForm: FC = () => {
  const { t } = useI18n();
  return (
    <ResourceForm
      tabsConfig={[
        {
          label: t(tk.tabs.procedureBatteryConcept),
          content: <ProcedureBatteryConceptContent />
        }
      ]}
    />
  );
};
