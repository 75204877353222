import { RadiusVariant, Theme, ThemeStyleColorVariant } from "model/theme";
import {
  borderMixin,
  borderMixinRadiusAll,
  createStyle,
  FlexAlignItems,
  FlexJustifyContent,
  flexRowMixin,
  getClassName,
  hoverMixin,
  marginMixin,
  paddingMixin,
  pxToRem,
  TypeVariant
} from "@laba/react-common";
import {
  getThemeRadius,
  getThemeStyleColor,
  getThemeStyleState,
  themeTextMixin
} from "model/themeUtils";
import { StyleVariant, TextVariant } from "model/themeVariant";
import { NexupColor } from "model/nexupColor";
import { MuiOverridesType } from "model/useMuiTheme";

export interface DateInputStyleProps {
  hasError: boolean;
  fullWidth: boolean;
}

export interface DateInputOutlinedStyleProps {
  disabled: boolean;
  variant: TypeVariant;
}
export interface DateEndIconStyleProps {
  style: StyleVariant;
}

interface DateOutlinedInputStylesClassNames {
  root?: string;
  adornedEnd?: string;
  input?: string;
  notchedOutline?: string;
  focused?: string;
}

interface DateInputStylesClassNames {
  container?: string;
  errorText?: string;
}

export const useInputLabelStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...themeTextMixin(theme, TextVariant.Body2, 1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      ),
      width: "calc(100% - 32px)"
    },
    focused: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayMedium
      ),
      width: "100%"
    }
  }),
  "InputLabel"
);

export const useDateEndIconStyle = createStyle(
  (theme: Theme) => ({
    root: (props: DateEndIconStyleProps) => ({
      color: getThemeStyleColor(
        theme,
        props.style,
        ThemeStyleColorVariant.GrayDark
      ),
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.Center
      }),
      ...paddingMixin({ all: 0 }),
      ...hoverMixin({ backgroundColor: NexupColor.Transparent })
    })
  }),
  "DateEndIcon"
);

export interface DateInputEndButtonStylesProps {
  disabled?: boolean;
  hasError?: boolean;
}

export interface DateInputEndButtonStylesClassNames {
  endButton?: string;
  closeIcon?: string;
}

const useEndButtonStyles = createStyle(
  (theme: Theme) => ({
    baseButton: {
      ...paddingMixin({ left: 4, right: 8 }),
      boxSizing: "content-box"
    },
    closeIcon: {
      width: pxToRem(22),
      height: pxToRem(22)
    },
    disabledColor: {
      color: getThemeStyleState(theme, StyleVariant.Primary).disabled
    },
    errorColor: {
      color: getThemeStyleColor(theme, StyleVariant.Danger)
    },
    activeColor: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "EndButton"
);

export const useDateInputEndButtonStyles = (
  props: DateInputEndButtonStylesProps
): DateInputEndButtonStylesClassNames => {
  const classes = useEndButtonStyles();
  const { disabled, hasError } = props;
  return {
    closeIcon: classes.closeIcon,
    endButton: getClassName(
      classes.baseButton,
      disabled
        ? classes.disabledColor
        : hasError
        ? classes.errorColor
        : classes.activeColor
    )
  };
};

const useDateOutlinedInputStaticStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...borderMixinRadiusAll(getThemeRadius(theme, RadiusVariant.Small)),
      ...themeTextMixin(theme, TextVariant.Body2, 1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayMedium
      ),
      overflow: "visible"
    },
    rootWithContrastBackground: {
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    },
    adornedEnd: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayMedium
      )
    },
    input: {
      boxSizing: "border-box",
      minHeight: pxToRem(40),
      textOverflow: "ellipsis"
    },
    inputColorDisabled: {
      color: getThemeStyleState(theme, StyleVariant.Primary).disabled
    },
    inputColorGrayDark: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    notchedOutline: {
      ...themeTextMixin(theme, TextVariant.Body2, 1)
    },
    notchedOutlineWithBorder: {
      ...borderMixin({
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.GrayMedium
        ),
        side: { all: 1 }
      })
    },
    notchedOutlineWithoutBorder: {
      ...borderMixin({ side: { all: 0 }, style: "none" })
    },
    focusedWithBorder: {
      ...borderMixin({
        side: { all: 1 }
      })
    },
    focusedWithoutBorder: {
      ...borderMixin({ side: { all: 0 }, style: "none" })
    }
  }),
  "DateOutlinedInputStaticStyle"
);

export const useDateOutlinedInputStyles = (
  props: DateInputOutlinedStyleProps
): DateOutlinedInputStylesClassNames => {
  const staticClasses = useDateOutlinedInputStaticStyle();

  return {
    root: getClassName(
      staticClasses.root,
      props.variant === TypeVariant.Contained
        ? staticClasses.rootWithContrastBackground
        : undefined
    ),
    adornedEnd: staticClasses.adornedEnd,
    input: getClassName(
      staticClasses.input,
      props.disabled
        ? staticClasses.inputColorDisabled
        : staticClasses.inputColorGrayDark
    ),
    notchedOutline: getClassName(
      staticClasses.notchedOutline,
      props.variant === TypeVariant.Contained
        ? staticClasses.notchedOutlineWithoutBorder
        : staticClasses.notchedOutlineWithBorder
    ),
    focused:
      props.variant === TypeVariant.Contained
        ? staticClasses.focusedWithoutBorder
        : staticClasses.focusedWithBorder
  };
};

const useDateInputStaticStyle = createStyle(
  (theme: Theme) => ({
    fullWidthContainer: { width: "100%" },
    autoWidthContainer: { width: "auto" },
    errorText: {
      ...themeTextMixin(theme, TextVariant.Caption),
      ...marginMixin({ leftRight: 14, top: 4 })
    },
    errorTextColorDanger: {
      color: getThemeStyleColor(theme, StyleVariant.Danger)
    },
    errorTextColorGrayDark: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "DateInputStaticStyle"
);

export const useDateInputStyles = (
  props: DateInputStyleProps
): DateInputStylesClassNames => {
  const staticClasses = useDateInputStaticStyle();

  return {
    container: props.fullWidth
      ? staticClasses.fullWidthContainer
      : staticClasses.autoWidthContainer,
    errorText: getClassName(
      staticClasses.errorText,
      props.hasError
        ? staticClasses.errorTextColorDanger
        : staticClasses.errorTextColorGrayDark
    )
  };
};

export const getDateTimeOverrides = (theme: Theme): MuiOverridesType => ({
  MuiPickersCalendarHeader: {
    iconButton: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }
});
