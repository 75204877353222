import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  MeasurementConfigKey,
  MeasurementGroupCategory,
  MeasurementGroupDefinitionKey,
  MeasurementGroupDefinitionListQueryParamsKey,
  OrganizationCombinedType,
  OrganizationKey,
  OrganizationListQueryParamsKey,
  PublicationStatus,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { TextField } from "components/generic/TextField/TextField";
import { ReferenceResourceField } from "components/generic/ReferenceResourceField/ReferenceResourceField";
import { List } from "components/generic/List/List";
import { Datagrid } from "components/generic/Datagrid/Datagrid";
import { measurementGroupDefinitionJsonToModel } from "components/pages/NexupAdmin/resources/measurementGroupDefinition/MeasurementGroupDefinitionForm";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { ArrayField, ChipField, SingleFieldList } from "react-admin";
import { useListExportedFileName } from "components/hook/useExportedFileName";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

const tk = tkCP.adminPage[ResourceType.MeasurementGroupDefinition];

export const MeasurementGroupDefinitionList: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const exportedFileName = useListExportedFileName(t(tk.title));

  return (
    <List
      resource={ResourceType.MeasurementGroupDefinition}
      exportedFileName={exportedFileName}
      jsonToModel={measurementGroupDefinitionJsonToModel}
      extraFilters={[
        workspaceOrganizationId === undefined ? (
          <OrganizationReferenceInput
            label={t(tk.filters.organization)}
            source={MeasurementGroupDefinitionListQueryParamsKey.organization}
            alwaysOn
            type={OrganizationCombinedType.ProviderAndGroup}
            extraFilters={{
              [OrganizationListQueryParamsKey.active]: true
            }}
          />
        ) : undefined,
        <EnumSelectorInput
          source={MeasurementGroupDefinitionListQueryParamsKey.status}
          label={t(tk.filters.status)}
          enumValue={PublicationStatus}
          alwaysOn
          resettable
        />,
        <EnumSelectorInput
          source={MeasurementGroupDefinitionListQueryParamsKey.category}
          label={t(tk.filters.category)}
          enumValue={MeasurementGroupCategory}
          alwaysOn
          resettable
        />
      ]}
    >
      <Datagrid>
        <TextField
          source={MeasurementGroupDefinitionKey.id}
          label={t(tk.fields.id)}
        />
        <TextField
          source={MeasurementGroupDefinitionKey.name}
          label={t(tk.fields.name)}
        />
        <ReferenceResourceField
          resourceType={ResourceType.Organization}
          label={t(tk.fields.organization)}
          source={MeasurementGroupDefinitionKey.organization}
          optionTextKey={OrganizationKey.name}
        />
        <TextField
          source={MeasurementGroupDefinitionKey.category}
          label={t(tk.fields.category)}
        />
        <TextField
          source={MeasurementGroupDefinitionKey.status}
          label={t(tk.fields.status)}
        />
        <ArrayField
          source={MeasurementGroupDefinitionKey.measurements}
          label={t(tk.fields.measurements)}
        >
          <SingleFieldList>
            <ChipField source={MeasurementConfigKey.title} />
          </SingleFieldList>
        </ArrayField>
      </Datagrid>
    </List>
  );
};
