import React from "react";
import { FC, useI18n } from "@laba/react-common";
import { OrganizationKey, ResourceType } from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { OrganizationWhiteLabelBannerContent } from "components/pages/NexupAdmin/resources/organization/OrganizationWhiteLabelBannerContent";
import { OrganizationWhiteLabelItemContent } from "components/pages/NexupAdmin/resources/organization/OrganizationWhiteLabelItemsContent";

export const tk = tkCP.adminPage[ResourceType.Organization];
export const OrganizationWhiteLabelDesktopContent: FC = () => {
  const { t } = useI18n();
  return (
    <>
      <OrganizationWhiteLabelBannerContent
        source={
          OrganizationKey.whiteLabelConfig.desktopHomeConfig
            .bannerItemConfigList
        }
        label={t(tk.fields.whiteLabelConfigDesktopHomeBanner)}
      />
      <OrganizationWhiteLabelItemContent
        columnsSource={
          OrganizationKey.whiteLabelConfig.desktopHomeConfig.numColumns
        }
        listSource={OrganizationKey.whiteLabelConfig.desktopHomeConfig.itemList}
        columnsLabel={t(tk.fields.whiteLabelConfigDesktopHomeColumns)}
        listLabel={t(tk.fields.whiteLabelConfigDesktopHomeItem)}
        columnDefault={12}
      />
    </>
  );
};
