import { FCC, getClassName } from "@laba/react-common";
import { isEmpty } from "lodash-es";
import React from "react";
import { useCredentialCardStyle } from "./CredentialCardStyle";

export interface CredentialCardProps {
  className?: string;
  cardBackground?: string;
  icon?: string;
  iconAltText?: string;
  logo?: string;
  logoAltText?: string;
  planText: string;
  plan?: string;
  fullName: string;
  identifierText: string;
  identityNumber?: string;
  credentialText: string;
  credentialNumber?: string;
}

export const CredentialCard: FCC<CredentialCardProps> = ({
  className,
  cardBackground,
  icon,
  iconAltText = "",
  logo,
  logoAltText = "",
  planText,
  plan,
  fullName,
  identifierText,
  identityNumber,
  credentialText,
  credentialNumber
}) => {
  const isUrl = cardBackground?.startsWith("http") ?? false;
  const classes = useCredentialCardStyle({ cardBackground, isUrl });

  return (
    <div className={getClassName(classes.cardContainer, className)}>
      {icon && <img src={icon} alt={iconAltText} className={classes.icon} />}
      <div className={classes.credentialData}>
        <div className={classes.header}>
          {logo && (
            <img src={logo} alt={logoAltText} className={classes.logo} />
          )}
          {!isEmpty(plan) && (
            <p className={classes.plan}>
              {planText} {plan}
            </p>
          )}
        </div>

        <p className={classes.fullName}>{fullName}</p>

        <div className={classes.identifiers}>
          <p className={classes.identifier}>
            {!isEmpty(identityNumber) && (
              <>
                {identifierText}
                <br />
                {identityNumber}
              </>
            )}
          </p>
          {!isEmpty(credentialNumber) && (
            <p
              className={getClassName(
                classes.identifier,
                classes.rightIdentifier
              )}
            >
              {credentialText} <br />
              {credentialNumber}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
