import { FC, getClassName } from "@laba/react-common";
import React from "react";
import { useFilePreviewClassNames } from "components/file/FileListPreviewPopup/FilePreviewViewer/useFilePreviewViewerStyles";
import { mimeIsImage, mimeIsPDF } from "components/download/DownloadLink/utils";
import { FilePreviewDialogInvalidFormatCard } from "components/file/FileListPreviewPopup/FilePreviewDialogInvalidFormatCard/FilePreviewDialogInvalidFormatCard";
import { FileBlobItem } from "components/file";
import { getFileNameOrDefault } from "components/file/utils";
import { Spinner } from "components/spinners/Spinner/Spinner";

interface Props {
  file?: FileBlobItem;
  className?: string;
  isMobile?: boolean;
  invalidFormatTitle?: string;
  invalidFormatDescription?: string;
}
export const FilePreviewViewer: FC<Props> = ({
  file,
  className,
  invalidFormatTitle,
  invalidFormatDescription,
  isMobile
}) => {
  const classes = useFilePreviewClassNames({ isMobile });
  const blob = file?.blob;
  const loading = file?.loading;
  const blobType = blob?.type;

  return (
    <div className={getClassName(className, classes.root)}>
      {loading ? (
        <div className={classes.loading}>
          <Spinner />
        </div>
      ) : blobType && mimeIsImage(blobType) ? (
        <img
          className={classes.imageViewer}
          alt={getFileNameOrDefault(file?.fileName)}
          src={URL.createObjectURL(blob)}
        />
      ) : blobType && mimeIsPDF(blobType) && !isMobile ? (
        <embed
          className={classes.pdfViewer}
          title={getFileNameOrDefault(file?.fileName, "pdfViewer")}
          src={URL.createObjectURL(blob)}
        />
      ) : (
        <FilePreviewDialogInvalidFormatCard
          className={classes.invalidFormatContainer}
          title={invalidFormatTitle}
          description={invalidFormatDescription}
        />
      )}
    </div>
  );
};
