import { DateTime, toApiDate } from "@laba/ts-common";
import { PrescriptionRequest } from "model/resource/prescription/prescriptionRequest";
import { ResourceType } from "model/primitives/resourceModel";
import { MedicalRequestStatus } from "model/resource/prescription/medicationRequest/medicationRequest";
import { Medication } from "model/resource/prescription/medication/medication";
import { getModelOrUndefined } from "model/primitives/modelReference/utils";
import { ModelId } from "model/primitives/model/model";

export interface EmptyPrescriptionRequestProps {
  prescriptionDate?: DateTime;
  patient?: ModelId;
  encounter?: ModelId;
  practitioner?: ModelId;
}

export const createEmptyPrescriptionRequest = (
  props: EmptyPrescriptionRequestProps
): PrescriptionRequest => {
  const prescriptionDate = props.prescriptionDate
    ? toApiDate(props.prescriptionDate)
    : undefined;
  return {
    resourceType: ResourceType.PrescriptionRequest,
    statusReason: MedicalRequestStatus.Active,
    ...props,
    prescriptionDate
  };
};

export const getDisplayTextFromMedication = (
  medication?: Medication
): string => {
  const description = medication?.description;
  const instanceOf = medication?.instanceOf;
  const descriptionGenericMedication =
    getModelOrUndefined(instanceOf)?.description;
  return descriptionGenericMedication
    ? `${description} (${descriptionGenericMedication})`
    : description ?? "";
};
