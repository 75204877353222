import React, { useCallback, useMemo, useState } from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  ResourceType,
  MeasurementGroupDefinitionKey,
  MeasurementConfig,
  getModelReferenceId,
  KnownCodeSystemSystem,
  MedicalRequestDefinitionKey,
  Organization,
  ModelReference
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { Autocomplete, AutocompleteOptionConfig } from "@laba/nexup-components";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { Optional } from "@laba/ts-common";
import { MeasurementGroupDefinitionMeasurementFields } from "components/pages/NexupAdmin/resources/measurementGroupDefinition/MeasurementGroupDefinitionMeasurementFields";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useSelector } from "react-redux";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";

export const tk = tkCP.adminPage[ResourceType.MeasurementGroupDefinition];

export const MeasurementGroupDefinitionMeasurementContent: FC = () => {
  const { t } = useI18n();
  const measurementList = useWatchResourceForm<MeasurementConfig[]>(
    MeasurementGroupDefinitionKey.measurements
  );
  const organization = useWatchResourceForm<ModelReference<Organization>>(
    MedicalRequestDefinitionKey.organization
  );
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organizationId =
    getModelReferenceId(organization) || workspaceOrganizationId;
  const measurementTypeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.ObservationCode,
    organizationId
  );
  const unitSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicationUnit,
    organizationId
  );

  const [toEditElementIndex, setToEditElementIndex] =
    useState<Optional<number>>(undefined);
  const fieldOptionList = useMemo<AutocompleteOptionConfig<number>[]>(
    () =>
      measurementList?.map((value, index) => {
        const text = `${value.title || "sin titulo"} (tipo: ${
          value.type
        } / link Id: ${value.linkId})`;
        return {
          value: index,
          text,
          title: text
        };
      }) || [],
    [measurementList]
  );
  const getElementSource = useCallback(
    (source: string) =>
      [
        MeasurementGroupDefinitionKey.measurements,
        String(toEditElementIndex),
        source
      ].join("."),
    [toEditElementIndex]
  );
  return (
    <>
      <Autocomplete<number>
        fullWidth
        value={toEditElementIndex}
        onChange={async value => {
          // set value to undefined for 1 render (await) to dismount and remount properties array input
          await setToEditElementIndex(undefined);
          setToEditElementIndex(value);
        }}
        options={fieldOptionList}
        compareValues={(v1, v2) => v1 === v2}
        clearText={t(tk.autocompleteClearText)}
        closeText={t(tk.autocompleteCloseText)}
        loadingText={t(tk.autocompleteLoadingText)}
        noOptionsText={t(tk.autocompleteNoOptionsText)}
        openText={t(tk.autocompleteOpenText)}
        label={t(tk.autocompleteTitle)}
      />
      {toEditElementIndex !== undefined && (
        <MeasurementGroupDefinitionMeasurementFields
          getSource={getElementSource}
          unitSystem={unitSystem}
          measurementTypeSystem={measurementTypeSystem}
          withProps
          withLinkId={false}
          organizationId={organizationId}
        />
      )}
    </>
  );
};
