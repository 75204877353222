import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  ContactPointKey,
  ContactPointRole,
  ContactPointType,
  Gender,
  getModelReferenceId,
  KnownCodeSystemSystem,
  ModelReference,
  Organization,
  OrganizationCombinedType,
  OrganizationListQueryParamsKey,
  Patient,
  PatientKey,
  PatientListQueryParamsKey,
  PatientManagedByKey,
  ResourceType,
  ValidationStatus
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { DateInput } from "components/generic/DateInput/DateInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { patientOptionText } from "components/generic/ReferenceResourceInput/optionText";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { IdentifierInput } from "components/generic/IdentifierInput/IdentifierInput";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";

export const tk = tkCP.adminPage[ResourceType.Patient];
export const PatientMainContent: FC = () => {
  const { t } = useI18n();
  const organization = useWatchResourceForm<ModelReference<Organization>>(
    PatientKey.organization
  );
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organizationId =
    getModelReferenceId(organization) || workspaceOrganizationId;

  const patientTaxType = useCodeSystemGroup(
    KnownCodeSystemSystem.TaxType,
    organizationId
  );

  return (
    <>
      <TextInput disabled source={PatientKey.id} label={t(tk.fields.id)} />
      <OrganizationReferenceInput
        source={PatientKey.organization}
        label={t(tk.fields.organization)}
        type={OrganizationCombinedType.ProviderAndGroup}
        extraFilters={{
          [OrganizationListQueryParamsKey.active]: true
        }}
        required
        defaultValue={workspaceOrganizationId}
        disabled={workspaceOrganizationId !== undefined}
      />
      <TextInput
        source={PatientKey.personalData.firstName}
        label={t(tk.fields.firstName)}
        required
      />
      <TextInput
        source={PatientKey.personalData.otherName}
        label={t(tk.fields.otherName)}
      />
      <TextInput
        source={PatientKey.personalData.lastName}
        label={t(tk.fields.lastName)}
        required
      />
      <TextInput
        source={PatientKey.personalData.motherLastName}
        label={t(tk.fields.motherLastName)}
      />
      <DateInput
        source={PatientKey.personalData.birthDate}
        label={t(tk.fields.birthDate)}
      />
      <EnumSelectorInput
        source={PatientKey.personalData.gender}
        label={t(tk.fields.gender)}
        enumValue={Gender}
        required
      />
      <IdentifierInput
        source={PatientKey.personalData.identifierList}
        organization={organizationId}
        system={KnownCodeSystemSystem.PersonIdentifierSystem}
      />
      <OrganizationReferenceInput
        source={PatientKey.patientData.healthcarePayer.provider}
        label={t(tk.fields.healthcarePayerProvider)}
        type={OrganizationCombinedType.Payer}
        extraFilters={{
          [OrganizationListQueryParamsKey.partOf]: organizationId,
          [OrganizationListQueryParamsKey.active]: true
        }}
      />
      <TextInput
        source={PatientKey.patientData.healthcarePayer.credentialNumber}
        label={t(tk.fields.healthcarePayerProviderId)}
      />
      <TextInput
        source={PatientKey.patientData.healthcarePayer.plan}
        label={t(tk.fields.healthcarePayerProviderPlan)}
      />
      <CodeSystemSelectorInput
        source={PatientKey.patientData.healthcarePayer.taxType}
        label={t(tk.fields.taxType)}
        codeSystem={patientTaxType}
        organizationId={organizationId}
      />
      <ArrayInput
        source={PatientKey.contactData.phoneList}
        label={t(tk.fields.phone)}
      >
        <TextInput
          source={ContactPointKey.value}
          label={t(tk.fields.phoneValue)}
          required
        />
        <EnumSelectorInput
          source={ContactPointKey.role}
          label={t(tk.fields.phoneRole)}
          enumValue={ContactPointRole}
        />
        <EnumSelectorInput
          source={ContactPointKey.type}
          label={t(tk.fields.phoneType)}
          enumValue={ContactPointType}
        />
      </ArrayInput>
      <ArrayInput
        source={PatientKey.contactData.emailList}
        label={t(tk.fields.email)}
      >
        <TextInput
          source={ContactPointKey.value}
          label={t(tk.fields.emailValue)}
          required
        />
        <EnumSelectorInput
          source={ContactPointKey.role}
          label={t(tk.fields.emailRole)}
          enumValue={ContactPointRole}
        />
        <EnumSelectorInput
          source={ContactPointKey.type}
          label={t(tk.fields.emailType)}
          enumValue={ContactPointType}
        />
      </ArrayInput>
      <BooleanInput
        source={PatientKey.active}
        label={t(tk.fields.active)}
        defaultValue
      />
      <EnumSelectorInput
        source={PatientKey.validationStatus}
        label={t(tk.fields.validationStatus)}
        enumValue={ValidationStatus}
        defaultValue={ValidationStatus.Temporal}
      />
      <TextInput
        disabled
        source={PatientKey.federationId}
        label={t(tk.fields.federationId)}
      />
      <TextInput
        source={PatientKey.patientData.medicalRecordId}
        label={t(tk.fields.medicalRecordId)}
      />
      <ArrayInput
        source={PatientKey.patientData.managedBy}
        label={t(tk.fields.managedBy)}
      >
        <ReferenceResourceInput<Patient>
          source={PatientManagedByKey.patient}
          label={t(tk.fields.managedByPatient)}
          resourceType={ResourceType.Patient}
          optionTextKey={patientOptionText}
          extraFilters={{
            [PatientListQueryParamsKey.organization]: organizationId
          }}
        />
      </ArrayInput>
      <TextInput source={PatientKey.hisCode} label={t(tk.fields.hisCode)} />
    </>
  );
};
