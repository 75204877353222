import { ObservationCode } from "model/resource/medical/observation/observation";
import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { ModelId } from "model/primitives/model/model";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { ApiDate, getKeyObj, HttpMethod } from "@laba/ts-common";
import {
  MeasurementGroup,
  FluidBalanceStatisticsRecord
} from "model/resource/medical/observation/measurementGroup";
import { createHydratedMock } from "ts-auto-mock";

export const MeasurementGroupBasePath = "/measurement-group";

export interface MeasurementGroupQueryParams {
  withPractitioner?: boolean;
  withMeasurement?: boolean;
  withDefinition?: boolean;
}

export interface MeasurementGroupListQueryParams
  extends ListQueryParams,
    MeasurementGroupQueryParams {
  definition?: ModelId;
  encounter?: ModelId;
  measuredEndDate?: ApiDate;
  measuredStartDate?: ApiDate;
  organization?: ModelId;
  patient?: ModelId;
  practitioner?: ModelId;
  type?: ObservationCode;
}

export interface FluidBalanceStatisticsQueryParams {
  measuredEndDate: ApiDate;
  measuredStartDate: ApiDate;
  patient: ModelId;
  definition?: ModelId;
}

export const MeasurementGroupFormDataParamsKey = getKeyObj(
  createHydratedMock<MeasurementGroup>()
);

export const getMeasurementGroupList = (
  params: MeasurementGroupListQueryParams
): Promise<ApiPageRequestResponse<MeasurementGroup>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(MeasurementGroupBasePath),
    params
  });

export const getMeasurementGroup = (
  id: ModelId,
  params?: MeasurementGroupQueryParams
): Promise<ApiRequestResponse<MeasurementGroup>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${MeasurementGroupBasePath}/${id}`),
    params
  });

export const createMeasurementGroup = (
  data: MeasurementGroup
): Promise<ApiRequestResponse<MeasurementGroup>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(MeasurementGroupBasePath),
    data
  });

export const updateMeasurementGroup = (
  data: MeasurementGroup
): Promise<ApiRequestResponse<MeasurementGroup>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${MeasurementGroupBasePath}/${data.id}`),
    data
  });

export const cancelMeasurementGroup = (
  id: ModelId,
  params?: MeasurementGroupQueryParams
): Promise<ApiRequestResponse<MeasurementGroup>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(`${MeasurementGroupBasePath}/${id}/cancel`),
    params
  });

export const getFluidBalanceStatistics = (
  params: FluidBalanceStatisticsQueryParams
): Promise<ApiRequestResponse<FluidBalanceStatisticsRecord>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${MeasurementGroupBasePath}/fluid-balance/statistics`),
    params
  });
