import { isEmpty, startsWith } from "lodash-es";
import { notEmpty, Optional } from "model/types";

export const joinText = (
  textList?: Optional<string>[],
  separator = " | ",
  upperCaseText = false
): Optional<string> => {
  const filteredList = textList?.filter(notEmpty);
  const str = isEmpty(filteredList) ? undefined : filteredList?.join(separator);
  return upperCaseText ? str?.toUpperCase() : str;
};

export const joinTextNotUndefined = (
  textList?: Optional<string>[],
  separator = " | "
): string => {
  return joinText(textList, separator) ?? "";
};

// German uses commas as decimal separator and points for miles
export const formatPrice = (price?: number): Optional<string> =>
  price?.toLocaleString("de-DE");

export const formatCurrencyString = (
  currency?: string,
  price?: number
): Optional<string> => joinText([currency, formatPrice(price) ?? " -"], "");

// Remove any whitespace from text (spaces, tabs or newlines)
export const removeSpacesFromText = (text: string): string =>
  text.replace(/\s/g, "");

const removeRepeatedSpaces = (text: string): string => text.replace(/ +/g, " ");
const removeRepeatedTabs = (text: string): string => text.replace(/\t+/g, "\t");
const removeRepeatedNewLine = (text: string): string =>
  text.replace(/\n+/g, "\n");
const removeRepeatedReturn = (text: string): string =>
  text.replace(/\r+/g, "\r");

export const removeRepeatedFormatCharactersFromText = (
  text: string
): string => {
  const textWithoutRepeatedSpaces = removeRepeatedSpaces(text);
  const textWithoutRepeatedTabs = removeRepeatedTabs(textWithoutRepeatedSpaces);
  const textWithoutRepeatedNewLines = removeRepeatedNewLine(
    textWithoutRepeatedTabs
  );
  return removeRepeatedReturn(textWithoutRepeatedNewLines);
};

export const removeSpacesFromTextOrUndefined = (
  text?: string
): Optional<string> => {
  return text ? removeSpacesFromText(text) : undefined;
};

export const removeSymbolsFromTextExceptPlus = (text: string): string => {
  return text.replace(/[^a-zA-Z0-9+]/g, "");
};

export const isHtmlString = (text?: string): boolean => {
  return startsWith(text?.trim(), "<");
};
