import {
  createStyle,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

interface HeaderCreationOrLastEditDateTextStyleClassNames {
  root: string;
}

const useHeaderCreationOrLastEditDateTextStyleStaticStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...themeTextMixin(theme, TextVariant.Caption),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "HeaderCreationOrLastEditDateTextStyleStatic"
);

export const useHeaderCreationOrLastEditDateTextStyle =
  (): HeaderCreationOrLastEditDateTextStyleClassNames => {
    const staticClasses =
      useHeaderCreationOrLastEditDateTextStyleStaticStyles();

    return {
      root: staticClasses.root
    };
  };
