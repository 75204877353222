import { createStyle, pxToRem, Theme } from "@laba/react-common";

export const useLatLngInputStyle = createStyle(
  (_theme: Theme) => ({
    map: {
      height: pxToRem(400)
    }
  }),
  "useLatLngInputStyle"
);
