import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  getModelReferenceId,
  KnownCodeSystemSystem,
  MedicalDeviceKey,
  MedicalDeviceStatus,
  MedicalDeviceTagKey,
  ModelReference,
  Organization,
  OrganizationCombinedType,
  OrganizationListQueryParamsKey,
  ResourceType
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

export const tk = tkCP.adminPage[ResourceType.MedicalDevice];
export const MedicalDeviceMainContent: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organization = useWatchResourceForm<ModelReference<Organization>>(
    MedicalDeviceKey.organization
  );
  const organizationId =
    getModelReferenceId(organization) || workspaceOrganizationId;
  const typeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicalDeviceType,
    organizationId
  );
  const tagSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicalDeviceTag,
    organizationId
  );

  return (
    <>
      <TextInput
        disabled
        source={MedicalDeviceKey.id}
        label={t(tk.fields.id)}
      />
      <OrganizationReferenceInput
        source={MedicalDeviceKey.organization}
        label={t(tk.fields.organization)}
        type={OrganizationCombinedType.ProviderAndGroup}
        extraFilters={{
          [OrganizationListQueryParamsKey.active]: true
        }}
        required
        defaultValue={workspaceOrganizationId}
        disabled={workspaceOrganizationId !== undefined}
      />
      <TextInput
        source={MedicalDeviceKey.name}
        label={t(tk.fields.name)}
        required
      />
      <EnumSelectorInput
        source={MedicalDeviceKey.status}
        label={t(tk.fields.status)}
        enumValue={MedicalDeviceStatus}
        required
      />
      <CodeSystemSelectorInput
        source={MedicalDeviceKey.type}
        label={t(tk.fields.type)}
        codeSystem={typeSystem}
        organizationId={organizationId}
        required
        withCreate
      />
      <TextInput
        source={MedicalDeviceKey.hisCode}
        label={t(tk.fields.hisCode)}
      />
      <ArrayInput source={MedicalDeviceKey.tags} label={t(tk.fields.tags)}>
        <CodeSystemSelectorInput
          source={MedicalDeviceTagKey.tag}
          label={t(tk.fields.tagElement)}
          codeSystem={tagSystem}
          organizationId={organizationId}
          withCreate
        />
      </ArrayInput>
    </>
  );
};
