import React from "react";
import { AccordionSummary as MuiAccordionSummary } from "@material-ui/core";
import { FCC, TextVariant, getClassName } from "@laba/react-common";
import { ExpandMore } from "@material-ui/icons";
import { useAccordionSummaryStyle } from "./useAccordionSummaryStyles";
import { AccordionSummaryMinHeight } from "./types";

interface Props {
  className?: string;
  title?: string;
  textVariant?: TextVariant;
  isExpanded?: boolean;
  showArrowLeft?: boolean;
  showArrowIcon?: boolean;
  withTopBottomMargin?: boolean;
  accordionSummaryMinHeightVariant?: AccordionSummaryMinHeight;
}

export const AccordionSummary: FCC<Props> = ({
  className,
  title,
  textVariant = TextVariant.Subtitle1,
  isExpanded = false,
  showArrowLeft = false,
  children,
  withTopBottomMargin = true,
  showArrowIcon = true,
  accordionSummaryMinHeightVariant = AccordionSummaryMinHeight.Large
}) => {
  const classes = useAccordionSummaryStyle({
    textVariant,
    isExpanded,
    showArrowLeft,
    withTopBottomMargin,
    accordionSummaryMinHeightVariant
  });

  return (
    <MuiAccordionSummary
      className={className}
      expandIcon={showArrowIcon ? <ExpandMore /> : undefined}
      classes={{
        expanded: classes.expanded,
        root: classes.root,
        content: classes.content,
        expandIcon: classes.expandIcon
      }}
      IconButtonProps={{ classes: { edgeEnd: classes.edgeEnd } }}
    >
      {children ?? <p className={getClassName(classes.text)}>{title}</p>}
    </MuiAccordionSummary>
  );
};
