import React from "react";
import { FC } from "@laba/react-common";
import { NexupColor } from "model/nexupColor";
import { OdontogramIconProps } from "components/icons/OdontogramIcons/utils";

export const BridgeIcon: FC<OdontogramIconProps> = props => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill={props.iconColor ?? NexupColor.Black}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 8.33339C4.5 6.92199 5.64416 5.77783 7.05556 5.77783H17.9444C19.3558 5.77783 20.5 6.92199 20.5 8.33339V19.2223H18.5V8.33339C18.5 8.02656 18.2513 7.77783 17.9444 7.77783H7.05556C6.74873 7.77783 6.5 8.02656 6.5 8.33339V19.2223H4.5V8.33339Z"
      />
    </svg>
  );
};
