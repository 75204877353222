import { ResourceModel, ResourceType } from "model/primitives/resourceModel";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { ApiDate, getKeyObj } from "@laba/ts-common";
import { ProcedureReportDefinition } from "model/resource/procedures/procedureReportDefinition/procedureReportDefinition";
import { Patient } from "model/resource/person/patient/patient";
import { Practitioner } from "model/resource/person/practitioner/practitioner";
import { Location } from "model/resource/entities/location/location";
import { ModelId } from "model/primitives/model/model";
import { Attachment } from "model/primitives/attachment/attachment";
import { MeasurementGroup } from "model/resource/medical/observation/measurementGroup";
import { Coding } from "model/resource/entities/codeSystem";
import { createHydratedMock } from "ts-auto-mock";
import { Encounter } from "model/resource/entities/encounter/encounter";
import { MedicalRequest } from "model/resource/prescription/medicalRequest/medicalRequest";

export interface ProcedureReportPerformer {
  role?: Coding;
  practitioner: ModelReference<Practitioner>;
  id?: ModelId;
}

export enum ProcedureReportStatus {
  registered = "registered",
  partial = "partial",
  final = "final",
  cancelled = "cancelled"
}

export interface ProcedureReport
  extends ResourceModel<ResourceType.ProcedureReport> {
  lastEditDate?: ApiDate;
  definition?: ModelReference<ProcedureReportDefinition>;
  subject: ModelReference<Patient>;
  encounter?: ModelReference<Encounter>;
  originalPractitioner?: ModelReference<Practitioner>;
  lastEditor?: ModelReference<Practitioner>;
  location?: ModelReference<Location>;
  performer?: ProcedureReportPerformer[];
  requestDate?: ApiDate;
  effectiveDate?: ApiDate;
  status: ProcedureReportStatus;
  statusReason: Coding;
  codes: Coding[];
  category?: Coding[];
  presentedForm?: Attachment[];
  result?: ModelReference<MeasurementGroup>;
  conclusion?: string;
  observation?: string;
  title?: string;
  basedOn?: ModelReference<MedicalRequest>;
}

export const ProcedureReportFormDataParamsKey = getKeyObj(
  createHydratedMock<ProcedureReport>()
);
