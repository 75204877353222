import { getKeyObj, KeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { Model } from "../../primitives/model/model";
import { ModelReference } from "../../primitives/modelReference/modelReference";
import { Organization } from "../entities/organization/organization";
import { ResourceModel, ResourceType } from "../../primitives/resourceModel";

export enum MedicalDeviceStatus {
  Active = "active",
  Inactive = "inactive"
}

export interface MedicalDeviceTag extends Model {
  tag?: string;
}

export const MedicalDeviceTagKey: KeyObj<MedicalDeviceTag> = getKeyObj(
  createHydratedMock<MedicalDeviceTag>()
);

export interface MedicalDevice
  extends ResourceModel<ResourceType.MedicalDevice> {
  name: string;
  type?: string;
  status: MedicalDeviceStatus;
  tags?: MedicalDeviceTag[];
  organization?: ModelReference<Organization>;
  hisCode?: string;
}

export const MedicalDeviceKey: KeyObj<MedicalDevice> = getKeyObj(
  createHydratedMock<MedicalDevice>()
);
