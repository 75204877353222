import { StyleVariant } from "model/themeVariant";
import { createStyle } from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { getThemeStyleColor } from "model/themeUtils";

interface SpinnerStyleProps {
  style: StyleVariant;
}

export const useSpinnerStyles = createStyle(
  (theme: Theme) => ({
    colorPrimary: {
      color: (props: SpinnerStyleProps) =>
        getThemeStyleColor(theme, props.style, ThemeStyleColorVariant.Main)
    }
  }),
  "Spinner"
);
