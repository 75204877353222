import { RuleStyle } from "style/createUseStyles";
import { ElementOrArray, getAsArray } from "@laba/ts-common";
import { fromPairs, reduce } from "lodash-es";

/*
Don't Add input pseudo-classes (like enabled, disabled, valid, invalid, checked, required, optional, etc). Use style props instance.
*/
export enum PseudoState {
  Hover = "hover",
  Active = "active",
  Focus = "focus",
  Disabled = "disabled"
}

export type PseudoStateResult = Partial<Record<string, RuleStyle>>;

export const pseudoStateMixin = (
  pseudoState: ElementOrArray<PseudoState>,
  ruleStyle: ElementOrArray<RuleStyle>
): PseudoStateResult => {
  const finalStyle = reduce(getAsArray(ruleStyle), (acc, element) => ({
    ...acc,
    ...element
  }));
  return fromPairs(
    getAsArray(pseudoState).map(state => [`&:${state}`, finalStyle])
  );
};

export const hoverMixin = (
  ruleStyle: ElementOrArray<RuleStyle>
): PseudoStateResult => pseudoStateMixin(PseudoState.Hover, ruleStyle);

export const activeMixin = (
  ruleStyle: ElementOrArray<RuleStyle>
): PseudoStateResult => pseudoStateMixin(PseudoState.Active, ruleStyle);

export const focusMixin = (
  ruleStyle: ElementOrArray<RuleStyle>
): PseudoStateResult => pseudoStateMixin(PseudoState.Focus, ruleStyle);

export const disabledMixin = (
  ruleStyle: ElementOrArray<RuleStyle>
): PseudoStateResult => pseudoStateMixin(PseudoState.Disabled, ruleStyle);
