import { createStyle, Theme } from "@laba/react-common";
import { ScrollDirection } from "components/containers/ScrollableContainer/ScrollableContainer";
import { Property } from "csstype";
import { Optional } from "@laba/ts-common";

interface ScrollConfig {
  direction?: ScrollDirection;
  showScrollBar?: boolean;
  withoutScrollPadding?: boolean;
}
interface ShowScrollResult {
  scrollbarWidth?: Property.ScrollbarWidth;
  msOverflowStyle?: Property.MsOverflowStyle;
  "&::-webkit-scrollbar": {
    width: number;
    display?: string;
  };
}

const showScrollBar = (config?: ScrollConfig): Optional<ShowScrollResult> => {
  if (config?.showScrollBar === false) {
    return {
      scrollbarWidth: "none",
      msOverflowStyle: "none",
      "&::-webkit-scrollbar": {
        display: config.withoutScrollPadding ? "none" : undefined,
        width: 0
      }
    };
  }
  return undefined;
};

const isDirectionAllowed = (
  direction: ScrollDirection,
  config?: ScrollConfig
): boolean => {
  return (
    config?.direction === direction || config?.direction === ScrollDirection.All
  );
};
export const useScrollableContainerStyle = createStyle(
  (_theme: Theme) => ({
    scrollableContainer: (config?: ScrollConfig) => ({
      height: "100%",
      width: "100%",
      scrollBehavior: "smooth",
      ...showScrollBar(config),
      overflowX: isDirectionAllowed(ScrollDirection.Horizontal, config)
        ? "auto"
        : undefined,
      overflowY: isDirectionAllowed(ScrollDirection.Vertical, config)
        ? "auto"
        : undefined
    })
  }),
  "ScrollableContainer"
);
