import React, { useMemo } from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  getModelReferenceId,
  HospitalizationKey,
  KnownCodeSystemSystem,
  ModelId,
  ModelReference,
  ObservationStatus,
  Organization,
  Patient,
  PatientTag,
  PatientTagKey,
  PatientTagListQueryParamsKey,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { ReferenceManyResourceInput } from "components/generic/ReferenceManyResourceInput/ReferenceManyResourceInput";
import { getDefaultResourceTransform } from "components/generic/ResourceForm/ResourceForm";
import { DeepPartial, getCurrentDateAsString } from "@laba/ts-common";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { TextField } from "components/generic/TextField/TextField";
import { DateField } from "components/generic/DateField/DateField";
import { TextInput } from "components/generic/TextInput/TextInput";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { DateTimeInput } from "components/generic/DateTimeInput/DateTimeInput";
import { isEmpty } from "lodash-es";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";

export const patientTagTransform = getDefaultResourceTransform<PatientTag>(
  ResourceType.PatientTag
);
export const tk = tkCP.adminPage[ResourceType.Hospitalization];
export const HospitalizationTagsContent: FC = () => {
  const { t } = useI18n();
  const hospitalizationPatient = useWatchResourceForm<ModelReference<Patient>>(
    HospitalizationKey.patient
  );
  const hospitalizationId = useWatchResourceForm<ModelId>(
    HospitalizationKey.id
  );
  const hospitalizationOrganization = useWatchResourceForm<
    ModelReference<Organization>
  >(HospitalizationKey.organization);
  const defaultValue = useMemo<DeepPartial<PatientTag>>(
    () => ({
      encounter: hospitalizationId,
      patient: getModelReferenceId(hospitalizationPatient),
      startDateTime: getCurrentDateAsString(),
      status: ObservationStatus.Preliminary
    }),
    [hospitalizationId, hospitalizationPatient]
  );
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organizationId =
    getModelReferenceId(hospitalizationOrganization) || workspaceOrganizationId;
  const tagValueSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.ObservationPatientTag,
    hospitalizationOrganization
  );

  return (
    <ReferenceManyResourceInput<PatientTag>
      resourceType={ResourceType.PatientTag}
      resourceSource={PatientTagListQueryParamsKey.encounter}
      label={t(tk.fields.tagList)}
      resourceTransformer={patientTagTransform}
      defaultCreateValue={defaultValue}
      createEditPopupTitle={t(tk.fields.tagListPopUpTitle)}
      canCreate={!isEmpty(hospitalizationId)}
      listFieldList={[
        <TextField
          key={PatientTagKey.id}
          source={PatientTagKey.id}
          label={t(tk.fields.tagListTagId)}
        />,
        <TextField
          key={PatientTagKey.value}
          source={PatientTagKey.value}
          label={t(tk.fields.tagLisValue)}
        />,
        <TextField
          key={PatientTagKey.status}
          source={PatientTagKey.status}
          label={t(tk.fields.tagListStatus)}
        />,
        <TextField
          key={PatientTagKey.hisCode}
          source={PatientTagKey.hisCode}
          label={t(tk.fields.tagListHisCode)}
        />,
        <DateField
          key={PatientTagKey.startDateTime}
          source={PatientTagKey.startDateTime}
          label={t(tk.fields.tagListStartDateTime)}
          showTime
        />,
        <DateField
          key={PatientTagKey.endDateTime}
          source={PatientTagKey.endDateTime}
          label={t(tk.fields.tagListEndDateTime)}
          showTime
        />
      ]}
      editInputList={[
        <TextInput
          disabled
          key={PatientTagKey.id}
          source={PatientTagKey.id}
          label={t(tk.fields.tagListTagId)}
        />,
        <CodeSystemSelectorInput
          key={PatientTagKey.value}
          source={PatientTagKey.value}
          label={t(tk.fields.tagLisValue)}
          codeSystem={tagValueSystem}
          organizationId={organizationId}
          withCreate
        />,
        <TextInput
          key={PatientTagKey.hisCode}
          source={PatientTagKey.hisCode}
          label={t(tk.fields.tagListHisCode)}
        />,
        <EnumSelectorInput
          key={PatientTagKey.status}
          source={PatientTagKey.status}
          label={t(tk.fields.tagListStatus)}
          enumValue={ObservationStatus}
        />,
        <TextInput
          key={PatientTagKey.observation}
          source={PatientTagKey.observation}
          label={t(tk.fields.tagListObservation)}
        />,
        <DateTimeInput
          key={PatientTagKey.startDateTime}
          source={PatientTagKey.startDateTime}
          label={t(tk.fields.tagListStartDateTime)}
        />,
        <DateTimeInput
          key={PatientTagKey.endDateTime}
          source={PatientTagKey.endDateTime}
          label={t(tk.fields.tagListEndDateTime)}
        />
      ]}
    />
  );
};
