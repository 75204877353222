export const animationData = {
  v: "4.10.1",
  fr: 48,
  ip: 0,
  op: 48,
  w: 56,
  h: 56,
  nm: "录入声纹 tick",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "对勾",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [28, 28, 0], ix: 2 },
        a: { a: 0, k: [0.988, 5.188, 0], ix: 1 },
        s: { a: 0, k: [66.824, 66.824, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-13.398, 3.94],
                    [-1.702, 13.989],
                    [20.038, -8.302]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: "路径 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "st",
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 6, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              nm: "描边 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "变换"
            }
          ],
          nm: "形状 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        },
        {
          ty: "tm",
          s: { a: 0, k: 0, ix: 1 },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.446], y: [1] },
                o: { x: [0.078], y: [0.582] },
                n: ["0p446_1_0p078_0p582"],
                t: 19,
                s: [0],
                e: [86]
              },
              { t: 31 }
            ],
            ix: 2
          },
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                n: ["0p667_1_0p333_0"],
                t: 21,
                s: [0],
                e: [60]
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                n: ["0p667_1_0p333_0"],
                t: 26,
                s: [60],
                e: [0]
              },
              { t: 31 }
            ],
            ix: 3
          },
          m: 1,
          ix: 2,
          nm: "修剪路径 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false
        }
      ],
      ip: 12,
      op: 468,
      st: -12,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "形状图层 1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [28, 28, 0], ix: 2 },
        a: { a: 0, k: [-0.121, 0.371, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.706, 0.706, 0.333], y: [0.004, 0.004, 0] },
              n: [
                "0p667_1_0p706_0p004",
                "0p667_1_0p706_0p004",
                "0p667_1_0p333_0"
              ],
              t: 0,
              s: [0, 0, 100],
              e: [111.445, 111.445, 100]
            },
            {
              i: { x: [0.105, 0.105, 0.667], y: [0.984, 0.984, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              n: [
                "0p105_0p984_0p333_0",
                "0p105_0p984_0p333_0",
                "0p667_1_0p333_0"
              ],
              t: 12,
              s: [111.445, 111.445, 100],
              e: [100, 100, 100]
            },
            { t: 19 }
          ],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [48.469, 48.469], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "椭圆路径 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.462745098039, 0.443137254902, 0.737254901961, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: "填充 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [-0.121, 0.371], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "变换"
            }
          ],
          nm: "椭圆 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 0,
      op: 144,
      st: 0,
      bm: 0
    }
  ]
};
