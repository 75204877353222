import {
  ResourceType,
  getMeasurementGroupDefinitionList,
  getMeasurementGroupDefinition,
  updateMeasurementGroupDefinition,
  createMeasurementGroupDefinition,
  MeasurementGroupDefinitionListQueryParams,
  MeasurementGroupDefinitionListQueryParamsKey,
  getModelReferenceId
} from "@laba/nexup-api";
import {
  getEnumOrderParam,
  getKeyTargetParam,
  resourceProviderGetter
} from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { workspaceOrganizationSelector } from "store/workspace/selectors";
import { store } from "store/store";

export const measurementGroupDefinitionProvider = resourceProviderGetter(
  ResourceType.MeasurementGroupDefinition,
  getEnumOrderParam({}),
  getKeyTargetParam<MeasurementGroupDefinitionListQueryParams>(
    MeasurementGroupDefinitionListQueryParamsKey
  ),
  getMeasurementGroupDefinitionList,
  getMeasurementGroupDefinition,
  updateMeasurementGroupDefinition,
  createMeasurementGroupDefinition,
  undefined,
  () => ({
    organization: getModelReferenceId(
      workspaceOrganizationSelector(store.getState())
    )
  })
);
