import { FC, getClassName, ThemeStyleColorVariant } from "@laba/react-common";
import React from "react";
import { IconOrImg, IconOrImgType } from "components/helpers/IconOrImg";
import { useCircularIconStyles } from "./useCircularIconStyles";

interface CircularIconProps {
  className?: string;
  IconOrImgProp: IconOrImgType;
  fullSize?: boolean;
  iconColorVariant?: ThemeStyleColorVariant;
}

export const CircularIconSize = 80;

export const CircularIcon: FC<CircularIconProps> = ({
  className,
  IconOrImgProp,
  fullSize,
  iconColorVariant
}) => {
  const classes = useCircularIconStyles();
  return (
    <div className={getClassName(className, classes.root)}>
      <IconOrImg
        Logo={IconOrImgProp}
        fullSize={fullSize}
        iconColorVariant={iconColorVariant}
      />
    </div>
  );
};
