export const procedureCodeConcept = {
  title: "Codigos de procedimientos",
  fields: {
    id: "Id",
    display: "Nombre interno",
    medicalAreaCode: "Codigo area medica",
    active: "Activo",
    preferredTerm: "Término preferido",
    synonymous: "Sinónimo",
    tag: "tags",
    bodyRegion: "Región corporal",
    subBodyRegion: "Sub región corporal",
    specimen: "Especimen",
    speciality: "Especialidad",
    nomenclatureValue: "Valor nomenclatura",
    nomenclatureCode: "Codigo nomenclatura"
  },
  errors: {
    repeatedSynonymous: "El sinónimo está repetido en otro estudio",
    repeatedPreferredTerm: "El término preferido está repetido en otro estudio"
  },
  tabs: {
    procedureCodeConcept: "Codigos de procedimientos"
  }
};
