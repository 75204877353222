import { ReactElement } from "@laba/react-common";
import { MouseEventHandler } from "react";

export enum MenuType {
  Leaf,
  Parent
}

interface MenuBaseNode {
  type: MenuType;
  title?: string;
}

export interface MenuLeafNode extends MenuBaseNode {
  type: MenuType.Leaf;
  onClick: MouseEventHandler;
  title: string;
  icon?: ReactElement;
}

export interface MenuParentNode extends MenuBaseNode {
  type: MenuType.Parent;
  children: (MenuLeafNode | MenuParentNode)[];
}

export type MenuNode = MenuLeafNode | MenuParentNode;
