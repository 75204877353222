import { FC, TextVariant, getClassName } from "@laba/react-common";
import React from "react";
import { usePatientDetailDataItemStyles } from "components/headers/PatientHeader/PatientDetailDataItem/usePatientDetailDataItemStyles";
import { notUndefined } from "@laba/ts-common";
import { isEmpty } from "lodash-es";

interface Props {
  className?: string;
  principalText?: string;
  secondaryText?: string;
  textVariant?: TextVariant;
  isMobile?: boolean;
}

const getPatientDetailDataItemText = (text?: string, isMobile?: boolean) =>
  !isEmpty(text) ? text : isMobile ? undefined : "-";

export const PatientDetailDataItem: FC<Props> = ({
  className,
  principalText,
  secondaryText,
  textVariant = TextVariant.Caption,
  isMobile
}) => {
  const classes = usePatientDetailDataItemStyles({ textVariant });
  const title = [principalText, secondaryText].filter(notUndefined).join(" ");
  return (
    <div className={getClassName(className, classes.root)} title={title}>
      <p className={classes.text}>
        {getPatientDetailDataItemText(principalText, isMobile)}
      </p>
      {isMobile && !isEmpty(secondaryText) && (
        <p className={classes.text}> | </p>
      )}
      <p className={classes.text}>
        {getPatientDetailDataItemText(secondaryText, isMobile)}
      </p>
    </div>
  );
};
