import { FC } from "@laba/react-common";
import React from "react";

export const NexupTextLogo: FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="98"
    height="31"
    viewBox="0 0 98 31"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path d="M9.529 5.95314C8.63783 5.94165 7.75657 6.14103 6.95714 6.53501C6.15771 6.92899 5.46276 7.50641 4.929 8.22014L4.742 8.45614V6.38214H0V23.3321H4.739V13.7321C4.72049 13.2911 4.79153 12.8509 4.94784 12.438C5.10414 12.0252 5.34246 11.6483 5.64843 11.3301C5.9544 11.0119 6.32166 10.7589 6.72806 10.5866C7.13446 10.4142 7.57156 10.3259 8.013 10.3271C8.44692 10.3214 8.87735 10.4054 9.27728 10.5738C9.6772 10.7423 10.038 10.9916 10.337 11.3061C10.9416 11.9681 11.2691 12.8368 11.252 13.7331V23.3331H15.99V12.4151C16.0174 11.5576 15.8642 10.7038 15.5403 9.90929C15.2164 9.11475 14.7291 8.39718 14.11 7.80314C13.5118 7.20018 12.7972 6.72523 12.0097 6.40719C11.2221 6.08915 10.3781 5.93467 9.529 5.95314Z" />
    <path d="M27.902 5.953C26.711 5.91665 25.5253 6.1268 24.4193 6.57026C23.3134 7.01371 22.311 7.68091 21.475 8.53C19.8124 10.227 18.8866 12.5115 18.8986 14.8872C18.9105 17.2628 19.8594 19.5378 21.539 21.218C22.4203 22.0592 23.4597 22.7172 24.5971 23.1538C25.7345 23.5905 26.9472 23.7972 28.165 23.762C29.8609 23.7795 31.5319 23.3531 33.012 22.525C34.355 21.7543 35.4643 20.6344 36.222 19.284L31.635 18.24C31.2142 18.736 30.6858 19.1294 30.09 19.3904C29.4943 19.6514 28.8468 19.773 28.197 19.746C27.2442 19.7705 26.3095 19.4832 25.535 18.9278C24.7604 18.3724 24.1884 17.5793 23.906 16.669L23.863 16.534H36.692C36.8113 15.9834 36.8703 15.4214 36.868 14.858C36.8816 13.6798 36.6584 12.5108 36.2118 11.4205C35.7651 10.3302 35.104 9.34062 34.2677 8.51058C33.4314 7.68053 32.437 7.02689 31.3433 6.58841C30.2496 6.14994 29.0791 5.93556 27.901 5.958L27.902 5.953ZM23.896 13.082L23.939 12.945C24.197 12.0922 24.7201 11.3438 25.4324 10.8084C26.1447 10.2731 27.009 9.97872 27.9 9.968C28.8118 9.97179 29.6996 10.2605 30.4392 10.7938C31.1788 11.327 31.7333 12.0781 32.025 12.942L32.076 13.084H23.895L23.896 13.082Z" />
    <path d="M94.6962 8.42303C93.3928 6.94758 91.5689 6.03379 89.6068 5.8731C87.6446 5.71241 85.6964 6.3173 84.1702 7.56103V6.27703H79.2202V30.863H84.1702V21.983C85.4968 23.1062 87.1528 23.7672 88.8882 23.866C89.9854 23.8869 91.0737 23.6672 92.0769 23.2225C93.0801 22.7778 93.9738 22.1189 94.6952 21.292C96.2605 19.5197 97.0975 17.2209 97.0382 14.857C97.1116 12.4914 96.2732 10.188 94.6962 8.42303ZM90.7372 17.794C90.4043 18.1739 89.9929 18.4768 89.5313 18.682C89.0698 18.8871 88.5692 18.9895 88.0642 18.982C87.2322 19.0516 86.3985 18.8679 85.6727 18.4552C84.9469 18.0424 84.3629 17.4198 83.9973 16.6692C83.6317 15.9185 83.5017 15.0748 83.6242 14.2489C83.7467 13.423 84.1161 12.6534 84.6838 12.0412C85.2516 11.429 85.9912 11.0028 86.8056 10.8185C87.6199 10.6342 88.471 10.7005 89.247 11.0086C90.0231 11.3167 90.6878 11.8522 91.154 12.5449C91.6202 13.2376 91.866 14.0551 91.8592 14.89C91.8753 15.4245 91.7843 15.9568 91.5914 16.4556C91.3985 16.9543 91.1077 17.4094 90.7362 17.794H90.7372Z" />
    <path d="M41.4499 13.5351C42.659 12.471 44.113 11.723 45.6819 11.3581C44.5069 10.7261 43.8459 9.42414 43.8459 6.73615L43.8889 5.54614C43.8889 5.54614 39.3059 5.54614 39.3309 5.54614C39.3083 5.87266 39.305 6.20023 39.3209 6.52715C39.3199 7.15115 39.1969 10.8701 41.4499 13.5351Z" />
    <path d="M53.8319 14.75C52.5477 15.831 50.9811 16.5222 49.3169 16.742C50.8389 17.507 51.3699 19.152 51.3699 21.842L51.3579 22.806C51.3579 22.806 56.0289 22.771 56.0579 22.783C56.1965 21.3229 56.0749 19.8497 55.6989 18.432C55.3151 17.0976 54.6816 15.8481 53.8319 14.75Z" />
    <path d="M64.3999 6.4661C64.3978 4.8886 63.8198 3.36615 62.7745 2.18465C61.7292 1.00315 60.2886 0.243877 58.7231 0.049406C57.1577 -0.145065 55.5751 0.238644 54.2725 1.12848C52.9699 2.01833 52.037 3.35308 51.6489 4.8821H56.9789C57.2567 4.71617 57.5732 4.62628 57.8967 4.62147C58.2202 4.61665 58.5393 4.6971 58.8218 4.8547C59.1044 5.01229 59.3405 5.2415 59.5064 5.51927C59.6723 5.79704 59.7621 6.1136 59.7669 6.4371V14.0871H64.3789L64.3999 6.4661Z" />
    <path d="M70.8529 15.6362C70.8714 16.0742 70.801 16.5115 70.6458 16.9216C70.4907 17.3317 70.2541 17.7062 69.9502 18.0223C69.6464 18.3385 69.2817 18.5898 68.8781 18.7611C68.4745 18.9324 68.0404 19.0202 67.6019 19.0192C67.1707 19.025 66.7429 18.9417 66.3454 18.7744C65.9478 18.6072 65.5892 18.3596 65.2919 18.0472C64.6916 17.3898 64.3663 16.5272 64.3829 15.6372V14.7512H59.7709V16.9382C59.7454 17.776 59.8932 18.61 60.2052 19.388C60.5172 20.166 60.9865 20.8711 61.5839 21.4592C62.1755 22.0517 62.8812 22.5179 63.6584 22.8295C64.4355 23.1412 65.2678 23.2918 66.1049 23.2722C66.9789 23.2835 67.8432 23.0879 68.6272 22.7013C69.4112 22.3148 70.0926 21.7483 70.6159 21.0482L70.8499 20.7482V22.8482H75.4609V6.21216H70.8499V15.6362H70.8529Z" />
    <path d="M51.4542 6.33519H51.4602L51.5012 7.58019C51.5012 10.3682 49.9402 11.5802 47.3182 11.7362C45.2172 11.8632 43.2264 12.7194 41.6892 14.1572C40.6967 15.2466 39.9917 16.5665 39.6382 17.9972C39.2689 19.5627 39.1601 21.1783 39.3162 22.7792C39.3472 22.7672 44.0162 22.8022 44.0162 22.8022V21.7852C44.0162 17.9452 45.1302 16.4252 48.3162 16.2462C49.3683 16.2279 50.4061 15.9983 51.3677 15.5711C52.3294 15.1439 53.1954 14.5278 53.9143 13.7594C54.6332 12.991 55.1904 12.0859 55.5526 11.0979C55.9149 10.1099 56.0749 9.05922 56.0232 8.00819L56.0822 6.33419C56.1021 6.05595 56.1848 5.78584 56.3242 5.54419H51.5162C51.4791 5.80632 51.4584 6.07049 51.4542 6.33519Z" />
  </svg>
);
