import { ApiDate, getKeyObj, HttpMethod } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { ApiRequestResponse } from "request/types";
import { getIntegrationApiUrl, request } from "request/nexupRequest";

export interface FinnegansFeeIntegrationRequestBody {
  startDate?: ApiDate;
  endDate?: ApiDate;
  tableName?: string;
  windowSize?: number;
}

export const FinnegansFeeIntegrationRequestBodyParamsKey = getKeyObj(
  createHydratedMock<FinnegansFeeIntegrationRequestBody>()
);

export const finnegansFeeIntegrationMaintenanceRequest = async (
  params: FinnegansFeeIntegrationRequestBody
): Promise<ApiRequestResponse<unknown>> => {
  return request({
    method: HttpMethod.POST,
    url: getIntegrationApiUrl("/maintenance/finnegans-fee"),
    data: params
  });
};
