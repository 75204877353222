import {
  FC,
  Form,
  FormErrorData,
  FormValidator,
  useI18n
} from "@laba/react-common";
import React, { useCallback, useMemo, useState } from "react";
import { DefaultButton, Popup } from "@laba/nexup-components";
import { tkCP } from "translation/i18n";
import { useAppDispatch } from "store/store";
import { isEmpty } from "lodash-es";
import { AlfabetaMode, AlfabetaRequestBody, PriceMode } from "@laba/nexup-api";
import { onAlfabetaContractGeneration } from "store/dashboard/event";
import { AlfabetaContractGenerationFormContent } from "components/pages/Dashboard/AlfabetaContractGeneration/AlfabetaContractGenerationFormContent";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";

export interface AlfabetaContractGenerationInputProps {
  onImportFinished?: (success: boolean) => void;
}

const tk = tkCP.adminPage.dashboard.alfabetaSync;
const formId = "alfabetaContractGenerationForm";
const formValidation: FormValidator<AlfabetaRequestBody> = (t, formData) => {
  const errors: FormErrorData<AlfabetaRequestBody> = {};

  if (formData.organizationId == null) {
    errors.organizationId = t(tk.validation.requiredField);
  }

  return isEmpty(errors) ? undefined : errors;
};
export const AlfabetaContractGenerationInput: FC<
  AlfabetaContractGenerationInputProps
> = ({ onImportFinished }) => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const onSubmit = useCallback(
    async (requestData: AlfabetaRequestBody) => {
      setLoading(true);
      const result = await dispatch(onAlfabetaContractGeneration(requestData));
      setLoading(false);
      setShowPopup(false);
      onImportFinished?.(result);
    },
    [setLoading, setShowPopup, onImportFinished, dispatch]
  );
  const validate = useCallback(
    (data: AlfabetaRequestBody) => formValidation(t, data),
    [t]
  );
  const onClick = useCallback(() => setShowPopup(true), [setShowPopup]);
  const onClose = useCallback(() => setShowPopup(false), [setShowPopup]);

  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const initialValues = useMemo<AlfabetaRequestBody>(
    () => ({
      mode: AlfabetaMode.Contract,
      priceMode: PriceMode.PerUnit,
      organizationId: workspaceOrganizationId
    }),
    [workspaceOrganizationId]
  );
  return (
    <>
      <DefaultButton
        title={t(tk.alfabetaContractGenerationButton)}
        text={t(tk.alfabetaContractGenerationButton)}
        onClick={onClick}
      />
      {showPopup && (
        <Popup
          open={showPopup}
          closableHeader
          headerTitle={t(tk.alfabetaContractGenerationTitle)}
          primaryButtonText={t(tk.sync)}
          disablePrimaryButton={false}
          formId={formId}
          loading={loading}
          onClose={onClose}
          disableBackdropClick
        >
          <Form<AlfabetaRequestBody>
            id={formId}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
          >
            <AlfabetaContractGenerationFormContent />
          </Form>
        </Popup>
      )}
    </>
  );
};
