import { FC, getClassName } from "@laba/react-common";
import { useDetailCheckboxStyles } from "components/detail/DetailCheckbox/useDetailCheckboxStyles";
import React from "react";
import { CheckIcon } from "components/icons";
import { TextVariant } from "model/themeVariant";
import { CheckboxTextVariant } from "components/inputs/Checkbox/Checkbox";

export interface DetailCheckboxProps {
  className?: string;
  checked?: boolean;
  text?: string;
  title?: string;
  textVariant?: CheckboxTextVariant;
}

export const DetailCheckbox: FC<DetailCheckboxProps> = ({
  className,
  checked,
  title,
  text,
  textVariant = TextVariant.Subtitle2
}) => {
  const classes = useDetailCheckboxStyles();

  return (
    <div className={getClassName(className, classes.root)} title={title}>
      {checked ? (
        <CheckIcon className={classes.checkbox} />
      ) : (
        <div className={classes.checkbox} />
      )}
      {text && (
        <p
          className={getClassName(
            classes.text,
            textVariant === TextVariant.Body2
              ? classes.body2TextVariant
              : classes.subtitle2TextVariant
          )}
        >
          {text}
        </p>
      )}
    </div>
  );
};
