import { ApiDate, getKeyObj, Optional } from "@laba/ts-common";
import { isUndefined } from "lodash-es";
import {
  getIdentifierValueBySystem,
  Identifier,
  KnownIdentifierSystem
} from "model/primitives";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { referenceIsModel } from "model/primitives/modelReference/utils";
import { ResourceModel, ResourceType } from "model/primitives/resourceModel";
import { PublicationStatus } from "model/resource/activity/activityDefinition";
import { Code } from "model/resource/entities/codeSystem";
import { Organization } from "model/resource/entities/organization/organization";
import { Practitioner } from "model/resource/person/practitioner/practitioner";
import { createHydratedMock } from "ts-auto-mock";

export interface Money {
  value?: number;
  currency?: Code;
}

export const MoneyKey = getKeyObj<Money>(createHydratedMock<Money>());

export interface ProductStock {
  quantity?: number;
  hasStock?: boolean;
}

export interface Product extends ResourceModel<ResourceType.Product> {
  title?: string;
  description?: string;
  status?: PublicationStatus;
  price?: Money;
  copay?: Money;
  category?: Code;
  subcategory?: Code;
  tag?: Code[];
  lastEditDate?: ApiDate;
  organization?: ModelReference<Organization>;
  originalPractitioner?: ModelReference<Practitioner>;
  lastEditor?: ModelReference<Practitioner>;
  identifier?: Identifier[];
  stock?: ProductStock;
  onlyForBillingContract?: boolean;
}

export const ProductKey = getKeyObj<Product>(createHydratedMock<Product>());

export const createBaseEmptyProduct = (
  category?: Code,
  organization?: ModelReference<Organization>,
  productTitle?: string,
  currencyTypeValue?: string
): Product => {
  return {
    title: productTitle,
    resourceType: ResourceType.Product,
    status: PublicationStatus.Active,
    identifier: [],
    category,
    organization,
    tag: [],
    copay: { currency: currencyTypeValue },
    price: { currency: currencyTypeValue }
  };
};

export const isProductActive = (product?: Product): boolean =>
  product?.status === PublicationStatus.Active;

export const isEmptyDebtMoney = (data?: Money): boolean => {
  return (
    data === undefined || isUndefined(data.value) || isUndefined(data.currency)
  );
};

export const isEmptyOrZeroDebtMoney = (data?: Money): boolean => {
  return isEmptyDebtMoney(data) || data?.value === 0;
};

export const filterProductListByActive = (
  productList: ModelReference<Product>[]
): Product[] =>
  productList.filter(referenceIsModel).filter(p => isProductActive(p));

export const hasProductAvailableStock = (product: Product): boolean => {
  if (!product.stock?.hasStock) return false;
  return product.stock.quantity !== undefined && product.stock.quantity > 0;
};

export const getProductStockIfAvailable = (
  product?: Product
): Optional<number> => {
  if (!product?.stock?.hasStock) return undefined;
  return product.stock.quantity ?? 0;
};

export const getProductCode = (product?: Product): Optional<Code> => {
  return getIdentifierValueBySystem(
    KnownIdentifierSystem.ProductCode,
    product?.identifier
  );
};
