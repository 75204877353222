export enum Languages {
  Es = "es",
  En = "en"
}

export enum Namespaces {
  Translation = "translation"
}

export enum Modules {
  Common = "common"
}

export enum Sections {
  Page = "page",
  Component = "component"
}
