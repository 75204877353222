import React from "react";
import { ReactElement, useI18n } from "@laba/react-common";
import { tkCC } from "translation/i18n";
import { EmailConfigKey, NotificationConfigActionCode } from "@laba/nexup-api";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { TextInput } from "components/generic/TextInput/TextInput";
import { ThymeleafInput } from "components/generic/ThymeleafInput/ThymeleafInput";
import { NumberInput } from "components/generic/NumberInput/TextInput";

export interface EmailConfigInputProps {
  source: string;
  label: string;
}

const tk = tkCC.inputs.emailConfigInput;
export const EmailConfigInput = ({
  source,
  label
}: EmailConfigInputProps): ReactElement => {
  const { t } = useI18n();

  return (
    <ArrayInput source={source} label={label}>
      <ArrayInput
        source={EmailConfigKey.actionCode}
        label={t(tk.emailConfigCode)}
      >
        <EnumSelectorInput
          source=""
          label={t(tk.emailConfigCode)}
          enumValue={NotificationConfigActionCode}
          withCreate
        />
      </ArrayInput>
      <TextInput
        source={EmailConfigKey.emailId}
        label={t(tk.emailConfigEmailId)}
      />
      <ArrayInput
        source={EmailConfigKey.recipientEmailList}
        label={t(tk.emailConfigEmail)}
      >
        <TextInput source="" label={t(tk.emailConfigEmailElement)} />
      </ArrayInput>
      <ThymeleafInput
        source={EmailConfigKey.subjectTemplate}
        label={t(tk.emailConfigSubjectTemplate)}
      />
      <ThymeleafInput
        source={EmailConfigKey.bodyTemplate}
        label={t(tk.emailConfigBodyTemplate)}
      />
      <NumberInput
        source={EmailConfigKey.waitDelay}
        label={t(tk.emailConfigWaitDelay)}
        min={0}
        step={1}
      />
    </ArrayInput>
  );
};
