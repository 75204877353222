import { FC } from "@laba/react-common";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import React from "react";

export const ShareScreenIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.25 4C3.65326 4 3.08097 4.23705 2.65901 4.65901C2.23705 5.08097 2 5.65326 2 6.25V17.75C2 18.0455 2.0582 18.3381 2.17127 18.611C2.28434 18.884 2.45008 19.1321 2.65901 19.341C2.86794 19.5499 3.11598 19.7157 3.38896 19.8287C3.66194 19.9418 3.95453 20 4.25 20H19.75C20.0455 20 20.3381 19.9418 20.611 19.8287C20.884 19.7157 21.1321 19.5499 21.341 19.341C21.5499 19.1321 21.7157 18.884 21.8287 18.611C21.9418 18.3381 22 18.0455 22 17.75V6.25C22 5.65326 21.7629 5.08097 21.341 4.65901C20.919 4.23705 20.3467 4 19.75 4H4.25ZM12.53 7.465L15.785 10.72C15.8587 10.7887 15.9178 10.8715 15.9588 10.9635C15.9998 11.0555 16.0218 11.1548 16.0236 11.2555C16.0254 11.3562 16.0068 11.4562 15.9691 11.5496C15.9314 11.643 15.8753 11.7278 15.804 11.799C15.7328 11.8703 15.648 11.9264 15.5546 11.9641C15.4612 12.0018 15.3612 12.0204 15.2605 12.0186C15.1598 12.0168 15.0605 11.9948 14.9685 11.9538C14.8765 11.9128 14.7937 11.8537 14.725 11.78L12.75 9.806V16.253C12.75 16.4519 12.671 16.6427 12.5303 16.7833C12.3897 16.924 12.1989 17.003 12 17.003C11.8011 17.003 11.6103 16.924 11.4697 16.7833C11.329 16.6427 11.25 16.4519 11.25 16.253V9.808L9.28 11.78C9.21134 11.8537 9.12854 11.9128 9.03654 11.9538C8.94454 11.9948 8.84522 12.0168 8.74452 12.0186C8.64382 12.0204 8.54379 12.0018 8.4504 11.9641C8.35701 11.9264 8.27218 11.8703 8.20096 11.799C8.12974 11.7278 8.0736 11.643 8.03588 11.5496C7.99816 11.4562 7.97963 11.3562 7.98141 11.2555C7.98319 11.1548 8.00523 11.0555 8.04622 10.9635C8.08721 10.8715 8.14631 10.7887 8.22 10.72L11.47 7.466C11.6106 7.32555 11.8012 7.24666 12 7.24666C12.1988 7.24666 12.3894 7.32455 12.53 7.465Z"
          fill={props.fill ?? "white"}
        />
      </svg>
    </SvgIcon>
  );
};
