import React from "react";
import { ResourceType, Schedule } from "@laba/nexup-api";
import { FC } from "@laba/react-common";
import { useElementExportedFileName } from "components/hook/useExportedFileName";
import { Edit } from "components/generic/Edit/Edit";
import {
  ScheduleForm,
  scheduleJsonToModel,
  scheduleTransform
} from "components/pages/NexupAdmin/resources/schedule/ScheduleForm";

export const ScheduleEdit: FC = () => {
  const exportedFileName = useElementExportedFileName<Schedule>(
    resource => resource.name
  );
  return (
    <Edit
      resourceTransform={scheduleTransform}
      resource={ResourceType.Schedule}
      jsonToModel={scheduleJsonToModel}
      exportedFileName={exportedFileName}
    >
      <ScheduleForm />
    </Edit>
  );
};
