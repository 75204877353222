import { ReactElement, useI18n } from "@laba/react-common";
import React, { useCallback } from "react";
import { ModelId, PermissionConfigKey } from "@laba/nexup-api";
import { tkCC } from "translation/i18n";
import { AppConfigListInput } from "./AppConfigListInput/AppConfigListInput";
import { PermissionSpecialityListInput } from "./PermissionSpecialityListInput/PermissionSpecialityListInput";

const tk = tkCC.inputs.permissionConfigInput;

interface PermissionConfigInputProps {
  source: string;
  organizationId?: ModelId;
}

export const PermissionConfigInput = ({
  source,
  organizationId
}: PermissionConfigInputProps): ReactElement => {
  const { t } = useI18n();

  const getElementSource = useCallback(
    (key: string) => [source, key].join("."),
    [source]
  );

  return (
    <>
      <AppConfigListInput
        label={t(tk.creationRequiredAppConfig)}
        source={getElementSource(
          PermissionConfigKey.createAppConfig.requiredAppConfigList
        )}
        organizationId={organizationId}
      />
      <AppConfigListInput
        label={t(tk.creationExcludedAppconfig)}
        source={getElementSource(
          PermissionConfigKey.createAppConfig.excludedAppConfigList
        )}
        organizationId={organizationId}
      />
      <AppConfigListInput
        label={t(tk.editionRequiredAppConfig)}
        source={getElementSource(
          PermissionConfigKey.editAppConfig.requiredAppConfigList
        )}
        organizationId={organizationId}
      />
      <AppConfigListInput
        label={t(tk.editionExcludedAppconfig)}
        source={getElementSource(
          PermissionConfigKey.editAppConfig.excludedAppConfigList
        )}
        organizationId={organizationId}
      />
      <PermissionSpecialityListInput
        label={t(tk.specialityRequiredPermission)}
        source={getElementSource(
          PermissionConfigKey.specialityConfig.requiredSpecialityList
        )}
        organizationId={organizationId}
      />
      <PermissionSpecialityListInput
        label={t(tk.specialityExcludedPermission)}
        source={getElementSource(
          PermissionConfigKey.specialityConfig.excludedSpecialityList
        )}
        organizationId={organizationId}
      />
    </>
  );
};
