import {
  ResourceType,
  getCodeSystemList,
  getCodeSystem,
  updateCodeSystem,
  createCodeSystem,
  CodeSystemListQueryParams,
  CodeSystemListQueryParamsKey,
  getModelReferenceId
} from "@laba/nexup-api";
import {
  getEnumOrderParam,
  getKeyTargetParam,
  resourceProviderGetter
} from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { store } from "store/store";
import { updateOrganizationCodeSystem } from "store/workspace/events";
import { workspaceOrganizationSelector } from "store/workspace/selectors";

export const codeSystemProvider = resourceProviderGetter(
  ResourceType.CodeSystem,
  getEnumOrderParam({}),
  getKeyTargetParam<CodeSystemListQueryParams>(CodeSystemListQueryParamsKey),
  getCodeSystemList,
  getCodeSystem,
  updateCodeSystem,
  createCodeSystem,
  data => {
    store.dispatch(updateOrganizationCodeSystem(data));
  },
  () => ({
    organization: getModelReferenceId(
      workspaceOrganizationSelector(store.getState())
    )
  })
);
