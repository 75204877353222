import {
  ResourceType,
  getPatientTagList,
  getPatientTag,
  updatePatientTag,
  createPatientTag,
  PatientTagListQueryParams,
  PatientTagListQueryParamsKey,
  getModelReferenceId
} from "@laba/nexup-api";
import {
  getEnumOrderParam,
  getKeyTargetParam,
  resourceProviderGetter
} from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { workspaceOrganizationSelector } from "store/workspace/selectors";
import { store } from "store/store";

export const patientTagProvider = resourceProviderGetter(
  ResourceType.PatientTag,
  getEnumOrderParam({}),
  getKeyTargetParam<PatientTagListQueryParams>(PatientTagListQueryParamsKey),
  getPatientTagList,
  getPatientTag,
  updatePatientTag,
  createPatientTag,
  undefined,
  () => ({
    organization: getModelReferenceId(
      workspaceOrganizationSelector(store.getState())
    )
  })
);
