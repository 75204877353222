import {
  borderMixin,
  createStyle,
  getClassName,
  pxToRem
} from "@laba/react-common";
import { getThemeStyleColor } from "model/themeUtils";
import { StyleVariant } from "model/themeVariant";
import { Theme, ThemeStyleColorVariant } from "model/theme";

export enum DividerOrientation {
  Vertical = "vertical",
  Horizontal = "horizontal"
}

export enum DividerSize {
  Small = "Small",
  Medium = "Medium",
  Large = "large"
}
export interface DividerStyleProps {
  size: DividerSize;
  orientation: DividerOrientation;
  colorVariant: ThemeStyleColorVariant;
  dashed: boolean;
}

const getRemBySize = (size: DividerSize): string => {
  switch (size) {
    case DividerSize.Small:
      return pxToRem(1);
    case DividerSize.Medium:
      return pxToRem(2);
    case DividerSize.Large:
      return pxToRem(4);
    default:
      return pxToRem(1);
  }
};

const useDividerStaticStyle = createStyle(
  (_theme: Theme) => ({
    inheritWidth: {
      width: "inherit"
    },
    inheritHeight: {
      height: "inherit"
    },
    smallWidth: {
      width: getRemBySize(DividerSize.Small)
    },
    mediumWidth: {
      width: getRemBySize(DividerSize.Medium)
    },
    largeWidth: {
      width: getRemBySize(DividerSize.Large)
    },
    smallHeight: {
      height: getRemBySize(DividerSize.Small)
    },
    mediumHeight: {
      height: getRemBySize(DividerSize.Medium)
    },
    largeHeight: {
      height: getRemBySize(DividerSize.Large)
    }
  }),
  "Divider"
);

const useDividerDynamicStyle = createStyle(
  (theme: Theme) => ({
    color: (props: DividerStyleProps) => ({
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        props.colorVariant
      )
    }),
    dashed: (props: DividerStyleProps) => ({
      backgroundColor: "transparent",
      ...borderMixin({
        side: {
          top:
            props.orientation === DividerOrientation.Horizontal
              ? getRemBySize(props.size)
              : 0,
          right:
            props.orientation === DividerOrientation.Vertical
              ? getRemBySize(props.size)
              : 0,
          bottom: 0,
          left: 0
        },
        style: "dashed",
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          props.colorVariant
        )
      })
    })
  }),
  "Divider"
);

interface UseDividerStyleReturn {
  root: string;
  dashed: string;
}

export const useDividerStyle = (
  props: DividerStyleProps
): UseDividerStyleReturn => {
  const staticClasses = useDividerStaticStyle();
  const dynamicClasses = useDividerDynamicStyle(props);

  const getHeightClassNameBySizeAndOrientation = (
    orientation: DividerOrientation,
    size: DividerSize
  ): string => {
    if (orientation === DividerOrientation.Vertical)
      return staticClasses.inheritHeight;

    switch (size) {
      case DividerSize.Small:
        return staticClasses.smallHeight;
      case DividerSize.Medium:
        return staticClasses.mediumHeight;
      case DividerSize.Large:
        return staticClasses.largeHeight;
      default:
        return staticClasses.smallHeight;
    }
  };

  const getWidthClassNameBySizeAndOrientation = (
    orientation: DividerOrientation,
    size: DividerSize
  ): string => {
    if (orientation === DividerOrientation.Horizontal)
      return staticClasses.inheritWidth;

    switch (size) {
      case DividerSize.Small:
        return staticClasses.smallWidth;
      case DividerSize.Medium:
        return staticClasses.mediumWidth;
      case DividerSize.Large:
        return staticClasses.largeWidth;
      default:
        return staticClasses.smallWidth;
    }
  };
  return {
    root: getClassName(
      dynamicClasses.color,
      getHeightClassNameBySizeAndOrientation(props.orientation, props.size),
      getWidthClassNameBySizeAndOrientation(props.orientation, props.size),
      props.dashed ? dynamicClasses.dashed : undefined
    ),
    dashed: dynamicClasses.dashed
  };
};
