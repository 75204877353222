import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  Day,
  getModelReferenceId,
  HealthcareServiceKey,
  HealthcareServiceTemplateKey,
  HealthcareServiceType,
  KnownCodeSystemSystem,
  KnownLocationCategory,
  Location,
  LocationContainerKey,
  LocationListQueryParamsKey,
  ModelReference,
  Organization,
  OrganizationCombinedType,
  OrganizationListQueryParamsKey,
  ResourceType,
  TemplateCode
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { ThymeleafInput } from "components/generic/ThymeleafInput/ThymeleafInput";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { locationOptionText } from "components/generic/ReferenceResourceInput/optionText";
import { UploadFileImageInput } from "components/generic/UploadFileImageInput/UploadFileImageInput";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

export const tk = tkCP.adminPage[ResourceType.HealthcareService];
export const HealthcareServiceContent: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const serviceOrganization = useWatchResourceForm<
    ModelReference<Organization>
  >(HealthcareServiceKey.organization);
  const organizationId =
    getModelReferenceId(serviceOrganization) || workspaceOrganizationId;
  const codeSystemSystemSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.CodeSystemSystem,
    organizationId
  );
  const categorySystem = useCodeSystemGroup(
    KnownCodeSystemSystem.HealthcareServiceCategory,
    organizationId
  );

  return (
    <>
      <TextInput
        disabled
        source={HealthcareServiceKey.id}
        label={t(tk.fields.id)}
      />
      <OrganizationReferenceInput
        source={HealthcareServiceKey.organization}
        label={t(tk.fields.organization)}
        type={OrganizationCombinedType.ProviderAndGroup}
        extraFilters={{
          [OrganizationListQueryParamsKey.active]: true
        }}
        defaultValue={workspaceOrganizationId}
        disabled={workspaceOrganizationId !== undefined}
      />
      <TextInput source={HealthcareServiceKey.name} label={t(tk.fields.name)} />
      <EnumSelectorInput
        source={HealthcareServiceKey.type}
        label={t(tk.fields.type)}
        enumValue={HealthcareServiceType}
      />
      <CodeSystemSelectorInput
        source={HealthcareServiceKey.category}
        label={t(tk.fields.category)}
        codeSystem={categorySystem}
        organizationId={organizationId}
      />
      <BooleanInput
        source={HealthcareServiceKey.active}
        label={t(tk.fields.active)}
      />
      <TextInput
        source={HealthcareServiceKey.description}
        label={t(tk.fields.description)}
      />
      <UploadFileImageInput
        source={HealthcareServiceKey.photo.url}
        label={t(tk.fields.photoUrl)}
        organization={organizationId}
      />
      <TextInput
        source={HealthcareServiceKey.photo.name}
        label={t(tk.fields.photoText)}
      />
      <BooleanInput
        source={HealthcareServiceKey.organizationDefault}
        label={t(tk.fields.organizationDefault)}
      />
      <ArrayInput
        source={HealthcareServiceKey.location}
        label={t(tk.fields.locationList)}
      >
        <ReferenceResourceInput<Location>
          source={LocationContainerKey.location}
          label={t(tk.fields.locationListElement)}
          resourceType={ResourceType.Location}
          optionTextKey={locationOptionText}
          extraFilters={{
            [LocationListQueryParamsKey.organization]: organizationId,
            [LocationListQueryParamsKey.category]:
              KnownLocationCategory.Hospitalization
          }}
        />
      </ArrayInput>
      <ArrayInput
        source={HealthcareServiceKey.availableTime.daysOfWeek}
        label={t(tk.fields.availableTimeDaysOfWeek)}
      >
        <EnumSelectorInput
          source=""
          label={t(tk.fields.availableTimeDaysOfWeekElement)}
          enumValue={Day}
        />
      </ArrayInput>
      <BooleanInput
        source={HealthcareServiceKey.availableTime.allDay}
        label={t(tk.fields.availableTimeAllDay)}
      />
      <TextInput
        source={HealthcareServiceKey.availableTime.startTime}
        label={t(tk.fields.availableTimeStartTime)}
      />
      <TextInput
        source={HealthcareServiceKey.availableTime.endTime}
        label={t(tk.fields.availableTimeEndTime)}
      />
      <CodeSystemSelectorInput
        source={HealthcareServiceKey.consultationReasonSystem}
        label={t(tk.fields.consultationReasonSystem)}
        codeSystem={codeSystemSystemSystem}
        organizationId={organizationId}
        withCreate
      />
      <TextInput
        source={HealthcareServiceKey.feedbackUrl}
        label={t(tk.fields.feedbackUrl)}
      />
      <ArrayInput
        source={HealthcareServiceKey.templateList}
        label={t(tk.fields.templateList)}
      >
        <EnumSelectorInput
          source={HealthcareServiceTemplateKey.code}
          label={t(tk.fields.templateListCode)}
          enumValue={TemplateCode}
          withCreate
        />
        <ThymeleafInput
          source={HealthcareServiceTemplateKey.template}
          label={t(tk.fields.templateListTemplate)}
        />
      </ArrayInput>
    </>
  );
};
