import { ModelReference } from "model/primitives/modelReference/modelReference";
import { Attachment } from "model/primitives/attachment/attachment";
import { ResourceType } from "model/primitives/resourceModel";
import { getModelReferenceId } from "model/primitives/modelReference/utils";
import { Practitioner } from "model/resource/person/practitioner/practitioner";
import { Patient } from "model/resource/person/patient/patient";
import { Hospitalization } from "model/resource/medical/hospitalization/hospitalization";
import {
  PatientMediaContainer,
  PatientMediaStatus
} from "model/resource/file/patientMedia/patientMedia";

export const buildPatientMediaContainer = (
  patient: ModelReference<Patient>,
  practitioner: ModelReference<Practitioner>,
  hospitalization: ModelReference<Hospitalization>,
  file: Attachment
): PatientMediaContainer => {
  return {
    file: {
      resourceType: ResourceType.PatientMedia,
      status: PatientMediaStatus.Completed,
      hospitalization: getModelReferenceId(hospitalization),
      practitioner,
      patient,
      file
    }
  };
};
