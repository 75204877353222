import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { StyleVariant, TextVariant } from "model/themeVariant";

export const useGenericPlaceholderStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexColumnMixin({
        gap: 24,
        alignItems: FlexAlignItems.Center
      })
    },
    imageContainer: { objectFit: "cover" },
    textContainer: {
      ...flexColumnMixin({
        gap: 8,
        alignItems: FlexAlignItems.Center
      })
    },
    textAndButtonContainer: {
      ...flexColumnMixin({ gap: 16 }),
      alignItems: FlexAlignItems.Center
    },
    description: {
      ...themeTextMixin(theme, TextVariant.Subtitle1),
      textAlign: "center",
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    prompt: {
      ...themeTextMixin(theme, TextVariant.Body2),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "GenericPlaceholderStyles"
);
