import { ModelId } from "model/primitives/model/model";
import { HttpMethod } from "@laba/ts-common";
import { getFrontApiUrl, request } from "request/nexupRequest";
import {
  CurrentVirtualGuardEncounter,
  CurrentVirtualGuardEncounterQueryParams,
  VirtualGuardEncounter
} from "model/resource/entities/encounter/virtualGuardEncounter";
import { VirtualGuardJoinData } from "model/resource/virtualGuard/virtualGuardJoinData";
import { virtualGuardEncounterBasePath } from "api/entities/encounter/virtualGuardEncounter";
import { ApiRequestResponse } from "request/types";

enum VirtualGuardStatusUpdateActions {
  PractitionerArrived = "PractitionerArrived",
  EncounterStarted = "EncounterStarted",
  EncounterFinished = "EncounterFinished",
  PractitionerCancelled = "PractitionerCancelled"
}

const buildVGEStatusUpdateUrl = (id: ModelId): string =>
  getFrontApiUrl(`${virtualGuardEncounterBasePath}/${id}/status-update`);

const buildJoinDataUrl = (id: ModelId): string =>
  getFrontApiUrl(`${virtualGuardEncounterBasePath}/${id}/join`);

const updateVirtualGuardEncounterStatus = (
  encounterId: ModelId,
  action: VirtualGuardStatusUpdateActions
): Promise<ApiRequestResponse<VirtualGuardEncounter>> =>
  request({
    method: HttpMethod.POST,
    url: buildVGEStatusUpdateUrl(encounterId),
    data: { action }
  });

export const cancelVirtualGuardEncounter = (
  encounterId: ModelId
): Promise<ApiRequestResponse<VirtualGuardEncounter>> =>
  updateVirtualGuardEncounterStatus(
    encounterId,
    VirtualGuardStatusUpdateActions.PractitionerCancelled
  );

export const joinVirtualGuardEncounter = (
  encounterId: ModelId
): Promise<ApiRequestResponse<VirtualGuardJoinData>> => {
  return request({
    method: HttpMethod.POST,
    url: buildJoinDataUrl(encounterId)
  });
};

export const getCurrentVirtualGuardEncounter = async (
  params?: CurrentVirtualGuardEncounterQueryParams
): Promise<ApiRequestResponse<CurrentVirtualGuardEncounter>> => {
  return request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${virtualGuardEncounterBasePath}/current`),
    params
  });
};
