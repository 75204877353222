import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  flexRowMixin,
  getClassName,
  marginMixin
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { StyleVariant, TextVariant } from "model/themeVariant";
import { Theme, ThemeStyleColorVariant } from "model/theme";

export interface DateTimeInputStyleProps {
  hasError: boolean;
  fullWidth: boolean;
  row?: boolean;
  titleVariant: TextVariant;
}

interface DateTimeInputStylesClassNames {
  root?: string;
  container?: string;
  errorText?: string;
  title?: string;
  inputContainer?: string;
}

export const useDateTimeInputStaticStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexColumnMixin({ gap: 4 })
    },
    containerColumn: {
      ...flexColumnMixin({
        alignItems: FlexAlignItems.FlexStart,
        gap: 16
      }),
      width: "100%"
    },
    containerRow: {
      ...flexRowMixin({
        gap: 16
      }),
      width: "100%"
    },
    errorText: {
      ...themeTextMixin(theme, TextVariant.Caption),
      ...marginMixin({ leftRight: 14, top: 4 })
    },
    errorTextColorDanger: {
      color: getThemeStyleColor(theme, StyleVariant.Danger)
    },
    errorTextColorGrayDark: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    title: {
      ...marginMixin({ all: 0 }),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    inputContainer: {
      ...flexColumnMixin({ gap: 8 })
    },
    fullWidthContainer: { width: "100%" },
    autoWidthContainer: { width: "auto" }
  }),
  "DateTimeInputStaticStyle"
);

export const useDateTimeInputDynamicStyle = createStyle(
  (theme: Theme) => ({
    titleVariant: (props: DateTimeInputStyleProps) => ({
      ...themeTextMixin(theme, props.titleVariant, 1)
    })
  }),
  "DateTimeInputDynamicStyle"
);

export const useDateInputStyles = (
  props: DateTimeInputStyleProps
): DateTimeInputStylesClassNames => {
  const staticClasses = useDateTimeInputStaticStyle();
  const dynamicClasses = useDateTimeInputDynamicStyle(props);

  const { row } = props;

  return {
    root: getClassName(
      staticClasses.root,
      props.fullWidth ? staticClasses.fullWidthContainer : undefined
    ),
    container: row ? staticClasses.containerRow : staticClasses.containerColumn,
    errorText: getClassName(
      staticClasses.errorText,
      props.hasError
        ? staticClasses.errorTextColorDanger
        : staticClasses.errorTextColorGrayDark
    ),
    title: getClassName(staticClasses.title, dynamicClasses.titleVariant),
    inputContainer: getClassName(
      staticClasses.inputContainer,
      props.fullWidth
        ? staticClasses.fullWidthContainer
        : staticClasses.autoWidthContainer
    )
  };
};
