import { MedicalRequestWithExtraData } from "model/resource/prescription/medicalRequest/medicalRequestWithExtraData";
import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { ApiDate, HttpMethod } from "@laba/ts-common";
import { ModelId } from "model/primitives/model/model";
import { MedicalRequestStatus } from "model/resource/prescription/medicationRequest/medicationRequest";
import { MedicalRequestDefinitionFlowConfig } from "model/resource/prescription/medicalRequestDefinition/medicalRequestDefinition";
import { HospitalizationType } from "api/medical/hospitalization";
import { medicalRequestBasePath } from "./medicalRequest";

export enum MedicalRequestWithExtraDataListOrder {
  CreationDate = "creationDate",
  UpdateDate = "updateDate",
  TypeUpdateDate = "typeUpdateDate",
  Category = "category"
}

export interface MedicalRequestWithExtraDataDetailQueryParams {
  withPatient?: boolean;
  withHealthcarePayer?: boolean;
  withDefinition?: boolean;
  withEncounter?: boolean;
  withPrescription?: boolean;
  withAdministration?: boolean;
  withPractitioner?: boolean;
  withMedicalDevice?: boolean;
  withMedication?: boolean;
  withDiagnosis?: boolean;
}

export interface MedicalRequestWithExtraDataListQueryParams
  extends MedicalRequestWithExtraDataDetailQueryParams,
    ListQueryParams {
  category?: string;
  content?: string;
  startDate?: ApiDate;
  endDate?: ApiDate;
  creationEditionStartDate?: ApiDate;
  creationEditionEndDate?: ApiDate;
  hospitalizationType?: HospitalizationType;
  order?: MedicalRequestWithExtraDataListOrder;
  organization?: ModelId;
  patient?: ModelId;
  practitioner?: ModelId;
  status?: MedicalRequestStatus[];
  type?: string;
  flowConfig?: MedicalRequestDefinitionFlowConfig[];
}

const medicalRequestWithExtraDataBasePath = "/extra";

export const getMedicalRequestWithExtraData = (
  id: ModelId,
  params: MedicalRequestWithExtraDataDetailQueryParams
): Promise<ApiRequestResponse<MedicalRequestWithExtraData>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(
      `${medicalRequestBasePath}/${id}${medicalRequestWithExtraDataBasePath}`
    ),
    params
  });

export const getMedicalRequestWithExtraDataList = (
  params: MedicalRequestWithExtraDataListQueryParams
): Promise<ApiPageRequestResponse<MedicalRequestWithExtraData>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(
      `${medicalRequestBasePath}${medicalRequestWithExtraDataBasePath}`
    ),
    params
  });
export const createMedicalRequestWithExtraData = (
  data: MedicalRequestWithExtraData
): Promise<ApiRequestResponse<MedicalRequestWithExtraData>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(
      `${medicalRequestBasePath}${medicalRequestWithExtraDataBasePath}`
    ),
    data
  });

export const updateMedicalRequestWithExtraData = (
  id: ModelId,
  data: MedicalRequestWithExtraData
): Promise<ApiRequestResponse<MedicalRequestWithExtraData>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(
      `${medicalRequestBasePath}/${id}${medicalRequestWithExtraDataBasePath}`
    ),
    data
  });

export const restoreMedicalRequestWithExtraData = (
  id: ModelId,
  data: MedicalRequestWithExtraData
): Promise<ApiRequestResponse<MedicalRequestWithExtraData>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(
      `${medicalRequestBasePath}/${id}${medicalRequestWithExtraDataBasePath}/restore`
    ),
    data
  });
