import {
  createStyle,
  flexColumnMixin,
  flexRowMixin,
  marginMixin,
  StyleVariant,
  TextVariant,
  Theme
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { ThemeStyleColorVariant } from "model/theme";

export interface ProblemInputStyleProps {
  hasError: boolean;
}

export const useProblemInputStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexColumnMixin({ gap: 8 })
    },
    inputsContainer: {
      ...flexRowMixin({ gap: 8 })
    },
    inputTitle: {
      ...themeTextMixin(theme, TextVariant.Subtitle2, 1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    createButton: {
      minWidth: "max-content"
    },
    errorText: (props: ProblemInputStyleProps) => ({
      color: props.hasError
        ? getThemeStyleColor(theme, StyleVariant.Danger)
        : getThemeStyleColor(
            theme,
            StyleVariant.Primary,
            ThemeStyleColorVariant.GrayDark
          ),
      ...themeTextMixin(theme, TextVariant.Caption),
      ...marginMixin({ leftRight: 14, top: 4 })
    })
  }),
  "ProblemInput"
);
