import React from "react";
import { FC } from "@laba/react-common";
import { ButtonBase } from "components/buttons/ButtonBase/ButtonBase";
import { VisibilityIcon, VisibilityOffIcon } from "components/icons";
import { Noop } from "@laba/ts-common";

interface Props {
  onClick: Noop;
  isVisible: boolean;
  makeVisibleText?: string;
  makeInvisibleText?: string;
}

export const PasswordVisibilityButton: FC<Props> = ({
  isVisible,
  onClick,
  makeInvisibleText,
  makeVisibleText
}) => {
  return (
    <ButtonBase
      title={isVisible ? makeInvisibleText : makeVisibleText}
      onClick={onClick}
    >
      {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
    </ButtonBase>
  );
};
