import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  FlexJustifyContent
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { StyleVariant, TextVariant } from "model/themeVariant";

export const useCurrentTimeStyle = createStyle(
  (theme: Theme) => ({
    currentTimeContainer: {
      ...flexColumnMixin({
        justifyContent: FlexJustifyContent.Center,
        alignItems: FlexAlignItems.FlexEnd
      }),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      ),
      height: "100%"
    },
    currentTime: {
      ...themeTextMixin(theme, TextVariant.Subtitle2, 1),
      textTransform: "uppercase"
    }
  }),
  "CurrentTime"
);
