import {
  createStyle,
  flexColumnMixin,
  marginMixin,
  StyleVariant,
  TextVariant,
  Theme
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { ThemeStyleColorVariant } from "model/theme";

export const useSelectedProblemListStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...marginMixin({ top: 24 }),
      ...flexColumnMixin({ gap: 8 })
    },
    title: {
      ...themeTextMixin(theme, TextVariant.Subtitle2),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "SelectedProblemList"
);
