import { FC } from "@laba/react-common";
import React from "react";
import { OdontogramIconProps } from "components/icons";

export const OdontogramItemIcon: FC<OdontogramIconProps> = props => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#4F4F4F" />
      <path d="M5 27L10.5 21.5" stroke="#4F4F4F" />
      <path d="M21.5 10.5L27 5" stroke="#4F4F4F" />
      <path d="M5 5L10 10" stroke="#4F4F4F" />
      <path d="M21.5 21.5L27 27" stroke="#4F4F4F" />
      <rect x="8" y="8" width="16" height="16" rx="8" stroke="#4F4F4F" />
    </svg>
  );
};
