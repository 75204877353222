import {
  MedicalRequestDefinition,
  MedicalRequestDefinitionFlowConfig
} from "model/resource/prescription/medicalRequestDefinition/medicalRequestDefinition";
import { ResourceType } from "model/primitives/resourceModel";
import { PublicationStatus } from "model/resource/activity/activityDefinition";

export const MedicalRequestDefinitionMock: MedicalRequestDefinition = {
  id: "1952",
  name: "ProcedureReport1",
  type: "Medical",
  category: "Test feature",
  fields: [],
  medicalDevice: [],
  organization: "10",
  templateList: [],
  status: PublicationStatus.Active,
  creationDate: "2022-10-14T19:20:33.658Z",
  emailConfig: [],
  color: "#E0FFFF",
  flowConfig: [MedicalRequestDefinitionFlowConfig.IsProcedureReport],
  resourceType: ResourceType.MedicalRequestDefinition
};
