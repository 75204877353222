import { useFieldArray as useFinalFormArrayField } from "react-final-form-arrays";
import {
  CustomFieldArrayRenderProps,
  HasElementType,
  RemoveListType,
  RemoveReplaceItemType,
  ReplaceOrPushType
} from "forms/types/CustomFieldRenderProps";
import {
  isArray,
  isObject,
  notUndefined,
  Optional,
  Noop
} from "@laba/ts-common";
import { FieldValidator } from "forms/types/FieldValidator";
import { InitialValueOrFunction } from "forms/useField";
import { useFormState } from "forms/useFormState";
import { differenceWith, get, isEmpty, isEqual, times } from "lodash-es";
import { useCallback, useState } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getUseFieldArrayError = <E>(error?: any): Optional<E[]> => {
  if (error === undefined) return undefined;
  const errorArray = (isArray(error) ? error : [error]) as E[];

  const filteredErrorArray = errorArray.filter(notUndefined);

  // when usefieldarray elements have individual specific usefield validations
  // final form returns an object
  return filteredErrorArray.every(element => !isObject(element))
    ? filteredErrorArray
    : undefined;
};

const getUseFieldCastedError = <E>(error?: E[]): Optional<E> => {
  return error === undefined || isEmpty(error)
    ? undefined
    : (error.join(". ") as unknown as E);
};

const useGetInitialValue = <V>(
  initialValue?: InitialValueOrFunction<V[]>,
  formFieldInitialValue?: V[]
) => {
  const [emptyList] = useState<V[]>([]);
  if (initialValue instanceof Function) {
    return initialValue(formFieldInitialValue) ?? emptyList;
  }
  return initialValue ?? emptyList;
};

export const useFieldArray = <V, FormValues = object, E = string>(
  fieldName: string,
  validate?: FieldValidator<V[], E, FormValues>,
  beforeSubmit?: Noop,
  initialValueOrFunction?: InitialValueOrFunction<V[]>
): CustomFieldArrayRenderProps<V, E> => {
  const { initialValues: formInitialValues } = useFormState();
  const fieldInitialValues = get(formInitialValues, fieldName);
  const initialValue = useGetInitialValue(
    initialValueOrFunction,
    fieldInitialValues
  );
  const {
    fields: { value, push, remove, update, length, pop, ...fieldsRest },
    meta
  } = useFinalFormArrayField<V>(fieldName, {
    validate: validate as unknown as FieldValidator<V[]>,
    beforeSubmit,
    initialValue
  });

  const getIndex = useCallback(
    (item: V, compareFn = isEqual): Optional<number> => {
      const index = value.findIndex(v => compareFn(item, v));
      if (index === -1) return;
      return index;
    },
    [value]
  );

  const pushList = useCallback(
    (values: V[]) => {
      values.forEach(v => push(v));
    },
    [push]
  );

  const removeItem: RemoveReplaceItemType<V> = useCallback(
    (item, compareFn = isEqual) => {
      const indexValueToRemove = getIndex(item, compareFn);
      return indexValueToRemove !== undefined
        ? remove(indexValueToRemove)
        : undefined;
    },
    [remove, getIndex]
  );

  const replaceItem: RemoveReplaceItemType<V> = useCallback(
    (item, compareFn = isEqual) => {
      const indexToReplace = getIndex(item, compareFn);
      if (indexToReplace === undefined) return;
      update(indexToReplace, item);
      return item;
    },
    [update, getIndex]
  );

  const clean = useCallback(() => {
    times(length ?? 0, () => pop());
  }, [length, pop]);

  const removeList: RemoveListType<V> = useCallback(
    (values, compareFn = isEqual) => {
      const filteredItems = differenceWith(value, values, compareFn);
      clean();
      pushList(filteredItems);
    },
    [clean, pushList, value]
  );

  const replaceList = useCallback(
    (values: V[]) => {
      clean();
      pushList(values);
    },
    [pushList, clean]
  );

  const hasElement: HasElementType<V> = useCallback(
    (element, compareFn = isEqual) => {
      return value.find(v => compareFn(v, element)) !== undefined;
    },
    [value]
  );

  const removeOrPush = useCallback(
    (item: V, compareFn = isEqual) => {
      const itemRemoved = removeItem(item, compareFn);
      if (itemRemoved === undefined) push(item);
    },
    [push, removeItem]
  );

  const replaceOrPush: ReplaceOrPushType<V> = useCallback(
    (item, compareFn = isEqual) => {
      const itemReplaced = replaceItem(item, compareFn);
      if (itemReplaced === undefined) push(item);
    },
    [push, replaceItem]
  );

  const castedError = getUseFieldArrayError<E>(meta.error);

  const castedSubmitError = getUseFieldArrayError<E>(meta.submitError);

  const error = getUseFieldCastedError(castedError);

  const submitError = getUseFieldCastedError(castedSubmitError);

  return {
    fields: {
      ...fieldsRest,
      //  this case is needed because we set undefined on array fields in certain cases. more info on mr
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      value: value ?? [],
      push,
      remove,
      update,
      length,
      pop,
      pushList,
      removeItem,
      removeList,
      clean,
      replaceList,
      hasElement,
      removeOrPush,
      replaceItem,
      replaceOrPush,
      getIndex
    },
    meta: {
      ...meta,
      error,
      validationError: error ?? submitError,
      submitError
    }
  };
};
