import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  KnownCodeSystemSystem,
  OrganizationCombinedType,
  OrganizationKey,
  OrganizationListQueryParamsKey,
  PublicationStatus,
  QuestionnaireKey,
  QuestionnaireListQueryParamsKey,
  QuestionnaireType,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { TextField } from "components/generic/TextField/TextField";
import { ReferenceResourceField } from "components/generic/ReferenceResourceField/ReferenceResourceField";
import { List } from "components/generic/List/List";
import { Datagrid } from "components/generic/Datagrid/Datagrid";
import { questionnaireJsonToModel } from "components/pages/NexupAdmin/resources/questionnaire/QuestionnaireForm";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useListExportedFileName } from "components/hook/useExportedFileName";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

const tk = tkCP.adminPage[ResourceType.Questionnaire];

export const QuestionnaireList: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const categorySystem = useCodeSystemGroup(
    KnownCodeSystemSystem.QuestionnaireCategory,
    workspaceOrganizationId
  );
  const exportedFileName = useListExportedFileName(t(tk.title));
  return (
    <List
      resource={ResourceType.Questionnaire}
      exportedFileName={exportedFileName}
      jsonToModel={questionnaireJsonToModel}
      extraFilters={[
        workspaceOrganizationId === undefined ? (
          <OrganizationReferenceInput
            label={t(tk.filters.organization)}
            source={QuestionnaireListQueryParamsKey.organization}
            alwaysOn
            type={OrganizationCombinedType.ProviderAndGroup}
            extraFilters={{
              [OrganizationListQueryParamsKey.active]: true
            }}
          />
        ) : undefined,
        <EnumSelectorInput
          source={QuestionnaireListQueryParamsKey.type}
          label={t(tk.filters.type)}
          enumValue={QuestionnaireType}
          alwaysOn
          resettable
        />,
        <CodeSystemSelectorInput
          source={QuestionnaireListQueryParamsKey.category}
          label={t(tk.filters.category)}
          codeSystem={categorySystem}
          organizationId={workspaceOrganizationId}
          alwaysOn
          resettable
          withCreate
        />,
        <EnumSelectorInput
          source={QuestionnaireListQueryParamsKey.status}
          label={t(tk.filters.status)}
          enumValue={PublicationStatus}
          alwaysOn
          resettable
        />
      ]}
    >
      <Datagrid>
        <TextField source={QuestionnaireKey.id} label={t(tk.fields.id)} />
        <TextField source={QuestionnaireKey.title} label={t(tk.fields.title)} />
        <ReferenceResourceField
          resourceType={ResourceType.Organization}
          label={t(tk.fields.organization)}
          source={QuestionnaireKey.organization}
          optionTextKey={OrganizationKey.name}
        />
        <TextField source={QuestionnaireKey.type} label={t(tk.fields.type)} />
        <TextField
          source={QuestionnaireKey.status}
          label={t(tk.fields.status)}
        />
        <TextField
          source={QuestionnaireKey.category}
          label={t(tk.fields.category)}
        />
        <TextField source={QuestionnaireKey.order} label={t(tk.fields.order)} />
      </Datagrid>
    </List>
  );
};
