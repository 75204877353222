import { FC } from "@laba/react-common";
import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export const RicardoIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      width="101"
      height="86"
      viewBox="0 0 101 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M55.8803 46.2187C50.0661 51.7298 38.1296 57.1307 26.9041 57.3905C20.5844 57.5401 7.9053 33.4486 5.49589 28.213C1.46703 19.4818 17.1638 17.3482 20.7976 18.2064C25.3242 19.2771 32.2206 39.1644 34.7169 41.2193C35.5938 41.9436 47.8542 40.306 53.0443 39.2668C58.5899 38.1488 58.8743 43.3844 55.8803 46.2187Z"
        fill="#FFB7B4"
      />
      <path
        d="M5.78713 28.7648C4.83127 26.7414 1.27639 17.0812 0.897204 14.1761C0.518017 11.2709 0.0124457 2.81528 2.21647 2.29566C4.42049 1.77604 4.76806 4.2403 4.76806 4.2403C4.76806 4.2403 5.12355 0.63445 7.39077 0.303783C9.65799 -0.0268844 10.0688 3.8309 10.0688 3.8309C10.0688 3.8309 10.7008 0.162071 12.9838 0.00460991C15.2668 -0.152851 15.0298 3.77579 15.0298 3.77579C15.0298 3.77579 15.4564 0.398257 17.3997 1.05172C19.7301 1.83902 18.2371 7.99573 18.9007 11.1764C19.0508 11.8929 23.0164 25.5841 23.0164 25.5841L5.78713 28.7648Z"
        fill="#FFB7B4"
      />
      <path
        d="M15.0459 3.44587C13.9636 7.97286 14.2559 10.1458 15.7568 14.4681C15.7568 14.5547 15.662 14.6177 15.6225 14.5311C13.7898 10.4135 12.9998 7.75242 14.8326 3.37501C14.9195 3.17032 15.1012 3.2333 15.0459 3.44587Z"
        fill="#4F4F4F"
      />
      <path
        d="M10.1572 3.50761C9.44618 8.30228 9.76217 10.6563 10.9471 15.3172C10.9471 15.388 10.8523 15.4195 10.8286 15.3565C9.24869 10.8925 8.52981 8.21568 9.88066 3.54698C9.97545 3.27142 10.1967 3.26354 10.1572 3.50761Z"
        fill="#4F4F4F"
      />
      <path
        d="M4.83939 4.46849C4.476 9.1923 4.71298 11.7589 6.48252 16.1284C6.48252 16.2308 6.37191 16.3016 6.32451 16.2072C4.08889 11.9242 3.77292 8.68843 4.68929 4.47636C4.75249 4.18506 4.85519 4.17718 4.83939 4.46849Z"
        fill="#4F4F4F"
      />
      <path
        d="M24.1312 28.8825C24.1312 28.8825 26.1061 23.3714 26.4379 20.789C26.7697 18.2066 24.4393 8.0347 22.2116 8.4126C19.3361 8.90073 19.5257 15.0259 20.0076 18.0177C20.0076 18.0177 13.3481 19.1357 12.5344 23.5288C11.7208 27.922 24.1312 28.8825 24.1312 28.8825Z"
        fill="#FFB7B4"
      />
      <path
        d="M21.4685 8.80664C19.1617 11.1686 20.0544 15.0185 20.3072 17.9551C20.3133 17.9979 20.309 18.0416 20.2944 18.0824C20.2799 18.1232 20.2557 18.1598 20.2239 18.1892C20.192 18.2186 20.1535 18.2399 20.1116 18.2513C20.0697 18.2626 20.0257 18.2636 19.9833 18.2543C17.2184 18.9077 13.5213 20.6162 12.4233 23.4111C12.4233 23.4278 12.4166 23.4438 12.4048 23.4556C12.3929 23.4675 12.3768 23.4741 12.3601 23.4741C12.3433 23.4741 12.3272 23.4675 12.3154 23.4556C12.3035 23.4438 12.2969 23.4278 12.2969 23.4111C13.0868 20.2619 16.5627 18.2858 19.6278 17.7583C19.3197 14.7508 18.6009 10.8221 21.4448 8.82238L21.4685 8.80664Z"
        fill="#4F4F4F"
      />
      <path
        d="M26.2005 23.9766C26.2005 23.9766 9.43726 36.7309 9.46096 38.148C9.46096 38.148 20.7971 57.3897 26.0504 57.7834C33.9501 58.366 46.171 53.9256 53.3676 49.0128C61.0698 43.7536 61.5833 37.4473 51.9219 38.7306C41.5259 40.1163 35.8697 41.0925 34.8507 40.3052C33.8316 39.5179 27.0457 24.2915 26.2005 23.9766Z"
        fill="#7487F2"
      />
      <path
        d="M27.5262 28.851C26.1764 29.6966 24.8712 30.6111 23.6159 31.5908C22.3361 32.5434 21.0643 33.5118 19.824 34.5117C18.5838 35.5115 17.3356 36.5193 16.127 37.5585C14.9183 38.5978 13.8281 39.6213 12.6669 40.6369C12.6116 40.6841 12.6669 40.7707 12.7459 40.7314C13.994 39.7472 15.2817 38.8103 16.5456 37.842C17.8096 36.8736 19.0499 35.8816 20.3138 34.8974C21.5778 33.9133 22.8101 32.9213 24.0583 31.9214C25.3064 30.9216 26.4914 30.0083 27.6132 28.9533C27.6685 28.8982 27.5894 28.8195 27.5262 28.851Z"
        fill="#4F4F4F"
      />
      <path
        opacity="0.1"
        d="M53.3665 49.0126C46.1698 53.9254 33.9253 58.3658 26.0493 57.7832C25.7307 57.7529 25.4189 57.6732 25.125 57.547L58.6435 40.0059C60.2234 41.9899 58.209 45.7059 53.3665 49.0126Z"
        fill="#4F4F4F"
      />
      <path
        d="M55.3898 44.1017C58.0126 45.0465 63.1079 41.4642 65.3119 38.3386C65.462 38.1261 65.383 35.6854 65.4304 32.7094C65.4699 30.9065 65.5489 28.891 65.7622 27.0645L53.9126 28.5525C54.309 30.9111 54.4151 33.3091 54.2286 35.6933C54.2003 35.9452 54.1446 36.1934 54.0627 36.4334C54.0627 36.4727 54.0627 36.5121 54.0627 36.5593C53.6598 37.9529 52.854 43.1963 55.3898 44.1017Z"
        fill="#FFB7B4"
      />
      <path
        d="M65.0903 27.127C64.4188 29.5912 61.7724 37.3225 55.2314 36.732C54.8347 36.6961 54.4412 36.6303 54.0544 36.5352C54.0544 36.488 54.0544 36.4486 54.0544 36.4093C54.1363 36.1693 54.192 35.9211 54.2203 35.6692C54.4068 33.285 54.3007 30.887 53.9043 28.5284L65.0903 27.127Z"
        fill="#4F4F4F"
      />
      <path
        d="M67.0803 12.3347C68.4628 16.3814 66.6696 28.2618 63.8336 30.9859C59.7178 34.9224 52.774 35.5129 49.3929 30.545C46.0829 25.7346 48.6977 10.2956 51.8892 7.79984C56.5737 4.12313 65.0422 6.3512 67.0803 12.3347Z"
        fill="#FFB7B4"
      />
      <path
        d="M57.5388 20.043C57.5388 20.043 57.6021 20.0902 57.5942 20.1217C57.452 20.909 57.4204 21.8538 58.1076 22.2238C58.1076 22.2238 58.1076 22.2789 58.1076 22.271C57.2308 22.0348 57.2782 20.8303 57.5388 20.043Z"
        fill="#4F4F4F"
      />
      <path
        d="M58.3829 19.3256C59.6863 19.4122 59.4098 22.0103 58.2012 21.9316C56.9925 21.8529 57.2848 19.2548 58.3829 19.3256Z"
        fill="#4F4F4F"
      />
      <path
        d="M52.7973 19.3789C52.7973 19.3789 52.7262 19.3789 52.7262 19.4498C52.663 20.2371 52.4576 21.1661 51.6992 21.355V21.4023C52.6077 21.3708 52.8684 20.1583 52.7973 19.3789Z"
        fill="#4F4F4F"
      />
      <path
        d="M52.1669 18.4663C50.8793 18.2301 50.5001 20.8282 51.693 21.0329C52.8858 21.2376 53.2413 18.6632 52.1669 18.4663Z"
        fill="#4F4F4F"
      />
      <path
        d="M59.5362 18.2462C59.2027 18.1121 58.8782 17.957 58.5645 17.7817C58.2 17.6693 57.8832 17.4395 57.664 17.1282C57.6137 17.016 57.6019 16.8904 57.6305 16.7709C57.659 16.6513 57.7263 16.5445 57.8219 16.4669C58.0333 16.3453 58.2731 16.2813 58.5171 16.2812C58.7612 16.2812 59.0009 16.3453 59.2123 16.4669C59.6792 16.6538 60.0595 17.0075 60.2788 17.4589C60.3236 17.5643 60.3367 17.6805 60.3165 17.7932C60.2963 17.9059 60.2437 18.0103 60.165 18.0938C60.0863 18.1772 59.9849 18.236 59.8733 18.2631C59.7617 18.2902 59.6445 18.2843 59.5362 18.2462Z"
        fill="#4F4F4F"
      />
      <path
        d="M51.2977 17.0484C51.6431 17.0183 51.9861 16.9657 52.3246 16.8909C52.7095 16.8913 53.0829 16.7607 53.3832 16.5209C53.4627 16.4292 53.5096 16.3139 53.5168 16.193C53.5239 16.072 53.4909 15.952 53.4227 15.8517C53.2558 15.6728 53.0443 15.5414 52.8097 15.4709C52.5751 15.4003 52.3259 15.3932 52.0876 15.4502C51.5883 15.4924 51.1231 15.72 50.7842 16.0879C50.7065 16.1769 50.6577 16.2873 50.6444 16.4044C50.631 16.5216 50.6536 16.6401 50.7093 16.7442C50.7649 16.8483 50.851 16.9331 50.956 16.9874C51.0611 17.0417 51.1802 17.063 51.2977 17.0484Z"
        fill="#4F4F4F"
      />
      <path
        d="M57.2707 27.474C57.0495 27.6394 56.8362 27.8756 56.536 27.8756C56.2365 27.8355 55.9486 27.7336 55.6908 27.5764C55.6908 27.5764 55.6355 27.5764 55.6513 27.6158C55.7492 27.7868 55.8923 27.9279 56.065 28.0236C56.2377 28.1194 56.4334 28.1662 56.6309 28.159C56.8004 28.1341 56.9586 28.0594 57.0852 27.9444C57.2119 27.8294 57.3012 27.6793 57.3418 27.5134C57.3418 27.5134 57.2944 27.4504 57.2707 27.474Z"
        fill="#4F4F4F"
      />
      <path
        d="M56.6783 24.9683C56.4395 25.2661 56.1415 25.5115 55.803 25.689C55.4645 25.8665 55.0928 25.9723 54.7113 25.9997C54.3781 26.0219 54.0435 26.0007 53.7159 25.9367H53.5263L53.3525 25.8894C53.3115 25.875 53.2759 25.8484 53.2505 25.8132C53.2251 25.778 53.2111 25.736 53.2103 25.6926C53.2057 25.6666 53.2057 25.6399 53.2103 25.6139V25.543C53.2577 24.921 53.4157 23.9684 53.4157 23.9684C53.1787 24.0629 51.9622 24.4565 52.0175 24.1337C52.3892 21.3484 53.043 18.6077 53.9687 15.9537C53.9687 15.9349 53.9762 15.9169 53.9895 15.9036C54.0028 15.8903 54.0209 15.8828 54.0398 15.8828C54.0587 15.8828 54.0767 15.8903 54.0901 15.9036C54.1034 15.9169 54.1109 15.9349 54.1109 15.9537C53.787 18.5281 53.0049 21.0318 52.6337 23.6141C53.0669 23.4654 53.519 23.378 53.9766 23.3543C54.0635 23.3937 53.6369 25.1572 53.6369 25.4407C54.1409 25.5449 54.6608 25.547 55.1656 25.4469C55.6705 25.3468 56.15 25.1466 56.5756 24.8581C56.6704 24.8502 56.7336 24.9132 56.6783 24.9683Z"
        fill="#4F4F4F"
      />
      <path
        d="M54.125 25.7641C54.4393 26.2302 54.8693 26.6072 55.3731 26.8585C55.6537 26.9806 55.9638 27.0189 56.2658 26.9687C56.9215 26.8348 56.9689 26.2444 56.882 25.7326C56.8327 25.4622 56.7532 25.1981 56.645 24.9453C55.9141 25.479 55.0311 25.7659 54.125 25.7641Z"
        fill="#4F4F4F"
      />
      <path
        d="M55.375 26.859C55.6556 26.9812 55.9657 27.0195 56.2677 26.9693C56.9233 26.8354 56.9707 26.2449 56.8838 25.7332C56.5415 25.7223 56.2056 25.8278 55.9313 26.0324C55.6571 26.237 55.4609 26.5285 55.375 26.859Z"
        fill="#F178B6"
      />
      <path
        d="M50.3493 11.2784C53.9674 14.8607 60.4215 11.617 60.4215 11.617C60.6073 12.5835 61.1014 13.4643 61.8302 14.1283C62.559 14.7922 63.4836 15.2038 64.4661 15.3016C64.4661 15.3016 63.9921 22.4345 65.8407 22.7573C68.6609 23.2533 71.2836 13.8923 70.7464 11.8532C70.2092 9.81405 67.9025 8.90077 67.9025 8.90077C67.8485 7.71199 67.3464 6.58731 66.4964 5.75156C64.8453 3.95651 57.8541 4.71232 57.8541 4.71232C57.6361 3.76122 57.1979 2.87415 56.5743 2.12209C56.4258 1.93468 56.2406 1.77929 56.03 1.66532C55.8195 1.55136 55.5879 1.48121 55.3493 1.45913C55.1107 1.43704 54.8701 1.46349 54.6421 1.53685C54.414 1.61021 54.2033 1.72896 54.0227 1.88591C54.2852 2.46649 54.4096 3.09968 54.3861 3.73607C54.3387 5.83817 52.664 5.53112 53.0194 3.68096C53.1261 2.98172 53.4823 2.34433 54.0227 1.88591C53.4223 0.523873 52.1426 -0.113846 51.2025 0.382155C49.4883 1.28755 47.3316 8.29455 50.3493 11.2784Z"
        fill="#4F4F4F"
      />
      <path
        d="M64.8548 22.0346C64.8548 22.0346 67.4933 18.1847 69.2391 19.1215C70.985 20.0584 69.0416 25.6168 67.0035 26.3962C66.7491 26.513 66.4736 26.5775 66.1936 26.5858C65.9136 26.5942 65.6348 26.5462 65.3738 26.4448C65.1128 26.3434 64.8749 26.1906 64.6745 25.9956C64.474 25.8006 64.3151 25.5673 64.207 25.3097L64.8548 22.0346Z"
        fill="#FFB7B4"
      />
      <path
        d="M68.6368 20.6953V20.7504C67.2069 21.3488 66.4723 22.6793 65.9588 24.0571C66.0403 23.9129 66.1523 23.788 66.2871 23.6912C66.4218 23.5944 66.5761 23.528 66.7391 23.4966C66.9022 23.4651 67.0702 23.4694 67.2315 23.5091C67.3927 23.5488 67.5434 23.6229 67.673 23.7264C67.673 23.7264 67.673 23.813 67.6335 23.8052C67.3805 23.728 67.1097 23.7305 66.8581 23.8122C66.6065 23.8939 66.3863 24.0509 66.2274 24.2618C65.9694 24.6185 65.7497 25.0011 65.5717 25.4034C65.5006 25.5294 65.2715 25.4664 65.3189 25.3168C65.2873 23.4666 66.6776 20.9 68.6368 20.6953Z"
        fill="#4F4F4F"
      />
      <path
        d="M82.5 73C78.2105 58.7498 72.0343 43.928 66.3544 39.3773C65.8172 38.59 66.5281 35.6613 66.149 35.3621C65.7698 35.0629 53.62 35.5589 53.2645 35.8581C52.909 36.1573 53.2645 37.9602 52.8774 38.4247C50.3624 42.4629 48.1726 46.6935 46.3286 51.0767C44.5353 55.6115 42.5957 63.9066 41 73H82.5Z"
        fill="#7487F2"
      />
      <path
        d="M64.9637 36.166C64.8272 37.0821 64.8272 38.0134 64.9637 38.9295C65.1107 38.0142 65.1107 37.0813 64.9637 36.166Z"
        fill="#4F4F4F"
      />
      <path
        d="M63.4305 36.166C63.2836 37.0813 63.2836 38.0142 63.4305 38.9295C63.5775 38.0142 63.5775 37.0813 63.4305 36.166Z"
        fill="#4F4F4F"
      />
      <path
        d="M61.9149 36.166C61.7679 37.0813 61.7679 38.0142 61.9149 38.9295C62.0514 38.0134 62.0514 37.0821 61.9149 36.166Z"
        fill="#4F4F4F"
      />
      <path
        d="M60.3914 36.166C60.2549 37.0821 60.2549 38.0134 60.3914 38.9295C60.4672 38.4742 60.5041 38.0132 60.5017 37.5517C60.5005 37.0877 60.4636 36.6244 60.3914 36.166Z"
        fill="#4F4F4F"
      />
      <path
        d="M58.8721 36.166C58.7999 36.6244 58.763 37.0877 58.7618 37.5517C58.7594 38.0132 58.7963 38.4742 58.8721 38.9295C59.0085 38.0134 59.0085 37.0821 58.8721 36.166Z"
        fill="#4F4F4F"
      />
      <path
        d="M57.3485 36.166C57.212 37.0821 57.212 38.0134 57.3485 38.9295C57.4281 38.4746 57.4651 38.0134 57.4587 37.5517C57.4614 37.0875 57.4246 36.6239 57.3485 36.166Z"
        fill="#4F4F4F"
      />
      <path
        d="M55.8309 36.166C55.684 37.0813 55.684 38.0142 55.8309 38.9295C55.9779 38.0142 55.9779 37.0813 55.8309 36.166Z"
        fill="#4F4F4F"
      />
      <path
        d="M54.3153 36.166C54.1683 37.0813 54.1683 38.0142 54.3153 38.9295C54.4518 38.0134 54.4518 37.0821 54.3153 36.166Z"
        fill="#4F4F4F"
      />
      <path
        d="M48.2647 46.4083C47.8001 47.2097 47.3833 48.0378 47.0166 48.8883C47.0166 48.7151 47.0877 48.534 47.1193 48.3529C47.2141 47.8805 47.3404 47.4003 47.4747 46.9358C47.4747 46.9358 47.4747 46.9358 47.4353 46.9358C47.2471 47.3906 47.094 47.8591 46.9771 48.3372C46.8896 48.692 46.8263 49.0524 46.7875 49.4158C46.7401 49.526 46.6848 49.6362 46.6453 49.7543C46.2061 50.8798 45.829 52.0283 45.5156 53.1948C45.5156 53.2735 45.6104 53.2972 45.6341 53.1948C46.4952 50.9195 47.4827 48.6993 48.3279 46.4161C48.3753 46.3925 48.2805 46.3768 48.2647 46.4083Z"
        fill="#4F4F4F"
      />
      <path
        d="M91.9333 65.5271C83.765 54.5049 72.9739 44.0338 67.8549 41.412C61.6931 38.2628 66.4567 50.0173 70.367 56.8668C73.5269 62.4488 80.5577 71.8256 80.1074 72.0932C79.6571 72.3609 78.1562 68.6055 75.8416 68.2276C73.1872 67.7867 61.9143 68.2276 62.1039 69.8022C62.175 70.4557 62.2212 70.8822 63.501 73H77.6929H91.9333V65.5271Z"
        fill="#FFB7B4"
      />
      <path
        d="M91.3728 73.0008H100.091C94.3829 65.707 79.3475 49.778 72.5263 43.5056C65.2348 37.1284 60.8821 36.8056 66.325 49.7017C69.4864 57.2992 73.8203 64.3573 79.1699 70.6203C79.1699 70.6203 71.3155 71.8907 66.7574 73.0008H91.3728Z"
        fill="#7487F2"
      />
      <path
        d="M65.7944 51.8802C66.3949 52.3133 66.965 52.7868 67.5007 53.2974L67.951 53.754C67.2401 52.5494 66.5449 51.337 65.8813 50.1088C65.8719 50.09 65.8703 50.0682 65.877 50.0483C65.8837 50.0284 65.898 50.0119 65.9168 50.0025C65.9357 49.9931 65.9575 49.9916 65.9775 49.9982C65.9975 50.0049 66.0141 50.0191 66.0235 50.0379C67.9984 53.1871 69.9734 56.3364 71.9562 59.399C73.939 62.4616 76.0166 65.4848 78.0706 68.5081C79.2397 70.2244 80.3615 71.9801 81.5386 73.6885C81.586 73.7515 81.4753 73.8224 81.4279 73.7594C80.2983 72.3107 79.1291 70.8936 78.0469 69.4056C76.9646 67.9176 75.9613 66.469 74.9581 64.9731C72.8989 61.9131 70.9292 58.7954 69.0491 55.6199L68.4882 54.6752C68.3302 54.5256 68.1643 54.3917 68.0063 54.2579C67.7298 54.0217 67.4613 53.7776 67.2164 53.5336C66.7025 53.0388 66.2196 52.5129 65.7707 51.9589C65.7707 51.9589 65.7549 51.8487 65.7944 51.8802Z"
        fill="#4F4F4F"
      />
      <path
        d="M79.879 65.0628C77.667 64.7793 76.6638 67.0153 76.6638 67.0153C76.6638 67.0153 75.6763 64.2676 73.4091 64.2676C72.2399 64.2676 71.2209 66.1335 71.2209 66.1335C70.935 65.5859 70.5041 65.1269 69.9748 64.8062C69.4456 64.4856 68.8383 64.3157 68.219 64.3148C66.639 64.4172 65.849 66.2673 65.849 66.2673C65.849 66.2673 64.0321 64.8344 62.4285 65.48C59.1738 66.7555 58.605 80.5254 63.6134 82.8558C65.9834 83.9423 65.8964 80.5726 65.8964 80.5726C65.8964 80.5726 67.1367 85.4067 69.9806 85.5956C72.8245 85.7846 72.5006 81.4072 72.5006 81.4072C72.5006 81.4072 73.7409 86.0759 76.7112 85.9735C79.6815 85.8712 78.7256 81.5489 78.7256 81.5489C78.7256 81.5489 80.6847 84.6981 82.9361 84.0997C86.6648 83.1156 82.6547 75.9996 83.5 73H91C91 73 85.3772 65.7871 79.879 65.0628Z"
        fill="#FFB7B4"
      />
      <path
        d="M62.0084 69.2401C58.8959 69.7519 56.0124 65.469 52.9711 65.5319C48.8475 65.6107 50.3191 71.7797 55.9989 73H61.999L62.0084 69.2401Z"
        fill="#FFB7B4"
      />
      <path
        d="M65.666 66.7447C65.666 66.6974 65.6028 66.6817 65.5949 66.7447C64.2338 71.401 64.3247 76.3597 65.8556 80.9634C65.9109 81.1051 66.1715 81.0815 66.1399 80.9161C65.2939 76.2401 65.1343 71.4664 65.666 66.7447Z"
        fill="#4F4F4F"
      />
      <path
        d="M71.1979 66.2729C71.1938 66.2627 71.1867 66.2539 71.1776 66.2478C71.1684 66.2416 71.1576 66.2383 71.1466 66.2383C71.1355 66.2383 71.1247 66.2416 71.1156 66.2478C71.1064 66.2539 71.0993 66.2627 71.0952 66.2729C69.6494 71.4193 70.0948 76.9111 72.3513 81.7592C72.4382 81.9245 72.6831 81.9954 72.5962 81.7592C71.2216 78.421 70.6292 70.6897 71.1979 66.2729Z"
        fill="#4F4F4F"
      />
      <path
        d="M79.0027 82.0329C76.8382 77.3091 76.4195 72.3018 76.7276 67.1371C76.7276 67.0663 76.6407 67.0663 76.617 67.1371C75.7796 69.625 75.9218 72.9396 76.2615 75.514C76.5231 77.9054 77.4113 80.1862 78.8368 82.1274C78.8921 82.1904 79.0501 82.1352 79.0027 82.0329Z"
        fill="#4F4F4F"
      />
      <path
        d="M60.0497 69.5079C59.5101 70.9939 59.5957 70.9156 59.7292 72.4902C59.7292 72.561 59.8477 72.5768 59.8556 72.4902C59.9504 71.7028 59.8838 70.9566 60.1761 69.5237C60.1919 69.4764 60.0813 69.4449 60.0497 69.5079Z"
        fill="#4F4F4F"
      />
    </SvgIcon>
  );
};
