import React from "react";
import { getClassName, ReactElement, TextVariant } from "@laba/react-common";
import { DetailCheckbox } from "components/detail/DetailCheckbox/DetailCheckbox";
import { useDetailCheckboxGroupStyle } from "components/detail/DetailCheckboxGroup/DetailCheckboxGroupStyle";
import { CheckboxTextVariant } from "components/inputs/Checkbox/Checkbox";
import { isOdd } from "@laba/ts-common";

export interface DetailCheckboxItem {
  text: string;
  checked: boolean;
}

export interface DetailCheckboxGroupProps {
  className?: string;
  items: DetailCheckboxItem[];
  textVariant?: CheckboxTextVariant;
}

export const DetailCheckboxGroup = ({
  className,
  items,
  textVariant = TextVariant.Body2
}: DetailCheckboxGroupProps): ReactElement => {
  const classes = useDetailCheckboxGroupStyle();
  const numberOfItems = items.length;
  const numberOfItemsIsOdd = isOdd(numberOfItems);

  return (
    <div className={getClassName(className, classes.root)}>
      {items.map((item, idx) => {
        const itemsIdx = idx + 1;
        const isLastItem = itemsIdx === numberOfItems;
        return (
          <DetailCheckbox
            className={
              numberOfItemsIsOdd && isLastItem ? classes.lastChild : undefined
            }
            key={item.text}
            text={item.text}
            title={item.text}
            checked={item.checked}
            textVariant={textVariant}
          />
        );
      })}
    </div>
  );
};
