import {
  createStyle,
  FlexAlignSelf,
  flexColumnMixin,
  flexItemMixin,
  FlexJustifyContent,
  flexRowMixin,
  getClassName,
  getZIndexValue,
  marginMixin,
  paddingMixin,
  pxToRem,
  ZIndexVariant
} from "@laba/react-common";
import { Theme } from "model/theme";

interface FileListPreviewPopupClassNames {
  dialog: string;
  paperScrollPaper: string;
  paper: string;
  container: string;
  buttonContainer: string;
  contentContainer: string;
  leftArrow: string;
  rightArrow: string;
  content: string;
  swiper: string;
  slide: string;
}

export interface FileListPreviewPopupStyleProps {
  showLeftArrow?: boolean;
  showRightArrow?: boolean;
  isMobile?: boolean;
}

const useFileListPreviewPopupStyles = createStyle(
  (_theme: Theme) => ({
    dialogDesktop: {
      ...paddingMixin({
        top: 70
      })
    },
    dialogMobile: {
      ...paddingMixin({
        all: 0
      })
    },
    paperScrollPaper: {
      maxHeight: "100%",
      maxWidth: "100%"
    },
    paper: {
      ...marginMixin({ all: 0 }),
      boxShadow: "none",
      backgroundColor: "transparent",
      color: "transparent",
      height: "100%",
      width: "100%",
      zIndex: getZIndexValue(ZIndexVariant.PopupUpElevation),
      elevation: 0
    },
    container: {
      ...flexColumnMixin({ gap: 40 }),
      height: "100%",
      width: "100%",
      boxShadow: "none"
    },
    buttonContainer: {
      ...flexItemMixin({ align: FlexAlignSelf.FlexEnd, shrink: 0 })
    },
    buttonContainerDesktop: {
      ...paddingMixin({ leftRight: 100 })
    },
    buttonContainerMobile: {
      ...paddingMixin({ right: 4 })
    },
    swiper: {
      ...flexItemMixin({ grow: 1 }),
      width: "100%"
    },
    slide: {
      height: "100%"
    },
    slideDesktop: {
      ...paddingMixin({ leftRight: 100 })
    },
    contentContainer: {
      ...flexItemMixin({ grow: 1, align: FlexAlignSelf.Center }),
      width: "100%"
    },
    contentContainerDesktop: {
      ...flexRowMixin({
        gap: 36,
        justifyContent: FlexJustifyContent.Center
      }),
      ...paddingMixin({ leftRight: 10 })
    },
    contentContainerMobile: {
      ...flexRowMixin({
        justifyContent: FlexJustifyContent.Center
      })
    },
    arrowButtonDesktop: {
      ...flexItemMixin({ align: FlexAlignSelf.FlexStart, shrink: 0 }),
      height: "100%"
    },
    arrowButtonMobile: {
      position: "absolute",
      height: "80%",
      zIndex: getZIndexValue(ZIndexVariant.PopupUpElevation)
    },
    leftArrowButtonMobile: {
      left: 20
    },
    rightArrowButtonMobile: {
      right: 20
    },
    arrowHidden: {
      width: pxToRem(40)
    },
    content: {
      ...flexItemMixin({ grow: 1, align: FlexAlignSelf.Center }),
      height: "100%"
    }
  }),
  "FileListPreviewPopupStatic"
);

export const useFileListPreviewPopupClassNames = (
  props: FileListPreviewPopupStyleProps
): FileListPreviewPopupClassNames => {
  const classes = useFileListPreviewPopupStyles();
  const { isMobile, showRightArrow, showLeftArrow } = props;
  return {
    dialog: isMobile ? classes.dialogMobile : classes.dialogDesktop,
    paper: classes.paper,
    paperScrollPaper: classes.paperScrollPaper,
    container: classes.container,
    buttonContainer: getClassName(
      classes.buttonContainer,
      isMobile ? classes.buttonContainerMobile : classes.buttonContainerDesktop
    ),
    contentContainer: getClassName(
      classes.contentContainer,
      isMobile
        ? classes.contentContainerMobile
        : classes.contentContainerDesktop
    ),
    leftArrow: getClassName(
      isMobile ? classes.arrowButtonMobile : classes.arrowButtonDesktop,
      isMobile ? classes.leftArrowButtonMobile : undefined,
      !showLeftArrow && !isMobile ? classes.arrowHidden : undefined
    ),
    rightArrow: getClassName(
      isMobile ? classes.arrowButtonMobile : classes.arrowButtonDesktop,
      isMobile ? classes.rightArrowButtonMobile : undefined,
      !showRightArrow && !isMobile ? classes.arrowHidden : undefined
    ),
    content: classes.content,
    slide: getClassName(
      classes.slide,
      isMobile ? undefined : classes.slideDesktop
    ),
    swiper: classes.swiper
  };
};
