import React from "react";
import { FC } from "@laba/react-common";
import { Create } from "components/generic/Create/Create";
import { ExternalResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import {
  NotificationForm,
  notificationJsonToModel,
  notificationTransform
} from "./NotificationForm";

export const NotificationCreate: FC = () => {
  return (
    <Create
      resourceTransform={notificationTransform}
      resource={ExternalResourceType.Notification}
      jsonToModel={notificationJsonToModel}
    >
      <NotificationForm />
    </Create>
  );
};
