import React from "react";
import { FC } from "@laba/react-common";
import { NexupColor } from "model/nexupColor";
import { OdontogramIconProps } from "components/icons/OdontogramIcons/utils";

export const RemovableProsthesisIcon: FC<OdontogramIconProps> = props => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.5 6.27778C5.5 5.84822 5.84822 5.5 6.27778 5.5H18.7222C19.1518 5.5 19.5 5.84822 19.5 6.27778V18.7222C19.5 19.1518 19.1518 19.5 18.7222 19.5H6.27778C5.84822 19.5 5.5 19.1518 5.5 18.7222V6.27778Z"
        stroke={props.iconColor ?? NexupColor.Black}
        strokeWidth="2"
      />
    </svg>
  );
};
