import { EncounterStatus } from "model/resource";

export const availableHospitalizationEncounterStatusTransitionList: EncounterStatus[] =
  [
    EncounterStatus.Planned,
    EncounterStatus.InProgress,
    EncounterStatus.Arrived,
    EncounterStatus.Finished,
    EncounterStatus.Cancelled
  ];

export const getHospitalizationOrderValidTransitionStatus = (
  status?: EncounterStatus,
  isReversible?: boolean
): EncounterStatus[] => {
  if (isReversible)
    return availableHospitalizationEncounterStatusTransitionList;
  switch (status) {
    case EncounterStatus.InProgress:
      return [
        EncounterStatus.InProgress,
        EncounterStatus.Finished,
        EncounterStatus.Cancelled
      ];
    case EncounterStatus.Planned:
      return availableHospitalizationEncounterStatusTransitionList;
    case EncounterStatus.Finished:
      return [EncounterStatus.Finished, EncounterStatus.Cancelled];
    case EncounterStatus.Cancelled:
      return [EncounterStatus.Cancelled];

    case EncounterStatus.Triaged:
      return [];
    case EncounterStatus.Arrived:
      return [];
    default:
      return availableHospitalizationEncounterStatusTransitionList;
  }
};
