import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { ModelId } from "model/primitives/model/model";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { HttpMethod } from "@laba/ts-common";
import { MeetingEncounter } from "model/resource/entities/encounter/meetingEncounter";
import { encounterBasePath } from "api/entities/encounter/encounter";

export enum EncounterStatusAction {
  Attend = "Attend",
  Start = "Start",
  Finish = "Finish",
  Cancel = "Cancel"
}

export interface CurrentEncounterQueryParams {
  withPatient?: boolean;
  withPatientHealthcarePayer?: boolean;
  withHealthcareService?: boolean;
}

export interface CurrentEncounterListQueryParams
  extends ListQueryParams,
    CurrentEncounterQueryParams {
  organization?: ModelId;
  practitioner?: ModelId;
}

export const getCurrentEncounterList = async (
  params: CurrentEncounterListQueryParams
): Promise<ApiPageRequestResponse<MeetingEncounter>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${encounterBasePath}/current`),
    params
  });

export const updateEncounterStatus = (
  encounterId: ModelId,
  action: EncounterStatusAction
): Promise<ApiRequestResponse<MeetingEncounter>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(`${encounterBasePath}/${encounterId}/status-update`),
    data: { action }
  });

export const attendEncounter = (
  encounterId: ModelId
): Promise<ApiRequestResponse<MeetingEncounter>> =>
  updateEncounterStatus(encounterId, EncounterStatusAction.Attend);

export const cancelEncounter = (
  encounterId: ModelId
): Promise<ApiRequestResponse<MeetingEncounter>> =>
  updateEncounterStatus(encounterId, EncounterStatusAction.Cancel);

export const finishEncounter = (
  encounterId: ModelId
): Promise<ApiRequestResponse<MeetingEncounter>> =>
  updateEncounterStatus(encounterId, EncounterStatusAction.Finish);

export const startEncounter = (
  encounterId: ModelId
): Promise<ApiRequestResponse<MeetingEncounter>> =>
  updateEncounterStatus(encounterId, EncounterStatusAction.Start);
