import React from "react";
import { Children, ReactElement, StyleVariant } from "@laba/react-common";
import { BaseAutocompleteOption } from "../BaseAutocompleteOption/BaseAutocompleteOption";
import { AutocompleteOptionConfig } from "../../Autocomplete";

interface Props<V> {
  isMobile?: boolean;
  children?: Children;
  onRemoveSelectedOption?: (option: V) => void;
  removeIconStyle?: StyleVariant;
  disabled?: boolean;
  fullWidth?: boolean;
  isSelectedOption?: boolean;
  hasBackgroundColor?: boolean;
  option: AutocompleteOptionConfig<V>;
  actionControlComponent?: ReactElement;
}

export const AutocompleteTitleWithSubtextOptionWrapper = <V,>({
  children,
  option,
  onRemoveSelectedOption,
  removeIconStyle,
  disabled,
  fullWidth,
  isSelectedOption,
  isMobile,
  hasBackgroundColor,
  actionControlComponent
}: Props<V>): ReactElement => {
  const { text: titleText, primaryText, subtext, title, value } = option;
  return (
    <BaseAutocompleteOption
      optionValue={value}
      onRemoveSelectedOption={onRemoveSelectedOption}
      removeIconStyle={removeIconStyle}
      disabled={disabled}
      fullWidth={fullWidth}
      isSelectedOption={isSelectedOption}
      hasBackgroundColor={hasBackgroundColor}
      isMobile={isMobile}
      title={title}
      titleText={titleText}
      primaryText={primaryText}
      subtext={subtext}
      actionControlComponent={actionControlComponent}
    >
      {children}
    </BaseAutocompleteOption>
  );
};
