import React from "react";
import { FC, getClassName, IconC } from "@laba/react-common";
import { isEmpty } from "lodash-es";
import { useIndicatorStyles } from "./useIndicatorStyles";

interface IndicatorProps {
  className?: string;
  Icon: IconC;
  text: string;
  label?: string;
  iconColor: string;
}

export const Indicator: FC<IndicatorProps> = ({
  className,
  Icon,
  text,
  label,
  iconColor
}) => {
  const classes = useIndicatorStyles({ iconColor });
  return (
    <div className={getClassName(className, classes.root)}>
      <Icon className={classes.icon} />
      <p className={classes.text} title={!isEmpty(label) ? label : text}>
        {text}
      </p>
    </div>
  );
};
