import { produce } from "immer";
import {
  BillingContract,
  BillingContractHospitalizationTerm,
  BillingContractMedTerm,
  BillingContractProductTerm,
  CommonBillingContractTermFields,
  KnownContractTermType
} from "model/resource";

const cleanTerm = <T extends CommonBillingContractTermFields>(list: T[]) => {
  return list.map(t => {
    return produce(t, draftT => {
      draftT.id = undefined;
    });
  });
};

export const cleanBillingContractIdsAndTitle = (
  billingContract: BillingContract
): BillingContract => {
  return produce(billingContract, draftBillingContract => {
    draftBillingContract.id = undefined;
    draftBillingContract.creationDate = undefined;
    draftBillingContract.lastEditDate = undefined;
    draftBillingContract.lastEditor = undefined;
    draftBillingContract.title = undefined;
    if (draftBillingContract.medTerm !== undefined) {
      draftBillingContract.medTerm = cleanTerm(draftBillingContract.medTerm);
    }
    if (draftBillingContract.hospitalizationTerm !== undefined) {
      draftBillingContract.hospitalizationTerm = cleanTerm(
        draftBillingContract.hospitalizationTerm
      );
    }
    if (draftBillingContract.productTerm !== undefined) {
      draftBillingContract.productTerm = cleanTerm(
        draftBillingContract.productTerm
      );
    }
  });
};

export type BillingContractTermType =
  | BillingContractProductTerm
  | BillingContractMedTerm
  | BillingContractHospitalizationTerm;

export enum BillingContractTermUsableType {
  Product = "Product",
  Med = "Med",
  Hospitalization = "Hospitalization"
}

export const isBillingContractProductTerm = (
  billingContract: BillingContractTermType
): billingContract is BillingContractProductTerm => {
  return billingContract.type === KnownContractTermType.Product;
};

export const isBillingContractMedTerm = (
  billingContract: BillingContractTermType
): billingContract is BillingContractMedTerm => {
  return (
    billingContract.type === KnownContractTermType.MedicalRequest ||
    billingContract.type === KnownContractTermType.MedicalAdministration
  );
};

export const isBillingContractHospitalizationTerm = (
  billingContract: BillingContractTermType
): billingContract is BillingContractHospitalizationTerm => {
  return billingContract.type === KnownContractTermType.Hospitalization;
};

export const isBillingContractProductTermList = (
  billingContractList: BillingContractTermType[]
): billingContractList is BillingContractProductTerm[] => {
  return billingContractList.every(isBillingContractProductTerm);
};

export const isBillingContractMedTermList = (
  billingContractList: BillingContractTermType[]
): billingContractList is BillingContractMedTerm[] => {
  return billingContractList.every(isBillingContractMedTerm);
};

export const isBillingContractHospitalizationTermList = (
  billingContractList: BillingContractTermType[]
): billingContractList is BillingContractHospitalizationTerm[] => {
  return billingContractList.every(isBillingContractHospitalizationTerm);
};
