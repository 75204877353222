import { useCallback, useMemo } from "react";
import { differenceBy, get, gt, isEmpty, lt, size } from "lodash-es";
import Fuse from "fuse.js";

export type FilterOptionsGetterFunc<V> = (
  searchText: string,
  options: V[]
) => V[];
type FilterOptionsFunc<V> = (options: V[]) => V[];

export const useSearchTextFilterGetter = <V>(
  searchPath: string[],
  valuePath?: string,
  selectedOptions: V[] = [],
  threshold = 0.3,
  minTextLength = 3,
  limit = 20
): FilterOptionsGetterFunc<V> => {
  return useCallback(
    (searchText, options: V[]) => {
      if (searchText.length < minTextLength) return [];
      const availableOptions =
        isEmpty(selectedOptions) || !valuePath
          ? options
          : differenceBy(options, selectedOptions, x => get(x, valuePath));
      const fuse = new Fuse(availableOptions, {
        keys: searchPath,
        threshold
      });
      return fuse.search(searchText, { limit }).map(result => result.item);
    },
    [searchPath, selectedOptions, limit, threshold, minTextLength, valuePath]
  );
};
export const useSearchTextFilter = <V>(
  searchText: string,
  searchPath: string[],
  valuePath?: string,
  selectedOptions: V[] = [],
  threshold = 0.3,
  minTextLength = 3,
  limit = 20
): FilterOptionsFunc<V> => {
  const fn = useSearchTextFilterGetter(
    searchPath,
    valuePath,
    selectedOptions,
    threshold,
    minTextLength,
    limit
  );
  return useCallback(
    (options: V[]) => {
      return fn(searchText, options);
    },
    [searchText, fn]
  );
};

export const useGetFilteredOptionsBySearchTextFilter = <V>(
  options: V[],
  searchText: string,
  searchPath: string[],
  valuePath?: string,
  selectedOptions?: V[],
  threshold?: number,
  minTextLength = 3,
  limit?: number
): V[] => {
  const filterOptions = useSearchTextFilter<V>(
    searchText,
    searchPath,
    valuePath,
    selectedOptions,
    threshold,
    minTextLength,
    limit
  );
  return useMemo(() => {
    const filteredOptions = filterOptions(options);
    return lt(size(searchText), minTextLength) ||
      (gt(searchText, minTextLength) && isEmpty(filteredOptions))
      ? options
      : filteredOptions;
  }, [filterOptions, options, minTextLength, searchText]);
};
