export enum ChatMessageType {
  Media = "Media",
  Text = "Text"
}
export enum ChatMessageStatus {
  Sending = "Sending",
  Sent = "Sent",
  Error = "Error"
}

export interface NexupChatMessage {
  id?: string;
  type: ChatMessageType;
  status: ChatMessageStatus;
  body?: string;
  mediaFilename?: string;
  mediaLink?: string;
  author: string;
  index?: number;
  uuid?: string;
}
