import { FC, useI18n } from "@laba/react-common";
import React from "react";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { ActivityDefinitionCommonInput } from "components/generic/ActivityDefinition/ActivityDefinitionCommonInput/ActivityDefinitionCommonInput";
import { useSelector } from "react-redux";
import {
  getModelReferenceId,
  ModelReference,
  Organization,
  ResourceType,
  ScheduleDefinitionKey,
  ScheduleFormDataParamsKey
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { ScheduleNotificationConfigInput } from "components/generic/ScheduleNotificationConfigInput/ScheduleNotificationConfigInput";

export const tk = tkCP.adminPage[ResourceType.ScheduleDefinition];

export const ScheduleDefinitionMainContent: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organization = useWatchResourceForm<ModelReference<Organization>>(
    ScheduleFormDataParamsKey.organization
  );
  const organizationId =
    getModelReferenceId(organization) || workspaceOrganizationId;

  return (
    <>
      <ActivityDefinitionCommonInput organizationId={organizationId} />
      <ScheduleNotificationConfigInput
        title={t(tk.fields.practitionerScheduleNotificationConfigInput)}
        source={ScheduleDefinitionKey.notificationConfig.practitioner.thisKey}
      />
      <ScheduleNotificationConfigInput
        title={t(tk.fields.patientScheduleNotificationConfigInput)}
        source={ScheduleDefinitionKey.notificationConfig.patient.thisKey}
      />
    </>
  );
};
