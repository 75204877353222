import { FC, getClassName } from "@laba/react-common";
import React from "react";
import { DetailIndicatorBox } from "../DetailIndicatorBox/DetailIndicatorBox";
import { useDetailIndicatorBoxGroupStyle } from "./DetailIndicatorBoxGroupStyle";

export interface DetailIndicatorBoxGroupProps {
  className?: string;
  contentList: string[];
  prefix?: string;
}

export const DetailIndicatorBoxGroup: FC<DetailIndicatorBoxGroupProps> = ({
  className,
  contentList,
  prefix
}) => {
  const classes = useDetailIndicatorBoxGroupStyle();
  return (
    <div className={getClassName(classes.root, className)}>
      <p className={classes.prefix}>{prefix}</p>
      {contentList.map((content, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <DetailIndicatorBox key={idx} content={content} />
      ))}
    </div>
  );
};
