import {
  BoxSidePropertyKey,
  getBoxPropertyMixinBuilder
} from "model/style/box/boxSide";

export const paddingMixin = getBoxPropertyMixinBuilder({
  [BoxSidePropertyKey.Top]: "padding-top",
  [BoxSidePropertyKey.Right]: "padding-right",
  [BoxSidePropertyKey.Bottom]: "padding-bottom",
  [BoxSidePropertyKey.Left]: "padding-left"
});
