import { FC } from "@laba/react-common";
import React from "react";
import { DialogViewer } from "components/popup/DialogViewer/DialogViewer";
import { ArrowBackIcon, ArrowForwardIcon } from "components/icons";
import { Noop } from "@laba/ts-common";
import { IFrame } from "components/iframe/IFrame/IFrame";
import { useLinkPreviewPopupStyles } from "./useLinkPreviewPopupStyles";

export interface LinkEntry {
  url: string;
  isImage: boolean;
}

interface Props {
  linkEntry: LinkEntry;
  isOpen?: boolean;
  onClose?: Noop;
}

export const LinkPreviewPopup: FC<Props> = ({ isOpen, linkEntry, onClose }) => {
  const classes = useLinkPreviewPopupStyles();

  return (
    <DialogViewer
      isOpen={isOpen}
      onClose={onClose}
      onLeftIconClick={onClose}
      LeftIcon={ArrowBackIcon}
      RightIcon={ArrowForwardIcon}
      onRightIconClick={() => {
        window.open(linkEntry.url);
      }}
    >
      {linkEntry.isImage ? (
        <img
          className={classes.imgPopup}
          alt={linkEntry.url}
          src={linkEntry.url}
        />
      ) : (
        <IFrame
          className={classes.iframe}
          title={linkEntry.url}
          source={linkEntry.url}
        />
      )}
    </DialogViewer>
  );
};
