import React from "react";
import { FC } from "@laba/react-common";
import { NexupColor } from "model/nexupColor";
import { OdontogramIconProps } from "components/icons/OdontogramIcons/utils";

export const PivotIcon: FC<OdontogramIconProps> = props => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill={props.iconColor ?? NexupColor.Black}
    >
      <path d="M12.6011 14.126H9.27002V12.377H12.6011C13.1812 12.377 13.6499 12.2832 14.0073 12.0957C14.3647 11.9082 14.6255 11.6504 14.7896 11.3223C14.9595 10.9883 15.0444 10.6074 15.0444 10.1797C15.0444 9.77539 14.9595 9.39746 14.7896 9.0459C14.6255 8.68848 14.3647 8.40137 14.0073 8.18457C13.6499 7.96777 13.1812 7.85938 12.6011 7.85938H9.94678V18.8984H7.74072V6.10156H12.6011C13.5913 6.10156 14.4321 6.27734 15.1235 6.62891C15.8208 6.97461 16.3511 7.45508 16.7144 8.07031C17.0776 8.67969 17.2593 9.37695 17.2593 10.1621C17.2593 10.9883 17.0776 11.6973 16.7144 12.2891C16.3511 12.8809 15.8208 13.335 15.1235 13.6514C14.4321 13.9678 13.5913 14.126 12.6011 14.126Z" />
    </svg>
  );
};
