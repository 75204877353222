import { castDraft, Draft } from "immer";
import { ActionCreatorWithPayload, GenericReducer } from "store/types";
import { Page, PageDownload, PageInfo } from "@laba/ts-common";

export interface PaginationState<T> {
  elementList: T[];
  currentPageDownload: PageDownload;
  lastPageInfo?: PageInfo;
}

interface ReturnReducers<SliceState, PaginationElement> {
  initElementListDownload: GenericReducer<SliceState, { searchId: number }>;
  setElementListResult: GenericReducer<
    SliceState,
    { page: Page<PaginationElement> }
  >;
  addElementNextPageResult: GenericReducer<
    SliceState,
    {
      page: Page<PaginationElement>;
    }
  >;
  cleanElementDownloadingData: GenericReducer<SliceState>;
  initOnGetElementList: GenericReducer<SliceState>;
}

export interface PaginationStateActions<PaginationElement> {
  initElementListDownload: ActionCreatorWithPayload<{ searchId: number }>;
  cleanElementDownloadingData: ActionCreatorWithPayload<void>;
  setElementListResult: ActionCreatorWithPayload<{
    page: Page<PaginationElement>;
  }>;
  addElementNextPageResult: ActionCreatorWithPayload<{
    page: Page<PaginationElement>;
  }>;
  initOnGetElementList: ActionCreatorWithPayload<void>;
}

export interface GetPaginationStateReturn<SliceState, PaginationElement> {
  initialState: PaginationState<PaginationElement>;
  reducers: ReturnReducers<SliceState, PaginationElement>;
}
export const getPaginationState = <SliceState, PaginationElement>(
  paginationDraftSliceStateGetter: (
    sliceState: Draft<SliceState>
  ) => Draft<PaginationState<PaginationElement>>
): GetPaginationStateReturn<SliceState, PaginationElement> => {
  type Reducer<T = void> = GenericReducer<SliceState, T>;

  const initState: PaginationState<PaginationElement> = {
    elementList: [],
    currentPageDownload: {
      downloading: false
    }
  };

  const initElementListDownload: Reducer<{ searchId: number }> = (
    draftState,
    { payload }
  ) => {
    const draftPaginationState = paginationDraftSliceStateGetter(draftState);
    draftPaginationState.currentPageDownload.id = payload.searchId;
    draftPaginationState.currentPageDownload.downloading = true;
  };
  const setElementListResult: Reducer<{
    page: Page<PaginationElement>;
  }> = (draftState, { payload }) => {
    const draftPaginationState = paginationDraftSliceStateGetter(draftState);
    draftPaginationState.lastPageInfo = payload.page.page;
    draftPaginationState.elementList = castDraft(payload.page.entries);
    draftPaginationState.currentPageDownload.downloading = false;
  };

  const addElementNextPageResult: Reducer<{
    page: Page<PaginationElement>;
  }> = (draftState, { payload }) => {
    const draftPaginationState = paginationDraftSliceStateGetter(draftState);

    draftPaginationState.lastPageInfo = payload.page.page;
    draftPaginationState.elementList.push(...castDraft(payload.page.entries));
    draftPaginationState.currentPageDownload.downloading = false;
  };

  const initOnGetElementList: Reducer = draftState => {
    const draftPaginationState = paginationDraftSliceStateGetter(draftState);

    draftPaginationState.elementList = castDraft(initState.elementList);
    draftPaginationState.currentPageDownload = {
      ...initState.currentPageDownload,
      downloading: true
    };
    draftPaginationState.lastPageInfo = initState.lastPageInfo;
  };

  const cleanElementDownloadingData: Reducer = draftState => {
    const draftPaginationState = paginationDraftSliceStateGetter(draftState);

    draftPaginationState.elementList = castDraft(initState.elementList);
    draftPaginationState.currentPageDownload = initState.currentPageDownload;
    draftPaginationState.lastPageInfo = initState.lastPageInfo;
  };
  return {
    initialState: initState,
    reducers: {
      initElementListDownload,
      setElementListResult,
      addElementNextPageResult,
      cleanElementDownloadingData,
      initOnGetElementList
    }
  };
};
