import {
  createStyle,
  FlexAlignItems,
  flexItemMixin,
  FlexJustifyContent,
  flexRowMixin
} from "@laba/react-common";
import { Theme } from "model/theme";

export const useLocationCardIndicatorsStyle = createStyle(
  (_theme: Theme) => ({
    root: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        gap: 12,
        justifyContent: FlexJustifyContent.SpaceBetween
      })
    },
    indicator: {
      ...flexItemMixin({ grow: 1, shrink: 1, basis: "0%" })
    }
  }),
  "LocationCardIndicators"
);
