import { useSelector } from "react-redux";
import { serverSelector } from "store/session/selectors";
import { ExportedFileNameFunction } from "components/generic/ResourceAction/ResourceAction";
import { Model } from "@laba/nexup-api";
import { DeepPartial, Optional } from "@laba/ts-common";

export const useListExportedFileName = <T extends Model>(
  resourceName: string
): ExportedFileNameFunction<T> => {
  const server = useSelector(serverSelector);
  return () => `${resourceName} (${server})`;
};

export const useElementExportedFileName = <T extends Model>(
  getResourceName: (resource: T | DeepPartial<T>) => Optional<string>
): ExportedFileNameFunction<T> => {
  const server = useSelector(serverSelector);
  return element => {
    return element
      ? `${(getResourceName(element) ?? "").replace(
          /[^a-z0-9]/gi,
          "_"
        )} (${server} ${element.id})`
      : "";
  };
};
