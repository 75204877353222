import { I18nT, ReactElement } from "@laba/react-common";
import React from "react";
import { tkCC } from "translation/i18n";
import { TextInput } from "components/generic/TextInput/TextInput";
import { notUndefined, Optional } from "@laba/ts-common";

export interface GetFiltersProps {
  searchSource?: string;
  extraFilters?: Optional<ReactElement>[];
}

const tk = tkCC.listFilters;
export const getFilters = (
  { searchSource = "content", extraFilters = [] }: GetFiltersProps,
  t: I18nT
): ReactElement[] => {
  return [
    <TextInput
      source={searchSource}
      alwaysOn
      label={t(tk.search)}
      resettable
    />,
    ...extraFilters.filter(notUndefined)
  ];
};
