import {
  colorChangeAlpha,
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  flexRowMixin,
  marginMixin,
  paddingMixin,
  pxToRem
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { StyleVariant, TextVariant } from "model/themeVariant";

export const ToSubmitFileItemStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexColumnMixin({ gap: 8 }),
      width: "100%"
    },
    attachmentContainer: {
      ...flexRowMixin({ gap: 12, alignItems: FlexAlignItems.Center }),
      ...paddingMixin({ leftRight: 16, topBottom: 12 }),
      backgroundColor: colorChangeAlpha(
        getThemeStyleColor(theme, StyleVariant.BlackWhite),
        0.08
      )
    },
    errorContainer: {
      ...flexRowMixin({ gap: 12, alignItems: FlexAlignItems.Center }),
      ...paddingMixin({ leftRight: 16, topBottom: 12 }),
      backgroundColor: colorChangeAlpha(
        getThemeStyleColor(theme, StyleVariant.Danger),
        0.08
      ),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Danger,
        ThemeStyleColorVariant.Dark
      )
    },
    errorText: {
      ...paddingMixin({ right: 16, left: 4 })
    },
    filename: {
      ...themeTextMixin(theme, TextVariant.Body2, 1)
    },
    initialIcon: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      ),
      width: pxToRem(24),
      height: pxToRem(24)
    },
    endIcon: {
      ...marginMixin({
        left: "auto",
        right: 8
      })
    },
    failedEndContainer: {
      ...marginMixin({
        left: "auto",
        right: 8
      }),
      ...flexRowMixin({ gap: 8 })
    },
    failedIcon: {
      color: getThemeStyleColor(theme, StyleVariant.Danger)
    }
  }),
  "ToSubmitFileItem"
);
