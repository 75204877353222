import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  MedicalRequestDefinition,
  MedicalRequestDefinitionFieldType,
  MedicalRequestDefinitionKey,
  MRDFieldPropertyBase,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { FormDataConsumer } from "components/generic/FormDataConsumer/FormDataConsumer";
import { MedicalRequestDefinitionFieldFields } from "components/pages/NexupAdmin/resources/medicalRequestDefinition/MedicalRequestDefinitionFieldFields";

export const tk = tkCP.adminPage[ResourceType.MedicalRequestDefinition];
export const MedicalRequestDefinitionFieldsContent: FC = () => {
  const { t } = useI18n();
  return (
    <ArrayInput
      source={MedicalRequestDefinitionKey.fields}
      label={t(tk.fields.fields)}
    >
      <FormDataConsumer<
        MedicalRequestDefinition,
        MRDFieldPropertyBase<MedicalRequestDefinitionFieldType>
      >>
        {({ getSource: getFieldSource, scopedFormData: field }) => {
          return (
            <MedicalRequestDefinitionFieldFields
              fieldType={field?.type}
              getSource={getFieldSource}
              withProps={false}
            />
          );
        }}
      </FormDataConsumer>
    </ArrayInput>
  );
};
