import { ReactElement } from "@laba/react-common";
import React from "react";
import {
  DateTimeInput as ReactAdminDateTimeInput,
  DateTimeInputProps as ReactAdminDateTimeInputProps,
  required as requiredValidate
} from "react-admin";
import {
  applyFormatToDate,
  DateFormat,
  DateTime,
  getAsArray,
  notNull
} from "@laba/ts-common";

export type DateTimeInputProps = ReactAdminDateTimeInputProps & {
  dateFormat?: string | DateFormat;
  required?: boolean;
};
const inputFormat = "yyyy-LL-dd'T'HH:mm";
export const DateTimeInput = ({
  dateFormat = "yyyy-LL-dd'T'HH:mm:ss.SSSZZ",
  validate,
  required,
  ...otherProps
}: DateTimeInputProps): ReactElement => {
  const validateArray = getAsArray(validate).filter(notNull);
  if (required) validateArray.push(requiredValidate());
  return (
    <ReactAdminDateTimeInput
      fullWidth
      variant="outlined"
      {...otherProps}
      format={value => {
        let date = DateTime.fromFormat(value ?? "", dateFormat);
        if (!date.isValid) date = DateTime.fromApiDate(value ?? "");
        return applyFormatToDate(date, inputFormat) ?? "";
      }}
      parse={value => {
        const date = DateTime.fromFormat(value ?? "", inputFormat);
        if (date.isValid) return applyFormatToDate(date, dateFormat);
        return value ?? "";
      }}
      validate={validateArray}
    />
  );
};
