import { ResourceType } from "model/primitives/resourceModel";
import { ContactPointType } from "model/primitives/contactPoint";
import { Day } from "model/primitives/time/time";
import { Location, LocationStatus } from "../location/location";

export const directoryLocation: Location = {
  resourceType: ResourceType.Location,
  name: "Nicolas Leyro",
  description:
    "Descripción de la farmacia: Lorem Ipsum, sometimes referred to as 'lipsum', is the placeholder text used in design when creating content. It helps designers plan out where the content will sit, without needing",
  status: LocationStatus.Active,
  payerSpecialityList: ["Cardiologia", "Dermatologia", "Infectología"],
  website: "www.google.com",
  address: {
    resourceType: ResourceType.Address,
    street: "Boulevard Galvez 1233",
    city: "Santa Fe",
    country: "Argentina",
    postalCode: "3000"
  },
  telecom: {
    phone: {
      resourceType: ResourceType.Phone,
      type: ContactPointType.Work,
      value: "+54 9 564565456654"
    }
  },
  latitude: -34.60078617119882,
  longitude: -58.38294211074161,
  hoursOfOperation: [
    {
      daysOfWeek: [
        Day.Monday,
        Day.Tuesday,
        Day.Wednesday,
        Day.Thursday,
        Day.Saturday
      ],
      openingTime: "08:00:00",
      closingTime: "18:00:00"
    },
    {
      daysOfWeek: [Day.Sunday],
      openingTime: "08:00:00",
      closingTime: "12:00:00"
    },
    {
      daysOfWeek: [Day.Friday],
      openingTime: "08:00:00",
      closingTime: "12:00:00",
      allDay: true
    }
  ],
  payerPlanList: ["Todos"]
};
