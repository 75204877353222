import { Theme, ThemeStyleColorVariant } from "model/theme";
import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  flexItemMixin,
  FlexJustifyContent,
  flexRowMixin,
  marginMixin,
  paddingMixin,
  pxToRem
} from "@laba/react-common";
import { StyleVariant, TextVariant } from "model/themeVariant";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

export interface ChatStyleProps {
  style: StyleVariant;
  showLeftIcon: boolean;
  showRightIcon: boolean;
}

export interface MuiIconStyleProps {
  style: StyleVariant;
}

export const useVerticalContainerStyle = createStyle(
  (theme: Theme) => ({
    root: (props: ChatStyleProps) => ({
      ...flexColumnMixin({
        justifyContent: FlexJustifyContent.FlexStart
      }),
      backgroundColor: getThemeStyleColor(
        theme,
        props.style,
        ThemeStyleColorVariant.Contrast
      ),
      ...paddingMixin({
        all: 16
      })
    }),
    header: {
      width: "100%",
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.FlexStart
      })
    },
    headerTitle: (props: ChatStyleProps) => ({
      ...themeTextMixin(theme, TextVariant.Subtitle2, 1),
      color: getThemeStyleColor(
        theme,
        props.style,
        ThemeStyleColorVariant.GrayDark
      )
    }),
    mainContent: {
      ...flexColumnMixin({
        justifyContent: FlexJustifyContent.FlexStart
      }),
      ...flexItemMixin({
        grow: 1
      }),
      overflow: "hidden"
    }
  }),
  "VerticalContainer"
);

export const useRightIconMuiIconClasses = createStyle(
  (theme: Theme) => ({
    root: (props: MuiIconStyleProps) => ({
      width: pxToRem(24),
      height: pxToRem(24),
      color: getThemeStyleColor(
        theme,
        props.style,
        ThemeStyleColorVariant.GrayDark
      ),
      ...marginMixin({
        left: "auto"
      })
    })
  }),
  "RightMuiIconClasses"
);

export const useLeftIconMuiIconClasses = createStyle(
  (theme: Theme) => ({
    root: (props: MuiIconStyleProps) => ({
      width: pxToRem(24),
      height: pxToRem(24),
      color: getThemeStyleColor(
        theme,
        props.style,
        ThemeStyleColorVariant.GrayDark
      ),
      ...marginMixin({
        right: 16
      })
    })
  }),
  "LeftMuiIconClasses"
);
