import React from "react";
import { FC, getClassName } from "@laba/react-common";
import { isEmpty } from "lodash-es";
import { Tag } from "components/tags/Tag/Tag";
import { useLocationDetailsCardStyle } from "components/card/LocationDetailsCard/LocationDetailsCardStyle";
import { LocationCardHeader } from "components/card/LocationCard/LocationCardHeader/LocationCardHeader";
import { LocationCardButtons } from "components/card/LocationCard/LocationCardButtons/LocationCardButtons";
import { LocationCardIndicators } from "components/card/LocationCard/LocationCardIndicators/LocationCardIndicators";
import { BaseIconButton } from "components/buttons/BaseIconButton/BaseIconButton";
import { CloseIcon } from "components/icons";
import { Noop } from "@laba/ts-common";
import { LocationDetailsCardTextContent } from "./LocationDetailsCardTextContent/LocationDetailsCardTextContent";
import { LocationCardAddressAndAvailability } from "../LocationCard/LocationCardAddressAndAvailability/LocationCardAddressAndAvailability";

export interface LocationDetailsCardProps {
  className?: string;
  name?: string;
  tag?: string;
  imageSource?: string;
  specialityList?: string[];
  active: boolean;
  statusText: string;
  distance?: number;
  distanceUnit?: string;
  phone?: string;
  onPhoneClicked?: Noop;
  website?: string;
  onWebsiteClicked?: Noop;
  address?: string;
  onAddressClicked?: Noop;
  schedule?: string[];
  addressTitle: string;
  descriptionTitle: string;
  description: string;
  planTitle: string;
  planList?: string[];
  onClose?: Noop;
  availability?: string;
}

export const LocationDetailsCard: FC<LocationDetailsCardProps> = ({
  className,
  tag,
  name,
  imageSource,
  active,
  statusText,
  distance,
  distanceUnit,
  specialityList = [],
  phone,
  onPhoneClicked,
  website,
  onWebsiteClicked,
  address,
  onAddressClicked,
  addressTitle,
  descriptionTitle,
  description,
  schedule = [],
  planTitle,
  planList = [],
  onClose,
  availability
}) => {
  const classes = useLocationDetailsCardStyle();

  return (
    <div className={getClassName(className, classes.root)}>
      <div className={classes.closeContainer}>
        {!isEmpty(tag) && <Tag text={tag} />}
        <BaseIconButton
          className={classes.closeIcon}
          Icon={CloseIcon}
          onClick={onClose}
        />
      </div>
      <LocationCardHeader name={name} imageSource={imageSource} />
      <LocationCardButtons
        phone={phone}
        onPhoneClicked={onPhoneClicked}
        website={website}
        onWebsiteClicked={onWebsiteClicked}
        address={address}
        onAddressClicked={onAddressClicked}
      />
      <LocationCardIndicators
        specialityList={specialityList}
        distance={distance}
        distanceUnit={distanceUnit}
        tag={tag}
      />
      <LocationCardAddressAndAvailability
        availability={availability}
        addressTitle={addressTitle}
        fullAddress={address}
        schedule={schedule}
        active={active}
        statusText={statusText}
      />
      <LocationDetailsCardTextContent
        title={planTitle}
        description={planList.join(", ")}
      />

      <LocationDetailsCardTextContent
        title={descriptionTitle}
        description={description}
      />
    </div>
  );
};
