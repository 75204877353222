import { FC } from "@laba/react-common";
import React from "react";
import { AttachFile, CloseIcon, ErrorIcon, ReplayIcon } from "components/icons";
import { Spinner } from "components/spinners/Spinner/Spinner";
import { SizeVariant } from "model/themeVariant";
import { ToSubmitFileItemStyle } from "components/popup/AttachmentPopup/AttachmentCardContent/ToSubmitFileItem/ToSubmitFileItemStyle";
import { BaseIconButton } from "components/buttons/BaseIconButton/BaseIconButton";
import { ErrorText } from "components/text/ErrorText/ErrorText";
import { AttachmentConfig, AttachmentLoadingState } from "../../helpers";

export interface ToSubmitFileItemProps {
  fileItem: AttachmentConfig;
  onClickClose?: (f: AttachmentConfig) => void;
  onRetryUploadFile?: (f: AttachmentConfig) => void;
  retryDownloadText?: string;
}

export const ToSubmitFileItem: FC<ToSubmitFileItemProps> = ({
  fileItem,
  onClickClose,
  onRetryUploadFile,
  retryDownloadText
}) => {
  const classes = ToSubmitFileItemStyle();

  const getSubmitFileItemEndIcon = (file: AttachmentConfig) => {
    switch (file.state) {
      case AttachmentLoadingState.FAILED:
        return (
          <BaseIconButton
            size={SizeVariant.ExtraSmall}
            Icon={ReplayIcon}
            className={classes.endIcon}
            onClick={() => onRetryUploadFile?.(fileItem)}
            title={retryDownloadText}
          />
        );
      case AttachmentLoadingState.UPLOADED:
        return (
          <BaseIconButton
            className={classes.endIcon}
            size={SizeVariant.ExtraSmall}
            Icon={CloseIcon}
            onClick={() => {
              onClickClose?.(file);
            }}
          />
        );
      case AttachmentLoadingState.LOADING:
        return <Spinner className={classes.endIcon} size={24} />;
    }
  };

  const isFailed = fileItem.state === AttachmentLoadingState.FAILED;

  return (
    <div className={classes.root}>
      <div
        className={
          isFailed ? classes.errorContainer : classes.attachmentContainer
        }
      >
        {isFailed ? (
          <ErrorIcon className={classes.failedIcon} />
        ) : (
          <AttachFile className={classes.initialIcon} />
        )}
        <p className={classes.filename}>{fileItem.filename}</p>
        {getSubmitFileItemEndIcon(fileItem)}
      </div>
      <ErrorText
        showError={Boolean(isFailed && fileItem.error)}
        error={fileItem.error}
        className={classes.errorText}
      />
    </div>
  );
};
