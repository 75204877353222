import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { getFrontPatientPortalApiUrl, request } from "request/nexupRequest";
import { ModelId } from "model/primitives/model/model";
import {
  CodeSystem,
  CodeSystemGroup,
  CodeSystemStatus,
  CodeSystemSystem
} from "model/resource/entities/codeSystem";
import {
  ElementOrArray,
  getAsArrayOrUndefined,
  HttpMethod
} from "@laba/ts-common";
import { CodeSystemGroupListQueryParams } from "api/entities";

export interface PatientCodeSystemListQueryParams extends ListQueryParams {
  id?: ModelId[];
  organization?: ModelId;
  status?: CodeSystemStatus;
  system?: ElementOrArray<CodeSystemSystem>;
}

const codeSystemBasePath = "/code-system";

export const getPatientCodeSystemList = (
  params?: PatientCodeSystemListQueryParams
): Promise<ApiPageRequestResponse<CodeSystem>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontPatientPortalApiUrl(codeSystemBasePath),
    params
  });

export const getPatientCodeSystem = (
  id: ModelId
): Promise<ApiRequestResponse<CodeSystem>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontPatientPortalApiUrl(`${codeSystemBasePath}/${id}`)
  });

export const getPatientCodeSystemGroupList = (
  params?: CodeSystemGroupListQueryParams
): Promise<ApiPageRequestResponse<CodeSystemGroup>> => {
  return request({
    method: HttpMethod.GET,
    url: getFrontPatientPortalApiUrl(`${codeSystemBasePath}/group`),
    params: {
      ...params,
      organization: getAsArrayOrUndefined(params?.organization)
    }
  });
};
