import { ReactElement, useI18n } from "@laba/react-common";
import {
  AutocompleteInput,
  required as requiredValidate,
  Validator
} from "react-admin";
import React from "react";
import {
  SelectInput,
  SelectInputVariant
} from "components/generic/SelectInput/SelectInput";
import { Concept, KnownConceptPropertyUse } from "@laba/nexup-api";
import { useCodeSystemSelectorInputStyle } from "components/generic/CodeSystemSelectorInput/codeSystemSelectorInputStyle";
import { tkCC } from "translation/i18n";
import { getAsArray, notNull, Optional } from "@laba/ts-common";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { isEmpty } from "lodash-es";

export interface CodeSystemSelectorInputProps<T> {
  source: string;
  label: string;
  concept?: Concept;
  propertyUse?: KnownConceptPropertyUse;
  alwaysOn?: boolean;
  fullWidth?: boolean;
  resettable?: boolean;
  validate?: Validator | Validator[];
  required?: boolean;
  autocomplete?: boolean;
  parse?: (selectValue: Optional<string>) => Optional<T>;
  format?: (formValue: Optional<T>) => Optional<string>;
  disabled?: boolean;
}

const tk = tkCC.inputs.conceptPropertyInput;
export const ConceptPropertyInput = <T,>({
  source,
  label,
  concept,
  propertyUse,
  alwaysOn = false,
  fullWidth = true,
  resettable = false,
  validate,
  required,
  autocomplete,
  parse,
  format,
  disabled
}: CodeSystemSelectorInputProps<T>): ReactElement => {
  const classes = useCodeSystemSelectorInputStyle();
  const currentValue = useWatchResourceForm<string>(source);
  const { t } = useI18n();
  const validateArray = getAsArray(validate).filter(notNull);
  if (required) validateArray.push(requiredValidate());

  const conceptProperties =
    concept?.property.filter(p => p.use === propertyUse) ?? [];

  const isAutocomplete = autocomplete ?? (conceptProperties.length || 0) > 15;

  const choices = conceptProperties.map(p => ({
    id: p.code ?? "",
    name: p.code ?? ""
  }));
  if (currentValue && !choices.find(element => element.id === currentValue)) {
    choices.push({
      id: currentValue,
      name: currentValue
    });
  }

  const disabledInput = disabled || isEmpty(choices);

  return isAutocomplete ? (
    <AutocompleteInput
      source={source}
      variant="outlined"
      fullWidth={fullWidth}
      choices={choices}
      shouldRenderSuggestions={() => true}
      noOptionsText={t(tk.noOptionsText)}
      className={classes.root}
      label={label}
      validate={validateArray}
      alwaysOn={alwaysOn}
      createLabel={t(tk.createLabel)}
      parse={value => {
        return (parse ? parse(value) : value) ?? null;
      }}
      format={value => {
        return format ? format(value) : value;
      }}
      disabled={disabledInput}
    />
  ) : (
    <SelectInput
      variant={SelectInputVariant.Outlined}
      fullWidth={fullWidth}
      source={source}
      label={label}
      choices={choices}
      alwaysOn={alwaysOn}
      className={classes.root}
      resettable={resettable}
      createLabel={t(tk.createLabel)}
      validate={validateArray}
      parse={parse}
      format={format}
      disabled={disabledInput}
    />
  );
};
