import {
  borderMixin,
  createStyle,
  flexColumnMixin,
  flexItemMixin,
  paddingMixin,
  pxToRem,
  StyleVariant,
  TextVariant,
  useUnselectableTextMixin
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

const searchbarContainerMarginTop = pxToRem(24);
const searchbarContainerMarginBottom = pxToRem(8);
const itemLateralPadding = pxToRem(16);

interface MenuDropdownColumnStylesProps {
  menuHeight: number;
  columnWidth: number;
}

export const useMenuDropdownColumnStyles = createStyle(
  (theme: Theme) => ({
    root: (props: MenuDropdownColumnStylesProps) => ({
      width: pxToRem(props.columnWidth),
      overflow: "hidden"
    }),
    secondary: {
      ...borderMixin({
        side: {
          left: 1,
          topBottom: 0,
          right: 0
        },
        style: "solid",
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.GrayLight
        )
      }),
      ...paddingMixin({ top: 48 })
    },
    searchbarContainer: {
      ...paddingMixin({
        top: searchbarContainerMarginTop,
        bottom: searchbarContainerMarginBottom,
        leftRight: itemLateralPadding
      }),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    },
    scrollContainer: (props: MenuDropdownColumnStylesProps) => ({
      maxHeight: pxToRem(props.menuHeight),
      height: "initial"
    }),
    categoriesContainer: {
      ...flexColumnMixin({})
    },
    itemCategory: {
      ...flexColumnMixin({}),
      "&:not(:last-child)": {
        ...borderMixin({
          side: {
            bottom: 1,
            leftRight: 0,
            top: 0
          },
          style: "solid",
          color: getThemeStyleColor(
            theme,
            StyleVariant.Primary,
            ThemeStyleColorVariant.GrayLight
          )
        })
      }
    },
    itemCategoryTopPadding: {
      ...paddingMixin({
        top: 16
      })
    },
    categoryTitle: {
      ...flexItemMixin({ grow: 0 }),
      ...themeTextMixin(theme, TextVariant.Body2),
      ...useUnselectableTextMixin(),
      height: "fit-content",
      textTransform: "uppercase",
      ...paddingMixin({ leftRight: itemLateralPadding })
    },
    secondaryTitle: {
      ...themeTextMixin(theme, TextVariant.Subtitle2),
      textTransform: "none"
    }
  }),
  "MenuDropdownColumn"
);
