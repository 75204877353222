import React from "react";
import { FC } from "@laba/react-common";
import { isEmpty } from "lodash-es";

import { useLocationDetailsCardTextContentStyle } from "./useLocationDetailsCardTextContentStyle";

export interface LocationDetailsCardTextContentProps {
  title: string;
  description?: string;
}

export const LocationDetailsCardTextContent: FC<
  LocationDetailsCardTextContentProps
> = ({ title, description }) => {
  const classes = useLocationDetailsCardTextContentStyle();

  if (isEmpty(description)) return null;

  return (
    <div className={classes.root}>
      <p className={classes.textContentTitle}>
        {`${title}: `}
        <span className={classes.textContentDescription} title={description}>
          {description}
        </span>
      </p>
    </div>
  );
};
