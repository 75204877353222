import {
  Appointment,
  AppointmentDefinition,
  getFullNameFromPerson,
  HealthcareService,
  Location,
  MedicalDevice,
  Medication,
  MedicationSubstance,
  Notification,
  Organization,
  Patient,
  Practitioner,
  ProcedureReportDefinition,
  Product,
  ScheduleDefinition,
  User
} from "@laba/nexup-api";

type OptionText<T> = (record?: T) => string;
export const organizationOptionText: OptionText<Organization> = record =>
  `${record?.name} (${record?.id})`;

export const practitionerOptionText: OptionText<Practitioner> = record =>
  `${getFullNameFromPerson(record)} (${record?.id})`;

export const patientOptionText: OptionText<Patient> = record =>
  `${getFullNameFromPerson(record)} (${record?.id})`;

export const healthcareServiceOptionText: OptionText<
  HealthcareService
> = record => `${record?.name} (${record?.id})`;

export const appointmentDefinitionOptionText: OptionText<
  AppointmentDefinition
> = record => `${record?.name} (${record?.id})`;

export const appointmentOptionText: OptionText<Appointment> = record =>
  `${record?.id} (inicio: ${record?.startDate})`;

export const procedureReportDefinitionOptionText: OptionText<
  ProcedureReportDefinition
> = record => `${record?.name} (${record?.id})`;

export const scheduleDefinitionOptionText: OptionText<
  ScheduleDefinition
> = record => `${record?.name} (${record?.id})`;

export const locationOptionText: OptionText<Location> = record =>
  `${record?.name}: ${record?.description} (${record?.id})`;

export const medicalDeviceOptionText: OptionText<MedicalDevice> = record =>
  `${record?.name} (${record?.id})`;

export const medicationSubstanceOptionText: OptionText<
  MedicationSubstance
> = record => `${record?.name} (${record?.id})`;

export const medicationOptionText: OptionText<Medication> = record =>
  `${record?.name}: ${record?.description} (${record?.id})`;

export const userOptionText: OptionText<User> = record =>
  `${record?.username}: ${record?.email || ""} (${record?.id})`;

export const notificationOptionText: OptionText<Notification> = record =>
  `${record?.id} (${record?.receiver || ""})`;

export const productOptionText: OptionText<Product> = record =>
  `${record?.title} (${record?.id}) [Estado: ${record?.status}]`;
