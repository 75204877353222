import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  FlexJustifyContent,
  paddingMixin,
  pxToRem,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

export const useFilePreviewDialogInvalidFormatCardStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexColumnMixin({
        gap: 8,
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.Center
      }),
      ...paddingMixin({ leftRight: 16, top: 16, bottom: 24 }),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      ),
      width: pxToRem(280),
      height: "fit-content"
    },
    warningIcon: {
      color: getThemeStyleColor(theme, StyleVariant.Danger),
      height: pxToRem(40),
      width: "auto"
    },
    title: {
      ...themeTextMixin(theme, TextVariant.H5),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      ),
      textAlign: "center"
    },
    description: {
      ...themeTextMixin(theme, TextVariant.Body2),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      ),
      textAlign: "center"
    }
  }),
  "FilePreviewDialogInvalidFormatCard"
);
