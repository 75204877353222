import {
  ActivityDefinition,
  ActivityDefinitionKind,
  PublicationStatus
} from "model/resource/activity/activityDefinition";
import { ResourceType } from "model/primitives/resourceModel";
import {
  validateCreateByResourcePermissions,
  validateEditByResourcePermissions
} from "model/primitives/resorcePermissions/utils";
import { MedicalAppConfigPermissionCode } from "../app/appConfig";

export const isActivityDefinitionActive = <
  R extends ResourceType,
  V extends ActivityDefinitionKind
>(
  definition: ActivityDefinition<R, V>
): boolean => {
  return definition.status === PublicationStatus.Active;
};

export const filterCreationByDefinitionPermissionConfig = <
  T extends ResourceType,
  P extends ActivityDefinitionKind
>(
  definitions: ActivityDefinition<T, P>[],
  permissionList?: MedicalAppConfigPermissionCode[]
): ActivityDefinition<T, P>[] => {
  return definitions.filter(def =>
    validateCreateByResourcePermissions(
      def.permissionConfig?.createAppConfig,
      permissionList
    )
  );
};

export const validateActivityDefinitionEditPermission = <
  T extends ResourceType,
  P extends ActivityDefinitionKind
>(
  definition?: ActivityDefinition<T, P>,
  availablePermissions?: MedicalAppConfigPermissionCode[]
): boolean => {
  return validateEditByResourcePermissions(
    definition?.permissionConfig?.editAppConfig,
    availablePermissions
  );
};
