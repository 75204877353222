import React from "react";
import { getClassName, ReactElement } from "@laba/react-common";
import { DrawerOptionConfig } from "components/drawer/Drawer/Drawer";
import { useDrawerOptionRowStyleClassNames } from "components/drawer/Drawer/DrawerItemRow/DrawerItemRowStyle";
import RightIcon from "@material-ui/icons/KeyboardArrowRight";
import DownIcon from "@material-ui/icons/KeyboardArrowDown";
import { head, size } from "lodash-es";
import { ButtonBase } from "components/buttons/ButtonBase/ButtonBase";
import { Noop } from "@laba/ts-common";

interface DrawerItemRowProps<V, R> {
  drawerOpen: boolean;
  activeOption: boolean;
  optionConfig: DrawerOptionConfig<V, R>;
  optionOpened: boolean;
  selectedRoute?: R;
  onOptionClicked?: (o?: V) => void;
  onRouteClicked?: (o: V, r: R) => void;
  onClose?: Noop;
  onOpen?: Noop;
  compareRoute?: (r1?: R, r2?: R) => boolean;
}

export const DrawerItemRow = <V, R>({
  drawerOpen,
  activeOption,
  optionOpened,
  selectedRoute,
  optionConfig,
  onOptionClicked,
  onRouteClicked,
  onClose,
  onOpen,
  compareRoute = (r1?: R, r2?: R) => r1 === r2
}: DrawerItemRowProps<V, R>): ReactElement => {
  const classes = useDrawerOptionRowStyleClassNames({ activeOption });
  const ArrowIcon = optionOpened ? DownIcon : RightIcon;
  const singleRoute = size(optionConfig.routes) === 1;
  const route = head(optionConfig.routes)?.routeValue;
  const isSingleAndSelectedRoute =
    singleRoute && compareRoute(selectedRoute, route);

  return (
    <div className={classes.itemRoot}>
      <ButtonBase
        className={getClassName(
          classes.itemContainer,
          isSingleAndSelectedRoute ? classes.defaultCursor : undefined
        )}
        disableRipple
        onClick={() => {
          if (!drawerOpen) {
            onOpen?.();
          }
          onOptionClicked?.(optionOpened ? undefined : optionConfig.value);
          if (singleRoute) {
            route && onRouteClicked?.(optionConfig.value, route);
            onClose?.();
          }
        }}
      >
        <div className={classes.icon}>
          {optionConfig.Icon && <optionConfig.Icon />}
        </div>
        {drawerOpen && (
          <div className={classes.endContainer}>
            <p className={classes.optionText}>{optionConfig.text}</p>
            {!singleRoute && (
              <div className={classes.arrowIconContainer}>
                <ArrowIcon />
              </div>
            )}
          </div>
        )}
      </ButtonBase>
      {!singleRoute &&
        drawerOpen &&
        optionOpened &&
        optionConfig.routes.map(r => {
          const isSelectedRoute = compareRoute(selectedRoute, r.routeValue);
          return (
            <ButtonBase
              disableRipple
              className={getClassName(
                classes.routeContainer,
                isSelectedRoute ? classes.defaultCursor : undefined
              )}
              key={r.routeText}
              type="button"
              onClick={() => {
                onRouteClicked?.(optionConfig.value, r.routeValue);
                onClose?.();
              }}
            >
              <p
                className={getClassName(
                  classes.routeText,
                  isSelectedRoute ? classes.routeSelected : undefined
                )}
              >
                {r.routeText}
              </p>
            </ButtonBase>
          );
        })}
    </div>
  );
};
