import { isString, Optional } from "model/types";

export type Dimension = string | number;

const rootElementSize = 16;

export const pxToRemNumber = (pxSize: number): number =>
  pxSize / rootElementSize;

export const numberToPx = (pxSize: number): string => `${pxSize}px`;

export const pxToRem = (pxSize: number): string =>
  `${pxToRemNumber(pxSize)}rem`;

export const getDimensionPropertyValue = (
  dimension?: Dimension
): Optional<string> =>
  dimension == null
    ? undefined
    : isString(dimension)
    ? dimension
    : pxToRem(dimension);
