import { FC, IconC, useClientRect } from "@laba/react-common";
import React from "react";
import { Add } from "components/icons";
import { IconOrDefaultButton } from "components/buttons/IconOrDefaultButton/IconOrDefaultButton";
import { isSingleValueArray } from "@laba/ts-common";
import { head, isEmpty } from "lodash-es";
import {
  bindPopover,
  bindTrigger,
  usePopupState
} from "material-ui-popup-state/hooks";
import { Popover } from "@material-ui/core";
import { usePopoverMenuDropdownStyles } from "./usePopoverMenuDropdownStyle";
import { MenuParentNode, MenuType } from "../menuTreeTypes";
import { MenuDropdown } from "./MenuDropdown/MenuDropdown";

import { HorizontalPosition, VerticalPosition } from "../Menu/Menu";

export interface PopoverMenuDropdownProps {
  isMobile?: boolean;
  buttonClassName?: string;
  buttonTitle?: string;
  searchText?: string;
  onChangeSearchText?: (newSearchText?: string) => void;
  categories: MenuParentNode[];
  menuHeight?: number;
  noCategoriesText?: string;
  adjustMenuColumnToButtonWidth?: boolean;
  showSearchBar?: boolean;
  buttonRef?: React.Ref<HTMLButtonElement>;
  buttonSize?: number;
  icon?: IconC;
}

export const PopoverMenuDropdown: FC<PopoverMenuDropdownProps> = ({
  isMobile,
  buttonClassName,
  buttonTitle,
  searchText = "",
  onChangeSearchText,
  categories,
  menuHeight = 400,
  noCategoriesText,
  adjustMenuColumnToButtonWidth,
  showSearchBar,
  icon = Add
}) => {
  const childrens = categories.flatMap(c => c.children);
  const [buttonRect, buttonRef] = useClientRect<HTMLButtonElement>();
  const buttonSize = buttonRect ? buttonRect.width : 0;

  const popoverState = usePopupState({
    variant: "popover",
    popupId: "DesktopMenuDropdown"
  });

  const columnWidth = adjustMenuColumnToButtonWidth ? buttonSize : 280;
  const classes = usePopoverMenuDropdownStyles({ columnWidth });
  if (isSingleValueArray(childrens) && isEmpty(searchText)) {
    const children = head(childrens);
    if (children?.type === MenuType.Leaf) {
      return (
        <IconOrDefaultButton
          className={buttonClassName}
          isIconButton={isMobile}
          icon={icon}
          onClick={children.onClick}
          title={buttonTitle}
          buttonRef={buttonRef}
        />
      );
    }
  }

  const bindTriggerState = bindTrigger(popoverState);
  const bindPopoverState = bindPopover(popoverState);

  const onClose = () => {
    bindPopoverState.onClose();
  };

  return (
    <>
      <IconOrDefaultButton
        isIconButton={isMobile}
        icon={icon}
        className={buttonClassName}
        title={buttonTitle}
        buttonRef={buttonRef}
        {...bindTriggerState}
      />
      <Popover
        {...bindPopoverState}
        anchorOrigin={{
          horizontal: HorizontalPosition.Left,
          vertical: VerticalPosition.Bottom
        }}
        PaperProps={{ className: classes.menu }}
        transformOrigin={{
          horizontal: HorizontalPosition.Left,
          vertical: VerticalPosition.Top
        }}
      >
        <MenuDropdown
          categories={categories}
          searchText={searchText}
          onChangeSearchText={onChangeSearchText}
          menuHeight={menuHeight}
          columnWidth={columnWidth}
          showSearchBar={showSearchBar}
          onClose={onClose}
        />
        {isEmpty(categories) && noCategoriesText && (
          <p className={classes.noCategoriesText} title={noCategoriesText}>
            {noCategoriesText}
          </p>
        )}
      </Popover>
    </>
  );
};
