import { createStyle, marginMixin } from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { StyleVariant, TextVariant } from "model/themeVariant";

export const useHelperTextStyles = createStyle(
  (theme: Theme) => ({
    text: {
      ...themeTextMixin(theme, TextVariant.Caption, 1),
      ...marginMixin({ left: 12 }),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "HelperText"
);
