import { ReactElement, useI18n } from "@laba/react-common";
import {
  AutocompleteInput,
  required as requiredValidate,
  Validator
} from "react-admin";
import React from "react";
import {
  SelectInput,
  SelectInputVariant
} from "components/generic/SelectInput/SelectInput";
import { CodeSystemGroup, ModelId } from "@laba/nexup-api";
import { useCodeSystemSelectorInputStyle } from "components/generic/CodeSystemSelectorInput/codeSystemSelectorInputStyle";
import { tkCC } from "translation/i18n";
import { getAsArray, notNull, Optional } from "@laba/ts-common";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { isEmpty } from "lodash-es";
import { CodeSystemCreateOptionDialog } from "components/generic/CodeSystemSelectorInput/CodeSystemCreateOptionDialog";
import { InputChoice } from "../types";

export interface CodeSystemSelectorInputProps<T> {
  source: string;
  label: string;
  codeSystem?: CodeSystemGroup;
  choices?: InputChoice[];
  organizationId?: ModelId;
  alwaysOn?: boolean;
  fullWidth?: boolean;
  resettable?: boolean;
  withCreate?: boolean;
  validate?: Validator | Validator[];
  required?: boolean;
  autocomplete?: boolean;
  parse?: (selectValue: Optional<string>) => Optional<T>;
  format?: (formValue: Optional<T>) => Optional<string>;
  extraOptions?: InputChoice[];
}

const tk = tkCC.inputs.codeSystemSelectorInput;
export const CodeSystemSelectorInput = <T,>({
  source,
  label,
  codeSystem,
  alwaysOn = false,
  fullWidth = true,
  resettable = false,
  withCreate = false,
  validate,
  required,
  autocomplete,
  parse,
  format,
  organizationId,
  extraOptions,
  choices
}: CodeSystemSelectorInputProps<T>): ReactElement => {
  const classes = useCodeSystemSelectorInputStyle();
  const currentValue = useWatchResourceForm<string>(source);
  const { t } = useI18n();
  const validateArray = getAsArray(validate).filter(notNull);
  if (required) validateArray.push(requiredValidate());

  const inputChoices: InputChoice[] = isEmpty(choices)
    ? codeSystem?.concept?.map(value => ({
        id: value.code,
        name: isEmpty(value.display)
          ? value.code
          : `${value.code} (${value.display})`
      })) ?? []
    : choices ?? [];
  if (extraOptions) {
    const toPush: InputChoice[] = extraOptions.filter(
      option => inputChoices.find(x => x.id === option.id) == null
    );
    inputChoices.push(...toPush);
  }
  if (
    currentValue &&
    !inputChoices.find(element => element.id === currentValue)
  ) {
    inputChoices.push({
      id: currentValue,
      name: currentValue
    });
  }

  const isAutocomplete = autocomplete ?? inputChoices.length > 15;

  return isAutocomplete ? (
    <AutocompleteInput
      source={source}
      variant="outlined"
      fullWidth={fullWidth}
      choices={inputChoices}
      shouldRenderSuggestions={() => true}
      noOptionsText={t(tk.noOptionsText)}
      className={classes.root}
      label={label}
      validate={validateArray}
      alwaysOn={alwaysOn}
      create={
        withCreate && codeSystem && organizationId ? (
          <CodeSystemCreateOptionDialog
            codeSystemGroup={codeSystem}
            organizationId={organizationId}
          />
        ) : undefined
      }
      createLabel={t(tk.createLabel)}
      parse={value => {
        return (parse ? parse(value) : value) ?? null;
      }}
      format={value => {
        return format ? format(value) : value;
      }}
    />
  ) : (
    <SelectInput
      variant={SelectInputVariant.Outlined}
      fullWidth={fullWidth}
      source={source}
      label={label}
      choices={inputChoices}
      alwaysOn={alwaysOn}
      className={classes.root}
      resettable={resettable}
      create={
        withCreate && codeSystem && organizationId ? (
          <CodeSystemCreateOptionDialog
            codeSystemGroup={codeSystem}
            organizationId={organizationId}
          />
        ) : undefined
      }
      createLabel={t(tk.createLabel)}
      validate={validateArray}
      parse={parse}
      format={format}
    />
  );
};
