import { ApiDate, HttpMethod } from "@laba/ts-common";
import { KnownScheduleType } from "model/resource/schedule/schedule";
import { Code } from "model/resource/entities/codeSystem";
import { ModelId, OpenCode } from "model/primitives/model/model";
import { ApiRequestResponse } from "request/types";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { Calendar } from "model/resource/schedule/calendar/calendar";

export interface CalendarQueryParams {
  endDate?: ApiDate;
  startDate?: ApiDate;
  location?: ModelId[];
  organization?: ModelId;
  practitioner?: ModelId[];
  schedule?: ModelId[];
  speciality?: Code[];
  type?: OpenCode<KnownScheduleType>[];
  withLocation?: boolean;
  withOrganization?: boolean;
  withPractitioner?: boolean;
  withSchedule?: boolean;
  limit?: number;
}

const calendarBasePath = "/schedule/calendar";

export const getCalendar = (
  params: CalendarQueryParams
): Promise<ApiRequestResponse<Calendar>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(calendarBasePath),
    params
  });
