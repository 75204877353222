import { FC, useI18n } from "@laba/react-common";
import { ReferenceManyResourceInput } from "components/generic/ReferenceManyResourceInput/ReferenceManyResourceInput";
import {
  ModelId,
  WhatsappNotification,
  WhatsappNotificationParamsKey
} from "@laba/nexup-api";
import { TextField } from "components/generic/TextField/TextField";
import { DateField } from "components/generic/DateField/DateField";
import React, { useMemo } from "react";
import { tkCP } from "translation/i18n";
import { ExternalResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { DeepPartial } from "@laba/ts-common";
import { whatsappNotificationTransform } from "./utils";

export const tk =
  tkCP.adminPage[ExternalResourceType.WhatsappNotificationHistory];

export const WhatsappNotificationHistoryListContent: FC = () => {
  const { t } = useI18n();

  const whatsappNotificationId = useWatchResourceForm<ModelId>(
    WhatsappNotificationParamsKey.id
  );

  const defaultValue = useMemo<DeepPartial<WhatsappNotification>>(
    () => ({
      notification: whatsappNotificationId
    }),
    [whatsappNotificationId]
  );

  return (
    <ReferenceManyResourceInput<WhatsappNotification>
      resourceType={ExternalResourceType.WhatsappNotificationHistory}
      resourceSource={WhatsappNotificationParamsKey.id}
      label={t(tk.fields.history)}
      resourceTransformer={whatsappNotificationTransform}
      canCreate={false}
      defaultCreateValue={defaultValue}
      listFieldList={[
        <TextField
          key={WhatsappNotificationParamsKey.id}
          source={WhatsappNotificationParamsKey.id}
          label={t(tk.fields.id)}
        />,
        <TextField
          key={WhatsappNotificationParamsKey.version}
          source={WhatsappNotificationParamsKey.version}
          label={t(tk.fields.version)}
        />,
        <TextField
          key={WhatsappNotificationParamsKey.message}
          source={WhatsappNotificationParamsKey.message}
          label={t(tk.fields.message)}
        />,
        <TextField
          key={WhatsappNotificationParamsKey.status}
          source={WhatsappNotificationParamsKey.status}
          label={t(tk.fields.status)}
        />,
        <TextField
          key={WhatsappNotificationParamsKey.externalId}
          source={WhatsappNotificationParamsKey.externalId}
          label={t(tk.fields.externalId)}
        />,
        <DateField
          key={WhatsappNotificationParamsKey.lastUpdatedDate}
          source={WhatsappNotificationParamsKey.lastUpdatedDate}
          label={t(tk.fields.lastUpdatedDate)}
          showTime
        />
      ]}
      editInputList={[]}
    />
  );
};
