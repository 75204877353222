import {
  borderMixin,
  createStyle,
  FlexAlignItems,
  flexRowMixin,
  pxToRem,
  RadiusVariant,
  StyleVariant,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { Theme } from "model/theme";
import {
  getThemeRadius,
  getThemeStyleColor,
  themeTextMixin
} from "model/themeUtils";
import { TextVariant } from "model/themeVariant";

export const useDetailCheckboxStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        gap: 12
      })
    },
    checkbox: {
      ...borderMixin({
        radius: { all: getThemeRadius(theme, RadiusVariant.Small) },
        side: { all: 2 },
        style: "solid",
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.GrayDark
        )
      }),
      height: pxToRem(18),
      width: pxToRem(18),
      minHeight: pxToRem(18),
      minWidth: pxToRem(18)
    },
    text: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    subtitle2TextVariant: {
      ...themeTextMixin(theme, TextVariant.Subtitle2, 1)
    },
    body2TextVariant: { ...themeTextMixin(theme, TextVariant.Body2, 1) }
  }),
  "DetailCheckbox"
);
