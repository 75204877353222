import {
  AdminPanelPermissions,
  AdminPanelSpecialPermissions,
  hasPermission
} from "models/permissions/adminPanelPermissions";
import { usePermissions } from "react-admin";
import { getAsArray } from "@laba/ts-common";

export const useHasPermission = (
  permissionList?: AdminPanelPermissions | AdminPanelPermissions[]
): boolean => {
  const { permissions } = usePermissions<AdminPanelPermissions[]>();
  const permissionListWithAdmin = [
    ...getAsArray(permissionList),
    AdminPanelSpecialPermissions.Admin
  ];
  return permissionListWithAdmin.some(permission =>
    hasPermission(permission, permissions)
  );
};
