import {
  createStyle,
  FlexAlignItems,
  flexRowMixin,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

export const useDetailIndicatorBoxGroupStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexRowMixin({
        gap: 8,
        wrapContent: true,
        alignItems: FlexAlignItems.Center
      })
    },
    prefix: {
      ...themeTextMixin(theme, TextVariant.Subtitle2),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "DetailIndicatorBoxGroup"
);
