import { FC } from "@laba/react-common";
import React from "react";
import { useDynamicTagList } from "hooks";
import { ColorTagItem, ColorTagList } from "components/tags";

interface DynamicColorTagListProps {
  className?: string;
  tagClassName?: string;
  tags: ColorTagItem[];
}

export const DynamicColorTagList: FC<DynamicColorTagListProps> = ({
  tagClassName,
  tags,
  className
}) => {
  const { ref, tagsList } = useDynamicTagList(tags, true);

  return (
    <ColorTagList
      className={className}
      ref={ref}
      tags={tagsList}
      tagClassName={tagClassName}
    />
  );
};
