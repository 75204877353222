import {
  InfoWindowObject,
  InfoWindowOptions,
  MapObject,
  MapOptions,
  MarkerObject,
  MapPoint
} from "components/maps/gmap/helpers/types";

export const getMap = (mapDiv: HTMLElement, opts?: MapOptions): MapObject => {
  return new google.maps.Map(mapDiv, opts);
};

export const getMarker = (): MarkerObject => {
  return new google.maps.Marker();
};

export const getInfoWindow = (opts?: InfoWindowOptions): InfoWindowObject => {
  return new google.maps.InfoWindow(opts);
};

export const getAnchorPoint = (x: number, y: number): MapPoint => {
  return new google.maps.Point(x, y);
};
