import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { ModelId } from "model/primitives/model/model";
import {
  MedicalRequestDefinition,
  MedicalRequestDefinitionCategory
} from "model/resource/prescription/medicalRequestDefinition/medicalRequestDefinition";
import { getKeyObj, HttpMethod, KeyObj } from "@laba/ts-common";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { createHydratedMock } from "ts-auto-mock";
import { PublicationStatus } from "model/resource/activity/activityDefinition";

export interface MedicalRequestDefinitionListQueryParams
  extends ListQueryParams {
  organization?: ModelId;
  category?: MedicalRequestDefinitionCategory;
  content?: string;
  status?: PublicationStatus;
  withMedicalDevice?: boolean;
}

export const MedicalRequestDefinitionListQueryParamsKey: KeyObj<MedicalRequestDefinitionListQueryParams> =
  getKeyObj(createHydratedMock<MedicalRequestDefinitionListQueryParams>());

const medicalRequestDefinitionBasePath = "/medical-request-definition";

export const getMedicalRequestDefinitionList = (
  params: MedicalRequestDefinitionListQueryParams
): Promise<ApiPageRequestResponse<MedicalRequestDefinition>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(medicalRequestDefinitionBasePath),
    params
  });

export const getMedicalRequestDefinition = (
  id: ModelId
): Promise<ApiRequestResponse<MedicalRequestDefinition>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${medicalRequestDefinitionBasePath}/${id}`)
  });

export const createMedicalRequestDefinition = (
  data: MedicalRequestDefinition
): Promise<ApiRequestResponse<MedicalRequestDefinition>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(medicalRequestDefinitionBasePath),
    data
  });

export const updateMedicalRequestDefinition = (
  data: MedicalRequestDefinition
): Promise<ApiRequestResponse<MedicalRequestDefinition>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${medicalRequestDefinitionBasePath}/${data.id}`),
    data
  });
