import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  CodeSystem,
  CodeSystemKey,
  CodeSystemListQueryParamsKey,
  CodeSystemStatus,
  KnownCodeSystemSystem,
  OrganizationCombinedType,
  OrganizationKey,
  OrganizationListQueryParamsKey,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { TextField } from "components/generic/TextField/TextField";
import { ReferenceResourceField } from "components/generic/ReferenceResourceField/ReferenceResourceField";
import { List } from "components/generic/List/List";
import { Datagrid } from "components/generic/Datagrid/Datagrid";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { codeSystemJsonToModel } from "components/pages/NexupAdmin/resources/codeSystem/CodeSystemForm";
import { FunctionField } from "components/generic/FunctionField/FunctionField";
import { useSelector } from "react-redux";
import {
  defaultWorkspaceOrganizationIdSelector,
  workspaceOrganizationIdSelector
} from "store/workspace/selectors";
import { useListExportedFileName } from "components/hook/useExportedFileName";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

const tk = tkCP.adminPage[ResourceType.CodeSystem];

export const CodeSystemList: FC = () => {
  const { t } = useI18n();
  const defaultOrganizationId = useSelector(
    defaultWorkspaceOrganizationIdSelector
  );
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const exportedFileName = useListExportedFileName(t(tk.title));
  const CodeSystemSystemSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.CodeSystemSystem,
    workspaceOrganizationId || defaultOrganizationId
  );

  return (
    <List
      resource={ResourceType.CodeSystem}
      exportedFileName={exportedFileName}
      jsonToModel={codeSystemJsonToModel}
      extraFilters={[
        workspaceOrganizationId === undefined ? (
          <OrganizationReferenceInput
            label={t(tk.filters.organization)}
            source={CodeSystemListQueryParamsKey.organization}
            alwaysOn
            type={OrganizationCombinedType.ProviderAndGroup}
            extraFilters={{
              [OrganizationListQueryParamsKey.active]: true
            }}
          />
        ) : undefined,
        <CodeSystemSelectorInput
          source={CodeSystemListQueryParamsKey.system}
          label={t(tk.filters.system)}
          codeSystem={CodeSystemSystemSystem}
          organizationId={workspaceOrganizationId}
          alwaysOn
          resettable
          withCreate
        />,
        <EnumSelectorInput
          source={CodeSystemListQueryParamsKey.status}
          label={t(tk.filters.status)}
          enumValue={CodeSystemStatus}
          alwaysOn
          resettable
        />
      ]}
    >
      <Datagrid>
        <TextField source={CodeSystemKey.id} label={t(tk.fields.id)} />
        <TextField source={CodeSystemKey.system} label={t(tk.fields.system)} />
        <TextField source={CodeSystemKey.status} label={t(tk.fields.status)} />
        <ReferenceResourceField
          resourceType={ResourceType.Organization}
          label={t(tk.fields.organization)}
          source={CodeSystemKey.organization}
          optionTextKey={OrganizationKey.name}
        />
        <FunctionField<CodeSystem>
          label={t(tk.fields.public)}
          render={system =>
            system?.public === true ? t(tk.isPublic) : t(tk.isPrivate)
          }
        />
        <FunctionField<CodeSystem>
          label={t(tk.fields.conceptSize)}
          render={system => system?.concept?.length}
        />
      </Datagrid>
    </List>
  );
};
