import { FC, useSearchTextFilter } from "@laba/react-common";
import React, { useState } from "react";
import { Autocomplete } from "components/autocomplete/Autocomplete/Autocomplete";
import { ProblemItem } from "components/inputs/ProblemInput/ProblemItem/ProblemItem";
import { OptionsConfig } from "components/inputs/SelectInput/SelectInput";
import { ProblemConfig } from "components/inputs/ProblemInput/ProblemInput";
import { useSelectedProblemsStyles } from "components/inputs/ProblemInput/ProblemCreationPopup/SelectedProblems/useSelectedProblemsStyles";
import { SearchIcon } from "components/icons";

export interface SelectedProblemsProps {
  searchbarOptions: OptionsConfig<ProblemConfig>[];
  onSelectSearchbarOption: (option: ProblemConfig) => void;
  clearText: string;
  closeText: string;
  loadingText: string;
  noOptionsText: string;
  openText: string;
  searchbarPlaceholder?: string;
  onRemoveProblem?: (problem: ProblemConfig) => void;
  createdProblems: ProblemConfig[];
  selectedOptions: ProblemConfig[];
}

export const SelectedProblems: FC<SelectedProblemsProps> = ({
  searchbarOptions,
  createdProblems,
  onRemoveProblem,
  onSelectSearchbarOption,
  clearText,
  closeText,
  loadingText,
  noOptionsText,
  openText,
  searchbarPlaceholder,
  selectedOptions
}) => {
  const classes = useSelectedProblemsStyles();
  const [searchText, setSearchText] = useState("");

  const filterOptions = useSearchTextFilter(searchText, ["name"], "name", [
    ...createdProblems,
    ...selectedOptions
  ]);
  return (
    <div className={classes.root}>
      <Autocomplete<ProblemConfig>
        options={searchbarOptions}
        filterOptions={filterOptions}
        inputValue={searchText}
        onInputChange={setSearchText}
        onChange={value => {
          value && onSelectSearchbarOption(value);
          setSearchText("");
        }}
        clearText={clearText}
        closeText={closeText}
        loadingText={loadingText}
        noOptionsText={noOptionsText}
        openText={openText}
        fullWidth
        label={searchbarPlaceholder}
        endIconShouldNotRotate
        EndIcon={SearchIcon}
      />
      <div className={classes.createdProblems}>
        {createdProblems.map(p => {
          return (
            <ProblemItem
              key={p.name}
              text={p.name}
              status={p.status}
              onClose={() => onRemoveProblem?.(p)}
            />
          );
        })}
      </div>
    </div>
  );
};
