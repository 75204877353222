import React from "react";
import { FC, useI18n } from "@laba/react-common";
import { tkCP } from "translation/i18n";
import { TextField } from "components/generic/TextField/TextField";
import { List } from "components/generic/List/List";
import { Datagrid } from "components/generic/Datagrid/Datagrid";
import { ExternalResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { useListExportedFileName } from "components/hook/useExportedFileName";
import { DateField } from "components/generic/DateField/DateField";
import { getDefaultJsonToModel } from "components/generic/ResourceAction/ResourceAction";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { TextInput } from "components/generic/TextInput/TextInput";
import {
  NotificationParamsKey,
  WhatsappMessageType,
  WhatsappNotificationLastVersionParamsKey,
  WhatsappNotificationParamsKey,
  WhatsappNotificationStatus
} from "@laba/nexup-api";

const tk = tkCP.adminPage[ExternalResourceType.WhatsappNotification];
export const WhatsappNotificationList: FC = () => {
  const { t } = useI18n();
  const exportedFileName = useListExportedFileName(t(tk.title));
  return (
    <List
      resource={ExternalResourceType.WhatsappNotification}
      exportedFileName={exportedFileName}
      jsonToModel={getDefaultJsonToModel()}
      extraFilters={[
        <TextInput
          source={WhatsappNotificationParamsKey.id}
          label={t(tk.filters.id)}
          alwaysOn
          clearAlwaysVisible
          resettable
        />,
        <EnumSelectorInput
          label={t(tk.filters.status)}
          source={WhatsappNotificationParamsKey.status}
          enumValue={WhatsappNotificationStatus}
          alwaysOn
          resettable
        />,
        <TextInput
          source={WhatsappNotificationParamsKey.notification}
          label={t(tk.filters.notification)}
          alwaysOn
          clearAlwaysVisible
          resettable
        />,
        <TextInput
          source={WhatsappNotificationParamsKey.externalId}
          label={t(tk.filters.externalId)}
          alwaysOn
          clearAlwaysVisible
          resettable
        />,
        <EnumSelectorInput
          label={t(tk.filters.messageType)}
          source={WhatsappNotificationParamsKey.messageType}
          enumValue={WhatsappMessageType}
          alwaysOn
          resettable
        />
      ]}
    >
      <Datagrid
        sx={{
          "& .column-message": { "max-width": "600px" }
        }}
      >
        <TextField
          source={WhatsappNotificationParamsKey.id}
          label={t(tk.fields.id)}
        />
        <TextField
          key={[
            WhatsappNotificationParamsKey.notification,
            NotificationParamsKey.receiver
          ].join(".")}
          source={[
            WhatsappNotificationParamsKey.notification,
            NotificationParamsKey.receiver
          ].join(".")}
          label={t(tk.fields.receiver)}
        />
        <TextField
          key={WhatsappNotificationParamsKey.status}
          source={WhatsappNotificationParamsKey.status}
          label={t(tk.fields.status)}
        />
        <TextField
          key={WhatsappNotificationParamsKey.message}
          source={WhatsappNotificationParamsKey.message}
          label={t(tk.fields.message)}
          sx={{ "overflow-wrap": "break-word" }}
        />
        <DateField
          key={WhatsappNotificationParamsKey.lastUpdatedDate}
          source={WhatsappNotificationParamsKey.lastUpdatedDate}
          label={t(tk.fields.lastUpdatedDate)}
          showTime
        />
        <DateField
          key={WhatsappNotificationLastVersionParamsKey.creationDate}
          source={WhatsappNotificationLastVersionParamsKey.creationDate}
          label={t(tk.fields.creationDate)}
          showTime
        />
      </Datagrid>
    </List>
  );
};
