import { Position } from "@laba/ts-common";

export type MarkerOptions = google.maps.MarkerOptions;
export type MarkerObject = google.maps.Marker;
export type MapObject = google.maps.Map;
export type MapOptions = google.maps.MapOptions;
export type MarkerIcon = google.maps.Symbol;
export type InfoWindowObject = google.maps.InfoWindow;
export type InfoWindowOptions = google.maps.InfoWindowOptions;
export type MapPosition = google.maps.LatLngLiteral;
export type MapPositionObject = google.maps.LatLng;
export type MapStylesListObject = google.maps.MapTypeStyle[];
export type MapPoint = google.maps.Point;

export type PositionType = Required<Position>;

export interface ControlPermission {
  zoomControl?: boolean;
  mapTypeControl?: boolean;
  scaleControl?: boolean;
  streetViewControl?: boolean;
  rotateControl?: boolean;
  fullscreenControl?: boolean;
}

export enum POIsCategory {
  Attraction = "poi.attraction",
  Business = "poi.business",
  Government = "poi.government",
  Medical = "poi.medical",
  Park = "poi.park",
  PlaceOfWorship = "poi.place_of_worship",
  School = "poi.school",
  SportsComplex = "poi.sports_complex"
}

// reference: https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions.gestureHandling
export enum GestureHandlingValue {
  None = "none",
  Greedy = "greedy",
  Cooperative = "cooperative",
  Auto = "auto"
}

export interface StaticMapConfig {
  gestureHandling: GestureHandlingValue;
  clickableIcons: boolean;
  keyboardShortcuts?: boolean;
}
