import { useCustomCompareEffect } from "react-use";
import { Noop } from "@laba/ts-common";

const compareDeps = (
  [oldFuncDeps]: unknown[][],
  [newFuncDeps]: unknown[][]
): boolean =>
  oldFuncDeps?.every((val, idx) => val === newFuncDeps?.[idx]) ?? false;

/**
 * @param callBack effect callback
 * @param functionalDeps deps that determine if callback reruns
 * @param ignoredDeps deps that useEffect requires
 *
 * Similar to useEffect but only runs the callback when one of the
 * dependencies on functionalDeps changes, instead of looking at every
 * dependency like useEffect
 */
export const useEffectSelective = (
  callBack: Noop,
  functionalDeps: unknown[] = [],
  ignoredDeps: unknown[] = []
): void =>
  useCustomCompareEffect(callBack, [functionalDeps, ignoredDeps], compareDeps);
