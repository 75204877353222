import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  flexRowMixin,
  getClassName,
  paddingMixin,
  Theme
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { StyleVariant, TextVariant } from "model/themeVariant";
import { ThemeStyleColorVariant } from "model/theme";

interface AutocompleteOptionTextItemProps {
  withSubtext?: boolean;
  disabled?: boolean;
}

interface AutocompleteOptionTextItemClassNames {
  root: string;
  title: string;
  primarySubtext: string;
  subtext: string;
  rootContainer: string;
}

const useAutocompleteOptionTextItemStylesStatic = createStyle(
  (theme: Theme) => ({
    rootContainer: {
      ...flexRowMixin({ gap: 0, alignItems: FlexAlignItems.Center }),
      width: "100%"
    },
    root: {
      ...paddingMixin({ left: 8 }),
      ...flexColumnMixin({ gap: 4 }),
      width: "100%"
    },
    titleWithSubtext: {
      ...themeTextMixin(theme, TextVariant.Subtitle1)
    },
    titleWithoutSubtext: {
      ...themeTextMixin(theme, TextVariant.Body2),
      width: "100%"
    },
    primarySubtext: {
      ...themeTextMixin(theme, TextVariant.Subtitle2)
    },
    subtext: {
      ...themeTextMixin(theme, TextVariant.Body2)
    },
    grayDarkColor: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "AutocompleteOptionTextItem"
);

export const useAutocompleteOptionTextItemStyles = (
  props: AutocompleteOptionTextItemProps
): AutocompleteOptionTextItemClassNames => {
  const staticClasses = useAutocompleteOptionTextItemStylesStatic();

  return {
    root: staticClasses.root,
    title: getClassName(
      staticClasses.grayDarkColor,
      props.withSubtext
        ? staticClasses.titleWithSubtext
        : staticClasses.titleWithoutSubtext
    ),
    subtext: getClassName(staticClasses.grayDarkColor, staticClasses.subtext),
    primarySubtext: getClassName(
      staticClasses.grayDarkColor,
      staticClasses.primarySubtext
    ),
    rootContainer: staticClasses.rootContainer
  };
};
