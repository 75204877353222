import {
  createStyle,
  getClassName,
  marginMixin,
  paddingMixin,
  pxToRem
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import {
  getThemeStyleColor,
  themeElevationMixin,
  themeTextMixin
} from "model/themeUtils";
import {
  ElevationVariant,
  StyleVariant,
  TextVariant
} from "model/themeVariant";

export interface PopoverMenuDropdownStylesProps {
  columnWidth: number;
}

interface PopoverMenuDropdownClassNames {
  menu: string;
  noCategoriesText: string;
}

const usePopoverMenuDropdownStaticStyles = createStyle(
  (theme: Theme) => ({
    menu: {
      ...marginMixin({ top: 4 }),
      ...themeElevationMixin(theme, ElevationVariant.Elevation24)
    },
    noCategoriesTextStatic: {
      ...paddingMixin({ leftRight: 16, top: 16, bottom: 24 }),
      ...themeTextMixin(theme, TextVariant.Body2, 2),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "PopoverMenuDropdownStatic"
);

const usePopoverMenuDropdownDynamicStyles = createStyle(
  (_theme: Theme) => ({
    noCategoriesDynamicText: (props: PopoverMenuDropdownStylesProps) => ({
      width: pxToRem(props.columnWidth)
    })
  }),
  "PopoverMenuDropdownDynamic"
);

export const usePopoverMenuDropdownStyles = (
  props: PopoverMenuDropdownStylesProps
): PopoverMenuDropdownClassNames => {
  const staticClasses = usePopoverMenuDropdownStaticStyles();
  const dynamicClasses = usePopoverMenuDropdownDynamicStyles(props);

  return {
    menu: staticClasses.menu,
    noCategoriesText: getClassName(
      staticClasses.noCategoriesTextStatic,
      dynamicClasses.noCategoriesDynamicText
    )
  };
};
