import { getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { ResourceModel, ResourceType } from "./resourceModel";

export interface Address extends ResourceModel<ResourceType.Address> {
  street?: string;
  streetNumber?: string;
  directions?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
}

export const AddressKey = getKeyObj(createHydratedMock<Address>());
