import { ElementOrArray, getKeyObj, HttpMethod } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { ApiRequestResponse } from "request/types";
import { getIntegrationApiUrl, request } from "request/nexupRequest";

export enum PatientSheetColumns {
  FirstName = "Nombre*",
  LastName = "Apellido*",
  MotherLastName = "Apellido materno",
  Gender = "Género*",
  BirthDate = "Fecha de nacimiento*",
  IdentifierType = "Tipo de doc.*",
  IdentifierValue = "Número de doc.*",
  IdentifierFile = "Foto de documento",
  ProfilePicture = "Foto de perfil",
  Citizenship = "Nacionalidad", // not implemented
  MaritalStatus = "Estado civil", // not implemented
  Email = "Mail",
  Phone = "Teléfono",
  AddressStreet = "Domicilio | Calle",
  AddressStreetNumber = "Domicilio |  Altura",
  AddressDirections = "Domicilio |  Piso / dpto",
  AddressPostalCode = "Domicilio |  CP",
  AddressCountry = "Domicilio |  País",
  AddressState = "Domicilio |  Región",
  PersonInChargeFirstName = "Acompañante | Nombre", // not implemented
  PersonInChargeLastName = "Acompañante | Apellido", // not implemented
  PersonInChargeEmail = "Acompañante | Mail", // not implemented
  PersonInChargePhone = "Acompañante | Teléfono", // not implemented
  PersonInChargeRelationship = "Acompañante | Observaciones", // not implemented
  HealthcarePayerProviderName = "Financiador | Nombre",
  HealthcarePayerPlan = "Financiador | Plan",
  HealthcarePayerCredentialNumber = "Financiador | N°Afiliado",
  HealthcarePayerStatus = "Financiador  | Estado de cobertura", // not implemented
  HealthcareTaxType = "Financiador  | Condición impositiva"
}

export interface ImportRequestBody {
  organization?: string;
  file?: string | File; // type string used only for createHydratedMock correct behaviour with DOM objects
  importField?: ElementOrArray<PatientSheetColumns>;
}

export const ImportRequestBodyParamsKey = getKeyObj(
  createHydratedMock<ImportRequestBody>()
);

export const importPatientRequest = async (
  params: ImportRequestBody
): Promise<ApiRequestResponse<unknown>> => {
  const data = new FormData();
  if (params.file) data.append(ImportRequestBodyParamsKey.file, params.file);
  if (params.organization)
    data.set(ImportRequestBodyParamsKey.organization, params.organization);
  if (params.importField) {
    for (let i = 0; i < params.importField.length; i += 1) {
      data.append(
        ImportRequestBodyParamsKey.importField,
        params.importField[i] ?? ""
      );
    }
  }
  return request({
    method: HttpMethod.POST,
    url: getIntegrationApiUrl("person/patient"),
    data
  });
};

export const importPractitionerRequest = async (
  params: ImportRequestBody
): Promise<ApiRequestResponse<unknown>> => {
  const data = new FormData();
  if (params.file) data.append(ImportRequestBodyParamsKey.file, params.file);
  if (params.organization)
    data.set(ImportRequestBodyParamsKey.organization, params.organization);
  return request({
    method: HttpMethod.POST,
    url: getIntegrationApiUrl("person/practitioner"),
    data
  });
};

export enum VademecumImportMode {
  CreateOnly = "CreateOnly",
  UpdateOnly = "UpdateOnly",
  CreateAndUpdateActiveOnly = "CreateAndUpdateActiveOnly",
  All = "All"
}

export enum VademecumFileType {
  Alfabeta = "Alfabeta",
  Sheet = "Sheet",
  Uruguay = "Uruguay"
}
export interface ImportVademecumRequestBody extends ImportRequestBody {
  importMode?: VademecumImportMode;
  fileType?: VademecumFileType;
}

export const ImportVademecumRequestBodyParamsKey = getKeyObj(
  createHydratedMock<ImportVademecumRequestBody>()
);

export const importVademecumRequest = async (
  params: ImportVademecumRequestBody
): Promise<ApiRequestResponse<unknown>> => {
  const data = new FormData();
  if (params.file)
    data.append(ImportVademecumRequestBodyParamsKey.file, params.file);
  if (params.organization)
    data.set(
      ImportVademecumRequestBodyParamsKey.organization,
      params.organization
    );
  if (params.importMode)
    data.set(ImportVademecumRequestBodyParamsKey.importMode, params.importMode);
  if (params.fileType)
    data.set(ImportVademecumRequestBodyParamsKey.fileType, params.fileType);
  return request({
    method: HttpMethod.POST,
    url: getIntegrationApiUrl("vademecum"),
    data
  });
};

export interface ImportQuestionnaireResponseRequestBody
  extends ImportRequestBody {
  questionnaire?: string;
}

export const ImportQuestionnaireResponseRequestBodyParamsKey = getKeyObj(
  createHydratedMock<ImportQuestionnaireResponseRequestBody>()
);

export const importQuestionnaireResponseRequest = async (
  params: ImportQuestionnaireResponseRequestBody
): Promise<ApiRequestResponse<unknown>> => {
  const data = new FormData();
  if (params.file)
    data.append(
      ImportQuestionnaireResponseRequestBodyParamsKey.file,
      params.file
    );
  if (params.organization)
    data.set(
      ImportQuestionnaireResponseRequestBodyParamsKey.organization,
      params.organization
    );
  if (params.questionnaire)
    data.set(
      ImportQuestionnaireResponseRequestBodyParamsKey.questionnaire,
      params.questionnaire
    );
  return request({
    method: HttpMethod.POST,
    url: getIntegrationApiUrl("clinic-history/questionnaire-response"),
    data
  });
};
