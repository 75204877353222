import { FC, useI18n } from "@laba/react-common";
import { useAppDispatch } from "store/store";
import React, { useCallback, useEffect, useState } from "react";
import { DefaultButton, Popup } from "@laba/nexup-components";
import { Grid, Typography } from "@mui/material";
import { tkCP } from "translation/i18n";
import { WhatsappClientSessionData } from "@laba/nexup-api";
import {
  onDownloadWhatsappSessionList,
  onRunNotificationProcessQueueClicked,
  onRunWhatsappNotificationProcessQueueClicked
} from "store/dashboard/event";
import { WhatsappSessionItem } from "./WhatsappSessionItem";

const tk = tkCP.adminPage.dashboard.whatsappSession;

export const WhatsappSessionList: FC = () => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const [sessionList, setSessionList] = useState<WhatsappClientSessionData[]>(
    []
  );

  const onClick = useCallback(() => setShowPopup(true), [setShowPopup]);
  const onClose = useCallback(() => setShowPopup(false), [setShowPopup]);

  const download = useCallback(async () => {
    setLoading(true);
    const list = await dispatch(onDownloadWhatsappSessionList());
    setSessionList(list);
    setLoading(false);
  }, [dispatch, setSessionList]);

  useEffect(() => {
    download();
  }, [download]);

  useEffect(() => {
    const listenerId = setTimeout(async () => {
      await download();
    }, 15000);
    return () => clearTimeout(listenerId);
  }, [download]);

  const notificationProcessQueueClicked = useCallback(async () => {
    await dispatch(onRunNotificationProcessQueueClicked());
  }, [dispatch]);

  const whatsappNotificationProcessQueueClicked = useCallback(async () => {
    await dispatch(onRunWhatsappNotificationProcessQueueClicked());
  }, [dispatch]);

  return (
    <>
      <DefaultButton
        title={t(tk.openButton)}
        text={t(tk.openButton)}
        onClick={onClick}
      />
      {showPopup && (
        <Popup
          open={showPopup}
          closableHeader
          headerTitle={t(tk.title)}
          loading={loading}
          onClose={onClose}
        >
          <Grid container columnSpacing={2} rowSpacing={4}>
            <Grid item container sm={12} columnSpacing={8}>
              <Grid item>
                <DefaultButton
                  title={t(tk.notificationProcessQueue)}
                  text={t(tk.notificationProcessQueue)}
                  onClick={notificationProcessQueueClicked}
                />
              </Grid>
              <Grid item>
                <DefaultButton
                  title={t(tk.whatsappNotificationProcessQueue)}
                  text={t(tk.whatsappNotificationProcessQueue)}
                  onClick={whatsappNotificationProcessQueueClicked}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h5">{t(tk.sessionListTitle)}</Typography>
            </Grid>
            <Grid item container sm={12} rowSpacing={4}>
              {sessionList.map(sessionItem => {
                return (
                  <Grid item sm={12}>
                    <WhatsappSessionItem session={sessionItem} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Popup>
      )}
    </>
  );
};
