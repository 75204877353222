import { createStyle, flexRowMixin, TextVariant } from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { StyleVariant } from "model/themeVariant";

export const useLocationDetailsCardTextContentStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexRowMixin({})
    },
    textContentTitle: {
      ...themeTextMixin(theme, TextVariant.Subtitle2),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    textContentDescription: {
      ...themeTextMixin(theme, TextVariant.Body2),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "LocationDetailsCardTextContent"
);
