import {
  FC,
  Form,
  FormErrorData,
  FormValidator,
  useI18n
} from "@laba/react-common";
import React, { useCallback, useState } from "react";
import { DefaultButton, Popup } from "@laba/nexup-components";
import { tkCP } from "translation/i18n";
import { useAppDispatch } from "store/store";
import { isEmpty } from "lodash-es";
import { onPatientImport } from "store/dashboard/event";
import { ImportRequestBody } from "@laba/nexup-api";
import { PatientImportInputFormContent } from "components/pages/Dashboard/PatientImport/PatientImportFormContent";

export interface PatientImportInputProps {
  onImportFinished?: (success: boolean) => void;
}

const tk = tkCP.adminPage.dashboard.importPatient;
const formId = "importPatientForm";
const initialValues: ImportRequestBody = {};
const formValidation: FormValidator<ImportRequestBody> = (t, formData) => {
  const errors: FormErrorData<ImportRequestBody> = {};

  if (formData.organization == null) {
    errors.organization = t(tk.validation.requiredField);
  }
  if (formData.file == null) {
    errors.file = t(tk.validation.requiredField);
  }

  return isEmpty(errors) ? undefined : errors;
};
export const PatientImportInput: FC<PatientImportInputProps> = ({
  onImportFinished
}) => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const [showPatientImport, setShowPatientImport] = useState(false);
  const [loading, setLoading] = useState(false);
  const onSubmit = useCallback(
    async (requestData: ImportRequestBody) => {
      setLoading(true);
      const result = await dispatch(onPatientImport(requestData));
      setLoading(false);
      setShowPatientImport(false);
      onImportFinished?.(result);
    },
    [setLoading, setShowPatientImport, onImportFinished, dispatch]
  );
  const validate = useCallback(
    (data: ImportRequestBody) => formValidation(t, data),
    [t]
  );
  const onClick = useCallback(
    () => setShowPatientImport(true),
    [setShowPatientImport]
  );
  const onClose = useCallback(
    () => setShowPatientImport(false),
    [setShowPatientImport]
  );
  return (
    <>
      <DefaultButton
        title={t(tk.importPatientButton)}
        text={t(tk.importPatientButton)}
        onClick={onClick}
      />
      {showPatientImport && (
        <Popup
          open={showPatientImport}
          closableHeader
          headerTitle={t(tk.title)}
          primaryButtonText={t(tk.sendImport)}
          disablePrimaryButton={false}
          formId={formId}
          loading={loading}
          onClose={onClose}
          disableBackdropClick
        >
          <Form<ImportRequestBody>
            id={formId}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
          >
            <PatientImportInputFormContent />
          </Form>
        </Popup>
      )}
    </>
  );
};
