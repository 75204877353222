import { ConsumptionGroupWithExtraData } from "model/resource/finance/consumption/consumption";
import { ResourceType } from "model/primitives/resourceModel";
import { ApiDate } from "@laba/ts-common";
import { getPractitionerMock } from "model/resource/person/__mocks__/practitioner";
import { patientContainerWithExtraDataMock } from "model/resource/person/__mocks__/patientContainerWithExtraData";
import { head } from "lodash-es";
import { productListMock } from "../../product/__mocks__/product";

const getConsumptionGroupWithExtraData = (
  occurrence?: ApiDate
): ConsumptionGroupWithExtraData => {
  const consumptionGroupWithExtraData: ConsumptionGroupWithExtraData = {
    consumptionGroup: {
      resourceType: ResourceType.ConsumptionGroup,
      receipt: [],
      occurrence,
      performer: [{ id: "123", practitioner: getPractitionerMock() }],
      patient: patientContainerWithExtraDataMock.patient,
      coverageDebt: { currency: "ARS", value: 123 },
      coveragePaymentStatus: "gasdasda",
      patientDebt: { currency: "ARS", value: 123 },
      patientPaymentMethod: "324",
      patientPaymentStatus: "fewass"
    },
    consumptionItemList: [
      {
        resourceType: ResourceType.ConsumptionItem,
        product: head(productListMock),
        id: "wegs",
        quantity: {
          quantity: 10
        }
      },
      {
        resourceType: ResourceType.ConsumptionItem,
        product: head(productListMock),
        id: "qrfw"
      }
    ]
  };

  return consumptionGroupWithExtraData;
};

export const consumptionGroupWithExtraData1 = getConsumptionGroupWithExtraData(
  "2023-08-13T23:59:59.999-03"
);
export const consumptionGroupWithExtraData2 = getConsumptionGroupWithExtraData(
  "2023-08-13T10:05:06.000-03"
);
export const consumptionGroupWithExtraData3 = getConsumptionGroupWithExtraData(
  "2023-09-13T10:05:06.000-03"
);
export const consumptionGroupWithExtraData4 = getConsumptionGroupWithExtraData(
  "2023-09-13T10:05:06.000-03"
);
export const consumptionGroupWithExtraData5 = getConsumptionGroupWithExtraData(
  "2023-10-13T10:05:06.000-03"
);
export const consumptionGroupWithExtraData6 = getConsumptionGroupWithExtraData(
  "2024-08-13T10:05:06.000-03"
);

export const consumptionGroupWithExtraData7 = getConsumptionGroupWithExtraData(
  "2024-10-13T12:05:06.000-03"
);

export const consumptionGroupWithExtraDataList = [
  consumptionGroupWithExtraData1,
  consumptionGroupWithExtraData2,
  consumptionGroupWithExtraData3,
  consumptionGroupWithExtraData4,
  consumptionGroupWithExtraData5,
  consumptionGroupWithExtraData6,
  consumptionGroupWithExtraData7
];
