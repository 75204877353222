import { ReactElement } from "@laba/react-common";
import React from "react";
import {
  OptionsConfig,
  SelectInput
} from "components/inputs/SelectInput/SelectInput";
import { Noop } from "@laba/ts-common";
import {
  AutocompleteOption,
  AutocompleteOptionProps
} from "../AutocompleteOption/AutocompleteOption";

export interface AutocompleteOptionWithSelectProps<T, V>
  extends AutocompleteOptionProps<T> {
  placeholder?: string;
  selectOptions?: OptionsConfig<V>[];
  selectValue?: V;
  selectOnChange?: (option: T, innerOption?: V) => void;
  error?: string;
  onBlur?: Noop;
  touched?: boolean;
  getOptionFromValue?: (innerOption: V) => OptionsConfig<V>;
}

export const AutocompleteOptionWithSelect = <T, V>({
  placeholder,
  error,
  selectValue,
  selectOnChange,
  onBlur,
  touched,
  option,
  selectOptions = [],
  getOptionFromValue,
  disabled,
  onRemoveSelectedOption,
  fullWidth,
  hasBackgroundColor,
  isSelectedOption,
  removeIconStyle,
  isMobile,
  actionControlComponent
}: AutocompleteOptionWithSelectProps<T, V>): ReactElement => {
  return (
    <AutocompleteOption
      option={option}
      disabled={disabled}
      fullWidth={fullWidth}
      hasBackgroundColor={hasBackgroundColor}
      onRemoveSelectedOption={onRemoveSelectedOption}
      isSelectedOption={isSelectedOption}
      removeIconStyle={removeIconStyle}
      isMobile={isMobile}
      actionControlComponent={actionControlComponent}
    >
      <SelectInput
        value={selectValue}
        onChange={v => selectOnChange?.(option.value, v)}
        onBlur={onBlur}
        errorText={error}
        showError={touched ?? true}
        placeholder={placeholder}
        options={selectOptions}
        getOptionFromValue={getOptionFromValue}
        showHelperOrErrorText={false}
        fullWidth
      />
    </AutocompleteOption>
  );
};
