import { Model, ModelId } from "model/primitives/model/model";
import { Attachment } from "model/primitives/attachment/attachment";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { ResourceModel, ResourceType } from "model/primitives/resourceModel";
import { Practitioner } from "model/resource/person/practitioner/practitioner";
import { Patient } from "../../person/patient/patient";

export enum PatientMediaStatus {
  Completed = "completed"
}

export interface PatientMedia extends ResourceModel<ResourceType.PatientMedia> {
  file: Attachment;
  status: PatientMediaStatus;
  patient: ModelReference<Patient>;
  hospitalization?: ModelId;
  practitioner: ModelReference<Practitioner>;
}

export interface PatientMediaContainer extends Model {
  file: ModelReference<PatientMedia>;
}
