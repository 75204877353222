import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { ModelId } from "model/primitives/model/model";
import { MedicalRequestType } from "model/resource/prescription/medicalRequest/medicalRequestType";
import { MedicalRequest } from "model/resource/prescription/medicalRequest/medicalRequest";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { HttpMethod } from "@laba/ts-common";

export enum MedicalRequestListOrder {
  CreationDate = "creationDate",
  UpdateDate = "updateDate",
  Type = "type"
}

export interface MedicalRequestQueryParams {
  withHospitalization?: boolean;
  withMedicalDevice?: boolean;
  withMedication?: boolean;
  withPatient?: boolean;
  withPractitioner?: boolean;
  withSubstance?: boolean;
}

export interface MedicalRequestListQueryParams
  extends ListQueryParams,
    MedicalRequestQueryParams {
  organization?: ModelId;
  content?: string;
  order?: MedicalRequestListOrder;
  patient?: ModelId;
  hospitalization?: ModelId;
  type?: MedicalRequestType;
}

export const medicalRequestBasePath = "/medical-request";

export const getMedicalRequestList = (
  params: MedicalRequestListQueryParams
): Promise<ApiPageRequestResponse<MedicalRequest>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(medicalRequestBasePath),
    params
  });

export const getMedicalRequest = (
  id: ModelId,
  params?: MedicalRequestQueryParams
): Promise<ApiRequestResponse<MedicalRequest>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${medicalRequestBasePath}/${id}`),
    params
  });

export const createMedicalRequest = (
  data: MedicalRequest
): Promise<ApiRequestResponse<MedicalRequest>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(medicalRequestBasePath),
    data
  });

export const updateMedicalRequest = (
  data: MedicalRequest
): Promise<ApiRequestResponse<MedicalRequest>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${medicalRequestBasePath}/${data.id}`),
    data
  });
