import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  MedicationKey,
  ResourceType,
  KnownCodeSystemSystem,
  MedicationIngredientKey,
  MedicationSubstanceListQueryParamsKey,
  ModelReference,
  Organization,
  getModelReferenceId,
  MedicationSubstance
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { NumberInput } from "components/generic/NumberInput/TextInput";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { medicationSubstanceOptionText } from "components/generic/ReferenceResourceInput/optionText";

export const tk = tkCP.adminPage[ResourceType.Medication];
export const MedicationIngredientContent: FC = () => {
  const { t } = useI18n();
  const organization = useWatchResourceForm<ModelReference<Organization>>(
    MedicationKey.organization
  );
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organizationId =
    getModelReferenceId(organization) || workspaceOrganizationId;
  const unitSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicationUnit,
    organizationId
  );
  return (
    <ArrayInput
      source={MedicationKey.ingredient}
      label={t(tk.fields.ingredient)}
    >
      <ReferenceResourceInput<MedicationSubstance>
        resourceType={ResourceType.MedicationSubstance}
        label={t(tk.fields.ingredientSubstance)}
        source={MedicationIngredientKey.substance}
        optionTextKey={medicationSubstanceOptionText}
        alwaysOn
        extraFilters={{
          [MedicationSubstanceListQueryParamsKey.organization]: organizationId
        }}
      />
      <NumberInput
        source={MedicationIngredientKey.amount.value}
        label={t(tk.fields.ingredientAmountValue)}
        min={0}
      />
      <CodeSystemSelectorInput
        source={MedicationIngredientKey.amount.unit}
        label={t(tk.fields.ingredientAmountUnit)}
        codeSystem={unitSystem}
        organizationId={organizationId}
        required
        withCreate
      />
    </ArrayInput>
  );
};
