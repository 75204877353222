import React, { useState } from "react";
import {
  OnBlurEvent,
  ReactElement,
  useI18n,
  withMemo
} from "@laba/react-common";
import { onUploadFileRequest } from "store/organization/event";
import { useAppDispatch } from "store/store";
import { UploadFileButton } from "components/generic/UploadFileButton/UploadFileButton";
import {
  SpinnerDialog,
  TextInput,
  UploadArrow,
  Video
} from "@laba/nexup-components";
import { Grid } from "@mui/material";
import { tkCC } from "translation/i18n";
import { useUploadFileImageFormInputStyles } from "components/generic/UploadFileImageFormInput/useUploadFileImageFormInputStyles";
import { ModelId } from "@laba/ts-common";

type FocusHandler =
  | OnBlurEvent<HTMLInputElement | HTMLTextAreaElement>
  | undefined;
export interface UploadFileImageFormInputProps {
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: FocusHandler;
  errorText?: string;
  showError?: boolean;
  label?: string;
  uploadLabel?: string;
  isVideo?: boolean;
  organization?: ModelId;
}

const tk = tkCC.inputs.uploadFileImageInput;

const UploadFileImageFormInputInt = ({
  value,
  onChange,
  onBlur,
  errorText,
  showError,
  label,
  uploadLabel,
  isVideo,
  organization
}: UploadFileImageFormInputProps): ReactElement => {
  const { t } = useI18n();
  const classes = useUploadFileImageFormInputStyles();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  return (
    <Grid container columnSpacing={2}>
      <Grid item sm={value ? 8 : 12} alignSelf="center">
        <div className={classes.inputContainer}>
          <TextInput
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            errorText={errorText}
            showError={showError ?? true}
            placeholder={label}
            fullWidth
          />
          <UploadFileButton
            fileType=""
            label={uploadLabel ?? t(tk.uploadLabel)}
            onFileAdded={async e => {
              setLoading(true);
              const { files } = e.target;
              const result = await dispatch(
                onUploadFileRequest(files ?? undefined, {
                  organization
                })
              );
              result && onChange?.(result.url);
              setLoading(false);
            }}
            Icon={UploadArrow}
          />
        </div>
      </Grid>
      {value && (
        <Grid item sm={4}>
          {isVideo ? (
            <Video src={value} className={classes.video} controls={false} />
          ) : (
            <img src={value} className={classes.image} alt={value} />
          )}
        </Grid>
      )}
      <SpinnerDialog open={loading} />
    </Grid>
  );
};

export const UploadFileImageFormInput = withMemo(UploadFileImageFormInputInt);
