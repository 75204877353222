/**
 * Returns substring after last index of given searchString.
 * If the string to search for is not found, the same string is returned
 *
 * @param str The string to search on.
 * @param searchString The string to search for.
 * @return Returns the substring after last index if found, or the same string if not found.
 */
export const afterLastIndex = (str: string, searchString: string): string => {
  const lastIndex = str.lastIndexOf(searchString);
  return lastIndex === -1 ? str : str.substring(lastIndex + 1);
};

/**
 * Returns substring before last index of given searchString.
 * If the string to search for is not found, the same string is returned
 *
 * @param str The string to search on.
 * @param searchString The string to search for.
 * @return Returns the substring before last index if found, or the same string if not found.
 */
export const beforeLastIndex = (str: string, searchString: string): string => {
  const lastIndex = str.lastIndexOf(searchString);
  return lastIndex === -1 ? str : str.substring(0, lastIndex);
};
