import {
  borderMixinRadiusAll,
  createStyle,
  FlexAlignItems,
  FlexAlignSelf,
  flexItemMixin,
  FlexJustifyContent,
  flexRowMixin,
  getClassName,
  GridAlignItems,
  gridContainerMixin,
  gridItemMixin,
  GridJustifyItems,
  marginMixin,
  paddingMixin,
  pxToRem,
  StyleVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeHeaderRadius, getThemeStyleColor } from "model/themeUtils";

export interface PatientHeaderStyleProps {
  showTags: boolean;
  isExpanded: boolean;
  isExpansible?: boolean;
  isMobile?: boolean;
}

export interface PatientHeaderStyleClassNames {
  root?: string;
  header?: string;
  verticalDivider?: string;
  horizontalDivider?: string;
  identificationDataContainer?: string;
  mobileIdentificationDataContainer?: string;
  identificationData?: string;
  primaryDataItemSmall?: string;
  primaryDataItemBig?: string;
  allergyItem?: string;
  secondaryDataItemSmall?: string;
  secondaryDataItemBig?: string;
  tagsItemContainer?: string;
  floatingButtons?: string;
  tagsHeaderContainer?: string;
  mobileActionsButtons: string;
}

const usePatientHeaderStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...borderMixinRadiusAll(getThemeHeaderRadius(theme)),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      ),
      position: "relative"
    },
    defaultCursor: { cursor: "default" },
    headerGrid: {
      ...paddingMixin({ all: 16 }),
      width: "100%"
    },
    mobileHeader: {
      ...flexRowMixin({
        gap: 16,
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.SpaceBetween
      }),
      ...paddingMixin({ leftRight: 16, topBottom: 8 }),
      width: "100%"
    },
    headerGridExpanded: {
      ...gridContainerMixin({
        gap: 8,
        templateColumn: ["repeat(18, 1fr)"],
        templateRow: [pxToRem(56), pxToRem(1), pxToRem(56)],
        alignItems: GridAlignItems.Center
      })
    },
    headerGridContracted: {
      ...gridContainerMixin({
        gap: 8,
        templateColumn: ["repeat(18, 1fr)"],
        templateRow: [pxToRem(56)],
        alignItems: GridAlignItems.Center
      })
    },
    verticalDivider: {
      ...marginMixin({ right: 8 }),
      height: pxToRem(56)
    },
    horizontalDividerWithTags: {
      ...gridItemMixin({
        columnSpan: 15,
        rowStart: 2
      })
    },
    horizontalDividerWithoutTags: {
      ...gridItemMixin({
        columnSpan: 18,
        rowStart: 2
      })
    },
    identificationDataContainer: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.SpaceBetween
      }),
      ...gridItemMixin({
        columnSpan: 4,
        rowStart: 1,
        align: GridAlignItems.Center
      })
    },
    mobileIdentificationDataContainer: {
      ...flexItemMixin({ grow: 1, shrink: 1, basis: "0%" })
    },
    mobileActionsButtons: { ...flexRowMixin({ gap: 8 }) },
    textItem: {
      ...paddingMixin({
        topBottom: 8
      }),
      height: "100%"
    },
    primaryDataGridItemSmall: {
      ...gridItemMixin({
        columnSpan: 2,
        rowStart: 1,
        align: GridAlignItems.Center
      })
    },
    primaryDataGridItemBig: {
      ...gridItemMixin({
        columnSpan: 3,
        rowStart: 1,
        align: GridAlignItems.Center
      })
    },
    allergyItemContainer: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.SpaceBetween,
        gap: 8
      })
    },
    allergyItem: {
      ...gridItemMixin({
        columnSpan: 4,
        rowStart: 1,
        columnStart: 12,
        align: GridAlignItems.Center
      }),
      ...paddingMixin({ leftRight: 8 })
    },
    allergyItemEnd: {
      ...gridItemMixin({
        columnSpan: 6,
        rowStart: 1,
        align: GridAlignItems.Center,
        columnStart: 13
      }),
      ...paddingMixin({ leftRight: 8 })
    },
    secondaryDataGridItemSmall: {
      ...gridItemMixin({
        columnSpan: 2,
        rowStart: 3,
        align: GridAlignItems.Center
      })
    },
    secondaryDataGridItemBig: {
      ...gridItemMixin({
        columnSpan: 3,
        rowStart: 3,
        align: GridAlignItems.Center
      })
    },
    tagsItemContainer: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.FlexStart,
        gap: 8
      }),
      width: "100%"
    },
    contractedTagsItem: {
      ...gridItemMixin({
        columnSpan: 3,
        columnStart: 16,
        rowSpan: 1,
        rowStart: 1,
        align: GridAlignItems.Start,
        justify: GridJustifyItems.Start
      }),
      width: "100%"
    },
    expandedTagsItem: {
      ...gridItemMixin({
        columnSpan: 3,
        columnStart: 16,
        rowSpan: 2,
        rowStart: 1,
        align: GridAlignItems.Start,
        justify: GridJustifyItems.Start
      })
    },
    floatingButtons: {
      position: "absolute",
      right: pxToRem(-12),
      top: pxToRem(0)
    },
    tagsHeaderContainer: {
      ...flexItemMixin({ grow: 1, align: FlexAlignSelf.FlexStart }),
      ...flexRowMixin({
        gap: 24,
        justifyContent: FlexJustifyContent.Center
      })
    }
  }),
  "PatientHeader"
);

export const usePatientHeaderStylesClassNames = (
  props: PatientHeaderStyleProps
): PatientHeaderStyleClassNames => {
  const classes = usePatientHeaderStyles();
  return {
    root: classes.root,
    header: props.isMobile
      ? classes.mobileHeader
      : getClassName(
          classes.headerGrid,
          props.isExpanded
            ? classes.headerGridExpanded
            : classes.headerGridContracted,
          !props.isExpansible ? classes.defaultCursor : undefined
        ),
    verticalDivider: classes.verticalDivider,
    identificationDataContainer: classes.identificationDataContainer,
    mobileIdentificationDataContainer:
      classes.mobileIdentificationDataContainer,
    mobileActionsButtons: classes.mobileActionsButtons,
    identificationData: classes.textItem,
    primaryDataItemSmall: getClassName(
      classes.textItem,
      classes.primaryDataGridItemSmall
    ),
    primaryDataItemBig: getClassName(
      classes.textItem,
      classes.primaryDataGridItemBig
    ),
    allergyItem: getClassName(
      classes.allergyItemContainer,
      classes.textItem,
      props.showTags ? classes.allergyItem : classes.allergyItemEnd
    ),
    secondaryDataItemSmall: getClassName(
      classes.textItem,
      classes.secondaryDataGridItemSmall
    ),
    secondaryDataItemBig: getClassName(
      classes.textItem,
      classes.secondaryDataGridItemBig
    ),
    tagsItemContainer: getClassName(
      classes.tagsItemContainer,
      props.isExpanded ? classes.expandedTagsItem : classes.contractedTagsItem
    ),
    floatingButtons: classes.floatingButtons,
    horizontalDivider: getClassName(
      props.showTags
        ? classes.horizontalDividerWithTags
        : classes.horizontalDividerWithoutTags
    ),
    tagsHeaderContainer: classes.tagsHeaderContainer
  };
};
