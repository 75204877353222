import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  MedicationSubstanceKey,
  OrganizationCombinedType,
  OrganizationListQueryParamsKey,
  ResourceType
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

export const tk = tkCP.adminPage[ResourceType.MedicationSubstance];
export const MedicationSubstanceMainContent: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);

  return (
    <>
      <TextInput
        disabled
        source={MedicationSubstanceKey.id}
        label={t(tk.fields.id)}
      />
      <OrganizationReferenceInput
        source={MedicationSubstanceKey.organization}
        label={t(tk.fields.organization)}
        type={OrganizationCombinedType.ProviderAndGroup}
        extraFilters={{
          [OrganizationListQueryParamsKey.active]: true
        }}
        required
        defaultValue={workspaceOrganizationId}
        disabled={workspaceOrganizationId !== undefined}
      />
      <TextInput
        source={MedicationSubstanceKey.name}
        label={t(tk.fields.name)}
        required
      />
      <TextInput
        source={MedicationSubstanceKey.code}
        label={t(tk.fields.code)}
      />
    </>
  );
};
