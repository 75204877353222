import React from "react";
import { FC } from "@laba/react-common";
import {
  MedicationForm,
  medicationJsonToModel,
  medicationTransform
} from "components/pages/NexupAdmin/resources/medication/MedicationForm";
import { Edit } from "components/generic/Edit/Edit";
import { Medication, ResourceType } from "@laba/nexup-api";
import { useElementExportedFileName } from "components/hook/useExportedFileName";

export const MedicationEdit: FC = () => {
  const exportedFileName = useElementExportedFileName<Medication>(
    resource => resource.name
  );
  return (
    <Edit
      resourceTransform={medicationTransform}
      resource={ResourceType.Medication}
      jsonToModel={medicationJsonToModel}
      exportedFileName={exportedFileName}
    >
      <MedicationForm />
    </Edit>
  );
};
