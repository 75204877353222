import { ParsedQs } from "qs";
import {
  getRoutePath,
  GetRoutePathParams,
  getRouterDefinitionPath
} from "../utils/routeDefinition";
import { DefaultRouteModule, RoutePath } from "./routeModule";

export interface ModulePathData {
  basePath: RoutePath;
  subPath: Record<string, string>;
}
export type ModuleRouterMap<M extends DefaultRouteModule = DefaultRouteModule> =
  Record<M, ModulePathData>;

export const getModuleRoutePath = <
  M extends DefaultRouteModule = DefaultRouteModule
>(
  module: M,
  route: string,
  moduleRouterMap: ModuleRouterMap<M>,
  params?: GetRoutePathParams,
  hash?: string,
  queryParams?: ParsedQs
): string | undefined => {
  const { basePath } = moduleRouterMap[module];
  const routePath = moduleRouterMap[module].subPath[route];
  if (routePath === undefined) return undefined;
  return getRoutePath(basePath, routePath, params, hash, queryParams);
};

const DEFAULT_ROOT_ROUTE = "/";

export const generateModuleGetRoutePath =
  <M extends DefaultRouteModule = DefaultRouteModule>(
    moduleRouterMap: ModuleRouterMap<M>,
    defaultRoute?: string
  ) =>
  <R extends string>(module: M) =>
  (
    route: R,
    params?: GetRoutePathParams,
    hash?: string,
    queryParams?: ParsedQs
  ): string => {
    return (
      getModuleRoutePath<M>(
        module,
        route,
        moduleRouterMap,
        params,
        hash,
        queryParams
      ) ||
      defaultRoute ||
      DEFAULT_ROOT_ROUTE
    );
  };

export const getModuleRouteDefinitionPath = <
  M extends DefaultRouteModule = DefaultRouteModule,
  T extends string = string
>(
  module: M,
  route: T,
  moduleRouterMap: ModuleRouterMap<M>
): string | undefined => {
  const { basePath } = moduleRouterMap[module];
  const defRoute = moduleRouterMap[module].subPath[route];
  if (defRoute === undefined) return undefined;
  return getRouterDefinitionPath(basePath, defRoute);
};
