import { FC, OnBlurEvent, SizeVariant } from "@laba/react-common";
import React from "react";
import { AttachmentInput } from "components/inputs/AttachmentInput/AttachmentInput";
import { AttachmentListInputStyle } from "components/inputs/AttachmentListInput/AttachmentListInputstyle";
import { FileAttachmentDownload } from "components/download/FileAttachmentDownload/FileAttachmentDownload";
import { Noop, Optional } from "@laba/ts-common";
import { CloseIcon } from "components/icons";
import { BaseIconButton } from "components/buttons/BaseIconButton/BaseIconButton";
import { isEmpty } from "lodash-es";
import { AttachmentConfig } from "components/popup/AttachmentPopup/helpers";
import { BaseAttachmentProps } from "components/popup/AttachmentPopup/AttachmentCardContent/AttachmentCardContent";

export interface AttachmentListInputProps extends BaseAttachmentProps {
  fileValues: AttachmentConfig[];
  onSubmit: (values: AttachmentConfig[]) => void;
  onRemoveFileValue?: (file: AttachmentConfig) => void;
  placeholder?: string;
  headerTitle?: string;
  disableInputButton?: boolean;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onDownloadFile?: (fileUrl?: string) => Promise<Optional<Blob>>;
  onFailureFile?: Noop;
  errorText?: string;
  helperText?: string;
  showError?: boolean;
  showHelperOrErrorText?: boolean;
  onClosePopup?: Noop;
  onBlur?: OnBlurEvent;
}

export const AttachmentListInput: FC<AttachmentListInputProps> = ({
  fileValues,
  onSubmit,
  primaryButtonText,
  secondaryButtonText,
  uploadFiles,
  headerTitle,
  dropButtonText,
  dropPromptText,
  orText,
  dropRejectedText,
  acceptedFileExtensionsText,
  acceptedFileExtensions,
  onDownloadFile,
  onFailureFile,
  onRemoveFileValue,
  placeholder,
  errorText,
  helperText,
  disableInputButton,
  onDropError,
  errorMessageMapper,
  maxFiles,
  maxFileSize,
  retryDownloadText,
  showHelperOrErrorText = true,
  showError = true,
  downloadFilesButtonText,
  downloadFilesText,
  showDownloadFiles,
  onClosePopup,
  fileErrors,
  isMobile,
  onBlur
}) => {
  const hasError = showError && Boolean(errorText);
  const hasErrorOrHelperText = !isEmpty(errorText) || !isEmpty(helperText);
  const showableHelperText = (hasError ? errorText : helperText) || "";
  const classes = AttachmentListInputStyle({ hasError });

  const wrappedOnDownload = async (file: AttachmentConfig) => {
    return onDownloadFile?.(file.url);
  };
  return (
    <div className={classes.root}>
      <AttachmentInput
        onSubmit={onSubmit}
        uploadFiles={uploadFiles}
        headerTitle={headerTitle}
        primaryButtonText={primaryButtonText}
        secondaryButtonText={secondaryButtonText}
        dropButtonText={dropButtonText}
        dropPromptText={dropPromptText}
        orText={orText}
        dropRejectedText={dropRejectedText}
        acceptedFileExtensions={acceptedFileExtensions}
        acceptedFileExtensionsText={acceptedFileExtensionsText}
        placeholder={placeholder}
        fileValues={fileValues}
        disableInputButton={disableInputButton}
        onDropError={onDropError}
        errorMessageMapper={errorMessageMapper}
        maxFiles={maxFiles}
        maxFileSize={maxFileSize}
        retryDownloadText={retryDownloadText}
        downloadFilesButtonText={downloadFilesButtonText}
        downloadFilesText={downloadFilesText}
        showDownloadFiles={showDownloadFiles}
        onClosePopup={onClosePopup}
        fileErrors={fileErrors}
        isMobile={isMobile}
        onBlur={onBlur}
      />
      {fileValues.map((f, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className={classes.fileValueItemContainer}>
            <FileAttachmentDownload
              className={classes.downloadButton}
              withPreview={!isMobile}
              underlined={false}
              name={f.filename ?? ""}
              onDownload={() => wrappedOnDownload(f)}
              onFailure={onFailureFile}
            />
            <BaseIconButton
              size={SizeVariant.ExtraSmall}
              Icon={CloseIcon}
              onClick={() => onRemoveFileValue?.(f)}
              withoutPadding
            />
          </div>
        );
      })}
      {showHelperOrErrorText && hasErrorOrHelperText && (
        <p className={classes.errorText}> {showableHelperText} </p>
      )}
    </div>
  );
};
