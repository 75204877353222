/**
 * @param str
 * Seed for the number that will be returned
 * This function applied to a string will always return the same number
 */
export const getHash = (str = ""): number =>
  str
    .split("")
    // eslint-disable-next-line no-bitwise
    .reduce((hash, char) => ((hash << 5) - hash + char.charCodeAt(0)) | 0, 0);

export const getHashIndex = (maxNum: number, text = ""): number => {
  const uniqueNumber = getHash(text);
  return uniqueNumber % maxNum;
};
