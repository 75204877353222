import { ResourceModel, ResourceType } from "model/primitives/resourceModel";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { Location } from "model/resource/entities/location/location";
import { Practitioner } from "model/resource/person/practitioner/practitioner";
import { ApiDate, getKeyObj } from "@laba/ts-common";
import {
  AvailableTime,
  NotAvailableTime,
  Period
} from "model/resource/utils/availableTime";
import { Organization } from "model/resource/entities/organization/organization";
import { Identifier } from "model/primitives/identifier";
import { Code } from "model/resource/entities/codeSystem";
import { Model } from "model/primitives/model/model";
import { createHydratedMock } from "ts-auto-mock";
import { Attachment, PayerRulesConfiguration } from "model/primitives";
import { AppointmentNotificationConfigCode } from "model/resource/appointment/appointment";
import { ScheduleDefinition } from "./scheduleDefinition";
import { Product } from "../finance/product/product";

export enum KnownScheduleType {
  Presential = "Presential",
  VideoChat = "VideoChat",
  All = "All"
}

export enum KnownScheduleDuration {
  Quarter = 15,
  OneThird = 20,
  Half = 30,
  Whole = 60
}

export interface ScheduleAvailability {
  slotDuration?: number;
  slotDurationList?: number[];
  overbookingLimit?: number;
  availableTime?: AvailableTime[];
  notAvailableTime?: NotAvailableTime[];
}

export interface SchedulePerformer extends Model {
  practitioner: ModelReference<Practitioner>;
}

export interface ScheduleNotificationConfig {
  codeList?: AppointmentNotificationConfigCode[];
  notifyPhone?: boolean;
  notifyEmail?: boolean;
  active?: boolean;
  reminderList?: number[];
  override?: boolean;
}

export interface ScheduleNotificationConfigObj {
  practitioner?: ScheduleNotificationConfig;
  patient?: ScheduleNotificationConfig;
}

export interface ScheduleActionWindow {
  start?: number;
  end?: number;
}

export interface ScheduleOnlineAppointmentBookingConfiguration {
  bookingWindow?: number;
  enabled?: boolean;
  scheduleName?: string;
  photo?: Attachment;
}

export interface Schedule extends ResourceModel<ResourceType.Schedule> {
  name?: string;
  location?: ModelReference<Location>;
  type?: KnownScheduleType;
  speciality?: Code[];
  performer?: SchedulePerformer[];
  planningHorizon?: Period;
  active: boolean;
  organization: ModelReference<Organization>;
  availability?: ScheduleAvailability;
  lastEditDate?: ApiDate;
  practitioner?: ModelReference<Practitioner>;
  originalPractitioner?: ModelReference<Practitioner>;
  identifier: Identifier[];
  definition?: ModelReference<ScheduleDefinition>;
  notificationConfig?: ScheduleNotificationConfigObj;
  patientInstruction?: string;
  product?: ModelReference<Product>[];
  confirmationWindow?: ScheduleActionWindow;
  cancellationWindow?: ScheduleActionWindow;
  payerRulesConfiguration?: PayerRulesConfiguration;
  overrideOrganizationPayerRulesConfiguration?: boolean;
  onlineAppointmentBookingConfiguration?: ScheduleOnlineAppointmentBookingConfiguration;
}

export const ScheduleFormDataParamsKey = getKeyObj(
  createHydratedMock<Schedule>()
);

export const AvailableTimeFormDataParamsKey = getKeyObj(
  createHydratedMock<AvailableTime>()
);

export const NotAvailableTimeFormDataParamsKey = getKeyObj(
  createHydratedMock<NotAvailableTime>()
);

export const ScheduleNotificationConfigKey = getKeyObj(
  createHydratedMock<ScheduleNotificationConfig>()
);

export interface RescheduleSchedule {
  oldSchedule?: Schedule;
  newSchedule?: Schedule;
}

export const ScheduleOnlineAppointmentBookingConfigurationKey = getKeyObj(
  createHydratedMock<ScheduleOnlineAppointmentBookingConfiguration>()
);
