import { FC } from "react";
import { Noop } from "@laba/ts-common";
import { useDisableNavigationEvents } from "@laba/react-common";

interface Props {
  onClose?: Noop;
}
export const NavigationBlocker: FC<Props> = ({ onClose }) => {
  useDisableNavigationEvents(onClose);
  return null;
};
