import React from "react";
import {
  getClassName,
  OnBlurEvent,
  ReactElement,
  TextVariant
} from "@laba/react-common";
import { FormHelperText } from "@material-ui/core";
import { isEven, isOdd } from "@laba/ts-common";
import { floor } from "lodash-es";
import { useCheckboxGroupStyles } from "./CheckboxGroupStyle";
import { CheckboxTextVariant, NexupCheckbox } from "../Checkbox/Checkbox";

export interface CheckboxItem<V> {
  value: V;
  text: string;
  checked: boolean;
  disabled?: boolean;
  error?: string;
}

export interface CheckboxGroupProps<V> {
  className?: string;
  onChange?: (checked: boolean, value: V) => void;
  items: CheckboxItem<V>[];
  showError?: boolean;
  showHelperOrErrorText?: boolean;
  errorText?: string;
  helperText?: string;
  onBlur?: OnBlurEvent<HTMLButtonElement>;
  textVariant?: CheckboxTextVariant;
  gridColumns?: number;
  withoutCheckboxPadding?: boolean;
  rowGap?: number;
  columnGap?: number;
  singleLine?: boolean;
  withColoredItems?: boolean;
}

export const CheckboxGroup = <V,>({
  className,
  items,
  onChange,
  showError = true,
  showHelperOrErrorText = true,
  errorText,
  helperText,
  onBlur,
  textVariant = TextVariant.Body2,
  gridColumns = 2,
  withoutCheckboxPadding,
  rowGap,
  columnGap,
  singleLine,
  withColoredItems = false
}: CheckboxGroupProps<V>): ReactElement => {
  const hasError = showError && Boolean(errorText);
  const classes = useCheckboxGroupStyles({
    gridColumns,
    hasError,
    rowGap,
    columnGap
  });
  const showableHelperText = (hasError ? errorText : helperText) || " ";
  const numberOfItems = items.length;
  const numberOfItemsIsOdd = isOdd(numberOfItems);

  return (
    <div className={getClassName(className, classes.root)}>
      <div className={classes.itemsWrapper}>
        {items.map((item, idx) => {
          const itemsIdx = idx + 1;
          const isLastItem = itemsIdx === numberOfItems;

          const rowIndex = floor(idx / gridColumns);
          const coloredItem = withColoredItems ? isEven(rowIndex) : false;
          return (
            <NexupCheckbox
              className={
                numberOfItemsIsOdd && gridColumns > 1 && isLastItem
                  ? classes.lastChild
                  : undefined
              }
              key={item.text}
              text={item.text}
              onChange={checked => onChange?.(checked, item.value)}
              checked={item.checked}
              disabled={item.disabled}
              errorText={item.error}
              onBlur={onBlur}
              textVariant={textVariant}
              singleLine={singleLine}
              withoutPadding={withoutCheckboxPadding}
              coloredBackground={coloredItem}
            />
          );
        })}
      </div>
      {showHelperOrErrorText && (
        <FormHelperText
          className={classes.errorText}
          error={hasError}
          margin="dense"
        >
          {showableHelperText}
        </FormHelperText>
      )}
    </div>
  );
};
