import { OrganizationPublicData } from "model/resource";
import { getFrontPublicApiUrl, requestAnon } from "request/nexupRequest";
import { ApiRequestResponse } from "request/types";
import { HttpMethod, ModelId } from "@laba/ts-common";
import { organizationBasePath } from "api/entities";

export const getOrganizationPublicData = (
  id: ModelId
): Promise<ApiRequestResponse<OrganizationPublicData>> =>
  requestAnon({
    method: HttpMethod.GET,
    url: getFrontPublicApiUrl(`${organizationBasePath}/${id}/public-data`)
  });
