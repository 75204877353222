import {
  createStyle,
  flexColumnMixin,
  marginMixin,
  StyleVariant,
  TextVariant,
  Theme
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { ThemeStyleColorVariant } from "model/theme";

export const useProblemHistoryStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexColumnMixin({})
    },
    title: {
      ...themeTextMixin(theme, TextVariant.Subtitle2, 1),
      ...marginMixin({ topBottom: 8 }),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "ProblemHistory"
);
