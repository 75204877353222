import { HttpMethod } from "@laba/ts-common";
import { ModelId } from "model/primitives/model/model";
import { Code } from "model/resource/entities/codeSystem";
import { MeasureObservationCode } from "model/resource/medical/observation/observation";
import { PatientContainerWithExtraData } from "model/resource/person/patient/patientContainerWithExtraData";
import { getFrontApiUrl, request } from "request/nexupRequest";
import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { PatientListOrder } from "./patient";

export enum PatientHospitalizationStatus {
  Hospitalized = "hospitalized",
  Discharged = "discharged",
  Ambulatory = "ambulatory",
  All = "all"
}

export interface PatientContainerWithExtraDataQueryParams {
  measurementCode?: MeasureObservationCode[];
  withAllergyIntoleranceList?: boolean;
  withCurrentBed?: boolean;
  withEncounterDiagnosis?: boolean;
  withHealthCarePayerProvider?: boolean;
  withHospitalization?: boolean;
  withLastEvolutionEditDate?: boolean;
  withLastPrescriptionEditDate?: boolean;
  withPatientOrganization?: boolean;
  withPractitionerTeam?: boolean;
  withPrescriptionStatus?: boolean;
  withTagList?: boolean;
  withManagedGroup?: boolean;
}

export interface PatientContainerWithExtraDataListQueryParams
  extends ListQueryParams,
    PatientContainerWithExtraDataQueryParams {
  content?: string;
  currentLocation?: ModelId;
  hospitalizationStatus?: PatientHospitalizationStatus;
  order?: PatientListOrder;
  organization?: ModelId;
  payer?: ModelId;
  practitioner?: ModelId;
  tag?: Code[];
  service?: Code;
  active?: boolean;
}

const patientBasePath = "/patient";
const patientExtraDataBasePath = "/extra";

export const getPatientContainerWithExtraData = (
  id: ModelId,
  params: PatientContainerWithExtraDataQueryParams
): Promise<ApiRequestResponse<PatientContainerWithExtraData>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${patientBasePath}/${id}${patientExtraDataBasePath}`),
    params
  });

export const getPatientContainerWithExtraDataList = (
  params: PatientContainerWithExtraDataListQueryParams
): Promise<ApiPageRequestResponse<PatientContainerWithExtraData>> => {
  return request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${patientBasePath}${patientExtraDataBasePath}`),
    params: {
      active: true,
      ...params
    }
  });
};
