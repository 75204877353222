import {
  borderMixinRadiusAll,
  createStyle,
  disabledMixin,
  getClassName,
  pxToRem
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { SizeVariant, StyleVariant, TypeVariant } from "model/themeVariant";
import { getThemeStyleColor } from "model/themeUtils";
import { getMapValueBuilder } from "@laba/ts-common";
import { NexupColor } from "model/nexupColor";
import {
  ClassNameDynamicGeneratedRecord,
  getGenericButtonBackgroundColor,
  getGenericButtonColor,
  TypeInvertedVariant
} from "components/utils/getGenericButtonColors";

export interface BaseIconButtonStyleProps {
  size: SizeVariant;
  style: StyleVariant;
  type: TypeInvertedVariant;
  withoutPadding: boolean;
  disabled: boolean;
}

interface BaseIconButtonClassNames {
  root?: string;
}
const getSize = getMapValueBuilder({
  [SizeVariant.Large]: pxToRem(44),
  [SizeVariant.Medium]: pxToRem(40),
  [SizeVariant.Small]: pxToRem(36),
  [SizeVariant.ExtraSmall]: pxToRem(24)
});

const generatedSizeStyleKeyTemplate = (size: SizeVariant) =>
  `generatedStyle${size}`;

const generateDynamicSizeStylesClassNames =
  (): ClassNameDynamicGeneratedRecord => {
    const classNameObject: ClassNameDynamicGeneratedRecord = {};
    Object.values(SizeVariant).forEach(sizeVariant => {
      classNameObject[generatedSizeStyleKeyTemplate(sizeVariant)] = {
        height: getSize(sizeVariant),
        width: getSize(sizeVariant)
      };
    });
    return classNameObject;
  };

const generatedStyleKeyTemplate = (
  type: TypeInvertedVariant,
  style: StyleVariant,
  disabled: boolean
) => `generatedStyle${type}${style}${disabled ? "Disabled" : "NotDisabled"}`;

const generateDynamicStylesClassNames = (
  theme: Theme
): ClassNameDynamicGeneratedRecord => {
  const classNameObject: ClassNameDynamicGeneratedRecord = {};
  Object.values(TypeInvertedVariant).forEach(typeInvertedVariant => {
    Object.values(StyleVariant).forEach(styleVariant => {
      [true, false].forEach(disabled => {
        classNameObject[
          generatedStyleKeyTemplate(typeInvertedVariant, styleVariant, disabled)
        ] = {
          color: getGenericButtonColor(theme, {
            style: styleVariant,
            type:
              typeInvertedVariant === TypeInvertedVariant.Normal
                ? TypeVariant.Contained
                : TypeVariant.Text,
            disabled
          }),
          "&:disabled": {
            backgroundColor: getGenericButtonBackgroundColor(theme, {
              style: styleVariant,
              type:
                typeInvertedVariant === TypeInvertedVariant.Normal
                  ? TypeVariant.Contained
                  : TypeVariant.Text,
              disabled
            }),
            color: getGenericButtonColor(theme, {
              style: styleVariant,
              type:
                typeInvertedVariant === TypeInvertedVariant.Normal
                  ? TypeVariant.Contained
                  : TypeVariant.Text,
              disabled
            })
          }
        };
      });
    });
  });
  return classNameObject;
};

const useBaseIconButtonStyles = createStyle<Theme, unknown, string>(
  (theme: Theme) => ({
    root: {
      backgroundColor: NexupColor.Transparent,
      ...borderMixinRadiusAll("50%")
    },
    typeNormal: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      ),
      ...disabledMixin({
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.GrayLight
        )
      })
    },
    withoutPadding: { padding: 0 },
    typeOther: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Main
      ),
      ...disabledMixin({
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.Light
        )
      })
    },
    ...generateDynamicSizeStylesClassNames(),
    ...generateDynamicStylesClassNames(theme)
  }),
  "BaseIconButton"
);

export const useBaseIconButtonStylesClasses = (
  props: BaseIconButtonStyleProps
): BaseIconButtonClassNames => {
  const classes = useBaseIconButtonStyles();

  return {
    root: getClassName(
      props.withoutPadding
        ? classes.withoutPadding
        : classes[generatedSizeStyleKeyTemplate(props.size)],
      classes.root,
      props.type === TypeInvertedVariant.Normal
        ? classes.typeNormal
        : classes[
            generatedStyleKeyTemplate(props.type, props.style, props.disabled)
          ]
    )
  };
};
