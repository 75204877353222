import { FC } from "@laba/react-common";
import React from "react";
import { DefaultButton } from "components/buttons/DefaultButton/DefaultButton";
import { TypeVariant } from "model/themeVariant";
import { UploadFileIcon } from "components/icons";
import {
  ErrorToStringMapper,
  useInputDropzone
} from "components/inputs/hooks/useInputDropzone";
import { useUploadZoneStyle } from "./UploadZoneStyle";

export interface UploadZoneProps {
  maxFiles?: number;
  formattedFileExtensions?: string;
  maxFileSize?: number;
  uploadFiles?: (files: File[]) => void;
  dropButtonText?: string;
  dropPromptText?: string;
  orText?: string;
  dropRejectedText?: string;
  acceptedFileExtensions?: string[];
  acceptedFileExtensionsText?: string;
  onDropError?: (errors: string[]) => void;
  errorMessageMapper?: ErrorToStringMapper;
  disabled?: boolean;
}

export const UploadZone: FC<UploadZoneProps> = ({
  onDropError,
  errorMessageMapper,
  maxFiles,
  maxFileSize,
  uploadFiles,
  dropButtonText,
  dropPromptText,
  orText,
  dropRejectedText,
  acceptedFileExtensions,
  acceptedFileExtensionsText,
  disabled
}) => {
  const { getRootProps, getInputProps, isDragReject } = useInputDropzone({
    disabled,
    uploadFiles,
    acceptedFileExtensions,
    maxFiles,
    maxFileSize,
    onDropError,
    errorMessageMapper
  });

  const { onClick, ...otherProps } = getRootProps();
  const classes = useUploadZoneStyle({ disabled });
  return (
    <div className={classes.root} {...otherProps}>
      <input {...getInputProps()} />
      <div className={classes.promptContainer}>
        <DefaultButton
          type={TypeVariant.Contained}
          text={dropButtonText}
          onClick={onClick}
          StartIcon={UploadFileIcon}
          disabled={disabled}
        />
        <div className={classes.promptTextContainer}>
          <p className={classes.promptText}>{orText}</p>
          <p className={classes.promptText}>{dropPromptText}</p>
          {isDragReject && (
            <p className={classes.dropRejected}>{dropRejectedText}</p>
          )}
        </div>
        {Boolean(acceptedFileExtensionsText) && (
          <p className={classes.promptText}>{acceptedFileExtensionsText}</p>
        )}
      </div>
    </div>
  );
};
