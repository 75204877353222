import React from "react";
import { Color, FC, IconC } from "@laba/react-common";
import { StyleVariant, TypeVariant } from "model/themeVariant";
import { useMuiTheme } from "model/useMuiTheme";
import { ThemeProvider } from "@material-ui/core/styles";
import { ButtonBase } from "@material-ui/core";
import { Noop } from "@laba/ts-common";
import { Tag, TagTypeVariant } from "../Tag/Tag";

export interface TagButtonProps {
  className?: string;
  disabled?: boolean;
  title?: string;
  text?: string;
  EndIcon?: IconC;
  style?: StyleVariant;
  type?: TagTypeVariant;
  onClick?: Noop;
  backgroundColor?: Color;
}

export const TagButton: FC<TagButtonProps> = ({
  className,
  EndIcon,
  onClick,
  text,
  title,
  disabled = false,
  style = StyleVariant.Primary,
  type = TypeVariant.Contained,
  backgroundColor
}) => {
  const muiTheme = useMuiTheme(style);
  return (
    <ThemeProvider theme={muiTheme}>
      <ButtonBase className={className} disabled={disabled} onClick={onClick}>
        <Tag
          disabled={disabled}
          EndIcon={EndIcon}
          isClickable
          style={style}
          text={text}
          title={title}
          type={type}
          backgroundColor={backgroundColor}
        />
      </ButtonBase>
    </ThemeProvider>
  );
};
