import { FC, getClassName } from "@laba/react-common";
import React, { KeyboardEvent, useRef } from "react";
import { NexupIconButton } from "components/buttons/IconButton/IconButton";
import {
  useChatInputStyle,
  useChatInputLabelStyle
} from "components/chat/Chat/ChatInput/useChatInputStyle";
import { TextField, IconButton } from "@material-ui/core";
import { AttachFile, SendIcon } from "components/icons";
import { StyleVariant } from "model/themeVariant";
import { Noop } from "@laba/ts-common";

export interface ChatInputProps {
  className?: string;
  inputValue?: string;
  onChange?: (value: string) => void;
  inputMaxRows?: number;
  disabledInput?: boolean;
  disabledSend?: boolean;
  disabledAttachment?: boolean;
  label?: string;
  onClickSend?: Noop;
  onClickAttachment?: (file: File) => void;
}

export const ChatInput: FC<ChatInputProps> = ({
  className,
  inputValue,
  onChange,
  disabledInput,
  disabledSend,
  disabledAttachment,
  label,
  onClickSend,
  onClickAttachment,
  inputMaxRows = 4
}) => {
  const classes = useChatInputStyle();
  const inputLabelClasses = useChatInputLabelStyle();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleEnter = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onClickSend?.();
    }
  };
  return (
    <div className={getClassName(classes.root, className)}>
      <TextField
        className={classes.overrideMui}
        value={inputValue ?? ""}
        variant="outlined"
        size="small"
        maxRows={inputMaxRows}
        multiline
        onChange={e => onChange?.(e.target.value)}
        disabled={disabledInput}
        onKeyDown={handleEnter}
        placeholder={label}
        InputProps={{
          classes: inputLabelClasses
        }}
      />
      <div className={classes.endContainer}>
        <IconButton
          onClick={() => fileInputRef.current?.click()}
          className={classes.attachmentIcon}
          size="small"
          disabled={disabledAttachment}
        >
          <AttachFile />
        </IconButton>
        <input
          type="file"
          onChange={e => {
            const file = e.target.files?.[0];

            file && onClickAttachment?.(file);
          }}
          ref={fileInputRef}
          hidden
        />
        <NexupIconButton
          onClick={onClickSend}
          disabled={disabledSend}
          style={StyleVariant.Success}
          Icon={SendIcon}
          className={classes.sendIcon}
        />
      </div>
    </div>
  );
};
