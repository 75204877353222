import { isArray, isObject } from "model/types";
import { deepMapObject } from "model/deepMap";

// eslint-disable-next-line @typescript-eslint/ban-types
type Obj = object;
type Key = string;
type ObjKeyObj<T> = NonNullable<T> & { thisKey: Key };
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ArrayKeyObj<T> = Key;
interface ObjArrayKeyObj<T> {
  thisKey: Key;
  elementKeyObj: T;
}
export type KeyObj<T> = Required<{
  [k in keyof T]-?: NonNullable<T[k]> extends (infer Item)[]
    ? ArrayKeyObj<Item>
    : NonNullable<T[k]> extends Obj
    ? ObjKeyObj<KeyObj<Required<T[k]>>>
    : Key;
}>;
export type KeyObjNested<T> = Required<{
  [k in keyof T]-?: NonNullable<T[k]> extends (infer Item)[]
    ? NonNullable<Item> extends Obj
      ? ObjArrayKeyObj<KeyObj<Item>>
      : ArrayKeyObj<Item>
    : NonNullable<T[k]> extends Obj
    ? ObjKeyObj<KeyObj<T[k]>>
    : Key;
}>;

/**
 * This function create an object with the same internal structure that obj but with the access key in the values like tk.
 * Use this function with createHydratedMock<T>() from ts-auto-mock so you dont need to define a mock object.
 * When mocking the interface the plugin create empty arrays (and cannot be change) so if you need the array internal object key you have to use this function again with the array element type.
 * ONLY CALL CREATE HYDRATE MOCK FROM A NON CREATE REACT APP PROJECT. This function use a typescript compiler transformation plugin that is incompatible with create react app webpack config.
 * *
 * @param obj The base object to extract internal structure.
 * @return Returns the key object.
 */
export function getKeyObj<T extends Obj>(obj: T): KeyObj<T> {
  return deepMapObject<T, KeyObj<T>>(obj, (value, path) => {
    if (isArray(value)) {
      return path;
    }
    if (isObject(value)) return { thisKey: path, ...value };
    return path;
  });
}
