import { createStyle, flexItemMixin, marginMixin } from "@laba/react-common";
import { Theme } from "model/theme";

export const useChatStyle = createStyle(
  (_theme: Theme) => ({
    messagesContainer: {
      ...marginMixin({
        top: 16
      }),
      ...flexItemMixin({
        grow: 1
      })
    }
  }),
  "NexupChat"
);
