import React from "react";
import { Create } from "components/generic/Create/Create";
import { FC } from "@laba/react-common";
import { ConceptResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import {
  ProcedureCodeConceptJsonToModel,
  procedureCodeConceptTransform
} from "components/pages/NexupAdmin/resources/concept/procedureCodeConceptList/ProcedureCodeConceptForm";
import { ProcedureBatteryConceptForm } from "components/pages/NexupAdmin/resources/concept/batteryProcedureCode/ProcedureBatteryConceptForm";

export const ProcedureBatteryConceptCreate: FC = () => {
  return (
    <Create
      resourceTransform={procedureCodeConceptTransform}
      resource={ConceptResourceType.ProcedureBatteryConcept}
      jsonToModel={ProcedureCodeConceptJsonToModel}
    >
      <ProcedureBatteryConceptForm />
    </Create>
  );
};
