import { FC, TextVariant } from "@laba/react-common";
import React from "react";
import { isValidUrl } from "@laba/ts-common";
import { UrlLink } from "components/link/UrlLink/UrlLink";

export interface TextOrLinkProps {
  className?: string;
  message?: string;
  linkTextVariant?: TextVariant;
}

export const TextOrLink: FC<TextOrLinkProps> = ({
  message,
  className,
  linkTextVariant = TextVariant.Body2
}) => {
  return message && isValidUrl(message) ? (
    <UrlLink
      url={message}
      displayText={message}
      textVariant={linkTextVariant}
    />
  ) : (
    <p className={className}>{message}</p>
  );
};
