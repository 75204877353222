import {
  borderMixin,
  createStyle,
  FlexAlignContent,
  flexRowMixin,
  pxToRem,
  Theme
} from "@laba/react-common";
import { NexupColor } from "@laba/nexup-components";

export const useUploadFileImageFormInputStyles = createStyle(
  (_theme: Theme) => ({
    image: {
      ...borderMixin({ side: { all: 1 }, style: "solid" }),
      objectFit: "contain",
      maxHeight: pxToRem(200),
      maxWidth: "100%",
      backgroundColor: NexupColor.LightGray
    },
    video: {
      ...borderMixin({ side: { all: 1 }, style: "solid" }),
      objectFit: "contain",
      maxHeight: pxToRem(200),
      maxWidth: "100%"
    },
    inputContainer: {
      ...flexRowMixin({ gap: 4, alignContent: FlexAlignContent.Center }),
      width: "100%"
    }
  }),
  "useImageFormInputStyle"
);
