import { getEnumOrUndefined, notUndefined, Optional } from "@laba/ts-common";
import {
  AdultOdontogramToothCode,
  ChildrenOdontogramToothCode,
  isOdontogramAdultToothCode,
  isOdontogramChildrenToothCode,
  OdontogramToothCodeType,
  OdontogramToothSurface
} from "./codes";

export enum ToothDiagnosticPosition {
  Top = "Top",
  Bottom = "Bottom",
  Left = "Left",
  Right = "Right",
  Center = "Center"
}

type PositionSurfaceMap = Partial<
  Record<OdontogramToothSurface, ToothDiagnosticPosition>
>;

type ToothPositionSurfaceMap = Partial<
  Record<OdontogramToothCodeType, PositionSurfaceMap>
>;

const AdultPositionSurfaceMap14: PositionSurfaceMap = {
  [OdontogramToothSurface.Buccal]: ToothDiagnosticPosition.Top,
  [OdontogramToothSurface.Palatine]: ToothDiagnosticPosition.Bottom,
  [OdontogramToothSurface.Distal]: ToothDiagnosticPosition.Left,
  [OdontogramToothSurface.Mesial]: ToothDiagnosticPosition.Right,
  [OdontogramToothSurface.Occlusal]: ToothDiagnosticPosition.Center
};

const AdultPositionSurfaceMap11: PositionSurfaceMap = {
  [OdontogramToothSurface.Buccal]: ToothDiagnosticPosition.Top,
  [OdontogramToothSurface.Palatine]: ToothDiagnosticPosition.Bottom,
  [OdontogramToothSurface.Distal]: ToothDiagnosticPosition.Left,
  [OdontogramToothSurface.Mesial]: ToothDiagnosticPosition.Right,
  [OdontogramToothSurface.Incisal]: ToothDiagnosticPosition.Center
};

const AdultPositionSurfaceMap21: PositionSurfaceMap = {
  [OdontogramToothSurface.Buccal]: ToothDiagnosticPosition.Top,
  [OdontogramToothSurface.Palatine]: ToothDiagnosticPosition.Bottom,
  [OdontogramToothSurface.Distal]: ToothDiagnosticPosition.Right,
  [OdontogramToothSurface.Mesial]: ToothDiagnosticPosition.Left,
  [OdontogramToothSurface.Incisal]: ToothDiagnosticPosition.Center
};

const AdultPositionSurfaceMap24: PositionSurfaceMap = {
  [OdontogramToothSurface.Buccal]: ToothDiagnosticPosition.Top,
  [OdontogramToothSurface.Palatine]: ToothDiagnosticPosition.Bottom,
  [OdontogramToothSurface.Distal]: ToothDiagnosticPosition.Right,
  [OdontogramToothSurface.Mesial]: ToothDiagnosticPosition.Left,
  [OdontogramToothSurface.Occlusal]: ToothDiagnosticPosition.Center
};

const AdultPositionSurfaceMap31: PositionSurfaceMap = {
  [OdontogramToothSurface.Buccal]: ToothDiagnosticPosition.Bottom,
  [OdontogramToothSurface.Lingual]: ToothDiagnosticPosition.Top,
  [OdontogramToothSurface.Distal]: ToothDiagnosticPosition.Right,
  [OdontogramToothSurface.Mesial]: ToothDiagnosticPosition.Left,
  [OdontogramToothSurface.Incisal]: ToothDiagnosticPosition.Center
};

const AdultPositionSurfaceMap34: PositionSurfaceMap = {
  [OdontogramToothSurface.Buccal]: ToothDiagnosticPosition.Bottom,
  [OdontogramToothSurface.Lingual]: ToothDiagnosticPosition.Top,
  [OdontogramToothSurface.Distal]: ToothDiagnosticPosition.Right,
  [OdontogramToothSurface.Mesial]: ToothDiagnosticPosition.Left,
  [OdontogramToothSurface.Occlusal]: ToothDiagnosticPosition.Center
};

const AdultPositionSurfaceMap41: PositionSurfaceMap = {
  [OdontogramToothSurface.Buccal]: ToothDiagnosticPosition.Bottom,
  [OdontogramToothSurface.Lingual]: ToothDiagnosticPosition.Top,
  [OdontogramToothSurface.Distal]: ToothDiagnosticPosition.Left,
  [OdontogramToothSurface.Mesial]: ToothDiagnosticPosition.Right,
  [OdontogramToothSurface.Incisal]: ToothDiagnosticPosition.Center
};

const AdultPositionSurfaceMap44: PositionSurfaceMap = {
  [OdontogramToothSurface.Buccal]: ToothDiagnosticPosition.Bottom,
  [OdontogramToothSurface.Lingual]: ToothDiagnosticPosition.Top,
  [OdontogramToothSurface.Distal]: ToothDiagnosticPosition.Left,
  [OdontogramToothSurface.Mesial]: ToothDiagnosticPosition.Right,
  [OdontogramToothSurface.Occlusal]: ToothDiagnosticPosition.Center
};

export const AdultToothPositionSurfaces: ToothPositionSurfaceMap = {
  [AdultOdontogramToothCode.T1_1]: AdultPositionSurfaceMap11,
  [AdultOdontogramToothCode.T1_2]: AdultPositionSurfaceMap11,
  [AdultOdontogramToothCode.T1_3]: AdultPositionSurfaceMap11,
  [AdultOdontogramToothCode.T1_4]: AdultPositionSurfaceMap14,
  [AdultOdontogramToothCode.T1_5]: AdultPositionSurfaceMap14,
  [AdultOdontogramToothCode.T1_6]: AdultPositionSurfaceMap14,
  [AdultOdontogramToothCode.T1_7]: AdultPositionSurfaceMap14,
  [AdultOdontogramToothCode.T1_8]: AdultPositionSurfaceMap14,
  [AdultOdontogramToothCode.T2_1]: AdultPositionSurfaceMap21,
  [AdultOdontogramToothCode.T2_2]: AdultPositionSurfaceMap21,
  [AdultOdontogramToothCode.T2_3]: AdultPositionSurfaceMap21,
  [AdultOdontogramToothCode.T2_4]: AdultPositionSurfaceMap24,
  [AdultOdontogramToothCode.T2_5]: AdultPositionSurfaceMap24,
  [AdultOdontogramToothCode.T2_6]: AdultPositionSurfaceMap24,
  [AdultOdontogramToothCode.T2_7]: AdultPositionSurfaceMap24,
  [AdultOdontogramToothCode.T2_8]: AdultPositionSurfaceMap24,
  [AdultOdontogramToothCode.T3_1]: AdultPositionSurfaceMap31,
  [AdultOdontogramToothCode.T3_2]: AdultPositionSurfaceMap31,
  [AdultOdontogramToothCode.T3_3]: AdultPositionSurfaceMap31,
  [AdultOdontogramToothCode.T3_4]: AdultPositionSurfaceMap34,
  [AdultOdontogramToothCode.T3_5]: AdultPositionSurfaceMap34,
  [AdultOdontogramToothCode.T3_6]: AdultPositionSurfaceMap34,
  [AdultOdontogramToothCode.T3_7]: AdultPositionSurfaceMap34,
  [AdultOdontogramToothCode.T3_8]: AdultPositionSurfaceMap34,
  [AdultOdontogramToothCode.T4_1]: AdultPositionSurfaceMap41,
  [AdultOdontogramToothCode.T4_2]: AdultPositionSurfaceMap41,
  [AdultOdontogramToothCode.T4_3]: AdultPositionSurfaceMap41,
  [AdultOdontogramToothCode.T4_4]: AdultPositionSurfaceMap44,
  [AdultOdontogramToothCode.T4_5]: AdultPositionSurfaceMap44,
  [AdultOdontogramToothCode.T4_6]: AdultPositionSurfaceMap44,
  [AdultOdontogramToothCode.T4_7]: AdultPositionSurfaceMap44,
  [AdultOdontogramToothCode.T4_8]: AdultPositionSurfaceMap44
};

const ChildrenPositionSurfaceMap14: PositionSurfaceMap = {
  [OdontogramToothSurface.Buccal]: ToothDiagnosticPosition.Top,
  [OdontogramToothSurface.Palatine]: ToothDiagnosticPosition.Bottom,
  [OdontogramToothSurface.Distal]: ToothDiagnosticPosition.Left,
  [OdontogramToothSurface.Mesial]: ToothDiagnosticPosition.Right,
  [OdontogramToothSurface.Occlusal]: ToothDiagnosticPosition.Center
};

const ChildrenPositionSurfaceMap11: PositionSurfaceMap = {
  [OdontogramToothSurface.Buccal]: ToothDiagnosticPosition.Top,
  [OdontogramToothSurface.Palatine]: ToothDiagnosticPosition.Bottom,
  [OdontogramToothSurface.Distal]: ToothDiagnosticPosition.Left,
  [OdontogramToothSurface.Mesial]: ToothDiagnosticPosition.Right,
  [OdontogramToothSurface.Incisal]: ToothDiagnosticPosition.Center
};

const ChildrenPositionSurfaceMap21: PositionSurfaceMap = {
  [OdontogramToothSurface.Buccal]: ToothDiagnosticPosition.Top,
  [OdontogramToothSurface.Palatine]: ToothDiagnosticPosition.Bottom,
  [OdontogramToothSurface.Distal]: ToothDiagnosticPosition.Right,
  [OdontogramToothSurface.Mesial]: ToothDiagnosticPosition.Left,
  [OdontogramToothSurface.Incisal]: ToothDiagnosticPosition.Center
};

const ChildrenPositionSurfaceMap24: PositionSurfaceMap = {
  [OdontogramToothSurface.Buccal]: ToothDiagnosticPosition.Top,
  [OdontogramToothSurface.Palatine]: ToothDiagnosticPosition.Bottom,
  [OdontogramToothSurface.Distal]: ToothDiagnosticPosition.Right,
  [OdontogramToothSurface.Mesial]: ToothDiagnosticPosition.Left,
  [OdontogramToothSurface.Occlusal]: ToothDiagnosticPosition.Center
};

const ChildrenPositionSurfaceMap31: PositionSurfaceMap = {
  [OdontogramToothSurface.Buccal]: ToothDiagnosticPosition.Bottom,
  [OdontogramToothSurface.Lingual]: ToothDiagnosticPosition.Top,
  [OdontogramToothSurface.Distal]: ToothDiagnosticPosition.Right,
  [OdontogramToothSurface.Mesial]: ToothDiagnosticPosition.Left,
  [OdontogramToothSurface.Incisal]: ToothDiagnosticPosition.Center
};

const ChildrenPositionSurfaceMap34: PositionSurfaceMap = {
  [OdontogramToothSurface.Buccal]: ToothDiagnosticPosition.Bottom,
  [OdontogramToothSurface.Lingual]: ToothDiagnosticPosition.Top,
  [OdontogramToothSurface.Distal]: ToothDiagnosticPosition.Right,
  [OdontogramToothSurface.Mesial]: ToothDiagnosticPosition.Left,
  [OdontogramToothSurface.Occlusal]: ToothDiagnosticPosition.Center
};

const ChildrenPositionSurfaceMap41: PositionSurfaceMap = {
  [OdontogramToothSurface.Buccal]: ToothDiagnosticPosition.Bottom,
  [OdontogramToothSurface.Lingual]: ToothDiagnosticPosition.Top,
  [OdontogramToothSurface.Distal]: ToothDiagnosticPosition.Left,
  [OdontogramToothSurface.Mesial]: ToothDiagnosticPosition.Right,
  [OdontogramToothSurface.Incisal]: ToothDiagnosticPosition.Center
};

const ChildrenPositionSurfaceMap44: PositionSurfaceMap = {
  [OdontogramToothSurface.Buccal]: ToothDiagnosticPosition.Bottom,
  [OdontogramToothSurface.Lingual]: ToothDiagnosticPosition.Top,
  [OdontogramToothSurface.Distal]: ToothDiagnosticPosition.Left,
  [OdontogramToothSurface.Mesial]: ToothDiagnosticPosition.Right,
  [OdontogramToothSurface.Occlusal]: ToothDiagnosticPosition.Center
};

export const ChildrenToothPositionSurfaces: ToothPositionSurfaceMap = {
  [ChildrenOdontogramToothCode.T5_1]: ChildrenPositionSurfaceMap11,
  [ChildrenOdontogramToothCode.T5_2]: ChildrenPositionSurfaceMap11,
  [ChildrenOdontogramToothCode.T5_3]: ChildrenPositionSurfaceMap11,
  [ChildrenOdontogramToothCode.T5_4]: ChildrenPositionSurfaceMap14,
  [ChildrenOdontogramToothCode.T5_5]: ChildrenPositionSurfaceMap14,
  [ChildrenOdontogramToothCode.T6_1]: ChildrenPositionSurfaceMap21,
  [ChildrenOdontogramToothCode.T6_2]: ChildrenPositionSurfaceMap21,
  [ChildrenOdontogramToothCode.T6_3]: ChildrenPositionSurfaceMap21,
  [ChildrenOdontogramToothCode.T6_4]: ChildrenPositionSurfaceMap24,
  [ChildrenOdontogramToothCode.T6_5]: ChildrenPositionSurfaceMap24,
  [ChildrenOdontogramToothCode.T7_1]: ChildrenPositionSurfaceMap31,
  [ChildrenOdontogramToothCode.T7_2]: ChildrenPositionSurfaceMap31,
  [ChildrenOdontogramToothCode.T7_3]: ChildrenPositionSurfaceMap31,
  [ChildrenOdontogramToothCode.T7_4]: ChildrenPositionSurfaceMap34,
  [ChildrenOdontogramToothCode.T7_5]: ChildrenPositionSurfaceMap34,
  [ChildrenOdontogramToothCode.T8_1]: ChildrenPositionSurfaceMap41,
  [ChildrenOdontogramToothCode.T8_2]: ChildrenPositionSurfaceMap41,
  [ChildrenOdontogramToothCode.T8_3]: ChildrenPositionSurfaceMap41,
  [ChildrenOdontogramToothCode.T8_4]: ChildrenPositionSurfaceMap44,
  [ChildrenOdontogramToothCode.T8_5]: ChildrenPositionSurfaceMap44
};

const getOdontogramToothSurfaceMapFromToothCode = (
  tooth: OdontogramToothCodeType
): Optional<PositionSurfaceMap> => {
  if (isOdontogramAdultToothCode(tooth)) {
    return AdultToothPositionSurfaces[tooth];
  }
  if (isOdontogramChildrenToothCode(tooth)) {
    return ChildrenToothPositionSurfaces[tooth];
  }
};

export const getOdontogramToothSurfaceListFromToothCode = (
  tooth: OdontogramToothCodeType
): OdontogramToothSurface[] => {
  const surfaceMap = getOdontogramToothSurfaceMapFromToothCode(tooth);
  return surfaceMap
    ? Object.keys(surfaceMap)
        .map(s => {
          return getEnumOrUndefined(OdontogramToothSurface)(s);
        })
        .filter(notUndefined)
    : [];
};

export const getOdontogramToothSurfacePosition = (
  tooth: OdontogramToothCodeType,
  surface: OdontogramToothSurface
): Optional<ToothDiagnosticPosition> => {
  if (isOdontogramAdultToothCode(tooth)) {
    return getOdontogramToothSurfaceMapFromToothCode(tooth)?.[surface];
  }
  if (isOdontogramChildrenToothCode(tooth)) {
    return getOdontogramToothSurfaceMapFromToothCode(tooth)?.[surface];
  }
};
