import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  flexRowMixin,
  paddingMixin,
  pxToRem
} from "@laba/react-common";
import { Theme } from "model/theme";

export const useAccordionListStyles = createStyle(
  (_theme: Theme) => ({
    accordionList: {
      ...flexColumnMixin({})
    },
    nonExpansibleItem: {
      ...paddingMixin({ leftRight: 24 })
    },
    summary: {
      ...flexRowMixin({ alignItems: FlexAlignItems.Center }),
      ...paddingMixin({ leftRight: 24 }),
      height: pxToRem(44)
    },
    accordionDetail: {
      ...paddingMixin({ leftRight: 32 })
    }
  }),
  "AccordionList"
);
