import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  KnownCodeSystemSystem,
  KnownIdentifierSystem,
  Medication,
  MedicationGenericType,
  MedicationKey,
  MedicationListQueryParamsKey,
  MedicationStatus,
  MedicationSubstance,
  MedicationSubstanceListQueryParamsKey,
  OrganizationCombinedType,
  OrganizationKey,
  OrganizationListQueryParamsKey,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import { TextField } from "components/generic/TextField/TextField";
import { ReferenceResourceField } from "components/generic/ReferenceResourceField/ReferenceResourceField";
import { List } from "components/generic/List/List";
import { Datagrid } from "components/generic/Datagrid/Datagrid";
import { medicationJsonToModel } from "components/pages/NexupAdmin/resources/medication/MedicationForm";
import { TextInput } from "components/generic/TextInput/TextInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { FunctionField } from "components/generic/FunctionField/FunctionField";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import {
  medicationOptionText,
  medicationSubstanceOptionText
} from "components/generic/ReferenceResourceInput/optionText";
import { useListExportedFileName } from "components/hook/useExportedFileName";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

const tk = tkCP.adminPage[ResourceType.Medication];

export const MedicationList: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const characteristicSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicationCharacteristic,
    workspaceOrganizationId
  );
  const formSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicationForm,
    workspaceOrganizationId
  );
  const functionCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicationFunctionalCode,
    workspaceOrganizationId
  );
  const presentationUnitSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicationPresentationUnit,
    workspaceOrganizationId
  );
  const routeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicationRoute,
    workspaceOrganizationId
  );
  const exportedFileName = useListExportedFileName(t(tk.title));

  return (
    <List
      resource={ResourceType.Medication}
      exportedFileName={exportedFileName}
      jsonToModel={medicationJsonToModel}
      extraFilters={[
        workspaceOrganizationId === undefined ? (
          <OrganizationReferenceInput
            label={t(tk.filters.organization)}
            source={MedicationListQueryParamsKey.organization}
            alwaysOn
            type={OrganizationCombinedType.ProviderAndGroup}
            extraFilters={{
              [OrganizationListQueryParamsKey.active]: true
            }}
          />
        ) : undefined,
        <TextInput
          source={MedicationListQueryParamsKey.hisCode}
          label={t(tk.filters.hisCode)}
          alwaysOn
          resettable
        />,
        <EnumSelectorInput
          source={MedicationListQueryParamsKey.status}
          label={t(tk.filters.status)}
          enumValue={MedicationStatus}
          alwaysOn
          resettable
        />,
        <CodeSystemSelectorInput
          source={MedicationListQueryParamsKey.characteristic}
          label={t(tk.filters.characteristic)}
          codeSystem={characteristicSystem}
          organizationId={workspaceOrganizationId}
          withCreate
          resettable
          alwaysOn
        />,
        <EnumSelectorInput
          source={MedicationListQueryParamsKey.genericType}
          label={t(tk.filters.genericType)}
          enumValue={MedicationGenericType}
        />,
        <CodeSystemSelectorInput
          source={MedicationListQueryParamsKey.form}
          label={t(tk.filters.form)}
          codeSystem={formSystem}
          organizationId={workspaceOrganizationId}
          withCreate
          resettable
        />,
        <CodeSystemSelectorInput
          source={MedicationListQueryParamsKey.functionCode}
          label={t(tk.filters.functionCode)}
          codeSystem={functionCodeSystem}
          organizationId={workspaceOrganizationId}
          withCreate
          resettable
        />,
        <CodeSystemSelectorInput
          source={MedicationListQueryParamsKey.presentationUnit}
          label={t(tk.filters.presentationUnit)}
          codeSystem={presentationUnitSystem}
          organizationId={workspaceOrganizationId}
          withCreate
          resettable
        />,
        <CodeSystemSelectorInput
          source={MedicationListQueryParamsKey.route}
          label={t(tk.filters.route)}
          codeSystem={routeSystem}
          organizationId={workspaceOrganizationId}
          withCreate
          resettable
        />,
        <TextInput
          source={MedicationListQueryParamsKey.snomedCode}
          label={t(tk.filters.snomedCode)}
          resettable
        />,
        <ReferenceResourceInput<Medication>
          source={MedicationListQueryParamsKey.instanceOf}
          label={t(tk.filters.instanceOf)}
          resourceType={ResourceType.Medication}
          optionTextKey={medicationOptionText}
          extraFilters={{
            [MedicationListQueryParamsKey.organization]:
              workspaceOrganizationId,
            [MedicationListQueryParamsKey.genericType]:
              MedicationGenericType.Generic
          }}
        />,
        <ReferenceResourceInput<MedicationSubstance>
          source={MedicationListQueryParamsKey.substance}
          label={t(tk.filters.substance)}
          resourceType={ResourceType.MedicationSubstance}
          optionTextKey={medicationSubstanceOptionText}
          extraFilters={{
            [MedicationSubstanceListQueryParamsKey.organization]:
              workspaceOrganizationId
          }}
        />
      ]}
    >
      <Datagrid>
        <TextField source={MedicationKey.id} label={t(tk.fields.id)} />
        <TextField source={MedicationKey.name} label={t(tk.fields.name)} />
        <TextField
          source={MedicationKey.description}
          label={t(tk.fields.description)}
        />
        <ReferenceResourceField
          resourceType={ResourceType.Organization}
          label={t(tk.fields.organization)}
          source={MedicationKey.organization}
          optionTextKey={OrganizationKey.name}
        />
        <TextField source={MedicationKey.status} label={t(tk.fields.status)} />
        <FunctionField<Medication>
          label={t(tk.fields.identifierHisCode)}
          render={medication => {
            const identifierList = medication?.identifier ?? [];
            const hisCode = identifierList.find(
              i => i.system === KnownIdentifierSystem.HisCode
            )?.value;
            if (hisCode != null) return hisCode;
            return t(tk.fields.identifierNull);
          }}
        />
      </Datagrid>
    </List>
  );
};
