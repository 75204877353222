import {
  FlexAlignItems,
  FlexJustifyContent,
  StyleVariant,
  TextVariant,
  createStyle,
  flexRowMixin,
  pxToRem
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

export const useAccordionItemStyles = createStyle(
  (theme: Theme) => ({
    item: {
      ...themeTextMixin(theme, TextVariant.Body2),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    button: {
      ...flexRowMixin({
        justifyContent: FlexJustifyContent.FlexStart,
        alignItems: FlexAlignItems.Center
      }),
      height: pxToRem(40)
    }
  }),
  "AccordionItem"
);
