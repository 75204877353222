import { Quantity, QuantityUnit } from "model/primitives";
import { isNumberZeroOrUndefined, Optional } from "@laba/ts-common";
import { Medication, MedicationAmount } from "./medication";

const getReferenceByUnit = (
  targetUnit?: QuantityUnit,
  unitReferenceValues?: MedicationAmount[]
): Optional<MedicationAmount> =>
  unitReferenceValues?.find(
    healthcareUnit => healthcareUnit.unit === targetUnit
  );

export const isReferenceUnitDisabled = (
  targetUnit?: QuantityUnit,
  unitReferenceValues?: MedicationAmount[]
): boolean => {
  const reference = getReferenceByUnit(targetUnit, unitReferenceValues);
  return reference?.disabled ?? false;
};

export const getEquivalentUnitFromMedication = (
  targetUnit: QuantityUnit,
  value?: Quantity,
  unitReferenceValues?: MedicationAmount[]
): Quantity => {
  const valueQuantity = value?.quantity;
  if (targetUnit === value?.unit || valueQuantity === undefined) {
    return { unit: targetUnit, quantity: valueQuantity };
  }

  const sourceUnitConversionValue = getReferenceByUnit(
    value?.unit,
    unitReferenceValues
  )?.value;

  const targetUnitConversionValue = getReferenceByUnit(
    targetUnit,
    unitReferenceValues
  )?.value;

  if (
    isNumberZeroOrUndefined(sourceUnitConversionValue) ||
    isNumberZeroOrUndefined(targetUnitConversionValue)
  ) {
    return { unit: targetUnit, quantity: undefined };
  }

  const transformedValue =
    (valueQuantity * targetUnitConversionValue) / sourceUnitConversionValue;

  return {
    quantity: transformedValue,
    unit: targetUnit
  };
};

export const getNormalizedUnitFromMedication = (
  value?: Quantity,
  unitReferenceValues?: MedicationAmount[]
): Optional<number> => {
  const valueUnitReference = getReferenceByUnit(
    value?.unit,
    unitReferenceValues
  );
  return isNumberZeroOrUndefined(valueUnitReference?.value)
    ? undefined
    : Number(value?.quantity) / Number(valueUnitReference?.value);
};

export const convertNumberToUnitValueFromMedication = (
  value?: number,
  unit?: QuantityUnit,
  unitReferenceValues?: MedicationAmount[]
): Optional<number> => {
  const unitReference = getReferenceByUnit(unit, unitReferenceValues);
  return isNumberZeroOrUndefined(unitReference?.value)
    ? undefined
    : Number(unitReference?.value) * Number(value);
};

export const getMedicationMaxUnits = (
  medication?: Medication
): Optional<number> => {
  return medication?.rules?.maxUnits;
};

export const getMedicationMinUnits = (
  medication?: Medication
): Optional<number> => {
  return medication?.rules?.minUnits;
};
