import { FC, useI18n } from "@laba/react-common";
import React from "react";
import { ResourceType, ScheduleParamsKey } from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";

import { Typography } from "@mui/material";

export const tk = tkCP.adminPage[ResourceType.Schedule];

export const ScheduleWindowContent: FC = () => {
  const { t } = useI18n();

  return (
    <>
      <Typography>{t(tk.fields.confirmationWindowTitle)}</Typography>
      <TextInput
        source={ScheduleParamsKey.confirmationWindow.start}
        label={t(tk.fields.confirmationWindowStart)}
      />
      <TextInput
        source={ScheduleParamsKey.confirmationWindow.end}
        label={t(tk.fields.confirmationWindowEnd)}
      />
      <Typography>{t(tk.fields.cancellationWindowTitle)}</Typography>
      <TextInput
        source={ScheduleParamsKey.cancellationWindow.start}
        label={t(tk.fields.cancellationWindowStart)}
      />
      <TextInput
        source={ScheduleParamsKey.cancellationWindow.end}
        label={t(tk.fields.cancellationWindowEnd)}
      />
    </>
  );
};
