import React from "react";
import { getClassName, IconC, ReactElement } from "@laba/react-common";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { DrawerItemRow } from "components/drawer/Drawer/DrawerItemRow/DrawerItemRow";
import { IFrameButton } from "components/iframe/IFrameButton/IFrameButton";
import { FeedbackIcon } from "components/icons";
import { IconOrImg, IconOrImgType } from "components/helpers/IconOrImg";
import { NexupLogo } from "components/svg/NexupLogo";
import {
  useDrawerStyle,
  useSwipeableDrawerClasses
} from "components/drawer/Drawer/DrawerStyle";
import { Noop } from "@laba/ts-common";

export interface DrawerOptionRoute<R> {
  routeValue: R;
  routeText: string;
}

export interface DrawerOptionConfig<O, R> {
  value: O;
  Icon?: IconC;
  text: string;
  routes: DrawerOptionRoute<R>[];
}

export interface DrawerProps<O, R> {
  className?: string;
  drawerOptions: DrawerOptionConfig<O, R>[];
  onOptionSelected?: (option?: O) => void;
  onRouteSelected?: (optionValue: O, routeValue: R) => void;
  activeOption?: O;
  openOption?: O;
  activeRoute?: R;
  drawerOpen?: boolean;
  Logo?: IconOrImgType;
  onClose?: Noop;
  onOpen?: Noop;
  isMobile?: boolean;
  compareOption?: (o1?: O, o2?: O) => boolean;
  compareRoute?: (r1?: R, r2?: R) => boolean;
  onHover?: (hover: boolean) => void;
  feedbackButtonText?: string;
  feedbackHeaderTitle?: string;
  openFeedBack?: boolean;
  feedbackSource?: string;
  onClickFeedback?: Noop;
  showFeedbackButton?: boolean;
}

export const Drawer = <O, R>({
  className,
  onOptionSelected,
  onRouteSelected,
  activeOption,
  openOption,
  activeRoute,
  Logo = NexupLogo,
  onHover,
  openFeedBack,
  feedbackSource,
  onClickFeedback,
  feedbackButtonText,
  feedbackHeaderTitle,
  showFeedbackButton,
  onClose = () => {},
  onOpen = () => {},
  isMobile = false,
  drawerOptions = [],
  drawerOpen = false,
  compareOption = (o1?: O, o2?: O) => o1 === o2,
  compareRoute = (r1?: R, r2?: R) => r1 === r2
}: DrawerProps<O, R>): ReactElement => {
  const classes = useDrawerStyle({ drawerOpen });
  const swipeableDrawerClasses = useSwipeableDrawerClasses();
  const isActiveOption = (option: O): boolean => {
    return compareOption(option, activeOption);
  };

  return !isMobile ? (
    <nav
      onMouseOver={() => onHover?.(true)}
      onFocus={() => onHover?.(true)}
      onMouseLeave={() => onHover?.(false)}
      className={getClassName(classes.root, className)}
    >
      {drawerOptions.map((o, index) => {
        return (
          o.routes.length > 0 && (
            <DrawerItemRow
              key={
                // eslint-disable-next-line react/no-array-index-key
                index
              }
              drawerOpen={drawerOpen}
              optionOpened={compareOption(o.value, openOption)}
              activeOption={isActiveOption(o.value)}
              selectedRoute={activeRoute}
              optionConfig={o}
              onOptionClicked={onOptionSelected}
              onRouteClicked={onRouteSelected}
              onOpen={onOpen}
              compareRoute={compareRoute}
            />
          )
        );
      })}
      {drawerOpen && showFeedbackButton && (
        <IFrameButton
          className={classes.feedbackButton}
          open={openFeedBack}
          StartIcon={FeedbackIcon}
          onClick={onClickFeedback}
          buttonText={feedbackButtonText}
          headerTitle={feedbackHeaderTitle}
          source={feedbackSource}
          allowFullScreen
          frameBorder={false}
          isMobile={isMobile}
        />
      )}
    </nav>
  ) : (
    <SwipeableDrawer
      classes={swipeableDrawerClasses}
      className={className}
      open={drawerOpen}
      onClose={onClose}
      onOpen={onOpen}
    >
      <div className={classes.mobileContent}>
        <IconOrImg alt="" Logo={Logo} className={classes.logo} />
        <nav className={classes.mobileNav}>
          {drawerOptions.map(o => {
            return (
              o.routes.length > 0 && (
                <DrawerItemRow
                  key={`${o.value}`}
                  drawerOpen={drawerOpen}
                  optionOpened={o.value === openOption}
                  activeOption={isActiveOption(o.value)}
                  selectedRoute={activeRoute}
                  optionConfig={o}
                  onOptionClicked={onOptionSelected}
                  onRouteClicked={onRouteSelected}
                  onClose={onClose}
                  compareRoute={compareRoute}
                />
              )
            );
          })}
        </nav>
        {drawerOpen && showFeedbackButton && (
          <IFrameButton
            className={classes.feedbackButton}
            open={openFeedBack}
            StartIcon={FeedbackIcon}
            onClick={onClickFeedback}
            buttonText={feedbackButtonText}
            headerTitle={feedbackHeaderTitle}
            source={feedbackSource}
            allowFullScreen
            frameBorder={false}
            isMobile={isMobile}
          />
        )}
      </div>
    </SwipeableDrawer>
  );
};
