import { getKeyObj, isObject, isSomeEnum } from "@laba/ts-common";
import { Quantity } from "model/primitives/quantity";
import { createHydratedMock } from "ts-auto-mock";
import {
  PatientMeasure,
  QuestionnaireEnableWhenBehavior,
  QuestionnaireItemEnableWhen,
  QuestionnaireResponseAnswer
} from "model/resource";
import { isEmpty } from "lodash-es";

export enum QuestionnaireFieldSize {
  None = "0",
  Whole = "1",
  Half = "1/2",
  Third = "1/3",
  Quarter = "1/4"
}

export enum QuestionnaireFieldPropertyType {
  CreateProblem = "CreateProblem",
  FileType = "FileType",
  HospitalizationUse = "HospitalizationUse",
  HospitalizationUseAction = "HospitalizationUseAction",
  ItemLinkId = "ItemLinkId",
  Label = "Label",
  MaxDate = "MaxDate",
  MaxElements = "MaxElements",
  MaxFileSize = "MaxFileSize",
  MaxValue = "MaxValue",
  MinDate = "MinDate",
  MinElements = "MinElements",
  MinValue = "MinValue",
  NumLines = "NumLines",
  Option = "Option",
  Placeholder = "Placeholder",
  Precision = "Precision",
  RelateProblem = "RelateProblem",
  RelateSolvedProblem = "RelateSolvedProblem",
  Score = "Score",
  ScoreColor = "ScoreColor",
  ScoreRangeMax = "ScoreRangeMax",
  SingleLine = "SingleLine",
  SolveProblem = "SolveProblem",
  Step = "Step",
  Text = "Text",
  Unit = "Unit",
  Type = "Type",
  ConditionSystem = "ConditionSystem",
  Class = "Class",
  Status = "Status",
  TimeWindow = "TimeWindow",
  ResponseEncounter = "ResponseEncounter",
  CurrentPractitioner = "CurrentPractitioner",
  OptionSystem = "OptionSystem",
  UnitSystem = "UnitSystem",
  Definition = "Definition",
  Category = "Category",
  HideField = "HideField",
  StatusReason = "StatusReason",
  RecommendedTag = "RecommendedTag",
  FilterTag = "FilterTag",
  Characteristic = "Characteristic",
  GenericType = "GenericType",
  SubCategory = "SubCategory",
  Tag = "Tag",
  CreateOption = "CreateOption",
  CreateOptionPermission = "CreateOptionPermission",
  CreateOptionPropertyUse = "CreateOptionPropertyUse",
  SelectedOptionType = "SelectedOptionType",
  IsEffectiveDate = "IsEffectiveDate"
}

export enum QuestionnaireFieldType {
  AllergyList = "AllergyList",
  Checkbox = "Checkbox",
  Date = "Date",
  Datetime = "Datetime",
  FileList = "FileList",
  Measurement = "Measurement",
  Numeric = "Numeric",
  ProblemList = "ProblemList",
  ScaleScore = "ScaleScore",
  SearchableList = "SearchableList",
  Selector = "Selector",
  TextField = "TextField",
  Whitespace = "Whitespace",
  DisplayText = "DisplayText",
  CategoryResult = "CategoryResult",
  EncounterSelector = "EncounterSelector",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  ProcedureReport = "ProcedureReport",
  DeviceList = "DeviceList",
  MedicationList = "MedicationList",
  Consumption = "Consumption",
  Odontogram = "Odontogram"
}

export enum ProcedureQuestionnaireHideFieldPropertyValue {
  Status = "Status",
  Category = "Category",
  Location = "Location",
  Performer = "Performer",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Attachment = "Attachment",
  Report = "Report",
  Observation = "Observation",
  Measurement = "Measurement",
  CreateProcedureReport = "CreateProcedureReport"
}

export enum ConsumptionQuestionnaireHideFieldPropertyValue {
  Payer = "Payer",
  Payment = "Payment",
  PatientPaymentStatus = "PatientPaymentStatus",
  PatientDebt = "PatientDebt",
  PatientPaymentMethod = "PatientPaymentMethod"
}

export enum QuestionnaireFieldValuePropertyType {
  ProblemType = "ProblemType",
  AllergyType = "AllergyType",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Quantity = "Quantity",
  Text = "Text"
}

export enum ProblemListResponsePropertyValue {
  CreatedProblem = "CreatedProblem",
  SolvedProblem = "SolvedProblem",
  RelatedProblem = "RelatedProblem",
  RelatedSolvedProblem = "RelatedSolvedProblem"
}

export enum AllergyListResponsePropertyValue {
  // Allergies which were created in this evolution
  AllergyCreated = "AllergyCreated",
  // Allergies which were linked to this evolution
  AllergyRelated = "AllergyRelated",
  // Allergies which were solved in this evolution
  AllergySolved = "AllergySolved"
}

export enum SearchableListSelectedOptionTypeResponsePropertyValue {
  SimpleOption = "SimpleOption",
  TextBoxOption = "TextBoxOption"
}

export enum QuestionnaireFieldValuePropertyHospitalizationUseAction {
  Append = "append",
  Replace = "replace"
}

export interface AllergyListResponseProperty {
  type?: QuestionnaireFieldValuePropertyType.AllergyType;
  valueString?: AllergyListResponsePropertyValue;
}

/** Each field in a questionnaire response has an "answer", an array of objects
 *  Each of these objects has certain values. It can have a "property" array.
 *  This array can only have objects with certain type-value combinations.
 */

export const isAllergyListResponseProperty = (
  p: unknown
): p is AllergyListResponseProperty => {
  if (isObject(p)) {
    const castP = p as AllergyListResponseProperty;
    const isValidType =
      castP.type === QuestionnaireFieldValuePropertyType.AllergyType;
    const isValidValue =
      castP.valueString === undefined ||
      isSomeEnum(AllergyListResponsePropertyValue)(castP.valueString);
    return isValidType && isValidValue;
  }
  return false;
};

interface ProblemListResponseProperty {
  type?: QuestionnaireFieldValuePropertyType.ProblemType;
  valueString?: ProblemListResponsePropertyValue;
}

interface QuantityResponseProperty {
  type?: QuestionnaireFieldValuePropertyType.Quantity;
  valueQuantity?: Quantity;
}

interface SearchableListResponseProperty {
  type?: QuestionnaireFieldValuePropertyType.Text;
  valueString?: string;
}

export type QuestionnaireFieldValueProperty =
  | ProblemListResponseProperty
  | AllergyListResponseProperty
  | QuantityResponseProperty
  | SearchableListResponseProperty;

const isProblemListResponsePropertyEmpty = (
  p: QuestionnaireFieldValueProperty
): boolean => {
  return p.type === QuestionnaireFieldValuePropertyType.ProblemType
    ? isEmpty(p.valueString)
    : false;
};

const isAllergyListResponsePropertyEmpty = (
  p: QuestionnaireFieldValueProperty
): boolean => {
  return p.type === QuestionnaireFieldValuePropertyType.AllergyType
    ? isEmpty(p.valueString)
    : false;
};

const isQuantityResponsePropertyEmpty = (
  p: QuestionnaireFieldValueProperty
): boolean => {
  return p.type === QuestionnaireFieldValuePropertyType.Quantity
    ? isEmpty(p.valueQuantity)
    : false;
};

const isSearchableListResponsePropertyEmpty = (
  p: QuestionnaireFieldValueProperty
): boolean => {
  return p.type === QuestionnaireFieldValuePropertyType.Text
    ? isEmpty(p.valueString)
    : false;
};

export const isQuestionnaireFieldValuePropertyEmpty = (
  p: QuestionnaireFieldValueProperty
): boolean => {
  return (
    isProblemListResponsePropertyEmpty(p) ||
    isAllergyListResponsePropertyEmpty(p) ||
    isQuantityResponsePropertyEmpty(p) ||
    isSearchableListResponsePropertyEmpty(p)
  );
};

export interface QuestionnaireFieldValue extends QuestionnaireResponseAnswer {
  property: QuestionnaireFieldValueProperty[];
}

export const QuestionnaireFieldValueKey = getKeyObj<QuestionnaireFieldValue>(
  createHydratedMock<QuestionnaireFieldValue>()
);

export const QuestionnaireFieldValuePropertyQuantityKey =
  getKeyObj<QuantityResponseProperty>(
    createHydratedMock<QuantityResponseProperty>()
  );

export const QuestionnaireFieldValuePropertySearchableListKey =
  getKeyObj<SearchableListResponseProperty>(
    createHydratedMock<SearchableListResponseProperty>()
  );

export const PatientMeasureValueKey = getKeyObj<PatientMeasure>(
  createHydratedMock<PatientMeasure>()
);

export interface QuestionnaireFieldPropertyBase<
  T extends QuestionnaireFieldPropertyType
> {
  type: T;
  value?: string;
}

export const QuestionnaireFieldPropertyKey = getKeyObj<
  QuestionnaireFieldPropertyBase<QuestionnaireFieldPropertyType>
>(
  createHydratedMock<
    QuestionnaireFieldPropertyBase<QuestionnaireFieldPropertyType>
  >()
);

export interface QuestionnaireFieldBase<
  T extends QuestionnaireFieldType,
  P extends QuestionnaireFieldPropertyType = QuestionnaireFieldPropertyType
> {
  title?: string;
  linkId: string;
  initialValue: QuestionnaireFieldValue[];
  fieldType: T;
  size?: QuestionnaireFieldSize;
  detailSize?: QuestionnaireFieldSize;
  reportSize?: QuestionnaireFieldSize;
  property: QuestionnaireFieldPropertyBase<P>[];
  required?: boolean;
  enableWhen?: QuestionnaireItemEnableWhen[];
  enableBehavior?: QuestionnaireEnableWhenBehavior;
}

export const QuestionnaireFieldKey = getKeyObj<
  QuestionnaireFieldBase<QuestionnaireFieldType>
>(createHydratedMock<QuestionnaireFieldBase<QuestionnaireFieldType>>());

export type AllergyListField =
  QuestionnaireFieldBase<QuestionnaireFieldType.AllergyList>;

type CheckboxFieldPropertyType = QuestionnaireFieldPropertyType.Label;

export type CheckboxField = QuestionnaireFieldBase<
  QuestionnaireFieldType.Checkbox,
  CheckboxFieldPropertyType
>;

type DateFieldPropertyType =
  | QuestionnaireFieldPropertyType.MinDate
  | QuestionnaireFieldPropertyType.MaxDate
  | QuestionnaireFieldPropertyType.Placeholder
  | QuestionnaireFieldPropertyType.IsEffectiveDate;

export type DateField = QuestionnaireFieldBase<
  QuestionnaireFieldType.Date,
  DateFieldPropertyType
>;

type DateTimeFieldPropertyType =
  | QuestionnaireFieldPropertyType.MinDate
  | QuestionnaireFieldPropertyType.MaxDate
  | QuestionnaireFieldPropertyType.Placeholder
  | QuestionnaireFieldPropertyType.IsEffectiveDate;

export type DateTimeField = QuestionnaireFieldBase<
  QuestionnaireFieldType.Datetime,
  DateTimeFieldPropertyType
>;

type FileListFieldPropertyType =
  | QuestionnaireFieldPropertyType.MinElements
  | QuestionnaireFieldPropertyType.MaxElements
  | QuestionnaireFieldPropertyType.FileType
  | QuestionnaireFieldPropertyType.MaxFileSize;

export type FileListField = QuestionnaireFieldBase<
  QuestionnaireFieldType.FileList,
  FileListFieldPropertyType
>;

type MeasurementFieldPropertyType =
  | QuestionnaireFieldPropertyType.Unit
  | QuestionnaireFieldPropertyType.MinValue
  | QuestionnaireFieldPropertyType.MaxValue
  | QuestionnaireFieldPropertyType.Step
  | QuestionnaireFieldPropertyType.Type;

export type MeasurementField = QuestionnaireFieldBase<
  QuestionnaireFieldType.Measurement,
  MeasurementFieldPropertyType
>;

type NumericFieldPropertyType =
  | QuestionnaireFieldPropertyType.Unit
  | QuestionnaireFieldPropertyType.MinValue
  | QuestionnaireFieldPropertyType.MaxValue
  | QuestionnaireFieldPropertyType.Step
  | QuestionnaireFieldPropertyType.Placeholder;

export type NumericField = QuestionnaireFieldBase<
  QuestionnaireFieldType.Numeric,
  NumericFieldPropertyType
>;

type ProblemListFieldPropertyType =
  | QuestionnaireFieldPropertyType.CreateProblem
  | QuestionnaireFieldPropertyType.HospitalizationUse
  | QuestionnaireFieldPropertyType.HospitalizationUseAction
  | QuestionnaireFieldPropertyType.MaxElements
  | QuestionnaireFieldPropertyType.MinElements
  | QuestionnaireFieldPropertyType.RelateProblem
  | QuestionnaireFieldPropertyType.RelateSolvedProblem
  | QuestionnaireFieldPropertyType.SolveProblem
  | QuestionnaireFieldPropertyType.ConditionSystem;

export type ProblemListField = QuestionnaireFieldBase<
  QuestionnaireFieldType.ProblemList,
  ProblemListFieldPropertyType
>;

type ScaleScoreFieldPropertyType =
  | QuestionnaireFieldPropertyType.Score
  | QuestionnaireFieldPropertyType.ScoreColor
  | QuestionnaireFieldPropertyType.Placeholder;

export type ScaleScoreField = QuestionnaireFieldBase<
  QuestionnaireFieldType.ScaleScore,
  ScaleScoreFieldPropertyType
>;

type SearchableListFieldPropertyType =
  | QuestionnaireFieldPropertyType.Option
  | QuestionnaireFieldPropertyType.MinElements
  | QuestionnaireFieldPropertyType.MaxElements
  | QuestionnaireFieldPropertyType.CreateOption
  | QuestionnaireFieldPropertyType.CreateOptionPermission
  | QuestionnaireFieldPropertyType.CreateOptionPropertyUse
  | QuestionnaireFieldPropertyType.SelectedOptionType;

export type SearchableListField = QuestionnaireFieldBase<
  QuestionnaireFieldType.SearchableList,
  SearchableListFieldPropertyType
>;

type SelectorFieldPropertyType =
  | QuestionnaireFieldPropertyType.Option
  | QuestionnaireFieldPropertyType.Placeholder;

export type SelectorField = QuestionnaireFieldBase<
  QuestionnaireFieldType.Selector,
  SelectorFieldPropertyType
>;

type TextFieldFieldPropertyType =
  | QuestionnaireFieldPropertyType.SingleLine
  | QuestionnaireFieldPropertyType.Placeholder;

export type TextFieldField = QuestionnaireFieldBase<
  QuestionnaireFieldType.TextField,
  TextFieldFieldPropertyType
>;

type WhitespaceFieldPropertyType = QuestionnaireFieldPropertyType.NumLines;

export type WhitespaceField = QuestionnaireFieldBase<
  QuestionnaireFieldType.Whitespace,
  WhitespaceFieldPropertyType
>;

type DisplayTextFieldPropertyType = QuestionnaireFieldPropertyType.Text;

export type DisplayTextField = QuestionnaireFieldBase<
  QuestionnaireFieldType.DisplayText,
  DisplayTextFieldPropertyType
>;

type CategoryResultFieldPropertyType =
  | QuestionnaireFieldPropertyType.Precision
  | QuestionnaireFieldPropertyType.ItemLinkId
  | QuestionnaireFieldPropertyType.ScoreRangeMax
  | QuestionnaireFieldPropertyType.ScoreColor;

export type CategoryResultField = QuestionnaireFieldBase<
  QuestionnaireFieldType.CategoryResult,
  CategoryResultFieldPropertyType
>;

export type EncounterSelectorField = QuestionnaireFieldBase<
  QuestionnaireFieldType.EncounterSelector,
  | QuestionnaireFieldPropertyType.Class
  | QuestionnaireFieldPropertyType.Status
  | QuestionnaireFieldPropertyType.TimeWindow
  | QuestionnaireFieldPropertyType.ResponseEncounter
  | QuestionnaireFieldPropertyType.CurrentPractitioner
>;

export type ProcedureReportField = QuestionnaireFieldBase<
  QuestionnaireFieldType.ProcedureReport,
  | QuestionnaireFieldPropertyType.Definition
  | QuestionnaireFieldPropertyType.Category
  | QuestionnaireFieldPropertyType.HideField
  | QuestionnaireFieldPropertyType.TimeWindow
  | QuestionnaireFieldPropertyType.StatusReason
>;

export type DeviceListField = QuestionnaireFieldBase<
  QuestionnaireFieldType.DeviceList,
  | QuestionnaireFieldPropertyType.MinElements
  | QuestionnaireFieldPropertyType.MaxElements
  | QuestionnaireFieldPropertyType.RecommendedTag
  | QuestionnaireFieldPropertyType.FilterTag
>;

export type MedicationListField = QuestionnaireFieldBase<
  QuestionnaireFieldType.MedicationList,
  | QuestionnaireFieldPropertyType.MinElements
  | QuestionnaireFieldPropertyType.MaxElements
  | QuestionnaireFieldPropertyType.Unit
  | QuestionnaireFieldPropertyType.UnitSystem
  | QuestionnaireFieldPropertyType.Characteristic
  | QuestionnaireFieldPropertyType.GenericType
>;

export type ConsumptionField = QuestionnaireFieldBase<
  QuestionnaireFieldType.Consumption,
  | QuestionnaireFieldPropertyType.MinElements
  | QuestionnaireFieldPropertyType.MaxElements
  | QuestionnaireFieldPropertyType.TimeWindow
  | QuestionnaireFieldPropertyType.Category
  | QuestionnaireFieldPropertyType.SubCategory
  | QuestionnaireFieldPropertyType.Tag
  | QuestionnaireFieldPropertyType.HideField
>;

export type OdontogramField =
  QuestionnaireFieldBase<QuestionnaireFieldType.Odontogram>;

export type QuestionnaireField =
  | AllergyListField
  | CheckboxField
  | DateField
  | DateTimeField
  | FileListField
  | MeasurementField
  | NumericField
  | ProblemListField
  | ScaleScoreField
  | SearchableListField
  | SelectorField
  | TextFieldField
  | WhitespaceField
  | DisplayTextField
  | CategoryResultField
  | EncounterSelectorField
  | ProcedureReportField
  | DeviceListField
  | MedicationListField
  | ConsumptionField
  | OdontogramField;

export enum QuestionnaireDateSpecificValues {
  CurrentDate = "CurrentDate",
  CreationDate = "CreationDate"
}
