import { createStyle, pxToRem, Theme } from "@laba/react-common";

export const useMapStyles = createStyle(
  (_theme: Theme) => ({
    root: {
      position: "relative",
      height: "100%",
      width: "100%",
      overflow: "hidden"
    },
    searchControl: {
      position: "absolute",
      top: pxToRem(24),
      left: "50%",
      transform: "translate(-50%, 0)"
    }
  }),
  "Map"
);
