import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import {
  Condition,
  ConditionCategory,
  ConditionClinical
} from "model/resource/medical/condition/condition";
import { ModelId } from "model/primitives/model/model";
import { ElementOrArray, HttpMethod } from "@laba/ts-common";
import { getFrontApiUrl, request } from "request/nexupRequest";

export enum ConditionOrder {
  CreationDate = "creationDate",
  UpdateDate = "updateDate"
}

export interface ConditionQueryParams {
  withResolver?: boolean;
}

export interface ConditionListQueryParams
  extends ListQueryParams,
    ConditionQueryParams {
  category?: ConditionCategory;
  clinicalStatus?: ConditionClinical;
  code?: string;
  content?: string;
  exclude?: ElementOrArray<ModelId>;
  hospitalization?: ElementOrArray<ModelId>;
  order: ConditionOrder;
  organization?: ElementOrArray<ModelId>;
  patient?: ElementOrArray<ModelId>;
  practitioner?: ElementOrArray<ModelId>;
  system?: string;
}

const conditionBasePath = "/condition";

export const getConditionList = <T extends Condition = Condition>(
  params: ConditionListQueryParams
): Promise<ApiPageRequestResponse<T>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(conditionBasePath),
    params
  });

export const getCondition = <T extends Condition = Condition>(
  id: ModelId,
  params: ConditionQueryParams
): Promise<ApiRequestResponse<T>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`/condition/${id}`),
    params
  });

export const updateCondition = <T extends Condition = Condition>(
  data: T
): Promise<ApiRequestResponse<T>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`/condition/${data.id}`),
    data
  });

export const createCondition = <T extends Condition = Condition>(
  data: T
): Promise<ApiRequestResponse<T>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(`/condition`),
    data
  });
