import {
  DropzoneInputProps,
  DropzoneRootProps,
  FileError,
  useDropzone
} from "react-dropzone";
import { isSomeEnum } from "@laba/ts-common";

export type ErrorToStringMapper = (kfe: KnownFileError) => string;

export enum KnownFileError {
  FileTooLarge = "file-too-large",
  FileTooSmall = "file-too-small",
  TooManyFiles = "too-many-files",
  InvalidType = "file-invalid-type"
}

export const getErrorMessagesFromErrors =
  (errMapper?: ErrorToStringMapper) =>
  (errors: FileError[]): string =>
    errors
      .map(({ code, message }) => {
        if (isSomeEnum(KnownFileError)(code)) return errMapper?.(code);
        return message;
      })
      .join(". ");

interface UseInputDropzone {
  disabled?: boolean;
  uploadFiles?: (fileList: File[]) => void;
  onDropError?: (errors: string[]) => void;
  acceptedFileExtensions?: string[];
  maxFiles?: number;
  maxFileSize?: number;
  errorMessageMapper?: ErrorToStringMapper;
}

interface UseInputDropzoneReturnData {
  getRootProps: <T extends DropzoneRootProps>(props?: T) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
  isDragReject: boolean;
}

export const useInputDropzone = ({
  disabled,
  uploadFiles,
  onDropError,
  acceptedFileExtensions,
  maxFiles,
  maxFileSize,
  errorMessageMapper
}: UseInputDropzone): UseInputDropzoneReturnData => {
  const { getRootProps, getInputProps, isDragReject } = useDropzone({
    disabled,
    onDrop: uploadFiles,
    accept: acceptedFileExtensions,
    maxFiles,
    maxSize: maxFileSize, // in bytes
    onDropRejected: rejections => {
      const tooManyFiles = rejections.some(r =>
        r.errors.some(e => e.code === KnownFileError.TooManyFiles)
      );
      if (tooManyFiles)
        return onDropError?.([
          errorMessageMapper?.(KnownFileError.TooManyFiles) ?? ""
        ]);
      const errorMessages = rejections.map(
        r =>
          `${r.file.name}: ${getErrorMessagesFromErrors(errorMessageMapper)(
            r.errors
          )}`
      );
      onDropError?.(errorMessages);
    }
  });

  return {
    getRootProps,
    getInputProps,
    isDragReject
  };
};
