import React from "react";
import { FC } from "@laba/react-common";
import {
  MedicalRequestDefinitionForm,
  medicalRequestDefinitionJsonToModel,
  medicalRequestDefinitionTransform
} from "components/pages/NexupAdmin/resources/medicalRequestDefinition/MedicalRequestDefinitionForm";
import { Edit } from "components/generic/Edit/Edit";
import { MedicalRequestDefinition, ResourceType } from "@laba/nexup-api";
import { useElementExportedFileName } from "components/hook/useExportedFileName";

export const MedicalRequestDefinitionEdit: FC = () => {
  const exportedFileName = useElementExportedFileName<MedicalRequestDefinition>(
    resource => resource.name
  );
  return (
    <Edit
      resourceTransform={medicalRequestDefinitionTransform}
      resource={ResourceType.MedicalRequestDefinition}
      jsonToModel={medicalRequestDefinitionJsonToModel}
      exportedFileName={exportedFileName}
    >
      <MedicalRequestDefinitionForm />
    </Edit>
  );
};
