import React from "react";
import { FC } from "@laba/react-common";
import { Edit } from "components/generic/Edit/Edit";
import {
  HealthcareServiceForm,
  healthcareServiceJsonToModel,
  healthcareServiceTransform
} from "components/pages/NexupAdmin/resources/healthcareService/HealthcareServiceForm";
import { HealthcareService, ResourceType } from "@laba/nexup-api";
import { useElementExportedFileName } from "components/hook/useExportedFileName";

export const HealthcareServiceEdit: FC = () => {
  const exportedFileName = useElementExportedFileName<HealthcareService>(
    resource => resource.name
  );
  return (
    <Edit
      resourceTransform={healthcareServiceTransform}
      resource={ResourceType.HealthcareService}
      jsonToModel={healthcareServiceJsonToModel}
      exportedFileName={exportedFileName}
    >
      <HealthcareServiceForm />
    </Edit>
  );
};
