import { ProcedureReportDefinition } from "model/resource/procedures/procedureReportDefinition/procedureReportDefinition";
import { ResourceType } from "model/primitives/resourceModel";
import { ActivityDefinitionConfigurationCodeSystemCode } from "model/resource/activity/activityDefinition";

export const procedureReportDefinitionMock: ProcedureReportDefinition = {
  resourceType: ResourceType.ProcedureReportDefinition,
  id: "999",
  organization: "10",
  name: "procedure report mock",
  configurationCodeSystem: [
    {
      code: ActivityDefinitionConfigurationCodeSystemCode.ProcedureCode,
      url: "procedureCodeSystemMock"
    },
    {
      code: ActivityDefinitionConfigurationCodeSystemCode.StatusReason,
      url: "statusReasonSystemMock"
    },
    {
      code: ActivityDefinitionConfigurationCodeSystemCode.Category,
      url: "categorySystemMock"
    },
    {
      code: ActivityDefinitionConfigurationCodeSystemCode.PerformerRole,
      url: "performerRoleCodeSystemMock"
    }
  ],
  measurementGroupDefinition: "161"
};
