import React, { ChangeEventHandler } from "react";
import { ReactElement } from "@laba/react-common";
import { ThemeProvider } from "@material-ui/core/styles";
import { StyleVariant } from "model/themeVariant";
import { useMuiTheme } from "model/useMuiTheme";
import { Radio as MuiRadio } from "@material-ui/core";
import { useRadioStyle } from "./RadioStyle";

export interface RadioProps<V> {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  id?: string;
  onChange?: ChangeEventHandler;
  required?: boolean;
  value?: V;
}

export const Radio = <V,>({
  className,
  id,
  onChange,
  value,
  checked = false,
  disabled = false,
  required = false
}: RadioProps<V>): ReactElement => {
  const theme = useMuiTheme(StyleVariant.Primary);
  const radioClasses = useRadioStyle();
  return (
    <ThemeProvider theme={theme}>
      <MuiRadio
        checked={checked}
        classes={radioClasses}
        className={className}
        color="primary"
        disabled={disabled}
        id={id}
        onChange={onChange}
        required={required}
        value={value}
      />
    </ThemeProvider>
  );
};
