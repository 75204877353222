import React from "react";
import { FCC, ReactElementOrNull } from "@laba/react-common";
import {
  bindPopover,
  bindTrigger,
  usePopupState
} from "material-ui-popup-state/hooks";
import { Noop } from "@laba/ts-common";
import { Popover as MaterialPopover, PopoverPosition } from "@material-ui/core";
import { isNil } from "lodash-es";

export enum PopoverOriginVerticalVariant {
  Top = "top",
  Center = "center",
  Bottom = "bottom"
}

export enum PopoverOriginHorizontalVariant {
  Left = "left",
  Center = "center",
  Right = "right"
}

interface PopoverOrigin {
  vertical: PopoverOriginVerticalVariant | number;
  horizontal: PopoverOriginHorizontalVariant | number;
}

export interface PopoverBind {
  id: string | undefined;
  anchorEl: HTMLElement | undefined;
  open: boolean;
  onClose: Noop;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  onMouseLeave: (event: React.SyntheticEvent<any, Event>) => void;
  disableAutoFocus?: boolean | undefined;
  disableEnforceFocus?: boolean | undefined;
  disableRestoreFocus?: boolean | undefined;
}

export interface PopoverProps {
  className?: string;
  popoverClassName?: string;
  PopoverComponent: ReactElementOrNull;
  anchorOrigin?: PopoverOrigin;
  anchorPosition?: PopoverPosition;
  transformOrigin?: PopoverOrigin;
  externalPopoverBinder?: PopoverBind;
  ignorePopoverState?: boolean;
}

export const Popover: FCC<PopoverProps> = ({
  className,
  popoverClassName,
  PopoverComponent,
  children,
  externalPopoverBinder,
  ignorePopoverState,
  anchorOrigin = {
    vertical: PopoverOriginVerticalVariant.Bottom,
    horizontal: PopoverOriginHorizontalVariant.Left
  },
  transformOrigin = {
    vertical: PopoverOriginVerticalVariant.Top,
    horizontal: PopoverOriginHorizontalVariant.Left
  }
}) => {
  const popoverState = usePopupState({
    variant: "popover",
    popupId: "nexupPopover"
  });

  const popoverBind = externalPopoverBinder ?? bindPopover(popoverState);

  return (
    <div
      {...(ignorePopoverState ? {} : bindTrigger(popoverState))}
      className={className}
    >
      {children}
      {isNil(PopoverComponent) ? null : (
        <MaterialPopover
          {...popoverBind}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          className={popoverClassName}
        >
          {PopoverComponent}
        </MaterialPopover>
      )}
    </div>
  );
};
