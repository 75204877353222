import { ApiDate } from "@laba/ts-common";
import { ModelId } from "model/primitives/model/model";
import { Quantity } from "model/primitives/quantity";
import { Patient } from "model/resource/person/patient/patient";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { ResourceModel, ResourceType } from "model/primitives/resourceModel";
import { Practitioner } from "model/resource/person/practitioner/practitioner";
import { Frequency } from "model/resource/prescription/medicalRequest/medicalRequestFrequency";
import { Hospitalization } from "model/resource/medical/hospitalization/hospitalization";
import { Medication } from "model/resource/prescription/medication/medication";
import { MedicalDevice } from "model/resource/prescription/medicalDevice";
import { MedicalRequest } from "model/resource/prescription/medicalRequest/medicalRequest";

export enum MedicalAdministrationStatus {
  InProgress = "InProgress",
  NotDone = "NotDone",
  Completed = "Completed",
  Stopped = "Stopped",
  EnteredInError = "EnteredInError"
}

export enum MedicalAdministrationStatusReason {
  Applied = "Applied",
  Discarded = "Discarded",
  Returned = "Returned",
  Cancelled = "Cancelled"
}

interface MedicalAdministrationMedicalRequestSummary {
  mainText?: string;
  supplementaryText?: string;
  reportText?: string;
  frequency?: Frequency;
  versionId?: ModelId;
}

export interface MedicalAdministrationDevice {
  device: ModelReference<MedicalDevice>;
  quantity: Quantity;
}

export interface MedicalAdministration extends ResourceModel<ResourceType> {
  patient: ModelReference<Patient>;
  hospitalization?: ModelReference<Hospitalization>;
  practitioner: ModelReference<Practitioner>;
  editPractitioner?: ModelReference<Practitioner>;
  administrationStartDate: ApiDate;
  administrationEndDate: ApiDate;
  closeDate?: ApiDate;
  lastEditDate?: ApiDate;
  status?: MedicalAdministrationStatus;
  statusReason: MedicalAdministrationStatusReason;
  medication?: ModelReference<Medication>;
  medicalRequest?: ModelReference<MedicalRequest>;
  medicalRequestSummary: MedicalAdministrationMedicalRequestSummary;
  observation?: string;
  deviceList: MedicalAdministrationDevice[];
  dose?: Quantity;
  doseCondition?: string;
}
