import { getKeyObj, isObject, Optional } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { PrescriptionRequest } from "model/resource/prescription/prescriptionRequest";
import { MedicalRequestDefinition } from "model/resource/prescription/medicalRequestDefinition/medicalRequestDefinition";
import { getModelOrUndefined } from "model/primitives/modelReference/utils";
import { MedicalAdministration } from "./medicalAdministration";
import { MedicalRequest } from "./medicalRequest";

export interface MedicalRequestWithExtraData {
  medicalRequest: MedicalRequest;
  prescriptionRequestList?: PrescriptionRequest[];
  medicalAdministrationList?: MedicalAdministration[];
}

export const MedicalRequestWithExtraDataFieldValueKey =
  getKeyObj<MedicalRequestWithExtraData>(
    createHydratedMock<MedicalRequestWithExtraData>()
  );

export const isMedicalRequestWithExtraData = (
  value: unknown
): value is MedicalRequestWithExtraData => {
  return (
    isObject(value) &&
    MedicalRequestWithExtraDataFieldValueKey.medicalRequest.thisKey in value
  );
};

export const getMedicalRequestWithExtraDataDefinitionModelOrUndefined = (
  mrd?: MedicalRequestWithExtraData
): Optional<MedicalRequestDefinition> => {
  return getModelOrUndefined(mrd?.medicalRequest.definition);
};
