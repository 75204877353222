import React, {
  FC,
  getClassName,
  IconC,
  StyleVariant
} from "@laba/react-common";
import {
  DialogVariant,
  useDialogHeaderStyles
} from "components/popup/Dialog/DialogHeader/DialogHeaderStyle";

interface DialogHeaderProps {
  className?: string;
  textTitle?: string;
  textSubtitle?: string | React.ReactElement;
  imageSource?: string;
  ImageSvg?: IconC;
  IconTitle?: IconC;
  isMobile?: boolean;
  iconStyle?: StyleVariant;
  dialogVariant: DialogVariant;
}

export const DialogHeader: FC<DialogHeaderProps> = ({
  className,
  textTitle,
  textSubtitle,
  imageSource,
  ImageSvg,
  IconTitle,
  isMobile,
  iconStyle,
  dialogVariant
}) => {
  const classes = useDialogHeaderStyles({
    iconStyle,
    dialogVariant,
    isMobile
  });

  const hasImageUrl = Boolean(imageSource);
  const hasImageSvg = Boolean(ImageSvg) && !hasImageUrl;
  const hasImage = hasImageSvg || hasImageUrl;
  const hasIcon = Boolean(IconTitle) && !hasImage;

  return (
    <div className={getClassName(classes.content, className)}>
      <div className={classes.titleContainer}>
        {hasImageUrl && (
          <img src={imageSource} alt={imageSource} className={classes.img} />
        )}
        {hasImageSvg && ImageSvg && <ImageSvg className={classes.imageSvg} />}
        {hasIcon && IconTitle && <IconTitle className={classes.icon} />}
        <p className={classes.textTitle}>{textTitle}</p>
      </div>
      {typeof textSubtitle === "string" ? (
        <p className={classes.textSubtitle}>{textSubtitle}</p>
      ) : (
        textSubtitle
      )}
    </div>
  );
};
