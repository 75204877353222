import React from "react";
import { FC } from "@laba/react-common";

interface ChatBubbleProps {
  color?: string;
  className?: string;
}

export const ChatBubbleTail: FC<ChatBubbleProps> = ({ color, className }) => {
  return (
    <svg
      width="9"
      height="8"
      viewBox="0 0 9 8"
      fill="none"
      className={className}
    >
      <path
        d="M9.15652 6.72831C9.54142 7.39498 9.06029 8.22831 8.29049 8.22831H1.09866C0.328858 8.22831 -0.152265 7.39497 0.232635 6.72831L3.82855 0.499999C4.21345 -0.166667 5.1757 -0.166666 5.5606 0.500001L9.15652 6.72831Z"
        fill={color}
      />
    </svg>
  );
};
