import { FC, getClassName, SizeVariant } from "@laba/react-common";
import { BaseIconButton } from "components/buttons/BaseIconButton/BaseIconButton";
import { CloseIcon, GetApp } from "components/icons";
import React from "react";
import { useFilePreviewDialogButtonContainerStyles } from "components/file/FileListPreviewPopup/FilePreviewDialogButtonContainer/useFilePreviewDialogButtonContainerStyles";
import { Noop } from "@laba/ts-common";

interface Props {
  className?: string;
  itemNumberText?: string;
  onDownloadButtonClicked?: Noop;
  onCloseButtonClicked?: Noop;
}
export const FilePreviewDialogButtonContainer: FC<Props> = ({
  className,
  onCloseButtonClicked,
  onDownloadButtonClicked,
  itemNumberText
}) => {
  const classes = useFilePreviewDialogButtonContainerStyles();
  return (
    <div className={getClassName(className, classes.buttonContainer)}>
      <p className={classes.itemNumberText}>{itemNumberText}</p>
      <BaseIconButton
        className={classes.button}
        Icon={GetApp}
        onClick={onDownloadButtonClicked}
        size={SizeVariant.Large}
      />
      <BaseIconButton
        className={classes.button}
        Icon={CloseIcon}
        onClick={onCloseButtonClicked}
        size={SizeVariant.Large}
      />
    </div>
  );
};
