import {
  FCC,
  ReactElement,
  getClassName,
  saveBlobToDevice,
  useEffectSelective
} from "@laba/react-common";
import { Noop, Optional } from "@laba/ts-common";
import { SpinnerDialog } from "components/spinners/SpinnerDialog/SpinnerDialog";
import React, { useState } from "react";
import { ButtonBase } from "components/buttons/ButtonBase/ButtonBase";
import { isUndefined } from "lodash-es";
import { FilePreviewPopup } from "./FilePreviewPopup/FilePreviewPopup";
import { canShowPreview } from "./utils";
import { useDownloadLinkStyles } from "./useDownloadLinkStyles";

interface Props {
  name: string;
  onDownload?: () => Promise<Optional<Blob>>;
  withPreview?: boolean;
  overrideButton?: ReactElement;
  className?: string;
  onSuccess?: Noop;
  onFailure?: (error: Error) => void;
  onLongPress?: Noop;
}

export const DownloadLink: FCC<Props> = ({
  name,
  onDownload,
  children,
  className,
  withPreview = false,
  overrideButton,
  onFailure,
  onSuccess,
  onLongPress
}) => {
  const classes = useDownloadLinkStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [fileBlob, setFileBlob] = useState<Blob>();
  const [isLoading, setIsLoading] = useState(false);

  useEffectSelective(
    () => {
      if (fileBlob !== undefined) setFileBlob(undefined);
    },
    [name],
    [fileBlob]
  );

  const handleOnClick = async () => {
    try {
      setIsLoading(true);
      const tmpBlob = fileBlob ?? (await onDownload?.());
      setFileBlob(tmpBlob);
      if (!withPreview || !canShowPreview(tmpBlob?.type)) {
        tmpBlob && saveBlobToDevice(tmpBlob, name);
        onSuccess && onSuccess();
      } else {
        setIsOpen(true);
      }
    } catch (e) {
      onFailure && onFailure(e);
      setFileBlob(undefined);
    }
    setIsLoading(false);
  };

  const handleDownload = async () => {
    if (fileBlob === undefined) return;
    try {
      saveBlobToDevice(fileBlob, name);
      onSuccess && onSuccess();
    } catch (e) {
      onFailure && onFailure(e);
    }
  };

  const buttonWithOnClick = overrideButton ? (
    React.cloneElement(overrideButton, {
      onClick: handleOnClick
    })
  ) : (
    <ButtonBase
      className={getClassName(classes.root, className)}
      enableLongPress={!isUndefined(onLongPress)}
      onLongPress={onLongPress}
      onClick={handleOnClick}
      disableRipple
    >
      {children}
    </ButtonBase>
  );

  return (
    <>
      {buttonWithOnClick}
      {isLoading ? (
        <SpinnerDialog open />
      ) : (
        fileBlob && (
          <FilePreviewPopup
            fileBlob={fileBlob}
            isOpen={isOpen}
            handleClose={() => setIsOpen(false)}
            handleDownload={handleDownload}
            name={name}
          />
        )
      )}
    </>
  );
};
