import React from "react";
import { FC } from "@laba/react-common";
import {
  ApiDate,
  applyFormatToDate,
  DateFormat,
  Noop,
  Optional,
  TextVariant,
  ThemeStyleColorVariant
} from "@laba/ts-common";
import { OnClickUrlAction, UrlLink } from "components/link";
import { isEmpty } from "lodash-es";
import { ColorTagItem } from "../ColorTag/ColorTag";
import { useTagWithPeriodListStyles } from "./useTagWithPeriodListStyles";
import { TagWithPeriod } from "../TagWithPeriod/TagWithPeriod";

export interface TagWithPeriodItem extends ColorTagItem {
  startDate?: ApiDate;
  endDate?: ApiDate;
}

const defaultDateFormat = (date?: ApiDate) =>
  applyFormatToDate(date, DateFormat.Spanish);

export interface TagWithPeriodListProps {
  tags: TagWithPeriodItem[];
  getStartDateText?: (startDate?: ApiDate) => Optional<string>;
  getEndDateText?: (endDate?: ApiDate) => Optional<string>;
  buttonText?: string;
  onButtonClick?: Noop;
  showEditButton?: boolean;
  emptyListText?: string;
  emptyListTextVariant?: TextVariant;
  emptyListTextColorVariant?: ThemeStyleColorVariant;
}

export const TagWithPeriodList: FC<TagWithPeriodListProps> = ({
  tags,
  getEndDateText = defaultDateFormat,
  getStartDateText = defaultDateFormat,
  buttonText,
  onButtonClick,
  showEditButton,
  emptyListText,
  emptyListTextVariant,
  emptyListTextColorVariant
}) => {
  const classes = useTagWithPeriodListStyles({
    emptyListTextVariant,
    emptyListTextColorVariant
  });
  return (
    <div className={classes.root}>
      {isEmpty(tags) ? (
        !isEmpty(emptyListText) ? (
          <p className={classes.emptyListText}>{emptyListText}</p>
        ) : null
      ) : (
        <div className={classes.tagListWrapper}>
          {tags.map(v => {
            return (
              <TagWithPeriod
                text={v.text}
                backgroundColor={v.backgroundColor}
                key={v.text}
                title={v.title}
                startDateText={getStartDateText(v.startDate)}
                endDateText={getEndDateText(v.endDate)}
              />
            );
          })}
        </div>
      )}
      {showEditButton && (
        <UrlLink
          displayText={buttonText}
          onClickUrlAction={OnClickUrlAction.CustomClick}
          onClick={onButtonClick}
        />
      )}
    </div>
  );
};
