import { ApiPageRequestResponse, ListQueryParams } from "request/types";
import { ModelId } from "model/primitives/model/model";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { ApiDate, HttpMethod } from "@laba/ts-common";
import { QuestionnaireType } from "model/resource/evolution/questionnaire/questionnaire";
import { QuestionnaireResponse } from "model/resource/evolution/questionnaireResponse/questionnaireResponse";
import { HospitalizationType } from "api/medical/hospitalization";

export enum EvolutionOrder {
  CreationDate = "creationDate",
  UpdateDate = "updateDate",
  Featured = "featured"
}

export enum EvolutionStatus {
  InProgress = "in-progress",
  Completed = "completed"
}

export interface EvolutionQueryParams {
  withPatient?: boolean;
  withProblem?: boolean;
  withPractitioner?: boolean;
  withPatientMedia?: boolean;
  withHospitalization?: boolean;
  withQuestionnaire?: boolean;
  withAllergyIntolerance?: boolean;
  withMeasurement?: boolean;
}
export interface EvolutionListQueryParams
  extends ListQueryParams,
    EvolutionQueryParams {
  author?: ModelId;
  category?: string;
  content?: string;
  encounter?: ModelId;
  hospitalizationType?: HospitalizationType;
  order?: EvolutionOrder;
  organization?: ModelId;
  patient?: ModelId;
  problem?: ModelId;
  status?: EvolutionStatus;
  type?: QuestionnaireType;
  startDate?: ApiDate;
  endDate?: ApiDate;
}

const EvolutionBasePath = "/evolution";

export const getEvolutionList = (
  params: EvolutionListQueryParams
): Promise<ApiPageRequestResponse<QuestionnaireResponse>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(EvolutionBasePath),
    params
  });
