import {
  Duration as LuxonDuration,
  DurationUnit as LuxonDurationUnit,
  DurationInput as LuxonDurationInput
} from "luxon";

export class Duration extends LuxonDuration {}

export type DurationUnit = LuxonDurationUnit;

export type DurationInput = LuxonDurationInput;

export enum DateTimeOperation {
  Plus = "Plus",
  Minus = "Minus"
}

export enum DurationUnitValues {
  Year = "year",
  Years = "years",
  Quarter = "quarter",
  Quarters = "quarters",
  Month = "month",
  Months = "months",
  Week = "week",
  Weeks = "weeks",
  Day = "day",
  Days = "days",
  Hour = "hour",
  Hours = "hours",
  Minute = "minute",
  Minutes = "minutes",
  Second = "second",
  Seconds = "seconds",
  Millisecond = "millisecond",
  Milliseconds = "milliseconds"
}
