import {
  borderMixin,
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  FlexJustifyContent,
  pxToRem,
  StyleVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor } from "model/themeUtils";
import { CircularIconSize } from "components/CircularIcon/CircularIcon";

interface CircularIconStylesClassNames {
  root: string;
}

const useCircularIconStylesStatic = createStyle(
  (theme: Theme) => ({
    rootStatic: {
      ...flexColumnMixin({
        justifyContent: FlexJustifyContent.Center,
        alignItems: FlexAlignItems.Center
      }),
      height: pxToRem(CircularIconSize),
      width: pxToRem(CircularIconSize),
      ...borderMixin({
        side: { all: pxToRem(4) },
        style: "solid",
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.Contrast
        ),
        radius: { all: "50%" }
      }),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Background
      ),
      overflow: "hidden"
    }
  }),
  "CircularIconStatic"
);

export const useCircularIconStyles = (): CircularIconStylesClassNames => {
  const staticClasses = useCircularIconStylesStatic();

  return {
    root: staticClasses.rootStatic
  };
};
