import { createStyle, pxToRem, Theme } from "@laba/react-common";

export const useEnumSelectorInputStyle = createStyle(
  (_theme: Theme) => ({
    root: {
      minWidth: pxToRem(238)
    }
  }),
  "useEnumSelectorInputStyle"
);
