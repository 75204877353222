import { TextVariant, StyleVariant } from "model/themeVariant";
import { themeTextMixin, getThemeStyleColor } from "model/themeUtils";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { createStyle, marginMixin, paddingMixin } from "@laba/react-common";

export const useSearchInputWithSuggestionsStyle = createStyle(
  (theme: Theme) => ({
    searchInputTitle: {
      ...marginMixin({ bottom: 8 }),
      ...themeTextMixin(theme, TextVariant.Subtitle2, 1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    suggestedListTitle: {
      ...marginMixin({ bottom: 16 }),
      ...themeTextMixin(theme, TextVariant.Subtitle2, 1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    suggestedListContainer: {
      ...marginMixin({ top: 16 })
    },
    suggestedList: {
      ...paddingMixin({ leftRight: 8 })
    }
  }),
  "SearchInputWithSuggestions"
);
