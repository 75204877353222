import React from "react";
import { getClassName, ReactElement } from "@laba/react-common";
import { GridItem } from "../GridItem/GridItem";
import { useCheckboxGridStyles } from "./useCheckboxGridStyles";

export interface CheckboxGridItem<V> {
  value: V;
  text: string;
  active: boolean;
}

export interface CheckboxGridProps<V> {
  className?: string;
  items: CheckboxGridItem<V>[];
  onClick?: (value: V) => void;
  gridColumns?: number;
  disabled?: boolean;
  gridErrorMessage?: string;
  gridGap?: number;
}

export const CheckboxGrid = <V,>({
  className,
  items,
  onClick,
  gridColumns = 6,
  disabled = false,
  gridErrorMessage,
  gridGap
}: CheckboxGridProps<V>): ReactElement => {
  const classes = useCheckboxGridStyles({ gridColumns, gridGap });
  return (
    <div className={getClassName(className, classes.root)}>
      <div className={classes.gridContainer}>
        {items.map(item => (
          <GridItem
            key={item.text}
            active={item.active}
            text={item.text}
            onClick={() => onClick?.(item.value)}
            disabled={disabled}
          />
        ))}
      </div>

      {gridErrorMessage && (
        <p className={classes.checkboxGridError}>{gridErrorMessage}</p>
      )}
    </div>
  );
};
