import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { ModelId } from "model/primitives/model/model";
import { getKeyObj, HttpMethod } from "@laba/ts-common";
import { ScheduleDefinition } from "model/resource/schedule/scheduleDefinition";
import { createHydratedMock } from "ts-auto-mock";
import { PublicationStatus } from "model/resource/activity/activityDefinition";
import { getFrontApiUrl, request } from "request/nexupRequest";

const scheduleDefinitionBasePath = "schedule-definition";

export interface ScheduleDefinitionListQueryParams extends ListQueryParams {
  organization?: ModelId;
  status?: PublicationStatus;
}

export const ScheduleListDefinitionQueryParamsKey =
  getKeyObj<ScheduleDefinitionListQueryParams>(
    createHydratedMock<ScheduleDefinitionListQueryParams>()
  );

export const getScheduleDefinitionList = (
  params: ScheduleDefinitionListQueryParams
): Promise<ApiPageRequestResponse<ScheduleDefinition>> => {
  return request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(scheduleDefinitionBasePath),
    params
  });
};

export const getScheduleDefinition = (
  id: ModelId
): Promise<ApiRequestResponse<ScheduleDefinition>> => {
  return request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${scheduleDefinitionBasePath}/${id}`)
  });
};

export const createScheduleDefinition = (
  data: ScheduleDefinition
): Promise<ApiRequestResponse<ScheduleDefinition>> => {
  return request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(scheduleDefinitionBasePath),
    data
  });
};

export const editScheduleDefinition = (
  data: ScheduleDefinition
): Promise<ApiRequestResponse<ScheduleDefinition>> => {
  return request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${scheduleDefinitionBasePath}/${data.id}`),
    data
  });
};
