import { DateTime } from "@laba/ts-common";
import { useEffect, useState } from "react";

/** Hook that returns the current datetime, updates every 'updateInterval' seconds */
export const useCurrentDateTime = (updateInterval: number): DateTime => {
  const [currentTime, setCurrentTime] = useState(DateTime.now());

  useEffect(() => {
    const dateInterval = setInterval(() => {
      setCurrentTime(DateTime.now());
    }, updateInterval * 1000);

    return () => clearInterval(dateInterval);
  });

  return currentTime;
};
