import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { ModelId } from "model/primitives/model/model";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { HttpMethod } from "@laba/ts-common";
import { urlJoin } from "url-join-ts";
import {
  locationBasePath,
  LocationListQueryParams
} from "api/entities/location";
import { LocationWithExtraData } from "model/resource/entities/location/locationWithExtraData";

const locationWithExtraDataBasePath = "/extra";

export interface LocationWithExtraDataQueryParams {
  withPartOfList?: boolean;
  withHospitalization?: boolean;
  withDiagnosis?: boolean;
  withHealthCarePayerProvider?: boolean;
  withPatient?: boolean;
  withPractitionerTeam?: boolean;
  withPractitioner?: boolean;
  withPatientTag?: boolean;
}

export interface LocationListWithExtraDataQueryParams
  extends ListQueryParams,
    LocationWithExtraDataQueryParams,
    LocationListQueryParams {}

export const getLocationWithExtraData = (
  id: ModelId,
  params: LocationWithExtraDataQueryParams
): Promise<ApiRequestResponse<LocationWithExtraData>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(
      urlJoin(locationBasePath, `/${id}`, locationWithExtraDataBasePath)
    ),
    params
  });

export const getLocationListWithExtraData = (
  params: LocationListWithExtraDataQueryParams
): Promise<ApiPageRequestResponse<LocationWithExtraData>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(
      urlJoin(locationBasePath, locationWithExtraDataBasePath)
    ),
    params
  });
