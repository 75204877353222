import {
  getEnumOrderParam,
  getKeyTargetParam,
  resourceProviderGetter
} from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { workspaceOrganizationSelector } from "store/workspace/selectors";
import { store } from "store/store";
import {
  createProcedureReportDefinition,
  getModelReferenceId,
  getProcedureReportDefinition,
  getProcedureReportDefinitionList,
  ProcedureReportDefinitionListQueryParams,
  ProcedureReportDefinitionListQueryParamsKey,
  ResourceType,
  updateProcedureReportDefinition
} from "@laba/nexup-api";

export const procedureReportDefinitionProvider = resourceProviderGetter(
  ResourceType.ProcedureReportDefinition,
  getEnumOrderParam({}),
  getKeyTargetParam<ProcedureReportDefinitionListQueryParams>(
    ProcedureReportDefinitionListQueryParamsKey
  ),
  getProcedureReportDefinitionList,
  getProcedureReportDefinition,
  updateProcedureReportDefinition,
  createProcedureReportDefinition,
  undefined,
  () => ({
    organization: getModelReferenceId(
      workspaceOrganizationSelector(store.getState())
    )
  })
);
