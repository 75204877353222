import { KnownIdentifierSystem } from "model/primitives/identifier";
import { ResourceType } from "model/primitives/resourceModel";
import { KnownCodeSystemSystem } from "model/resource/entities/codeSystem";
import {
  EncounterClass,
  EncounterStatus,
  KnownEncounterService,
  RoleCodes
} from "model/resource/entities/encounter/encounter";
import {
  LocationStatus,
  LocationType
} from "model/resource/entities/location/location";
import {
  Gender,
  KnownCitizenship,
  MaritalStatus,
  ValidationStatus
} from "model/resource/person";
import {
  ConditionCategory,
  ConditionClinical,
  KnownDiagnosisRole,
  PatientClassificationSC
} from "../../condition";
import {
  KnownMeasureObservationCode,
  KnownPatientTagCodes,
  ObservationCategory,
  ObservationStatus
} from "../../observation";
import {
  Hospitalization,
  HospitalizationWithExtraData
} from "../hospitalization";

export const hospitalizationListMock: Hospitalization[] = [
  {
    resourceType: ResourceType.Hospitalization,
    status: EncounterStatus.Planned,
    patient: "1242"
  },
  {
    resourceType: ResourceType.Hospitalization,
    status: EncounterStatus.InProgress,
    patient: "1242"
  },
  {
    resourceType: ResourceType.Hospitalization,
    status: EncounterStatus.Finished,
    patient: "1242"
  }
];

export const hospitalizationListWithExtraDataMock: HospitalizationWithExtraData[] =
  [
    {
      hospitalization: {
        resourceType: ResourceType.Hospitalization,
        status: EncounterStatus.Planned,
        patient: "1242",
        practitionerTeam: [
          {
            practitioner: {
              resourceType: ResourceType.Practitioner,
              organization: [],
              validationStatus: ValidationStatus.Permanent,
              personalData: {
                firstName: "Carlitos",
                lastName: "Tevez",
                gender: Gender.Male
              }
            },
            role: {
              code: RoleCodes.Physiatrist,
              name: "dete",
              system: KnownCodeSystemSystem.EncounterPractitionerType
            }
          }
        ],
        currentBed: {
          bed: {
            buildingName: "Springfield Hospital",
            roomName: "102A",
            name: "Springfield Healthcare",
            resourceType: ResourceType.Bed,
            status: LocationStatus.Active,
            children: undefined
          }
        },
        observation:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.",
        dischargeObservation:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters."
      },
      tagList: [
        {
          resourceType: ResourceType.PatientTag,
          status: ObservationStatus.Final,
          type: KnownMeasureObservationCode.Unknown,
          category: ObservationCategory.Tag,
          value: KnownPatientTagCodes.Arm,
          startDateTime: "2022-02-03T10:14:36.000-03",
          endDateTime: "2022-02-07T10:14:36.000-03"
        },
        {
          resourceType: ResourceType.PatientTag,
          status: ObservationStatus.Final,
          type: KnownMeasureObservationCode.Unknown,
          category: ObservationCategory.Tag,
          value: KnownPatientTagCodes.Arm,
          startDateTime: "2022-02-03T10:14:36.000-03",
          endDateTime: "2022-02-07T10:14:36.000-03"
        },
        {
          resourceType: ResourceType.PatientTag,
          status: ObservationStatus.Final,
          type: KnownMeasureObservationCode.Unknown,
          category: ObservationCategory.Tag,
          value: KnownPatientTagCodes.Arm,
          startDateTime: "2022-02-03T10:14:36.000-03",
          endDateTime: "2022-02-07T10:14:36.000-03"
        },
        {
          resourceType: ResourceType.PatientTag,
          status: ObservationStatus.Final,
          type: KnownMeasureObservationCode.Unknown,
          category: ObservationCategory.Tag,
          value: KnownPatientTagCodes.Arm,
          startDateTime: "2022-02-03T10:14:36.000-03",
          endDateTime: "2022-02-07T10:14:36.000-03"
        },
        {
          resourceType: ResourceType.PatientTag,
          status: ObservationStatus.Final,
          type: KnownMeasureObservationCode.Unknown,
          category: ObservationCategory.Tag,
          value: KnownPatientTagCodes.Arm,
          startDateTime: "2022-02-03T10:14:36.000-03",
          endDateTime: "2022-02-07T10:14:36.000-03"
        },
        {
          resourceType: ResourceType.PatientTag,
          status: ObservationStatus.Final,
          type: KnownMeasureObservationCode.Unknown,
          category: ObservationCategory.Tag,
          value: KnownPatientTagCodes.Arm,
          startDateTime: "2022-02-03T10:14:36.000-03",
          endDateTime: "2022-02-07T10:14:36.000-03"
        }
      ]
    },
    {
      hospitalization: {
        healthcareService: "",
        resourceType: ResourceType.Hospitalization,
        id: "1",
        patient: "29",
        currentBed: {
          id: "unNexupId",
          bed: {
            children: undefined,
            resourceType: ResourceType.Bed,
            id: "1",
            name: "101A",
            description: "Catamarca 101A",
            status: LocationStatus.Active,
            type: LocationType.Bed,
            roomName: "roomName",
            roomId: "roomId",
            buildingName: "Catamarca"
          }
        },
        practitionerTeam: [
          {
            role: {
              system: KnownCodeSystemSystem.EncounterPractitionerType,
              code: RoleCodes.GeneralPractitioner
            },
            practitioner: {
              resourceType: ResourceType.Practitioner,
              id: "1",
              validationStatus: ValidationStatus.Permanent,
              organization: ["1"],
              personalData: {
                firstName: "Primer Nombre",
                otherName: "Segundo Nombre",
                lastName: "Apellido",
                motherLastName: "Apellido materno",
                birthDate: "1993-01-02",
                gender: Gender.Male,
                identifierList: [
                  {
                    resourceType: ResourceType.Identifier,
                    value: "88",
                    system: KnownIdentifierSystem.Dni
                  }
                ],
                maritalStatus: MaritalStatus.Married,
                citizenship: KnownCitizenship.Argentinian
              }
            }
          }
        ],
        startDate: "2020-07-14",
        finishDate: "2021-01-02",
        service: KnownEncounterService.Acute,
        status: EncounterStatus.InProgress,
        class: EncounterClass.Inpatient,
        diagnosis: [
          {
            condition: {
              resourceType: ResourceType.Condition,
              clinicalStatus: ConditionClinical.Active,
              code: {
                code: PatientClassificationSC.A,
                name: PatientClassificationSC.A,
                system: KnownCodeSystemSystem.PatientClassificationSC
              },
              category: ConditionCategory.PatientClassification
            },
            use: KnownDiagnosisRole.MedicalAdmissionDiagnosis
          }
        ]
      }
    },
    {
      hospitalization: {
        resourceType: ResourceType.Hospitalization,
        status: EncounterStatus.Finished,
        patient: "1242"
      }
    }
  ];
