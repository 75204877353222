import {
  ActivityDefinitionKind,
  PublicationStatus
} from "model/resource/activity/activityDefinition";
import { ResourceType } from "model/primitives/resourceModel";
import { KnownMeasureObservationCode } from "model/resource/medical/observation/observation";
import {
  MeasurementGroupCategory,
  MeasurementGroupDefinition
} from "../measurementGroupDefinition";

export const measurementGroupDefinitionMockList: MeasurementGroupDefinition[] =
  [
    {
      id: "1234",
      status: PublicationStatus.Active,
      resourceType: ResourceType.MeasurementGroupDefinition,
      kind: ActivityDefinitionKind.Observation,
      category: MeasurementGroupCategory.VitalSigns,
      color: "#7487F2",
      editPeriod: 1,
      measurements: [
        {
          linkId: "1110",
          title: "PESO",
          type: KnownMeasureObservationCode.Weight,
          units: ["kg"],
          maxValue: 500,
          minValue: 10,
          step: 0.5,
          required: false,
          isComponent: false,
          range: "60"
        },
        {
          linkId: "1111",
          title: "T°",
          type: "Temperature",
          units: ["°C"],
          maxValue: 44,
          minValue: 32,
          step: 0.1,
          required: false,
          isComponent: true,
          range: "40"
        },
        {
          linkId: "1112",
          title: "SIST.",
          type: "Sistole",
          units: ["mmHg"],
          maxValue: 300,
          minValue: 0,
          step: 1,
          required: false,
          isComponent: true,
          range: "100"
        },
        {
          linkId: "1113",
          title: "DIAS.",
          type: "Diastole",
          units: ["mmHg"],
          maxValue: 0,
          minValue: 180,
          step: 1,
          required: false,
          isComponent: true,
          range: "125"
        }
      ]
    }
  ];
