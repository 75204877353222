import React from "react";
import { FC, StyleVariant, TypeVariant, useI18n } from "@laba/react-common";
import {
  ModelId,
  Organization,
  OrganizationKey,
  ResourceType,
  WhiteLabelBannerItemConfig,
  WhiteLabelBannerItemConfigKey
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { ThymeleafInput } from "components/generic/ThymeleafInput/ThymeleafInput";
import { UrlInput } from "components/generic/UrlInput/UrlInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { FormDataConsumer } from "components/generic/FormDataConsumer/FormDataConsumer";
import { UploadFileImageInput } from "components/generic/UploadFileImageInput/UploadFileImageInput";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useSelector } from "react-redux";
import { useWatchResourceForm } from "components/hook/useResourceContext";

export interface OrganizationWhiteLabelBannerContentProps {
  source: string;
  label?: string;
}
export const tk = tkCP.adminPage[ResourceType.Organization];
export const OrganizationWhiteLabelBannerContent: FC<
  OrganizationWhiteLabelBannerContentProps
> = ({ source, label }) => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organizationId = useWatchResourceForm<ModelId>(OrganizationKey.id);
  const organization = organizationId ?? workspaceOrganizationId;

  return (
    <ArrayInput source={source} label={label}>
      <FormDataConsumer<Organization, WhiteLabelBannerItemConfig>>
        {({ scopedFormData, getSource }) => {
          return (
            <UploadFileImageInput
              source={getSource(WhiteLabelBannerItemConfigKey.itemUrl)}
              label={t(tk.fields.whiteLabelConfigBannerItemUrl)}
              isVideo={scopedFormData?.isVideo}
              organization={organization}
            />
          );
        }}
      </FormDataConsumer>
      <BooleanInput
        source={WhiteLabelBannerItemConfigKey.isVideo}
        label={t(tk.fields.whiteLabelConfigBannerIsVideo)}
      />
      <ThymeleafInput
        source={WhiteLabelBannerItemConfigKey.title}
        label={t(tk.fields.whiteLabelConfigBannerTitle)}
      />
      <ThymeleafInput
        source={WhiteLabelBannerItemConfigKey.principalText}
        label={t(tk.fields.whiteLabelConfigBannerPrincipalText)}
      />
      <ThymeleafInput
        source={WhiteLabelBannerItemConfigKey.secondaryText}
        label={t(tk.fields.whiteLabelConfigBannerSecondaryText)}
      />
      <ThymeleafInput
        source={WhiteLabelBannerItemConfigKey.buttonText}
        label={t(tk.fields.whiteLabelConfigBannerButtonText)}
      />
      <UrlInput
        source={WhiteLabelBannerItemConfigKey.buttonUrl}
        label={t(tk.fields.whiteLabelConfigBannerButtonUrl)}
      />
      <EnumSelectorInput
        source={WhiteLabelBannerItemConfigKey.buttonType}
        label={t(tk.fields.whiteLabelConfigBannerButtonType)}
        enumValue={TypeVariant}
      />
      <EnumSelectorInput
        source={WhiteLabelBannerItemConfigKey.buttonStyle}
        label={t(tk.fields.whiteLabelConfigBannerButtonStyle)}
        enumValue={StyleVariant}
      />
    </ArrayInput>
  );
};
