import { getKeyObj, HttpMethod, Validator } from "@laba/ts-common";
import { User } from "model/resource/person/user";
import { getApiUrl, request, requestAnon } from "request/nexupRequest";
import { get } from "lodash-es";
import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { ModelId } from "model/primitives/model/model";
import { createHydratedMock } from "ts-auto-mock";

export enum RequestSource {
  His = "His",
  Patient = "Patient"
}

export interface UserListQueryParams extends ListQueryParams {
  content?: string;
  id?: ModelId[];
}

export interface ForgotPasswordData {
  email?: string;
}

export interface ChangePasswordData {
  password?: string;
  passwordAgain?: string;
}

export interface ForgotPasswordQueryParams {
  email: string;
  source: RequestSource;
  baseUrl?: string;
}

export const forgotPasswordFormDataParamsKey = getKeyObj(
  createHydratedMock<ForgotPasswordData>()
);

export const changePasswordFormDataParamsKey = getKeyObj(
  createHydratedMock<ChangePasswordData>()
);

export const userBasePath = "/user";

export const getUserList = (
  params: UserListQueryParams
): Promise<ApiPageRequestResponse<User>> =>
  request({
    url: getApiUrl(userBasePath),
    method: HttpMethod.GET,
    params
  });

export const getUser = (id: ModelId): Promise<ApiRequestResponse<User>> =>
  request({
    method: HttpMethod.GET,
    url: getApiUrl(`${userBasePath}/${id}`)
  });

export const createUser = (data: User): Promise<ApiRequestResponse<User>> =>
  request({
    method: HttpMethod.POST,
    url: getApiUrl(userBasePath),
    data
  });

export const updateUser = (data: User): Promise<ApiRequestResponse<User>> =>
  request({
    method: HttpMethod.PUT,
    url: getApiUrl(`${userBasePath}/${data.id}`),
    data
  });

export const getCurrentUser = async (): Promise<ApiRequestResponse<User>> =>
  request({
    method: HttpMethod.GET,
    url: getApiUrl(`${userBasePath}/current`)
  });

export const sendPasswordRecoveryEmail = async (
  params: ForgotPasswordQueryParams
): Promise<ApiRequestResponse<unknown>> =>
  requestAnon({
    method: HttpMethod.POST,
    url: getApiUrl(`${userBasePath}/reset`),
    data: params
  });

export const changePassword = async (
  token: string,
  password: string
): Promise<ApiRequestResponse<unknown>> =>
  requestAnon({
    method: HttpMethod.POST,
    url: getApiUrl(`${userBasePath}/set-password`),
    data: { token, password }
  });

export const createOrUpdateUserFromPractitioner = async (
  id: ModelId,
  data: unknown
): Promise<ApiRequestResponse<unknown>> =>
  request({
    method: HttpMethod.POST,
    url: getApiUrl(`${userBasePath}/practitioner/${id}`),
    data
  });

export const getUserFromPractitioner = async (
  id: ModelId
): Promise<ApiRequestResponse<User>> =>
  request({
    method: HttpMethod.GET,
    url: getApiUrl(`${userBasePath}/practitioner/${id}`)
  });

export interface UserData {
  email?: string;
  username?: string;
}

export interface UserDataValidation {
  validEmail?: boolean;
  validUsername?: boolean;
}

const validateUserDataValidator: Validator<UserDataValidation> = response => {
  return {
    validEmail: get(response, "wrongEmail", true),
    validUsername: get(response, "wrongUsername", true)
  };
};

export const validateUserData = async (
  data: UserData
): Promise<ApiRequestResponse<UserDataValidation>> => {
  return request(
    {
      method: HttpMethod.POST,
      url: getApiUrl(`${userBasePath}/check`),
      data
    },
    validateUserDataValidator
  );
};

export const UserListQueryParamsKey = getKeyObj(
  createHydratedMock<UserListQueryParams>()
);
