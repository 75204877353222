import { GenericObject } from "@laba/ts-common";

export const getActionControl = (action: string): GenericObject => {
  return {
    action,
    table: {
      disable: true
    }
  };
};
