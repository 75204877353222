import { useState } from "react";
import { isString, ModelId, Optional } from "@laba/ts-common";
import { useEffectSelective } from "hooks/useEffectSelective";

export interface Props<T> {
  valueReference: T | ModelId;
  getValue: (id: ModelId) => Promise<Optional<T>>;
}

export const useGetModelReference = <T>({
  valueReference,
  getValue
}: Props<T>): Optional<T> => {
  const [value, setValue] = useState<Optional<T>>(
    isString(valueReference) ? undefined : valueReference
  );

  useEffectSelective(
    () => {
      const getNewValue = async () => {
        if (isString(valueReference)) {
          setValue(await getValue(valueReference));
        } else if (valueReference !== value) {
          setValue(valueReference);
        }
      };
      getNewValue();
    },
    [valueReference],
    [getValue]
  );

  return value;
};
