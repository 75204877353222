import React, { Context } from "react";
import { useMediaQuery } from "@material-ui/core";

export type DeviceTypeContext = Context<{ deviceType: DeviceType }>;

export const defaultDesktopMinSizeBreakpoint = 1280;

export enum DeviceType {
  Mobile = "mobile",
  Desktop = "desktop"
}

export interface InitDeviceType {
  deviceTypeContext: DeviceTypeContext;
  useDeviceType: () => DeviceType;
  useIsDeviceMobile: () => boolean;
}

export const useWindowIsNarrowerOrEqualThan = (breakpoint: number): boolean => {
  // useMediaQuery is called to force a rerender
  const mediaQueryMatch = useMediaQuery(`(max-width:${breakpoint}px)`);
  return mediaQueryMatch;
};

const createUseDeviceType = (context: DeviceTypeContext) => (): DeviceType =>
  React.useContext(context).deviceType;

const createUseIsDeviceMobile = (context: DeviceTypeContext) => (): boolean => {
  return createUseDeviceType(context)() === DeviceType.Mobile;
};

export const initDeviceType = (): InitDeviceType => {
  const deviceTypeContext = React.createContext({
    deviceType: DeviceType.Desktop
  });
  const useDeviceType = createUseDeviceType(deviceTypeContext);
  const useIsDeviceMobile = createUseIsDeviceMobile(deviceTypeContext);
  return { deviceTypeContext, useDeviceType, useIsDeviceMobile };
};
