import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import {
  Hospitalization,
  HospitalizationPatientWristbandSize,
  HospitalizationWithExtraData
} from "model/resource/medical/hospitalization";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { ApiDate, getKeyObj, HttpMethod, KeyObj } from "@laba/ts-common";
import { ModelId } from "model/primitives/model/model";
import { createHydratedMock } from "ts-auto-mock";
import { EncounterQueryParams } from "api/entities/encounter/encounter";
import { urlJoin } from "url-join-ts";

export enum HospitalizationFilterOrder {
  LastUpdateDate = "lastUpdateDate",
  CreationDate = "creationDate"
}

export enum HospitalizationType {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Hospitalization = "Hospitalization",
  Ambulatory = "Ambulatory",
  All = "All"
}

interface BedStatisticsQueryParams {
  organization: ModelId;
  building: ModelId;
}

interface BedSectionStatistics {
  id?: ModelId;
  total?: number;
  free?: number;
  occupied?: number;
}

export interface BedStatistics extends BedSectionStatistics {
  section: BedSectionStatistics[];
}

export interface HospitalizationQueryParams extends EncounterQueryParams {
  withAllergyIntoleranceList?: boolean;
  withClassification?: boolean;
  withCurrentBed?: boolean;
  withDiagnosis?: boolean;
  withMeasurements?: boolean;
  withOrganization?: boolean;
  withPrescriptionStatus?: boolean;
  withTagList?: boolean;
  withHealthCarePayerProvider?: boolean;
  withPractitionerTeam?: boolean;
  withPractitioner?: boolean;
}

export interface HospitalizationListQueryParams
  extends ListQueryParams,
    HospitalizationQueryParams {
  order?: HospitalizationFilterOrder;
  patient?: ModelId[];
  type?: string[];
  tag?: string[];
  payer?: string[];
  startDate?: ApiDate;
  endDate?: ApiDate;
  location?: ModelId;
  withoutBed?: boolean;
}

interface GetHospitalizationWithExtraData {
  withPatientTag?: boolean;
}

export interface HospitalizationListWithExtraDataQueryParams
  extends HospitalizationListQueryParams,
    GetHospitalizationWithExtraData {}

export interface HospitalizationWithExtraDataQueryParams
  extends HospitalizationQueryParams,
    GetHospitalizationWithExtraData {}

export const HospitalizationListQueryParamsKey: KeyObj<HospitalizationListQueryParams> =
  getKeyObj(createHydratedMock<HospitalizationListQueryParams>());

const hospitalizationBasePath = "/hospitalization";

const extraDataBasePath = "/extra";
const wristbandPath = "/wristband";

const hospitalizationWithExtraDataBasePath = `${hospitalizationBasePath}${extraDataBasePath}`;

const getHospitalizationWithExtraDataIdPath = (id?: ModelId) => {
  return `${hospitalizationBasePath}/${id}${extraDataBasePath}`;
};

export const getHospitalizationList = (
  params?: HospitalizationListQueryParams
): Promise<ApiPageRequestResponse<Hospitalization>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(hospitalizationBasePath),
    params
  });

export const getHospitalization = (
  id: ModelId,
  params?: HospitalizationQueryParams
): Promise<ApiRequestResponse<Hospitalization>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${hospitalizationBasePath}/${id}`),
    params
  });

export const createHospitalization = (
  data: Hospitalization
): Promise<ApiRequestResponse<Hospitalization>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(hospitalizationBasePath),
    data
  });

export const updateHospitalization = (
  data: Hospitalization
): Promise<ApiRequestResponse<Hospitalization>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${hospitalizationBasePath}/${data.id}`),
    data
  });

export const getHospitalizationListWithExtraData = (
  params?: HospitalizationListWithExtraDataQueryParams
): Promise<ApiPageRequestResponse<HospitalizationWithExtraData>> => {
  return request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${hospitalizationBasePath}/extra`),
    params
  });
};

export const cancelHospitalizationWithExtraData = (
  id: ModelId
): Promise<ApiRequestResponse<HospitalizationWithExtraData>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(`${hospitalizationBasePath}/${id}/extra/cancel`)
  });

export const createHospitalizationWristbandFile = (
  id: ModelId,
  size: HospitalizationPatientWristbandSize
): Promise<ApiRequestResponse<Blob>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(urlJoin(hospitalizationBasePath, id, wristbandPath)),
    data: { size },
    responseType: "blob"
  });

export const getHospitalizationWithExtraData = (
  id: ModelId,
  params?: HospitalizationWithExtraDataQueryParams
): Promise<ApiRequestResponse<HospitalizationWithExtraData>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(getHospitalizationWithExtraDataIdPath(id)),
    params
  });

export const createHospitalizationWithExtraData = (
  data: HospitalizationWithExtraData
): Promise<ApiRequestResponse<HospitalizationWithExtraData>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(hospitalizationWithExtraDataBasePath),
    data
  });

export const editHospitalizationWithExtraData = (
  data: HospitalizationWithExtraData
): Promise<ApiRequestResponse<HospitalizationWithExtraData>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(
      getHospitalizationWithExtraDataIdPath(data.hospitalization.id)
    ),
    data
  });

export const getBedStatistics = (
  params?: BedStatisticsQueryParams
): Promise<ApiRequestResponse<BedStatistics>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(urlJoin("/bed", "/statistics")),
    params
  });
