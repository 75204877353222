import {
  createStyle,
  FlexAlignItems,
  flexRowMixin,
  marginMixin
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { StyleVariant, TextVariant } from "model/themeVariant";

export const useFileAttachmentStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center
      }),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    icon: { ...marginMixin({ right: 4 }) },
    filename: { ...themeTextMixin(theme, TextVariant.Subtitle2, 1) },
    underlined: { textDecoration: "underline" }
  }),
  "FileAttachment"
);
