import React from "react";
import { Color, FC, getClassName, TextVariant } from "@laba/react-common";
import { BaseIconButton } from "components/buttons/BaseIconButton/BaseIconButton";
import { CloseIcon, StatusIcon } from "components/icons";
import { NexupMarkdown } from "components/text/NexupMarkdown/NexupMarkdown";
import { ColorTagList } from "components/tags/ColorTagList/ColorTagList";
import { Skeleton } from "components/skeleton/Skeleton/Skeleton";
import { ColorTagItem } from "components/tags/ColorTag/ColorTag";
import { Divider, DividerSize } from "components/divider/Divider";
import { useDynamicTagList } from "hooks";
import { Noop } from "@laba/ts-common";
import { useResourceDetailHeaderStyles } from "./ResourceDetailHeaderStyle";
import { ResourceDateAndPractitioner } from "./HeaderCreationOrLastEditDateText/utils";
import { HeaderCreationOrLastEditDateText } from "./HeaderCreationOrLastEditDateText/HeaderCreationOrLastEditDateText";

export interface ResourceStatus {
  statusLabel?: string;
  statusColor?: Color;
}

export interface ResourceDetailHeaderProps {
  onCloseIconClick?: Noop;
  headerColor?: string;
  headerTitle?: string;
  tags?: ColorTagItem[];
  status?: ResourceStatus;
  lastEditData?: ResourceDateAndPractitioner;
  onlyEffectiveData?: boolean;
  creationData?: ResourceDateAndPractitioner;
  className?: string;
  isMobile?: boolean;
  isLoading?: boolean;
  withCloseIcon?: boolean;
  reverseStatusIcon?: boolean;
}

const StatusIconAndLabelComponent: FC<{
  statusLabel?: string;
  statusColor?: Color;
  statusLabelClassName?: string;
  resourceDetailStatusLabelClassName?: string;
  statusIconClassName?: string;
  reverseStatusIcon?: boolean;
}> = ({
  statusLabel,
  statusColor,
  statusLabelClassName,
  resourceDetailStatusLabelClassName,
  statusIconClassName,
  reverseStatusIcon
}) => {
  if (!statusLabel || !statusColor) return null;
  const StatusIconC = (
    <StatusIcon titleAccess={statusLabel} className={statusIconClassName} />
  );

  return (
    <div className={statusLabelClassName}>
      {reverseStatusIcon && StatusIconC}
      <p className={resourceDetailStatusLabelClassName}>{statusLabel}</p>
      {!reverseStatusIcon && StatusIconC}
    </div>
  );
};

export const ResourceDetailHeader: FC<ResourceDetailHeaderProps> = ({
  onCloseIconClick,
  headerColor,
  headerTitle,
  tags = [],
  status,
  lastEditData,
  creationData,
  className,
  isMobile,
  isLoading,
  onlyEffectiveData,
  withCloseIcon = true,
  reverseStatusIcon
}) => {
  const statusColor = status?.statusColor;
  const statusLabel = status?.statusLabel;
  const classes = useResourceDetailHeaderStyles({
    headerColor,
    statusColor,
    isMobile
  });

  const { ref, tagsList } = useDynamicTagList(tags, true);

  if (isLoading) return <Skeleton className={classes.resourceHeaderSkeleton} />;

  return (
    <div className={getClassName(classes.root)}>
      <div className={getClassName(classes.header, className)}>
        <div className={classes.headerContent}>
          <div className={classes.headerRow}>
            <div className={classes.titleAndTags}>
              <NexupMarkdown
                className={classes.title}
                defaultVariant={
                  isMobile ? TextVariant.Subtitle1 : TextVariant.H6
                }
                text={headerTitle}
                title={headerTitle}
              />
              <ColorTagList
                ref={ref}
                tags={tagsList}
                tagClassName={classes.tag}
              />
            </div>

            <div className={classes.statusAndCloseButton}>
              {!isMobile && (
                <StatusIconAndLabelComponent
                  statusLabel={statusLabel}
                  statusColor={statusColor}
                  statusLabelClassName={classes.statusLabel}
                  resourceDetailStatusLabelClassName={
                    classes.resourceDetailStatusLabel
                  }
                  statusIconClassName={classes.statusIcon}
                />
              )}
              {withCloseIcon && (
                <BaseIconButton
                  Icon={CloseIcon}
                  onClick={onCloseIconClick}
                  withoutPadding={!isMobile}
                />
              )}
            </div>
          </div>
          {((status && isMobile) || lastEditData || creationData) && (
            <div className={classes.secondHeaderRow}>
              {!isMobile && (
                <HeaderCreationOrLastEditDateText creationData={creationData} />
              )}
              <HeaderCreationOrLastEditDateText
                className={classes.lastEditData}
                creationData={creationData}
                lastEditData={lastEditData}
                showEditTextOnly={!isMobile}
                onlyEffectiveData={onlyEffectiveData}
              />
              {isMobile && (
                <StatusIconAndLabelComponent
                  statusLabel={statusLabel}
                  statusColor={statusColor}
                  statusLabelClassName={classes.statusLabel}
                  resourceDetailStatusLabelClassName={
                    classes.resourceDetailStatusLabel
                  }
                  statusIconClassName={classes.statusIcon}
                  reverseStatusIcon={reverseStatusIcon}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <Divider size={DividerSize.Medium} />
    </div>
  );
};
