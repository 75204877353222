import { head, isNil } from "lodash-es";
import { TouchEvent, useCallback, useRef } from "react";
import {
  Optional,
  ScreenPosition,
  positionGreaterThanMinimumDistance,
  Noop
} from "@laba/ts-common";
import { OnClickEvent } from "./eventHandler";

const isPositionAtOrigin = (p?: ScreenPosition): boolean => {
  if (!p) return true;
  return p.x === 0 && p.y === 0;
};

const validatePositionChanges = (
  distance: number,
  initialPosition?: ScreenPosition,
  currentPosition?: ScreenPosition
): boolean => {
  if (isNil(initialPosition) || isNil(currentPosition)) return false;
  return (
    !positionGreaterThanMinimumDistance(
      initialPosition,
      currentPosition,
      distance
    ) && !isPositionAtOrigin(initialPosition)
  );
};

const positionFromTouch = (e: TouchEvent<HTMLButtonElement>) => {
  const x = head(e.touches)?.clientX;
  const y = head(e.touches)?.clientY;
  if (x && y) return { x, y };
  return undefined;
};

interface LongPressHandlers {
  handlers: {
    onClick: OnClickEvent;
    onTouchStart: (e: TouchEvent<HTMLButtonElement>) => void;
    onTouchEnd: (e: TouchEvent<HTMLButtonElement>) => void;
    onTouchMove: (e: TouchEvent<HTMLButtonElement>) => void;
  };
}

export const useLongPress = (
  onClick?: OnClickEvent,
  onLongPress?: Noop,
  delay = 500,
  distance = 10
): LongPressHandlers => {
  const timerRef = useRef<number>();
  const isLongPressRef = useRef<boolean>(false);
  const initialPositionRef = useRef<Optional<ScreenPosition>>();
  const currentPositionRef = useRef<Optional<ScreenPosition>>();

  const clearAction = useCallback(() => {
    clearTimeout(timerRef.current);
    isLongPressRef.current = false;
    initialPositionRef.current = undefined;
    currentPositionRef.current = undefined;
  }, [timerRef, isLongPressRef, initialPositionRef, currentPositionRef]);

  const onTimerEnd = useCallback(() => {
    const initialPosition = initialPositionRef.current;
    const currentPosition = currentPositionRef.current;
    if (validatePositionChanges(distance, initialPosition, currentPosition)) {
      isLongPressRef.current = true;
      onLongPress?.();
      clearAction();
    }
  }, [
    initialPositionRef,
    currentPositionRef,
    isLongPressRef,
    clearAction,
    distance,
    onLongPress
  ]);

  const handleOnTouchStart = useCallback(
    (e: TouchEvent<HTMLButtonElement>) => {
      clearAction();
      initialPositionRef.current = positionFromTouch(e);
      currentPositionRef.current = positionFromTouch(e);
      timerRef.current = window.setTimeout(onTimerEnd, delay);
    },
    [clearAction, delay, onTimerEnd]
  );

  const handleTouchMove = (e: TouchEvent<HTMLButtonElement>) => {
    currentPositionRef.current = positionFromTouch(e);
  };

  const handleOnTouchEnd = (e: TouchEvent<HTMLButtonElement>) => {
    if (isLongPressRef.current) {
      e.preventDefault();
      return;
    }
    clearTimeout(timerRef.current);
    initialPositionRef.current = undefined;
    currentPositionRef.current = undefined;
  };

  const handleOnClick: OnClickEvent = e => {
    e.preventDefault();
    if (isLongPressRef.current) return;
    onClick?.(e);
  };

  return {
    handlers: {
      onClick: handleOnClick,
      onTouchStart: handleOnTouchStart,
      onTouchEnd: handleOnTouchEnd,
      onTouchMove: handleTouchMove
    }
  };
};
