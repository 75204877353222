import {
  Theme,
  createStyle,
  flexRowMixin,
  flexColumnMixin,
  flexItemMixin
} from "@laba/react-common";

export const useAddRemoveButtonsContainerStyles = createStyle(
  (_theme: Theme) => ({
    root: { ...flexColumnMixin({ gap: 16 }) },
    inputRow: { ...flexRowMixin({ gap: 8 }) },
    children: { ...flexItemMixin({ grow: 1 }) }
  }),
  "AddRemoveButtonsContainer"
);
