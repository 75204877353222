import {
  createStyle,
  destyledButtonMixin,
  FlexAlignItems,
  flexColumnMixin,
  FlexJustifyContent,
  paddingMixin,
  StyleVariant,
  TextVariant
} from "@laba/react-common";
import { NexupColor } from "model/nexupColor";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

interface AttachmentCardContentStyleProps {
  isMobile?: boolean;
}

interface AttachmentCardContentClassNames {
  root: string;
  downloadFilesText: string;
  downloadFilesContainer: string;
  downloadFilesButton: string;
}

const useAttachmentCardContentStaticStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexColumnMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.Center,
        gap: 16
      })
    },
    downloadFilesText: {
      ...themeTextMixin(theme, TextVariant.Caption),
      color: getThemeStyleColor(
        theme,
        StyleVariant.BlackWhite,
        ThemeStyleColorVariant.GrayLight
      ),
      textAlign: "center"
    },
    downloadFilesButton: {
      ...destyledButtonMixin(),
      backgroundColor: NexupColor.Transparent,
      color: getThemeStyleColor(theme, StyleVariant.Primary)
    }
  }),
  "AttachmentCardContent"
);

const useAttachmentCardContentDynamicStyles = createStyle(
  (_theme: Theme) => ({
    downloadFilesContainer: (props: AttachmentCardContentStyleProps) => ({
      ...flexColumnMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.Center,
        gap: 4
      }),
      ...(props.isMobile ? {} : paddingMixin({ leftRight: 40, top: 8 }))
    })
  }),
  "AttachmentCardContentDynamic"
);

export const useAttachmentCardContentStyle = (
  props: AttachmentCardContentStyleProps
): AttachmentCardContentClassNames => {
  const staticClasses = useAttachmentCardContentStaticStyles();
  const dynamicClasses = useAttachmentCardContentDynamicStyles(props);

  return {
    root: staticClasses.root,
    downloadFilesText: staticClasses.downloadFilesText,
    downloadFilesContainer: dynamicClasses.downloadFilesContainer,
    downloadFilesButton: staticClasses.downloadFilesButton
  };
};
