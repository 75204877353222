import React from "react";
import { FC } from "@laba/react-common";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export const UploadArrow: FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        className="b"
        d="M14,17H0V15H14v2Zm-4-4H4V7H0L7,0l7,7H10v6Z"
        transform="translate(5.5 3)"
      />
    </SvgIcon>
  );
};
