export enum Server {
  Local = "Local",
  Develop = "Develop",
  Staging = "Staging",
  Production = "Production"
}

export const serverUrlMap: Record<Server, string> = {
  [Server.Local]: "http://localhost:8081/",
  [Server.Develop]: "https://development.nexup.com.ar/api/",
  [Server.Staging]: "https://staging.nexup.com.ar/api/",
  [Server.Production]: "https://nexup.com.ar/api/"
};

export const provisionalServerUrlMap: Record<Server, string> = {
  [Server.Local]: "http://localhost:8081/",
  [Server.Develop]: "https://development.provisional.nexup.com.ar/api/",
  [Server.Staging]: "https://staging.provisional.nexup.com.ar/api/",
  [Server.Production]: "https://provisional.nexup.com.ar/api/"
};
