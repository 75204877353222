import React from "react";
import {
  TimeInput as ReactAdminTimeInput,
  TimeInputProps as ReactAdminTimeInputProps,
  required as requiredValidate
} from "react-admin";
import { ReactElement } from "@laba/react-common";
import {
  applyFormatToDate,
  DateFormat,
  DateTime,
  getAsArray,
  notNull
} from "@laba/ts-common";

export type TimeInputProps = ReactAdminTimeInputProps & {
  required?: boolean;
};

const inputFormat = DateFormat.NoSecTime;
const dateFormat = DateFormat.SecTime;

export const TimeInput = ({
  validate,
  required,
  ...otherProps
}: TimeInputProps): ReactElement => {
  const validateArray = getAsArray(validate).filter(notNull);
  if (required) validateArray.push(requiredValidate());
  return (
    <ReactAdminTimeInput
      fullWidth
      variant="outlined"
      {...otherProps}
      format={value => {
        let date = DateTime.fromFormat(value ?? "", inputFormat);
        if (!date.isValid) date = DateTime.fromApiDate(value ?? "");
        return applyFormatToDate(date, inputFormat) ?? "";
      }}
      parse={value => {
        const date = DateTime.fromFormat(value ?? "", inputFormat);
        if (date.isValid) return applyFormatToDate(date, dateFormat);

        return DateTime.fromApiDate(value ?? "");
      }}
      validate={validateArray}
    />
  );
};
