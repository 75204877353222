import { PractitionerContainer } from "model/resource/person/practitioner/practitioner";
import { ModelReference } from "../../primitives/modelReference/modelReference";
import { Organization } from "./organization/organization";
import { ResourceModel, ResourceType } from "../../primitives/resourceModel";

export enum GroupType {
  Person = "Person",
  Animal = "Animal",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Practitioner = "Practitioner",
  Device = "Device",
  Medication = "Medication",
  Substance = "Substance"
}

export enum GroupCode {
  VirtualGuardGroup = "VirtualGuardGroup"
}

export interface Group extends ResourceModel<ResourceType.Group> {
  active?: boolean;
  type?: GroupType;
  actual?: boolean;
  code?: GroupCode;
  organization: ModelReference<Organization>;
  member: PractitionerContainer[];
}
