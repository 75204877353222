import React, { useState } from "react";
import { FC, getClassName, IconC } from "@laba/react-common";
import { useImageContainerStyle } from "components/containers/ImageContainer/useImageContainerStyle";

interface Props {
  className?: string;
  url?: string;
  OnErrorIcon?: IconC;
  alt?: string;
}
export const ImageContainer: FC<Props> = ({
  className,
  url,
  OnErrorIcon,
  alt
}) => {
  const classes = useImageContainerStyle();
  const [photoError, setPhotoError] = useState(false);

  const errorIconClassName = getClassName(className, classes.errorIcon);
  return !photoError && url !== undefined ? (
    <img
      className={getClassName(classes.img, className)}
      src={url}
      alt={alt}
      onError={() => setPhotoError(true)}
    />
  ) : OnErrorIcon ? (
    <OnErrorIcon className={errorIconClassName} />
  ) : null;
};
