import React from "react";
import { FC } from "@laba/react-common";
import { Create } from "components/generic/Create/Create";
import {
  CodeSystemForm,
  codeSystemJsonToModel,
  codeSystemTransform
} from "components/pages/NexupAdmin/resources/codeSystem/CodeSystemForm";
import { ResourceType } from "@laba/nexup-api";

export const CodeSystemCreate: FC = () => {
  return (
    <Create
      resourceTransform={codeSystemTransform}
      resource={ResourceType.CodeSystem}
      jsonToModel={codeSystemJsonToModel}
    >
      <CodeSystemForm />
    </Create>
  );
};
