import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  ContactPointKey,
  ContactPointRole,
  ContactPointType,
  Gender,
  KnownCodeSystemSystem,
  OrganizationCombinedType,
  OrganizationListQueryParamsKey,
  PractitionerKey,
  ResourceType,
  ValidationStatus
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { DateInput } from "components/generic/DateInput/DateInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { IdentifierInput } from "components/generic/IdentifierInput/IdentifierInput";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { notUndefined } from "@laba/ts-common";
import { useGetFirstPractitionerOrganizationId } from "./hooks/useGetFirstPractitionerOrganizationId";

export const tk = tkCP.adminPage[ResourceType.Practitioner];
export const PractitionerPersonalContent: FC = () => {
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const { t } = useI18n();
  const firstPractitionerOrganizationId =
    useGetFirstPractitionerOrganizationId();

  const organizationId =
    firstPractitionerOrganizationId || workspaceOrganizationId;

  return (
    <>
      <TextInput disabled source={PractitionerKey.id} label={t(tk.fields.id)} />
      <ArrayInput<string>
        source={PractitionerKey.organization}
        label={t(tk.fields.organization)}
        defaultValue={[workspaceOrganizationId].filter(notUndefined)}
      >
        <OrganizationReferenceInput
          source=""
          label={t(tk.fields.organization)}
          type={OrganizationCombinedType.ProviderAndGroup}
          extraFilters={{
            [OrganizationListQueryParamsKey.active]: true
          }}
          required
          disabled={workspaceOrganizationId !== undefined}
        />
      </ArrayInput>
      <TextInput
        source={PractitionerKey.personalData.firstName}
        label={t(tk.fields.firstName)}
        required
      />
      <TextInput
        source={PractitionerKey.personalData.otherName}
        label={t(tk.fields.otherName)}
      />
      <TextInput
        source={PractitionerKey.personalData.lastName}
        label={t(tk.fields.lastName)}
        required
      />
      <TextInput
        source={PractitionerKey.personalData.motherLastName}
        label={t(tk.fields.motherLastName)}
      />
      <DateInput
        source={PractitionerKey.personalData.birthDate}
        label={t(tk.fields.birthDate)}
      />
      <EnumSelectorInput
        source={PractitionerKey.personalData.gender}
        label={t(tk.fields.gender)}
        enumValue={Gender}
        required
      />
      <IdentifierInput
        source={PractitionerKey.personalData.identifierList}
        organization={organizationId}
        system={KnownCodeSystemSystem.PersonIdentifierSystem}
      />
      <ArrayInput
        source={PractitionerKey.contactData.phoneList}
        label={t(tk.fields.phone)}
      >
        <TextInput
          source={ContactPointKey.value}
          label={t(tk.fields.phoneValue)}
          required
        />
        <EnumSelectorInput
          source={ContactPointKey.role}
          label={t(tk.fields.phoneRole)}
          enumValue={ContactPointRole}
        />
        <EnumSelectorInput
          source={ContactPointKey.type}
          label={t(tk.fields.phoneType)}
          enumValue={ContactPointType}
        />
      </ArrayInput>
      <ArrayInput
        source={PractitionerKey.contactData.emailList}
        label={t(tk.fields.email)}
      >
        <TextInput
          source={ContactPointKey.value}
          label={t(tk.fields.emailValue)}
          required
        />
        <EnumSelectorInput
          source={ContactPointKey.role}
          label={t(tk.fields.emailRole)}
          enumValue={ContactPointRole}
        />
        <EnumSelectorInput
          source={ContactPointKey.type}
          label={t(tk.fields.emailType)}
          enumValue={ContactPointType}
        />
      </ArrayInput>
      <TextInput
        disabled
        source={PractitionerKey.federationId}
        label={t(tk.fields.federationId)}
      />
      <EnumSelectorInput
        source={PractitionerKey.validationStatus}
        label={t(tk.fields.validationStatus)}
        enumValue={ValidationStatus}
        defaultValue={ValidationStatus.Temporal}
      />
      <TextInput
        source={PractitionerKey.hisCode}
        label={t(tk.fields.hisCode)}
      />
      <BooleanInput
        source={PractitionerKey.selfAdministered}
        label={t(tk.fields.selfAdministered)}
      />
    </>
  );
};
