import { tkCP } from "translation/i18n";
import { ConceptResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { FC, useI18n } from "@laba/react-common";
import React from "react";
import { TextInput } from "components/generic/TextInput/TextInput";
import {
  KnownCodeSystemSystem,
  ProcedureBatteryConceptKey
} from "@laba/nexup-api";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useSelector } from "react-redux";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";

export const tk = tkCP.adminPage[ConceptResourceType.ProcedureBatteryConcept];
export const ProcedureBatteryConceptContent: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);

  const procedureCodeTagSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.CodeSystemConceptProcedureCodeTag,
    workspaceOrganizationId
  );

  const procedureReportCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.ProcedureReportCode,
    workspaceOrganizationId
  );

  return (
    <>
      <TextInput
        source={ProcedureBatteryConceptKey.id}
        label={t(tk.fields.id)}
        required
      />
      <TextInput
        source={ProcedureBatteryConceptKey.display}
        label={t(tk.fields.display)}
        required
      />
      <ArrayInput
        source={ProcedureBatteryConceptKey.procedure}
        label={t(tk.fields.procedures)}
      >
        <CodeSystemSelectorInput
          source=""
          label=""
          codeSystem={procedureReportCodeSystem}
          organizationId={workspaceOrganizationId}
        />
      </ArrayInput>
      <ArrayInput
        source={ProcedureBatteryConceptKey.tag}
        label={t(tk.fields.tag)}
      >
        <CodeSystemSelectorInput
          source=""
          label=""
          codeSystem={procedureCodeTagSystem}
          organizationId={workspaceOrganizationId}
          withCreate
        />
      </ArrayInput>
    </>
  );
};
