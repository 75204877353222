/* eslint-disable react/no-array-index-key */
import { tkCP } from "translation/i18n";
import {
  FC,
  TypeVariant,
  useField,
  useFieldArray,
  useForm,
  useI18n
} from "@laba/react-common";
import { Grid, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import {
  Add,
  AutocompleteOptionConfig,
  DateInput,
  DefaultButton,
  ModelReferenceAutocomplete,
  PhoneInput,
  SearchIcon,
  SelectInput,
  SwitchButtonInput,
  TextInput
} from "@laba/nexup-components";
import { useDataProvider } from "react-admin";
import {
  getModelReferenceId,
  KnownCodeSystemSystem,
  Model,
  ModelReference,
  Organization,
  OrganizationListQueryParamsKey,
  OrganizationType,
  ResourceType
} from "@laba/nexup-api";
import { useAppDispatch } from "store/store";
import { downloadOrganizationCompleteConfiguration } from "store/organization/event";
import {
  workspaceConfigDataFromOrganizationConfiguration,
  WorkspaceLocationConfigData,
  WorkspacePractitionerConfigData,
  WorkspaceSpecialityConfigData
} from "models/organization/workspaceConfiguration";
import { UploadFileImageFormInput } from "components/generic/UploadFileImageFormInput/UploadFileImageFormInput";
import { useSelector } from "react-redux";
import { DateTime, toApiDay } from "@laba/ts-common";
import { WorkspaceAdministrationSpecialityField } from "components/pages/WorkspaceAdministration/WorkspaceAdministrationSpecialityField";
import { defaultWorkspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { RaResource } from "providers/dataProvider/resourceProvider/utils/resourceProviderTypes";
import { OrganizationConfiguration } from "models/organization/organizationConfiguration";
import { organizationWorkspaceSkipDownloadConfig } from "common/constants/organizationWorkspaceSkipDownloadConfig";
import { WorkspacePractitionerField } from "./WorkspacePractitionerField";
import { WorkspaceAdministrationLocationField } from "./WorkspaceAdministrationLocationField";

const tk = tkCP.adminPage.dashboard.workspaceAdministration;
const getOrganizationOptionFromValue = (
  organization: Organization
): AutocompleteOptionConfig<Organization> => ({
  text: organization.name,
  value: organization,
  title: organization.name
});
const minInputForSearch = 3;
export enum WorkspaceAdministrationMode {
  Create = "Create",
  Edit = "Edit"
}
const compareModelId = (v1: Model, v2: Model) => v1.id === v2.id;

export interface WorkspaceAdministrationFormContentProps {
  setLoading: (loading: boolean) => void;
}
export const WorkspaceAdministrationFormContent: FC<
  WorkspaceAdministrationFormContentProps
> = ({ setLoading }) => {
  const { t } = useI18n();
  const dataProvider = useDataProvider();
  const dispatch = useAppDispatch();
  const { initialize: formInitialize } = useForm();
  const baseOrganizationId = useSelector(
    defaultWorkspaceOrganizationIdSelector
  );

  const [mode, setMode] = useState<WorkspaceAdministrationMode>();
  const setCreateMode = useCallback(() => {
    setMode(WorkspaceAdministrationMode.Create);
  }, [setMode]);
  const setEditMode = useCallback(() => {
    setMode(WorkspaceAdministrationMode.Edit);
  }, [setMode]);

  const {
    input: { value: organizationValue, onBlur: organizationOnBlur },
    meta: { error: organizationError, touched: organizationTouched }
  } = useField<string, string>("organizationId");
  const getOrganizationValueHandler = useCallback(
    async (valueId: string) => {
      const result = await dataProvider.getOne(ResourceType.Organization, {
        id: valueId
      });
      return result.data;
    },
    [dataProvider]
  );
  const getOrganizationOptions = useCallback(
    async (searchText?: string) => {
      const result = await dataProvider.getList<RaResource<Organization>>(
        ResourceType.Organization,
        {
          filter: {
            [OrganizationListQueryParamsKey.type]: OrganizationType.Provider,
            [OrganizationListQueryParamsKey.content]: searchText
          },
          pagination: {
            page: 1,
            perPage: 30
          },
          sort: {
            field: "",
            order: ""
          }
        }
      );
      return result.data;
    },
    [dataProvider]
  );
  const onChangeOrganizationHandler = useCallback(
    async (value?: Organization) => {
      setLoading(true);
      const organizationConfig = await dispatch(
        downloadOrganizationCompleteConfiguration(
          value,
          organizationWorkspaceSkipDownloadConfig
        )
      );
      const workspaceConfig = organizationConfig
        ? workspaceConfigDataFromOrganizationConfiguration(organizationConfig)
        : {};
      formInitialize(workspaceConfig);

      setLoading(false);
    },
    [dispatch, formInitialize, setLoading]
  );

  const {
    input: {
      value: parentOrganizationValue,
      onChange: parentOrganizationOnChange,
      onBlur: parentOrganizationOnBlur
    },
    meta: { error: parentOrganizationError, touched: parentOrganizationTouched }
  } = useField<ModelReference<Organization>, string>("parentOrganization");

  const getParentOrganizationOptions = useCallback(
    async (searchText?: string) => {
      const result = await dataProvider.getList<RaResource<Organization>>(
        ResourceType.Organization,
        {
          filter: {
            [OrganizationListQueryParamsKey.type]: OrganizationType.Group,
            [OrganizationListQueryParamsKey.content]: searchText
          },
          pagination: {
            page: 1,
            perPage: 30
          },
          sort: {
            field: "",
            order: ""
          }
        }
      );
      return result.data;
    },
    [dataProvider]
  );
  const onChangeParentOrganizationHandler = useCallback(
    async (value?: Organization) => {
      if (mode === WorkspaceAdministrationMode.Create) {
        setLoading(true);
        const newOrganizationConfig: OrganizationConfiguration = {
          parentOrganizationName: value?.name,
          organization: {
            resourceType: ResourceType.Organization,
            type: OrganizationType.Provider,
            partOf: value?.id,
            active: true,
            name: ""
          }
        };
        const workspaceConfig =
          workspaceConfigDataFromOrganizationConfiguration(
            newOrganizationConfig
          );
        formInitialize(workspaceConfig);
        setLoading(false);
      }
      parentOrganizationOnChange(value);
    },
    [parentOrganizationOnChange, mode, formInitialize, setLoading]
  );

  const {
    input: { value: activeValue, onChange: activeOnChange }
  } = useField<boolean, string>("active");

  const {
    input: {
      value: organizationNameValue,
      onChange: organizationNameOnChange,
      onBlur: organizationNameOnBlur
    },
    meta: { error: organizationNameError, touched: organizationNameTouched }
  } = useField<string, string>("organizationName");

  const {
    input: {
      value: organizationCrmIdValue,
      onChange: organizationCrmIdOnChange,
      onBlur: organizationCrmIdOnBlur
    },
    meta: { error: organizationCrmIdError, touched: organizationCrmIdTouched }
  } = useField<string, string>("organizationCrmId");

  const {
    input: {
      value: organizationPictureUrlValue,
      onChange: organizationPictureUrlOnChange,
      onBlur: organizationPictureUrlOnBlur
    },
    meta: {
      error: organizationPictureUrlError,
      touched: organizationPictureUrlTouched
    }
  } = useField<string, string>("organizationPictureUrl");

  const {
    input: {
      value: organizationPhoneValue,
      onChange: organizationPhoneOnChange,
      onBlur: organizationPhoneOnBlur
    },
    meta: { error: organizationPhoneError, touched: organizationPhoneTouched }
  } = useField<string, string>("organizationPhone");

  const {
    input: {
      value: organizationEmailValue,
      onChange: organizationEmailOnChange,
      onBlur: organizationEmailOnBlur
    },
    meta: { error: organizationEmailError, touched: organizationEmailTouched }
  } = useField<string, string>("organizationEmail");

  const {
    input: {
      value: organizationAddressValue,
      onChange: organizationAddressOnChange,
      onBlur: organizationAddressOnBlur
    },
    meta: {
      error: organizationAddressError,
      touched: organizationAddressTouched
    }
  } = useField<string, string>("organizationAddress");

  const {
    input: {
      value: organizationPlanValue,
      onChange: organizationPlanOnChange,
      onBlur: organizationPlanOnBlur
    },
    meta: { error: organizationPlanError, touched: organizationPlanTouched }
  } = useField<string, string>("organizationPlan");

  const {
    input: {
      value: organizationPaymentStatusValue,
      onChange: organizationPaymentStatusOnChange,
      onBlur: organizationPaymentStatusOnBlur
    },
    meta: {
      error: organizationPaymentStatusError,
      touched: organizationPaymentStatusTouched
    }
  } = useField<string, string>("organizationPaymentStatus");

  const {
    input: {
      value: organizationPaymentExpirationValue,
      onChange: organizationPaymentExpirationOnChange,
      onBlur: organizationPaymentExpirationOnBlur
    },
    meta: {
      error: organizationPaymentExpirationError,
      touched: organizationPaymentExpirationTouched
    }
  } = useField<string, string>("organizationPaymentExpiration");
  const organizationPaymentExpirationValueDate = useMemo(() => {
    return organizationPaymentExpirationValue
      ? DateTime.fromApiDate(organizationPaymentExpirationValue)
      : undefined;
  }, [organizationPaymentExpirationValue]);
  const organizationPaymentExpirationOnChangeHandler = useCallback(
    (pickedDate?: DateTime) => {
      organizationPaymentExpirationOnChange(
        pickedDate ? toApiDay(pickedDate) : undefined
      );
    },
    [organizationPaymentExpirationOnChange]
  );

  const {
    fields: {
      value: organizationSpecialityListValue,
      update: organizationSpecialityListUpdate,
      push: organizationSpecialityListPush,
      remove: organizationSpecialityListRemove
    }
  } = useFieldArray<WorkspaceSpecialityConfigData>(
    "organizationSpeciality",
    undefined,
    undefined,
    initialValue => initialValue ?? []
  );

  const clientPlanSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.NexupClientPlan,
    baseOrganizationId
  );
  const clientPlanOptions =
    clientPlanSystem?.concept?.map(value => {
      const display = value.display || value.code || "";
      return {
        itemId: value.code,
        text: display,
        title: display,
        value: value.code
      };
    }) ?? [];
  if (
    organizationPlanValue &&
    !clientPlanOptions.find(element => element.value === organizationPlanValue)
  ) {
    clientPlanOptions.push({
      itemId: organizationPlanValue,
      text: organizationPlanValue,
      title: organizationPlanValue,
      value: organizationPlanValue
    });
  }

  const subscriptionStatusSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.OrganizationSubscriptionStatus,
    baseOrganizationId
  );
  const subscriptionStatusOptions =
    subscriptionStatusSystem?.concept?.map(value => {
      const display = value.display || value.code || "";
      return {
        itemId: value.code,
        text: display,
        title: display,
        value: value.code
      };
    }) ?? [];

  const {
    fields: {
      push: pushPractitionerConfigList,
      value: practitionerConfigList,
      remove: removePractitionerConfigList
    }
  } = useFieldArray<WorkspacePractitionerConfigData>(
    "practitionerConfigList",
    undefined,
    undefined,
    initialValue => initialValue ?? [{}]
  );

  const {
    fields: {
      value: locationListValue,
      update: locationListUpdate,
      push: locationListPush,
      remove: locationListRemove
    }
  } = useFieldArray<WorkspaceLocationConfigData>(
    "locationList",
    undefined,
    undefined,
    initialValue => initialValue ?? []
  );

  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      {mode == null && (
        <>
          <Grid item sm={6}>
            <DefaultButton
              title={t(tk.create)}
              text={t(tk.create)}
              onClick={setCreateMode}
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <DefaultButton
              title={t(tk.edit)}
              text={t(tk.edit)}
              onClick={setEditMode}
              fullWidth
            />
          </Grid>
        </>
      )}
      {mode === WorkspaceAdministrationMode.Edit && (
        <Grid item sm={12}>
          <Typography title={t(tk.toEditOrganization)}>
            {t(tk.toEditOrganization)}
          </Typography>
          <ModelReferenceAutocomplete<Organization>
            valueReference={organizationValue}
            onChange={onChangeOrganizationHandler}
            onBlur={organizationOnBlur}
            errorText={organizationError}
            showError={organizationTouched ?? true}
            getValue={getOrganizationValueHandler}
            getValues={getOrganizationOptions}
            getOptionFromValue={getOrganizationOptionFromValue}
            compareValues={compareModelId}
            fullWidth
            clearText={t(tk.clearText)}
            closeText={t(tk.closeText)}
            loadingText={t(tk.loadingText)}
            noOptionsText={t(tk.noOptionsText)}
            openText={t(tk.openText)}
            EndIcon={SearchIcon}
            endIconShouldNotRotate
            variant={TypeVariant.Outlined}
            minInputForSearch={minInputForSearch}
            minCharPromptText={t(tk.minCharPromptText, {
              minInputForSearch
            })}
            clearable
          />
        </Grid>
      )}
      {(mode === WorkspaceAdministrationMode.Create || organizationValue) && (
        <Grid item sm={12}>
          <Typography title={t(tk.parentOrganization)}>
            {t(tk.parentOrganization)}
          </Typography>
          <ModelReferenceAutocomplete<Organization>
            valueReference={parentOrganizationValue}
            onChange={onChangeParentOrganizationHandler}
            onBlur={parentOrganizationOnBlur}
            errorText={parentOrganizationError}
            showError={parentOrganizationTouched ?? true}
            getValue={getOrganizationValueHandler}
            getValues={getParentOrganizationOptions}
            getOptionFromValue={getOrganizationOptionFromValue}
            compareValues={(v1, v2) => v1.id === v2.id}
            fullWidth
            clearText={t(tk.clearText)}
            closeText={t(tk.closeText)}
            loadingText={t(tk.loadingText)}
            noOptionsText={t(tk.noOptionsText)}
            openText={t(tk.openText)}
            EndIcon={SearchIcon}
            endIconShouldNotRotate
            variant={TypeVariant.Outlined}
            minInputForSearch={minInputForSearch}
            minCharPromptText={t(tk.minCharPromptText, {
              minInputForSearch
            })}
            downloadOptionsIfEmpty
            clearable
          />
        </Grid>
      )}
      {((mode === WorkspaceAdministrationMode.Create &&
        parentOrganizationValue) ||
        (mode === WorkspaceAdministrationMode.Edit && organizationValue)) && (
        <>
          <Grid item sm={12}>
            <Typography title={t(tk.organizationName)}>
              {t(tk.organizationName)}
            </Typography>
            <TextInput
              value={organizationNameValue}
              onChange={organizationNameOnChange}
              onBlur={organizationNameOnBlur}
              errorText={organizationNameError}
              showError={organizationNameTouched ?? true}
              placeholder={t(tk.organizationName)}
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <Typography title={t(tk.organizationCrm)}>
              {t(tk.organizationCrm)}
            </Typography>
            <TextInput
              value={organizationCrmIdValue}
              onChange={organizationCrmIdOnChange}
              onBlur={organizationCrmIdOnBlur}
              errorText={organizationCrmIdError}
              showError={organizationCrmIdTouched ?? true}
              placeholder={t(tk.organizationCrm)}
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <Typography title={t(tk.active)}>{t(tk.active)} </Typography>
            <SwitchButtonInput
              value={activeValue}
              firstOption={{
                value: true,
                text: t(tk.active)
              }}
              secondOption={{
                value: false,
                text: t(tk.inactive)
              }}
              onChange={activeOnChange}
              fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <Typography title={t(tk.organizationPictureUrl)}>
              {t(tk.organizationPictureUrl)}
            </Typography>
            <UploadFileImageFormInput
              value={organizationPictureUrlValue}
              onChange={organizationPictureUrlOnChange}
              onBlur={organizationPictureUrlOnBlur}
              errorText={organizationPictureUrlError}
              showError={organizationPictureUrlTouched ?? true}
              label={t(tk.organizationPictureUrl)}
              uploadLabel={t(tk.organizationPictureUrl)}
            />
          </Grid>
          <Grid item sm={6}>
            <Typography title={t(tk.organizationPhone)}>
              {t(tk.organizationPhone)}
            </Typography>
            <PhoneInput
              value={organizationPhoneValue}
              onChange={organizationPhoneOnChange}
              onBlur={organizationPhoneOnBlur}
              errorText={
                organizationPhoneTouched ?? true
                  ? organizationPhoneError
                  : undefined
              }
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <Typography title={t(tk.organizationEmail)}>
              {t(tk.organizationEmail)}
            </Typography>
            <TextInput
              value={organizationEmailValue}
              onChange={organizationEmailOnChange}
              onBlur={organizationEmailOnBlur}
              errorText={organizationEmailError}
              showError={organizationEmailTouched ?? true}
              placeholder={t(tk.organizationEmail)}
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <Typography title={t(tk.organizationAddress)}>
              {t(tk.organizationAddress)}
            </Typography>
            <TextInput
              value={organizationAddressValue}
              onChange={organizationAddressOnChange}
              onBlur={organizationAddressOnBlur}
              errorText={organizationAddressError}
              showError={organizationAddressTouched ?? true}
              placeholder={t(tk.organizationAddress)}
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <Typography title={t(tk.organizationPlan)}>
              {t(tk.organizationPlan)}
            </Typography>
            <SelectInput
              value={organizationPlanValue}
              onChange={organizationPlanOnChange}
              onBlur={organizationPlanOnBlur}
              errorText={organizationPlanError}
              showError={organizationPlanTouched ?? true}
              placeholder={t(tk.organizationPlan)}
              options={clientPlanOptions}
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <Typography title={t(tk.organizationPaymentStatus)}>
              {t(tk.organizationPaymentStatus)}
            </Typography>
            <SelectInput
              value={organizationPaymentStatusValue}
              onChange={organizationPaymentStatusOnChange}
              onBlur={organizationPaymentStatusOnBlur}
              errorText={organizationPaymentStatusError}
              showError={organizationPaymentStatusTouched ?? true}
              placeholder={t(tk.organizationPaymentStatus)}
              options={subscriptionStatusOptions}
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <Typography title={t(tk.organizationPaymentExpiration)}>
              {t(tk.organizationPaymentExpiration)}
            </Typography>
            <DateInput
              value={organizationPaymentExpirationValueDate}
              onChange={organizationPaymentExpirationOnChangeHandler}
              onBlur={organizationPaymentExpirationOnBlur}
              errorText={organizationPaymentExpirationError}
              showError={organizationPaymentExpirationTouched ?? true}
              placeholder={t(tk.organizationPaymentExpiration)}
              fullWidth
              clearable
            />
          </Grid>
          <Grid item sm={12}>
            <Typography title={t(tk.organizationSpeciality)}>
              {t(tk.organizationSpeciality)}
            </Typography>
          </Grid>
          {organizationSpecialityListValue.map((speciality, idx) => {
            return (
              <WorkspaceAdministrationSpecialityField
                idx={idx}
                organizationId={getModelReferenceId(parentOrganizationValue)}
                specialityListRemove={organizationSpecialityListRemove}
                specialityListUpdate={organizationSpecialityListUpdate}
              />
            );
          })}
          <Grid item sm={12}>
            <DefaultButton
              fullWidth
              text={t(tk.organizationSpecialityAdd)}
              StartIcon={Add}
              onClick={() => {
                organizationSpecialityListPush({
                  name: ""
                });
              }}
            />
          </Grid>
          <Grid item sm={12}>
            <Typography title={t(tk.location)}>{t(tk.location)}</Typography>
          </Grid>
          {locationListValue.map((location, idx) => {
            return (
              <WorkspaceAdministrationLocationField
                idx={idx}
                locationListUpdate={locationListUpdate}
                locationListRemove={locationListRemove}
              />
            );
          })}
          <Grid item sm={12}>
            <DefaultButton
              fullWidth
              text={t(tk.locationAdd)}
              StartIcon={Add}
              onClick={() => {
                locationListPush({});
              }}
            />
          </Grid>
          <Grid item sm={12}>
            <Typography title={t(tk.practitioner)}>
              {t(tk.practitioner)}
            </Typography>
          </Grid>
          {practitionerConfigList.map((_, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid item sm={12} key={`practitioner ${idx}`}>
              <WorkspacePractitionerField
                index={idx}
                onDeleteRow={removePractitionerConfigList}
              />
            </Grid>
          ))}
          <Grid item sm={12}>
            <DefaultButton
              fullWidth
              text={t(tk.practitionerAdd)}
              StartIcon={Add}
              onClick={() => {
                pushPractitionerConfigList({});
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
