import { FC, TypeVariant } from "@laba/react-common";
import React from "react";
import { useLinkInputStyles } from "components/inputs/LinkInput/useLinkInputStyles";
import { LinkEntries } from "components/inputs/LinkInput/LinkEntries";
import { TextInput } from "components/inputs/TextInput/TextInput";
import { Add } from "components/icons";
import { isEmpty } from "lodash-es";
import { isValidUrl, Noop } from "@laba/ts-common";

export interface LinkInputProps {
  isMobile?: boolean;
  text: string;
  onChange: (text: string) => void;
  valueUrls: string[];
  addValue: Noop;
  removeValue: (valueUrl: string) => void;
  iframeTitle: string;
  placeholder?: string;
}

export const LinkInput: FC<LinkInputProps> = ({
  isMobile,
  text,
  onChange,
  addValue,
  removeValue,
  placeholder,
  valueUrls = []
}) => {
  const classes = useLinkInputStyles();

  return (
    <div className={classes.root}>
      <TextInput
        value={text}
        onChange={onChange}
        EndIcon={Add}
        onEndIconClick={addValue}
        disabledEndIcon={
          isEmpty(text) || !isValidUrl(text) || valueUrls.includes(text)
        }
        variant={TypeVariant.Outlined}
        placeholder={placeholder}
        disableEnterKeyDefaultAction
        onEnterKeyPress={isEndIconDisabled => {
          if (!isEndIconDisabled) {
            addValue();
          }
        }}
      />
      <div className={classes.selectedItems}>
        <LinkEntries
          isMobile={isMobile}
          urls={valueUrls}
          onRemoveClick={removeValue}
          itemClassName={classes.item}
        />
      </div>
    </div>
  );
};
