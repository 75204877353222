import {
  borderMixin,
  createStyle,
  flexColumnMixin,
  FlexJustifyContent,
  pxToRem
} from "@laba/react-common";
import { getThemeRadius } from "model/themeUtils";
import { RadiusVariant, Theme } from "model/theme";

export const useVerticalTabContainerStyle = createStyle(
  (theme: Theme) => ({
    container: {
      ...flexColumnMixin({
        justifyContent: FlexJustifyContent.FlexStart
      }),
      ...borderMixin({
        radius: { top: getThemeRadius(theme, RadiusVariant.Small) }
      }),
      rowGap: pxToRem(1),
      overflow: "hidden"
    }
  }),
  "VerticalTabContainer"
);
