import { Day } from "model/primitives/time/time";
import { OpenCode } from "model/primitives/model/model";
import { ApiDate, ApiHour } from "@laba/ts-common";

export enum Meals {
  BreakFast = "CM",
  Lunch = "CD",
  Snack = "AFT",
  Dinner = "CV"
}
export type MealsModifierOrExtra = MealsModifier | MealsModifierExtra;

export enum MealsModifierExtra {
  During = "During"
}

export enum MealsModifier {
  Before = "Before",
  After = "After"
}

export enum FrequencyUnit {
  Hour = "h",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Day = "d",
  Week = "wk",
  Minute = "min",
  Month = "mo"
}

export type PeriodFrequencyUnit = FrequencyUnit.Day | FrequencyUnit.Hour;

export enum RequestFrequencyType {
  FixedHours = "FixedHours",
  TimeInterval = "TimeInterval",
  WithMeals = "WithMeals",
  OnEvent = "OnEvent",
  Once = "Once",
  WithoutFrequency = "WithoutFrequency"
}

export enum KnownFrequencyEvent {
  Fever = "Fever",
  Diarrhea = "Diarrhea",
  Pain = "Pain",
  Nausea = "Nausea",
  Convulsion = "Convulsion",
  Unrest = "Unrest",
  Anxiety = "Anxiety"
}

export type FrequencyEvent = OpenCode<KnownFrequencyEvent>;

export interface Frequency {
  startDate?: ApiDate;
  endDate?: ApiDate;
  type?: RequestFrequencyType;
  timeList: ApiHour[];
  period?: number;
  periodUnit?: PeriodFrequencyUnit;
  meals: Meals[];
  mealsModifier?: MealsModifier;
  extraDoseNow?: boolean;
  event?: FrequencyEvent;
  weekDayList: Day[];
}
