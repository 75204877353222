import { Interval as LuxonInterval } from "luxon";
import { DateTime } from "date/datetime";
import { ApiHour } from "date/apiDate";

export class Interval extends LuxonInterval {}

export const getIntervalWindowList = (
  date: DateTime,
  start: ApiHour,
  end: ApiHour
): Interval[] => {
  const startDateTime = DateTime.fromApiHour(start, date);
  if (end >= start) {
    return [
      Interval.fromDateTimes(startDateTime, DateTime.fromApiHour(end, date))
    ];
  }
  return [
    Interval.fromDateTimes(
      startDateTime.minus({ day: 1 }),
      DateTime.fromApiHour(end, date)
    ),
    Interval.fromDateTimes(
      startDateTime,
      DateTime.fromApiHour(end, date.plus({ day: 1 }))
    )
  ];
};
