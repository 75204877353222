import { tkCP } from "translation/i18n";
import { FC, TypeVariant, useField, useI18n } from "@laba/react-common";
import { Grid, Typography } from "@mui/material";
import React, { useCallback } from "react";
import {
  AutocompleteOptionConfig,
  ModelReferenceAutocomplete,
  SearchIcon,
  UploadArrow
} from "@laba/nexup-components";
import { useDataProvider } from "react-admin";
import {
  getModelReferenceId,
  ImportQuestionnaireResponseRequestBodyParamsKey,
  Organization,
  OrganizationListQueryParamsKey,
  OrganizationType,
  Questionnaire,
  QuestionnaireListQueryParamsKey,
  PublicationStatus,
  ResourceType
} from "@laba/nexup-api";
import { UploadFileButton } from "components/generic/UploadFileButton/UploadFileButton";
import { head, isEmpty } from "lodash-es";

const tk = tkCP.adminPage.dashboard.importQuestionnaireResponse;
const getOrganizationOptionFromValue = (
  organization: Organization
): AutocompleteOptionConfig<Organization> => ({
  text: organization.name,
  value: organization,
  title: organization.name
});
const getQuestionnaireOptionFromValue = (
  questionnaire: Questionnaire
): AutocompleteOptionConfig<Questionnaire> => ({
  text: `${questionnaire.name} (${questionnaire.id})`,
  value: questionnaire,
  title: `${questionnaire.name} (${questionnaire.id})`
});
const minInputForSearch = 3;
export const QuestionnaireResponseImportInputFormContent: FC = () => {
  const { t } = useI18n();
  const {
    input: {
      value: organizationValue,
      onChange: organizationOnChange,
      onBlur: organizationOnBlur
    },
    meta: { error: organizationError, touched: organizationTouched }
  } = useField<string, string>(
    ImportQuestionnaireResponseRequestBodyParamsKey.organization
  );
  const {
    input: { value: fileValue, onChange: fileOnChange },
    meta: { error: fileError, touched: fileTouched }
  } = useField<File, string>(
    ImportQuestionnaireResponseRequestBodyParamsKey.file
  );
  const {
    input: {
      value: questionnaireValue,
      onChange: questionnaireOnChange,
      onBlur: questionnaireOnBlur
    },
    meta: { error: questionnaireError, touched: questionnaireTouched }
  } = useField<string, string>(
    ImportQuestionnaireResponseRequestBodyParamsKey.questionnaire
  );
  const dataProvider = useDataProvider();

  const onChangeOrganizationHandler = useCallback(
    (value?: Organization) => {
      organizationOnChange(getModelReferenceId(value));
    },
    [organizationOnChange]
  );
  const getOrganizationValueHandler = useCallback(
    async (valueId: string) => {
      const result = await dataProvider.getOne(ResourceType.Organization, {
        id: valueId
      });
      return result.data;
    },
    [dataProvider]
  );
  const getOrganizationOptions = useCallback(
    async (searchText?: string) => {
      const result = await dataProvider.getList(ResourceType.Organization, {
        filter: {
          [OrganizationListQueryParamsKey.type]: OrganizationType.Provider,
          [OrganizationListQueryParamsKey.content]: searchText
        },
        pagination: {
          page: 1,
          perPage: 30
        },
        sort: {
          field: "",
          order: ""
        }
      });
      return result.data;
    },
    [dataProvider]
  );

  const onChangeFileHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      fileOnChange(head(e.target.files));
    },
    [fileOnChange]
  );
  const uploadFileButtonText =
    !isEmpty(fileError) && fileTouched
      ? t(tk.fileInputWithError, {
          error: fileError
        })
      : fileValue
      ? t(tk.fileInputWithFile, {
          fileName: fileValue.name
        })
      : t(tk.fileInputEmpty);

  const onChangeQuestionnaireHandler = useCallback(
    (value?: Questionnaire) => {
      questionnaireOnChange(getModelReferenceId(value));
    },
    [questionnaireOnChange]
  );
  const getQuestionnaireValueHandler = useCallback(
    async (valueId: string) => {
      const result = await dataProvider.getOne(ResourceType.Questionnaire, {
        id: valueId
      });
      return result.data;
    },
    [dataProvider]
  );
  const getQuestionnaireOptions = useCallback(
    async (searchText?: string) => {
      const result = await dataProvider.getList(ResourceType.Questionnaire, {
        filter: {
          [QuestionnaireListQueryParamsKey.status]: PublicationStatus.Active,
          [QuestionnaireListQueryParamsKey.content]: searchText
        },
        pagination: {
          page: 1,
          perPage: 30
        },
        sort: {
          field: "",
          order: ""
        }
      });
      return result.data;
    },
    [dataProvider]
  );

  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      <Grid item sm={12}>
        <Typography title={t(tk.organization)}>{t(tk.organization)}</Typography>
        <ModelReferenceAutocomplete<Organization>
          valueReference={organizationValue}
          onChange={onChangeOrganizationHandler}
          onBlur={organizationOnBlur}
          errorText={organizationError}
          showError={organizationTouched ?? true}
          getValue={getOrganizationValueHandler}
          getValues={getOrganizationOptions}
          getOptionFromValue={getOrganizationOptionFromValue}
          compareValues={(v1, v2) => v1.id === v2.id}
          fullWidth
          clearText={t(tk.clearText)}
          closeText={t(tk.closeText)}
          loadingText={t(tk.loadingText)}
          noOptionsText={t(tk.noOptionsText)}
          openText={t(tk.openText)}
          EndIcon={SearchIcon}
          endIconShouldNotRotate
          variant={TypeVariant.Outlined}
          minInputForSearch={minInputForSearch}
          minCharPromptText={t(tk.minCharPromptText, {
            minInputForSearch
          })}
          clearable
        />
      </Grid>
      <Grid item sm={12}>
        <Typography title={t(tk.file)}>{t(tk.file)} </Typography>
        <UploadFileButton
          label={uploadFileButtonText}
          fileType=""
          onFileAdded={onChangeFileHandler}
          Icon={UploadArrow}
        />
      </Grid>
      <Grid item sm={12}>
        <Typography title={t(tk.questionnaire)}>
          {t(tk.questionnaire)}
        </Typography>
        <ModelReferenceAutocomplete<Questionnaire>
          valueReference={questionnaireValue}
          onChange={onChangeQuestionnaireHandler}
          onBlur={questionnaireOnBlur}
          errorText={questionnaireError}
          showError={questionnaireTouched ?? true}
          getValue={getQuestionnaireValueHandler}
          getValues={getQuestionnaireOptions}
          getOptionFromValue={getQuestionnaireOptionFromValue}
          compareValues={(v1, v2) => v1.id === v2.id}
          fullWidth
          clearText={t(tk.clearText)}
          closeText={t(tk.closeText)}
          loadingText={t(tk.loadingText)}
          noOptionsText={t(tk.noOptionsText)}
          openText={t(tk.openText)}
          EndIcon={SearchIcon}
          endIconShouldNotRotate
          variant={TypeVariant.Outlined}
          minInputForSearch={minInputForSearch}
          minCharPromptText={t(tk.minCharPromptText, {
            minInputForSearch
          })}
          clearable
        />
      </Grid>
    </Grid>
  );
};
