/* eslint-disable react/no-array-index-key */
import { FC, getClassName } from "@laba/react-common";
import { Optional, Noop } from "@laba/ts-common";
import { SearchIcon } from "components/icons";
import { TextInput } from "components/inputs/TextInput/TextInput";
import { isEmpty, isEqual } from "lodash-es";
import React from "react";
import { ScrollableContainer } from "components/containers/ScrollableContainer/ScrollableContainer";
import { MenuParentNode } from "components/menu/menuTreeTypes";
import { useMenuDropdownColumnStyles } from "./useMenuDropdownColumnStyles";
import { MenuDropdownItem } from "./MenuDropdownItem/MenuDropdownItem";

export interface MenuDropdownColumnProps {
  className?: string;
  categories: MenuParentNode[];
  selectedElement?: Optional<MenuParentNode>;
  setSelectedElement: (node: MenuParentNode) => void;
  showSearchbar?: boolean;
  searchText?: string;
  onChangeSearchText?: (s: string) => void;
  secondary?: boolean;
  menuHeight: number;
  columnWidth: number;
  onClose?: Noop;
}

export const MenuDropdownColumn: FC<MenuDropdownColumnProps> = ({
  className,
  categories,
  selectedElement,
  setSelectedElement,
  showSearchbar = false,
  searchText = "",
  onChangeSearchText,
  secondary = false,
  menuHeight = 400,
  columnWidth,
  onClose
}: MenuDropdownColumnProps) => {
  const classes = useMenuDropdownColumnStyles({
    menuHeight,
    columnWidth
  });
  const fullClassname = getClassName(
    className,
    classes.root,
    secondary ? classes.secondary : undefined
  );
  const categoryTitleClassname = getClassName(
    className,
    classes.categoryTitle,
    secondary ? classes.secondaryTitle : undefined
  );
  return (
    <div className={fullClassname}>
      {showSearchbar && (
        <div className={classes.searchbarContainer}>
          <TextInput
            autoFocus
            value={searchText}
            onChange={onChangeSearchText}
            EndIcon={SearchIcon}
            fullWidth
          />
        </div>
      )}
      <ScrollableContainer className={classes.scrollContainer}>
        <div className={classes.categoriesContainer}>
          {categories.map((category, cIndex) => (
            <div
              key={cIndex}
              className={
                showSearchbar
                  ? getClassName(
                      classes.itemCategory,
                      classes.itemCategoryTopPadding
                    )
                  : classes.itemCategory
              }
            >
              {category.title && !isEmpty(category.children) && (
                <h3 className={categoryTitleClassname}>{category.title}</h3>
              )}
              {category.children.map((el, iIndex) => (
                <MenuDropdownItem
                  key={iIndex}
                  node={el}
                  selected={isEqual(selectedElement, el)}
                  setSelectedElement={setSelectedElement}
                  onClose={onClose}
                />
              ))}
            </div>
          ))}
        </div>
      </ScrollableContainer>
    </div>
  );
};
