/* eslint-disable no-empty */
import React from "react";
import { JssDefaultTheme, StyleProvider } from "style/StyleProvider";
import { StoryDecorator } from "storybook/decorator/types";
import { get, isNil, merge } from "lodash-es";
import { Optional } from "@laba/ts-common";
import { produce } from "immer";

export const getStyleThemeDecorator = <T extends JssDefaultTheme>(
  theme: T
): StoryDecorator => {
  return (story, context) => {
    let localTheme: T = theme;
    try {
      const globalThemeString: Optional<string> = get(context, "args.theme");
      if (!isNil(globalThemeString)) {
        const globalTheme = JSON.parse(globalThemeString);
        localTheme = produce(theme, draftTheme =>
          merge(draftTheme, globalTheme)
        );
      }
    } catch (e) {}
    return <StyleProvider theme={localTheme}>{story()}</StyleProvider>;
  };
};
