import { Noop, Page, sleep } from "@laba/ts-common";
import { useEffectSelective } from "hooks/useEffectSelective";
import { isEmpty, size } from "lodash-es";
import { useCallback, useEffect, useMemo, useState } from "react";

export interface UseLocalPaginationElementsReturn<T> {
  pageElementListInfo: Page<T>;
  loadMore: Noop;
  hasNoResult: boolean;
}

export const useLocalPaginationElements = <T>(
  elementList?: T[],
  pageSize = 10
): UseLocalPaginationElementsReturn<T> => {
  const EmptyList = useMemo<T[]>(() => [], []);
  const listElements = elementList ?? EmptyList;
  const [currentNumberPage, setCurrentNumberPage] = useState(0);
  const [paginatedElements, setPaginatedElements] = useState<T[]>([]);
  const sizeElementList = size(listElements);

  const pageEntries = Math.ceil(sizeElementList / pageSize);

  const onLoadMore = useCallback(async () => {
    await sleep(100);
    setCurrentNumberPage(prevV => prevV + 1);
  }, []);

  useEffect(() => {
    setCurrentNumberPage(0);
    setPaginatedElements([]);
  }, [listElements]);

  useEffectSelective(() => {
    const startIndex = currentNumberPage * pageSize;
    const endIndex = Math.min(startIndex + pageSize, sizeElementList);

    const newEntries = listElements.slice(startIndex, endIndex);
    setPaginatedElements(list => [...list, ...newEntries]);
  }, [currentNumberPage, listElements]);

  const pageElementListInfo: Page<T> = useMemo(() => {
    return {
      entries: paginatedElements,
      page: {
        page: currentNumberPage,
        pageEntries,
        pageSize,
        totalEntries: sizeElementList
      }
    };
  }, [
    paginatedElements,
    pageEntries,
    pageSize,
    sizeElementList,
    currentNumberPage
  ]);

  return {
    loadMore: onLoadMore,
    pageElementListInfo,
    hasNoResult: isEmpty(paginatedElements)
  };
};
