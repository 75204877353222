import React from "react";
import { FC, useI18n } from "@laba/react-common";
import { OrganizationKey, ResourceType } from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { NumberInput } from "components/generic/NumberInput/TextInput";

export const tk = tkCP.adminPage[ResourceType.Organization];
export const OrganizationOnlineBookingContent: FC = () => {
  const { t } = useI18n();
  return (
    <>
      <TextInput
        source={OrganizationKey.onlineAppointmentBookingConfiguration.notes}
        label={t(tk.fields.onlineAppointmentBookingConfigurationNotes)}
      />
      <NumberInput
        source={
          OrganizationKey.onlineAppointmentBookingConfiguration.bookingWindow
        }
        label={t(tk.fields.onlineAppointmentBookingConfigurationBookingWindow)}
      />
    </>
  );
};
