import {
  borderMixinRadiusAll,
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  flexItemMixin,
  FlexJustifyContent,
  getClassName,
  pxToRem
} from "@laba/react-common";
import { RadiusVariant, Theme, ThemeStyleColorVariant } from "model/theme";
import {
  getThemeRadius,
  getThemeStyleColor,
  themeElevationMixin,
  themeTextMixin
} from "model/themeUtils";
import { StyleVariant, TextVariant } from "model/themeVariant";
import { ElevationVariant, getMapValueBuilder } from "@laba/ts-common";
import { ClassNameDynamicGeneratedRecord } from "components/utils/getGenericButtonColors";
import {
  profilePictureSizeExtraLarge,
  profilePictureSizeExtraSmall,
  profilePictureSizeLarge,
  profilePictureSizeMedium,
  profilePictureSizeSmall,
  ProfilePictureSizeVariant
} from "./utils";

const getSize = getMapValueBuilder({
  [ProfilePictureSizeVariant.ExtraLarge]: pxToRem(profilePictureSizeExtraLarge),
  [ProfilePictureSizeVariant.Large]: pxToRem(profilePictureSizeLarge),
  [ProfilePictureSizeVariant.Medium]: pxToRem(profilePictureSizeMedium),
  [ProfilePictureSizeVariant.Small]: pxToRem(profilePictureSizeSmall),
  [ProfilePictureSizeVariant.ExtraSmall]: pxToRem(profilePictureSizeExtraSmall)
});

const getTextSize = getMapValueBuilder({
  [ProfilePictureSizeVariant.ExtraLarge]: TextVariant.H3,
  [ProfilePictureSizeVariant.Large]: TextVariant.H6,
  [ProfilePictureSizeVariant.Medium]: TextVariant.Body1,
  [ProfilePictureSizeVariant.Small]: TextVariant.Body2,
  [ProfilePictureSizeVariant.ExtraSmall]: TextVariant.Overline
});

const generatedSizeStyleKeyTemplate = (
  sizeVariant: ProfilePictureSizeVariant
) => `generatedStyle${sizeVariant}`;

const generateDynamicSizeStylesClassNames =
  (): ClassNameDynamicGeneratedRecord => {
    const classNameObject: ClassNameDynamicGeneratedRecord = {};
    Object.values(ProfilePictureSizeVariant).forEach(sizeVariant => {
      classNameObject[generatedSizeStyleKeyTemplate(sizeVariant)] = {
        height: getSize(sizeVariant),
        width: getSize(sizeVariant)
      };
    });
    return classNameObject;
  };

const generateDynamicTextSizeStylesClassNames = (theme: Theme) => {
  return Object.fromEntries(
    Object.values(ProfilePictureSizeVariant).map(sizeVariant => [
      sizeVariant,
      {
        ...themeTextMixin(theme, getTextSize(sizeVariant))
      }
    ])
  );
};

export interface ProfilePictureStyleClassNames {
  img: string;
  noImageContainer: string;
  noImageText: string;
}

export interface ProfilePictureStyleProps {
  style: StyleVariant;
  initials: string;
  size: ProfilePictureSizeVariant;
  inverted: boolean;
}

const useProfilePictureStyleStatic = createStyle(
  (theme: Theme) => ({
    borderRadiusSmall: {
      ...borderMixinRadiusAll(getThemeRadius(theme, RadiusVariant.Small))
    },
    img: {
      objectFit: "cover"
    },
    noImageContainerStatic: {
      ...flexColumnMixin({
        justifyContent: FlexJustifyContent.Center,
        alignItems: FlexAlignItems.Center
      }),
      ...flexItemMixin({
        shrink: 0
      })
    },
    noImageTextStatic: {
      ...themeTextMixin(theme, TextVariant.Caption, 1),
      textAlign: "center"
    },
    invertedElevation: {
      ...themeElevationMixin(theme, ElevationVariant.Elevation2)
    }
  }),
  "ProfilePictureStatic"
);

const useProfilePictureSizeStyle = createStyle<Theme, unknown, string>(
  (_theme: Theme) => ({
    ...generateDynamicSizeStylesClassNames()
  }),
  "ProfilePictureSize"
);

const useProfilePictureTextSizeStyle = createStyle<Theme, unknown, string>(
  (theme: Theme) => ({
    ...generateDynamicTextSizeStylesClassNames(theme)
  }),
  "ProfilePictureTextSize"
);

const useProfilePictureStyleDynamic = createStyle(
  (theme: Theme) => ({
    noImageContainerDynamic: (props: ProfilePictureStyleProps) => ({
      backgroundColor: getThemeStyleColor(
        theme,
        props.style,
        props.inverted
          ? ThemeStyleColorVariant.Contrast
          : ThemeStyleColorVariant.Main
      )
    }),
    noImageTextDynamic: (props: ProfilePictureStyleProps) => ({
      color: getThemeStyleColor(
        theme,
        props.style,
        props.inverted
          ? ThemeStyleColorVariant.Main
          : ThemeStyleColorVariant.Contrast
      )
    })
  }),
  "ProfilePictureDynamic"
);

export const useProfilePictureStyle = (
  props: ProfilePictureStyleProps
): ProfilePictureStyleClassNames => {
  const staticClasses = useProfilePictureStyleStatic();
  const dynamicClasses = useProfilePictureStyleDynamic(props);
  const sizeClasses = useProfilePictureSizeStyle();
  const textSizeClasses = useProfilePictureTextSizeStyle();

  const defaultNoImageContainerClasses = getClassName(
    staticClasses.noImageContainerStatic,
    staticClasses.borderRadiusSmall,
    dynamicClasses.noImageContainerDynamic,
    sizeClasses[generatedSizeStyleKeyTemplate(props.size)]
  );

  return {
    img: getClassName(
      staticClasses.img,
      staticClasses.borderRadiusSmall,
      sizeClasses[generatedSizeStyleKeyTemplate(props.size)]
    ),
    noImageContainer: props.inverted
      ? getClassName(
          defaultNoImageContainerClasses,
          staticClasses.invertedElevation
        )
      : defaultNoImageContainerClasses,
    noImageText: getClassName(
      staticClasses.noImageTextStatic,
      dynamicClasses.noImageTextDynamic,
      textSizeClasses[props.size]
    )
  };
};
