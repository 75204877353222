export interface FileBlobItem {
  blob?: Blob;
  url: string;
  fileName?: string;
  loading?: boolean;
}

export const getFileNameOrDefault = (
  fileName?: string,
  defaultName?: string
): string => fileName ?? defaultName ?? "file";
