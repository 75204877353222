import { useState } from "react";
import { useEffectSelective } from "hooks/useEffectSelective";

export const useEffectQueryParams = <T>(
  onFirstRender: () => Promise<void> | void,
  onAnotherRender: () => Promise<void> | void,
  queryParams: T
): void => {
  const [firstRender, setFirstRender] = useState(true);

  useEffectSelective(
    async () => {
      await onFirstRender();
      setFirstRender(false);
    },
    [],
    [onFirstRender, setFirstRender]
  );
  useEffectSelective(
    async () => {
      if (firstRender) return;
      await onAnotherRender();
    },
    [queryParams, firstRender],
    [onAnotherRender]
  );
};
