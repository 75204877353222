import {
  ResourceType,
  getMedicalRequestDefinitionList,
  getMedicalRequestDefinition,
  updateMedicalRequestDefinition,
  createMedicalRequestDefinition,
  MedicalRequestDefinitionListQueryParams,
  MedicalRequestDefinitionListQueryParamsKey,
  getModelReferenceId
} from "@laba/nexup-api";
import {
  getEnumOrderParam,
  getKeyTargetParam,
  resourceProviderGetter
} from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { workspaceOrganizationSelector } from "store/workspace/selectors";
import { store } from "store/store";

export const medicalRequestDefinitionProvider = resourceProviderGetter(
  ResourceType.MedicalRequestDefinition,
  getEnumOrderParam({}),
  getKeyTargetParam<MedicalRequestDefinitionListQueryParams>(
    MedicalRequestDefinitionListQueryParamsKey
  ),
  getMedicalRequestDefinitionList,
  getMedicalRequestDefinition,
  updateMedicalRequestDefinition,
  createMedicalRequestDefinition,
  undefined,
  () => ({
    organization: getModelReferenceId(
      workspaceOrganizationSelector(store.getState())
    )
  })
);
