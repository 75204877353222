import {
  Children,
  IconC,
  ReactElement,
  ReactElementOrNull,
  StyleVariant
} from "@laba/react-common";
import React from "react";
import { BaseIconButton } from "components/buttons/BaseIconButton/BaseIconButton";
import { CloseIcon } from "components/icons";
import { isUndefined } from "lodash-es";
import { AutocompleteOptionTextItem } from "components/autocomplete/Autocomplete/AutocompleteOption/BaseAutocompleteOption/AutocompleteOptionTextItem/AutocompleteOptionTextItem";
import { useAutocompleteOptionStyles } from "./useBaseAutocompleteOptionStyles";

export interface BaseAutocompleteOptionProps<V> {
  isMobile?: boolean;
  children?: Children;
  optionValue: V;
  onRemoveSelectedOption?: (value: V) => void;
  removeIconStyle?: StyleVariant;
  disabled?: boolean;
  hasBackgroundColor?: boolean;
  fullWidth?: boolean;
  isSelectedOption?: boolean;
  title?: string;
  titleText?: string;
  primaryText?: string;
  subtext?: string;
  actionControlComponent?: ReactElement;
  Icon?: IconC;
  endChildrenPosition?: boolean;
  withPadding?: boolean;
}

type CloseIconComponentProps<V> = Pick<
  BaseAutocompleteOptionProps<V>,
  "optionValue" | "onRemoveSelectedOption" | "removeIconStyle" | "disabled"
>;

const CloseIconComponent = <V,>({
  optionValue,
  onRemoveSelectedOption,
  removeIconStyle,
  disabled
}: CloseIconComponentProps<V>): ReactElementOrNull => {
  if (isUndefined(onRemoveSelectedOption)) return null;

  return (
    <BaseIconButton
      Icon={CloseIcon}
      onClick={() => onRemoveSelectedOption(optionValue)}
      style={removeIconStyle}
      withoutPadding
      disabled={disabled}
    />
  );
};

export const BaseAutocompleteOption = <V,>({
  children,
  optionValue,
  onRemoveSelectedOption,
  removeIconStyle = StyleVariant.BlackWhite,
  disabled,
  hasBackgroundColor,
  fullWidth,
  isSelectedOption,
  isMobile,
  title,
  titleText,
  primaryText,
  subtext,
  actionControlComponent,
  Icon,
  endChildrenPosition,
  withPadding
}: BaseAutocompleteOptionProps<V>): ReactElement => {
  const classes = useAutocompleteOptionStyles({
    disabled,
    hasBackgroundColor,
    fullWidth,
    isSelectedOption,
    isMobile,
    endChildrenPosition,
    withPadding
  });

  return isMobile ? (
    <div className={classes.root}>
      <div className={classes.mobileRow}>
        <AutocompleteOptionTextItem
          title={title}
          titleText={titleText}
          primaryText={primaryText}
          secondaryText={subtext}
          Icon={Icon}
        />
        {actionControlComponent || (
          <CloseIconComponent
            optionValue={optionValue}
            disabled={disabled}
            onRemoveSelectedOption={onRemoveSelectedOption}
            removeIconStyle={removeIconStyle}
          />
        )}
      </div>
      {children}
    </div>
  ) : (
    <div className={classes.root}>
      <AutocompleteOptionTextItem
        title={title}
        titleText={titleText}
        primaryText={primaryText}
        secondaryText={subtext}
        Icon={Icon}
      />
      {children}
      {actionControlComponent || (
        <CloseIconComponent
          optionValue={optionValue}
          disabled={disabled}
          onRemoveSelectedOption={onRemoveSelectedOption}
          removeIconStyle={removeIconStyle}
        />
      )}
    </div>
  );
};
