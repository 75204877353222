import { tkCC } from "translation/i18n";
import { FC, useI18n } from "@laba/react-common";
import React, { useCallback } from "react";
import {
  AppointmentNotificationConfigCodes,
  ScheduleNotificationConfigKey
} from "@laba/nexup-api";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { NumberInput } from "components/generic/NumberInput/TextInput";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { Typography } from "@mui/material";
import { joinTextNotUndefined } from "@laba/ts-common";

interface ScheduleNotificationConfigInputProps {
  source: string;
  title: string;
}

const tk = tkCC.inputs.scheduleNotificationConfigInput;

export const ScheduleNotificationConfigInput: FC<
  ScheduleNotificationConfigInputProps
> = ({ source, title }) => {
  const { t } = useI18n();

  const getNotificationConfigInputSource = useCallback(
    (key: string) => joinTextNotUndefined([source, key], "."),
    [source]
  );

  const getNotificationConfigInputLabel = useCallback(
    (label: string) => joinTextNotUndefined([label, title.toLowerCase()], " "),
    [title]
  );

  return (
    <>
      <Typography>{title}</Typography>
      <ArrayInput
        source={getNotificationConfigInputSource(
          ScheduleNotificationConfigKey.codeList
        )}
        label={getNotificationConfigInputLabel(t(tk.codeList))}
      >
        <EnumSelectorInput
          source=""
          label={getNotificationConfigInputLabel(t(tk.code))}
          enumValue={AppointmentNotificationConfigCodes}
          withCreate
        />
      </ArrayInput>
      <BooleanInput
        source={getNotificationConfigInputSource(
          ScheduleNotificationConfigKey.notifyPhone
        )}
        label={getNotificationConfigInputLabel(t(tk.notifyPhone))}
      />
      <BooleanInput
        source={getNotificationConfigInputSource(
          ScheduleNotificationConfigKey.notifyEmail
        )}
        label={getNotificationConfigInputLabel(t(tk.notifyEmail))}
      />
      <BooleanInput
        source={getNotificationConfigInputSource(
          ScheduleNotificationConfigKey.active
        )}
        label={getNotificationConfigInputLabel(t(tk.active))}
      />
      <ArrayInput
        source={getNotificationConfigInputSource(
          ScheduleNotificationConfigKey.reminderList
        )}
        label={getNotificationConfigInputLabel(t(tk.reminderList))}
      >
        <NumberInput
          source=""
          label={getNotificationConfigInputLabel(t(tk.reminder))}
          min={0}
          step={1}
        />
      </ArrayInput>
      <BooleanInput
        source={getNotificationConfigInputSource(
          ScheduleNotificationConfigKey.override
        )}
        label={getNotificationConfigInputLabel(t(tk.override))}
      />
    </>
  );
};
