import { FC, getClassName, TextVariant } from "@laba/react-common";
import React from "react";
import Markdown from "markdown-to-jsx";
import { useNexupMarkdownStyles } from "components/text/NexupMarkdown/useNexupMarkdownStyles";
import removeMd from "remove-markdown";
import { removeRepeatedFormatCharactersFromText } from "@laba/ts-common";

export interface NexupMarkdownProps {
  forceBlock?: boolean;
  forceInline?: boolean;
  forceWrapper?: boolean;
  text?: string;
  className?: string;
  defaultVariant?: TextVariant;
  maxLines?: number;
  title?: string;
}

const isHeadingTag = (variant: TextVariant) => {
  const headingsList = [
    TextVariant.H1,
    TextVariant.H2,
    TextVariant.H3,
    TextVariant.H4,
    TextVariant.H5,
    TextVariant.H6
  ];
  return headingsList.includes(variant);
};

const generateOverrides = (
  classes: ReturnType<typeof useNexupMarkdownStyles>,
  className?: string,
  titleWithoutMarkdownTags?: string
) =>
  Object.fromEntries(
    Object.values(TextVariant).map(variant => [
      variant,
      {
        component: isHeadingTag(variant) ? variant.toLowerCase() : "p",
        props: {
          className: getClassName(className, classes[variant]),
          title: titleWithoutMarkdownTags
        }
      }
    ])
  );

// Remove characters that make the componente freeze (there last version does not solve this https://github.com/quantizor/markdown-to-jsx/issues/473)
// - Replace all repeated blanks for a single blank
// - Replaces all repeated tabs for a single tab
// - Replaces all repeated new lines for a single new line
const removeInvalidCharacters = (text: string): string => {
  return removeRepeatedFormatCharactersFromText(text);
};

export const NexupMarkdown: FC<NexupMarkdownProps> = ({
  text,
  className,
  defaultVariant,
  forceBlock = true,
  forceInline = false,
  forceWrapper = false,
  maxLines,
  title
}) => {
  const classes = useNexupMarkdownStyles({ maxLines });
  if (!text) return null;
  const titleWithoutMarkdownTags = removeMd(title ?? "");
  const sanitizedText = removeInvalidCharacters(text);
  return (
    <Markdown
      options={{
        forceBlock,
        forceInline,
        forceWrapper,
        overrides: {
          ...generateOverrides(classes, className, titleWithoutMarkdownTags),
          h1: {
            props: {
              className: getClassName(className, classes.H1, classes.maxLines),
              title: titleWithoutMarkdownTags
            }
          },
          h2: {
            props: {
              className: getClassName(className, classes.H2, classes.maxLines),
              title: titleWithoutMarkdownTags
            }
          },
          h3: {
            props: {
              className: getClassName(className, classes.H3, classes.maxLines),
              title: titleWithoutMarkdownTags
            }
          },
          h4: {
            props: {
              className: getClassName(className, classes.H4, classes.maxLines),
              title: titleWithoutMarkdownTags
            }
          },
          h5: {
            props: {
              className: getClassName(className, classes.H5, classes.maxLines),
              title: titleWithoutMarkdownTags
            }
          },
          h6: {
            props: {
              className: getClassName(className, classes.H6, classes.maxLines),
              title: titleWithoutMarkdownTags
            }
          },
          p: {
            props: {
              className: defaultVariant
                ? getClassName(
                    className,
                    classes[defaultVariant],
                    classes.maxLines
                  )
                : className,
              title: titleWithoutMarkdownTags
            }
          }
        }
      }}
    >
      {sanitizedText}
    </Markdown>
  );
};
