import { NexupChatMessage, FC, IconC } from "@laba/react-common";
import React, { ForwardedRef } from "react";
import { StyleVariant } from "model/themeVariant";
import { VerticalContainer } from "components/containers/VerticalContainer/VerticalContainer";
import { useChatStyle } from "components/chat/Chat/useChatStyle";
import { ChatMessages } from "components/chat/Chat/ChatMessages/ChatMessages";
import { Noop, Optional } from "@laba/ts-common";
import { ChatInput } from "./ChatInput/ChatInput";

export interface NexupChatProps {
  className?: string;
  LeftIconHeader?: IconC;
  onLeftIconClick?: Noop;
  headerTitle?: string;
  RightIconHeader?: IconC;
  onRightIconClick?: Noop;
  inputValue?: string;
  onChangeInput?: (value: string) => void;
  inputMaxRows?: number;
  disabledInput?: boolean;
  disabledSend?: boolean;
  disabledAttachment?: boolean;
  onClickSend?: Noop;
  onClickAttachment?: (file: File) => void;
  scrollRef?: ForwardedRef<HTMLDivElement>;
  sender?: string;
  label?: string;
  messages?: NexupChatMessage[];
  unsentMessages?: NexupChatMessage[];
  onMessageBecomesVisible: (m: NexupChatMessage) => void;
  onDownloadAttachment?: (
    message: NexupChatMessage
  ) => () => Promise<Optional<Blob>>;
  withPreview?: boolean;
  style?: StyleVariant;
  rightIconHidden?: boolean;
}

export const NexupChat: FC<NexupChatProps> = ({
  className,
  headerTitle,
  LeftIconHeader,
  RightIconHeader,
  onRightIconClick,
  onLeftIconClick,
  inputValue,
  onChangeInput,
  inputMaxRows,
  disabledInput,
  disabledSend,
  disabledAttachment,
  onClickSend,
  onClickAttachment,
  scrollRef,
  sender,
  messages,
  unsentMessages,
  label,
  onMessageBecomesVisible,
  onDownloadAttachment,
  withPreview,
  style = StyleVariant.Primary,
  rightIconHidden
}) => {
  const classes = useChatStyle();
  return (
    <VerticalContainer
      className={className}
      headerTitle={headerTitle}
      onLeftIconClick={onLeftIconClick}
      LeftIconHeader={LeftIconHeader}
      onRightIconClick={onRightIconClick}
      RightIconHeader={RightIconHeader}
      style={style}
      rightIconHidden={rightIconHidden}
    >
      <ChatMessages
        scrollRef={scrollRef}
        className={classes.messagesContainer}
        sender={sender}
        messages={messages}
        unsentMessages={unsentMessages}
        onMessageBecomesVisible={onMessageBecomesVisible}
        onDownloadAttachment={onDownloadAttachment}
        withPreview={withPreview}
      />
      <ChatInput
        inputValue={inputValue}
        onChange={onChangeInput}
        inputMaxRows={inputMaxRows}
        disabledInput={disabledInput}
        disabledSend={disabledSend}
        disabledAttachment={disabledAttachment}
        onClickSend={onClickSend}
        onClickAttachment={onClickAttachment}
        label={label}
      />
    </VerticalContainer>
  );
};
