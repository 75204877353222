import { tkCP } from "translation/i18n";
import { FC, useField, useI18n } from "@laba/react-common";
import { Grid, Typography } from "@mui/material";
import React from "react";
import {
  OptionsConfig,
  SearchableListSimpleInput,
  SelectInput,
  TextInput
} from "@laba/nexup-components";
import range from "lodash-es/range";
import { SantaCatalinaPayrollAllocationsRequestBodyParamsKey } from "@laba/nexup-api";
import {
  organizationOptionFromValue,
  organizationOptions
} from "../SantaCatalinaPayrollInput/SantaCatalinaPayrollFormContent";

const tk = tkCP.adminPage.dashboard.santaCatalinaPayrollAllocations;
const yearOptions: OptionsConfig<string>[] = range(2020, 2050).map(x => ({
  value: String(x),
  itemId: String(x),
  text: String(x)
}));
const monthOptions: OptionsConfig<string>[] = range(1, 13).map(x => ({
  value: String(x),
  itemId: String(x),
  text: String(x)
}));

export const SantaCatalinaPayrollAllocationsFormContent: FC = () => {
  const { t } = useI18n();
  const {
    input: { value: yearValue, onChange: yearOnChange, onBlur: yearOnBlur },
    meta: { error: yearError, touched: yearTouched }
  } = useField<string, string>(
    SantaCatalinaPayrollAllocationsRequestBodyParamsKey.year
  );

  const {
    input: { value: monthValue, onChange: monthOnChange, onBlur: monthOnBlur },
    meta: { error: monthError, touched: monthTouched }
  } = useField<string, string>(
    SantaCatalinaPayrollAllocationsRequestBodyParamsKey.month
  );
  const {
    input: {
      value: typeIdValue,
      onChange: typeIdOnChange,
      onBlur: typeIdOnBlur
    },
    meta: { error: typeIdError, touched: typeIdTouched }
  } = useField<string, string>(
    SantaCatalinaPayrollAllocationsRequestBodyParamsKey.typeId
  );

  const {
    input: {
      value: organizationIdListValue,
      onChange: organizationIdListOnChange,
      onBlur: organizationIdListOnBlur
    },
    meta: { error: organizationIdListError, touched: organizationIdListTouched }
  } = useField<string[], string>(
    SantaCatalinaPayrollAllocationsRequestBodyParamsKey.organizationIdList
  );
  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      <Grid item sm={6}>
        <Typography title={t(tk.year)}>{t(tk.year)} </Typography>
        <SelectInput
          value={yearValue}
          onChange={yearOnChange}
          onBlur={yearOnBlur}
          errorText={yearError}
          showError={yearTouched ?? true}
          placeholder={t(tk.year)}
          options={yearOptions}
          fullWidth
        />
      </Grid>
      <Grid item sm={6}>
        <Typography title={t(tk.month)}>{t(tk.month)} </Typography>
        <SelectInput
          value={monthValue}
          onChange={monthOnChange}
          onBlur={monthOnBlur}
          errorText={monthError}
          showError={monthTouched ?? true}
          placeholder={t(tk.month)}
          options={monthOptions}
          fullWidth
        />
      </Grid>
      <Grid item sm={6}>
        <Typography title={t(tk.typeId)}>{t(tk.typeId)} </Typography>
        <TextInput
          value={typeIdValue}
          onChange={typeIdOnChange}
          onBlur={typeIdOnBlur}
          errorText={typeIdError}
          showError={typeIdTouched ?? true}
          placeholder={t(tk.typeId)}
          fullWidth
        />
      </Grid>
      <Grid item sm={12}>
        <Typography title={t(tk.organizationId)}>
          {t(tk.organizationId)}
        </Typography>
        <SearchableListSimpleInput
          getOptionFromValue={organizationOptionFromValue}
          valueList={organizationIdListValue}
          optionList={organizationOptions}
          setOptionList={organizationIdListOnChange}
          onBlur={organizationIdListOnBlur}
          errorText={organizationIdListError}
          showError={organizationIdListTouched ?? true}
          noOptionsText={t(tk.organizationNoOptions)}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
