import { ApiRequestResponse } from "request/types";
import { PatientAppConfig } from "model/resource/app/appConfig";
import { getFrontPatientPortalApiUrl, request } from "request/nexupRequest";
import { HttpMethod } from "@laba/ts-common";
import { ModelId } from "model/primitives/model/model";

interface PatientAppConfigQueryParams {
  organization?: ModelId;
}

export const getPatientAppConfig = async (
  params?: PatientAppConfigQueryParams
): Promise<ApiRequestResponse<PatientAppConfig>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontPatientPortalApiUrl("/app-config"),
    params
  });
