import { Event } from "store/store";
import {
  ApiRequestResponse,
  AppointmentDefinition,
  CodeSystem,
  CodeSystemStatus,
  createAppointmentDefinition,
  createCodeSystem,
  createHealthcareService,
  createLocation,
  createMeasurementGroupDefinition,
  createMedicalDevice,
  createMedicalRequestDefinition,
  createMedication,
  createMedicationSubstance,
  createOrganization,
  createPatient,
  createPractitionerWithExtraData,
  createProcedureReportDefinition,
  createQuestionnaire,
  createSchedule,
  createScheduleDefinition,
  createUser,
  dosubaDirectoryPharmaRequest,
  dosubaDirectoryRequest,
  dosubaPatientRequest,
  editSchedule,
  editScheduleDefinition,
  getAppointmentDefinitionList,
  getCodeSystemList,
  getHealthcareServiceList,
  getIdentifierValueBySystem,
  getLocationList,
  getMeasurementGroupDefinitionList,
  getMedicalDeviceList,
  getMedicalRequestDefinitionList,
  getMedicationList,
  getMedicationSubstanceList,
  getModelOrUndefined,
  getModelReferenceId,
  getOrganization,
  getOrganizationList,
  getPatientList,
  getPractitionerWithExtraDataList,
  getProcedureReportDefinitionList,
  getQuestionnaireList,
  getScheduleDefinitionList,
  getScheduleList,
  getUserList,
  HealthcareService,
  Identifier,
  KnownIdentifierSystem,
  Location,
  LocationStatus,
  MeasurementGroupDefinition,
  MedicalDevice,
  MedicalDeviceStatus,
  MedicalRequestDefinition,
  Medication,
  MedicationGenericType,
  MedicationIngredient,
  MedicationStatus,
  MedicationSubstance,
  Model,
  ModelId,
  ModelReference,
  Organization,
  OrganizationType,
  Patient,
  Practitioner,
  PractitionerRole,
  PractitionerWithExtraData,
  ProcedureReportDefinition,
  PublicationStatus,
  Questionnaire,
  QuestionnaireCard,
  QuestionnaireFieldPropertyType,
  QuestionnaireFieldType,
  Schedule,
  ScheduleDefinition,
  scrapeResourceListEndpoint,
  updateAppointmentDefinition,
  updateCodeSystem,
  updateHealthcareService,
  updateLocation,
  updateMeasurementGroupDefinition,
  updateMedicalDevice,
  updateMedicalRequestDefinition,
  updateMedication,
  updateMedicationSubstance,
  updateOrganization,
  updatePatient,
  updatePractitionerWithExtraData,
  updateProcedureReportDefinition,
  updateQuestionnaire,
  updateUser,
  uploadFileRequest,
  User
} from "@laba/nexup-api";
import {
  DeepPartial,
  isEqualWithoutUndefined,
  isObject,
  logger,
  notUndefined,
  Optional,
  RequestFailureStatus,
  UploadFileMetadata,
  UploadFileResponse
} from "@laba/ts-common";
import { OrganizationConfiguration } from "models/organization/organizationConfiguration";
import { organizationJsonToModel } from "components/pages/NexupAdmin/resources/organization/OrganizationForm";
import produce from "immer";
import { codeSystemJsonToModel } from "components/pages/NexupAdmin/resources/codeSystem/CodeSystemForm";
import { locationJsonToModel } from "components/pages/NexupAdmin/resources/location/LocationForm";
import { healthcareServiceJsonToModel } from "components/pages/NexupAdmin/resources/healthcareService/HealthcareServiceForm";
import { appointmentDefinitionJsonToModel } from "components/pages/NexupAdmin/resources/appointmentDefinition/AppointmentDefinitionForm";
import { questionnaireJsonToModel } from "components/pages/NexupAdmin/resources/questionnaire/QuestionnaireForm";
import { medicalDeviceJsonToModel } from "components/pages/NexupAdmin/resources/medicalDevice/MedicalDeviceForm";
import { medicationSubstanceJsonToModel } from "components/pages/NexupAdmin/resources/medicationSubstance/MedicationSubstanceForm";
import { medicationJsonToModel } from "components/pages/NexupAdmin/resources/medication/MedicationForm";
import { medicalRequestDefinitionJsonToModel } from "components/pages/NexupAdmin/resources/medicalRequestDefinition/MedicalRequestDefinitionForm";
import {
  cloneDeep,
  find,
  head,
  isEmpty,
  isEqual,
  isError,
  merge,
  sortBy
} from "lodash-es";
import { serverSelector } from "store/session/selectors";
import { measurementGroupDefinitionJsonToModel } from "components/pages/NexupAdmin/resources/measurementGroupDefinition/MeasurementGroupDefinitionForm";
import { procedureReportDefinitionJsonToModel } from "components/pages/NexupAdmin/resources/procedureReportDefinition/ProcedureReportDefinitionForm";
import { scheduleDefinitionJsonToModel } from "components/pages/NexupAdmin/resources/scheduleDefinition/ScheduleDefinitionForm";
import { practitionerJsonToModel } from "components/pages/NexupAdmin/resources/practitioner/PractitionerForm";
import { userJsonToModel } from "components/pages/NexupAdmin/resources/user/UserForm";
import { practitionerRoleJsonToModel } from "components/pages/NexupAdmin/resources/practitioner/PractitionerRoleTabContent";
import { patientJsonToModel } from "components/pages/NexupAdmin/resources/patient/PatientForm";
import { scheduleJsonToModel } from "components/pages/NexupAdmin/resources/schedule/ScheduleForm";
import { HttpError } from "react-admin";

const getResponse = async <T>(
  requestPromise: Promise<ApiRequestResponse<T>>
): Promise<T> => {
  const response = await requestPromise;
  if (response.failureStatus === RequestFailureStatus.Failure)
    throw new HttpError(response.errorMsg, response.status, response.data);
  return cloneDeep(response.data);
};

type ModelIdMap = Record<ModelId, Optional<ModelId>>;

const mergeObj = <T>(
  a: Optional<DeepPartial<T>>,
  b: Optional<DeepPartial<T>>
): T =>
  merge<Optional<DeepPartial<T>>, Optional<DeepPartial<T>>>(
    cloneDeep<Optional<DeepPartial<T>>>(a),
    b
  ) as T;

export interface SkipDownloadConfig {
  skipParentOrganization?: boolean;
  skipOrganizationList?: boolean;
  skipCodeSystemList?: boolean;
  skipLocationList?: boolean;
  skipHealthCareServiceList?: boolean;
  skipAppointmentDefinitionList?: boolean;
  skipMedicalRequestDefinitionList?: boolean;
  skipMedicationList?: boolean;
  skipMedicalDeviceList?: boolean;
  skipQuestionnaireList?: boolean;
  skipMeasurementGroupDefinitionList?: boolean;
  skipProcedureReportDefinitionList?: boolean;
  skipScheduleDefinitionList?: boolean;
  skipScheduleList?: boolean;
  skipPractitionerWithExtraDataList?: boolean;
  skipPatientList?: boolean;
}

export const downloadOrganizationCompleteConfiguration =
  (
    organizationReference?: ModelReference<Organization>,
    skipDownloadConfig?: SkipDownloadConfig,
    onError?: (error: Error) => void
  ): Event<Optional<OrganizationConfiguration>> =>
  async (_, getState) => {
    const organizationId = getModelReferenceId(organizationReference);
    if (!organizationId) return;
    try {
      const organization = await getResponse(getOrganization(organizationId));
      const parentOrganizationId = getModelReferenceId(organization.partOf);

      const [
        parentOrganization,
        organizationList,
        codeSystemList,
        locationList,
        healthCareServiceList,
        appointmentDefinitionList,
        medicalRequestDefinitionList,
        medicationList,
        medicationSubstanceList
      ] = await Promise.all([
        parentOrganizationId &&
        !skipDownloadConfig?.skipParentOrganization === true
          ? getResponse(getOrganization(parentOrganizationId))
          : undefined,
        skipDownloadConfig?.skipOrganizationList === true
          ? []
          : scrapeResourceListEndpoint(getOrganizationList, {
              partOf: organizationId,
              pageSize: 100,
              type: OrganizationType.Payer
            }),
        skipDownloadConfig?.skipCodeSystemList === true
          ? []
          : scrapeResourceListEndpoint(getCodeSystemList, {
              organization: organizationId,
              status: CodeSystemStatus.Active,
              pageSize: 100
            }),
        skipDownloadConfig?.skipLocationList === true
          ? []
          : scrapeResourceListEndpoint(getLocationList, {
              organization: organizationId,
              status: LocationStatus.Active,
              pageSize: 100
            }),
        skipDownloadConfig?.skipHealthCareServiceList === true
          ? []
          : scrapeResourceListEndpoint(getHealthcareServiceList, {
              organization: organizationId,
              active: true,
              pageSize: 100
            }),
        skipDownloadConfig?.skipAppointmentDefinitionList === true
          ? []
          : scrapeResourceListEndpoint(getAppointmentDefinitionList, {
              organization: organizationId,
              status: PublicationStatus.Active,
              pageSize: 100
            }),
        skipDownloadConfig?.skipMedicalRequestDefinitionList === true
          ? []
          : scrapeResourceListEndpoint(getMedicalRequestDefinitionList, {
              organization: organizationId,
              status: PublicationStatus.Active,
              pageSize: 100
            }),
        skipDownloadConfig?.skipMedicationList === true
          ? []
          : scrapeResourceListEndpoint(getMedicationList, {
              organization: organizationId,
              status: MedicationStatus.Active,
              pageSize: 100
            }),
        skipDownloadConfig?.skipMedicationList === true
          ? []
          : scrapeResourceListEndpoint(getMedicationSubstanceList, {
              organization: organizationId,
              pageSize: 100
            })
      ]);
      const [
        medicalDeviceList,
        questionnaireList,
        measurementGroupDefinitionList,
        procedureReportDefinitionList,
        scheduleDefinitionList,
        scheduleList,
        practitionerWithExtraDataList,
        patientList
      ] = await Promise.all([
        skipDownloadConfig?.skipMedicalDeviceList === true
          ? []
          : scrapeResourceListEndpoint(getMedicalDeviceList, {
              organization: organizationId,
              status: MedicalDeviceStatus.Active,
              pageSize: 100
            }),
        skipDownloadConfig?.skipQuestionnaireList === true
          ? []
          : scrapeResourceListEndpoint(getQuestionnaireList, {
              organization: organizationId,
              status: PublicationStatus.Active,
              pageSize: 100
            }),
        skipDownloadConfig?.skipMeasurementGroupDefinitionList === true
          ? []
          : scrapeResourceListEndpoint(getMeasurementGroupDefinitionList, {
              organization: organizationId,
              status: PublicationStatus.Active,
              pageSize: 100
            }),
        skipDownloadConfig?.skipProcedureReportDefinitionList === true
          ? []
          : scrapeResourceListEndpoint(getProcedureReportDefinitionList, {
              organization: organizationId,
              status: PublicationStatus.Active,
              pageSize: 100
            }),
        skipDownloadConfig?.skipScheduleDefinitionList === true
          ? []
          : scrapeResourceListEndpoint(getScheduleDefinitionList, {
              organization: organizationId,
              status: PublicationStatus.Active,
              pageSize: 100
            }),
        skipDownloadConfig?.skipScheduleList === true
          ? []
          : scrapeResourceListEndpoint(getScheduleList, {
              organization: organizationId,
              active: true,
              pageSize: 100
            }),
        skipDownloadConfig?.skipPractitionerWithExtraDataList === true
          ? []
          : scrapeResourceListEndpoint(getPractitionerWithExtraDataList, {
              organization: organizationId,
              withRoleList: true,
              withUser: true,
              pageSize: 100
            }),
        skipDownloadConfig?.skipPatientList === true
          ? []
          : (
              await scrapeResourceListEndpoint(getPatientList, {
                organization: organizationId,
                active: true,
                pageSize: 100
              })
            )?.map(x =>
              produce(x, draft => {
                draft.patientData?.managedBy?.forEach(draftManagedBy => {
                  draftManagedBy.patient = getModelReferenceId(
                    draftManagedBy.patient
                  );
                });
              })
            ) ?? []
      ]);

      const organizationConfiguration: OrganizationConfiguration = {
        environment: serverSelector(getState()),
        organization,
        parentOrganizationName: parentOrganization?.name,
        organizationList,
        codeSystemList,
        locationList,
        healthCareServiceList,
        appointmentDefinitionList,
        medicalRequestDefinitionList,
        medicationList,
        medicationSubstanceList,
        medicalDeviceList,
        questionnaireList,
        measurementGroupDefinitionList,
        procedureReportDefinitionList,
        scheduleDefinitionList,
        practitionerWithExtraDataList,
        patientList,
        scheduleList
      };
      return organizationConfiguration;
    } catch (e) {
      const error = e as Error;
      onError?.(error);
    }
  };

const updateModelList = async <T extends Model = Model>(
  currentModelList: T[],
  createRequest: (model: T) => Promise<ApiRequestResponse<T>>,
  updateRequest: (model: T) => Promise<ApiRequestResponse<T>>,
  modelList: T[],
  modelTransform: (targetModel: T, currentModel?: T) => Optional<T>,
  isTargetModel?: (toTestModel: T, targetModel: T) => boolean,
  onModelSaved?: (targetModel: T, newModel: T, oldModel?: T) => void
): Promise<T[]> => {
  const newModelList: T[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const targetModel of modelList) {
    const currentModel = currentModelList.find(
      value =>
        isTargetModel?.(value, targetModel) ?? value.id === targetModel.id
    );
    const toSaveModel = modelTransform(targetModel, cloneDeep(currentModel));
    if (toSaveModel) {
      const needSave = !isEqualWithoutUndefined(currentModel, toSaveModel);
      const newModel = needSave
        ? // eslint-disable-next-line no-await-in-loop
          await getResponse(
            isEmpty(toSaveModel.id)
              ? createRequest(toSaveModel)
              : updateRequest(toSaveModel)
          )
        : toSaveModel;
      if (needSave) {
        onModelSaved?.(targetModel, newModel, currentModel);
      }
      newModelList.push(newModel);
    }
  }
  return newModelList;
};

const updatePractitionerList = async (
  currentModelList: PractitionerWithExtraData[],
  modelList: PractitionerWithExtraData[],
  mainOrganizationId: ModelId,
  onModelSaved?: (
    targetModel: PractitionerWithExtraData,
    newModel: PractitionerWithExtraData,
    oldModel?: PractitionerWithExtraData
  ) => void
): Promise<PractitionerWithExtraData[]> => {
  const newModelList: PractitionerWithExtraData[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const targetModel of modelList) {
    const currentModel = currentModelList.find(
      value => value.practitioner.id === targetModel.practitioner.id
    );

    const currentModelUser = getModelOrUndefined(
      currentModel?.practitioner.user
    );
    const currentModelToCompare = currentModel
      ? produce(currentModel, draft => {
          draft.practitioner.user = getModelReferenceId(
            draft.practitioner.user
          );
        })
      : undefined;
    const targetModelUser = getModelOrUndefined(targetModel.practitioner.user);
    const toSaveUser = targetModelUser
      ? mergeObj<User>(currentModelUser, userJsonToModel(targetModelUser))
      : currentModelUser;

    let newUser: Optional<User> = currentModelUser;
    if (toSaveUser) {
      const needSave = !isEqualWithoutUndefined(currentModelUser, toSaveUser);
      if (needSave) {
        if (toSaveUser.id) {
          // eslint-disable-next-line no-await-in-loop
          newUser = await getResponse(updateUser(toSaveUser));
        } else {
          try {
            // eslint-disable-next-line no-await-in-loop
            newUser = await getResponse(createUser(toSaveUser));
          } catch (e) {
            // eslint-disable-next-line no-await-in-loop
            const searchResponse = await getResponse(
              getUserList({
                content: toSaveUser.username
              })
            );
            newUser = searchResponse.entries.find(
              x => x.username === toSaveUser.username
            );
          }
        }
      } else {
        newUser = toSaveUser;
      }
    }

    const practitioner = produce(
      mergeObj<Practitioner>(
        currentModelToCompare?.practitioner,
        practitionerJsonToModel(targetModel.practitioner)
      ),
      draft => {
        const organizationList = !isEmpty(draft.organization)
          ? draft.organization
          : [mainOrganizationId];
        draft.organization = organizationList;
        draft.user = newUser
          ? getModelReferenceId(newUser)
          : getModelReferenceId(targetModel.practitioner.user);
        const federationIdentifier =
          currentModelToCompare?.practitioner.personalData.identifierList?.find(
            x => x.system === KnownIdentifierSystem.Nexup
          );
        draft.personalData.identifierList = [
          federationIdentifier,
          ...mergeObj<Identifier[]>(
            currentModelToCompare?.practitioner.personalData.identifierList?.filter(
              x => x.system !== KnownIdentifierSystem.Nexup
            ) ?? [],
            practitionerJsonToModel(
              targetModel.practitioner
            ).personalData?.identifierList?.filter(
              x => x?.system !== KnownIdentifierSystem.Nexup
            ) ?? []
          )
        ].filter(notUndefined);
      }
    );
    const roleList = cloneDeep(currentModelToCompare?.roleList ?? []);
    targetModel.roleList?.forEach(targetRol => {
      const index = roleList.findIndex(
        value => value.id === targetRol.id && !isEmpty(targetRol.id)
      );
      if (index >= 0) {
        roleList[index] = produce(
          mergeObj<PractitionerRole>(
            roleList[index],
            practitionerRoleJsonToModel(targetRol)
          ),
          draft => {
            draft.organization = mainOrganizationId;
            draft.practitioner = practitioner.id;
          }
        );
      } else {
        roleList.push(
          produce(
            practitionerRoleJsonToModel(targetRol) as PractitionerRole,
            draft => {
              draft.organization = mainOrganizationId;
              draft.practitioner = practitioner.id;
            }
          )
        );
      }
    });

    const toSaveModel: PractitionerWithExtraData = {
      practitioner,
      roleList
    };
    const needSave = !isEqualWithoutUndefined(
      currentModelToCompare,
      toSaveModel
    );
    const newModel = needSave
      ? // eslint-disable-next-line no-await-in-loop
        await getResponse(
          isEmpty(toSaveModel.practitioner.id)
            ? createPractitionerWithExtraData(toSaveModel)
            : updatePractitionerWithExtraData(toSaveModel)
        )
      : toSaveModel;
    if (needSave) {
      onModelSaved?.(targetModel, newModel, currentModel);
    }
    newModelList.push(newModel);
  }
  return newModelList;
};

const replaceIdentifierInList = (
  identifierList: Identifier[],
  identifier?: DeepPartial<Identifier>
): Identifier[] => {
  return identifierList
    .map(i => {
      if (i.system === identifier?.system) return identifier as Identifier;
      return i;
    })
    .filter(notUndefined);
};
const fixEditedOrganization = (
  editedOrganization: DeepPartial<Organization>,
  baseMainOrganization: Organization,
  parentOrganization?: Organization
) => {
  const baseEdit = mergeObj<Organization>(
    baseMainOrganization,
    editedOrganization
  );
  return produce(baseEdit, draft => {
    if (draft.whiteLabelConfig)
      draft.whiteLabelConfig.organization = baseMainOrganization.id;
    draft.partOf = parentOrganization?.id;

    // Keep the edited version of the OrganizationCRMId identifier
    draft.identifier = replaceIdentifierInList(
      draft.identifier ?? [],
      find(
        editedOrganization.identifier,
        i => i?.system === KnownIdentifierSystem.OrganizationCRMId
      )
    );
  });
};
export const importOrganizationConfiguration =
  (
    organizationConfiguration: OrganizationConfiguration,
    skipDownloadConfig?: SkipDownloadConfig
  ): Event<Optional<OrganizationConfiguration | Error>> =>
  async (dispatch, getState) => {
    // download current organization config only if environment are the same (the ids are from this server)
    const currentOrganizationConfig =
      organizationConfiguration.environment === serverSelector(getState())
        ? await dispatch(
            downloadOrganizationCompleteConfiguration(
              organizationConfiguration.organization,
              skipDownloadConfig
            )
          )
        : undefined;

    // search current server parent Organization
    const parentOrganizationSearchResult =
      organizationConfiguration.parentOrganizationName
        ? await getResponse(
            getOrganizationList({
              active: true,
              type: [OrganizationType.Group, OrganizationType.Provider],
              content: organizationConfiguration.parentOrganizationName
            })
          )
        : undefined;
    const parentOrganization = parentOrganizationSearchResult?.entries.find(
      x => x.name === organizationConfiguration.parentOrganizationName
    );

    try {
      // use current organization config as base organization o create a new one if this doesn't exist
      const baseMainOrganization =
        currentOrganizationConfig?.organization ??
        (await getResponse(
          createOrganization(
            organizationJsonToModel(
              organizationConfiguration.organization
            ) as Organization
          )
        ));
      // update organization merging curren and target and fix whiteLabelConfig id
      const aux = organizationJsonToModel(
        organizationConfiguration.organization
      );
      const editedOrganization = fixEditedOrganization(
        aux,
        baseMainOrganization,
        parentOrganization
      );
      const mainOrganization = isEqual(baseMainOrganization, editedOrganization)
        ? baseMainOrganization
        : await getResponse(updateOrganization(editedOrganization));

      const organizationList: Organization[] = await updateModelList(
        currentOrganizationConfig?.organizationList ?? [],
        createOrganization,
        updateOrganization,
        sortBy(organizationConfiguration.organizationList ?? [], x => x.id),
        (targetModel, currentModel) => {
          return produce(
            mergeObj<Organization>(
              currentModel,
              organizationJsonToModel(targetModel)
            ),
            draft => {
              draft.partOf = getModelReferenceId(mainOrganization);
            }
          );
        },
        (toTestModel, targetModel) =>
          isEmpty(targetModel.name)
            ? toTestModel.id === targetModel.id
            : toTestModel.name === targetModel.name
      );

      const codeSystemList: CodeSystem[] = await updateModelList(
        currentOrganizationConfig?.codeSystemList ?? [],
        createCodeSystem,
        updateCodeSystem,
        sortBy(organizationConfiguration.codeSystemList ?? [], x => x.id),
        (targetModel, currentModel) =>
          produce(
            mergeObj<CodeSystem>(
              currentModel,
              codeSystemJsonToModel(targetModel)
            ),
            draft => {
              draft.organization = getModelReferenceId(mainOrganization) ?? "";
            }
          ),
        (toTestModel, targetModel) =>
          isEmpty(targetModel.system)
            ? toTestModel.id === targetModel.id
            : toTestModel.system === targetModel.system
      );

      const orderByIdLocationList = sortBy(
        organizationConfiguration.locationList ?? [],
        x => x.id
      );
      const orderByPartOfLocationList = orderByIdLocationList.filter(
        x => x.partOf == null
      );
      let pendingLocationList = orderByIdLocationList.filter(
        x => x.partOf != null
      );
      while (pendingLocationList.length > 0) {
        const newPendingList: Location[] = [];
        pendingLocationList.forEach(pendingLocation => {
          if (
            orderByPartOfLocationList.find(
              location => pendingLocation.partOf === location.id
            ) != null
          ) {
            orderByPartOfLocationList.push(pendingLocation);
          } else {
            newPendingList.push(pendingLocation);
          }
        });
        pendingLocationList = newPendingList;
      }
      const orderLocationList = orderByPartOfLocationList;
      const locationIdMap: ModelIdMap = {};
      const locationList: Location[] = await updateModelList(
        currentOrganizationConfig?.locationList ?? [],
        createLocation,
        updateLocation,
        orderLocationList,
        (targetModel, currentModel) => {
          return produce(
            mergeObj<Location>(currentModel, locationJsonToModel(targetModel)),
            draft => {
              draft.organization = getModelReferenceId(mainOrganization);
              draft.partOf =
                locationIdMap[getModelReferenceId(targetModel.partOf) ?? ""] ??
                draft.partOf;
            }
          );
        },
        (toTestModel, targetModel) =>
          isEmpty(targetModel.hisCode)
            ? toTestModel.id === targetModel.id
            : toTestModel.hisCode === targetModel.hisCode,
        (targetModel, newModel) => {
          const targetId = getModelReferenceId(targetModel);
          if (targetId) {
            locationIdMap[targetId] = getModelReferenceId(newModel);
          }
        }
      );

      const healthCareServiceIdMap: ModelIdMap = {};
      const healthCareServiceList: HealthcareService[] = await updateModelList(
        currentOrganizationConfig?.healthCareServiceList ?? [],
        createHealthcareService,
        updateHealthcareService,
        sortBy(
          organizationConfiguration.healthCareServiceList ?? [],
          x => x.id
        ),
        (targetModel, currentModel) => {
          const targetLocationIdList = targetModel.location
            .map(value => getModelReferenceId(value.location))
            .filter(notUndefined);
          return produce(
            mergeObj<HealthcareService>(
              currentModel,
              healthcareServiceJsonToModel(targetModel)
            ),
            draft => {
              draft.organization = getModelReferenceId(mainOrganization) ?? "";
              draft.location = targetLocationIdList.map(value => ({
                location: locationIdMap[value]
              }));
            }
          );
        },
        (toTestModel, targetModel) => toTestModel.id === targetModel.id,
        (targetModel, newModel) => {
          const targetId = getModelReferenceId(targetModel);
          if (targetId) {
            healthCareServiceIdMap[targetId] = getModelReferenceId(newModel);
          }
        }
      );

      const appointmentDefinitionList: AppointmentDefinition[] =
        await updateModelList(
          currentOrganizationConfig?.appointmentDefinitionList ?? [],
          createAppointmentDefinition,
          updateAppointmentDefinition,
          sortBy(
            organizationConfiguration.appointmentDefinitionList ?? [],
            x => x.id
          ),
          (targetModel, currentModel) => {
            const targetServiceIdList = targetModel.availableServiceList
              .map(value => getModelReferenceId(value))
              .filter(notUndefined);
            return produce(
              mergeObj<AppointmentDefinition>(
                currentModel,
                appointmentDefinitionJsonToModel(targetModel)
              ),
              draft => {
                draft.organization = getModelReferenceId(mainOrganization);
                draft.availableServiceList = targetServiceIdList
                  .map(value => healthCareServiceIdMap[value])
                  .filter(notUndefined);
              }
            );
          },
          (toTestModel, targetModel) => toTestModel.id === targetModel.id
        );

      const measurementGroupDefinitionIdMap: ModelIdMap = {};
      const measurementGroupDefinitionList: MeasurementGroupDefinition[] =
        await updateModelList(
          currentOrganizationConfig?.measurementGroupDefinitionList ?? [],
          createMeasurementGroupDefinition,
          updateMeasurementGroupDefinition,
          sortBy(
            organizationConfiguration.measurementGroupDefinitionList ?? [],
            x => x.id
          ),
          (targetModel, currentModel) =>
            produce(
              mergeObj<MeasurementGroupDefinition>(
                currentModel,
                measurementGroupDefinitionJsonToModel(targetModel)
              ),
              draft => {
                draft.organization =
                  getModelReferenceId(mainOrganization) ?? "";
              }
            ),
          (toTestModel, targetModel) =>
            isEmpty(targetModel.name)
              ? toTestModel.id === targetModel.id
              : toTestModel.name === targetModel.name,
          (targetModel, newModel) => {
            const targetId = getModelReferenceId(targetModel);
            if (targetId) {
              measurementGroupDefinitionIdMap[targetId] =
                getModelReferenceId(newModel);
            }
          }
        );

      const procedureReportIdMap: ModelIdMap = {};
      const procedureReportDefinitionList: ProcedureReportDefinition[] =
        await updateModelList(
          currentOrganizationConfig?.procedureReportDefinitionList ?? [],
          createProcedureReportDefinition,
          updateProcedureReportDefinition,
          sortBy(
            organizationConfiguration.procedureReportDefinitionList ?? [],
            x => x.id
          ),
          (targetModel, currentModel) =>
            produce(
              mergeObj<ProcedureReportDefinition>(
                currentModel,
                procedureReportDefinitionJsonToModel(targetModel)
              ),
              draft => {
                draft.organization =
                  getModelReferenceId(mainOrganization) ?? "";
                draft.measurementGroupDefinition =
                  measurementGroupDefinitionIdMap[
                    getModelReferenceId(
                      targetModel.measurementGroupDefinition
                    ) ?? ""
                  ] ?? draft.measurementGroupDefinition;
              }
            ),
          (toTestModel, targetModel) =>
            isEmpty(targetModel.name)
              ? toTestModel.id === targetModel.id
              : toTestModel.name === targetModel.name,
          (targetModel, newModel) => {
            const targetId = getModelReferenceId(targetModel);
            if (targetId) {
              procedureReportIdMap[targetId] = getModelReferenceId(newModel);
            }
          }
        );

      const questionnaireList: Questionnaire[] = await updateModelList(
        currentOrganizationConfig?.questionnaireList ?? [],
        createQuestionnaire,
        updateQuestionnaire,
        sortBy(organizationConfiguration.questionnaireList ?? [], x => x.id),
        (targetModel, currentModel) =>
          produce(
            mergeObj<Questionnaire>(
              currentModel,
              questionnaireJsonToModel(targetModel)
            ),
            (draft: Questionnaire) => {
              draft.organization = getModelReferenceId(mainOrganization) ?? "";
              draft.cards = targetModel.cards.map((c: QuestionnaireCard) => {
                return {
                  ...c,
                  fields: c.fields.map(f => {
                    if (
                      f.fieldType === QuestionnaireFieldType.ProcedureReport
                    ) {
                      const propertyDefinitionToReplace = f.property.find(
                        p =>
                          p.type === QuestionnaireFieldPropertyType.Definition
                      );
                      return {
                        ...f,
                        property: [
                          ...f.property.filter(
                            p =>
                              p.type !==
                              QuestionnaireFieldPropertyType.Definition
                          ),
                          {
                            type: QuestionnaireFieldPropertyType.Definition,
                            value:
                              procedureReportIdMap[
                                propertyDefinitionToReplace?.value ?? ""
                              ] ?? propertyDefinitionToReplace?.value
                          }
                        ]
                      };
                    }
                    return f;
                  })
                };
              });
            }
          ),
        (toTestModel, targetModel) =>
          isEmpty(targetModel.name)
            ? toTestModel.id === targetModel.id
            : toTestModel.name === targetModel.name
      );

      const medicalDeviceIdMap: ModelIdMap = {};
      const medicalDeviceList: MedicalDevice[] = await updateModelList(
        currentOrganizationConfig?.medicalDeviceList ?? [],
        createMedicalDevice,
        updateMedicalDevice,
        sortBy(organizationConfiguration.medicalDeviceList ?? [], x => x.id),
        (targetModel, currentModel) =>
          produce(
            mergeObj<MedicalDevice>(
              currentModel,
              medicalDeviceJsonToModel(targetModel)
            ),
            draft => {
              draft.organization = getModelReferenceId(mainOrganization) ?? "";
            }
          ),
        (toTestModel, targetModel) =>
          isEmpty(targetModel.hisCode)
            ? toTestModel.id === targetModel.id
            : toTestModel.hisCode === targetModel.hisCode,
        (targetModel, newModel) => {
          const targetId = getModelReferenceId(targetModel);
          if (targetId) {
            medicalDeviceIdMap[targetId] = getModelReferenceId(newModel);
          }
        }
      );

      const medicationSubstanceIdMap: ModelIdMap = {};
      const medicationSubstanceList: MedicationSubstance[] =
        await updateModelList(
          currentOrganizationConfig?.medicationSubstanceList ?? [],
          createMedicationSubstance,
          updateMedicationSubstance,
          sortBy(
            organizationConfiguration.medicationSubstanceList ?? [],
            x => x.id
          ),
          (targetModel, currentModel) =>
            produce(
              mergeObj<MedicationSubstance>(
                currentModel,
                medicationSubstanceJsonToModel(targetModel)
              ),
              draft => {
                draft.organization =
                  getModelReferenceId(mainOrganization) ?? "";
              }
            ),
          (toTestModel, targetModel) =>
            isEmpty(targetModel.code)
              ? toTestModel.id === targetModel.id
              : toTestModel.code === targetModel.code,
          (targetModel, newModel) => {
            const targetId = getModelReferenceId(targetModel);
            if (targetId) {
              medicationSubstanceIdMap[targetId] =
                getModelReferenceId(newModel);
            }
          }
        );

      const rawMedicationList = [
        ...sortBy(
          organizationConfiguration.medicationList?.filter(
            value => value.genericType === MedicationGenericType.Generic
          ) ?? [],
          x => x.id
        ),
        ...sortBy(
          organizationConfiguration.medicationList?.filter(
            value => value.genericType !== MedicationGenericType.Generic
          ) ?? [],
          x => x.id
        )
      ];
      const medicationIdMap: ModelIdMap = {};
      const medicationList: Medication[] = await updateModelList(
        currentOrganizationConfig?.medicationList ?? [],
        createMedication,
        updateMedication,
        rawMedicationList,
        (targetModel, currentModel) => {
          return produce(
            mergeObj<Medication>(
              currentModel,
              medicationJsonToModel(targetModel)
            ),
            draft => {
              draft.organization = getModelReferenceId(mainOrganization) ?? "";
              draft.instanceOf =
                medicationIdMap[
                  getModelReferenceId(targetModel.instanceOf) ?? ""
                ] ?? draft.instanceOf;

              const targetIngredientList = targetModel.ingredient
                .map<Optional<MedicationIngredient>>(value => {
                  const newID =
                    medicalDeviceIdMap[
                      getModelReferenceId(value.substance) ?? ""
                    ];
                  return newID
                    ? {
                        device: newID,
                        amount: value.amount
                      }
                    : undefined;
                })
                .filter(notUndefined);
              draft.ingredient = isEmpty(targetIngredientList)
                ? draft.ingredient
                : targetIngredientList;
            }
          );
        },
        (toTestModel, targetModel) =>
          isEmpty(
            targetModel.identifier?.filter(
              identifier => identifier.system === KnownIdentifierSystem.HisCode
            )
          )
            ? toTestModel.id === targetModel.id
            : getIdentifierValueBySystem(
                KnownIdentifierSystem.HisCode,
                toTestModel.identifier
              ) ===
              getIdentifierValueBySystem(
                KnownIdentifierSystem.HisCode,
                targetModel.identifier
              ),
        (targetModel, newModel) => {
          const targetId = getModelReferenceId(targetModel);
          if (targetId) {
            medicationIdMap[targetId] = getModelReferenceId(newModel);
          }
        }
      );

      const medicalRequestDefinitionList: MedicalRequestDefinition[] =
        await updateModelList(
          currentOrganizationConfig?.medicalRequestDefinitionList ?? [],
          createMedicalRequestDefinition,
          updateMedicalRequestDefinition,
          sortBy(
            organizationConfiguration.medicalRequestDefinitionList ?? [],
            x => x.id
          ),
          (targetModel, currentModel) =>
            produce(
              mergeObj<MedicalRequestDefinition>(
                currentModel,
                medicalRequestDefinitionJsonToModel(targetModel)
              ),
              draft => {
                draft.organization =
                  getModelReferenceId(mainOrganization) ?? "";
                draft.procedureReportDefinition =
                  procedureReportIdMap[
                    getModelReferenceId(
                      targetModel.procedureReportDefinition
                    ) ?? ""
                  ] ?? draft.procedureReportDefinition;
                const targetDeviceList = targetModel.medicalDevice
                  .map(value => {
                    const newID =
                      medicalDeviceIdMap[
                        getModelReferenceId(value.device) ?? ""
                      ];
                    return newID
                      ? {
                          device: newID
                        }
                      : undefined;
                  })
                  .filter(notUndefined);
                draft.medicalDevice = isEmpty(targetDeviceList)
                  ? draft.medicalDevice
                  : targetDeviceList;
              }
            ),
          (toTestModel, targetModel) =>
            isEmpty(targetModel.name)
              ? toTestModel.id === targetModel.id
              : toTestModel.name === targetModel.name
        );

      const scheduleDefinitionIdMap: ModelIdMap = {};
      const scheduleDefinitionList: ScheduleDefinition[] =
        await updateModelList(
          currentOrganizationConfig?.scheduleDefinitionList ?? [],
          createScheduleDefinition,
          editScheduleDefinition,
          sortBy(
            organizationConfiguration.scheduleDefinitionList ?? [],
            x => x.id
          ),
          (targetModel, currentModel) =>
            produce(
              mergeObj<ScheduleDefinition>(
                currentModel,
                scheduleDefinitionJsonToModel(targetModel)
              ),
              draft => {
                draft.organization =
                  getModelReferenceId(mainOrganization) ?? "";
              }
            ),
          (toTestModel, targetModel) =>
            isEmpty(targetModel.name)
              ? toTestModel.id === targetModel.id
              : toTestModel.name === targetModel.name,
          (targetModel, newModel) => {
            const targetId = getModelReferenceId(targetModel);
            if (targetId) {
              scheduleDefinitionIdMap[targetId] = getModelReferenceId(newModel);
            }
          }
        );

      const practitionerWithExtraDataIdMap: ModelIdMap = {};
      const practitionerWithExtraDataList: PractitionerWithExtraData[] =
        await updatePractitionerList(
          currentOrganizationConfig?.practitionerWithExtraDataList ?? [],
          sortBy(
            organizationConfiguration.practitionerWithExtraDataList ?? [],
            x => x.practitioner.id
          ),
          getModelReferenceId(mainOrganization) ?? "",
          (targetModel, newModel) => {
            const targetId = targetModel.practitioner.id;
            if (targetId) {
              practitionerWithExtraDataIdMap[targetId] =
                newModel.practitioner.id;
            }
          }
        );

      const patientList: Patient[] = await updateModelList(
        currentOrganizationConfig?.patientList ?? [],
        createPatient,
        updatePatient,
        sortBy(organizationConfiguration.patientList ?? [], x => x.id),
        (targetModel, currentModel) =>
          produce(
            mergeObj<Patient>(currentModel, patientJsonToModel(targetModel)),
            draft => {
              draft.organization = getModelReferenceId(mainOrganization) ?? "";
              const federationIdentifier =
                currentModel?.personalData.identifierList?.find(
                  x => x.system === KnownIdentifierSystem.Nexup
                );
              draft.personalData.identifierList = [
                federationIdentifier,
                ...mergeObj<Identifier[]>(
                  currentModel?.personalData.identifierList?.filter(
                    x => x.system !== KnownIdentifierSystem.Nexup
                  ) ?? [],
                  patientJsonToModel(
                    targetModel
                  ).personalData?.identifierList?.filter(
                    x => x?.system !== KnownIdentifierSystem.Nexup
                  ) ?? []
                )
              ].filter(notUndefined);
            }
          ),
        (toTestModel, targetModel) => toTestModel.id === targetModel.id
      );

      const scheduleList: Schedule[] = await updateModelList(
        currentOrganizationConfig?.scheduleList ?? [],
        createSchedule,
        editSchedule,
        sortBy(organizationConfiguration.scheduleList ?? [], x => x.id),
        (targetModel, currentModel) =>
          produce(
            mergeObj<Schedule>(currentModel, scheduleJsonToModel(targetModel)),
            draft => {
              draft.organization = getModelReferenceId(mainOrganization) ?? "";
              draft.location =
                locationIdMap[
                  getModelReferenceId(targetModel.location) ?? ""
                ] ?? draft.location;
              draft.practitioner =
                practitionerWithExtraDataIdMap[
                  getModelReferenceId(targetModel.practitioner) ?? ""
                ] ?? draft.practitioner;
              draft.originalPractitioner =
                practitionerWithExtraDataIdMap[
                  getModelReferenceId(targetModel.originalPractitioner) ?? ""
                ] ?? draft.originalPractitioner;
              draft.definition =
                scheduleDefinitionIdMap[
                  getModelReferenceId(targetModel.definition) ?? ""
                ] ?? draft.definition;

              const targetPerformerList =
                targetModel.performer
                  ?.map(value => {
                    const newID =
                      practitionerWithExtraDataIdMap[
                        getModelReferenceId(value.practitioner) ?? ""
                      ];
                    return newID
                      ? {
                          practitioner: newID
                        }
                      : undefined;
                  })
                  .filter(notUndefined) ?? [];
              draft.performer = isEmpty(targetPerformerList)
                ? draft.performer
                : targetPerformerList;
            }
          ),
        (toTestModel, targetModel) => toTestModel.id === targetModel.id
      );

      const newOrganizationConfig: OrganizationConfiguration = {
        environment: serverSelector(getState()),
        organization: mainOrganization,
        organizationList,
        codeSystemList,
        locationList,
        healthCareServiceList,
        appointmentDefinitionList,
        medicalRequestDefinitionList,
        medicationList,
        medicationSubstanceList,
        medicalDeviceList,
        questionnaireList,
        measurementGroupDefinitionList,
        procedureReportDefinitionList,
        scheduleDefinitionList,
        practitionerWithExtraDataList,
        patientList,
        scheduleList
      };
      return newOrganizationConfig;
    } catch (e) {
      logger.error(e);
      return e as Error;
    }
  };

export const importOrganizationConfigurationFiles =
  (
    files?: FileList,
    onError?: (error: Error) => void
  ): Event<Optional<OrganizationConfiguration>> =>
  async dispatch => {
    const file = files?.item(0) ?? undefined;
    const fileContent = (await file?.text()) ?? "";
    const json = JSON.parse(fileContent);
    if (!isObject(json)) return;
    const organizationConfiguration =
      json as unknown as OrganizationConfiguration;

    const result = await dispatch(
      importOrganizationConfiguration(organizationConfiguration, {
        skipPractitionerWithExtraDataList: isEmpty(
          organizationConfiguration.practitionerWithExtraDataList
        ),
        skipPatientList: isEmpty(organizationConfiguration.patientList),
        skipScheduleList: isEmpty(organizationConfiguration.scheduleList),
        skipMedicationList: isEmpty(organizationConfiguration.medicationList),
        skipMedicalDeviceList: isEmpty(
          organizationConfiguration.medicalDeviceList
        )
      })
    );
    if (isError(result)) {
      onError?.(result);
    } else {
      return result;
    }
  };

export const importDosubaDirectoryXlsConfiguration =
  (files?: FileList, onError?: (error: Error) => void): Event =>
  async () => {
    try {
      const file = files?.item(0) ?? undefined;
      if (!file) return;
      await dosubaDirectoryRequest(file);
    } catch (e) {
      const error = e as Error;
      onError?.(error);
    }
  };

export const importDosubaDirectoryPharmaXlsConfiguration =
  (files?: FileList, onError?: (error: Error) => void): Event =>
  async () => {
    try {
      const file = files?.item(0) ?? undefined;
      if (!file) return;
      await dosubaDirectoryPharmaRequest(file);
    } catch (e) {
      const error = e as Error;
      onError?.(error);
    }
  };

export const importDosubaPatientXlsConfiguration =
  (files?: FileList, onError?: (error: Error) => void): Event =>
  async () => {
    try {
      const file = files?.item(0) ?? undefined;
      if (!file) return;
      await dosubaPatientRequest(file);
    } catch (e) {
      const error = e as Error;
      onError?.(error);
    }
  };

export const onUploadFileRequest =
  (
    fileList?: FileList,
    metadata?: UploadFileMetadata
  ): Event<Optional<UploadFileResponse>> =>
  async () => {
    const file = head(fileList);
    if (!file) return;
    const result = await uploadFileRequest(file, { ...metadata, public: true });

    if (result.failureStatus === RequestFailureStatus.Failure) {
      return;
    }

    return result.data;
  };
