import { FC, TextVariant, TypeVariant, withMemo } from "@laba/react-common";
import React from "react";
import {
  OptionsConfig,
  SelectInput
} from "components/inputs/SelectInput/SelectInput";
import { useCurrencyInputStyles } from "components/inputs/CurrencyInput/useCurrencyInputStyles";
import { UnitInput } from "components/inputs/UnitInput/UnitInput";
import { isEmpty } from "lodash-es";
import { Noop, notUndefined, Optional } from "@laba/ts-common";
import { ErrorText } from "components/text/ErrorText/ErrorText";
import { HelperText } from "components/text";

export interface CurrencyType {
  currencySymbol?: string;
  currencyText: string;
}

export interface CurrencyInputProps {
  className?: string;
  currencyTypeOptions: CurrencyType[];
  currencyTypeValue?: string;
  onChangeCurrencyTypeValue?: (v?: string) => void;
  currencyQuantityValue?: number;
  onChangeQuantityValue?: (v?: number) => void;
  error?: string;
  helperText?: string;
  onBlurQuantity?: Noop;
  onBlurTypeCurrency?: Noop;
  fullWidth?: boolean;
  quantityPlaceholder?: string;
  currencyTypeValueToOption: (value: string) => Optional<CurrencyType>;
  disabled?: boolean;
  minValue?: number;
  maxValue?: number;
}

const getSymbolText = (symbolText?: string): string => {
  return `(${symbolText})`;
};

const getCurrencyText = (c: CurrencyType): string => {
  return c.currencySymbol !== undefined
    ? `${c.currencyText} ${getSymbolText(c.currencySymbol)}`
    : c.currencyText;
};

const currencyTypeOptionsToSelectOption = (
  c?: CurrencyType
): Optional<OptionsConfig<string>> => {
  return c !== undefined
    ? {
        value: c.currencyText,
        text: getCurrencyText(c),
        itemId: c.currencyText
      }
    : undefined;
};

const CurrencyInputInt: FC<CurrencyInputProps> = ({
  currencyTypeOptions,
  currencyTypeValue,
  onChangeCurrencyTypeValue,
  currencyQuantityValue,
  onChangeQuantityValue,
  error,
  onBlurQuantity,
  onBlurTypeCurrency,
  fullWidth,
  quantityPlaceholder,
  currencyTypeValueToOption,
  disabled = false,
  minValue = 0,
  maxValue,
  helperText
}) => {
  const showErrorText = error !== undefined && !isEmpty(error);

  const classes = useCurrencyInputStyles({
    fullWidth,
    hasError: showErrorText,
    disabled
  });

  const selectOptions = currencyTypeOptions
    .map(currencyTypeOptionsToSelectOption)
    .filter(notUndefined);

  return (
    <div className={classes.container}>
      <div className={classes.rootInput}>
        <SelectInput
          value={currencyTypeValue}
          options={selectOptions}
          className={classes.select}
          type={TypeVariant.Outlined}
          onChange={onChangeCurrencyTypeValue}
          showHelperOrErrorText={false}
          onBlur={onBlurTypeCurrency}
          disabled={isEmpty(currencyTypeOptions) || disabled}
          getOptionFromValue={v =>
            currencyTypeOptionsToSelectOption(currencyTypeValueToOption(v))
          }
          showError={showErrorText}
          errorText={" "}
          showRightBorder={false}
          textVariant={TextVariant.Subtitle2}
        />
        <div className={classes.separatorContainer}>
          <div className={classes.separator} />
        </div>
        <UnitInput
          className={classes.quantity}
          fullWidth
          variant={TypeVariant.Outlined}
          quantityValue={currencyQuantityValue}
          onChangeQuantity={onChangeQuantityValue}
          withUnitSelector={false}
          showHelperOrErrorText={false}
          onBlur={onBlurQuantity}
          placeholder={quantityPlaceholder}
          showError={showErrorText}
          errorText={" "}
          disabled={disabled}
          minValue={minValue}
          maxValue={maxValue}
          showLeftBorder={false}
          showFocusBorder={false}
          decimalDigits={2}
        />
      </div>
      {showErrorText && <ErrorText showError={showErrorText} error={error} />}
      <HelperText text={helperText} />
    </div>
  );
};

export const CurrencyInput = withMemo(CurrencyInputInt);
