import { FC, getClassName, SizeVariant } from "@laba/react-common";
import React, { useState } from "react";
import { useProfileImageInputStyles } from "components/inputs/ProfileImageInput/useProfileImageInputStyles";
import { EditIcon, PhotoCameraIcon } from "components/icons";
import { NexupIconButton } from "components/buttons/IconButton/IconButton";
import { TypeInvertedVariant } from "components/utils/getGenericButtonColors";
import { ImageContainer } from "components/containers/ImageContainer/ImageContainer";
import { Spinner } from "components/spinners/Spinner/Spinner";
import { ButtonBase } from "components/buttons/ButtonBase/ButtonBase";
import {
  ErrorToStringMapper,
  useInputDropzone
} from "../hooks/useInputDropzone";

export enum ProfileImageSize {
  Small = "Small",
  Medium = "Medium"
}

export interface ProfileImageInputProps {
  className?: string;
  imgSource?: string;
  onUpload: (fileList: File[]) => void;
  size?: ProfileImageSize;
  isMobile?: boolean;
  noImgDefaultText: string;
  acceptedFileExtensions?: string[];
  maxFileSize?: number;
  onDropError?: (errors: string[]) => void;
  errorMessageMapper?: ErrorToStringMapper;
}

export const ProfileImageInput: FC<ProfileImageInputProps> = ({
  className,
  imgSource,
  onUpload,
  noImgDefaultText,
  size = ProfileImageSize.Medium,
  isMobile,
  acceptedFileExtensions = [".png", ".jpg", ".jpeg"],
  maxFileSize,
  onDropError,
  errorMessageMapper
}) => {
  const classes = useProfileImageInputStyles({ size, isMobile });
  const [isLoading, setIsLoading] = useState(false);

  const handleOnDrop = async (fileList: File[]) => {
    setIsLoading(true);
    await onUpload(fileList);
    setIsLoading(false);
  };

  const { getRootProps, getInputProps } = useInputDropzone({
    acceptedFileExtensions,
    uploadFiles: handleOnDrop,
    errorMessageMapper,
    maxFileSize,
    maxFiles: 1,
    onDropError
  });

  const { onClick, ...otherProps } = getRootProps();

  return (
    <ButtonBase
      {...otherProps}
      className={getClassName(className, classes.root)}
      onClick={onClick}
    >
      {!isLoading && (
        <>
          {imgSource ? (
            <ImageContainer
              className={classes.img}
              url={imgSource}
              alt={noImgDefaultText}
            />
          ) : (
            <PhotoCameraIcon className={classes.defaultIcon} />
          )}
          <NexupIconButton
            className={classes.icon}
            Icon={EditIcon}
            size={
              size === ProfileImageSize.Medium
                ? SizeVariant.Medium
                : SizeVariant.ExtraSmall
            }
            type={TypeInvertedVariant.Inverted}
            circular
          />
        </>
      )}
      {isLoading && (
        <Spinner size={size === ProfileImageSize.Small ? 24 : undefined} />
      )}
      <input {...getInputProps()} />
    </ButtonBase>
  );
};
