import {
  ReactElement,
  SizeVariant,
  StyleVariant,
  TypeVariant,
  useI18n
} from "@laba/react-common";
import { useCreateSuggestionContext } from "react-admin";
import React, { useCallback, useState } from "react";
import {
  CodeSystem,
  CodeSystemGroup,
  CodeSystemStatus,
  CodeSystemSystem,
  createCodeSystem,
  getCodeSystemList,
  ModelId,
  ResourceType,
  updateCodeSystem
} from "@laba/nexup-api";
import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { DefaultButton, TextInput } from "@laba/nexup-components";
import produce from "immer";
import { head, isEmpty } from "lodash-es";
import { Optional, RequestFailureStatus } from "@laba/ts-common";
import { useAppDispatch } from "store/store";
import { updateOrganizationCodeSystem } from "store/workspace/events";
import { tkCC } from "translation/i18n";

export interface CodeSystemCreateOptionDialogProps {
  codeSystemGroup: CodeSystemGroup;
  organizationId?: ModelId;
}

const tk = tkCC.inputs.codeSystemSelectorInput.createDialog;

const getEmptyCodeSystem = (
  system: CodeSystemSystem,
  organizationId: ModelId
): CodeSystem => {
  return {
    resourceType: ResourceType.CodeSystem,
    organization: organizationId,
    status: CodeSystemStatus.Active,
    system,
    concept: []
  };
};

const createOrUpdateCodeSystem = async (codeSystem: CodeSystem) => {
  if (codeSystem.id === undefined) {
    return createCodeSystem(codeSystem);
  }
  return updateCodeSystem(codeSystem);
};

const getCodeSystemOrEmpty = async (
  system: CodeSystemSystem,
  organizationId: ModelId
): Promise<Optional<CodeSystem>> => {
  const codeSystemResponse = await getCodeSystemList({
    system,
    status: CodeSystemStatus.Active,
    organization: organizationId
  });
  if (codeSystemResponse.failureStatus === RequestFailureStatus.Failure) {
    return;
  }
  return (
    head(codeSystemResponse.data.entries) ??
    getEmptyCodeSystem(system, organizationId)
  );
};
export const CodeSystemCreateOptionDialog = ({
  codeSystemGroup,
  organizationId
}: CodeSystemCreateOptionDialogProps): ReactElement => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [code, setCode] = useState(filter);
  const [display, setDisplay] = useState(filter);
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const codeSystemSystem = codeSystemGroup.system;

  const onCreateCode = useCallback(
    (createdCode: string) => {
      onCreate({
        id: createdCode
      });
    },
    [onCreate]
  );

  const onSave = useCallback(
    async (toCreateCode?: string, toCreateDisplay?: string) => {
      if (
        !toCreateCode ||
        isEmpty(toCreateCode) ||
        organizationId === undefined
      ) {
        onCancel();
        return;
      }

      const codeSystem = await getCodeSystemOrEmpty(
        codeSystemSystem,
        organizationId
      );
      if (!codeSystem) {
        onCancel();
        return;
      }
      if (codeSystem.concept?.map(value => value.code).includes(toCreateCode)) {
        onCreateCode(toCreateCode);
        return;
      }
      const newCodeSystem = produce(codeSystem, draft => {
        if (!draft.concept) draft.concept = [];
        draft.concept.push({
          code: toCreateCode,
          display: toCreateDisplay,
          property: []
        });
      });
      const response = await createOrUpdateCodeSystem(newCodeSystem);
      if (response.failureStatus === RequestFailureStatus.Failure) {
        onCancel();
        return;
      }
      await dispatch(updateOrganizationCodeSystem(response.data));
      onCreateCode(toCreateCode);
    },
    [onCreateCode, onCancel, dispatch, codeSystemSystem, organizationId]
  );

  return (
    <Dialog open fullWidth onClose={onCancel}>
      <DialogTitle>
        {t(tk.title, { system: codeSystemGroup.system })}
      </DialogTitle>
      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={2} paddingTop={1}>
          <Grid item sm={12}>
            <TextInput
              fullWidth
              label={t(tk.code)}
              onChange={setCode}
              value={code}
              variant={TypeVariant.Outlined}
            />
          </Grid>
          <Grid item sm={12}>
            <TextInput
              fullWidth
              label={t(tk.display)}
              onChange={setDisplay}
              value={display}
              variant={TypeVariant.Outlined}
            />
          </Grid>
          <Grid item sm={12}>
            <DefaultButton
              fullWidth
              text={t(tk.create)}
              onClick={() => onSave(code, display)}
              size={SizeVariant.Large}
              style={StyleVariant.Primary}
              type={TypeVariant.Contained}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
