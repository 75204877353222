import { getKeyObj, HttpMethod } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { ApiRequestResponse } from "request/types";
import { getIntegrationApiUrl, request } from "request/nexupRequest";

export interface SantaCatalinaPayrollRequestBody {
  tableName?: string;
  organizationIdList?: string[];
  startYear?: string;
  endYear?: string;
  startMonth?: string;
  endMonth?: string;
}

export const SantaCatalinaPayrollRequestBodyParamsKey = getKeyObj(
  createHydratedMock<SantaCatalinaPayrollRequestBody>()
);

export const santaCatalinaPayrollRequest = async (
  params: SantaCatalinaPayrollRequestBody
): Promise<ApiRequestResponse<unknown>> => {
  return request({
    method: HttpMethod.POST,
    url: getIntegrationApiUrl("santa-catalina/payroll"),
    data: params
  });
};

export interface SantaCatalinaPayrollAllocationsRequestBody {
  tableName?: string;
  organizationIdList?: string[];
  year?: string;
  month?: string;
  typeId?: string;
}

export const SantaCatalinaPayrollAllocationsRequestBodyParamsKey = getKeyObj(
  createHydratedMock<SantaCatalinaPayrollAllocationsRequestBody>()
);

export const santaCatalinaPayrollAllocationListRequest = async (
  params: SantaCatalinaPayrollAllocationsRequestBody
): Promise<ApiRequestResponse<unknown>> => {
  return request({
    method: HttpMethod.POST,
    url: getIntegrationApiUrl("santa-catalina/payroll/allocations"),
    data: params
  });
};
