import { Optional } from "@laba/ts-common";
import { DownloadLink } from "components/download/DownloadLink/DownloadLink";
import React, { useState } from "react";
import { AttachmentButtonLayout } from "./AttatchmentButtonLayout/AttachmentButtonLayout";

interface Props {
  attatchmentName?: string;
  onDownload?: () => Promise<Optional<Blob>>;
  withPreview?: boolean;
  disabled?: boolean;
  withSpinner?: boolean;
}

export const AttachmentButton: React.FC<Props> = ({
  attatchmentName,
  onDownload,
  withPreview = false,
  disabled = false,
  withSpinner = false
}) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const wrappedOnDownload = onDownload
    ? async () => {
        setIsDownloading(true);
        const optionalBlob = await onDownload();
        setIsDownloading(false);
        return optionalBlob;
      }
    : undefined;

  const attachmentLayout = (
    <AttachmentButtonLayout
      attatchmentName={attatchmentName}
      disabled={isDownloading || disabled}
      withSpinner={withSpinner}
    />
  );

  return wrappedOnDownload ? (
    <DownloadLink
      overrideButton={attachmentLayout}
      name={attatchmentName || ""}
      onDownload={wrappedOnDownload}
      withPreview={withPreview}
    />
  ) : (
    attachmentLayout
  );
};
