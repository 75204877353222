import { getFrontApiUrl, request } from "request/nexupRequest";
import { ApiRequestResponse } from "request/types";
import { ApiDate, getKeyObj, HttpMethod, ModelId } from "@laba/ts-common";
import { PractitionerRoleCodes } from "model/resource/person/practitionerRole";
import { createHydratedMock } from "ts-auto-mock";

export interface PractitionerInvitationData {
  token?: string;
  url?: string;
  role?: PractitionerRoleCodes[];
  expiration?: ApiDate;
  email?: string[];
  phone?: string[];
  organization?: ModelId;
}

const practitionerBasePath = "/practitioner-invitation";

export const createPractitionerInvitation = (
  data: PractitionerInvitationData
): Promise<ApiRequestResponse<PractitionerInvitationData>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(practitionerBasePath),
    data
  });

export const PractitionerInvitationDataParams = getKeyObj(
  createHydratedMock<PractitionerInvitationData>()
);
