import React from "react";
import { Create } from "components/generic/Create/Create";
import {
  ScheduleDefinitionForm,
  scheduleDefinitionJsonToModel,
  scheduleDefinitionTransform
} from "components/pages/NexupAdmin/resources/scheduleDefinition/ScheduleDefinitionForm";
import { FC } from "@laba/react-common";
import { ResourceType } from "@laba/nexup-api";

export const ScheduleDefinitionCreate: FC = () => {
  return (
    <Create
      resourceTransform={scheduleDefinitionTransform}
      resource={ResourceType.ScheduleDefinition}
      jsonToModel={scheduleDefinitionJsonToModel}
    >
      <ScheduleDefinitionForm />
    </Create>
  );
};
