import {
  borderMixin,
  createStyle,
  FlexAlignContent,
  FlexAlignItems,
  flexColumnMixin,
  flexItemMixin,
  FlexJustifyContent,
  flexRowMixin,
  getClassName,
  paddingMixin,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import {
  getThemeHeaderRadius,
  getThemeStyleColor,
  themeTextMixin
} from "model/themeUtils";

interface ResourceDetailHeaderDynamicStylesProps {
  headerColor?: string;
  statusColor?: string;
  isMobile?: boolean;
}
interface ResourceDetailHeaderClassNames {
  header?: string;
  headerContent?: string;
  headerRow?: string;
  titleAndTags?: string;
  title?: string;
  tag?: string;
  lastEditData?: string;
  statusAndCloseButton?: string;
  resourceDetailStatusLabel?: string;
  headerColor?: string;
  statusIcon?: string;
  root?: string;
  statusLabel?: string;
  secondHeaderRow?: string;
  resourceHeaderSkeleton?: string;
}

const useResourceDetailHeaderStaticStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexColumnMixin({}),
      width: "100%"
    },
    header: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.FlexStart
      }),
      ...borderMixin({
        radius: {
          top: getThemeHeaderRadius(theme)
        }
      }),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    },
    titleAndTagsDesktopVariant: {
      ...flexRowMixin({
        gap: 8,
        alignContent: FlexAlignContent.FlexEnd
      })
    },
    titleAndTagsMobileVariant: {
      ...flexColumnMixin({
        gap: 8
      })
    },
    titleAndTags: {
      ...flexItemMixin({ basis: "0", grow: 1 })
    },
    tag: {
      ...flexItemMixin({ shrink: 0 })
    },
    lastEditData: {
      ...paddingMixin({ right: 8 })
    },
    statusAndCloseButton: {
      ...flexItemMixin({ shrink: 0 }),
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.FlexEnd
      })
    },
    statusLabelRightPadding: { ...paddingMixin({ right: 4 }) },
    resourceDetailStatusLabel: {
      ...themeTextMixin(theme, TextVariant.Subtitle2, 1),
      textAlign: "center",
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    secondHeaderRow: {
      ...flexRowMixin({
        gap: 32,
        justifyContent: FlexJustifyContent.SpaceBetween,
        alignItems: FlexAlignItems.Center
      })
    },
    headerRowMobile: {
      ...flexRowMixin({
        gap: 8,
        justifyContent: FlexJustifyContent.SpaceBetween,
        alignItems: FlexAlignItems.FlexStart
      })
    },
    headerRowDesktop: {
      ...flexRowMixin({
        gap: 32,
        justifyContent: FlexJustifyContent.SpaceBetween,
        alignItems: FlexAlignItems.Center
      })
    },
    headerContent: {
      width: "100%"
    },
    headerContentMobile: {
      ...flexColumnMixin({ gap: 8 }),
      ...paddingMixin({ left: 24, right: 8, topBottom: 8 })
    },
    headerContentDesktop: {
      ...flexColumnMixin({ gap: 16 }),
      ...paddingMixin({ all: 16, left: 24 })
    },
    title: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    titleMobile: {
      ...paddingMixin({ top: 12 })
    },
    statusLabel: {
      ...flexItemMixin({ shrink: 0 })
    },
    statusLabelMobile: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.FlexEnd,
        gap: 4
      })
    },
    statusLabelDesktop: {
      ...flexItemMixin({ shrink: 0 }),
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.FlexEnd,
        gap: 8
      })
    },
    resourceHeaderSkeleton: {
      ...borderMixin({
        radius: {
          top: getThemeHeaderRadius(theme)
        }
      })
    },
    resourceHeaderSkeletonMobile: {
      height: 128
    },
    resourceHeaderSkeletonDesktop: {
      height: 80
    }
  }),
  "ResourceDetailHeaderStatic"
);

const useResourceDetailHeaderDynamicStyles = createStyle(
  (theme: Theme) => ({
    headerColorDynamic: (props: ResourceDetailHeaderDynamicStylesProps) => ({
      ...(props.headerColor
        ? borderMixin({
            style: "solid",
            color: props.headerColor,
            side: { bottom: 0, top: 0, right: 0, left: 8 }
          })
        : undefined)
    }),
    statusColorDynamic: (props: ResourceDetailHeaderDynamicStylesProps) => ({
      color:
        props.statusColor ??
        getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.GrayMedium
        )
    })
  }),
  "ResourceDetailHeaderDynamic"
);

export const useResourceDetailHeaderStyles = (
  props: ResourceDetailHeaderDynamicStylesProps
): ResourceDetailHeaderClassNames => {
  const staticClasses = useResourceDetailHeaderStaticStyles();
  const dynamicClasses = useResourceDetailHeaderDynamicStyles(props);

  const { isMobile } = props;
  return {
    header: getClassName(
      staticClasses.header,
      dynamicClasses.headerColorDynamic
    ),
    headerRow: isMobile
      ? staticClasses.headerRowMobile
      : staticClasses.headerRowDesktop,
    secondHeaderRow: staticClasses.secondHeaderRow,
    headerContent: getClassName(
      staticClasses.headerContent,
      isMobile
        ? staticClasses.headerContentMobile
        : staticClasses.headerContentDesktop
    ),
    titleAndTags: getClassName(
      staticClasses.titleAndTags,
      props.isMobile
        ? staticClasses.titleAndTagsMobileVariant
        : staticClasses.titleAndTagsDesktopVariant
    ),
    title: getClassName(
      staticClasses.title,
      isMobile ? staticClasses.titleMobile : undefined
    ),
    tag: staticClasses.tag,
    lastEditData: staticClasses.lastEditData,
    statusAndCloseButton: staticClasses.statusAndCloseButton,
    resourceDetailStatusLabel: staticClasses.resourceDetailStatusLabel,
    statusIcon: dynamicClasses.statusColorDynamic,
    root: staticClasses.root,
    statusLabel: getClassName(
      staticClasses.statusLabel,
      isMobile
        ? staticClasses.statusLabelMobile
        : staticClasses.statusLabelDesktop,
      props.isMobile ? staticClasses.statusLabelRightPadding : undefined
    ),
    resourceHeaderSkeleton: getClassName(
      staticClasses.resourceHeaderSkeleton,
      isMobile
        ? staticClasses.resourceHeaderSkeletonMobile
        : staticClasses.resourceHeaderSkeletonDesktop
    )
  };
};
