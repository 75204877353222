import { useListExportedFileName } from "components/hook/useExportedFileName";
import { List } from "components/generic/List/List";
import { codeSystemJsonToModel } from "components/pages/NexupAdmin/resources/codeSystem/CodeSystemForm";
import { Datagrid } from "components/generic/Datagrid/Datagrid";
import { TextField } from "components/generic/TextField/TextField";
import React, { FC } from "react";
import { ConceptResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { ProcedureBatteryConceptKey } from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { useI18n } from "@laba/react-common";

const tk = tkCP.adminPage[ConceptResourceType.ProcedureBatteryConcept];

export const ProcedureBatteryConceptList: FC = () => {
  const exportedFileName = useListExportedFileName(tk.title);
  const { t } = useI18n();

  return (
    <List
      resource={ConceptResourceType.ProcedureBatteryConcept}
      exportedFileName={exportedFileName}
      jsonToModel={codeSystemJsonToModel}
      extraFilters={[]}
    >
      <Datagrid>
        <TextField
          source={ProcedureBatteryConceptKey.id}
          label={t(tk.fields.id)}
        />
        <TextField
          source={ProcedureBatteryConceptKey.display}
          label={t(tk.fields.display)}
        />
      </Datagrid>
    </List>
  );
};
