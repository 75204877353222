import { Property } from "csstype";
import { marginMixin, paddingMixin } from "model/style/box";

export interface DestyledButtonResult {
  border?: Property.Border;
  borderImage?: Property.BorderImage;
  outline?: Property.Outline;
  textDecoration?: Property.TextDecoration;
  appearance?: Property.Appearance;
  cursor?: Property.Cursor;
  display?: Property.Display;
  backgroundColor?: Property.BackgroundColor;
}

export const destyledButtonMixin = (): DestyledButtonResult => {
  return {
    border: "none",
    borderImage: "initial",
    outline: "none",
    textDecoration: "none",
    appearance: "none",
    ...paddingMixin({ all: 0 }),
    ...marginMixin({ all: 0 }),
    cursor: "pointer",
    backgroundColor: "transparent"
  };
};
