import { ChatMessageType, FC, NexupChatMessage } from "@laba/react-common";
import {
  ScrollableContainer,
  ScrollDirection
} from "components/containers/ScrollableContainer/ScrollableContainer";
import {
  ChatMessageComponent,
  MessageOrigin
} from "components/chat/Chat/ChatMessages/ChatMessage/ChatMessageComponent";

import React, { ForwardedRef } from "react";
import { useChatMessagesStyle } from "components/chat/Chat/ChatMessages/useChatMessagesStyle";
import ReactVisibilitySensor from "react-visibility-sensor";
import { Optional } from "@laba/ts-common";

export interface ChatMessagesProps {
  className?: string;
  sender?: string;
  messages?: NexupChatMessage[];
  unsentMessages?: NexupChatMessage[];
  scrollRef?: ForwardedRef<HTMLDivElement>;
  onMessageBecomesVisible: (m: NexupChatMessage) => void;
  onDownloadAttachment?: (
    message: NexupChatMessage
  ) => () => Promise<Optional<Blob>>;
  withPreview?: boolean;
}

export const ChatMessages: FC<ChatMessagesProps> = ({
  className,
  sender,
  messages,
  unsentMessages,
  scrollRef,
  onMessageBecomesVisible,
  onDownloadAttachment,
  withPreview
}) => {
  const classes = useChatMessagesStyle();
  return (
    <ScrollableContainer
      className={className}
      scrollDirection={ScrollDirection.Vertical}
    >
      <div className={classes.container}>
        {messages?.map(m => {
          const sent = m.author === sender;
          return (
            <ReactVisibilitySensor
              key={m.id}
              onChange={(visible: boolean) => {
                visible && onMessageBecomesVisible(m);
              }}
            >
              <ChatMessageComponent
                className={sent ? classes.send : classes.received}
                message={m.body}
                messageOrigin={
                  sent ? MessageOrigin.Sent : MessageOrigin.Received
                }
                attatchmentName={m.mediaFilename}
                onDownload={
                  m.mediaLink && onDownloadAttachment
                    ? onDownloadAttachment(m)
                    : undefined
                }
                withPreview={withPreview}
              />
            </ReactVisibilitySensor>
          );
        })}
        {unsentMessages?.map(m => {
          const isMediaMessage = m.type === ChatMessageType.Media;
          return (
            <ChatMessageComponent
              key={m.uuid}
              className={classes.send}
              message={!isMediaMessage ? m.body : undefined}
              attatchmentName={isMediaMessage ? m.mediaFilename : undefined}
              messageOrigin={MessageOrigin.Sent}
              unsent
            />
          );
        })}
        <div ref={scrollRef} />
      </div>
    </ScrollableContainer>
  );
};
