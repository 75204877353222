import {
  borderMixin,
  createStyle,
  FlexAlignItems,
  flexItemMixin,
  FlexJustifyContent,
  flexRowMixin,
  marginMixin,
  paddingMixin,
  pxToRem
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { StyleVariant, TextVariant } from "model/themeVariant";
import { NexupColor } from "model/nexupColor";

export interface HeaderStyleProps {
  isMobile: boolean;
  style: StyleVariant;
  isLoggedIn: boolean;
}

export const useHeaderStyle = createStyle(
  (theme: Theme) => ({
    container: (props: HeaderStyleProps) => ({
      ...paddingMixin({
        left: 16,
        right: props.isMobile ? 20 : 32
      }),
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center
      }),
      ...borderMixin({
        style: "solid",
        side: { all: 1 },
        color: NexupColor.Background
      }),
      backgroundColor: getThemeStyleColor(
        theme,
        props.style,
        ThemeStyleColorVariant.Contrast
      ),
      width: "100%",
      height: pxToRem(65)
    }),
    menuButton: {
      ...marginMixin({ right: 12 })
    },
    logoButton: (props: HeaderStyleProps) => ({
      ...marginMixin({
        left: !props.isLoggedIn ? 52 : 0,
        right: 80
      })
    }),
    defaultCursor: { cursor: "default" },
    Logo: {
      height: pxToRem(30),
      width: "auto",
      objectFit: "contain",
      overflow: "hidden"
    },
    text: (props: HeaderStyleProps) => ({
      ...themeTextMixin(theme, TextVariant.Body2, 1),
      color: getThemeStyleColor(
        theme,
        props.style,
        ThemeStyleColorVariant.GrayDark
      ),
      minWidth: "min-content"
    }),
    endContainer: {
      ...flexRowMixin({
        justifyContent: FlexJustifyContent.Center,
        alignItems: FlexAlignItems.Center,
        gap: 16
      }),
      ...marginMixin({
        left: "auto"
      }),
      height: "100%"
    },
    verticalSeparator: {
      ...marginMixin({
        left: 16
      }),
      ...borderMixin({
        style: "solid",
        color: NexupColor.Background,
        side: { left: pxToRem(1) }
      }),
      height: pxToRem(36)
    },
    headerButton: {
      ...flexRowMixin({
        justifyContent: FlexJustifyContent.Center,
        alignItems: FlexAlignItems.Center
      }),
      height: "100%"
    },
    centerContainer: {
      ...flexItemMixin({ grow: 1, shrink: 1, basis: "0%" }),
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center
      })
    },
    leftContainer: {
      ...flexRowMixin({ alignItems: FlexAlignItems.Center, gap: 4 })
    }
  }),
  "Header"
);
