import { Context, createContext, useContext } from "react";
import { CountryAreaPhoneCode, Optional } from "@laba/ts-common";

export type CountryCodeState = {
  countryCode?: CountryAreaPhoneCode;
};

const defaultCountryCode = CountryAreaPhoneCode.Argentina;

export type CountryCodeContext = Context<CountryCodeState>;

export interface InitCountryCode {
  countryCodeContext: CountryCodeContext;
  useCountryCode: () => Optional<CountryAreaPhoneCode>;
}

const createUseCountryCode =
  (context: CountryCodeContext) => (): Optional<CountryAreaPhoneCode> =>
    useContext(context).countryCode;

export const initCountryCode = (): InitCountryCode => {
  const countryCodeContext = createContext<CountryCodeState>({
    countryCode: defaultCountryCode
  });
  const useCountryCode = createUseCountryCode(countryCodeContext);

  return { countryCodeContext, useCountryCode };
};
