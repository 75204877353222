import {
  createStyle,
  FlexAlignItems,
  FlexAlignSelf,
  flexItemMixin,
  flexRowMixin,
  Theme
} from "@laba/react-common";

export const useLocationCardButtonsStyles = createStyle(
  (_theme: Theme) => ({
    root: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        gap: 16
      })
    },
    button: {
      ...flexItemMixin({ shrink: 1, align: FlexAlignSelf.FlexEnd })
    }
  }),
  "LocationCardButtons"
);
