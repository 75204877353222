import { FC, IconC } from "@laba/react-common";
import React from "react";
import { NexupColor } from "model/nexupColor";
import { Tag, TagPaddingVariant } from "components/tags/Tag/Tag";
import { PaymentsInvertedIcon } from "components/icons";

export interface PriceTagProps {
  price?: string;
  title?: string;
  className?: string;
  backgroundColor?: string;
  Icon?: IconC;
  paddingVariant?: TagPaddingVariant;
}

export const PriceTag: FC<PriceTagProps> = ({
  className,
  price,
  backgroundColor = NexupColor.DarkGray,
  Icon = PaymentsInvertedIcon,
  title,
  paddingVariant
}) => (
  <Tag
    StartIcon={Icon}
    backgroundColor={backgroundColor}
    className={className}
    text={price}
    title={title ?? price}
    paddingVariant={paddingVariant}
  />
);
