import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { ModelId } from "model/primitives/model/model";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { ApiDate, HttpMethod } from "@laba/ts-common";
import { ProcedureReport } from "model/resource/procedures/procedureReport/procedureReport";
import { Code } from "model/resource/entities/codeSystem";
import { HospitalizationType } from "api/medical/hospitalization";

export interface ProcedureReportQueryParams {
  withPatient?: boolean;
  withHealthcarePayer?: boolean;
  withEncounter?: boolean;
  withPractitioner?: boolean;
  withPerformer?: boolean;
  withMeasurementGroup?: boolean;
  withLocation?: boolean;
  withDefinition?: boolean;
}

export interface ProcedureReportListQueryParams
  extends ListQueryParams,
    ProcedureReportQueryParams {
  category?: Code;
  content?: string;
  definition?: ModelId;
  encounter?: ModelId;
  endDate?: ApiDate;
  hospitalizationType?: HospitalizationType;
  organization?: ModelId;
  patient?: ModelId;
  performer?: ModelId;
  payer?: ModelId;
  startDate?: ApiDate;
  status?: Code;
  statusExclude?: Code[];
  statusReason?: Code[];
}

const procedureReportBasePath = "/procedure-report";

export const getProcedureReport = (
  id: ModelId,
  params?: ProcedureReportQueryParams
): Promise<ApiRequestResponse<ProcedureReport>> => {
  return request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${procedureReportBasePath}/${id}`),
    params
  });
};

export const getProcedureReportList = (
  params: ProcedureReportListQueryParams
): Promise<ApiPageRequestResponse<ProcedureReport>> => {
  return request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(procedureReportBasePath),
    params
  });
};

export const cancelProcedureReport = (
  id: ModelId
): Promise<ApiPageRequestResponse<ProcedureReport>> =>
  request({
    method: HttpMethod.POST,
    url: [getFrontApiUrl(procedureReportBasePath), id, "cancel"].join("/")
  });

export const createProcedureReport = (
  data: ProcedureReport
): Promise<ApiRequestResponse<ProcedureReport>> => {
  return request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(procedureReportBasePath),
    data
  });
};

export const editProcedureReport = (
  data: ProcedureReport
): Promise<ApiRequestResponse<ProcedureReport>> => {
  return request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${procedureReportBasePath}/${data.id}`),
    data
  });
};
