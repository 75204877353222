import { InputProps, useInput } from "react-admin";
import { Optional, Noop } from "@laba/ts-common";

interface UseReactHookFormInputReturnData<V> {
  onChange: (...event: any[]) => void;
  onBlur: Noop;
  value: Optional<V>;
}

export const useReactHookFormInput = <V>(
  props: InputProps<V>
): UseReactHookFormInputReturnData<V> => {
  const {
    field: { value, onBlur, onChange }
  } = useInput(props);

  const castedValue =
    (value as unknown as Optional<V> | "") === "" ? undefined : value;

  return { value: castedValue, onBlur, onChange };
};
