import { ApiDate, getKeyObj, HttpMethod } from "@laba/ts-common";
import { ModelId } from "model/primitives/model/model";
import { Person } from "model/resource/person/person";
import { getFrontPatientPortalApiUrl, requestAnon } from "request/nexupRequest";
import { ApiRequestResponse } from "request/types";
import { createHydratedMock } from "ts-auto-mock";

export interface RegisterRequestParams {
  identificationNumber?: string;
  affiliationNumber?: string;
  birthDate?: ApiDate;
  email?: string;
  username?: string;
  password?: string;
  phone?: string;
  organization?: ModelId;
}

export const registerRequest = async (
  params: RegisterRequestParams
): Promise<ApiRequestResponse<Person>> =>
  requestAnon({
    method: HttpMethod.POST,
    url: getFrontPatientPortalApiUrl("/register"),
    data: { ...params }
  });

export interface RegisterRequestParamsWithExtraData
  extends RegisterRequestParams {
  passwordAgain: string;
  acceptsTermsAndConditions: boolean;
}

export const registerRequestParamsKeyWithExtraData = getKeyObj(
  createHydratedMock<RegisterRequestParamsWithExtraData>()
);
