import { useEffect } from "react";

const resetAppState = () => {
  localStorage.clear();
  // eslint-disable-next-line no-restricted-properties
  window.location.href = "/";
};

export const useResetAppState = (): void => {
  // eslint-disable-next-line no-restricted-properties
  const { pathname } = window.location;
  const isResetPath = pathname === "/reset";

  useEffect(() => {
    if (isResetPath) {
      resetAppState();
    }
  }, [isResetPath]);
};
