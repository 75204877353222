import { FC, useI18n } from "@laba/react-common";
import React from "react";
import { ResourceType, ScheduleParamsKey } from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { TextInput } from "components/generic/TextInput/TextInput";
import { NumberInput } from "components/generic/NumberInput/TextInput";
import { UploadFileImageInput } from "components/generic/UploadFileImageInput/UploadFileImageInput";

export const tk = tkCP.adminPage[ResourceType.Schedule];

export const ScheduleOnlineBookingContent: FC = () => {
  const { t } = useI18n();

  return (
    <>
      <BooleanInput
        source={ScheduleParamsKey.onlineAppointmentBookingConfiguration.enabled}
        label={t(
          tk.fields.onlineAppointmentBookingConfigurationScheduleEnabled
        )}
      />
      <TextInput
        source={
          ScheduleParamsKey.onlineAppointmentBookingConfiguration.scheduleName
        }
        label={t(tk.fields.onlineAppointmentBookingConfigurationScheduleName)}
      />
      <NumberInput
        source={
          ScheduleParamsKey.onlineAppointmentBookingConfiguration.bookingWindow
        }
        label={t(
          tk.fields.onlineAppointmentBookingConfigurationScheduleBookingWindow
        )}
      />
      <UploadFileImageInput
        source={
          ScheduleParamsKey.onlineAppointmentBookingConfiguration.photo.url
        }
        label={t(tk.fields.onlineAppointmentBookingConfigurationSchedulePhoto)}
      />
    </>
  );
};
