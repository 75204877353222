import {
  createStyle,
  getClassName,
  pxToRem,
  StyleVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor } from "model/themeUtils";

interface IconOrImgStylesProps {
  fullSize?: boolean;
  iconColorVariant?: ThemeStyleColorVariant;
}
interface IconOrImgStylesClassNames {
  default: string;
}
const useIconOrImgStylesStatic = createStyle(
  (_theme: Theme) => ({
    defaultStatic: {
      objectFit: "cover"
    },
    normalSize: {
      height: pxToRem(24),
      width: pxToRem(24)
    },
    fullSize: {
      height: "100%",
      width: "100%"
    }
  }),
  "IconOrImgStatic"
);

const useIconOrImgStylesDynamic = createStyle(
  (theme: Theme) => ({
    defaultDynamic: (props: IconOrImgStylesProps) => ({
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        props.iconColorVariant
      )
    })
  }),
  "IconOrImgDynamic"
);

export const useIconOrImgStyles = (
  props: IconOrImgStylesProps
): IconOrImgStylesClassNames => {
  const staticClasses = useIconOrImgStylesStatic();
  const dynamicClasses = useIconOrImgStylesDynamic(props);

  return {
    default: getClassName(
      staticClasses.defaultStatic,
      dynamicClasses.defaultDynamic,
      props.fullSize ? staticClasses.fullSize : staticClasses.normalSize
    )
  };
};
