import React from "react";
import { FC, IconC } from "@laba/react-common";
import { Noop } from "@laba/ts-common";
import { EmptySearch } from "components/svg/EmptySearch";
import { GenericPlaceholder } from "components/placeholder/GenericPlaceholder/GenericPlaceholder";

export interface NoResultsPlaceholderProps {
  title: string;
  prompt?: string;
  className?: string;
  buttonText?: string;
  StartIcon?: IconC;
  onClick?: Noop;
}

export const NoResultsPlaceholder: FC<NoResultsPlaceholderProps> = ({
  title,
  prompt,
  className,
  buttonText,
  StartIcon,
  onClick
}) => {
  return (
    <GenericPlaceholder
      title={title}
      prompt={prompt}
      className={className}
      buttonText={buttonText}
      StartIcon={StartIcon}
      onClick={onClick}
    >
      <EmptySearch />
    </GenericPlaceholder>
  );
};
