import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import { FC } from "@laba/react-common";

export const WristbandIcon: FC<SvgIconProps> = props => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M20 2.00684H12.913C12.3826 2.00695 11.874 2.21774 11.499 2.59284L2.59302 11.4988C2.21808 11.8739 2.00745 12.3825 2.00745 12.9128C2.00745 13.4432 2.21808 13.9518 2.59302 14.3268L9.67902 21.4128C10.0541 21.7878 10.5627 21.9984 11.093 21.9984C11.6233 21.9984 12.132 21.7878 12.507 21.4128L21.413 12.5068C21.789 12.1328 22 11.6238 22 11.0938V4.00684C22 3.4764 21.7893 2.9677 21.4142 2.59262C21.0392 2.21755 20.5305 2.00684 20 2.00684ZM17.007 8.99984C16.7407 9.00586 16.4758 8.95861 16.228 8.86086C15.9802 8.76312 15.7544 8.61685 15.5638 8.43063C15.3733 8.24442 15.2219 8.02202 15.1185 7.77649C15.0152 7.53096 14.9619 7.26725 14.9618 7.00085C14.9617 6.73444 15.0149 6.4707 15.1182 6.22512C15.2214 5.97954 15.3727 5.75707 15.5631 5.57076C15.7536 5.38445 15.9793 5.23807 16.2271 5.1402C16.4748 5.04233 16.7397 4.99495 17.006 5.00084C17.5286 5.01239 18.0259 5.22806 18.3914 5.60168C18.7569 5.97529 18.9617 6.47716 18.9618 6.99985C18.9619 7.52254 18.7574 8.02451 18.3921 8.3983C18.0267 8.7721 17.5296 8.98802 17.007 8.99984Z"
      fill="#4F4F4F"
    />
  </SvgIcon>
);
