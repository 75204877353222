import { Report } from "model/primitives/report";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { ResourceModel, ResourceType } from "model/primitives/resourceModel";
import { Address } from "model/primitives/address";
import { Code } from "model/resource/entities/codeSystem";
import { Email, Phone } from "model/primitives/contactPoint";
import { HoursOfOperation } from "model/resource/utils/hoursOfOperation";
import { getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { OpenCode } from "model/primitives/model/model";
import { Organization } from "../organization/organization";

export enum LocationStatus {
  Active = "active",
  Inactive = "inactive"
}

export enum LocationType {
  Bed = "Bed",
  Room = "Room",
  Section = "Section",
  Building = "Building"
}

export enum KnownLocationCategory {
  Directory = "Directory",
  Hospitalization = "Hospitalization"
}

export type LocationCategory = OpenCode<KnownLocationCategory>;

export interface Contact {
  phone?: Phone;
  email?: Email;
}

export type LocationResourceType = ResourceType.Location | ResourceType.Bed;

export interface Location<
  RType extends LocationResourceType = LocationResourceType,
  LType extends LocationType = LocationType
> extends ResourceModel<RType> {
  name: string;
  description?: string;
  partOf?: ModelReference<Location>;
  status: LocationStatus;
  type?: LType;
  organization?: ModelReference<Organization>;
  hisCode?: string;
  reportConfig?: Report[];
  children?: Location[];
  latitude?: number;
  longitude?: number;
  address?: Address;
  directoryType?: Code;
  category?: LocationCategory;
  hoursOfOperation?: HoursOfOperation[];
  availabilityExceptions?: string;
  payerPlanList?: Code[];
  payerSpecialityList?: Code[];
  telecom?: Contact;
  website?: string;
  photo?: string;
}

export const LocationKey = getKeyObj<Location>(createHydratedMock<Location>());
