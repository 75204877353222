import React from "react";
import { Children, ReactElement, StyleVariant } from "@laba/react-common";
import { BaseAutocompleteOption } from "../BaseAutocompleteOption/BaseAutocompleteOption";
import { AutocompleteOptionConfig } from "../../Autocomplete";

interface Props<V> {
  children?: Children;
  isMobile?: boolean;
  option: AutocompleteOptionConfig<V>;
  onRemoveSelectedOption?: (optionValue: V) => void;
  removeIconStyle?: StyleVariant;
  disabled?: boolean;
  hasBackgroundColor?: boolean;
  fullWidth?: boolean;
  isSelectedOption?: boolean;
  actionControlComponent?: ReactElement;
  withPadding?: boolean;
}

export const AutocompleteTitleOnlyOptionWrapper = <V,>({
  children,
  option,
  onRemoveSelectedOption,
  removeIconStyle,
  disabled,
  hasBackgroundColor,
  fullWidth,
  isSelectedOption,
  isMobile,
  actionControlComponent,
  withPadding
}: Props<V>): ReactElement => {
  const { text: titleText, title, value, Icon } = option;
  return (
    <BaseAutocompleteOption
      optionValue={value}
      onRemoveSelectedOption={onRemoveSelectedOption}
      removeIconStyle={removeIconStyle}
      disabled={disabled}
      hasBackgroundColor={hasBackgroundColor}
      fullWidth={fullWidth}
      isSelectedOption={isSelectedOption}
      isMobile={isMobile}
      title={title}
      titleText={titleText}
      actionControlComponent={actionControlComponent}
      Icon={Icon}
      withPadding={withPadding}
    >
      {children}
    </BaseAutocompleteOption>
  );
};
