import { getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { OpenCode } from "./model/model";

export enum KnownQuantityUnit {
  Meter = "m",
  Kilogram = "kg",
  Unit = "unidad",
  Milliliters = "ml",
  Milligrams = "mg",
  Gram = "g",
  Microgram = "ug",
  Nanogram = "ng"
}

export type QuantityUnit = OpenCode<KnownQuantityUnit>;

export interface Quantity {
  quantity?: number;
  unit?: QuantityUnit;
}

export const QuantityKey = getKeyObj<Quantity>(createHydratedMock<Quantity>());
