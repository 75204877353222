import { Theme, ThemeStyleColorVariant } from "model/theme";
import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  flexItemMixin,
  FlexJustifyContent,
  flexRowMixin,
  getClassName,
  paddingMixin,
  pxToRem
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { StyleVariant, TextVariant } from "model/themeVariant";

export interface UserMenuButtonStyleProps {
  styleVariant: StyleVariant;
  showProfileDataOnDropdown?: boolean;
}

interface UserMenuButtonStyleClassNames {
  rootWithDivider?: string;
  root?: string;
  usernameText?: string;
  userExtraData?: string;
  textWrapper?: string;
  badge?: string;
}

const useUserMenuDataStyleStatic = createStyle(
  (theme: Theme) => ({
    usernameText: {
      ...themeTextMixin(theme, TextVariant.Subtitle2, 1),
      maxWidth: "100%"
    },
    userExtraData: {
      ...themeTextMixin(theme, TextVariant.Caption, 1),
      maxWidth: "100%"
    },
    rootPopover: {
      ...paddingMixin({ top: 8, bottom: 8, leftRight: 16 }),
      ...flexRowMixin({
        justifyContent: FlexJustifyContent.SpaceBetween,
        alignItems: FlexAlignItems.Center,
        gap: 32
      }),
      height: pxToRem(52)
    },
    badge: {
      ...flexItemMixin({ shrink: 0 })
    },
    textWrapperStatic: {
      ...flexColumnMixin({
        justifyContent: FlexJustifyContent.Center,
        alignItems: FlexAlignItems.FlexStart,
        gap: 4
      })
    },
    rootWithDivider: {
      ...flexColumnMixin({})
    }
  }),
  "UserMenuDataStatic"
);

const useUserMenuDataStyleDynamic = createStyle(
  (theme: Theme) => ({
    textWrapperDynamic: (props: UserMenuButtonStyleProps) => ({
      color: getThemeStyleColor(
        theme,
        props.styleVariant,
        ThemeStyleColorVariant.GrayDark
      )
    })
  }),
  "UserMenuButtonDynamic"
);

export const useUserMenuDataStyle = (
  props: UserMenuButtonStyleProps
): UserMenuButtonStyleClassNames => {
  const staticClasses = useUserMenuDataStyleStatic();
  const dynamicClasses = useUserMenuDataStyleDynamic(props);

  return {
    rootWithDivider: staticClasses.rootWithDivider,
    root: props.showProfileDataOnDropdown
      ? staticClasses.rootPopover
      : undefined,
    textWrapper: getClassName(
      staticClasses.textWrapperStatic,
      dynamicClasses.textWrapperDynamic
    ),
    usernameText: staticClasses.usernameText,
    userExtraData: staticClasses.userExtraData,
    badge: staticClasses.badge
  };
};
