import {
  createStyle,
  flexColumnMixin,
  GridAutoFlow,
  gridContainerMixin,
  Theme
} from "@laba/react-common";

interface Props {
  isChild?: boolean;
}

interface MobileOdontogramGridStylesClassNames {
  root: string;
  row: string;
}

const useMobileOdontogramGridStyles = createStyle(
  (_theme: Theme) => ({
    root: {
      ...flexColumnMixin({ gap: 12 })
    },
    rowAdult: {
      ...gridContainerMixin({
        templateColumn: ["repeat(4,1fr)"],
        autoFlow: GridAutoFlow.Row,
        rowGap: 12
      })
    },
    rowChildren: {
      ...gridContainerMixin({
        templateColumn: ["repeat(5,1fr)"],
        autoFlow: GridAutoFlow.Row,
        rowGap: 12
      })
    }
  }),
  "MobileOdontogramGrid"
);

export const useMobileOdontogramGridStylesClassNames = (
  props: Props
): MobileOdontogramGridStylesClassNames => {
  const classes = useMobileOdontogramGridStyles();
  const { isChild } = props;
  return {
    root: classes.root,
    row: isChild ? classes.rowChildren : classes.rowAdult
  };
};
