import React from "react";
import { FC } from "@laba/react-common";
import { NexupColor } from "model/nexupColor";
import { OdontogramIconProps } from "components/icons/OdontogramIcons/utils";

export const ParadentosisIcon: FC<OdontogramIconProps> = props => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill={props.iconColor ?? NexupColor.Black}
    >
      <path d="M7.81543 14.3896H4.48438V12.6406H7.81543C8.39551 12.6406 8.86426 12.5469 9.22168 12.3594C9.5791 12.1719 9.83984 11.9141 10.0039 11.5859C10.1738 11.252 10.2588 10.8711 10.2588 10.4434C10.2588 10.0391 10.1738 9.66113 10.0039 9.30957C9.83984 8.95215 9.5791 8.66504 9.22168 8.44824C8.86426 8.23145 8.39551 8.12305 7.81543 8.12305H5.16113V19.1621H2.95508V6.36523H7.81543C8.80566 6.36523 9.64648 6.54102 10.3379 6.89258C11.0352 7.23828 11.5654 7.71875 11.9287 8.33398C12.292 8.94336 12.4736 9.64062 12.4736 10.4258C12.4736 11.252 12.292 11.9609 11.9287 12.5527C11.5654 13.1445 11.0352 13.5986 10.3379 13.915C9.64648 14.2314 8.80566 14.3896 7.81543 14.3896Z" />
      <path d="M19.918 17.1934V5.66211H22.0449V19.1621H20.1201L19.918 17.1934ZM13.7305 14.5127V14.3281C13.7305 13.6074 13.8154 12.9512 13.9854 12.3594C14.1553 11.7617 14.4014 11.249 14.7236 10.8213C15.0459 10.3877 15.4385 10.0566 15.9014 9.82812C16.3643 9.59375 16.8857 9.47656 17.4658 9.47656C18.04 9.47656 18.5439 9.58789 18.9775 9.81055C19.4111 10.0332 19.7803 10.3525 20.085 10.7686C20.3896 11.1787 20.6328 11.6709 20.8145 12.2451C20.9961 12.8135 21.125 13.4463 21.2012 14.1436V14.7324C21.125 15.4121 20.9961 16.0332 20.8145 16.5957C20.6328 17.1582 20.3896 17.6445 20.085 18.0547C19.7803 18.4648 19.4082 18.7812 18.9688 19.0039C18.5352 19.2266 18.0283 19.3379 17.4482 19.3379C16.874 19.3379 16.3555 19.2178 15.8926 18.9775C15.4355 18.7373 15.0459 18.4004 14.7236 17.9668C14.4014 17.5332 14.1553 17.0234 13.9854 16.4375C13.8154 15.8457 13.7305 15.2041 13.7305 14.5127ZM15.8486 14.3281V14.5127C15.8486 14.9463 15.8867 15.3506 15.9629 15.7256C16.0449 16.1006 16.1709 16.4316 16.3408 16.7188C16.5107 17 16.7305 17.2227 17 17.3867C17.2754 17.5449 17.6035 17.624 17.9844 17.624C18.4648 17.624 18.8604 17.5186 19.1709 17.3076C19.4814 17.0967 19.7246 16.8125 19.9004 16.4551C20.082 16.0918 20.2051 15.6875 20.2695 15.2422V13.6514C20.2344 13.3057 20.1611 12.9834 20.0498 12.6846C19.9443 12.3857 19.8008 12.125 19.6191 11.9023C19.4375 11.6738 19.2119 11.498 18.9424 11.375C18.6787 11.2461 18.3652 11.1816 18.002 11.1816C17.6152 11.1816 17.2871 11.2637 17.0176 11.4277C16.748 11.5918 16.5254 11.8174 16.3496 12.1045C16.1797 12.3916 16.0537 12.7256 15.9717 13.1064C15.8896 13.4873 15.8486 13.8945 15.8486 14.3281Z" />
    </svg>
  );
};
