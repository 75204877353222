import {
  ObservationStatus,
  PatientTagCodes
} from "model/resource/medical/observation/observation";
import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { ModelId } from "model/primitives/model/model";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { getKeyObj, HttpMethod } from "@laba/ts-common";
import { PatientTag } from "model/resource/medical/observation/patientTag";
import { createHydratedMock } from "ts-auto-mock";

export const PatientTagBasePath = "/patient-tag";

export interface PatientTagListQueryParams extends ListQueryParams {
  organization?: ModelId;
  encounter?: ModelId;
  patient?: ModelId;
  practitioner?: ModelId;
  type?: PatientTagCodes;
  status?: ObservationStatus;
}

export const PatientTagListQueryParamsKey =
  getKeyObj<PatientTagListQueryParams>(
    createHydratedMock<PatientTagListQueryParams>()
  );

export const getPatientTagList = (
  params: PatientTagListQueryParams
): Promise<ApiPageRequestResponse<PatientTag>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(PatientTagBasePath),
    params
  });

export const getPatientTag = (
  id: ModelId
): Promise<ApiRequestResponse<PatientTag>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${PatientTagBasePath}/${id}`)
  });

export const createPatientTag = (
  data: PatientTag
): Promise<ApiRequestResponse<PatientTag>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(PatientTagBasePath),
    data
  });

export const updatePatientTag = (
  data: PatientTag
): Promise<ApiRequestResponse<PatientTag>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${PatientTagBasePath}/${data.id}`),
    data
  });
