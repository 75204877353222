import React from "react";
import { FC } from "@laba/react-common";
import { Create } from "components/generic/Create/Create";
import {
  MedicationForm,
  medicationJsonToModel,
  medicationTransform
} from "components/pages/NexupAdmin/resources/medication/MedicationForm";
import { ResourceType } from "@laba/nexup-api";

export const MedicationCreate: FC = () => {
  return (
    <Create
      resourceTransform={medicationTransform}
      resource={ResourceType.Medication}
      jsonToModel={medicationJsonToModel}
    >
      <MedicationForm />
    </Create>
  );
};
