import {
  ContactPointRole,
  ContactPointType
} from "model/primitives/contactPoint";
import { ResourceType } from "model/primitives/resourceModel";
import { Gender, ValidationStatus } from "../person";
import { Practitioner } from "../practitioner/practitioner";

export const getPractitionerMock = (): Practitioner => ({
  id: "260",
  federationId: "cc410821-8bde-4452-873a-55c05990a0d8",
  personalData: {
    firstName: "Nicolás",
    otherName: "Gabriel",
    lastName: "Zorzano",
    birthDate: "1992-09-24T00:00:00.000Z",
    gender: Gender.Male,
    identifierList: [
      {
        system:
          "http://platform.lab-a.com.ar/fhir/StructureDefinition/person-nexup-identifier",
        value: "cc410821-8bde-4452-873a-55c05990a0d8",
        files: [],
        property: [],
        resourceType: ResourceType.Identifier
      },
      {
        system: "http://www.renaper.gob.ar/dni",
        value: "37124217",
        files: [],
        property: [],
        resourceType: ResourceType.Identifier
      },
      {
        system:
          "http://platform.lab-a.com.ar/fhir/StructureDefinition/practitioner-signature",
        value: "Nicolas Zorzano",
        files: [
          {
            url: "https://upload.wikimedia.org/wikipedia/commons/6/6d/Firmas_cover.png",
            name: "Titulo",
            resourceType: ResourceType.Attachment
          }
        ],
        property: [],
        resourceType: ResourceType.Identifier
      }
    ]
  },
  contactData: {
    phoneList: [
      {
        id: "20",
        type: ContactPointType.Home,
        value: "1159297217",
        role: ContactPointRole.NotificationPhone,
        resourceType: ResourceType.Phone
      }
    ],
    emailList: [
      {
        id: "21",
        type: ContactPointType.Work,
        value: "nicozorza@gmail.com",
        role: ContactPointRole.NotificationEmail,
        resourceType: ResourceType.Email
      }
    ],
    addressList: []
  },
  validationStatus: ValidationStatus.Temporal,
  organization: ["10"],
  healthcareProfessionalData: {
    registrationQualification: [
      {
        type: "Medico M.N.",
        issuer: "Medico M.N.",
        identifier: {
          system:
            "http://platform.lab-a.com.ar/fhir/StructureDefinition/person-nexup-identifier",
          value: "12312312312",
          files: [],
          property: [],
          resourceType: ResourceType.Identifier
        }
      }
    ]
  },
  user: "e0e33859-4ab1-4160-900e-3c39005fde2f",
  creationDate: "2022-08-11T17:14:20.699Z",
  resourceType: ResourceType.Practitioner
});
