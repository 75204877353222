import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  BaseHomeItemKey,
  BaseHomeItemProperty,
  BaseHomeItemPropertyKey,
  HomeItemPropertyType,
  HomeItemType,
  ModelId,
  Organization,
  OrganizationKey,
  ResourceType,
  WhiteLabelButtonVariant,
  WhiteLabelClickAction
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { NumberInput } from "components/generic/NumberInput/TextInput";
import { TextInput } from "components/generic/TextInput/TextInput";
import { FormDataConsumer } from "components/generic/FormDataConsumer/FormDataConsumer";
import { ThymeleafInput } from "components/generic/ThymeleafInput/ThymeleafInput";
import { UploadFileImageInput } from "components/generic/UploadFileImageInput/UploadFileImageInput";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useSelector } from "react-redux";
import { useWatchResourceForm } from "components/hook/useResourceContext";

export interface OrganizationWhiteLabelItemContentProps {
  listSource: string;
  columnsSource: string;
  listLabel?: string;
  columnsLabel?: string;
  columnDefault?: number;
}
export const tk = tkCP.adminPage[ResourceType.Organization];
export const OrganizationWhiteLabelItemContent: FC<
  OrganizationWhiteLabelItemContentProps
> = ({
  listSource,
  columnsSource,
  listLabel,
  columnsLabel,
  columnDefault = 12
}) => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organizationId = useWatchResourceForm<ModelId>(OrganizationKey.id);
  const organization = organizationId ?? workspaceOrganizationId;
  return (
    <>
      <NumberInput
        source={columnsSource}
        label={columnsLabel}
        required
        defaultValue={columnDefault}
      />
      <ArrayInput source={listSource} label={listLabel}>
        <EnumSelectorInput
          source={BaseHomeItemKey.type}
          label={t(tk.fields.whiteLabelConfigItemType)}
          enumValue={HomeItemType}
        />
        <ArrayInput
          source={BaseHomeItemKey.property}
          label={t(tk.fields.whiteLabelConfigItemProperties)}
        >
          <EnumSelectorInput
            source={BaseHomeItemPropertyKey.type}
            label={t(tk.fields.whiteLabelConfigItemPropertyType)}
            enumValue={HomeItemPropertyType}
          />
          <FormDataConsumer<
            Organization,
            BaseHomeItemProperty<HomeItemPropertyType>
          >>
            {({ scopedFormData, getSource }) => {
              switch (scopedFormData?.type) {
                case HomeItemPropertyType.NumRows:
                case HomeItemPropertyType.NumColumns:
                  return (
                    <NumberInput
                      source={getSource(BaseHomeItemPropertyKey.value)}
                      label={t(tk.fields.whiteLabelConfigItemPropertyValue)}
                      format={value => Number(value)}
                      parse={value => String(value)}
                      min={0}
                      step={1}
                    />
                  );
                case HomeItemPropertyType.Icon:
                  return (
                    <UploadFileImageInput
                      source={getSource(BaseHomeItemPropertyKey.value)}
                      label={t(tk.fields.whiteLabelConfigItemPropertyValue)}
                      organization={organization}
                    />
                  );
                case HomeItemPropertyType.ButtonVariant:
                  return (
                    <EnumSelectorInput
                      source={getSource(BaseHomeItemPropertyKey.value)}
                      label={t(tk.fields.whiteLabelConfigItemPropertyValue)}
                      enumValue={WhiteLabelButtonVariant}
                    />
                  );
                case HomeItemPropertyType.ClickAction:
                  return (
                    <EnumSelectorInput
                      source={getSource(BaseHomeItemPropertyKey.value)}
                      label={t(tk.fields.whiteLabelConfigItemPropertyValue)}
                      enumValue={WhiteLabelClickAction}
                    />
                  );
                case HomeItemPropertyType.EmailSubject:
                case HomeItemPropertyType.EmailBody:
                  return (
                    <ThymeleafInput
                      source={getSource(BaseHomeItemPropertyKey.value)}
                      label={t(tk.fields.whiteLabelConfigItemPropertyValue)}
                    />
                  );
                case HomeItemPropertyType.Title:
                case HomeItemPropertyType.Text:
                case HomeItemPropertyType.Value:
                default:
                  return (
                    <TextInput
                      source={getSource(BaseHomeItemPropertyKey.value)}
                      label={t(tk.fields.whiteLabelConfigItemPropertyValue)}
                    />
                  );
              }
            }}
          </FormDataConsumer>
        </ArrayInput>
      </ArrayInput>
    </>
  );
};
