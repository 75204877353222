import {
  createStyle,
  FlexAlignItems,
  flexItemMixin,
  flexRowMixin,
  getClassName,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

const useCircleDynamicStyles = createStyle((theme: Theme) => {
  return {
    text: (props: UseCircleDynamicStylesProps) => ({
      ...themeTextMixin(
        theme,
        props.textVariant,
        props.notShrinkText ? undefined : 1
      ),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    })
  };
}, "CircleWithTextDynamic");

const useCircleStaticStyles = createStyle((_theme: Theme) => {
  return {
    container: {
      ...flexRowMixin({ gap: 4, alignItems: FlexAlignItems.Center })
    },
    containerReverse: {
      ...flexRowMixin({
        gap: 4,
        alignItems: FlexAlignItems.Center,
        reverseOrder: true
      })
    },
    notShrinkText: {
      ...flexItemMixin({ shrink: 0 })
    }
  };
}, "CircleWithTextStatic");

export interface UseCircleDynamicStylesProps {
  reverseOrder: boolean;
  textVariant: TextVariant;
  notShrinkText: boolean;
}

export interface UseCircleDynamicStylesReturn {
  container: string;
  text: string;
}

export const useCircleWithTextStyles = (
  props: UseCircleDynamicStylesProps
): UseCircleDynamicStylesReturn => {
  const dynamicClasses = useCircleDynamicStyles(props);
  const staticClasses = useCircleStaticStyles();
  return {
    container: props.reverseOrder
      ? staticClasses.containerReverse
      : staticClasses.container,
    text: getClassName(
      dynamicClasses.text,
      props.notShrinkText ? staticClasses.notShrinkText : undefined
    )
  };
};
