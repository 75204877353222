import {
  borderMixin,
  createStyle,
  FlexAlignItems,
  flexItemMixin,
  flexRowMixin,
  marginMixin,
  paddingMixin
} from "@laba/react-common";
import {
  getThemeRadius,
  getThemeStyleColor,
  themeTextMixin
} from "model/themeUtils";
import { RadiusVariant, Theme, ThemeStyleColorVariant } from "model/theme";
import { StyleVariant, TextVariant } from "model/themeVariant";

export const useAttachmentButtonLayoutStyles = createStyle(
  (theme: Theme) => ({
    attachmentButton: {
      ...flexRowMixin({}),
      maxWidth: "100%"
    },
    nameSection: {
      ...flexRowMixin({ alignItems: FlexAlignItems.Center }),
      ...borderMixin({
        side: { left: getThemeRadius(theme, RadiusVariant.Small) }
      }),
      ...marginMixin({ right: 2 }),
      ...paddingMixin({
        topBottom: 4,
        leftRight: 8
      }),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    },
    text: {
      ...paddingMixin({ left: 4 }),
      ...themeTextMixin(theme, TextVariant.Caption, 1)
    },
    iconSection: {
      ...flexRowMixin({ alignItems: FlexAlignItems.Center }),
      ...flexItemMixin({ shrink: 0 }),
      ...borderMixin({
        side: { right: getThemeRadius(theme, RadiusVariant.Small) }
      }),
      ...paddingMixin({ all: 4 }),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    },
    circularProgress: {
      ...paddingMixin({ all: 2 }),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Main
      )
    }
  }),
  "AttachmentButtonLayout"
);
