import {
  isValidPhoneNumber as libIsValidPhoneNumber,
  parsePhoneNumberFromString
} from "libphonenumber-js";
import { find, isEmpty, startsWith, uniq } from "lodash-es";
import { notUndefined, Optional } from "model/types";
import {
  CountryAreaPhoneCode,
  getCountryAreaPhoneCodeFromString
} from "phone/countryAreaPhoneCode";
import { PhoneLibCountryCode, PhoneLibPhoneNumber } from "../types";
import { PhoneValidationError } from "./PhoneValidationError";
import {
  isArgentinianPhone,
  validateArgentinianPhoneNumber
} from "./argentinianPhoneValidation";
import {
  getPhoneNumberCountryCode,
  hasPhoneNumberPlusPrefix,
  phonePrefixFromRegion
} from "./utils";
import {
  isUruguayanPhone,
  validateUruguayanPhoneNumber
} from "./uruguayanPhoneValidation";

const phoneNumberFromString = (
  phoneNumber: string,
  countryCode?: CountryAreaPhoneCode
): Optional<PhoneLibPhoneNumber> => {
  return parsePhoneNumberFromString(
    phoneNumber,
    countryCode as PhoneLibCountryCode
  );
};

export const countryCodeFromPhoneNumber = (
  phoneNumber: string,
  countryCode?: CountryAreaPhoneCode
): Optional<CountryAreaPhoneCode> => {
  const phone = phoneNumberFromString(phoneNumber, countryCode);
  const country = phone?.country;
  return getCountryAreaPhoneCodeFromString(country);
};

const isValidPhone = (
  phoneNumber: string,
  countryCode?: CountryAreaPhoneCode
) => {
  return libIsValidPhoneNumber(phoneNumber, countryCode as PhoneLibCountryCode);
};

export const validatePhoneNumber = (
  phoneNumber: string,
  defaultCountryCode?: CountryAreaPhoneCode
): PhoneValidationError[] => {
  const countryCode = getPhoneNumberCountryCode(
    phoneNumber,
    defaultCountryCode
  );
  const countryPrefix = countryCode
    ? phonePrefixFromRegion(countryCode)
    : undefined;
  const validPrefix = countryPrefix
    ? startsWith(phoneNumber, countryPrefix)
    : true;
  const hasPlusPrefix = hasPhoneNumberPlusPrefix(phoneNumber);
  const argentinianErrorList =
    countryCode === CountryAreaPhoneCode.Argentina ||
    isArgentinianPhone(phoneNumber)
      ? validateArgentinianPhoneNumber(phoneNumber)
      : [];
  const uruguayanErrorList =
    countryCode === CountryAreaPhoneCode.Uruguay ||
    isUruguayanPhone(phoneNumber)
      ? validateUruguayanPhoneNumber(phoneNumber)
      : [];
  const isValid = isValidPhone(phoneNumber, countryCode);
  return uniq(
    [
      !hasPlusPrefix ? PhoneValidationError.MissingPrefix : undefined,
      !validPrefix ? PhoneValidationError.InvalidCountryPrefix : undefined,
      !isValid ? PhoneValidationError.InvalidPhoneNumber : undefined,
      ...argentinianErrorList,
      ...uruguayanErrorList
    ].filter(notUndefined)
  );
};

export const isValidPhoneNumber = (
  phoneNumber: string,
  countryCode?: CountryAreaPhoneCode
): boolean => {
  const errorList = validatePhoneNumber(phoneNumber, countryCode);
  return isEmpty(errorList);
};

export const getCountryCodeOrDefault = (
  phoneNumber: string,
  defaultCountryCode: CountryAreaPhoneCode
): CountryAreaPhoneCode => {
  return (
    (isValidPhoneNumber(phoneNumber)
      ? countryCodeFromPhoneNumber(phoneNumber)
      : undefined) ?? defaultCountryCode
  );
};

// Ignore this errors because cannot be handled by the end user
const internalErrors = [
  PhoneValidationError.InvalidArgentinaInternalAreaCode,
  PhoneValidationError.InvalidCountryPrefix
];

export interface PhoneNumberEndUserError {
  hasInvalidPhoneNumberError: boolean;
  errorList: PhoneValidationError[];
}
export const getPhoneEndUserErrorList = (
  errorList: PhoneValidationError[]
): Optional<PhoneNumberEndUserError> => {
  if (isEmpty(errorList)) return undefined;

  const errorListWithoutInternal = errorList.filter(
    error => !internalErrors.includes(error)
  );
  const invalidPhoneNumberError = find(
    errorListWithoutInternal,
    error => error === PhoneValidationError.InvalidPhoneNumber
  );

  return {
    hasInvalidPhoneNumberError: invalidPhoneNumberError !== undefined,
    errorList: errorListWithoutInternal.filter(
      error => error !== PhoneValidationError.InvalidPhoneNumber
    )
  };
};
