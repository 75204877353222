import { createStyle, Theme } from "@laba/react-common";

export const useStepErrorStyles = createStyle(
  (_theme: Theme) => ({
    a: {
      fill: "#fe4e4e"
    },
    c: {
      fill: "none",
      strokeLinecap: "round",
      strokeWidth: "3px",
      stroke: "#fff"
    },
    b: {
      fill: "none",
      strokeLinecap: "round",
      strokeWidth: "3px",
      stroke: "#fe4e4e"
    }
  }),
  "StepError"
);
