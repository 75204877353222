export const organizationWorkspaceSkipDownloadConfig = {
  skipOrganizationList: true,
  skipCodeSystemList: true,
  skipHealthCareServiceList: true,
  skipAppointmentDefinitionList: true,
  skipMedicalRequestDefinitionList: true,
  skipMedicationList: true,
  skipMedicalDeviceList: true,
  skipQuestionnaireList: true,
  skipMeasurementGroupDefinitionList: true,
  skipProcedureReportDefinitionList: true,
  skipScheduleDefinitionList: true,
  skipScheduleList: true,
  skipPatientList: true
};
