import React from "react";
import {
  TextField as ReactAdminTextField,
  TextFieldProps as ReactAdminTextFieldProps
} from "react-admin";
import { ReactElement } from "@laba/react-common";

export type TextFieldProps = ReactAdminTextFieldProps;

export const TextField = (props: TextFieldProps): ReactElement => {
  return <ReactAdminTextField {...props} />;
};

// this is a bad practice but needed for react-admin datagrid to don't show sort arrow by default.
TextField.defaultProps = {
  sortable: false
};
