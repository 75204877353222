import React from "react";
import {
  DashboardKey,
  ModelId,
  OrganizationKey,
  ResourceType
} from "@laba/nexup-api";
import { FC, useI18n } from "@laba/react-common";
import { tkCP } from "translation/i18n";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { TextInput } from "components/generic/TextInput/TextInput";
import { UploadFileImageInput } from "components/generic/UploadFileImageInput/UploadFileImageInput";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useSelector } from "react-redux";
import { useWatchResourceForm } from "components/hook/useResourceContext";

export const tk = tkCP.adminPage[ResourceType.Organization];

export const OrganizationDashboardContent: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organizationId = useWatchResourceForm<ModelId>(OrganizationKey.id);
  const organization = organizationId ?? workspaceOrganizationId;
  return (
    <ArrayInput
      source={OrganizationKey.dashboardConfig}
      label={t(tk.fields.dashboardList)}
    >
      <TextInput
        source={DashboardKey.title}
        label={t(tk.fields.dashboardTitle)}
      />

      <TextInput source={DashboardKey.url} label={t(tk.fields.dashboardUrl)} />
      <UploadFileImageInput
        source={DashboardKey.icon}
        label={t(tk.fields.dashboardIcon)}
        organization={organization}
      />
      <TextInput
        source={DashboardKey.code}
        label={t(tk.fields.dashboardCode)}
      />
    </ArrayInput>
  );
};
