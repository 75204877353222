import { FC, saveBlobToDevice, useEffectSelective } from "@laba/react-common";
import { Noop, Optional } from "@laba/ts-common";
import { SpinnerDialog } from "components/spinners/SpinnerDialog/SpinnerDialog";
import React, { useState } from "react";
import { FilePreviewPopup } from "../DownloadLink/FilePreviewPopup/FilePreviewPopup";
import { canShowPreview } from "../DownloadLink/utils";

interface Props {
  isOpen?: boolean;
  handleClose: Noop;
  url: string;
  name: string;
  withPreview?: boolean;
  onSuccess?: Noop;
  onFailure?: (error: Error) => void;
  onDownload?: (url: string) => Promise<Optional<Blob>>;
}

export const AttachmentFilePreviewPopup: FC<Props> = ({
  name,
  url,
  withPreview = true,
  isOpen,
  handleClose,
  onFailure,
  onSuccess,
  onDownload
}) => {
  const [fileBlob, setFileBlob] = useState<Blob>();
  const [isLoading, setIsLoading] = useState(false);

  useEffectSelective(() => {
    const onInit = async () => {
      try {
        setIsLoading(true);
        const tmpBlob = fileBlob ?? (await onDownload?.(url));
        setFileBlob(tmpBlob);
        if (!withPreview || !canShowPreview(tmpBlob?.type)) {
          tmpBlob && saveBlobToDevice(tmpBlob, name);
          onSuccess?.();
        }
      } catch (e) {
        onFailure && onFailure(e);
        setFileBlob(undefined);
      }
      setIsLoading(false);
    };
    onInit();
  }, []);

  const handleDownload = async () => {
    if (fileBlob === undefined) return;
    try {
      saveBlobToDevice(fileBlob, name);
      onSuccess?.();
    } catch (e) {
      onFailure?.(e);
    }
  };

  return isLoading ? (
    <SpinnerDialog open />
  ) : fileBlob ? (
    <FilePreviewPopup
      fileBlob={fileBlob}
      isOpen={isOpen}
      handleClose={handleClose}
      handleDownload={handleDownload}
      name={name}
    />
  ) : null;
};
