import { isEmpty } from "lodash-es";
import { Attachment } from "model/primitives/attachment/attachment";

export const getInternalOrExternalFilesFromAttachment = (
  attachment?: Attachment[],
  getExternalFiles?: boolean
): Attachment[] => {
  if (!attachment || isEmpty(attachment)) return [];
  if (getExternalFiles) {
    return attachment.filter(attach => attach.external);
  }
  return attachment.filter(attach => !attach.external);
};
