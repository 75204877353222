export const questionnaire = {
  title: "Evoluciones",
  filters: {
    organization: "Organizacion",
    category: "Categoria",
    type: "tipo",
    status: "Estado"
  },
  fields: {
    id: "Id",
    organization: "Organizacion",
    title: "Titulo",
    name: "Codigo",
    status: "Estado",
    category: "Categoria",
    type: "Tipo",
    editInterval: "Ventana edicion (seg)",
    color: "Color",
    featured: "Destacado",
    order: "Orden",
    responseTitleTemplate: "Template respuesta titulo",
    responseDescriptionTemplate: "Template respuesta descripcion",

    cardList: "Secciones",
    cardElement: "Seccion",
    cardLinkId: "Id Seccion",
    cardTitle: "Titulo",
    cardFieldList: "Campos",
    cardFieldElement: "Campo",

    fieldLinkId: "Id Campo",
    fieldTitle: "Titulo",
    fieldType: "Tipo",
    fieldInitialValue: "Valores iniciales",
    fieldInitialValueElement: "Valor",
    fieldInitialValueElementDate: "Fecha (no usar junto con codigo)",
    fieldInitialValueElementDateCode: "Codigo (no usar junto con fecha)",
    fieldInitialValueElementNumericValue: "Valor",
    fieldInitialValueElementNumericUnit: "Unidad",
    fieldInitialValueElementScaleScoreValue:
      "Valor (solo cargar en la primera respuesta)",
    fieldInitialValueElementScaleScoreComment:
      "Comentario (solo cargar en la segunda respuesta)",
    fieldSize: "Tamaño edicion",
    fieldDetailSize: "Tamaño detalle",
    fieldReportSize: "Tamaño reporte",
    fieldRequired: "Requerido",
    fieldPropertyList: "Propiedades",
    fieldPropertyElement: "Propiedad",
    fieldPropertyType: "Tipo",
    fieldPropertyValue: "Valor",
    fieldPropertyValueEnable: "Activado",
    fieldPropertyValueFieldType:
      "Tipo de archivo (audio/*, video/*, image/* o .pdf)",
    fieldPropertyValueDate: "Fecha (no usar junto con codigo)",
    fieldPropertyValueDateCode: "Codigo (no usar junto con fecha)",
    fieldPropertyValueWindowMseg: "ventana (mseg)",
    enableBehavior: "enableBehavior",
    enableWhenLinkId: "linkId",
    enableWhenOperator: "operator",
    answerSelectInput: "answer",
    enableWhen: "enableWhen",
    enableWhenAnswerValue: "Valor",
    enableWhenAnswerValueDate: "Fecha",
    enableWhenAnswerValueUnit: "Unidad",
    enableWhenAnswerValueCoding: "Codigo",
    consumptionConfig: {
      ignorePayer: "Ignorar cobertura",
      configList: "Configuración de consumos",
      enableBehavior: "Comportamiento",
      enableWhen: "Enable when",
      productList: {
        title: "Listado de productos",
        product: "Producto",
        quantity: "Cantidad"
      }
    }
  },
  tabs: {
    main: "Evolucion",
    fields: "Campos",
    fieldProperties: "Detalle Campo",
    card: "Seccion {{ index }}"
  },
  autocompleteClearText: "Limpiar",
  autocompleteCloseText: "Cerrar",
  autocompleteLoadingText: "Cargando",
  autocompleteNoOptionsText: "Sin opciones",
  autocompleteOpenText: "Abrir",
  autocompleteTitle: "Campo a Editar"
};
