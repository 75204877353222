import {
  borderMixinRadiusAll,
  createStyle,
  ElevationVariant,
  FlexAlignItems,
  flexColumnMixin,
  paddingMixin,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import {
  getThemeRadius,
  getThemeStyleColor,
  themeElevationMixin,
  themeTextMixin
} from "model/themeUtils";
import { RadiusVariant } from "model/theme";

export const useLocationMarkerCardStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...borderMixinRadiusAll(getThemeRadius(theme, RadiusVariant.Large)),
      ...themeElevationMixin(theme, ElevationVariant.Elevation8),
      ...paddingMixin({ topBottom: 8, leftRight: 12 }),
      ...flexColumnMixin({ gap: 12, alignItems: FlexAlignItems.FlexStart }),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    },
    name: {
      ...themeTextMixin(theme, TextVariant.H6),
      textAlign: "left",
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    address: {
      ...themeTextMixin(theme, TextVariant.Subtitle2),
      textAlign: "left",
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    scheduleContainerText: {
      ...themeTextMixin(theme, TextVariant.Body2),
      textAlign: "left",
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayMedium
      )
    },
    scheduleContainer: {
      scheduleContainer: {
        ...flexColumnMixin({})
      }
    }
  }),
  "LocationMarkerCard"
);
