import { CodeSystemDesignationCode, Concept } from "@laba/nexup-api";
import { Optional } from "@laba/ts-common";

export const validatePreferredTermSynonymDesignation = (
  errorMessage: string,
  concepts?: Concept[],
  value?: string
): Optional<string> => {
  return concepts?.find(c => {
    return c.designation?.some(
      d =>
        (d.use === CodeSystemDesignationCode.Synonymous ||
          d.use === CodeSystemDesignationCode.PreferredTerm) &&
        d.value === value
    );
  }) === undefined
    ? undefined
    : errorMessage;
};
