import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  ContactPointRole,
  ContactPointType,
  getModelReferenceId,
  KnownCodeSystemSystem,
  LocationKey,
  ModelReference,
  Organization,
  ResourceType
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";

export const tk = tkCP.adminPage[ResourceType.Location];
export const LocationDirectoryContent: FC = () => {
  const { t } = useI18n();
  const locationOrganization = useWatchResourceForm<
    ModelReference<Organization>
  >(LocationKey.organization);
  const locationDirectoryTypeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.LocationDirectoryType,
    locationOrganization
  );
  const directoryPayerPlanSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.OrganizationPayerPlan,
    locationOrganization
  );
  const directorySpecialitySystem = useCodeSystemGroup(
    KnownCodeSystemSystem.OrganizationDirectorySpeciality,
    locationOrganization
  );
  const organizationId = getModelReferenceId(locationOrganization);
  return (
    <>
      <CodeSystemSelectorInput
        source={LocationKey.directoryType}
        label={t(tk.filters.directoryType)}
        codeSystem={locationDirectoryTypeSystem}
        organizationId={organizationId}
        withCreate
      />
      <ArrayInput
        source={LocationKey.payerPlanList}
        label={t(tk.fields.payerPlanList)}
      >
        <CodeSystemSelectorInput
          source=""
          label={t(tk.fields.payerPlanListElement)}
          codeSystem={directoryPayerPlanSystem}
          organizationId={organizationId}
          withCreate
        />
      </ArrayInput>
      <ArrayInput
        source={LocationKey.payerSpecialityList}
        label={t(tk.fields.payerSpecialityList)}
      >
        <CodeSystemSelectorInput
          source=""
          label={t(tk.fields.payerSpecialityListElement)}
          codeSystem={directorySpecialitySystem}
          organizationId={organizationId}
          withCreate
        />
      </ArrayInput>
      <EnumSelectorInput
        source={LocationKey.telecom.phone.type}
        label={t(tk.fields.phoneType)}
        enumValue={ContactPointType}
      />
      <TextInput
        source={LocationKey.telecom.phone.value}
        label={t(tk.fields.phoneValue)}
      />
      <EnumSelectorInput
        source={LocationKey.telecom.phone.role}
        label={t(tk.fields.phoneRole)}
        enumValue={ContactPointRole}
      />
      <EnumSelectorInput
        source={LocationKey.telecom.email.type}
        label={t(tk.fields.emailType)}
        enumValue={ContactPointType}
      />
      <TextInput
        source={LocationKey.telecom.email.value}
        label={t(tk.fields.emailValue)}
      />
      <EnumSelectorInput
        source={LocationKey.telecom.email.role}
        label={t(tk.fields.emailRole)}
        enumValue={ContactPointRole}
      />
      <TextInput source={LocationKey.website} label={t(tk.fields.website)} />
    </>
  );
};
