import React, { useEffect, useState } from "react";
import { useInput } from "react-admin";
import { FC } from "@laba/react-common";
import {
  CodeSystemSystem,
  createBaseIdentifier,
  Identifier,
  IdentifierKey
} from "@laba/nexup-api";
import { useReactHookFormInput } from "components/hook/useReactHookFormInput";
import { notUndefined } from "@laba/ts-common";
import { TextInput } from "../TextInput/TextInput";

interface Props {
  identifierListPath: string;
  label?: string;
  system: CodeSystemSystem;
}
export const SingleIdentifierTextInput: FC<Props> = ({
  identifierListPath,
  system,
  label
}) => {
  const [index, setIndex] = useState<number>(-1);

  const identifierValuePath = [
    identifierListPath,
    index,
    IdentifierKey.value
  ].join(".");

  const { onChange: onChangeIdentifierList, value: identifierList } =
    useReactHookFormInput<Identifier[]>({
      source: identifierListPath
    });

  const {
    field: { onChange: onChangeIdentifierValue, value: identifierValue }
  } = useInput({
    source: identifierValuePath
  });

  useEffect(() => {
    const idx = identifierList?.findIndex(i => i.system === system);
    if (idx !== -1 && notUndefined(idx)) {
      setIndex(idx);
    } else {
      const newIdentifierList = [
        ...(identifierList ?? []),
        createBaseIdentifier(system)
      ];
      onChangeIdentifierList(newIdentifierList);
      setIndex(newIdentifierList.length - 1);
    }
  }, [identifierList, system, setIndex, onChangeIdentifierList]);

  if (index === -1) {
    return null;
  }

  return (
    <TextInput
      source={identifierValuePath}
      value={identifierValue}
      onChange={onChangeIdentifierValue}
      fullWidth
      label={label}
    />
  );
};
