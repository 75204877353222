import { resourceConceptProviderGetter } from "providers/dataProvider/resourceProvider/utils/resourceConceptProviderGetter";
import {
  conceptProcedureCodeConceptMapper,
  getModelReferenceId,
  KnownCodeSystemSystem,
  ProcedureCodeConcept,
  ProcedureCodeConceptFilter,
  ProcedureCodeConceptKey,
  procedureCodeConceptMapper
} from "@laba/nexup-api";
import { workspaceOrganizationSelector } from "store/workspace/selectors";
import { store } from "store/store";
import { ConceptResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { isEmpty } from "lodash-es";
import { filterListByFuzzySearch } from "@laba/ts-common";

export const procedureCodeConceptProvider = resourceConceptProviderGetter(
  ConceptResourceType.ProcedureCodeConcept,
  KnownCodeSystemSystem.ProcedureReportCode,
  procedureCodeConceptMapper,
  conceptProcedureCodeConceptMapper,
  () => ({
    organization: getModelReferenceId(
      workspaceOrganizationSelector(store.getState())
    )
  }),
  (resourceConcepts, filters: ProcedureCodeConceptFilter) => {
    let filteredProcedureCodeConcepts: ProcedureCodeConcept[] =
      resourceConcepts;
    const { content, medicalArea, active } = filters;
    if (content !== undefined && !isEmpty(content)) {
      filteredProcedureCodeConcepts = filterListByFuzzySearch(
        filteredProcedureCodeConcepts,
        [ProcedureCodeConceptKey.display, ProcedureCodeConceptKey.synonyms],
        content
      );
    }

    if (medicalArea !== undefined) {
      filteredProcedureCodeConcepts = filterListByFuzzySearch(
        filteredProcedureCodeConcepts,
        [ProcedureCodeConceptKey.medicalArea],
        medicalArea
      );
    }
    if (active !== undefined) {
      filteredProcedureCodeConcepts = filteredProcedureCodeConcepts.filter(
        pcc => pcc.active === active
      );
    }
    return filteredProcedureCodeConcepts;
  }
);
