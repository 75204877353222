import { ModelId, Optional } from "@laba/ts-common";
import { getModelReferenceId } from "model/primitives";
import { Workspace } from "./workspace";

export const getWorkspaceOrganizationName = (workspace?: Workspace): string =>
  workspace?.organization?.name ?? "";

export const getWorkspaceOrganizationId = (
  workspace?: Workspace
): Optional<ModelId> => getModelReferenceId(workspace?.organization);
