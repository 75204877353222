import axios, { AxiosPromise } from "axios";
import qs from "qs";
import { isNil } from "lodash-es";
import { sentryLogError } from "logger/sentry/sentry";
import { sleep } from "utils/sleep";
import {
  DefaultError,
  isAxiosError,
  isAxiosUnknownRequestError,
  ManageError,
  RequestConfig,
  RequestFailureStatus,
  RequestResponse,
  Validator
} from "./models";

const defaultApiTimeout = 600000;
const maxRequestTries = 100;

const validateResponse = <R, E>(
  response: RequestResponse<unknown, E>,
  responseValidator: Validator<R>
): RequestResponse<R, E> => {
  if (response.failureStatus === RequestFailureStatus.Failure) return response;
  try {
    const mappedResponse = responseValidator(response.data);
    return {
      ...response,
      data: mappedResponse
    };
  } catch (error) {
    sentryLogError({ error, errorContext: { response } });
    return {
      failureStatus: RequestFailureStatus.Failure,
      data: error,
      status: response.status,
      errorMsg: "Mapping Response Error"
    };
  }
};

const validateError = <R, E>(
  response: RequestResponse<R>,
  errorValidator: Validator<E>
): RequestResponse<R, E> => {
  if (response.failureStatus === RequestFailureStatus.Success) return response;
  try {
    const mappedError = errorValidator(response.data);
    return {
      ...response,
      data: mappedError
    };
  } catch (error) {
    sentryLogError({ error, errorContext: { response } });
    return {
      ...response,
      data: undefined,
      errorMsg: `Mapping Response Error, original errorMsg: ${response.errorMsg}`
    };
  }
};

export const axiosRequest = <R>(options: RequestConfig): AxiosPromise<R> =>
  axios(options) as AxiosPromise<R>;

const managedRequest = async <R, E = DefaultError>(
  request: () => AxiosPromise<R>,
  errorManagement: ManageError<E>,
  tryIndex = 0
): Promise<RequestResponse<R, E>> => {
  try {
    const response = await request();
    return {
      failureStatus: RequestFailureStatus.Success,
      data: response.data,
      status: response.status,
      headers: response.headers
    };
  } catch (e) {
    if (isAxiosError(e)) {
      const { error, retryRequest, retryRequestDelayMs } =
        await errorManagement(e, tryIndex);
      if (retryRequest && tryIndex < maxRequestTries) {
        if (retryRequestDelayMs > 0) await sleep(retryRequestDelayMs);
        return managedRequest(request, errorManagement, tryIndex + 1);
      }
      if (isAxiosUnknownRequestError(error)) {
        sentryLogError({ error: e });
      }
      return {
        failureStatus: RequestFailureStatus.Failure,
        data: error.response?.data,
        status: error.response?.status,
        errorMsg: error.message
      };
    }
    sentryLogError({ error: e });
    return {
      failureStatus: RequestFailureStatus.Failure,
      errorMsg: `Unknown request error: ${e.name}, ${e.message}`
    };
  }
};

export const baseRequest = async <R, E = DefaultError>(
  getOptions: () => RequestConfig | Promise<RequestConfig>,
  errorManagement?: ManageError<E>,
  responseValidator?: Validator<R>,
  errorValidator?: Validator<E>
): Promise<RequestResponse<R, E>> => {
  const secureErrorManagement: ManageError<E> = !isNil(errorManagement)
    ? errorManagement
    : async error => {
        return { error, retryRequest: false, retryRequestDelayMs: 0 };
      };
  let result = await managedRequest<R, E>(
    async () =>
      axiosRequest<R>({
        timeout: defaultApiTimeout,
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: "comma", encode: true });
        },
        ...(await getOptions())
      }),
    secureErrorManagement
  );
  if (responseValidator !== undefined) {
    result = validateResponse(result, responseValidator);
  }
  if (errorValidator !== undefined) {
    result = validateError(result, errorValidator);
  }
  return result;
};
