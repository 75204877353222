import { StyleVariant, TextVariant } from "model/themeVariant";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import {
  createStyle,
  GridAutoFlow,
  gridContainerMixin,
  paddingMixin
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

export const useRadioGroupFormControlLabelStyle = createStyle(
  (theme: Theme) => ({
    label: {
      ...themeTextMixin(theme, TextVariant.Body2),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "RadioGroupFormControlLabel"
);

export const useFormHelperTextStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...paddingMixin({ leftRight: 14 }),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "FormHelperText"
);

export const useRadioGroupStyle = createStyle(
  (_theme: Theme) => ({
    twoColumns: {
      ...gridContainerMixin({
        templateColumn: ["repeat(2,1fr)"],
        autoFlow: GridAutoFlow.Row
      })
    }
  }),
  "FormHelperText"
);
