import React from "react";
import { FC } from "@laba/react-common";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export const ReactivatePatientIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M17.741 18V20H1.74097V18C1.74097 15.374 6.94897 14 9.74097 14C12.533 14 17.741 15.373 17.741 18ZM11.9632 11.3259C11.3055 11.7654 10.5321 12 9.74097 12C8.68051 11.9987 7.66386 11.5768 6.914 10.827C6.16414 10.0771 5.74229 9.06046 5.74097 8C5.74097 7.20888 5.97556 6.43552 6.41509 5.77772C6.85461 5.11992 7.47933 4.60723 8.21023 4.30448C8.94114 4.00173 9.7454 3.92252 10.5213 4.07686C11.2973 4.2312 12.01 4.61216 12.5694 5.17157C13.1288 5.73098 13.5098 6.44372 13.6641 7.21964C13.8184 7.99556 13.7392 8.79983 13.4365 9.53074C13.1337 10.2616 12.621 10.8864 11.9632 11.3259ZM17.409 11.1642L21.0227 8L22.2589 9.0825L17.409 13.3292L14.741 10.993L15.9772 9.91045L17.409 11.1642Z" />
      </svg>
    </SvgIcon>
  );
};
