import { HospitalizationWithExtraData } from "model/resource/medical";
import { Location } from "model/resource/entities/location/location";
import { getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";

export interface LocationWithExtraData {
  location?: Location;
  partOf?: Location[];
  hospitalization?: HospitalizationWithExtraData[];
}

export const LocationWithExtraDataKey = getKeyObj<LocationWithExtraData>(
  createHydratedMock<LocationWithExtraData>()
);
