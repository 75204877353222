import { useState, useCallback, useMemo, useEffect } from "react";
import {
  defaultLocationOptions,
  errorMapper,
  ErrorPosition,
  PositionResult,
  onGetCurrentPosition
} from "hooks/location/helpers";
import { Optional, Position } from "@laba/ts-common";

// you should not call useCurrentUserPosition and useUserPosition on the same
// rendered component or child rendered components at the same time
// https://bugs.chromium.org/p/chromium/issues/detail?id=586015

export const useIntervalDevicePosition = (
  updateTimerMs: number,
  options?: PositionOptions
): PositionResult => {
  const [currentPosition, setCurrentPosition] = useState<Optional<Position>>();
  const [error, setError] = useState<Optional<ErrorPosition>>();

  const positionOptions = useMemo(
    () => ({
      ...defaultLocationOptions,
      ...options
    }),
    [options]
  );

  const locationCallback = useCallback(() => {
    onGetCurrentPosition(
      position => {
        setCurrentPosition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        });
      },
      positionError => {
        setCurrentPosition(undefined);
        setError(errorMapper(positionError));
      },
      positionOptions
    );
  }, [positionOptions]);

  useEffect(() => {
    locationCallback();
    const idInterval = setInterval(locationCallback, updateTimerMs);
    return () => clearInterval(idInterval);
  }, [locationCallback, updateTimerMs]);

  return { currentPosition, error };
};
