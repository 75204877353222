import {
  encounterHasMemberWithRole,
  isEncounterArrived,
  isEncounterCancelled,
  isEncounterFinished,
  isEncounterInProgress,
  isEncounterTriaged
} from "model/resource/entities/encounter/helpers";
import {
  EncounterStatus,
  RoleCodes
} from "model/resource/entities/encounter/encounter";
import { ResourceType } from "model/primitives/resourceModel";
import { ConferenceEncounter } from "model/resource/entities/encounter/conference/conferenceEncounter";
import { ApiDate, DateTime, Optional } from "@laba/ts-common";
import { MeetingEncounter } from "../meetingEncounter";

export const isEncounterActive = (encounter?: MeetingEncounter): boolean =>
  isEncounterTriaged(encounter) || isEncounterInProgress(encounter);

export const isEncounterInactive = (encounter?: MeetingEncounter): boolean =>
  isEncounterCancelled(encounter) || isEncounterFinished(encounter);

export const conferenceEncounterEndDate = (
  conferenceEncounter?: ConferenceEncounter
): Optional<ApiDate> => {
  switch (conferenceEncounter?.status) {
    case EncounterStatus.Finished:
      return conferenceEncounter.finishDate;
    case EncounterStatus.Cancelled:
      return conferenceEncounter.cancelDate;
    default:
      return undefined;
  }
};

export const isConferenceEncounterPractitioner = (
  pracititonerId: string,
  conferenceEncounter: ConferenceEncounter
): boolean => {
  return encounterHasMemberWithRole(
    conferenceEncounter,
    pracititonerId,
    RoleCodes.VirtualGuardPractitioner
  );
};

export const conferenceEncounterWaitingMinutes = (
  conferenceEncounter: ConferenceEncounter,
  currentTime?: DateTime
): number => {
  const startDate = conferenceEncounter.startDate
    ? DateTime.fromApiDate(conferenceEncounter.startDate)
    : undefined;
  const arrivedDate = conferenceEncounter.arrivedDate
    ? DateTime.fromApiDate(conferenceEncounter.arrivedDate)
    : undefined;

  if (!currentTime || !arrivedDate) return 0;

  if (
    isEncounterTriaged(conferenceEncounter) ||
    isEncounterArrived(conferenceEncounter)
  ) {
    return currentTime.diff(arrivedDate, ["minutes", "seconds"]).minutes;
  }

  return startDate
    ? startDate.diff(arrivedDate, ["minutes", "seconds"]).minutes
    : 0;
};

export const isVideoAppointmentEncounter = (
  conferenceEncounter?: ConferenceEncounter
): boolean =>
  conferenceEncounter?.resourceType === ResourceType.VideoAppointmentEncounter;

export const isVirtualGuardEncounter = (
  conferenceEncounter?: ConferenceEncounter
): boolean =>
  conferenceEncounter?.resourceType === ResourceType.VirtualGuardEncounter;

export const isVirtualGuardEncounterAndArrived = (
  conferenceEncounter?: ConferenceEncounter
): boolean =>
  isVirtualGuardEncounter(conferenceEncounter) &&
  isEncounterArrived(conferenceEncounter);
