import React from "react";
import {
  FC,
  ReactElement,
  saveBlobToDevice,
  SheetsRegistry,
  StyleProvider
} from "@laba/react-common";
import { DefaultButton } from "components/buttons/DefaultButton/DefaultButton";
import { defaultTheme } from "model/defaultTheme";
import { renderToStaticMarkup } from "react-dom/server";

interface HtmlCommonLayoutProps {
  renderedContentString: string;
  stylesString: string;
}

const HtmlCommonLayout: FC<HtmlCommonLayoutProps> = ({
  renderedContentString,
  stylesString
}) => {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf8" />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap"
          rel="stylesheet"
        />
        <style type="text/css">
          {`
            html {
              font-size: 16px;
            }
            body {
              -webkit-print-color-adjust: exact !important;
              padding: 0;
              margin: 0;
            }
          `}
          {stylesString}
        </style>
      </head>
      {/* eslint-disable-next-line react/no-danger */}
      <body dangerouslySetInnerHTML={{ __html: renderedContentString }} />
    </html>
  );
};

interface DownloadComponentAsHtmlProps {
  Component: ReactElement;
  downloadButtonText?: string;
}

export const DownloadComponentAsHtmlButton: FC<
  DownloadComponentAsHtmlProps
> = ({ Component, downloadButtonText = "DESCARGAR HTML" }) => {
  const registry = new SheetsRegistry();

  const MainComponentHtmlString = renderToStaticMarkup(
    <StyleProvider theme={defaultTheme} registry={registry}>
      {Component}
    </StyleProvider>
  );

  const cssRegistryString = registry.toString();

  const MainHtmlString = renderToStaticMarkup(
    <HtmlCommonLayout
      renderedContentString={MainComponentHtmlString}
      stylesString={cssRegistryString}
    />
  );

  return (
    <DefaultButton
      text={downloadButtonText}
      onClick={() => {
        const blob = new Blob([MainHtmlString], {
          type: "text/html"
        });
        saveBlobToDevice(blob, "component.html");
      }}
    />
  );
};
