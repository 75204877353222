import React from "react";
import { useElementExportedFileName } from "components/hook/useExportedFileName";
import { Edit } from "components/generic/Edit/Edit";
import { ConceptResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { FC } from "@laba/react-common";
import { ProcedureBatteryConcept } from "@laba/nexup-api";
import {
  ProcedureBatteryConceptForm,
  ProcedureBatteryConceptJsonToModel,
  ProcedureBatteryConceptTransform
} from "components/pages/NexupAdmin/resources/concept/batteryProcedureCode/ProcedureBatteryConceptForm";

export const ProcedureBatteryConceptEdit: FC = () => {
  const exportedFileName = useElementExportedFileName<ProcedureBatteryConcept>(
    resource => resource.display
  );
  return (
    <Edit
      resourceTransform={ProcedureBatteryConceptTransform}
      resource={ConceptResourceType.ProcedureBatteryConcept}
      jsonToModel={ProcedureBatteryConceptJsonToModel}
      exportedFileName={exportedFileName}
    >
      <ProcedureBatteryConceptForm />
    </Edit>
  );
};
