import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  flexRowMixin,
  pxToRem
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { StyleVariant, TextVariant } from "model/themeVariant";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import {
  PopupSizeVariant,
  PopupVariant
} from "components/popup/BasePopup/BasePopupStyle";

export type DialogVariant = PopupVariant;
export type DialogSizeVariant = PopupSizeVariant;

interface DialogAlertCommercialStyleProps {
  iconStyle?: StyleVariant;
  dialogVariant: DialogVariant;
  isMobile?: boolean;
}

interface DialogAlertCommercialClassNames {
  content?: string;
  headerContainer?: string;
  grayDarkColor?: string;
  icon?: string;
  textTitle?: string;
  textSubtitle?: string;
  imageSvg?: string;
  textContainer?: string;
}

const useDialogAlertCommercialStaticStyles = createStyle(
  (theme: Theme) => ({
    content: {
      ...flexColumnMixin({
        gap: 16
      })
    },
    verticalcontent: {
      ...flexColumnMixin({ gap: 16, alignItems: FlexAlignItems.Center }),
      textAlign: "center"
    },
    grayDarkColor: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    headerContainer: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        gap: 16
      })
    },
    verticalHeaderContainer: {
      ...flexColumnMixin({
        gap: 8,
        alignItems: FlexAlignItems.Center
      })
    },
    textTitle: {
      ...themeTextMixin(theme, TextVariant.H5)
    },
    textSubtitle: {
      ...themeTextMixin(theme, TextVariant.Body2)
    },
    textContainer: {
      ...flexColumnMixin({ gap: 16 })
    }
  }),
  "DialogAlertCommercialStatic"
);

const useDialogAlertCommercialDynamicStyles = createStyle(
  (theme: Theme) => ({
    icon: (props: DialogAlertCommercialStyleProps) => ({
      height: pxToRem(40),
      width: pxToRem(40),
      color: props.iconStyle
        ? getThemeStyleColor(theme, props.iconStyle)
        : getThemeStyleColor(
            theme,
            StyleVariant.Primary,
            ThemeStyleColorVariant.GrayDark
          )
    }),
    imageSvg: (props: DialogAlertCommercialStyleProps) => ({
      height: pxToRem(props.isMobile ? 140 : 130),
      width: "auto"
    })
  }),
  "DialogAlertCommercialDynamic"
);

export const useDialogAlertCommercialStyles = (
  props: DialogAlertCommercialStyleProps
): DialogAlertCommercialClassNames => {
  const staticClasses = useDialogAlertCommercialStaticStyles();
  const dynamicClasses = useDialogAlertCommercialDynamicStyles(props);

  return {
    content:
      props.dialogVariant === PopupVariant.Horizontal
        ? staticClasses.content
        : staticClasses.verticalcontent,
    headerContainer:
      props.dialogVariant === PopupVariant.Horizontal
        ? staticClasses.headerContainer
        : staticClasses.verticalHeaderContainer,
    grayDarkColor: staticClasses.grayDarkColor,
    imageSvg: dynamicClasses.imageSvg,
    icon: dynamicClasses.icon,
    textTitle: staticClasses.textTitle,
    textSubtitle: staticClasses.textSubtitle,
    textContainer: staticClasses.textContainer
  };
};
