import React from "react";
import { ReactElement, StyleVariant } from "@laba/react-common";
import { MenuItemConfig } from "components/menu/Menu/Menu";
import { CurrentTime } from "components/headers/AppHeader/UserButton/currentDate/CurrentTime";
import {
  UserMenuButtonConfig,
  UserMenuButton
} from "components/buttons/UserMenuButton/UserMenuButton";
import { UserButtonStyles } from "components/headers/AppHeader/UserButton/UserButtonStyles";
import {
  Divider,
  DividerOrientation,
  DividerSize
} from "components/divider/Divider";
import { Noop } from "@laba/ts-common";

export interface UserButtonProps<V> {
  style: StyleVariant;
  isMobile: boolean;
  user: UserMenuButtonConfig;
  userExtraData?: string;
  userOptions?: MenuItemConfig<V>[];
  onUserMenuOptionSelected?: (option: V) => void;
  userPendingActions?: number;
  onUserMenuDataClick?: Noop;
}

export const UserButton = <V,>({
  style,
  isMobile,
  user,
  userExtraData,
  userOptions,
  onUserMenuOptionSelected,
  userPendingActions,
  onUserMenuDataClick
}: UserButtonProps<V>): ReactElement => {
  const classes = UserButtonStyles();
  return (
    <div className={classes.root}>
      {!isMobile && <CurrentTime />}
      {!isMobile && (
        <Divider
          className={classes.verticalSeparator}
          size={DividerSize.Medium}
          orientation={DividerOrientation.Vertical}
        />
      )}
      <UserMenuButton
        style={style}
        user={user}
        userExtraData={userExtraData}
        isMobile={isMobile}
        onUserMenuOptionSelected={onUserMenuOptionSelected}
        userOptions={userOptions}
        userPendingActions={userPendingActions}
        onUserMenuDataClick={onUserMenuDataClick}
      />
    </div>
  );
};
