import { get } from "lodash-es";
import { CreateSliceOptions, Slice, SliceCaseReducers } from "store/types";
import {
  createSelector as _createSelector,
  createSlice as _createSlice
} from "@reduxjs/toolkit";

export const sliceSelector =
  <S, R>(slice: Slice<S>) =>
  (state: R): S => {
    return get(state, slice.name);
  };

export const multiSliceSelector =
  <S, R>(slices: Slice[]) =>
  (state: R): Record<string, S> =>
    slices.reduce((acc, slice) => {
      // eslint-disable-next-line no-param-reassign
      acc[slice.name] = sliceSelector(slice)(state);
      return acc;
    }, {} as Record<string, S>);

export const createSlice = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  State = any,
  CaseReducers extends SliceCaseReducers<State> = SliceCaseReducers<State>,
  Name extends string = string
>(
  options: CreateSliceOptions<State, CaseReducers, Name>
): Slice<State, CaseReducers, Name> => _createSlice(options);

export const createSelector = _createSelector;
