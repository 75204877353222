import {
  CodeSystemGroup,
  CodeSystemsRecord,
  CodeSystemSystem,
  ModelId,
  OrganizationWorkspaces,
  PractitionerRole,
  Workspace
} from "@laba/nexup-api";
import { GenericReducer } from "@laba/react-common";
import { createSlice } from "@reduxjs/toolkit";
import { SliceName } from "store/sliceNames";

export interface WorkspaceSlice {
  workspaceList?: OrganizationWorkspaces;
  currentWorkspaceOrganizationId?: ModelId;
  organizationCodeSystemRecord?: Record<ModelId, CodeSystemsRecord>;
  practitionerRoleRecord?: Record<ModelId, PractitionerRole[]>;
}

const initState: WorkspaceSlice = {};

type Reducer<T = void> = GenericReducer<WorkspaceSlice, T>;

const clean: Reducer = () => initState;

const updateWorkspaceList: Reducer<{
  workspaceList: OrganizationWorkspaces;
}> = (draftState, { payload }) => {
  draftState.workspaceList = payload.workspaceList;
};

const cleanWorkspaceList: Reducer = draftState => {
  draftState.workspaceList = { workspace: [], permissionList: [] };
};

const updateCurrentWorkspace: Reducer<{
  currentWorkspace?: Workspace;
}> = (draftState, { payload }) => {
  if (
    payload.currentWorkspace === undefined ||
    draftState.currentWorkspaceOrganizationId !==
      payload.currentWorkspace.organization?.id
  ) {
    draftState.organizationCodeSystemRecord = undefined;
  }
  draftState.currentWorkspaceOrganizationId =
    payload.currentWorkspace?.organization?.id;
};

const cleanCurrentWorkspace: Reducer = draftState => {
  draftState.currentWorkspaceOrganizationId = undefined;
};

const getCodeSystemRecord = (
  draftState: WorkspaceSlice,
  organization: ModelId,
  system: CodeSystemSystem
) => {
  let draftOrganizationCodeSystemRecord =
    draftState.organizationCodeSystemRecord;
  if (draftOrganizationCodeSystemRecord == null) {
    draftOrganizationCodeSystemRecord = {};
    draftState.organizationCodeSystemRecord = draftOrganizationCodeSystemRecord;
  }

  let draftCodeSystemRecord = draftOrganizationCodeSystemRecord[organization];
  if (draftCodeSystemRecord == null) {
    draftCodeSystemRecord = {};
    draftOrganizationCodeSystemRecord[organization] = draftCodeSystemRecord;
  }

  let record = draftCodeSystemRecord[system];
  if (record == null) {
    record = {
      downloadStatus: {}
    };
    draftCodeSystemRecord[system] = record;
  }

  return record;
};

const updateCodeSystemGroup: Reducer<{
  system: CodeSystemSystem;
  codeSystem: CodeSystemGroup;
  organization: ModelId;
}> = (draftState, { payload }) => {
  const record = getCodeSystemRecord(
    draftState,
    payload.organization,
    payload.system
  );
  record.system = payload.codeSystem;
};

const initSystemDownload: Reducer<{
  system: CodeSystemSystem;
  organization: ModelId;
}> = (draftState, { payload }) => {
  const record = getCodeSystemRecord(
    draftState,
    payload.organization,
    payload.system
  );
  record.downloadStatus.isDownloading = true;
  record.downloadStatus.downloadFailed = false;
};

const finishSystemDownload: Reducer<{
  system: CodeSystemSystem;
  organization: ModelId;
  codeSystem: CodeSystemGroup;
}> = (draftState, { payload }) => {
  const record = getCodeSystemRecord(
    draftState,
    payload.organization,
    payload.system
  );
  record.system = payload.codeSystem;
  record.downloadStatus.isDownloading = false;
  record.downloadStatus.downloadFailed = false;
};

const setSystemDownloadFailed: Reducer<{
  system: CodeSystemSystem;
  organization: ModelId;
}> = (draftState, { payload }) => {
  const record = getCodeSystemRecord(
    draftState,
    payload.organization,
    payload.system
  );
  record.system = undefined;
  record.downloadStatus.isDownloading = false;
  record.downloadStatus.downloadFailed = true;
};

const updatePractitionerRole: Reducer<{
  practitionerId: ModelId;
  practitionerRoleList: PractitionerRole[];
}> = (draftState, { payload }) => {
  if (!draftState.practitionerRoleRecord)
    draftState.practitionerRoleRecord = {};
  draftState.practitionerRoleRecord[payload.practitionerId] =
    payload.practitionerRoleList;
};

export const workspaceSlice = createSlice({
  name: SliceName.Workspace,
  initialState: initState,
  reducers: {
    clean,
    updateWorkspaceList,
    cleanWorkspaceList,
    updateCurrentWorkspace,
    cleanCurrentWorkspace,
    updateCodeSystemGroup,
    initSystemDownload,
    finishSystemDownload,
    setSystemDownloadFailed,
    updatePractitionerRole
  }
});
