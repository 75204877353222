import {
  createStyle,
  FlexAlignItems,
  FlexAlignSelf,
  flexColumnMixin,
  flexItemMixin,
  flexRowMixin,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

export const useLocationCardAddressAndAvailabilityStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexColumnMixin({
        gap: 4
      }),
      textAlign: "start"
    },
    adressWrapper: {
      ...flexColumnMixin({})
    },
    adressTitle: {
      ...themeTextMixin(theme, TextVariant.Subtitle2),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    addressText: {
      ...themeTextMixin(theme, TextVariant.Body2, 1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      ),
      width: "100%"
    },
    availabilityText: {
      ...themeTextMixin(theme, TextVariant.Body2, 1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      ),
      width: "100%"
    },
    scheduleContainer: {
      ...flexColumnMixin({ gap: 4 })
    },
    indicator: {
      ...flexItemMixin({ align: FlexAlignSelf.FlexStart, shrink: 0 })
    },
    textContainer: {
      ...flexRowMixin({ gap: 4, alignItems: FlexAlignItems.Center })
    }
  }),
  "LocationCardAddressAndAvailability"
);
