import { FC, IconC, SizeVariant } from "@laba/react-common";
import React from "react";
import { NexupIconButton } from "components/buttons/IconButton/IconButton";
import { Add, StatusIcon, CloseIcon } from "components/icons";
import { TagButton } from "components/tags/TagButton/TagButton";
import { BaseIconButton } from "components/buttons/BaseIconButton/BaseIconButton";
import { TypeVariant } from "model/themeVariant";
import { Noop } from "@laba/ts-common";
import { Tag, TagTypeVariant } from "components/tags/Tag/Tag";
import { ProblemStatus } from "components/inputs/ProblemInput/ProblemInput";
import { useProblemItemStyles } from "components/inputs/ProblemInput/ProblemItem/useProblemItemStyles";

export interface AddProblemItemProps {
  onClickAdd?: Noop;
  text: string;
  status: ProblemStatus;
  tagText?: string;
  onClickTag?: Noop;
  TagEndIcon?: IconC;
  tagType?: TagTypeVariant;
  onClose?: Noop;
  disabledAddProblem?: boolean;
}

export const ProblemItem: FC<AddProblemItemProps> = ({
  onClickAdd,
  text,
  tagText,
  onClickTag,
  status,
  onClose,
  TagEndIcon,
  tagType = TypeVariant.Contained,
  disabledAddProblem = false
}) => {
  const classes = useProblemItemStyles({ status });
  const showAddIcon = Boolean(onClickAdd);
  const showTagButton = Boolean(tagText) && Boolean(onClickTag);
  const showTag = Boolean(tagText) && !showTagButton;
  const showClose = Boolean(onClose);
  return (
    <div className={classes.root}>
      {showAddIcon && (
        <NexupIconButton
          onClick={onClickAdd}
          Icon={Add}
          size={SizeVariant.ExtraSmall}
          disabled={disabledAddProblem}
        />
      )}
      <p className={classes.text}>{text}</p>
      <div className={classes.endContainer}>
        {showTag && <Tag text={tagText} type={TypeVariant.Outlined} />}
        {showTagButton && (
          <TagButton
            text={tagText}
            onClick={onClickTag}
            EndIcon={TagEndIcon}
            type={tagType}
          />
        )}
        {!showClose && <StatusIcon className={classes.statusIcon} />}
        {showClose && (
          <BaseIconButton
            onClick={onClose}
            Icon={CloseIcon}
            size={SizeVariant.ExtraSmall}
          />
        )}
      </div>
    </div>
  );
};
