export const dashboard = {
  title: "Panel de administracion {{workspace}}",
  importCompleteOrganization: "Importar configuracion completa",
  importDosubaDirectory: "Importar cartilla dosuba",
  importDosubaDirectoryPharma: "Importar cartilla farmacias dosuba",
  importDosubaPatient: "Importar pacientes dosuba",
  organizationConfigError:
    "Hubo un error en la configuracion completa de la organizacion",
  workspaceCreateEditError:
    "Hubo un error en el guardado del espacio de trabajo",
  santaCatalinaPayroll: {
    santaCatalinaPayrollButton: "Importar Payroll",
    title: "Importar Payroll",
    sendImport: "Importar",
    validation: {
      invalidField: "El campo tiene un valor invalido"
    },
    startYear: "año inicio",
    endYear: "año fin",
    startMonth: "mes inicio",
    endMonth: "mes fin",
    organizationId: "organizaciones",
    organizationNoOptions: "sin opciones",
    errorImporting: "Hubo un error al importar"
  },
  santaCatalinaPayrollAllocations: {
    button: "Importar imputaciones de Payroll",
    title: "Importar imputaciones de Payroll",
    sendImport: "Importar",
    year: "Año",
    month: "Mes",
    typeId: "Id de tipo",
    organizationId: "organizaciones",
    organizationNoOptions: "sin opciones",
    errorImporting: "Hubo un error al importar"
  },
  importPatient: {
    importPatientButton: "Importar Pacientes",
    title: "Importar Pacientes",
    sendImport: "Importar",
    validation: {
      requiredField: "El campo es requerido"
    },
    errorImporting: "Hubo un error al importar",
    organization: "espacio",
    file: "Archivo",
    clearText: "Limpiar",
    closeText: "Cerrar",
    loadingText: "Cargando",
    noOptionsText: "Sin opciones",
    minCharPromptText: "Ingrese al menos {{minInputForSearch}} caracteres",
    openText: "Abrir",
    fileInputEmpty: "Cargar archivo",
    fileInputWithFile: "Archivo: {{fileName}}",
    fileInputWithError: "Error: {{error}}",
    field: "Campos"
  },
  importVademecum: {
    importVademecumButton: "Importar Vademecum",
    title: "Importar Vademecum",
    sendImport: "Importar",
    validation: {
      requiredField: "El campo es requerido"
    },
    errorImporting: "Hubo un error al importar",
    organization: "espacio",
    file: "Archivo",
    importMode: "Modo de importacion",
    fileType: "Tipo de archivo",
    clearText: "Limpiar",
    closeText: "Cerrar",
    loadingText: "Cargando",
    noOptionsText: "Sin opciones",
    minCharPromptText: "Ingrese al menos {{minInputForSearch}} caracteres",
    openText: "Abrir",
    fileInputEmpty: "Cargar archivo",
    fileInputWithFile: "Archivo: {{fileName}}",
    fileInputWithError: "Error: {{error}}"
  },
  importPractitioner: {
    importPractitionerButton: "Importar Colaboradores",
    title: "Importar Colaboradores",
    sendImport: "Importar",
    validation: {
      requiredField: "El campo es requerido"
    },
    errorImporting: "Hubo un error al importar",
    organization: "espacio",
    file: "Archivo",
    clearText: "Limpiar",
    closeText: "Cerrar",
    loadingText: "Cargando",
    noOptionsText: "Sin opciones",
    minCharPromptText: "Ingrese al menos {{minInputForSearch}} caracteres",
    openText: "Abrir",
    fileInputEmpty: "Cargar archivo",
    fileInputWithFile: "Archivo: {{fileName}}",
    fileInputWithError: "Error: {{error}}"
  },
  importQuestionnaireResponse: {
    importQuestionnaireResponseButton: "Importar Evoluciones",
    title: "Importar Evoluciones",
    sendImport: "Importar",
    validation: {
      requiredField: "El campo es requerido"
    },
    errorImporting: "Hubo un error al importar",
    organization: "espacio",
    file: "Archivo",
    questionnaire:
      "Questionario (Tiene que tener el formato Evolucion Importada)",
    clearText: "Limpiar",
    closeText: "Cerrar",
    loadingText: "Cargando",
    noOptionsText: "Sin opciones",
    minCharPromptText: "Ingrese al menos {{minInputForSearch}} caracteres",
    openText: "Abrir",
    fileInputEmpty: "Cargar archivo",
    fileInputWithFile: "Archivo: {{fileName}}",
    fileInputWithError: "Error: {{error}}"
  },
  maintenancePanel: {
    openButton: "Panel Mantenimiento",
    title: "Panel Mantenimiento",
    refresh: "Actualizar estado",
    statusTitle: "Estado",
    statusTextPending: "Sin informacion de sync",
    complete: "Completar sincronizacion",
    configSync: "Configurar sincronizacion",
    startSync: "Iniciar sincronizacion",
    syncCode: "Codigo de Sync",
    startDate: "Instante inicio de Sync (-03:00)",
    count: "Cantidad de elemento por pagina",
    delay: "Delay entre ventanas minimo en minutos"
  },
  workspaceAdministration: {
    title: "Crear/Editar Espacio",
    administrateWorkspaceButton: "Crear/Editar Espacio",
    save: "Guardar Cambios",
    reset: "Reiniciar Formularios",
    validation: {
      requiredField: "El campo es requerido",
      wrongRole:
        "Los siguiente roles no estas disponibles para este plan: {{wrongRoleList}}"
    },
    create: "Crear",
    edit: "Editar",
    toEditOrganization: "Espacio a editar",
    clearText: "Limpiar",
    closeText: "Cerrar",
    loadingText: "Cargando",
    noOptionsText: "Sin opciones",
    openText: "Abrir",
    minCharPromptText: "Ingrese al menos {{minInputForSearch}} caracteres",
    parentOrganization: "Organization Padre *",
    active: "Activo",
    inactive: "Inactivo",
    organizationName: "Nombre del espacio *",
    organizationCrm: "Id del cliente en CRM *",
    organizationPictureUrl: "Logo cliente",
    organizationPhone: "Telefono organizacion (recetas y turnos)",
    organizationEmail: "Mail organizacion (recetas y turnos)",
    organizationAddress: "direccion organizacion (recetas y turnos)",
    organizationPlan: "Plan del cliente *",
    organizationPaymentStatus: "Estado de pago *",
    organizationPaymentExpiration: "Fecha de expiracion de pago",
    organizationSpeciality: "Especialidades",
    organizationSpecialityElement: "Especialidad *",
    organizationSpecialityAdd: "Agregar Especialidad",
    organizationSpecialityRemove: "Borrar Especialidad",
    createDefaultSchedule: "Crear Agendas por defecto",
    cleanOldConfig:
      "Desactivar configuración específica (cuestionarios: {{questionnaire}}, indicaciones: {{medicalRequest}}, procedimientos: {{procedure}}, mediciones: {{measurement}})",
    createDefaultScheduleYes: "Si",
    createDefaultScheduleNo: "No",
    practitioner: "Profesionales",
    practitionerAdd: "Agregar Profesional",
    practitionerRemove: "Borrar Profesional",
    practitionerFirstName: "Nombre *",
    practitionerLastName: "Apellido *",
    practitionerGender: "Genero *",
    practitionerBirthDate: "Fecha de nacimiento",
    practitionerIdentifierSystem: "Tipo de documento",
    practitionerIdentifierValue: "Documento",
    practitionerPhone: "Telefono",
    practitionerEmail: "Mail *",
    practitionerRegistration: "Matrícula",
    practitionerRegistrationType: "Tipo de matrícula",
    practitionerRegistrationPlace: "Provincia matrícula",
    practitionerRegistrationValidation: "Estado de validacion",
    practitionerSignatureFile: "Foto de firma",
    practitionerUserId:
      "Usuario existente: Dejarlo vacio para crearlo a partir del mail",
    practitionerUserPassword: "Clave: solo completar para usuarios nuevo",
    practitionerRolList: "Roles *",
    location: "Consultorio / Sede (Turnos)",
    locationName: "Nombre Consultorio / Sede *",
    locationAdd: "Agregar lugar",
    locationRemove: "Borrar lugar"
  },
  rolUpdate: {
    openButton: "Actualizador de roles",
    title: "Actualizador de roles",
    toModifyRol: "Roles a modificar",
    noOptionsText: "Sin opciones",
    newRol: "Rol nuevo",
    migrate: "Migrar",
    needNewRol: "Elije un rol para migrar"
  },
  whatsappSession: {
    openButton: "Configuración de notificaciones",
    notificationProcessQueue: "Procesar notificaciones",
    whatsappNotificationProcessQueue: "Procesar whatsapps",
    title: "Configuración de notificaciones",
    sessionListTitle: "Listado de sesiones de whatsapp web",
    item: {
      id: "Identificador: {{id}}",
      version: "Version: {{version}}",
      authenticatedTitle: "Autenticado: {{authenticated}}",
      isReadyTitle: "Ready: {{isReady}}",
      phoneNumber: "Número: {{phone}}"
    }
  },
  createPrescriptionDocument: {
    openButton: "Regenerar pdf recetas",
    title: "Regenerar pdf recetas",
    confirmButton: "Aceptar",
    idList: "Lista de ids de prescripciones (separado por coma)"
  },
  alfabetaSync: {
    alfabetaProductSyncButton: "Alfabeta Productos Sync",
    alfabetaProductTitle: "Alfabeta Productos Sync",
    alfabetaMedicationSyncButton: "Alfabeta Medicamentos Sync",
    alfabetaMedicationTitle: "Alfabeta Medicamentos Sync",
    alfabetaContractGenerationButton: "Alfabeta Convenio",
    alfabetaContractGenerationTitle: "Alfabeta Convenio",
    sync: "Sincronizar",
    generate: "Generar",
    validation: {
      requiredField: "El campo es requerido"
    },
    errorImporting: "Hubo un error al sincronizar",
    organization: "espacio",
    file: "Archivo",
    priceMode: "Modo de calculo de precio",
    processMode: "Modo de procesamiento",
    codefile: "Archivo de mapa de codigos",
    clearText: "Limpiar",
    closeText: "Cerrar",
    loadingText: "Cargando",
    noOptionsText: "Sin opciones",
    minCharPromptText: "Ingrese al menos {{minInputForSearch}} caracteres",
    openText: "Abrir",
    fileInputEmpty: "Cargar archivo",
    fileInputWithFile: "Archivo: {{fileName}}",
    fileInputWithError: "Error: {{error}}"
  },
  finnegansFeeIntegration: {
    buttonText: "Facturas Finnegans",
    popupTitle: "Integrar facturas de Finnegans",
    submitText: "Integrar",
    fields: {
      startDateField: {
        title: "Fecha de inicio de la ventana",
        placeholder: "Fecha de inicio de la ventana"
      },
      endDateField: {
        title: "Fecha de finalización de la ventana",
        placeholder: "Fecha de finalización de la ventana"
      },
      windowField: {
        title: "Tamaño de la ventana",
        placeholder: "Tamaño de la ventana en dias"
      },
      tableNameField: {
        title: "Nombre de la tabla",
        placeholder: "Nombre de la tabla"
      }
    },
    validation: {
      emptyWindow: "Debe configurarse al menos una ventana de fechas",
      requiredDate: "La fecha es requerida",
      invalidDate: "La fecha de finalización debe ser posterior a la de inicio"
    }
  }
};
