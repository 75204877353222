import React from "react";
import { FC, useI18n } from "@laba/react-common";
import { Edit } from "components/generic/Edit/Edit";
import {
  HospitalizationForm,
  hospitalizationJsonToModel,
  hospitalizationTransform
} from "components/pages/NexupAdmin/resources/hospitalization/HospitalizationForm";
import { Hospitalization, ResourceType } from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { useElementExportedFileName } from "components/hook/useExportedFileName";

const tk = tkCP.adminPage[ResourceType.Hospitalization];

export const HospitalizationEdit: FC = () => {
  const { t } = useI18n();
  const exportedFileName = useElementExportedFileName<Hospitalization>(() =>
    t(tk.title)
  );
  return (
    <Edit
      resourceTransform={hospitalizationTransform}
      resource={ResourceType.Hospitalization}
      jsonToModel={hospitalizationJsonToModel}
      exportedFileName={exportedFileName}
      meta={{
        extraParams: {
          withDiagnosis: true
        }
      }}
    >
      <HospitalizationForm />
    </Edit>
  );
};
