import { pxToRem } from "model/style/dimension";
import { notUndefined, Optional } from "model/types";
import { pickBy } from "lodash-es";

export type BoxCornerElementPropertyConfig = string | number;
export interface BoxCornerPropertyConfig {
  topLeft?: BoxCornerElementPropertyConfig;
  topRight?: BoxCornerElementPropertyConfig;
  bottomRight?: BoxCornerElementPropertyConfig;
  bottomLeft?: BoxCornerElementPropertyConfig;
  top?: BoxCornerElementPropertyConfig;
  bottom?: BoxCornerElementPropertyConfig;
  left?: BoxCornerElementPropertyConfig;
  right?: BoxCornerElementPropertyConfig;
  all?: BoxCornerElementPropertyConfig;
}
export enum BoxCornerPropertyKey {
  TopLeft = "TopLeft",
  TopRight = "TopRight",
  BottomLeft = "BottomLeft",
  BottomRight = "BottomRight"
}

const getBoxCornerPropertyValue = (
  config?: BoxCornerElementPropertyConfig
): Optional<string> =>
  config == null
    ? undefined
    : typeof config === "string"
    ? config
    : pxToRem(config);

export const getBoxCornerPropertyMixinBuilder =
  <T extends string>(boxPropertyConfig: Record<BoxCornerPropertyKey, T>) =>
  (config: BoxCornerPropertyConfig): Partial<Record<T, string>> => {
    const {
      [BoxCornerPropertyKey.TopLeft]: topLeftKey,
      [BoxCornerPropertyKey.TopRight]: topRightKey,
      [BoxCornerPropertyKey.BottomLeft]: bottomLeftKey,
      [BoxCornerPropertyKey.BottomRight]: bottomRightKey
    } = boxPropertyConfig;
    const result = {
      [topLeftKey]: getBoxCornerPropertyValue(
        config.topLeft ?? config.top ?? config.left ?? config.all
      ),
      [topRightKey]: getBoxCornerPropertyValue(
        config.topRight ?? config.top ?? config.right ?? config.all
      ),
      [bottomLeftKey]: getBoxCornerPropertyValue(
        config.bottomLeft ?? config.bottom ?? config.left ?? config.all
      ),
      [bottomRightKey]: getBoxCornerPropertyValue(
        config.bottomRight ?? config.bottom ?? config.right ?? config.all
      )
    };

    return pickBy(result, notUndefined) as Record<T, string>;
  };
