import {
  borderMixinRadiusAll,
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  flexItemMixin,
  flexRowMixin,
  pxToRem,
  RadiusVariant,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import {
  getThemeRadius,
  getThemeStyleColor,
  themeTextMixin
} from "model/themeUtils";

export const useSideNavigatorStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...borderMixinRadiusAll(getThemeRadius(theme, RadiusVariant.Small)),
      ...flexColumnMixin({})
    },
    navItemLink: {
      ...flexRowMixin({ alignItems: FlexAlignItems.Center, gap: 8 }),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      ),
      height: pxToRem(48)
    },
    text: {
      ...flexItemMixin({ grow: 1, shrink: 1, basis: "0%" }),
      textAlign: "start"
    },
    textIsSelected: {
      ...themeTextMixin(theme, TextVariant.Subtitle2, 2),
      color: getThemeStyleColor(theme, StyleVariant.Primary)
    },
    textIsNotSelected: {
      ...themeTextMixin(theme, TextVariant.Body2, 2),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    selectedIndicator: {
      width: pxToRem(8),
      height: "100%"
    },
    indicatorIsSelected: {
      backgroundColor: getThemeStyleColor(theme, StyleVariant.Primary)
    }
  }),
  "SideNavigator"
);
