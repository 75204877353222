import {
  Color,
  FC,
  getClassName,
  IconC,
  RadiusVariant
} from "@laba/react-common";
import React from "react";
import { StyleVariant, TypeVariant } from "model/themeVariant";
import { isEmpty } from "lodash-es";
import { useTagStyle } from "./TagStyle";

export type TagTypeVariant = TypeVariant.Contained | TypeVariant.Outlined;
export enum TagPaddingVariant {
  Normal = "Normal",
  Small = "Small"
}
export interface TagProps {
  className?: string;
  disabled?: boolean;
  isClickable?: boolean;
  title?: string;
  text?: string;
  EndIcon?: IconC;
  StartIcon?: IconC;
  style?: StyleVariant;
  type?: TagTypeVariant;
  backgroundColor?: Color;
  radius?: RadiusVariant;
  paddingVariant?: TagPaddingVariant;
}

const removeWhite = (color?: Color) => {
  return color === "#FFF" || color === "#FFFFFF" ? "#000" : color;
};

export const Tag: FC<TagProps> = ({
  className,
  EndIcon,
  StartIcon,
  text,
  title,
  backgroundColor,
  disabled = false,
  isClickable = false,
  style = StyleVariant.Primary,
  type = TypeVariant.Contained,
  radius = RadiusVariant.Small,
  paddingVariant = TagPaddingVariant.Normal
}) => {
  const classes = useTagStyle({
    disabled,
    type,
    style,
    isClickable,
    radius,
    backgroundColor: removeWhite(backgroundColor),
    withIcon: EndIcon !== undefined,
    paddingVariant
  });
  return (
    <div
      className={getClassName(
        className,
        classes.container,
        isClickable ? classes.pointerCursor : classes.defaultCursor
      )}
      title={title?.toUpperCase()}
    >
      {StartIcon && <StartIcon className={classes.startIcon} />}
      {!isEmpty(text) && (
        <span className={classes.text}>{text?.toUpperCase()}</span>
      )}
      {EndIcon && <EndIcon className={classes.endIcon} />}
    </div>
  );
};
