import CancelIcon from "@material-ui/icons/Cancel";
import AddAlarmIcon from "@material-ui/icons/AddAlarm";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CheckIcon from "@material-ui/icons/Check";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import PersonIcon from "@material-ui/icons/Person";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { GenericObject } from "@laba/ts-common";
import _ from "lodash-es";
import { IconC } from "components/types";

export const storyIcons = {
  none: undefined,
  cancel: CancelIcon,
  addAlarm: AddAlarmIcon,
  assignment: AssignmentIcon,
  check: CheckIcon,
  filterList: FilterListIcon,
  arrowDropDown: ArrowDropDownIcon,
  search: SearchIcon,
  person: PersonIcon
};

export const getIconControl = (optional = true): GenericObject => {
  return {
    options: Object.keys(optional ? storyIcons : _.omit(storyIcons, ["none"])),
    mapping: optional ? storyIcons : _.omit(storyIcons, ["none"]),
    control: { type: "select" }
  };
};

// storybook needs the arg for an icon to be a key of storyIcons but the components typing need a IconC so we cast it
export const getIconControlDefaultValue = (
  defaultIcon: keyof typeof storyIcons = "none"
): IconC => defaultIcon as unknown as IconC;
