import { getMinutesDiff, Optional } from "@laba/ts-common";
import { getModelOrUndefined, ResourceType } from "model/primitives";
import { getPublicAppointmentTypeFromSchedule } from "model/resource";
import { PublicAppointmentData } from "./publicAppointment";
import { PublicPatientData } from "../publicPatient";

export const getPublicAppointmentPatientData = (
  publicAppointment?: PublicAppointmentData
): Optional<PublicPatientData> =>
  getModelOrUndefined(publicAppointment?.patient);

type CreateBasePublicAppointmentDataProps = Omit<
  PublicAppointmentData,
  "resourceType"
>;
export const createBasePublicAppointment = ({
  schedule,
  startDate,
  endDate,
  type,
  ...rest
}: CreateBasePublicAppointmentDataProps): PublicAppointmentData => {
  const scheduleModel = getModelOrUndefined(schedule);
  const specialityList = scheduleModel?.speciality?.map(s => s.code);

  const schedulePerformerList = scheduleModel?.performerList;

  const appointmentTypeFromSchedule =
    getPublicAppointmentTypeFromSchedule(scheduleModel);

  return {
    ...rest,
    resourceType: ResourceType.PublicAppointment,
    schedule,
    startDate,
    endDate,
    minutesDuration:
      startDate && endDate ? getMinutesDiff(startDate, endDate) : undefined,
    speciality: specialityList,
    location: scheduleModel?.location,
    type: type ?? appointmentTypeFromSchedule,
    practitionerTeam: schedulePerformerList
  };
};
