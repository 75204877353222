import {
  borderMixin,
  colorChangeAlpha,
  createStyle,
  FlexJustifyContent,
  flexRowMixin,
  paddingMixin,
  pxToRem,
  RadiusVariant,
  StyleVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeRadius, getThemeStyleColor } from "model/themeUtils";

export const useSliderStyles = createStyle(
  (theme: Theme) => ({
    root: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    },
    track: {
      color: getThemeStyleColor(theme, StyleVariant.Success)
    }
  }),
  "Slider"
);

export const useVolumeControlButtonStyles = createStyle(
  (theme: Theme) => ({
    root: {
      position: "relative"
    },
    sliderContainer: {
      ...flexRowMixin({ justifyContent: FlexJustifyContent.Center }),
      ...borderMixin({
        radius: {
          top: getThemeRadius(theme, RadiusVariant.Small)
        }
      }),
      ...paddingMixin({
        all: 0
      }),
      overflow: "hidden",
      width: pxToRem(40),
      backgroundColor: colorChangeAlpha(
        getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.Contrast
        ),
        0.3
      ),
      position: "absolute",
      bottom: pxToRem(40),
      height: 0,
      maxHeight: 0,
      opacity: 0,
      transition: [
        ["height 0.5s"],
        ["max-height 0.5s"],
        ["padding 0.5s step-end"],
        ["opacity 0.5s"]
      ]
    },
    open: {
      ...paddingMixin({ topBottom: 16 }),
      height: pxToRem(90),
      maxHeight: pxToRem(90),
      transition: [["height 0.5s"], ["max-height 0.5s"], ["padding 0s"]],
      opacity: 1
    },
    sliderIsOpen: {
      ...borderMixin({
        radius: {
          top: 0,
          bottom: getThemeRadius(theme, RadiusVariant.Small)
        }
      })
    }
  }),
  "VolumeControlButton"
);
