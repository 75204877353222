import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { ModelId } from "model/primitives/model/model";
import { PractitionerRoleCodes } from "model/resource/person/practitionerRole";
import { ValidationStatus } from "model/resource/person/person";
import { Practitioner } from "model/resource/person/practitioner/practitioner";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { getKeyObj, HttpMethod } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { QualificationValidationStatus } from "model/resource";

export enum PractitionerOrder {
  CreationDate = "creationDate",
  UpdateDate = "updateDate",
  Name = "name"
}

export interface PractitionerQueryParams {
  withRoleList?: boolean;
  withUser?: boolean;
  withOrganization?: boolean;
}

export interface PractitionerListQueryParams
  extends ListQueryParams,
    PractitionerQueryParams {
  content?: string;
  organization?: ModelId;
  role?: PractitionerRoleCodes;
  userId?: string;
  validationStatus?: ValidationStatus;
  order?: PractitionerOrder;
  registrationValidationStatus?: QualificationValidationStatus;
}

export const PractitionerListQueryParamsKey = getKeyObj(
  createHydratedMock<PractitionerListQueryParams>()
);

const practitionerBasePath = "/practitioner";

export const getPractitionerList = (
  params: PractitionerListQueryParams
): Promise<ApiPageRequestResponse<Practitioner>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(practitionerBasePath),
    params
  });

export const getPractitioner = (
  id: ModelId,
  params: PractitionerQueryParams
): Promise<ApiRequestResponse<Practitioner>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${practitionerBasePath}/${id}`),
    params
  });

export const createPractitioner = (
  data: Practitioner
): Promise<ApiRequestResponse<Practitioner>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(practitionerBasePath),
    data
  });

export const updatePractitioner = (
  data: Practitioner
): Promise<ApiRequestResponse<Practitioner>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${practitionerBasePath}/${data.id}`),
    data
  });
