import React from "react";
import { FC, getClassName } from "@laba/react-common";
import { useLocationCardHeaderStyle } from "components/card/LocationCard/LocationCardHeader/useLocationCardHeaderStyles";

interface LocationCardHeaderProps {
  className?: string;
  name?: string;
  imageSource?: string;
}

export const LocationCardHeader: FC<LocationCardHeaderProps> = ({
  className,
  name,
  imageSource
}) => {
  const classes = useLocationCardHeaderStyle();
  return (
    <div className={getClassName(className, classes.root)}>
      {imageSource && (
        <img className={classes.icon} src={imageSource} alt={name} />
      )}
      <p className={classes.text}>{name}</p>
    </div>
  );
};
