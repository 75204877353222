import React from "react";
import { FC, getClassName, IconC, StyleVariant } from "@laba/react-common";
import { Noop } from "@laba/ts-common";
import { BasePopup } from "../BasePopup/BasePopup";
import { useDialogAlertCommercialStyles } from "./DialogAlertCommercialStyle";
import { PopupVariant } from "../BasePopup/BasePopupStyle";
import { DialogSizeVariant } from "../Dialog/DialogStyle";

export interface DialogAlertCommercialProps {
  className?: string;
  disableBackdropClick?: boolean;
  textTitle?: string;
  textSubtitle?: string;
  onClose?: Noop;
  open?: boolean;
  style?: StyleVariant;
  primaryButtonStyle?: StyleVariant;
  iconStyle?: StyleVariant;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick?: Noop;
  onSecondaryButtonClick?: Noop;
  PrimaryButtonIcon?: IconC;
  SecondaryButtonIcon?: IconC;
  ImageSvg?: IconC;
  IconTitle?: IconC;
  dialogSizeVariant?: DialogSizeVariant;
  loading?: boolean;
  isMobile?: boolean;
}

export const DialogAlertCommercial: FC<DialogAlertCommercialProps> = ({
  className,
  onClose,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonText,
  secondaryButtonText,
  PrimaryButtonIcon,
  SecondaryButtonIcon,
  textTitle,
  textSubtitle,
  ImageSvg,
  IconTitle,
  disableBackdropClick = false,
  open = false,
  style = StyleVariant.Primary,
  primaryButtonStyle,
  iconStyle,
  dialogSizeVariant,
  loading,
  isMobile
}) => {
  const hasImage = Boolean(ImageSvg);
  const hasIcon = Boolean(IconTitle) && !hasImage;
  const dialogVariant = isMobile
    ? PopupVariant.Vertical
    : PopupVariant.Horizontal;
  const classes = useDialogAlertCommercialStyles({
    iconStyle,
    dialogVariant,
    isMobile
  });
  return (
    <BasePopup
      className={className}
      open={open}
      style={style}
      primaryButtonStyle={primaryButtonStyle}
      onClose={onClose}
      onPrimaryButtonClick={onPrimaryButtonClick}
      onSecondaryButtonClick={onSecondaryButtonClick}
      disableBackdropClick={disableBackdropClick}
      primaryButtonText={primaryButtonText}
      secondaryButtonText={secondaryButtonText}
      PrimaryButtonIcon={PrimaryButtonIcon}
      SecondaryButtonIcon={SecondaryButtonIcon}
      popupVariant={dialogVariant}
      popupSizeVariant={dialogSizeVariant}
      fullWidth={false}
      loading={loading}
      isCommercialPopup
    >
      <div className={getClassName(classes.grayDarkColor, classes.content)}>
        <div className={classes.headerContainer}>
          {hasImage && ImageSvg && <ImageSvg className={classes.imageSvg} />}
          {hasIcon && IconTitle && <IconTitle className={classes.icon} />}
          <div className={classes.textContainer}>
            <p className={classes.textTitle}>{textTitle}</p>
            {dialogVariant === PopupVariant.Horizontal && (
              <p className={classes.textSubtitle}>{textSubtitle}</p>
            )}
          </div>
        </div>
        {dialogVariant === PopupVariant.Vertical && (
          <p className={classes.textSubtitle}>{textSubtitle}</p>
        )}
      </div>
    </BasePopup>
  );
};
