import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  getModelReferenceId,
  MeasurementGroupCategory,
  MeasurementGroupDefinitionKey,
  ModelReference,
  Organization,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { NumberInput } from "components/generic/NumberInput/TextInput";
import { ColorInput } from "components/generic/ColorInput/ColorInput";
import { ActivityDefinitionCommonInput } from "components/generic/ActivityDefinition/ActivityDefinitionCommonInput/ActivityDefinitionCommonInput";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useWatchResourceForm } from "components/hook/useResourceContext";

export const tk = tkCP.adminPage[ResourceType.MeasurementGroupDefinition];
export const MeasurementGroupDefinitionMainContent: FC = () => {
  const { t } = useI18n();

  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organization = useWatchResourceForm<ModelReference<Organization>>(
    MeasurementGroupDefinitionKey.organization
  );

  const organizationId =
    getModelReferenceId(organization) || workspaceOrganizationId;

  return (
    <>
      <ActivityDefinitionCommonInput organizationId={organizationId} />
      <EnumSelectorInput
        source={MeasurementGroupDefinitionKey.category}
        label={t(tk.fields.category)}
        enumValue={MeasurementGroupCategory}
        required
      />
      <ColorInput
        source={MeasurementGroupDefinitionKey.color}
        label={t(tk.fields.color)}
      />
      <NumberInput
        source={MeasurementGroupDefinitionKey.editPeriod}
        label={t(tk.fields.editPeriod)}
        min={0}
        step={1}
      />
    </>
  );
};
