export enum Day {
  Monday = "mon",
  Tuesday = "tue",
  Wednesday = "wed",
  Thursday = "thu",
  Friday = "fri",
  Saturday = "sat",
  Sunday = "sun"
}

export enum Hour {
  One = "01:00:00",
  Two = "02:00:00",
  Three = "03:00:00",
  Four = "04:00:00",
  Five = "05:00:00",
  Six = "06:00:00",
  Seven = "07:00:00",
  Eight = "08:00:00",
  Nine = "09:00:00",
  Ten = "10:00:00",
  Eleven = "11:00:00",
  Twelve = "12:00:00",
  Thirteen = "13:00:00",
  Fourteen = "14:00:00",
  Fifteen = "15:00:00",
  Sixsteen = "16:00:00",
  Seventeen = "17:00:00",
  Eighteen = "18:00:00",
  Nineteen = "19:00:00",
  Twenty = "20:00:00",
  Twentyone = "21:00:00",
  Twentytwo = "22:00:00",
  Twentythree = "23:00:00",
  Twentyfour = "00:00:00"
}
