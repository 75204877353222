import { FC, ReactElementOrNull, useI18n } from "@laba/react-common";
import React from "react";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { TextInput } from "components/generic/TextInput/TextInput";
import {
  CodeSystemSystem,
  IdentifierKey,
  IdentifierProperty,
  IdentifierPropertyKey,
  IdentifierPropertyType,
  Model,
  ModelId
} from "@laba/nexup-api";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { tkCC } from "translation/i18n";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { NumberInput } from "components/generic/NumberInput/TextInput";
import { FormDataConsumer } from "components/generic/FormDataConsumer/FormDataConsumer";
import { isEmpty } from "lodash-es";
import { IdentifierFilesInput } from "../IdentifierFilesInput/IdentifierFilesInput";

const tk = tkCC.inputs.identifierInput;

interface PropertyValueProps {
  propertyType?: IdentifierPropertyType;
  getSource: (source: string) => string;
}
const IdentifierPropertyValue = ({
  propertyType,
  getSource
}: PropertyValueProps): ReactElementOrNull => {
  const { t } = useI18n();
  if (isEmpty(propertyType)) return null;
  switch (propertyType) {
    case IdentifierPropertyType.FederationVersion:
    case IdentifierPropertyType.MedicationPresentation:
      return (
        <TextInput
          source={getSource(IdentifierPropertyKey.valueCode)}
          label={t(tk.propertyValueCode)}
        />
      );
    default:
      return (
        <>
          <TextInput
            source={getSource(IdentifierPropertyKey.valueCode)}
            label={t(tk.propertyValueCode)}
          />
          <NumberInput
            source={getSource(IdentifierPropertyKey.valueDecimal)}
            label={t(tk.propertyValueNumber)}
          />
          <BooleanInput
            source={getSource(IdentifierPropertyKey.valueBoolean)}
            label={t(tk.propertyValueBool)}
          />
          <TextInput
            source={getSource(IdentifierPropertyKey.valueString)}
            label={t(tk.propertyValueString)}
          />
        </>
      );
  }
};

export interface IdentifierInputProps {
  organization?: ModelId;
  source: string;
  system: CodeSystemSystem;
  withAttachment?: boolean;
  withProps?: boolean;
}
export const IdentifierInput: FC<IdentifierInputProps> = ({
  source,
  organization,
  system,
  withProps = true,
  withAttachment = true
}) => {
  const { t } = useI18n();
  const identifierSystem = useCodeSystemGroup(system, organization);
  return (
    <ArrayInput source={source} label={t(tk.identifier)}>
      <CodeSystemSelectorInput
        source={IdentifierKey.system}
        label={t(tk.identifierSystem)}
        codeSystem={identifierSystem}
        organizationId={organization}
        withCreate
      />
      <TextInput source={IdentifierKey.value} label={t(tk.identifierValue)} />
      {withAttachment && (
        <IdentifierFilesInput
          source={IdentifierKey.files}
          organization={organization}
        />
      )}
      {withProps && (
        <ArrayInput source={IdentifierKey.property} label={t(tk.property)}>
          <EnumSelectorInput
            source={IdentifierPropertyKey.type}
            label={t(tk.propertyType)}
            enumValue={IdentifierPropertyType}
            withCreate
          />
          <FormDataConsumer<Model, IdentifierProperty>>
            {({ getSource: getPropertySource, scopedFormData: property }) => {
              return (
                <IdentifierPropertyValue
                  propertyType={property?.type}
                  getSource={getPropertySource}
                />
              );
            }}
          </FormDataConsumer>
        </ArrayInput>
      )}
    </ArrayInput>
  );
};
