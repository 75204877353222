import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  flexItemMixin,
  flexRowMixin,
  marginMixin,
  paddingMixin,
  pxToRem
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { NexupColor } from "model/nexupColor";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { StyleVariant, TextVariant } from "model/themeVariant";

export interface AttachmentListInputStyleProps {
  hasError: boolean;
}

export const AttachmentListInputStyle = createStyle(
  (theme: Theme) => ({
    root: { width: "100%", ...flexColumnMixin({ gap: 8 }) },
    fileValueItemContainer: {
      ...flexRowMixin({ alignItems: FlexAlignItems.Center }),
      ...paddingMixin({ left: 8, right: 16 }),
      width: "100%",
      backgroundColor: NexupColor.Background,
      height: pxToRem(40)
    },
    downloadButton: {
      ...flexItemMixin({ grow: 1 })
    },
    errorText: (props: AttachmentListInputStyleProps) => ({
      ...themeTextMixin(theme, TextVariant.Caption),
      ...marginMixin({ leftRight: 14, top: 4 }),
      color: props.hasError
        ? getThemeStyleColor(theme, StyleVariant.Danger)
        : getThemeStyleColor(
            theme,
            StyleVariant.Primary,
            ThemeStyleColorVariant.GrayDark
          )
    })
  }),
  "AttachmentListInput"
);
