import {
  PublicScheduleAvailabilityCalendar,
  ScheduleItemAvailabilityList
} from "../scheduleAvailabilityCalendar";
import { publicScheduleMock } from "./publicSchedule";

export const publicScheduleItemAvailabilityListItemMock: ScheduleItemAvailabilityList =
  {
    date: "2024-09-03",
    slotList: [
      { startDate: "09:30:00", endDate: "10:30:00" },
      { startDate: "10:30:00", endDate: "11:30:00" },
      { startDate: "11:30:00", endDate: "12:30:00" },
      { startDate: "12:30:00", endDate: "13:30:00" },
      { startDate: "13:30:00", endDate: "14:30:00" }
    ]
  };

export const publicScheduleAvailabilityCalendarMock: PublicScheduleAvailabilityCalendar =
  {
    startDate: "2024-09-03T03:00:00.000Z",
    endDate: "2025-09-03T03:00:00.000Z",
    scheduleList: [
      {
        schedule: publicScheduleMock,
        availabilityList: [publicScheduleItemAvailabilityListItemMock]
      }
    ]
  };
