import {
  KnownIdentifierSystemType,
  ModelReference,
  OpenCode,
  ResourceModel,
  ResourceType
} from "model/primitives";
import { createHydratedMock } from "ts-auto-mock";
import { ApiDate, getKeyObj, KeyObj } from "@laba/ts-common";
import { Gender } from "model/resource/person";
import { OrganizationPublicData } from "../publicOrganization/publicOrganization";

export interface PublicPatientData
  extends ResourceModel<ResourceType.PublicPatient> {
  firstName?: string;
  otherName?: string;
  lastName?: string;
  motherLastName?: string;
  payer?: ModelReference<OrganizationPublicData>;
  plan?: string;
  credentialNumber?: string;
  identifierType?: OpenCode<KnownIdentifierSystemType>;
  identifierValue?: string;
  organization?: ModelReference<OrganizationPublicData>;
  birthDate?: ApiDate;
  gender?: Gender;
}

export const PublicPatientDataKey: KeyObj<PublicPatientData> = getKeyObj(
  createHydratedMock<PublicPatientData>()
);
