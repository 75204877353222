import {
  createStyle,
  FlexAlignItems,
  FlexJustifyContent,
  flexRowMixin,
  marginMixin,
  pxToRem
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { MessageOrigin } from "components/chat/Chat/ChatMessages/ChatMessage/ChatMessageComponent";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { StyleVariant, TextVariant } from "model/themeVariant";

export interface ChatMessageStyleProps {
  messageOrigin: MessageOrigin;
}

export const useChatMessageComponentStyle = createStyle(
  (theme: Theme) => ({
    root: (props: ChatMessageStyleProps) => ({
      ...flexRowMixin({
        justifyContent: FlexJustifyContent.FlexStart,
        alignItems: FlexAlignItems.Center,
        gap: 8
      }),
      ...marginMixin({
        left: props.messageOrigin === MessageOrigin.Sent ? 0 : 3,
        right: props.messageOrigin === MessageOrigin.Sent ? 3 : 0
      })
    }),
    messageText: (props: ChatMessageStyleProps) => ({
      ...themeTextMixin(theme, TextVariant.Body2),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        props.messageOrigin === MessageOrigin.Sent
          ? ThemeStyleColorVariant.Contrast
          : ThemeStyleColorVariant.GrayDark
      ),
      whiteSpace: "pre-wrap"
    }),
    icon: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      ),
      width: pxToRem(10),
      height: pxToRem(10)
    }
  }),
  "ChatMessageComponent"
);
