import { PublicationStatus } from "model/resource/activity/activityDefinition";
import { ModelId } from "model/primitives/model/model";
import { getKeyObj, HttpMethod, KeyObj } from "@laba/ts-common";
import { getFrontApiUrl, request } from "request/nexupRequest";
import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import {
  MeasurementGroupCategory,
  MeasurementGroupDefinition
} from "model/resource/medical/observation/measurementGroupDefinition";
import { createHydratedMock } from "ts-auto-mock";

const measurementGroupDefinitionBasePath = "/measurement-group-definition";

export interface MeasurementGroupDefinitionListQueryParams
  extends ListQueryParams {
  category?: MeasurementGroupCategory;
  organization?: ModelId;
  status?: PublicationStatus;
}

export const MeasurementGroupDefinitionListQueryParamsKey: KeyObj<MeasurementGroupDefinitionListQueryParams> =
  getKeyObj(createHydratedMock<MeasurementGroupDefinitionListQueryParams>());

export const getMeasurementGroupDefinitionList = (
  params: MeasurementGroupDefinitionListQueryParams
): Promise<ApiPageRequestResponse<MeasurementGroupDefinition>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(measurementGroupDefinitionBasePath),
    params
  });

export const getMeasurementGroupDefinition = (
  id: ModelId
): Promise<ApiRequestResponse<MeasurementGroupDefinition>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${measurementGroupDefinitionBasePath}/${id}`)
  });

export const createMeasurementGroupDefinition = (
  data: MeasurementGroupDefinition
): Promise<ApiRequestResponse<MeasurementGroupDefinition>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(measurementGroupDefinitionBasePath),
    data
  });

export const updateMeasurementGroupDefinition = (
  data: MeasurementGroupDefinition
): Promise<ApiRequestResponse<MeasurementGroupDefinition>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${measurementGroupDefinitionBasePath}/${data.id}`),
    data
  });
