import {
  FC,
  Form,
  FormErrorData,
  FormValidator,
  useI18n
} from "@laba/react-common";
import React, { useCallback, useMemo, useState } from "react";
import { DefaultButton, Popup } from "@laba/nexup-components";
import { tkCP } from "translation/i18n";
import { useAppDispatch } from "store/store";
import { isEmpty } from "lodash-es";
import {
  organizationOptions,
  SantaCatalinaPayrollFormContent
} from "components/pages/Dashboard/SantaCatalinaPayrollInput/SantaCatalinaPayrollFormContent";
import { onSantaCatalinaPayrollImport } from "store/dashboard/event";
import { Duration, getCurrentDateTime } from "@laba/ts-common";
import { SantaCatalinaPayrollRequestBody } from "@laba/nexup-api";

export interface SantaCatalinaPayrollInputProps {
  onImportFinished?: (success: boolean) => void;
}

const tk = tkCP.adminPage.dashboard.santaCatalinaPayroll;
const formId = "SantaCatalinaPayrollInputForm";
const formValidation: FormValidator<SantaCatalinaPayrollRequestBody> = (
  t,
  formData
) => {
  const errors: FormErrorData<SantaCatalinaPayrollRequestBody> = {};

  if (
    formData.startYear == null ||
    Number(formData.startYear) < 2020 ||
    Number(formData.startYear) > 2050
  ) {
    errors.startYear = t(tk.validation.invalidField);
  }
  if (
    formData.endYear == null ||
    Number(formData.endYear) < 2000 ||
    Number(formData.endYear) > 2100
  ) {
    errors.endYear = t(tk.validation.invalidField);
  }
  if (
    formData.startMonth == null ||
    Number(formData.startMonth) < 1 ||
    Number(formData.startMonth) > 12
  ) {
    errors.startMonth = t(tk.validation.invalidField);
  }
  if (
    formData.endMonth == null ||
    Number(formData.endMonth) < 1 ||
    Number(formData.endMonth) > 12
  ) {
    errors.endMonth = t(tk.validation.invalidField);
  }

  return isEmpty(errors) ? undefined : errors;
};
export const SantaCatalinaPayrollInput: FC<SantaCatalinaPayrollInputProps> = ({
  onImportFinished
}) => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const [showSantaCatalinaPayroll, setShowSantaCatalinaPayroll] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const initialValues = useMemo<SantaCatalinaPayrollRequestBody>(() => {
    const currentDate = getCurrentDateTime().minus(
      Duration.fromObject({ month: 1 })
    );
    return {
      startYear: String(currentDate.year),
      endYear: String(currentDate.year),
      startMonth: String(currentDate.month),
      endMonth: String(currentDate.month),
      organizationIdList: organizationOptions
    };
  }, []);
  const onSubmit = useCallback(
    async (requestData: SantaCatalinaPayrollRequestBody) => {
      setLoading(true);
      const result = await dispatch(onSantaCatalinaPayrollImport(requestData));
      setLoading(false);
      setShowSantaCatalinaPayroll(false);
      onImportFinished?.(result);
    },
    [setLoading, setShowSantaCatalinaPayroll, onImportFinished, dispatch]
  );
  const validate = useCallback(
    (data: SantaCatalinaPayrollRequestBody) => formValidation(t, data),
    [t]
  );
  const onClick = useCallback(
    () => setShowSantaCatalinaPayroll(true),
    [setShowSantaCatalinaPayroll]
  );
  const onClose = useCallback(
    () => setShowSantaCatalinaPayroll(false),
    [setShowSantaCatalinaPayroll]
  );
  return (
    <>
      <DefaultButton
        title={t(tk.santaCatalinaPayrollButton)}
        text={t(tk.santaCatalinaPayrollButton)}
        onClick={onClick}
      />
      {showSantaCatalinaPayroll && (
        <Popup
          open={showSantaCatalinaPayroll}
          closableHeader
          headerTitle={t(tk.title)}
          primaryButtonText={t(tk.sendImport)}
          disablePrimaryButton={false}
          formId={formId}
          loading={loading}
          onClose={onClose}
          disableBackdropClick
        >
          <Form<SantaCatalinaPayrollRequestBody>
            id={formId}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
          >
            <SantaCatalinaPayrollFormContent />
          </Form>
        </Popup>
      )}
    </>
  );
};
