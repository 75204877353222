import { FC, TypeVariant } from "@laba/react-common";
import React from "react";
import { ProblemItem } from "components/inputs/ProblemInput/ProblemItem/ProblemItem";
import { ProblemConfig } from "components/inputs/ProblemInput/ProblemInput";
import { useProblemHistoryStyles } from "components/inputs/ProblemInput/ProblemCreationPopup/ProblemHistory/UseProblemHistoryStyles";

export interface ProblemHistoryProps {
  problems: ProblemConfig[];
  title: string;
  tagText: string;
  onClickTag: (problem: ProblemConfig) => void;
}

export const ProblemHistory: FC<ProblemHistoryProps> = ({
  problems,
  title,
  tagText,
  onClickTag
}) => {
  const classes = useProblemHistoryStyles();
  return (
    <div className={classes.root}>
      <p className={classes.title}>{title}</p>
      {problems.map(p => {
        return (
          <ProblemItem
            key={p.name}
            text={p.name}
            status={p.status}
            tagText={tagText}
            onClickTag={() => onClickTag(p)}
            tagType={TypeVariant.Outlined}
          />
        );
      })}
    </div>
  );
};
