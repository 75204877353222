import { Theme } from "model/theme";
import { borderMixinRadiusAll, createStyle, pxToRem } from "@laba/react-common";

export const useLinearProgressStyle = createStyle(
  (_theme: Theme) => ({
    root: {
      ...borderMixinRadiusAll(1),
      height: pxToRem(2)
    },
    bar: {
      ...borderMixinRadiusAll(1)
    }
  }),
  "LinearProgress"
);
