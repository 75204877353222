import { Optional } from "./types";

export const isSomeEnum =
  <T>(e: T) =>
  (token: unknown): token is T[keyof T] =>
    Object.values(e).includes(token as T[keyof T]);

export const getEnumOrUndefined =
  <T>(e: T) =>
  (token: unknown): Optional<T[keyof T]> =>
    isSomeEnum(e)(token) ? token : undefined;
