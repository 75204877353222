import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  AppointmentDefinitionKey,
  AppointmentDefinitionListQueryParamsKey,
  OrganizationCombinedType,
  OrganizationKey,
  OrganizationListQueryParamsKey,
  PublicationStatus,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { TextField } from "components/generic/TextField/TextField";
import { ReferenceResourceField } from "components/generic/ReferenceResourceField/ReferenceResourceField";
import { List } from "components/generic/List/List";
import { Datagrid } from "components/generic/Datagrid/Datagrid";
import { appointmentDefinitionJsonToModel } from "components/pages/NexupAdmin/resources/appointmentDefinition/AppointmentDefinitionForm";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useListExportedFileName } from "components/hook/useExportedFileName";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

const tk = tkCP.adminPage[ResourceType.AppointmentDefinition];

export const AppointmentDefinitionList: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const exportedFileName = useListExportedFileName(t(tk.title));
  return (
    <List
      resource={ResourceType.AppointmentDefinition}
      exportedFileName={exportedFileName}
      jsonToModel={appointmentDefinitionJsonToModel}
      extraFilters={[
        workspaceOrganizationId === undefined ? (
          <OrganizationReferenceInput
            label={t(tk.filters.organization)}
            source={AppointmentDefinitionListQueryParamsKey.organization}
            alwaysOn
            type={OrganizationCombinedType.ProviderAndGroup}
            extraFilters={{
              [OrganizationListQueryParamsKey.active]: true
            }}
          />
        ) : undefined,
        <EnumSelectorInput
          source={AppointmentDefinitionListQueryParamsKey.status}
          label={t(tk.filters.status)}
          enumValue={PublicationStatus}
          alwaysOn
          resettable
        />
      ]}
    >
      <Datagrid>
        <TextField
          source={AppointmentDefinitionKey.id}
          label={t(tk.fields.id)}
        />
        <TextField
          source={AppointmentDefinitionKey.name}
          label={t(tk.fields.name)}
        />
        <ReferenceResourceField
          resourceType={ResourceType.Organization}
          label={t(tk.fields.organization)}
          source={AppointmentDefinitionKey.organization}
          optionTextKey={OrganizationKey.name}
        />
        <TextField
          source={AppointmentDefinitionKey.status}
          label={t(tk.fields.status)}
        />
      </Datagrid>
    </List>
  );
};
