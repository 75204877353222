import { Day } from "model/primitives/time/time";
import {
  ApiDate,
  ApiHour,
  areMidnightHoursAndEquals,
  dateInputToDateTime,
  DateTime,
  dateTimeIsBetweenApiHours,
  EndDateMode,
  getCurrentDateTime,
  getKeyObj,
  setDateTimeAsEndDate,
  setDateToMidnight,
  toApiDate
} from "@laba/ts-common";
import { dayToIsoWeekday } from "model/primitives/time/utils";
import { createHydratedMock } from "ts-auto-mock";

export interface BaseAvailableTime {
  allDay?: boolean;
  startTime?: ApiHour;
  endTime?: ApiHour;
}

export interface AvailableTime extends BaseAvailableTime {
  daysOfWeek?: Day[];
}

export interface Period {
  start?: ApiDate;
  end?: ApiDate;
}

export interface NotAvailableTime extends BaseAvailableTime {
  description?: string;
  during: Period;
}

export const AvailableTimeKey = getKeyObj<AvailableTime>(
  createHydratedMock<AvailableTime>()
);

export const NotAvailableTimeKey = getKeyObj<NotAvailableTime>(
  createHydratedMock<NotAvailableTime>()
);

export const createBaseAvailableTime = (
  startHour?: ApiHour,
  endHour?: ApiHour
): AvailableTime => {
  return {
    daysOfWeek: [],
    endTime: endHour,
    startTime: startHour,
    allDay: true
  };
};

const defaultPeriod = {
  start: toApiDate(setDateToMidnight(getCurrentDateTime())),
  end: toApiDate(setDateTimeAsEndDate(getCurrentDateTime(), EndDateMode.EndDay))
};

export const createBaseNotAvailableTime = (
  during: Period = defaultPeriod,
  allDay = true
): NotAvailableTime => {
  return {
    during,
    allDay
  };
};

export const currentTimeIsAvailable = (
  currentDateTime: DateTime,
  time?: AvailableTime
): boolean => {
  if (
    time?.daysOfWeek?.some(
      day => dayToIsoWeekday[day] === currentDateTime.weekday
    ) === true
  ) {
    if (time.allDay) return true;

    if (!time.startTime || !time.endTime) return false;

    return dateTimeIsBetweenApiHours(
      currentDateTime,
      time.startTime,
      time.endTime
    );
  }

  return false;
};

export const handleAllDayStatus = <T extends BaseAvailableTime>(data: T): T => {
  if (
    data.startTime &&
    data.endTime &&
    areMidnightHoursAndEquals(
      dateInputToDateTime(data.startTime),
      dateInputToDateTime(data.endTime)
    )
  )
    return { ...data, startTime: undefined, endTime: undefined, allDay: true };
  return { ...data, allDay: false };
};
