import { ApiRequestResponse } from "request/types";
import { getCommunicationApiUrl, request } from "request/nexupRequest";
import { HttpMethod } from "@laba/ts-common";
import { WhatsappClientSessionData } from "model/whatsapp";

const basePath = "/session";

export const getWhatsappSessionList = (): Promise<
  ApiRequestResponse<WhatsappClientSessionData[]>
> =>
  request({
    url: getCommunicationApiUrl(basePath),
    method: HttpMethod.GET
  });
