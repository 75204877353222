import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";
import { useStepErrorStyles } from "./useStepErrorStyles";

export const StepErrorIcon: React.FC<SvgIconProps> = props => {
  const classes = useStepErrorStyles();
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="59.203"
      height="77.131"
      viewBox="0 0 59.203 77.131"
      {...props}
    >
      <g transform="translate(-1425.898 -415.869)">
        <circle
          cx="23"
          cy="23"
          r="23"
          className={classes.a}
          transform="translate(1432.371 447.33) translate(-.371 -.33)"
        />
        <path
          d="M0 18.778L0 0"
          className={classes.b}
          transform="translate(1455.5 417.369)"
        />
        <path
          d="M7.328 7.328L0 0"
          className={classes.b}
          transform="translate(1428.02 435.231)"
        />
        <path
          d="M0 7.248L7.477 0"
          className={classes.b}
          transform="translate(1475.504 435.231)"
        />
        <g transform="translate(523.642 -109.857)">
          <path
            d="M0 0L15.713 15.713"
            className={classes.c}
            transform="translate(923.501 572.501)"
          />
          <path
            d="M0 0L15.713 15.713"
            className={classes.c}
            transform="rotate(90 183.357 755.858)"
          />
        </g>
      </g>
    </SvgIcon>
  );
};
