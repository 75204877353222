import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  getModelReferenceId,
  KnownCodeSystemSystem,
  MedicalRequestDefinitionKey,
  ModelReference,
  Organization,
  ProcedureReportDefinition,
  ProcedureReportDefinitionListQueryParamsKey,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { NumberInput } from "components/generic/NumberInput/TextInput";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { ColorInput } from "components/generic/ColorInput/ColorInput";
import { ActivityDefinitionCommonInput } from "components/generic/ActivityDefinition/ActivityDefinitionCommonInput/ActivityDefinitionCommonInput";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import { procedureReportDefinitionOptionText } from "components/generic/ReferenceResourceInput/optionText";

export const tk = tkCP.adminPage[ResourceType.MedicalRequestDefinition];
export const MedicalRequestDefinitionMainContent: FC = () => {
  const { t } = useI18n();
  const organization = useWatchResourceForm<ModelReference<Organization>>(
    MedicalRequestDefinitionKey.organization
  );
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organizationId =
    getModelReferenceId(organization) || workspaceOrganizationId;
  const categorySystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicalRequestCategory,
    organizationId
  );

  return (
    <>
      <ActivityDefinitionCommonInput organizationId={organizationId} />
      <CodeSystemSelectorInput
        source={MedicalRequestDefinitionKey.category}
        label={t(tk.fields.category)}
        codeSystem={categorySystem}
        organizationId={organizationId}
        withCreate
        required
      />
      <NumberInput
        source={MedicalRequestDefinitionKey.order}
        label={t(tk.fields.order)}
        min={0}
        step={1}
      />
      <ColorInput
        source={MedicalRequestDefinitionKey.color}
        label={t(tk.fields.color)}
      />
      <ReferenceResourceInput<ProcedureReportDefinition>
        source={MedicalRequestDefinitionKey.procedureReportDefinition}
        label={t(tk.fields.procedureReportDefinition)}
        resourceType={ResourceType.ProcedureReportDefinition}
        optionTextKey={procedureReportDefinitionOptionText}
        extraFilters={{
          [ProcedureReportDefinitionListQueryParamsKey.organization]:
            organizationId
        }}
      />
    </>
  );
};
