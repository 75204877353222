import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  getModelReferenceId,
  KnownCodeSystemSystem,
  MedicationAmountKey,
  MedicationKey,
  ModelReference,
  Organization,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { NumberInput } from "components/generic/NumberInput/TextInput";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";

export const tk = tkCP.adminPage[ResourceType.Medication];
export const MedicationMedicalInformationContent: FC = () => {
  const { t } = useI18n();
  const organization = useWatchResourceForm<ModelReference<Organization>>(
    MedicationKey.organization
  );
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organizationId =
    getModelReferenceId(organization) || workspaceOrganizationId;
  const routeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicationRoute,
    organizationId
  );
  const presentationUnitSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicationPresentationUnit,
    organizationId
  );
  const formSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicationForm,
    organizationId
  );
  const unitSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicationUnit,
    organizationId
  );
  const medicationCharacteristicCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicationCharacteristic,
    organizationId
  );
  return (
    <>
      <ArrayInput
        source={MedicationKey.characteristic}
        label={t(tk.fields.characteristicList)}
      >
        <CodeSystemSelectorInput
          source=""
          label={t(tk.fields.characteristicListElement)}
          codeSystem={medicationCharacteristicCodeSystem}
          organizationId={organizationId}
          required
          withCreate
        />
      </ArrayInput>
      <ArrayInput
        source={MedicationKey.routeList}
        label={t(tk.fields.routeList)}
      >
        <CodeSystemSelectorInput
          source=""
          label={t(tk.fields.routeListElement)}
          codeSystem={routeSystem}
          organizationId={organizationId}
          required
          withCreate
        />
      </ArrayInput>
      <CodeSystemSelectorInput
        source={MedicationKey.form}
        label={t(tk.fields.form)}
        codeSystem={formSystem}
        organizationId={organizationId}
        withCreate
      />
      <CodeSystemSelectorInput
        source={MedicationKey.presentationUnit}
        label={t(tk.fields.presentationUnit)}
        codeSystem={presentationUnitSystem}
        organizationId={organizationId}
        withCreate
      />
      <NumberInput
        source={MedicationKey.presentationUnitAmount.value}
        label={t(tk.fields.presentationUnitAmountValue)}
        min={0}
      />
      <CodeSystemSelectorInput
        source={MedicationKey.presentationUnitAmount.unit}
        label={t(tk.fields.presentationUnitAmountUnit)}
        codeSystem={unitSystem}
        organizationId={organizationId}
        withCreate
      />
      <NumberInput
        source={MedicationKey.presentationUnitFractions}
        label={t(tk.fields.presentationUnitFractions)}
        min={0}
        step={1}
      />
      <ArrayInput
        source={MedicationKey.healthcareUnits}
        label={t(tk.fields.healthcareUnits)}
      >
        <NumberInput
          source={MedicationAmountKey.value}
          label={t(tk.fields.healthcareUnitValue)}
          min={0}
        />
        <CodeSystemSelectorInput
          source={MedicationAmountKey.unit}
          label={t(tk.fields.healthcareUnitUnit)}
          codeSystem={unitSystem}
          organizationId={organizationId}
          required
          withCreate
        />
        <BooleanInput
          source={MedicationAmountKey.disabled}
          label={t(tk.fields.disabled)}
        />
      </ArrayInput>
    </>
  );
};
