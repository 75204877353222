import { Noop, Optional } from "@laba/ts-common";
import { useField as useFinalFormField } from "react-final-form";
import { useFormState } from "forms/useFormState";
import { get } from "lodash-es";
import { CustomFieldRenderProps, FieldValidator } from "forms/types";
import { VirtualFormField } from "forms/Form";
import { useRecipeFunction, ValueOrRecipeFunction } from "./useRecipeFunction";

export type InitialValueOrFunction<V> = V | ((value?: V) => Optional<V>);
const getInitialValue = <V>(
  initialValue?: InitialValueOrFunction<V>,
  formFieldInitialValue?: V
) => {
  if (initialValue instanceof Function) {
    return initialValue(formFieldInitialValue);
  }
  return initialValue;
};
export const useField = <V, E = unknown, FormData = object>(
  fieldName: string,
  validate?: FieldValidator<Optional<V>, E, FormData>,
  beforeSubmit?: Noop,
  initialValue?: InitialValueOrFunction<V>
): CustomFieldRenderProps<Optional<V>, E> => {
  const { initialValues: formInitialValues } = useFormState();
  const fieldInitialValues = get(formInitialValues, fieldName);
  const {
    input: { value, onChange, ...inputProps },
    meta,
    ...otherProps
  } = useFinalFormField<Optional<V>>(fieldName, {
    validate: validate as unknown as FieldValidator<Optional<V>>,
    beforeSubmit,
    initialValue: getInitialValue(initialValue, fieldInitialValues)
  });

  const recipeFunction = useRecipeFunction(value, onChange);

  // Final form treats empty string and undefined as the same value.
  // We convert empty string to undefined to avoid having to check
  // for both cases in client code.
  const castedValue =
    (value as unknown as Optional<V> | "") === "" ? undefined : value;
  return {
    input: {
      value: castedValue as unknown as Optional<V>,
      onChange: recipeFunction as unknown as ValueOrRecipeFunction<Optional<V>>,
      ...inputProps
    },
    meta: {
      ...meta,
      error: (meta.error as E) || (meta.submitError as E),
      validationError: meta.error as E,
      submitError: meta.submitError as E
    },
    ...otherProps
  };
};

export const useOptionalField = <V, E = unknown, FormData = object>(
  fieldName?: string,
  validate?: FieldValidator<Optional<V>, E, FormData>,
  beforeSubmit?: Noop,
  initialValue?: InitialValueOrFunction<V>
): CustomFieldRenderProps<Optional<V>, E> => {
  return useField(
    fieldName ?? VirtualFormField,
    validate,
    beforeSubmit,
    initialValue
  );
};
