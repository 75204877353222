import {
  ApiPageRequestResponse,
  ApiRequestFileResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { ApiDate, HttpMethod, ModelId } from "@laba/ts-common";
import { Code } from "model/resource/entities/codeSystem";
import { Invoice, InvoiceStatus } from "model/resource/finance/invoice/invoice";
import { FinanceStatistics } from "model/resource/finance/common";
import { ConsumptionGroupWithExtraData } from "model/resource";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { Patient } from "model/resource/person/patient/patient";
import { urlJoin } from "url-join-ts";
import { downloadFileRequest } from "api/file";

const InvoicePath = "/invoice";
const ConsumptionPath = "/consumption";
const RemovePath = "/remove";
const AddPath = "/add";
const AddAllPath = "/add-all";
const SendPatientFilePath = "/send-patient-file";
const CreatePreInvoiceFilePath = "/create-pre-invoice-file";

export interface InvoiceQueryParams {
  withPractitioner?: boolean;
  withOrganization?: boolean;
  withLocation?: boolean;
  withAccount?: boolean;
  withPatient?: boolean;
  withPayer?: boolean;
}

export interface InvoiceListQueryParams
  extends InvoiceQueryParams,
    ListQueryParams {
  account?: ModelId[];
  endDate?: ApiDate;
  location?: ModelId[];
  organization?: ModelId;
  plan?: Code[];
  startDate?: ApiDate;
  status?: InvoiceStatus[];
  statusReason?: Code[];
}

export interface InvoiceRelatedConsumptionWithExtraDataListQueryParams
  extends ListQueryParams {
  endDate?: ApiDate;
  patient?: ModelReference<Patient>;
  startDate?: ApiDate;
}

export interface InvoiceStatisticsQueryParams {
  organization?: ModelId;
  account?: ModelId;
  invoiceEndDate?: ApiDate;
  invoiceStartDate?: ApiDate;
  totalEndDate?: ApiDate;
  totalStartDate?: ApiDate;
}

export const getInvoiceStatistics = (
  params?: InvoiceStatisticsQueryParams
): Promise<ApiRequestResponse<FinanceStatistics>> => {
  return request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${InvoicePath}/statistics`),
    params
  });
};

export const getInvoice = (
  id: ModelId,
  params?: InvoiceQueryParams
): Promise<ApiRequestResponse<Invoice>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${InvoicePath}/${id}`),
    params
  });

export const getInvoiceList = (
  params?: InvoiceListQueryParams
): Promise<ApiPageRequestResponse<Invoice>> => {
  return request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${InvoicePath}`),
    params
  });
};

export const createInvoice = (
  data: Invoice
): Promise<ApiRequestResponse<Invoice>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(`${InvoicePath}`),
    data
  });

export const editInvoice = (
  data: Invoice
): Promise<ApiRequestResponse<Invoice>> => {
  return request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${InvoicePath}/${data.id}`),
    data
  });
};

export const cancelInvoice = (
  invoiceId: ModelId
): Promise<ApiRequestResponse<Invoice>> => {
  return request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(urlJoin(InvoicePath, invoiceId, "cancel"))
  });
};

export const getInvoiceRelatedConsumptionWithExtraDataList = (
  invoiceId: ModelId,
  params: InvoiceRelatedConsumptionWithExtraDataListQueryParams
): Promise<ApiPageRequestResponse<ConsumptionGroupWithExtraData>> => {
  return request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(urlJoin(InvoicePath, invoiceId, ConsumptionPath)),
    params
  });
};

export const removeConsumptionsFromInvoice = (
  invoiceId: ModelId,
  entries: ModelId[]
): Promise<ApiRequestResponse<Invoice>> => {
  return request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(
      urlJoin(InvoicePath, invoiceId, ConsumptionPath, RemovePath)
    ),
    data: { entries }
  });
};

export const addAllConsumptionsToInvoice = (
  invoiceId: ModelId,
  patient?: ModelId[],
  startDate?: ApiDate,
  endDate?: ApiDate
): Promise<ApiRequestResponse<Invoice>> => {
  return request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(
      urlJoin(InvoicePath, invoiceId, ConsumptionPath, AddAllPath)
    ),
    data: { patient, startDate, endDate }
  });
};

export const addConsumptionsToInvoice = (
  invoiceId: ModelId,
  entries: ModelId[]
): Promise<ApiRequestResponse<Invoice>> => {
  return request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(
      urlJoin(InvoicePath, invoiceId, ConsumptionPath, AddPath)
    ),
    data: { entries }
  });
};

export interface SendPatientInvoiceFileProps {
  invoiceId: ModelId;
  mailReceivers?: string[];
  phoneReceivers?: string[];
}

export const sendPatientInvoiceFile = ({
  invoiceId,
  mailReceivers,
  phoneReceivers
}: SendPatientInvoiceFileProps): Promise<ApiRequestResponse<undefined>> => {
  return request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(urlJoin(InvoicePath, invoiceId, SendPatientFilePath)),
    data: { mailReceivers, phoneReceivers, invoiceId }
  });
};

export const getPreInvoiceFile = (
  invoiceId: ModelId
): Promise<ApiRequestFileResponse> => {
  return downloadFileRequest(
    getFrontApiUrl(urlJoin(InvoicePath, invoiceId, CreatePreInvoiceFilePath))
  );
};
