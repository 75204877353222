import { useContext, createContext, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AppAction,
  BaseEvent,
  CaseReducerActions,
  SliceCaseReducers,
  ThunkAction,
  ThunkDispatch
} from "store/types";
import { sliceSelector } from "store/utils";
import { Optional } from "@laba/ts-common";
import {
  SliceEvent,
  GenericSliceType,
  GetUseSliceDispatchType,
  SliceContextType,
  SliceSelectorType,
  GetSlicerType
} from "./types";
import { SliceState } from "../../state";

const useGetContextValue = <
  S extends SliceState,
  A extends SliceCaseReducers<S>
>(
  context: SliceContextType<S, A>
): GenericSliceType<S, A> => {
  const contextValue = useContext(context);
  if (contextValue === undefined) {
    // we know contextValue isn't undefined at runtime if this return hook is called inside a provider
    throw new Error(
      "dont call this hook without a wrapped GenericSliceProvider "
    );
  }
  return contextValue;
};

const useGetSliceActions = <
  S extends SliceState,
  A extends SliceCaseReducers<S>
>(
  context: SliceContextType<S, A>
): CaseReducerActions<A> => {
  return useGetContextValue(context).actions;
};

const sliceContextCreatorGetter = <
  S extends SliceState,
  A extends SliceCaseReducers<S>
>(): SliceContextType<S, A> => {
  return createContext<Optional<GenericSliceType<S, A>>>(undefined);
};

const useGetSlicer = <
  S extends SliceState,
  A extends SliceCaseReducers<S>,
  RootState
>(
  context: SliceContextType<S, A>
): GetSlicerType<S, RootState> => {
  const sliceFromContext = useGetContextValue(context);
  return useCallback(
    (state: RootState) => sliceSelector(sliceFromContext)(state),
    [sliceFromContext]
  );
};

const getUseSliceSelector = <
  S extends SliceState,
  A extends SliceCaseReducers<S>,
  RootState
>(
  context: SliceContextType<S, A>
): SliceSelectorType<S, RootState> => {
  return selectorFn => {
    const slicer = useGetSlicer(context);
    return useSelector((state: RootState) => {
      const slicedState = slicer(state);
      return selectorFn(slicedState, state);
    });
  };
};

const sliceEventToEventMapper = <
  S extends SliceState,
  A extends SliceCaseReducers<S>,
  RootState,
  AppDispatch extends ThunkDispatch<RootState>,
  R
>(
  sliceEvent: SliceEvent<S, A, RootState, AppDispatch, R>,
  sliceActionsGetter: CaseReducerActions<A>,
  slicer: (s: RootState) => S
): BaseEvent<R, RootState, AppDispatch> => {
  return async (dispatch, getState): Promise<R> => {
    const sliceDispatch = <OtherR>(
      innerSliceEvent: SliceEvent<S, A, RootState, AppDispatch, OtherR>
    ): Promise<OtherR> => {
      const transformedEvent = sliceEventToEventMapper<
        S,
        A,
        RootState,
        AppDispatch,
        OtherR
      >(innerSliceEvent, sliceActionsGetter, slicer);

      /* Because we changed the BaseEvent so that instead of a ThunkAction, it becomes a function defined by us
       * where we can specify the type of useDispatch, TypeScript cannot infer the type,
       * and we have to cast it.
       */
      return dispatch(
        transformedEvent as unknown as ThunkAction<
          Promise<OtherR>,
          RootState,
          void,
          AppAction
        >
      );
    };
    const getSliceState = () => slicer(getState());
    return sliceEvent({
      dispatch,
      sliceDispatch,
      getState,
      getSliceState,
      sliceActions: sliceActionsGetter,
      slicer
    });
  };
};

export const getUseSliceDispatch = <
  S extends SliceState,
  A extends SliceCaseReducers<S>,
  RootState,
  AppDispatch extends ThunkDispatch<RootState>
>(
  context: SliceContextType<S, A>
): GetUseSliceDispatchType<S, A, RootState, AppDispatch> => {
  return () => {
    const appDispatch: AppDispatch = useDispatch<AppDispatch>();
    const actions = useGetSliceActions<S, A>(context);
    const slicer = useGetSlicer<S, A, RootState>(context);

    return useCallback(
      async <R>(
        sliceEvent: SliceEvent<S, A, RootState, AppDispatch, R>
      ): Promise<R> => {
        const innerEvent: BaseEvent<R, RootState, AppDispatch> =
          sliceEventToEventMapper<S, A, RootState, AppDispatch, R>(
            sliceEvent,
            actions,
            slicer
          );

        /* Because we changed the BaseEvent so that instead of a ThunkAction, it becomes a function defined by us
         * where we can specify the type of useDispatch, TypeScript cannot infer the type,
         * and we have to cast it.
         */

        return appDispatch(
          innerEvent as unknown as ThunkAction<
            Promise<R>,
            RootState,
            void,
            AppAction
          >
        );
      },
      [actions, appDispatch, slicer]
    );
  };
};

export interface UseCommonStateInitProviderProps<
  S extends SliceState,
  A extends SliceCaseReducers<S>,
  RootState,
  AppDispatch extends ThunkDispatch<RootState>
> {
  context: SliceContextType<S, A>;
  useSliceSelector: SliceSelectorType<S, RootState>;
  useSliceDispatch: GetUseSliceDispatchType<S, A, RootState, AppDispatch>;
}

export const initGenericSliceProvider = <
  S extends SliceState,
  A extends SliceCaseReducers<S>,
  RootState,
  AppDispatch extends ThunkDispatch<RootState>
>(): UseCommonStateInitProviderProps<S, A, RootState, AppDispatch> => {
  const context = sliceContextCreatorGetter<S, A>();
  const useSliceSelector = getUseSliceSelector<S, A, RootState>(context);
  const useSliceDispatch = getUseSliceDispatch<S, A, RootState, AppDispatch>(
    context
  );

  /*
   * Due to a strange error caused by the IDE's inability to infer the correct types,
   * it is necessary to explicitly specify the typing when consuming this hook.
   */
  return {
    context,
    useSliceSelector,
    useSliceDispatch
  };
};
