export const appointmentDefinition = {
  title: "Tipos de turno",
  filters: {
    organization: "Organizacion",
    status: "Estado"
  },
  fields: {
    id: "Id",
    organization: "Organizacion",
    name: "Nombre",
    type: "Nombre Programatico",
    status: "Estado",
    code: "Código",
    slotConfigMinDuration: "Duracion minima turno",
    slotConfigDurationList: "Lista de duraciones",
    slotConfigDurationListElement: "Duracion",
    consultationReasonSystem: "Sistema de razones de consulta",
    availableServiceList: "Lista de servicios",
    availableServiceListElement: "Servicio",
    notificationChannelList: "Canales de notificacion habilitados",
    notificationChannelListElement: "Canal",
    templateList: "Listado de Formatos",
    templateListCode: "Codigo",
    templateListTemplate: "Formato",

    emailConfig: "Emails",
    phoneConfig: "Whatsapp"
  },
  tabs: {
    appointmentDefinition: "Tipo de turno",
    emailConfig: "Emails",
    phoneConfig: "Whatsapp"
  }
};
