import React from "react";
import { FC, useI18n } from "@laba/react-common";
import { PractitionerKey, ResourceType, User } from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import { UserResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { userOptionText } from "components/generic/ReferenceResourceInput/optionText";
import { UserMainContent } from "components/pages/NexupAdmin/resources/user/UserMainContent";

export const tk = tkCP.adminPage[ResourceType.Practitioner];
export const PractitionerUserTabContent: FC = () => {
  const { t } = useI18n();
  return (
    <ReferenceResourceInput<User>
      source={PractitionerKey.user}
      label={t(tk.fields.user)}
      resourceType={UserResourceType}
      optionTextKey={userOptionText}
      defaultCreateValue={filter => ({
        username: filter
      })}
      createPopupTitle={t(tk.fields.user)}
      createInputList={[<UserMainContent />]}
    />
  );
};
