import { ApiRequestResponse } from "request/types";
import { AppMetadata } from "model/resource/app/appMetadata";
import { HttpMethod } from "@laba/ts-common";
import { getAppBaseUrl, requestAnon } from "request/nexupRequest";

export const getPatientPortalAppMetadata = async (): Promise<
  ApiRequestResponse<AppMetadata>
> => {
  return requestAnon({
    method: HttpMethod.GET,
    url: getAppBaseUrl("metadata")
  });
};
