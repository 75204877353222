import { ApiRequestResponse } from "request/types";
import { getCommunicationApiUrl, request } from "request/nexupRequest";
import { HttpMethod } from "@laba/ts-common";
import { urlJoin } from "url-join-ts";

const basePath = "/queue";

export const runNotificationProcessQueue = (): Promise<
  ApiRequestResponse<void>
> =>
  request({
    url: urlJoin(getCommunicationApiUrl(basePath), "/notification/run"),
    method: HttpMethod.POST
  });

export const runWhatsappNotificationProcessQueue = (): Promise<
  ApiRequestResponse<void>
> =>
  request({
    url: urlJoin(
      getCommunicationApiUrl(basePath),
      "/whatsapp-notification/run"
    ),
    method: HttpMethod.POST
  });
