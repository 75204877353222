import { FC, getClassName, TextVariant } from "@laba/react-common";
import React, { useEffect, useState } from "react";
import { head, isEmpty, isNaN, size } from "lodash-es";
import {
  OptionsConfig,
  SelectInput
} from "components/inputs/SelectInput/SelectInput";
import { Noop } from "@laba/ts-common";
import { UnitInputStyle } from "components/inputs/UnitInput/UnitInputStyle";
import { InputType, TextInput } from "components/inputs/TextInput/TextInput";
import { TypeVariant } from "model/themeVariant";
import {
  numberValueToString,
  sanitizeStringNumberWithDecimals,
  stringNumberToNumber
} from "utils";

export enum UnitAutocompleteType {
  Never = "Never",
  Always = "Always",
  Single = "Single",
  WithoutOptsOrSingle = "WithoutOptsOrSingle"
}

type UnitInputTypeVariant = TypeVariant.Outlined | TypeVariant.Contained;

export interface UnitInputProps {
  className?: string;
  inputsWrapperClassName?: string;
  unitValue?: string;
  quantityValue?: number;
  onChangeUnit?: (newValue?: string) => void;
  onChangeQuantity?: (newValue?: number) => void;
  placeholder?: string;
  onBlur?: Noop;
  onBlurUnit?: Noop;
  unitOptions?: OptionsConfig<string>[];
  maxValue?: number;
  minValue?: number;
  forcedMaxMin?: boolean;
  step?: number;
  errorText?: string;
  helperText?: string;
  showError?: boolean;
  showHelperOrErrorText?: boolean;
  fullWidth?: boolean;
  withUnitSelector?: boolean;
  title?: string;
  titleTextVariant?: TextVariant;
  autocompleteDisabledType?: UnitAutocompleteType;
  autocompleteUnitSelectedType?: UnitAutocompleteType;
  variant?: UnitInputTypeVariant;
  label?: string;
  disabled?: boolean;
  getUnitOptionFromValue?: (value: string) => OptionsConfig<string>;
  disableAllOutlinesQuantityInput?: boolean;
  showLeftBorder?: boolean;
  showFocusBorder?: boolean;
  decimalDigits?: number;
  isUnitEditable?: boolean;
}

const getUnit = (
  autocompleteType: UnitAutocompleteType,
  optionsConfig: OptionsConfig<string>[]
) => {
  switch (autocompleteType) {
    case UnitAutocompleteType.WithoutOptsOrSingle:
    case UnitAutocompleteType.Never:
      return undefined;
    case UnitAutocompleteType.Always:
      return head(optionsConfig)?.value;
    case UnitAutocompleteType.Single:
      return size(optionsConfig) === 1 ? head(optionsConfig)?.value : undefined;
  }
};

const getSingleUnitDisabled = (
  optionsConfig: OptionsConfig<string>[],
  unitValue?: string
) => {
  return (
    size(optionsConfig) === 1 &&
    unitValue !== undefined &&
    optionsConfig.map(v => v.value).includes(unitValue)
  );
};

const getUnitDisabled = (
  autocompleteType: UnitAutocompleteType,
  optionsConfig: OptionsConfig<string>[],
  unitValue?: string
) => {
  switch (autocompleteType) {
    case UnitAutocompleteType.Never:
      return false;
    case UnitAutocompleteType.Always:
    case UnitAutocompleteType.Single:
      return getSingleUnitDisabled(optionsConfig, unitValue);
    case UnitAutocompleteType.WithoutOptsOrSingle:
      return (
        size(optionsConfig) === 0 ||
        getSingleUnitDisabled(optionsConfig, unitValue)
      );
  }
};

const numericRegex = /^-?(?:[0-9][0-9.,]{0,15})?$/;

export const UnitInput: FC<UnitInputProps> = ({
  className,
  inputsWrapperClassName,
  unitValue,
  quantityValue,
  onChangeUnit,
  onChangeQuantity,
  placeholder,
  onBlur,
  onBlurUnit,
  unitOptions = [],
  errorText,
  helperText,
  minValue,
  maxValue,
  step,
  title,
  label,
  disableAllOutlinesQuantityInput,
  titleTextVariant = TextVariant.Subtitle2,
  showHelperOrErrorText = true,
  showError = true,
  fullWidth = true,
  withUnitSelector = true,
  autocompleteDisabledType = UnitAutocompleteType.Always,
  autocompleteUnitSelectedType = UnitAutocompleteType.Always,
  variant = TypeVariant.Outlined,
  disabled = false,
  forcedMaxMin = false,
  getUnitOptionFromValue,
  showLeftBorder = true,
  showFocusBorder,
  decimalDigits,
  isUnitEditable = true
}) => {
  const [showableQuantity, setShowableQuantity] = useState(
    numberValueToString(quantityValue)
  );
  const hasError = showError && Boolean(errorText);
  const showableHelperText = (hasError ? errorText : helperText) || "";

  const classes = UnitInputStyle({
    hasError,
    titleTextVariant,
    disabled
  });

  useEffect(() => {
    const numberValue = stringNumberToNumber(showableQuantity);
    if (numberValue !== quantityValue && showableQuantity !== "-") {
      const quantityValueString = numberValueToString(quantityValue);
      setShowableQuantity(quantityValueString);
    }
  }, [quantityValue, showableQuantity, decimalDigits]);

  const internalWrappedOnChange = (value?: string) => {
    if (value === undefined || isEmpty(value)) {
      setShowableQuantity(undefined);
      return onChangeQuantity?.(undefined);
    }
    if (!numericRegex.test(value)) return;

    if (value.startsWith("-") && size(value) === 1) {
      if (minValue !== undefined && minValue >= 0) return;
      setShowableQuantity(value);
      return onChangeQuantity?.(undefined);
    }
    const sanitizedStringNumber = sanitizeStringNumberWithDecimals(
      value,
      decimalDigits
    );
    const numberValue = stringNumberToNumber(sanitizedStringNumber);

    if (
      forcedMaxMin &&
      numberValue !== undefined &&
      ((maxValue && numberValue > maxValue) ||
        (minValue && numberValue < minValue))
    )
      return;

    if (isNaN(numberValue)) {
      setShowableQuantity(undefined);
      return onChangeQuantity?.(undefined);
    }
    onChangeQuantity?.(numberValue);
    setShowableQuantity(sanitizedStringNumber);
  };

  const withUnit = withUnitSelector && !isEmpty(unitOptions);

  useEffect(() => {
    if (unitValue === undefined) {
      const unit = getUnit(autocompleteUnitSelectedType, unitOptions);
      unit && onChangeUnit?.(unit);
    }
  }, [unitValue, onChangeUnit, unitOptions, autocompleteUnitSelectedType]);

  return (
    <div
      className={getClassName(
        classes.root,
        className,
        fullWidth ? classes.fullWidth : undefined
      )}
    >
      {title && <p className={classes.title}>{title}</p>}
      <div
        className={getClassName(classes.inputsWrapper, inputsWrapperClassName)}
      >
        <TextInput
          value={showableQuantity}
          onChange={internalWrappedOnChange}
          placeholder={placeholder}
          showHelperOrErrorText={false}
          showError={hasError}
          errorText=" "
          onBlur={onBlur}
          type={InputType.Text}
          fullWidth={fullWidth}
          minValue={minValue}
          maxValue={maxValue}
          step={step}
          variant={variant}
          label={label}
          disabled={disabled}
          disableAllOutlines={disableAllOutlinesQuantityInput}
          showLeftBorder={showLeftBorder}
          showFocusBorder={showFocusBorder}
          className={classes.inputItem}
        />
        {withUnit && isUnitEditable && (
          <SelectInput
            value={unitValue}
            onChange={onChangeUnit}
            options={unitOptions}
            onBlur={onBlurUnit ?? onBlur}
            showHelperOrErrorText={false}
            errorText=" "
            showError={hasError}
            type={variant}
            fullWidth={fullWidth}
            disabled={
              disabled ||
              getUnitDisabled(autocompleteDisabledType, unitOptions, unitValue)
            }
            getOptionFromValue={getUnitOptionFromValue}
            className={classes.inputItem}
          />
        )}
        {withUnitSelector && !isUnitEditable && (
          <p className={classes.unitText} title={unitValue}>
            {unitValue}
          </p>
        )}
      </div>
      {showHelperOrErrorText && (
        <p className={classes.errorText}> {showableHelperText} </p>
      )}
    </div>
  );
};
