import {
  deepMapObject,
  GenericObject,
  isArray,
  isObject
} from "@laba/ts-common";
import { split } from "lodash-es";

export const getTk = <T extends GenericObject>(resource: T): T => {
  return deepMapObject(resource, (value, path) => {
    if (isObject(value) || isArray(value)) return value;
    return split(path, "_", 1)[0];
  });
};
