import { ReactElement, useI18n } from "@laba/react-common";
import {
  Datagrid,
  ReferenceManyField,
  SimpleForm,
  SortPayload,
  useRefresh
} from "react-admin";
import React, { useState } from "react";
import { Button, Dialog, DialogTitle, Grid } from "@mui/material";
import { Add } from "@laba/nexup-components";
import { tkCC } from "translation/i18n";
import { Create, ResourceTransform } from "components/generic/Create/Create";
import { Model, ModelId } from "@laba/nexup-api";
import { DeepPartial, Optional } from "@laba/ts-common";
import { Edit } from "components/generic/Edit/Edit";
import { ResourceFormToolbar } from "components/generic/ResourceForm/ResourceFormToolbar";
import { useReferenceManyResourceInputStyle } from "components/generic/ReferenceManyResourceInput/referenceManyResourceInputStyle";
import { RaResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";

export interface ReferenceManyResourceInputProps<T extends Model> {
  resourceType: RaResourceType;
  resourceSource: string;
  label: string;
  listFieldList: ReactElement[];
  editInputList: ReactElement[];
  extraFilters?: Record<string, string>;
  sort?: SortPayload;
  resourceTransformer?: ResourceTransform<T>;
  defaultCreateValue?: DeepPartial<T>;
  createEditPopupTitle?: string;
  canCreate?: boolean;
}

const tk = tkCC.inputs.referenceManyResourceInput;
export const ReferenceManyResourceInput = <T extends Model>({
  resourceSource,
  label,
  resourceType,
  listFieldList,
  editInputList,
  extraFilters,
  sort,
  resourceTransformer,
  defaultCreateValue,
  createEditPopupTitle,
  canCreate = true
}: ReferenceManyResourceInputProps<T>): ReactElement => {
  const { t } = useI18n();
  const refresh = useRefresh();
  const classes = useReferenceManyResourceInputStyle();
  const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);
  const [editDialogID, setEditDialogId] =
    useState<Optional<ModelId>>(undefined);
  const showDialog = showCreateDialog || editDialogID !== undefined;
  const CreateEdit = editDialogID ? Edit : Create;
  const closeDialog = () => {
    setShowCreateDialog(false);
    setEditDialogId(undefined);
  };
  return (
    <>
      <Grid container columnSpacing={2}>
        <Grid item sm={12}>
          <ReferenceManyField
            label={label}
            target={resourceSource}
            reference={resourceType}
            filter={extraFilters}
            sort={sort}
            fullWidth
          >
            <Datagrid
              bulkActionButtons={false}
              rowClick={id => {
                setEditDialogId(String(id));
                return "toggleSelection";
              }}
            >
              {listFieldList}
            </Datagrid>
          </ReferenceManyField>
        </Grid>
        {canCreate && (
          <Grid item sm={12}>
            <Button
              onClick={() => setShowCreateDialog(true)}
              startIcon={<Add />}
              className={classes.addButton}
            >
              {t(tk.add)}
            </Button>
          </Grid>
        )}
      </Grid>
      <Dialog open={showDialog} fullWidth onClose={closeDialog}>
        {createEditPopupTitle && (
          <DialogTitle>{createEditPopupTitle}</DialogTitle>
        )}
        <CreateEdit
          redirect={false}
          resourceTransform={resourceTransformer}
          resource={resourceType}
          id={editDialogID}
          actions={false}
          mutationOptions={{
            onSuccess: () => {
              closeDialog();
              refresh();
            }
          }}
        >
          <SimpleForm
            defaultValues={defaultCreateValue}
            toolbar={
              <ResourceFormToolbar onCancelButtonClicked={closeDialog} />
            }
          >
            {editInputList}
          </SimpleForm>
        </CreateEdit>
      </Dialog>
    </>
  );
};
