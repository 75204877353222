import React from "react";
import {
  Children,
  FC,
  getClassName,
  IconC,
  OnBlurEvent,
  PropsWithChildren,
  ReactElement
} from "@laba/react-common";
import { Noop } from "@laba/ts-common";
import { AutocompleteOptionConfig } from "components/autocomplete/Autocomplete/Autocomplete";
import { AddCircleOutlineIcon, SearchIcon } from "components/icons";
import { TypeInvertedVariant } from "components/utils/getGenericButtonColors";
import { ModelAutocomplete } from "components/autocomplete/ModelAutocomplete/ModelAutocomplete";
import { NexupIconButton } from "components/buttons/IconButton/IconButton";
import { AutocompleteOptionProps } from "components/autocomplete/Autocomplete/AutocompleteOption/AutocompleteOption";
import { isEmpty } from "lodash-es";
import { SearchableListModelInputStyle } from "./SearchableListModelInputStyle";
import { SearchableListSelectedOptions } from "../SearchableListInput/SearchableListSelectedOptions/SearchableListSelectedOptions";

export interface SearchableListModelInputProps<T> {
  className?: string;
  isMobile?: boolean;
  selectedOptions?: AutocompleteOptionConfig<T>[];
  onChange?: (option?: T) => void;
  onRemoveSelectedOption?: (option: T) => void;
  onBlur?: OnBlurEvent;
  disabled?: boolean;
  showError?: boolean;
  errorText?: string;
  helperText?: string;
  showHelperOrErrorText?: boolean;
  noOptionsText: string;
  closeText?: string;
  clearText?: string;
  loadingText?: string;
  openText?: string;
  label?: string;
  fullWidth: boolean;
  EndIcon?: IconC;
  endIconShouldNotRotate?: boolean;
  onIconButtonClick?: Noop;
  iconButtonTitle?: string;
  value?: T;
  getValues: (searchText?: string) => Promise<T[]>;
  getOptionFromValue: (value: T) => AutocompleteOptionConfig<T>;
  searchDebounceMs?: number;
  minInputForSearch?: number;
  minCharPromptText?: string;
  excludedValues?: T[];
  children?: Children;
  AutocompleteOptionC?: FC<AutocompleteOptionProps<T>>;
  downloadOptionsIfEmpty?: boolean;
  showCustomOption?: boolean;
  customOptionText?: string;
  onClickCustomClickableOption?: Noop;
  minInputForCustomOption?: number;
  resetOptions?: boolean;
  hasOptionBackgroundColor?: boolean;
  moreCharsText?: string;
}

export const SearchableListModelInput = <T,>({
  isMobile,
  selectedOptions,
  errorText,
  helperText,
  onRemoveSelectedOption,
  noOptionsText,
  onChange,
  disabled,
  onBlur,
  label,
  AutocompleteOptionC,
  showError = false,
  showHelperOrErrorText = true,
  fullWidth = true,
  closeText = "",
  openText = "",
  clearText = "",
  loadingText = "",
  EndIcon = SearchIcon,
  endIconShouldNotRotate = true,
  onIconButtonClick,
  iconButtonTitle,
  getValues,
  value,
  minInputForSearch = 3,
  searchDebounceMs = 500,
  excludedValues,
  minCharPromptText,
  getOptionFromValue,
  children,
  downloadOptionsIfEmpty,
  showCustomOption = false,
  customOptionText,
  onClickCustomClickableOption,
  minInputForCustomOption,
  resetOptions,
  className,
  hasOptionBackgroundColor,
  moreCharsText
}: SearchableListModelInputProps<T>): PropsWithChildren<ReactElement> => {
  const hasError = showError && Boolean(errorText);
  const showableHelperText = (hasError ? errorText : helperText) || "";
  const classes = SearchableListModelInputStyle();
  const showBottomText = showHelperOrErrorText && !isEmpty(showableHelperText);
  return (
    <div className={getClassName(classes.root, className)}>
      <div className={classes.autocompleteAndButtonContainer}>
        <ModelAutocomplete<T>
          clearText={clearText}
          closeText={closeText}
          disabled={disabled}
          EndIcon={EndIcon}
          endIconShouldNotRotate={endIconShouldNotRotate}
          fullWidth={fullWidth}
          getOptionFromValue={getOptionFromValue}
          getValues={getValues}
          label={label}
          loadingText={loadingText}
          minInputForSearch={minInputForSearch}
          noOptionsText={noOptionsText}
          onBlur={onBlur}
          onChange={onChange}
          openText={openText}
          searchDebounceMs={searchDebounceMs}
          value={value}
          errorText={" "}
          showError={showError}
          showHelperOrErrorText={false}
          showSelectedValueOnInput={false}
          excludedValues={excludedValues}
          minCharPromptText={minCharPromptText}
          AutocompleteOptionC={AutocompleteOptionC}
          downloadOptionsIfEmpty={downloadOptionsIfEmpty}
          showCustomOption={showCustomOption}
          customOptionText={customOptionText}
          onClickCustomClickableOption={onClickCustomClickableOption}
          minInputForCustomOption={minInputForCustomOption}
          resetOptions={resetOptions}
          moreCharsText={moreCharsText}
        />
        {onIconButtonClick && (
          <NexupIconButton
            Icon={AddCircleOutlineIcon}
            type={TypeInvertedVariant.Outlined}
            onClick={() => onIconButtonClick()}
            title={iconButtonTitle}
            disabled={disabled}
          />
        )}
      </div>
      {children ||
        (selectedOptions && onRemoveSelectedOption && (
          <SearchableListSelectedOptions
            selectedOptions={selectedOptions}
            onRemoveSelectedOption={onRemoveSelectedOption}
            fullWidth={fullWidth}
            disabled={disabled}
            isMobile={isMobile}
            hasBackgroundColor={hasOptionBackgroundColor}
          />
        ))}
      {showBottomText && (
        <p
          className={getClassName(
            classes.errorText,
            hasError ? classes.errorTextColor : classes.helperTextColor
          )}
        >
          {showableHelperText}
        </p>
      )}
    </div>
  );
};
