import React from "react";
import { FC, getClassName, TypeVariant } from "@laba/react-common";
import { Tag, TagPaddingVariant } from "components/tags/Tag/Tag";
import { usePatientTagsStyles } from "components/headers/PatientHeader/PatientTags/usePatientTagsStyles";
import { TagFlatData } from "components/tags";

export interface PatientTagsProps {
  className?: string;
  isExpanded?: boolean;
  tags?: TagFlatData[];
}

export const PatientTags: FC<PatientTagsProps> = ({
  className,
  isExpanded = false,
  tags
}) => {
  const classes = usePatientTagsStyles();
  const tagContainerSize = isExpanded ? 8 : 4;
  if (tags === undefined) return null;
  const tagsList =
    tags.length > tagContainerSize ? tags.slice(0, tagContainerSize - 1) : tags;
  const overflowedTags =
    tags.length > tagContainerSize
      ? tags
          .slice(-(tags.length - tagsList.length))
          .map(overflowedTag => overflowedTag.display)
      : [];
  return (
    <div className={getClassName(classes.tagsContainer, className)}>
      {tagsList.map((tag, index) => (
        <Tag
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={classes.patientTag}
          text={tag.abbreviation ?? tag.display?.slice(0, 3) ?? tag.code ?? ""}
          type={TypeVariant.Outlined}
          title={tag.display}
          paddingVariant={TagPaddingVariant.Small}
          backgroundColor={tag.color}
        />
      ))}
      {tags.length > tagContainerSize && (
        <Tag
          className={classes.patientTag}
          text={`+${overflowedTags.length}`}
          type={TypeVariant.Outlined}
          title={overflowedTags.join(", ")}
          paddingVariant={TagPaddingVariant.Small}
        />
      )}
    </div>
  );
};
