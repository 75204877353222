import React from "react";
import { Create } from "components/generic/Create/Create";
import { FC } from "@laba/react-common";
import { ConceptResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import {
  ProcedureCodeConceptForm,
  ProcedureCodeConceptJsonToModel,
  procedureCodeConceptTransform
} from "components/pages/NexupAdmin/resources/concept/procedureCodeConceptList/ProcedureCodeConceptForm";

export const ProcedureCodeConceptCreate: FC = () => {
  return (
    <Create
      resourceTransform={procedureCodeConceptTransform}
      resource={ConceptResourceType.ProcedureCodeConcept}
      jsonToModel={ProcedureCodeConceptJsonToModel}
    >
      <ProcedureCodeConceptForm />
    </Create>
  );
};
