import React from "react";
import { FC } from "@laba/react-common";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const BioTech: FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M7 19C5.9 19 5 19.9 5 21H19C19 19.9 18.1 19 17 19H13V17H16C17.1 17 18 16.1 18 15H10C8.34 15 7 13.66 7 12C7 10.91 7.59 9.96 8.46 9.44C8.17 9.03 8 8.54 8 8C8 7.79 8.04 7.58 8.09 7.38C6.28 8.13 5 9.92 5 12C5 14.76 7.24 17 10 17V19H7Z" />
      <path d="M10.56 5.51C11.91 5.54 13 6.64 13 8C13 8.75 12.67 9.41 12.15 9.87L12.74 11.49L13.68 11.15L14.02 12.09L15.9 11.41L15.56 10.47L16.5 10.13L13.76 2.6L12.82 2.94L12.48 2L10.6 2.68L10.94 3.62L10 3.97L10.56 5.51Z" />
      <path d="M10.5 9.5C11.3284 9.5 12 8.82843 12 8C12 7.17157 11.3284 6.5 10.5 6.5C9.67157 6.5 9 7.17157 9 8C9 8.82843 9.67157 9.5 10.5 9.5Z" />
    </SvgIcon>
  );
};
