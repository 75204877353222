import { Attachment } from "model/primitives/attachment/attachment";
import { ApiHour, emptyTheme, getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { WhiteLabelConfig } from "model/resource/app/whiteLabelConfig";
import { produce } from "immer";
import { PhoneConfig } from "model/primitives/phoneConfig";
import { ResourceModel, ResourceType } from "model/primitives/resourceModel";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { EmailConfig } from "model/primitives/emailConfig";
import { PayerRulesConfiguration } from "model/primitives/payerRulesConfiguration";
import { Report } from "model/primitives/report";
import { Identifier } from "model/primitives/identifier";
import { OpenCode } from "model/primitives/model/model";
import { Code } from "model/resource/entities/codeSystem";
import { Email, Phone } from "model/primitives/contactPoint";
import { Address } from "model/primitives/address";
import { Practitioner } from "model/resource/person/practitioner/practitioner";
import { AvailableTime, NotAvailableTime } from "model/resource/utils";
import { Dashboard } from "./dashboard";

export enum OrganizationHierarchySearchMode {
  Normal = "Normal",
  OwnAndParent = "OwnAndParent"
}

export enum OrganizationHospitalizationType {
  Ambulatory = "Ambulatory",
  Mixed = "Mixed"
}

export enum OrganizationType {
  Provider = "prov",
  Payer = "pay",
  Group = "group"
}

export enum OrganizationCombinedType {
  Provider = "Provider",
  ProviderAndGroup = "ProviderAndGroup",
  Payer = "Payer",
  Group = "Group"
}

export interface OrganizationAvailability {
  availableTime?: AvailableTime[];
  notAvailableTime?: NotAvailableTime[];
  nationalHolidaysDisabled?: boolean;
  nationalHolidaysException?: Code[];
}

export enum KnownOrganizationPayerCoverageStatus {
  Default = "Default",
  Secondary = "Secondary",
  Inactive = "Inactive"
}
export type OrganizationPayerCoverageStatus =
  OpenCode<KnownOrganizationPayerCoverageStatus>;

export enum OrganizationFlowConfig {
  PatientRegisterDisabled = "PatientRegisterDisabled",
  HospitalizationFreeBedValidationDisabled = "HospitalizationFreeBedValidationDisabled",
  IsNursingHome = "IsNursingHome"
}

export interface OrganizationOnlineAppointmentBookingConfiguration {
  notes?: string;
  bookingWindow?: number;
}

export interface Organization extends ResourceModel<ResourceType.Organization> {
  name: string;
  type: OrganizationType;
  serviceList?: string[];
  medicalRequestExpirationTimeList?: ApiHour[];
  medicalRequestExpirationWarningTimeList?: ApiHour[];
  hospitalizationType?: OrganizationHospitalizationType;
  partOf?: ModelReference<Organization>;
  emailConfig?: EmailConfig[];
  phoneConfig?: PhoneConfig[];
  reportConfig?: Report[];
  dailyEvolutionClosePeriod?: number;
  dailyEvolutionTitleTemplate?: string;
  timezone?: string;
  public?: boolean;
  domainUrlList?: string[];
  whiteLabelConfig?: WhiteLabelConfig;
  ipWhitelist?: string[];
  dashboardConfig?: Dashboard[];
  identifier?: Identifier[];
  active?: boolean;
  phone?: Phone[];
  email?: Email[];
  address?: Address[];
  speciality?: Code[];
  interest?: Code[];
  interestObservation?: string;
  country?: string;
  timezoneIana?: string;
  photo?: Attachment;
  availability?: OrganizationAvailability;
  originalPractitioner?: ModelReference<Practitioner>;
  lastEditor?: ModelReference<Practitioner>;
  invitationBlacklist?: string[];
  flowConfig?: OrganizationFlowConfig[];
  payerRulesConfiguration?: PayerRulesConfiguration;
  onlineAppointmentBookingConfiguration?: OrganizationOnlineAppointmentBookingConfiguration;
  website?: string;
  phoneSenderId?: string;
}
// add default Theme so key generates correctly https://typescript-tdd.github.io/ts-auto-mock/types-not-supported#extends-mapped-type
export const OrganizationKey = getKeyObj<Organization>(
  produce(createHydratedMock<Organization>(), draft => {
    if (draft.whiteLabelConfig) draft.whiteLabelConfig.theme = emptyTheme;
  })
);

export const OrganizationAvailabilityKey = getKeyObj<OrganizationAvailability>(
  createHydratedMock<OrganizationAvailability>()
);

export const OrganizationOnlineAppointmentBookingConfigurationKey =
  getKeyObj<OrganizationOnlineAppointmentBookingConfiguration>(
    createHydratedMock<OrganizationOnlineAppointmentBookingConfiguration>()
  );

export interface OrganizationInvitationData {
  id?: string;
  name?: string;
  photo?: Attachment;
}
