import { TimePickerHour } from "components/inputs/TimePicker/TimePicker";
import { ApiHour, DateTime, Optional, toApiHour } from "@laba/ts-common";

export const timePickerHourToApiHour = (
  timePickerHour?: TimePickerHour
): Optional<ApiHour> => {
  if (!timePickerHour) return undefined;
  const hour = String(timePickerHour.hour).padStart(2, "0");
  const minute = String(timePickerHour.minute).padStart(2, "0");
  return `${hour}:${minute}:00`;
};

export const apiHourToTimePickerHour = (
  apiHour?: ApiHour
): Optional<TimePickerHour> => {
  if (!apiHour) return undefined;
  const hourList = apiHour.split(":");
  const hour = hourList[0];
  const minute = hourList[1];
  if (hour && minute) return { hour: Number(hour), minute: Number(minute) };
};

export const dateTimeToTimePickerHour = (
  value: DateTime
): Optional<TimePickerHour> => apiHourToTimePickerHour(toApiHour(value));
