import { ResourceType } from "model/primitives/resourceModel";
import { EncounterStatus } from "model/resource/entities/encounter/encounter";
import {
  PatientContainerWithExtraData,
  PatientPrescriptionStatus
} from "model/resource/person/patient/patientContainerWithExtraData";
import { patientContainerWithExtraDataMock } from "./patientContainerWithExtraData";

const patientWithNearToExpiredPrescription: PatientContainerWithExtraData = {
  ...patientContainerWithExtraDataMock,
  prescriptionStatus: PatientPrescriptionStatus.ToExpire
};

const patientWithExpiredPrescription: PatientContainerWithExtraData = {
  ...patientContainerWithExtraDataMock,
  prescriptionStatus: PatientPrescriptionStatus.Expired
};

const patientDischarged: PatientContainerWithExtraData = {
  ...patientContainerWithExtraDataMock,
  hospitalization: {
    status: EncounterStatus.Finished,
    finishDate: "2020-07-18",
    healthcareService: "",
    resourceType: ResourceType.Hospitalization,
    patient: "29"
  }
};

const patientHospitalized: PatientContainerWithExtraData = {
  ...patientContainerWithExtraDataMock,
  hospitalization: {
    ...patientContainerWithExtraDataMock.hospitalization,
    status: EncounterStatus.InProgress,
    healthcareService: "",
    resourceType: ResourceType.Hospitalization,
    patient: "25"
  }
};

const patientAmbulatory = {
  ...patientContainerWithExtraDataMock,
  hospitalization: undefined
};

export const getPatientContainerWithExtraDataListMock =
  (): PatientContainerWithExtraData[] => [
    patientWithNearToExpiredPrescription,
    patientWithExpiredPrescription,
    patientDischarged,
    patientHospitalized,
    patientAmbulatory
  ];
