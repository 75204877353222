import { Theme } from "model/theme";
import { createStyle, flexRowMixin, flexItemMixin } from "@laba/react-common";

interface ColorTagListStyleProps {
  wrapContent?: boolean;
}

export const useColorTagListStyle = createStyle(
  (_theme: Theme) => ({
    root: (props: ColorTagListStyleProps) => ({
      ...flexRowMixin({ gap: 8, wrapContent: props.wrapContent })
    }),
    tag: {
      ...flexItemMixin({ shrink: 0 })
    }
  }),
  "ColorTagList"
);
