import React from "react";
import {
  getDefaultJsonToModel,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";
import {
  Questionnaire,
  QuestionnaireFieldPropertyType,
  ResourceType
} from "@laba/nexup-api";
import {
  getDefaultResourceTransform,
  ResourceForm,
  TabConfig
} from "components/generic/ResourceForm/ResourceForm";
import { tkCP } from "translation/i18n";
import { FC, useI18n } from "@laba/react-common";
import { QuestionnaireMainContent } from "components/pages/NexupAdmin/resources/questionnaire/QuestionnaireMainContent";
import { QuestionnaireFieldsContent } from "components/pages/NexupAdmin/resources/questionnaire/QuestionnaireFieldsContent";
import { QuestionnaireFieldElementContent } from "components/pages/NexupAdmin/resources/questionnaire/QuestionnaireFieldElementContent";
import { QuestionnaireCardContent } from "components/pages/NexupAdmin/resources/questionnaire/QuestionnaireCardContent";
import { isEmpty, times } from "lodash-es";
import { notUndefined } from "@laba/ts-common";

export const questionnaireTransform =
  getDefaultResourceTransform<Questionnaire>(
    ResourceType.Questionnaire,
    draft => {
      if (draft.cards) {
        for (let i = 0; i < draft.cards.length; i += 1) {
          const draftCard = draft.cards[i];
          if (draftCard && draftCard.fields) {
            for (let j = 0; j < draftCard.fields.length; j += 1) {
              const draftField = draftCard.fields[j];
              if (
                draftField &&
                draftField.fieldType === undefined &&
                draftField.linkId === undefined
              ) {
                draftCard.fields[j] = undefined;
              }
              if (draftField && draftField.property) {
                for (let k = 0; k < draftField.property.length; k += 1) {
                  const draftProperty = draftField.property[k];
                  const draftPropertyType = draftProperty?.type;
                  if (
                    draftProperty &&
                    draftPropertyType &&
                    [
                      QuestionnaireFieldPropertyType.CreateProblem,
                      QuestionnaireFieldPropertyType.RelateProblem,
                      QuestionnaireFieldPropertyType.RelateSolvedProblem,
                      QuestionnaireFieldPropertyType.SingleLine,
                      QuestionnaireFieldPropertyType.SolveProblem,
                      QuestionnaireFieldPropertyType.ResponseEncounter,
                      QuestionnaireFieldPropertyType.CurrentPractitioner
                    ].includes(draftPropertyType) &&
                    draftProperty.value == null
                  ) {
                    draftProperty.value = "false";
                  }
                }
              }
            }
            draftCard.fields = draftCard.fields.filter(notUndefined);
            if (draftCard.linkId === undefined && isEmpty(draftCard.fields))
              draft.cards[i] = undefined;
          }
        }
        draft.cards = draft.cards.filter(notUndefined);
      }
    }
  );
export const questionnaireJsonToModel: JsonToModel<Questionnaire> =
  getDefaultJsonToModel(draft => {
    draft.organization = undefined;
  });
export const tk = tkCP.adminPage[ResourceType.Questionnaire];

export const QuestionnaireForm: FC = () => {
  const { t } = useI18n();

  const cardsTabConfig: TabConfig[] = times(10).map(index => ({
    label: t(tk.tabs.card, { index }),
    content: <QuestionnaireCardContent cardIndex={index} />
  }));
  return (
    <ResourceForm
      tabsConfig={[
        {
          label: t(tk.tabs.main),
          content: <QuestionnaireMainContent />
        },
        {
          label: t(tk.tabs.fields),
          content: <QuestionnaireFieldsContent />
        },
        {
          label: t(tk.tabs.fieldProperties),
          content: <QuestionnaireFieldElementContent />
        }
      ]}
      postJsonTabsConfig={cardsTabConfig}
    />
  );
};
