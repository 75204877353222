import { StyleVariant, TextVariant } from "model/themeVariant";
import {
  themeTextMixin,
  getThemeStyleColor,
  getThemeButtonRadius
} from "model/themeUtils";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import {
  borderMixinRadiusAll,
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  FlexJustifyContent,
  flexRowMixin,
  pxToRem
} from "@laba/react-common";

export const useSuggestedListStyle = createStyle(
  (theme: Theme) => ({
    container: {
      ...flexColumnMixin({ gap: 16 })
    },
    showMoreButton: {
      ...borderMixinRadiusAll(getThemeButtonRadius(theme)),
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.SpaceBetween
      }),
      ...themeTextMixin(theme, TextVariant.Body2, 1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      ),
      height: pxToRem(24)
    }
  }),
  "SuggestedList"
);
