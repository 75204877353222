import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  getModelReferenceId,
  KnownCodeSystemSystem,
  ModelReference,
  Organization,
  OrganizationCombinedType,
  OrganizationListQueryParamsKey,
  ProductKey,
  PublicationStatus,
  ResourceType
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

export const tk = tkCP.adminPage[ResourceType.Product];
export const ProductMainContent: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organization = useWatchResourceForm<ModelReference<Organization>>(
    ProductKey.organization
  );
  const organizationId =
    getModelReferenceId(organization) || workspaceOrganizationId;
  const categorySystem = useCodeSystemGroup(
    KnownCodeSystemSystem.ProductCategory,
    organizationId
  );
  const subCategorySystem = useCodeSystemGroup(
    KnownCodeSystemSystem.ProductSubcategory,
    organizationId
  );
  const tagSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.ProductTag,
    organizationId
  );

  return (
    <>
      <TextInput disabled source={ProductKey.id} label={t(tk.fields.id)} />
      <OrganizationReferenceInput
        source={ProductKey.organization}
        label={t(tk.fields.organization)}
        type={OrganizationCombinedType.ProviderAndGroup}
        extraFilters={{
          [OrganizationListQueryParamsKey.active]: true
        }}
        required
        defaultValue={workspaceOrganizationId}
        disabled={workspaceOrganizationId !== undefined}
      />
      <TextInput
        source={ProductKey.title}
        label={t(tk.fields.title)}
        required
      />
      <EnumSelectorInput
        source={ProductKey.status}
        label={t(tk.fields.status)}
        enumValue={PublicationStatus}
        required
      />
      <CodeSystemSelectorInput
        source={ProductKey.category}
        label={t(tk.fields.category)}
        codeSystem={categorySystem}
        organizationId={organizationId}
        required
        withCreate
      />
      <CodeSystemSelectorInput
        source={ProductKey.subcategory}
        label={t(tk.fields.subcategory)}
        codeSystem={subCategorySystem}
        organizationId={organizationId}
        required
        withCreate
      />
      <ArrayInput source={ProductKey.tag} label={t(tk.fields.tags)}>
        <CodeSystemSelectorInput
          source=""
          label={t(tk.fields.tagElement)}
          codeSystem={tagSystem}
          organizationId={organizationId}
          withCreate
        />
      </ArrayInput>
    </>
  );
};
