import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";
import { FC } from "@laba/react-common";

export const StatusIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <circle cx="50%" cy="50%" r="7" />
    </SvgIcon>
  );
};
