import { useState } from "react";
import { isEqual } from "lodash-es";

export const useOrToggleSelectedItem = <T>(
  defaultItem: T
): [T, (item: T) => void] => {
  const [selectedItem, internalSetSelectedItem] = useState<T>(defaultItem);
  const setSelectedItem = (newElement: T): void => {
    if (isEqual(newElement, selectedItem)) {
      internalSetSelectedItem(defaultItem);
    } else {
      internalSetSelectedItem(newElement);
    }
  };
  return [selectedItem, setSelectedItem];
};
