import React, { ChangeEvent } from "react";
import { FC } from "@laba/react-common";
import { ThemeProvider } from "@material-ui/core/styles";
import { StyleVariant } from "model/themeVariant";
import { Slider as MuiSlider } from "@material-ui/core";
import { useMuiTheme } from "model/useMuiTheme";

export interface SliderProps {
  className?: string;
  disabled?: boolean;
  max?: number;
  min?: number;
  name?: string;
  onChange?: (e: ChangeEvent<unknown>, value: number | number[]) => void;
  step?: number;
  value?: number;
}

export const Slider: FC<SliderProps> = ({
  className,
  max,
  min,
  name,
  onChange,
  step,
  value,
  disabled = false
}) => {
  const theme = useMuiTheme(StyleVariant.Primary);
  return (
    <ThemeProvider theme={theme}>
      <MuiSlider
        className={className}
        disabled={disabled}
        max={max}
        min={min}
        name={name}
        onChange={onChange}
        step={step}
        value={value}
      />
    </ThemeProvider>
  );
};
