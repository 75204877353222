import { getKeyObj } from "@laba/ts-common";
import { MedicalAppConfigPermissionCode } from "model/resource/app/appConfig";
import { createHydratedMock } from "ts-auto-mock";
import { Code } from "model/resource/entities/codeSystem";

export interface ResourcePermissions {
  requiredAppConfigList: MedicalAppConfigPermissionCode[];
  excludedAppConfigList: MedicalAppConfigPermissionCode[];
}

export interface SpecialityConfigPermissions {
  requiredSpecialityList: Code[];
  excludedSpecialityList: Code[];
}

export interface PermissionConfig {
  createAppConfig: ResourcePermissions;
  editAppConfig: ResourcePermissions;
  specialityConfig: SpecialityConfigPermissions;
}

export const PermissionConfigKey = getKeyObj<PermissionConfig>(
  createHydratedMock<PermissionConfig>()
);
