import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { Location } from "model/resource/entities/location/location";
import { HttpMethod } from "@laba/ts-common";
import { getFrontPublicApiUrl, requestAnon } from "request/nexupRequest";
import { ModelId } from "model/primitives/model/model";

export enum DirectoryListOrder {
  CreationDate = "creationDate",
  Score = "score",
  Name = "name",
  Distance = "distance"
}

export interface DirectoryQueryParams extends ListQueryParams {
  city?: string;
  content?: string;
  contentBoost?: number;
  directoryType?: string;
  latitude?: number;
  locationBoostPrecision?: number;
  longitude?: number;
  plan?: string;
  radius?: number;
  speciality?: string;
  state?: string;
  organization?: ModelId;
  order?: DirectoryListOrder;
}

export const getDirectoryList = async (
  params?: DirectoryQueryParams
): Promise<ApiPageRequestResponse<Location>> => {
  return requestAnon({
    method: HttpMethod.GET,
    url: getFrontPublicApiUrl("/directory"),
    params
  });
};

export const getDirectoryDetail = (
  id: ModelId
): Promise<ApiRequestResponse<Location>> =>
  requestAnon({
    method: HttpMethod.GET,
    url: getFrontPublicApiUrl(`directory/${id}`)
  });
