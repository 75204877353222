import React from "react";
import { ReactElement, useI18n } from "@laba/react-common";
import { FormTabProps } from "react-admin";
import { JsonInput } from "components/generic/JsonInput/JsonInput";
import { tkCC } from "translation/i18n";
import { FormTab } from "components/generic/FormTab/FormTab";

export interface ResourceJsonTabProps
  extends Omit<FormTabProps, "children" | "label"> {
  activeTab?: boolean;
}

const tk = tkCC.inputs.jsonTab;
export const ResourceJsonTab = ({
  activeTab,
  ...props
}: ResourceJsonTabProps): ReactElement => {
  const { t } = useI18n();
  return (
    <FormTab label={t(tk.label)} {...props}>
      {activeTab !== false && <JsonInput />}
    </FormTab>
  );
};
