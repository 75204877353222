export const procedureBatteryConcept = {
  title: "Bateria de estudios",
  fields: {
    id: "Id",
    display: "Nombre",
    tag: "Etiquetas",
    procedures: "Estudios"
  },
  tabs: {
    procedureBatteryConcept: "Bateria de estudios"
  }
};
