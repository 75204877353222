import { PatientMeasure } from "model/resource/medical/observation/patientMeasure";
import { ApiRequestResponse } from "request/types";
import { HttpMethod } from "@laba/ts-common";
import { getFrontApiUrl, request } from "request/nexupRequest";

export const PatientMeasurePath = "/patient-measure";

export const createPatientMeasure = (
  data: PatientMeasure
): Promise<ApiRequestResponse<PatientMeasure>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(`${PatientMeasurePath}`),
    data
  });

export const updatePatientMeasure = (
  data: PatientMeasure
): Promise<ApiRequestResponse<PatientMeasure>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${PatientMeasurePath}/${data.id}`),
    data
  });

export const createOrUpdatePatientMeasure = (
  data: PatientMeasure
): Promise<ApiRequestResponse<PatientMeasure>> => {
  if (data.id !== undefined) {
    return updatePatientMeasure(data);
  }
  return createPatientMeasure(data);
};
