import {
  borderMixin,
  flexItemMixin,
  pxToRem,
  StyleVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/ts-common";
import { getThemeStyleColor } from "model/themeUtils";
import {
  CalendarLargeSizeHeight,
  CalendarLargeSizeWidth,
  CalendarSize
} from "./types";

const CalendarLargeDaySize = pxToRem(45);

export const useCalendarStyleOverrides = (
  theme: Theme,
  size?: CalendarSize
): Record<string, unknown> => {
  return {
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        ...(size === CalendarSize.Large
          ? flexItemMixin({ shrink: 0 })
          : undefined),
        width:
          size === CalendarSize.Large
            ? pxToRem(CalendarLargeSizeWidth)
            : undefined,
        height:
          size === CalendarSize.Large
            ? pxToRem(CalendarLargeSizeHeight)
            : undefined
      }
    },
    MuiPickersBasePicker: {
      pickerView: {
        maxWidth:
          size === CalendarSize.Large
            ? pxToRem(CalendarLargeSizeWidth)
            : pxToRem(325)
      }
    },
    MuiPickersCalendar: {
      transitionContainer: {
        minHeight: size === CalendarSize.Large ? pxToRem(280) : pxToRem(216)
      }
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        width: size === CalendarSize.Large ? CalendarLargeDaySize : pxToRem(36)
      },
      switchHeader: {
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.GrayDark
        )
      },
      iconButton: {
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.GrayDark
        )
      }
    },
    MuiPickersDay: {
      day: {
        width: size === CalendarSize.Large ? CalendarLargeDaySize : pxToRem(36),
        height:
          size === CalendarSize.Large ? CalendarLargeDaySize : pxToRem(36),
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.GrayDark
        )
      },
      current: {
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.GrayDark
        ),
        ...borderMixin({
          color: getThemeStyleColor(
            theme,
            StyleVariant.Primary,
            ThemeStyleColorVariant.GrayDark
          ),
          side: {
            all: 1
          },
          style: "solid"
        })
      },
      daySelected: {
        ...borderMixin({
          side: {
            all: 0
          }
        })
      }
    }
  };
};
