import {
  borderMixin,
  borderMixinRadiusAll,
  createStyle,
  destyledButtonMixin,
  marginMixin,
  paddingMixin,
  pxToRem,
  RadiusVariant,
  Theme
} from "@laba/react-common";
import {
  themeElevationMixin,
  ElevationVariant,
  NexupColor,
  getThemeRadius
} from "@laba/nexup-components";

export interface ColorInputStyleProps {
  color: string;
}
export const useColorInputStyle = createStyle(
  (theme: Theme) => ({
    color: (props: ColorInputStyleProps) => ({
      ...borderMixinRadiusAll(getThemeRadius(theme, RadiusVariant.Small)),
      width: "100%",
      height: "100%",
      background: props.color
    }),
    swatch: {
      ...destyledButtonMixin(),
      ...borderMixinRadiusAll(getThemeRadius(theme, RadiusVariant.Small)),
      ...themeElevationMixin(theme, ElevationVariant.Elevation6),
      ...paddingMixin({ all: 5 }),
      ...marginMixin({ top: 8 }),
      width: "100%",
      height: pxToRem(40),
      background: NexupColor.White
    },
    popover: {
      position: "absolute",
      zIndex: "2"
    },
    cover: {
      ...destyledButtonMixin(),
      position: "fixed",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    },
    image: {
      ...borderMixin({ side: { all: 1 }, style: "solid" }),
      objectFit: "contain",
      maxHeight: pxToRem(200),
      maxWidth: "100%",
      backgroundColor: NexupColor.Gray
    }
  }),
  "useColorInputStyle"
);
