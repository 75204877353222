import React from "react";
import { ReactElement } from "@laba/react-common";
import { StyleVariant } from "model/themeVariant";
import { useMuiTheme } from "model/useMuiTheme";
import { ThemeProvider } from "@material-ui/core/styles";
import {
  FormControl,
  FormControlLabel,
  RadioGroup as MuiRadioGroup,
  FormHelperText
} from "@material-ui/core";
import { Radio } from "components/radios/Radio/Radio";
import {
  useRadioGroupFormControlLabelStyle,
  useFormHelperTextStyle,
  useRadioGroupStyle
} from "./RadioGroupStyle";

export interface RadioConfig<V> {
  disabled?: boolean;
  label: string;
  value: V;
}

export interface RadioGroupProps<V> {
  className?: string;
  errorText?: string;
  helperText?: string;
  name?: string;
  onChange?: (value?: V) => void;
  options: RadioConfig<V>[];
  showError?: boolean;
  showHelperOrErrorText?: boolean;
  value?: V;
  compareValues?: (val1?: V, val2?: V) => boolean;
  displayInTwoColumns?: boolean;
  canUnselect?: boolean;
  getOptionFromValue?: (value: V) => RadioConfig<V>;
}

export const RadioGroup = <V,>({
  className,
  errorText,
  helperText,
  name,
  onChange,
  options,
  value,
  getOptionFromValue,
  showError = true,
  showHelperOrErrorText = true,
  compareValues = (v1?: V, v2?: V) => v1 === v2,
  displayInTwoColumns = false,
  canUnselect = false
}: RadioGroupProps<V>): ReactElement => {
  const hasError = showError && Boolean(errorText);
  const showableHelperText = (hasError ? errorText : helperText) || " ";
  const theme = useMuiTheme(StyleVariant.Primary);
  const muiRadioGroupClasses = useRadioGroupStyle();
  const formControlLabelClasses = useRadioGroupFormControlLabelStyle();
  const formHelperTextClasses = useFormHelperTextStyle();

  const handleOnClick = (event: React.MouseEvent<HTMLInputElement>) => {
    const val = (event.target as HTMLInputElement).value;
    const option = options.find(elem => elem.label === val);
    if (option !== undefined) {
      if (canUnselect && option.value === value) {
        onChange?.(undefined);
      } else {
        onChange?.(option.value);
      }
    }
  };

  const valueLabel =
    options.find(o => compareValues(value, o.value))?.label ??
    (value && getOptionFromValue?.(value));
  const mappedOptions =
    value !== undefined &&
    getOptionFromValue !== undefined &&
    !options.some(v => compareValues(v.value, value))
      ? [...options, getOptionFromValue(value)]
      : options;
  return (
    <ThemeProvider theme={theme}>
      <FormControl className={className}>
        <MuiRadioGroup
          name={name}
          onClick={handleOnClick}
          value={valueLabel ?? ""}
          className={
            displayInTwoColumns ? muiRadioGroupClasses.twoColumns : undefined
          }
        >
          {mappedOptions.map(radioOption => (
            <FormControlLabel
              classes={formControlLabelClasses}
              control={
                <Radio checked={compareValues(value, radioOption.value)} />
              }
              disabled={radioOption.disabled}
              key={radioOption.label}
              label={radioOption.label}
              title={radioOption.label}
              value={radioOption.label}
            />
          ))}
        </MuiRadioGroup>
        {showHelperOrErrorText && (
          <FormHelperText
            classes={formHelperTextClasses}
            error={hasError}
            margin="dense"
          >
            {showableHelperText}
          </FormHelperText>
        )}
      </FormControl>
    </ThemeProvider>
  );
};
