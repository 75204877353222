import { DateTime, Optional } from "@laba/ts-common";
import { ConsumptionGroupWithExtraData } from "model/resource/finance/consumption/consumption";

export const getStartMonthOfOccurrenceConsumptionGroupOrUndefined = (
  consumptionGroup?: ConsumptionGroupWithExtraData
): Optional<DateTime> => {
  const occurrence = consumptionGroup?.consumptionGroup?.occurrence;
  return occurrence
    ? DateTime.fromApiDate(occurrence).startOf("month")
    : undefined;
};
