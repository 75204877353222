import {
  createStyle,
  FlexAlignItems,
  flexRowMixin,
  getClassName,
  paddingMixin,
  pxToRem,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant,
  flexColumnMixin,
  flexItemMixin
} from "@laba/react-common";
import {
  themeElevationMixin,
  getThemeStyleColor,
  themeTextMixin
} from "model/themeUtils";
import { ElevationVariant } from "model/themeVariant";

interface AppHeaderWithoutDrawerClassNames {
  root: string;
  containerIcon: string;
  title: string;
  textIcon: string;
  stepper: string;
  firstRow: string;
}

interface AppHeaderWithoutDrawerProps {
  isMobile: boolean;
  wrapChildrenIfIsMobile: boolean;
  hasChildren: boolean;
  showLogo: boolean;
}

const useAppHeaderWithoutDrawerStaticStyles = createStyle((theme: Theme) => {
  return {
    root: {
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    },
    rootWithShadow: {
      ...themeElevationMixin(theme, ElevationVariant.Elevation1)
    },
    rootDesktop: {
      ...flexRowMixin({ alignItems: FlexAlignItems.Center, gap: 8 })
    },
    rootMobileStaticGap: {
      ...flexColumnMixin({ alignItems: FlexAlignItems.FlexStart, gap: 16 })
    },
    containerIconWithWrappedChildren: {
      ...flexRowMixin({ gap: 8, alignItems: FlexAlignItems.Center }),
      ...flexItemMixin({ grow: 1 }),
      width: "100%"
    },
    boxShadow: {
      ...themeElevationMixin(theme, ElevationVariant.Elevation1)
    },
    paddingBoxShadow: {
      ...paddingMixin({ leftRight: 8, topBottom: 14 })
    },
    stepper: {
      width: pxToRem(500)
    },
    firstRowWithoutChildren: {
      ...flexRowMixin({ gap: 8, alignItems: FlexAlignItems.Center })
    },
    firstRowWithLogo: {
      ...flexRowMixin({ gap: 80, alignItems: FlexAlignItems.Center })
    },
    containerIcon: {
      ...flexRowMixin({ gap: 8, alignItems: FlexAlignItems.Center }),
      ...flexItemMixin({ shrink: 0 })
    },
    title: {
      ...flexItemMixin({ grow: 1 }),
      ...themeTextMixin(theme, TextVariant.Body2),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    textIcon: {
      height: pxToRem(30),
      width: "auto",
      objectFit: "contain",
      overflow: "hidden"
    }
  };
}, "AppHeaderWithoutDrawerStatic");

export const useAppHeaderWithoutDrawerStyles = (
  props: AppHeaderWithoutDrawerProps
): AppHeaderWithoutDrawerClassNames => {
  const staticClasses = useAppHeaderWithoutDrawerStaticStyles();
  const { isMobile, wrapChildrenIfIsMobile, hasChildren, showLogo } = props;
  return {
    root: getClassName(
      staticClasses.root,
      wrapChildrenIfIsMobile
        ? isMobile
          ? staticClasses.rootMobileStaticGap
          : getClassName(
              staticClasses.rootWithShadow,
              staticClasses.paddingBoxShadow
            )
        : getClassName(
            staticClasses.rootWithShadow,
            staticClasses.paddingBoxShadow
          ),
      isMobile ? undefined : staticClasses.rootDesktop
    ),
    containerIcon: staticClasses.containerIcon,
    title: staticClasses.title,
    textIcon: staticClasses.textIcon,
    stepper: staticClasses.stepper,
    firstRow: hasChildren
      ? wrapChildrenIfIsMobile && isMobile
        ? getClassName(
            staticClasses.boxShadow,
            staticClasses.paddingBoxShadow,
            staticClasses.containerIconWithWrappedChildren
          )
        : staticClasses.containerIconWithWrappedChildren
      : isMobile
      ? staticClasses.firstRowWithoutChildren
      : showLogo
      ? staticClasses.firstRowWithLogo
      : staticClasses.firstRowWithoutChildren
  };
};
