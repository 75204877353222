import React from "react";
import { FC, useI18n } from "@laba/react-common";
import { KnownIdentifierSystem, Patient, ResourceType } from "@laba/nexup-api";
import {
  getDefaultResourceTransform,
  ResourceForm
} from "components/generic/ResourceForm/ResourceForm";
import { tkCP } from "translation/i18n";
import {
  getDefaultJsonToModel,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";
import { PatientMainContent } from "components/pages/NexupAdmin/resources/patient/PatientMainContent";
import { PatientRoleContent } from "components/pages/NexupAdmin/resources/patient/PatientRoleContent";
import { PatientUserContent } from "components/pages/NexupAdmin/resources/patient/PatientUserContent";
import { useHasPermission } from "components/hook/UseHasPermission";
import { UserResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";

export const patientTransform = getDefaultResourceTransform<Patient>(
  ResourceType.Patient
);
export const patientJsonToModel: JsonToModel<Patient> = getDefaultJsonToModel(
  draft => {
    draft.federationId = undefined;
    if (draft.personalData) {
      draft.personalData.identifierList =
        draft.personalData.identifierList?.filter(
          x => x?.system !== KnownIdentifierSystem.Nexup
        );
    }
    draft.organization = undefined;
    const healthcarePayer = draft.patientData?.healthcarePayer;
    if (healthcarePayer) healthcarePayer.provider = undefined;
    draft.user = undefined;
  }
);
export const tk = tkCP.adminPage[ResourceType.Patient];

export const PatientForm: FC = () => {
  const hasUserPermissions = useHasPermission(UserResourceType);
  const { t } = useI18n();
  const tabs = [
    {
      label: t(tk.tabs.personal),
      content: <PatientMainContent />
    }
  ];
  if (hasUserPermissions) {
    tabs.push(
      {
        label: t(tk.tabs.rol),
        content: <PatientRoleContent />
      },
      {
        label: t(tk.tabs.user),
        content: <PatientUserContent />
      }
    );
  }
  return <ResourceForm tabsConfig={tabs} />;
};
