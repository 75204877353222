import { FC } from "@laba/react-common";
import React from "react";
import { NexupColor } from "model/nexupColor";
import { Tag } from "components/tags/Tag/Tag";
import { PaymentsInvertedIcon } from "../PaymentsInvertedIcon";

export interface MoneyIconProps {
  price?: string;
  className?: string;
}

export const MoneyIcon: FC<MoneyIconProps> = ({ className, price }) => (
  <Tag
    StartIcon={PaymentsInvertedIcon}
    backgroundColor={NexupColor.DarkGray}
    className={className}
    text={price}
    title={price}
  />
);
