import { ModelId, OpenCode } from "model/primitives/model/model";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { ApiDate } from "@laba/ts-common";
import { ResourceModel, ResourceType } from "model/primitives/resourceModel";
import { Practitioner } from "model/resource/person/practitioner/practitioner";
import { Patient } from "../../person/patient/patient";

export enum AllergyStatus {
  Active = "active",
  Inactive = "inactive"
}

export enum KnownAllergyIntoleranceElements {
  NoKnownAllergy = "716186003"
}

export type AllergyIntoleranceElements =
  OpenCode<KnownAllergyIntoleranceElements>;
export interface AllergyIntolerance
  extends ResourceModel<ResourceType.AllergyIntolerance> {
  status: AllergyStatus;
  patient?: ModelReference<Patient>;
  hospitalization?: ModelId;
  practitioner?: ModelReference<Practitioner>;
  recordedDate?: ApiDate;
  resolvedDate?: ApiDate;
  resolver?: ModelReference<Practitioner>;
  element?: AllergyIntoleranceElements;
  elementName?: string;
}

export enum PatientAllergyStatus {
  HasAllergies = "HasAllergies",
  NoAllergies = "NoAllergies",
  Untested = "Untested"
}
