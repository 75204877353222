import React from "react";
import { Badge } from "@material-ui/core";
import { Color, FCC } from "@laba/react-common";
import { useBadgedComponentStyle } from "components/containers/BadgedComponent/useBadgedComponentStyle";
import { BadgeOrigin } from "@material-ui/core/Badge/Badge";
import { StyleVariant } from "model/themeVariant";
import { Noop } from "@laba/ts-common";

export enum BadgePosition {
  TopRight = "top-right",
  BottomRight = "bottom-right",
  TopLeft = "top-left",
  BottomLeft = "bottom-left"
}

export enum BadgeVariant {
  Dot = "dot",
  Standard = "standard"
}

const getBadgeAnchor = (badgePosition: BadgePosition): BadgeOrigin => {
  return {
    vertical:
      badgePosition === BadgePosition.TopLeft ||
      badgePosition === BadgePosition.TopRight
        ? "top"
        : "bottom",
    horizontal:
      badgePosition === BadgePosition.TopRight ||
      badgePosition === BadgePosition.BottomRight
        ? "right"
        : "left"
  };
};

export interface BadgedComponentProps {
  className?: string;
  position?: BadgePosition;
  style?: StyleVariant;
  color?: Color;
  isAvailable?: boolean;
  isVisible?: boolean;
  variant?: BadgeVariant;
  badgeContent?: number;
  onBadgeClick?: Noop;
  title?: string;
  isClickable?: boolean;
}

export const BadgedComponent: FCC<BadgedComponentProps> = ({
  children,
  className,
  position = BadgePosition.TopRight,
  isAvailable = true,
  isVisible = true,
  style = StyleVariant.Primary,
  color,
  badgeContent,
  variant = BadgeVariant.Dot,
  onBadgeClick,
  title,
  isClickable = true
}) => {
  const classes = useBadgedComponentStyle({
    isAvailable,
    style,
    color,
    onBadgeClick,
    isClickable
  });
  return (
    <Badge
      className={className}
      classes={classes}
      color="primary"
      overlap="circular"
      variant={variant}
      anchorOrigin={getBadgeAnchor(position)}
      invisible={!isVisible}
      badgeContent={badgeContent}
      onClick={onBadgeClick}
      title={title}
    >
      {children}
    </Badge>
  );
};
