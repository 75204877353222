import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  getModelReferenceId,
  KnownCodeSystemSystem,
  Location,
  LocationKey,
  LocationListQueryParamsKey,
  LocationStatus,
  LocationType,
  ModelReference,
  Organization,
  OrganizationCombinedType,
  OrganizationListQueryParamsKey,
  ResourceType
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { locationOptionText } from "components/generic/ReferenceResourceInput/optionText";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { UrlInput } from "components/generic/UrlInput/UrlInput";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

export const tk = tkCP.adminPage[ResourceType.Location];
export const LocationMainContent: FC = () => {
  const { t } = useI18n();
  const locationOrganization = useWatchResourceForm<
    ModelReference<Organization>
  >(LocationKey.organization);
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organizationId =
    getModelReferenceId(locationOrganization) || workspaceOrganizationId;
  const locationPhysicalType = useWatchResourceForm<LocationType>(
    LocationKey.type
  );

  const locationCategorySystem = useCodeSystemGroup(
    KnownCodeSystemSystem.LocationCategory,
    organizationId
  );

  return (
    <>
      <TextInput disabled source={LocationKey.id} label={t(tk.fields.id)} />
      <OrganizationReferenceInput
        source={LocationKey.organization}
        label={t(tk.fields.organization)}
        type={OrganizationCombinedType.ProviderAndGroup}
        extraFilters={{
          [OrganizationListQueryParamsKey.active]: true
        }}
        defaultValue={workspaceOrganizationId}
        disabled={workspaceOrganizationId !== undefined}
      />
      <TextInput source={LocationKey.name} label={t(tk.fields.name)} />
      <TextInput
        source={LocationKey.description}
        label={t(tk.fields.description)}
      />
      <CodeSystemSelectorInput
        source={LocationKey.category}
        label={t(tk.fields.category)}
        codeSystem={locationCategorySystem}
        organizationId={organizationId}
        withCreate
      />
      <EnumSelectorInput
        source={LocationKey.type}
        label={t(tk.fields.type)}
        enumValue={LocationType}
      />
      <EnumSelectorInput
        source={LocationKey.status}
        label={t(tk.fields.status)}
        enumValue={LocationStatus}
      />
      <ReferenceResourceInput<Location>
        source={LocationKey.partOf}
        label={t(tk.fields.partOf)}
        resourceType={ResourceType.Location}
        optionTextKey={locationOptionText}
        extraFilters={{
          [LocationListQueryParamsKey.organization]: organizationId,
          [LocationListQueryParamsKey.physicalType]:
            locationPhysicalType === LocationType.Bed
              ? LocationType.Room
              : locationPhysicalType === LocationType.Room
              ? LocationType.Section
              : locationPhysicalType === LocationType.Section
              ? LocationType.Building
              : undefined
        }}
      />
      <TextInput source={LocationKey.hisCode} label={t(tk.fields.hisCode)} />
      <UrlInput source={LocationKey.photo} name={t(tk.fields.hisCode)} />
    </>
  );
};
