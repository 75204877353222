import { getKeyObj } from "@laba/ts-common";
import { filter, isEmpty } from "lodash-es";
import { createHydratedMock } from "ts-auto-mock";
import { ResourceModel, ResourceType } from "./resourceModel";

export enum ContactPointType {
  Home = "home",
  Work = "work",
  Mobile = "mobile",
  Temp = "temp"
}

export enum ContactPointRole {
  NotificationPhone = "NotificationPhone",
  NotificationEmail = "NotificationEmail"
}

type ContactPointResourceType = ResourceType.Phone | ResourceType.Email;

export interface ContactPoint<
  RType extends ContactPointResourceType = ContactPointResourceType
> extends ResourceModel<RType> {
  type?: ContactPointType;
  value?: string;
  role?: ContactPointRole;
}

export type Email = ContactPoint<ResourceType.Email>;
export type Phone = ContactPoint<ResourceType.Phone>;

export const ContactPointKey = getKeyObj(createHydratedMock<ContactPoint>());

export const createEmptyContactPointPhone = (
  value?: string,
  type?: ContactPointType,
  isNotificationPhone = true
): Phone => {
  return {
    resourceType: ResourceType.Phone,
    role: isNotificationPhone ? ContactPointRole.NotificationPhone : undefined,
    value: value ?? "",
    type
  };
};

export const createEmptyContactPointEmail = (
  value?: string,
  type?: ContactPointType,
  isNotificationEmail = true
): Email => {
  return {
    resourceType: ResourceType.Email,
    role: isNotificationEmail ? ContactPointRole.NotificationEmail : undefined,
    value: value ?? "",
    type
  };
};

const isNotEmptyContactPoint = <T extends ContactPointResourceType>(
  contactPoint: ContactPoint<T>
): boolean => !isEmpty(contactPoint.value);

export const removeEmptyContactPointValues = <
  T extends ContactPointResourceType
>(
  contactPointList: ContactPoint<T>[]
): ContactPoint<T>[] => filter(contactPointList, isNotEmptyContactPoint);
