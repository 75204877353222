import React from "react";
import {
  getDefaultResourceTransform,
  ResourceForm
} from "components/generic/ResourceForm/ResourceForm";
import { ResourceType, ScheduleDefinition } from "@laba/nexup-api";
import {
  getDefaultJsonToModel,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";
import { FC, useI18n } from "@laba/react-common";
import { ScheduleDefinitionMainContent } from "components/pages/NexupAdmin/resources/scheduleDefinition/ScheduleDefinitionMainContent";
import { tkCP } from "translation/i18n";

export const scheduleDefinitionJsonToModel: JsonToModel<ScheduleDefinition> =
  getDefaultJsonToModel(draft => {
    draft.organization = undefined;
  });

export const scheduleDefinitionTransform =
  getDefaultResourceTransform<ScheduleDefinition>(
    ResourceType.ScheduleDefinition
  );

export const tk = tkCP.adminPage[ResourceType.ScheduleDefinition];

export const ScheduleDefinitionForm: FC = () => {
  const { t } = useI18n();

  return (
    <ResourceForm
      tabsConfig={[
        {
          content: <ScheduleDefinitionMainContent />,
          label: t(tk.tabs.scheduleDefinition)
        }
      ]}
    />
  );
};
