import {
  createWhatsappNotification,
  editWhatsappNotification,
  getWhatsappNotificationLastVersionDetail,
  getWhatsappNotificationLastVersionList,
  WhatsappNotification,
  WhatsappNotificationLastVersion,
  WhatsappNotificationListQueryParams,
  WhatsappNotificationListQueryParamsKey
} from "@laba/nexup-api";
import { RequestFailureStatus } from "@laba/ts-common";
import { HttpError } from "react-admin";
import produce from "immer";
import {
  ProviderGetMany,
  ResourceProviderTypes
} from "./utils/resourceProviderTypes";
import {
  createGetter,
  deleteGetter,
  deleteManyGetter,
  ExternalResourceType,
  getKeyTargetParam,
  getListGetter,
  getManyReferenceGetter,
  getOneGetter,
  updateGetter,
  updateManyGetter
} from "./utils/resourceProviderGetter";

const getList = async (params: WhatsappNotificationListQueryParams) => {
  const queryParams = produce(params, draft => {
    draft.withNotification = true;
  });
  return getWhatsappNotificationLastVersionList(queryParams);
};
const getOne = async (id: string) => {
  return getWhatsappNotificationLastVersionDetail(id, {
    withNotification: true
  });
};
const update = editWhatsappNotification;
const create = createWhatsappNotification;
const getOrderParam = () => undefined;

const getTargetParams = getKeyTargetParam<WhatsappNotificationListQueryParams>(
  WhatsappNotificationListQueryParamsKey
);

const getManyWhatsappNotification: ProviderGetMany<
  WhatsappNotificationLastVersion
> = async params => {
  const result: WhatsappNotificationLastVersion[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const id of params.ids.map(value => value.toString())) {
    // eslint-disable-next-line no-await-in-loop
    const response = await getWhatsappNotificationLastVersionDetail(id);
    if (response.failureStatus === RequestFailureStatus.Failure) {
      throw new HttpError(response.errorMsg, response.status, response.data);
    }
    result.push(response.data);
  }
  return {
    data: result
  };
};

export const whatsappNotificationProvider: ResourceProviderTypes<
  WhatsappNotification,
  WhatsappNotificationListQueryParams
> = {
  resourceType: ExternalResourceType.WhatsappNotification,
  getList: getListGetter(getList, getOrderParam),
  getOne: getOneGetter(getOne),
  getMany: getManyWhatsappNotification,
  getManyReference: getManyReferenceGetter(
    getList,
    getOrderParam,
    getTargetParams
  ),
  update: updateGetter(update),
  updateMany: updateManyGetter(update),
  create: createGetter(create),
  delete: deleteGetter(),
  deleteMany: deleteManyGetter()
};
