import {
  createStyle,
  FlexAlignItems,
  flexItemMixin,
  FlexJustifyContent,
  flexRowMixin,
  getClassName,
  paddingMixin,
  StyleVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { TextVariant } from "model/themeVariant";

interface InstallButtonDynamicStylesProps {
  isMobile?: boolean;
  isiOSDevice?: boolean;
}

interface InstallButtonClassNames {
  root: string;
  text: string;
  iconButton: string;
  shareIcon: string;
  textSubtitle: string;
  closeIconAndTextContainer: string;
  acceptButton: string;
}

const useInstallButtonStaticStyles = createStyle(
  (theme: Theme) => ({
    mainColor: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Main
      )
    },
    contrastColor: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    },
    closeIconAndTextContainer: {
      ...flexRowMixin({ gap: 8, alignItems: FlexAlignItems.Center })
    },
    shareIcon: {
      fill: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      ),
      verticalAlign: "middle"
    },
    textSubtitle: {
      ...themeTextMixin(theme, TextVariant.Body2)
    },
    text: {
      ...themeTextMixin(theme, TextVariant.Subtitle2, 1)
    },
    acceptButton: { ...flexItemMixin({ shrink: 0 }) }
  }),
  "InstallButton"
);

const useInstallButtonDynamicStyle = createStyle(
  (theme: Theme) => ({
    root: (props: InstallButtonDynamicStylesProps) => ({
      ...paddingMixin(
        props.isMobile
          ? { all: 8 }
          : {
              topBottom: 12,
              leftRight: 16
            }
      ),
      ...flexRowMixin({
        gap: 8,
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.SpaceBetween
      }),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Main
      )
    })
  }),
  "InstallButtonDynamic"
);

export const useInstallButtonStyles = (
  props: InstallButtonDynamicStylesProps
): InstallButtonClassNames => {
  const staticClasses = useInstallButtonStaticStyles();
  const dynamicClasses = useInstallButtonDynamicStyle(props);

  return {
    root: dynamicClasses.root,
    closeIconAndTextContainer: staticClasses.closeIconAndTextContainer,
    text: getClassName(staticClasses.contrastColor, staticClasses.text),
    iconButton: staticClasses.contrastColor,
    shareIcon: staticClasses.shareIcon,
    textSubtitle: staticClasses.textSubtitle,
    acceptButton: staticClasses.acceptButton
  };
};
