import React from "react";
import { FC, GradientType, GradientVariant, useI18n } from "@laba/react-common";
import { OrganizationKey, ResourceType } from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { Labeled } from "react-admin";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { TextInput } from "components/generic/TextInput/TextInput";
import { ColorInput } from "components/generic/ColorInput/ColorInput";

export const tk = tkCP.adminPage[ResourceType.Organization];
export interface OrganizationWhiteLabelThemeGradientContentProps {
  gradientVariant: GradientVariant;
  label: string;
}
export const OrganizationWhiteLabelThemeGradientContent: FC<
  OrganizationWhiteLabelThemeGradientContentProps
> = ({ gradientVariant, label }) => {
  const { t } = useI18n();
  return (
    <Labeled label={label} fullWidth>
      <>
        <ArrayInput
          source={
            OrganizationKey.whiteLabelConfig.theme.gradient[gradientVariant]
              .colorList
          }
          label={t(tk.fields.whiteLabelConfigThemeGradientColorList)}
        >
          <ColorInput
            source=""
            label={t(tk.fields.whiteLabelConfigThemeGradientColorListItem)}
          />
        </ArrayInput>
        <EnumSelectorInput
          source={
            OrganizationKey.whiteLabelConfig.theme.gradient[gradientVariant]
              .gradientType
          }
          label={t(tk.fields.whiteLabelConfigThemeGradientType)}
          enumValue={GradientType}
        />
        <TextInput
          source={
            OrganizationKey.whiteLabelConfig.theme.gradient[gradientVariant]
              .linearDirection
          }
          label={t(tk.fields.whiteLabelConfigThemeGradientLinearDirection)}
        />
        <TextInput
          source={
            OrganizationKey.whiteLabelConfig.theme.gradient[gradientVariant]
              .radialShape
          }
          label={t(tk.fields.whiteLabelConfigThemeGradientRadialShape)}
        />
        <TextInput
          source={
            OrganizationKey.whiteLabelConfig.theme.gradient[gradientVariant]
              .radialSize
          }
          label={t(tk.fields.whiteLabelConfigThemeGradientRadialSize)}
        />
        <TextInput
          source={
            OrganizationKey.whiteLabelConfig.theme.gradient[gradientVariant]
              .radialPosition
          }
          label={t(tk.fields.whiteLabelConfigThemeGradientRadialPosition)}
        />
      </>
    </Labeled>
  );
};
