import React from "react";
import { FC } from "@laba/react-common";
import { OdontogramIconProps } from "components/icons/OdontogramIcons/utils";
import { NexupColor } from "model/nexupColor";

export const AcrylicCompositeSealingIcon: FC<OdontogramIconProps> = props => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill={props.iconColor ?? NexupColor.Black}
    >
      <path d="M6.64453 5.55225L1.66113 19.4478H0L4.99219 5.55225H6.64453Z" />
      <path d="M10.6348 7.75732L6.81152 18.8491H4.5L9.31641 6.05225H10.793L10.6348 7.75732ZM13.834 18.8491L10.002 7.75732L9.83496 6.05225H11.3203L16.1543 18.8491H13.834ZM13.6494 14.103V15.8521H6.68848V14.103H13.6494Z" />
      <path d="M21.1064 17.1616C21.4521 17.1616 21.7627 17.0942 22.0381 16.9595C22.3193 16.8188 22.5449 16.6255 22.7148 16.3794C22.8906 16.1333 22.9873 15.8491 23.0049 15.5269H25C24.9883 16.1421 24.8066 16.7017 24.4551 17.2056C24.1035 17.7095 23.6377 18.1108 23.0576 18.4097C22.4775 18.7026 21.8359 18.8491 21.1328 18.8491C20.4062 18.8491 19.7734 18.7261 19.2344 18.48C18.6953 18.228 18.2471 17.8823 17.8896 17.4429C17.5322 17.0034 17.2627 16.4966 17.0811 15.9224C16.9053 15.3481 16.8174 14.7329 16.8174 14.0767V13.769C16.8174 13.1128 16.9053 12.4976 17.0811 11.9233C17.2627 11.3433 17.5322 10.8335 17.8896 10.394C18.2471 9.95459 18.6953 9.61182 19.2344 9.36572C19.7734 9.11377 20.4033 8.98779 21.124 8.98779C21.8857 8.98779 22.5537 9.14014 23.1279 9.44482C23.7021 9.74365 24.1533 10.1626 24.4814 10.7017C24.8154 11.2349 24.9883 11.856 25 12.5649H23.0049C22.9873 12.2134 22.8994 11.897 22.7412 11.6157C22.5889 11.3286 22.3721 11.1001 22.0908 10.9302C21.8154 10.7603 21.4844 10.6753 21.0977 10.6753C20.6699 10.6753 20.3154 10.7632 20.0342 10.939C19.7529 11.1089 19.5332 11.3433 19.375 11.6421C19.2168 11.9351 19.1025 12.2661 19.0322 12.6353C18.9678 12.9985 18.9355 13.3765 18.9355 13.769V14.0767C18.9355 14.4692 18.9678 14.8501 19.0322 15.2192C19.0967 15.5884 19.208 15.9194 19.3662 16.2124C19.5303 16.4995 19.7529 16.731 20.0342 16.9067C20.3154 17.0767 20.6729 17.1616 21.1064 17.1616Z" />
    </svg>
  );
};
