import { ApiRequestResponse } from "request/types";
import {
  ConferenceEncounter,
  CurrentConferenceEncounter
} from "model/resource/entities/encounter/conference/conferenceEncounter";
import { getFrontPatientPortalApiUrl, request } from "request/nexupRequest";
import { HttpMethod } from "@laba/ts-common";
import {
  ConferenceEncounterQueryParams,
  ConferenceStatusUpdateActions
} from "api/entities/encounter/conferenceEncounter";
import { ModelId } from "model/primitives/model/model";
import { ConferenceJoinData } from "model/resource/entities/encounter/conference/conferenceJoinData";

const conferenceEncounterBasePath = "/encounter/conference";

const buildCEStatusUpdateUrl = (id: ModelId): string =>
  getFrontPatientPortalApiUrl(
    `${conferenceEncounterBasePath}/${id}/status-update`
  );

const buildJoinDataUrl = (id: ModelId): string =>
  getFrontPatientPortalApiUrl(`${conferenceEncounterBasePath}/${id}/join`);

export const getCurrentPatientConferenceEncounter = async (
  params?: ConferenceEncounterQueryParams
): Promise<ApiRequestResponse<CurrentConferenceEncounter>> => {
  return request({
    method: HttpMethod.GET,
    url: getFrontPatientPortalApiUrl(`${conferenceEncounterBasePath}/current`),
    params
  });
};

const updatePatientConferenceEncounterStatus = (
  encounterId: ModelId,
  action: ConferenceStatusUpdateActions
): Promise<ApiRequestResponse<ConferenceEncounter>> =>
  request({
    method: HttpMethod.POST,
    url: buildCEStatusUpdateUrl(encounterId),
    data: { action }
  });

export const triagePatientConferenceEncounter = (
  encounterId: ModelId
): Promise<ApiRequestResponse<ConferenceEncounter>> =>
  updatePatientConferenceEncounterStatus(
    encounterId,
    ConferenceStatusUpdateActions.PractitionerArrived
  );

export const startPatientConferenceEncounter = (
  encounterId: ModelId
): Promise<ApiRequestResponse<ConferenceEncounter>> =>
  updatePatientConferenceEncounterStatus(
    encounterId,
    ConferenceStatusUpdateActions.EncounterStarted
  );

export const cancelPatientConferenceEncounter = (
  encounterId: ModelId
): Promise<ApiRequestResponse<ConferenceEncounter>> =>
  updatePatientConferenceEncounterStatus(
    encounterId,
    ConferenceStatusUpdateActions.PatientCancelled
  );

export const finishPatientConferenceEncounter = (
  encounterId: ModelId
): Promise<ApiRequestResponse<ConferenceEncounter>> =>
  updatePatientConferenceEncounterStatus(
    encounterId,
    ConferenceStatusUpdateActions.EncounterFinished
  );

export const joinPatientConferenceEncounter = (
  encounterId: ModelId
): Promise<ApiRequestResponse<ConferenceJoinData>> => {
  return request({
    method: HttpMethod.POST,
    url: buildJoinDataUrl(encounterId)
  });
};
