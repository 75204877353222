import React, { useState } from "react";
import _ from "lodash-es";
import { ButtonBase } from "@material-ui/core";
import { getClassName, ReactElement } from "@laba/react-common";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon
} from "components/icons";
import { SuggestedItemConfig } from "./SuggestedItem/config";
import { SuggestedItem } from "./SuggestedItem/SuggestedItem";
import { useSuggestedListStyle } from "./SuggestedListStyle";

export interface SuggestedListProps<V> {
  className?: string;
  items: SuggestedItemConfig<V>[];
  numberOfItemsToShow: number;
  onClick?: (itemValue?: SuggestedItemConfig<V>) => void;
  showLessText: string;
  showMoreText: string;
}

export const SuggestedList = <V,>({
  className,
  items,
  numberOfItemsToShow,
  onClick,
  showLessText,
  showMoreText
}: SuggestedListProps<V>): ReactElement => {
  const [showingAll, setShowingAll] = useState<boolean>(false);
  const classes = useSuggestedListStyle();
  const itemsToShow = _.take(
    items,
    showingAll ? items.length : numberOfItemsToShow
  );
  const numberOfItemsToShowIsLessThanArrayLenght =
    numberOfItemsToShow < items.length;
  return (
    <div className={getClassName(classes.container, className)}>
      {itemsToShow.map(item => (
        <SuggestedItem
          key={item.itemId}
          item={item}
          onClick={() => onClick?.(item)}
        />
      ))}
      {numberOfItemsToShowIsLessThanArrayLenght && (
        <ButtonBase
          className={classes.showMoreButton}
          onClick={() => setShowingAll(!showingAll)}
        >
          {showingAll ? showLessText : showMoreText}
          {showingAll ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </ButtonBase>
      )}
    </div>
  );
};
