import React from "react";
import { FC } from "@laba/react-common";
import { Create } from "components/generic/Create/Create";
import {
  UserForm,
  userJsonToModel,
  userTransform
} from "components/pages/NexupAdmin/resources/user/UserForm";
import { UserResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";

export const UserCreate: FC = () => {
  return (
    <Create
      resourceTransform={userTransform}
      resource={UserResourceType}
      jsonToModel={userJsonToModel}
    >
      <UserForm />
    </Create>
  );
};
