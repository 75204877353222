import React from "react";
import { Color, FC, IconC } from "@laba/react-common";
import { StyleVariant, TypeVariant } from "model/themeVariant";
import { DoneIcon } from "components/icons";
import { Noop } from "@laba/ts-common";
import { TagButton } from "../TagButton/TagButton";

export interface TagInputProps {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  onClick?: Noop;
  style?: StyleVariant;
  type?: TypeVariant;
  text?: string;
  title?: string;
  uncheckedIcon?: IconC;
  backgroundColor?: Color;
}

export const TagInput: FC<TagInputProps> = ({
  className,
  onClick,
  text,
  title,
  checked = false,
  disabled = false,
  style = StyleVariant.Primary,
  uncheckedIcon,
  backgroundColor,
  type
}) => {
  return (
    <TagButton
      className={className}
      disabled={disabled}
      EndIcon={checked ? DoneIcon : uncheckedIcon}
      onClick={onClick}
      style={style}
      text={text}
      title={title}
      type={type ?? checked ? TypeVariant.Contained : TypeVariant.Outlined}
      backgroundColor={backgroundColor}
    />
  );
};
