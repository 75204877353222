import {
  FormTabProps as ReactAdminFormTabProps,
  FormTab as ReactAdminFormTab
} from "react-admin";
import React from "react";
import { ReactElement } from "@laba/react-common";

export interface FormTabProps extends ReactAdminFormTabProps {
  activeTab?: boolean;
}

export const FormTab = (props: FormTabProps): ReactElement => {
  const { activeTab, children, ...tabProps } = props;
  return (
    <ReactAdminFormTab {...tabProps}>
      {activeTab !== false && children}
    </ReactAdminFormTab>
  );
};
