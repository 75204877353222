import React from "react";
import { Modal } from "@material-ui/core";
import { FC } from "@laba/react-common";
import { Spinner } from "../Spinner/Spinner";
import { useSpinnerDialogStyles } from "./spinnerDialogStyle";

export interface SpinnerDialogProps {
  className?: string;
  open: boolean;
}

export const SpinnerDialog: FC<SpinnerDialogProps> = ({ className, open }) => {
  const classes = useSpinnerDialogStyles();
  return (
    <Modal open={open} className={className}>
      <div className={classes.circularProgressContainer}>
        <Spinner className={classes.spinnerClassname} />
      </div>
    </Modal>
  );
};
