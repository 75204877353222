import {
  createStyle,
  FlexAlignSelf,
  flexColumnMixin,
  flexItemMixin,
  getClassName,
  marginMixin,
  paddingMixin,
  pxToRem
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { getThemeStyleColor } from "model/themeUtils";
import { StyleVariant } from "model/themeVariant";

export interface DrawerStyle {
  drawerOpen: boolean;
}

export interface DrawerStyleClassNames {
  root: string;
  mobileContent: string;
  mobileNav: string;
  logo: string;
  feedbackButton: string;
}

const useDrawerStaticStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexColumnMixin({}),
      height: "100%",
      transition: "width",
      transitionDuration: 500,
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    },
    rootOpen: {
      ...paddingMixin({
        left: 18,
        right: 12,
        topBottom: 28
      }),
      width: pxToRem(220)
    },
    rootClosed: {
      ...paddingMixin({
        left: 24,
        right: 24,
        topBottom: 32
      }),
      width: pxToRem(72)
    },
    mobileContent: {
      ...flexColumnMixin({
        gap: 12
      }),
      ...paddingMixin({
        leftRight: 24,
        topBottom: 32
      }),
      height: "100%",
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    },
    mobileNav: {
      ...flexColumnMixin({})
    },
    logo: {
      ...flexItemMixin({ align: FlexAlignSelf.FlexStart }),
      height: pxToRem(30),
      width: "auto"
    },
    feedbackButton: {
      ...marginMixin({ top: "auto" })
    }
  }),
  "DrawerStatic"
);

export const useDrawerStyle = (props: DrawerStyle): DrawerStyleClassNames => {
  const staticClasses = useDrawerStaticStyle();
  return {
    root: getClassName(
      staticClasses.root,
      props.drawerOpen ? staticClasses.rootOpen : staticClasses.rootClosed
    ),
    logo: staticClasses.logo,
    mobileContent: staticClasses.mobileContent,
    mobileNav: staticClasses.mobileNav,
    feedbackButton: staticClasses.feedbackButton
  };
};

export const useSwipeableDrawerClasses = createStyle(
  (_theme: Theme) => ({
    paper: {
      width: "85%"
    }
  }),
  "SwipeableDrawer"
);
