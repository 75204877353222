export const user = {
  title: "Usuarios",
  fields: {
    id: "Id",
    username: "Nombre de usuario",
    email: "Email",
    password: "Clave inicial",
    enabled: "Habilitado",
    name: "Nombre",
    surname: "Apellido",
    autogenerated: "Autogenerado",
    assignedTo: "Profesionales",
    assignedToElement: "Profesional"
  },
  tabs: {
    main: "Usuario"
  }
};
