import * as Sentry from "@sentry/browser";
import { ErrorContext } from "logger/utils";
import { Optional } from "model/types";

export type SentryAdditionalData = Record<string, Optional<string>>;
export type SentryGlobalStateData = Record<string, unknown>;

export const SentryNormalizeDepthValue = 6;

interface SentryLogError {
  error: Error;
  errorContext?: ErrorContext;
  additionalTagsData?: SentryAdditionalData;
  additionalDataContext?: SentryAdditionalData;
  globalState?: SentryGlobalStateData;
  additionalUserData?: SentryAdditionalData;
}

export const sentryLogError = ({
  error,
  errorContext,
  additionalTagsData,
  additionalDataContext,
  globalState,
  additionalUserData
}: SentryLogError): void => {
  Sentry.captureException(error, scope => {
    errorContext && scope.setContext("Error Context", errorContext);
    additionalDataContext &&
      scope.setContext("Additional Data", additionalDataContext);
    additionalTagsData && scope.setTags(additionalTagsData);
    globalState &&
      scope.addAttachment({
        filename: "redux-state.json",
        data: JSON.stringify(globalState),
        contentType: "application/json"
      });
    additionalUserData && scope.setUser(additionalUserData);
    return scope;
  });
};
