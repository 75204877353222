import React from "react";
import { FC } from "@laba/react-common";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

export const Mesh03: FC<SvgIconProps> = props => {
  return (
    <svg
      {...props}
      id="Capa_2"
      data-name="Capa 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 322 102.47"
      opacity="0.4"
    >
      <defs>
        <clipPath id="clippath">
          <rect fill="none" x="0" width="322" height="102.47" />
        </clipPath>
      </defs>
      <g id="_Õ__7_x5F___2" data-name="Õº≤„_7_x5F_∏¥÷∆_2">
        <g clipPath="url(#clippath)">
          <path
            fill="#fff"
            d="m316.53,67.93c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m309.51,69.32c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m302.51,70.75c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m295.51,72.21c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m288.46,73.41c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m281.37,74.28c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m274.26,75.05c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m267.12,75.38c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m259.97,75.71c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m252.83,75.55c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m245.68,75.35c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m238.55,74.81c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m231.44,74.08c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m224.36,73.14c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="218.04" cy="71.97" r=".73" />
          <path
            fill="#fff"
            d="m210.31,70.49c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m203.41,68.59c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m196.52,66.7c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m189.83,64.18c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m183.15,61.61c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m176.76,58.42c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m170.42,55.11c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m164.52,51.09c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="159.41" cy="47.06" r=".73" />
          <path
            fill="#fff"
            d="m153.04,42.57c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m147.3,38.3c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m141.28,34.44c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m134.97,31.1c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m128.4,28.31c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m121.56,26.28c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m114.51,25.14c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m107.38,24.88c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m100.24,25.33c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m93.15,26.27c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m86.1,27.47c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m79.06,28.68c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m72,29.85c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m64.92,30.82c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m57.81,31.54c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m50.68,32.09c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m43.53,32.2c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m36.38,32.24c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m29.25,31.75c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m22.14,31c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m15.11,29.71c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m8.25,27.69c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="2.34" cy="25.17" r=".73" />
          <circle fill="#fff" cx="317.17" cy="68.65" r=".73" />
          <path
            fill="#fff"
            d="m309.47,70.01c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m302.49,71.51c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m295.51,73.02c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m288.47,74.26c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m281.38,75.15c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m274.28,75.91c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m267.13,76.22c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m259.99,76.49c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m252.86,76.26c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m245.74,75.96c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m238.65,75.32c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m231.58,74.47c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m224.54,73.42c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m217.57,72.01c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m210.61,70.54c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m203.77,68.54c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m196.92,66.56c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m190.27,63.98c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m183.61,61.38c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m177.21,58.2c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m170.86,54.95c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m164.91,51.04c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m159.05,47.06c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m153.35,42.87c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="148.29" cy="38.9" r=".73" />
          <path
            fill="#fff"
            d="m141.52,35.04c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="135.93" cy="31.82" r=".73" />
          <circle fill="#fff" cx="129.43" cy="28.94" r=".73" />
          <path
            fill="#fff"
            d="m121.97,26.66c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="115.75" cy="25.48" r=".73" />
          <path
            fill="#fff"
            d="m107.99,24.92c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m100.94,25.18c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="94.64" cy="26.03" r=".73" />
          <path
            fill="#fff"
            d="m86.93,26.92c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m79.93,27.95c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m72.92,28.94c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m65.87,29.76c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="59.5" cy="30.48" r=".73" />
          <circle fill="#fff" cx="52.37" cy="30.96" r=".73" />
          <path
            fill="#fff"
            d="m44.5,30.95c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m37.36,31.03c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m30.24,30.64c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m23.16,30.01c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m16.15,28.88c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m9.32,27.04c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m2.69,24.58c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m316.55,68.82c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m309.6,70.37c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m302.65,71.95c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m295.69,73.51c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m288.66,74.78c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m281.57,75.7c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m274.47,76.45c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m267.33,76.74c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m260.19,76.96c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m253.07,76.64c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m245.98,76.25c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m238.91,75.51c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m231.9,74.54c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m224.91,73.38c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m217.99,71.86c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m211.09,70.27c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m204.31,68.18c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m197.51,66.1c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m190.89,63.46c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m184.25,60.82c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m177.85,57.66c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m171.47,54.46c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m165.49,50.68c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m159.58,46.85c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="154.56" cy="42.96" r=".73" />
          <path
            fill="#fff"
            d="m148.02,38.93c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m141.93,35.32c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m135.62,31.98c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m129.19,29.01c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m122.55,26.72c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m115.72,25.26c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="109.49" cy="24.75" r=".73" />
          <circle fill="#fff" cx="102.53" cy="24.82" r=".73" />
          <circle fill="#fff" cx="95.58" cy="25.36" r=".73" />
          <path
            fill="#fff"
            d="m87.94,26.04c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m80.99,26.89c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m74.02,27.71c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m67,28.37c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m59.92,28.86c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m52.8,29.27c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m45.66,29.37c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m38.52,29.5c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m31.41,29.2c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m24.35,28.7c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m17.37,27.73c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m10.56,26.06c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m3.94,23.79c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m316.81,68.8c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m309.9,70.44c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m302.98,72.08c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m296.03,73.7c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m289.01,75.01c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m281.93,75.95c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m274.82,76.69c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m267.68,76.95c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m260.55,77.12c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m253.45,76.72c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="247.1" cy="76.35" r=".73" />
          <path
            fill="#fff"
            d="m239.35,75.39c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="233.1" cy="74.42" r=".73" />
          <path
            fill="#fff"
            d="m225.44,73.03c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m218.58,71.39c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m211.73,69.69c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m205,67.5c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="198.97" cy="65.45" r=".73" />
          <path
            fill="#fff"
            d="m191.67,62.63c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m185.05,59.96c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m178.64,56.81c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m172.24,53.67c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m166.22,50.01c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m160.28,46.34c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m154.49,42.52c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m148.63,38.78c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m142.5,35.29c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m136.19,31.96c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m129.83,28.9c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m123.3,26.47c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m116.57,24.85c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m109.73,24.04c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m102.84,23.92c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m95.97,24.26c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m89.1,24.87c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m82.2,25.53c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m75.27,26.17c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m68.29,26.68c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m61.23,27.05c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m54.12,27.4c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m46.97,27.49c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m39.84,27.66c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m32.75,27.46c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m25.71,27.09c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m18.75,26.27c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m11.96,24.78c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m5.35,22.69c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="-.33" cy="20.14" r=".73" />
          <path
            fill="#fff"
            d="m317.31,68.51c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m310.44,70.23c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m303.54,71.94c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m296.61,73.61c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m289.61,74.96c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m282.52,75.92c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m275.42,76.66c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m268.28,76.89c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m261.15,77.01c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="254.79" cy="76.66" r=".73" />
          <path
            fill="#fff"
            d="m247.02,75.95c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m240.02,75.01c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m233.1,73.8c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m226.21,72.41c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="220.12" cy="70.78" r=".73" />
          <path
            fill="#fff"
            d="m212.62,68.84c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m205.94,66.55c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m199.24,64.3c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="193.41" cy="61.65" r=".73" />
          <path
            fill="#fff"
            d="m186.09,58.82c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m179.68,55.69c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m173.26,52.61c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m167.2,49.07c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m161.21,45.56c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m155.38,41.92c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m149.48,38.36c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m143.31,34.99c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m137,31.66c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m130.72,28.51c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m124.29,25.95c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m117.67,24.17c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m110.92,23.19c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m104.12,22.87c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m97.32,23.01c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m90.51,23.42c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m83.66,23.89c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m76.77,24.36c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m69.82,24.72c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m62.78,24.97c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m55.67,25.25c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m48.53,25.34c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m41.4,25.56c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m34.32,25.45c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m27.31,25.21c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m20.38,24.54c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m13.6,23.22c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m7,21.33c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m.59,18.84c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m317.97,67.96c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="311.85" cy="69.88" r=".73" />
          <path
            fill="#fff"
            d="m304.26,71.55c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m297.35,73.27c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="291.07" cy="74.77" r=".73" />
          <path
            fill="#fff"
            d="m283.27,75.63c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="276.89" cy="76.49" r=".73" />
          <path
            fill="#fff"
            d="m269.03,76.58c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="262.63" cy="76.76" r=".73" />
          <path
            fill="#fff"
            d="m254.83,76.09c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m247.81,75.4c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m240.85,74.36c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m233.97,73.03c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="227.85" cy="71.65" r=".73" />
          <path
            fill="#fff"
            d="m220.38,69.67c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="214.37" cy="67.86" r=".73" />
          <path
            fill="#fff"
            d="m207.04,65.35c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m200.38,63c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m193.87,60.18c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m187.28,57.43c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m180.86,54.31c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m174.43,51.29c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m168.33,47.87c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m162.3,44.52c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="157.14" cy="41.18" r=".73" />
          <path
            fill="#fff"
            d="m150.48,37.68c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="145" cy="34.55" r=".73" />
          <path
            fill="#fff"
            d="m137.97,31.11c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m131.76,27.87c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m125.43,25.17c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="119.63" cy="23.35" r=".73" />
          <path
            fill="#fff"
            d="m112.26,22.07c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m105.55,21.56c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m98.82,21.5c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m92.07,21.71c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m85.27,22c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m78.42,22.3c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m71.5,22.5c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m64.48,22.64c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m57.38,22.85c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m50.23,22.93c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m43.11,23.19c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m36.05,23.18c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m29.06,23.06c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m22.15,22.55c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m15.4,21.41c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="9.52" cy="19.83" r=".73" />
          <path
            fill="#fff"
            d="m2.38,17.4c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m318.74,67.13c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m311.94,69.02c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m305.09,70.87c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m298.2,72.64c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m291.21,74.07c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m284.14,75.07c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m277.03,75.79c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m269.9,75.98c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m262.78,75.98c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m255.72,75.36c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m248.73,74.57c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m241.79,73.43c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m234.96,71.99c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m228.17,70.37c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="222.2" cy="68.51" r=".73" />
          <path
            fill="#fff"
            d="m214.81,66.35c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m208.25,63.86c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m201.64,61.43c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m195.16,58.54c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m188.6,55.76c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="182.89" cy="52.77" r=".73" />
          <path
            fill="#fff"
            d="m175.71,49.68c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m169.57,46.39c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m163.51,43.19c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m157.58,39.92c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m151.6,36.71c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m145.36,33.59c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="139.77" cy="30.4" r=".73" />
          <path
            fill="#fff"
            d="m132.91,26.94c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m126.69,24.11c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m120.28,22.01c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m113.72,20.67c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m107.1,19.97c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m100.43,19.71c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m93.75,19.72c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m86.99,19.83c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m80.19,19.95c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m73.31,20c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m66.31,20.02c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m59.21,20.16c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m52.06,20.24c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m44.94,20.54c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m37.9,20.63c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m30.93,20.64c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m24.05,20.28c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m17.31,19.32c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m10.72,17.8c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m4.29,15.68c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m319.62,66.04c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m312.85,68.01c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m306.03,69.94c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m299.15,71.76c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m292.18,73.22c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="285.83" cy="74.36" r=".73" />
          <circle fill="#fff" cx="278.72" cy="75.08" r=".73" />
          <path
            fill="#fff"
            d="m270.87,75.12c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="264.47" cy="75.19" r=".73" />
          <path
            fill="#fff"
            d="m256.71,74.37c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m249.74,73.49c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m242.84,72.25c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m236.06,70.68c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m229.32,68.95c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m222.68,66.86c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m216.07,64.7c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m209.56,62.12c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m203,59.59c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m196.56,56.65c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="190.73" cy="53.94" r=".73" />
          <path
            fill="#fff"
            d="m183.58,50.73c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m177.1,47.82c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m170.92,44.65c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m164.82,41.61c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m158.85,38.53c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m152.82,35.49c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m146.55,32.49c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m140.24,29.18c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m134.17,25.75c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m128.05,22.79c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m121.75,20.54c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m115.29,19.01c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m108.75,18.12c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m102.15,17.66c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m95.53,17.47c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m88.83,17.4c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m82.07,17.34c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="75.93" cy="17.36" r=".73" />
          <circle fill="#fff" cx="68.95" cy="17.26" r=".73" />
          <path
            fill="#fff"
            d="m61.14,17.21c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="54.71" cy="17.41" r=".73" />
          <path
            fill="#fff"
            d="m46.88,17.64c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="40.56" cy="17.94" r=".73" />
          <path
            fill="#fff"
            d="m32.9,17.96c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m26.04,17.75c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m19.33,16.97c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m12.75,15.64c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m6.31,13.71c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m-.03,11.24c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m320.57,64.83c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m313.83,66.88c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="307.76" cy="68.99" r=".73" />
          <path
            fill="#fff"
            d="m300.18,70.75c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m293.21,72.25c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m286.15,73.29c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m279.04,74c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m271.91,74.14c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m264.8,74.04c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m257.77,73.26c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m250.83,72.28c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="244.67" cy="71.06" r=".73" />
          <path
            fill="#fff"
            d="m237.22,69.25c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m230.53,67.4c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m223.95,65.2c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m217.39,62.93c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m210.94,60.25c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m204.43,57.63c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m198.02,54.63c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m191.5,51.76c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m185.06,48.69c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m178.56,45.83c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m172.34,42.78c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m166.19,39.91c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m160.18,37.01c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m154.11,34.15c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m147.81,31.26c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m141.5,27.96c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m135.5,24.44c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="130.2" cy="21.47" r=".73" />
          <path
            fill="#fff"
            d="m123.28,18.93c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m116.92,17.23c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m110.46,16.14c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="104.66" cy="15.61" r=".73" />
          <path
            fill="#fff"
            d="m97.38,15.1c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m90.72,14.84c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m84.01,14.61c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m77.19,14.36c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m70.22,14.14c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="63.85" cy="14.26" r=".73" />
          <path
            fill="#fff"
            d="m55.99,14.21c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m48.88,14.61c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m41.86,14.88c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m34.94,15.15c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m28.11,15.09c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m21.41,14.49c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m14.84,13.35c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m8.39,11.6c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m2.02,9.31c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m321.55,63.55c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m314.85,65.69c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m308.09,67.75c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m301.25,69.68c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m294.29,71.22c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m287.23,72.28c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m280.12,72.99c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m272.99,73.1c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m265.88,72.94c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m258.87,72.08c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m251.95,71.01c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m245.11,69.56c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m238.43,67.76c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="232.5" cy="65.92" r=".73" />
          <path
            fill="#fff"
            d="m225.26,63.48c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m218.76,61.1c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m212.37,58.32c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m205.91,55.61c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m199.53,52.54c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m193.02,49.65c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m186.58,46.58c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m180.06,43.79c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="174.52" cy="40.98" r=".73" />
          <circle fill="#fff" cx="168.33" cy="38.26" r=".73" />
          <path
            fill="#fff"
            d="m161.56,35.42c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m155.45,32.74c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m149.11,29.98c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m142.79,26.69c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="137.59" cy="23.19" r=".73" />
          <path
            fill="#fff"
            d="m130.95,19.85c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m124.86,17.27c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m118.6,15.39c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m112.22,14.11c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m105.78,13.25c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m99.27,12.67c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m92.66,12.22c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m85.99,11.82c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m79.2,11.41c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m72.26,11.08c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m65.17,11.01c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m58.03,11.08c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m50.92,11.51c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m43.92,11.89c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m37.02,12.28c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m30.22,12.38c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m23.54,11.95c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m16.97,11c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="11.23" cy="9.56" r=".73" />
          <path
            fill="#fff"
            d="m4.12,7.31c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="316.65" cy="64.41" r=".73" />
          <path
            fill="#fff"
            d="m309.19,66.42c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m302.37,68.41c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m295.42,69.98c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="289.08" cy="71.18" r=".73" />
          <circle fill="#fff" cx="281.98" cy="71.88" r=".73" />
          <path
            fill="#fff"
            d="m274.12,71.85c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m267.02,71.64c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="260.74" cy="70.83" r=".73" />
          <path
            fill="#fff"
            d="m253.13,69.53c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m246.32,67.99c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m239.69,66.07c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m233.09,63.99c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m226.63,61.56c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m220.19,59.06c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m213.85,56.18c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m207.43,53.39c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m201.09,50.26c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m194.6,47.32c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m188.15,44.27c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m181.61,41.54c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="176.04" cy="38.84" r=".73" />
          <path
            fill="#fff"
            d="m169.09,36.17c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m162.99,33.64c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m156.83,31.13c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m150.46,28.49c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="144.87" cy="25.32" r=".73" />
          <path
            fill="#fff"
            d="m138.29,21.5c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m132.48,18.14c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m126.5,15.4c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m120.33,13.34c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m114.04,11.86c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="108.38" cy="10.93" r=".73" />
          <path
            fill="#fff"
            d="m101.22,10.03c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="95.38" cy="9.52" r=".73" />
          <path
            fill="#fff"
            d="m88.03,8.82c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m81.27,8.26c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m74.35,7.81c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="67.99" cy="7.8" r=".73" />
          <path
            fill="#fff"
            d="m60.12,7.73c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m53.02,8.22c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m46.04,8.69c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m39.16,9.2c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="33.1" cy="9.58" r=".73" />
          <circle fill="#fff" cx="26.44" cy="9.33" r=".73" />
          <path
            fill="#fff"
            d="m19.16,8.44c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m12.69,7.07c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m6.28,5.11c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx=".59" cy="2.77" r=".73" />
          <path
            fill="#fff"
            d="m317.03,62.8c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="311.04" cy="65.12" r=".73" />
          <path
            fill="#fff"
            d="m303.51,67.04c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m296.57,68.64c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m289.52,69.75c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m282.42,70.44c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m275.28,70.51c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m268.19,70.24c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m261.2,69.23c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m254.34,67.96c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m247.56,66.31c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m240.97,64.28c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m234.43,62.08c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m228.02,59.54c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m221.64,56.93c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m215.35,53.95c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m208.99,51.07c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m202.68,47.87c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m196.21,44.9c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m189.75,41.87c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m183.19,39.19c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="177.58" cy="36.62" r=".73" />
          <path
            fill="#fff"
            d="m170.59,34.1c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m164.44,31.75c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m158.25,29.42c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m151.84,26.9c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m145.52,23.62c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m139.74,19.82c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="134.75" cy="16.45" r=".73" />
          <path
            fill="#fff"
            d="m128.15,13.44c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m122.09,11.19c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m115.88,9.53c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m109.57,8.27c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="103.91" cy="7.41" r=".73" />
          <path
            fill="#fff"
            d="m96.68,6.48c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m90.09,5.73c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m83.37,5.01c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m76.46,4.45c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m69.39,4.24c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="62.96" cy="4.42" r=".73" />
          <path
            fill="#fff"
            d="m55.15,4.82c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="48.89" cy="5.51" r=".73" />
          <path
            fill="#fff"
            d="m41.32,6.03c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m34.57,6.44c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m27.93,6.37c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m21.38,5.79c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m14.9,4.6c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m8.46,2.81c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m2.01.48c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m318.14,61.19c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m311.46,63.47c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m304.67,65.56c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m297.74,67.2c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m290.69,68.33c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m283.59,69.01c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m276.46,69.05c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m269.36,68.73c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="263.12" cy="67.76" r=".73" />
          <path
            fill="#fff"
            d="m255.56,66.27c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m248.81,64.53c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m242.27,62.38c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m235.77,60.06c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m229.42,57.4c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m223.1,54.68c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m216.87,51.61c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m210.55,48.63c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m204.28,45.38c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m197.83,42.37c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m191.36,39.35c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m184.78,36.73c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m178.41,34.16c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m172.1,31.92c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m165.91,29.76c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m159.67,27.6c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m153.23,25.21c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m146.91,21.93c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m141.2,18.04c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m135.59,14.42c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m129.82,11.36c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m123.85,8.94c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m117.73,7.08c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m111.5,5.63c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m105.18,4.45c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="99.43" cy="3.57" r=".73" />
          <path
            fill="#fff"
            d="m92.17,2.52c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m85.48,1.65c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="79.31" cy="1.1" r=".73" />
          <path
            fill="#fff"
            d="m71.52.7c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m64.38.75c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m57.29,1.32c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m50.32,1.98c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m43.49,2.75c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m36.77,3.32c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m30.15,3.42c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m23.6,3.03c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="17.83" cy="2.15" r=".73" />
          <path
            fill="#fff"
            d="m10.65.4c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m319.24,59.53c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m312.59,61.88c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m305.82,64.02c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m298.9,65.7c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m291.85,66.84c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m284.75,67.52c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m277.62,67.54c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m270.53,67.16c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="264.3" cy="66.12" r=".73" />
          <path
            fill="#fff"
            d="m256.77,64.53c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m250.05,62.69c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m243.56,60.42c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m237.11,57.99c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m230.81,55.22c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="225.27" cy="52.5" r=".73" />
          <path
            fill="#fff"
            d="m218.37,49.21c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m212.11,46.14c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m205.87,42.83c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m199.44,39.78c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m192.97,36.77c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m186.37,34.21c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="180.67" cy="31.88" r=".73" />
          <path
            fill="#fff"
            d="m173.6,29.69c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m167.37,27.7c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m161.09,25.73c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m154.61,23.45c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m148.3,20.18c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m142.65,16.2c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m137.15,12.44c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m131.48,9.23c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m125.61,6.62c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m119.58,4.57c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m113.41,2.92c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="107.87" cy="1.67" r=".73" />
          <path
            fill="#fff"
            d="m100.74.36c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m94.23-.74c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m45.66-.59c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m38.96.13c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m32.36.42c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="26.54" cy=".33" r=".73" />
          <path
            fill="#fff"
            d="m19.32-.61c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m320.32,57.87c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m313.69,60.29c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m306.94,62.48c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="300.75" cy="64.32" r=".73" />
          <path
            fill="#fff"
            d="m292.99,65.36c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m285.88,66.04c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m278.75,66.03c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m271.67,65.6c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m264.73,64.36c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m257.94,62.79c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m251.26,60.85c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m244.81,58.46c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m238.42,55.92c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="232.9" cy="53.15" r=".73" />
          <path
            fill="#fff"
            d="m225.97,50.08c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m219.85,46.81c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m213.63,43.65c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m207.42,40.28c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m201.01,37.19c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m194.54,34.2c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m187.92,31.7c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m181.47,29.37c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m175.07,27.45c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m168.8,25.65c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m162.47,23.85c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m155.96,21.7c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m149.64,18.43c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m144.07,14.36c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m138.67,10.47c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m133.11,7.09c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m127.34,4.31c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m121.39,2.06c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m115.29.22c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m321.36,56.12c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m314.76,58.61c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m308.03,60.85c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="301.84" cy="62.73" r=".73" />
          <path
            fill="#fff"
            d="m294.09,63.79c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m286.98,64.46c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m279.86,64.43c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m272.77,63.95c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m265.85,62.63c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m259.09,60.96c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m252.44,58.92c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m246.04,56.41c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m239.69,53.75c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m233.51,50.75c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m227.36,47.69c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m221.29,44.32c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m215.12,41.08c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m208.95,37.64c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m202.56,34.52c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="196.8" cy="31.66" r=".73" />
          <path
            fill="#fff"
            d="m189.44,29.09c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m182.95,26.88c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m176.51,25.13c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="170.91" cy="23.63" r=".73" />
          <path
            fill="#fff"
            d="m163.83,21.89c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m157.28,19.85c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m150.96,16.6c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="146.18" cy="12.55" r=".73" />
          <path
            fill="#fff"
            d="m140.17,8.41c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m134.71,4.87c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m129.04,1.91c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m123.17-.53c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m315.79,56.81c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m309.08,59.11c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m302.18,60.9c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m295.15,62.11c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m288.05,62.77c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m280.92,62.71c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m273.84,62.17c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m266.94,60.78c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m260.2,59.01c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m253.58,56.87c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m247.23,54.24c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="241.65" cy="51.59" r=".73" />
          <path
            fill="#fff"
            d="m234.8,48.36c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m228.71,45.18c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m222.7,41.72c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m216.58,38.38c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m210.44,34.88c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m204.07,31.72c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m197.58,28.76c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m190.92,26.37c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m184.39,24.28c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m177.92,22.68c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m171.55,21.26c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="165.87" cy="19.92" r=".73" />
          <path
            fill="#fff"
            d="m158.56,17.89c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m152.25,14.64c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="147.54" cy="10.51" r=".73" />
          <circle fill="#fff" cx="142.34" cy="6.35" r=".73" />
          <path
            fill="#fff"
            d="m136.28,2.53c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="131.42" cy="-.49" r=".73" />
          <path
            fill="#fff"
            d="m316.76,54.96c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m310.07,57.31c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m303.18,59.14c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m296.15,60.37c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m289.05,61.03c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m281.92,60.94c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m274.85,60.35c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m267.96,58.88c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m261.25,57.02c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m254.66,54.77c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m248.35,52.03c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m242.11,49.14c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m236.04,45.91c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m230,42.62c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m224.05,39.06c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m217.97,35.63c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="212.59" cy="32.2" r=".73" />
          <path
            fill="#fff"
            d="m205.52,28.88c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m199.02,25.93c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m192.35,23.6c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m185.77,21.63c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m179.26,20.19c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m172.85,18.95c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m166.4,17.67c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m159.78,15.88c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m153.47,12.64c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m148.11,8.29c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m143.02,4c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m137.78.15c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m317.71,52.75c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m311.03,55.15c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="304.88" cy="57.14" r=".73" />
          <path
            fill="#fff"
            d="m297.13,58.27c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m290.03,58.92c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m282.9,58.81c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m275.84,58.16c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m268.96,56.61c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m262.27,54.65c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m255.72,52.31c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m249.46,49.45c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="243.98" cy="46.57" r=".73" />
          <circle fill="#fff" cx="237.97" cy="43.22" r=".73" />
          <circle fill="#fff" cx="231.99" cy="39.82" r=".73" />
          <path
            fill="#fff"
            d="m225.37,36.04c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m219.35,32.52c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m213.28,28.9c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m206.94,25.67c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m200.44,22.73c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m193.74,20.46c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m187.13,18.61c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m180.58,17.33c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m174.13,16.27c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m167.64,15.17c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="161.7" cy="13.63" r=".73" />
          <path
            fill="#fff"
            d="m154.66,10.27c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m149.38,5.83c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m144.39,1.4c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m318.65,50.04c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m311.98,52.5c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m305.12,54.41c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m298.1,55.67c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m290.99,56.32c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m283.87,56.18c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m276.81,55.49c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m269.94,53.86c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m263.28,51.8c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m256.76,49.36c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m250.55,46.37c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="245.12" cy="43.38" r=".73" />
          <path
            fill="#fff"
            d="m238.44,39.8c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m232.52,36.28c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m226.68,32.53c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m220.7,28.92c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m214.67,25.24c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m208.35,21.97c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m201.85,19.05c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m195.13,16.83c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m188.48,15.11c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m181.88,13.98c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m175.39,13.11c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m168.85,12.19c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m162.16,10.64c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="156.57" cy="7.53" r=".73" />
          <path
            fill="#fff"
            d="m150.63,2.87c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m319.48,47.28c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m312.84,49.79c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="306.7" cy="51.85" r=".73" />
          <path
            fill="#fff"
            d="m298.96,53.02c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m291.86,53.65c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m284.74,53.5c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m277.68,52.74c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m270.83,51.04c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m264.2,48.89c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m257.7,46.34c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m251.54,43.24c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m245.44,40.01c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m239.54,36.44c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m233.68,32.81c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m227.89,28.95c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m221.96,25.26c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m215.96,21.52c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m209.67,18.21c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m203.15,15.3c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m196.42,13.14c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m189.73,11.54c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m183.09,10.57c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m176.55,9.88c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m169.97,9.14c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m163.24,7.71c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m156.93,4.48c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m151.78-.14c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m320.2,44.46c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m313.57,47.03c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m306.73,49c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m299.72,50.31c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m292.61,50.94c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m285.49,50.76c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m278.43,49.96c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m271.6,48.17c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m264.99,45.92c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m258.53,43.27c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m252.42,40.06c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m246.37,36.71c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="241.24" cy="33.15" r=".73" />
          <path
            fill="#fff"
            d="m234.72,29.28c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m228.99,25.33c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m223.11,21.54c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m217.14,17.74c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m210.86,14.39c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m204.35,11.5c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m197.59,9.4c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m190.86,7.92c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m184.18,7.11c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m177.6,6.61c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m170.98,6.04c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m164.21,4.73c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m157.9,1.51c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m320.79,41.62c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m314.18,44.23c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m307.35,46.25c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m300.34,47.58c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m293.23,48.2c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m286.11,47.99c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m279.06,47.13c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m272.25,45.27c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m265.66,42.92c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m259.23,40.18c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m253.16,36.84c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="247.88" cy="33.5" r=".73" />
          <path
            fill="#fff"
            d="m241.38,29.58c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m235.63,25.72c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m229.95,21.67c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m224.12,17.8c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m218.19,13.93c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m211.93,10.55c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m205.41,7.67c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m198.63,5.62c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m191.86,4.26c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m185.14,3.62c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m178.51,3.3c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m171.85,2.9c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m165.05,1.72c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m321.24,38.75c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m314.65,41.42c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m307.82,43.47c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m300.81,44.82c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m293.71,45.44c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m286.59,45.21c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m279.54,44.29c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m272.74,42.35c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m266.18,39.91c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m259.78,37.06c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m253.77,33.6c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m247.82,30.03c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="242.8" cy="26.24" r=".73" />
          <path
            fill="#fff"
            d="m236.4,22.14c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m230.77,18c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="225.71" cy="14.15" r=".73" />
          <path
            fill="#fff"
            d="m219.1,10.11c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m212.85,6.68c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m206.32,3.82c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m199.53,1.83c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m192.72.59c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m185.96.11c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m179.29-.03c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m172.58-.25c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m321.52,35.87c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m314.95,38.6c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m308.13,40.68c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m301.13,42.06c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m294.03,42.67c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m286.91,42.41c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m279.87,41.44c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m273.08,39.43c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="267.27" cy="37" r=".73" />
          <circle fill="#fff" cx="260.9" cy="34.05" r=".73" />
          <path
            fill="#fff"
            d="m254.21,30.36c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="249.03" cy="26.79" r=".73" />
          <circle fill="#fff" cx="243.35" cy="22.77" r=".73" />
          <path
            fill="#fff"
            d="m237,18.56c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m231.44,14.31c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m225.7,10.26c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m219.84,6.27c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m213.62,2.81c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m207.08-.04c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="322.35" cy="33.13" r=".73" />
          <path
            fill="#fff"
            d="m315.08,35.79c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m308.27,37.91c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m301.27,39.3c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m294.17,39.91c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m287.05,39.63c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m280.01,38.61c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m273.24,36.52c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m266.73,33.87c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m260.4,30.82c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m254.47,27.13c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m248.62,23.33c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="243.72" cy="19.31" r=".73" />
          <path
            fill="#fff"
            d="m237.43,14.99c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m231.92,10.64c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m226.23,6.5c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="221.13" cy="2.57" r=".73" />
          <path
            fill="#fff"
            d="m321.54,30.21c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m315.01,33.03c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m308.21,35.19c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m301.21,36.61c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m294.11,37.2c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m287,36.9c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="280.68" cy="35.94" r=".73" />
          <path
            fill="#fff"
            d="m273.2,33.66c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m266.72,30.91c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m260.42,27.77c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m254.54,23.96c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="249.46" cy="20.16" r=".73" />
          <path
            fill="#fff"
            d="m243.18,15.78c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m237.66,11.47c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m232.21,7.03c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m226.57,2.79c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m321.25,27.44c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m314.73,30.31c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m307.94,32.51c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m300.95,33.95c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m293.85,34.54c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="287.45" cy="34.33" r=".73" />
          <path
            fill="#fff"
            d="m279.7,33.08c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m272.96,30.83c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m266.51,27.99c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="260.95" cy="24.87" r=".73" />
          <path
            fill="#fff"
            d="m254.41,20.82c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m248.65,16.78c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="243.87" cy="12.54" r=".73" />
          <path
            fill="#fff"
            d="m237.69,7.99c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m232.29,3.45c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m320.74,24.71c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m314.23,27.64c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m307.45,29.88c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m300.47,31.33c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m293.37,31.92c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m286.25,31.57c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m279.22,30.38c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m272.5,28.06c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m266.07,25.12c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="260.55" cy="21.89" r=".73" />
          <path
            fill="#fff"
            d="m254.05,17.73c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m248.34,13.58c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m242.89,9.09c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m237.5,4.55c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="232.87" cy=".04" r=".73" />
          <path
            fill="#fff"
            d="m319.99,22.03c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m313.5,25.02c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m306.73,27.29c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m299.75,28.76c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m292.65,29.34c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m285.54,28.97c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="279.23" cy="27.85" r=".73" />
          <path
            fill="#fff"
            d="m271.8,25.33c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m265.4,22.29c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m259.19,18.84c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m253.46,14.68c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m247.8,10.41c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m242.41,5.82c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m237.07,1.16c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="319.71" cy="19.56" r=".73" />
          <path
            fill="#fff"
            d="m312.52,22.47c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m305.76,24.78c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m298.78,26.28c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="292.4" cy="26.97" r=".73" />
          <path
            fill="#fff"
            d="m284.57,26.45c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m277.55,25.15c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m270.85,22.68c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m264.47,19.54c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m258.3,15.99c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m252.61,11.71c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m247,7.33c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m241.67,2.62c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m317.72,16.93c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m311.27,20.02c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m304.52,22.37c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m297.54,23.88c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m290.44,24.45c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m283.33,24.03c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m276.31,22.68c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m269.63,20.12c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="264" cy="17.01" r=".73" />
          <path
            fill="#fff"
            d="m257.13,13.24c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m251.5,8.84c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m245.94,4.35c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m240.66-.48c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m321.86,6.4c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="316.38" cy="10.06" r=".73" />
          <path
            fill="#fff"
            d="m309.2,13c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m302.47,15.38c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m295.52,16.96c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m288.44,17.6c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m281.33,17.24c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m274.31,15.93c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m267.62,13.4c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m261.26,10.2c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="255.81" cy="6.72" r=".73" />
          <circle fill="#fff" cx="250.13" cy="2.41" r=".73" />
          <path
            fill="#fff"
            d="m320.55.03c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m314.33,3.5c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m307.86,6.53c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="301.86" cy="9.07" r=".73" />
          <path
            fill="#fff"
            d="m294.21,10.59c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m287.15,11.31c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m280.05,11.02c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m273.03,9.74c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m266.33,7.24c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="260.67" cy="4.19" r=".73" />
          <path
            fill="#fff"
            d="m253.76.51c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="307.58" cy=".7" r=".73" />
          <path
            fill="#fff"
            d="m300.16,3.02c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m293.26,4.74c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m286.21,5.53c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <circle fill="#fff" cx="279.84" cy="5.42" r=".73" />
          <circle fill="#fff" cx="272.81" cy="4.18" r=".73" />
          <path
            fill="#fff"
            d="m265.39,1.59c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m285.68-.19c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
          <path
            fill="#fff"
            d="m278.59-.36c.07-.4.44-.67.84-.6s.67.44.6.84-.44.67-.84.6-.67-.44-.6-.84Z"
          />
        </g>
      </g>
    </svg>
  );
};
