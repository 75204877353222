import {
  createStyle,
  flexColumnMixin,
  marginMixin,
  Theme
} from "@laba/react-common";

export const useLoginFormContentStyle = createStyle(
  (_theme: Theme) => ({
    root: {
      ...flexColumnMixin({
        gap: 24
      }),
      ...marginMixin({ top: 48 })
    }
  }),
  "useLoginFormContentStyle"
);
