import { PublicPatientData } from "model/resource";
import { ResourceType } from "model/primitives";

type CreateBasePublicPatientDataProps = Omit<PublicPatientData, "resourceType">;

export const createBasePublicPatientData = (
  props: CreateBasePublicPatientDataProps
): PublicPatientData => {
  return {
    ...props,
    resourceType: ResourceType.PublicPatient
  };
};
