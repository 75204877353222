import React from "react";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useListExportedFileName } from "components/hook/useExportedFileName";
import { List } from "components/generic/List/List";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { FC, useI18n } from "@laba/react-common";
import {
  OrganizationCombinedType,
  OrganizationListQueryParamsKey,
  PublicationStatus,
  ResourceType,
  ScheduleListDefinitionQueryParamsKey
} from "@laba/nexup-api";
import { ActivityDefinitionCommonListItemInput } from "components/generic/ActivityDefinition/ActivityDefinitionCommonListInput/ActivityDefinitionCommonListItemInput";
import { useSelector } from "react-redux";
import { tkCP } from "translation/i18n";
import { scheduleDefinitionJsonToModel } from "components/pages/NexupAdmin/resources/scheduleDefinition/ScheduleDefinitionForm";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

const tk = tkCP.adminPage[ResourceType.ScheduleDefinition];

export const ScheduleDefinitionList: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const exportedFileName = useListExportedFileName(t(tk.title));

  return (
    <List
      resource={ResourceType.ScheduleDefinition}
      exportedFileName={exportedFileName}
      jsonToModel={scheduleDefinitionJsonToModel}
      extraFilters={[
        workspaceOrganizationId === undefined ? (
          <OrganizationReferenceInput
            label={t(tk.filters.organization)}
            source={ScheduleListDefinitionQueryParamsKey.organization}
            alwaysOn
            type={OrganizationCombinedType.ProviderAndGroup}
            extraFilters={{
              [OrganizationListQueryParamsKey.active]: true
            }}
          />
        ) : undefined,
        <EnumSelectorInput
          source={ScheduleListDefinitionQueryParamsKey.status}
          label={t(tk.filters.status)}
          enumValue={PublicationStatus}
          alwaysOn
          resettable
        />
      ]}
    >
      <ActivityDefinitionCommonListItemInput />
    </List>
  );
};
