import {
  getDefaultJsonToModel,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";
import { MedicationSubstance, ResourceType } from "@laba/nexup-api";
import {
  getDefaultResourceTransform,
  ResourceForm
} from "components/generic/ResourceForm/ResourceForm";
import { tkCP } from "translation/i18n";
import { FC, useI18n } from "@laba/react-common";
import { MedicationSubstanceMainContent } from "components/pages/NexupAdmin/resources/medicationSubstance/MedicationSubstanceMainContent";
import React from "react";

export const medicationSubstanceJsonToModel: JsonToModel<MedicationSubstance> =
  getDefaultJsonToModel(draft => {
    draft.organization = undefined;
  });

export const medicationSubstanceTransform =
  getDefaultResourceTransform<MedicationSubstance>(
    ResourceType.MedicationSubstance
  );

export const tk = tkCP.adminPage[ResourceType.MedicationSubstance];

export const MedicationSubstanceForm: FC = () => {
  const { t } = useI18n();

  return (
    <ResourceForm
      tabsConfig={[
        {
          label: t(tk.tabs.main),
          content: <MedicationSubstanceMainContent />
        }
      ]}
    />
  );
};
