import { ApiDate, getKeyObj } from "@laba/ts-common";
import { ResourceType } from "model/primitives/resourceModel";
import { createHydratedMock } from "ts-auto-mock";
import {
  Observation,
  ObservationCategory,
  PatientTagCodes
} from "./observation";

export interface PatientTag
  extends Observation<ResourceType.PatientTag, ObservationCategory.Tag> {
  value?: PatientTagCodes;
  startDateTime?: ApiDate;
  endDateTime?: ApiDate;
}

export const PatientTagKey = getKeyObj<PatientTag>(
  createHydratedMock<PatientTag>()
);
