import { isEmpty, map, mapKeys, mapValues, omitBy, pickBy } from "lodash-es";
import { isArray, isObject, isString } from "./types";

// eslint-disable-next-line @typescript-eslint/ban-types
type Obj = object;

const defaultFilter = (value: unknown): boolean => {
  if (isObject(value) || isArray(value) || isString(value))
    return !isEmpty(value);
  return value != null;
};

const getPath = (...array: string[]) =>
  array.filter(value => !isEmpty(value)).join(".");

const deepMapObjectInt = <T extends Obj, R = T>(
  value?: T,
  mapper: (value: unknown, path: string) => unknown = x => x,
  basePath = ""
): R => {
  const objects = pickBy(value, data => isObject(data));
  const mappedObjects = mapValues(objects, (object: Obj, key) => {
    const objPath = getPath(basePath, key);
    const internalMappedObject = deepMapObjectInt(object, mapper, objPath);
    return mapper(internalMappedObject, objPath);
  });

  const arrays = pickBy(value, isArray);
  const mappedArrays = mapValues(arrays, (array: unknown[], key) => {
    const arrayPath = getPath(basePath, key);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const mappedArray = deepMapArrayInt(array, mapper, arrayPath);
    return mapper(mappedArray, arrayPath);
  });

  const basicProps = omitBy(value, data => isObject(data) || isArray(data));
  const mappedBasicProps = mapValues(basicProps, (prop, key) => {
    const propPath = getPath(basePath, key);
    return mapper(prop, propPath);
  });

  const result: unknown = {
    ...mappedBasicProps,
    ...mappedObjects,
    ...mappedArrays
  };
  return result as R;
};

const deepMapArrayInt = (
  array: unknown[],
  mapper: (value: unknown, path: string) => unknown,
  basePath = ""
): unknown[] => {
  return map(array, (value, index) => {
    const elementPath = `${basePath}[${index}]`;
    if (isObject(value)) {
      const mappedObject = deepMapObjectInt(value, mapper, elementPath);
      return mapper(mappedObject, elementPath);
    }
    if (isArray(value)) {
      const mappedArray = deepMapArrayInt(value, mapper, elementPath);
      return mapper(mappedArray, elementPath);
    }
    return mapper(value, elementPath);
  });
};

/**
 * This function filter all the elements of an object deeply with the provided function.
 * The filtering start from the deep end so the filter function run over the objects and arrays after they where filtered internally
 * This function don't ensure correct return Type
 *
 * @param value The object to filter.
 * @param filter The filter function that run on every nested prop.
 * @return Returns the filtered object (casted to R).
 */
export const deepFilterObject = <T extends Obj, R = T>(
  value?: T,
  filter: (value: unknown) => boolean = defaultFilter
): R => {
  const objects = pickBy(value, data => isObject(data));
  const cleanObjects = mapValues(objects, (object: Obj) =>
    deepFilterObject(object, filter)
  );
  const filteredObjects = pickBy(cleanObjects, filter);

  const arrays = pickBy(value, isArray);
  const cleanArrays = mapValues(arrays, (array: unknown[]) =>
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    deepFilterArray(array, filter)
  );
  const filteredArrays = pickBy(cleanArrays, filter);

  const basicProps = omitBy(value, data => isObject(data) || isArray(data));
  const filteredBasicProps = pickBy(basicProps, prop => filter(prop));

  const result: unknown = {
    ...filteredBasicProps,
    ...filteredObjects,
    ...filteredArrays
  };
  return result as R;
};

/**
 * This function filter all the elements of an array deeply with the provided function.
 * The filtering start from the deep end so the filter function run over the objects and arrays after they where filtered internally
 *
 * @param array The array to filter.
 * @param filter The filter function that run on every nested prop.
 * @return Returns the filtered array.
 */
export const deepFilterArray = (
  array: unknown[],
  filter: (value: unknown) => boolean = defaultFilter
): unknown[] => {
  const mappedArray = map(array, value => {
    if (isObject(value)) {
      const filteredObject = deepFilterObject(value, filter);
      if (filter(filteredObject)) return filteredObject;
      return undefined;
    }
    if (isArray(value)) {
      const filteredArray = deepFilterArray(value, filter);
      if (filter(filteredArray)) return filteredArray;
      return undefined;
    }
    return filter(value) ? value : undefined;
  });
  return mappedArray.filter(value => filter(value));
};

/**
 * This function map all the elements of an object deeply with the provided function.
 * The map start from the deep end so the map function run over the objects and arrays after they where mapped internally
 * This function don't ensure correct return Type
 *
 * @param value The object to filter.
 * @param mapper The mapper function that run on every nested prop.
 * @return Returns the mapped object (casted to R).
 */
export const deepMapObject = <T extends Obj, R = T>(
  value?: T,
  mapper: (value: unknown, path: string) => unknown = x => x
): R => {
  return mapper(deepMapObjectInt<T, R>(value, mapper), "") as R;
};

/**
 * This function map all the elements of an array deeply with the provided function.
 * The map start from the deep end so the map function run over the objects and arrays after they where mapped internally
 *
 * @param array The array to filter.
 * @param mapper The mapper function that run on every nested prop.
 * @return Returns the mapped array.
 */
export const deepMapArray = (
  array: unknown[],
  mapper: (value: unknown, path: string) => unknown
): unknown[] => {
  return mapper(deepMapArrayInt(array, mapper), "") as unknown[];
};

export const deepMapKeys = (
  obj: unknown,
  keyMapper: (key: string) => string
): unknown =>
  isArray(obj)
    ? obj.map(item => deepMapKeys(item, keyMapper))
    : isObject(obj)
    ? mapValues(
        mapKeys(obj, (_, key) => keyMapper(key)),
        value => deepMapKeys(value, keyMapper)
      )
    : obj;
