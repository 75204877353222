export enum ErrorIssueType {
  NotFound = "NotFound",
  UserUnknown = "UserUnknown",
  UserSessionExpired = "UserSessionExpired",
  BadCredentials = "BadCredentials",
  Forbidden = "Forbidden",
  InvalidField = "InvalidField",
  InternalError = "InternalError",
  InvalidQueryParam = "InvalidQueryParam",
  InvalidBody = "InvalidBody",
  InvalidFile = "InvalidFile",
  ResourceIsNotModifiable = "ResourceIsNotModifiable",
  BadRequest = "BadRequest",
  NotSupported = "NotSupported"
}
