import { ResourceType } from "model/primitives/resourceModel";
import { Day } from "model/primitives/time/time";
import {
  HealthcareService,
  HealthcareServiceType
} from "../healthcareService/healthcareService";

export const healthcareService: HealthcareService = {
  resourceType: ResourceType.HealthcareService,
  id: "133",
  category: "Guardia general",
  type: HealthcareServiceType.VirtualGuard,
  name: "Guardia general",
  description: "Guardia general",
  organization: "24",
  location: [],
  availableTime: {
    allDay: true,
    startTime: "20:00:00",
    endTime: "23:00:00",
    daysOfWeek: [
      Day.Sunday,
      Day.Monday,
      Day.Tuesday,
      Day.Wednesday,
      Day.Thursday,
      Day.Friday,
      Day.Saturday
    ]
  },
  organizationDefault: true,
  group: "145",
  creationDate: "2021-10-12T19:02:50.713Z",
  consultationReasonSystem: "system-prueba-consultation-reason",
  templateList: []
};
