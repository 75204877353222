import { createStyle, Theme } from "@laba/react-common";

export const useJsonEditorStyle = createStyle(
  (_theme: Theme) => ({
    root: {
      width: "100%",
      height: "70vh"
    }
  }),
  "useJsonEditorStyle"
);
