import {
  Optional,
  hasAtLeastOneLetterAndNumber,
  hasWhiteSpaces
} from "@laba/ts-common";
import { isEmpty, size } from "lodash-es";

export enum PasswordFormError {
  Required = "Required",
  PasswordTooShort = "PasswordTooShort",
  HasWhiteSpaces = "HasWhiteSpaces",
  AtLeastOneLetterAndOneNumber = "AtLeastOneLetterAndOneNumber"
}

export const validatePasswordFormField = (
  password?: string
): Optional<PasswordFormError> => {
  if (!password || isEmpty(password)) return PasswordFormError.Required;
  if (size(password) < 8) return PasswordFormError.PasswordTooShort;
  if (hasWhiteSpaces(password)) return PasswordFormError.HasWhiteSpaces;
  if (hasAtLeastOneLetterAndNumber(password))
    return PasswordFormError.AtLeastOneLetterAndOneNumber;
};
