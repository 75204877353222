import { tkCP } from "translation/i18n";
import {
  FC,
  Form,
  FormErrorData,
  FormValidator,
  useI18n
} from "@laba/react-common";
import { FinnegansFeeIntegrationRequestBody } from "@laba/nexup-api";
import { useAppDispatch } from "store/store";
import React, { useCallback, useState } from "react";
import { DefaultButton, Popup } from "@laba/nexup-components";
import { isEmpty, isUndefined } from "lodash-es";
import { dateIsAfter, dateTimeFromApiDateOrUndefined } from "@laba/ts-common";
import { onFinnegansFeeIntegrationSubmit } from "store/dashboard/event";
import { FinnegansFeeIntegrationFormContent } from "./FinnegansFeeIntegrationFormContent";

const tk = tkCP.adminPage.dashboard.finnegansFeeIntegration;
const formId = "FinnegansFeeIntegrationInputForm";
const formValidation: FormValidator<FinnegansFeeIntegrationRequestBody> = (
  t,
  formData
) => {
  const errors: FormErrorData<FinnegansFeeIntegrationRequestBody> = {};
  if (
    isUndefined(formData.windowSize) &&
    isUndefined(formData.startDate) &&
    isUndefined(formData.endDate)
  ) {
    errors.windowSize = t(tk.validation.emptyWindow);
  }

  if (isUndefined(formData.startDate) && !isUndefined(formData.endDate)) {
    errors.startDate = t(tk.validation.requiredDate);
  }

  if (!isUndefined(formData.startDate) && isUndefined(formData.endDate)) {
    errors.endDate = t(tk.validation.requiredDate);
  }

  const startDate = dateTimeFromApiDateOrUndefined(formData.startDate);
  const endDate = dateTimeFromApiDateOrUndefined(formData.endDate);
  if (startDate && endDate && dateIsAfter(startDate, endDate)) {
    errors.endDate = t(tk.validation.invalidDate);
  }

  return isEmpty(errors) ? undefined : errors;
};

export const FinnegansFeeIntegrationInput: FC = () => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const onSubmit = useCallback(
    async (requestData: FinnegansFeeIntegrationRequestBody) => {
      setLoading(true);
      const result = await dispatch(
        onFinnegansFeeIntegrationSubmit(requestData)
      );
      setLoading(false);
      if (result) {
        setIsOpen(false);
      }
    },
    [setLoading, setIsOpen, dispatch]
  );
  const validate = useCallback(
    (data: FinnegansFeeIntegrationRequestBody) => formValidation(t, data),
    [t]
  );
  const onClick = useCallback(() => setIsOpen(true), [setIsOpen]);
  const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);
  return (
    <>
      <DefaultButton
        title={t(tk.buttonText)}
        text={t(tk.buttonText)}
        onClick={onClick}
      />
      {isOpen && (
        <Popup
          open={isOpen}
          closableHeader
          headerTitle={t(tk.popupTitle)}
          primaryButtonText={t(tk.submitText)}
          disablePrimaryButton={false}
          formId={formId}
          loading={loading}
          onClose={onClose}
          disableBackdropClick
        >
          <Form<FinnegansFeeIntegrationRequestBody>
            id={formId}
            onSubmit={onSubmit}
            validate={validate}
          >
            <FinnegansFeeIntegrationFormContent />
          </Form>
        </Popup>
      )}
    </>
  );
};
