import {
  ResourceType,
  getLocationList,
  getLocation,
  updateLocation,
  createLocation,
  LocationListQueryParams,
  LocationListQueryParamsKey,
  getModelReferenceId
} from "@laba/nexup-api";
import {
  getEnumOrderParam,
  getKeyTargetParam,
  resourceProviderGetter
} from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { workspaceOrganizationSelector } from "store/workspace/selectors";
import { store } from "store/store";

export const locationProvider = resourceProviderGetter(
  ResourceType.Location,
  getEnumOrderParam({}),
  getKeyTargetParam<LocationListQueryParams>(LocationListQueryParamsKey),
  getLocationList,
  getLocation,
  updateLocation,
  createLocation,
  undefined,
  () => ({
    organization: getModelReferenceId(
      workspaceOrganizationSelector(store.getState())
    )
  })
);
