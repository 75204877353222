import React from "react";
import { FC, useI18n } from "@laba/react-common";
import { OrganizationKey, ResourceType } from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { OrganizationWhiteLabelBannerContent } from "components/pages/NexupAdmin/resources/organization/OrganizationWhiteLabelBannerContent";
import { OrganizationWhiteLabelItemContent } from "components/pages/NexupAdmin/resources/organization/OrganizationWhiteLabelItemsContent";

export const tk = tkCP.adminPage[ResourceType.Organization];
export const OrganizationWhiteLabelMobileContent: FC = () => {
  const { t } = useI18n();
  return (
    <>
      <OrganizationWhiteLabelBannerContent
        source={
          OrganizationKey.whiteLabelConfig.mobileHomeConfig.bannerItemConfigList
        }
        label={t(tk.fields.whiteLabelConfigMobileHomeBanner)}
      />
      <OrganizationWhiteLabelItemContent
        columnsSource={
          OrganizationKey.whiteLabelConfig.mobileHomeConfig.numColumns
        }
        listSource={OrganizationKey.whiteLabelConfig.mobileHomeConfig.itemList}
        columnsLabel={t(tk.fields.whiteLabelConfigMobileHomeColumns)}
        listLabel={t(tk.fields.whiteLabelConfigMobileHomeItem)}
        columnDefault={4}
      />
    </>
  );
};
