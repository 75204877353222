export const codeSystem = {
  title: "Codigos",
  filters: {
    organization: "Organizacion",
    system: "Sistema",
    status: "Estado",
    public: "Publico"
  },
  fields: {
    id: "Id",
    organization: "Organizacion",
    system: "Sistema",
    status: "Estado",
    public: "Publico",
    conceptSize: "Codigos",

    conceptList: "Codigos",
    conceptCode: "Codigo",
    conceptDisplay: "Nombre alternativo",
    conceptProperties: "Propiedades",
    conceptPropertyUse: "Tipo",
    conceptPropertyValue: "Valor"
  },
  tabs: {
    codeSystem: "Sistema",
    concept: "Lista Codigos",
    conceptElement: "Propiedades Codigo",
    conceptWithProps: "Lista Codigos con Propiedades"
  },
  isPublic: "Publico",
  isPrivate: "Privado",
  autocompleteClearText: "Limpiar",
  autocompleteCloseText: "Cerrar",
  autocompleteLoadingText: "Cargando",
  autocompleteNoOptionsText: "Sin opciones",
  autocompleteOpenText: "Abrir",
  autocompleteTitle: "Codigo a Editar"
};
