import React from "react";
import { FC } from "@laba/react-common";
import { Create } from "components/generic/Create/Create";
import {
  HealthcareServiceForm,
  healthcareServiceJsonToModel,
  healthcareServiceTransform
} from "components/pages/NexupAdmin/resources/healthcareService/HealthcareServiceForm";
import { ResourceType } from "@laba/nexup-api";

export const HealthcareServiceCreate: FC = () => {
  return (
    <Create
      resourceTransform={healthcareServiceTransform}
      resource={ResourceType.HealthcareService}
      jsonToModel={healthcareServiceJsonToModel}
    >
      <HealthcareServiceForm />
    </Create>
  );
};
