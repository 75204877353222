import { Practitioner } from "model/resource/person/practitioner/practitioner";
import { ApiDate, getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { MedicalAppConfigPermissionCode } from "model/resource/app/appConfig";
import { User } from "model/resource/person/user";
import { Building } from "model/resource/entities/location/building";
import { Organization } from "model/resource/entities/organization";

export enum KnownSubscriptionStatus {
  Active = "Active",
  Inactive = "Inactive",
  Trial = "Trial",
  PendingPayment = "PendingPayment",
  PaymentError = "PaymentError",
  Cancelled = "Cancelled"
}

interface Subscription {
  status?: KnownSubscriptionStatus;
  expiration?: ApiDate;
}

export interface Workspace {
  organization?: Organization;
  location?: Building[];
  practitioner?: Practitioner;
  permissionList?: MedicalAppConfigPermissionCode[];
  subscription?: Subscription;
  discountCode?: string;
}

export interface OrganizationWorkspaces {
  workspace: Workspace[];
  user?: User;
  practitioner?: Practitioner;
  permissionList: MedicalAppConfigPermissionCode[];
}

export const WorkspaceKey = getKeyObj(createHydratedMock<Workspace>());
