import { FC, useDebounce } from "@laba/react-common";
import React from "react";
import {
  TextInput,
  TextInputProps
} from "components/inputs/TextInput/TextInput";

export interface DebounceTextInputProps extends TextInputProps {
  searchDebounceMs?: number;
}

export const DebounceTextInput: FC<DebounceTextInputProps> = ({
  onChange,
  value,
  searchDebounceMs = 500,
  ...props
}) => {
  const { onChange: debouncedOnChange, value: debouncedValue } = useDebounce({
    onChange,
    value,
    searchDebounceMs
  });
  return (
    <TextInput {...props} onChange={debouncedOnChange} value={debouncedValue} />
  );
};
