import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  AccountKey,
  AccountListQueryParamsKey,
  AccountStatus,
  AccountType,
  getFullNameFromPerson,
  OrganizationCombinedType,
  OrganizationKey,
  OrganizationListQueryParamsKey,
  Patient,
  PatientListQueryParamsKey,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { TextField } from "components/generic/TextField/TextField";
import { ReferenceResourceField } from "components/generic/ReferenceResourceField/ReferenceResourceField";
import { List } from "components/generic/List/List";
import { Datagrid } from "components/generic/Datagrid/Datagrid";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useListExportedFileName } from "components/hook/useExportedFileName";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";
import { accountJsonToModel } from "components/pages/NexupAdmin/resources/account/AccountForm";
import { patientOptionText } from "components/generic/ReferenceResourceInput/optionText";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";

const tk = tkCP.adminPage[ResourceType.Account];

export const AccountList: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const exportedFileName = useListExportedFileName(t(tk.title));

  return (
    <List
      resource={ResourceType.Account}
      exportedFileName={exportedFileName}
      jsonToModel={accountJsonToModel}
      extraFilters={[
        workspaceOrganizationId === undefined ? (
          <OrganizationReferenceInput
            label={t(tk.filters.organization)}
            source={AccountListQueryParamsKey.organization}
            alwaysOn
            type={OrganizationCombinedType.ProviderAndGroup}
            extraFilters={{
              [OrganizationListQueryParamsKey.active]: true
            }}
          />
        ) : undefined,
        <OrganizationReferenceInput
          label={t(tk.filters.payer)}
          source={AccountListQueryParamsKey.payer}
          alwaysOn
          type={OrganizationCombinedType.Payer}
          extraFilters={{
            [OrganizationListQueryParamsKey.active]: true
          }}
        />,
        <ReferenceResourceInput<Patient>
          resourceType={ResourceType.Patient}
          label={t(tk.filters.patient)}
          source={AccountListQueryParamsKey.patient}
          optionTextKey={patientOptionText}
          alwaysOn
          extraFilters={{
            [PatientListQueryParamsKey.organization]: workspaceOrganizationId
          }}
        />,
        <EnumSelectorInput
          source={AccountListQueryParamsKey.status}
          label={t(tk.filters.status)}
          enumValue={AccountStatus}
          alwaysOn
          resettable
        />,
        <EnumSelectorInput
          source={AccountListQueryParamsKey.type}
          label={t(tk.filters.type)}
          enumValue={AccountType}
          alwaysOn
          resettable
        />
      ]}
    >
      <Datagrid>
        <TextField source={AccountKey.id} label={t(tk.fields.id)} />
        <ReferenceResourceField
          resourceType={ResourceType.Organization}
          label={t(tk.fields.organization)}
          source={AccountKey.organization}
          optionTextKey={OrganizationKey.name}
        />
        <ReferenceResourceField
          resourceType={ResourceType.Organization}
          label={t(tk.fields.payer)}
          source={AccountKey.payer}
          optionTextKey={OrganizationKey.name}
        />
        <ReferenceResourceField<Patient>
          resourceType={ResourceType.Patient}
          label={t(tk.fields.patient)}
          source={AccountKey.patient}
          optionTextKey={getFullNameFromPerson}
        />
        <TextField source={AccountKey.status} label={t(tk.fields.status)} />
        <TextField source={AccountKey.type} label={t(tk.fields.type)} />
      </Datagrid>
    </List>
  );
};
