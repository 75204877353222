import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  EncounterClass,
  getModelReferenceId,
  Hospitalization,
  Identifier,
  KnownCodeSystemSystem,
  LocationType,
  ResourceType,
  sanitizeIdentifierList
} from "@laba/nexup-api";
import {
  getDefaultResourceTransform,
  ResourceForm
} from "components/generic/ResourceForm/ResourceForm";
import { tkCP } from "translation/i18n";
import { HospitalizationMainContent } from "components/pages/NexupAdmin/resources/hospitalization/HospitalizationMainContent";
import {
  getDefaultJsonToModel,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";
import { HospitalizationPractitionerContent } from "components/pages/NexupAdmin/resources/hospitalization/HospitalizationPractitionerContent";
import { HospitalizationTagsContent } from "components/pages/NexupAdmin/resources/hospitalization/HospitalizationTagsContent";
import { HospitalizationDiagnosisContent } from "components/pages/NexupAdmin/resources/hospitalization/HospitalizationDiagnosisContent";
import { isEqual } from "lodash-es";

export const hospitalizationTransform =
  getDefaultResourceTransform<Hospitalization>(
    ResourceType.Hospitalization,
    draft => {
      draft.class = EncounterClass.Inpatient;
      draft.practitionerTeam?.forEach(draftElement => {
        if (draftElement?.role && draftElement.role.system === undefined)
          draftElement.role.system =
            KnownCodeSystemSystem.EncounterPractitionerType;
      });
      draft.identifier = sanitizeIdentifierList(
        draft.identifier as Identifier[]
      );
      // TODO HIS-16752 borrar seteo de currentBed cuando back borre la logica de su lado
      const selectedBedId = getModelReferenceId(
        draft.location?.find(l => l?.physicalType === LocationType.Bed)
          ?.location
      );
      const currentBedId = getModelReferenceId(draft.currentBed?.bed);
      if (!isEqual(selectedBedId, currentBedId)) {
        draft.currentBed = {
          bed: selectedBedId
        };
      }
    }
  );
export const hospitalizationJsonToModel: JsonToModel<Hospitalization> =
  getDefaultJsonToModel(draft => {
    draft.patient = undefined;
    draft.organization = undefined;
    draft.practitionerTeam?.forEach(draftValue => {
      if (draftValue) {
        draftValue.practitioner = undefined;
      }
    });
    draft.healthcareService = undefined;
    draft.appointment = undefined;
    if (draft.diagnosis) {
      draft.diagnosis = undefined;
    }

    if (draft.currentBed) {
      draft.currentBed.bed = undefined;
    }
  });

export const tk = tkCP.adminPage[ResourceType.Hospitalization];

export const HospitalizationForm: FC = () => {
  const { t } = useI18n();
  return (
    <ResourceForm
      tabsConfig={[
        {
          label: t(tk.tabs.hospitalization),
          content: <HospitalizationMainContent />
        },
        {
          label: t(tk.tabs.practitioner),
          content: <HospitalizationPractitionerContent />
        },
        {
          label: t(tk.tabs.tags),
          content: <HospitalizationTagsContent />
        },
        {
          label: t(tk.tabs.diagnosis),
          content: <HospitalizationDiagnosisContent />
        }
      ]}
    />
  );
};
