import React from "react";
import { FC } from "@laba/react-common";
import { NexupColor } from "model/nexupColor";
import { OdontogramIconProps } from "components/icons/OdontogramIcons/utils";

export const ImplantIcon: FC<OdontogramIconProps> = props => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill={props.iconColor ?? NexupColor.Black}
    >
      <path d="M5.6665 6.10156V18.8984H3.46045V6.10156H5.6665Z" />
      <path d="M9.30518 6.10156H11.2739L14.9741 15.9717L18.6655 6.10156H20.6343L15.7476 18.8984H14.1831L9.30518 6.10156ZM8.40869 6.10156H10.2808L10.606 14.6445V18.8984H8.40869V6.10156ZM19.6587 6.10156H21.5396V18.8984H19.3335V14.6445L19.6587 6.10156Z" />
    </svg>
  );
};
