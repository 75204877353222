import { joinText, notUndefined, Optional } from "@laba/ts-common";
import { head, isEmpty, sortBy } from "lodash-es";
import { produce } from "immer";
import { ResourceType } from "model/primitives/resourceModel";
import {
  Identifier,
  IdentifierProperty,
  IdentifierSystem,
  KnownIdentifierSystem,
  KnownIdentifierSystemType,
  LegalIdentifierImportanceOrder
} from "model/primitives/identifier";
import { Attachment } from "./attachment";
import { Address } from "./address";

export const getIdentifierBySystem = (
  system: IdentifierSystem,
  identifiers?: Identifier[]
): Optional<Identifier> =>
  identifiers?.find(identifier => identifier.system === system);

export const getIdentifierValueBySystem = (
  system: IdentifierSystem,
  identifiers?: Identifier[]
): Optional<string> => getIdentifierBySystem(system, identifiers)?.value;

export const getIdentifierEnumKey = (
  identifier?: KnownIdentifierSystem
): Optional<KnownIdentifierSystemType> => {
  if (identifier === undefined) return undefined;
  const keyIndex = Object.values(KnownIdentifierSystem).indexOf(identifier);
  const res = Object.keys(KnownIdentifierSystem)[
    keyIndex
  ] as KnownIdentifierSystemType;
  return res;
};

const getKnownLegalIdentifiers = (): KnownIdentifierSystem[] => {
  const legalIdentifierTypeList = Object.entries(
    LegalIdentifierImportanceOrder
  ).filter(([_k, v]) => v !== undefined);

  return sortBy(legalIdentifierTypeList, ([_k, v]) => v).map(
    ([k, _v]) => k as KnownIdentifierSystem
  );
};

export const getLegalIdentifierByImportance = (
  identifiers?: Identifier[]
): Optional<Identifier> => {
  const sortedIdentifierTypes = getKnownLegalIdentifiers();

  const identifierList = sortedIdentifierTypes
    .map(type => getIdentifierBySystem(type, identifiers))
    .filter(notUndefined);

  return head(identifierList);
};

export const getMedicalIdentifierByImportance = (
  identifiers?: Identifier[]
): Optional<Identifier> => {
  return (
    identifiers?.find(
      identifier => identifier.system === KnownIdentifierSystem.MedicalRecordId
    ) ?? getLegalIdentifierByImportance(identifiers)
  );
};

export const createBaseIdentifier = (
  system?: IdentifierSystem,
  value?: string
): Identifier => {
  return {
    resourceType: ResourceType.Identifier,
    system: system ?? "",
    value
  };
};

export const isIdentifierPropertyEmpty = (
  property?: IdentifierProperty
): boolean => {
  return (
    isEmpty(property?.type) ||
    (isEmpty(property?.valueCode) &&
      isEmpty(property?.valueBoolean) &&
      isEmpty(property?.valueDecimal) &&
      isEmpty(property?.valueString))
  );
};

export const isIdentifierEmpty = (identifier?: Identifier): boolean => {
  return isEmpty(identifier?.system) || isEmpty(identifier?.value);
};

export const identifierIsNotEmpty = (identifier?: Identifier): boolean => {
  return !isEmpty(identifier?.value) && !isEmpty(identifier?.system);
};

export const removeEmptyIdentifierProperty = (
  identifier: Identifier
): Identifier => {
  return produce(identifier, draft => {
    draft.property = draft.property?.filter(p => !isIdentifierPropertyEmpty(p));
  });
};

export const removeEmptyIdentifiers = (
  identifierList?: Identifier[]
): Identifier[] => identifierList?.filter(identifierIsNotEmpty) ?? [];

export const sanitizeIdentifierList = (
  identifierList?: Identifier[]
): Identifier[] => {
  return removeEmptyIdentifiers(identifierList).map(i =>
    removeEmptyIdentifierProperty(i)
  );
};

export const getIdentifierFirstNotEmptyFile = (
  identifier?: Identifier
): Optional<Attachment> => identifier?.files?.find(file => !isEmpty(file.url));

export const getAddressFullText = (address?: Address): Optional<string> => {
  if (isEmpty(address)) return;

  const streetText = joinText(
    [
      address.street,
      address.streetNumber,
      address.directions ? `(${address.directions})` : undefined
    ],
    " "
  );

  return joinText([streetText, address.city, address.state], ", ");
};
