import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  Appointment,
  AppointmentKey,
  LogicType,
  NotificationActionCode,
  NotificationLastVersionParamsKey,
  NotificationStatus,
  Patient,
  Practitioner,
  ReceiverType,
  ResourceType
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { ExternalResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { DateTimeInput } from "components/generic/DateTimeInput/DateTimeInput";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import {
  appointmentOptionText,
  patientOptionText,
  practitionerOptionText
} from "components/generic/ReferenceResourceInput/optionText";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

export const tk = tkCP.adminPage[ExternalResourceType.Notification];
export const NotificationMainContent: FC = () => {
  const { t } = useI18n();

  return (
    <>
      <TextInput
        disabled
        source={NotificationLastVersionParamsKey.id}
        label={t(tk.fields.id)}
      />
      <EnumSelectorInput
        source={NotificationLastVersionParamsKey.status}
        label={t(tk.fields.status)}
        enumValue={NotificationStatus}
      />
      <EnumSelectorInput
        source={NotificationLastVersionParamsKey.notificationActionCode}
        label={t(tk.fields.notificationActionCode)}
        enumValue={NotificationActionCode}
      />
      <TextInput
        source={NotificationLastVersionParamsKey.senderId}
        label={t(tk.fields.senderId)}
      />
      <TextInput
        source={NotificationLastVersionParamsKey.receiver}
        label={t(tk.fields.receiver)}
      />
      <EnumSelectorInput
        source={NotificationLastVersionParamsKey.receiverType}
        label={t(tk.fields.receiverType)}
        enumValue={ReceiverType}
      />
      <TextInput
        source={NotificationLastVersionParamsKey.messageTitle}
        label={t(tk.fields.messageTitle)}
        multiline
      />
      <TextInput
        source={NotificationLastVersionParamsKey.messageBody}
        label={t(tk.fields.messageBody)}
        multiline
      />
      <TextInput
        source={NotificationLastVersionParamsKey.sessionInitMessage}
        label={t(tk.fields.sessionInitMessage)}
        multiline
      />
      <EnumSelectorInput
        source={NotificationLastVersionParamsKey.logicType}
        label={t(tk.fields.logicType)}
        enumValue={LogicType}
      />
      <DateTimeInput
        source={NotificationLastVersionParamsKey.scheduledDate}
        label={t(tk.fields.scheduledDate)}
      />
      <DateTimeInput
        source={NotificationLastVersionParamsKey.creationDate}
        label={t(tk.fields.creationDate)}
        disabled
      />
      <DateTimeInput
        source={NotificationLastVersionParamsKey.lastUpdatedDate}
        label={t(tk.fields.lastUpdatedDate)}
        disabled
      />
      <ArrayInput
        source={NotificationLastVersionParamsKey.mediaUrl}
        label={t(tk.fields.mediaUrl)}
      >
        <TextInput source="" label={t(tk.fields.mediaUrlItem)} />
      </ArrayInput>
      <OrganizationReferenceInput
        source={NotificationLastVersionParamsKey.organizationId}
        label={t(tk.fields.organizationId)}
      />
      <ReferenceResourceInput<Practitioner>
        source={NotificationLastVersionParamsKey.practitionerId}
        label={t(tk.fields.practitionerId)}
        resourceType={ResourceType.Practitioner}
        optionTextKey={practitionerOptionText}
      />
      <ReferenceResourceInput<Patient>
        source={NotificationLastVersionParamsKey.patientId}
        label={t(tk.fields.patientId)}
        resourceType={ResourceType.Patient}
        optionTextKey={patientOptionText}
      />
      <ReferenceResourceInput<Appointment>
        source={NotificationLastVersionParamsKey.appointmentId}
        label={t(tk.fields.appointmentId)}
        resourceType={ResourceType.Appointment}
        optionTextKey={appointmentOptionText}
        searchFilterKey={AppointmentKey.id}
      />
    </>
  );
};
