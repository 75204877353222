import {
  StyleVariant,
  createStyle,
  flexColumnMixin,
  getClassName,
  marginMixin,
  paddingMixin
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { getThemeStyleColor, getThemeStyleState } from "model/themeUtils";

export interface AccordionStylesProps {
  hasBackgroundColor?: boolean;
  withoutPadding: boolean;
  fullWidth?: boolean;
}

interface AccordionClassNames {
  accordionRoot: string;
  detailsRoot: string;
  expanded: string;
  disabled: string;
}

const useAccordionStaticStyle = createStyle(
  (_theme: Theme) => ({
    accordionRoot: {
      "&:before": {
        display: "none"
      }
    },
    detailsRootStatic: {
      ...flexColumnMixin({})
    },
    expanded: {
      "&$expanded": {
        ...marginMixin({ topBottom: 0 })
      }
    },
    fullWidth: {
      width: "100%"
    }
  }),
  "AccordionStatic"
);

const useAccordionDynamicStyle = createStyle(
  (theme: Theme) => ({
    detailsRootDynamic: (props: AccordionStylesProps) => ({
      ...paddingMixin({
        topBottom: !props.withoutPadding ? 8 : 0,
        leftRight: !props.withoutPadding ? 16 : 0
      }),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        props.hasBackgroundColor
          ? ThemeStyleColorVariant.Background
          : ThemeStyleColorVariant.Contrast
      )
    }),
    disabled: (props: AccordionStylesProps) => ({
      "&$disabled": {
        color: getThemeStyleState(theme, StyleVariant.Primary).disabled,
        backgroundColor: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          props.hasBackgroundColor
            ? ThemeStyleColorVariant.Background
            : ThemeStyleColorVariant.Contrast
        )
      }
    })
  }),
  "AccordionDynamic"
);

export const useAccordionStyle = (
  props: AccordionStylesProps
): AccordionClassNames => {
  const { fullWidth } = props;
  const staticClasses = useAccordionStaticStyle();
  const dynamicClasses = useAccordionDynamicStyle(props);

  return {
    accordionRoot: getClassName(
      staticClasses.accordionRoot,
      fullWidth ? staticClasses.fullWidth : undefined
    ),
    detailsRoot: getClassName(
      staticClasses.detailsRootStatic,
      dynamicClasses.detailsRootDynamic
    ),
    expanded: staticClasses.expanded,
    disabled: dynamicClasses.disabled
  };
};
