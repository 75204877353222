import {
  getThemeStyleColor,
  getThemeStyleState,
  themeElevationMixin,
  themeTextMixin
} from "model/themeUtils";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import {
  FlexAlignItems,
  createStyle,
  flexColumnMixin,
  flexItemMixin,
  flexRowMixin,
  hoverMixin,
  paddingMixin,
  pxToRem
} from "@laba/react-common";

import {
  ElevationVariant,
  StyleVariant,
  TextVariant
} from "model/themeVariant";

interface MenuStyleProps {
  width?: number;
  allowOverflow?: boolean;
}

interface MenuItemTextStyleProps {
  multiline: boolean;
}

export const usePaperStyle = createStyle(
  (theme: Theme) => ({
    root: (props: MenuStyleProps) => ({
      ...flexColumnMixin({ gap: 8 }),
      ...(props.allowOverflow
        ? { maxWidth: "100%" }
        : props.width !== undefined
        ? { width: pxToRem(props.width) }
        : { maxWidth: pxToRem(280), minWidth: pxToRem(112) }),
      ...themeElevationMixin(theme, ElevationVariant.Elevation24)
    })
  }),
  "Paper"
);

export enum MenuItemHeight {
  Small = "Small",
  Normal = "Normal"
}

interface MenuItemProps {
  height?: MenuItemHeight;
  fullWidth?: boolean;
  withoutPadding?: boolean;
}

export const useMenuItemStyle = createStyle(
  (theme: Theme) => ({
    root: (props: MenuItemProps) => ({
      ...hoverMixin({
        backgroundColor: getThemeStyleState(theme, StyleVariant.Primary).hover
      }),
      ...flexRowMixin({ alignItems: FlexAlignItems.Center, gap: 4 }),
      ...paddingMixin({
        topBottom: props.withoutPadding ? 0 : 8,
        leftRight: props.withoutPadding ? 0 : 16
      }),
      width: props.fullWidth ? "100%" : "inherit",
      minHeight:
        props.height === MenuItemHeight.Small ? pxToRem(24) : pxToRem(40),
      whiteSpace: "normal"
    })
  }),
  "MenuItem"
);

export const usePopupMenuItemStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...hoverMixin({
        backgroundColor: getThemeStyleState(theme, StyleVariant.Primary).hover
      }),
      ...paddingMixin({ topBottom: pxToRem(15) }),
      ...flexRowMixin({ alignItems: FlexAlignItems.Center, gap: 4 }),
      height: pxToRem(48),
      width: "100%"
    }
  }),
  "MenuItem"
);

export const useMenuItemTextStyle = createStyle(
  (theme: Theme) => ({
    text: (props: MenuItemTextStyleProps) => ({
      ...themeTextMixin(
        theme,
        TextVariant.Body2,
        props.multiline ? undefined : 1
      ),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      ),
      ...flexItemMixin({ grow: 1, basis: "0" })
    }),
    selectedText: (props: MenuItemTextStyleProps) => ({
      ...themeTextMixin(
        theme,
        TextVariant.Subtitle2,
        props.multiline ? undefined : 1
      ),
      color: getThemeStyleColor(theme, StyleVariant.Primary)
    })
  }),
  "MenuItemText"
);
