import { getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { ModelId } from "model/primitives/model/model";

export interface Dashboard {
  title: string;
  url: string;
  icon?: string;
  code?: string;
}

export interface DashboardUrlTemplateData {
  currentOrganization?: ModelId;
  currentOrganizationParent?: ModelId;
  currentLocation?: ModelId;
  currentPractitioner?: ModelId;
  currentUser?: ModelId;
}

export const DashboardKey = getKeyObj<Dashboard>(
  createHydratedMock<Dashboard>()
);
