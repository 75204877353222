import { ApiDate, getKeyObj } from "@laba/ts-common";
import { Attachment } from "model/primitives/attachment/attachment";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { Quantity } from "model/primitives/quantity";
import { AllergyIntolerance } from "model/resource/medical/allergyIntolerance/allergyIntolerance";
import { Condition } from "model/resource/medical/condition/condition";
import { Encounter } from "model/resource/entities/encounter/encounter";
import { PatientMeasure } from "model/resource/medical/observation/patientMeasure";
import { MedicalDevice } from "model/resource/prescription/medicalDevice";
import { Medication } from "model/resource/prescription/medication/medication";
import { ProcedureReport } from "model/resource/procedures/procedureReport/procedureReport";
import { createHydratedMock } from "ts-auto-mock";
import { ConsumptionGroup } from "model/resource/finance/consumption";
import { Odontogram } from "model/resource/medical/odontogram/odontogram";

export interface QuestionnaireResponseAnswer {
  valueString?: string;
  valueDecimal?: number;
  valueBoolean?: boolean;
  valueDate?: ApiDate;
  valueDateTime?: ApiDate;
  valueQuantity?: Quantity;
  valueAttachment?: Attachment;
  valueReferenceAllergy?: ModelReference<AllergyIntolerance>;
  valueReferenceProblem?: ModelReference<Condition>;
  valueReferenceMeasure?: ModelReference<PatientMeasure>;
  valueReferenceEncounter?: ModelReference<Encounter>;
  valueReferenceProcedureReport?: ModelReference<ProcedureReport>;
  valueReferenceDevice?: ModelReference<MedicalDevice>;
  valueReferenceMedication?: ModelReference<Medication>;
  valueReferenceConsumptionGroup?: ModelReference<ConsumptionGroup>;
  valueCoding?: string;
  valueReferenceOdontogram?: ModelReference<Odontogram>;
}

export const QuestionnaireResponseAnswerValueKey =
  getKeyObj<QuestionnaireResponseAnswer>(
    createHydratedMock<QuestionnaireResponseAnswer>()
  );
