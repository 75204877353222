import {
  FC,
  Form,
  FormErrorData,
  FormValidator,
  useI18n
} from "@laba/react-common";
import React, { useCallback, useState } from "react";
import { DefaultButton, Popup } from "@laba/nexup-components";
import { tkCP } from "translation/i18n";
import { useAppDispatch } from "store/store";
import { isEmpty } from "lodash-es";
import { onVademecumImport } from "store/dashboard/event";
import { ImportVademecumRequestBody } from "@laba/nexup-api";
import { VademecumImportInputFormContent } from "components/pages/Dashboard/VademecumImport/VademecumImportFormContent";

export interface VademecumImportInputProps {
  onImportFinished?: (success: boolean) => void;
}

const tk = tkCP.adminPage.dashboard.importVademecum;
const formId = "importVademecumForm";
const initialValues: ImportVademecumRequestBody = {};
const formValidation: FormValidator<ImportVademecumRequestBody> = (
  t,
  formData
) => {
  const errors: FormErrorData<ImportVademecumRequestBody> = {};

  if (formData.organization == null) {
    errors.organization = t(tk.validation.requiredField);
  }
  if (formData.file == null) {
    errors.file = t(tk.validation.requiredField);
  }
  if (formData.importMode == null) {
    errors.importMode = t(tk.validation.requiredField);
  }
  if (formData.fileType == null) {
    errors.fileType = t(tk.validation.requiredField);
  }

  return isEmpty(errors) ? undefined : errors;
};
export const VademecumImportInput: FC<VademecumImportInputProps> = ({
  onImportFinished
}) => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const [showVademecumImport, setShowVademecumImport] = useState(false);
  const [loading, setLoading] = useState(false);
  const onSubmit = useCallback(
    async (requestData: ImportVademecumRequestBody) => {
      setLoading(true);
      const result = await dispatch(onVademecumImport(requestData));
      setLoading(false);
      setShowVademecumImport(false);
      onImportFinished?.(result);
    },
    [setLoading, setShowVademecumImport, onImportFinished, dispatch]
  );
  const validate = useCallback(
    (data: ImportVademecumRequestBody) => formValidation(t, data),
    [t]
  );
  const onClick = useCallback(
    () => setShowVademecumImport(true),
    [setShowVademecumImport]
  );
  const onClose = useCallback(
    () => setShowVademecumImport(false),
    [setShowVademecumImport]
  );
  return (
    <>
      <DefaultButton
        title={t(tk.importVademecumButton)}
        text={t(tk.importVademecumButton)}
        onClick={onClick}
      />
      {showVademecumImport && (
        <Popup
          open={showVademecumImport}
          closableHeader
          headerTitle={t(tk.title)}
          primaryButtonText={t(tk.sendImport)}
          disablePrimaryButton={false}
          formId={formId}
          loading={loading}
          onClose={onClose}
          disableBackdropClick
        >
          <Form<ImportVademecumRequestBody>
            id={formId}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
          >
            <VademecumImportInputFormContent />
          </Form>
        </Popup>
      )}
    </>
  );
};
