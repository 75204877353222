import { FC, useField, useI18n } from "@laba/react-common";
import { FinnegansFeeIntegrationRequestBodyParamsKey } from "@laba/nexup-api";
import { Grid, Typography } from "@mui/material";
import React from "react";
import { tkCP } from "translation/i18n";
import { DateInput, TextInput, UnitInput } from "@laba/nexup-components";
import {
  ApiDate,
  dateTimeFromApiDateOrUndefined,
  dateTimeToApiDateOrUndefined
} from "@laba/ts-common";

const tk = tkCP.adminPage.dashboard.finnegansFeeIntegration.fields;

export const FinnegansFeeIntegrationFormContent: FC = () => {
  const { t } = useI18n();

  const {
    input: {
      value: startDate,
      onChange: onChangeStartDate,
      onBlur: startDateOnBlur
    },
    meta: { error: startDateError, touched: startDateTouched }
  } = useField<ApiDate, string>(
    FinnegansFeeIntegrationRequestBodyParamsKey.startDate
  );

  const {
    input: { value: endDate, onChange: onChangeEndDate, onBlur: endDateOnBlur },
    meta: { error: endDateError, touched: endDateTouched }
  } = useField<ApiDate, string>(
    FinnegansFeeIntegrationRequestBodyParamsKey.endDate
  );

  const {
    input: {
      value: windowSize,
      onChange: onChangeWindowSize,
      onBlur: windowSizeOnBlur
    },
    meta: { error: windowSizeError, touched: windowSizeTouched }
  } = useField<number, string>(
    FinnegansFeeIntegrationRequestBodyParamsKey.windowSize
  );

  const {
    input: {
      value: tableName,
      onChange: onChangeTableName,
      onBlur: tableNameOnBlur
    }
  } = useField<string, string>(
    FinnegansFeeIntegrationRequestBodyParamsKey.tableName
  );

  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      <Grid item sm={6}>
        <Typography title={t(tk.startDateField.title)}>
          {t(tk.startDateField.title)}
        </Typography>
        <DateInput
          value={dateTimeFromApiDateOrUndefined(startDate)}
          onChange={d => onChangeStartDate(dateTimeToApiDateOrUndefined(d))}
          onBlur={startDateOnBlur}
          errorText={startDateError}
          showError={startDateTouched ?? true}
          placeholder={t(tk.startDateField.placeholder)}
          fullWidth
          clearable
        />
      </Grid>
      <Grid item sm={6}>
        <Typography title={t(tk.endDateField.title)}>
          {t(tk.endDateField.title)}
        </Typography>
        <DateInput
          value={dateTimeFromApiDateOrUndefined(endDate)}
          onChange={d => onChangeEndDate(dateTimeToApiDateOrUndefined(d))}
          onBlur={endDateOnBlur}
          errorText={endDateError}
          showError={endDateTouched ?? true}
          placeholder={t(tk.endDateField.placeholder)}
          fullWidth
          clearable
        />
      </Grid>

      <Grid item sm={6}>
        <Typography title={t(tk.windowField.title)}>
          {t(tk.windowField.title)}
        </Typography>
        <UnitInput
          quantityValue={windowSize}
          onChangeQuantity={onChangeWindowSize}
          onBlur={windowSizeOnBlur}
          errorText={windowSizeError}
          showError={windowSizeTouched ?? true}
          placeholder={t(tk.windowField.placeholder)}
          fullWidth
          withUnitSelector={false}
        />
      </Grid>

      <Grid item sm={6}>
        <Typography title={t(tk.tableNameField.title)}>
          {t(tk.tableNameField.title)}
        </Typography>
        <TextInput
          value={tableName}
          onChange={onChangeTableName}
          onBlur={tableNameOnBlur}
          placeholder={t(tk.tableNameField.placeholder)}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
