import {
  getModelReferenceId,
  ModelId,
  ModelReference,
  Organization,
  PractitionerKey
} from "@laba/nexup-api";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { head } from "lodash-es";
import { Optional } from "@laba/ts-common";

export const useGetFirstPractitionerOrganizationId = (): Optional<ModelId> => {
  const practitionerOrganizationReferenceList = useWatchResourceForm<
    ModelReference<Organization>[]
  >(PractitionerKey.organization);
  const practitionerOrganizationIdList =
    practitionerOrganizationReferenceList?.map(getModelReferenceId);
  return head(practitionerOrganizationIdList);
};
