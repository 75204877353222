import {
  FC,
  getClassName,
  IconC,
  OnBlurEvent,
  useEffectSelective,
  withMemo
} from "@laba/react-common";
import React, { useCallback, useState } from "react";
import { StyleVariant, TextVariant } from "model/themeVariant";
import { DateTime, Optional, setDateToMidnight } from "@laba/ts-common";
import { DateInput } from "components/inputs/DateInput/DateInput";
import { useDateInputStyles } from "components/inputs/DateTimeInput/DateTimeInputStyle";
import {
  dateTimeToTimePickerHour,
  TimePicker,
  TimePickerHour,
  timePickerHourToApiHour
} from "components/inputs/TimePicker";

export interface DateTimeInputProps {
  style?: StyleVariant;
  className?: string;
  containerClassName?: string;
  value?: DateTime;
  onChange: (d?: DateTime) => void;
  disabledDate?: boolean;
  disabledTime?: boolean;
  labelDate?: string;
  labelTime?: string;
  titleDate?: string;
  titleTime?: string;
  errorText?: string;
  helperText?: string;
  showError?: boolean;
  showHelperOrErrorText?: boolean;
  fullWidth?: boolean;
  row?: boolean;
  TimeInputEndIcon?: IconC;
  minDate?: DateTime;
  maxDate?: DateTime;
  onBlur?: OnBlurEvent<HTMLInputElement | HTMLTextAreaElement>;
  datePlaceholder?: string;
  timePlaceholder?: string;
  clearable?: boolean;
  clearText?: string;
  stepMinutes?: number;
  shouldDisableDate?: (date?: DateTime) => boolean;
  titleVariant?: TextVariant;
}

export const DateTimeInputInt: FC<DateTimeInputProps> = ({
  className,
  containerClassName,
  onChange,
  value,
  errorText,
  helperText,
  labelDate,
  titleDate,
  titleTime,
  disabledDate,
  disabledTime,
  TimeInputEndIcon,
  minDate,
  maxDate,
  onBlur,
  showHelperOrErrorText = true,
  showError = true,
  row = true,
  fullWidth = true,
  style = StyleVariant.Primary,
  datePlaceholder,
  timePlaceholder,
  clearable = false,
  clearText,
  shouldDisableDate,
  stepMinutes,
  titleVariant = TextVariant.Subtitle2
}) => {
  const [localDate, setLocalDate] = useState(value);
  const valueTime = value ? dateTimeToTimePickerHour(value) : undefined;
  const [localTime, setLocalTime] = useState(valueTime);
  const [lastUpdatedValue, setLastUpdatedValue] = useState(value);

  const hasError = showError && Boolean(errorText);
  const showableHelperText = (hasError ? errorText : helperText) || "";
  const classes = useDateInputStyles({
    hasError,
    fullWidth,
    row,
    titleVariant
  });

  useEffectSelective(
    () => {
      let newDate: Optional<DateTime>;
      if (localDate?.isValid && localTime) {
        const apiHour = timePickerHourToApiHour(localTime);
        newDate = DateTime.fromApiHourOrUndefined(apiHour, localDate);
      } else if (localDate === undefined && localTime === undefined) {
        newDate = undefined;
      } else if (!localDate?.isValid) {
        newDate = DateTime.invalid("Invalid");
      }
      // only call onChange when user interacts with input
      const isNotChanged =
        (value === undefined && newDate === undefined) ||
        (value && newDate?.equals(value));
      if (!isNotChanged) onChange(newDate);
      setLastUpdatedValue(newDate);
    },
    [localTime, localDate],
    [onChange]
  );

  useEffectSelective(
    () => {
      if (
        lastUpdatedValue === value ||
        (!lastUpdatedValue?.isValid && !value?.isValid) ||
        lastUpdatedValue?.equals(value ?? DateTime.local())
      )
        return;

      setLocalDate(value);
      setLocalTime(value);
    },
    [value],
    [lastUpdatedValue]
  );

  const wrappedDateInputOnChange = useCallback(
    (d?: DateTime) => {
      if (clearable && d === undefined) {
        setLocalTime(d);
      }
      setLocalDate(d);
      !localTime &&
        d &&
        setLocalTime(dateTimeToTimePickerHour(setDateToMidnight(d)));
    },
    [localTime, clearable]
  );

  const wrappedTimeInputOnChange = (d?: TimePickerHour) => {
    setLocalTime(d);
  };

  return (
    <div className={getClassName(classes.root, className)}>
      <div className={getClassName(classes.container, containerClassName)}>
        <div className={classes.inputContainer}>
          {titleDate && <p className={classes.title}>{titleDate}</p>}
          <DateInput
            label={labelDate}
            disabled={disabledDate}
            style={style}
            onChange={wrappedDateInputOnChange}
            value={localDate}
            fullWidth
            showHelperOrErrorText={false}
            showError={hasError}
            errorText=" "
            maxDate={maxDate}
            minDate={minDate}
            onBlur={onBlur}
            placeholder={datePlaceholder}
            clearable={clearable}
            clearText={clearText}
            shouldDisableDate={shouldDisableDate}
          />
        </div>
        <div className={classes.inputContainer}>
          {titleTime && <p className={classes.title}>{titleTime}</p>}
          <TimePicker
            value={localTime}
            onChange={wrappedTimeInputOnChange}
            placeholder={timePlaceholder}
            disabled={disabledTime}
            fullWidth
            showError={hasError}
            errorText={hasError ? " " : undefined}
            EndIcon={TimeInputEndIcon}
            onBlur={onBlur}
            clearText={clearText}
            stepMinutes={stepMinutes}
            showHelperOrErrorText={false}
          />
        </div>
      </div>
      {showHelperOrErrorText && (
        <p className={classes.errorText}>{showableHelperText}</p>
      )}
    </div>
  );
};

export const DateTimeInput = withMemo(DateTimeInputInt);
