import React, { useState } from "react";
import { FC, getClassName, IconC } from "@laba/react-common";
import { Noop } from "@laba/ts-common";
import { ButtonBase } from "components/buttons/ButtonBase/ButtonBase";
import { useArrowTabButtonStyles } from "components/tabs/HorizontalTabContainer/ArrowTabButton/useArrowTabButtonStyles";

interface ArrowTabButtonProps {
  Icon: IconC;
  disabled?: boolean;
  className?: string;
  onClick?: Noop;
  onMouseDown?: Noop;
  onMouseUp?: Noop;
}

export const ArrowTabButton: FC<ArrowTabButtonProps> = ({
  Icon,
  onClick,
  onMouseDown,
  onMouseUp,
  className
}) => {
  const [isActive, setIsActive] = useState(false);
  const classes = useArrowTabButtonStyles({ isActive });

  const onMouseDownWrapper = () => {
    setIsActive(true);
    onMouseDown?.();
  };
  const onMouseUpWrapper = () => {
    setIsActive(false);
    onMouseUp?.();
  };
  return (
    <ButtonBase
      className={getClassName(classes.root, className)}
      onClick={onClick}
      onMouseDown={onMouseDownWrapper}
      onMouseUp={onMouseUpWrapper}
      disableRipple
    >
      <Icon className={classes.icon} fontSize="medium" />
    </ButtonBase>
  );
};
