import React from "react";
import {
  FC,
  IconC,
  OnClickEvent,
  stopPropagationWrapper,
  withMemo
} from "@laba/react-common";
import { SizeVariant, StyleVariant } from "model/themeVariant";
import { TypeInvertedVariant } from "components/utils/getGenericButtonColors";
import { IconButtonType } from "components/buttons/IconButton/IconButton";
import { useBaseIconButtonStylesClasses } from "components/buttons/BaseIconButton/useBaseIconButtonStyles";
import { ThemeProvider } from "@material-ui/core/styles";
import { useMuiTheme } from "model/useMuiTheme";
import { getMapValueBuilder } from "@laba/ts-common";
import { IconButton } from "@material-ui/core";
import {
  BadgedComponent,
  BadgePosition,
  BadgeVariant
} from "components/containers/BadgedComponent/BadgedComponent";

export interface BaseIconButtonProps {
  className?: string;
  iconClassName?: string;
  title?: string;
  Icon: IconC;
  onClick?: OnClickEvent;
  disabled?: boolean;
  size?: SizeVariant;
  type?: TypeInvertedVariant;
  style?: StyleVariant;
  withoutPadding?: boolean;
  iconButtonType?: IconButtonType;
  formId?: string;
  withBadge?: boolean;
  badgeContent?: number;
  badgeVariant?: BadgeVariant;
  badgePosition?: BadgePosition;
}

enum IconSize {
  Small = "small",
  Medium = "medium",
  Large = "large"
}

const BaseIconButtonInt: FC<BaseIconButtonProps> = ({
  className,
  iconClassName,
  Icon,
  onClick,
  title,
  disabled = false,
  style = StyleVariant.Primary,
  type = TypeInvertedVariant.Normal,
  size = SizeVariant.Medium,
  withoutPadding = false,
  iconButtonType = IconButtonType.Button,
  formId,
  withBadge,
  badgeContent,
  badgeVariant,
  badgePosition
}) => {
  const muiTheme = useMuiTheme(style);

  const classes = useBaseIconButtonStylesClasses({
    type,
    size,
    withoutPadding,
    style,
    disabled
  });

  const getIconSize = (buttonSize: SizeVariant): IconSize => {
    const sizes = getMapValueBuilder({
      [SizeVariant.ExtraSmall]: IconSize.Small,
      [SizeVariant.Small]: IconSize.Small,
      [SizeVariant.Medium]: IconSize.Medium,
      [SizeVariant.Large]: IconSize.Medium
    });
    return sizes(buttonSize);
  };

  const Ic = withBadge ? (
    <BadgedComponent
      badgeContent={badgeContent}
      variant={badgeVariant}
      position={badgePosition}
    >
      <Icon className={iconClassName} fontSize={getIconSize(size)} />
    </BadgedComponent>
  ) : (
    <Icon className={iconClassName} fontSize={getIconSize(size)} />
  );

  return (
    <ThemeProvider theme={muiTheme}>
      <IconButton
        classes={classes}
        className={className}
        title={title}
        onClick={stopPropagationWrapper(onClick)}
        disabled={disabled}
        type={iconButtonType}
        form={formId}
      >
        {Ic}
      </IconButton>
    </ThemeProvider>
  );
};

export const BaseIconButton = withMemo(BaseIconButtonInt);
