import { useState } from "react";

interface CommonPopupStateInitialText {
  isOpen: boolean;
  initialText?: string;
}

export const useCommonPopupStateInitialText = (): [
  CommonPopupStateInitialText,
  (text?: string) => void,
  VoidFunction
] => {
  const [popupStateStatus, setPopupStateStatus] =
    useState<CommonPopupStateInitialText>({ isOpen: false });

  const openCommonPopupState = (initialText?: string) =>
    setPopupStateStatus({ isOpen: true, initialText });

  const closeCommonPopupState = () => setPopupStateStatus({ isOpen: false });

  return [popupStateStatus, openCommonPopupState, closeCommonPopupState];
};
