import { createStyle, flexColumnMixin, Theme } from "@laba/react-common";

export const useFileListContainerWithPreviewStyles = createStyle(
  (_theme: Theme) => ({
    root: {
      ...flexColumnMixin({ gap: 8 })
    }
  }),
  "FileListContainerWithPreview"
);
