import {
  borderMixin,
  borderMixinCircular,
  createStyle,
  getClassName,
  hoverMixin,
  pxToRem,
  SizeVariant,
  StyleVariant,
  TypeVariant
} from "@laba/react-common";
import { Theme } from "model/theme";
import { getMapValueBuilder } from "@laba/ts-common";
import { getThemeButtonRadius } from "model/themeUtils";
import {
  ClassNameDynamicGeneratedRecord,
  getBorderColorByType,
  getGenericButtonBackgroundColor,
  getGenericButtonColor,
  getTypeInvertedVariantHoverBackgroundColor,
  TypeInvertedVariant
} from "../../utils/getGenericButtonColors";

export interface IconButtonStyleProps {
  size: SizeVariant;
  style: StyleVariant;
  type: TypeInvertedVariant;
  disabled: boolean;
  circular: boolean;
}

interface IconButtonClassNames {
  root?: string;
  icon?: string;
}

const getSize = getMapValueBuilder({
  [SizeVariant.Large]: pxToRem(44),
  [SizeVariant.Medium]: pxToRem(40),
  [SizeVariant.Small]: pxToRem(36),
  [SizeVariant.ExtraSmall]: pxToRem(24)
});

const getBackgroundColorByType = (
  type: TypeInvertedVariant,
  style: StyleVariant,
  disabled: boolean,
  theme: Theme
) => {
  switch (type) {
    case TypeInvertedVariant.Normal:
      return getGenericButtonBackgroundColor(theme, {
        style,
        type: TypeVariant.Contained,
        disabled
      });
    case TypeInvertedVariant.Inverted:
      return getGenericButtonBackgroundColor(theme, {
        style,
        type: TypeVariant.Text,
        disabled
      });
    case TypeInvertedVariant.Outlined:
      return undefined;
    default:
      return undefined;
  }
};

const generatedStyleKeyTemplate = (
  type: TypeInvertedVariant,
  style: StyleVariant,
  disabled: boolean
) => `generatedStyle${type}${style}${disabled ? "Disabled" : "NotDisabled"}`;

const generateDynamicStylesClassNames = (
  theme: Theme
): ClassNameDynamicGeneratedRecord => {
  const classNameObject: ClassNameDynamicGeneratedRecord = {};
  Object.values(TypeInvertedVariant).forEach(typeInvertedVariant => {
    Object.values(StyleVariant).forEach(styleVariant => {
      [true, false].forEach(disabled => {
        const backgroundColorProp = getBackgroundColorByType(
          typeInvertedVariant,
          styleVariant,
          disabled,
          theme
        );
        classNameObject[
          generatedStyleKeyTemplate(typeInvertedVariant, styleVariant, disabled)
        ] = {
          ...borderMixin({
            style:
              typeInvertedVariant === TypeInvertedVariant.Outlined
                ? "solid"
                : undefined,
            color: getBorderColorByType(
              typeInvertedVariant,
              styleVariant,
              disabled,
              theme
            ),
            side: { all: 1 },
            radius: {
              all: getThemeButtonRadius(theme)
            }
          }),
          ...hoverMixin({
            backgroundColor: getTypeInvertedVariantHoverBackgroundColor(
              theme,
              styleVariant,
              typeInvertedVariant
            ),
            "@media (hover: none)": {
              backgroundColor: getGenericButtonBackgroundColor(theme, {
                style: styleVariant,
                typeInvertedVariant,
                type:
                  typeInvertedVariant === TypeInvertedVariant.Normal
                    ? TypeVariant.Contained
                    : TypeVariant.Text,
                disabled
              })
            }
          }),
          ...(backgroundColorProp && { backgroundColor: backgroundColorProp }),
          color: getGenericButtonColor(theme, {
            style: styleVariant,
            type:
              typeInvertedVariant === TypeInvertedVariant.Normal
                ? TypeVariant.Contained
                : TypeVariant.Text,
            disabled
          }),
          "&:disabled": {
            backgroundColor: getGenericButtonBackgroundColor(theme, {
              style: styleVariant,
              type:
                typeInvertedVariant === TypeInvertedVariant.Normal
                  ? TypeVariant.Contained
                  : TypeVariant.Text,
              disabled
            }),
            color: getGenericButtonColor(theme, {
              style: styleVariant,
              type:
                typeInvertedVariant === TypeInvertedVariant.Normal
                  ? TypeVariant.Contained
                  : TypeVariant.Text,
              disabled
            })
          }
        };
      });
    });
  });
  return classNameObject;
};

const generatedSizeStyleKeyTemplate = (size: SizeVariant) =>
  `generatedStyle${size}`;
const generateDynamicSizeStylesClassNames =
  (): ClassNameDynamicGeneratedRecord => {
    const classNameObject: ClassNameDynamicGeneratedRecord = {};
    Object.values(SizeVariant).forEach(sizeVariant => {
      classNameObject[generatedSizeStyleKeyTemplate(sizeVariant)] = {
        height: getSize(sizeVariant),
        width: getSize(sizeVariant)
      };
    });
    return classNameObject;
  };

const useIconButtonStyle = createStyle<Theme, unknown, string>(
  (theme: Theme) => ({
    ...generateDynamicSizeStylesClassNames(),
    ...generateDynamicStylesClassNames(theme)
  }),
  "IconButton"
);

const staticIconButtonStyle = createStyle(
  (_theme: Theme) => ({
    circular: {
      ...borderMixinCircular()
    },
    smallIcon: {
      height: pxToRem(14),
      width: pxToRem(14)
    }
  }),
  "StaticIconButton"
);

export const useIconButtonStyleClasses = (
  props: IconButtonStyleProps
): IconButtonClassNames => {
  const classes = useIconButtonStyle();

  const staticClasses = staticIconButtonStyle();

  return {
    root: getClassName(
      classes[generatedSizeStyleKeyTemplate(props.size)],
      classes[
        generatedStyleKeyTemplate(props.type, props.style, props.disabled)
      ],
      props.circular ? staticClasses.circular : undefined
    ),
    icon:
      props.circular && props.size === SizeVariant.ExtraSmall
        ? staticClasses.smallIcon
        : undefined
  };
};
