import {
  createProduct,
  getModelReferenceId,
  getProductDetail,
  getProductList,
  ProductListQueryParams,
  ProductListQueryParamsKey,
  ResourceType,
  updateProduct
} from "@laba/nexup-api";
import {
  getEnumOrderParam,
  getKeyTargetParam,
  resourceProviderGetter
} from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { workspaceOrganizationSelector } from "store/workspace/selectors";
import { store } from "store/store";

export const productProvider = resourceProviderGetter(
  ResourceType.Product,
  getEnumOrderParam({}),
  getKeyTargetParam<ProductListQueryParams>(ProductListQueryParamsKey),
  getProductList,
  getProductDetail,
  updateProduct,
  createProduct,
  undefined,
  () => ({
    organization: getModelReferenceId(
      workspaceOrganizationSelector(store.getState())
    )
  })
);
