import { ModelId } from "model/primitives/model/model";
import { medicalRequestBasePath } from "api/prescription/medicalRequest/medicalRequest";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { MedicalRequest } from "model/resource/prescription/medicalRequest/medicalRequest";
import { ApiRequestResponse } from "request/types";
import { HttpMethod } from "@laba/ts-common";
import { getFrontApiUrl, request } from "request/nexupRequest";

export enum MedicalRequestAction {
  Renew = "Renew",
  Cancel = "Cancel"
}

const medicalRequestActionUrlSectionMap: Record<MedicalRequestAction, string> =
  {
    [MedicalRequestAction.Renew]: "renew",
    [MedicalRequestAction.Cancel]: "cancel"
  };

export interface MedicalRequestActionBody {
  entries: ModelReference<MedicalRequest>[];
}

export interface MedicalRequestActionResponse {
  entries: MedicalRequest[];
}

export type MedicalRequestUpdateAction = (
  data: MedicalRequestActionBody
) => Promise<ApiRequestResponse<MedicalRequestActionResponse>>;

export const medicalRequestCancel: MedicalRequestUpdateAction = data =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(
      `${medicalRequestBasePath}/${
        medicalRequestActionUrlSectionMap[MedicalRequestAction.Cancel]
      }`
    ),
    data
  });

export const medicalRequestRenew: MedicalRequestUpdateAction = data =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(
      `${medicalRequestBasePath}/${
        medicalRequestActionUrlSectionMap[MedicalRequestAction.Renew]
      }`
    ),
    data
  });

export const restoreMedicalRequest = (
  oldMrId: ModelId,
  newRequest: MedicalRequest
): Promise<ApiRequestResponse<MedicalRequest>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(`${medicalRequestBasePath}/${oldMrId}/restore`),
    data: newRequest
  });
