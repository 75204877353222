export enum ScrollVerticalAlignment {
  Center = "center",
  End = "end",
  Nearest = "nearest",
  Start = "start"
}

export enum ScrollBehavior {
  Smooth = "smooth",
  Auto = "auto"
}

export const scrollElementIntoView = (
  elementId: string,
  scrollBehavior?: ScrollBehavior,
  verticalAlignment?: ScrollVerticalAlignment
): void => {
  const sectionElement = document.getElementById(elementId);
  sectionElement &&
    sectionElement.scrollIntoView({
      behavior: scrollBehavior ?? ScrollBehavior.Smooth,
      block: verticalAlignment ?? ScrollVerticalAlignment.Center
    });
};
