import React from "react";
import { FC } from "@laba/react-common";
import { NexupColor } from "model/nexupColor";
import { OdontogramIconProps } from "components/icons/OdontogramIcons/utils";

export const CurableCavitiesIcon: FC<OdontogramIconProps> = props => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill={props.iconColor ?? NexupColor.Black}
    >
      <path d="M12.5 19.5C16.366 19.5 19.5 16.366 19.5 12.5C19.5 8.63401 16.366 5.5 12.5 5.5C8.63401 5.5 5.5 8.63401 5.5 12.5C5.5 16.366 8.63401 19.5 12.5 19.5Z" />
    </svg>
  );
};
