import React, { useCallback, useEffect, useState } from "react";
import {
  FC,
  IconC,
  OnBlurEvent,
  ReactElement,
  useDebounce,
  useEffectSelective
} from "@laba/react-common";
import { useStateIfMounted } from "use-state-if-mounted";
import { AutocompleteOptionProps } from "components/autocomplete/Autocomplete/AutocompleteOption/AutocompleteOption";
import {
  Autocomplete,
  AutocompleteOptionConfig,
  AutoCompleteTypeVariant
} from "../Autocomplete/Autocomplete";

export type ModelAutocompleteOptionConfig<V> = AutocompleteOptionConfig<V>;

export interface ModelAutocompleteProps<V> {
  className?: string;
  clearText?: string;
  closeText: string;
  disabled?: boolean;
  EndIcon?: IconC;
  endIconShouldNotRotate?: boolean;
  errorText?: string;
  fullWidth?: boolean;
  getValues: (searchText?: string) => Promise<V[]>;
  extraValues?: V[];
  getOptionFromValue: (value: V) => AutocompleteOptionConfig<V>;
  helperText?: string;
  id?: string;
  label?: string;
  loadingText: string;
  minInputForSearch?: number;
  noOptionsText: string;
  onBlur?: OnBlurEvent;
  onChange?: (val?: V) => void;
  openText: string;
  searchDebounceMs?: number;
  showError?: boolean;
  showHelperOrErrorText?: boolean;
  value?: V;
  StartIcon?: IconC;
  compareValues?: (v1: V, v2: V) => boolean;
  variant?: AutoCompleteTypeVariant;
  showSelectedValueOnInput?: boolean;
  excludedValues?: V[];
  minCharPromptText?: string;
  searchDependency?: string;
  clearable?: boolean;
  clearOnBlur?: boolean;
  AutocompleteOptionC?: FC<AutocompleteOptionProps<V>>;
  downloadOptionsIfEmpty?: boolean;
  resetOptions?: boolean;
  showCustomOption?: boolean;
  customOptionText?: string;
  onClickCustomClickableOption?: (searchText?: string) => void;
  minInputForCustomOption?: number;
  moreCharsText?: string;
}

interface SearchTextType {
  text: string;
  isOptionText: boolean;
}

export const ModelAutocomplete = <V,>({
  className,
  clearText,
  closeText,
  disabled,
  EndIcon,
  endIconShouldNotRotate,
  errorText,
  fullWidth,
  getValues,
  getOptionFromValue,
  helperText,
  id,
  label,
  loadingText,
  noOptionsText,
  onBlur,
  onChange,
  openText,
  showError,
  showHelperOrErrorText,
  value,
  variant,
  StartIcon,
  AutocompleteOptionC,
  compareValues = (v1, v2) => v1 === v2,
  minInputForSearch = 3,
  searchDebounceMs = 500,
  searchDependency = "",
  showSelectedValueOnInput = true,
  excludedValues = [],
  minCharPromptText,
  clearable,
  clearOnBlur,
  downloadOptionsIfEmpty,
  resetOptions,
  showCustomOption,
  customOptionText,
  onClickCustomClickableOption,
  minInputForCustomOption = 3,
  extraValues = [],
  moreCharsText
}: ModelAutocompleteProps<V>): ReactElement => {
  const [options, setOptions] = useStateIfMounted<
    AutocompleteOptionConfig<V>[]
  >([]);

  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState<SearchTextType>({
    text: "",
    isOptionText: true
  });

  useEffect(() => {
    if (value === undefined) setSearchText({ text: "", isOptionText: true });
  }, [value]);

  const getOptionsAsync = async (text: string) => {
    const parsedText = text.trim();
    const textHasLessCharThanMinInputForSearch =
      parsedText.length < minInputForSearch;
    if (!downloadOptionsIfEmpty && textHasLessCharThanMinInputForSearch) {
      setOptions([]);
      return;
    }
    setLoading(true);
    let newValues = [];
    if (downloadOptionsIfEmpty && textHasLessCharThanMinInputForSearch) {
      newValues = await getValues();
    } else {
      newValues = await getValues(parsedText);
    }
    setLoading(false);
    const newValuesWithExtraValues = [...extraValues, ...newValues];
    const newOptions = newValuesWithExtraValues.map(getOptionFromValue);
    setOptions(newOptions);
  };

  useEffectSelective(
    () => {
      if (downloadOptionsIfEmpty) {
        return getOptionsAsync("");
      }
    },
    [downloadOptionsIfEmpty, resetOptions],
    [getOptionsAsync]
  );

  const { value: debouncedValue, onChange: debouncedOnChange } =
    useDebounce<SearchTextType>({
      value: searchText,
      onChange: setSearchText,
      searchDebounceMs
    });

  useEffectSelective(
    () => {
      if (!searchText.isOptionText) {
        getOptionsAsync(searchText.text);
      }
    },
    [searchText, searchDependency],
    [getOptionsAsync, value]
  );

  const cleanOptionsAndInput = useCallback(() => {
    setOptions([]);
    debouncedOnChange?.({
      text: "",
      isOptionText: true
    });
  }, [debouncedOnChange, setOptions]);

  useEffectSelective(
    () => {
      if (!showSelectedValueOnInput) {
        cleanOptionsAndInput();
      }
    },
    [resetOptions, showSelectedValueOnInput],
    []
  );

  return (
    <Autocomplete
      className={className}
      clearText={clearText}
      closeText={closeText}
      disabled={disabled}
      EndIcon={EndIcon}
      endIconShouldNotRotate={endIconShouldNotRotate}
      errorText={errorText}
      fullWidth={fullWidth}
      helperText={helperText}
      id={id}
      inputValue={debouncedValue?.text}
      label={label}
      loading={loading}
      loadingText={loadingText}
      noOptionsText={
        searchText.text.length < minInputForSearch && minCharPromptText
          ? minCharPromptText
          : noOptionsText
      }
      onBlur={onBlur}
      onChange={onChange}
      onInputChange={(newSearchText, optionChanged) => {
        if (optionChanged && !showSelectedValueOnInput) {
          cleanOptionsAndInput();
          downloadOptionsIfEmpty && getOptionsAsync("");
        } else {
          debouncedOnChange?.({
            text: newSearchText,
            isOptionText: optionChanged
          });
        }
      }}
      openText={openText}
      options={options}
      showError={showError}
      showHelperOrErrorText={showHelperOrErrorText}
      value={value}
      StartIcon={StartIcon}
      compareValues={compareValues}
      variant={variant}
      getOptionFromValue={getOptionFromValue}
      excludedValues={excludedValues}
      localSearch={false}
      clearable={clearable}
      clearOnBlur={clearOnBlur}
      AutocompleteOptionC={AutocompleteOptionC}
      showCustomOption={
        showCustomOption &&
        searchText.text.length >= Number(minInputForCustomOption)
      }
      customOptionText={customOptionText}
      onClickCustomClickableOption={() =>
        onClickCustomClickableOption?.(searchText.text)
      }
      customOptionOptionsSizeMaxLimit={20}
      moreCharsText={moreCharsText}
    />
  );
};
