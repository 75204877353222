import { FC, getClassName } from "@laba/react-common";
import React, { useMemo, useState } from "react";
import { Popup } from "components/popup/Popup/Popup";
import { SelectedProblems } from "components/inputs/ProblemInput/ProblemCreationPopup/SelectedProblems/SelectedProblems";
import { OptionsConfig } from "components/inputs/SelectInput/SelectInput";
import { isEmpty, isEqual, remove, uniqBy } from "lodash-es";
import { Noop } from "@laba/ts-common";
import { ProblemHistory } from "components/inputs/ProblemInput/ProblemCreationPopup/ProblemHistory/ProblemHistory";
import {
  ProblemConfig,
  ProblemType
} from "components/inputs/ProblemInput/ProblemInput";
import { useProblemCreationPopupStyles } from "components/inputs/ProblemInput/ProblemCreationPopup/useProblemCreationPopupStyles";

export interface ProblemCreationPopupProps {
  className?: string;
  isOpen: boolean;
  onSubmit?: (newProblems: ProblemConfig[]) => void;
  onCancel?: Noop;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  existingProblems: ProblemConfig[];
  historyProblems: ProblemConfig[];
  title?: string;
  searchbarOptions: OptionsConfig<ProblemConfig>[];
  searchbarPlaceholder?: string;
  clearText: string;
  closeText: string;
  loadingText: string;
  noOptionsText: string;
  openText: string;
  historyTitle: string;
  historyTagText: string;
  singleSelectedOption?: boolean;
  isMobile?: boolean;
}

export const ProblemCreationPopup: FC<ProblemCreationPopupProps> = ({
  className,
  isOpen,
  onSubmit,
  onCancel,
  primaryButtonText,
  secondaryButtonText,
  searchbarPlaceholder,
  searchbarOptions,
  openText,
  noOptionsText,
  loadingText,
  closeText,
  clearText,
  title,
  existingProblems,
  historyProblems,
  historyTitle,
  historyTagText,
  singleSelectedOption = false,
  isMobile
}) => {
  const classes = useProblemCreationPopupStyles();
  const [newProblems, setNewProblems] = useState<ProblemConfig[]>([]);
  const selectedOptions = useMemo(
    () => [...newProblems, ...existingProblems],
    [newProblems, existingProblems]
  );
  const notCreatedHistoricProblems = uniqBy(
    historyProblems,
    problem => problem.code
  ).filter(
    problem =>
      ![...existingProblems, ...newProblems].some(existingProblem =>
        isEqual(problem.code, existingProblem.code)
      )
  );
  const onRemoveCreatedProblem = (problem?: ProblemConfig) => {
    const problems = [...newProblems];
    remove(problems, createdProblem => isEqual(problem, createdProblem));
    setNewProblems(problems);
  };
  const addNewProblems = (problem: ProblemConfig) => {
    const newCreatedProblem: ProblemConfig = {
      ...problem,
      type: ProblemType.CreatedProblem
    };
    singleSelectedOption
      ? setNewProblems([newCreatedProblem])
      : setNewProblems([...newProblems, newCreatedProblem]);
  };
  const onClose = () => {
    setNewProblems([]);
    onCancel?.();
  };
  return (
    <Popup
      open={isOpen}
      primaryButtonText={primaryButtonText}
      onPrimaryButtonClick={() => {
        onSubmit?.(newProblems);
        setNewProblems([]);
      }}
      secondaryButtonText={secondaryButtonText}
      onSecondaryButtonClick={onClose}
      onClose={onClose}
      isMobile={isMobile}
    >
      <div className={getClassName(className, classes.contentRoot)}>
        <p className={classes.title}>{title}</p>
        <div className={classes.content}>
          <SelectedProblems
            searchbarOptions={searchbarOptions}
            onRemoveProblem={onRemoveCreatedProblem}
            onSelectSearchbarOption={option => {
              addNewProblems(option);
            }}
            selectedOptions={selectedOptions}
            openText={openText}
            createdProblems={newProblems}
            clearText={clearText}
            closeText={closeText}
            loadingText={loadingText}
            noOptionsText={noOptionsText}
            searchbarPlaceholder={searchbarPlaceholder}
          />
          {!isEmpty(notCreatedHistoricProblems) && (
            <ProblemHistory
              problems={notCreatedHistoricProblems}
              title={historyTitle}
              onClickTag={problem => addNewProblems(problem)}
              tagText={historyTagText}
            />
          )}
        </div>
      </div>
    </Popup>
  );
};
