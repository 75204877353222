import { useEffect, useState } from "react";

// delays the transition from the true to the false state.
// This is specific to the volume control button, where it must keep its border-radius until the end of
// the slider container transitioning from its open to its closed state
export const useOpenSliderTransition = (
  transitionDurationInMs: number
): [boolean, (newBool: boolean) => void, boolean] => {
  const [sliderIsOpen, setSliderIsOpen] = useState<boolean>(false);
  const [delayedSliderIsOpen, setDelayedSliderIsOpen] =
    useState<boolean>(false);
  useEffect(() => {
    if (!sliderIsOpen) {
      const timeout = setTimeout(() => {
        setDelayedSliderIsOpen(sliderIsOpen);
      }, transitionDurationInMs);
      return () => clearTimeout(timeout);
    }
    setDelayedSliderIsOpen(sliderIsOpen);
    return undefined;
  }, [sliderIsOpen, transitionDurationInMs]);

  return [sliderIsOpen, setSliderIsOpen, delayedSliderIsOpen];
};
