export const hospitalization = {
  title: "Internaciones",
  filters: {
    organization: "Organizacion",
    patient: "Paciente",
    status: "Estado",
    practitioner: "Profesional"
  },
  fields: {
    id: "Id",
    organization: "Organizacion",
    patient: "Paciente",
    status: "Estado",
    startDate: "Fecha de inicio",
    finishDate: "Fecha de finalizacion",
    hisCode: "Codigo",
    hisType: "Tipo",
    hisOrigin: "Origen",
    service: "Servicio",
    currentBed: "Cama",
    currentBuilding: "Edificio",
    authorizationCode: "Código autorización",

    diagnosisList: "Diagnosticos",
    diagnosisListCondition: "Diagnostico",
    diagnosisListUse: "Rol",

    practitionerTeam: "Profesionales",
    practitionerTeamPractitioner: "Profesional",
    practitionerTeamRole: "Rol",

    tagList: "Tags",
    tagListAdd: "Añadir tag",
    tagListPopUpTitle: "Tag",
    tagListTagId: "Id",
    tagLisValue: "Tag",
    tagListHisCode: "Codigo",
    tagListStatus: "Estado",
    tagListObservation: "Observacion",
    tagListStartDateTime: "Fecha de inicio",
    tagListEndDateTime: "Fecha de finalizacion"
  },
  tabs: {
    hospitalization: "Internacion",
    practitioner: "Profesionales",
    tags: "Tags",
    diagnosis: "Diagnosticos"
  }
};
