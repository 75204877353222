import React from "react";
import { FC } from "@laba/react-common";
import { NexupColor } from "model/nexupColor";
import { OdontogramIconProps } from "components/icons/OdontogramIcons/utils";

export const AmalgamFillingIcon: FC<OdontogramIconProps> = props => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill={props.iconColor ?? NexupColor.Black}
    >
      <path d="M10.7773 5.55225L5.79395 19.4478H4.13281L9.125 5.55225H10.7773Z" />
      <path d="M15.3477 7.9082L11.5244 19H9.21289L14.0293 6.20312H15.5059L15.3477 7.9082ZM18.5469 19L14.7148 7.9082L14.5479 6.20312H16.0332L20.8672 19H18.5469ZM18.3623 14.2539V16.0029H11.4014V14.2539H18.3623Z" />
    </svg>
  );
};
