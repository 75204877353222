import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { ModelId } from "model/primitives/model/model";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { getKeyObj, HttpMethod, KeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { Group, GroupCode, GroupType } from "model/resource/entities/group";

interface GroupQueryParams {
  withPractitioner?: boolean;
}

export interface GroupListQueryParams
  extends ListQueryParams,
    GroupQueryParams {
  code?: GroupCode;
  organization?: ModelId;
  practitioner?: ModelId;
  type?: GroupType;
}

export const GroupListQueryParamsKey: KeyObj<GroupListQueryParams> = getKeyObj(
  createHydratedMock<GroupListQueryParams>()
);

export const groupBasePath = "/group";

export const getGroupList = (
  params?: GroupListQueryParams
): Promise<ApiPageRequestResponse<Group>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(groupBasePath),
    params
  });

export const getGroup = (
  id: ModelId,
  params?: GroupQueryParams
): Promise<ApiRequestResponse<Group>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${groupBasePath}/${id}`),
    params
  });

export const createGroup = (data: Group): Promise<ApiRequestResponse<Group>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(groupBasePath),
    data
  });

export const updateGroup = (data: Group): Promise<ApiRequestResponse<Group>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${groupBasePath}/${data.id}`),
    data
  });
