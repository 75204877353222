import {
  ResourceType,
  AppointmentListQueryParams,
  AppointmentListQueryParamsKey,
  AppointmentListOrder,
  getAppointmentList,
  updateAppointment,
  createAppointment,
  getAppointmentDetail,
  getModelReferenceId
} from "@laba/nexup-api";
import {
  getEnumOrderParam,
  getKeyTargetParam,
  resourceProviderGetter
} from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { workspaceOrganizationSelector } from "store/workspace/selectors";
import { store } from "store/store";

export const appointmentProvider = resourceProviderGetter(
  ResourceType.Appointment,
  getEnumOrderParam(AppointmentListOrder),
  getKeyTargetParam<AppointmentListQueryParams>(AppointmentListQueryParamsKey),
  getAppointmentList,
  getAppointmentDetail,
  updateAppointment,
  createAppointment,
  undefined,
  () => ({
    organization: getModelReferenceId(
      workspaceOrganizationSelector(store.getState())
    )
  })
);
