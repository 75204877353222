import React from "react";
import { FC } from "@laba/react-common";
import { Create } from "components/generic/Create/Create";
import {
  AppointmentForm,
  appointmentJsonToModel,
  appointmentTransform
} from "components/pages/NexupAdmin/resources/appointment/AppointmentForm";
import { ResourceType } from "@laba/nexup-api";

export const AppointmentCreate: FC = () => {
  return (
    <Create
      resourceTransform={appointmentTransform}
      resource={ResourceType.Appointment}
      jsonToModel={appointmentJsonToModel}
    >
      <AppointmentForm />
    </Create>
  );
};
