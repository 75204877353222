import { isSomeEnum, ContentTypeHeader } from "@laba/ts-common";

export enum ImgMimes {
  APNG = "image/apng",
  AVIF = "image/avif",
  GIF = "image/gif",
  JPEG = "image/jpeg",
  PNG = "image/png",
  SVG = "image/svg+xml",
  WebP = "image/webp"
}

export const pdfMime = ContentTypeHeader.Pdf;

export const canShowPreview = (mime?: string): boolean =>
  mime !== undefined && (isSomeEnum(ImgMimes)(mime) || mime === pdfMime);

export const mimeIsPDF = (mime: string): boolean => mime === pdfMime;

export const mimeIsImage = (mime: string): boolean =>
  isSomeEnum(ImgMimes)(mime);
