import { OdontogramGridItemConfig } from "components/odontogram/OdontogramGrid/utils";
import { OdontogramItem } from "components/odontogram";
import React, { ReactElement } from "react";

export const gridItemMapper = (
  item: OdontogramGridItemConfig,
  onClick?: (tooth: string) => void
): ReactElement => {
  return (
    <OdontogramItem
      diagnosticList={item.diagnosticList ?? []}
      disabled={item.disabled}
      tooth={item.tooth}
      clickable={item.clickable}
      key={item.tooth}
      onClick={() => {
        onClick?.(item.tooth);
      }}
    />
  );
};
