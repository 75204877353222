import { ActionCreatorWithPayload, GenericReducer } from "store/types";
import { Optional } from "@laba/ts-common";
import { castDraft, Draft } from "immer";

export interface CancelElementState<Element> {
  selectedElement?: Element;
  isCancelConfirmElementPopupOpen: boolean;
  isCancellingElement: boolean;
}

interface CancelElementStateReturnReducers<SliceState, Element> {
  setSelectedElement: GenericReducer<SliceState, Optional<Element>>;
  setIsCancelConfirmElementPopupOpen: GenericReducer<SliceState, boolean>;
  setIsCancellingElement: GenericReducer<SliceState, boolean>;
}

export interface CancelElementStateActions<Element> {
  setSelectedElement: ActionCreatorWithPayload<Optional<Element>>;
  setIsCancelConfirmElementPopupOpen: ActionCreatorWithPayload<boolean>;
  setIsCancellingElement: ActionCreatorWithPayload<boolean>;
}

export interface CancelElementStateReturn<SliceState, Element> {
  initialState: CancelElementState<Element>;
  reducers: CancelElementStateReturnReducers<SliceState, Element>;
}

export const getCancelElementState = <SliceState, Element>(
  cancelElementDraftSliceStateGetter: (
    sliceState: Draft<SliceState>
  ) => Draft<CancelElementState<Element>>
): CancelElementStateReturn<SliceState, Element> => {
  type Reducer<T = void> = GenericReducer<SliceState, T>;

  const initState: CancelElementState<Element> = {
    isCancelConfirmElementPopupOpen: false,
    isCancellingElement: false
  };

  const setSelectedElement: Reducer<Optional<Element>> = (
    draftState,
    { payload }
  ) => {
    const draftPaginationState = cancelElementDraftSliceStateGetter(draftState);

    draftPaginationState.selectedElement = castDraft(payload);
  };

  const setIsCancelConfirmElementPopupOpen: Reducer<boolean> = (
    draftState,
    { payload }
  ) => {
    const draftPaginationState = cancelElementDraftSliceStateGetter(draftState);
    draftPaginationState.isCancelConfirmElementPopupOpen = payload;
  };

  const setIsCancellingElement: Reducer<boolean> = (
    draftState,
    { payload }
  ) => {
    const draftPaginationState = cancelElementDraftSliceStateGetter(draftState);
    draftPaginationState.isCancellingElement = payload;
  };

  return {
    initialState: initState,
    reducers: {
      setSelectedElement,
      setIsCancelConfirmElementPopupOpen,
      setIsCancellingElement
    }
  };
};
