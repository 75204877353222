import { ReactElement } from "@laba/react-common";
import React from "react";
import { BooleanInput as ReactAdminBooleanInput } from "react-admin";
import { Optional } from "@laba/ts-common";

export interface BooleanInputProps<T> {
  source: string;
  label: string;
  defaultValue?: boolean;
  disabled?: boolean;
  parse?: (selectValue: Optional<boolean>) => Optional<T>;
  format?: (formValue: Optional<T>) => Optional<boolean>;
  alwaysOn?: boolean;
}
export const BooleanInput = <T,>(props: BooleanInputProps<T>): ReactElement => {
  return <ReactAdminBooleanInput fullWidth variant="outlined" {...props} />;
};
