import React from "react";
import { DialogViewer } from "components/popup/DialogViewer/DialogViewer";
import { useFilePreviewPopupStyles } from "components/download/DownloadLink/FilePreviewPopup/useFilePreviewPopupStyles";
import { ArrowBackIcon, GetApp } from "components/icons";
import { Noop } from "@laba/ts-common";
import { mimeIsImage, mimeIsPDF } from "../utils";
import { PdfViewer } from "./PdfViewer/PdfViewer";

interface Props {
  fileBlob: Blob;
  isOpen?: boolean;
  handleClose: Noop;
  handleDownload: Noop;
  name: string;
}

export const FilePreviewPopup: React.FC<Props> = ({
  fileBlob,
  handleClose,
  handleDownload,
  isOpen = false,
  name
}: Props) => {
  const classes = useFilePreviewPopupStyles();
  return (
    <DialogViewer
      isOpen={isOpen}
      onClose={handleClose}
      LeftIcon={ArrowBackIcon}
      onLeftIconClick={handleClose}
      RightIcon={GetApp}
      onRightIconClick={handleDownload}
    >
      {mimeIsImage(fileBlob.type) ? (
        <img
          alt={name}
          src={URL.createObjectURL(fileBlob)}
          className={classes.imgPopup}
        />
      ) : mimeIsPDF(fileBlob.type) ? (
        <PdfViewer pdfBlob={fileBlob} />
      ) : undefined}
    </DialogViewer>
  );
};
