import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  getClassName,
  marginMixin,
  pxToRem,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import {
  getThemeStyleColor,
  getThemeStyleState,
  themeTextMixin
} from "model/themeUtils";

export const useOdontogramStaticStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexColumnMixin({ alignItems: FlexAlignItems.Center, gap: 4 })
    },
    toothContainer: {
      height: pxToRem(32),
      width: pxToRem(32),
      position: "relative"
    },
    toothIcon: {
      height: pxToRem(32),
      width: pxToRem(32)
    },
    wholeToothDiagnosticIcon: {
      height: pxToRem(32),
      width: pxToRem(32)
    },
    surfaceDiagnosticIcon: {
      height: pxToRem(16),
      width: pxToRem(16)
    },
    positionCenter: {
      position: "absolute",
      right: 0,
      left: 0,
      top: 0,
      bottom: 0,
      margin: "auto"
    },
    positionBottom: {
      ...marginMixin({
        top: "auto",
        bottom: -4
      }),
      bottom: 0,
      left: "25%",
      position: "absolute"
    },
    positionTop: {
      ...marginMixin({
        bottom: "auto",
        top: -4
      }),
      top: 0,
      left: "25%",
      position: "absolute"
    },
    positionRight: {
      ...marginMixin({
        left: "auto",
        right: -4
      }),
      right: 0,
      top: "25%",
      position: "absolute"
    },
    positionLeft: {
      ...marginMixin({
        right: "auto",
        left: -4
      }),
      left: 0,
      top: "25%",
      position: "absolute"
    },
    name: {
      ...themeTextMixin(theme, TextVariant.Caption)
    },
    nameColor: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    nameColorDisabled: {
      color: getThemeStyleState(theme, StyleVariant.Primary).disabled
    },
    disabled: {
      opacity: 0.6
    },
    defaultCursor: { cursor: "default" }
  }),
  "OdontogramItem"
);

export interface UseOdontogramItemStylesReturn {
  root: string;
  toothContainer: string;
  name: string;
  toothIcon: string;
  defaultCursor: string;
  positionCenter: string;
  positionBottom: string;
  positionTop: string;
  positionLeft: string;
  positionRight: string;
  wholeToothDiagnosticIcon: string;
  surfaceDiagnosticIcon: string;
}

interface UseOdontogramItemStylesProps {
  disabled: boolean;
}

export const useOdontogramItemStyles = (
  props: UseOdontogramItemStylesProps
): UseOdontogramItemStylesReturn => {
  const classes = useOdontogramStaticStyles();
  return {
    root: classes.root,
    toothContainer: getClassName(
      classes.toothContainer,
      props.disabled ? classes.disabled : undefined
    ),
    name: getClassName(
      classes.name,
      props.disabled ? classes.nameColorDisabled : classes.nameColor
    ),
    toothIcon: getClassName(classes.positionCenter, classes.toothIcon),
    defaultCursor: classes.defaultCursor,
    positionCenter: classes.positionCenter,
    positionBottom: classes.positionBottom,
    positionTop: classes.positionTop,
    positionLeft: classes.positionLeft,
    positionRight: classes.positionRight,
    surfaceDiagnosticIcon: classes.surfaceDiagnosticIcon,
    wholeToothDiagnosticIcon: classes.wholeToothDiagnosticIcon
  };
};
