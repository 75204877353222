import { head } from "lodash-es";
import { ResourceType } from "model/primitives/resourceModel";
import { PublicationStatus } from "model/resource/activity/activityDefinition";
import { Product } from "../product";

export const productMock1: Product = {
  category: "categoria",
  organization: "213",
  price: { currency: "", value: 123 },
  copay: { currency: "", value: 23 },
  resourceType: ResourceType.Product,
  status: PublicationStatus.Active,
  subcategory: "subcategoria",
  tag: ["river"],
  title: "libertadores",
  identifier: [
    { value: "code", system: "fqwec", resourceType: ResourceType.Identifier }
  ]
};

export const productListMock: Product[] = [
  productMock1,
  {
    category: "categoria",
    organization: "213",
    price: { currency: "", value: 123 },
    resourceType: ResourceType.Product,
    status: PublicationStatus.Active,
    subcategory: "subcategoria",
    tag: ["boca"],
    title: "libertadores",
    identifier: [
      { value: "code", system: "fqwec", resourceType: ResourceType.Identifier }
    ]
  },
  {
    category: "categoria",
    organization: "213",
    price: { currency: "", value: 123 },
    resourceType: ResourceType.Product,
    status: PublicationStatus.Active,
    subcategory: "subcategoria",
    tag: ["neewells"],
    title: "libertadores",
    identifier: [
      { value: "code", system: "fqwec", resourceType: ResourceType.Identifier }
    ]
  }
];

export const getProductListMock = (title: string): Product => {
  return {
    ...head(productListMock),
    resourceType: ResourceType.Product,
    title
  };
};
