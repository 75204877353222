import { ResourceType } from "model/primitives/resourceModel";
import { Day } from "model/primitives/time/time";
import { LocationStatus } from "model/resource/entities/location/location";
import { Gender, ValidationStatus } from "model/resource/person/person";
import { KnownScheduleType, Schedule } from "model/resource/schedule/schedule";

export const getScheduleDetailMock = (
  id = "10",
  name = "Radiografia de cadera"
): Schedule => {
  return {
    id,
    name,
    resourceType: ResourceType.Schedule,
    location: {
      resourceType: ResourceType.Location,
      name: "Amenabar",
      status: LocationStatus.Active
    },
    type: KnownScheduleType.Presential,
    speciality: ["Radiologias"],
    performer: [
      {
        practitioner: {
          resourceType: ResourceType.Practitioner,
          id: "32",
          federationId: "e61c0075-a750-48f0-93a7-a3319b5a0233",
          personalData: {
            firstName: "ivan",
            otherName: "",
            lastName: "di vito",
            gender: Gender.Unknown,
            identifierList: []
          },
          contactData: {
            phoneList: [],
            emailList: [],
            addressList: []
          },
          validationStatus: ValidationStatus.Temporal,
          organization: ["24"],
          creationDate: "2021-10-12T18:39:35.195Z"
        }
      },
      {
        practitioner: {
          resourceType: ResourceType.Practitioner,
          id: "21",
          federationId: "e61c0075-a750-48f0-93a7-a3319b5a0233",
          personalData: {
            firstName: "Alfredo",
            otherName: "",
            lastName: "Blabla",
            gender: Gender.Unknown,
            identifierList: []
          },
          contactData: {
            phoneList: [],
            emailList: [],
            addressList: []
          },
          validationStatus: ValidationStatus.Temporal,
          organization: ["24"],
          creationDate: "2021-11-12T18:39:35.195Z"
        }
      }
    ],
    planningHorizon: {
      start: "2019-08-24T14:15:22Z",
      end: "2019-08-24T14:15:22Z"
    },
    active: true,
    availability: {
      slotDuration: 20,
      overbookingLimit: 0,
      availableTime: [
        {
          startTime: "14:15:22Z",
          endTime: "14:15:22Z",
          daysOfWeek: [Day.Friday],
          allDay: false
        }
      ],
      notAvailableTime: [
        {
          description: "Me voy a ver la scaloneta",
          during: {
            start: "2019-08-24T14:15:22Z",
            end: "2019-08-24T14:15:22Z"
          },
          startTime: "2019-08-24T14:15:22Z",
          endTime: "2019-08-24T15:15:22Z",
          allDay: false
        }
      ]
    },

    creationDate: "2019-08-24T14:15:22Z",
    lastEditDate: "2022-08-28T19:17:08.095Z",

    practitioner: {
      resourceType: ResourceType.Practitioner,
      id: "31",
      personalData: {
        firstName: "Nicolas",
        otherName: "",
        lastName: "Diaz",
        gender: Gender.Male,
        identifierList: []
      },
      validationStatus: ValidationStatus.Temporal,
      organization: ["24"],
      creationDate: "2021-10-12T18:39:35.195Z"
    },

    originalPractitioner: {
      resourceType: ResourceType.Practitioner,
      id: "32",
      federationId: "e61c0075-a750-48f0-93a7-a3319b5a0233",
      personalData: {
        firstName: "ivan",
        otherName: "",
        lastName: "di vito",
        gender: Gender.Unknown,
        identifierList: []
      },
      contactData: {
        phoneList: [],
        emailList: [],
        addressList: []
      },
      validationStatus: ValidationStatus.Temporal,
      organization: ["24"],
      creationDate: "2021-10-12T18:39:35.195Z"
    },

    organization: "10",

    identifier: [
      {
        id: "11",
        resourceType: ResourceType.Identifier,
        system: "string",
        value: "string",
        files: [
          {
            id: "13",
            resourceType: ResourceType.Attachment,
            url: "string",
            name: "string",
            external: true
          }
        ]
      }
    ]
  };
};

export const getScheduleListMock = (): Schedule[] => {
  return [
    getScheduleDetailMock("12", "Resonancia magnetica"),
    getScheduleDetailMock("13", "Consulta odontologica"),
    getScheduleDetailMock("124", "Resonancia magnetica"),
    getScheduleDetailMock("137", "Consulta odontologica"),
    getScheduleDetailMock("1256", "Resonancia magnetica"),
    getScheduleDetailMock("13534", "Consulta odontologica"),
    getScheduleDetailMock("12756", "Resonancia magnetica"),
    getScheduleDetailMock("1373467", "Consulta odontologica"),
    getScheduleDetailMock("125473", "Resonancia magnetica"),
    getScheduleDetailMock("13456", "Consulta odontologica"),
    getScheduleDetailMock("124737", "Resonancia magnetica"),
    getScheduleDetailMock("1354754745", "Consulta odontologica"),
    getScheduleDetailMock("12456", "Resonancia magnetica"),
    getScheduleDetailMock("13456456", "Consulta odontologica"),
    getScheduleDetailMock("12437347", "Resonancia magnetica"),
    getScheduleDetailMock("14113", "Consulta odontologica"),
    getScheduleDetailMock("124112", "Resonancia magnetica"),
    getScheduleDetailMock("142113", "Consulta odontologica"),
    getScheduleDetailMock("523512", "Resonancia magnetica"),
    getScheduleDetailMock("568613", "Consulta odontologica"),
    getScheduleDetailMock("45712", "Resonancia magnetica"),
    getScheduleDetailMock("46413", "Consulta odontologica"),
    getScheduleDetailMock("64512", "Resonancia magnetica"),
    getScheduleDetailMock("43513", "Consulta odontologica"),
    getScheduleDetailMock("345412", "Resonancia magnetica"),
    getScheduleDetailMock("15343", "Consulta odontologica"),
    getScheduleDetailMock("14572", "Resonancia magnetica"),
    getScheduleDetailMock("15683", "Consulta odontologica"),
    getScheduleDetailMock("14562", "Resonancia magnetica"),
    getScheduleDetailMock("13476983", "Consulta odontologica"),
    getScheduleDetailMock("196782", "Resonancia magnetica"),
    getScheduleDetailMock("15686783", "Consulta odontologica"),
    getScheduleDetailMock("145642", "Resonancia magnetica"),
    getScheduleDetailMock("175473", "Consulta odontologica"),
    getScheduleDetailMock("14562", "Resonancia magnetica"),
    getScheduleDetailMock("16587973", "Consulta odontologica"),
    getScheduleDetailMock("186982", "Resonancia magnetica"),
    getScheduleDetailMock("18703", "Consulta odontologica"),
    getScheduleDetailMock("1807982", "Resonancia magnetica"),
    getScheduleDetailMock("107803", "Consulta odontologica"),
    getScheduleDetailMock("187082", "Resonancia magnetica"),
    getScheduleDetailMock("1098093", "Consulta odontologica"),
    getScheduleDetailMock("109802", "Resonancia magnetica"),
    getScheduleDetailMock("109803", "Consulta odontologica"),
    getScheduleDetailMock("10890902", "Resonancia magnetica"),
    getScheduleDetailMock("13980890", "Consulta odontologica"),
    getScheduleDetailMock("120980", "Resonancia magnetica"),
    getScheduleDetailMock("13980890", "Consulta odontologica"),
    getScheduleDetailMock("128908", "Resonancia magnetica"),
    getScheduleDetailMock("1356756", "Consulta odontologica"),
    getScheduleDetailMock("1245645", "Resonancia magnetica"),
    getScheduleDetailMock("133453", "Consulta odontologica"),
    getScheduleDetailMock("1223", "Resonancia magnetica"),
    getScheduleDetailMock("13123", "Consulta odontologica"),
    getScheduleDetailMock("11231232", "Resonancia magnetica"),
    getScheduleDetailMock("131231", "Consulta odontologica"),
    getScheduleDetailMock("1121112", "Resonancia magnetica"),
    getScheduleDetailMock("1322", "Consulta odontologica"),
    getScheduleDetailMock("122", "Resonancia magnetica"),
    getScheduleDetailMock("12223", "Consulta odontologica"),
    getScheduleDetailMock("11112", "Resonancia magnetica"),
    getScheduleDetailMock("88813", "Consulta odontologica"),
    getScheduleDetailMock("9912", "Resonancia magnetica"),
    getScheduleDetailMock("1883", "Consulta odontologica"),
    getScheduleDetailMock("17772", "Resonancia magnetica"),
    getScheduleDetailMock("15553", "Consulta odontologica"),
    getScheduleDetailMock("848812", "Resonancia magnetica"),
    getScheduleDetailMock("1575473", "Consulta odontologica"),
    getScheduleDetailMock("166662", "Resonancia magnetica"),
    getScheduleDetailMock("1880983", "Consulta odontologica"),
    getScheduleDetailMock("10002", "Resonancia magnetica"),
    getScheduleDetailMock("103", "Consulta odontologica")
  ];
};
