import { PractitionerRole } from "model/resource/person/practitionerRole";
import { Practitioner } from "model/resource/person/practitioner/practitioner";
import { getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";

export interface PractitionerWithExtraData {
  practitioner: Practitioner;
  roleList?: PractitionerRole[];
}

export const PractitionerWithExtraDataKey = getKeyObj(
  createHydratedMock<PractitionerWithExtraData>()
);
