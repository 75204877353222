import { Color, createStyle, pxToRem } from "@laba/react-common";
import { getThemeStyleColor } from "model/themeUtils";
import { StyleVariant } from "model/themeVariant";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { isUndefined } from "lodash-es";
import { Noop } from "@laba/ts-common";

interface Props {
  style: StyleVariant;
  color?: Color;
  isAvailable: boolean;
  onBadgeClick?: Noop;
  isClickable?: boolean;
}
export const useBadgedComponentStyle = createStyle(
  (theme: Theme) => ({
    badge: (props: Props) => ({
      backgroundColor: props.isAvailable
        ? props.color ??
          getThemeStyleColor(theme, props.style, ThemeStyleColorVariant.Main)
        : getThemeStyleColor(
            theme,
            props.style,
            ThemeStyleColorVariant.GrayMedium
          ),
      borderColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      ),
      borderWidth: pxToRem(1),
      borderStyle: "solid",
      cursor:
        isUndefined(props.onBadgeClick) && !props.isClickable
          ? "default"
          : "pointer"
    })
  }),
  "BadgedComponent"
);
