import {
  ApiPageRequestResponse,
  createOrganization,
  getModelReferenceId,
  getOrganization,
  getOrganizationList,
  ModelId,
  Organization,
  OrganizationHierarchySearchMode,
  OrganizationListQueryParams,
  OrganizationListQueryParamsKey,
  ResourceType,
  updateOrganization
} from "@laba/nexup-api";
import {
  getEnumOrderParam,
  getKeyTargetParam,
  resourceProviderGetter
} from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { RequestFailureStatus } from "@laba/ts-common";
import { store } from "store/store";
import { workspaceOrganizationSelector } from "store/workspace/selectors";
import produce from "immer";
import { includes } from "lodash-es";

export const getOrganizationListWrapped = async (
  params: OrganizationListQueryParams & { workspaceOrganizationId?: ModelId }
): Promise<ApiPageRequestResponse<Organization>> => {
  // This check is necessary because React Admin ReferenceInput only supports references as strings.
  // When an object is passed, the library casts it to "object Object", causing issues.
  // If that happens, we return a failure without making the request,
  // ensuring the correct request is made on the next render.
  if (params.id?.some(v => includes(v, "Object"))) {
    return { failureStatus: RequestFailureStatus.Failure, errorMsg: "" };
  }

  const { workspaceOrganizationId, ...listParams } = params;
  if (workspaceOrganizationId === undefined || listParams.page !== 1) {
    const response = await getOrganizationList(listParams);
    if (listParams.partOf && listParams.id?.includes(listParams.partOf)) {
      const partOfOrganizationResponse = await getOrganization(
        listParams.partOf
      );
      if (
        response.failureStatus === RequestFailureStatus.Success &&
        partOfOrganizationResponse.failureStatus ===
          RequestFailureStatus.Success
      ) {
        return produce(response, draft => {
          draft.data.entries.unshift(partOfOrganizationResponse.data);
          draft.data.page.totalEntries += 1;
          draft.data.page.pageSize += 1;
        });
      }
    }
    return response;
  }
  const baseOrganizationResponse = await getOrganization(
    workspaceOrganizationId
  );
  const response = await getOrganizationList(listParams);
  if (
    response.failureStatus === RequestFailureStatus.Success &&
    baseOrganizationResponse.failureStatus === RequestFailureStatus.Success
  ) {
    return produce(response, draft => {
      draft.data.entries.unshift(baseOrganizationResponse.data);
      draft.data.page.totalEntries += 1;
      draft.data.page.pageSize += 1;
    });
  }
  return response;
};

export const organizationProvider = resourceProviderGetter(
  ResourceType.Organization,
  getEnumOrderParam({}),
  getKeyTargetParam<OrganizationListQueryParams>(
    OrganizationListQueryParamsKey
  ),
  getOrganizationListWrapped,
  getOrganization,
  updateOrganization,
  createOrganization,
  undefined,
  (isManyGetter?: boolean) => {
    return {
      organizationHierarchyMode: isManyGetter
        ? OrganizationHierarchySearchMode.OwnAndParent
        : undefined,
      partOf: getModelReferenceId(
        workspaceOrganizationSelector(store.getState())
      )
    };
  }
);
