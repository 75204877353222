import { ResourceType } from "model/primitives/resourceModel";
import { MedicalRequestStatus } from "model/resource/prescription/medicationRequest/medicationRequest";
import { RequestFrequencyType } from "../medicalRequest/medicalRequestFrequency";
import { MedicalRequestWithExtraData } from "../medicalRequest/medicalRequestWithExtraData";
import { Medication, MedicationStatus } from "../medication/medication";

const medication6Mock: Medication = {
  resourceType: ResourceType.Medication,
  id: "90",
  name: "Medicación 6 (Diluyente 2)",
  alternativeName: ["qwe", "qwee"],
  description: "Medicación 6 (Diluyente 2) solución 100.0 ml",
  status: MedicationStatus.Active,
  form: "solución",
  presentationUnit: "frasco",
  presentationUnitAmount: {
    value: 100,
    unit: "ml"
  },
  healthcareUnits: [
    {
      value: 100,
      unit: "ml"
    }
  ],
  ingredient: [],
  routeList: ["oral"],
  characteristic: [
    "solvent",
    "phpSolution",
    "volumeExpansionSolution",
    "continuousInfusionSolution",
    "aerosolTherapySolution"
  ],
  organization: "25",
  functionCode: "ABC1234",
  creationDate: "2021-12-29T15:39:41.276Z",
  presentationList: []
};

export const getMedicalRequestWithExtraDataListMock =
  (): MedicalRequestWithExtraData[] => [
    {
      medicalRequest: {
        resourceType: ResourceType.MedicalRequest,
        id: "797",
        versionId: "1",
        patient: "65",
        practitioner: "34",
        originalPractitioner: "34",
        statusReason: MedicalRequestStatus.Active,
        expirationDate: "2022-03-22T14:23:12.000Z",
        lastRenewDate: "2022-03-22T14:22:48.000Z",
        lastEditDate: "2022-03-22T14:22:48.000Z",
        mainText:
          "<p>Medicación 6 (Diluyente 2) solución 100.0 ml 12 ml por vía oral</p>",
        supplementaryText: "<p></p>",
        reportText:
          "<p>Medicación 6 (Diluyente 2) solución 100.0 ml 12 ml por vía oral </p>",
        requestData: {
          mainMedication: medication6Mock,
          dose: {
            quantity: 12,
            unit: "ml"
          },
          rate: {},
          dilution: {},
          medicationStructure: [],
          route: "oral",
          frequency: {
            startDate: "2022-03-22",
            type: RequestFrequencyType.FixedHours,
            timeList: ["21:00:00"],
            meals: [],
            extraDoseNow: false,
            weekDayList: []
          },
          providedByPatient: false,
          extraData: []
        },
        medicalDevice: [],
        definition: "103",
        creationDate: "2022-03-22T14:22:49.184Z"
      },
      medicalAdministrationList: []
    },
    {
      medicalRequest: {
        resourceType: ResourceType.MedicalRequest,
        id: "797",
        versionId: "1",
        patient: "65",
        practitioner: "34",
        originalPractitioner: "34",
        statusReason: MedicalRequestStatus.Active,
        expirationDate: "2022-03-22T14:23:12.000Z",
        lastRenewDate: "2022-03-22T14:22:48.000Z",
        lastEditDate: "2022-03-22T14:22:48.000Z",
        mainText:
          "<p>Medicación 6 (Diluyente 2) solución 100.0 ml 12 ml por vía oral</p>",
        supplementaryText: "<p></p>",
        reportText:
          "<p>Medicación 6 (Diluyente 2) solución 100.0 ml 12 ml por vía oral </p>",
        requestData: {
          mainMedication: medication6Mock,
          dose: {
            quantity: 12,
            unit: "ml"
          },
          rate: {},
          dilution: {},
          medicationStructure: [],
          route: "oral",
          frequency: {
            startDate: "2022-03-22",
            type: RequestFrequencyType.FixedHours,
            timeList: ["21:00:00"],
            meals: [],
            extraDoseNow: false,
            weekDayList: []
          },
          providedByPatient: false,
          extraData: []
        },
        medicalDevice: [],
        definition: "103",
        creationDate: "2022-03-22T14:22:49.184Z"
      },
      medicalAdministrationList: []
    },
    {
      medicalRequest: {
        resourceType: ResourceType.MedicalRequest,
        id: "797",
        versionId: "1",
        patient: "65",
        practitioner: "34",
        originalPractitioner: "34",
        statusReason: MedicalRequestStatus.Active,
        expirationDate: "2022-03-22T14:23:12.000Z",
        lastRenewDate: "2022-03-22T14:22:48.000Z",
        lastEditDate: "2022-03-22T14:22:48.000Z",
        mainText:
          "<p>Medicación 6 (Diluyente 2) solución 100.0 ml 12 ml por vía oral</p>",
        supplementaryText: "<p></p>",
        reportText:
          "<p>Medicación 6 (Diluyente 2) solución 100.0 ml 12 ml por vía oral </p>",
        requestData: {
          mainMedication: medication6Mock,
          dose: {
            quantity: 12,
            unit: "ml"
          },
          rate: {},
          dilution: {},
          medicationStructure: [],
          route: "oral",
          frequency: {
            startDate: "2022-03-22",
            type: RequestFrequencyType.FixedHours,
            timeList: ["21:00:00"],
            meals: [],
            extraDoseNow: false,
            weekDayList: []
          },
          providedByPatient: false,
          extraData: []
        },
        medicalDevice: [],
        definition: "103",
        creationDate: "2022-03-22T14:22:49.184Z"
      },
      medicalAdministrationList: []
    },
    {
      medicalRequest: {
        resourceType: ResourceType.MedicalRequest,
        id: "797",
        versionId: "1",
        patient: "65",
        practitioner: "34",
        originalPractitioner: "34",
        statusReason: MedicalRequestStatus.Active,
        expirationDate: "2022-03-22T14:23:12.000Z",
        lastRenewDate: "2022-03-22T14:22:48.000Z",
        lastEditDate: "2022-03-22T14:22:48.000Z",
        mainText:
          "<p>Medicación 6 (Diluyente 2) solución 100.0 ml 12 ml por vía oral</p>",
        supplementaryText: "<p></p>",
        reportText:
          "<p>Medicación 6 (Diluyente 2) solución 100.0 ml 12 ml por vía oral </p>",
        requestData: {
          mainMedication: medication6Mock,
          dose: {
            quantity: 12,
            unit: "ml"
          },
          rate: {},
          dilution: {},
          medicationStructure: [],
          route: "oral",
          frequency: {
            startDate: "2022-03-22",
            type: RequestFrequencyType.FixedHours,
            timeList: ["21:00:00"],
            meals: [],
            extraDoseNow: false,
            weekDayList: []
          },
          providedByPatient: false,
          extraData: []
        },
        medicalDevice: [],
        definition: "103",
        creationDate: "2022-03-22T14:22:49.184Z"
      },
      medicalAdministrationList: []
    },
    {
      medicalRequest: {
        resourceType: ResourceType.MedicalRequest,
        id: "797",
        versionId: "1",
        patient: "65",
        practitioner: "34",
        originalPractitioner: "34",
        statusReason: MedicalRequestStatus.Active,
        expirationDate: "2022-03-22T14:23:12.000Z",
        lastRenewDate: "2022-03-22T14:22:48.000Z",
        lastEditDate: "2022-03-22T14:22:48.000Z",
        mainText:
          "<p>Medicación 6 (Diluyente 2) solución 100.0 ml 12 ml por vía oral</p>",
        supplementaryText: "<p></p>",
        reportText:
          "<p>Medicación 6 (Diluyente 2) solución 100.0 ml 12 ml por vía oral </p>",
        requestData: {
          mainMedication: medication6Mock,
          dose: {
            quantity: 12,
            unit: "ml"
          },
          rate: {},
          dilution: {},
          medicationStructure: [],
          route: "oral",
          frequency: {
            startDate: "2022-03-22",
            type: RequestFrequencyType.FixedHours,
            timeList: ["21:00:00"],
            meals: [],
            extraDoseNow: false,
            weekDayList: []
          },
          providedByPatient: false,
          extraData: []
        },
        medicalDevice: [],
        definition: "103",
        creationDate: "2022-03-22T14:22:49.184Z"
      },
      medicalAdministrationList: []
    },
    {
      medicalRequest: {
        resourceType: ResourceType.MedicalRequest,
        id: "797",
        versionId: "1",
        patient: "65",
        practitioner: "34",
        originalPractitioner: "34",
        statusReason: MedicalRequestStatus.Active,
        expirationDate: "2022-03-22T14:23:12.000Z",
        lastRenewDate: "2022-03-22T14:22:48.000Z",
        lastEditDate: "2022-03-22T14:22:48.000Z",
        mainText:
          "<p>Medicación 6 (Diluyente 2) solución 100.0 ml 12 ml por vía oral</p>",
        supplementaryText: "<p></p>",
        reportText:
          "<p>Medicación 6 (Diluyente 2) solución 100.0 ml 12 ml por vía oral </p>",
        requestData: {
          mainMedication: medication6Mock,
          dose: {
            quantity: 12,
            unit: "ml"
          },
          rate: {},
          dilution: {},
          medicationStructure: [],
          route: "oral",
          frequency: {
            startDate: "2022-03-22",
            type: RequestFrequencyType.FixedHours,
            timeList: ["21:00:00"],
            meals: [],
            extraDoseNow: false,
            weekDayList: []
          },
          providedByPatient: false,
          extraData: []
        },
        medicalDevice: [],
        definition: "103",
        creationDate: "2022-03-22T14:22:49.184Z"
      },
      medicalAdministrationList: []
    },
    {
      medicalRequest: {
        resourceType: ResourceType.MedicalRequest,
        id: "797",
        versionId: "1",
        patient: "65",
        practitioner: "34",
        originalPractitioner: "34",
        statusReason: MedicalRequestStatus.Active,
        expirationDate: "2022-03-22T14:23:12.000Z",
        lastRenewDate: "2022-03-22T14:22:48.000Z",
        lastEditDate: "2022-03-22T14:22:48.000Z",
        mainText:
          "<p>Medicación 6 (Diluyente 2) solución 100.0 ml 12 ml por vía oral</p>",
        supplementaryText: "<p></p>",
        reportText:
          "<p>Medicación 6 (Diluyente 2) solución 100.0 ml 12 ml por vía oral </p>",
        requestData: {
          mainMedication: {
            resourceType: ResourceType.Medication,
            id: "90",
            name: "Medicación 6 (Diluyente 2)",
            alternativeName: ["qwe", "qwee"],
            description: "Medicación 6 (Diluyente 2) solución 100.0 ml",
            status: MedicationStatus.Active,
            form: "solución",
            presentationUnit: "frasco",
            presentationUnitAmount: {
              value: 100,
              unit: "ml"
            },
            healthcareUnits: [
              {
                value: 100,
                unit: "ml"
              }
            ],
            ingredient: [],
            routeList: ["oral"],
            characteristic: [
              "solvent",
              "phpSolution",
              "volumeExpansionSolution",
              "continuousInfusionSolution",
              "aerosolTherapySolution"
            ],
            organization: "25",
            functionCode: "ABC1234",
            creationDate: "2021-12-29T15:39:41.276Z",
            presentationList: []
          },
          dose: {
            quantity: 12,
            unit: "ml"
          },
          rate: {},
          dilution: {},
          medicationStructure: [],
          route: "oral",
          frequency: {
            startDate: "2022-03-22",
            type: RequestFrequencyType.FixedHours,
            timeList: ["21:00:00"],
            meals: [],
            extraDoseNow: false,
            weekDayList: []
          },
          providedByPatient: false,
          extraData: []
        },
        medicalDevice: [],
        definition: "103",
        creationDate: "2022-03-22T14:22:49.184Z"
      },
      medicalAdministrationList: []
    },
    {
      medicalRequest: {
        resourceType: ResourceType.MedicalRequest,
        id: "797",
        versionId: "1",
        patient: "65",
        practitioner: "34",
        originalPractitioner: "34",
        statusReason: MedicalRequestStatus.Cancelled,
        expirationDate: "2022-03-22T14:23:12.000Z",
        lastRenewDate: "2022-03-22T14:22:48.000Z",
        lastEditDate: "2022-03-22T14:22:48.000Z",
        mainText:
          "<p>Medicación 6 (Diluyente 2) solución 100.0 ml 12 ml por vía oral</p>",
        supplementaryText: "<p></p>",
        reportText:
          "<p>Medicación 6 (Diluyente 2) solución 100.0 ml 12 ml por vía oral </p>",
        requestData: {
          mainMedication: {
            resourceType: ResourceType.Medication,
            id: "90",
            name: "Medicación 6 (Diluyente 2)",
            alternativeName: ["qwe", "qwee"],
            description: "Medicación 6 (Diluyente 2) solución 100.0 ml",
            status: MedicationStatus.Active,
            form: "solución",
            presentationUnit: "frasco",
            presentationUnitAmount: {
              value: 100,
              unit: "ml"
            },
            healthcareUnits: [
              {
                value: 100,
                unit: "ml"
              }
            ],
            ingredient: [],
            routeList: ["oral"],
            characteristic: [
              "solvent",
              "phpSolution",
              "volumeExpansionSolution",
              "continuousInfusionSolution",
              "aerosolTherapySolution"
            ],
            organization: "25",
            functionCode: "ABC1234",
            creationDate: "2021-12-29T15:39:41.276Z",
            presentationList: []
          },
          dose: {
            quantity: 12,
            unit: "ml"
          },
          rate: {},
          dilution: {},
          medicationStructure: [],
          route: "oral",
          frequency: {
            startDate: "2022-03-22",
            type: RequestFrequencyType.FixedHours,
            timeList: ["21:00:00"],
            meals: [],
            extraDoseNow: false,
            weekDayList: []
          },
          providedByPatient: false,
          extraData: []
        },
        medicalDevice: [],
        definition: "103",
        creationDate: "2022-03-22T14:22:49.184Z"
      },
      medicalAdministrationList: []
    },
    {
      medicalRequest: {
        resourceType: ResourceType.MedicalRequest,
        id: "797",
        versionId: "1",
        patient: "65",
        practitioner: "34",
        originalPractitioner: "34",
        statusReason: MedicalRequestStatus.Completed,
        expirationDate: "2022-03-22T14:23:12.000Z",
        lastRenewDate: "2022-03-22T14:22:48.000Z",
        lastEditDate: "2022-03-22T14:22:48.000Z",
        mainText:
          "<p>Medicación 6 (Diluyente 2) solución 100.0 ml 12 ml por vía oral</p>",
        supplementaryText: "<p></p>",
        reportText:
          "<p>Medicación 6 (Diluyente 2) solución 100.0 ml 12 ml por vía oral </p>",
        requestData: {
          mainMedication: {
            resourceType: ResourceType.Medication,
            id: "90",
            name: "Medicación 6 (Diluyente 2)",
            alternativeName: ["qwe", "qwee"],
            description: "Medicación 6 (Diluyente 2) solución 100.0 ml",
            status: MedicationStatus.Active,
            form: "solución",
            presentationUnit: "frasco",
            presentationUnitAmount: {
              value: 100,
              unit: "ml"
            },
            healthcareUnits: [
              {
                value: 100,
                unit: "ml"
              }
            ],
            ingredient: [],
            routeList: ["oral"],
            characteristic: [
              "solvent",
              "phpSolution",
              "volumeExpansionSolution",
              "continuousInfusionSolution",
              "aerosolTherapySolution"
            ],
            organization: "25",
            functionCode: "ABC1234",
            creationDate: "2021-12-29T15:39:41.276Z",
            presentationList: []
          },
          dose: {
            quantity: 12,
            unit: "ml"
          },
          rate: {},
          dilution: {},
          medicationStructure: [],
          route: "oral",
          frequency: {
            startDate: "2022-03-22",
            type: RequestFrequencyType.FixedHours,
            timeList: ["21:00:00"],
            meals: [],
            extraDoseNow: false,
            weekDayList: []
          },
          providedByPatient: false,
          extraData: []
        },
        medicalDevice: [],
        definition: "103",
        creationDate: "2022-03-22T14:22:49.184Z"
      },
      medicalAdministrationList: []
    },
    {
      medicalRequest: {
        resourceType: ResourceType.MedicalRequest,
        id: "797",
        versionId: "1",
        patient: "65",
        practitioner: "34",
        originalPractitioner: "34",
        statusReason: MedicalRequestStatus.Completed,
        expirationDate: "2022-03-22T14:23:12.000Z",
        lastRenewDate: "2022-03-22T14:22:48.000Z",
        lastEditDate: "2022-03-22T14:22:48.000Z",
        mainText:
          "<p>Medicación 6 (Diluyente 2) solución 100.0 ml 12 ml por vía oral</p>",
        supplementaryText: "<p></p>",
        reportText:
          "<p>Medicación 6 (Diluyente 2) solución 100.0 ml 12 ml por vía oral </p>",
        requestData: {
          mainMedication: {
            resourceType: ResourceType.Medication,
            id: "90",
            name: "Medicación 6 (Diluyente 2)",
            alternativeName: ["qwe", "qwee"],
            description: "Medicación 6 (Diluyente 2) solución 100.0 ml",
            status: MedicationStatus.Active,
            form: "solución",
            presentationUnit: "frasco",
            presentationUnitAmount: {
              value: 100,
              unit: "ml"
            },
            healthcareUnits: [
              {
                value: 100,
                unit: "ml"
              }
            ],
            ingredient: [],
            routeList: ["oral"],
            characteristic: [
              "solvent",
              "phpSolution",
              "volumeExpansionSolution",
              "continuousInfusionSolution",
              "aerosolTherapySolution"
            ],
            organization: "25",
            functionCode: "ABC1234",
            creationDate: "2021-12-29T15:39:41.276Z",
            presentationList: []
          },
          dose: {
            quantity: 12,
            unit: "ml"
          },
          rate: {},
          dilution: {},
          medicationStructure: [],
          route: "oral",
          frequency: {
            startDate: "2022-03-22",
            type: RequestFrequencyType.FixedHours,
            timeList: ["21:00:00"],
            meals: [],
            extraDoseNow: false,
            weekDayList: []
          },
          providedByPatient: false,
          extraData: []
        },
        medicalDevice: [],
        definition: "103",
        creationDate: "2022-03-22T14:22:49.184Z"
      },
      medicalAdministrationList: []
    },
    {
      medicalRequest: {
        resourceType: ResourceType.MedicalRequest,
        id: "797",
        versionId: "1",
        patient: "65",
        practitioner: "34",
        originalPractitioner: "34",
        statusReason: MedicalRequestStatus.DischargeCancelled,
        expirationDate: "2022-03-22T14:23:12.000Z",
        lastRenewDate: "2022-03-22T14:22:48.000Z",
        lastEditDate: "2022-03-22T14:22:48.000Z",
        mainText:
          "<p>Medicación 6 (Diluyente 2) solución 100.0 ml 12 ml por vía oral</p>",
        supplementaryText: "<p></p>",
        reportText:
          "<p>Medicación 6 (Diluyente 2) solución 100.0 ml 12 ml por vía oral </p>",
        requestData: {
          mainMedication: {
            resourceType: ResourceType.Medication,
            id: "90",
            name: "Medicación 6 (Diluyente 2)",
            alternativeName: ["qwe", "qwee"],
            description: "Medicación 6 (Diluyente 2) solución 100.0 ml",
            status: MedicationStatus.Active,
            form: "solución",
            presentationUnit: "frasco",
            presentationUnitAmount: {
              value: 100,
              unit: "ml"
            },
            healthcareUnits: [
              {
                value: 100,
                unit: "ml"
              }
            ],
            ingredient: [],
            routeList: ["oral"],
            characteristic: [
              "solvent",
              "phpSolution",
              "volumeExpansionSolution",
              "continuousInfusionSolution",
              "aerosolTherapySolution"
            ],
            organization: "25",
            functionCode: "ABC1234",
            creationDate: "2021-12-29T15:39:41.276Z",
            presentationList: []
          },
          dose: {
            quantity: 12,
            unit: "ml"
          },
          rate: {},
          dilution: {},
          medicationStructure: [],
          route: "oral",
          frequency: {
            startDate: "2022-03-22",
            type: RequestFrequencyType.FixedHours,
            timeList: ["21:00:00"],
            meals: [],
            extraDoseNow: false,
            weekDayList: []
          },
          providedByPatient: false,
          extraData: []
        },
        medicalDevice: [],
        definition: "103",
        creationDate: "2022-03-22T14:22:49.184Z"
      },
      medicalAdministrationList: []
    },
    {
      medicalRequest: {
        resourceType: ResourceType.MedicalRequest,
        id: "797",
        versionId: "1",
        patient: "65",
        practitioner: "34",
        originalPractitioner: "34",
        statusReason: MedicalRequestStatus.Expired,
        expirationDate: "2022-03-22T14:23:12.000Z",
        lastRenewDate: "2022-03-22T14:22:48.000Z",
        lastEditDate: "2022-03-22T14:22:48.000Z",
        mainText:
          "<p>Medicación 6 (Diluyente 2) solución 100.0 ml 12 ml por vía oral</p>",
        supplementaryText: "<p></p>",
        reportText:
          "<p>Medicación 6 (Diluyente 2) solución 100.0 ml 12 ml por vía oral </p>",
        requestData: {
          mainMedication: medication6Mock,
          dose: {
            quantity: 12,
            unit: "ml"
          },
          rate: {},
          dilution: {},
          medicationStructure: [],
          route: "oral",
          frequency: {
            startDate: "2022-03-22",
            type: RequestFrequencyType.FixedHours,
            timeList: ["21:00:00"],
            meals: [],
            extraDoseNow: false,
            weekDayList: []
          },
          providedByPatient: false,
          extraData: []
        },
        medicalDevice: [],
        definition: "103",
        creationDate: "2022-03-22T14:22:49.184Z"
      },
      medicalAdministrationList: []
    },
    {
      medicalRequest: {
        resourceType: ResourceType.MedicalRequest,
        id: "797",
        versionId: "1",
        patient: "65",
        practitioner: "34",
        originalPractitioner: "34",
        statusReason: MedicalRequestStatus.ToExpire,
        expirationDate: "2022-03-22T14:23:12.000Z",
        lastRenewDate: "2022-03-22T14:22:48.000Z",
        lastEditDate: "2022-03-22T14:22:48.000Z",
        mainText:
          "<p>Medicación 6 (Diluyente 2) solución 100.0 ml 12 ml por vía oral</p>",
        supplementaryText: "<p></p>",
        reportText:
          "<p>Medicación 6 (Diluyente 2) solución 100.0 ml 12 ml por vía oral </p>",
        requestData: {
          mainMedication: {
            resourceType: ResourceType.Medication,
            id: "90",
            name: "Medicación 6 (Diluyente 2)",
            alternativeName: ["qwe", "qwee"],
            description: "Medicación 6 (Diluyente 2) solución 100.0 ml",
            status: MedicationStatus.Active,
            form: "solución",
            presentationUnit: "frasco",
            presentationUnitAmount: {
              value: 100,
              unit: "ml"
            },
            healthcareUnits: [
              {
                value: 100,
                unit: "ml"
              }
            ],
            ingredient: [],
            routeList: ["oral"],
            characteristic: [
              "solvent",
              "phpSolution",
              "volumeExpansionSolution",
              "continuousInfusionSolution",
              "aerosolTherapySolution"
            ],
            organization: "25",
            functionCode: "ABC1234",
            creationDate: "2021-12-29T15:39:41.276Z",
            presentationList: []
          },
          dose: {
            quantity: 12,
            unit: "ml"
          },
          rate: {},
          dilution: {},
          medicationStructure: [],
          route: "oral",
          frequency: {
            startDate: "2022-03-22",
            type: RequestFrequencyType.FixedHours,
            timeList: ["21:00:00"],
            meals: [],
            extraDoseNow: false,
            weekDayList: []
          },
          providedByPatient: false,
          extraData: []
        },
        medicalDevice: [],
        definition: "103",
        creationDate: "2022-03-22T14:22:49.184Z"
      },
      medicalAdministrationList: []
    }
  ];
