import { Property } from "csstype";
import { marginMixin, paddingMixin } from "model/style/box";

export interface DestyledListResult
  extends Partial<
    Record<
      | "margin-top"
      | "margin-right"
      | "margin-bottom"
      | "margin-left"
      | "padding-top"
      | "padding-right"
      | "padding-bottom"
      | "padding-left",
      string
    >
  > {
  listStyle: Property.ListStyle;
  listStyleType: Property.ListStyleType;
}

export const destyledListMixin = (): DestyledListResult => {
  return {
    ...marginMixin({ topBottom: 0 }),
    ...paddingMixin({ left: 0 }),
    listStyle: "none",
    listStyleType: "none"
  };
};
