import { getMapValueBuilder } from "@laba/ts-common";
import {
  ElevationVariant,
  SizeVariant,
  StyleVariant,
  TextVariant,
  TypeVariant
} from "model/themeVariant";
import {
  borderMixin,
  createStyle,
  getClassName,
  hoverMixin,
  marginMixin,
  paddingMixin,
  pxToRem
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import {
  getThemeButtonRadius,
  getThemeStyleColor,
  getThemeStyleState,
  themeElevationMixin,
  themeTextMixin
} from "model/themeUtils";
import {
  ClassNameDynamicGeneratedRecord,
  getDefaultButtonBackgroundColor,
  getDefaultButtonHoverBackgroundColor,
  getGenericButtonColor
} from "components/utils/getGenericButtonColors";

export interface DefaultButtonStyleProps {
  size: SizeVariant;
  style: StyleVariant;
  type: TypeVariant;
  fullWidth: boolean;
  disabled: boolean;
}

interface DefaultButtonClassNames {
  root?: string;
  disabled?: string;
  label?: string;
  startIcon?: string;
}

const getHeight = getMapValueBuilder({
  [SizeVariant.Large]: pxToRem(44),
  [SizeVariant.Medium]: pxToRem(40),
  [SizeVariant.Small]: pxToRem(36),
  [SizeVariant.ExtraSmall]: pxToRem(24)
});

const generatedStyleKeyTemplate = (
  size: SizeVariant,
  style: StyleVariant,
  type: TypeVariant
) => `generatedStyle${size}${style}${type}`;
const generateDynamicStylesClassNames = (
  theme: Theme
): ClassNameDynamicGeneratedRecord => {
  const classNameObject: ClassNameDynamicGeneratedRecord = {};
  Object.values(SizeVariant).forEach(sizeVariant => {
    Object.values(StyleVariant).forEach(styleVariant => {
      Object.values(TypeVariant).forEach(typeVariant => {
        classNameObject[
          generatedStyleKeyTemplate(sizeVariant, styleVariant, typeVariant)
        ] = {
          ...borderMixin({
            radius: {
              all: getThemeButtonRadius(theme)
            },
            side: { all: pxToRem(1) },
            color: getThemeStyleColor(
              theme,
              styleVariant,
              ThemeStyleColorVariant.Main
            )
          }),
          ...hoverMixin({
            backgroundColor: getDefaultButtonHoverBackgroundColor(
              theme,
              styleVariant,
              typeVariant
            )
          }),
          height: getHeight(sizeVariant),
          backgroundColor: getDefaultButtonBackgroundColor(theme, {
            style: styleVariant,
            type: typeVariant
          }),
          color: getGenericButtonColor(theme, {
            style: styleVariant,
            type: typeVariant
          })
        };
      });
    });
  });
  return classNameObject;
};

const useDefaultButtonStyle = createStyle(
  (theme: Theme) => ({
    ...generateDynamicStylesClassNames(theme)
  }),
  "DefaultButton"
);

const useDefaultButtonStaticStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...paddingMixin({ leftRight: 20 }),
      ...hoverMixin({
        ...borderMixin({
          side: { all: pxToRem(1) }
        }),
        ...themeElevationMixin(theme, ElevationVariant.Elevation2)
      })
    },
    disabled: {
      "&:disabled": {
        ...borderMixin({
          side: { all: pxToRem(1) },
          color: getThemeStyleState(theme, StyleVariant.Primary).disabled
        }),
        ...themeElevationMixin(theme, ElevationVariant.Elevation0)
      }
    },
    label: {
      ...themeTextMixin(theme, TextVariant.Button, 1)
    },
    startIcon: {
      ...marginMixin({ left: 0 }),
      "& > *:first-child": {
        fontSize: pxToRem(24)
      }
    }
  }),
  "DefaultButtonStatic"
);

export const useDefaultButtonStylesClasses = (
  props: DefaultButtonStyleProps
): DefaultButtonClassNames => {
  const dynamicClasses = useDefaultButtonStyle();
  const staticClasses = useDefaultButtonStaticStyle();

  return {
    root: getClassName(
      staticClasses.root,
      dynamicClasses[
        generatedStyleKeyTemplate(props.size, props.style, props.type)
      ]
    ),
    disabled: staticClasses.disabled,
    label: staticClasses.label,
    startIcon: staticClasses.startIcon
  };
};
