import { GenericObject } from "@laba/ts-common";

export const getEnumControl = <T>(e: T): GenericObject => {
  return {
    options: Object.values(e),
    control: { type: "select" }
  };
};

export const getNumberControl = (): GenericObject => ({
  type: "number"
});

export const getBooleanControl = (): GenericObject => ({
  type: "boolean"
});

export const getStringControl = (): GenericObject => ({
  type: "string"
});

export const getObjectControl = (): GenericObject => ({
  type: "object"
});

export const getDisabledControl = (): GenericObject => ({
  table: {
    disable: true
  }
});

export const getColorControl = (): GenericObject => ({
  control: { type: "color" }
});
