import { FC, Form, useI18n } from "@laba/react-common";
import React, { useCallback, useMemo, useState } from "react";
import { DefaultButton, Popup } from "@laba/nexup-components";
import { tkCP } from "translation/i18n";
import { useAppDispatch } from "store/store";
import { organizationOptions } from "components/pages/Dashboard/SantaCatalinaPayrollInput/SantaCatalinaPayrollFormContent";
import { onSantaCatalinaPayrollAllocationsImport } from "store/dashboard/event";
import { Duration, getCurrentDateTime } from "@laba/ts-common";
import { SantaCatalinaPayrollAllocationsRequestBody } from "@laba/nexup-api";
import { SantaCatalinaPayrollAllocationsFormContent } from "./SantaCatalinaPayrollAllocationsFormContent";

export interface SantaCatalinaPayrollInputProps {
  onImportFinished?: (success: boolean) => void;
}

const tk = tkCP.adminPage.dashboard.santaCatalinaPayrollAllocations;
const formId = "SantaCatalinaPayrollAllocationsInputForm";

export const SantaCatalinaPayrollAllocationsInput: FC<
  SantaCatalinaPayrollInputProps
> = ({ onImportFinished }) => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const [showSantaCatalinaPayroll, setShowSantaCatalinaPayroll] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const initialValues =
    useMemo<SantaCatalinaPayrollAllocationsRequestBody>(() => {
      const currentDate = getCurrentDateTime().minus(
        Duration.fromObject({ month: 1 })
      );
      return {
        year: String(currentDate.year),
        month: String(currentDate.month),
        typeId: String(1),
        organizationIdList: organizationOptions
      };
    }, []);
  const onSubmit = useCallback(
    async (requestData: SantaCatalinaPayrollAllocationsRequestBody) => {
      setLoading(true);
      const result = await dispatch(
        onSantaCatalinaPayrollAllocationsImport(requestData)
      );
      setLoading(false);
      setShowSantaCatalinaPayroll(false);
      onImportFinished?.(result);
    },
    [setLoading, setShowSantaCatalinaPayroll, onImportFinished, dispatch]
  );

  const onClick = useCallback(
    () => setShowSantaCatalinaPayroll(true),
    [setShowSantaCatalinaPayroll]
  );
  const onClose = useCallback(
    () => setShowSantaCatalinaPayroll(false),
    [setShowSantaCatalinaPayroll]
  );
  return (
    <>
      <DefaultButton
        title={t(tk.button)}
        text={t(tk.button)}
        onClick={onClick}
      />
      {showSantaCatalinaPayroll && (
        <Popup
          open={showSantaCatalinaPayroll}
          closableHeader
          headerTitle={t(tk.title)}
          primaryButtonText={t(tk.sendImport)}
          disablePrimaryButton={false}
          formId={formId}
          loading={loading}
          onClose={onClose}
          disableBackdropClick
        >
          <Form<SantaCatalinaPayrollAllocationsRequestBody>
            id={formId}
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            <SantaCatalinaPayrollAllocationsFormContent />
          </Form>
        </Popup>
      )}
    </>
  );
};
