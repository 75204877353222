import {
  createStyle,
  GridAutoFlow,
  gridContainerMixin,
  gridItemMixin,
  marginMixin,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

interface CheckboxGroupDynamicStylesProps {
  gridColumns: number;
  hasError: boolean;
  rowGap?: number;
  columnGap?: number;
}

interface PrescriptionItemClassNames {
  root?: string;
  lastChild?: string;
  itemsWrapper?: string;
  errorText?: string;
}

const useCheckboxGroupStaticStyles = createStyle(
  (_theme: Theme) => ({
    root: { width: "100%" },
    lastChild: {
      ...gridItemMixin({
        columnSpan: 2
      })
    }
  }),
  "CheckboxGroupStatic"
);

const useCheckboxGroupDynamicStyles = createStyle(
  (theme: Theme) => ({
    itemsWrapper: (props: CheckboxGroupDynamicStylesProps) => ({
      ...gridContainerMixin({
        templateColumn: [`repeat(${props.gridColumns}, minmax(0, 1fr))`],
        autoFlow: GridAutoFlow.Row,
        rowGap: props.rowGap,
        columnGap: props.columnGap
      })
    }),
    errorText: (props: CheckboxGroupDynamicStylesProps) => ({
      ...themeTextMixin(theme, TextVariant.Caption),
      ...marginMixin({ leftRight: 14, top: 4 }),
      color: props.hasError
        ? getThemeStyleColor(theme, StyleVariant.Danger)
        : getThemeStyleColor(
            theme,
            StyleVariant.Primary,
            ThemeStyleColorVariant.GrayDark
          )
    })
  }),
  "CheckboxGroupDynamic"
);

export const useCheckboxGroupStyles = (
  props: CheckboxGroupDynamicStylesProps
): PrescriptionItemClassNames => {
  const staticClasses = useCheckboxGroupStaticStyles();
  const dynamicClasses = useCheckboxGroupDynamicStyles(props);

  return {
    root: staticClasses.root,
    lastChild: staticClasses.lastChild,
    itemsWrapper: dynamicClasses.itemsWrapper
  };
};
