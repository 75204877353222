import { ApiDate } from "@laba/ts-common";
import { Model } from "./model/model";

export enum ResourceType {
  Address = "Address",
  AllergyIntolerance = "AllergyIntolerance",
  Attachment = "File",
  Appointment = "Appointment",
  AppointmentDefinition = "AppointmentDefinition",
  Bed = "Bed",
  Condition = "Condition",
  DailyEvolution = "DailyEvolution",
  Email = "Email",
  Encounter = "Encounter",
  Error = "Error",
  Group = "Group",
  HealthcareService = "HealthcareService",
  Hospitalization = "Hospitalization",
  Identifier = "Identifier",
  Location = "Location",
  Medication = "Medication",
  MedicalAdministration = "MedicalAdministration",
  MedicalDevice = "MedicalDevice",
  MedicalRequest = "MedicalRequest",
  MedicalRequestDefinition = "MedicalRequestDefinition",
  MedicationSubstance = "MedicationSubstance",
  Organization = "Organization",
  Phone = "Phone",
  Patient = "Patient",
  PatientMeasure = "PatientMeasure",
  PatientMedia = "PatientMedia",
  PatientTag = "PatientTag",
  Person = "Person",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
  Questionnaire = "Questionnaire",
  QuestionnaireResponse = "QuestionnaireResponse",
  CodeSystem = "CodeSystem",
  InPersonEncounter = "InPersonEncounter",
  VirtualGuardEncounter = "VirtualGuardEncounter",
  VideoAppointmentEncounter = "VideoAppointmentEncounter",
  ActivityDefinition = "ActivityDefinition",
  MeasurementGroup = "MeasurementGroup",
  MeasurementGroupDefinition = "MeasurementGroupDefinition",
  PrescriptionRequest = "PrescriptionRequest",
  ProcedureReport = "ProcedureReport",
  ProcedureReportDefinition = "ProcedureReportDefinition",
  Schedule = "Schedule",
  ScheduleDefinition = "ScheduleDefinition",
  Product = "Product",
  ConsumptionGroup = "ConsumptionGroup",
  ConsumptionItem = "ConsumptionItem",
  Consumption = "Consumption",
  Invoice = "Invoice",
  Account = "Account",
  Odontogram = "Odontogram",
  BillingContract = "BillingContract",
  PublicPatient = "PublicPatient",
  PublicSchedule = "PublicSchedule",
  PublicPractitioner = "PublicPractitioner",
  PublicLocation = "PublicLocation",
  PublicOrganization = "PublicOrganization",
  PublicAppointment = "PublicAppointment"
}

export interface ResourceModel<RType extends ResourceType> extends Model {
  resourceType: RType;
  creationDate?: ApiDate;
  lastEditDate?: ApiDate;
}
