import { QuestionnaireResponse } from "model/resource/evolution/questionnaireResponse/questionnaireResponse";
import { ConsumptionGroupWithExtraData } from "model/resource/finance/consumption";
import { getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";

export interface QuestionnaireExtraDataValues {
  consumptionGroupWithExtraData?: ConsumptionGroupWithExtraData;
}

export type QuestionnaireCardExtraDataField = Record<
  string,
  QuestionnaireExtraDataValues[]
>;

export type QuestionnaireExtraDataCard = Record<
  string,
  QuestionnaireCardExtraDataField
>;
export interface QuestionnaireResponseWithExtraData {
  questionnaireResponse: QuestionnaireResponse;
  cardsExtraData?: QuestionnaireExtraDataCard;
}

export const questionnaireResponseWithExtraDataParamsKey = getKeyObj(
  createHydratedMock<QuestionnaireResponseWithExtraData>()
);

export const QuestionnaireExtraDataValuesParamsKey = getKeyObj(
  createHydratedMock<QuestionnaireExtraDataValues>()
);
