import { ResourceType } from "model/primitives/resourceModel";
import { PublicationStatus } from "model/resource/activity/activityDefinition";
import { KnownAppointmentType } from "../appointment";
import { AppointmentDefinition } from "../appointmentDefinition";

export const mockedAppointmentDefinition: AppointmentDefinition = {
  resourceType: ResourceType.Appointment,
  availableServiceList: ["131"],
  notificationChannelList: [],
  slotConfig: { minDuration: 15, durationList: [15, 30, 45, 60] },
  templateList: [],
  code: KnownAppointmentType.VideoChat,
  consultationReasonSystem: "mockedConsultationReasonSystem",
  id: "1",
  status: PublicationStatus.Active,
  name: "Mocked Appointment Def",
  organization: "1"
};
