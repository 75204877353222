import {
  Questionnaire,
  QuestionnaireFlowConfig,
  QuestionnaireType
} from "model/resource/evolution/questionnaire/questionnaire";
import { ResourceType } from "model/primitives/resourceModel";
import { PublicationStatus } from "model/resource/activity/activityDefinition";
import {
  QuestionnaireDateSpecificValues,
  QuestionnaireFieldPropertyType,
  QuestionnaireFieldSize,
  QuestionnaireFieldType
} from "model/resource/evolution/questionnaire/questionnaireField";
import { MedicationAmountKnownUnit } from "model/resource/prescription/medication/medication";
import {
  QuestionnaireEnableWhenBehavior,
  QuestionnaireEnableWhenOperator
} from "../questionnaire/questionnaireItemEnableWhen";

const smallOrderQuestionnaireCatA: Questionnaire = {
  resourceType: ResourceType.Questionnaire,
  cards: [],
  category: "A",
  name: "",
  title: "SA",
  organization: "1",
  responseTitleTemplate: "",
  status: PublicationStatus.Active,
  order: 1,
  responseDescriptionTemplate: ""
};

const bigOrderQuestionnaireCatA: Questionnaire = {
  resourceType: ResourceType.Questionnaire,
  cards: [],
  category: "A",
  name: "",
  title: "BA",
  organization: "1",
  responseTitleTemplate: "",
  status: PublicationStatus.Active,
  order: 100000000000,
  responseDescriptionTemplate: ""
};

const mediumOrderQuestionnaireCatB: Questionnaire = {
  resourceType: ResourceType.Questionnaire,
  cards: [],
  category: "B",
  name: "",
  title: "MB",
  organization: "1",
  responseTitleTemplate: "",
  status: PublicationStatus.Active,
  order: 100,
  responseDescriptionTemplate: ""
};

const unorderedQuestionnaireCatA: Questionnaire = {
  resourceType: ResourceType.Questionnaire,
  cards: [],
  category: "A",
  name: "",
  title: "UA",
  organization: "1",
  responseTitleTemplate: "",
  status: PublicationStatus.Active,
  responseDescriptionTemplate: ""
};

const questionnaireWithCards: Questionnaire = {
  resourceType: ResourceType.Questionnaire,
  cards: [
    {
      fields: [
        {
          linkId: "1",
          fieldType: QuestionnaireFieldType.Selector,
          size: QuestionnaireFieldSize.Half,
          detailSize: QuestionnaireFieldSize.Half,
          initialValue: [],
          property: [
            { type: QuestionnaireFieldPropertyType.Option, value: "hola" },
            { type: QuestionnaireFieldPropertyType.Option, value: "chau" }
          ]
        }
      ],
      linkId: "link1",
      title: "autocuidado"
    },
    {
      fields: [],
      linkId: "link2",
      title: "control de esfínter"
    },
    {
      fields: [],
      linkId: "link3",
      title: "comunicación"
    }
  ],
  category: "A",
  name: "",
  title: "UA",
  organization: "1",
  responseTitleTemplate: "",
  status: PublicationStatus.Active,
  responseDescriptionTemplate: ""
};

const questionnaireWithFields: Questionnaire = {
  resourceType: ResourceType.Questionnaire,
  category: "Test",
  cards: [
    {
      linkId: "Texto1",
      title: "Card 1",
      fields: [
        {
          size: QuestionnaireFieldSize.Whole,
          detailSize: QuestionnaireFieldSize.Whole,
          fieldType: QuestionnaireFieldType.AllergyList,
          initialValue: [],
          linkId: "Allergy",
          property: []
        },
        {
          size: QuestionnaireFieldSize.Whole,
          detailSize: QuestionnaireFieldSize.Whole,
          fieldType: QuestionnaireFieldType.ProblemList,
          initialValue: [],
          linkId: "ProblemList",
          title: "Listado de problemas",
          property: [
            {
              type: QuestionnaireFieldPropertyType.RelateSolvedProblem,
              value: "true"
            },
            { type: QuestionnaireFieldPropertyType.MaxElements, value: "1" }
          ]
        },
        {
          size: QuestionnaireFieldSize.Whole,
          detailSize: QuestionnaireFieldSize.Whole,
          fieldType: QuestionnaireFieldType.TextField,
          initialValue: [{ valueString: "ValorInicial", property: [] }],
          linkId: "TextField1",
          property: [
            {
              type: QuestionnaireFieldPropertyType.Placeholder,
              value: "Placeholder text1"
            },
            {
              type: QuestionnaireFieldPropertyType.SingleLine,
              value: "3"
            }
          ]
        },
        {
          size: QuestionnaireFieldSize.Whole,
          detailSize: QuestionnaireFieldSize.Whole,
          fieldType: QuestionnaireFieldType.Numeric,
          initialValue: [
            {
              valueQuantity: {
                quantity: 1,
                unit: MedicationAmountKnownUnit.Ampoule
              },
              property: []
            }
          ],
          linkId: "TextField0",
          property: [
            {
              type: QuestionnaireFieldPropertyType.Placeholder,
              value: "Placeholder text1"
            },
            {
              type: QuestionnaireFieldPropertyType.MinValue,
              value: "5"
            },
            {
              type: QuestionnaireFieldPropertyType.MaxValue,
              value: "50"
            },
            {
              type: QuestionnaireFieldPropertyType.Step,
              value: "0.5"
            },
            {
              type: QuestionnaireFieldPropertyType.Unit,
              value: "Unit 0"
            },
            {
              type: QuestionnaireFieldPropertyType.Unit,
              value: "Unit 1"
            },
            {
              type: QuestionnaireFieldPropertyType.Unit,
              value: "Unit 2"
            },
            {
              type: QuestionnaireFieldPropertyType.Unit,
              value: "Unit 3"
            }
          ]
        },
        {
          size: QuestionnaireFieldSize.Whole,
          detailSize: QuestionnaireFieldSize.Whole,
          fieldType: QuestionnaireFieldType.Numeric,
          initialValue: [
            {
              valueQuantity: {
                quantity: 1,
                unit: MedicationAmountKnownUnit.Ampoule
              },
              property: []
            }
          ],
          linkId: "TextField00",
          property: [
            {
              type: QuestionnaireFieldPropertyType.Placeholder,
              value: "Placeholder text1"
            }
          ]
        },
        {
          title: "SearchableList",
          size: QuestionnaireFieldSize.Whole,
          detailSize: QuestionnaireFieldSize.Whole,
          fieldType: QuestionnaireFieldType.SearchableList,
          initialValue: [{ valueCoding: "Valor1", property: [] }],
          linkId: "SearchableList",
          required: true,
          property: [
            { type: QuestionnaireFieldPropertyType.MinElements, value: "0" },
            { type: QuestionnaireFieldPropertyType.MaxElements, value: "2" },
            { type: QuestionnaireFieldPropertyType.Option, value: "Valor1" },
            { type: QuestionnaireFieldPropertyType.Option, value: "Valor2" },
            { type: QuestionnaireFieldPropertyType.Option, value: "Valor3" },
            { type: QuestionnaireFieldPropertyType.Option, value: "Valor4" }
          ]
        },
        {
          title: "Scale score",
          size: QuestionnaireFieldSize.Whole,
          detailSize: QuestionnaireFieldSize.Whole,
          fieldType: QuestionnaireFieldType.ScaleScore,
          initialValue: [],
          linkId: "ScaleScore",
          required: true,
          property: [
            {
              type: QuestionnaireFieldPropertyType.Placeholder,
              value: "Ingrese una observacion"
            },
            { type: QuestionnaireFieldPropertyType.Score, value: "1" },
            { type: QuestionnaireFieldPropertyType.Score, value: "2" },
            { type: QuestionnaireFieldPropertyType.Score, value: "3" },
            { type: QuestionnaireFieldPropertyType.Score, value: "4" }
          ]
        }
      ]
    },
    {
      linkId: "Texto2",
      title: "Card 2",
      fields: [
        {
          size: QuestionnaireFieldSize.Whole,
          detailSize: QuestionnaireFieldSize.Whole,
          fieldType: QuestionnaireFieldType.TextField,
          initialValue: [{ valueString: "ValorInicial2", property: [] }],
          linkId: "TextField2",
          property: [
            {
              type: QuestionnaireFieldPropertyType.Placeholder,
              value: "Placeholder text1"
            },
            {
              type: QuestionnaireFieldPropertyType.SingleLine,
              value: "3"
            }
          ]
        },
        {
          size: QuestionnaireFieldSize.Half,
          detailSize: QuestionnaireFieldSize.Half,
          fieldType: QuestionnaireFieldType.Date,
          initialValue: [
            {
              valueCoding: QuestionnaireDateSpecificValues.CreationDate,
              property: []
            }
          ],
          linkId: "DateInput",
          property: [
            {
              type: QuestionnaireFieldPropertyType.MinDate,
              value: QuestionnaireDateSpecificValues.CurrentDate
            }
          ]
        },
        {
          size: QuestionnaireFieldSize.Half,
          detailSize: QuestionnaireFieldSize.Half,
          fieldType: QuestionnaireFieldType.Datetime,
          initialValue: [
            {
              valueCoding: QuestionnaireDateSpecificValues.CreationDate,
              property: []
            }
          ],
          linkId: "DateTimeInput",
          property: [
            {
              type: QuestionnaireFieldPropertyType.MinDate,
              value: QuestionnaireDateSpecificValues.CurrentDate
            }
          ]
        },
        {
          size: QuestionnaireFieldSize.Whole,
          detailSize: QuestionnaireFieldSize.Whole,
          fieldType: QuestionnaireFieldType.FileList,
          initialValue: [],
          linkId: "FileList",
          property: [
            { type: QuestionnaireFieldPropertyType.MaxElements, value: "2" },
            { type: QuestionnaireFieldPropertyType.FileType, value: "image/*" },
            { type: QuestionnaireFieldPropertyType.FileType, value: "mp3" }
          ]
        }
      ]
    }
  ],
  name: "Test",
  organization: "1",
  responseTitleTemplate: "",
  status: PublicationStatus.Active,
  title: "Test",
  responseDescriptionTemplate: ""
};

const questionnaireWithEnabledBehavior: Questionnaire = {
  creationDate: "2022-08-11T00:45:04.640Z",
  name: "Mock de prueba",
  title: "Mock de prueba",
  status: PublicationStatus.Active,
  organization: "10",
  category: "Reales",
  editInterval: 86400,
  color: "#1A865F",
  responseTitleTemplate: "",
  responseDescriptionTemplate: "",
  type: QuestionnaireType.Medical,
  cards: [
    {
      title: "Evolución",
      linkId: "evolucion-diaria",
      fields: [
        {
          title: "Problemas asociados",
          linkId: "problemas",
          initialValue: [],
          fieldType: QuestionnaireFieldType.ProblemList,
          size: QuestionnaireFieldSize.Whole,
          detailSize: QuestionnaireFieldSize.Whole,
          property: [
            {
              type: QuestionnaireFieldPropertyType.CreateProblem,
              value: "true"
            },
            {
              type: QuestionnaireFieldPropertyType.RelateProblem,
              value: "true"
            },
            {
              type: QuestionnaireFieldPropertyType.SolveProblem,
              value: "true"
            }
          ],
          required: true
        },
        {
          linkId: "evolucion",
          initialValue: [],
          fieldType: QuestionnaireFieldType.TextField,
          size: QuestionnaireFieldSize.Whole,
          detailSize: QuestionnaireFieldSize.Whole,
          property: [
            {
              type: QuestionnaireFieldPropertyType.SingleLine,
              value: "false"
            },
            {
              type: QuestionnaireFieldPropertyType.Placeholder,
              value: "Cargue aquí la evolución"
            }
          ],
          required: true
        },
        {
          linkId: "archivos",
          initialValue: [],
          fieldType: QuestionnaireFieldType.FileList,
          size: QuestionnaireFieldSize.Whole,
          detailSize: QuestionnaireFieldSize.Whole,
          property: [],
          required: false
        },
        {
          title: "Fecha de evolución",
          linkId: "fecha",
          initialValue: [
            {
              property: [],
              valueCoding: "CurrentDate"
            }
          ],
          fieldType: QuestionnaireFieldType.Date,
          size: QuestionnaireFieldSize.Whole,
          detailSize: QuestionnaireFieldSize.Whole,
          property: [],
          required: true
        }
      ]
    },
    // esta card solo aparece si el link-id=evolucion tiene algo escrito
    {
      title: "PruebaCard",
      linkId: "PruebaCard",
      enableBehavior: QuestionnaireEnableWhenBehavior.Any,
      enableWhen: [
        {
          linkId: "evolucion",
          answer: { valueBoolean: true },
          operator: QuestionnaireEnableWhenOperator.Exists
        }
      ],
      fields: [
        {
          title: "Por defecto valor inicial sin unidad",
          linkId: "numerico",
          initialValue: [],
          fieldType: QuestionnaireFieldType.Numeric,
          size: QuestionnaireFieldSize.Whole,
          property: [],
          required: false
        },
        // este campo solo aparece si el link-id=numerico tiene un valor mayor a 5
        {
          linkId: "campo-text",
          initialValue: [],
          enableBehavior: QuestionnaireEnableWhenBehavior.Any,
          enableWhen: [
            {
              linkId: "numerico",
              answer: { valueQuantity: { quantity: 5 } },
              operator: QuestionnaireEnableWhenOperator.GreaterThan
            }
          ],
          fieldType: QuestionnaireFieldType.TextField,
          size: QuestionnaireFieldSize.Whole,
          detailSize: QuestionnaireFieldSize.Whole,
          property: [
            {
              type: QuestionnaireFieldPropertyType.SingleLine,
              value: "false"
            },
            {
              type: QuestionnaireFieldPropertyType.Placeholder,
              value: "Cargue aquí la evolución"
            }
          ],
          required: true
        }
      ]
    }
  ],
  featured: false,
  order: 10,
  flowConfig: [QuestionnaireFlowConfig.IsHospitalization],
  resourceType: ResourceType.Questionnaire
};

export const QuestionnaireMocks = {
  smallOrderQuestionnaireCatA,
  bigOrderQuestionnaireCatA,
  mediumOrderQuestionnaireCatB,
  unorderedQuestionnaireCatA,
  questionnaireWithCards,
  questionnaireWithFields,
  questionnaireWithEnabledBehavior
};
