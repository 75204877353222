import { DateTime } from "@laba/ts-common";
import {
  currentTimeIsAvailable,
  AvailableTime
} from "model/resource/utils/availableTime";

export const isHealthcareServiceAvailable = (
  currentTime: DateTime,
  availableTime?: AvailableTime
): boolean => currentTimeIsAvailable(currentTime, availableTime);
