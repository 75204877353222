import React from "react";
import { FC } from "@laba/react-common";
import {
  MedicationSubstanceForm,
  medicationSubstanceJsonToModel,
  medicationSubstanceTransform
} from "components/pages/NexupAdmin/resources/medicationSubstance/MedicationSubstanceForm";
import { Edit } from "components/generic/Edit/Edit";
import { MedicationSubstance, ResourceType } from "@laba/nexup-api";
import { useElementExportedFileName } from "components/hook/useExportedFileName";

export const MedicationSubstanceEdit: FC = () => {
  const exportedFileName = useElementExportedFileName<MedicationSubstance>(
    resource => resource.name
  );
  return (
    <Edit
      resourceTransform={medicationSubstanceTransform}
      resource={ResourceType.MedicationSubstance}
      jsonToModel={medicationSubstanceJsonToModel}
      exportedFileName={exportedFileName}
    >
      <MedicationSubstanceForm />
    </Edit>
  );
};
