import {
  ElevationVariant,
  StyleVariant,
  TextVariant
} from "model/themeVariant";
import { defaultTheme } from "model/defaultTheme";
import {
  elevationMixin,
  ElevationResult,
  gradientMixin,
  GradientResult,
  GradientVariant,
  GradientVariantData,
  textMixin,
  TextMixinResult,
  textMixinWithoutMaxLine,
  ThemeStyleVariantData
} from "@laba/react-common";
import {
  ElevationVariantData,
  RadiusVariant,
  Theme,
  ThemeStyleColorVariant,
  ThemeStyleVariantStateData,
  ThemeTextVariantData
} from "model/theme";

const getSafeTheme = (theme?: Theme): Theme =>
  theme?.isNexupComponentTheme === true ? theme : defaultTheme;

export const getSafeThemeStyle = (
  theme: Theme,
  style: StyleVariant
): ThemeStyleVariantData =>
  getSafeTheme(theme).style[style] ?? defaultTheme.style[style];

export const getThemeStyleColor = (
  theme: Theme,
  style: StyleVariant,
  colorVariant = ThemeStyleColorVariant.Main
): string =>
  getSafeThemeStyle(theme, style).color[colorVariant] ??
  defaultTheme.style[style].color[colorVariant];

export const getThemeElevation = (
  theme: Theme,
  variant: ElevationVariant
): ElevationVariantData =>
  getSafeTheme(theme).elevation[variant] ?? defaultTheme.elevation[variant];

export const getThemeGradient = (
  theme: Theme,
  variant: GradientVariant
): GradientVariantData =>
  getSafeTheme(theme).gradient[variant] ?? defaultTheme.gradient[variant];

export const getThemeStyleState = (
  theme: Theme,
  style: StyleVariant
): ThemeStyleVariantStateData => getSafeThemeStyle(theme, style).state;

export const getThemeTextVariantConfig = (
  theme: Theme,
  textVariant: TextVariant
): ThemeTextVariantData =>
  getSafeTheme(theme).text[textVariant] ?? defaultTheme.text[textVariant];

export const getThemeRadius = (theme: Theme, variant: RadiusVariant): number =>
  getSafeTheme(theme).radius[variant] ?? defaultTheme.radius[variant];

export const getThemeButtonRadius = (theme: Theme): number =>
  getThemeRadius(theme, RadiusVariant.Small);

export const getThemeCardRadius = (theme: Theme): number =>
  getThemeRadius(theme, RadiusVariant.Small);

export const getThemeHeaderRadius = (theme: Theme): number =>
  getThemeRadius(theme, RadiusVariant.Small);

export const getThemeListItemRadius = (theme: Theme): number =>
  getThemeRadius(theme, RadiusVariant.Small);

export const themeTextMixin = (
  theme: Theme,
  textVariant: TextVariant,
  maxLines?: number,
  showLineBreaks = false
): TextMixinResult =>
  textMixin({
    ...getThemeTextVariantConfig(theme, textVariant),
    maxLines,
    showLineBreaks
  });

export const themeTextMixinWithoutMaxLine = (
  theme: Theme,
  textVariant: TextVariant
): TextMixinResult =>
  textMixinWithoutMaxLine({ ...getThemeTextVariantConfig(theme, textVariant) });

export const themeElevationMixin = (
  theme: Theme,
  elevationVariant: ElevationVariant
): ElevationResult =>
  elevationMixin(getThemeElevation(theme, elevationVariant));

export const themeGradientMixin = (
  theme: Theme,
  gradientVariant = GradientVariant.MainGradient
): GradientResult => gradientMixin(getThemeGradient(theme, gradientVariant));
