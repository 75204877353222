import { difference, intersection, isEmpty } from "lodash-es";
import { MedicalAppConfigPermissionCode } from "model/resource/app/appConfig";
import { ResourcePermissions } from "./resourcePermissions";

const validateRequirePermission = (
  requiredCodeList?: MedicalAppConfigPermissionCode[],
  availablePermissions?: MedicalAppConfigPermissionCode[]
): boolean => {
  return isEmpty(difference(requiredCodeList, availablePermissions ?? []));
};

const validateExcludePermission = (
  excludedCodeList?: MedicalAppConfigPermissionCode[],
  availablePermissions?: MedicalAppConfigPermissionCode[]
): boolean => {
  return isEmpty(intersection(excludedCodeList, availablePermissions ?? []));
};
const validateResourcePermissions = (
  resourcePermissions?: ResourcePermissions,
  availablePermissions?: MedicalAppConfigPermissionCode[]
): boolean => {
  const requiredAppConfigList = resourcePermissions?.requiredAppConfigList;
  const excludedAppConfigList = resourcePermissions?.excludedAppConfigList;
  return (
    validateRequirePermission(requiredAppConfigList, availablePermissions) &&
    validateExcludePermission(excludedAppConfigList, availablePermissions)
  );
};

export const validateCreateByResourcePermissions = (
  createAppConfig?: ResourcePermissions,
  availablePermissions?: MedicalAppConfigPermissionCode[]
): boolean =>
  validateResourcePermissions(createAppConfig, availablePermissions);

export const validateEditByResourcePermissions = (
  editAppConfig?: ResourcePermissions,
  availablePermissions?: MedicalAppConfigPermissionCode[]
): boolean => validateResourcePermissions(editAppConfig, availablePermissions);
