import {
  WhatsappMessageType,
  WhatsappNotificationLastVersion,
  WhatsappNotificationStatus
} from "model/communication";

export const isWhatsappNotificationPendingSessionOpen = (
  wspNotification: WhatsappNotificationLastVersion
): boolean => {
  return (
    wspNotification.status === WhatsappNotificationStatus.PendingSessionOpen &&
    (wspNotification.messageType === WhatsappMessageType.Text ||
      wspNotification.messageType === WhatsappMessageType.Media)
  );
};

export const isWhatsappNotificationQueuedSessionInit = (
  wspNotification: WhatsappNotificationLastVersion
): boolean => {
  return (
    wspNotification.status === WhatsappNotificationStatus.Queued &&
    wspNotification.messageType === WhatsappMessageType.SessionInit
  );
};

export const isWhatsappNotificationPendingSessionInit = (
  wspNotification: WhatsappNotificationLastVersion
): boolean => {
  return (
    wspNotification.status === WhatsappNotificationStatus.Pending &&
    wspNotification.messageType === WhatsappMessageType.SessionInit
  );
};

export const isWhatsappNotificationQueued = (
  wspNotification: WhatsappNotificationLastVersion
): boolean => {
  return (
    wspNotification.status === WhatsappNotificationStatus.Queued &&
    (wspNotification.messageType === WhatsappMessageType.Text ||
      wspNotification.messageType === WhatsappMessageType.Media)
  );
};

export const isWhatsappNotificationPending = (
  wspNotification: WhatsappNotificationLastVersion
): boolean => {
  return (
    wspNotification.status === WhatsappNotificationStatus.Pending &&
    (wspNotification.messageType === WhatsappMessageType.Text ||
      wspNotification.messageType === WhatsappMessageType.Media)
  );
};
