import React from "react";
import { FC } from "@laba/react-common";
import { NexupColor } from "model/nexupColor";
import { OdontogramIconProps } from "components/icons/OdontogramIcons/utils";

export const OrthodonticsIcon: FC<OdontogramIconProps> = props => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill={props.iconColor ?? NexupColor.Black}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.70306 9.70215L2.5 11.0868L5.96064 14.0944L7.34434 15.2975L8.54741 13.9129L9.95954 12.2898L12.0356 14.0944L13.4202 15.2975L14.6223 13.9129L16.0326 12.2908L18.1077 14.0944L19.4923 15.2975L20.6945 13.9129L22.5 11.8369L21.1154 10.6338L19.3108 12.7098L15.8501 9.70215L14.6471 11.0868H14.6489L13.2386 12.7098L9.77798 9.70215L8.9683 10.6338L7.1637 12.7098L3.70306 9.70215Z"
      />
    </svg>
  );
};
