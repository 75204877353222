import { getKeyObj, KeyObj, OpenCode } from "@laba/ts-common";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { ResourceModel, ResourceType } from "model/primitives/resourceModel";
import { createHydratedMock } from "ts-auto-mock";
import { Organization } from "../entities/organization";
import { Practitioner } from "./practitioner/practitioner";

export enum KnownPractitionerRoleCodes {
  ItUser = "it_user",
  AuditUser = "audit_user",
  RrhhUser = "rrhh_user",
  HospitalizationUser = "hospitalization_user",
  PrescriptionUser = "prescription_user",
  PrescriptionReadUser = "prescription_read_user",
  EvolutionUser = "evolution_user",
  AlephooUser = "alephoo_user",
  AdminUser = "admin_user",
  QuestionnaireUser = "questionnaire_user",
  NursingUser = "nursing_user",
  NursingAdministrationUser = "nursing_administration_user",
  NexupEtlUser = "nexup_etl_user"
}

export type PractitionerRoleCodes = OpenCode<KnownPractitionerRoleCodes>;

export interface PractitionerRole
  extends ResourceModel<ResourceType.PractitionerRole> {
  role?: PractitionerRoleCodes;
  practitioner?: ModelReference<Practitioner>;
  active?: boolean;
  organization?: ModelReference<Organization>;
}

export const PractitionerRoleKey: KeyObj<PractitionerRole> = getKeyObj(
  createHydratedMock<PractitionerRole>()
);
