import { Property } from "csstype";

export interface DestyledAnchorMixin {
  color?: Property.Color;
  textDecoration?: Property.TextDecoration;
  cursor?: Property.Cursor;
  WebkitTapHighlightColor?: Property.WebkitTapHighlightColor;
}

export const destyledAnchorMixin = (): DestyledAnchorMixin => {
  return {
    color: "inherit",
    textDecoration: "inherit",
    cursor: "inherit",
    WebkitTapHighlightColor: "transparent"
  };
};
