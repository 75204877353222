import {
  ProviderCreate,
  ProviderDelete,
  ProviderDeleteMany,
  ProviderGetList,
  ProviderGetMany,
  ProviderGetManyReference,
  ProviderGetOne,
  ProviderUpdate,
  ProviderUpdateMany,
  ResourceProviderTypes
} from "providers/dataProvider/resourceProvider/utils/resourceProviderTypes";
import { ConceptResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import {
  addConceptToCodeSystem,
  CodeSystemGroup,
  CodeSystemListQueryParams,
  CodeSystemStatus,
  CodeSystemSystem,
  Concept,
  getCodeSystemGroupList,
  Model,
  ModelId,
  ResourceConcept,
  ResourceType,
  updateConceptCodeSystem
} from "@laba/nexup-api";
import { getAsArray, Optional, RequestFailureStatus } from "@laba/ts-common";
import { HttpError } from "react-admin";
import { head, size } from "lodash-es";
import { produce } from "immer";
import { store } from "store/store";
import { updateOrganizationCodeSystem } from "store/workspace/events";

type ConceptToResourceConceptMapper<T extends ResourceConcept> = (
  concept: Concept
) => T;
type ResourceConceptToConceptMapper<T extends ResourceConcept> = (
  resourceConcept: T
) => Concept;

export const getListConceptGetter =
  <T extends ResourceConcept, FilterParams>(
    conceptToResourceConceptMapper: ConceptToResourceConceptMapper<T>,
    system: CodeSystemSystem,
    organizationFilterGetter?: () => Optional<CodeSystemListQueryParams>,
    filterResourceConcepts?: (
      resourceConcepts: T[],
      filters: FilterParams
    ) => T[]
  ): ProviderGetList<T, FilterParams> =>
  async params => {
    const response = await getCodeSystemGroupList({
      ...params.meta?.extraParams,
      ...organizationFilterGetter?.(),
      system
    });

    if (response.failureStatus === RequestFailureStatus.Failure) {
      throw new HttpError(response.errorMsg, response.status, response.data);
    }

    const pageNumber = params.pagination.page;
    const pageSize = params.pagination.perPage;

    const bottomLimit = (pageNumber - 1) * pageSize;
    const upperLimit = pageNumber * pageSize;
    const concepts = response.data.entries[0]?.concept ?? [];

    const resourceConceptList = concepts.map(c => {
      return conceptToResourceConceptMapper(c);
    });
    const filteredResourceConceptList = filterResourceConcepts
      ? filterResourceConcepts(resourceConceptList, params.filter)
      : resourceConceptList;

    const pagedFilteredResourceConcepts = filteredResourceConceptList.slice(
      bottomLimit,
      upperLimit
    );

    const totalEntries = size(filteredResourceConceptList);

    return {
      total: totalEntries,
      data: pagedFilteredResourceConcepts
    };
  };

export const getOneConceptGetter =
  <T extends ResourceConcept>(
    conceptToResourceConceptMapper: ConceptToResourceConceptMapper<T>,
    system?: CodeSystemSystem,
    organizationFilterGetter?: () => Optional<CodeSystemListQueryParams>
  ): ProviderGetOne<T> =>
  async params => {
    const response = await getCodeSystemGroupList({
      system,
      organization: organizationFilterGetter?.()?.organization
    });

    if (response.failureStatus === RequestFailureStatus.Failure) {
      throw new HttpError(response.errorMsg, response.status, response.data);
    }
    const concepts = response.data.entries[0]?.concept ?? [];
    const concept = concepts.find(c => c.code === params.id);

    if (concept === undefined) throw new Error("Concepto no encontrado");

    const data = conceptToResourceConceptMapper(concept);

    return {
      data
    };
  };

export const getManyConceptGetter =
  <T extends ResourceConcept>(
    conceptToResourceConceptMapper: ConceptToResourceConceptMapper<T>,
    system?: CodeSystemSystem,
    organizationFilterGetter?: () => Optional<CodeSystemListQueryParams>
  ): ProviderGetMany<T> =>
  async params => {
    const idList = params.ids.map(String);
    const response = await getCodeSystemGroupList({
      ...params.meta?.extraParams,
      ...organizationFilterGetter?.()
    });
    if (response.failureStatus === RequestFailureStatus.Failure) {
      throw new HttpError(response.errorMsg, response.status, response.data);
    }
    const concepts =
      response.data.entries[0]?.concept?.filter(c =>
        idList.some(id => id === c.code)
      ) ?? [];
    const mappedConcepts = concepts.map(conceptToResourceConceptMapper);
    return {
      data: mappedConcepts
    };
  };

export const getManyReferenceConceptGetter =
  <T extends ResourceConcept>(): ProviderGetManyReference<T> =>
  async _params => {
    throw new Error("not implemented getManyReferenceConceptGetter");
  };

const getOrganizationIdFromOrganizationFilterGetter = (
  organizationFilterGetter?: () => Optional<CodeSystemListQueryParams>
): string => head(getAsArray(organizationFilterGetter?.()?.organization)) ?? "";

export const updateConceptGetter =
  <T extends ResourceConcept>(
    resourceConceptToConceptMapper: ResourceConceptToConceptMapper<T>,
    conceptToResourceConceptMapper: ConceptToResourceConceptMapper<T>,
    system?: CodeSystemSystem,
    organizationFilterGetter?: () => Optional<CodeSystemListQueryParams>,
    onSave?: (data: CodeSystemGroup) => void
  ): ProviderUpdate<T> =>
  async params => {
    if (params.id !== params.data.id) {
      throw new HttpError("update with id inconsistency", -1);
    }
    const concept = resourceConceptToConceptMapper(params.data);
    const response = await updateConceptCodeSystem({
      concept,
      system: system ?? "",
      organization: getOrganizationIdFromOrganizationFilterGetter(
        organizationFilterGetter
      )
    });
    if (response.failureStatus === RequestFailureStatus.Failure) {
      throw new HttpError(response.errorMsg, response.status, response.data);
    }
    const conceptFromResponse = response.data.concept?.find(
      c => c.code === params.data.id
    );
    if (conceptFromResponse === undefined)
      throw new Error("El concepto no se editó correctamente");
    onSave?.(response.data);
    const conceptResourceFromResponse =
      conceptToResourceConceptMapper(conceptFromResponse);
    return { data: conceptResourceFromResponse };
  };

export const updateManyConceptGetter =
  <T extends ResourceConcept>(
    resourceConceptToConceptMapper: ResourceConceptToConceptMapper<T>,
    conceptToResourceConceptMapper: ConceptToResourceConceptMapper<T>,
    system?: CodeSystemSystem,
    organizationFilterGetter?: () => Optional<CodeSystemListQueryParams>,
    onSave?: (data: CodeSystemGroup) => void
  ): ProviderUpdateMany<T> =>
  async params => {
    const updatedIdList: ModelId[] = [];
    await Promise.all(
      params.ids.map(async id => {
        const model = produce(params.data, draftModel => {
          draftModel.id = String(id);
        });
        const concept = resourceConceptToConceptMapper(model);
        const response = await updateConceptCodeSystem({
          concept,
          system: system ?? "",
          organization: getOrganizationIdFromOrganizationFilterGetter(
            organizationFilterGetter
          )
        });
        if (response.failureStatus === RequestFailureStatus.Success) {
          updatedIdList.push(String(id));
          onSave?.(response.data);
        }
      })
    );
    return {
      data: updatedIdList
    };
  };

export const createConceptGetter =
  <T extends ResourceConcept>(
    resourceConceptToConceptMapper: ResourceConceptToConceptMapper<T>,
    conceptToResourceConceptMapper: ConceptToResourceConceptMapper<T>,
    system?: CodeSystemSystem,
    organizationFilterGetter?: () => Optional<CodeSystemListQueryParams>,
    onSave?: (data: CodeSystemGroup) => void
  ): ProviderCreate<T> =>
  async params => {
    const concept = resourceConceptToConceptMapper(params.data);

    const response = await addConceptToCodeSystem({
      concept,
      system: system ?? "",
      organization: getOrganizationIdFromOrganizationFilterGetter(
        organizationFilterGetter
      )
    });
    if (response.failureStatus === RequestFailureStatus.Failure) {
      throw new HttpError(response.errorMsg, response.status, params.data);
    }
    const conceptFromResponse = response.data.concept?.find(
      c => c.code === params.data.id
    );
    if (conceptFromResponse === undefined)
      throw new Error("El concepto no se creó correctamente");
    onSave?.(response.data);
    const conceptResourceFromResponse =
      conceptToResourceConceptMapper(conceptFromResponse);
    return { data: conceptResourceFromResponse };
  };

export const deleteConceptGetter =
  <T extends Model>(): ProviderDelete<T> =>
  async params => {
    throw new HttpError(`no delete ${params.id}`, -1);
  };

export const deleteManyConceptGetter =
  <T extends Model>(): ProviderDeleteMany<T> =>
  async params => {
    throw new HttpError(`no delete many ${params.ids}`, -1);
  };

export const resourceConceptProviderGetter = <
  T extends ResourceConcept,
  FilterParams
>(
  resourceType: ConceptResourceType,
  system: CodeSystemSystem,
  conceptToResourceConceptMapper: ConceptToResourceConceptMapper<T>,
  resourceConceptToConceptMapper: ResourceConceptToConceptMapper<T>,
  organizationFilterGetter?: () => Optional<CodeSystemListQueryParams>,
  filterResourceConcepts?: (resourceConcepts: T[], filters: FilterParams) => T[]
): ResourceProviderTypes<T, FilterParams> => {
  const onSave = (data: CodeSystemGroup) => {
    store.dispatch(
      updateOrganizationCodeSystem({
        ...data,
        resourceType: ResourceType.CodeSystem,
        organization: getOrganizationIdFromOrganizationFilterGetter(
          organizationFilterGetter
        ),
        status: CodeSystemStatus.Active
      })
    );
  };
  return {
    resourceType,
    getList: getListConceptGetter(
      conceptToResourceConceptMapper,
      system,
      organizationFilterGetter,
      filterResourceConcepts
    ),
    getOne: getOneConceptGetter(
      conceptToResourceConceptMapper,
      system,
      organizationFilterGetter
    ),
    getMany: getManyConceptGetter(
      conceptToResourceConceptMapper,
      system,
      organizationFilterGetter
    ),
    getManyReference: getManyReferenceConceptGetter(),
    update: updateConceptGetter(
      resourceConceptToConceptMapper,
      conceptToResourceConceptMapper,
      system,
      organizationFilterGetter,
      onSave
    ),
    updateMany: updateManyConceptGetter(
      resourceConceptToConceptMapper,
      conceptToResourceConceptMapper,
      system,
      organizationFilterGetter
    ),
    create: createConceptGetter(
      resourceConceptToConceptMapper,
      conceptToResourceConceptMapper,
      system,
      organizationFilterGetter,
      onSave
    ),
    delete: deleteConceptGetter(),
    deleteMany: deleteManyConceptGetter()
  };
};
