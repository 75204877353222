import { Optional } from "@laba/ts-common";
import { isEqual, last, reverse } from "lodash-es";
import { RouterAction } from "routing/models/routerAction";
import { RouterLocation } from "routing/models/routerLocation";
import {
  getSingleParamFromParsedQs,
  parseUrlLocationSearch
} from "routing/utils/url";
import { sliceSelector } from "store/utils";
import {
  navigationSlice,
  NavigatorCompatibleState,
  NavigatorSlice
} from "./slice";

export interface NavigatorSelectors<S extends NavigatorCompatibleState> {
  nextActionSelector: (state: S) => Optional<RouterAction>;
  currentLocationSelector: (state: S) => Optional<RouterLocation>;
  lastLocationHistorySelector: (state: S) => Optional<RouterLocation>;
  singleUrlParamSelectorGetter: <T extends string>(
    paramName?: string,
    defaultValue?: T
  ) => (state: S) => Optional<T>;
  hasPrevLocation: (state: S) => boolean;
  goBackToPreviousPathCount: (state: S) => Optional<number>;
  previousPathLocationSelector: (state: S) => Optional<RouterLocation>;
  currentHostNameSelector: (state: S) => Optional<string>;
  isCurrentHostNameLocalhostSelector: (state: S) => boolean;
}

export const getNavigatorSelectors = <
  S extends NavigatorCompatibleState
>(): NavigatorSelectors<S> => {
  const slice = sliceSelector<NavigatorSlice, S>(navigationSlice);

  const nextActionSelector = (state: S): Optional<RouterAction> =>
    last(slice(state).pendingActions);

  let currentLocationAux: Optional<RouterLocation>;
  const currentLocationSelector = (_state: S): Optional<RouterLocation> => {
    // eslint-disable-next-line no-restricted-properties
    const { history: windowHistory, location: windowLocation } = window;
    const aux = {
      state: windowHistory.state?.state,
      hash: windowLocation.hash,
      search: windowLocation.search,
      pathname: windowLocation.pathname,
      key: windowHistory.state?.key
    };
    if (!isEqual(currentLocationAux, aux)) {
      currentLocationAux = aux;
    }
    return currentLocationAux;
  };

  const lastLocationHistorySelector = (state: S): Optional<RouterLocation> => {
    const { locationHistory } = slice(state);
    return last(locationHistory);
  };

  const singleUrlParamSelectorGetter =
    <T extends string>(paramName?: string, defaultValue?: T) =>
    (state: S): Optional<T> =>
      getSingleParamFromParsedQs(
        parseUrlLocationSearch(currentLocationSelector(state)?.search || ""),
        paramName
      ) ?? defaultValue;

  const hasPrevLocation = (state: S): boolean => {
    const { currentLocationIndex } = slice(state);
    return currentLocationIndex > 0;
  };

  const goBackToPreviousPathCount = (state: S): Optional<number> => {
    const { currentLocationIndex, locationHistory } = slice(state);
    const currentLocation = locationHistory[currentLocationIndex];
    const prevLocationList = locationHistory
      .slice(0, currentLocationIndex)
      .reverse();
    const goBackCount =
      prevLocationList.findIndex(
        location => location.pathname !== currentLocation?.pathname
      ) + 1;
    return goBackCount !== 0 ? goBackCount : undefined;
  };

  const previousPathLocationSelector = (state: S): Optional<RouterLocation> => {
    if (hasPrevLocation(state)) {
      const { locationHistory } = slice(state);
      const previousPathCount = goBackToPreviousPathCount(state);
      const previousLocation = previousPathCount
        ? reverse([...locationHistory])[previousPathCount]
        : undefined;
      return previousLocation;
    }
  };

  const currentHostNameSelector = (_state: S): Optional<string> => {
    // eslint-disable-next-line no-restricted-properties
    return window.location.hostname;
  };

  const isCurrentHostNameLocalhostSelector = (state: S): boolean => {
    return currentHostNameSelector(state) === "localhost";
  };

  return {
    nextActionSelector,
    currentLocationSelector,
    lastLocationHistorySelector,
    singleUrlParamSelectorGetter,
    hasPrevLocation,
    goBackToPreviousPathCount,
    previousPathLocationSelector,
    currentHostNameSelector,
    isCurrentHostNameLocalhostSelector
  };
};
