import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { ModelId } from "model/primitives/model/model";
import { Questionnaire } from "model/resource/evolution/questionnaire/questionnaire";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { getKeyObj, HttpMethod, KeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { PublicationStatus } from "model/resource/activity/activityDefinition";

export interface QuestionnaireQueryParams {
  withAllergyIntolerance?: boolean;
  withMeasurement?: boolean;
  withOrganization?: boolean;
  withProblem?: boolean;
}

export interface QuestionnaireListQueryParams
  extends ListQueryParams,
    QuestionnaireQueryParams {
  organization?: ModelId;
  status?: PublicationStatus;
  category?: string;
  content?: string;
  type?: string;
}

export const QuestionnaireListQueryParamsKey: KeyObj<QuestionnaireListQueryParams> =
  getKeyObj(createHydratedMock<QuestionnaireListQueryParams>());

const questionnaireBasePath = "/questionnaire";

export const getQuestionnaireList = (
  params?: QuestionnaireListQueryParams
): Promise<ApiPageRequestResponse<Questionnaire>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(questionnaireBasePath),
    params
  });

export const getQuestionnaire = (
  id: ModelId
): Promise<ApiRequestResponse<Questionnaire>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${questionnaireBasePath}/${id}`)
  });

export const createQuestionnaire = (
  data: Questionnaire
): Promise<ApiRequestResponse<Questionnaire>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(questionnaireBasePath),
    data
  });

export const updateQuestionnaire = (
  data: Questionnaire
): Promise<ApiRequestResponse<Questionnaire>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${questionnaireBasePath}/${data.id}`),
    data
  });
