import { ReactElement } from "@laba/react-common";
import { ChipField, ReferenceManyField, SingleFieldList } from "react-admin";
import React from "react";
import { RaResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";

export interface ReferenceManyResourceFieldProps {
  resourceSource: string;
  label: string;
  resourceType: RaResourceType;
  optionTextKey: string;
  sortable: boolean;
  extraFilters?: Record<string, string | boolean | number>;
}

export const ReferenceManyResourceField = ({
  resourceSource,
  label,
  resourceType,
  optionTextKey,
  extraFilters
}: ReferenceManyResourceFieldProps): ReactElement => {
  return (
    <ReferenceManyField
      label={label}
      target={resourceSource}
      reference={resourceType}
      filter={extraFilters}
    >
      <SingleFieldList>
        <ChipField source={optionTextKey} />
      </SingleFieldList>
    </ReferenceManyField>
  );
};

// this is a bad practice but needed for react-admin datagrid to don't show sort arrow by default.
ReferenceManyResourceField.defaultProps = {
  sortable: false
};
