import { FC, getClassName } from "@laba/react-common";
import { StyleVariant } from "model/themeVariant";
import React, { useState } from "react";
import { useProfilePictureStyle } from "components/profile/ProfilePicture/ProfilePictureStyle";
import { ProfilePictureSizeVariant } from "./utils";

export interface PatientProfilePictureProps {
  className?: string;
  firstName?: string;
  lastName?: string;
  imageSource?: string;
  style?: StyleVariant;
  size?: ProfilePictureSizeVariant;
  title?: string;
  inverted?: boolean;
}

const getInitials = (firstName?: string, lastName?: string): string => {
  const first = firstName ? firstName[0] : "";
  const second = lastName ? lastName[0] : "";

  return `${first}${second}`.toLocaleUpperCase();
};

export const ProfilePicture: FC<PatientProfilePictureProps> = ({
  className,
  firstName,
  lastName,
  imageSource,
  title,
  style = StyleVariant.Primary,
  size = ProfilePictureSizeVariant.ExtraSmall,
  inverted = false
}) => {
  const [errorImage, setErrorImage] = useState(false);
  const hasImage = !errorImage && Boolean(imageSource);
  const initials = getInitials(firstName, lastName);
  const classes = useProfilePictureStyle({
    style,
    initials,
    size,
    inverted
  });

  return hasImage ? (
    <img
      className={getClassName(classes.img, className)}
      src={imageSource}
      alt={`${lastName}, ${firstName}`}
      onError={() => {
        setErrorImage(true);
      }}
    />
  ) : (
    <div
      className={getClassName(classes.noImageContainer, className)}
      title={title ?? [firstName, lastName].filter(Boolean).join(" ")}
    >
      <p className={classes.noImageText}>{initials}</p>
    </div>
  );
};
