import React from "react";
import { FC, getClassName } from "@laba/react-common";
import { Optional } from "@laba/ts-common";
import { useOrToggleSelectedItem } from "components/menu/useOrToggleSelectedItem";
import { MenuNode, MenuType } from "components/menu/menuTreeTypes";
import { isEqual } from "lodash-es";
import { Accordion } from "../Accordion";
import { AccordionItem } from "./AccordionItem/AccordionItem";
import { useAccordionListStyles } from "./AccordionListStyle";

export interface AccordionListProps {
  items: MenuNode[];
  className?: string;
  hasBackgroundColor?: boolean;
}

export const AccordionList: FC<AccordionListProps> = ({
  className,
  items,
  hasBackgroundColor = false
}) => {
  const [selectedElement, setSelectedElement] =
    useOrToggleSelectedItem<Optional<MenuNode>>(undefined);
  const classes = useAccordionListStyles();

  return (
    <div className={getClassName(classes.accordionList, className)}>
      {items.map((it, index) =>
        it.type === MenuType.Parent ? (
          <Accordion
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            title={it.title}
            onAccordionClick={() => setSelectedElement(it)}
            isExpanded={isEqual(it.title, selectedElement?.title)}
            summaryClassName={classes.summary}
            hasBackgroundColor={hasBackgroundColor}
            detailsClassName={classes.accordionDetail}
          >
            {it.children.map((el, idx) => (
              <AccordionItem
                itemName={el.title}
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                onClick={el.type === MenuType.Leaf ? el.onClick : undefined}
              />
            ))}
          </Accordion>
        ) : (
          <AccordionItem
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            itemName={it.title}
            onClick={it.onClick}
            className={getClassName(classes.nonExpansibleItem, className)}
          />
        )
      )}
    </div>
  );
};
