import React from "react";
import {
  FCC,
  IconC,
  StyleVariant,
  TypeVariant,
  getClassName
} from "@laba/react-common";
import { Noop } from "@laba/ts-common";
import { useGenericPlaceholderStyles } from "components/placeholder/GenericPlaceholder/useGenericPlaceholderStyles";
import { DefaultButton } from "components/buttons/DefaultButton/DefaultButton";

interface GenericPlaceholderProps {
  title?: string;
  prompt?: string;
  className?: string;
  buttonText?: string;
  StartIcon?: IconC;
  buttonType?: TypeVariant;
  buttonStyle?: StyleVariant;
  onClick?: Noop;
}

export const GenericPlaceholder: FCC<GenericPlaceholderProps> = ({
  title,
  prompt,
  className,
  children,
  buttonText,
  StartIcon,
  buttonType,
  buttonStyle,
  onClick
}) => {
  const classes = useGenericPlaceholderStyles();
  return (
    <div className={getClassName(classes.root, className)}>
      <div className={classes.imageContainer}>{children}</div>
      <div className={classes.textAndButtonContainer}>
        <div className={classes.textContainer}>
          {title && <p className={classes.description}>{title}</p>}
          {prompt && <p className={classes.prompt}>{prompt}</p>}
        </div>
        {buttonText && (
          <DefaultButton
            StartIcon={StartIcon}
            text={buttonText}
            title={buttonText}
            onClick={onClick}
            type={buttonType}
            style={buttonStyle}
          />
        )}
      </div>
    </div>
  );
};
