import { FC } from "@laba/react-common";
import React from "react";

interface VideoProps {
  className?: string;
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  controls?: boolean;
  src: string;
}
export const Video: FC<VideoProps> = ({
  className,
  src,
  autoPlay = true,
  loop = true,
  muted = true,
  controls = true
}) => {
  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      className={className}
      autoPlay={autoPlay}
      loop={loop}
      muted={muted}
      controls={controls}
      src={src}
    />
  );
};
