import { FCC, getClassName } from "@laba/react-common";
import React from "react";
import { Noop } from "@laba/ts-common";
import { useScrollableContainerStyle } from "./useScrollableContainerStyle";

export enum ScrollDirection {
  Vertical = "Vertical",
  Horizontal = "Horizontal",
  All = "All"
}

interface Props {
  className?: string;
  scrollDirection?: ScrollDirection;
  showScrollBar?: boolean;
  refContainer?: React.Ref<HTMLDivElement>;
  onScroll?: Noop;
  withoutScrollPadding?: boolean;
}

export const ScrollableContainer: FCC<Props> = ({
  className,
  children,
  refContainer,
  onScroll,
  withoutScrollPadding = false,
  scrollDirection = ScrollDirection.Vertical,
  showScrollBar = true
}) => {
  const classes = useScrollableContainerStyle({
    direction: scrollDirection,
    showScrollBar,
    withoutScrollPadding
  });
  const fullClassname = getClassName(classes.scrollableContainer, className);
  return (
    <div className={fullClassname} ref={refContainer} onScroll={onScroll}>
      {children}
    </div>
  );
};
