import { ElementOrArray, joinText, notUndefined } from "@laba/ts-common";
import { isArray, isEmpty } from "lodash-es";

export const getRedirectEmailLink = (
  email: string,
  emailSubject?: string,
  emailBody?: string,
  emailCc?: ElementOrArray<string>,
  emailBcc?: ElementOrArray<string>
): string => {
  const emailStr = `mailto:${email}`;
  const subject = emailSubject ? `subject=${emailSubject.trim()}` : undefined;

  const ccValue = isArray(emailCc) ? joinText(emailCc, ",") : emailCc;
  const cc = ccValue ? `cc=${ccValue}` : undefined;

  const bccValue = isArray(emailBcc) ? joinText(emailBcc, ",") : emailBcc;
  const bcc = bccValue ? `bcc=${bccValue}` : undefined;

  const body = emailBody ? `body=${emailBody}` : undefined;
  const params = [subject, cc, bcc, body].filter(notUndefined).join("&");

  return isEmpty(params) ? emailStr : `${emailStr}?${params}`;
};
