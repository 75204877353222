import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  ActivityDefinitionKind,
  AppointmentDefinition,
  ResourceType
} from "@laba/nexup-api";
import {
  getDefaultResourceTransform,
  ResourceForm
} from "components/generic/ResourceForm/ResourceForm";
import { tkCP } from "translation/i18n";
import {
  getDefaultJsonToModel,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";
import { AppointmentDefinitionContent } from "components/pages/NexupAdmin/resources/appointmentDefinition/AppointmentDefinitionContent";
import { AppointmentDefinitionEmailContent } from "components/pages/NexupAdmin/resources/appointmentDefinition/AppointmentDefinitionEmailContent";
import { AppointmentDefinitionPhoneContent } from "components/pages/NexupAdmin/resources/appointmentDefinition/AppointmentDefinitionPhoneContent";

export const appointmentDefinitionTransform =
  getDefaultResourceTransform<AppointmentDefinition>(
    ResourceType.AppointmentDefinition,
    draftDefinition => {
      draftDefinition.kind = ActivityDefinitionKind.Appointment;
    }
  );
export const appointmentDefinitionJsonToModel: JsonToModel<AppointmentDefinition> =
  getDefaultJsonToModel(draft => {
    draft.organization = undefined;
    draft.availableServiceList = [];
  });
export const tk = tkCP.adminPage[ResourceType.AppointmentDefinition];

export const AppointmentDefinitionForm: FC = () => {
  const { t } = useI18n();
  return (
    <ResourceForm
      tabsConfig={[
        {
          label: t(tk.tabs.appointmentDefinition),
          content: <AppointmentDefinitionContent />
        },
        {
          label: t(tk.tabs.emailConfig),
          content: <AppointmentDefinitionEmailContent />
        },
        {
          label: t(tk.tabs.phoneConfig),
          content: <AppointmentDefinitionPhoneContent />
        }
      ]}
    />
  );
};
