import React from "react";
import { FC } from "@laba/react-common";
import { Create } from "components/generic/Create/Create";
import {
  HospitalizationForm,
  hospitalizationJsonToModel,
  hospitalizationTransform
} from "components/pages/NexupAdmin/resources/hospitalization/HospitalizationForm";
import { ResourceType } from "@laba/nexup-api";

export const HospitalizationCreate: FC = () => {
  return (
    <Create
      resourceTransform={hospitalizationTransform}
      resource={ResourceType.Hospitalization}
      jsonToModel={hospitalizationJsonToModel}
      meta={{
        extraParams: {
          withDiagnosis: true
        }
      }}
    >
      <HospitalizationForm />
    </Create>
  );
};
