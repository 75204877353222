import {
  createStyle,
  flexColumnMixin,
  FlexJustifyContent,
  flexRowMixin,
  marginMixin,
  Theme
} from "@laba/react-common";

export const useDesktopOdontogramGridStyles = createStyle(
  (_theme: Theme) => ({
    root: {
      ...flexColumnMixin({ gap: 12 }),
      width: "min-content"
    },
    itemRow: {
      ...flexRowMixin({
        gap: 23,
        justifyContent: FlexJustifyContent.SpaceEvenly
      })
    },
    verticalDivider: {
      ...marginMixin({ leftRight: 10 })
    },
    horizontalDivider: {
      width: "100%"
    }
  }),
  "DesktopOdontogramGrid"
);
