import { ResourceModel, ResourceType } from "model/primitives/resourceModel";
import { ModelId } from "model/primitives/model/model";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { Patient } from "model/resource/person/patient/patient";
import { Encounter } from "model/resource/entities/encounter/encounter";
import { Condition } from "model/resource/medical/condition/condition";
import { Practitioner } from "model/resource/person/practitioner/practitioner";
import { Identifier } from "model/primitives/identifier";

export enum MedicationRequestStatus {
  Active = "active",
  OnHold = "on-hold",
  Cancelled = "cancelled",
  Completed = "completed",
  EnteredInError = "entered-in-error",
  Stopped = "cancelled",
  Draft = "draft",
  Unknown = "unknown"
}

export enum MedicalRequestStatus {
  Active = "Active",
  Expired = "Expired",
  ToExpire = "ToExpire",
  Cancelled = "Cancelled",
  Completed = "Completed",
  DischargeCancelled = "DischargeCancelled"
}

export interface MedicationRequest<
  RType extends ResourceType =
    | ResourceType.MedicalRequest
    | ResourceType.PrescriptionRequest
> extends ResourceModel<RType> {
  versionId?: ModelId;
  patient?: ModelReference<Patient>;
  originalPractitioner?: ModelReference<Practitioner>;
  practitioner?: ModelReference<Practitioner>;
  identifier?: Identifier[];
  encounter?: ModelReference<Encounter>;
  diagnosis?: ModelReference<Condition>;
  status?: MedicationRequestStatus;
  statusReason: MedicalRequestStatus;
}
