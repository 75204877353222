export const practitioner = {
  title: "Colaboradores",
  filters: {
    organization: "Organizacion",
    role: "Rol",
    validationStatus: "Estado de validacion",
    registrationValidationStatus: "Estado de matricula",
    user: "Usuario"
  },
  fields: {
    id: "Id",
    federationId: "Id federacion",
    creationDate: "Creacion",
    firstName: "Nombre",
    otherName: "Segundo nombre",
    lastName: "Apellido",
    motherLastName: "Apellido materno",
    birthDate: "Fecha de nacimiento",
    gender: "Genero",
    identifier: "Identificadores",
    phone: "Telefonos",
    phoneValue: "Numero",
    phoneType: "Typo",
    phoneRole: "Uso",
    email: "Email",
    emailValue: "Direccion",
    emailType: "Tipo",
    emailRole: "Uso",
    validationStatus: "Validacion",
    organization: "Organizacion",
    hisCode: "Codigo HIS",
    selfAdministered: "Autoadministrado",
    profession: "Profesion",
    registration: "Matrícula",
    registrationType: "Tipo de matrícula",
    registrationPlace: "Lugar",
    registrationValue: "Número de matrícula",
    registrationValueType: "Tipo de identificador",
    registrationValidationStatus: "Validación",
    registrationSignature: "Firma y sello",
    registrationSignatureFileName: "Nombre de archivo de firma",
    registrationIdentifierFiles: "Adjuntar matrícula",
    registrationIdentifierFileName: "Nombre de archivo de matrícula",
    registrationIdentifierFileUrl: "URL de archivo de matrícula",
    registrationIsDefault: "Predeterminado",
    user: "Usuario",
    userId: "Id Usuario",
    username: "Nombre de usuario",
    userEmail: "Email Usuario",
    userEnabled: "Usuario activo",
    role: "Roles",
    roleCreateEditPopUpTitle: "Rol",
    roleId: "Id",
    roleCode: "Rol",
    roleOrganization: "Organizacion",
    roleActive: "Activo",
    name: "Nombre",
    identifierDni: "Dni: {{value}}",
    identifierPassport: "Pasaporte: {{value}}",
    identifierNull: "-",
    contact: "Contacto",
    qualification: "Calificaciones"
  },
  tabs: {
    personal: "Colaborador",
    qualification: "Calificaciones",
    rol: "Roles",
    user: "Usuario"
  }
};
