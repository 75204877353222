import React from "react";
import { Color, FC, getClassName, StyleVariant } from "@laba/react-common";
import { NexupColor } from "model/nexupColor";
import { useBadgeStyle } from "./useBadgeStyle";

export interface BadgeProps {
  color?: Color;
  style?: StyleVariant;
  isAvailable?: boolean;
  badgeContent?: number;
  title?: string;
  className?: string;
}

export const Badge: FC<BadgeProps> = ({
  className,
  color = NexupColor.DarkFuchsia,
  style = StyleVariant.Primary,
  isAvailable = true,
  badgeContent,
  title
}) => {
  const classes = useBadgeStyle({ isAvailable, color, style });

  return (
    <div className={getClassName(className, classes.circle)} title={title}>
      <span className={classes.number}>{badgeContent}</span>
    </div>
  );
};
