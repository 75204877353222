import {
  createStyle,
  FlexAlignContent,
  FlexAlignItems,
  flexItemMixin,
  FlexJustifyContent,
  flexRowMixin,
  Theme
} from "@laba/react-common";

export interface FilePreviewViewerStylesProps {
  isMobile?: boolean;
}
export interface FilePreviewViewerClassNames {
  root: string;
  imageViewer: string;
  pdfViewer: string;
  invalidFormatContainer: string;
  loading: string;
}

const useFilePreviewStyles = createStyle(
  (_theme: Theme) => ({
    root: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        alignContent: FlexAlignContent.Center
      })
    },
    imageViewerDesktop: {
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "100%",
      maxHeight: "75vh"
    },
    imageViewerMobile: {
      width: "100%",
      height: "auto",
      maxHeight: "80vh",
      objectFit: "contain"
    },
    pdfViewerDesktop: { height: "75vh", width: "80vw" },
    pdfViewerMobile: { height: "80vh", width: "100%" },

    invalidFormatContainer: {
      marginLeft: "auto",
      marginRight: "auto"
    },
    loading: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.Center
      }),
      ...flexItemMixin({ grow: 1 })
    }
  }),
  "FileListPreviewPopup"
);

export const useFilePreviewClassNames = (
  props: FilePreviewViewerStylesProps
): FilePreviewViewerClassNames => {
  const classes = useFilePreviewStyles();
  const { isMobile } = props;
  return {
    root: classes.root,
    imageViewer: isMobile
      ? classes.imageViewerMobile
      : classes.imageViewerDesktop,
    pdfViewer: isMobile ? classes.pdfViewerMobile : classes.pdfViewerDesktop,
    invalidFormatContainer: classes.invalidFormatContainer,
    loading: classes.loading
  };
};
