import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import { FC } from "@laba/react-common";

export const HealthAndSafetyIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.5 13H8V10H10.5V7.5H13.5V10H16V13H13.5V15.5H10.5V13ZM12 2L4 5V11.09C4 16.14 7.41 20.85 12 22C16.59 20.85 20 16.14 20 11.09V5L12 2ZM18 11.09C18.0225 12.9987 17.4594 14.8684 16.3866 16.4472C15.3138 18.026 13.7828 19.2381 12 19.92C10.2163 19.2393 8.68456 18.0275 7.61156 16.4484C6.53857 14.8694 5.97605 12.999 6 11.09V6.39L12 4.14L18 6.39V11.09Z" />
    </SvgIcon>
  );
};
