import React from "react";
import { ReactElement, useI18n } from "@laba/react-common";
import { tkCC } from "translation/i18n";
import {
  Location,
  ModelId,
  ReportKey,
  ReportProperty,
  ReportPropertyKey,
  ReportPropertyKnownHeaderSection,
  ReportPropertyKnownPrescriptionRequestProvider,
  ReportPropertyKnownPrescriptionRequestProviderCountry,
  ReportPropertyKnownType,
  ReportType
} from "@laba/nexup-api";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { FormDataConsumer } from "components/generic/FormDataConsumer/FormDataConsumer";
import { TextInput } from "components/generic/TextInput/TextInput";
import { UploadFileImageInput } from "components/generic/UploadFileImageInput/UploadFileImageInput";

export interface ReportConfigInputProps {
  source: string;
  label: string;
  organization?: ModelId;
}

const tk = tkCC.inputs.reportConfigInput;
export const ReportConfigInput = ({
  source,
  label,
  organization
}: ReportConfigInputProps): ReactElement => {
  const { t } = useI18n();

  return (
    <ArrayInput source={source} label={label}>
      <EnumSelectorInput
        source={ReportKey.reportType}
        label={t(tk.reportConfigType)}
        enumValue={ReportType}
        withCreate
      />
      <ArrayInput
        source={ReportKey.properties}
        label={t(tk.reportConfigProperties)}
      >
        <EnumSelectorInput
          source={ReportPropertyKey.type}
          label={t(tk.reportConfigPropertyType)}
          enumValue={ReportPropertyKnownType}
          withCreate
        />
        <FormDataConsumer<Location, ReportProperty>>
          {({ scopedFormData, getSource }) => {
            switch (scopedFormData?.type) {
              case ReportPropertyKnownType.Logo:
              case ReportPropertyKnownType.Picture:
                return (
                  <UploadFileImageInput
                    source={getSource(ReportPropertyKey.value)}
                    label={t(tk.reportConfigPropertyValue)}
                    organization={organization}
                  />
                );
              case ReportPropertyKnownType.PrescriptionRequestProvider:
                return (
                  <EnumSelectorInput
                    source={getSource(ReportPropertyKey.value)}
                    label={t(tk.reportConfigPropertyValue)}
                    enumValue={ReportPropertyKnownPrescriptionRequestProvider}
                    withCreate
                  />
                );
              case ReportPropertyKnownType.Country:
                return (
                  <EnumSelectorInput
                    source={getSource(ReportPropertyKey.value)}
                    label={t(tk.reportConfigPropertyValue)}
                    enumValue={
                      ReportPropertyKnownPrescriptionRequestProviderCountry
                    }
                    withCreate
                  />
                );
              case ReportPropertyKnownType.HeaderSection:
                return (
                  <EnumSelectorInput
                    source={getSource(ReportPropertyKey.value)}
                    label={t(tk.reportConfigPropertyValue)}
                    enumValue={ReportPropertyKnownHeaderSection}
                    withCreate
                  />
                );
              case ReportPropertyKnownType.NursingStartTime:
              case ReportPropertyKnownType.Prefix:
              case ReportPropertyKnownType.Title:
              default:
                return (
                  <TextInput
                    source={getSource(ReportPropertyKey.value)}
                    label={t(tk.reportConfigPropertyValue)}
                  />
                );
            }
          }}
        </FormDataConsumer>
      </ArrayInput>
    </ArrayInput>
  );
};
