import {
  borderMixinRadiusAll,
  colorChangeAlpha,
  createStyle,
  FlexAlignItems,
  FlexJustifyContent,
  flexRowMixin,
  paddingMixin,
  pxToRem,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import {
  getThemeButtonRadius,
  getThemeStyleColor,
  themeTextMixin
} from "model/themeUtils";

export const useFiltersButtonStyle = createStyle(
  (theme: Theme) => ({
    container: {
      ...borderMixinRadiusAll(getThemeButtonRadius(theme)),
      ...flexRowMixin({
        gap: 8,
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.Center
      }),
      ...paddingMixin({ topBottom: 0, leftRight: 24 }),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      ),
      height: pxToRem(40)
    },
    icon: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    filtersText: {
      ...themeTextMixin(theme, TextVariant.Button, 1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    expandedIconButton: {
      backgroundColor: colorChangeAlpha(
        getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.Main
        ),
        0.32
      )
    }
  }),
  "FiltersButton"
);
