import { Children, ReactElement } from "components";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { DefaultRouteModule, RouteModuleData } from "./routeModule";
import { getModuleRouteDefinitionPath, ModuleRouterMap } from "./utils";

interface SwitchRouterProps<
  M extends DefaultRouteModule = DefaultRouteModule,
  T extends string = string,
  C = unknown
> {
  moduleList: RouteModuleData<M, T, C>[];
  drawerSwitch?: boolean;
  moduleRouterMap: ModuleRouterMap<M>;
  children?: Children;
}

export const SwitchModule = <
  M extends DefaultRouteModule = DefaultRouteModule,
  T extends string = string,
  C = unknown
>({
  moduleList,
  moduleRouterMap,
  children,
  drawerSwitch = false
}: SwitchRouterProps<M, T, C>): ReactElement => {
  return (
    <Switch>
      {moduleList.flatMap(module => {
        return module.routeList
          .map(routeDefinition => {
            const routePath = getModuleRouteDefinitionPath<M, T>(
              module.module,
              routeDefinition.route,
              moduleRouterMap
            );
            const component = drawerSwitch
              ? routeDefinition.drawerComponent
              : routeDefinition.component;
            if (component !== undefined) {
              return (
                <Route
                  exact
                  key={routePath}
                  path={routePath}
                  component={component}
                />
              );
            }
            return undefined;
          })
          .filter(component => component !== undefined);
      })}
      {children}
    </Switch>
  );
};
