import React from "react";
import { FC } from "@laba/react-common";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export const LocalFireIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 16 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.48 11.35C13.91 7.27002 8.32 7.05002 9.67 1.12002C9.77 0.680018 9.3 0.340018 8.92 0.570018C5.29 2.71002 2.68 7.00002 4.87 12.62C5.05 13.08 4.51 13.51 4.12 13.21C2.31 11.84 2.12 9.87002 2.28 8.46002C2.34 7.94002 1.66 7.69002 1.37 8.12002C0.69 9.16002 0 10.84 0 13.37C0.38 18.97 5.11 20.69 6.81 20.91C9.24 21.22 11.87 20.77 13.76 19.04C15.84 17.11 16.6 14.03 15.48 11.35ZM6.2 16.38C7.64 16.03 8.38 14.99 8.58 14.07C8.91 12.64 7.62 11.24 8.49 8.98002C8.82 10.85 11.76 12.02 11.76 14.06C11.84 16.59 9.1 18.76 6.2 16.38Z"
          fill="#4F4F4F"
        />
      </svg>
    </SvgIcon>
  );
};
