import { MedicalAppConfigPermissionCode } from "model/resource/app/appConfig";
import {
  filterCreationByDefinitionPermissionConfig,
  validateActivityDefinitionEditPermission
} from "model/resource/activity/utils";
import { ResourceType } from "model/primitives/resourceModel";
import { ActivityDefinitionKind } from "model/resource/activity/activityDefinition";
import { ProcedureReportDefinition } from "./procedureReportDefinition";

export const filterPRCreationByDefinitionPermissionConfig = (
  definitions: ProcedureReportDefinition[],
  permissionList?: MedicalAppConfigPermissionCode[]
): ProcedureReportDefinition[] => {
  return filterCreationByDefinitionPermissionConfig<
    ResourceType.ProcedureReportDefinition,
    ActivityDefinitionKind.DiagnosticReport
  >(definitions, permissionList) as ProcedureReportDefinition[];
};

export const validatePRDefinitionEditPermission = (
  definition?: ProcedureReportDefinition,
  availablePermissions?: MedicalAppConfigPermissionCode[]
): boolean => {
  return validateActivityDefinitionEditPermission(
    definition,
    availablePermissions
  );
};
