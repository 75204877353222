import {
  createStyle,
  FlexAlignSelf,
  flexItemMixin,
  flexRowMixin,
  marginMixin,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

export const useFilePreviewDialogButtonContainerStyles = createStyle(
  (theme: Theme) => ({
    buttonContainer: {
      ...flexRowMixin({
        gap: 8
      })
    },
    itemNumberText: {
      ...themeTextMixin(theme, TextVariant.H6, 1),
      ...flexItemMixin({ align: FlexAlignSelf.Center }),
      ...marginMixin({ right: 24 }),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    },
    button: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    }
  }),
  "FilePreviewDialogButtonContainer"
);
