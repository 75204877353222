import React from "react";
import { Dialog, ThemeProvider } from "@material-ui/core";
import { useMuiTheme } from "model/useMuiTheme";
import { StyleVariant } from "model/themeVariant";
import { Noop } from "@laba/ts-common";
import { FCC, IconC } from "@laba/react-common";
import { useDialogViewerStyles } from "components/popup/DialogViewer/useDialogViewerStyles";
import { BaseIconButton } from "components/buttons/BaseIconButton/BaseIconButton";

interface DialogViewerProps {
  isOpen?: boolean;
  onClose?: Noop;
  LeftIcon?: IconC;
  onLeftIconClick?: Noop;
  RightIcon?: IconC;
  onRightIconClick?: Noop;
}

export const DialogViewer: FCC<DialogViewerProps> = ({
  children,
  RightIcon,
  LeftIcon,
  onLeftIconClick,
  onRightIconClick,
  onClose,
  isOpen = false
}) => {
  const theme = useMuiTheme(StyleVariant.Primary);
  const classes = useDialogViewerStyles();
  return (
    <ThemeProvider theme={theme}>
      <Dialog maxWidth={false} open={isOpen} onClose={onClose}>
        <div>
          {LeftIcon && (
            <BaseIconButton
              onClick={onLeftIconClick}
              Icon={LeftIcon}
              iconClassName={classes.icon}
              className={classes.fixedTopLeft}
            />
          )}
          {RightIcon && (
            <BaseIconButton
              onClick={onRightIconClick}
              Icon={RightIcon}
              iconClassName={classes.icon}
              className={classes.fixedTopRight}
            />
          )}
        </div>
        {children}
      </Dialog>
    </ThemeProvider>
  );
};
