import { getClassName, ReactElement } from "@laba/react-common";
import React from "react";
import _ from "lodash-es";
import { VerticalTab } from "components/tabs/VerticalTab/VerticalTab";
import { useVerticalTabContainerStyle } from "components/tabs/VerticalTabContainer/VerticalTabContainerStyle";

export interface VerticalTabConfig<V> {
  tabId: V;
  title?: string;
  text?: string;
  disabled?: boolean;
  classname?: string;
}

export interface VerticalTabContainerProps<V> {
  className?: string;
  tabs?: VerticalTabConfig<V>[];
  currentTabId?: V;
  onChangeCurrentTab?: (tabId: V) => void;
}

export const VerticalTabContainer = <V,>({
  className,
  tabs = [],
  onChangeCurrentTab,
  currentTabId
}: VerticalTabContainerProps<V>): ReactElement => {
  const classes = useVerticalTabContainerStyle();
  return (
    <div className={getClassName(classes.container, className)}>
      {tabs.map(t => {
        return (
          <VerticalTab
            key={_.toString(t.tabId)}
            className={t.classname}
            title={t.title}
            text={t.text}
            onSelect={() =>
              onChangeCurrentTab && !t.disabled && onChangeCurrentTab(t.tabId)
            }
            isActive={t.tabId === currentTabId}
            disabled={t.disabled}
          />
        );
      })}
    </div>
  );
};
