import React, { useRef, useState } from "react";
import { FC, IconC } from "@laba/react-common";
import { Add, SearchIcon } from "components/icons";
import { TextInput } from "components/inputs/TextInput/TextInput";
import { head, isEmpty } from "lodash-es";
import { NexupIconButton } from "components/buttons/IconButton/IconButton";
import { Popup } from "components/popup/Popup/Popup";
import { AccordionList } from "components/accordion/AccordionList/AccordionList";
import { MenuParentNode, MenuType } from "components/menu/menuTreeTypes";
import { isSingleValueArray } from "@laba/ts-common";
import { usePopupMenuDropdownStyles } from "./PopupMenuDropdownStyle";

export interface PopupMenuDropdownProps {
  buttonClassName?: string;
  icon?: IconC;
  buttonTitle: string;
  showSearchbar?: boolean;
  searchText?: string;
  onChangeSearchText?: (s: string) => void;
  categories: MenuParentNode[];
  noCategoriesText?: string;
  popupTitle?: string;
  buttonRef?: React.Ref<HTMLButtonElement>;
}

export const PopupMenuDropdown: FC<PopupMenuDropdownProps> = ({
  buttonClassName,
  icon,
  buttonTitle,
  showSearchbar,
  searchText,
  onChangeSearchText,
  categories,
  noCategoriesText,
  popupTitle
}) => {
  const classes = usePopupMenuDropdownStyles();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const childrens = categories.flatMap(c => c.children);
  const buttonRef = useRef<HTMLButtonElement>(null);

  if (isSingleValueArray(childrens) && isEmpty(searchText)) {
    const children = head(childrens);
    if (children?.type === MenuType.Leaf) {
      return (
        <NexupIconButton
          className={buttonClassName}
          Icon={icon ?? Add}
          onClick={children.onClick}
          title={buttonTitle}
          buttonRef={buttonRef}
        />
      );
    }
  }

  const items = head(categories)?.children;

  return (
    <>
      <NexupIconButton
        className={buttonClassName}
        Icon={icon ?? Add}
        title={buttonTitle}
        onClick={() => setIsPopupOpen(true)}
        buttonRef={buttonRef}
      />
      <Popup
        open={isPopupOpen}
        headerTitle={popupTitle}
        closableHeader
        onClose={() => setIsPopupOpen(false)}
        isMobile
        dropMargins
      >
        <div className={classes.popupContent}>
          {showSearchbar && (
            <TextInput
              autoFocus
              value={searchText}
              onChange={onChangeSearchText}
              EndIcon={SearchIcon}
              fullWidth
              className={classes.searchbarContainer}
            />
          )}
          {!isEmpty(items) && (
            <AccordionList items={items ?? []} hasBackgroundColor />
          )}
          {isEmpty(categories) && noCategoriesText && (
            <p className={classes.noCategoriesText} title={noCategoriesText}>
              {noCategoriesText}
            </p>
          )}
        </div>
      </Popup>
    </>
  );
};
