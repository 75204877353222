import { FC, useI18n } from "@laba/react-common";
import { useAppDispatch } from "store/store";
import React, { useCallback, useState } from "react";
import {
  DateTimeInput,
  DefaultButton,
  OptionsConfig,
  Popup,
  SelectInput,
  UnitInput
} from "@laba/nexup-components";
import { tkCP } from "translation/i18n";
import { Grid, Typography } from "@mui/material";
import {
  onMaintenanceCompleteConfiguration,
  onMaintenanceRefresh,
  onMaintenanceStartSync
} from "store/dashboard/event";
import { MaintenanceSyncStatus, SyncResourceCode } from "@laba/nexup-api";
import { get } from "lodash-es";
import { applyFormatToDate, DateTime, Optional } from "@laba/ts-common";

const tk = tkCP.adminPage.dashboard.maintenancePanel;
const syncCodeOptions: OptionsConfig<SyncResourceCode>[] = Object.values(
  SyncResourceCode
).map(x => ({
  value: x,
  itemId: x,
  text: x
}));
export const MaintenancePanelInput: FC = () => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const [showMaintenancePanel, setShowMaintenancePanel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [maintenanceStatus, setMaintenanceStatus] =
    useState<MaintenanceSyncStatus>();

  const [showSync, setShowSync] = useState(false);
  const [syncCode, setSyncCode] = useState<Optional<SyncResourceCode>>(
    SyncResourceCode.Auto
  );
  const [syncStartDate, setSyncStartDate] = useState<DateTime>();
  const [syncCount, setSyncCount] = useState<Optional<number>>(100);
  const [syncDelayWindowMinutes, setSyncDelayWindowMinutes] =
    useState<Optional<number>>(30);
  const onClick = useCallback(
    () => setShowMaintenancePanel(true),
    [setShowMaintenancePanel]
  );
  const onClose = useCallback(
    () => setShowMaintenancePanel(false),
    [setShowMaintenancePanel]
  );
  const onRefreshStatusClicked = useCallback(
    async (withLoading = true) => {
      if (withLoading) setLoading(true);
      const maintenanceStatusResult = await dispatch(onMaintenanceRefresh());
      setMaintenanceStatus(maintenanceStatusResult);
      if (withLoading) setLoading(false);
    },
    [dispatch, setMaintenanceStatus, setLoading]
  );
  const onShowSyncClicked = useCallback(async () => {
    setShowSync(true);
  }, [setShowSync]);
  const onStartSyncClicked = useCallback(
    async (withLoading = true) => {
      if (!syncCode) return;
      if (withLoading) setLoading(true);
      await dispatch(
        onMaintenanceStartSync({
          syncCodes: [syncCode],
          startDate: applyFormatToDate(
            syncStartDate,
            "yyyy-LL-dd'T'HH:mm:ss.SSS'-03'"
          ),
          count: syncCount,
          delayWindowMinutes: syncDelayWindowMinutes
        })
      );
      if (withLoading) setLoading(false);
    },
    [
      dispatch,
      setLoading,
      syncCode,
      syncStartDate,
      syncCount,
      syncDelayWindowMinutes
    ]
  );
  const onCompleteConfigurationClicked = useCallback(
    async (withLoading = true) => {
      if (withLoading) setLoading(true);
      await dispatch(onMaintenanceCompleteConfiguration());
      if (withLoading) setLoading(false);
    },
    [dispatch, setLoading]
  );

  const maintenanceStatusString = maintenanceStatus
    ? `${Object.keys(maintenanceStatus)
        .map(key => `${key}: ${JSON.stringify(get(maintenanceStatus, key))}`)
        .join("\n")}\n\nraw: ${JSON.stringify(maintenanceStatus)}`
    : undefined;

  return (
    <>
      <DefaultButton
        title={t(tk.openButton)}
        text={t(tk.openButton)}
        onClick={onClick}
      />
      {showMaintenancePanel && (
        <Popup
          open={showMaintenancePanel}
          closableHeader
          headerTitle={t(tk.title)}
          loading={loading}
          onClose={onClose}
          disableBackdropClick
        >
          <Grid container columnSpacing={2} rowSpacing={2}>
            {showSync && (
              <>
                <Grid item sm={6}>
                  <Typography title={t(tk.syncCode)}>
                    {t(tk.syncCode)}
                  </Typography>
                  <SelectInput
                    value={syncCode}
                    onChange={setSyncCode}
                    placeholder={t(tk.syncCode)}
                    options={syncCodeOptions}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6}>
                  <Typography title={t(tk.startDate)}>
                    {t(tk.startDate)}
                  </Typography>
                  <DateTimeInput
                    value={syncStartDate}
                    onChange={setSyncStartDate}
                    clearable
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6}>
                  <Typography title={t(tk.count)}>{t(tk.count)}</Typography>
                  <UnitInput
                    quantityValue={syncCount}
                    onChangeQuantity={setSyncCount}
                    minValue={0}
                    step={1}
                    fullWidth
                    withUnitSelector={false}
                  />
                </Grid>
                <Grid item sm={6}>
                  <Typography title={t(tk.delay)}>{t(tk.delay)}</Typography>
                  <UnitInput
                    quantityValue={syncDelayWindowMinutes}
                    onChangeQuantity={setSyncDelayWindowMinutes}
                    minValue={0}
                    step={1}
                    fullWidth
                    withUnitSelector={false}
                  />
                </Grid>
                <Grid item sm={12}>
                  <DefaultButton
                    title={t(tk.startSync)}
                    text={t(tk.startSync)}
                    onClick={onStartSyncClicked}
                  />
                </Grid>
              </>
            )}
            {!showSync && (
              <Grid item sm={12}>
                <DefaultButton
                  title={t(tk.configSync)}
                  text={t(tk.configSync)}
                  onClick={onShowSyncClicked}
                />
              </Grid>
            )}
            <Grid item sm={12}>
              <DefaultButton
                title={t(tk.refresh)}
                text={t(tk.refresh)}
                onClick={onRefreshStatusClicked}
              />
            </Grid>
            <Grid item sm={12}>
              {maintenanceStatus ? (
                <Typography
                  sx={{ whiteSpace: "pre-line" }}
                  title={maintenanceStatusString}
                >
                  {maintenanceStatusString}
                </Typography>
              ) : (
                <Typography title={t(tk.statusTextPending)}>
                  {t(tk.statusTextPending)}
                </Typography>
              )}
            </Grid>
            <Grid item sm={12}>
              <DefaultButton
                title={t(tk.complete)}
                text={t(tk.complete)}
                onClick={onCompleteConfigurationClicked}
              />
            </Grid>
          </Grid>
        </Popup>
      )}
    </>
  );
};
