import { isUndefined } from "lodash-es";
import { SliceEnumType, SliceNamesObject } from "./types";

const getSliceName = (prefix: string, name: string) => `${prefix}${name}`;

export const moduleSlicesNamesGetter = <T extends SliceEnumType>(
  prefix: string,
  sliceEnum: T
): SliceNamesObject<T> =>
  Object.keys(sliceEnum).reduce((acc, key: keyof T) => {
    const sliceName = sliceEnum[key];
    if (!isUndefined(sliceName)) {
      return { ...acc, [key]: getSliceName(prefix, sliceName) };
    }
    return acc;
  }, {} as SliceNamesObject<T>);
