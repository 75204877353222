import {
  borderMixinRadiusAll,
  createStyle,
  FlexAlignItems,
  flexRowMixin,
  pxToRem,
  TextVariant,
  StyleVariant
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { themeTextMixin, getThemeStyleColor } from "model/themeUtils";

export const useLocationCardHeaderStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexRowMixin({ alignItems: FlexAlignItems.Center, gap: 8 })
    },
    icon: {
      ...borderMixinRadiusAll("50%"),
      objectFit: "cover",
      width: pxToRem(40),
      height: pxToRem(40)
    },
    text: {
      ...themeTextMixin(theme, TextVariant.H6, 1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Main
      )
    }
  }),
  "LocationCardHeader"
);
