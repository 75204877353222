export const medicalDevice = {
  title: "Dispositivos",
  filters: {
    organization: "Organizacion",
    status: "Estado",
    type: "Tipo",
    tags: "Tag"
  },
  fields: {
    id: "Id",
    organization: "Organizacion",
    name: "Nombre",
    status: "Estado",
    type: "Tipo",
    tags: "Tags",
    tagElement: "Tag",
    hisCode: "Codigo"
  },
  tabs: {
    main: "Dispositivo"
  }
};
