import {
  getArgTypes,
  getDisabledControl,
  getEnumControl,
  getIconControl
} from "@laba/react-common";
import { Meta, Story } from "@storybook/react";
import React from "react";
import { decorators } from "storybook/decorator/defaultDecorator";
import { UrlLinkProps, UrlLink, OnClickUrlAction } from "./UrlLink";

const Template: Story<UrlLinkProps> = args => {
  return <UrlLink {...args} />;
};

const args: UrlLinkProps = {
  url: "https://www.google.com/",
  displayText: "Google",
  onClickUrlAction: OnClickUrlAction.Redirect,
  withStartIcon: false,
  snackbarText: "Link copiado exitosamente."
};

const argTypes = {
  onClickUrlAction: getEnumControl(OnClickUrlAction),
  className: getDisabledControl(),
  onClick: getDisabledControl(),
  StartIcon: getIconControl()
};

export default {
  title: "Components/UrlLink/UrlLink",
  component: UrlLink,
  args,
  argTypes: getArgTypes(argTypes),
  decorators
} as Meta<UrlLinkProps>;

export const WithRedirectAction = Template.bind({});

export const WithCopyToClipboardAction = Template.bind({});
WithCopyToClipboardAction.args = {
  onClickUrlAction: OnClickUrlAction.CopyToClipboard
};

export const WithCustomClickAction = Template.bind({});
WithCustomClickAction.args = {
  onClickUrlAction: OnClickUrlAction.CustomClick,
  onClick: () => {
    // eslint-disable-next-line no-console
    console.log("Click");
  }
};

export const UnderlinedOnHover = Template.bind({});
UnderlinedOnHover.args = {
  underlineOnlyOnHover: true
};

export const WithStartIcon = Template.bind({});
WithStartIcon.args = {
  withStartIcon: true
};
