import { LocationState, RouterLocationDescriptor } from "./routerLocation";

export const enum RouterActionType {
  Push = "Push",
  Replace = "Replace",
  GoBack = "GoBack",
  ScrollToElement = "ScrollToElement",
  Reload = "Reload"
}

interface BaseRouterAction {
  type: RouterActionType;
}

export interface PushRouterAction<S extends LocationState = LocationState>
  extends BaseRouterAction {
  type: RouterActionType.Push;
  route: RouterLocationDescriptor<S>;
  reload: boolean;
}

export interface ReplaceRouterAction<S extends LocationState = LocationState>
  extends BaseRouterAction {
  type: RouterActionType.Replace;
  route: RouterLocationDescriptor<S>;
  reload: boolean;
}

interface GoBackRouterAction extends BaseRouterAction {
  type: RouterActionType.GoBack;
}

interface ReloadRouterAction extends BaseRouterAction {
  type: RouterActionType.Reload;
}

interface ScrollToElementRouterAction extends BaseRouterAction {
  type: RouterActionType.ScrollToElement;
  elementId: string;
}

export type RouterAction<S extends LocationState = LocationState> =
  | PushRouterAction<S>
  | ReplaceRouterAction<S>
  | GoBackRouterAction
  | ReloadRouterAction
  | ScrollToElementRouterAction;
