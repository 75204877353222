import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  ModelId,
  Organization,
  OrganizationType,
  ResourceType
} from "@laba/nexup-api";
import {
  getDefaultResourceTransform,
  ResourceForm
} from "components/generic/ResourceForm/ResourceForm";
import { tkCP } from "translation/i18n";
import { OrganizationMainContent } from "components/pages/NexupAdmin/resources/organization/OrganizationMainContent";
import { OrganizationClinicHistoryContent } from "components/pages/NexupAdmin/resources/organization/OrganizationClinicHistoryContent";
import { OrganizationEmailContent } from "components/pages/NexupAdmin/resources/organization/OrganizationEmailContent";
import { OrganizationReportContent } from "components/pages/NexupAdmin/resources/organization/OrganizationReportContent";
import { OrganizationWhiteLabelContent } from "components/pages/NexupAdmin/resources/organization/OrganizationWhiteLabelContent";
import { OrganizationWhiteLabelDesktopContent } from "components/pages/NexupAdmin/resources/organization/OrganizationWhiteLabelDesktopContent";
import { OrganizationWhiteLabelMobileContent } from "components/pages/NexupAdmin/resources/organization/OrganizationWhiteLabelMobileContent";
import { OrganizationWhiteLabelThemeContent } from "components/pages/NexupAdmin/resources/organization/OrganizationWhiteLabelThemeContent";
import {
  getDefaultJsonToModel,
  ImportElementPreprocess,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";
import { OrganizationPhoneContent } from "components/pages/NexupAdmin/resources/organization/OrganizationPhoneContent";
import produce from "immer";
import { isEmpty, isUndefined } from "lodash-es";
import { OrganizationDashboardContent } from "./OrganizationDashboardContent";
import { OrganizationWhiteLabelHisBrandData } from "./OrganizationWhiteLabelHisBrandData";
import { OrganizationWhiteLabelPatientBrandData } from "./OrganizationWhiteLabelPatientBrandData";
import { OrganizationOnlineBookingContent } from "./OrganizationOnlineBookingContent";

export const organizationTransform = getDefaultResourceTransform<Organization>(
  ResourceType.Organization,
  draft => {
    if (
      isEmpty(draft.photo) ||
      (isUndefined(draft.photo.name) && isUndefined(draft.photo.url))
    ) {
      draft.photo = undefined;
    }
  }
);
export const organizationJsonToModel: JsonToModel<Organization> =
  getDefaultJsonToModel(draft => {
    draft.partOf = undefined;
  });
export const getOrganizationImportElementPreprocess: (
  organizationId?: ModelId
) => ImportElementPreprocess<Organization> = organizationId => model =>
  model && model.type === OrganizationType.Payer && organizationId
    ? produce(model, draft => {
        draft.partOf = organizationId;
      })
    : undefined;
export const tk = tkCP.adminPage[ResourceType.Organization];

export const OrganizationForm: FC = () => {
  const { t } = useI18n();
  return (
    <ResourceForm
      tabsConfig={[
        {
          label: t(tk.tabs.organization),
          content: <OrganizationMainContent />
        },
        {
          label: t(tk.tabs.onlineBooking),
          content: <OrganizationOnlineBookingContent />
        },
        {
          label: t(tk.tabs.clinicHistory),
          content: <OrganizationClinicHistoryContent />
        },
        {
          label: t(tk.tabs.emails),
          content: <OrganizationEmailContent />
        },
        {
          label: t(tk.tabs.phones),
          content: <OrganizationPhoneContent />
        },
        {
          label: t(tk.tabs.reports),
          content: <OrganizationReportContent />
        },
        {
          label: t(tk.tabs.dashboards),
          content: <OrganizationDashboardContent />
        },
        {
          label: t(tk.tabs.whiteLabel),
          content: <OrganizationWhiteLabelContent />
        },
        {
          label: t(tk.tabs.whiteLabelHisBrandData),
          content: <OrganizationWhiteLabelHisBrandData />
        },
        {
          label: t(tk.tabs.whiteLabelPatientBrandData),
          content: <OrganizationWhiteLabelPatientBrandData />
        },
        {
          label: t(tk.tabs.whiteLabelDesktop),
          content: <OrganizationWhiteLabelDesktopContent />
        },
        {
          label: t(tk.tabs.whiteLabelMobile),
          content: <OrganizationWhiteLabelMobileContent />
        },
        {
          label: t(tk.tabs.whiteLabelTheme),
          content: <OrganizationWhiteLabelThemeContent />
        }
      ]}
    />
  );
};
