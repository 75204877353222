import { createStyle, Theme } from "@laba/react-common";

export const useReferenceManyResourceInputStyle = createStyle(
  (_theme: Theme) => ({
    addButton: {
      width: "100%"
    }
  }),
  "useReferenceManyResourceInputStyle"
);
