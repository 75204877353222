import {
  Practitioner,
  PractitionerQualification,
  QualificationValidationStatus
} from "model/resource";
import { Optional } from "@laba/ts-common";
import { find, head, some } from "lodash-es";

export const getDefaultPractitionerQualification = (
  qualificationList?: PractitionerQualification[]
): Optional<PractitionerQualification> => {
  const validatedQualificationList = qualificationList?.filter(
    q => q.validationStatus === QualificationValidationStatus.Validated
  );
  return (
    find(validatedQualificationList, q => q.default === true) ??
    head(validatedQualificationList)
  );
};

export const getPractitionerDefaultQualification = (
  practitioner?: Practitioner
): Optional<PractitionerQualification> => {
  return getDefaultPractitionerQualification(
    practitioner?.healthcareProfessionalData?.registrationQualification
  );
};

export const isQualificationFromPractitionerQualificationList = (
  qualification?: PractitionerQualification,
  qualificationList?: PractitionerQualification[]
): boolean => {
  return some(
    qualificationList,
    q =>
      q.identifier?.value === qualification?.identifier?.value &&
      q.type === qualification?.type
  );
};
