import { Property } from "csstype";
import { Color } from "./color";
import { pxToRem } from "./dimension";
import { getZIndexValue, ZIndexVariant } from "./zIndex";

export interface StickyConfig {
  backgroundColor?: Color;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  zIndex?: number;
}

export interface StickyResult {
  position?: Property.Position;
  zIndex?: Property.ZIndex;
  backgroundColor?: Property.BackgroundColor;
  top?: Property.Top;
  bottom?: Property.Bottom;
  left?: Property.Left;
  right?: Property.Right;
}

export const stickyElementMixin = (config: StickyConfig): StickyResult => {
  return {
    position: "sticky",
    top: config.top ? pxToRem(config.top) : "auto",
    bottom: config.bottom ? pxToRem(config.bottom) : "auto",
    left: config.left ? pxToRem(config.left) : "auto",
    right: config.right ? pxToRem(config.right) : "auto",
    zIndex: config.zIndex,
    backgroundColor: config.backgroundColor
  };
};

interface StickyHeaderConfig extends StickyConfig {
  top?: number;
  backgroundColor?: Color;
}

export const stickyHeaderMixin = (config: StickyHeaderConfig): StickyResult => {
  return {
    position: "sticky",
    top: pxToRem(config.top ?? 0),
    zIndex: getZIndexValue(ZIndexVariant.HeaderElevation),
    backgroundColor: config.backgroundColor
  };
};
