import React, { FC } from "react";
import { NexupMarkdown } from "components/text/NexupMarkdown/NexupMarkdown";
import { TextVariant, ThemeStyleColorVariant } from "@laba/ts-common";
import { useTitleContentDataVerticalHorizontalItemStyle } from "./useTitleContentDataVerticalHorizontalItemStyle";

export interface TitleContentDataHorizontalItemProps {
  principalText?: string;
  secondaryText?: string;
  isMarkdown?: boolean;
  showLineBreaks?: boolean;
  isVertical?: boolean;
  principalTextVariant?: TextVariant;
  secondaryTextVariant?: TextVariant;
  textColorVariant?: ThemeStyleColorVariant;
  maxLines?: number;
}

export const TitleContentDataVerticalHorizontalItem: FC<
  TitleContentDataHorizontalItemProps
> = ({
  principalText,
  secondaryText,
  principalTextVariant,
  secondaryTextVariant,
  isVertical = true,
  isMarkdown = false,
  showLineBreaks = false,
  maxLines,
  textColorVariant
}) => {
  const classes = useTitleContentDataVerticalHorizontalItemStyle({
    showLineBreaks,
    isVertical,
    principalTextVariant,
    secondaryTextVariant,
    maxLines,
    textColorVariant
  });
  return (
    <p className={classes.root}>
      {isMarkdown ? (
        <NexupMarkdown
          className={classes.principalTextItemList}
          text={principalText}
        />
      ) : (
        <span className={classes.principalTextItemList}>{principalText}</span>
      )}
      {!isVertical ? " " : null}
      {isMarkdown ? (
        <NexupMarkdown
          className={classes.secondaryTextItemList}
          text={secondaryText}
        />
      ) : (
        <span className={classes.secondaryTextItemList}>{secondaryText}</span>
      )}
    </p>
  );
};
