import { FC, useI18n } from "@laba/react-common";
import { Title, useRedirect } from "react-admin";
import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { tkCP } from "translation/i18n";
import { Grid } from "@mui/material";
import { showOnErrorAlert } from "components/generic/Create/Create";
import { WorkspaceAdministrationInput } from "./WorkspaceAdministrationInput";

const tk = tkCP.adminPage.dashboard;

export const WorkspaceAdministration: FC = () => {
  const { t } = useI18n();
  const redirect = useRedirect();

  return (
    <Card>
      <Title title={t(tk.workspaceAdministration.title)} />
      <CardContent>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item sm={12} alignSelf="center">
            <WorkspaceAdministrationInput
              onEditionFinished={(success, error) => {
                if (success) {
                  redirect("/");
                } else {
                  showOnErrorAlert(error);
                  redirect("/");
                }
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
