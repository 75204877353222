import { ApiRequestResponse } from "request/types";
import { getFrontPatientPortalApiUrl, requestAnon } from "request/nexupRequest";
import { HttpMethod } from "@laba/ts-common";
import { LoginResponse } from "api/authentication/login";

export const anonymousConferenceLoginRequest = async (
  hash: string
): Promise<ApiRequestResponse<LoginResponse>> =>
  requestAnon({
    method: HttpMethod.POST,
    url: getFrontPatientPortalApiUrl(`/encounter/conference/${hash}/token`)
  });
