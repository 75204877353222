import React from "react";
import { FC, useI18n } from "@laba/react-common";
import { OrganizationKey, ResourceType } from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { NumberInput } from "components/generic/NumberInput/TextInput";
import { ThymeleafInput } from "components/generic/ThymeleafInput/ThymeleafInput";

export const tk = tkCP.adminPage[ResourceType.Organization];
export const OrganizationClinicHistoryContent: FC = () => {
  const { t } = useI18n();
  return (
    <>
      <ArrayInput
        source={OrganizationKey.medicalRequestExpirationTimeList}
        label={t(tk.fields.medicalRequestExpirationTimeList)}
      >
        <TextInput
          source=""
          label={t(tk.fields.medicalRequestExpirationTimeListElement)}
        />
      </ArrayInput>
      <ArrayInput
        source={OrganizationKey.medicalRequestExpirationWarningTimeList}
        label={t(tk.fields.medicalRequestExpirationWarningTimeList)}
      >
        <TextInput
          source=""
          label={t(tk.fields.medicalRequestExpirationWarningTimeListElement)}
        />
      </ArrayInput>
      <NumberInput
        source={OrganizationKey.dailyEvolutionClosePeriod}
        label={t(tk.fields.dailyEvolutionClosePeriod)}
        min={0}
        step={1}
      />
      <ThymeleafInput
        source={OrganizationKey.dailyEvolutionTitleTemplate}
        label={t(tk.fields.dailyEvolutionTitleTemplate)}
      />
    </>
  );
};
