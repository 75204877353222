import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  EncounterStatus,
  getFullNameFromPerson,
  HospitalizationKey,
  HospitalizationListQueryParamsKey,
  LocationKey,
  ObservationStatus,
  OrganizationCombinedType,
  OrganizationKey,
  OrganizationListQueryParamsKey,
  Patient,
  PatientListQueryParamsKey,
  PatientTagKey,
  PatientTagListQueryParamsKey,
  Practitioner,
  PractitionerListQueryParamsKey,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { TextField } from "components/generic/TextField/TextField";
import { ReferenceResourceField } from "components/generic/ReferenceResourceField/ReferenceResourceField";
import { List } from "components/generic/List/List";
import { Datagrid } from "components/generic/Datagrid/Datagrid";
import { hospitalizationJsonToModel } from "components/pages/NexupAdmin/resources/hospitalization/HospitalizationForm";
import { DateField } from "components/generic/DateField/DateField";
import { ReferenceManyResourceField } from "components/generic/ReferenceManyResourceField/ReferenceManyResourceField";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import {
  patientOptionText,
  practitionerOptionText
} from "components/generic/ReferenceResourceInput/optionText";
import { useListExportedFileName } from "components/hook/useExportedFileName";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

const tk = tkCP.adminPage[ResourceType.Hospitalization];

export const HospitalizationList: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const exportedFileName = useListExportedFileName(t(tk.title));

  return (
    <List
      resource={ResourceType.Hospitalization}
      exportedFileName={exportedFileName}
      jsonToModel={hospitalizationJsonToModel}
      meta={{
        extraParams: {
          withDiagnosis: true
        }
      }}
      extraFilters={[
        workspaceOrganizationId === undefined ? (
          <OrganizationReferenceInput
            label={t(tk.filters.organization)}
            source={HospitalizationListQueryParamsKey.organization}
            alwaysOn
            type={OrganizationCombinedType.ProviderAndGroup}
            extraFilters={{
              [OrganizationListQueryParamsKey.active]: true
            }}
          />
        ) : undefined,
        <ReferenceResourceInput<Patient>
          resourceType={ResourceType.Patient}
          label={t(tk.filters.patient)}
          source={HospitalizationListQueryParamsKey.patient}
          optionTextKey={patientOptionText}
          alwaysOn
          extraFilters={{
            [PatientListQueryParamsKey.organization]: workspaceOrganizationId
          }}
        />,
        <EnumSelectorInput
          source={HospitalizationListQueryParamsKey.status}
          label={t(tk.filters.status)}
          enumValue={EncounterStatus}
          alwaysOn
          resettable
        />,
        <ReferenceResourceInput<Practitioner>
          resourceType={ResourceType.Practitioner}
          label={t(tk.filters.practitioner)}
          source={HospitalizationListQueryParamsKey.practitioner}
          optionTextKey={practitionerOptionText}
          alwaysOn
          extraFilters={{
            [PractitionerListQueryParamsKey.organization]:
              workspaceOrganizationId
          }}
        />
      ]}
      filterDefaultValues={{
        [HospitalizationListQueryParamsKey.status]: EncounterStatus.InProgress
      }}
    >
      <Datagrid>
        <TextField source={HospitalizationKey.id} label={t(tk.fields.id)} />
        <ReferenceResourceField
          resourceType={ResourceType.Organization}
          label={t(tk.fields.organization)}
          source={HospitalizationKey.organization}
          optionTextKey={OrganizationKey.name}
        />
        <ReferenceResourceField<Patient>
          resourceType={ResourceType.Patient}
          label={t(tk.fields.patient)}
          source={HospitalizationKey.patient}
          optionTextKey={getFullNameFromPerson}
        />
        <TextField
          source={HospitalizationKey.status}
          label={t(tk.fields.status)}
        />
        <DateField
          source={HospitalizationKey.startDate}
          label={t(tk.fields.startDate)}
        />
        <DateField
          source={HospitalizationKey.finishDate}
          label={t(tk.fields.finishDate)}
        />
        <ReferenceResourceField
          resourceType={ResourceType.Location}
          label={t(tk.fields.currentBed)}
          source={HospitalizationKey.currentBed.bed}
          optionTextKey={LocationKey.description}
        />
        <ReferenceManyResourceField
          resourceType={ResourceType.PatientTag}
          label={t(tk.fields.tagList)}
          resourceSource={PatientTagListQueryParamsKey.encounter}
          extraFilters={{
            [PatientTagListQueryParamsKey.status]: ObservationStatus.Preliminary
          }}
          optionTextKey={PatientTagKey.value}
        />
      </Datagrid>
    </List>
  );
};
