import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  KnownCodeSystemSystem,
  MedicalRequestDefinitionKey,
  MedicalRequestDefinitionListQueryParamsKey,
  OrganizationCombinedType,
  OrganizationKey,
  OrganizationListQueryParamsKey,
  PublicationStatus,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { TextField } from "components/generic/TextField/TextField";
import { ReferenceResourceField } from "components/generic/ReferenceResourceField/ReferenceResourceField";
import { List } from "components/generic/List/List";
import { Datagrid } from "components/generic/Datagrid/Datagrid";
import { medicalRequestDefinitionJsonToModel } from "components/pages/NexupAdmin/resources/medicalRequestDefinition/MedicalRequestDefinitionForm";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useListExportedFileName } from "components/hook/useExportedFileName";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

const tk = tkCP.adminPage[ResourceType.MedicalRequestDefinition];

export const MedicalRequestDefinitionList: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const categorySystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicalRequestCategory,
    workspaceOrganizationId
  );
  const exportedFileName = useListExportedFileName(t(tk.title));

  return (
    <List
      resource={ResourceType.MedicalRequestDefinition}
      exportedFileName={exportedFileName}
      jsonToModel={medicalRequestDefinitionJsonToModel}
      extraFilters={[
        workspaceOrganizationId === undefined ? (
          <OrganizationReferenceInput
            label={t(tk.filters.organization)}
            source={MedicalRequestDefinitionListQueryParamsKey.organization}
            alwaysOn
            type={OrganizationCombinedType.ProviderAndGroup}
            extraFilters={{
              [OrganizationListQueryParamsKey.active]: true
            }}
          />
        ) : undefined,
        <EnumSelectorInput
          source={MedicalRequestDefinitionListQueryParamsKey.status}
          label={t(tk.filters.status)}
          enumValue={PublicationStatus}
          alwaysOn
          resettable
        />,
        <CodeSystemSelectorInput
          source={MedicalRequestDefinitionListQueryParamsKey.category}
          label={t(tk.filters.category)}
          codeSystem={categorySystem}
          organizationId={workspaceOrganizationId}
          withCreate
          alwaysOn
          resettable
        />
      ]}
    >
      <Datagrid>
        <TextField
          source={MedicalRequestDefinitionKey.id}
          label={t(tk.fields.id)}
        />
        <TextField
          source={MedicalRequestDefinitionKey.name}
          label={t(tk.fields.name)}
        />
        <ReferenceResourceField
          resourceType={ResourceType.Organization}
          label={t(tk.fields.organization)}
          source={MedicalRequestDefinitionKey.organization}
          optionTextKey={OrganizationKey.name}
        />
        <TextField
          source={MedicalRequestDefinitionKey.category}
          label={t(tk.fields.category)}
        />
        <TextField
          source={MedicalRequestDefinitionKey.type}
          label={t(tk.fields.type)}
        />
        <TextField
          source={MedicalRequestDefinitionKey.status}
          label={t(tk.fields.status)}
        />
        <TextField
          source={MedicalRequestDefinitionKey.order}
          label={t(tk.fields.order)}
        />
      </Datagrid>
    </List>
  );
};
