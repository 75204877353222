import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  FlexJustifyContent,
  hoverMixin,
  pxToRem,
  StyleVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor } from "model/themeUtils";

export const useFloatingButtonsStyle = createStyle(
  (theme: Theme) => ({
    floatingButtonsContainer: {
      ...flexColumnMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.Center,
        gap: 8
      }),
      height: pxToRem(88)
    },
    actionButton: {
      ...hoverMixin({
        backgroundColor: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.Main
        )
      }),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      ),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Main
      )
    },
    editIcon: {
      fontSize: pxToRem(16)
    }
  }),
  "FloatingButtons"
);
