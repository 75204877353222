import {
  ReactElement,
  ReactElementOrNull,
  StyleVariant
} from "@laba/react-common";
import React, { FC } from "react";
import { AutocompleteOptionConfig } from "components/autocomplete/Autocomplete/Autocomplete";
import { isEmpty } from "lodash-es";
import { AutocompleteOption as DefaultAutocompleteOption } from "components/autocomplete/Autocomplete/AutocompleteOption/AutocompleteOption";
import { OptionsConfig } from "components/inputs/SelectInput/SelectInput";
import { AutocompleteOptionWithSelectProps } from "components/autocomplete/Autocomplete/AutocompleteOptionWithSelect/AutocompleteOptionWithSelect";
import { Optional } from "@laba/ts-common";
import { useSearchableListSelectedOptionsStyles } from "components/inputs/SearchableListInput/SearchableListSelectedOptions/useSearchableListSelectedOptionsStyles";

interface Props<T, V> {
  isMobile?: boolean;
  selectedOptions: AutocompleteOptionConfig<T>[];
  fullWidth?: boolean;
  onRemoveSelectedOption: (option: T) => void;
  removeIconStyle?: StyleVariant;
  AutocompleteOption?: FC<AutocompleteOptionWithSelectProps<T, V>>;
  innerOptions?: OptionsConfig<V>[];
  innerOnChange?: (option: T, innerOption?: V) => void;
  getInnerValueFromOption?: (op: AutocompleteOptionConfig<T>) => Optional<V>;
  getInnerOptionFromValue?: (v: V) => OptionsConfig<V>;
  innerPlaceholder?: string;
  hasBackgroundColor?: boolean;
  disabled?: boolean;
  actionControlComponent?: ReactElement;
  getLowerComponent?: (
    index: number,
    option: AutocompleteOptionConfig<T>
  ) => ReactElementOrNull;
}

export const SearchableListSelectedOptions = <T, V>({
  isMobile,
  selectedOptions,
  fullWidth,
  onRemoveSelectedOption,
  removeIconStyle = StyleVariant.BlackWhite,
  AutocompleteOption = DefaultAutocompleteOption,
  innerOptions,
  innerOnChange,
  innerPlaceholder,
  getInnerValueFromOption,
  getInnerOptionFromValue,
  hasBackgroundColor = false,
  disabled,
  actionControlComponent,
  getLowerComponent
}: Props<T, V>): ReactElementOrNull => {
  const classes = useSearchableListSelectedOptionsStyles();

  if (isEmpty(selectedOptions)) return null;

  return (
    <>
      {selectedOptions.map((selectedOption, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div className={classes.root} key={index}>
            <AutocompleteOption
              isMobile={isMobile}
              option={selectedOption}
              selectOptions={innerOptions ?? []}
              selectOnChange={innerOnChange}
              selectValue={getInnerValueFromOption?.(selectedOption)}
              placeholder={innerPlaceholder}
              getOptionFromValue={getInnerOptionFromValue}
              onRemoveSelectedOption={onRemoveSelectedOption}
              disabled={disabled}
              removeIconStyle={removeIconStyle}
              hasBackgroundColor={hasBackgroundColor}
              fullWidth={fullWidth}
              isSelectedOption
              actionControlComponent={actionControlComponent}
            />
            {getLowerComponent
              ? getLowerComponent(index, selectedOption)
              : null}
          </div>
        );
      })}
    </>
  );
};
