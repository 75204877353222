import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  getModelReferenceId,
  KnownCodeSystemSystem,
  Medication,
  MedicationGenericType,
  MedicationKey,
  MedicationListQueryParamsKey,
  MedicationStatus,
  ModelReference,
  Organization,
  OrganizationCombinedType,
  OrganizationListQueryParamsKey,
  ResourceType
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { medicationOptionText } from "components/generic/ReferenceResourceInput/optionText";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { IdentifierInput } from "components/generic/IdentifierInput/IdentifierInput";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";
import { NumberInput } from "components/generic/NumberInput/TextInput";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";

export const tk = tkCP.adminPage[ResourceType.Medication];
export const MedicationMainContent: FC = () => {
  const { t } = useI18n();
  const organization = useWatchResourceForm<ModelReference<Organization>>(
    MedicationKey.organization
  );
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organizationId =
    getModelReferenceId(organization) || workspaceOrganizationId;
  const functionCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicationFunctionalCode,
    organizationId
  );

  return (
    <>
      <TextInput disabled source={MedicationKey.id} label={t(tk.fields.id)} />
      <OrganizationReferenceInput
        source={MedicationKey.organization}
        label={t(tk.fields.organization)}
        type={OrganizationCombinedType.ProviderAndGroup}
        extraFilters={{
          [OrganizationListQueryParamsKey.active]: true
        }}
        required
        defaultValue={workspaceOrganizationId}
        disabled={workspaceOrganizationId !== undefined}
      />
      <TextInput
        source={MedicationKey.name}
        label={t(tk.fields.name)}
        required
      />
      <TextInput
        source={MedicationKey.description}
        label={t(tk.fields.description)}
        required
      />
      <ArrayInput
        source={MedicationKey.alternativeName}
        label={t(tk.fields.alternativeName)}
      >
        <TextInput
          source=""
          label={t(tk.fields.alternativeNameElement)}
          required
        />
      </ArrayInput>
      <TextInput
        source={MedicationKey.snomedCode}
        label={t(tk.fields.snomedCode)}
      />
      <EnumSelectorInput
        source={MedicationKey.status}
        label={t(tk.filters.status)}
        enumValue={MedicationStatus}
      />
      <IdentifierInput
        source={MedicationKey.identifier}
        organization={organizationId}
        system={KnownCodeSystemSystem.Identifier}
      />
      <CodeSystemSelectorInput
        source={MedicationKey.functionCode}
        label={t(tk.fields.functionCode)}
        codeSystem={functionCodeSystem}
        organizationId={organizationId}
        withCreate
      />
      <EnumSelectorInput
        source={MedicationKey.genericType}
        label={t(tk.fields.genericType)}
        enumValue={MedicationGenericType}
        required
      />
      <ReferenceResourceInput<Medication>
        source={MedicationKey.instanceOf}
        label={t(tk.fields.instanceOf)}
        resourceType={ResourceType.Medication}
        optionTextKey={medicationOptionText}
        extraFilters={{
          [MedicationListQueryParamsKey.genericType]:
            MedicationGenericType.Generic
        }}
      />
      <ArrayInput
        source={MedicationKey.presentationList}
        label={t(tk.fields.presentationList)}
      >
        <TextInput
          source=""
          label={t(tk.fields.presentationListElement)}
          required
        />
      </ArrayInput>
      <NumberInput
        source={MedicationKey.rules.minUnits}
        label={t(tk.fields.minUnits)}
      />
      <NumberInput
        source={MedicationKey.rules.maxUnits}
        label={t(tk.fields.maxUnits)}
      />
      <BooleanInput
        source={MedicationKey.rules.dilutionRequired}
        label={t(tk.fields.dilutionRequired)}
      />
      <BooleanInput
        source={MedicationKey.rules.endDateRequired}
        label={t(tk.fields.endDateRequired)}
      />
    </>
  );
};
