import { size, startsWith } from "lodash-es";
import {
  CountryCode,
  getCountryCallingCode,
  parsePhoneNumber
} from "libphonenumber-js";
import { CountryAreaPhoneCode } from "phone/countryAreaPhoneCode";
import { PhoneLibCountryCode } from "phone/types";
import { Optional } from "model/types";
import { getCountryPhonePrefix } from "phone/countryPrefix";

export const hasPhoneNumberPlusPrefix = (phoneNumber: string): boolean => {
  return startsWith(phoneNumber, "+");
};

export const removePhoneCountryCode = (
  phoneNumber: string,
  countryCode: CountryAreaPhoneCode
): string => {
  const prefix = getCountryPhonePrefix(countryCode);
  if (!prefix) return phoneNumber;
  const phoneWithoutPrefix = hasPhoneNumberPlusPrefix(phoneNumber)
    ? phoneNumber.slice(1)
    : phoneNumber;
  return startsWith(phoneWithoutPrefix, prefix)
    ? phoneWithoutPrefix.slice(size(prefix))
    : phoneWithoutPrefix;
};

export const phonePrefixFromRegion = (
  countryCode: CountryAreaPhoneCode
): string => {
  const prefix = getCountryCallingCode(countryCode as PhoneLibCountryCode);
  return `+${prefix}`;
};

export const getPhoneNumberCountryCode = (
  phoneNumber: string,
  defaultCountryCode?: CountryAreaPhoneCode
): Optional<CountryAreaPhoneCode> => {
  try {
    return parsePhoneNumber(phoneNumber, defaultCountryCode as CountryCode)
      .country as CountryAreaPhoneCode;
  } catch (e) {
    return defaultCountryCode;
  }
};
