import React from "react";
import { StyleVariant } from "@laba/ts-common";
import { PopupSizeVariant } from "components";
import { CancelIcon, CheckCircle, InfoIcon } from "components/icons";
import { FCC } from "@laba/react-common";
import { Dialog } from "../Dialog/Dialog";

export enum AlertPopupVariant {
  Error = "Error",
  Success = "Success",
  Warning = "Warning"
}

export interface AlertPopupProps {
  isMobile?: boolean;
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
  subtitle?: string;
  alertPopupVariant?: AlertPopupVariant;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  dialogSizeVariant?: PopupSizeVariant;
  loading?: boolean;
}

export const AlertPopup: FCC<AlertPopupProps> = ({
  isMobile,
  isOpen,
  onClose,
  title,
  subtitle,
  alertPopupVariant = AlertPopupVariant.Error,
  primaryButtonText,
  secondaryButtonText,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  dialogSizeVariant,
  children,
  loading
}) => {
  const iconTitleMap = {
    [AlertPopupVariant.Error]: {
      iconTitle: CancelIcon,
      iconStyle: StyleVariant.Danger
    },
    [AlertPopupVariant.Success]: {
      iconTitle: CheckCircle,
      iconStyle: StyleVariant.Success
    },
    [AlertPopupVariant.Warning]: {
      iconTitle: InfoIcon,
      iconStyle: StyleVariant.Warning
    }
  };
  return (
    <Dialog
      open={isOpen}
      isMobile={isMobile}
      onPrimaryButtonClick={onPrimaryButtonClick ?? onClose}
      onSecondaryButtonClick={onSecondaryButtonClick ?? onClose}
      onClose={onClose}
      primaryButtonText={primaryButtonText}
      secondaryButtonText={secondaryButtonText}
      IconTitle={iconTitleMap[alertPopupVariant].iconTitle}
      textTitle={title}
      textSubtitle={subtitle}
      dialogSizeVariant={dialogSizeVariant}
      iconStyle={iconTitleMap[alertPopupVariant].iconStyle}
      loading={loading}
    >
      {children}
    </Dialog>
  );
};
