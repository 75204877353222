import React from "react";
import { FC } from "@laba/react-common";
import {
  MeasurementGroupDefinitionForm,
  measurementGroupDefinitionJsonToModel,
  measurementGroupDefinitionTransform
} from "components/pages/NexupAdmin/resources/measurementGroupDefinition/MeasurementGroupDefinitionForm";
import { Edit } from "components/generic/Edit/Edit";
import { MeasurementGroupDefinition, ResourceType } from "@laba/nexup-api";
import { useElementExportedFileName } from "components/hook/useExportedFileName";

export const MeasurementGroupDefinitionEdit: FC = () => {
  const exportedFileName =
    useElementExportedFileName<MeasurementGroupDefinition>(
      resource => resource.name
    );
  return (
    <Edit
      resourceTransform={measurementGroupDefinitionTransform}
      resource={ResourceType.MeasurementGroupDefinition}
      jsonToModel={measurementGroupDefinitionJsonToModel}
      exportedFileName={exportedFileName}
    >
      <MeasurementGroupDefinitionForm />
    </Edit>
  );
};
