import {
  CreateParams,
  CreateResult,
  DataProvider,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult
} from "react-admin";
import { practitionerProvider } from "providers/dataProvider/resourceProvider/practitionerProvider";
import { ResourceProviderTypes } from "providers/dataProvider/resourceProvider/utils/resourceProviderTypes";
import { patientProvider } from "providers/dataProvider/resourceProvider/patientProvider";
import { organizationProvider } from "providers/dataProvider/resourceProvider/organizationProvider";
import { userProvider } from "providers/dataProvider/resourceProvider/userProvider";
import { Optional } from "@laba/ts-common";
import { practitionerRoleProvider } from "providers/dataProvider/resourceProvider/practitionerRoleProvider";
import { codeSystemProvider } from "providers/dataProvider/resourceProvider/codeSystemProvider";
import { healthcareServiceProvider } from "providers/dataProvider/resourceProvider/healthcareServiceProvider";
import { hospitalizationProvider } from "providers/dataProvider/resourceProvider/hospitalizationProvider";
import { locationProvider } from "providers/dataProvider/resourceProvider/locationProvider";
import { medicalDeviceProvider } from "providers/dataProvider/resourceProvider/medicalDeviceProvider";
import { medicalRequestDefinitionProvider } from "providers/dataProvider/resourceProvider/medicalRequestDefinitionProvider";
import { medicationProvider } from "providers/dataProvider/resourceProvider/medicationProvider";
import { medicationSubstanceProvider } from "providers/dataProvider/resourceProvider/medicationSubstanceProvider";
import { questionnaireProvider } from "providers/dataProvider/resourceProvider/questionnaireProvider";
import { appointmentDefinitionProvider } from "providers/dataProvider/resourceProvider/appointmentDefinitionProvider";
import { measurementGroupDefinitionProvider } from "providers/dataProvider/resourceProvider/measurementGroupDefinitionProvider";
import { patientTagProvider } from "providers/dataProvider/resourceProvider/patientTagProvider";
import { appointmentProvider } from "providers/dataProvider/resourceProvider/appointmentProvider";
import { procedureReportDefinitionProvider } from "providers/dataProvider/resourceProvider/procedureReportDefinitionProvider";
import { scheduleDefinitionProvider } from "providers/dataProvider/resourceProvider/scheduleDefinitionProvider";
import { scheduleProvider } from "providers/dataProvider/resourceProvider/scheduleProvider";
import { procedureCodeConceptProvider } from "providers/dataProvider/resourceProvider/procedureCodeConceptProvider";
import { batteryProcedureConceptProvider } from "providers/dataProvider/resourceProvider/procedureBatteryConceptProvider";
import { notificationProvider } from "./resourceProvider/notificationProvider";
import { whatsappNotificationProvider } from "./resourceProvider/whatsappNotificationProvider";
import { whatsappNotificationHistoryProvider } from "./resourceProvider/whatsappNotificationHistoryProvider";
import { productProvider } from "./resourceProvider/productProvider";
import { accountProvider } from "./resourceProvider/accountProvider";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyResourceProvider = ResourceProviderTypes<any, any, any>;

const resourceProviderList: AnyResourceProvider[] = [
  appointmentDefinitionProvider,
  appointmentProvider,
  codeSystemProvider,
  healthcareServiceProvider,
  hospitalizationProvider,
  locationProvider,
  measurementGroupDefinitionProvider,
  medicalDeviceProvider,
  medicalRequestDefinitionProvider,
  medicationProvider,
  medicationSubstanceProvider,
  organizationProvider,
  patientProvider,
  patientTagProvider,
  practitionerProvider,
  practitionerRoleProvider,
  questionnaireProvider,
  userProvider,
  notificationProvider,
  whatsappNotificationProvider,
  whatsappNotificationHistoryProvider,
  procedureReportDefinitionProvider,
  scheduleDefinitionProvider,
  scheduleProvider,
  procedureCodeConceptProvider,
  batteryProcedureConceptProvider,
  productProvider,
  accountProvider
];

const getProvider = (resourceType: string): Optional<AnyResourceProvider> => {
  return resourceProviderList.find(
    value => value.resourceType === resourceType
  );
};

const getList = (
  resourceType: string,
  params: GetListParams
): Promise<GetListResult> => {
  return (
    getProvider(resourceType)?.getList(params) ??
    Promise.reject(new Error(`unknown resource type ${resourceType}`))
  );
};

const getOne = (
  resourceType: string,
  params: GetOneParams
): Promise<GetOneResult> => {
  return (
    getProvider(resourceType)?.getOne(params) ??
    Promise.reject(new Error(`unknown resource type ${resourceType}`))
  );
};

const getMany = (
  resourceType: string,
  params: GetManyParams
): Promise<GetManyResult> => {
  return (
    getProvider(resourceType)?.getMany(params) ??
    Promise.reject(new Error(`unknown resource type ${resourceType}`))
  );
};

const getManyReference = (
  resourceType: string,
  params: GetManyReferenceParams
): Promise<GetManyReferenceResult> => {
  return (
    getProvider(resourceType)?.getManyReference(params) ??
    Promise.reject(new Error(`unknown resource type ${resourceType}`))
  );
};

const update = (
  resourceType: string,
  params: UpdateParams
): Promise<UpdateResult> => {
  return (
    getProvider(resourceType)?.update(params) ??
    Promise.reject(new Error(`unknown resource type ${resourceType}`))
  );
};

const updateMany = (
  resourceType: string,
  params: UpdateManyParams
): Promise<UpdateManyResult> => {
  return (
    getProvider(resourceType)?.updateMany(params) ??
    Promise.reject(new Error(`unknown resource type ${resourceType}`))
  );
};

const create = (
  resourceType: string,
  params: CreateParams
): Promise<CreateResult> => {
  return (
    getProvider(resourceType)?.create(params) ??
    Promise.reject(new Error(`unknown resource type ${resourceType}`))
  );
};

const deleteProvider = (
  resourceType: string,
  params: DeleteParams
): Promise<DeleteResult> => {
  return (
    getProvider(resourceType)?.delete(params) ??
    Promise.reject(new Error(`unknown resource type ${resourceType}`))
  );
};

const deleteMany = (
  resourceType: string,
  params: DeleteManyParams
): Promise<DeleteManyResult> => {
  return (
    getProvider(resourceType)?.deleteMany(params) ??
    Promise.reject(new Error(`unknown resource type ${resourceType}`))
  );
};

export const nexupDataProvider: DataProvider = {
  getList,
  getOne,
  getMany,
  getManyReference,
  update,
  updateMany,
  create,
  delete: deleteProvider,
  deleteMany
};
