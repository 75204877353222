import React from "react";
import { FC } from "@laba/react-common";
import {
  MedicalDeviceForm,
  medicalDeviceJsonToModel,
  medicalDeviceTransform
} from "components/pages/NexupAdmin/resources/medicalDevice/MedicalDeviceForm";
import { Edit } from "components/generic/Edit/Edit";
import { MedicalDevice, ResourceType } from "@laba/nexup-api";
import { useElementExportedFileName } from "components/hook/useExportedFileName";

export const MedicalDeviceEdit: FC = () => {
  const exportedFileName = useElementExportedFileName<MedicalDevice>(
    resource => resource.name
  );
  return (
    <Edit
      resourceTransform={medicalDeviceTransform}
      resource={ResourceType.MedicalDevice}
      jsonToModel={medicalDeviceJsonToModel}
      exportedFileName={exportedFileName}
    >
      <MedicalDeviceForm />
    </Edit>
  );
};
