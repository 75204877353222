import { getStyleThemeDecorator } from "storybook/decorator/jss";
import { getResizableBoxDecorator } from "storybook/decorator/resizable";
import { JssDefaultTheme } from "style/StyleProvider";
import { StoryDecorator } from "storybook/decorator/types";

export const getDefaultDecorators = <T extends JssDefaultTheme>(
  theme: T,
  withResizableBox = true,
  extraDecorators: StoryDecorator[] = []
): StoryDecorator[] => [
  getStyleThemeDecorator(theme),
  ...(withResizableBox ? [getResizableBoxDecorator()] : []),
  ...extraDecorators
];
