import { tkCP } from "translation/i18n";
import { ConceptResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { FC, useI18n } from "@laba/react-common";
import React, { useState } from "react";
import { TextInput } from "components/generic/TextInput/TextInput";
import {
  KnownCodeSystemSystem,
  KnownConceptPropertyUse,
  KnownProcedureCodeNomenclature,
  ProcedureCodeConceptKey,
  ProcedureCodeConceptNomenclature,
  ProcedureCodeConceptNomenclatureKey
} from "@laba/nexup-api";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useSelector } from "react-redux";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { ConceptPropertyInput } from "components/generic/ConceptPropertyInput/ConceptPropertyInput";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { validatePreferredTermSynonymDesignation } from "components/pages/NexupAdmin/resources/concept/procedureCodeConceptList/validate";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { v4 as uuidV4 } from "uuid";

export const tk = tkCP.adminPage[ConceptResourceType.ProcedureCodeConcept];
export const ProcedureCodeConceptContent: FC = () => {
  const procedureCodeConceptId = useWatchResourceForm<string>(
    ProcedureCodeConceptKey.id
  );
  const [initialIdValue] = useState(procedureCodeConceptId);
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const medicalAreaCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.CodeSystemConceptMedicalArea,
    workspaceOrganizationId
  );

  const procedureCodeConceptSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.ProcedureReportCode,
    workspaceOrganizationId
  );

  const validationConcepts = procedureCodeConceptSystem?.concept?.filter(
    c => c.code !== initialIdValue
  );

  const tagSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.CodeSystemConceptProcedureCodeTag,
    workspaceOrganizationId
  );
  const bodyRegionSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.CodeSystemConceptBodyRegion,
    workspaceOrganizationId
  );

  const specimenSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.CodeSystemConceptSpecimen,
    workspaceOrganizationId
  );
  const specialitySystem = useCodeSystemGroup(
    KnownCodeSystemSystem.CodeSystemConceptSpeciality,
    workspaceOrganizationId
  );

  const bodyRegionValue = useWatchResourceForm<string>(
    ProcedureCodeConceptKey.bodyRegion
  );
  const concept = bodyRegionSystem?.concept?.find(
    c => c.code === bodyRegionValue
  );
  const procedureCodeConceptsNomenclatures = useWatchResourceForm<
    ProcedureCodeConceptNomenclature[]
  >(ProcedureCodeConceptKey.nomenclature);
  const nbuNomenclature = procedureCodeConceptsNomenclatures?.find(
    nomenclature =>
      nomenclature.nomenclature === KnownProcedureCodeNomenclature.NBU
  );
  return (
    <>
      <TextInput
        source={ProcedureCodeConceptKey.id}
        label={t(tk.fields.id)}
        required
        disabled={initialIdValue !== undefined}
        defaultValue={uuidV4()}
      />
      <TextInput
        source={ProcedureCodeConceptKey.display}
        label={t(tk.fields.display)}
        required
      />
      <CodeSystemSelectorInput
        source={ProcedureCodeConceptKey.medicalArea}
        label={t(tk.fields.medicalAreaCode)}
        codeSystem={medicalAreaCodeSystem}
        organizationId={workspaceOrganizationId}
        withCreate
      />
      <BooleanInput
        source={ProcedureCodeConceptKey.active}
        label={t(tk.fields.active)}
        defaultValue
      />
      <TextInput
        source={ProcedureCodeConceptKey.preferredTerm}
        label={t(tk.fields.preferredTerm)}
        validate={(value: string) => {
          return validatePreferredTermSynonymDesignation(
            t(tk.errors.repeatedPreferredTerm),
            validationConcepts,
            value
          );
        }}
        required
      />
      <ArrayInput
        source={ProcedureCodeConceptKey.synonyms}
        label={t(tk.fields.synonymous)}
      >
        <TextInput
          source=""
          label=""
          validate={(value: string) => {
            return validatePreferredTermSynonymDesignation(
              t(tk.errors.repeatedSynonymous),
              validationConcepts,
              value
            );
          }}
        />
      </ArrayInput>
      <ArrayInput source={ProcedureCodeConceptKey.tag} label={t(tk.fields.tag)}>
        <CodeSystemSelectorInput
          source=""
          label=""
          codeSystem={tagSystem}
          organizationId={workspaceOrganizationId}
          withCreate
        />
      </ArrayInput>
      <CodeSystemSelectorInput
        source={ProcedureCodeConceptKey.bodyRegion}
        label={t(tk.fields.bodyRegion)}
        codeSystem={bodyRegionSystem}
        organizationId={workspaceOrganizationId}
        withCreate
      />
      <ConceptPropertyInput
        source={ProcedureCodeConceptKey.subBodyRegion}
        label={t(tk.fields.subBodyRegion)}
        concept={concept}
        propertyUse={KnownConceptPropertyUse.SubBodyRegion}
        disabled={bodyRegionValue === undefined}
      />
      <CodeSystemSelectorInput
        source={ProcedureCodeConceptKey.specimen}
        label={t(tk.fields.specimen)}
        codeSystem={specimenSystem}
        organizationId={workspaceOrganizationId}
        withCreate
      />
      <ArrayInput
        source={ProcedureCodeConceptKey.speciality}
        label={t(tk.fields.speciality)}
      >
        <CodeSystemSelectorInput
          source=""
          label=""
          codeSystem={specialitySystem}
          organizationId={workspaceOrganizationId}
          withCreate
        />
      </ArrayInput>
      <ArrayInput source={ProcedureCodeConceptKey.nomenclature}>
        <EnumSelectorInput
          source={ProcedureCodeConceptNomenclatureKey.nomenclature}
          label={t(tk.fields.nomenclatureValue)}
          enumValue={KnownProcedureCodeNomenclature}
        />
        <TextInput
          source={ProcedureCodeConceptNomenclatureKey.code}
          label={t(tk.fields.nomenclatureCode)}
        />
        {nbuNomenclature !== undefined && (
          <TextInput source={ProcedureCodeConceptNomenclatureKey.book} />
        )}
      </ArrayInput>
    </>
  );
};
