import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  MedicalRequestDefinitionKey,
  ResourceType,
  MedicalRequestDefinitionAdministrationTimeframeType,
  MedicalRequestDefinitionFlowConfig
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { NumberInput } from "components/generic/NumberInput/TextInput";
import { FlowConfigInput } from "components/generic/FlowConfigInput/FlowConfigInput";

export const tk = tkCP.adminPage[ResourceType.MedicalRequestDefinition];
export const MedicalRequestDefinitionMedicalConfigurationContent: FC = () => {
  const { t } = useI18n();
  return (
    <>
      <FlowConfigInput
        enumOptions={MedicalRequestDefinitionFlowConfig}
        source={MedicalRequestDefinitionKey.flowConfig}
      />
      <NumberInput
        source={MedicalRequestDefinitionKey.renewPeriod}
        label={t(tk.fields.renewPeriod)}
        min={0}
        step={1}
      />
      <EnumSelectorInput
        source={MedicalRequestDefinitionKey.administrationTimeframeType}
        label={t(tk.fields.administrationTimeframeType)}
        enumValue={MedicalRequestDefinitionAdministrationTimeframeType}
      />
      <NumberInput
        source={MedicalRequestDefinitionKey.administrationEditPeriod}
        label={t(tk.fields.administrationEditPeriod)}
        min={0}
        step={1}
      />
      <NumberInput
        source={MedicalRequestDefinitionKey.prescriptionDatePeriod}
        label={t(tk.fields.prescriptionDatePeriod)}
        min={0}
        step={1}
      />
    </>
  );
};
