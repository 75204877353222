import React from "react";
import { usePdfViewerStyles } from "./usePdfViewerStyles";

interface Props {
  pdfBlob: Blob;
}

export const PdfViewer: React.FC<Props> = ({ pdfBlob }) => {
  const classes = usePdfViewerStyles();
  return (
    <embed
      className={classes.root}
      title="pdfViewer"
      src={URL.createObjectURL(pdfBlob)}
    />
  );
};
