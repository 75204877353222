import {
  FlexAlignItems,
  createStyle,
  flexRowMixin,
  FlexJustifyContent
} from "@laba/react-common";
import { Theme } from "model/theme";

export const useMenuDefaultOptionStyles = createStyle(
  (_theme: Theme) => ({
    root: {
      ...flexRowMixin({
        justifyContent: FlexJustifyContent.SpaceBetween,
        gap: 16
      }),
      width: " 100%"
    },
    leftContainer: {
      ...flexRowMixin({ alignItems: FlexAlignItems.Center, gap: 8 })
    }
  }),
  "MenuDefaultOption"
);
