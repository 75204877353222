import qs, { ParsedQs } from "qs";
import { ReplaceQueryParamsAction } from "../models/replaceQueryParamsAction";
import { RouterLocation, RouterLocationPath } from "../models/routerLocation";

const updatedQueryString = (
  oldParams: ParsedQs,
  newParams: ParsedQs
): string => {
  return qs.stringify({ ...oldParams, ...newParams });
};

const buildNewParams = (
  action: ReplaceQueryParamsAction,
  location: RouterLocation
): string =>
  action.overrideAllParams
    ? `?${qs.stringify(action.params)}`
    : `?${updatedQueryString(
        qs.parse(location.search, { ignoreQueryPrefix: true }),
        action.params ?? {}
      )}`;

const trimHashFromHashString = (str: string) => str.replace("#", "");

const buildNewHash = (
  action: ReplaceQueryParamsAction,
  location: RouterLocation
): string => {
  if (action.hash) {
    return `#${trimHashFromHashString(action.hash)}`;
  }
  return action.overrideHash ? "" : `#${trimHashFromHashString(location.hash)}`;
};

export const applyReplaceQueryParamsAction = (
  action: ReplaceQueryParamsAction,
  location: RouterLocation
): RouterLocationPath => {
  const newParams = buildNewParams(action, location);
  const newHash = buildNewHash(action, location);
  return `${location.pathname}${newParams}${newHash}`;
};
