import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  paddingMixin
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { StyleVariant } from "model/themeVariant";
import { getThemeStyleColor } from "model/themeUtils";
import {
  PopupSizeVariant,
  PopupVariant
} from "components/popup/BasePopup/BasePopupStyle";

export type DialogVariant = PopupVariant;
export type DialogSizeVariant = PopupSizeVariant;

interface DialogStyleProps {
  iconStyle?: StyleVariant;
  dialogVariant: DialogVariant;
  isMobile?: boolean;
}

interface DialogClassNames {
  content?: string;
  grayDarkColor?: string;
}

const useDialogStaticStyles = createStyle(
  (theme: Theme) => ({
    content: {
      ...flexColumnMixin({
        gap: 16
      }),
      ...paddingMixin({ leftRight: 8, top: 8 })
    },
    verticalcontent: {
      ...flexColumnMixin({ gap: 16, alignItems: FlexAlignItems.Center }),
      textAlign: "center"
    },
    grayDarkColor: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "DialogStatic"
);

export const useDialogStyles = (props: DialogStyleProps): DialogClassNames => {
  const staticClasses = useDialogStaticStyles();

  return {
    content:
      props.dialogVariant === PopupVariant.Horizontal
        ? staticClasses.content
        : staticClasses.verticalcontent,
    grayDarkColor: staticClasses.grayDarkColor
  };
};
