import { FC, getClassName, IconC } from "@laba/react-common";
import { Noop } from "@laba/ts-common";
import { IconOrImg } from "components/helpers/IconOrImg";
import { useTabStyle } from "components/tabs/HorizontalTab/HorizontalTabStyle";
import { StyleVariant } from "model/themeVariant";
import React from "react";

export interface HorizontalTabProps {
  isMobile?: boolean;
  className?: string;
  title?: string;
  text?: string;
  isActive?: boolean;
  disabled?: boolean;
  onSelect?: Noop;
  Icon?: IconC | string;
  tabRef?: React.Ref<HTMLButtonElement>;
  showText?: boolean;
}

export const HorizontalTab: FC<HorizontalTabProps> = ({
  isMobile,
  className,
  title,
  text,
  onSelect,
  Icon,
  tabRef,
  isActive = false,
  disabled = false,
  showText = true
}) => {
  const style = StyleVariant.Primary;
  const classes = useTabStyle({
    isActive,
    disabled,
    style,
    isMobile,
    showText
  });
  return (
    <button
      type="button"
      className={getClassName(classes.container, className)}
      title={title}
      onClick={() => !isActive && !disabled && onSelect?.()}
      ref={tabRef}
    >
      {Icon && <IconOrImg Logo={Icon} className={classes.icon} />}
      {showText && <span className={classes.text}>{text}</span>}
    </button>
  );
};
