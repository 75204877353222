import { sliceSelector } from "@laba/react-common";
import { RootState } from "store/store";
import {
  CodeSystem,
  CodeSystemsRecord,
  CodeSystemSystem,
  CodeSystemWithStatus,
  getModelReferenceId,
  ModelId,
  ModelReference,
  Organization,
  OrganizationWorkspaces,
  Practitioner,
  PractitionerRole,
  Workspace
} from "@laba/nexup-api";
import { workspaceSlice } from "store/workspace/slice";
import { notNull, Optional } from "@laba/ts-common";
import { minBy } from "lodash-es";
import { createSelector } from "@reduxjs/toolkit";

const slice = sliceSelector(workspaceSlice);

const organizationWorkspacesSelector = (
  state: RootState
): Optional<OrganizationWorkspaces> => slice(state).workspaceList;

export const workspaceListSelector = (state: RootState): Workspace[] => {
  return organizationWorkspacesSelector(state)?.workspace ?? [];
};

export const workspaceListOrganizationIdSelector = (
  state: RootState
): ModelId[] =>
  workspaceListSelector(state)
    .map(value => value.organization?.id)
    .filter(notNull);

export const defaultWorkspaceSelector = (
  state: RootState
): Optional<Workspace> =>
  minBy(workspaceListSelector(state), workspace =>
    Number(workspace.organization?.id)
  );

export const defaultWorkspaceOrganizationIdSelector = (
  state: RootState
): Optional<ModelId> =>
  getModelReferenceId(defaultWorkspaceSelector(state)?.organization);

export const workspaceOrganizationIdSelector = (
  state: RootState
): Optional<ModelId> => slice(state).currentWorkspaceOrganizationId;

export const workspaceOrganizationSelector = (
  state: RootState
): Optional<Organization> =>
  workspaceListSelector(state)
    .map(value => value.organization)
    .find(value => value?.id === workspaceOrganizationIdSelector(state));

export const workspaceOrganizationOrDefaultSelector = (
  state: RootState
): Optional<Organization> =>
  workspaceOrganizationSelector(state) ??
  defaultWorkspaceSelector(state)?.organization;

export const workspaceSelector = (state: RootState): Optional<Workspace> =>
  workspaceListSelector(state).find(
    value => value.organization?.id === workspaceOrganizationIdSelector(state)
  ) ?? defaultWorkspaceSelector(state);

export const organizationCodeSystemRecordSelector = (
  state: RootState
): Record<ModelId, CodeSystemsRecord> =>
  slice(state).organizationCodeSystemRecord ?? {};

export const codeSystemRecordSelector = createSelector(
  organizationCodeSystemRecordSelector,
  workspaceOrganizationOrDefaultSelector,
  (organizationCodeSystemRecord, defaultOrganization) =>
    (organization?: ModelReference<Organization>): CodeSystemsRecord => {
      const toUseOrganizationId = getModelReferenceId(
        organization || defaultOrganization
      );
      if (!toUseOrganizationId) return {};
      return organizationCodeSystemRecord[toUseOrganizationId] ?? {};
    }
);

export const codeSystemWithStatusSelector = createSelector(
  codeSystemRecordSelector,
  codeSystemRecord =>
    (
      codeSystem: CodeSystemSystem,
      organization?: ModelReference<Organization>
    ): Optional<CodeSystemWithStatus> => {
      return codeSystemRecord(organization)[codeSystem];
    }
);

export const codeSystemSelector = createSelector(
  codeSystemWithStatusSelector,
  codeSystemWithStatus =>
    (
      codeSystem: CodeSystemSystem,
      organization?: ModelReference<Organization>
    ): Optional<CodeSystem> => {
      return codeSystemWithStatus(codeSystem, organization)
        ?.system as CodeSystem;
    }
);

export const isDownloadedCodeSystemSelector = createSelector(
  codeSystemSelector,
  codeSystemData =>
    (
      codeSystem: CodeSystemSystem,
      organization?: ModelReference<Organization>
    ): boolean =>
      codeSystemData(codeSystem, organization) !== undefined
);

export const isDownloadingCodeSystemSelector = createSelector(
  codeSystemWithStatusSelector,
  codeSystemWithStatus =>
    (
      codeSystem: CodeSystemSystem,
      organization?: ModelReference<Organization>
    ): boolean =>
      codeSystemWithStatus(codeSystem, organization)?.downloadStatus
        .isDownloading ?? false
);

export const codeSystemDownloadFailedSelector = createSelector(
  codeSystemWithStatusSelector,
  codeSystemWithStatus =>
    (
      codeSystem: CodeSystemSystem,
      organization?: ModelReference<Organization>
    ): boolean =>
      codeSystemWithStatus(codeSystem, organization)?.downloadStatus
        .downloadFailed ?? false
);

export const shouldDownloadSystemSelector = createSelector(
  codeSystemDownloadFailedSelector,
  isDownloadedCodeSystemSelector,
  isDownloadingCodeSystemSelector,
  (codeSystemDownloadFailed, isDownloadedCodeSystem, isDownloadingCodeSystem) =>
    (
      system: CodeSystemSystem,
      organization?: ModelReference<Organization>
    ): boolean => {
      const failedDownload = codeSystemDownloadFailed(system, organization);
      const alreadyDownloaded = isDownloadedCodeSystem(system, organization);
      const currentlyDownloading = isDownloadingCodeSystem(
        system,
        organization
      );
      return failedDownload || (!alreadyDownloaded && !currentlyDownloading);
    }
);

export const practitionerRoleRecordSelector = (
  state: RootState
): Optional<Record<ModelId, PractitionerRole[]>> =>
  slice(state).practitionerRoleRecord;

export const practitionerRoleSelector = createSelector(
  practitionerRoleRecordSelector,
  practitionerRoleRecord =>
    (
      practitioner?: ModelReference<Practitioner>
    ): Optional<PractitionerRole[]> => {
      return practitionerRoleRecord?.[getModelReferenceId(practitioner) ?? ""];
    }
);
