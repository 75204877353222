import { createStyle, flexRowMixin } from "@laba/react-common";
import { Theme } from "model/theme";

interface StepperStyleClassNames {
  container: string;
  step: string;
}
interface StepperStyleProps {
  isMobile?: boolean;
}

const useStepperStaticStaticStyles = createStyle(
  (_theme: Theme) => ({
    desktopContainer: {
      ...flexRowMixin({
        gap: 16
      })
    },
    mobileContainer: {
      ...flexRowMixin({
        gap: 8
      })
    },
    step: {
      width: "100%"
    }
  }),
  "StepperStatic"
);

export const useStepperStyles = (
  props: StepperStyleProps
): StepperStyleClassNames => {
  const { isMobile } = props;
  const staticStyles = useStepperStaticStaticStyles();
  return {
    container: isMobile
      ? staticStyles.mobileContainer
      : staticStyles.desktopContainer,
    step: staticStyles.step
  };
};
