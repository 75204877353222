import { ModelId } from "model/primitives/model/model";
import { ApiRequestResponse } from "request/types";
import { getFrontApiUrl, getReportApiUrl, request } from "request/nexupRequest";
import { ElementOrArray, HttpMethod, ApiDate } from "@laba/ts-common";
import { PatientReportType } from "model/primitives/report";
import { QuestionnaireType } from "model/resource/evolution/questionnaire/questionnaire";

export interface PatientReportQueryParams {
  date?: ElementOrArray<ApiDate>;
  patient?: ModelId;
  type?: PatientReportType;
}

export const getPatientReport = (
  params: PatientReportQueryParams
): Promise<ApiRequestResponse<Blob>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`/patient-report`),
    params,
    responseType: "blob"
  });

export interface EvolutionReportQueryParams {
  patient: ModelId;
  endDate?: ApiDate;
  startDate?: ApiDate;
  id?: ModelId[];
  type?: QuestionnaireType;
}

export const getEvolutionReport = (
  params: EvolutionReportQueryParams
): Promise<ApiRequestResponse<Blob>> =>
  request({
    method: HttpMethod.GET,
    url: getReportApiUrl(`/evolution`),
    params: { ...params, type: params.type ?? QuestionnaireType.Medical },
    responseType: "blob"
  });

export const getEvolutionDetailReport = (
  evolutionId: ModelId
): Promise<ApiRequestResponse<Blob>> =>
  request({
    method: HttpMethod.GET,
    url: getReportApiUrl(`/evolution/${evolutionId}`),
    responseType: "blob"
  });

export interface NurseryReportQueryParams {
  patient: ModelId;
  startDate?: ApiDate;
  endDate?: ApiDate;
}

export const getNurseryReport = (
  params: NurseryReportQueryParams
): Promise<ApiRequestResponse<Blob>> =>
  request({
    method: HttpMethod.GET,
    url: getReportApiUrl(`/nursing`),
    params,
    responseType: "blob"
  });
