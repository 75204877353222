import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  getModelReferenceId,
  KnownCodeSystemSystem,
  ModelReference,
  Organization,
  OrganizationCombinedType,
  OrganizationListQueryParamsKey,
  PublicationStatus,
  QuestionnaireFlowConfig,
  QuestionnaireKey,
  QuestionnaireType,
  ResourceType
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { NumberInput } from "components/generic/NumberInput/TextInput";
import { ColorInput } from "components/generic/ColorInput/ColorInput";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { ThymeleafInput } from "components/generic/ThymeleafInput/ThymeleafInput";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { PermissionConfigInput } from "components/generic/PermissionConfigInput/PermissionConfigInput";
import { FlowConfigInput } from "components/generic/FlowConfigInput/FlowConfigInput";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";
import { QuestionnaireConsumptionConfigComponent } from "./common/QuestionnaireConsumptionConfig/QuestionnaireConsumptionConfigComponent";

export const tk = tkCP.adminPage[ResourceType.Questionnaire];
export const QuestionnaireMainContent: FC = () => {
  const { t } = useI18n();
  const organization = useWatchResourceForm<ModelReference<Organization>>(
    QuestionnaireKey.organization
  );
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organizationId =
    getModelReferenceId(organization) || workspaceOrganizationId;
  const categorySystem = useCodeSystemGroup(
    KnownCodeSystemSystem.QuestionnaireCategory,
    organizationId
  );
  return (
    <>
      <TextInput
        disabled
        source={QuestionnaireKey.id}
        label={t(tk.fields.id)}
      />
      <OrganizationReferenceInput
        source={QuestionnaireKey.organization}
        label={t(tk.fields.organization)}
        type={OrganizationCombinedType.ProviderAndGroup}
        extraFilters={{
          [OrganizationListQueryParamsKey.active]: true
        }}
        required
        defaultValue={workspaceOrganizationId}
        disabled={workspaceOrganizationId !== undefined}
      />
      <TextInput
        source={QuestionnaireKey.title}
        label={t(tk.fields.title)}
        required
      />
      <TextInput source={QuestionnaireKey.name} label={t(tk.fields.name)} />
      <EnumSelectorInput
        source={QuestionnaireKey.status}
        label={t(tk.fields.status)}
        enumValue={PublicationStatus}
        required
      />
      <EnumSelectorInput
        source={QuestionnaireKey.type}
        label={t(tk.fields.type)}
        enumValue={QuestionnaireType}
        required
      />
      <CodeSystemSelectorInput
        source={QuestionnaireKey.category}
        label={t(tk.filters.category)}
        codeSystem={categorySystem}
        organizationId={organizationId}
        required
        alwaysOn
        resettable
        withCreate
      />
      <NumberInput
        source={QuestionnaireKey.editInterval}
        label={t(tk.fields.editInterval)}
        min={0}
        step={1}
      />
      <ColorInput source={QuestionnaireKey.color} label={t(tk.fields.color)} />
      <BooleanInput
        source={QuestionnaireKey.featured}
        label={t(tk.fields.featured)}
      />
      <NumberInput
        source={QuestionnaireKey.order}
        label={t(tk.fields.order)}
        min={0}
        step={1}
      />
      <ThymeleafInput
        source={QuestionnaireKey.responseTitleTemplate}
        label={t(tk.fields.responseTitleTemplate)}
      />
      <ThymeleafInput
        source={QuestionnaireKey.responseDescriptionTemplate}
        label={t(tk.fields.responseDescriptionTemplate)}
      />
      <FlowConfigInput
        enumOptions={QuestionnaireFlowConfig}
        source={QuestionnaireKey.flowConfig}
      />
      <PermissionConfigInput
        source={QuestionnaireKey.permissionConfig.thisKey}
        organizationId={organizationId}
      />
      <QuestionnaireConsumptionConfigComponent />
    </>
  );
};
