import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  KnownCodeSystemSystem,
  Location,
  LocationKey,
  LocationListQueryParamsKey,
  LocationStatus,
  LocationType,
  OrganizationCombinedType,
  OrganizationKey,
  OrganizationListQueryParamsKey,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { TextField } from "components/generic/TextField/TextField";
import { ReferenceResourceField } from "components/generic/ReferenceResourceField/ReferenceResourceField";
import { List } from "components/generic/List/List";
import { Datagrid } from "components/generic/Datagrid/Datagrid";
import { TextInput } from "components/generic/TextInput/TextInput";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { locationJsonToModel } from "components/pages/NexupAdmin/resources/location/LocationForm";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { locationOptionText } from "components/generic/ReferenceResourceInput/optionText";
import { useListExportedFileName } from "components/hook/useExportedFileName";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

const tk = tkCP.adminPage[ResourceType.Location];

export const LocationList: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const locationDirectoryTypeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.LocationDirectoryType,
    workspaceOrganizationId
  );
  const exportedFileName = useListExportedFileName(t(tk.title));

  const locationCategorySystem = useCodeSystemGroup(
    KnownCodeSystemSystem.LocationCategory,
    workspaceOrganizationId
  );

  return (
    <List
      resource={ResourceType.Location}
      exportedFileName={exportedFileName}
      jsonToModel={locationJsonToModel}
      extraFilters={[
        workspaceOrganizationId === undefined ? (
          <OrganizationReferenceInput
            label={t(tk.filters.organization)}
            source={LocationListQueryParamsKey.organization}
            alwaysOn
            type={OrganizationCombinedType.ProviderAndGroup}
            extraFilters={{
              [OrganizationListQueryParamsKey.active]: true
            }}
          />
        ) : undefined,
        <CodeSystemSelectorInput
          source={LocationListQueryParamsKey.category}
          label={t(tk.fields.category)}
          codeSystem={locationCategorySystem}
          organizationId={workspaceOrganizationId}
          withCreate
        />,
        <ReferenceResourceInput<Location>
          resourceType={ResourceType.Location}
          label={t(tk.filters.partOf)}
          source={LocationListQueryParamsKey.partOf}
          optionTextKey={locationOptionText}
          alwaysOn
          extraFilters={{
            [LocationListQueryParamsKey.organization]: workspaceOrganizationId
          }}
        />,
        <EnumSelectorInput
          source={LocationListQueryParamsKey.status}
          label={t(tk.filters.status)}
          enumValue={LocationStatus}
          alwaysOn
          resettable
        />,
        <TextInput
          source={LocationListQueryParamsKey.hisCode}
          label={t(tk.filters.hisCode)}
          resettable
        />,
        <EnumSelectorInput
          source={LocationListQueryParamsKey.physicalType}
          label={t(tk.filters.physicalType)}
          enumValue={LocationType}
          resettable
        />,
        <CodeSystemSelectorInput
          source={LocationListQueryParamsKey.directoryType}
          label={t(tk.filters.directoryType)}
          codeSystem={locationDirectoryTypeSystem}
          organizationId={workspaceOrganizationId}
          resettable
        />
      ]}
    >
      <Datagrid>
        <TextField source={LocationKey.id} label={t(tk.fields.id)} />
        <TextField source={LocationKey.name} label={t(tk.fields.name)} />
        <TextField
          source={LocationKey.description}
          label={t(tk.fields.description)}
        />
        <TextField source={LocationKey.status} label={t(tk.fields.status)} />
        <ReferenceResourceField
          resourceType={ResourceType.Organization}
          label={t(tk.fields.organization)}
          source={LocationKey.organization}
          optionTextKey={OrganizationKey.name}
        />
        <TextField
          source={LocationKey.category}
          label={t(tk.fields.category)}
        />
        <ReferenceResourceField
          resourceType={ResourceType.Location}
          label={t(tk.fields.partOf)}
          source={LocationKey.partOf}
          optionTextKey={LocationKey.name}
        />
        <TextField source={LocationKey.type} label={t(tk.fields.type)} />
        <TextField
          source={LocationKey.directoryType}
          label={t(tk.fields.directoryType)}
        />
        <TextField source={LocationKey.hisCode} label={t(tk.fields.hisCode)} />
      </Datagrid>
    </List>
  );
};
