import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { ApiDate, getKeyObj, HttpMethod } from "@laba/ts-common";
import {
  Appointment,
  AppointmentStatus
} from "model/resource/appointment/appointment";
import { ModelId } from "model/primitives/model/model";
import { createHydratedMock } from "ts-auto-mock";
import { VideoAppointmentEncounter } from "model/resource/entities/encounter/appointmentEncounter";
import { KnownScheduleType } from "model/resource/schedule/schedule";

export interface AppointmentDetailQueryParams {
  withPatient?: boolean;
  withPatientHealthcarePayer?: boolean;
  withPractitioner?: boolean;
  withLocation?: boolean;
  withSchedule?: boolean;
  withDefinition?: boolean;
  withOriginatingAppointment?: boolean;
  withPayer?: boolean;
  withProduct?: boolean;
}

export enum AppointmentListOrder {
  StatusStartDate = "statusStartDate",
  CreationDate = "creationDate",
  EndDateDesc = "endDateDesc"
}

export enum AppointmentStatusAction {
  Confirm = "Confirm",
  Admit = "Admit",
  Attend = "Attend",
  Finish = "Finish",
  Cancel = "Cancel",
  CancelSeries = "CancelSeries"
}

export interface AppointmentListQueryParams
  extends ListQueryParams,
    AppointmentDetailQueryParams {
  order?: AppointmentListOrder;
  appointmentDefinition?: ModelId;
  endStartDate?: ApiDate;
  hash?: string;
  healthcareService?: ModelId;
  organization?: ModelId;
  patient?: ModelId;
  practitioner?: ModelId;
  startStartDate?: ApiDate;
  status?: AppointmentStatus;
  recurrenceSeriesId?: ModelId;
  type?: KnownScheduleType;
}

export const AppointmentListQueryParamsKey =
  getKeyObj<AppointmentListQueryParams>(
    createHydratedMock<AppointmentListQueryParams>()
  );

export const AppointmentFormDataParamsKey = getKeyObj(
  createHydratedMock<Appointment>()
);

export const appointmentBasePath = "/appointment";

export const getAppointmentList = (
  params?: AppointmentListQueryParams
): Promise<ApiPageRequestResponse<Appointment>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(appointmentBasePath),
    params
  });

export const createAppointment = (
  data: Appointment
): Promise<ApiRequestResponse<Appointment>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(appointmentBasePath),
    data
  });

export const updateAppointment = (
  data: Appointment
): Promise<ApiRequestResponse<Appointment>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${appointmentBasePath}/${data.id}`),
    data
  });

export const updateAppointmentSeries = (
  data: Appointment
): Promise<ApiRequestResponse<Appointment>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${appointmentBasePath}/${data.id}/series`),
    data
  });

export const getAppointmentDetail = (
  id: ModelId,
  params?: AppointmentDetailQueryParams
): Promise<ApiRequestResponse<Appointment>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${appointmentBasePath}/${id}`),
    params
  });

export const cancelAppointment = (
  id: ModelId
): Promise<ApiRequestResponse<Appointment>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(`${appointmentBasePath}/${id}/cancel`)
  });

export const attendAppointment = (
  id: ModelId
): Promise<ApiRequestResponse<VideoAppointmentEncounter>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(`${appointmentBasePath}/${id}/attend`)
  });
