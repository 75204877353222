import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  getModelReferenceId,
  KnownCodeSystemSystem,
  ModelReference,
  Organization,
  OrganizationCombinedType,
  OrganizationListQueryParamsKey,
  PatientKey,
  PatientRoleKey,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

export const tk = tkCP.adminPage[ResourceType.Patient];
export const PatientRoleContent: FC = () => {
  const { t } = useI18n();
  const patientOrganization = useWatchResourceForm<
    ModelReference<Organization>
  >(PatientKey.organization);
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organizationId =
    getModelReferenceId(patientOrganization) || workspaceOrganizationId;
  const patientRole = useCodeSystemGroup(
    KnownCodeSystemSystem.PatientRole,
    organizationId
  );
  return (
    <ArrayInput source={PatientKey.roleList} label={t(tk.fields.role)}>
      <CodeSystemSelectorInput
        source={PatientRoleKey.role}
        label={t(tk.fields.roleCode)}
        codeSystem={patientRole}
        organizationId={organizationId}
      />
      <OrganizationReferenceInput
        source={PatientRoleKey.organization}
        label={t(tk.fields.roleOrganization)}
        type={OrganizationCombinedType.ProviderAndGroup}
        extraFilters={{
          [OrganizationListQueryParamsKey.active]: true
        }}
        required
        defaultValue={organizationId}
        disabled={workspaceOrganizationId !== undefined}
      />
    </ArrayInput>
  );
};
