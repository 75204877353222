import {
  ActivityDefinitionKey,
  ActivityDefinitionTemplateCode,
  ActivityDefinitionTemplateKey,
  ModelId,
  OrganizationCombinedType,
  OrganizationListQueryParamsKey,
  PublicationStatus
} from "@laba/nexup-api";
import { FC, useI18n } from "@laba/react-common";
import React from "react";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { tkCC } from "translation/i18n";
import { TextInput } from "components/generic/TextInput/TextInput";
import { ConfigurationCodeSystemInput } from "components/generic/ConfigurationCodeSystemInput/ConfigurationCodeSystemInput";
import { EmailConfigInput } from "components/generic/EmailConfigInput/EmailConfigInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { PhoneConfigInput } from "components/generic/PhoneConfigInput/PhoneConfigInput";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { ThymeleafInput } from "components/generic/ThymeleafInput/ThymeleafInput";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { PermissionConfigInput } from "components/generic/PermissionConfigInput/PermissionConfigInput";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

const tk = tkCC.inputs.activityDefinitionCommonInput;

interface Props {
  organizationId?: ModelId;
}
export const ActivityDefinitionCommonInput: FC<Props> = ({
  organizationId
}) => {
  const { t } = useI18n();

  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  return (
    <>
      <TextInput disabled source={ActivityDefinitionKey.id} label={t(tk.id)} />
      <TextInput source={ActivityDefinitionKey.name} label={t(tk.name)} />
      <TextInput source={ActivityDefinitionKey.type} label={t(tk.type)} />
      <OrganizationReferenceInput
        source={ActivityDefinitionKey.organization}
        label={t(tk.organization)}
        type={OrganizationCombinedType.ProviderAndGroup}
        extraFilters={{
          [OrganizationListQueryParamsKey.active]: true
        }}
        required
        defaultValue={workspaceOrganizationId}
        disabled={workspaceOrganizationId !== undefined}
      />
      <EnumSelectorInput
        source={ActivityDefinitionKey.status}
        label={t(tk.status)}
        enumValue={PublicationStatus}
        required
      />
      <PhoneConfigInput
        source={ActivityDefinitionKey.phoneConfig}
        label={t(tk.phoneConfig)}
      />
      <EmailConfigInput
        source={ActivityDefinitionKey.emailConfig}
        label={t(tk.emailConfig)}
      />
      <ConfigurationCodeSystemInput
        source={ActivityDefinitionKey.configurationCodeSystem}
        label={t(tk.configurationCodeSystem)}
        organizationId={organizationId || workspaceOrganizationId}
      />
      <ArrayInput
        source={ActivityDefinitionKey.templateList}
        label={t(tk.templateList)}
      >
        <EnumSelectorInput
          source={ActivityDefinitionTemplateKey.code}
          label={t(tk.templateListCode)}
          enumValue={ActivityDefinitionTemplateCode}
          withCreate
        />
        <ThymeleafInput
          source={ActivityDefinitionTemplateKey.template}
          label={t(tk.templateListTemplate)}
        />
      </ArrayInput>
      <BooleanInput
        source={ActivityDefinitionKey.default}
        label={t(tk.default)}
        defaultValue={false}
      />
      <PermissionConfigInput
        source={ActivityDefinitionKey.permissionConfig.thisKey}
        organizationId={organizationId}
      />
    </>
  );
};
