import { createStyle, Theme } from "@laba/react-common";

export interface IFrameButtonStylesClassNames {
  iframe: string;
  popupContent: string;
}

export interface IFrameButtonStylesProps {
  isMobile?: boolean;
}

const useIFrameButtonStylesStatic = createStyle(
  (_theme: Theme) => ({
    iframe: {
      width: "100%",
      minHeight: "100%",
      height: "100%"
    },
    popupContentDesktop: {
      height: "70vh",
      overflow: "hidden"
    },
    popupContentMobile: {
      height: "90%",
      overflow: "hidden"
    }
  }),
  "IFrameButton"
);

export const useIFrameButtonStyles = (
  props: IFrameButtonStylesProps
): IFrameButtonStylesClassNames => {
  const classes = useIFrameButtonStylesStatic();
  return {
    iframe: classes.iframe,
    popupContent: props.isMobile
      ? classes.popupContentMobile
      : classes.popupContentDesktop
  };
};
