import {
  createStyle,
  FlexAlignItems,
  FlexJustifyContent,
  flexRowMixin,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

interface PatientAllergiesDynamicStylesProps {
  textVariant: TextVariant;
  maxLines?: number;
  titleVariant: TextVariant;
}
interface PatientAllergiesClassNames {
  patientAllergiesContainer: string;
  patientNoAllergiesIcon: string;
  patientAllergiesIcon: string;
  patientUntestedIcon: string;
  patientAllergiesText: string;
  patientAllergiesTitle: string;
}

const usePatientAllergiesStaticStyles = createStyle(
  (theme: Theme) => ({
    patientAllergiesContainer: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.SpaceBetween,
        gap: 8
      })
    },
    patientNoAllergiesIcon: {
      color: getThemeStyleColor(theme, StyleVariant.Success)
    },
    patientAllergiesIcon: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Danger,
        ThemeStyleColorVariant.Dark
      )
    },
    patientUntestedIcon: {
      color: getThemeStyleColor(theme, StyleVariant.Warning)
    }
  }),
  "PatientAllergiesStatic"
);

const usePatientAllergiesDynamicStyles = createStyle(
  (theme: Theme) => ({
    patientAllergiesText: (props: PatientAllergiesDynamicStylesProps) => ({
      ...themeTextMixin(theme, props.textVariant, props.maxLines),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      ),
      textAlign: "start"
    }),
    patientAllergiesTitle: (props: PatientAllergiesDynamicStylesProps) => ({
      ...themeTextMixin(theme, props.titleVariant)
    })
  }),
  "PatientAllergiesDynamic"
);

export const usePatientAllergiesStyles = (
  props: PatientAllergiesDynamicStylesProps
): PatientAllergiesClassNames => {
  const staticClasses = usePatientAllergiesStaticStyles();
  const dynamicClasses = usePatientAllergiesDynamicStyles(props);

  return {
    patientAllergiesContainer: staticClasses.patientAllergiesContainer,
    patientNoAllergiesIcon: staticClasses.patientNoAllergiesIcon,
    patientAllergiesIcon: staticClasses.patientAllergiesIcon,
    patientUntestedIcon: staticClasses.patientUntestedIcon,
    patientAllergiesTitle: dynamicClasses.patientAllergiesTitle,
    patientAllergiesText: dynamicClasses.patientAllergiesText
  };
};
