import React, { useMemo, useState } from "react";
import {
  FC,
  Form,
  FORM_ERROR,
  FormErrorData,
  FormSubmitValidator,
  FormValidator,
  useI18n
} from "@laba/react-common";
import { useLoginPageStyle } from "components/pages/LoginPage/useLoginPageStyle";
import { useLogin } from "react-admin";
import { Card, CardVariant } from "@laba/nexup-components";
import { LoginProviderParams } from "providers/authProvider/authProvider";
import { Server } from "models/server/server";
import { isEmpty } from "lodash-es";
import { tkCP } from "translation/i18n";
import { LoginFormContent } from "components/pages/LoginPage/LoginFormContent/LoginFormContent";
import { RequestFailureResponse } from "@laba/ts-common";
import { BackendError } from "@laba/nexup-api";
import { useLocation } from "react-router-dom";
import qs from "qs";
import { useSelector } from "react-redux";
import { localServerUrlSelector } from "store/session/selectors";
import { login } from "store/session/events";
import { useAppDispatch } from "store/store";
import { WorkspaceSelectorPopUp } from "components/pages/LoginPage/WorkspaceSelectorPopUp/WorkspaceSelectorPopup";
import { onSelectWorkspace } from "store/workspace/events";

const tk = tkCP.loginPage;
const loginFormId = "LoginForm";
const loginValidation: FormValidator<LoginProviderParams> = (t, loginData) => {
  const errors: FormErrorData<LoginProviderParams> = {};

  if (isEmpty(loginData.username)) {
    errors.username = t(tk.validation.required);
  }
  if (isEmpty(loginData.password)) {
    errors.password = t(tk.validation.required);
  }
  if (isEmpty(loginData.server)) {
    errors.server = t(tk.validation.required);
  }

  return isEmpty(errors) ? undefined : errors;
};

export const loginResponseValidation: FormSubmitValidator<
  RequestFailureResponse<BackendError>,
  LoginProviderParams
> = (t, errorResponse) => {
  if (errorResponse === undefined) return undefined;
  const { status, data } = errorResponse;
  if (status && data) {
    if (status === 401) {
      return {
        [FORM_ERROR]: t(tk.validation.invalidCredentials)
      };
    }
    return {
      [FORM_ERROR]: t(tk.validation.defaultError)
    };
  }
  return {
    [FORM_ERROR]: t(tk.validation.timeoutError)
  };
};

export const LoginPage: FC = () => {
  const classes = useLoginPageStyle();
  const { t } = useI18n();
  const dispatch = useAppDispatch();

  const { search } = useLocation();
  const localServerUrl = useSelector(localServerUrlSelector);
  const reactAdminLogin = useLogin();
  const [loading, setLoading] = useState(false);
  const [showWorkspaceSelector, setShowWorkspaceSelector] = useState(false);

  const searchParams = qs.parse(search, { ignoreQueryPrefix: true });
  const paramOrganizationId = String(searchParams.organization);

  const initialValues = useMemo<LoginProviderParams>(() => {
    return {
      password: "",
      username: "",
      server: Server.Production,
      organizationId: paramOrganizationId,
      localUrl: localServerUrl
    };
  }, [paramOrganizationId, localServerUrl]);

  return (
    <div className={classes.root}>
      <img
        className={classes.backgroundImg}
        src="https://storage.googleapis.com/nexup/static/Pantallas.png"
        alt={t(tk.backgroundAlt)}
      />
      <Card
        className={classes.loginCard}
        cardVariant={CardVariant.Vertical}
        primaryButtonText={t(tk.ingress)}
        formId={loginFormId}
        loading={loading}
      >
        <Form<LoginProviderParams>
          id={loginFormId}
          initialValues={initialValues}
          onSubmit={async ({
            username,
            password,
            server,
            organizationId,
            localUrl
          }) => {
            if (!username || !password || !server) return;
            setLoading(true);
            const result = await dispatch(
              login(username, password, server, organizationId, localUrl)
            );
            setLoading(false);
            if (result.result) {
              setShowWorkspaceSelector(true);
            } else {
              return loginResponseValidation(t, result.error ?? undefined);
            }
          }}
          validate={data => loginValidation(t, data)}
        >
          <LoginFormContent />
        </Form>
      </Card>
      {showWorkspaceSelector && (
        <WorkspaceSelectorPopUp
          isOpen={showWorkspaceSelector}
          onWorkspaceSelected={async workspace => {
            await dispatch(onSelectWorkspace(workspace));
            await reactAdminLogin({ alreadyLogged: true });
          }}
        />
      )}
    </div>
  );
};
