import {
  getKeyTargetParam,
  resourceProviderGetter
} from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import {
  createPractitionerRole,
  getModelReferenceId,
  getPractitionerRole,
  getPractitionerRoleList,
  PractitionerRoleListQueryParams,
  PractitionerRoleListQueryParamsKey,
  ResourceType,
  updatePractitionerRole
} from "@laba/nexup-api";
import { workspaceOrganizationSelector } from "store/workspace/selectors";
import { store } from "store/store";

export const practitionerRoleProvider = resourceProviderGetter(
  ResourceType.PractitionerRole,
  () => undefined,
  getKeyTargetParam<PractitionerRoleListQueryParams>(
    PractitionerRoleListQueryParamsKey
  ),
  getPractitionerRoleList,
  getPractitionerRole,
  updatePractitionerRole,
  createPractitionerRole,
  undefined,
  () => ({
    organization: getModelReferenceId(
      workspaceOrganizationSelector(store.getState())
    )
  })
);
