import { ModelId } from "model/model";

export interface PageInfo {
  pageSize: number;
  totalEntries: number;
  page: number;
  pageEntries: number;
}

export interface Page<T> {
  entries: T[];
  page: PageInfo;
}

export interface PageDownload {
  id?: number;
  downloading: boolean;
}

export const defaultPageSize = 10;

export const defaultInitPageNumber = 1;

export const getOffsetFromListQueryParams = (
  page: number,
  pageSize: number
): number => {
  if (page === 0) return 0;
  return (page - 1) * pageSize;
};

export interface ListQueryParams {
  pageSize?: number;
  page?: number;
  id?: ModelId[];
  version?: number;
}
