import {
  getClassName,
  RadiusVariant,
  ReactElement,
  StyleVariant,
  TextVariant
} from "@laba/react-common";
import React from "react";
import { SwitchButtonInputStyles } from "components/inputs/SwitchButtonInput/SwitchButtonInputStyles";

export interface SwitchButtonValue<V> {
  text: string;
  value: V;
}

export interface SwitchButtonInputProps<V> {
  className?: string;
  style?: StyleVariant;
  value?: V;
  firstOption: SwitchButtonValue<V>;
  secondOption: SwitchButtonValue<V>;
  onChange: (option: V) => void;
  fullWidth?: boolean;
  radius?: RadiusVariant;
  textVariant?: TextVariant;
}

export const SwitchButtonInput = <V,>({
  className,
  onChange,
  value,
  firstOption,
  secondOption,
  fullWidth = false,
  style = StyleVariant.Primary,
  radius,
  textVariant
}: SwitchButtonInputProps<V>): ReactElement => {
  const classes = SwitchButtonInputStyles({
    style,
    fullWidth,
    radius,
    textVariant
  });
  return (
    <div className={getClassName(classes.root, className)}>
      <button
        type="button"
        className={getClassName(
          classes.button,
          classes.firstButton,
          value === firstOption.value ? classes.active : classes.inactive
        )}
        onClick={() => {
          value !== firstOption.value && onChange(firstOption.value);
        }}
      >
        <p className={classes.text}>{firstOption.text}</p>
      </button>
      <button
        type="button"
        className={getClassName(
          classes.button,
          classes.secondButton,
          value === secondOption.value ? classes.active : classes.inactive
        )}
        onClick={() => {
          value !== secondOption.value && onChange(secondOption.value);
        }}
      >
        <p className={classes.text}>{secondOption.text}</p>
      </button>
    </div>
  );
};
