import { tkCP } from "translation/i18n";
import {
  CodeSystem,
  CodeSystemConceptPropertyKey,
  CodeSystemKey,
  CodeSystemSystem,
  ConceptProperty,
  getModelReferenceId,
  KnownCodeSystemSystem,
  KnownConceptPropertyUse,
  KnownSpecialEffectPropertyCode,
  ModelReference,
  Organization,
  ResourceType
} from "@laba/nexup-api";
import { FC, useI18n } from "@laba/react-common";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import React from "react";
import { TextInput } from "components/generic/TextInput/TextInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { FormDataConsumer } from "components/generic/FormDataConsumer/FormDataConsumer";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { isEmpty } from "lodash-es";

export const tk = tkCP.adminPage[ResourceType.CodeSystem];
export const CodeSystemPropertyCodeInput: FC = props => {
  const { t } = useI18n();

  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organization = useWatchResourceForm<ModelReference<Organization>>(
    CodeSystemKey.organization
  );
  const system = useWatchResourceForm<CodeSystemSystem>(CodeSystemKey.system);
  const organizationId =
    getModelReferenceId(organization) || workspaceOrganizationId;
  const permissionCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.CodeSystemPermissionProperty,
    organizationId
  );
  const appConfigCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.CodeSystemAppConfigProperty,
    organizationId
  );
  const directoryTypeCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.LocationDirectoryType,
    organizationId
  );
  const procedureReportCategoryCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.ProcedureReportCategory,
    organizationId
  );
  const procedureReportStatusCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.ProcedureReportStatus,
    organizationId
  );
  const roleCodeSystem = useCodeSystemGroup(
    system ?? KnownCodeSystemSystem.PractitionerRole,
    organizationId
  );
  const planCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.NexupClientPlan,
    organizationId
  );

  return (
    <FormDataConsumer<CodeSystem, ConceptProperty> {...props}>
      {({ formData, scopedFormData, getSource }) => {
        switch (scopedFormData?.use) {
          case KnownConceptPropertyUse.ProcedureReportStatus:
            return (
              <CodeSystemSelectorInput
                source={getSource(CodeSystemConceptPropertyKey.code)}
                label={t(tk.fields.conceptPropertyValue)}
                codeSystem={procedureReportStatusCodeSystem}
                withCreate
                organizationId={organizationId}
              />
            );
          case KnownConceptPropertyUse.ProcedureCategory:
            return (
              <CodeSystemSelectorInput
                source={getSource(CodeSystemConceptPropertyKey.code)}
                label={t(tk.fields.conceptPropertyValue)}
                codeSystem={procedureReportCategoryCodeSystem}
                withCreate
                organizationId={organizationId}
              />
            );
          case KnownConceptPropertyUse.Permission:
            return (
              <CodeSystemSelectorInput
                source={getSource(CodeSystemConceptPropertyKey.code)}
                label={t(tk.fields.conceptPropertyValue)}
                codeSystem={permissionCodeSystem}
                withCreate
                organizationId={organizationId}
              />
            );
          case KnownConceptPropertyUse.AppConfig:
            return (
              <CodeSystemSelectorInput
                source={getSource(CodeSystemConceptPropertyKey.code)}
                label={t(tk.fields.conceptPropertyValue)}
                codeSystem={appConfigCodeSystem}
                withCreate
                organizationId={organizationId}
              />
            );
          case KnownConceptPropertyUse.SpecialEffect:
            return (
              <EnumSelectorInput
                source={getSource(CodeSystemConceptPropertyKey.code)}
                label={t(tk.fields.conceptPropertyValue)}
                enumValue={KnownSpecialEffectPropertyCode}
                withCreate
              />
            );
          case KnownConceptPropertyUse.ParentRole:
            return (
              <CodeSystemSelectorInput
                source={getSource(CodeSystemConceptPropertyKey.code)}
                label={t(tk.fields.conceptPropertyValue)}
                codeSystem={formData}
                organizationId={organizationId}
                extraOptions={roleCodeSystem?.concept?.map(value => ({
                  id: value.code,
                  name: isEmpty(value.display)
                    ? value.code
                    : `${value.code} (${value.display})`
                }))}
              />
            );
          case KnownConceptPropertyUse.DirectoryType:
            return (
              <CodeSystemSelectorInput
                source={getSource(CodeSystemConceptPropertyKey.code)}
                label={t(tk.fields.conceptPropertyValue)}
                codeSystem={directoryTypeCodeSystem}
                withCreate
                organizationId={organizationId}
              />
            );
          case KnownConceptPropertyUse.AdminPanelAppConfig:
            return (
              <CodeSystemSelectorInput
                source={getSource(CodeSystemConceptPropertyKey.code)}
                label={t(tk.fields.conceptPropertyValue)}
                codeSystem={appConfigCodeSystem}
                withCreate
                organizationId={organizationId}
              />
            );
          case KnownConceptPropertyUse.RolPlan:
            return (
              <CodeSystemSelectorInput
                source={getSource(CodeSystemConceptPropertyKey.code)}
                label={t(tk.fields.conceptPropertyValue)}
                codeSystem={planCodeSystem}
                withCreate
                organizationId={organizationId}
              />
            );
          default:
            return (
              <TextInput
                source={getSource(CodeSystemConceptPropertyKey.code)}
                label={t(tk.fields.conceptPropertyValue)}
              />
            );
        }
      }}
    </FormDataConsumer>
  );
};
