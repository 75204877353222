import { OpenCode } from "model/primitives/model/model";

export enum KnownRouteOption {
  Intravenous = "endovenosa",
  Gastrostomy = "gastrostomía",
  Inhalation = "inhalatoria",
  Intramuscular = "intramuscular",
  Oral = "oral",
  NasogastricTube = "sonda nasogástrica",
  Sublingual = "sublingual",
  Otic = "ótica",
  Nasal = "nasal",
  Topical = "tópica",
  Rectal = "rectal",
  Subcutaneous = "subcutánea",
  Vaginal = "vaginal",
  Ophthalmic = "oftálmica",
  Jejunostomy = "yeyunostomía",
  Transdermal = "transdérmico"
}

export type RouteOption = OpenCode<KnownRouteOption>;
