import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { ModelId } from "model/primitives/model/model";

import { getFrontApiUrl, request } from "request/nexupRequest";
import { getKeyObj, HttpMethod, KeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import {
  PractitionerRole,
  PractitionerRoleCodes
} from "model/resource/person/practitionerRole";

export interface PractitionerRoleListQueryParams extends ListQueryParams {
  organization?: ModelId;
  practitioner?: ModelId;
  role?: PractitionerRoleCodes;
  active?: boolean;
}

export const PractitionerRoleListQueryParamsKey: KeyObj<PractitionerRoleListQueryParams> =
  getKeyObj(createHydratedMock<PractitionerRoleListQueryParams>());

const practitionerRoleBasePath = "/practitioner-role";

export const getPractitionerRoleList = (
  params: PractitionerRoleListQueryParams
): Promise<ApiPageRequestResponse<PractitionerRole>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(practitionerRoleBasePath),
    params
  });

export const getPractitionerRole = (
  id: ModelId
): Promise<ApiRequestResponse<PractitionerRole>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${practitionerRoleBasePath}/${id}`)
  });

export const createPractitionerRole = (
  data: PractitionerRole
): Promise<ApiRequestResponse<PractitionerRole>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(practitionerRoleBasePath),
    data
  });

export const updatePractitionerRole = (
  data: PractitionerRole
): Promise<ApiRequestResponse<PractitionerRole>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${practitionerRoleBasePath}/${data.id}`),
    data
  });
