export const schedule = {
  title: "Agenda",
  fields: {
    id: "Id",
    name: "Nombre",
    location: "Ubicacion",
    type: "Tipo",
    speciality: "Especialidad",
    performer: "Participante",
    planningHorizonStartDate: "Fecha de inicio",
    planningHorizonEndDate: "Fecha de finalizacion",
    organization: "Organización",
    availableTimeStartDate: "Fecha de inicio disponible",
    availableTimeEndDate: "Fecha de finalizacion disponible",
    practitioner: "Profesional",
    originalPractitioner: "Profesional original",
    active: "Activo",
    daysOfWeek: "Dias de la semana",
    allDay: "Todo el dia",
    availableTime: "Tiempo disponible",
    slotDuration: "Duracion slot",
    confirmationWindowTitle: "Confirmación",
    cancellationWindowTitle: "Cancelación",
    confirmationWindowStart: "Inicio ventana de confirmación",
    confirmationWindowEnd: "Fin ventana de confirmación",
    cancellationWindowStart: "Inicio ventana de cancelación",
    cancellationWindowEnd: "Fin ventana de cancelación",
    patientScheduleNotificationConfigInput: "Paciente",
    practitionerScheduleNotificationConfigInput: "Profesional",
    patientInstruction: "Instrucciones para el paciente",

    onlineAppointmentBookingConfigurationScheduleName: "Nombre agenda",
    onlineAppointmentBookingConfigurationScheduleEnabled: "Habilitado",
    onlineAppointmentBookingConfigurationScheduleBookingWindow:
      "Ventana (Días en el futuro)",
    onlineAppointmentBookingConfigurationSchedulePhoto: "Imagen"
  },
  tabs: {
    schedule: "Agendas",
    scheduleOnlineBooking: "Turnos online",
    scheduleWindows: "Ventanas",
    scheduleNotificationConfig: "Notificaciones"
  },
  filters: {
    organization: "Organizacion",
    practitioner: "Profesional",
    speciality: "Especialidad",
    active: "Activa"
  }
};
