import { Color } from "@laba/react-common";

export enum NexupColor {
  Black = "#4F4F4F",
  FullBlack = "#000000",
  DarkGray = "#686868",
  Gray = "#878787",
  MediumGray = "#BEBFBE",
  LightGray = "#E1E2E1",
  Background = "#EFF2F9",
  White = "#FFFFFF",
  SuperDarkPurple = "#262559",
  DarkPurple = "#5260AD",
  MainPurple = "#7487F2",
  LightPurple = "#A9B7FF",
  DarkBlue = "#165AB5",
  Blue = "#3D81DB",
  MediumBlue = "#6FB5EB",
  LightBlue = "#C0DEF5",
  DarkGreen = "#1A865F",
  Green = "#4CB383",
  MediumGreen = "#64D6A5",
  LightGreen = "#90F6C7",
  DarkSalmon = "#FE4E4E",
  Salmon = "#FB7878",
  MediumSalmon = "#FE9696",
  LightSalmon = "#FEA5A1",
  DarkOrange = "#C67C00",
  Orange = "#FFAB04",
  MediumOrange = "#FFDE04",
  LightOrange = "#FFDD4C",
  Transparent = "transparent",
  LightHeather = "#C3C4E2",
  Heather = "#8C8FBE",
  DarkHeather = "#6E7298",
  Fuchsia = "#F178B6",
  DarkFuchsia = "#EE3F99",
  Lilac = "#A26FE3"
}

const NexupUserAllowedColor: Partial<Record<NexupColor, Color>> = {
  [NexupColor.MediumGreen]: NexupColor.MediumGreen,
  [NexupColor.MediumBlue]: NexupColor.MediumBlue,
  [NexupColor.Orange]: NexupColor.Orange,
  [NexupColor.MediumSalmon]: NexupColor.MediumSalmon,
  [NexupColor.DarkSalmon]: NexupColor.DarkSalmon,
  [NexupColor.DarkHeather]: NexupColor.DarkHeather,
  [NexupColor.Green]: NexupColor.Green,
  [NexupColor.Heather]: NexupColor.Heather,
  [NexupColor.Fuchsia]: NexupColor.Fuchsia
};

export const NexupColors: NexupColor[] = Object.values(NexupColor);

export const NexupAllowedColors: NexupColor[] = NexupColors.filter(
  c => NexupUserAllowedColor[c]
);
