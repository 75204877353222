import { ReactElement } from "@laba/react-common";
import React from "react";
import {
  TextInputProps as ReactAdminTextInputProps,
  TextInput as ReactAdminTextInput,
  required as requiredValidate
} from "react-admin";
import { getAsArray, notNull } from "@laba/ts-common";

export interface TextInputProps extends ReactAdminTextInputProps {
  required?: boolean;
}
export const TextInput = ({
  validate,
  required,
  ...props
}: TextInputProps): ReactElement => {
  const validateArray = getAsArray(validate).filter(notNull);
  if (required) validateArray.push(requiredValidate());
  return (
    <ReactAdminTextInput
      fullWidth
      variant="outlined"
      validate={validateArray}
      {...props}
    />
  );
};
