import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  KnownCodeSystemSystem,
  OrganizationCombinedType,
  OrganizationKey,
  OrganizationListQueryParamsKey,
  ProductKey,
  ProductListQueryParamsKey,
  PublicationStatus,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { TextField } from "components/generic/TextField/TextField";
import { ReferenceResourceField } from "components/generic/ReferenceResourceField/ReferenceResourceField";
import { List } from "components/generic/List/List";
import { Datagrid } from "components/generic/Datagrid/Datagrid";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useListExportedFileName } from "components/hook/useExportedFileName";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";
import { productJsonToModel } from "./ProductForm";

const tk = tkCP.adminPage[ResourceType.Product];

export const ProductList: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const categorySystem = useCodeSystemGroup(
    KnownCodeSystemSystem.ProductCategory,
    workspaceOrganizationId
  );
  const subCategorySystem = useCodeSystemGroup(
    KnownCodeSystemSystem.ProductSubcategory,
    workspaceOrganizationId
  );
  const exportedFileName = useListExportedFileName(t(tk.title));

  return (
    <List
      resource={ResourceType.Product}
      exportedFileName={exportedFileName}
      jsonToModel={productJsonToModel}
      extraFilters={[
        workspaceOrganizationId === undefined ? (
          <OrganizationReferenceInput
            label={t(tk.filters.organization)}
            source={ProductListQueryParamsKey.organization}
            alwaysOn
            type={OrganizationCombinedType.ProviderAndGroup}
            extraFilters={{
              [OrganizationListQueryParamsKey.active]: true
            }}
          />
        ) : undefined,
        <EnumSelectorInput
          source={ProductListQueryParamsKey.status}
          label={t(tk.filters.status)}
          enumValue={PublicationStatus}
          alwaysOn
          resettable
        />,
        <CodeSystemSelectorInput
          source={ProductListQueryParamsKey.category}
          label={t(tk.filters.category)}
          codeSystem={categorySystem}
          organizationId={workspaceOrganizationId}
          withCreate
          alwaysOn
          resettable
        />,
        <CodeSystemSelectorInput
          source={ProductListQueryParamsKey.subcategory}
          label={t(tk.filters.subcategory)}
          codeSystem={subCategorySystem}
          organizationId={workspaceOrganizationId}
          withCreate
          alwaysOn
          resettable
        />
      ]}
    >
      <Datagrid>
        <TextField source={ProductKey.id} label={t(tk.fields.id)} />
        <TextField source={ProductKey.title} label={t(tk.fields.title)} />
        <ReferenceResourceField
          resourceType={ResourceType.Organization}
          label={t(tk.fields.organization)}
          source={ProductKey.organization}
          optionTextKey={OrganizationKey.name}
        />
        <TextField source={ProductKey.status} label={t(tk.fields.status)} />
        <TextField source={ProductKey.category} label={t(tk.fields.category)} />
        <TextField
          source={ProductKey.subcategory}
          label={t(tk.fields.subcategory)}
        />
      </Datagrid>
    </List>
  );
};
