import { Dimension, getDimensionPropertyValue } from "model/style/dimension";
import { pickBy } from "lodash-es";
import { notUndefined, Optional } from "model/types";

export enum BoxSidePropertyConfigKey {
  top = "top",
  right = "right",
  bottom = "bottom",
  left = "left",
  leftRight = "leftRight",
  topBottom = "topBottom",
  all = "all"
}

export interface BoxSidePropertyConfig {
  top?: Dimension;
  right?: Dimension;
  bottom?: Dimension;
  left?: Dimension;
  leftRight?: Dimension;
  topBottom?: Dimension;
  all?: Dimension;
}
export enum BoxSidePropertyKey {
  Top = "Top",
  Right = "Right",
  Bottom = "Bottom",
  Left = "Left"
}

export const getBoxPropertyMixinBuilder =
  <T extends string>(boxPropertyConfig: Record<BoxSidePropertyKey, T>) =>
  (config: BoxSidePropertyConfig): Partial<Record<T, string>> => {
    const {
      [BoxSidePropertyKey.Top]: topKey,
      [BoxSidePropertyKey.Right]: rightKey,
      [BoxSidePropertyKey.Bottom]: bottomKey,
      [BoxSidePropertyKey.Left]: leftKey
    } = boxPropertyConfig;
    const result = {
      [topKey]: getDimensionPropertyValue(
        config.top ?? config.topBottom ?? config.all
      ),
      [rightKey]: getDimensionPropertyValue(
        config.right ?? config.leftRight ?? config.all
      ),
      [bottomKey]: getDimensionPropertyValue(
        config.bottom ?? config.topBottom ?? config.all
      ),
      [leftKey]: getDimensionPropertyValue(
        config.left ?? config.leftRight ?? config.all
      ),
      "box-sizing": "border-box"
    };

    return pickBy(result, notUndefined) as Record<T, Optional<string>>;
  };
