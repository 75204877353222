import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  Condition,
  ConditionClinical,
  DiagnosisContainer,
  DiagnosisContainerKey,
  getModelOrUndefined,
  getModelReferenceId,
  Hospitalization,
  HospitalizationKey,
  KnownCodeSystemSystem,
  KnownDiagnosisRole,
  ModelReference,
  Organization,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { Optional } from "@laba/ts-common";
import { FormDataConsumer } from "components/generic/FormDataConsumer/FormDataConsumer";
import produce from "immer";

export const tk = tkCP.adminPage[ResourceType.Hospitalization];
export const HospitalizationDiagnosisContent: FC = () => {
  const { t } = useI18n();
  const hospitalizationOrganization = useWatchResourceForm<
    ModelReference<Organization>
  >(HospitalizationKey.organization);
  const conditionSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.ConditionList,
    hospitalizationOrganization
  );
  return (
    <ArrayInput
      label={t(tk.fields.diagnosisList)}
      source={HospitalizationKey.diagnosis}
    >
      <EnumSelectorInput
        source={DiagnosisContainerKey.use}
        label={t(tk.fields.diagnosisListUse)}
        enumValue={KnownDiagnosisRole}
        withCreate
      />
      <FormDataConsumer<Hospitalization, DiagnosisContainer>>
        {({ scopedFormData, getSource }) => {
          return (
            <CodeSystemSelectorInput
              source={getSource(DiagnosisContainerKey.condition)}
              label={t(tk.fields.diagnosisListCondition)}
              codeSystem={conditionSystem}
              organizationId={getModelReferenceId(hospitalizationOrganization)}
              withCreate
              format={(formValue?: Condition) => {
                return formValue?.code?.code;
              }}
              parse={(selectValue?: string): Optional<Condition> => {
                const currentCondition = getModelOrUndefined(
                  scopedFormData?.condition
                );
                const defaultCondition: Condition = {
                  resourceType: ResourceType.Condition,
                  clinicalStatus: ConditionClinical.Active,
                  category: "patient-problem"
                };
                const concept = conditionSystem?.concept?.find(
                  conceptElement => conceptElement.code === selectValue
                );
                if (concept) {
                  return produce(
                    currentCondition ?? defaultCondition,
                    draft => {
                      draft.code = {
                        code: concept.code,
                        name: concept.display,
                        system: conditionSystem?.system
                      };
                    }
                  );
                }
                return undefined;
              }}
            />
          );
        }}
      </FormDataConsumer>
    </ArrayInput>
  );
};
