import {
  borderMixinCircular,
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  FlexJustifyContent,
  getClassName,
  paddingMixin,
  StyleVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor } from "model/themeUtils";

export interface FilePreviewArrowIconClassNames {
  root: string;
  icon: string;
}
export interface FilePreviewArrowIconStylesProps {
  isMobile?: boolean;
}

const useFilePreviewArrowIconStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...borderMixinCircular()
    },
    rootDesktop: {
      ...flexColumnMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.Center
      }),
      ...paddingMixin({ leftRight: 8 })
    },
    rootMobile: {
      ...flexColumnMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.Center
      }),
      ...paddingMixin({ leftRight: 8 }),
      opacity: "60%"
    },
    iconDesktop: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      ),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    },
    iconMobile: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      ),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayMedium
      )
    }
  }),
  "FilePreviewArrowIcon"
);

export const useFilePreviewArrowIconClassNames = (
  props: FilePreviewArrowIconStylesProps
): FilePreviewArrowIconClassNames => {
  const classes = useFilePreviewArrowIconStyles();
  const { isMobile } = props;

  return {
    root: getClassName(
      classes.root,
      isMobile ? classes.rootMobile : classes.rootDesktop
    ),
    icon: isMobile ? classes.iconMobile : classes.iconDesktop
  };
};
