import { FC, useI18n } from "@laba/react-common";
import { useAppDispatch } from "store/store";
import React, { useCallback, useState } from "react";
import {
  AutocompleteOptionConfig,
  DefaultButton,
  Popup,
  SearchableListSimpleInput
} from "@laba/nexup-components";
import { Grid, Typography } from "@mui/material";
import { tkCP } from "translation/i18n";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { KnownCodeSystemSystem } from "@laba/nexup-api";
import { useSelector } from "react-redux";
import { defaultWorkspaceOrganizationIdSelector } from "store/workspace/selectors";
import { onMigrateRol } from "store/dashboard/event";

const tk = tkCP.adminPage.dashboard.rolUpdate;
const rolOptionFromValue = (
  value: string
): AutocompleteOptionConfig<string> => {
  return {
    value,
    text: value,
    title: value
  };
};

export const RolUpdateInput: FC = () => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const [showRolUpdate, setShowRolUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  const [toReplaceRolList, setToReplaceRolList] = useState<string[]>([]);
  const [newRol, setNewRol] = useState<string[]>([]);

  const onClick = useCallback(() => setShowRolUpdate(true), [setShowRolUpdate]);
  const onClose = useCallback(
    () => setShowRolUpdate(false),
    [setShowRolUpdate]
  );
  const baseOrganizationId = useSelector(
    defaultWorkspaceOrganizationIdSelector
  );
  const rolListSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.PractitionerRole,
    baseOrganizationId
  );
  const rolListOptions =
    rolListSystem?.concept?.map(value => {
      return value.code;
    }) ?? [];

  const onMigrateClicked = useCallback(async () => {
    setLoading(true);
    const rol = newRol[0];
    if (rol) {
      await dispatch(onMigrateRol(toReplaceRolList, rol));
    }
    setLoading(false);
    onClose();
  }, [dispatch, setLoading, toReplaceRolList, newRol, onClose]);

  return (
    <>
      <DefaultButton
        title={t(tk.openButton)}
        text={t(tk.openButton)}
        onClick={onClick}
      />
      {showRolUpdate && (
        <Popup
          open={showRolUpdate}
          closableHeader
          headerTitle={t(tk.title)}
          loading={loading}
          onClose={onClose}
          disableBackdropClick
        >
          <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid item sm={12}>
              <Typography title={t(tk.toModifyRol)}>
                {t(tk.toModifyRol)}
              </Typography>
              <SearchableListSimpleInput
                valueList={toReplaceRolList}
                setOptionList={setToReplaceRolList}
                optionList={rolListOptions}
                getOptionFromValue={rolOptionFromValue}
                noOptionsText={t(tk.noOptionsText)}
                fullWidth
              />
            </Grid>
            <Grid item sm={12}>
              <Typography title={t(tk.newRol)}>{t(tk.newRol)}</Typography>
              <SearchableListSimpleInput
                valueList={newRol}
                setOptionList={setNewRol}
                optionList={rolListOptions}
                getOptionFromValue={rolOptionFromValue}
                noOptionsText={t(tk.noOptionsText)}
                maxOptionsLimit={1}
                errorText={newRol.length !== 1 ? t(tk.needNewRol) : undefined}
                fullWidth
              />
            </Grid>
            <Grid item sm={12}>
              <DefaultButton
                title={t(tk.migrate)}
                text={t(tk.migrate)}
                onClick={onMigrateClicked}
              />
            </Grid>
          </Grid>
        </Popup>
      )}
    </>
  );
};
