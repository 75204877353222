import { StyleVariant } from "@laba/ts-common";

export type ErrorContext = Record<string, unknown>;

export interface GenericError {
  message?: string;
  exception?: Error;
  context?: ErrorContext;
  isFatalError?: boolean;
}

export enum AlertTypes {
  Error = "error",
  Info = "info",
  Warning = "warning",
  Success = "success"
}

export interface GenericAlert {
  type: AlertTypes;
  message?: string;
}

export type SnackbarStyleVariant =
  | StyleVariant.Info
  | StyleVariant.Success
  | StyleVariant.Warning
  | StyleVariant.Danger;

export const AlertTypesToSnackbarStyleVariant: Record<
  AlertTypes,
  SnackbarStyleVariant
> = {
  [AlertTypes.Error]: StyleVariant.Danger,
  [AlertTypes.Info]: StyleVariant.Info,
  [AlertTypes.Success]: StyleVariant.Success,
  [AlertTypes.Warning]: StyleVariant.Warning
};
