import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  getModelReferenceId,
  KnownCodeSystemSystem,
  MeasurementConfig,
  MeasurementGroupDefinition,
  MeasurementGroupDefinitionKey,
  ModelReference,
  Organization,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { MeasurementGroupDefinitionMeasurementFields } from "components/pages/NexupAdmin/resources/measurementGroupDefinition/MeasurementGroupDefinitionMeasurementFields";
import { FormDataConsumer } from "components/generic/FormDataConsumer/FormDataConsumer";

export const tk = tkCP.adminPage[ResourceType.MeasurementGroupDefinition];
export const MeasurementGroupDefinitionMeasurementListCompleteContent: FC =
  () => {
    const { t } = useI18n();
    const organization = useWatchResourceForm<ModelReference<Organization>>(
      MeasurementGroupDefinitionKey.organization
    );
    const workspaceOrganizationId = useSelector(
      workspaceOrganizationIdSelector
    );
    const organizationId =
      getModelReferenceId(organization) || workspaceOrganizationId;
    const measurementTypeSystem = useCodeSystemGroup(
      KnownCodeSystemSystem.ObservationCode,
      organizationId
    );
    const unitSystem = useCodeSystemGroup(
      KnownCodeSystemSystem.MedicationUnit,
      organizationId
    );
    return (
      <ArrayInput
        source={MeasurementGroupDefinitionKey.measurements}
        label={t(tk.fields.measurements)}
      >
        <FormDataConsumer<MeasurementGroupDefinition, MeasurementConfig>>
          {({ getSource: getMeasurementSource }) => {
            return (
              <MeasurementGroupDefinitionMeasurementFields
                unitSystem={unitSystem}
                measurementTypeSystem={measurementTypeSystem}
                getSource={getMeasurementSource}
                withProps
                withLinkId
              />
            );
          }}
        </FormDataConsumer>
      </ArrayInput>
    );
  };
