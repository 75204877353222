import { useState, useMemo } from "react";
import {
  defaultLocationOptions,
  errorMapper,
  ErrorPosition,
  PositionResult,
  onWatchPosition,
  onClearWatchPosition
} from "hooks/location/helpers";
import { useEffectSelective } from "hooks/useEffectSelective";
import { Optional, Position } from "@laba/ts-common";

// you should not call useCurrentUserPosition and useUserPosition on the same
// rendered component or child rendered components at the same time
// https://bugs.chromium.org/p/chromium/issues/detail?id=586015

export const useRealTimeDevicePosition = (
  options?: PositionOptions
): PositionResult => {
  const [currentPosition, setCurrentPosition] = useState<Optional<Position>>();
  const [error, setError] = useState<Optional<ErrorPosition>>();

  const positionOptions = useMemo(
    () => ({
      ...defaultLocationOptions,
      ...options
    }),
    [options]
  );

  useEffectSelective(
    () => {
      const watchId = onWatchPosition(
        position => {
          setCurrentPosition({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
        },
        positionError => {
          setCurrentPosition(undefined);
          setError(errorMapper(positionError));
        },
        positionOptions
      );

      return () => onClearWatchPosition(watchId);
    },
    [],
    [positionOptions]
  );

  return { currentPosition, error };
};
