import React from "react";
import { FC } from "@laba/react-common";
import { Create } from "components/generic/Create/Create";
import {
  LocationForm,
  locationJsonToModel,
  locationTransform
} from "components/pages/NexupAdmin/resources/location/LocationForm";
import { ResourceType } from "@laba/nexup-api";

export const LocationCreate: FC = () => {
  return (
    <Create
      resourceTransform={locationTransform}
      resource={ResourceType.Location}
      jsonToModel={locationJsonToModel}
    >
      <LocationForm />
    </Create>
  );
};
