import React from "react";
import { FC, getClassName } from "@laba/react-common";
import { Noop } from "@laba/ts-common";
import { LocationCardHeader } from "components/card/LocationCard/LocationCardHeader/LocationCardHeader";
import { useLocationCardStyle } from "components/card/LocationCard/useLocationCardStyles";
import { LocationCardIndicators } from "components/card/LocationCard/LocationCardIndicators/LocationCardIndicators";
import { LocationCardButtons } from "components/card/LocationCard/LocationCardButtons/LocationCardButtons";
import { ButtonBase } from "components/buttons/ButtonBase/ButtonBase";
import { LocationCardAddressAndAvailability } from "./LocationCardAddressAndAvailability/LocationCardAddressAndAvailability";

export interface LocationCardProps {
  className?: string;
  name?: string;
  imageSource?: string;
  specialityList: string[];
  active: boolean;
  statusText: string;
  distance?: number;
  distanceUnit?: string;
  phone?: string;
  onPhoneClicked?: Noop;
  website?: string;
  onWebsiteClicked?: Noop;
  address?: string;
  onAddressClicked?: Noop;
  onComponentClicked?: Noop;
  aHref?: string;
  tag?: string;
  fullAddress?: string;
  availability?: string;
  schedule?: string[];
}

export const LocationCard: FC<LocationCardProps> = ({
  className,
  name,
  imageSource,
  active,
  statusText,
  distance,
  distanceUnit,
  specialityList = [],
  phone,
  onPhoneClicked,
  website,
  onWebsiteClicked,
  address,
  onAddressClicked,
  onComponentClicked,
  aHref,
  tag,
  fullAddress,
  availability,
  schedule
}) => {
  const classes = useLocationCardStyle();
  return (
    <ButtonBase
      className={getClassName(className, classes.root)}
      onClick={onComponentClicked}
      aHref={aHref}
    >
      <LocationCardHeader name={name} imageSource={imageSource} />
      <LocationCardIndicators
        specialityList={specialityList}
        distance={distance}
        distanceUnit={distanceUnit}
        tag={tag}
      />
      <LocationCardAddressAndAvailability
        fullAddress={fullAddress}
        availability={availability}
        schedule={schedule}
        active={active}
        statusText={statusText}
      />
      <LocationCardButtons
        className={classes.buttons}
        phone={phone}
        onPhoneClicked={onPhoneClicked}
        website={website}
        onWebsiteClicked={onWebsiteClicked}
        address={address}
        onAddressClicked={onAddressClicked}
      />
    </ButtonBase>
  );
};
