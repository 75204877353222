import { ReactElement } from "@laba/react-common";
import React from "react";
import { TagButton, TagTypeVariant } from "components/tags";
import { Noop } from "@laba/ts-common";
import {
  AutocompleteOption,
  AutocompleteOptionProps
} from "../AutocompleteOption/AutocompleteOption";

export interface AutocompleteOptionTagButtonProps<T>
  extends AutocompleteOptionProps<T> {
  placeholder?: string;
  tagButtonText?: string;
  onClickTagButton?: Noop;
  tagButtonTypeVariant?: TagTypeVariant;
  isDisabledTagButton?: boolean;
}

export const AutocompleteOptionTagButton = <T,>({
  option,
  disabled,
  onRemoveSelectedOption,
  fullWidth,
  hasBackgroundColor,
  isSelectedOption,
  removeIconStyle,
  isMobile,
  actionControlComponent,
  tagButtonText,
  onClickTagButton,
  tagButtonTypeVariant,
  isDisabledTagButton,
  withPadding = true
}: AutocompleteOptionTagButtonProps<T>): ReactElement => {
  return (
    <AutocompleteOption
      option={option}
      disabled={disabled}
      fullWidth={fullWidth}
      hasBackgroundColor={hasBackgroundColor}
      onRemoveSelectedOption={onRemoveSelectedOption}
      isSelectedOption={isSelectedOption}
      removeIconStyle={removeIconStyle}
      isMobile={isMobile}
      actionControlComponent={actionControlComponent}
      withPadding={withPadding}
    >
      <TagButton
        text={tagButtonText}
        onClick={onClickTagButton}
        type={tagButtonTypeVariant}
        disabled={isDisabledTagButton}
      />
    </AutocompleteOption>
  );
};
