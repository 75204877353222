import { FC } from "@laba/react-common";
import React, { useState } from "react";
import { Prompt as ReactRouterPrompt, useLocation } from "react-router-dom";

export interface PromptProps {
  message: string;
  when: boolean;
  strictLocationCompare?: boolean;
}

export const Prompt: FC<PromptProps> = ({
  message,
  when,
  strictLocationCompare = false
}) => {
  const [currentPathname] = useState(useLocation().pathname);
  return (
    <ReactRouterPrompt
      message={goToLocation =>
        (strictLocationCompare && currentPathname === goToLocation.pathname) ||
        message
      }
      when={when}
    />
  );
};
