import { HttpMethod, ModelId } from "@laba/ts-common";
import { ApiRequestResponse } from "request/types";
import { getFrontApiUrl, requestMultipart } from "request/nexupRequest";

export enum ImportFormDataKey {
  File = "file",
  Organization = "organization"
}

export const commonFileUploadRequestHandler = <T = unknown>(
  basePath: string,
  organization: ModelId,
  file: File
): Promise<ApiRequestResponse<T>> => {
  const data = new FormData();
  data.append(ImportFormDataKey.File, file);
  data.append(ImportFormDataKey.Organization, organization);
  return requestMultipart({
    method: HttpMethod.POST,
    url: getFrontApiUrl(`${basePath}/import`),
    data
  });
};
