export interface ScreenPosition {
  x: number;
  y: number;
}

export const positionGreaterThanMinimumDistance = (
  initialPosition: ScreenPosition,
  currentPosition: ScreenPosition,
  distance: number
): boolean => {
  return (
    Math.abs(initialPosition.x - currentPosition.x) > distance ||
    Math.abs(initialPosition.y - currentPosition.y) > distance
  );
};
