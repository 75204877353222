export const account = {
  title: "Cuentas",
  filters: {
    organization: "Organizacion",
    patient: "Paciente",
    payer: "Financiador",
    status: "Estado",
    type: "Tipo"
  },
  fields: {
    id: "Id",
    status: "Estado",
    organization: "Organizacion",
    identifier: "Identificadores",
    patient: "Paciente",
    payer: "Financiador",
    type: "Tipo",
    name: "Nombre"
  },
  tabs: {
    main: "Cuenta"
  }
};
