export const scheduleDefinition = {
  title: "Tipo de Agenda",
  filters: {
    organization: "Organizacion",
    status: "Estado"
  },
  tabs: {
    scheduleDefinition: "Definición de Agendas"
  },
  fields: {
    patientScheduleNotificationConfigInput: "Paciente",
    practitionerScheduleNotificationConfigInput: "Profesional"
  }
};
