import React from "react";
import { FCC } from "@laba/react-common";
import { FloatingIconButton } from "components/buttons/FAB/FloatingIconButton/FloatingIconButton";
import { PrintIcon } from "components/icons";
import { Noop } from "@laba/ts-common";
import { Popup } from "../Popup/Popup";

interface PDFReportDownloadPopupProps {
  handleDownload: Noop;
  isOpen: boolean;
  loading: boolean;
  onFloatingIconButtonClick?: Noop;
  onPopupClose?: Noop;
  printButtonIsDisabled: boolean;
  printButtonText: string;
  title: string;
  isMobile?: boolean;
}

export const PDFReportDownloadPopup: FCC<PDFReportDownloadPopupProps> = ({
  children,
  isOpen,
  handleDownload,
  loading,
  onFloatingIconButtonClick,
  onPopupClose,
  printButtonIsDisabled,
  printButtonText,
  title,
  isMobile
}) => {
  return (
    <>
      <FloatingIconButton
        Icon={PrintIcon}
        onClick={onFloatingIconButtonClick}
      />
      <Popup
        closableHeader
        disablePrimaryButton={printButtonIsDisabled}
        headerTitle={title}
        loading={loading}
        onClose={onPopupClose}
        onPrimaryButtonClick={handleDownload}
        open={isOpen}
        primaryButtonText={printButtonText}
        isMobile={isMobile}
      >
        {children}
      </Popup>
    </>
  );
};
