import { useCallback } from "react";
import { Noop } from "@laba/ts-common";

export const useConfirmExitDialog = (
  enableConfirm: boolean,
  when: boolean,
  message: string,
  onConfirm?: Noop
): Noop => {
  const confirmedCallback = useCallback(() => {
    if (when) {
      // eslint-disable-next-line no-alert
      const confirm = window.confirm(message);
      confirm && onConfirm?.();
    } else {
      onConfirm?.();
    }
  }, [when, onConfirm, message]);

  return useCallback(() => {
    return enableConfirm ? confirmedCallback() : onConfirm?.();
  }, [enableConfirm, confirmedCallback, onConfirm]);
};
