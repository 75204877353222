import { MouseEvent, MouseEventHandler, KeyboardEvent } from "react";
import { Optional } from "@laba/ts-common";

export enum KeyboardKeys {
  Enter = "Enter",
  Escape = "Escape"
}

export const stopPropagAndPrevDef = (e: KeyboardEvent | MouseEvent): void => {
  e.stopPropagation();
  e.preventDefault();
};

export const stopPropagationWrapper =
  (h: Optional<MouseEventHandler>): Optional<MouseEventHandler> =>
  e => {
    if (h === undefined) return;
    stopPropagAndPrevDef(e);
    h(e);
  };

export type OnBlurEvent<T = HTMLElement> = (e?: React.FocusEvent<T>) => void;
export type OnClickEvent<T = HTMLButtonElement> = MouseEventHandler<T>;
