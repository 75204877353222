// eslint-disable-next-line no-restricted-imports
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { GenericObject } from "@laba/ts-common";
import { getI18nTFromTFunction, I18nT } from "./i18nT";

export interface UseI18nResponse<
  TInterpolationMap extends GenericObject = GenericObject
> {
  t: I18nT<TInterpolationMap>;
}

export const useI18n = <
  TInterpolationMap extends GenericObject = GenericObject
>(): UseI18nResponse<TInterpolationMap> => {
  const { t: tFunction } = useTranslation();
  const t = useMemo<I18nT<TInterpolationMap>>(
    () => getI18nTFromTFunction(tFunction),
    [tFunction]
  );
  return {
    t
  };
};
