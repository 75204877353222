import React from "react";
import { FC, useI18n } from "@laba/react-common";
import { ProcedureCodeConcept } from "@laba/nexup-api";
import {
  cleanEmptyString,
  ResourceForm
} from "components/generic/ResourceForm/ResourceForm";
import { tkCP } from "translation/i18n";
import {
  getDefaultJsonToModel,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";
import { ProcedureCodeConceptContent } from "components/pages/NexupAdmin/resources/concept/procedureCodeConceptList/ProcedureCodeConceptContent";
import { ConceptResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { ResourceTransform } from "components/generic/Create/Create";

export const procedureCodeConceptTransform: ResourceTransform<
  ProcedureCodeConcept
> = resource => {
  return cleanEmptyString(resource);
};
export const ProcedureCodeConceptJsonToModel: JsonToModel<ProcedureCodeConcept> =
  getDefaultJsonToModel();

export const tk = tkCP.adminPage[ConceptResourceType.ProcedureCodeConcept];

export const ProcedureCodeConceptForm: FC = () => {
  const { t } = useI18n();
  return (
    <ResourceForm
      tabsConfig={[
        {
          label: t(tk.tabs.procedureCodeConcept),
          content: <ProcedureCodeConceptContent />
        }
      ]}
    />
  );
};
