import { Property } from "csstype";
import { Color } from "model/style/color";

export enum GradientType {
  Linear = "Linear",
  Radial = "Radial"
}
export interface GradientConfig {
  colorList: Color[];
  gradientType?: GradientType;
  linearDirection?: string;
  radialShape?: string;
  radialSize?: string;
  radialPosition?: string;
}

export interface GradientResult {
  "background-image"?: Property.BackgroundImage;
}

const linearGradient = (colorList: Color[], direction = "top to bottom") =>
  `linear-gradient(${direction},${colorList.join(",")})`;

const radialGradient = (
  colorList: Color[],
  shape = "ellipse",
  size = "farthest-corner",
  position = "center"
) => `radial-gradient(${shape} ${size} at ${position},${colorList.join(",")})`;

export const gradientMixin = (config: GradientConfig): GradientResult => {
  return {
    "background-image":
      config.gradientType === GradientType.Linear
        ? linearGradient(config.colorList, config.linearDirection)
        : radialGradient(
            config.colorList,
            config.radialShape,
            config.radialSize,
            config.radialPosition
          )
  };
};
