import {
  FC,
  FCC,
  getClassName,
  OnBlurEvent,
  ReactElement
} from "@laba/react-common";
import React from "react";
import { MenuOptionProps } from "components/menu/Menu/Menu";
import { TagInput } from "components/tags/TagInput/TagInput";
import { ClearIcon } from "components/icons";
import { isEmpty } from "lodash-es";
import { SelectInput } from "components/inputs/SelectInput/SelectInput";
import { MenuItemHeight } from "components/menu/MenuStyle";
import { TagListSelectOptionConfig } from "../utils";
import { useSelectTagListInputStyles } from "./useSelectTagListIntputStyles";

export interface SelectTagListInputProps<V> {
  className?: string;
  onChange?: (val?: V) => void;
  selectedOptions: TagListSelectOptionConfig<V>[];
  options?: TagListSelectOptionConfig<V>[];
  compareValues: (v1: V, v2: V) => boolean;
  openText: string;
  closeText: string;
  noOptionsText: string;
  onClickOnRemoveOption: (option: TagListSelectOptionConfig<V>) => void;
  placeholder?: string;
  errorText?: string;
  excludedValues?: V[];
  onBlur?: OnBlurEvent;
}

export const SelectTagListInput = <V,>({
  className,
  onChange,
  selectedOptions,
  options = [],
  compareValues,
  openText,
  closeText,
  noOptionsText,
  onClickOnRemoveOption,
  placeholder,
  errorText,
  excludedValues,
  onBlur
}: SelectTagListInputProps<V>): ReactElement => {
  const classes = useSelectTagListInputStyles();

  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomOption: FC<MenuOptionProps<V>> = ({ text, value, color }) => {
    return (
      <TagInput
        text={text}
        checked={selectedOptions.some(
          v => value && compareValues(v.value, value)
        )}
        backgroundColor={color}
      />
    );
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const ListBoxComponent: FCC = ({ children }) => {
    return <div className={classes.listBoxComponent}>{children}</div>;
  };

  return (
    <div className={getClassName(classes.root, className)}>
      <SelectInput<V>
        value={undefined}
        options={options}
        openText={openText}
        closeText={closeText}
        noOptionsText={noOptionsText}
        CustomOption={CustomOption}
        onChange={onChange}
        placeholder={placeholder}
        fullWidth
        errorText={errorText}
        excludedValues={excludedValues}
        onBlur={onBlur}
        optionsContainerClassName={classes.listBoxComponent}
        SelectListBoxComponent={ListBoxComponent}
        fullWidthItem={false}
        itemHeight={MenuItemHeight.Small}
        itemWithoutPadding
        keepOpenOnSelect
      />
      {!isEmpty(selectedOptions) && (
        <div className={classes.selectedOptionsContainer}>
          {selectedOptions.map(v => {
            return (
              <TagInput
                text={v.text}
                onClick={() => onClickOnRemoveOption(v)}
                uncheckedIcon={ClearIcon}
                backgroundColor={v.color}
                checked={false}
                key={v.text}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
