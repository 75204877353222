import React, { useState } from "react";
import { FC, saveJsonToDevice, useI18n } from "@laba/react-common";
import { Edit } from "components/generic/Edit/Edit";
import {
  OrganizationForm,
  organizationJsonToModel,
  organizationTransform
} from "components/pages/NexupAdmin/resources/organization/OrganizationForm";
import { Organization, ResourceType } from "@laba/nexup-api";
import { Button, useRecordContext } from "react-admin";
import { ArrowDownward, SpinnerDialog } from "@laba/nexup-components";
import { tkCP } from "translation/i18n";
import { useAppDispatch } from "store/store";
import { downloadOrganizationCompleteConfiguration } from "store/organization/event";
import { logger } from "@laba/ts-common";
import { useElementExportedFileName } from "components/hook/useExportedFileName";

export const tk = tkCP.adminPage[ResourceType.Organization];

const EditExtraButtons = () => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const organization = useRecordContext<Organization>();
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Button
        label={t(tk.exportCompleteOrganization)}
        onClick={async () => {
          setLoading(true);
          const organizationConfiguration = await dispatch(
            downloadOrganizationCompleteConfiguration(
              organization,
              {
                skipPractitionerWithExtraDataList: true,
                skipPatientList: true,
                skipScheduleList: true,
                skipMedicationList: true,
                skipMedicalDeviceList: true
              },
              error => {
                logger.error(error);
                // eslint-disable-next-line no-alert
                alert(t(tk.organizationConfigError));
              }
            )
          );
          if (organizationConfiguration) {
            saveJsonToDevice(
              organizationConfiguration,
              t(tk.organizationConfigFilename, {
                organization: organizationConfiguration.organization?.name
              })
            );
          }
          setLoading(false);
        }}
      >
        <ArrowDownward />
      </Button>
      <SpinnerDialog open={loading} />
    </>
  );
};

export const OrganizationEdit: FC = () => {
  const exportedFileName = useElementExportedFileName<Organization>(
    resource => resource.name
  );
  return (
    <Edit
      resourceTransform={organizationTransform}
      resource={ResourceType.Organization}
      jsonToModel={organizationJsonToModel}
      extraButtons={<EditExtraButtons />}
      exportedFileName={exportedFileName}
    >
      <OrganizationForm />
    </Edit>
  );
};
