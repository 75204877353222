import { ApiPageRequestResponse } from "request/types";
import { getFrontPublicApiUrl, requestAnon } from "request/nexupRequest";
import {
  CodeSystem,
  CodeSystemGroup
} from "model/resource/entities/codeSystem";
import { getAsArrayOrUndefined, HttpMethod } from "@laba/ts-common";
import {
  codeSystemBasePath,
  codeSystemGroupBasePath,
  CodeSystemGroupListQueryParams,
  CodeSystemListQueryParams
} from "api/entities/codeSystem";

export const getPublicCodeSystemList = (
  params?: CodeSystemListQueryParams
): Promise<ApiPageRequestResponse<CodeSystem>> =>
  requestAnon({
    method: HttpMethod.GET,
    url: getFrontPublicApiUrl(codeSystemBasePath),
    params
  });

export const getPublicCodeSystemGroupList = (
  params?: CodeSystemGroupListQueryParams
): Promise<ApiPageRequestResponse<CodeSystemGroup>> => {
  return requestAnon({
    method: HttpMethod.GET,
    url: getFrontPublicApiUrl(
      `${codeSystemBasePath}${codeSystemGroupBasePath}`
    ),
    params: {
      ...params,
      organization: getAsArrayOrUndefined(params?.organization)
    }
  });
};
