import { ResourceType } from "model/primitives";
import { PublicPractitionerData } from "../publicPractitioner";

export const publicPractitionerMock: PublicPractitionerData = {
  resourceType: ResourceType.PublicPractitioner,
  firstName: "Rodrigo",
  id: "1",
  lastName: "Ortiz de Zarate",
  organization: "10",
  otherName: "Agustin",
  profilePictureUrl:
    "https://development.nexup.com.ar/api/files/signed/366f3616c974425c833ff40e905e0048?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJuZXh1cCIsInJlc291cmNlX3R5cGUiOiJGaWxlIiwicmVzb3VyY2VfaWQiOiIzNjZmMzYxNmM5NzQ0MjVjODMzZmY0MGU5MDVlMDA0OCJ9.OCeuKO9iEbuDhsyDjxoahOeKa27hRDxL0W2AMK6q7ug"
};
