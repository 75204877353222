import { FC, useField, useI18n } from "@laba/react-common";
import React from "react";
import { tkCP } from "translation/i18n";
import { DefaultButton, RemoveIcon, SelectInput } from "@laba/nexup-components";
import { Grid } from "@mui/material";
import { Optional } from "@laba/ts-common";
import { isEmpty } from "lodash-es";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { KnownCodeSystemSystem } from "@laba/nexup-api";
import { WorkspaceSpecialityConfigData } from "models/organization/workspaceConfiguration";
import { produce } from "immer";

export interface WorkspaceAdministrationSpecialityFieldProps {
  idx: number;
  organizationId: Optional<string>;
  specialityListUpdate: (
    idx: number,
    value: WorkspaceSpecialityConfigData
  ) => void;
  specialityListRemove: (idx: number) => void;
}
const tk = tkCP.adminPage.dashboard.workspaceAdministration;

export const WorkspaceAdministrationSpecialityField: FC<
  WorkspaceAdministrationSpecialityFieldProps
> = ({ idx, organizationId, specialityListUpdate, specialityListRemove }) => {
  const { t } = useI18n();
  const {
    input: { value: speciality, onBlur },
    meta: { error, touched }
  } = useField<WorkspaceSpecialityConfigData, Optional<string>>(
    ["organizationSpeciality", idx].join("."),
    validateValue =>
      isEmpty(validateValue?.name) ? t(tk.validation.requiredField) : undefined
  );
  const specialitySystem = useCodeSystemGroup(
    KnownCodeSystemSystem.OrganizationSpeciality,
    organizationId
  );
  const specialityOptions =
    specialitySystem?.concept?.map(value => {
      const display = value.display || value.code || "";
      return {
        itemId: value.code,
        text: display,
        title: display,
        value: value.code
      };
    }) ?? [];
  if (
    speciality?.name &&
    !specialityOptions.find(element => element.value === speciality)
  ) {
    specialityOptions.push({
      itemId: speciality.name,
      text: speciality.name,
      title: speciality.name,
      value: speciality.name
    });
  }

  return speciality !== undefined ? (
    <>
      <Grid item sm={6} key={`location ${idx}`}>
        <SelectInput
          value={speciality.name}
          onChange={value =>
            specialityListUpdate(
              idx,
              produce(speciality, draft => {
                draft.name = value;
              })
            )
          }
          onBlur={onBlur}
          errorText={error}
          showError={touched ?? true}
          placeholder={t(tk.organizationSpecialityElement)}
          options={specialityOptions}
          fullWidth
        />
      </Grid>
      <Grid item sm={6} key={`remove ${idx}`}>
        <DefaultButton
          fullWidth
          text={t(tk.organizationSpecialityRemove)}
          StartIcon={RemoveIcon}
          onClick={() => {
            specialityListRemove(idx);
          }}
        />
      </Grid>
    </>
  ) : null;
};
