import { FC, ReactElement } from "@laba/react-common";
import { useEffect, useState, MutableRefObject } from "react";
import ReactDOM from "react-dom";

export interface Props {
  refContainer?: MutableRefObject<HTMLDivElement | null>;
  component?: ReactElement;
}

export const GetDOMComponent: FC<Props> = ({ refContainer, component }) => {
  const [element, setElement] = useState<HTMLDivElement>();

  useEffect(() => {
    if (refContainer !== undefined) {
      const newElement = document.createElement("div");
      newElement.style.minWidth = "0";
      // eslint-disable-next-line no-param-reassign
      refContainer.current = newElement;
      setElement(newElement);
    }
  }, [refContainer]);

  return element !== undefined &&
    refContainer !== undefined &&
    refContainer.current !== null
    ? ReactDOM.createPortal(component, refContainer.current)
    : null;
};
