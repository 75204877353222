import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { ModelId } from "model/primitives/model/model";
import {
  CodeSystem,
  CodeSystemGroup,
  CodeSystemStatus,
  CodeSystemSystem,
  Concept
} from "model/resource/entities/codeSystem";
import {
  ElementOrArray,
  getAsArrayOrUndefined,
  getKeyObj,
  HttpMethod,
  KeyObj
} from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";

export interface CodeSystemGroupListQueryParams extends ListQueryParams {
  organization?: ElementOrArray<ModelId>;
  system?: ElementOrArray<CodeSystemSystem>;
}

export interface CodeSystemListQueryParams
  extends CodeSystemGroupListQueryParams {
  id?: ModelId[];
  status?: CodeSystemStatus;
  public?: boolean;
}

export const CodeSystemListQueryParamsKey: KeyObj<CodeSystemListQueryParams> =
  getKeyObj(createHydratedMock<CodeSystemListQueryParams>());

export const CodeSystemGroupListQueryParamsKey: KeyObj<CodeSystemGroupListQueryParams> =
  getKeyObj(createHydratedMock<CodeSystemGroupListQueryParams>());

export const codeSystemBasePath = "/code-system";
export const codeSystemGroupBasePath = "/group";

export const getCodeSystemList = (
  params?: CodeSystemListQueryParams
): Promise<ApiPageRequestResponse<CodeSystem>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(codeSystemBasePath),
    params
  });

export const getCodeSystemGroupList = (
  params?: CodeSystemGroupListQueryParams
): Promise<ApiPageRequestResponse<CodeSystemGroup>> => {
  return request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${codeSystemBasePath}${codeSystemGroupBasePath}`),
    params: {
      ...params,
      organization: getAsArrayOrUndefined(params?.organization)
    }
  });
};

export const getCodeSystem = (
  id: ModelId
): Promise<ApiRequestResponse<CodeSystem>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${codeSystemBasePath}/${id}`)
  });

export const createCodeSystem = (
  data: CodeSystem
): Promise<ApiRequestResponse<CodeSystem>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(codeSystemBasePath),
    data
  });

export const updateCodeSystem = (
  data: CodeSystem
): Promise<ApiRequestResponse<CodeSystem>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${codeSystemBasePath}/${data.id}`),
    data
  });

export interface ConceptToCodeSystemData {
  system: CodeSystemSystem;
  concept?: Concept;
  organization: ModelId;
}

export const addConceptToCodeSystem = (
  data: ConceptToCodeSystemData
): Promise<ApiRequestResponse<CodeSystemGroup>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(
      `${codeSystemBasePath}${codeSystemGroupBasePath}/concept`
    ),
    data
  });

export const updateConceptCodeSystem = (
  data: ConceptToCodeSystemData
): Promise<ApiRequestResponse<CodeSystemGroup>> => {
  return request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(
      `${codeSystemBasePath}${codeSystemGroupBasePath}/concept`
    ),
    data
  });
};
export const ConceptToCodeSystemDataParamsKey =
  getKeyObj<ConceptToCodeSystemData>(
    createHydratedMock<ConceptToCodeSystemData>()
  );
