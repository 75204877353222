import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { ModelId } from "model/primitives/model/model";
import {
  QuestionnaireResponse,
  QuestionnaireResponseCard,
  QuestionnaireResponseField,
  QuestionnaireResponseStatus
} from "model/resource/evolution/questionnaireResponse/questionnaireResponse";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { getKeyObj, HttpMethod } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { HospitalizationType } from "api/medical/hospitalization";
import { QuestionnaireType } from "model/resource";

const questionnaireResponseBasePath = "/questionnaire-response";

export interface QuestionnaireResponseQueryParams {
  withPatient?: boolean;
  withPractitioner?: boolean;
  withHospitalization?: boolean;
  withQuestionnaire?: boolean;
  withDefinition?: boolean;
  withProblem?: boolean;
  withAllergyIntolerance?: boolean;
  withMeasurement?: boolean;
  withProcedureReport?: boolean;
  withMedication?: boolean;
  withMedicalDevice?: boolean;
  withEncounter?: boolean;
  withLocation?: boolean;
}

export interface QuestionnaireResponseListQueryParams
  extends ListQueryParams,
    QuestionnaireResponseQueryParams {
  questionnaire?: ModelId;
  content?: string;
  status?: QuestionnaireResponseStatus;
  author?: ModelId;
  patient?: ModelId;
  encounter?: ModelId;
  organization?: ModelId;
  category?: string;
  startDate?: string;
  hospitalizationType?: HospitalizationType;
  endDate?: string;
  type?: QuestionnaireType;
  featured?: boolean;
}

export const questionnaireResponseFormDataParamsKey = getKeyObj(
  createHydratedMock<QuestionnaireResponse>()
);

export const questionnaireResponseCardKey = getKeyObj(
  createHydratedMock<QuestionnaireResponseCard>()
);

export const questionnaireResponseFieldKey = getKeyObj(
  createHydratedMock<QuestionnaireResponseField>()
);

export const getQuestionnaireResponseList = (
  id: ModelId,
  params: QuestionnaireResponseListQueryParams
): Promise<ApiPageRequestResponse<QuestionnaireResponse>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(questionnaireResponseBasePath),
    params
  });

export const getQuestionnaireResponse = (
  id: ModelId,
  params: QuestionnaireResponseQueryParams
): Promise<ApiRequestResponse<QuestionnaireResponse>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${questionnaireResponseBasePath}/${id}`),
    params
  });

export const createQuestionnaireResponse = (
  data: QuestionnaireResponse
): Promise<ApiRequestResponse<QuestionnaireResponse>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(questionnaireResponseBasePath),
    data,
    useLongTimeout: false
  });

export const editQuestionnaireResponse = (
  data: QuestionnaireResponse
): Promise<ApiRequestResponse<QuestionnaireResponse>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${questionnaireResponseBasePath}/${data.id}`),
    data,
    useLongTimeout: false
  });

export const saveQuestionnaireResponse = (
  response: QuestionnaireResponse
): Promise<ApiRequestResponse<QuestionnaireResponse>> => {
  if (response.id) {
    return editQuestionnaireResponse(response);
  }
  return createQuestionnaireResponse(response);
};

export const cancelQuestionnaireResponse = (
  id: ModelId
): Promise<ApiRequestResponse<QuestionnaireResponse>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(`${questionnaireResponseBasePath}/${id}/cancel`)
  });
