export enum ProfilePictureSizeVariant {
  ExtraSmall = "ExtraSmall",
  Small = "Small",
  Medium = "Medium",
  Large = "Large",
  ExtraLarge = "ExtraLarge"
}

export const profilePictureSizeExtraLarge = 148;
export const profilePictureSizeLarge = 80;
export const profilePictureSizeMedium = 52;
export const profilePictureSizeSmall = 40;
export const profilePictureSizeExtraSmall = 24;
