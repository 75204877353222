import { getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { NotificationConfigActionCode } from "model/primitives/emailConfig";
import { Model } from "./model/model";

export interface PhoneConfig extends Model {
  actionCode?: NotificationConfigActionCode[];
  phoneId?: string;
  bodyTemplate?: string;
  waitDelay?: number;
  sessionInitTemplate?: string;
}

export const PhoneConfigKey = getKeyObj<PhoneConfig>(
  createHydratedMock<PhoneConfig>()
);
