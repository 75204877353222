import { Model } from "model/model";
import { ApiDate } from "date/apiDate";
import { getKeyObj } from "model/key";
import { createHydratedMock } from "ts-auto-mock";

export interface BaseVersionedModel extends Model {
  version?: number;
  lastUpdatedDate?: ApiDate;
  creationDate?: ApiDate;
  isLastVersion?: boolean;
}

export const BaseVersionedModelParamsKey = getKeyObj<BaseVersionedModel>(
  createHydratedMock<BaseVersionedModel>()
);
