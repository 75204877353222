import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { ModelId } from "model/primitives/model/model";
import {
  HealthcareService,
  ServiceCategory
} from "model/resource/entities/healthcareService/healthcareService";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { getKeyObj, HttpMethod, KeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";

interface HealthcareServiceQueryParams {
  withGroup?: boolean;
  withGroupPractitioner?: boolean;
  withLocation?: boolean;
  withOrganization?: boolean;
}

export interface HealthcareServiceListQueryParams
  extends ListQueryParams,
    HealthcareServiceQueryParams {
  id?: ModelId[];
  location?: ModelId;
  organization?: ModelId;
  practitioner?: ModelId;
  type?: ServiceCategory;
  active?: boolean;
}

export const HealthcareServiceListQueryParamsKey: KeyObj<HealthcareServiceListQueryParams> =
  getKeyObj(createHydratedMock<HealthcareServiceListQueryParams>());

export const healthcareServiceBasePath = "/healthcare-service";

export const getHealthcareServiceList = (
  params?: HealthcareServiceListQueryParams
): Promise<ApiPageRequestResponse<HealthcareService>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(healthcareServiceBasePath),
    params
  });

export const getHealthcareService = (
  id: ModelId,
  params?: HealthcareServiceQueryParams
): Promise<ApiRequestResponse<HealthcareService>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${healthcareServiceBasePath}/${id}`),
    params
  });

export const createHealthcareService = (
  data: HealthcareService
): Promise<ApiRequestResponse<HealthcareService>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(healthcareServiceBasePath),
    data
  });

export const updateHealthcareService = (
  data: HealthcareService
): Promise<ApiRequestResponse<HealthcareService>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${healthcareServiceBasePath}/${data.id}`),
    data
  });
