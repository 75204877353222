import { createStyle, destyledAnchorMixin, Theme } from "@laba/react-common";

export const useButtonBaseAnchorStyles = createStyle(
  (_theme: Theme) => ({
    anchor: {
      ...destyledAnchorMixin()
    }
  }),
  "ButtonBaseAnchor"
);

export const useSelectableButtonBaseStyles = createStyle(
  (_theme: Theme) => ({
    root: {
      userSelect: "text"
    }
  }),
  "SelectableButtonBase"
);
