import {
  getEnumOrderParam,
  getKeyTargetParam,
  resourceProviderGetter
} from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { workspaceOrganizationSelector } from "store/workspace/selectors";
import { store } from "store/store";
import {
  createSchedule,
  editSchedule,
  getModelReferenceId,
  getSchedule,
  getScheduleList,
  ResourceType,
  ScheduleListQueryParams,
  ScheduleListQueryParamsKey
} from "@laba/nexup-api";

export const scheduleProvider = resourceProviderGetter(
  ResourceType.Schedule,
  getEnumOrderParam({}),
  getKeyTargetParam<ScheduleListQueryParams>(ScheduleListQueryParamsKey),
  getScheduleList,
  getSchedule,
  editSchedule,
  createSchedule,
  undefined,
  () => ({
    organization: getModelReferenceId(
      workspaceOrganizationSelector(store.getState())
    )
  })
);
