import { FC, getClassName, useEffectSelective } from "@laba/react-common";
import React, { useEffect, useRef, useState } from "react";
import { useJsonEditorStyle } from "components/generic/JsonEditor/JsonEditorStyle";
import JSONEditor, { Template, ValidationError } from "jsoneditor";
import { logger, Optional } from "@laba/ts-common";
import { isEqual, isNil } from "lodash-es";

export enum JsonEditorMode {
  Tree = "tree",
  View = "view",
  Form = "form",
  Code = "code",
  Text = "text",
  Preview = "preview"
}

export interface JsonEditorProps {
  classname?: string;
  value?: string | unknown;
  onChangeText?: (value: string) => void;
  onChangeObj?: (value: unknown) => void;
  onValidate?: (value: unknown) => ValidationError[];
  mode?: JsonEditorMode;
  templates?: Template[];
}

export const JsonEditor: FC<JsonEditorProps> = ({
  classname,
  value,
  onChangeText,
  onChangeObj,
  onValidate,
  mode,
  templates
}) => {
  const classes = useJsonEditorStyle();
  const ref = useRef<HTMLDivElement>(null);
  const [editor, setEditor] = useState<Optional<JSONEditor>>(undefined);
  useEffectSelective(
    () => {
      if (!ref.current) return;
      const newEditor = new JSONEditor(ref.current, {
        onChangeText: jsonString => {
          onChangeText?.(jsonString);
          try {
            const obj = JSON.parse(jsonString);
            onChangeObj?.(obj);
            // eslint-disable-next-line no-empty
          } catch (e) {}
        },
        onValidate,
        escapeUnicode: false,
        mode,
        templates,
        language: "es",
        modes: [JsonEditorMode.Tree, JsonEditorMode.Code]
      });
      setEditor(newEditor);
      return () => {
        newEditor.destroy();
      };
    },
    [],
    [mode, onChangeText, onChangeObj, onValidate, templates]
  );
  useEffect(() => {
    if (!editor) return;
    if (!isNil(value)) {
      try {
        const editorValue = editor.get();
        if (isEqual(value, editorValue)) return;
      } catch (e) {
        logger.error(e);
      }
      if (typeof value === "string") {
        editor.updateText(value);
      } else {
        editor.update(value);
      }
    }
  }, [editor, value]);
  useEffect(() => {
    if (!editor) return;
    if (!isNil(mode)) {
      editor.setMode(mode);
    }
  }, [editor, mode]);
  return <div className={getClassName(classname, classes.root)} ref={ref} />;
};
