import React from "react";
import {
  FCC,
  getClassName,
  StyleVariant,
  TypeVariant
} from "@laba/react-common";
import { TypeInvertedVariant } from "components/utils/getGenericButtonColors";
import {
  KeyboardArrowLeftIcon,
  KeyboardArrowRightIcon
} from "components/icons";
import { TagButton } from "components/tags/TagButton/TagButton";
import { DateFormat, DateTime, Noop } from "@laba/ts-common";
import { BaseIconButton } from "components/buttons/BaseIconButton/BaseIconButton";
import { CalendarDatePicker } from "components/inputs/CalendarDatePicker/CalendarDatePicker";
import { isEmpty } from "lodash-es";
import { Tag } from "components/tags/Tag/Tag";
import { useDateNavigatorHeaderStyles } from "./useDateNavigatorHeaderStyles";

export interface DateNavigatorHeaderProps {
  isMobile?: boolean;
  className?: string;
  currentDateText?: string;
  isCurrentDate?: boolean;

  dateValue?: DateTime;
  onChangeDate?: (dateTime?: DateTime) => void;
  maxDate?: DateTime;
  minDate?: DateTime;
  badgedDays?: DateTime[];

  onGoForwardDate?: Noop;
  onGoBackwardDate?: Noop;
  onGoToCurrentDate?: Noop;
  dateTextFormat?: DateFormat;
  dateButtonClassName?: string;
}

export const DateNavigatorHeader: FCC<DateNavigatorHeaderProps> = ({
  isMobile,
  className,
  children,
  currentDateText,
  isCurrentDate,
  dateValue,
  onChangeDate,
  onGoForwardDate,
  onGoBackwardDate,
  onGoToCurrentDate,
  maxDate,
  minDate,
  badgedDays,
  dateTextFormat = DateFormat.MonthAbbAndYear,
  dateButtonClassName
}) => {
  const classes = useDateNavigatorHeaderStyles({ isMobile });

  return (
    <div className={getClassName(className, classes.root)}>
      <div className={classes.dateModifier}>
        <CalendarDatePicker
          value={dateValue}
          onChange={onChangeDate}
          dateTextFormat={dateTextFormat}
          maxDate={maxDate}
          minDate={minDate}
          badgedDays={badgedDays}
          popoverClassName={getClassName(
            dateButtonClassName,
            classes.datePicker
          )}
        />
        <div className={classes.arrowsAndTagWrapper}>
          <div className={classes.arrowsWrapper}>
            <BaseIconButton
              Icon={KeyboardArrowLeftIcon}
              type={TypeInvertedVariant.Inverted}
              style={StyleVariant.BlackWhite}
              onClick={onGoBackwardDate}
              withoutPadding
            />
            <BaseIconButton
              Icon={KeyboardArrowRightIcon}
              type={TypeInvertedVariant.Inverted}
              style={StyleVariant.BlackWhite}
              onClick={onGoForwardDate}
              withoutPadding
            />
          </div>
          {!isEmpty(currentDateText) &&
            (isCurrentDate ? (
              <Tag
                className={classes.tag}
                text={currentDateText}
                type={TypeVariant.Contained}
                style={StyleVariant.Primary}
              />
            ) : (
              <TagButton
                className={classes.tag}
                text={currentDateText}
                type={TypeVariant.Outlined}
                style={StyleVariant.Primary}
                onClick={onGoToCurrentDate}
              />
            ))}
        </div>
      </div>
      {children}
    </div>
  );
};
