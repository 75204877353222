import { ModelReference } from "model/primitives/modelReference/modelReference";
import { ResourceModel, ResourceType } from "model/primitives/resourceModel";
import { getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { Organization } from "model/resource/entities/organization/organization";
import { PermissionConfig } from "model/primitives/resorcePermissions/resourcePermissions";
import {
  Product,
  QuestionnaireEnableWhenBehavior,
  QuestionnaireField,
  QuestionnaireItemEnableWhen
} from "model/resource";
import { PublicationStatus } from "model/resource/activity/activityDefinition";

export enum QuestionnaireType {
  Medical = "Medical",
  Nursing = "Nursing"
}

export interface QuestionnaireCard {
  title?: string;
  linkId: string;
  fields: QuestionnaireField[];
  enableWhen?: QuestionnaireItemEnableWhen[];
  enableBehavior?: QuestionnaireEnableWhenBehavior;
}

export enum QuestionnaireFlowConfig {
  IsHospitalization = "IsHospitalization",
  IsProcedureReport = "IsProcedureReport",
  NonPrintable = "NonPrintable",
  SingleUse = "SingleUse",
  ShowPractitionerSignatureOnReport = "ShowPractitionerSignatureOnReport",
  ShowOnlyEffectiveData = "ShowOnlyEffectiveData"
}

export interface QuestionnaireConsumptionConfigItemProduct {
  product?: ModelReference<Product>;
  quantity?: number;
}

export interface QuestionnaireConsumptionConfigItem {
  enableWhen?: QuestionnaireItemEnableWhen;
  enableBehavior?: QuestionnaireEnableWhenBehavior;
  products?: QuestionnaireConsumptionConfigItemProduct[];
}
export interface QuestionnaireConsumptionConfig {
  ignorePayer?: boolean;
  consumptionConfig?: QuestionnaireConsumptionConfigItem[];
}

export interface Questionnaire
  extends ResourceModel<ResourceType.Questionnaire> {
  name: string;
  title: string;
  order?: number;
  status: PublicationStatus;
  organization: ModelReference<Organization>;
  category: string;
  editInterval?: number;
  responseTitleTemplate: string;
  responseDescriptionTemplate: string;
  cards: QuestionnaireCard[];
  featured?: boolean;
  color?: string;
  type?: QuestionnaireType;
  flowConfig?: QuestionnaireFlowConfig[];
  permissionConfig?: PermissionConfig;
  consumptionsConfig?: QuestionnaireConsumptionConfig;
}

export const QuestionnaireKey = getKeyObj<Questionnaire>(
  createHydratedMock<Questionnaire>()
);

export const QuestionnaireCardKey = getKeyObj<QuestionnaireCard>(
  createHydratedMock<QuestionnaireCard>()
);

export const QuestionnaireConsumptionConfigItemKey =
  getKeyObj<QuestionnaireConsumptionConfigItem>(
    createHydratedMock<QuestionnaireConsumptionConfigItem>()
  );

export const QuestionnaireConsumptionConfigItemProductKey =
  getKeyObj<QuestionnaireConsumptionConfigItemProduct>(
    createHydratedMock<QuestionnaireConsumptionConfigItemProduct>()
  );

export const isQuestionnaireActive = (questionnaire: Questionnaire): boolean =>
  questionnaire.status === PublicationStatus.Active;
