import { createStyle } from "@laba/react-common";
import { Theme } from "model/theme";

export const usePdfViewerStyles = createStyle(
  (_theme: Theme) => ({
    root: {
      height: "90vh",
      width: "80vw"
    }
  }),
  "PdfViewer"
);
