import React from "react";
import {
  FC,
  StyleVariant,
  TextVariant,
  getClassName
} from "@laba/react-common";
import { NoAllergiesIcon, WarningIcon } from "components/icons";
import { isEmpty } from "lodash-es";
import { NexupIconButton } from "components/buttons/IconButton/IconButton";
import { TypeInvertedVariant } from "components/utils/getGenericButtonColors";
import { usePatientAllergiesStyles } from "./PatientAllergiesStyle";

export enum PatientAllergyStatusFlatData {
  TestedWithAllergies = "TestedWithAllergies",
  NoAllergies = "NoAllergies",
  Untested = "Untested"
}

export interface PatientAllergiesProps {
  showIconOnly?: boolean;
  className?: string;
  allergyStatus?: PatientAllergyStatusFlatData;
  allergiesTitle?: string;
  noAllergiesText?: string;
  untestedForAllergiesText?: string;
  allergies?: string[];
  textVariant?: TextVariant;
  titleVariant?: TextVariant;
  maxLines?: number;
}

export const PatientAllergies: FC<PatientAllergiesProps> = ({
  showIconOnly,
  className,
  allergyStatus = PatientAllergyStatusFlatData.Untested,
  allergiesTitle,
  noAllergiesText,
  untestedForAllergiesText,
  allergies,
  textVariant = TextVariant.Body2,
  titleVariant = TextVariant.Subtitle2,
  maxLines
}) => {
  const classes = usePatientAllergiesStyles({
    textVariant,
    maxLines,
    titleVariant
  });
  const allergiesData = !isEmpty(allergies)
    ? allergies?.join(", ")
    : allergyStatus === PatientAllergyStatusFlatData.Untested
    ? untestedForAllergiesText
    : noAllergiesText;

  if (showIconOnly)
    return allergyStatus === PatientAllergyStatusFlatData.NoAllergies ? null : (
      <NexupIconButton
        Icon={WarningIcon}
        style={
          allergyStatus === PatientAllergyStatusFlatData.TestedWithAllergies
            ? StyleVariant.Danger
            : StyleVariant.Warning
        }
        type={TypeInvertedVariant.Inverted}
      />
    );

  return (
    <div className={getClassName(classes.patientAllergiesContainer, className)}>
      {
        {
          Untested: <WarningIcon className={classes.patientUntestedIcon} />,
          NoAllergies: (
            <NoAllergiesIcon className={classes.patientNoAllergiesIcon} />
          ),
          TestedWithAllergies: (
            <WarningIcon className={classes.patientAllergiesIcon} />
          )
        }[allergyStatus]
      }
      <p className={classes.patientAllergiesText} title={allergiesData}>
        <span className={classes.patientAllergiesTitle}>{allergiesTitle}</span>
        {allergiesData}
      </p>
    </div>
  );
};
