import React from "react";
import { FC, useI18n } from "@laba/react-common";
import { CodeSystem, ResourceType } from "@laba/nexup-api";
import {
  getDefaultResourceTransform,
  ResourceForm
} from "components/generic/ResourceForm/ResourceForm";
import { tkCP } from "translation/i18n";
import {
  getDefaultJsonToModel,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";
import { CodeSystemMainContent } from "components/pages/NexupAdmin/resources/codeSystem/CodeSystemMainContent";
import { CodeSystemConceptContent } from "components/pages/NexupAdmin/resources/codeSystem/CodeSystemConceptContent";
import { CodeSystemConceptPropsContent } from "components/pages/NexupAdmin/resources/codeSystem/CodeSystemConceptPropsContent";
import { CodeSystemConceptElementContent } from "components/pages/NexupAdmin/resources/codeSystem/CodeSystemConceptElementContent";

export const codeSystemTransform = getDefaultResourceTransform<CodeSystem>(
  ResourceType.CodeSystem
);
export const codeSystemJsonToModel: JsonToModel<CodeSystem> =
  getDefaultJsonToModel(draft => {
    draft.organization = undefined;
  });
export const tk = tkCP.adminPage[ResourceType.CodeSystem];

export const CodeSystemForm: FC = () => {
  const { t } = useI18n();
  return (
    <ResourceForm
      tabsConfig={[
        {
          label: t(tk.tabs.codeSystem),
          content: <CodeSystemMainContent />
        },
        {
          label: t(tk.tabs.concept),
          content: <CodeSystemConceptContent />
        },
        {
          label: t(tk.tabs.conceptElement),
          content: <CodeSystemConceptElementContent />
        },
        {
          label: t(tk.tabs.conceptWithProps),
          content: <CodeSystemConceptPropsContent />
        }
      ]}
    />
  );
};
