import React from "react";
import { FC } from "@laba/react-common";
import { Create } from "components/generic/Create/Create";
import {
  MedicalRequestDefinitionForm,
  medicalRequestDefinitionJsonToModel,
  medicalRequestDefinitionTransform
} from "components/pages/NexupAdmin/resources/medicalRequestDefinition/MedicalRequestDefinitionForm";
import { ResourceType } from "@laba/nexup-api";

export const MedicalRequestDefinitionCreate: FC = () => {
  return (
    <Create
      resourceTransform={medicalRequestDefinitionTransform}
      resource={ResourceType.MedicalRequestDefinition}
      jsonToModel={medicalRequestDefinitionJsonToModel}
    >
      <MedicalRequestDefinitionForm />
    </Create>
  );
};
