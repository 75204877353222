import { Optional } from "@laba/ts-common";
import { isEmpty } from "lodash-es";
import { PaginationState } from "./getPaginationState";

type CommonStateReturnSelector<T, RootState> = (state: RootState) => T;

export interface ReturnSelectors<PaginationElement, State> {
  elementListSelector: CommonStateReturnSelector<PaginationElement[], State>;
  isDownloadingElementSelector: CommonStateReturnSelector<boolean, State>;
  elementListLastPageNumberSelector: CommonStateReturnSelector<
    Optional<number>,
    State
  >;
  elementTotalSizeSelector: CommonStateReturnSelector<Optional<number>, State>;
  elementListNextPageNumberSelector: CommonStateReturnSelector<
    Optional<number>,
    State
  >;
  elementLisHasNextPageSelector: CommonStateReturnSelector<boolean, State>;
  elementHasNoResultSelector: CommonStateReturnSelector<boolean, State>;
  elementRequestPageIdSelector: CommonStateReturnSelector<
    Optional<number>,
    State
  >;
}

export const getPaginationStateSelectors = <PaginationElement, State>(
  stateElementListGetter: (state: State) => PaginationState<PaginationElement>
): ReturnSelectors<PaginationElement, State> => {
  const elementListSelector = (state: State): PaginationElement[] => {
    return stateElementListGetter(state).elementList;
  };
  const isDownloadingElementSelector = (state: State): boolean => {
    return stateElementListGetter(state).currentPageDownload.downloading;
  };

  const elementListLastPageNumberSelector = (
    state: State
  ): Optional<number> => {
    return stateElementListGetter(state).lastPageInfo?.page;
  };

  const elementTotalSizeSelector = (state: State): Optional<number> => {
    return stateElementListGetter(state).lastPageInfo?.totalEntries;
  };

  const elementListNextPageNumberSelector = (
    state: State
  ): Optional<number> => {
    const listLastPageNumber = elementListLastPageNumberSelector(state);

    if (listLastPageNumber != null) return listLastPageNumber + 1;
    return undefined;
  };

  const elementLisHasNextPageSelector = (state: State): boolean => {
    return elementListNextPageNumberSelector(state) !== undefined;
  };

  const elementHasNoResultSelector = (state: State): boolean => {
    return (
      isEmpty(elementListSelector(state)) &&
      !isDownloadingElementSelector(state)
    );
  };

  const elementRequestPageIdSelector = (state: State): Optional<number> => {
    return stateElementListGetter(state).currentPageDownload.id;
  };
  return {
    elementListSelector,
    isDownloadingElementSelector,
    elementListLastPageNumberSelector,
    elementTotalSizeSelector,
    elementListNextPageNumberSelector,
    elementLisHasNextPageSelector,
    elementHasNoResultSelector,
    elementRequestPageIdSelector
  };
};
