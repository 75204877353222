import React from "react";
import { FC, getClassName } from "@laba/react-common";
import { ButtonBase } from "components/buttons/ButtonBase/ButtonBase";
import {
  bindPopover,
  bindTrigger,
  usePopupState
} from "material-ui-popup-state/hooks";
import { Calendar } from "components/inputs/Calendar/Calendar";
import {
  applyFormatToDate,
  DateFormat,
  DateTime,
  dateTimeToApiDateOrUndefined
} from "@laba/ts-common";
import {
  Popover,
  PopoverOriginHorizontalVariant,
  PopoverOriginVerticalVariant
} from "components/popover/Popover/Popover";
import { useCalendarDatePickerStyle } from "./CalendarDatePickerStyle";

export interface CalendarDatePickerProps {
  className?: string;
  popoverClassName?: string;
  value?: DateTime;
  onChange?: (d?: DateTime) => void;
  dateTextFormat?: DateFormat;
  maxDate?: DateTime;
  minDate?: DateTime;
  badgedDays?: DateTime[];
  selectedDateText?: string;
}

export const CalendarDatePicker: FC<CalendarDatePickerProps> = ({
  className,
  popoverClassName,
  value,
  onChange,
  dateTextFormat = DateFormat.MonthAbbAndYear,
  maxDate,
  minDate,
  badgedDays,
  selectedDateText
}) => {
  const classes = useCalendarDatePickerStyle();
  const popupState = usePopupState({
    variant: "popover",
    popupId: "datePicker"
  });
  const popoverBind = bindPopover(popupState);

  const dateText =
    selectedDateText ??
    applyFormatToDate(dateTimeToApiDateOrUndefined(value), dateTextFormat);

  return (
    <Popover
      className={popoverClassName}
      PopoverComponent={
        <Calendar
          onChange={newDate => {
            onChange?.(newDate);
            popoverBind.onClose();
          }}
          value={value}
          minDate={minDate}
          maxDate={maxDate}
          badgedDays={badgedDays}
          className={classes.datePicker}
        />
      }
      anchorOrigin={{
        vertical: PopoverOriginVerticalVariant.Bottom,
        horizontal: PopoverOriginHorizontalVariant.Left
      }}
      transformOrigin={{
        vertical: PopoverOriginVerticalVariant.Top,
        horizontal: PopoverOriginHorizontalVariant.Center
      }}
      popoverClassName={classes.popover}
      externalPopoverBinder={popoverBind}
      ignorePopoverState
    >
      <ButtonBase
        {...bindTrigger(popupState)}
        className={getClassName(classes.buttonBase, className)}
      >
        <p className={classes.dateText}>{dateText}</p>
      </ButtonBase>
    </Popover>
  );
};
