import {
  borderMixinCircular,
  Color,
  createStyle,
  FlexAlignItems,
  FlexJustifyContent,
  flexRowMixin,
  getClassName,
  pxToRem,
  StyleVariant,
  TextVariant
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

interface Props {
  isAvailable?: boolean;
  color?: Color;
  style: StyleVariant;
}
interface ClassNames {
  circle?: string;
  number?: string;
}

const useBadgeStyleStatic = createStyle(
  (theme: Theme) => ({
    numberStatic: {
      ...themeTextMixin(theme, TextVariant.Button, 1)
    },
    circleStatic: {
      ...borderMixinCircular(),
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.Center
      }),
      width: pxToRem(24),
      height: pxToRem(24)
    }
  }),
  "BadgeStatic"
);

const useBadgeStyleDynamic = createStyle(
  (theme: Theme) => ({
    numberDynamic: (props: Props) => ({
      color: getThemeStyleColor(
        theme,
        props.style,
        ThemeStyleColorVariant.Contrast
      )
    }),
    circleDynamic: (props: Props) => ({
      backgroundColor: props.isAvailable
        ? props.color ??
          getThemeStyleColor(theme, props.style, ThemeStyleColorVariant.Main)
        : getThemeStyleColor(
            theme,
            props.style,
            ThemeStyleColorVariant.GrayMedium
          )
    })
  }),
  "BadgeDynamic"
);

export const useBadgeStyle = (props: Props): ClassNames => {
  const staticClasses = useBadgeStyleStatic();
  const dynamicClasses = useBadgeStyleDynamic(props);

  return {
    circle: getClassName(
      dynamicClasses.circleDynamic,
      staticClasses.circleStatic
    ),
    number: getClassName(
      staticClasses.numberStatic,
      dynamicClasses.numberDynamic
    )
  };
};
