import {
  createStyle,
  flexColumnMixin,
  paddingMixin,
  pxToRem,
  StyleVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor } from "model/themeUtils";

export const useLinkInputStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexColumnMixin({ gap: 8 })
    },
    selectedItems: {
      ...flexColumnMixin({ gap: 8 })
    },
    item: {
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Background
      ),
      ...paddingMixin({ left: 8, right: 16 }),
      height: pxToRem(40)
    }
  }),
  "LinkInput"
);
