import { useMemo } from "react";
import {
  DateTime,
  getCurrentDateTime,
  getDateTimeMillisBefore,
  Optional
} from "@laba/ts-common";

export const useDateTimeBeforeTimeWindowOrUndefined = (
  timeWindow?: number,
  dateTime = getCurrentDateTime().toUTC()
): Optional<DateTime> => {
  return useMemo(
    () =>
      timeWindow ? getDateTimeMillisBefore(timeWindow, dateTime) : undefined,
    [dateTime, timeWindow]
  );
};
