import { ApiDate, getKeyObj } from "@laba/ts-common";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { AllergyIntolerance } from "model/resource/medical/allergyIntolerance/allergyIntolerance";
import { Hospitalization } from "model/resource/medical/hospitalization/hospitalization";
import { PatientMeasure } from "model/resource/medical/observation/patientMeasure";
import { PatientTag } from "model/resource/medical/observation/patientTag";
import { createHydratedMock } from "ts-auto-mock";
import { Patient } from "model/resource/person/patient/patient";

export enum PatientPrescriptionStatus {
  Active = "Active",
  Expired = "Expired",
  ToExpire = "ToExpire"
}
export interface PatientContainerWithExtraData {
  patient: Patient;
  hospitalization?: Hospitalization;
  lastEvolutionEditDate?: ApiDate;
  lastPrescriptionEditDate?: ApiDate;
  prescriptionStatus?: PatientPrescriptionStatus;
  allergyIntoleranceList?: AllergyIntolerance[];
  tagList?: PatientTag[];
  measurementList?: PatientMeasure[];
  managedGroup?: ModelReference<Patient>[];
}

export const PatientContainerWithExtraDataParamsKey = getKeyObj(
  createHydratedMock<PatientContainerWithExtraData>()
);
