import React, { FormEventHandler, useState } from "react";
import { FC, getClassName, useDebounce } from "@laba/react-common";
import {
  NexupIconButton,
  IconButtonType
} from "components/buttons/IconButton/IconButton";
import { ThemeProvider } from "@material-ui/core/styles";
import { TextField, FormControl } from "@material-ui/core";
import { useMuiTheme } from "model/useMuiTheme";
import { StyleVariant } from "model/themeVariant";
import { Search as SearchIcon } from "@material-ui/icons";
import { Noop } from "@laba/ts-common";
import {
  useSearchBarFormControlStyle,
  useSearchBarStyle,
  useSearchBarOutlinedInputStyle,
  useSearchBarFormStyle
} from "./SearchBarStyle";

export interface SearchBarProps {
  className?: string;
  fullWidth?: boolean;
  label?: string;
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void | Promise<void>;
  value: string;
  searchDebounceMs?: number;
  onBlur?: Noop;
  onFocus?: Noop;
}

export const SearchBar: FC<SearchBarProps> = ({
  className,
  label,
  onChange,
  onSubmit,
  value,
  searchDebounceMs = 0,
  fullWidth = false,
  onBlur,
  onFocus
}) => {
  const theme = useMuiTheme(StyleVariant.Primary);
  const formClasses = useSearchBarFormStyle({ fullWidth });
  const formControlClasses = useSearchBarFormControlStyle();
  const textFieldClasses = useSearchBarStyle();
  const outlinedInputClasses = useSearchBarOutlinedInputStyle();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const handleSubmit: FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await onSubmit?.(value);
    } catch (err) {
      setIsSubmitting(false);
    }
    setIsSubmitting(false);
  };
  const { onChange: debouncedOnChange, value: debouncedValue } = useDebounce({
    onChange,
    value,
    searchDebounceMs
  });
  return (
    <form
      className={getClassName(className, formClasses.form)}
      onSubmit={handleSubmit}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <ThemeProvider theme={theme}>
        <FormControl classes={formControlClasses} fullWidth={fullWidth}>
          <TextField
            classes={textFieldClasses}
            disabled={isSubmitting}
            InputProps={{ classes: outlinedInputClasses }}
            placeholder={label}
            onChange={e => debouncedOnChange?.(e.target.value)}
            size="small"
            value={debouncedValue}
            variant="outlined"
          />
          <NexupIconButton
            disabled={isSubmitting}
            Icon={SearchIcon}
            buttonType={IconButtonType.Submit}
          />
        </FormControl>
      </ThemeProvider>
    </form>
  );
};
