import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  FlexJustifyContent,
  StyleVariant
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { getThemeStyleColor } from "model/themeUtils";

export const useSpinnerDialogStyles = createStyle(
  (theme: Theme) => ({
    circularProgressContainer: {
      ...flexColumnMixin({
        justifyContent: FlexJustifyContent.Center,
        alignItems: FlexAlignItems.Center
      }),
      width: "100%",
      height: "100%"
    },
    spinnerClassname: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Background
      )
    }
  }),
  "SpinnerDialog"
);
