import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  Notification,
  WhatsappMessageType,
  WhatsappNotificationLastVersionParamsKey,
  WhatsappNotificationStatus
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { ExternalResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { DateTimeInput } from "components/generic/DateTimeInput/DateTimeInput";
import { notificationOptionText } from "components/generic/ReferenceResourceInput/optionText";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";

export const tk = tkCP.adminPage[ExternalResourceType.WhatsappNotification];
export const WhatsappNotificationMainContent: FC = () => {
  const { t } = useI18n();

  return (
    <>
      <TextInput
        disabled
        source={WhatsappNotificationLastVersionParamsKey.id}
        label={t(tk.fields.id)}
      />
      <TextInput
        disabled
        source={WhatsappNotificationLastVersionParamsKey.version}
        label={t(tk.fields.version)}
      />
      <EnumSelectorInput
        source={WhatsappNotificationLastVersionParamsKey.status}
        label={t(tk.fields.status)}
        enumValue={WhatsappNotificationStatus}
      />
      <TextInput
        source={WhatsappNotificationLastVersionParamsKey.message}
        label={t(tk.fields.message)}
        multiline
      />
      <EnumSelectorInput
        source={WhatsappNotificationLastVersionParamsKey.messageType}
        label={t(tk.fields.messageType)}
        enumValue={WhatsappMessageType}
        disabled
      />
      <TextInput
        source={WhatsappNotificationLastVersionParamsKey.externalId}
        label={t(tk.fields.externalId)}
        disabled
      />
      <DateTimeInput
        source={WhatsappNotificationLastVersionParamsKey.creationDate}
        label={t(tk.fields.creationDate)}
        disabled
      />
      <DateTimeInput
        source={WhatsappNotificationLastVersionParamsKey.lastUpdatedDate}
        label={t(tk.fields.lastUpdatedDate)}
        disabled
      />
      <ReferenceResourceInput<Notification>
        // eslint-disable-next-line  no-underscore-dangle
        source={WhatsappNotificationLastVersionParamsKey._notificationId}
        label={t(tk.fields.notification)}
        resourceType={ExternalResourceType.Notification}
        optionTextKey={notificationOptionText}
        disabled
      />
    </>
  );
};
