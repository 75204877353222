import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { getKeyObj, HttpMethod, KeyObj } from "@laba/ts-common";
import { AppointmentDefinition } from "model/resource/appointment/appointmentDefinition";
import { ModelId } from "model/primitives/model/model";
import { createHydratedMock } from "ts-auto-mock";
import { PublicationStatus } from "model/resource/activity/activityDefinition";

export interface AppointmentDefinitionListQueryParams extends ListQueryParams {
  organization?: ModelId;
  status?: PublicationStatus;
}

export const AppointmentDefinitionListQueryParamsKey: KeyObj<AppointmentDefinitionListQueryParams> =
  getKeyObj(createHydratedMock<AppointmentDefinitionListQueryParams>());

const AppointmentDefinitionBasePath = "/appointment-definition";

export const getAppointmentDefinitionList = (
  params?: AppointmentDefinitionListQueryParams
): Promise<ApiPageRequestResponse<AppointmentDefinition>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(AppointmentDefinitionBasePath),
    params
  });

export const createAppointmentDefinition = (
  data: AppointmentDefinition
): Promise<ApiRequestResponse<AppointmentDefinition>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(AppointmentDefinitionBasePath),
    data
  });

export const updateAppointmentDefinition = (
  data: AppointmentDefinition
): Promise<ApiRequestResponse<AppointmentDefinition>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${AppointmentDefinitionBasePath}/${data.id}`),
    data
  });

export const getAppointmentDefinition = (
  id: ModelId
): Promise<ApiRequestResponse<AppointmentDefinition>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${AppointmentDefinitionBasePath}/${id}`)
  });
