import { ApiRequestResponse } from "request/types";
import { getApiUrl, getProvisionalApiUrl, request } from "request/nexupRequest";
import { ApiDate, HttpMethod } from "@laba/ts-common";
import { ResourceType } from "model/primitives/resourceModel";
import { urlJoin } from "url-join-ts";
import { ModelId } from "model/primitives/model/model";

export interface MaintenanceSyncStatus {
  isEnabled: boolean;
  isOngoing: boolean;
  isEnabledToStart: boolean;
}
export const maintenanceSyncStatusRequest = async (
  provisionalServer = true
): Promise<ApiRequestResponse<MaintenanceSyncStatus>> => {
  return request({
    method: HttpMethod.GET,
    url: provisionalServer
      ? getProvisionalApiUrl("maintenance/resources/sync")
      : getApiUrl("maintenance/resources/sync")
  });
};

export enum CompleteConfigurationAction {
  StopSync = "stopSync",
  CompleteIndexes = "completeIndexes",
  UpdateMigrationsApplied = "updateMigrationsApplied",
  InitSubscribers = "initSubscribers"
}
export interface CompleteConfigurationBody {
  actions?: CompleteConfigurationAction[];
}

export const maintenanceCompleteConfigurationRequest = async (
  data: CompleteConfigurationBody,
  provisionalServer = true
): Promise<ApiRequestResponse<MaintenanceSyncStatus>> => {
  return request({
    method: HttpMethod.POST,
    url: provisionalServer
      ? getProvisionalApiUrl("maintenance/configuration/complete")
      : getApiUrl("maintenance/configuration/complete"),
    data
  });
};

export enum SyncResourceCode {
  All = "All",
  Auto = "Auto"
}
export interface SyncResourceClass {
  type?: ResourceType;
  migrationIds?: number[];
}

export interface SyncResourceBody {
  startDate?: ApiDate;
  endDate?: ApiDate;
  count?: number;
  delayWindowMinutes?: number;
  emptySearchLimit?: number;
  classesList?: SyncResourceClass[];
  syncCodes?: SyncResourceCode[];
}

export const maintenanceStartSyncRequest = async (
  data: SyncResourceBody,
  provisionalServer = true
): Promise<ApiRequestResponse<MaintenanceSyncStatus>> => {
  return request({
    method: HttpMethod.POST,
    url: provisionalServer
      ? getProvisionalApiUrl("maintenance/resources/sync")
      : getApiUrl("maintenance/resources/sync"),
    data
  });
};

export const maintenanceCreatePrescriptionDocumentRequest = async (
  prescriptionId: ModelId
): Promise<ApiRequestResponse<MaintenanceSyncStatus>> => {
  return request({
    method: HttpMethod.POST,
    url: getApiUrl(
      urlJoin("maintenance/prescription-document/", prescriptionId)
    )
  });
};
