import {
  BoxSidePropertyKey,
  getBoxPropertyMixinBuilder
} from "model/style/box/boxSide";

export const marginMixin = getBoxPropertyMixinBuilder({
  [BoxSidePropertyKey.Top]: "margin-top",
  [BoxSidePropertyKey.Right]: "margin-right",
  [BoxSidePropertyKey.Bottom]: "margin-bottom",
  [BoxSidePropertyKey.Left]: "margin-left"
});
