import {
  GenericObject,
  getKeyObj,
  HttpMethod,
  Validator
} from "@laba/ts-common";
import {
  getApiUrl,
  getProvisionalApiUrl,
  requestAnon
} from "request/nexupRequest";
import { camelCase, mapKeys } from "lodash-es";
import { ApiRequestResponse } from "request/types";
import { createHydratedMock } from "ts-auto-mock";
import { ModelId } from "model/primitives/model/model";

export interface LoginResponse {
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
  scope?: string;
  organization?: ModelId;
}

export interface LoginFormData {
  user?: string;
  password?: string;
  invitation?: string;
}

export const loginFormDataParamsKey = getKeyObj(
  createHydratedMock<LoginFormData>()
);

const responseValidator: Validator<LoginResponse> = response => {
  const mappedResponse = mapKeys(response as GenericObject, (_value, key) =>
    camelCase(key)
  );
  return mappedResponse as unknown as LoginResponse;
};

export const loginRequest = async (
  username: string,
  password: string,
  invitation?: string
): Promise<ApiRequestResponse<LoginResponse>> =>
  requestAnon(
    {
      method: HttpMethod.POST,
      url: getApiUrl("/auth/token"),
      data: { username, password, invitation }
    },
    responseValidator
  );

export const refreshTokenRequest = async (
  refreshToken: string,
  provisionalServer = false
): Promise<ApiRequestResponse<LoginResponse>> =>
  requestAnon(
    {
      method: HttpMethod.POST,
      url: provisionalServer
        ? getProvisionalApiUrl("/auth/refresh")
        : getApiUrl("/auth/refresh"),
      data: { refresh_token: refreshToken }
    },
    responseValidator
  );

export const revokeTokenRequest = async (
  refreshToken: string
): Promise<ApiRequestResponse<unknown>> =>
  requestAnon({
    method: HttpMethod.POST,
    url: getApiUrl("/auth/revoke"),
    data: { refresh_token: refreshToken }
  });

export const googleLogin = async (
  credential: string,
  invitation?: string,
  baseUrl?: string
): Promise<ApiRequestResponse<LoginResponse>> => {
  return requestAnon({
    method: HttpMethod.POST,
    url: getApiUrl("/auth/google"),
    data: { credential, invitation, baseUrl }
  });
};
