import { useFileListContainerWithPreviewStyles } from "components/file/FileListContainerWithPreview/useFileListContainerWithPreviewStyles";
import { FC, getClassName } from "@laba/react-common";
import React from "react";
import {
  FileBlobItem,
  FileListItem,
  FileListPreviewPopup
} from "components/file";
import { Noop } from "@laba/ts-common";

interface Props {
  className?: string;
  blobDataList?: FileBlobItem[];
  onItemClicked?: (item: FileBlobItem) => void;
  onDownloadClicked?: (item: FileBlobItem) => void;
  isOpen?: boolean;
  isMobile?: boolean;
  initialFileIndex?: number;
  onClose?: Noop;
  invalidFormatTitle?: string;
  invalidFormatDescription?: string;
}
export const FileListContainerWithPreview: FC<Props> = ({
  className,
  blobDataList,
  onItemClicked,
  isOpen,
  isMobile,
  initialFileIndex,
  onClose,
  onDownloadClicked,
  invalidFormatTitle,
  invalidFormatDescription
}) => {
  const classes = useFileListContainerWithPreviewStyles();

  return (
    <>
      <div className={getClassName(className, classes.root)}>
        {blobDataList?.map(item => {
          return (
            <FileListItem
              key={item.url}
              file={item}
              onClick={clickedItem => {
                onItemClicked?.(clickedItem);
              }}
            />
          );
        })}
      </div>
      <FileListPreviewPopup
        isOpen={isOpen}
        initialItemIndex={initialFileIndex}
        blobItemList={blobDataList}
        isMobile={isMobile}
        onCloseButtonClicked={onClose}
        invalidFormatTitle={invalidFormatTitle}
        invalidFormatDescription={invalidFormatDescription}
        onDownloadButtonClicked={onDownloadClicked}
      />
    </>
  );
};
