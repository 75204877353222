import { ApiPageRequestResponse, ApiRequestResponse } from "request/types";
import { getCommunicationApiUrl, request } from "request/nexupRequest";
import { HttpMethod } from "@laba/ts-common";
import {
  WhatsappNotification,
  WhatsappNotificationDetailQueryParams,
  WhatsappNotificationLastVersion,
  WhatsappNotificationListQueryParams
} from "model/communication";

const basePath = "/whatsapp-notification";

export const getWhatsappNotificationLastVersionList = (
  params: WhatsappNotificationListQueryParams
): Promise<ApiPageRequestResponse<WhatsappNotificationLastVersion>> =>
  request({
    url: getCommunicationApiUrl(basePath),
    method: HttpMethod.GET,
    params
  });

export const getWhatsappNotificationLastVersionDetail = (
  id: string,
  params?: WhatsappNotificationDetailQueryParams
): Promise<ApiRequestResponse<WhatsappNotificationLastVersion>> =>
  request({
    url: getCommunicationApiUrl(`${basePath}/${id}`),
    method: HttpMethod.GET,
    params
  });

export const createWhatsappNotification = (
  notification: WhatsappNotification
): Promise<ApiRequestResponse<WhatsappNotification>> =>
  request({
    url: getCommunicationApiUrl(basePath),
    method: HttpMethod.POST,
    data: notification
  });

export const editWhatsappNotification = (
  notification: WhatsappNotification
): Promise<ApiRequestResponse<WhatsappNotification>> =>
  request({
    method: HttpMethod.PUT,
    url: getCommunicationApiUrl(`${basePath}/${notification.id ?? ""}`),
    data: notification
  });

export const getWhatsappNotificationHistoryList = (
  id: string,
  params: WhatsappNotificationListQueryParams
): Promise<ApiPageRequestResponse<WhatsappNotificationLastVersion>> =>
  request({
    url: getCommunicationApiUrl(`${basePath}/${id}/history`),
    method: HttpMethod.GET,
    params
  });

export const getWhatsappNotificationHistoryDetail = (
  id: string,
  version: number,
  params: WhatsappNotificationListQueryParams
): Promise<ApiRequestResponse<WhatsappNotificationLastVersion>> =>
  request({
    url: getCommunicationApiUrl(`${basePath}/${id}/history/${version}`),
    method: HttpMethod.GET,
    params
  });
