import {
  borderMixin,
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  flexItemMixin,
  FlexJustifyContent,
  flexRowMixin,
  getClassName,
  paddingMixin
} from "@laba/react-common";
import { RadiusVariant, Theme, ThemeStyleColorVariant } from "model/theme";
import {
  getThemeRadius,
  getThemeStyleColor,
  themeTextMixin
} from "model/themeUtils";
import { StyleVariant, TextVariant } from "model/themeVariant";

interface UploadZoneStylesProps {
  disabled?: boolean;
}

interface UploadZoneClassNames {
  root: string;
  promptContainer: string;
  promptTextContainer: string;
  promptText: string;
  dropRejected: string;
}

const useUploadZoneStaticStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexColumnMixin({
        justifyContent: FlexJustifyContent.Center
      }),
      ...paddingMixin({ all: 48 }),
      width: "100%"
    },
    rootDefaultBorder: {
      ...borderMixin({
        side: { all: 2 },
        style: "dashed",
        radius: { all: getThemeRadius(theme, RadiusVariant.Small) },
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.Light
        )
      })
    },
    rootDisabledBorder: {
      ...borderMixin({
        side: { all: 2 },
        style: "dashed",
        radius: { all: getThemeRadius(theme, RadiusVariant.Small) },
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.GrayLight
        )
      })
    },
    promptContainer: {
      ...flexItemMixin({ grow: 1 }),
      ...flexColumnMixin({
        justifyContent: FlexJustifyContent.Center,
        alignItems: FlexAlignItems.Center,
        gap: 16
      })
    },
    promptTextContainer: {
      ...flexRowMixin({
        justifyContent: FlexJustifyContent.Center,
        gap: 2
      })
    },
    promptText: {
      ...themeTextMixin(theme, TextVariant.Subtitle1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    dropRejected: {
      ...themeTextMixin(theme, TextVariant.Subtitle2),
      color: getThemeStyleColor(theme, StyleVariant.Danger)
    },
    disabledTextColor: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayLight
      )
    }
  }),
  "UploadZoneStatic"
);

export const useUploadZoneStyle = (
  props: UploadZoneStylesProps
): UploadZoneClassNames => {
  const staticClasses = useUploadZoneStaticStyle();

  return {
    root: getClassName(
      staticClasses.root,
      props.disabled
        ? staticClasses.rootDisabledBorder
        : staticClasses.rootDefaultBorder
    ),
    promptContainer: staticClasses.promptContainer,
    promptTextContainer: staticClasses.promptTextContainer,
    promptText: getClassName(
      staticClasses.promptText,
      props.disabled ? staticClasses.disabledTextColor : undefined
    ),
    dropRejected: staticClasses.dropRejected
  };
};
