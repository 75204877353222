import { tkCP } from "translation/i18n";
import { FC, useField, useFormState, useI18n } from "@laba/react-common";
import { useLoginFormContentStyle } from "components/pages/LoginPage/LoginFormContent/loginFormContentStyle";
import {
  FormError,
  InputType,
  OptionsConfig,
  SelectInput,
  TextInput,
  TextInputAutoCompleteType
} from "@laba/nexup-components";
import React, { useMemo } from "react";
import { Server } from "models/server/server";

const tk = tkCP.loginPage;
export const LoginFormContent: FC = () => {
  const classes = useLoginFormContentStyle();
  const { t } = useI18n();
  const { showGlobalError, error } = useFormState();
  const {
    input: {
      value: usernameValue,
      onChange: usernameOnChange,
      onBlur: usernameOnBlur
    },
    meta: { error: usernameError, touched: usernameTouched }
  } = useField<string, string>("username");
  const {
    input: {
      value: passwordValue,
      onChange: passwordOnChange,
      onBlur: passwordOnBlur
    },
    meta: { error: passwordError, touched: passwordTouched }
  } = useField<string, string>("password");
  const {
    input: {
      value: serverValue,
      onChange: serverOnChange,
      onBlur: serverOnBlur
    },
    meta: { error: serverError, touched: serverTouched }
  } = useField<Server, string>("server");
  const {
    input: {
      value: localUrlValue,
      onChange: localUrlOnChange,
      onBlur: localUrlOnBlur
    },
    meta: { error: localUrlError, touched: localUrlTouched }
  } = useField<string, string>("localUrl");
  const serverOption: OptionsConfig<Server>[] = useMemo(
    () =>
      Object.values(Server).map(value => ({
        value,
        itemId: value,
        text: t(tk.serverOptions[value]),
        title: t(tk.serverOptions[value])
      })),
    [t]
  );

  return (
    <div className={classes.root}>
      <TextInput
        value={usernameValue}
        onChange={usernameOnChange}
        onBlur={usernameOnBlur}
        errorText={usernameError}
        showError={usernameTouched ?? true}
        label={t(tk.usernameLabel)}
        fullWidth
        autoComplete={TextInputAutoCompleteType.UserName}
      />
      <TextInput
        value={passwordValue}
        onChange={passwordOnChange}
        onBlur={passwordOnBlur}
        errorText={passwordError}
        showError={passwordTouched ?? true}
        label={t(tk.passwordLabel)}
        type={InputType.Password}
        fullWidth
        autoComplete={TextInputAutoCompleteType.CurrentPassword}
      />
      <SelectInput
        value={serverValue}
        onChange={serverOnChange}
        onBlur={serverOnBlur}
        errorText={serverError}
        showError={serverTouched ?? true}
        placeholder={t(tk.serverLabel)}
        options={serverOption}
        fullWidth
      />
      {serverValue === Server.Local && (
        <TextInput
          value={localUrlValue}
          onChange={localUrlOnChange}
          onBlur={localUrlOnBlur}
          errorText={localUrlError}
          showError={localUrlTouched ?? true}
          label={t(tk.localUrlLabel)}
          fullWidth
        />
      )}
      {showGlobalError && (
        <FormError error={error} defaultError={t(tk.validation.defaultError)} />
      )}
    </div>
  );
};
