import React from "react";
import {
  FC,
  IconC,
  OnClickEvent,
  SizeVariant,
  StyleVariant,
  TypeVariant
} from "@laba/react-common";
import { DefaultButton } from "components/buttons/DefaultButton/DefaultButton";
import { NexupIconButton } from "components/buttons/IconButton/IconButton";
import { Add } from "components/icons";
import { TypeInvertedVariant } from "components/utils/getGenericButtonColors";

export interface IconOrDefaultButtonProps {
  className?: string;
  isIconButton?: boolean;
  icon?: IconC;
  title?: string;
  text?: string;
  onClick?: OnClickEvent;
  style?: StyleVariant;
  iconType?: TypeInvertedVariant;
  type?: TypeVariant;
  size?: SizeVariant;
  buttonRef?: React.Ref<HTMLButtonElement>;
  disabled?: boolean;
  fullWidth?: boolean;
}

export const IconOrDefaultButton: FC<IconOrDefaultButtonProps> = ({
  className,
  isIconButton,
  icon,
  title,
  text,
  onClick,
  style,
  size,
  buttonRef,
  iconType,
  type,
  disabled,
  fullWidth
}) => {
  if (isIconButton) {
    return (
      <NexupIconButton
        className={className}
        Icon={icon ?? Add}
        onClick={onClick}
        title={title}
        style={style}
        size={size}
        type={iconType}
        disabled={disabled}
        buttonRef={buttonRef}
      />
    );
  }
  return (
    <DefaultButton
      className={className}
      title={title}
      text={text ?? title}
      StartIcon={icon}
      onClick={onClick}
      style={style}
      size={size}
      type={type}
      buttonRef={buttonRef}
      disabled={disabled}
      fullWidth={fullWidth}
    />
  );
};
