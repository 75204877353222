import {
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant,
  createStyle,
  marginMixin,
  paddingMixin,
  flexRowMixin,
  pxToRem,
  getClassName,
  FlexAlignItems
} from "@laba/react-common";
import { isEqual } from "lodash-es";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { AccordionSummaryMinHeight } from "./types";

interface AccordionSummaryDynamicStylesProps {
  textVariant: TextVariant;
  isExpanded: boolean;
  showArrowLeft?: boolean;
  withTopBottomMargin?: boolean;
  accordionSummaryMinHeightVariant?: AccordionSummaryMinHeight;
}

interface AccordionSummaryClassNames {
  root: string;
  expanded: string;
  content: string;
  expandIcon: string;
  text: string;
  edgeEnd: string;
}

const useAccordionSummaryStaticStyles = createStyle(
  (theme: Theme) => ({
    /* Next line overrides material-ui styles */
    summarySmallMinHeight: {
      minHeight: pxToRem(32),
      "&$expanded": {
        minHeight: pxToRem(32)
      }
    },
    summaryLargeMinHeight: {
      minHeight: pxToRem(40),
      "&$expanded": {
        minHeight: pxToRem(40)
      }
    },
    expanded: {},
    contentMargin0: {
      "&$expanded": { ...marginMixin({ all: 0 }) }
    },
    expandIcon: {
      ...marginMixin({ left: 8 }),
      ...paddingMixin({ all: 0 }),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    edgeEnd: { ...marginMixin({ all: 0 }) }
  }),
  "AccordionSummary"
);

const useAccordionSummaryDynamicStyles = createStyle(
  (theme: Theme) => ({
    root: (props: AccordionSummaryDynamicStylesProps) => ({
      ...flexRowMixin({
        gap: 8,
        reverseOrder: props.showArrowLeft,
        alignItems: FlexAlignItems.Center
      }),
      ...paddingMixin({
        left: props.showArrowLeft ? 16 : 0,
        right: 0
      })
    }),
    text: (props: AccordionSummaryDynamicStylesProps) => ({
      ...themeTextMixin(theme, props.textVariant),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        props.isExpanded
          ? ThemeStyleColorVariant.Main
          : ThemeStyleColorVariant.GrayDark
      )
    }),
    content: (props: AccordionSummaryDynamicStylesProps) => ({
      ...marginMixin({ topBottom: !props.withTopBottomMargin ? 0 : undefined }),
      width: "100%"
    })
  }),
  "AccordionSummaryDynamic"
);

export const useAccordionSummaryStyle = (
  props: AccordionSummaryDynamicStylesProps
): AccordionSummaryClassNames => {
  const { accordionSummaryMinHeightVariant } = props;
  const {
    summarySmallMinHeight,
    summaryLargeMinHeight,
    expanded,
    contentMargin0,
    expandIcon,
    edgeEnd
  } = useAccordionSummaryStaticStyles();
  const { root, content, text } = useAccordionSummaryDynamicStyles(props);

  return {
    root: getClassName(
      isEqual(accordionSummaryMinHeightVariant, AccordionSummaryMinHeight.Large)
        ? summaryLargeMinHeight
        : summarySmallMinHeight,
      root
    ),
    expanded,
    content: getClassName(contentMargin0, content),
    expandIcon,
    text,
    edgeEnd
  };
};
