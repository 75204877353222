import { getKeyObj, HttpMethod } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { ApiRequestResponse } from "request/types";
import { getIntegrationApiUrl, request } from "request/nexupRequest";

export enum AlfabetaMode {
  Product = "Product",
  Contract = "Contract",
  Medication = "Medication"
}

export enum PriceMode {
  PerProduct = "PerProduct",
  PerUnit = "PerUnit"
}

export enum ProcessMode {
  All = "All",
  HisCodeBased = "HisCodeBased"
}

export interface AlfabetaRequestBody<T = File> {
  codeFile?: T;
  mode?: AlfabetaMode;
  organizationId?: string;
  practitionerId?: string;
  priceMode?: PriceMode;
  processMode?: ProcessMode;
}

export const AlfabetaRequestBodyParamsKey = getKeyObj(
  createHydratedMock<AlfabetaRequestBody<string>>()
);

export const alfabetaRequest = async (
  params: AlfabetaRequestBody
): Promise<ApiRequestResponse<Blob>> => {
  const data = new FormData();
  if (params.codeFile)
    data.append(AlfabetaRequestBodyParamsKey.codeFile, params.codeFile);
  if (params.mode) data.set(AlfabetaRequestBodyParamsKey.mode, params.mode);
  if (params.organizationId)
    data.set(
      AlfabetaRequestBodyParamsKey.organizationId,
      params.organizationId
    );
  if (params.practitionerId)
    data.set(
      AlfabetaRequestBodyParamsKey.practitionerId,
      params.practitionerId
    );
  if (params.priceMode)
    data.set(AlfabetaRequestBodyParamsKey.priceMode, params.priceMode);
  if (params.processMode)
    data.set(AlfabetaRequestBodyParamsKey.processMode, params.processMode);
  return request({
    method: HttpMethod.POST,
    url: getIntegrationApiUrl("alfabeta"),
    data,
    responseType: "blob"
  });
};
