export const medicationSubstance = {
  title: "Ingredientes",
  filters: {
    organization: "Organizacion",
    code: "Codigo"
  },
  fields: {
    id: "Id",
    organization: "Organizacion",
    name: "Nombre",
    code: "Codigo"
  },
  tabs: {
    main: "Ingrediente"
  }
};
