import { Quantity } from "model/primitives/quantity";
import { ResourceType } from "model/primitives/resourceModel";
import { getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { Observation, ObservationCategory } from "./observation";

export enum KnownPatientMeasureUnits {
  kg = "kg",
  m = "m",
  kg_m2 = "kg/m2",
  ml_min = "ml/min"
}

export interface PatientMeasure
  extends Observation<
    ResourceType.PatientMeasure,
    ObservationCategory.Measure
  > {
  value?: Quantity;
  range?: string;
}

export const PatientMeasureParamsKey = getKeyObj(
  createHydratedMock<PatientMeasure>()
);
