import { Attachment } from "model/primitives/attachment/attachment";
import produce from "immer";
import { OpenCode } from "@laba/ts-common";
import { Identifier } from "../../primitives/identifier";
import {
  Email,
  Phone,
  removeEmptyContactPointValues
} from "../../primitives/contactPoint";
import { Address } from "../../primitives/address";
import { ResourceModel, ResourceType } from "../../primitives/resourceModel";

export enum Gender {
  Male = "male",
  Female = "female",
  Other = "other",
  Unknown = "unknown"
}

export enum MaritalStatus {
  Single = "S",
  Married = "M",
  Divorced = "D",
  Widowed = "W",
  DomesticPartner = "T",
  Unknown = "UNK"
}

export enum KnownCitizenship {
  Argentinian = "Argentinian",
  Brazilians = "Brazilians",
  Bolivians = "Bolivians",
  Chileans = "Chileans",
  Colombians = "Colombians",
  Paraguayan = "Paraguayan",
  Uruguayan = "Uruguayan",
  German = "German",
  Chinese = "Chinese",
  Spanish = "Spanish",
  French = "French",
  Italian = "Italian",
  Mexican = "Mexican",
  Peruvian = "Peruvian",
  Russian = "Russian",
  Ukrainian = "Ukrainian",
  Venezuelan = "Venezuelan"
}

export type Citizenship = OpenCode<KnownCitizenship>;

export enum ValidationStatus {
  Permanent = "Permanent",
  Temporal = "Temporal"
}

export enum PatientCreationType {
  Creation = "Creation",
  SimpleCreation = "SimpleCreation"
}

export interface PersonalData {
  firstName: string;
  otherName?: string;
  lastName: string;
  motherLastName?: string;
  birthDate?: string;
  gender: Gender;
  identifierList?: Identifier[];
  maritalStatus?: MaritalStatus;
  citizenship?: Citizenship;
  profilePicture?: Attachment;
}

export interface ContactData {
  phoneList?: Phone[];
  emailList?: Email[];
  addressList?: Address[];
}

export interface BasePerson<RType extends ResourceType>
  extends ResourceModel<RType> {
  federationId?: string;
  personalData: PersonalData;
  contactData?: ContactData;
  validationStatus: ValidationStatus;
  hisCode?: string;
}

export type Person = BasePerson<ResourceType.Person>;

export const removeEmptyContactDataValues = (
  contactData: ContactData
): ContactData =>
  produce(contactData, draftState => {
    draftState.emailList = removeEmptyContactPointValues(
      draftState.emailList ?? []
    );
    draftState.phoneList = removeEmptyContactPointValues(
      draftState.phoneList ?? []
    );
  });
