import React from "react";
import { FC, getClassName } from "@laba/react-common";
import { WarningIcon } from "components/icons";
import { useFilePreviewDialogInvalidFormatCardStyles } from "components/file/FileListPreviewPopup/FilePreviewDialogInvalidFormatCard/useFilePreviewDialogInvalidFormatCardStyles";

export interface FilePreviewDialogInvalidFormatCardProps {
  className?: string;
  title?: string;
  description?: string;
}
export const FilePreviewDialogInvalidFormatCard: FC<
  FilePreviewDialogInvalidFormatCardProps
> = ({ className, title, description }) => {
  const classes = useFilePreviewDialogInvalidFormatCardStyles();
  return (
    <div className={getClassName(className, classes.root)}>
      <WarningIcon className={classes.warningIcon} />
      <p className={classes.title}>{title}</p>
      <p className={classes.description}>{description}</p>
    </div>
  );
};
