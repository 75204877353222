import React from "react";
import { FC, getClassName, SizeVariant } from "@laba/react-common";
import { DirectionsIcon, PhoneIcon, PublicIcon } from "components/icons";
import { TypeInvertedVariant } from "components/utils/getGenericButtonColors";
import { NexupIconButton } from "components/buttons/IconButton/IconButton";
import { useLocationCardButtonsStyles } from "components/card/LocationCard/LocationCardButtons/useLocationCardButtonsStyles";
import { Noop } from "@laba/ts-common";

interface LocationCardButtonsProps {
  className?: string;
  phone?: string;
  onPhoneClicked?: Noop;
  website?: string;
  onWebsiteClicked?: Noop;
  address?: string;
  onAddressClicked?: Noop;
}

export const LocationCardButtons: FC<LocationCardButtonsProps> = ({
  className,
  phone,
  onPhoneClicked,
  website,
  onWebsiteClicked,
  address,
  onAddressClicked
}) => {
  const classes = useLocationCardButtonsStyles();
  return (
    <div className={getClassName(className, classes.root)}>
      {phone && (
        <NexupIconButton
          className={classes.button}
          Icon={PhoneIcon}
          type={TypeInvertedVariant.Normal}
          size={SizeVariant.Small}
          title={phone}
          onClick={() => {
            onPhoneClicked?.();
          }}
        />
      )}
      {website && (
        <NexupIconButton
          className={classes.button}
          Icon={PublicIcon}
          type={TypeInvertedVariant.Outlined}
          size={SizeVariant.Small}
          title={website}
          onClick={() => {
            onWebsiteClicked?.();
          }}
        />
      )}
      {address && (
        <NexupIconButton
          className={classes.button}
          Icon={DirectionsIcon}
          type={TypeInvertedVariant.Outlined}
          size={SizeVariant.Small}
          title={address}
          onClick={() => {
            onAddressClicked?.();
          }}
        />
      )}
    </div>
  );
};
