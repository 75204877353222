import { BaseVersionedModel, getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { keys } from "lodash-es";
import { ListQueryParams } from "request/types";

export interface WhatsappReceivedMessage extends BaseVersionedModel {
  receiver?: string;
  receiverId?: string;
  sender?: string;
  senderId?: string;
  externalId?: string;
  message?: string;
}

export interface WhatsappReceivedMessageListQueryParams
  extends ListQueryParams {
  receiver?: string;
}

export const WhatsappReceivedMessageListQueryParamsKey =
  getKeyObj<WhatsappReceivedMessageListQueryParams>(
    createHydratedMock<WhatsappReceivedMessageListQueryParams>()
  );

export const WhatsappReceivedMessageKeysList = keys(
  WhatsappReceivedMessageListQueryParamsKey
);
