import { HTMLDivElementOrNull } from "components/types";
import {
  MutableRefObject,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";

export interface IsDivOverflow {
  ref: MutableRefObject<HTMLDivElementOrNull>;
  isOverflow: boolean;
}
export const useIsDivOverflow = (
  callback?: (hasOverflow: boolean) => void
): IsDivOverflow => {
  const [isOverflow, setIsOverflow] = useState<boolean>(false);
  const ref = useRef<HTMLDivElementOrNull>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      const hasOverflow =
        Number(ref.current.scrollWidth) > Number(ref.current.clientWidth);

      setIsOverflow(hasOverflow);

      if (callback) callback(hasOverflow);
    }
  }, [ref, callback, setIsOverflow]);

  return useMemo(
    () => ({
      ref,
      isOverflow
    }),
    [ref, isOverflow]
  );
};
