import { FC } from "@laba/react-common";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import React from "react";

export const StopShareScreenIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.25 4H19.75C20.3467 4 20.919 4.23705 21.341 4.65901C21.7629 5.08097 22 5.65326 22 6.25V17.75C22 18.0455 21.9418 18.3381 21.8287 18.611C21.7157 18.884 21.5499 19.1321 21.341 19.341C21.1321 19.5499 20.884 19.7157 20.611 19.8287C20.3381 19.9418 20.0455 20 19.75 20H4.25C3.95453 20 3.66194 19.9418 3.38896 19.8287C3.11598 19.7157 2.86794 19.5499 2.65901 19.341C2.45008 19.1321 2.28434 18.884 2.17127 18.611C2.0582 18.3381 2 18.0455 2 17.75V6.25C2 5.65326 2.23705 5.08097 2.65901 4.65901C3.08097 4.23705 3.65326 4 4.25 4ZM9.28 8.215C9.13783 8.08252 8.94978 8.0104 8.75548 8.01383C8.56118 8.01725 8.37579 8.09597 8.23838 8.23338C8.10097 8.37079 8.02225 8.55618 8.01882 8.75048C8.0154 8.94478 8.08752 9.13282 8.22 9.275L10.94 11.995L8.22 14.72C8.08752 14.8622 8.0154 15.0502 8.01882 15.2445C8.02225 15.4388 8.10097 15.6242 8.23838 15.7616C8.37579 15.899 8.56118 15.9777 8.75548 15.9812C8.94978 15.9846 9.13783 15.9125 9.28 15.78L12 13.057L14.724 15.78C14.7927 15.8537 14.8755 15.9128 14.9675 15.9538C15.0595 15.9948 15.1588 16.0168 15.2595 16.0186C15.3602 16.0204 15.4602 16.0018 15.5536 15.9641C15.647 15.9264 15.7318 15.8703 15.803 15.799C15.8743 15.7278 15.9304 15.643 15.9681 15.5496C16.0058 15.4562 16.0244 15.3562 16.0226 15.2555C16.0208 15.1548 15.9988 15.0555 15.9578 14.9635C15.9168 14.8715 15.8577 14.7887 15.784 14.72L13.061 11.996L15.784 9.281C15.8537 9.2114 15.9091 9.12875 15.9469 9.03776C15.9847 8.94677 16.0041 8.84923 16.0042 8.75071C16.0043 8.65218 15.985 8.55461 15.9474 8.46355C15.9098 8.37249 15.8546 8.28973 15.785 8.22C15.7154 8.15027 15.6327 8.09493 15.5418 8.05714C15.4508 8.01935 15.3532 7.99985 15.2547 7.99976C15.1562 7.99967 15.0586 8.01898 14.9675 8.0566C14.8765 8.09421 14.7937 8.1494 14.724 8.219L12.001 10.936L9.28 8.215Z"
          fill={props.fill ?? "white"}
        />
      </svg>
    </SvgIcon>
  );
};
