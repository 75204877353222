import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { ModelId } from "model/primitives/model/model";
import { getKeyObj, HttpMethod } from "@laba/ts-common";
import { Product } from "model/resource/finance/product/product";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { createHydratedMock } from "ts-auto-mock";
import { commonFileUploadRequestHandler } from "api/utils";

export enum ProductListOrder {
  Name = "name",
  CreationDate = "creationDate",
  LastUpdateDate = "lastUpdateDate"
}

interface BaseProductQueryParams {
  category?: string[];
  organization?: ModelId;
  tag?: string[];
  status?: string[];
  subcategory?: string[];
}

export interface ExportProductsQueryParams extends BaseProductQueryParams {
  email?: string;
  template?: boolean;
}

export interface ProductDetailQueryParams {
  withPractitioner?: boolean;
  withOrganization?: boolean;
}
export interface ProductListQueryParams
  extends ListQueryParams,
    ProductDetailQueryParams,
    BaseProductQueryParams {
  order?: ProductListOrder;
  content?: string;
  code?: string;
  onlyForBillingContract?: boolean;
}

export const ProductListQueryParamsKey = getKeyObj(
  createHydratedMock<ProductListQueryParams>()
);

const ProductBasePath = "/product";

export const getProductList = (
  params?: ProductListQueryParams
): Promise<ApiPageRequestResponse<Product>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(ProductBasePath),
    params
  });

export const createProduct = (
  data: Product
): Promise<ApiRequestResponse<Product>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(ProductBasePath),
    data
  });

export const updateProduct = (
  data: Product
): Promise<ApiRequestResponse<Product>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${ProductBasePath}/${data.id}`),
    data
  });

export const getProductDetail = (
  id: ModelId,
  params?: ProductDetailQueryParams
): Promise<ApiRequestResponse<Product>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${ProductBasePath}/${id}`),
    params
  });

export const exportProducts = (
  params?: ExportProductsQueryParams
): Promise<ApiRequestResponse<Blob>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${ProductBasePath}/export`),
    params,
    responseType: "blob"
  });

export const importProducts = (
  organization: ModelId,
  file: File
): Promise<ApiRequestResponse<unknown>> => {
  return commonFileUploadRequestHandler(ProductBasePath, organization, file);
};
