import React from "react";
import { FC } from "@laba/react-common";
import {
  QuestionnaireForm,
  questionnaireJsonToModel,
  questionnaireTransform
} from "components/pages/NexupAdmin/resources/questionnaire/QuestionnaireForm";
import { Edit } from "components/generic/Edit/Edit";
import { Questionnaire, ResourceType } from "@laba/nexup-api";
import { useElementExportedFileName } from "components/hook/useExportedFileName";

export const QuestionnaireEdit: FC = () => {
  const exportedFileName = useElementExportedFileName<Questionnaire>(
    resource => resource.name
  );
  return (
    <Edit
      resourceTransform={questionnaireTransform}
      resource={ResourceType.Questionnaire}
      jsonToModel={questionnaireJsonToModel}
      exportedFileName={exportedFileName}
    >
      <QuestionnaireForm />
    </Edit>
  );
};
