export const healthcareService = {
  title: "Servicios",
  filters: {
    organization: "Organizacion",
    location: "Lugar",
    practitioner: "Profesional",
    type: "Tipo"
  },
  fields: {
    id: "Id",
    organization: "Organizacion",
    name: "Nombre",
    category: "Categoria",
    type: "Tipo",
    active: "Activo",
    description: "Descripcion",
    photoUrl: "Icono",
    photoText: "Texto alternativo icono",
    organizationDefault: "Por defecto",
    locationList: "Lugares",
    locationListElement: "Lugar",
    availableTime: "Horarios",
    availableTimeDaysOfWeek: "Dias de la semana",
    availableTimeDaysOfWeekElement: "Dia",
    availableTimeAllDay: "Todo el dia",
    availableTimeStartTime: "Hora de apertura (HH:MM:SS)",
    availableTimeEndTime: "Hora de cierre (HH:MM:SS)",
    consultationReasonSystem: "Sistema de razones de consulta",
    feedbackUrl: "Url feedback",
    templateList: "Listado de Formatos",
    templateListCode: "Codigo",
    templateListTemplate: "Formato",

    isActive: "Activo",
    isNotActive: "Inactivo"
  },
  tabs: {
    healthcareService: "Servicio"
  }
};
