import { ResourceModel, ResourceType } from "model/primitives/resourceModel";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { Practitioner } from "model/resource/person/practitioner/practitioner";
import { Organization } from "model/resource/entities/organization";
import {
  Account,
  Money,
  Period,
  Product as ProductModel
} from "model/resource";
import {
  getEnumOrUndefined,
  getKeyObj,
  KeyObj,
  ModelId,
  Optional
} from "@laba/ts-common";
import { Location } from "model/resource/entities/location/location";
import { MedicalRequestDefinition } from "model/resource/prescription/medicalRequestDefinition/medicalRequestDefinition";
import { Medication } from "model/resource/prescription/medication/medication";
import { MedicalDevice } from "model/resource/prescription/medicalDevice";
import { Code } from "model/resource/entities/codeSystem";
import { createHydratedMock } from "ts-auto-mock";

export enum KnownContractStatus {
  EnteredInError = "entered-in-error",
  Draft = "executable",
  Executed = "executed",
  Disputed = "disputed"
}

export enum BillingContractStatus {
  Expired = "expired",
  Draft = "executable",
  Executed = "executed",
  Disputed = "disputed"
}

export enum KnownContractType {
  BillingContract = "BillingContract"
}

export enum KnownBillingContractType {
  Patient = "Patient",
  Payer = "Payer"
}

export enum KnownContractTermType {
  Product = "Product",
  MedicalRequest = "MedicalRequest",
  MedicalAdministration = "MedicalAdministration",
  Hospitalization = "Hospitalization"
}

export interface BillingContractPayment {
  price?: Money;
  percentage?: number;
}

export interface CommonBillingContractTermFields {
  id?: ModelId;
  plan?: string[];
  location?: ModelReference<Location>[];
}

export interface CommonBillingContractTerm<T extends KnownContractTermType>
  extends CommonBillingContractTermFields {
  type?: T;
}

export interface BillingContractProductQuantity {
  product: ModelReference<ProductModel>;
  quantity?: number;
}

export interface BillingContractProductTerm
  extends CommonBillingContractTerm<KnownContractTermType.Product> {
  product?: ModelReference<ProductModel>;
  payerPayment?: BillingContractPayment;
  patientPayment?: BillingContractPayment;
  replacement?: BillingContractProductQuantity[];
}

export interface BillingContractMedTerm
  extends CommonBillingContractTerm<
    | KnownContractTermType.MedicalAdministration
    | KnownContractTermType.MedicalRequest
  > {
  medicalRequestDefinition?: ModelReference<MedicalRequestDefinition>[];
  medication?: ModelReference<Medication>[];
  device?: ModelReference<MedicalDevice>[];
  consumptionConfig?: BillingContractProductQuantity[];
}

export interface BillingContractHospitalizationTerm
  extends CommonBillingContractTerm<KnownContractTermType.Hospitalization> {
  tag?: Code[];
  consumptionConfig?: BillingContractProductQuantity[];
}

export interface BillingContract
  extends ResourceModel<ResourceType.BillingContract> {
  originalPractitioner?: ModelReference<Practitioner>;
  lastEditor?: ModelReference<Practitioner>;
  organization?: ModelReference<Organization>;
  status?: KnownContractStatus;
  account?: ModelReference<Account>;
  period?: Period;
  title?: string;
  type?: KnownContractType;
  subType?: KnownBillingContractType;
  isMacro?: boolean;
  partOf?: ModelReference<BillingContract>[];
  skipConsumptionRecalculation?: boolean;
  productTerm?: BillingContractProductTerm[];
  medTerm?: BillingContractMedTerm[];
  hospitalizationTerm?: BillingContractHospitalizationTerm[];
}

export const getKnownContractStatusFromBillingContractStatus = (
  status?: BillingContractStatus
): Optional<KnownContractStatus> => {
  return getEnumOrUndefined(KnownContractStatus)(status);
};

export const createBaseBillingContract = (
  status: KnownContractStatus = KnownContractStatus.Draft,
  organization?: ModelId
): BillingContract => {
  return {
    resourceType: ResourceType.BillingContract,
    isMacro: false,
    status,
    organization
  };
};

export const createBaseBillingContractProductTerm =
  (): BillingContractProductTerm => {
    return {
      type: KnownContractTermType.Product
    };
  };

export const createBaseBillingContractMedTerm = (): BillingContractMedTerm => {
  return {
    type: KnownContractTermType.MedicalAdministration
  };
};

export const createBaseBillingContractHospitalizationTerm =
  (): BillingContractHospitalizationTerm => {
    return {
      type: KnownContractTermType.Hospitalization
    };
  };

export const BillingContractParamsKey: KeyObj<BillingContract> = getKeyObj(
  createHydratedMock<BillingContract>()
);

export const CommonBillingContractTermFieldsParamsKey: KeyObj<CommonBillingContractTermFields> =
  getKeyObj(createHydratedMock<CommonBillingContractTermFields>());

export const BillingContractProductTermParamsKey: KeyObj<BillingContractProductTerm> =
  getKeyObj(createHydratedMock<BillingContractProductTerm>());

export const BillingContractMedTermParamsKey: KeyObj<BillingContractMedTerm> =
  getKeyObj(createHydratedMock<BillingContractMedTerm>());

export const BillingContractHospitalizationTermParamsKey: KeyObj<BillingContractHospitalizationTerm> =
  getKeyObj(createHydratedMock<BillingContractHospitalizationTerm>());

export const BillingContractProductQuantityParamsKey: KeyObj<BillingContractProductQuantity> =
  getKeyObj(createHydratedMock<BillingContractProductQuantity>());
