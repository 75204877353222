import React from "react";
import { FC, useI18n } from "@laba/react-common";
import { Edit } from "components/generic/Edit/Edit";
import { ExternalResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { tkCP } from "translation/i18n";
import { useElementExportedFileName } from "components/hook/useExportedFileName";
import { WhatsappNotificationForm } from "./WhatsappNotificationForm";
import {
  whatsappNotificationJsonToModel,
  whatsappNotificationTransform
} from "./utils";

const tk = tkCP.adminPage[ExternalResourceType.WhatsappNotification];

export const WhatsappNotificationEdit: FC = () => {
  const { t } = useI18n();
  const exportedFileName = useElementExportedFileName(() => t(tk.title));
  return (
    <Edit
      resourceTransform={whatsappNotificationTransform}
      resource={ExternalResourceType.WhatsappNotification}
      jsonToModel={whatsappNotificationJsonToModel}
      exportedFileName={exportedFileName}
    >
      <WhatsappNotificationForm />
    </Edit>
  );
};
