import {
  IconC,
  OnClickEvent,
  stopPropagationWrapper,
  withMemo
} from "@laba/react-common";
import { SizeVariant, StyleVariant } from "model/themeVariant";
import { useMuiTheme } from "model/useMuiTheme";
import { ThemeProvider } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import React, { FC } from "react";
import {
  BadgePosition,
  BadgeVariant,
  BadgedComponent
} from "components/containers/BadgedComponent/BadgedComponent";
import { TypeInvertedVariant } from "components/utils/getGenericButtonColors";
import { useIconButtonStyleClasses } from "components/buttons/IconButton/IconButtonStyle";

export enum IconButtonType {
  Submit = "submit",
  Reset = "reset",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Button = "button"
}

export interface IconButtonProps {
  className?: string;
  title?: string;
  Icon: IconC;
  onClick?: OnClickEvent;
  disabled?: boolean;
  size?: SizeVariant;
  type?: TypeInvertedVariant;
  style?: StyleVariant;
  buttonType?: IconButtonType;
  buttonRef?: React.Ref<HTMLButtonElement>;
  withBadge?: boolean;
  badgePosition?: BadgePosition;
  badgeStyle?: StyleVariant;
  badgeVariant?: BadgeVariant;
  badgeContent?: number;
  isBadgeVisible?: boolean;
  circular?: boolean;
}

const NexupIconButtonInt: FC<IconButtonProps> = ({
  className,
  Icon,
  onClick,
  title,
  disabled = false,
  style = StyleVariant.Primary,
  type = TypeInvertedVariant.Normal,
  size = SizeVariant.Medium,
  buttonType = IconButtonType.Button,
  buttonRef,
  withBadge = false,
  badgePosition,
  badgeStyle,
  badgeVariant,
  badgeContent,
  isBadgeVisible,
  circular = false
}) => {
  const classes = useIconButtonStyleClasses({
    size,
    style,
    type,
    disabled,
    circular
  });
  const muiTheme = useMuiTheme(style);
  return (
    <ThemeProvider theme={muiTheme}>
      <IconButton
        classes={classes}
        className={className}
        title={title}
        onClick={stopPropagationWrapper(onClick)}
        disabled={disabled}
        type={buttonType}
        ref={buttonRef}
      >
        {withBadge ? (
          <BadgedComponent
            badgeContent={badgeContent}
            position={badgePosition}
            variant={badgeVariant}
            style={badgeStyle}
            isVisible={isBadgeVisible}
          >
            <Icon className={classes.icon} />
          </BadgedComponent>
        ) : (
          <Icon className={classes.icon} />
        )}
      </IconButton>
    </ThemeProvider>
  );
};

export const NexupIconButton = withMemo(NexupIconButtonInt);
