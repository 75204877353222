import { Optional } from "@laba/ts-common";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { ResourceType } from "model/primitives/resourceModel";
import { getModelReferenceId } from "model/primitives/modelReference/utils";
import { Practitioner } from "model/resource/person/practitioner/practitioner";
import { head } from "lodash-es";
import {
  Diagnosis,
  DiagnosisContainer,
  DiagnosisRole,
  KnownDiagnosisRole
} from "model/resource/medical/condition/diagnosis";
import {
  Code,
  KnownCodeSystemSystem
} from "model/resource/entities/codeSystem";
import { Hospitalization } from "model/resource/medical/hospitalization/hospitalization";
import {
  Condition,
  ConditionCategory,
  ConditionClinical
} from "model/resource/medical/condition/condition";
import { Patient } from "model/resource/person/patient/patient";

export const getConditionDisplayName = (
  condition?: Condition
): Optional<string> => condition?.code?.name ?? condition?.code?.code;

export const newProblemFromHistoric = (
  problem: Condition,
  hospitalization?: ModelReference<Hospitalization>,
  patient?: Patient,
  practitioner?: Practitioner
): Condition => {
  return {
    resourceType: ResourceType.Condition,
    clinicalStatus: ConditionClinical.Active,
    category: ConditionCategory.PatientProblem,
    practitioner: practitioner?.id,
    patient: patient?.id,
    code: problem.code,
    encounter: getModelReferenceId(hospitalization)
  };
};

export const getDiagnosisContainerByRole = (
  diagnosis?: DiagnosisContainer[],
  role?: DiagnosisRole
): Diagnosis[] => {
  return diagnosis?.filter(d => d.use === role).map(d => d.condition) ?? [];
};

export const getAdmissionDiagnosis = (
  diagnosis?: DiagnosisContainer[]
): Diagnosis[] => {
  return getDiagnosisContainerByRole(diagnosis, KnownDiagnosisRole.AD);
};

export const getFirstAdmissionDiagnosis = (
  diagnosis?: DiagnosisContainer[]
): Optional<Diagnosis> => {
  return head(getAdmissionDiagnosis(diagnosis));
};

export const getSantaCatalinaClassificationDiagnosis = (
  diagnosis?: DiagnosisContainer[]
): Diagnosis[] => {
  return getDiagnosisContainerByRole(
    diagnosis,
    KnownDiagnosisRole.SantaCatalinaClassification
  );
};

export const getFirstSantaCatalinaClassificationDiagnosis = (
  diagnosis?: DiagnosisContainer[]
): Optional<Diagnosis> => {
  return head(getSantaCatalinaClassificationDiagnosis(diagnosis));
};

export const getMedicalAdmissionDiagnosisDiagnosis = (
  diagnosis?: DiagnosisContainer[]
): Diagnosis[] => {
  return getDiagnosisContainerByRole(
    diagnosis,
    KnownDiagnosisRole.MedicalAdmissionDiagnosis
  );
};

export const getFirstMedicalAdmissionDiagnosisDiagnosis = (
  diagnosis?: DiagnosisContainer[]
): Optional<Diagnosis> => {
  return head(getMedicalAdmissionDiagnosisDiagnosis(diagnosis));
};

export const createClassificationCondition = (code: Code): Condition => {
  return {
    resourceType: ResourceType.Condition,
    clinicalStatus: ConditionClinical.Active,
    code: {
      code,
      system: KnownCodeSystemSystem.DiagnosisRole
    }
  };
};

export const createBaseCondition = (
  code: string,
  name?: string,
  status = ConditionClinical.Active,
  system: string = KnownCodeSystemSystem.ConditionList,
  category = ConditionCategory.PatientProblem
): Condition => {
  return {
    resourceType: ResourceType.Condition,
    clinicalStatus: status,
    code: { code, name, system },
    category
  };
};

export const createDiagnosisContainerFromCondition = (
  condition: Condition,
  use: KnownDiagnosisRole
): DiagnosisContainer => {
  return { condition, use };
};
