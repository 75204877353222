import {
  createPractitioner,
  getModelReferenceId,
  getPractitioner,
  getPractitionerList,
  PractitionerListQueryParams,
  PractitionerListQueryParamsKey,
  PractitionerOrder,
  ResourceType,
  updatePractitioner
} from "@laba/nexup-api";
import {
  getEnumOrderParam,
  getKeyTargetParam,
  resourceProviderGetter
} from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { workspaceOrganizationSelector } from "store/workspace/selectors";
import { store } from "store/store";

export const practitionerProvider = resourceProviderGetter(
  ResourceType.Practitioner,
  getEnumOrderParam(PractitionerOrder),
  getKeyTargetParam<PractitionerListQueryParams>(
    PractitionerListQueryParamsKey
  ),
  getPractitionerList,
  getPractitioner,
  updatePractitioner,
  createPractitioner,
  undefined,
  () => ({
    organization: getModelReferenceId(
      workspaceOrganizationSelector(store.getState())
    ),
    withOrganization: true
  })
);
