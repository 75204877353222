export enum NotificationActionCode {
  AppointmentCreateForPatient = "AppointmentCreateForPatient",
  AppointmentCreateForPractitioner = "AppointmentCreateForPractitioner",
  AppointmentCreateSeriesForPatient = "AppointmentCreateSeriesForPatient",
  AppointmentCreateSeriesForPractitioner = "AppointmentCreateSeriesForPractitioner",
  AppointmentEditForPatient = "AppointmentEditForPatient",
  AppointmentEditForPractitioner = "AppointmentEditForPractitioner",
  AppointmentEditSeriesForPatient = "AppointmentEditSeriesForPatient",
  AppointmentEditSeriesForPractitioner = "AppointmentEditSeriesForPractitioner",
  AppointmentEditPatientDataForPatient = "AppointmentEditPatientDataForPatient",
  AppointmentEditSeriesPatientDataForPatient = "AppointmentEditSeriesPatientDataForPatient",
  AppointmentCancelForPatient = "AppointmentCancelForPatient",
  AppointmentCancelForPractitioner = "AppointmentCancelForPractitioner",
  AppointmentCancelSeriesForPatient = "AppointmentCancelSeriesForPatient",
  AppointmentCancelSeriesForPractitioner = "AppointmentCancelSeriesForPractitioner",
  AppointmentAdmitForPatient = "AppointmentAdmitForPatient",
  AppointmentAdmitForPractitioner = "AppointmentAdmitForPractitioner",
  AppointmentAttendForPatient = "AppointmentAttendForPatient",
  AppointmentAttendForPractitioner = "AppointmentAttendForPractitioner",
  AppointmentFinishForPatient = "AppointmentFinishForPatient",
  AppointmentFinishForPractitioner = "AppointmentFinishForPractitioner",
  AppointmentReminderForPatient = "AppointmentReminderForPatient",
  AppointmentReminderForPractitioner = "AppointmentReminderForPractitioner",
  AppointmentStartingReminderForPatient = "AppointmentStartingReminderForPatient",
  AppointmentConfirmationReminderForPatient = "AppointmentConfirmationReminderForPatient"
}
