import { ResourceType } from "@laba/nexup-api";
import { practitioner } from "translation/common/page/practitioner";
import { organization } from "translation/common/page/organization";
import { location } from "translation/common/page/location";
import { codeSystem } from "translation/common/page/codeSystem";
import { patient } from "translation/common/page/patient";
import { healthcareService } from "translation/common/page/healthcareService";
import { appointmentDefinition } from "translation/common/page/appointmentDefinition";
import { hospitalization } from "translation/common/page/hospitalization";
import { questionnaire } from "translation/common/page/questionnaire";
import { medicalDevice } from "translation/common/page/medicalDevice";
import { medicationSubstance } from "translation/common/page/medicationSubstance";
import { medication } from "translation/common/page/medication";
import { medicalRequestDefinition } from "translation/common/page/medicalRequestDefinition";
import { appointment } from "translation/common/page/appointment";
import {
  ConceptResourceType,
  ExternalResourceType,
  UserResourceType
} from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { user } from "translation/common/page/user";
import { measurementGroupDefinition } from "translation/common/page/measurementGroupDefinition";
import { dashboard } from "translation/common/page/dashboard";
import { procedureReportDefinition } from "translation/common/page/procedureReportDefinition";
import { scheduleDefinition } from "translation/common/page/scheduleDefinition";
import { schedule } from "translation/common/page/schedule";
import { procedureCodeConcept } from "translation/common/page/procedureCodeConcept";
import { procedureBatteryConcept } from "translation/common/page/procedureBatteryConcept";
import { notification } from "./notitication";
import { whatsappNotification } from "./whatsappNotification";
import { product } from "./product";
import { account } from "./account";

export const adminPage = {
  title: "Nexup Panel de administracion",
  dashboard,
  [ResourceType.Appointment]: appointment,
  [ResourceType.AppointmentDefinition]: appointmentDefinition,
  [ResourceType.CodeSystem]: codeSystem,
  [ResourceType.HealthcareService]: healthcareService,
  [ResourceType.Hospitalization]: hospitalization,
  [ResourceType.Location]: location,
  [ResourceType.MeasurementGroupDefinition]: measurementGroupDefinition,
  [ResourceType.MedicalDevice]: medicalDevice,
  [ResourceType.MedicalRequestDefinition]: medicalRequestDefinition,
  [ResourceType.Medication]: medication,
  [ResourceType.MedicationSubstance]: medicationSubstance,
  [ResourceType.Organization]: organization,
  [ResourceType.Patient]: patient,
  [ResourceType.Practitioner]: practitioner,
  [ResourceType.Questionnaire]: questionnaire,
  [UserResourceType]: user,
  [ResourceType.ProcedureReportDefinition]: procedureReportDefinition,
  [ResourceType.ScheduleDefinition]: scheduleDefinition,
  [ResourceType.Schedule]: schedule,
  [ResourceType.Product]: product,
  [ConceptResourceType.ProcedureCodeConcept]: procedureCodeConcept,
  [ConceptResourceType.ProcedureBatteryConcept]: procedureBatteryConcept,
  [ExternalResourceType.Notification]: notification,
  [ExternalResourceType.WhatsappNotification]: whatsappNotification,
  [ExternalResourceType.WhatsappNotificationHistory]: whatsappNotification,
  [ResourceType.Account]: account
};
