import { createSlice } from "@reduxjs/toolkit";
import { original } from "immer";
import _ from "lodash-es";
import { RouterAction, RouterActionType } from "routing/models/routerAction";
import {
  RouterLocation,
  RouterLocationDescriptor
} from "routing/models/routerLocation";
import { GenericReducer } from "store";

export interface NavigatorSlice {
  locationHistory: RouterLocation[];
  pendingActions: RouterAction[];
  currentLocationIndex: number;
}

const initialState: NavigatorSlice = {
  locationHistory: [],
  pendingActions: [],
  currentLocationIndex: -1
};

type Reducer<T = void> = GenericReducer<NavigatorSlice, T>;

const onPushLocation: Reducer<{
  location: RouterLocation;
}> = (draftState, { payload }) => {
  draftState.currentLocationIndex += 1;
  // in push action history remove all elements after the prev active history element so we do the same with locationHistory
  // if prevLocationIndex is -1 (first app push) the next statement delete all history and start over as expected
  draftState.locationHistory.splice(
    draftState.currentLocationIndex,
    draftState.locationHistory.length,
    payload.location
  );
};

const onReplaceLocation: Reducer<{
  location: RouterLocation;
}> = (draftState, { payload }) => {
  if (draftState.currentLocationIndex >= 0) {
    // in replace action history only change current the prev active history element so we do the same with locationHistory
    draftState.locationHistory[draftState.currentLocationIndex] =
      payload.location;
  } else {
    // If first app location change is replace we reset locationHistory
    draftState.currentLocationIndex = 0;
    draftState.locationHistory = [payload.location];
  }
};

const onPopLocation: Reducer<{
  location: RouterLocation;
}> = (draftState, { payload }) => {
  const popLocationIndex = draftState.locationHistory.findIndex(
    value => value.key === payload.location.key
  );
  if (popLocationIndex >= 0) {
    // in pop action history dont change history elements so we only change currentLocationIndex
    draftState.currentLocationIndex = popLocationIndex;
  } else {
    // If first app location change is pop we reset locationHistory
    draftState.currentLocationIndex = 0;
    draftState.locationHistory = [payload.location];
  }
};

const push: Reducer<{
  location: RouterLocationDescriptor;
  reload?: boolean;
}> = (draftState, { payload }) => {
  draftState.pendingActions.push({
    type: RouterActionType.Push,
    route: payload.location,
    reload: payload.reload ?? false
  });
};

const replace: Reducer<{
  location: RouterLocationDescriptor;
  reload?: boolean;
}> = (state, { payload }) => {
  state.pendingActions.push({
    type: RouterActionType.Replace,
    route: payload.location,
    reload: payload.reload ?? false
  });
};

const goBack: Reducer = state => {
  state.pendingActions.push({
    type: RouterActionType.GoBack
  });
};

const reload: Reducer = state => {
  state.pendingActions.push({
    type: RouterActionType.Reload
  });
};

const scrollToElement: Reducer<{ elementId: string }> = (
  state,
  { payload }
) => {
  state.pendingActions.push({
    type: RouterActionType.ScrollToElement,
    elementId: payload.elementId
  });
};

const succeedAction: Reducer<{ action: RouterAction }> = (
  state,
  { payload }
) => {
  const nextPendingAction = original(state.pendingActions[0]);
  if (_.isEqual(nextPendingAction, payload.action)) {
    state.pendingActions.shift();
  }
};

const reset: Reducer = () => initialState;

export const navigationSlice = createSlice({
  name: "laba-router",
  initialState,
  reducers: {
    onPushLocation,
    onReplaceLocation,
    onPopLocation,
    push,
    replace,
    goBack,
    scrollToElement,
    succeedAction,
    reset,
    reload
  }
});

export interface NavigatorCompatibleState {
  [navigationSlice.name]: NavigatorSlice;
}
