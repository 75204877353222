import React from "react";
import { ReactElementOrNull, useI18n } from "@laba/react-common";
import {
  QuestionnaireItemEnableWhenValueKey,
  ResourceType
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { DateInput } from "components/generic/DateInput/DateInput";
import { DateTimeInput } from "components/generic/DateTimeInput/DateTimeInput";
import { NumberInput } from "components/generic/NumberInput/TextInput";
import { tkCP } from "translation/i18n";

export enum QuestionnaireResponseAnswerEnum {
  valueString = "valueString",
  valueDecimal = "valueDecimal",
  valueBoolean = "valueBoolean",
  valueDate = "valueDate",
  valueDateTime = "valueDateTime",
  valueQuantity = "valueQuantity",
  valueAttachment = "valueAttachment",
  valueReferenceAllergy = "valueReferenceAllergy",
  valueReferenceProblem = "valueReferenceProblem",
  valueReferenceMeasure = "valueReferenceMeasure",
  valueReferenceEncounter = "valueReferenceEncounter",
  valueReferenceProcedureReport = "valueReferenceProcedureReport",
  valueReferenceDevice = "valueReferenceDevice",
  valueReferenceMedication = "valueReferenceMedication",
  valueCoding = "valueCoding",
  valueReferenceOdontogram = "valueReferenceOdontogram"
}

export const tk = tkCP.adminPage[ResourceType.Questionnaire].fields;

interface QuestionnaireItemEnableWhenAnswerProps {
  getSource: (source: string) => string;
  answerType?: QuestionnaireResponseAnswerEnum;
}

export const QuestionnaireItemEnableWhenAnswer = ({
  getSource,
  answerType
}: QuestionnaireItemEnableWhenAnswerProps): ReactElementOrNull => {
  const { t } = useI18n();

  switch (answerType) {
    case QuestionnaireResponseAnswerEnum.valueString:
      return (
        <TextInput
          source={getSource(
            QuestionnaireItemEnableWhenValueKey.answer.valueString
          )}
          label={t(tk.enableWhenAnswerValue)}
        />
      );
    case QuestionnaireResponseAnswerEnum.valueDecimal:
      return (
        <NumberInput
          source={getSource(
            QuestionnaireItemEnableWhenValueKey.answer.valueDecimal
          )}
          label={t(tk.enableWhenAnswerValue)}
        />
      );
    case QuestionnaireResponseAnswerEnum.valueBoolean:
      return (
        <BooleanInput
          source={getSource(
            QuestionnaireItemEnableWhenValueKey.answer.valueBoolean
          )}
          label={t(tk.enableWhenAnswerValue)}
        />
      );
    case QuestionnaireResponseAnswerEnum.valueDate:
      return (
        <DateInput
          source={getSource(
            QuestionnaireItemEnableWhenValueKey.answer.valueDate
          )}
          label={t(tk.enableWhenAnswerValueDate)}
        />
      );
    case QuestionnaireResponseAnswerEnum.valueDateTime:
      return (
        <DateTimeInput
          source={getSource(
            QuestionnaireItemEnableWhenValueKey.answer.valueDateTime
          )}
          label={t(tk.enableWhenAnswerValueDate)}
        />
      );
    case QuestionnaireResponseAnswerEnum.valueQuantity:
      return (
        <>
          <NumberInput
            source={getSource(
              QuestionnaireItemEnableWhenValueKey.answer.valueQuantity.quantity
            )}
            label={t(tk.enableWhenAnswerValue)}
          />
          <TextInput
            source={getSource(
              QuestionnaireItemEnableWhenValueKey.answer.valueQuantity.unit
            )}
            label={t(tk.enableWhenAnswerValueUnit)}
          />
        </>
      );
    case QuestionnaireResponseAnswerEnum.valueCoding:
      return (
        <TextInput
          source={getSource(
            QuestionnaireItemEnableWhenValueKey.answer.valueCoding
          )}
          label={t(tk.enableWhenAnswerValueCoding)}
        />
      );
    default:
      return null;
  }
};
