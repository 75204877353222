import { FC } from "@laba/react-common";
import React, { useEffect } from "react";
import { OnClickUrlAction, UrlLink } from "components/link/UrlLink/UrlLink";
import { isEqual, size } from "lodash-es";
import { Noop } from "@laba/ts-common";
import { ErrorToStringMapper } from "components/inputs/hooks/useInputDropzone";
import { useAttachmentCardContentStyle } from "./AttachmentCardContentStyle";
import { ToSubmitFileItem } from "./ToSubmitFileItem/ToSubmitFileItem";
import { UploadZone } from "./UploadZone/UploadZone";
import { AttachmentConfig, AttachmentError } from "../helpers";

export interface BaseAttachmentProps {
  isMobile?: boolean;
  maxFileSize?: number;
  maxFiles?: number;
  retryDownloadText?: string;
  downloadFiles?: Noop;
  downloadFilesText?: string;
  downloadFilesButtonText?: string;
  showDownloadFiles?: boolean;
  fileErrors?: AttachmentError[];
  uploadFiles?: (files: File[]) => Promise<AttachmentConfig[] | void>;
  fileValues?: AttachmentConfig[];
  onDropError?: (errors: string[]) => void;
  errorMessageMapper?: ErrorToStringMapper;
  acceptedFileExtensions?: string[];
  acceptedFileExtensionsText?: string;
  dropButtonText?: string;
  dropPromptText?: string;
  orText?: string;
  dropRejectedText?: string;
}

export interface AttachmentCardContentProps extends BaseAttachmentProps {
  failedFiles: File[];

  toSubmitFiles: AttachmentConfig[];
  setToSubmitFiles?: (files: AttachmentConfig[]) => void;
  deleteToSubmitFile: (fileToDelete: AttachmentConfig) => void;
}

export const AttachmentCardContent: FC<AttachmentCardContentProps> = ({
  isMobile,
  maxFileSize,
  maxFiles,
  uploadFiles,
  failedFiles,
  fileValues = [],
  toSubmitFiles,
  setToSubmitFiles,
  deleteToSubmitFile,
  dropButtonText,
  dropPromptText,
  orText,
  dropRejectedText,
  acceptedFileExtensions,
  acceptedFileExtensionsText,
  onDropError,
  errorMessageMapper,
  retryDownloadText,
  downloadFilesButtonText,
  downloadFilesText,
  downloadFiles,
  showDownloadFiles = false,
  fileErrors
}) => {
  const classes = useAttachmentCardContentStyle({ isMobile });

  const disabledUploadZone = isEqual(size(toSubmitFiles), maxFiles);

  useEffect(() => {
    setToSubmitFiles?.(fileValues);
  }, [fileValues, setToSubmitFiles]);

  return (
    <div className={classes.root}>
      {toSubmitFiles.map((fileToUpload, index) => {
        const fileToUploadWithError = {
          ...fileToUpload,
          error: fileErrors?.find(
            fileError => fileError.filename === fileToUpload.filename
          )?.error
        };
        return (
          <ToSubmitFileItem
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            fileItem={fileToUploadWithError}
            onClickClose={f => {
              deleteToSubmitFile(f);
            }}
            onRetryUploadFile={f => {
              const toUploadFile = failedFiles.find(
                file => file.name === f.filename
              );
              toUploadFile && uploadFiles?.([toUploadFile]);
            }}
            retryDownloadText={retryDownloadText}
          />
        );
      })}
      <UploadZone
        maxFileSize={maxFileSize}
        maxFiles={maxFiles}
        uploadFiles={uploadFiles}
        dropButtonText={dropButtonText}
        dropPromptText={dropPromptText}
        orText={orText}
        dropRejectedText={dropRejectedText}
        acceptedFileExtensions={acceptedFileExtensions}
        acceptedFileExtensionsText={acceptedFileExtensionsText}
        onDropError={onDropError}
        errorMessageMapper={errorMessageMapper}
        disabled={disabledUploadZone}
      />
      {showDownloadFiles && (
        <div className={classes.downloadFilesContainer}>
          <UrlLink
            onClick={downloadFiles}
            onClickUrlAction={OnClickUrlAction.CustomClick}
            displayText={downloadFilesButtonText}
          />
          <p className={classes.downloadFilesText}>{downloadFilesText}</p>
        </div>
      )}
    </div>
  );
};
