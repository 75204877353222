import { getKeyObj, KeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { Practitioner } from "model/resource/person/practitioner/practitioner";

export interface User {
  id?: string;
  username: string;
  email: string;
  password?: string;
  enabled?: boolean;
  name?: string;
  surname?: string;
  autogenerated?: boolean;
  assignedTo?: ModelReference<Practitioner>[];
}

export const UserKey: KeyObj<User> = getKeyObj(createHydratedMock<User>());
