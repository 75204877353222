import React from "react";
import { FC } from "@laba/react-common";
import { Edit } from "components/generic/Edit/Edit";
import {
  CodeSystemForm,
  codeSystemJsonToModel,
  codeSystemTransform
} from "components/pages/NexupAdmin/resources/codeSystem/CodeSystemForm";
import { CodeSystem, ResourceType } from "@laba/nexup-api";
import { useElementExportedFileName } from "components/hook/useExportedFileName";

export const CodeSystemEdit: FC = () => {
  const exportedFileName = useElementExportedFileName<CodeSystem>(
    resource => resource.system
  );
  return (
    <Edit
      resourceTransform={codeSystemTransform}
      resource={ResourceType.CodeSystem}
      jsonToModel={codeSystemJsonToModel}
      exportedFileName={exportedFileName}
    >
      <CodeSystemForm />
    </Edit>
  );
};
