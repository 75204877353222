import { ReactElement, useI18n } from "@laba/react-common";
import {
  AutocompleteInput,
  ReferenceInput,
  required as requiredValidate,
  Validator
} from "react-admin";
import React, { useCallback } from "react";
import { useReferenceResourceInputStyle } from "components/generic/ReferenceResourceInput/referenceResourceInputStyle";
import { RaResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { tkCC } from "translation/i18n";
import { Model, ModelId } from "@laba/nexup-api";
import { DeepPartial, getAsArray, notNull, Optional } from "@laba/ts-common";
import { ResourceTransform } from "components/generic/Create/Create";
import { ReferenceResourceCreateInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInputCreate";

export interface ReferenceResourceInputProps<T extends Model = Model> {
  source: string;
  label: string;
  resourceType: RaResourceType;
  optionTextKey: string | ((record: T) => string);
  searchFilterKey?: string;
  alwaysOn?: boolean;
  extraFilters?: Record<string, Optional<string | boolean | string[]>>;
  fullWidth?: boolean;
  disabled?: boolean;
  defaultValue?: ModelId;
  validate?: Validator | Validator[];
  required?: boolean;
  createInputList?: ReactElement[];
  createResourceTransformer?: ResourceTransform<T>;
  defaultCreateValue?: (filter: Optional<string>) => DeepPartial<T>;
  createPopupTitle?: string;
}

const tk = tkCC.inputs.referenceResourceInput;
export const ReferenceResourceInput = <T extends Model>({
  source,
  label,
  resourceType,
  optionTextKey,
  searchFilterKey = "content",
  alwaysOn = false,
  extraFilters = {},
  fullWidth = true,
  disabled = false,
  defaultValue,
  validate,
  required,
  createInputList,
  createResourceTransformer,
  defaultCreateValue,
  createPopupTitle
}: ReferenceResourceInputProps<T>): ReactElement => {
  const { t } = useI18n();
  const classes = useReferenceResourceInputStyle();
  const validateArray = getAsArray(validate).filter(notNull);
  if (required) validateArray.push(requiredValidate());
  const filterToQuery = useCallback(
    (searchText: string) => {
      return {
        [searchFilterKey]: searchText
      };
    },
    [searchFilterKey]
  );
  return (
    <ReferenceInput
      source={source}
      reference={resourceType}
      alwaysOn={alwaysOn}
      enableGetChoices={(filters: Record<string, string>) => {
        const searchLength = filters[searchFilterKey]?.length ?? 0;
        return searchLength === 0 || searchLength >= 3;
      }}
      filter={extraFilters}
      allowEmpty
    >
      <AutocompleteInput
        variant="outlined"
        fullWidth={fullWidth}
        optionText={data => {
          if (data.id === "@@ra-create") return data.name;
          return typeof optionTextKey === "function"
            ? optionTextKey(data)
            : optionTextKey;
        }}
        createLabel={t(tk.createOption)}
        createItemLabel={t(tk.createOption)}
        filterToQuery={filterToQuery}
        shouldRenderSuggestions={(value?: string) => {
          const searchLength = value?.length ?? 0;
          return searchLength >= 3;
        }}
        noOptionsText={t(tk.noOptionsText)}
        className={classes.root}
        label={label}
        parse={value => {
          return value ?? null;
        }}
        disabled={disabled}
        defaultValue={defaultValue}
        validate={validateArray}
        matchSuggestion={() => true}
        create={
          createInputList && (
            <ReferenceResourceCreateInput
              createInputList={createInputList}
              createResourceTransformer={createResourceTransformer}
              defaultCreateValue={defaultCreateValue}
              resourceType={resourceType}
              createPopupTitle={createPopupTitle}
            />
          )
        }
      />
    </ReferenceInput>
  );
};
