import React from "react";
import { FC, getClassName } from "@laba/react-common";
import { joinText, Optional } from "@laba/ts-common";
import { useStepStyles } from "./StepStyle";

interface StepProps {
  isActive?: boolean;
  text?: string;
  stepNumber: number;
  className?: string;
}

const makeStepItemText = (
  stepNumber: number,
  text?: string
): Optional<string> => {
  return joinText([stepNumber.toString(), text], ". ");
};

export const Step: FC<StepProps> = ({
  isActive,
  text,
  stepNumber,
  className
}) => {
  const classes = useStepStyles({ isActive });
  const stepText = makeStepItemText(stepNumber, text);
  return (
    <div className={getClassName(classes.container, className)}>
      <div className={classes.stepSegment} />
      <p className={classes.text}>{stepText}</p>
    </div>
  );
};
