import { FC, TypeVariant, useField, useI18n } from "@laba/react-common";
import { tkCP } from "translation/i18n";
import { DateTime, Optional, toApiDate } from "@laba/ts-common";
import React, { useCallback, useMemo } from "react";
import { isEmpty } from "lodash-es";
import { Grid, Typography } from "@mui/material";
import {
  AutocompleteOptionConfig,
  DateInput,
  DefaultButton,
  ModelReferenceAutocomplete,
  OptionsConfig,
  PhoneInput,
  RemoveIcon,
  SearchableListSimpleInput,
  SearchIcon,
  SelectInput,
  TextInput
} from "@laba/nexup-components";
import { WorkspaceConfigData } from "models/organization/workspaceConfiguration";
import {
  Gender,
  getConceptPropertyCodeList,
  getModelReferenceId,
  KnownCodeSystemSystem,
  KnownConceptPropertyUse,
  KnownSpecialEffectPropertyCode,
  Model,
  ModelReference,
  Organization,
  QualificationValidationStatus,
  User,
  UserListQueryParamsKey
} from "@laba/nexup-api";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { UploadFileImageFormInput } from "components/generic/UploadFileImageFormInput/UploadFileImageFormInput";
import { useDataProvider } from "react-admin";
import { UserResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { RaResource } from "providers/dataProvider/resourceProvider/utils/resourceProviderTypes";
import { useSelector } from "react-redux";
import { defaultWorkspaceOrganizationIdSelector } from "store/workspace/selectors";

const tk = tkCP.adminPage.dashboard.workspaceAdministration;

interface WorkspacePractitionerFieldProps {
  index: number;
  onDeleteRow?: (id: number) => void;
}

const minInputForSearch = 3;

const getKey = (index: number, path: string) =>
  `practitionerConfigList[${String(index)}].${path}`;

const genderOptions: OptionsConfig<Gender>[] = Object.values(Gender).map(x => ({
  value: x,
  itemId: x,
  text: {
    [Gender.Female]: "Femenino",
    [Gender.Male]: "Masculino",
    [Gender.Other]: "Otro",
    [Gender.Unknown]: "No especifica"
  }[x]
}));

const getUserOptionFromValue = (
  user: User
): AutocompleteOptionConfig<User> => ({
  text: `${user.username}: ${user.email || ""} (${user.id})`,
  value: user,
  title: `${user.username}: ${user.email || ""} (${user.id})`
});

const compareModelId = (v1: Model, v2: Model) => v1.id === v2.id;

export const WorkspacePractitionerField: FC<
  WorkspacePractitionerFieldProps
> = ({ index, onDeleteRow }) => {
  const { t } = useI18n();
  const dataProvider = useDataProvider();

  const baseOrganizationId = useSelector(
    defaultWorkspaceOrganizationIdSelector
  );
  const {
    input: { value: organizationValue }
  } = useField<string, string>("organizationId");
  const {
    input: { value: parentOrganizationValue }
  } = useField<ModelReference<Organization>, string>("parentOrganization");
  const organizationId =
    organizationValue ||
    getModelReferenceId(parentOrganizationValue) ||
    baseOrganizationId;

  const validateRequired = useCallback(
    (value?: unknown) => {
      return value == null || isEmpty(value)
        ? t(tk.validation.requiredField)
        : undefined;
    },
    [t]
  );

  const validateRequiredIdentifier = useCallback(
    (value?: unknown, formData?: WorkspaceConfigData) => {
      const practitionerConfig = formData?.practitionerConfigList?.[index];
      if (
        isEmpty(practitionerConfig?.identifierSystem) &&
        isEmpty(practitionerConfig?.identifierValue)
      )
        return undefined;
      return value == null || isEmpty(value)
        ? t(tk.validation.requiredField)
        : undefined;
    },
    [t, index]
  );

  const validateRequiredRegistration = useCallback(
    (value?: unknown, formData?: WorkspaceConfigData) => {
      const practitionerConfig = formData?.practitionerConfigList?.[index];
      if (
        isEmpty(practitionerConfig?.registration) &&
        isEmpty(practitionerConfig?.registrationType) &&
        isEmpty(practitionerConfig?.registrationPlace)
      )
        return undefined;
      return value == null || isEmpty(value)
        ? t(tk.validation.requiredField)
        : undefined;
    },
    [t, index]
  );

  const validateRequiredUserId = useCallback(
    (value?: unknown, formData?: WorkspaceConfigData) => {
      const practitionerConfig = formData?.practitionerConfigList?.[index];
      if (
        isEmpty(value) &&
        isEmpty(practitionerConfig?.userPassword) &&
        isEmpty(practitionerConfig?.email)
      )
        return t(tk.validation.requiredField);
      return undefined;
    },
    [t, index]
  );

  const {
    input: {
      value: firstNameValue,
      onChange: firstNameOnChange,
      onBlur: firstNameOnBlur
    },
    meta: { error: firstNameError, touched: firstNameTouched }
  } = useField<string, Optional<string>>(
    getKey(index, "firstName"),
    validateRequired
  );

  const {
    input: {
      value: lastNameValue,
      onChange: lastNameOnChange,
      onBlur: lastNameOnBlur
    },
    meta: { error: lastNameError, touched: lastNameTouched }
  } = useField<string, Optional<string>>(
    getKey(index, "lastName"),
    validateRequired
  );

  const {
    input: {
      value: genderValue,
      onChange: genderOnChange,
      onBlur: genderOnBlur
    },
    meta: { error: genderError, touched: genderTouched }
  } = useField<string, Optional<string>>(
    getKey(index, "gender"),
    validateRequired
  );

  const {
    input: {
      value: birthDateValue,
      onChange: birthDateOnChange,
      onBlur: birthDateOnBlur
    },
    meta: { error: birthDateError, touched: birthDateTouched }
  } = useField<string, Optional<string>>(getKey(index, "birthDate"));
  const birthDateValueDate = useMemo(() => {
    return birthDateValue ? DateTime.fromApiDate(birthDateValue) : undefined;
  }, [birthDateValue]);
  const birthDateOnChangeHandler = useCallback(
    (pickedDate: Optional<DateTime>) => {
      birthDateOnChange(pickedDate ? toApiDate(pickedDate) : undefined);
    },
    [birthDateOnChange]
  );

  const {
    input: {
      value: identifierSystemValue,
      onChange: identifierSystemOnChange,
      onBlur: identifierSystemOnBlur
    },
    meta: { error: identifierSystemError, touched: identifierSystemTouched }
  } = useField<string, Optional<string>>(
    getKey(index, "identifierSystem"),
    validateRequiredIdentifier
  );
  const personIdentifierSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.PersonLegalIdentifierSystem,
    organizationId
  );
  const personIdentifierOptions =
    personIdentifierSystem?.concept?.map(value => {
      const display = value.display || value.code || "";
      return {
        itemId: value.code,
        text: display,
        title: display,
        value: value.code
      };
    }) ?? [];
  if (
    identifierSystemValue &&
    !personIdentifierOptions.find(
      element => element.value === identifierSystemValue
    )
  ) {
    personIdentifierOptions.push({
      itemId: identifierSystemValue,
      text: identifierSystemValue,
      title: identifierSystemValue,
      value: identifierSystemValue
    });
  }

  const {
    input: {
      value: identifierValueValue,
      onChange: identifierValueOnChange,
      onBlur: identifierValueOnBlur
    },
    meta: { error: identifierValueError, touched: identifierValueTouched }
  } = useField<string, Optional<string>>(
    getKey(index, "identifierValue"),
    validateRequiredIdentifier
  );

  const {
    input: { value: emailValue, onChange: emailOnChange, onBlur: emailOnBlur },
    meta: { error: emailError, touched: emailTouched }
  } = useField<string, Optional<string>>(
    getKey(index, "email"),
    validateRequired
  );

  const {
    input: { value: phoneValue, onChange: phoneOnChange, onBlur: phoneOnBlur },
    meta: { error: phoneError, touched: phoneTouched }
  } = useField<string, Optional<string>>(getKey(index, "phone"));

  const {
    input: {
      value: registrationValue,
      onChange: registrationOnChange,
      onBlur: registrationOnBlur
    },
    meta: { error: registrationError, touched: registrationTouched }
  } = useField<string, Optional<string>>(
    getKey(index, "registration"),
    validateRequiredRegistration
  );

  const {
    input: {
      value: registrationTypeValue,
      onChange: registrationTypeOnChange,
      onBlur: registrationTypeOnBlur
    },
    meta: { error: registrationTypeError, touched: registrationTypeTouched }
  } = useField<string, Optional<string>>(
    getKey(index, "registrationType"),
    validateRequiredRegistration
  );
  const practitionerRegistrationTypeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.PractitionerRegistrationType,
    organizationId
  );
  const qualificationRegistrationTypeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.QualificationRegistrationType,
    organizationId
  );
  const registrationTypeOptions =
    qualificationRegistrationTypeSystem?.concept?.map(value => {
      const display = value.display || value.code || "";
      return {
        itemId: value.code,
        text: display,
        title: display,
        value: value.code
      };
    }) ?? [];
  registrationTypeOptions.push(
    ...(practitionerRegistrationTypeSystem?.concept?.map(value => {
      const display = value.display || value.code || "";
      return {
        itemId: value.code,
        text: display,
        title: display,
        value: value.code
      };
    }) ?? [])
  );
  if (
    registrationTypeValue &&
    !registrationTypeOptions.find(
      element => element.value === registrationTypeValue
    )
  ) {
    registrationTypeOptions.push({
      itemId: registrationTypeValue,
      text: registrationTypeValue,
      title: registrationTypeValue,
      value: registrationTypeValue
    });
  }

  const {
    input: {
      value: registrationPlaceValue,
      onChange: registrationPlaceOnChange,
      onBlur: registrationPlaceOnBlur
    },
    meta: { error: registrationPlaceError, touched: registrationPlaceTouched }
  } = useField<string, Optional<string>>(getKey(index, "registrationPlace"));
  const registrationPlaceSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.RegionCode,
    organizationId
  );
  const registrationPlaceOptions: OptionsConfig<string>[] =
    registrationPlaceSystem?.concept?.map(value => {
      const display = value.display || value.code || "";
      return {
        itemId: value.code,
        text: display,
        title: display,
        value: value.code
      };
    }) ?? [];
  if (
    registrationPlaceValue &&
    !registrationPlaceOptions.find(
      element => element.value === registrationPlaceValue
    )
  ) {
    registrationPlaceOptions.push({
      itemId: registrationPlaceValue,
      text: registrationPlaceValue,
      title: registrationPlaceValue,
      value: registrationPlaceValue
    });
  }

  const {
    input: {
      value: registrationValidationValue,
      onChange: registrationValidationOnChange,
      onBlur: registrationValidationOnBlur
    },
    meta: {
      error: registrationValidationError,
      touched: registrationValidationTouched
    }
  } = useField<string, Optional<string>>(
    getKey(index, "registrationValidation")
  );
  const registrationValidationOptions: OptionsConfig<string>[] = [
    {
      itemId: QualificationValidationStatus.Validated,
      text: "Validado",
      title: "Validado",
      value: QualificationValidationStatus.Validated
    },
    {
      itemId: QualificationValidationStatus.Pending,
      text: "Pendiente",
      title: "Pendiente",
      value: QualificationValidationStatus.Pending
    },
    {
      itemId: QualificationValidationStatus.Denied,
      text: "Denegada",
      title: "Denegada",
      value: QualificationValidationStatus.Denied
    }
  ];

  const {
    input: {
      value: signatureFileValue,
      onChange: signatureFileOnChange,
      onBlur: signatureFileOnBlur
    },
    meta: { error: signatureFileError, touched: signatureFileTouched }
  } = useField<string, Optional<string>>(getKey(index, "signatureFile"));

  const {
    input: {
      value: userIdValue,
      onChange: userIdOnChange,
      onBlur: userIdOnBlur
    },
    meta: { error: userIdError, touched: userIdTouched }
  } = useField<string, Optional<string>>(
    getKey(index, "userId"),
    validateRequiredUserId
  );
  const userIdOnChangeHandler = useCallback(
    (x: Optional<User>) => userIdOnChange(getModelReferenceId(x)),
    [userIdOnChange]
  );
  const getUserValueHandler = useCallback(
    async (valueId: string) => {
      const result = await dataProvider.getOne(UserResourceType, {
        id: valueId
      });
      return result.data;
    },
    [dataProvider]
  );
  const getUserOptions = useCallback(
    async (searchText?: string) => {
      const result = await dataProvider.getList<RaResource<User>>(
        UserResourceType,
        {
          filter: {
            [UserListQueryParamsKey.content]: searchText
          },
          pagination: {
            page: 1,
            perPage: 30
          },
          sort: {
            field: "",
            order: ""
          }
        }
      );
      return result.data;
    },
    [dataProvider]
  );

  const {
    input: {
      value: userPasswordValue,
      onChange: userPasswordOnChange,
      onBlur: userPasswordOnBlur
    },
    meta: { error: userPasswordError, touched: userPasswordTouched }
  } = useField<string, Optional<string>>(getKey(index, "userPassword"));

  const practitionerRoleSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.PractitionerRole,
    organizationId
  );
  const roleValidator = useCallback(
    (value: Optional<string[]>): Optional<string> => {
      if (isEmpty(value)) {
        return t(tk.validation.requiredField);
      }
    },
    [t]
  );
  const {
    input: {
      value: rolListValue,
      onChange: rolListOnChange,
      onBlur: rolListOnBlur
    },
    meta: { error: rolListError, touched: rolListTouched }
  } = useField<string[], Optional<string>>(
    getKey(index, "rolList"),
    roleValidator
  );
  const practitionerRoleOptions = (
    practitionerRoleSystem?.concept
      ?.filter(x =>
        getConceptPropertyCodeList(
          KnownConceptPropertyUse.SpecialEffect,
          x
        ).includes(KnownSpecialEffectPropertyCode.PractitionerInvitation)
      )
      .map(x => x.code) ?? []
  ).concat(rolListValue ?? []);
  const practitionerRoleOptionsFromValue = useCallback(
    (rol: string) => {
      const concept = practitionerRoleSystem?.concept?.find(
        x => x.code === rol
      );
      return {
        text: concept?.code || rol,
        title: concept?.code || rol,
        value: rol
      };
    },
    [practitionerRoleSystem]
  );

  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      <Grid item sm={6}>
        <Typography title={t(tk.practitionerFirstName)}>
          {t(tk.practitionerFirstName)}
        </Typography>
        <TextInput
          value={firstNameValue}
          onChange={firstNameOnChange}
          onBlur={firstNameOnBlur}
          errorText={firstNameError}
          showError={firstNameTouched ?? true}
          placeholder={t(tk.practitionerFirstName)}
          fullWidth
        />
      </Grid>
      <Grid item sm={6}>
        <Typography title={t(tk.practitionerLastName)}>
          {t(tk.practitionerLastName)}
        </Typography>
        <TextInput
          value={lastNameValue}
          onChange={lastNameOnChange}
          onBlur={lastNameOnBlur}
          errorText={lastNameError}
          showError={lastNameTouched ?? true}
          placeholder={t(tk.practitionerLastName)}
          fullWidth
        />
      </Grid>
      <Grid item sm={6}>
        <Typography title={t(tk.practitionerGender)}>
          {t(tk.practitionerGender)}
        </Typography>
        <SelectInput
          value={genderValue}
          onChange={genderOnChange}
          onBlur={genderOnBlur}
          errorText={genderError}
          showError={genderTouched ?? true}
          placeholder={t(tk.practitionerGender)}
          options={genderOptions}
          fullWidth
        />
      </Grid>
      <Grid item sm={6}>
        <Typography title={t(tk.practitionerBirthDate)}>
          {t(tk.practitionerBirthDate)}
        </Typography>
        <DateInput
          value={birthDateValueDate}
          onChange={birthDateOnChangeHandler}
          onBlur={birthDateOnBlur}
          errorText={birthDateError}
          showError={birthDateTouched ?? true}
          placeholder={t(tk.practitionerBirthDate)}
          fullWidth
        />
      </Grid>
      <Grid item sm={6}>
        <Typography title={t(tk.practitionerIdentifierSystem)}>
          {t(tk.practitionerIdentifierSystem)}
        </Typography>
        <SelectInput
          value={identifierSystemValue}
          onChange={identifierSystemOnChange}
          onBlur={identifierSystemOnBlur}
          errorText={identifierSystemError}
          showError={identifierSystemTouched ?? true}
          placeholder={t(tk.practitionerIdentifierSystem)}
          options={personIdentifierOptions}
          fullWidth
        />
      </Grid>
      <Grid item sm={6}>
        <Typography title={t(tk.practitionerIdentifierValue)}>
          {t(tk.practitionerIdentifierValue)}
        </Typography>
        <TextInput
          value={identifierValueValue}
          onChange={identifierValueOnChange}
          onBlur={identifierValueOnBlur}
          errorText={identifierValueError}
          showError={identifierValueTouched ?? true}
          placeholder={t(tk.practitionerIdentifierValue)}
          fullWidth
        />
      </Grid>
      <Grid item sm={6}>
        <Typography title={t(tk.practitionerPhone)}>
          {t(tk.practitionerPhone)}
        </Typography>
        <PhoneInput
          value={phoneValue}
          onChange={phoneOnChange}
          onBlur={phoneOnBlur}
          errorText={phoneTouched ?? true ? phoneError : undefined}
          fullWidth
        />
      </Grid>
      <Grid item sm={6}>
        <Typography title={t(tk.practitionerEmail)}>
          {t(tk.practitionerEmail)}
        </Typography>
        <TextInput
          value={emailValue}
          onChange={emailOnChange}
          onBlur={emailOnBlur}
          errorText={emailError}
          showError={emailTouched ?? true}
          placeholder={t(tk.practitionerEmail)}
          fullWidth
        />
      </Grid>
      <Grid item sm={6}>
        <Typography title={t(tk.practitionerRegistrationType)}>
          {t(tk.practitionerRegistrationType)}
        </Typography>
        <SelectInput
          value={registrationTypeValue}
          onChange={registrationTypeOnChange}
          onBlur={registrationTypeOnBlur}
          errorText={registrationTypeError}
          showError={registrationTypeTouched ?? true}
          placeholder={t(tk.practitionerRegistrationType)}
          options={registrationTypeOptions}
          fullWidth
        />
      </Grid>
      <Grid item sm={6}>
        <Typography title={t(tk.practitionerRegistration)}>
          {t(tk.practitionerRegistration)}
        </Typography>
        <TextInput
          value={registrationValue}
          onChange={registrationOnChange}
          onBlur={registrationOnBlur}
          errorText={registrationError}
          showError={registrationTouched ?? true}
          placeholder={t(tk.practitionerRegistration)}
          fullWidth
        />
      </Grid>
      <Grid item sm={6}>
        <Typography title={t(tk.practitionerRegistrationPlace)}>
          {t(tk.practitionerRegistrationPlace)}
        </Typography>
        <SelectInput
          value={registrationPlaceValue}
          onChange={registrationPlaceOnChange}
          onBlur={registrationPlaceOnBlur}
          errorText={registrationPlaceError}
          showError={registrationPlaceTouched ?? true}
          placeholder={t(tk.practitionerRegistrationPlace)}
          options={registrationPlaceOptions}
          fullWidth
          clearable
        />
      </Grid>
      <Grid item sm={6}>
        <Typography title={t(tk.practitionerRegistrationValidation)}>
          {t(tk.practitionerRegistrationValidation)}
        </Typography>
        <SelectInput
          value={registrationValidationValue}
          onChange={registrationValidationOnChange}
          onBlur={registrationValidationOnBlur}
          errorText={registrationValidationError}
          showError={registrationValidationTouched ?? true}
          placeholder={t(tk.practitionerRegistrationValidation)}
          options={registrationValidationOptions}
          fullWidth
          clearable
        />
      </Grid>
      <Grid item sm={12}>
        <Typography title={t(tk.practitionerSignatureFile)}>
          {t(tk.practitionerSignatureFile)}
        </Typography>
        <UploadFileImageFormInput
          value={signatureFileValue}
          onChange={signatureFileOnChange}
          onBlur={signatureFileOnBlur}
          errorText={signatureFileError}
          showError={signatureFileTouched ?? true}
          label={t(tk.practitionerSignatureFile)}
          uploadLabel={t(tk.practitionerSignatureFile)}
        />
      </Grid>
      <Grid item sm={12}>
        <Typography title={t(tk.practitionerUserId)}>
          {t(tk.practitionerUserId)}
        </Typography>
        <ModelReferenceAutocomplete<User>
          valueReference={userIdValue}
          onChange={userIdOnChangeHandler}
          onBlur={userIdOnBlur}
          errorText={userIdError}
          showError={userIdTouched ?? true}
          getValue={getUserValueHandler}
          getValues={getUserOptions}
          getOptionFromValue={getUserOptionFromValue}
          compareValues={compareModelId}
          fullWidth
          clearText={t(tk.clearText)}
          closeText={t(tk.closeText)}
          loadingText={t(tk.loadingText)}
          noOptionsText={t(tk.noOptionsText)}
          openText={t(tk.openText)}
          EndIcon={SearchIcon}
          endIconShouldNotRotate
          variant={TypeVariant.Outlined}
          minInputForSearch={minInputForSearch}
          minCharPromptText={t(tk.minCharPromptText, {
            minInputForSearch
          })}
          downloadOptionsIfEmpty
          clearable
        />
      </Grid>
      <Grid item sm={6}>
        <Typography title={t(tk.practitionerUserPassword)}>
          {t(tk.practitionerUserPassword)}
        </Typography>
        <TextInput
          value={userPasswordValue}
          onChange={userPasswordOnChange}
          onBlur={userPasswordOnBlur}
          errorText={userPasswordError}
          showError={userPasswordTouched ?? true}
          placeholder={t(tk.practitionerUserPassword)}
          fullWidth
        />
      </Grid>
      <Grid item sm={6}>
        <Typography title={t(tk.practitionerRolList)}>
          {t(tk.practitionerRolList)}
        </Typography>
        <SearchableListSimpleInput
          valueList={rolListValue}
          setOptionList={rolListOnChange}
          onBlur={rolListOnBlur}
          errorText={rolListError}
          showError={rolListTouched ?? true}
          optionList={practitionerRoleOptions}
          getOptionFromValue={practitionerRoleOptionsFromValue}
          noOptionsText={t(tk.noOptionsText)}
          fullWidth
        />
      </Grid>
      <Grid item sm={12}>
        <DefaultButton
          fullWidth
          text={t(tk.practitionerRemove)}
          StartIcon={RemoveIcon}
          onClick={() => {
            onDeleteRow?.(index);
          }}
        />
      </Grid>
    </Grid>
  );
};
