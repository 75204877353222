import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { StyleVariant } from "model/themeVariant";
import { FC, getClassName } from "@laba/react-common";
import { useMuiTheme } from "model/useMuiTheme";
import { useSpinnerStyles } from "./spinnerStyle";

export interface SpinnerProps {
  style?: StyleVariant;
  className?: string;
  size?: number;
}

/**
 * This component has the same spec as Progress Indicator on Figma.
 * Its use case is to show progress and block certain content when an action has to be awaited
 * On cards, it has to replace the content of the card.
 */
export const Spinner: FC<SpinnerProps> = ({
  className,
  style = StyleVariant.Primary,
  size = 44
}) => {
  const classes = useSpinnerStyles({ style });
  const muiTheme = useMuiTheme(style);
  return (
    <ThemeProvider theme={muiTheme}>
      <CircularProgress
        size={size}
        className={getClassName(classes.colorPrimary, className)}
      />
    </ThemeProvider>
  );
};
