import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { ModelId } from "model/primitives/model/model";
import { MedicationSubstance } from "model/resource/prescription/medicationSubstance";
import { getKeyObj, HttpMethod, KeyObj } from "@laba/ts-common";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { createHydratedMock } from "ts-auto-mock";

export interface MedicationSubstanceListQueryParams extends ListQueryParams {
  organization?: ModelId;
  content?: string;
  code?: string;
}

export const MedicationSubstanceListQueryParamsKey: KeyObj<MedicationSubstanceListQueryParams> =
  getKeyObj(createHydratedMock<MedicationSubstanceListQueryParams>());

const medicationSubstanceBasePath = "/medication-substance";

export const getMedicationSubstanceList = (
  params: MedicationSubstanceListQueryParams
): Promise<ApiPageRequestResponse<MedicationSubstance>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(medicationSubstanceBasePath),
    params
  });

export const getMedicationSubstance = (
  id: ModelId
): Promise<ApiRequestResponse<MedicationSubstance>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${medicationSubstanceBasePath}/${id}`)
  });

export const createMedicationSubstance = (
  data: MedicationSubstance
): Promise<ApiRequestResponse<MedicationSubstance>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(medicationSubstanceBasePath),
    data
  });

export const updateMedicationSubstance = (
  data: MedicationSubstance
): Promise<ApiRequestResponse<MedicationSubstance>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${medicationSubstanceBasePath}/${data.id}`),
    data
  });
