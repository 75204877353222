import { ReactElement } from "@laba/react-common";
import React from "react";
import {
  TextInput,
  TextInputProps
} from "components/generic/TextInput/TextInput";
import { getAsArray, notNull, Optional } from "@laba/ts-common";

export type UrlInputProps = TextInputProps;

export const UrlInput = ({
  validate,
  ...props
}: UrlInputProps): ReactElement => {
  const validateArray = getAsArray(validate).filter(notNull);
  validateArray.push((value: Optional<string>) => {
    if (value && !value.startsWith("http"))
      return "Link invalido (debe empezar con http)";
    return undefined;
  });
  return <TextInput validate={validateArray} {...props} />;
};
