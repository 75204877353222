import React from "react";
import { FC, useI18n } from "@laba/react-common";
import { CountryAreaPhoneCode, ModelId } from "@laba/ts-common";
import {
  Organization,
  OrganizationCombinedType,
  OrganizationKey,
  OrganizationListQueryParamsKey,
  PatientAppConfigPermissionCode,
  ResourceType
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { Labeled } from "react-admin";
import { Grid } from "@mui/material";
import { ColorInput } from "components/generic/ColorInput/ColorInput";
import { ThymeleafInput } from "components/generic/ThymeleafInput/ThymeleafInput";
import { FormDataConsumer } from "components/generic/FormDataConsumer/FormDataConsumer";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { UploadFileImageInput } from "components/generic/UploadFileImageInput/UploadFileImageInput";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useSelector } from "react-redux";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { NumberInput } from "components/generic/NumberInput/TextInput";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

export const tk = tkCP.adminPage[ResourceType.Organization];
export const OrganizationWhiteLabelContent: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organizationId = useWatchResourceForm<ModelId>(OrganizationKey.id);
  const organization = organizationId ?? workspaceOrganizationId;
  return (
    <>
      <ArrayInput
        source={OrganizationKey.domainUrlList}
        label={t(tk.fields.domainUrlList)}
      >
        <TextInput source="" label={t(tk.fields.domainUrlListElement)} />
      </ArrayInput>

      <FormDataConsumer<Organization>>
        {({ formData }) => (
          <>
            <OrganizationReferenceInput
              source={OrganizationKey.whiteLabelConfig.organization}
              label={t(tk.fields.whiteLabelConfigOrganization)}
              type={OrganizationCombinedType.ProviderAndGroup}
              extraFilters={{
                [OrganizationListQueryParamsKey.active]: true
              }}
              defaultValue={formData.id}
              disabled
            />
            <OrganizationReferenceInput
              source={OrganizationKey.whiteLabelConfig.templateOrganizationId}
              label={t(tk.fields.templateOrganizationId)}
              type={OrganizationCombinedType.ProviderAndGroup}
              extraFilters={{
                [OrganizationListQueryParamsKey.partOf]: formData.id,
                [OrganizationListQueryParamsKey.active]: true
              }}
            />
          </>
        )}
      </FormDataConsumer>
      <Labeled label={t(tk.fields.whiteLabelConfigSection)} fullWidth>
        <Grid container columnSpacing={2}>
          <Grid item sm={3}>
            <ArrayInput
              label={t(tk.fields.whiteLabelConfigSection)}
              source={OrganizationKey.whiteLabelConfig.permissionList}
            >
              <EnumSelectorInput
                source=""
                label={t(tk.fields.whiteLabelConfigSectionItem)}
                enumValue={PatientAppConfigPermissionCode}
              />
            </ArrayInput>
          </Grid>
          <Grid item sm={3}>
            <BooleanInput
              source={OrganizationKey.whiteLabelConfig.brandData.disableLogin}
              label={t(tk.fields.whiteLabelConfigDisableLogin)}
            />
            <BooleanInput
              source={
                OrganizationKey.whiteLabelConfig.brandData.disableRegister
              }
              label={t(tk.fields.whiteLabelConfigDisableRegister)}
            />
            <BooleanInput
              source={
                OrganizationKey.whiteLabelConfig.brandData.disableGoogleLogin
              }
              label={t(tk.fields.whiteLabelConfigDisableGoogleLogin)}
            />
          </Grid>
        </Grid>
      </Labeled>
      <ColorInput
        source={OrganizationKey.whiteLabelConfig.credentialData.color}
        label={t(tk.fields.whiteLabelConfigCredentialColor)}
      />
      <UploadFileImageInput
        source={OrganizationKey.whiteLabelConfig.credentialData.logo}
        label={t(tk.fields.whiteLabelConfigCredentialLogo)}
        organization={organization}
      />
      <UploadFileImageInput
        source={OrganizationKey.whiteLabelConfig.credentialData.icon}
        label={t(tk.fields.whiteLabelConfigCredentialIcon)}
        organization={organization}
      />
      <ThymeleafInput
        source={OrganizationKey.whiteLabelConfig.directoryConfig.title}
        label={t(tk.fields.whiteLabelConfigDirectoryTitle)}
      />
      <BooleanInput
        source={OrganizationKey.whiteLabelConfig.directoryConfig.enableMap}
        label={t(tk.fields.whiteLabelConfigDirectoryMap)}
      />
      <BooleanInput
        source={
          OrganizationKey.whiteLabelConfig.directoryConfig.enablePlanFilter
        }
        label={t(tk.fields.whiteLabelConfigDirectoryPlanFilter)}
      />
      <BooleanInput
        source={
          OrganizationKey.whiteLabelConfig.directoryConfig
            .enableSpecialityFilter
        }
        label={t(tk.fields.whiteLabelConfigDirectorySpecialityFilter)}
      />
      <BooleanInput
        source={
          OrganizationKey.whiteLabelConfig.directoryConfig.enableCityFilter
        }
        label={t(tk.fields.whiteLabelConfigDirectoryCityFilter)}
      />
      <NumberInput
        source={
          OrganizationKey.whiteLabelConfig.organizationData.maxDateInterval
        }
        label={t(tk.fields.whiteLabelConfigMaxDateInterval)}
        min={0}
        step={1}
      />
      <TextInput
        source={
          OrganizationKey.whiteLabelConfig.organizationData.drawerButtonText
        }
        label={t(tk.fields.whiteLabelConfigBottomDrawerButtonTitle)}
      />
      <TextInput
        source={OrganizationKey.whiteLabelConfig.organizationData.helpButtonUrl}
        label={t(tk.fields.whiteLabelConfigShowBottomDrawerButton)}
      />
      <Labeled
        label={t(tk.fields.whiteLabelConfigPhoneConfigSection)}
        fullWidth
      >
        <>
          <EnumSelectorInput
            source={
              OrganizationKey.whiteLabelConfig.organizationData.countryPhone
                .defaultAreaCode
            }
            label={t(tk.fields.whiteLabelConfigPhoneConfigDefaultAreaCode)}
            enumValue={CountryAreaPhoneCode}
          />
          <ArrayInput
            label={t(tk.fields.whiteLabelConfigPhoneConfigExcludedCountries)}
            source={
              OrganizationKey.whiteLabelConfig.organizationData.countryPhone
                .excludeCountries
            }
          >
            <EnumSelectorInput
              source=""
              label={t(tk.fields.whiteLabelConfigPhoneConfigCountryAreaCode)}
              enumValue={CountryAreaPhoneCode}
            />
          </ArrayInput>
          <ArrayInput
            label={t(tk.fields.whiteLabelConfigPhoneConfigPreferredCountries)}
            source={
              OrganizationKey.whiteLabelConfig.organizationData.countryPhone
                .preferredCountries
            }
          >
            <EnumSelectorInput
              source=""
              label={t(tk.fields.whiteLabelConfigPhoneConfigCountryAreaCode)}
              enumValue={CountryAreaPhoneCode}
            />
          </ArrayInput>
        </>
      </Labeled>
    </>
  );
};
