import React, { useCallback, useMemo, useState } from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  ResourceType,
  Concept,
  CodeSystemKey,
  CodeSystemConceptKey,
  CodeSystemConceptPropertyKey,
  KnownConceptPropertyUse
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { Autocomplete, AutocompleteOptionConfig } from "@laba/nexup-components";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { isEmpty } from "lodash-es";
import { TextInput } from "components/generic/TextInput/TextInput";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { Optional } from "@laba/ts-common";
import { CodeSystemPropertyCodeInput } from "components/pages/NexupAdmin/resources/codeSystem/CodeSystemPropertyCodeInput";

export const tk = tkCP.adminPage[ResourceType.CodeSystem];
const compareConcept = (val1: Concept, val2: Concept) =>
  val1.code === val2.code;
export const CodeSystemConceptElementContent: FC = () => {
  const { t } = useI18n();
  const conceptList = useWatchResourceForm<Concept[]>(CodeSystemKey.concept);
  const [toEditConcept, setToEditConcept] = useState<Optional<Concept>>(
    conceptList?.[0]
  );
  const conceptOptionList = useMemo<AutocompleteOptionConfig<Concept>[]>(
    () =>
      conceptList?.map(value => ({
        value,
        itemId: value.code,
        text: isEmpty(value.display)
          ? value.code
          : `${value.code} (${value.display})`,
        title: isEmpty(value.display)
          ? value.code
          : `${value.code} (${value.display})`
      })) ?? [],
    [conceptList]
  );
  const toEditConceptIndex = useMemo(
    () =>
      conceptList?.findIndex(
        value => toEditConcept?.code && value.code === toEditConcept.code
      ) ?? -1,
    [toEditConcept, conceptList]
  );
  const getElementSource = useCallback(
    (source: string) =>
      `${CodeSystemKey.concept}.${toEditConceptIndex}.${source}`,
    [toEditConceptIndex]
  );
  return (
    <>
      <Autocomplete<Concept>
        fullWidth
        value={toEditConcept}
        onChange={async value => {
          // set value to undefined for 1 render (await) to dismount and remount properties array input
          await setToEditConcept(undefined);
          setToEditConcept(value);
        }}
        options={conceptOptionList}
        compareValues={compareConcept}
        clearText={t(tk.autocompleteClearText)}
        closeText={t(tk.autocompleteCloseText)}
        loadingText={t(tk.autocompleteLoadingText)}
        noOptionsText={t(tk.autocompleteNoOptionsText)}
        openText={t(tk.autocompleteOpenText)}
        label={t(tk.autocompleteTitle)}
      />
      {toEditConceptIndex >= 0 && (
        <>
          <TextInput
            source={getElementSource(CodeSystemConceptKey.display)}
            label={t(tk.fields.conceptDisplay)}
          />
          <ArrayInput
            source={getElementSource(CodeSystemConceptKey.property)}
            label={t(tk.fields.conceptProperties)}
          >
            <EnumSelectorInput
              source={CodeSystemConceptPropertyKey.use}
              label={t(tk.fields.conceptPropertyUse)}
              enumValue={KnownConceptPropertyUse}
              withCreate
            />
            <CodeSystemPropertyCodeInput />
          </ArrayInput>
        </>
      )}
    </>
  );
};
