import { createStyle, Theme } from "@laba/react-common";

interface TimePickerStylesClassNames {
  root: string;
}

interface TimePickerStylesProps {
  fullWidth?: boolean;
}

const useTimePickerStylesStatic = createStyle(
  (_theme: Theme) => ({
    fullWidthContainer: { width: "100%" },
    autoWidthContainer: { width: "auto" }
  }),
  "TimePickerStylesStatic"
);

export const useTimePickerStyles = (
  props: TimePickerStylesProps
): TimePickerStylesClassNames => {
  const staticClasses = useTimePickerStylesStatic();
  return {
    root: props.fullWidth
      ? staticClasses.fullWidthContainer
      : staticClasses.autoWidthContainer
  };
};
