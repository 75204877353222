import React, { useState } from "react";
import { Color, FCC } from "@laba/react-common";
import { Accordion, AccordionProps } from "components/accordion/Accordion";
import { useAccordionComponentListItemStyles } from "components/wrappers/AccordionComponentListItem/useAccordionComponentListItemStyles";
import { BaseIconButton } from "components/buttons/BaseIconButton/BaseIconButton";
import { KeyboardArrowDown, KeyboardArrowUp } from "components/icons";

export interface AccordionComponentListItemProps
  extends Omit<
    AccordionProps,
    | "showArrowIcon"
    | "isSelfControlled"
    | "isExpanded"
    | "withMarginSummary"
    | "hasBackgroundColor"
  > {
  borderColor?: Color;
}

export const AccordionComponentListItem: FCC<
  AccordionComponentListItemProps
> = ({ borderColor, ...props }) => {
  const classes = useAccordionComponentListItemStyles({
    borderColor
  });
  const [isExpanded, setIsExpanded] = useState(false);

  const onClickExpand = () => setIsExpanded(!isExpanded);
  return (
    <Accordion
      {...props}
      showArrowIcon={false}
      isSelfControlled={false}
      summaryChildren={
        <div className={classes.rootChildren}>
          {props.summaryChildren}
          <BaseIconButton
            className={classes.arrowButton}
            Icon={isExpanded ? KeyboardArrowUp : KeyboardArrowDown}
            onClick={onClickExpand}
          />
        </div>
      }
      className={classes.root}
      withMarginSummary={false}
      isExpanded={isExpanded}
      hasBackgroundColor={false}
      onAccordionClick={onClickExpand}
    />
  );
};
