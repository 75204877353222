import {
  getDefaultJsonToModel,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";
import { WhatsappNotification } from "@laba/nexup-api";
import { ResourceTransform } from "components/generic/Create/Create";
import { cleanEmptyString } from "components/generic/ResourceForm/ResourceForm";

export const whatsappNotificationJsonToModel: JsonToModel<WhatsappNotification> =
  getDefaultJsonToModel();
export const whatsappNotificationTransform: ResourceTransform<
  WhatsappNotification
> = resource => {
  return cleanEmptyString(resource);
};
