import React from "react";
import { IconC, ReactElementOrNull, StyleVariant } from "@laba/react-common";
import { isEmpty } from "lodash-es";
import { NexupIconButton } from "components/buttons/IconButton/IconButton";
import { bindPopover, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import { MoreVertIcon } from "components/icons";
import { BaseIconButton } from "components/buttons/BaseIconButton/BaseIconButton";
import { TypeInvertedVariant } from "components/utils/getGenericButtonColors";
import { HorizontalPosition, Menu, MenuProps } from "../Menu/Menu";

export interface IconButtonMenuProps<V> extends MenuProps<V> {
  Icon?: IconC;
  style?: StyleVariant;
  type?: TypeInvertedVariant;
  isBaseIcon?: boolean;
  withoutPadding?: boolean;
}

export const IconButtonMenu = <V,>({
  Icon = MoreVertIcon,
  transformOriginHorizontal = HorizontalPosition.Right,
  anchorOriginHorizontal = HorizontalPosition.Right,
  style = StyleVariant.BlackWhite,
  type = TypeInvertedVariant.Inverted,
  isBaseIcon = false,
  menuItems,
  withoutPadding = false,
  ...rest
}: IconButtonMenuProps<V>): ReactElementOrNull => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: "iconButtonPopover"
  });

  const isListEmpty = isEmpty(menuItems);

  if (isListEmpty) return null;

  return (
    <>
      {isBaseIcon ? (
        <BaseIconButton
          {...bindTrigger(popupState)}
          Icon={Icon}
          style={style}
          type={type}
          withoutPadding={withoutPadding}
        />
      ) : (
        <NexupIconButton
          {...bindTrigger(popupState)}
          Icon={Icon}
          style={style}
          type={type}
        />
      )}

      <Menu
        {...bindPopover(popupState)}
        {...rest}
        menuItems={menuItems}
        transformOriginHorizontal={transformOriginHorizontal}
        anchorOriginHorizontal={anchorOriginHorizontal}
      />
    </>
  );
};
