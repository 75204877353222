import { filter } from "lodash-es";
import { ErrorIssueType } from "./errorIssueType";
import {
  ErrorIssueParameter,
  ErrorIssueParameterAll,
  ErrorIssueParameterType,
  ErrorMessageErrorIssueParameter,
  FieldErrorIssueParameter
} from "./errorIssueParameter";

export enum ErrorIssueSeverity {
  Fatal = "fatal",
  Error = "error",
  Warning = "warning",
  Information = "information"
}

export interface BaseErrorIssue<T extends ErrorIssueType> {
  type: T;
  code?: string;
  severity?: ErrorIssueSeverity;
  name?: string;
  description?: string;
  parameter?: ErrorIssueParameter<T>[];
  externalExtraMessage?: string;
}

export type NotFoundErrorIssue = BaseErrorIssue<ErrorIssueType.NotFound>;

export type UserUnknownErrorIssue = BaseErrorIssue<ErrorIssueType.UserUnknown>;

export type UserSessionExpiredErrorIssue =
  BaseErrorIssue<ErrorIssueType.UserSessionExpired>;

export type BadCredentialsErrorIssue =
  BaseErrorIssue<ErrorIssueType.BadCredentials>;

export type ForbiddenErrorIssue = BaseErrorIssue<ErrorIssueType.Forbidden>;

export type InvalidFieldErrorIssue =
  BaseErrorIssue<ErrorIssueType.InvalidField>;

export type InternalErrorErrorIssue =
  BaseErrorIssue<ErrorIssueType.InternalError>;

export type InvalidQueryParamErrorIssue =
  BaseErrorIssue<ErrorIssueType.InvalidQueryParam>;

export type InvalidBodyErrorIssue = BaseErrorIssue<ErrorIssueType.InvalidBody>;

export type InvalidFileErrorIssue = BaseErrorIssue<ErrorIssueType.InvalidFile>;

export type ResourceIsNotModifiableErrorIssue =
  BaseErrorIssue<ErrorIssueType.ResourceIsNotModifiable>;

// TODO Hay que revisar si está bien que exista este issue
export type BadRequestErrorIssue = BaseErrorIssue<ErrorIssueType.BadRequest>;

export type ErrorIssue =
  | NotFoundErrorIssue
  | UserUnknownErrorIssue
  | UserSessionExpiredErrorIssue
  | BadCredentialsErrorIssue
  | ForbiddenErrorIssue
  | InvalidFieldErrorIssue
  | InternalErrorErrorIssue
  | InvalidQueryParamErrorIssue
  | InvalidBodyErrorIssue
  | InvalidFileErrorIssue
  | ResourceIsNotModifiableErrorIssue
  | BadRequestErrorIssue;

const filterIssueParameterByType = (
  errorIssue: ErrorIssue,
  type: ErrorIssueParameterType
) =>
  filter(
    errorIssue.parameter as ErrorIssueParameterAll[],
    parameter => parameter.type === type
  );

export const getErrorMessageParameterList = (
  errorIssue: ErrorIssue
): ErrorMessageErrorIssueParameter[] =>
  filterIssueParameterByType(
    errorIssue,
    ErrorIssueParameterType.ErrorMessage
  ) as ErrorMessageErrorIssueParameter[];

export const getInvalidFieldParameterList = (
  errorIssue: ErrorIssue
): FieldErrorIssueParameter[] =>
  filterIssueParameterByType(
    errorIssue,
    ErrorIssueParameterType.Field
  ) as FieldErrorIssueParameter[];
