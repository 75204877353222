import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { ModelId } from "model/primitives/model/model";
import { PractitionerRoleCodes } from "model/resource/person/practitionerRole";
import { ValidationStatus } from "model/resource/person/person";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { getKeyObj, HttpMethod } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { PractitionerOrder } from "api/person/practitioner";
import { PractitionerWithExtraData } from "model/resource/person/practitioner/practitionerWithExtraData";
import { urlJoin } from "url-join-ts";

export interface PractitionerWithExtraDataQueryParams {
  withOrganization?: boolean;
  withRoleList?: boolean;
  withUser?: boolean;
}

export interface PractitionerWithExtraDataListQueryParams
  extends ListQueryParams,
    PractitionerWithExtraDataQueryParams {
  content?: string;
  order?: PractitionerOrder;
  organization?: ModelId;
  role?: PractitionerRoleCodes;
  userId?: string;
  validationStatus?: ValidationStatus;
}

export const PractitionerWithExtraDataListQueryParamsKey = getKeyObj(
  createHydratedMock<PractitionerWithExtraDataListQueryParams>()
);

const practitionerWithExtraDataBasePath = "/practitioner";
const practitionerWithExtraDataExtraPath = "/extra";

export const getPractitionerWithExtraDataList = (
  params: PractitionerWithExtraDataListQueryParams
): Promise<ApiPageRequestResponse<PractitionerWithExtraData>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(
      urlJoin(
        practitionerWithExtraDataBasePath,
        practitionerWithExtraDataExtraPath
      )
    ),
    params
  });

export const getPractitionerWithExtraData = (
  id: ModelId,
  params: PractitionerWithExtraDataQueryParams
): Promise<ApiRequestResponse<PractitionerWithExtraData>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(
      urlJoin(
        practitionerWithExtraDataBasePath,
        id,
        practitionerWithExtraDataExtraPath
      )
    ),
    params
  });

export const createPractitionerWithExtraData = (
  data: PractitionerWithExtraData
): Promise<ApiRequestResponse<PractitionerWithExtraData>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(
      urlJoin(
        practitionerWithExtraDataBasePath,
        practitionerWithExtraDataExtraPath
      )
    ),
    data
  });

export const updatePractitionerWithExtraData = (
  data: PractitionerWithExtraData
): Promise<ApiRequestResponse<PractitionerWithExtraData>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(
      urlJoin(
        practitionerWithExtraDataBasePath,
        data.practitioner.id,
        practitionerWithExtraDataExtraPath
      )
    ),
    data
  });
