import {
  HomeItemPropertyType,
  HomeItemType,
  WhiteLabelConfig,
  WhiteLabelHomeItem
} from "model/resource/app/whiteLabelConfig";
import { StyleVariant, TypeVariant } from "@laba/ts-common";
import { PatientAppConfigPermissionCode } from "../appConfig";

const bannerItemConfigList = [
  {
    itemUrl:
      "https://media.geeksforgeeks.org/wp-content/uploads/20210314115545/sample-video.mp4",
    isVideo: true,
    title: '<Subtitle1 style="color:black">titulo</Subtitle1>',
    principalText: '<H1 style="color:black">texto principal</H1>',
    secondaryText:
      '<Subtitle2 style="color:black">texto secundario</Subtitle2>',
    buttonText: "Ver mas",
    buttonUrl: "https://laba.ar/",
    buttonType: TypeVariant.Contained,
    buttonStyle: StyleVariant.Primary
  },
  {
    itemUrl: "https://i.imgur.com/ooXpb9K.png",
    isVideo: false,
    title: '<Subtitle1 style="color:white">BIENVENIDOS</Subtitle1>',
    principalText:
      '<H4 style="color:white">Hisopados <span style="color:#7487F2">COVID-19 <br/></span> Nuevos centros de testeo</H4>',
    secondaryText:
      '<Subtitle2 style="color:white">AFILIADOS DE DOSUBA CON COBERTURA 100%</Subtitle2>',
    buttonText: "Leer más",
    buttonUrl: "https://laba.ar/",
    buttonType: TypeVariant.Contained,
    buttonStyle: StyleVariant.Primary
  },
  {
    itemUrl: "https://i.imgur.com/XBHe1KZ.png",
    isVideo: false,
    title: '<Subtitle1 style="color:white">BIENVENIDOS</Subtitle1>',
    principalText:
      '<H4 style="color:white">lorem ipsum has been the industry\'s standard<span style="color:#7487F2">dummy text</span> ever since the 1500</H4>',
    secondaryText:
      '<Subtitle2 style="color:white">AFILIADOS DE DOSUBA CON COBERTURA 100%</Subtitle2>',
    buttonText: "Leer más",
    buttonUrl: "https://laba.ar/",
    buttonType: TypeVariant.Contained,
    buttonStyle: StyleVariant.Primary
  }
];

const itemList: WhiteLabelHomeItem[] = [
  {
    type: HomeItemType.Button,
    property: [
      { type: HomeItemPropertyType.ButtonVariant, value: "Vertical" },
      { type: HomeItemPropertyType.ClickAction, value: "Email" },
      {
        type: HomeItemPropertyType.Value,
        value: "asd@gmail.com"
      },
      { type: HomeItemPropertyType.EmailSubject, value: "Body subject" },
      { type: HomeItemPropertyType.EmailBody, value: "Body mail" },
      { type: HomeItemPropertyType.NumColumns, value: "2" },
      { type: HomeItemPropertyType.NumRows, value: "4" },
      {
        type: HomeItemPropertyType.Title,
        value: "Enviar mail"
      },
      {
        type: HomeItemPropertyType.Text,
        value:
          "Consultá nuestras farmacias, Centros Medicos, Centros Diagnósticos y Profesionales"
      },
      {
        type: HomeItemPropertyType.Icon,
        value: "https://i.imgur.com/FZo49Ot.png"
      }
    ]
  },
  {
    type: HomeItemType.Button,
    property: [
      { type: HomeItemPropertyType.ButtonVariant, value: "Vertical" },
      { type: HomeItemPropertyType.ClickAction, value: "Redirect" },
      {
        type: HomeItemPropertyType.Value,
        value: "/virtual-guard/services"
      },
      { type: HomeItemPropertyType.EmailSubject, value: "Body subject" },
      { type: HomeItemPropertyType.EmailBody, value: "Body mail" },
      { type: HomeItemPropertyType.NumColumns, value: "2" },
      { type: HomeItemPropertyType.NumRows, value: "4" },
      {
        type: HomeItemPropertyType.Title,
        value: "Guardia virtual"
      },
      {
        type: HomeItemPropertyType.Text,
        value:
          "Consultá nuestras farmacias, Centros Medicos, Centros Diagnósticos y Profesionales"
      },
      {
        type: HomeItemPropertyType.Icon,
        value: "https://i.imgur.com/FZo49Ot.png"
      }
    ]
  },
  {
    type: HomeItemType.Button,
    property: [
      { type: HomeItemPropertyType.ButtonVariant, value: "Vertical" },
      { type: HomeItemPropertyType.ClickAction, value: "Call" },
      {
        type: HomeItemPropertyType.Value,
        value: "12345765"
      },
      { type: HomeItemPropertyType.EmailSubject, value: "Body subject" },
      { type: HomeItemPropertyType.EmailBody, value: "Body mail" },
      { type: HomeItemPropertyType.NumColumns, value: "2" },
      { type: HomeItemPropertyType.NumRows, value: "2" },
      {
        type: HomeItemPropertyType.Title,
        value: "Llamar"
      },
      {
        type: HomeItemPropertyType.Icon,
        value: "https://i.imgur.com/ufRnExX.png"
      }
    ]
  },
  {
    type: HomeItemType.Button,
    property: [
      { type: HomeItemPropertyType.ButtonVariant, value: "Vertical" },
      { type: HomeItemPropertyType.ClickAction, value: "Redirect" },
      {
        type: HomeItemPropertyType.Value,
        value:
          "https://stackoverflow.com/questions/16781772/trigger-phone-call-with-javascript"
      },
      { type: HomeItemPropertyType.EmailSubject, value: "Body subject" },
      { type: HomeItemPropertyType.EmailBody, value: "Body mail" },
      { type: HomeItemPropertyType.NumColumns, value: "2" },
      { type: HomeItemPropertyType.NumRows, value: "2" },
      {
        type: HomeItemPropertyType.Title,
        value: "Abrir pagina externa"
      },
      {
        type: HomeItemPropertyType.Icon,
        value: "https://i.imgur.com/ufRnExX.png"
      }
    ]
  },
  {
    type: HomeItemType.Button,
    property: [
      { type: HomeItemPropertyType.ButtonVariant, value: "Horizontal" },
      { type: HomeItemPropertyType.ClickAction, value: "Redirect" },
      {
        type: HomeItemPropertyType.Value,
        value:
          "https://stackoverflow.com/questions/16781772/trigger-phone-call-with-javascript"
      },
      { type: HomeItemPropertyType.EmailSubject, value: "Body subject" },
      { type: HomeItemPropertyType.EmailBody, value: "Body mail" },
      { type: HomeItemPropertyType.NumColumns, value: "4" },
      { type: HomeItemPropertyType.NumRows, value: "2" },
      {
        type: HomeItemPropertyType.Title,
        value: "titulo"
      },
      {
        type: HomeItemPropertyType.Text,
        value:
          "Consultá nuestras farmacias, Centros Medicos, Centros Diagnósticos y Profesionales"
      },
      {
        type: HomeItemPropertyType.Icon,
        value: "https://i.imgur.com/FZo49Ot.png"
      }
    ]
  },
  {
    type: HomeItemType.Button,
    property: [
      { type: HomeItemPropertyType.ButtonVariant, value: "Horizontal" },
      { type: HomeItemPropertyType.ClickAction, value: "Redirect" },
      {
        type: HomeItemPropertyType.Value,
        value:
          "https://stackoverflow.com/questions/16781772/trigger-phone-call-with-javascript"
      },
      { type: HomeItemPropertyType.EmailSubject, value: "Body subject" },
      { type: HomeItemPropertyType.EmailBody, value: "Body mail" },
      { type: HomeItemPropertyType.NumColumns, value: "4" },
      { type: HomeItemPropertyType.NumRows, value: "2" },
      {
        type: HomeItemPropertyType.Title,
        value: "titulo"
      },
      {
        type: HomeItemPropertyType.Text,
        value:
          "Consultá nuestras farmacias, Centros Medicos, Centros Diagnósticos y Profesionales"
      },
      {
        type: HomeItemPropertyType.Icon,
        value: "https://i.imgur.com/FZo49Ot.png"
      }
    ]
  },
  {
    type: HomeItemType.Button,
    property: [
      { type: HomeItemPropertyType.ButtonVariant, value: "Horizontal" },
      { type: HomeItemPropertyType.ClickAction, value: "Redirect" },
      {
        type: HomeItemPropertyType.Value,
        value:
          "https://stackoverflow.com/questions/16781772/trigger-phone-call-with-javascript"
      },
      { type: HomeItemPropertyType.EmailSubject, value: "Body subject" },
      { type: HomeItemPropertyType.EmailBody, value: "Body mail" },
      { type: HomeItemPropertyType.NumColumns, value: "4" },
      { type: HomeItemPropertyType.NumRows, value: "2" },
      {
        type: HomeItemPropertyType.Title,
        value: "titulo"
      },
      {
        type: HomeItemPropertyType.Text,
        value:
          "Consultá nuestras farmacias, Centros Medicos, Centros Diagnósticos y Profesionales"
      },
      {
        type: HomeItemPropertyType.Icon,
        value: "https://i.imgur.com/FZo49Ot.png"
      }
    ]
  }
];

export const mockedWhiteLabelConfig: WhiteLabelConfig = {
  organization: "25",
  permissionList: [
    PatientAppConfigPermissionCode.Directory,
    PatientAppConfigPermissionCode.Home
  ],
  brandData: {
    name: "DOSUBA",
    loginTitle: "Bienvenido a DOSUBA",
    loginText: "Ingrese su usuario y contraseña"
  },
  desktopHomeConfig: {
    bannerItemConfigList,
    itemList,
    numColumns: 12
  },
  mobileHomeConfig: {
    bannerItemConfigList,
    itemList,
    numColumns: 4
  }
};
