import { ModelId } from "model/primitives/model/model";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { ApiDate, getKeyObj } from "@laba/ts-common";
import { ResourceModel, ResourceType } from "model/primitives/resourceModel";
import { Practitioner } from "model/resource/person/practitioner/practitioner";
import { createHydratedMock } from "ts-auto-mock";
import { Code, CodeSystemCode } from "../../entities/codeSystem";
import { Patient } from "../../person/patient/patient";

export enum ConditionClinical {
  Active = "active",
  Inactive = "inactive",
  Resolved = "resolved"
}

export enum ConditionCategory {
  EncounterDiagnosis = "encounter-diagnosis",
  PatientClassification = "patient-classification",
  PatientProblem = "patient-problem"
}

export interface Condition extends ResourceModel<ResourceType.Condition> {
  clinicalStatus: ConditionClinical;
  patient?: ModelReference<Patient>;
  encounter?: ModelId;
  category?: Code;
  hisCode?: string;
  practitioner?: ModelReference<Practitioner>;
  resolver?: ModelReference<Practitioner>;
  resolvedDate?: ApiDate;
  recordedDate?: ApiDate;
  code?: CodeSystemCode;
}

export const ConditionKey = getKeyObj<Condition>(
  createHydratedMock<Condition>()
);
