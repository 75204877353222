import {
  FC,
  getClassName,
  OnBlurEvent,
  stopPropagationWrapper
} from "@laba/react-common";
import { StyleVariant, TextVariant } from "model/themeVariant";
import { useMuiTheme } from "model/useMuiTheme";
import { ThemeProvider } from "@material-ui/core/styles";
import { Checkbox } from "@material-ui/core";
import React from "react";
import { useCheckboxStyleClassNames } from "components/inputs/Checkbox/CheckboxStyle";
import { ErrorText } from "components/text/ErrorText/ErrorText";
import { Noop } from "@laba/ts-common";

export type CheckboxTextVariant = TextVariant.Body2 | TextVariant.Subtitle2;

export interface CheckboxProps {
  className?: string;
  containerClassName?: string;
  title?: string;
  checked?: boolean;
  text?: string;
  onChange?: (checked: boolean) => void;
  onClick?: Noop;
  onBlur?: OnBlurEvent<HTMLButtonElement>;
  disabled?: boolean;
  style?: StyleVariant;
  errorText?: string;
  showErrorSpaceWhenErrorEmpty?: boolean;
  showError?: boolean;
  textVariant?: CheckboxTextVariant;
  withoutPadding?: boolean;
  singleLine?: boolean;
  textGap?: number;
  coloredBackground?: boolean;
}

export const NexupCheckbox: FC<CheckboxProps> = ({
  className,
  onChange,
  onClick,
  onBlur,
  title,
  text,
  errorText,
  checked = false,
  disabled = false,
  style = StyleVariant.Primary,
  textVariant = TextVariant.Subtitle2,
  withoutPadding = false,
  singleLine = true,
  containerClassName,
  textGap = withoutPadding ? 4 : 0,
  coloredBackground = false
}) => {
  const hasErrorText = Boolean(errorText);

  const classes = useCheckboxStyleClassNames({
    maxLines: singleLine ? 1 : undefined,
    textVariant,
    textGap,
    coloredBackground,
    disabled
  });

  const muiTheme = useMuiTheme(style);
  return (
    <ThemeProvider theme={muiTheme}>
      <div className={getClassName(classes.root, className)}>
        <div
          className={getClassName(
            classes.checkboxContainer,
            containerClassName
          )}
        >
          <Checkbox
            className={withoutPadding ? classes.withoutPadding : undefined}
            title={title}
            checked={checked}
            onChange={e => onChange?.(e.target.checked)}
            onBlur={onBlur}
            disabled={disabled}
            color="primary"
            onClick={stopPropagationWrapper(onClick)}
          />
          {text && (
            <span className={classes.text} title={text}>
              {text}
            </span>
          )}
        </div>
        <ErrorText showError={hasErrorText} error={errorText} />
      </div>
    </ThemeProvider>
  );
};
