import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  KnownCodeSystemSystem,
  KnownIdentifierSystem,
  PractitionerKey,
  PractitionerQualificationKey,
  QualificationValidationStatus,
  ResourceType
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { UploadFileImageInput } from "components/generic/UploadFileImageInput/UploadFileImageInput";
import { isEmpty } from "lodash-es";
import { IdentifierFilesInput } from "components/generic/IdentifierFilesInput/IdentifierFilesInput";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { useGetFirstPractitionerOrganizationId } from "./hooks/useGetFirstPractitionerOrganizationId";

export const tk = tkCP.adminPage[ResourceType.Practitioner];
export const PractitionerQualificationTabContent: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);

  const firstPractitionerOrganizationId =
    useGetFirstPractitionerOrganizationId();
  const organizationId =
    firstPractitionerOrganizationId || workspaceOrganizationId;
  const qualificationRegistrationType = useCodeSystemGroup(
    KnownCodeSystemSystem.QualificationRegistrationType,
    firstPractitionerOrganizationId
  );
  const registrationType = useCodeSystemGroup(
    KnownCodeSystemSystem.PractitionerRegistrationType,
    firstPractitionerOrganizationId
  );
  const registrationPlace = useCodeSystemGroup(
    KnownCodeSystemSystem.RegionCode,
    firstPractitionerOrganizationId
  );
  const professionType = useCodeSystemGroup(
    KnownCodeSystemSystem.PractitionerProfession,
    firstPractitionerOrganizationId
  );

  return (
    <>
      <CodeSystemSelectorInput
        source={PractitionerKey.healthcareProfessionalData.profession}
        label={t(tk.fields.profession)}
        codeSystem={professionType}
        organizationId={organizationId}
        withCreate
      />
      <ArrayInput
        source={
          PractitionerKey.healthcareProfessionalData.registrationQualification
        }
        label={t(tk.fields.registration)}
      >
        <CodeSystemSelectorInput
          source={PractitionerQualificationKey.type}
          label={t(tk.fields.registrationType)}
          codeSystem={registrationType}
          organizationId={organizationId}
          extraOptions={qualificationRegistrationType?.concept?.map(value => ({
            id: value.code,
            name: isEmpty(value.display)
              ? value.code
              : `${value.code} (${value.display})`
          }))}
          withCreate
        />
        <EnumSelectorInput
          source={PractitionerQualificationKey.validationStatus}
          label={t(tk.fields.registrationValidationStatus)}
          enumValue={QualificationValidationStatus}
          defaultValue={QualificationValidationStatus.Pending}
        />
        <TextInput
          source={PractitionerQualificationKey.identifier.value}
          label={t(tk.fields.registrationValue)}
        />
        <CodeSystemSelectorInput
          // TODO HIS-15900 armar logica de autodeccion de place a partir del type de la matricula
          source={PractitionerQualificationKey.place}
          label={t(tk.fields.registrationPlace)}
          codeSystem={registrationPlace}
          organizationId={organizationId}
          withCreate
        />
        <EnumSelectorInput
          source={PractitionerQualificationKey.identifier.system}
          label={t(tk.fields.registrationValueType)}
          enumValue={KnownIdentifierSystem}
          defaultValue={
            KnownIdentifierSystem.PractitionerQualificationRegistration
          }
          optionValue={[
            KnownIdentifierSystem.PractitionerQualificationRegistration
          ]}
          disabled
        />
        <TextInput
          // TODO HIS-15897 Armar input
          source={PractitionerQualificationKey.signature.name}
          label={t(tk.fields.registrationSignatureFileName)}
        />
        <UploadFileImageInput
          source={PractitionerQualificationKey.signature.url}
          label={t(tk.fields.registrationSignature)}
          organization={organizationId}
        />
        <IdentifierFilesInput
          source={PractitionerQualificationKey.identifier.files}
          titleLabel={t(tk.fields.registrationIdentifierFiles)}
          organization={organizationId}
          attachmentNameLabel={t(tk.fields.registrationIdentifierFileName)}
          attachmentURLLabel={t(tk.fields.registrationIdentifierFileUrl)}
        />
        <BooleanInput
          source={PractitionerQualificationKey.default}
          label={t(tk.fields.registrationIsDefault)}
        />
      </ArrayInput>
    </>
  );
};
