import {
  ActivityDefinition,
  ActivityDefinitionKind
} from "model/resource/activity/activityDefinition";
import { ResourceType } from "model/primitives/resourceModel";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { MeasurementGroupDefinition } from "model/resource/medical/observation/measurementGroupDefinition";
import { LocationCategory } from "model/resource/entities/location/location";
import { getKeyObj, KeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";

export interface ProcedureReportDefinition
  extends ActivityDefinition<
    ResourceType.ProcedureReportDefinition,
    ActivityDefinitionKind.DiagnosticReport
  > {
  measurementGroupDefinition?: ModelReference<MeasurementGroupDefinition>;
  locationCategory?: LocationCategory[];
  color?: string;
  order?: number;
}

export const ProcedureReportDefinitionKey: KeyObj<ProcedureReportDefinition> =
  getKeyObj(createHydratedMock<ProcedureReportDefinition>());
