import React, { forwardRef } from "react";
import {
  getClassName,
  HTMLDivElementOrNull,
  RadiusVariant
} from "@laba/react-common";
import { Tag, TagTypeVariant } from "components/tags/Tag/Tag";
import { isEmpty } from "lodash-es";
import { ColorTagItem } from "../ColorTag/ColorTag";
import { useColorTagListStyle } from "./ColorTagListStyle";

export interface ColorTagListProps {
  className?: string;
  tagClassName?: string;
  tags?: ColorTagItem[];
  type?: TagTypeVariant;
  radius?: RadiusVariant;
  wrapContent?: boolean;
}

export const ColorTagList = forwardRef<HTMLDivElementOrNull, ColorTagListProps>(
  (
    { className, tags, tagClassName, type, radius, wrapContent = false },
    ref
  ) => {
    const classes = useColorTagListStyle({ wrapContent });
    if (isEmpty(tags)) return null;

    return (
      <div className={getClassName(classes.root, className)} ref={ref}>
        {tags?.map((tag, idx) => (
          <Tag
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            className={getClassName(classes.tag, tagClassName)}
            text={tag.text}
            title={tag.title}
            backgroundColor={tag.backgroundColor}
            type={type}
            radius={radius}
          />
        ))}
      </div>
    );
  }
);
