import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  Theme
} from "@laba/react-common";

interface Props {
  isMobile?: boolean;
}

export interface OdontogramGridStylesClassNames {
  root: string;
}

const useOdontogramGridStyles = createStyle(
  (_theme: Theme) => ({
    rootDesktop: {
      ...flexColumnMixin({ gap: 24, alignItems: FlexAlignItems.Center })
    },
    rootMobile: {
      ...flexColumnMixin({ gap: 32 })
    }
  }),
  "OdontogramGrid"
);

export const useOdontogramGridStylesClassNames = (
  props: Props
): OdontogramGridStylesClassNames => {
  const classes = useOdontogramGridStyles();
  return {
    root: props.isMobile ? classes.rootMobile : classes.rootDesktop
  };
};
