import React from "react";
import { FC, useI18n } from "@laba/react-common";
import { Datagrid } from "components/generic/Datagrid/Datagrid";
import { TextField } from "components/generic/TextField/TextField";
import { ReferenceResourceField } from "components/generic/ReferenceResourceField/ReferenceResourceField";
import {
  ActivityDefinitionKey,
  OrganizationKey,
  ResourceType
} from "@laba/nexup-api";
import { tkCC } from "translation/i18n";

const tk = tkCC.inputs.activityDefinitionCommonListItemInput;

export const ActivityDefinitionCommonListItemInput: FC = () => {
  const { t } = useI18n();

  return (
    <Datagrid>
      <TextField source={ActivityDefinitionKey.id} label={t(tk.id)} />
      <TextField source={ActivityDefinitionKey.name} label={t(tk.name)} />
      <ReferenceResourceField
        resourceType={ResourceType.Organization}
        label={t(tk.organization)}
        source={ActivityDefinitionKey.organization}
        optionTextKey={OrganizationKey.name}
      />
      <TextField source={ActivityDefinitionKey.status} label={t(tk.status)} />
    </Datagrid>
  );
};
