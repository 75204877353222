import { FC } from "components/types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  PushRouterAction,
  ReplaceRouterAction,
  RouterActionType
} from "routing/models/routerAction";
import { NavigatorEvents } from "routing/state/events";
import { NavigatorSelectors } from "routing/state/selectors";
import { NavigatorCompatibleState } from "routing/state/slice";
import { LocationState } from "routing/models";
import { Optional } from "@laba/ts-common";

interface Path {
  pathname: string;
  completePath: string;
}

const getBasePath = (path?: string) => {
  return path?.match(/^([^?#]+)/)?.[1];
};
const getRoutePath = <S extends LocationState>(
  nextAction?: PushRouterAction<S> | ReplaceRouterAction<S>
): Optional<Path> => {
  const route = nextAction?.route;
  if (!route) return undefined;
  if (typeof route === "string") {
    return {
      pathname: getBasePath(route) ?? route,
      completePath: route
    };
  }
  return {
    pathname: getBasePath(route.pathname) ?? route.pathname ?? "",
    completePath: route.pathname ?? ""
  };
};

export const getNavigatorComponent =
  <S extends NavigatorCompatibleState>(
    events: NavigatorEvents<S>,
    selectors: NavigatorSelectors<S>,
    reloadPath?: (path?: string, isLocalhost?: boolean) => boolean
  ): FC =>
  () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const nextAction = useSelector(selectors.nextActionSelector);
    const lastLocationHistory = useSelector(
      selectors.lastLocationHistorySelector
    );
    const isLocalHost = useSelector(
      selectors.isCurrentHostNameLocalhostSelector
    );

    useEffect(() => {
      if (
        lastLocationHistory?.key == null ||
        location.key !== lastLocationHistory.key
      ) {
        dispatch(events.onChangeLocation(location, history.action));
      }
      if (nextAction !== undefined) {
        dispatch(events.onSucceedAction(nextAction));
        switch (nextAction.type) {
          case RouterActionType.Push: {
            const routePath = getRoutePath(nextAction);
            const needsReload = reloadPath?.(routePath?.pathname) ?? false;
            if (routePath && (needsReload || nextAction.reload)) {
              // eslint-disable-next-line no-restricted-properties
              window.location.href = routePath.completePath;
            } else {
              history.push(nextAction.route);
            }
            break;
          }
          case RouterActionType.Replace: {
            const routePath = getRoutePath(nextAction);
            const needsReload =
              reloadPath?.(routePath?.pathname, isLocalHost) ?? false;
            if (routePath && (needsReload || nextAction.reload)) {
              // eslint-disable-next-line no-restricted-properties
              window.location.href = routePath.completePath;
            } else {
              history.replace(nextAction.route);
            }
            break;
          }

          case RouterActionType.GoBack:
            history.goBack();
            break;
          case RouterActionType.Reload:
            // eslint-disable-next-line no-restricted-properties
            window.location.reload();
            break;
          case RouterActionType.ScrollToElement: {
            const target = document.getElementById(nextAction.elementId);
            if (target) {
              target.scrollIntoView();
            }
            break;
          }
          default:
            break;
        }
      }
    }, [
      dispatch,
      lastLocationHistory,
      nextAction,
      history,
      location,
      isLocalHost
    ]);
    return null;
  };
