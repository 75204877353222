import { WhatsappNotificationStatus } from "model/communication/whatsappNotification/whatsappNotificationStatus";
import { WhatsappMessageType } from "model/communication/whatsappNotification/whatsappMessageType";
import { Notification } from "model/communication/notification";
import { createHydratedMock } from "ts-auto-mock";
import { ListQueryParams } from "request/types";
import { getKeyObj, VersionedModelOrReference } from "@laba/ts-common";
import { keys } from "lodash-es";
import { WhatsappBaseNotification } from "model/communication/whatsappBaseNotifcation";
import { ModelId } from "model/primitives/model/model";

export enum WhatsappNotificationOrder {
  CreationDate = "creationDate",
  UpdateDate = "lastUpdatedDate"
}

export interface WhatsappNotification extends WhatsappBaseNotification {
  notification?: VersionedModelOrReference<Notification>;
  status?: WhatsappNotificationStatus;
  messageType?: WhatsappMessageType;
}
export interface WhatsappNotificationLastVersion extends WhatsappNotification {
  _notificationId?: ModelId;
}

export interface WhatsappNotificationDetailQueryParams {
  withNotification?: boolean;
}

export interface WhatsappNotificationListQueryParams
  extends ListQueryParams,
    WhatsappNotificationDetailQueryParams {
  status?: string;
  notification?: string;
  messageType?: WhatsappMessageType;
  externalId?: string;
  order?: WhatsappNotificationOrder;
}

export const WhatsappNotificationParamsKey = getKeyObj<WhatsappNotification>(
  createHydratedMock<WhatsappNotification>()
);

export const WhatsappNotificationLastVersionParamsKey =
  getKeyObj<WhatsappNotificationLastVersion>(
    createHydratedMock<WhatsappNotificationLastVersion>()
  );

export const WhatsappNotificationListQueryParamsKey =
  getKeyObj<WhatsappNotificationListQueryParams>(
    createHydratedMock<WhatsappNotificationListQueryParams>()
  );

export const WhatsappNotificationKeysList = keys(WhatsappNotificationParamsKey);
