import { ModelReference } from "model/primitives/modelReference/modelReference";
import { HealthcareService } from "model/resource/entities/healthcareService/healthcareService";
import { ApiRequestResponse } from "request/types";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { HttpMethod } from "@laba/ts-common";
import { healthcareServiceBasePath } from "api/entities/healthcareService/healthcareService";

export enum HealthcareServiceAction {
  AddToService = "AddToService",
  RemoveFromService = "RemoveFromService"
}

export interface HealthcareServiceActionBody {
  entries: ModelReference<HealthcareService>[];
}

export interface HealthcareServiceActionResponse {
  entries: HealthcareService[];
}

export type HealthcareServiceUpdateAction = (
  data: HealthcareServiceActionBody
) => Promise<ApiRequestResponse<HealthcareServiceActionResponse>>;

export const addCurrentPractitionerToHealthcareService: HealthcareServiceUpdateAction =
  data =>
    request({
      method: HttpMethod.POST,
      url: getFrontApiUrl(
        `${healthcareServiceBasePath}/add-current-practitioner`
      ),
      data
    });

export const removeCurrentPractitionerToHealthcareService: HealthcareServiceUpdateAction =
  data =>
    request({
      method: HttpMethod.POST,
      url: getFrontApiUrl(
        `${healthcareServiceBasePath}/remove-current-practitioner`
      ),
      data
    });
