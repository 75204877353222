import React from "react";
import { getClassName, ReactElement } from "@laba/react-common";
import { NexupIconButton } from "components/buttons/IconButton/IconButton";
import { Add as AddIcon } from "components/icons";
import { Noop } from "@laba/ts-common";
import { SuggestedItemConfig } from "./config";
import { useSuggestedItemStyle } from "./SuggestedItemStyle";

export interface SuggestedItemProps<V> {
  className?: string;
  item: SuggestedItemConfig<V>;
  onClick: Noop;
}

export const SuggestedItem = <V,>({
  className,
  item,
  onClick
}: SuggestedItemProps<V>): ReactElement => {
  const classes = useSuggestedItemStyle();
  return (
    <div className={getClassName(classes.container, className)}>
      <NexupIconButton
        className={classes.button}
        Icon={AddIcon}
        onClick={onClick}
      />
      <p className={classes.text} title={item.title}>
        {item.text}
      </p>
    </div>
  );
};
