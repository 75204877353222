import {
  createPatient,
  getPatient,
  getPatientList,
  PatientListOrder,
  PatientListQueryParamsKey,
  ResourceType,
  updatePatient,
  PatientListQueryParams,
  getModelReferenceId
} from "@laba/nexup-api";
import {
  getEnumOrderParam,
  getKeyTargetParam,
  resourceProviderGetter
} from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { workspaceOrganizationSelector } from "store/workspace/selectors";
import { store } from "store/store";

export const patientProvider = resourceProviderGetter(
  ResourceType.Patient,
  getEnumOrderParam(PatientListOrder),
  getKeyTargetParam<PatientListQueryParams>(PatientListQueryParamsKey),
  getPatientList,
  getPatient,
  updatePatient,
  createPatient,
  undefined,
  () => ({
    organization: getModelReferenceId(
      workspaceOrganizationSelector(store.getState())
    )
  })
);
