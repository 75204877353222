import { createUseStyles, useTheme } from "react-jss";
import { Classes, JssStyle, Styles } from "jss";

export type RuleStyle = JssStyle<undefined>;
export type Style<P = unknown, C extends string = string> = Styles<C, P>;
export type UseStyle<T = unknown, P = unknown, C extends string = string> = (
  data?: P & { theme?: T }
) => Classes<C>;

export type StyleBuilder<
  T = unknown,
  P = unknown,
  C extends string = string
> = (theme: T) => Style<P, C>;

let jssIndexCounter = 0;
const getNextJssIndex = (): number => {
  jssIndexCounter += 1;
  return jssIndexCounter;
};

export const createStyle = <
  T = unknown,
  P = unknown,
  C extends string = string
>(
  style: StyleBuilder<T, P, C>,
  name: string
): UseStyle<T, P, C> =>
  createUseStyles<C, P, T>(style, {
    index: getNextJssIndex(),
    name: `${name}(${jssIndexCounter})`
  });

export { useTheme };
