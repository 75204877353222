import { FC, useField, useI18n } from "@laba/react-common";
import React from "react";
import { WorkspaceLocationConfigData } from "models/organization/workspaceConfiguration";
import { tkCP } from "translation/i18n";
import { DefaultButton, RemoveIcon, TextInput } from "@laba/nexup-components";
import { Grid } from "@mui/material";
import { produce } from "immer";
import { Optional } from "@laba/ts-common";
import { isEmpty } from "lodash-es";

export interface WorkspaceAdministrationLocationFieldProps {
  idx: number;
  locationListUpdate: (idx: number, value: WorkspaceLocationConfigData) => void;
  locationListRemove: (idx: number) => void;
}
const tk = tkCP.adminPage.dashboard.workspaceAdministration;

export const WorkspaceAdministrationLocationField: FC<
  WorkspaceAdministrationLocationFieldProps
> = ({ idx, locationListUpdate, locationListRemove }) => {
  const { t } = useI18n();
  const {
    input: { value: location, onBlur },
    meta: { error, touched }
  } = useField<WorkspaceLocationConfigData, Optional<string>>(
    ["locationList", idx].join("."),
    validateValue =>
      isEmpty(validateValue?.name) ? t(tk.validation.requiredField) : undefined
  );

  return location !== undefined ? (
    <>
      <Grid item sm={6} key={`location ${idx}`}>
        <TextInput
          value={location.name}
          onChange={value =>
            locationListUpdate(
              idx,
              produce(location, draft => {
                draft.name = value;
              })
            )
          }
          onBlur={onBlur}
          errorText={error}
          showError={touched ?? true}
          placeholder={t(tk.locationName)}
          fullWidth
        />
      </Grid>
      <Grid item sm={6} key={`add ${idx}`}>
        <DefaultButton
          fullWidth
          text={t(tk.locationRemove)}
          StartIcon={RemoveIcon}
          onClick={() => {
            locationListRemove(idx);
          }}
        />
      </Grid>
    </>
  ) : null;
};
