import React from "react";
import { FC, getClassName } from "@laba/react-common";
import { ButtonBase } from "components/buttons/ButtonBase/ButtonBase";
import { useSideNavigatorStyles } from "./SideNavigatorStyle";

interface NavItem {
  title: string;
  linkId: string;
}

export interface SideNavigatorProps {
  className?: string;
  items: NavItem[];
  currentlyVisibleLinkId?: string;
  onItemClick: (linkId: string) => void;
}

export const SideNavigator: FC<SideNavigatorProps> = ({
  className,
  onItemClick,
  items,
  currentlyVisibleLinkId
}) => {
  const classes = useSideNavigatorStyles();
  return (
    <div className={getClassName(classes.root, className)}>
      {items.map(({ linkId, title }) => {
        const isSelected = currentlyVisibleLinkId === linkId;
        return (
          <ButtonBase
            key={linkId}
            className={classes.navItemLink}
            onClick={() => onItemClick(linkId)}
          >
            <div
              className={getClassName(
                classes.selectedIndicator,
                isSelected ? classes.indicatorIsSelected : undefined
              )}
            />
            <p
              className={getClassName(
                classes.text,
                isSelected ? classes.textIsSelected : classes.textIsNotSelected
              )}
              title={title}
            >
              {title}
            </p>
          </ButtonBase>
        );
      })}
    </div>
  );
};
