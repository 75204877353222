import { FC, FieldError, OnBlurEvent } from "@laba/react-common";
import React, { useCallback, useState } from "react";
import { PasswordVisibilityButton } from "./PasswordVisibilityButton/PasswordVisibilityButton";
import {
  InputType,
  TextInput,
  TextInputAutoCompleteType
} from "../TextInput/TextInput";

export interface PasswordInputProps {
  className?: string;
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: OnBlurEvent;
  error?: FieldError;
  placeholder?: string;
  autoComplete?: TextInputAutoCompleteType;
  makeVisibleText?: string;
  makeInvisibleText?: string;
  fullWidth?: boolean;
  label?: string;
}

export const PasswordInput: FC<PasswordInputProps> = ({
  value,
  className,
  onChange,
  onBlur,
  error,
  placeholder,
  autoComplete = TextInputAutoCompleteType.CurrentPassword,
  makeVisibleText,
  makeInvisibleText,
  fullWidth,
  label
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const MemorizedPasswordVisibilityButton = useCallback(
    () => (
      <PasswordVisibilityButton
        isVisible={showPassword}
        onClick={() => setShowPassword(!showPassword)}
        makeVisibleText={makeVisibleText}
        makeInvisibleText={makeInvisibleText}
      />
    ),
    [makeVisibleText, makeInvisibleText, showPassword]
  );

  return (
    <TextInput
      className={className}
      label={label}
      fullWidth={fullWidth}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      errorText={error}
      autoComplete={autoComplete}
      EndIcon={MemorizedPasswordVisibilityButton}
      type={showPassword ? InputType.Text : InputType.Password}
    />
  );
};
