import React from "react";
import { FC } from "@laba/react-common";
import { ModelId } from "@laba/ts-common";
import { OrganizationKey } from "@laba/nexup-api";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useSelector } from "react-redux";
import { WhiteLabelBrandDataFields } from "components/generic/WhiteLabelBrandData/WhiteLabelBrandDataFields";
import { useWatchResourceForm } from "components/hook/useResourceContext";

export const OrganizationWhiteLabelHisBrandData: FC = () => {
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organizationId = useWatchResourceForm<ModelId>(OrganizationKey.id);
  const organization = organizationId ?? workspaceOrganizationId;
  return (
    <WhiteLabelBrandDataFields
      source={OrganizationKey.whiteLabelConfig.hisBrandData.thisKey}
      organization={organization}
    />
  );
};
