import { ApiPageRequestResponse, ListQueryParams } from "request/types";
import {
  Optional,
  RequestFailureResponse,
  RequestFailureStatus
} from "@laba/ts-common";
import { BackendError } from "model/resource/error/error";

export const scrapeResourceListEndpoint = async <ResItem, ReqParams>(
  endpoint: (
    args: ReqParams & ListQueryParams
  ) => Promise<ApiPageRequestResponse<ResItem>>,
  params: ReqParams,
  onError?: (resp?: RequestFailureResponse<BackendError>) => void,
  initialPage = 1
): Promise<Optional<ResItem[]>> => {
  let page = initialPage;
  let downloadEnded = false;

  const resources: ResItem[] = [];

  while (!downloadEnded) {
    // Its okay to disable rule in this case, loops are not independant
    // https://eslint.org/docs/rules/no-await-in-loop#when-not-to-use-it
    // eslint-disable-next-line no-await-in-loop
    const response = await endpoint({
      ...params,
      page
    });

    if (response.failureStatus === RequestFailureStatus.Failure) {
      onError?.(response);
      return undefined;
    }

    resources.push(...response.data.entries);
    page += 1;
    downloadEnded = page > response.data.page.pageEntries;
  }

  return resources;
};
