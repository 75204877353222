import { createStyle, pxToRem } from "@laba/react-common";
import { Theme } from "model/theme";

export const useImageContainerStyle = createStyle(
  (_theme: Theme) => ({
    img: {
      objectFit: "cover"
    },
    errorIcon: {
      height: pxToRem(48)
    }
  }),
  "ImageContainer"
);
