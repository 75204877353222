import { ApiDate, getKeyObj } from "@laba/ts-common";
import { Model, OpenCode } from "model/primitives/model/model";
import { Quantity } from "model/primitives/quantity";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { ResourceType } from "model/primitives/resourceModel";
import { Practitioner, PractitionerQualification } from "model/resource";
import { createHydratedMock } from "ts-auto-mock";
import { MedicationRequest } from "model/resource/prescription/medicationRequest/medicationRequest";
import { RouteOption } from "./medicalRequestRoute";
import { Medication } from "../medication/medication";
import { MedicalDevice } from "../medicalDevice";
import { MedicalRequestDefinition } from "../medicalRequestDefinition/medicalRequestDefinition";
import { Frequency } from "./medicalRequestFrequency";

// TODO volver a los nombres en ingles cuando el backned procese i18n
enum KnownRateDevice {
  Dropper = "Gotero" /* "Dropper" */,
  MicroDropper = "Microgotero" /* "MicroDropper" */,
  DialAFlow = "Dial-a-Flow" /* "DialAFlow" */,
  Pump = "Bomba" /* "Pump" */
}

export type RateDevice = OpenCode<KnownRateDevice>;

export interface MedicalRequestRate extends Quantity {
  device?: RateDevice;
}

export interface MedicalRequestDilution extends Quantity {
  solution?: ModelReference<Medication>;
}

export interface ValueCode extends Model {
  code: string;
}

export interface MedicalRequestExtraData extends Model {
  type: string;
  valueString?: string;
  valueDecimal?: number;
  valueBoolean?: boolean;
  valueDateTime?: ApiDate;
  valueQuantity?: Quantity;
  valueCode?: string;
  valueCodeList?: ValueCode[];
  valueMedicalDevice?: ModelReference<MedicalDevice>;
}

export interface Additive extends Model {
  medication?: ModelReference<Medication>;
  dose?: Quantity;
}

export interface MedicationStructureElement extends Model {
  medication?: ModelReference<Medication>;
  dose?: Quantity;
  condition?: string;
  warning?: boolean;
  additives: Additive[];
  presentation?: string;
  presentationAmount?: number;
}

export interface MedicalRequestData {
  mainMedication?: ModelReference<Medication>;
  dose?: Quantity;
  rate?: MedicalRequestRate;
  dilution?: MedicalRequestDilution;
  medicationStructure?: MedicationStructureElement[];
  route?: RouteOption;
  observation?: string;
  frequency?: Frequency;
  providedByPatient?: boolean;
  extraData: MedicalRequestExtraData[];
  presentation?: string;
  presentationAmount?: number;
  practitionerRegistration?: PractitionerQualification;
}

export interface MedicalRequest
  extends MedicationRequest<ResourceType.MedicalRequest> {
  lastRenewDate?: ApiDate;
  expirationDate?: ApiDate;
  lastEditDate?: ApiDate;
  suspensionDate?: ApiDate;
  mainText?: string;
  supplementaryText?: string;
  reportText?: string;
  requestData?: MedicalRequestData;
  medicalDevice?: MedicalDevice[];
  definition?: ModelReference<MedicalRequestDefinition>;
  lastEditor?: ModelReference<Practitioner>;
}

export const MedicalRequestExtraDataFieldValueKey =
  getKeyObj<MedicalRequestExtraData>(
    createHydratedMock<MedicalRequestExtraData>()
  );

export const MedicalRequestMedicationStructureItemFieldValueKey =
  getKeyObj<MedicationStructureElement>(
    createHydratedMock<MedicationStructureElement>()
  );
