import { isString, notUndefined, Optional } from "@laba/ts-common";
import qs, { ParsedQs } from "qs";

export const parseUrlLocationSearch = (search: string): ParsedQs =>
  qs.parse(search, { ignoreQueryPrefix: true });

const defaultValidator = <T extends string>(_s?: string): _s is T => true;

export const getSingleParamFromParsedQs = <T extends string>(
  pq?: ParsedQs,
  paramName?: string,
  validator: (s?: string) => s is T = defaultValidator
): Optional<T> => {
  if (notUndefined(paramName) && notUndefined(pq)) {
    const singleParam = pq[paramName];
    return isString(singleParam) && singleParam !== "" && validator(singleParam)
      ? singleParam
      : undefined;
  }
  return undefined;
};

export const isExternalLocation = (location: string): boolean =>
  location.startsWith("http");

export const transformHostToLink = (url: string): string =>
  url.startsWith("www") ? `http://${url}` : url;
