import React from "react";
import { FC, useI18n } from "@laba/react-common";
import { Edit } from "components/generic/Edit/Edit";
import { ExternalResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { tkCP } from "translation/i18n";
import { useElementExportedFileName } from "components/hook/useExportedFileName";
import {
  NotificationForm,
  notificationJsonToModel,
  notificationTransform
} from "./NotificationForm";

const tk = tkCP.adminPage[ExternalResourceType.Notification];

export const NotificationEdit: FC = () => {
  const { t } = useI18n();
  const exportedFileName = useElementExportedFileName(() => t(tk.title));
  return (
    <Edit
      resourceTransform={notificationTransform}
      resource={ExternalResourceType.Notification}
      jsonToModel={notificationJsonToModel}
      exportedFileName={exportedFileName}
    >
      <NotificationForm />
    </Edit>
  );
};
