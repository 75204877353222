import { DurationUnitValues } from "date/duration";
import { getCurrentDateTime } from "date/utils";

export const MaxVirtualInfiniteDateTime = getCurrentDateTime().set({
  [DurationUnitValues.Year]: 2300
});

export const MinVirtualInfiniteDateTime = getCurrentDateTime().set({
  [DurationUnitValues.Year]: 1900
});
