import {
  borderMixinRadiusAll,
  createStyle,
  ElevationVariant,
  FlexJustifyContent,
  flexRowMixin,
  marginMixin,
  paddingMixin,
  RadiusVariant,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import {
  getThemeRadius,
  getThemeStyleColor,
  themeElevationMixin,
  themeTextMixin
} from "model/themeUtils";

export const useCalendarDatePickerStyle = createStyle(
  (theme: Theme) => ({
    dateText: {
      ...themeTextMixin(theme, TextVariant.Button, 1),
      ...paddingMixin({ topBottom: 4 }),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    datePicker: {
      ...borderMixinRadiusAll(getThemeRadius(theme, RadiusVariant.Small)),
      ...themeElevationMixin(theme, ElevationVariant.Elevation8)
    },
    buttonBase: {
      ...flexRowMixin({ justifyContent: FlexJustifyContent.FlexStart }),
      width: "100%"
    },
    popover: {
      ...marginMixin({ top: 8 })
    }
  }),
  "CalendarDatePicker"
);
