import React from "react";
import {
  FormTab,
  TabbedForm,
  TabbedFormProps,
  TabbedFormTabs
} from "react-admin";
import { ReactElement } from "@laba/react-common";
import { ResourceFormToolbar } from "components/generic/ResourceForm/ResourceFormToolbar";
import { ResourceJsonTab } from "components/generic/ResourceForm/ResourceJsonTab";
import { ResourceModel, ResourceType } from "@laba/nexup-api";
import { deepMapObject, DeepPartial } from "@laba/ts-common";
import produce from "immer";
import { ResourceTransform } from "components/generic/Create/Create";
import { useResolvedPath } from "react-router-dom";
import { matchPath } from "react-router";

export const cleanEmptyString = <T,>(model: T): T =>
  deepMapObject(model || {}, value => (value === "" ? undefined : value));
export const getDefaultResourceTransform =
  <T extends ResourceModel<ResourceType>>(
    resourceType: ResourceType,
    extraTransform?: (draft: DeepPartial<T>) => void
  ): ResourceTransform<T> =>
  resource => {
    const result = produce(cleanEmptyString(resource), draft => {
      draft.resourceType = resourceType;
    });
    return extraTransform ? produce(result, extraTransform) : result;
  };

export interface TabConfig {
  content: ReactElement;
  label: string;
}
export interface ResourceFormProps extends Omit<TabbedFormProps, "children"> {
  tabsConfig?: TabConfig[];
  postJsonTabsConfig?: TabConfig[];
}

export const ResourceForm = (props: ResourceFormProps): ReactElement => {
  const { tabsConfig, postJsonTabsConfig, ...formProps } = props;
  const resolvedPath = useResolvedPath("");
  const match = matchPath(
    `${resolvedPath.pathname}/:tabId`,
    // eslint-disable-next-line no-restricted-globals
    location.pathname
  );
  const tabId = match?.params.tabId ?? "0";
  return (
    <TabbedForm
      toolbar={<ResourceFormToolbar />}
      warnWhenUnsavedChanges
      tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />}
      {...formProps}
    >
      {tabsConfig?.map(({ label, content }, index) => (
        <FormTab key={label} label={label}>
          {tabId === String(index) && content}
        </FormTab>
      ))}
      <ResourceJsonTab activeTab={tabId === String(tabsConfig?.length || 0)} />
      {postJsonTabsConfig?.map(({ label, content }, index) => (
        <FormTab key={label} label={label}>
          {tabId === String(index + (tabsConfig?.length || 0) + 1) && content}
        </FormTab>
      ))}
    </TabbedForm>
  );
};
