import React, { useMemo } from "react";
import { FCC } from "components/types";
import { useGetUserCountryCode } from "hooks/location/useGetUserCountryCode";
import { CountryCodeContext } from "./utils";

interface Props {
  context: CountryCodeContext;
  onlyIp?: boolean;
  ipProviderApiKey?: string;
}

export const CountryCodeProvider: FCC<Props> = ({
  children,
  context,
  onlyIp,
  ipProviderApiKey
}) => {
  const defaultCountryCode = useGetUserCountryCode(ipProviderApiKey, onlyIp);

  const value = useMemo(
    () => ({
      countryCode: defaultCountryCode
    }),
    [defaultCountryCode]
  );
  return <context.Provider value={value}>{children}</context.Provider>;
};
