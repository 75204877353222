import React from "react";
import { FC, StyleProvider } from "@laba/react-common";
import { Provider } from "react-redux";
import { persistor, store } from "store/store";
import { BrowserRouter } from "react-router-dom";
import { defaultTheme } from "@laba/nexup-components";
import { NexupAdmin } from "components/pages/NexupAdmin/NexupAdmin";
/*
 * "redux-persist" has a bug where compilation fails if you only import from
 * "redux-persist/integration/react". Current workaround consist of importing anything
 * else from redux-persist so de compiler finds de type declarations, for more information
 * see: https://github.com/rt2zz/redux-persist/issues/1166
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars,  no-unused-vars,@typescript-eslint/ban-ts-comment
// @ts-ignore
import { PersistGate } from "redux-persist/integration/react";

export const App: FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyleProvider theme={defaultTheme}>
          <BrowserRouter>
            <NexupAdmin />
          </BrowserRouter>
        </StyleProvider>
      </PersistGate>
    </Provider>
  );
};
