import { get } from "lodash-es";
import { notUndefined } from "model/types";
import { PhoneValidationError } from "./PhoneValidationError";
import { hasPhoneNumberPlusPrefix } from "./utils";

const uruguayanCodeRegex = /^(?:\+?598)\d+/; // Test if it's an uruguayan phone
const phoneInvalidAdditionalZeroRegex = /^(?:\+?598)?(0)\d+/; // Test if it starts with 0
const phoneWithoutAreaCodeRegex = /^(?:\+?598?)(\d+)$/; // Matches de digits after +598

const hasInvalidAdditionalZeroAreaCode = (phoneNumber: string) =>
  phoneInvalidAdditionalZeroRegex.test(phoneNumber);

const getPhoneWithoutAreaCode = (phoneNumber: string) => {
  const match = phoneNumber.match(phoneWithoutAreaCodeRegex);
  return get(match, 1);
};
const getPhoneWithoutAreaCodeLength = (phoneNumber: string) => {
  const phone = getPhoneWithoutAreaCode(phoneNumber);
  return phone?.length ?? 0;
};

export const isUruguayanPhone = (phoneNumber: string): boolean =>
  uruguayanCodeRegex.test(phoneNumber);

export const validateUruguayanPhoneNumber = (
  phoneNumber: string
): PhoneValidationError[] => {
  const hasPrefix = hasPhoneNumberPlusPrefix(phoneNumber);

  const invalidAdditionalZeroAreaCode =
    hasInvalidAdditionalZeroAreaCode(phoneNumber);
  const phoneWithoutAreaCodeLength = getPhoneWithoutAreaCodeLength(phoneNumber);

  return [
    !hasPrefix ? PhoneValidationError.MissingPrefix : undefined,
    invalidAdditionalZeroAreaCode
      ? PhoneValidationError.InvalidAdditionZeroAreaCode
      : undefined,
    phoneWithoutAreaCodeLength > 8
      ? PhoneValidationError.TooManyDigits
      : undefined,
    phoneWithoutAreaCodeLength < 8
      ? PhoneValidationError.MissingDigits
      : undefined
  ].filter(notUndefined);
};
