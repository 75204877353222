import {
  createStyle,
  FlexAlignItems,
  FlexAlignSelf,
  flexColumnMixin,
  flexItemMixin,
  flexRowMixin,
  getClassName,
  paddingMixin,
  pxToRem,
  StyleVariant,
  TextAlign,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { isEqual } from "lodash-es";
import { getThemeStyleColor, getThemeStyleState } from "model/themeUtils";
import { FormWarningInfoFieldVariant } from "./types";

interface FormWarningInfoFieldDynamicStylesProps {
  isMobile?: boolean;
  variant?: FormWarningInfoFieldVariant;
  textAlign: TextAlign;
}

interface FormWarningInfoFieldClassNames {
  icon: string;
  text: string;
  urlLink: string;
  root: string;
  textAndLinkWrapper: string;
}

const useFormWarningInfoFieldStaticStyles = createStyle(
  (theme: Theme) => ({
    icon: {
      ...flexItemMixin({ align: FlexAlignSelf.FlexStart, shrink: 0 })
    },
    text: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayMedium
      )
    },
    urlLink: { minWidth: "min-content" },
    warningColor: {
      color: getThemeStyleColor(theme, StyleVariant.Warning)
    },
    infoColor: {
      color: getThemeStyleColor(theme, StyleVariant.Info)
    },
    warningBackgroundColor: {
      backgroundColor: getThemeStyleState(theme, StyleVariant.Warning).hover
    },
    infoBackgroundColor: {
      backgroundColor: getThemeStyleState(theme, StyleVariant.Info).hover
    }
  }),
  "FormWarningInfoField"
);

const useFormWarningInfoFieldDynamicStyles = createStyle(
  (_theme: Theme) => ({
    root: (props: FormWarningInfoFieldDynamicStylesProps) => ({
      ...paddingMixin({ leftRight: props.isMobile ? 8 : 16, topBottom: 8 }),
      ...flexRowMixin({ gap: 8, alignItems: FlexAlignItems.Center }),
      minHeight: pxToRem(40),
      width: "100%"
    }),
    textAndLinkWrapper: (props: FormWarningInfoFieldDynamicStylesProps) => ({
      ...(props.isMobile
        ? flexColumnMixin({ gap: 4 })
        : flexRowMixin({ gap: 4 }))
    }),
    textAlign: props => ({
      textAlign: props.textAlign
    })
  }),
  "FormWarningInfoFieldDynamic"
);

export const useFormWarningInfoFieldStyles = (
  props: FormWarningInfoFieldDynamicStylesProps
): FormWarningInfoFieldClassNames => {
  const { variant } = props;
  const staticClasses = useFormWarningInfoFieldStaticStyles();
  const dynamicClasses = useFormWarningInfoFieldDynamicStyles(props);

  const isWarningVariant = isEqual(
    variant,
    FormWarningInfoFieldVariant.Warning
  );

  return {
    icon: getClassName(
      staticClasses.icon,
      isWarningVariant ? staticClasses.warningColor : staticClasses.infoColor
    ),
    text: getClassName(staticClasses.text, dynamicClasses.textAlign),
    urlLink: staticClasses.urlLink,
    root: getClassName(
      dynamicClasses.root,
      isWarningVariant
        ? staticClasses.warningBackgroundColor
        : staticClasses.infoBackgroundColor
    ),
    textAndLinkWrapper: dynamicClasses.textAndLinkWrapper
  };
};
