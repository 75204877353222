import React from "react";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useListExportedFileName } from "components/hook/useExportedFileName";
import { List } from "components/generic/List/List";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import { practitionerOptionText } from "components/generic/ReferenceResourceInput/optionText";
import { FC, useI18n } from "@laba/react-common";
import {
  getFullNameFromPerson,
  KnownCodeSystemSystem,
  OrganizationCombinedType,
  OrganizationListQueryParamsKey,
  Practitioner,
  PractitionerListQueryParamsKey,
  ResourceType,
  ScheduleListQueryParamsKey,
  ScheduleParamsKey
} from "@laba/nexup-api";
import { useSelector } from "react-redux";
import { tkCP } from "translation/i18n";
import { Datagrid } from "components/generic/Datagrid/Datagrid";
import { TextField } from "components/generic/TextField/TextField";
import { ReferenceResourceField } from "components/generic/ReferenceResourceField/ReferenceResourceField";
import { DateField } from "components/generic/DateField/DateField";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { scheduleJsonToModel } from "components/pages/NexupAdmin/resources/schedule/ScheduleForm";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

const tk = tkCP.adminPage[ResourceType.Schedule];

export const ScheduleList: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const exportedFileName = useListExportedFileName(t(tk.title));

  const scheduleSpecialitySystem = useCodeSystemGroup(
    KnownCodeSystemSystem.ScheduleSpeciality,
    workspaceOrganizationId
  );

  return (
    <List
      resource={ResourceType.Schedule}
      exportedFileName={exportedFileName}
      jsonToModel={scheduleJsonToModel}
      filterDefaultValues={{
        [ScheduleListQueryParamsKey.active]: true
      }}
      extraFilters={[
        workspaceOrganizationId === undefined ? (
          <OrganizationReferenceInput
            label={t(tk.filters.organization)}
            source={ScheduleListQueryParamsKey.organization}
            alwaysOn
            type={OrganizationCombinedType.ProviderAndGroup}
            extraFilters={{
              [OrganizationListQueryParamsKey.active]: true
            }}
          />
        ) : undefined,
        <ReferenceResourceInput<Practitioner>
          resourceType={ResourceType.Practitioner}
          label={t(tk.filters.practitioner)}
          source={ScheduleListQueryParamsKey.practitioner}
          optionTextKey={practitionerOptionText}
          alwaysOn
          extraFilters={{
            [PractitionerListQueryParamsKey.organization]:
              workspaceOrganizationId
          }}
        />,
        <CodeSystemSelectorInput
          source={ScheduleListQueryParamsKey.speciality}
          label={t(tk.filters.speciality)}
          codeSystem={scheduleSpecialitySystem}
          organizationId={workspaceOrganizationId}
          alwaysOn
          resettable
          withCreate
        />,
        <BooleanInput
          source={ScheduleListQueryParamsKey.active}
          label={t(tk.filters.active)}
          alwaysOn
        />
      ]}
    >
      <Datagrid>
        <TextField source={ScheduleParamsKey.id} label={t(tk.fields.id)} />
        <TextField source={ScheduleParamsKey.name} label={t(tk.fields.name)} />
        <ReferenceResourceField<Practitioner>
          resourceType={ResourceType.Practitioner}
          label={t(tk.fields.practitioner)}
          source={ScheduleParamsKey.practitioner}
          optionTextKey={getFullNameFromPerson}
        />
        <TextField
          source={ScheduleParamsKey.active}
          label={t(tk.fields.active)}
        />
        <DateField
          source={ScheduleParamsKey.planningHorizon.start}
          label={t(tk.fields.planningHorizonStartDate)}
          showTime
        />
        <DateField
          source={ScheduleParamsKey.planningHorizon.end}
          label={t(tk.fields.planningHorizonEndDate)}
          showTime
        />
      </Datagrid>
    </List>
  );
};
