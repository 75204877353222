import React from "react";
import Lottie from "react-lottie";
import { FC } from "@laba/react-common";
import { animationData } from "./success";

export const SentIcon: FC = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <div className="sentIcon">
      <Lottie options={defaultOptions} height={75} width={75} />
    </div>
  );
};
