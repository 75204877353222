export enum PhoneValidationError {
  InvalidPhoneNumber = "InvalidPhoneNumber",
  MissingPrefix = "MissingPrefix",
  InvalidCountryPrefix = "InvalidCountryPrefix",
  TooManyDigits = "TooManyDigits",
  MissingDigits = "MissingDigits",
  InvalidAdditionZeroAreaCode = "InvalidAdditionZeroAreaCode",
  InvalidArgentinaBsAsAreaCode = "InvalidArgentinaBsAsAreaCode",
  InvalidArgentinaBsAsAdditionZeroAreaCode = "InvalidArgentinaBsAsAdditionZeroAreaCode",
  InvalidArgentinaInternalAreaCode = "InvalidArgentinaInternalAreaCode"
}
