import {
  getDefaultJsonToModel,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";
import { MedicalDevice, ResourceType } from "@laba/nexup-api";
import {
  getDefaultResourceTransform,
  ResourceForm
} from "components/generic/ResourceForm/ResourceForm";
import { tkCP } from "translation/i18n";
import { FC, useI18n } from "@laba/react-common";
import { MedicalDeviceMainContent } from "components/pages/NexupAdmin/resources/medicalDevice/MedicalDeviceMainContent";
import React from "react";

export const medicalDeviceJsonToModel: JsonToModel<MedicalDevice> =
  getDefaultJsonToModel(draft => {
    draft.organization = undefined;
  });
export const medicalDeviceTransform =
  getDefaultResourceTransform<MedicalDevice>(ResourceType.MedicalDevice);

export const tk = tkCP.adminPage[ResourceType.MedicalDevice];

export const MedicalDeviceForm: FC = () => {
  const { t } = useI18n();

  return (
    <ResourceForm
      tabsConfig={[
        {
          label: t(tk.tabs.main),
          content: <MedicalDeviceMainContent />
        }
      ]}
    />
  );
};
