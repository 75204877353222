import { ApiPageRequestResponse, ApiRequestResponse } from "request/types";
import { getCommunicationApiUrl, request } from "request/nexupRequest";
import { HttpMethod } from "@laba/ts-common";
import {
  Notification,
  NotificationLastVersion,
  NotificationListQueryParams
} from "model/communication";

const notificationBasePath = "/notification";

export const getNotificationLastVersionList = (
  params: NotificationListQueryParams
): Promise<ApiPageRequestResponse<NotificationLastVersion>> =>
  request({
    url: getCommunicationApiUrl(notificationBasePath),
    method: HttpMethod.GET,
    params
  });

export const getNotificationLastVersionDetail = (
  id: string
): Promise<ApiRequestResponse<NotificationLastVersion>> =>
  request({
    url: getCommunicationApiUrl(`${notificationBasePath}/${id}`),
    method: HttpMethod.GET
  });

export const createNotification = (
  notification: Notification
): Promise<ApiRequestResponse<Notification>> =>
  request({
    url: getCommunicationApiUrl(notificationBasePath),
    method: HttpMethod.POST,
    data: notification
  });

export const editNotification = (
  notification: Notification
): Promise<ApiRequestResponse<Notification>> =>
  request({
    method: HttpMethod.PUT,
    url: getCommunicationApiUrl(
      `${notificationBasePath}/${notification.id ?? ""}`
    ),
    data: notification
  });
