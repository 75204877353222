import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { ModelId } from "model/primitives/model/model";
import { ValidationStatus } from "model/resource/person/person";
import { Patient } from "model/resource/person/patient/patient";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { getKeyObj, HttpMethod, KeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { EncounterService } from "model/resource/entities/encounter/encounter";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { Email, Phone } from "model/primitives/contactPoint";
import { getModelReferenceId } from "model/primitives/modelReference/utils";

export enum PatientListOrder {
  CreationDate = "creationDate",
  Name = "name",
  CurrentBed = "currentBed",
  LastResourceDate = "lastResourceDate"
}

export enum HospitalizationStatus {
  Hospitalized = "hospitalized",
  NotHospitalized = "notHospitalized"
}

export interface PatientQueryParams {
  withOrganization?: boolean;
  withHealthCarePayerProvider?: boolean;
  withCurrentHospitalization?: boolean;
  withCurrentBed?: boolean;
  withPractitionerTeam?: boolean;
  withTagList?: boolean;
  withMeasurements?: boolean;
  withDiagnosis?: boolean;
  withClassification?: boolean;
  withAllergyIntoleranceList?: boolean;
  withPrescriptionStatus?: boolean;
}

export interface PatientListQueryParams
  extends ListQueryParams,
    PatientQueryParams {
  content?: string;
  currentLocation?: ModelId;
  diagnostic?: string;
  hospitalizationStatus?: HospitalizationStatus;
  order?: PatientListOrder;
  organization?: ModelId;
  practitioner?: ModelId;
  service?: EncounterService;
  validationStatus?: ValidationStatus;
  active?: boolean;
  payer?: ModelId;
}

export interface UpdateNotificationPatientData {
  notificationPhone?: Phone;
  notificationEmail?: Email;
}

export const PatientListQueryParamsKey: KeyObj<PatientListQueryParams> =
  getKeyObj(createHydratedMock<PatientListQueryParams>());

const patientBasePath = "/patient";

const sanitizeQueryParams = (
  qp: PatientListQueryParams
): PatientListQueryParams => {
  if (qp.hospitalizationStatus === HospitalizationStatus.NotHospitalized) {
    return { ...qp, currentLocation: undefined };
  }
  return qp;
};

export const getPatientList = (
  queryParams: PatientListQueryParams
): Promise<ApiPageRequestResponse<Patient>> => {
  const params = sanitizeQueryParams(queryParams);
  return request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(patientBasePath),
    params: {
      active: true,
      ...params
    }
  });
};

export const getPatient = (
  id: ModelId,
  params?: PatientQueryParams
): Promise<ApiRequestResponse<Patient>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${patientBasePath}/${id}`),
    params
  });

export const createPatient = (
  data: Patient
): Promise<ApiRequestResponse<Patient>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(patientBasePath),
    data
  });

export const updatePatient = (
  data: Patient
): Promise<ApiRequestResponse<Patient>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${patientBasePath}/${data.id}`),
    data
  });

export const updatePatientNotificationContactPointData = (
  patient: ModelReference<Patient>,
  data: UpdateNotificationPatientData
): Promise<ApiRequestResponse<Patient>> => {
  return request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(
      `${patientBasePath}/${getModelReferenceId(patient)}/notification-data`
    ),
    data
  });
};
