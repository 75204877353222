import React from "react";
import { some } from "lodash-es";
import { notUndefined } from "@laba/ts-common";
import { Children, ReactElement, StyleVariant } from "@laba/react-common";
import { AutocompleteOptionConfig } from "../Autocomplete";
import { AutocompleteTitleOnlyOptionWrapper } from "./AutocompleteOptionVariants/AutocompleteTitleOnlyOptionWrapper";
import { AutocompleteTitleWithSubtextOptionWrapper } from "./AutocompleteOptionVariants/AutocompleteTitleWithSubtextOptionWrapper";

export enum AutocompleteOptionVariant {
  TitleWithSubtext = "TitleWithSubtext",
  TitleOnly = "TitleOnly"
}

export interface AutocompleteOptionProps<V> {
  isMobile?: boolean;
  children?: Children;
  option: AutocompleteOptionConfig<V>;
  onRemoveSelectedOption?: (optionValue: V) => void;
  removeIconStyle?: StyleVariant;
  disabled?: boolean;
  hasBackgroundColor?: boolean;
  fullWidth?: boolean;
  isSelectedOption?: boolean;
  actionControlComponent?: ReactElement;
  withPadding?: boolean;
}

export const AutocompleteOption = <V,>({
  children,
  option,
  onRemoveSelectedOption,
  removeIconStyle,
  disabled,
  hasBackgroundColor,
  fullWidth,
  isSelectedOption,
  isMobile,
  actionControlComponent,
  withPadding
}: AutocompleteOptionProps<V>): ReactElement => {
  const variant = some([option.primaryText, option.subtext], notUndefined)
    ? AutocompleteOptionVariant.TitleWithSubtext
    : AutocompleteOptionVariant.TitleOnly;

  switch (variant) {
    case AutocompleteOptionVariant.TitleOnly:
      return (
        <AutocompleteTitleOnlyOptionWrapper
          option={option}
          onRemoveSelectedOption={onRemoveSelectedOption}
          removeIconStyle={removeIconStyle}
          disabled={disabled ?? option.disabled}
          fullWidth={fullWidth}
          isSelectedOption={isSelectedOption}
          isMobile={isMobile}
          hasBackgroundColor={hasBackgroundColor}
          actionControlComponent={actionControlComponent}
          withPadding={withPadding}
        >
          {children}
        </AutocompleteTitleOnlyOptionWrapper>
      );
    case AutocompleteOptionVariant.TitleWithSubtext:
      return (
        <AutocompleteTitleWithSubtextOptionWrapper
          option={option}
          onRemoveSelectedOption={onRemoveSelectedOption}
          removeIconStyle={removeIconStyle}
          disabled={disabled}
          fullWidth={fullWidth}
          isSelectedOption={isSelectedOption}
          isMobile={isMobile}
          hasBackgroundColor={hasBackgroundColor}
          actionControlComponent={actionControlComponent}
        >
          {children}
        </AutocompleteTitleWithSubtextOptionWrapper>
      );
  }
};
