import React, { useCallback, useMemo, useState, ChangeEvent } from "react";
import { ReactElementOrNull, useI18n } from "@laba/react-common";
import {
  Questionnaire,
  QuestionnaireEnableWhenOperator,
  QuestionnaireFieldType,
  QuestionnaireItemEnableWhen,
  QuestionnaireItemEnableWhenValueKey,
  ResourceType
} from "@laba/nexup-api";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { getEnumOrUndefined, Optional } from "@laba/ts-common";
import { SelectInput } from "@laba/nexup-components";
import {
  RaRecord,
  SelectInput as ReactAdminSelectInput,
  useInput
} from "react-admin";
import { tkCP } from "translation/i18n";
import { head, isUndefined } from "lodash-es";
import {
  QuestionnaireItemEnableWhenAnswer,
  QuestionnaireResponseAnswerEnum
} from "./QuestionnaireItemEnableWhenAnswer/QuestionnaireItemEnableWhenAnswer";

type ChangeEventOrRaRecord = ChangeEvent<HTMLInputElement> | RaRecord;

export const tk = tkCP.adminPage[ResourceType.Questionnaire].fields;

interface QuestionnaireItemEnableWhenComponentProps {
  getSource: (source: string) => string;
  questionnaire: Questionnaire;
  qItemEnableWhen?: QuestionnaireItemEnableWhen;
}

const getAnswerTypeByLinkId = (
  questionnaire: Questionnaire,
  linkId?: string
): Optional<QuestionnaireResponseAnswerEnum> => {
  if (isUndefined(linkId)) return;

  const field = questionnaire.cards
    .flatMap(c => c.fields)
    .find(f => f.linkId === linkId);

  switch (field?.fieldType) {
    case QuestionnaireFieldType.Date:
      return QuestionnaireResponseAnswerEnum.valueDate;
    case QuestionnaireFieldType.Datetime:
      return QuestionnaireResponseAnswerEnum.valueDateTime;
    case QuestionnaireFieldType.Numeric:
      return QuestionnaireResponseAnswerEnum.valueQuantity;
    case QuestionnaireFieldType.CategoryResult:
    case QuestionnaireFieldType.ScaleScore:
      return QuestionnaireResponseAnswerEnum.valueDecimal;
    case QuestionnaireFieldType.SearchableList:
    case QuestionnaireFieldType.Selector:
      return QuestionnaireResponseAnswerEnum.valueCoding;
    case QuestionnaireFieldType.TextField:
      return QuestionnaireResponseAnswerEnum.valueString;
    case QuestionnaireFieldType.Checkbox:
      return QuestionnaireResponseAnswerEnum.valueBoolean;
    case QuestionnaireFieldType.FileList:
      return QuestionnaireResponseAnswerEnum.valueAttachment;
    case QuestionnaireFieldType.ProblemList:
      return QuestionnaireResponseAnswerEnum.valueReferenceProblem;
    case QuestionnaireFieldType.Measurement:
      return QuestionnaireResponseAnswerEnum.valueReferenceMeasure;
    case QuestionnaireFieldType.AllergyList:
      return QuestionnaireResponseAnswerEnum.valueReferenceAllergy;
    case QuestionnaireFieldType.ProcedureReport:
      return QuestionnaireResponseAnswerEnum.valueReferenceProcedureReport;
    case QuestionnaireFieldType.DeviceList:
      return QuestionnaireResponseAnswerEnum.valueReferenceDevice;
    case QuestionnaireFieldType.MedicationList:
      return QuestionnaireResponseAnswerEnum.valueReferenceMedication;
    case QuestionnaireFieldType.EncounterSelector:
      return QuestionnaireResponseAnswerEnum.valueReferenceEncounter;
    case QuestionnaireFieldType.Odontogram:
      return QuestionnaireResponseAnswerEnum.valueReferenceOdontogram;
    case QuestionnaireFieldType.Whitespace:
    case QuestionnaireFieldType.DisplayText:
    default:
      return undefined;
  }
};

export const QuestionnaireItemEnableWhenComponent = ({
  getSource,
  questionnaire,
  qItemEnableWhen
}: QuestionnaireItemEnableWhenComponentProps): ReactElementOrNull => {
  const { t } = useI18n();

  const linkIdsChoices = useMemo(
    () =>
      questionnaire.cards
        .flatMap(c => c.fields.map(f => f.linkId))
        .map(id => ({ id, name: id })),
    [questionnaire]
  );

  const firstAnswer = head(Object.keys(qItemEnableWhen?.answer ?? {}));

  const [answerType, setAnswerType] = useState<
    Optional<QuestionnaireResponseAnswerEnum>
  >(getEnumOrUndefined(QuestionnaireResponseAnswerEnum)(firstAnswer));

  const options = useMemo(
    () =>
      Object.values(QuestionnaireResponseAnswerEnum).map(v => ({
        itemId: v,
        text: v,
        value: v,
        title: v
      })),
    []
  );

  const {
    field: { onChange: onChangeAnswerValue }
  } = useInput({
    source: getSource(QuestionnaireItemEnableWhenValueKey.answer.thisKey)
  });

  const handleOnChangeAnswerType = useCallback(
    (value?: QuestionnaireResponseAnswerEnum) => {
      onChangeAnswerValue(undefined);
      setAnswerType(value);
    },
    [onChangeAnswerValue]
  );

  const getOptionNameFromValue = useCallback(
    opt =>
      Object.entries(QuestionnaireEnableWhenOperator).find(
        ([_key, value]) => value === opt
      )?.[0],
    []
  );

  const handleChangeAnswerTypeByLinkId = useCallback(
    (qtn: Questionnaire, linkId?: string) => {
      const answerTypeByLinkId = getAnswerTypeByLinkId(qtn, linkId);
      handleOnChangeAnswerType(answerTypeByLinkId);
    },
    [handleOnChangeAnswerType]
  );

  const handleOnChangeLinkIdSelector = (event: ChangeEventOrRaRecord) => {
    const linkId = event.target.value;
    handleChangeAnswerTypeByLinkId(questionnaire, linkId);
  };

  const handleOnChangeOperatorSelector = (event: ChangeEventOrRaRecord) => {
    if (event.target.value === QuestionnaireEnableWhenOperator.Exists) {
      handleOnChangeAnswerType(QuestionnaireResponseAnswerEnum.valueBoolean);
    } else {
      handleChangeAnswerTypeByLinkId(questionnaire, qItemEnableWhen?.linkId);
    }
  };

  return (
    <>
      {/* TODO HIS-11165 agregar componente de SelectInput a los componentes genericos y modifcar aca */}
      <ReactAdminSelectInput
        source={getSource(QuestionnaireItemEnableWhenValueKey.linkId)}
        choices={linkIdsChoices}
        label={t(tk.enableWhenLinkId)}
        variant="outlined"
        fullWidth
        onChange={handleOnChangeLinkIdSelector}
        required
      />
      <EnumSelectorInput
        source={getSource(QuestionnaireItemEnableWhenValueKey.operator)}
        label={t(tk.enableWhenOperator)}
        enumValue={QuestionnaireEnableWhenOperator}
        getOptionNameFromValue={getOptionNameFromValue}
        onChange={handleOnChangeOperatorSelector}
        required
      />
      <SelectInput
        fullWidth
        placeholder={t(tk.answerSelectInput)}
        value={answerType}
        onChange={handleOnChangeAnswerType}
        options={options}
      />
      <QuestionnaireItemEnableWhenAnswer
        answerType={answerType}
        getSource={getSource}
      />
    </>
  );
};
