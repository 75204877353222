import React from "react";
import { FC } from "@laba/react-common";
import { OdontogramIconProps } from "components/icons/OdontogramIcons/utils";
import { NexupColor } from "model/nexupColor";

export const IncrustationIcon: FC<OdontogramIconProps> = props => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill={props.iconColor ?? NexupColor.Black}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 4.5V20.5H11.5V4.5H13.5Z"
      />
    </svg>
  );
};
