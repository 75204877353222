import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  flexRowMixin,
  paddingMixin,
  StyleVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor } from "model/themeUtils";

export const usePatientDetailMobilePopupContentStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexColumnMixin({ gap: 16 })
    },
    patientDetailData: {
      ...paddingMixin({ leftRight: 24, topBottom: 24 }),
      ...flexColumnMixin({ gap: 24 }),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Background
      )
    },
    patientDetailDataItem: {
      ...flexRowMixin({ gap: 4 })
    },
    colorTagList: {
      ...flexRowMixin({ gap: 8, wrapContent: true })
    },
    patientAllergies: {
      ...paddingMixin({ leftRight: 24, bottom: 24 }),
      ...flexRowMixin({ gap: 8, alignItems: FlexAlignItems.Center })
    }
  }),
  "PatientDetailMobilePopupContent"
);
