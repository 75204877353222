import { ReactElement } from "@laba/react-common";
import React from "react";
import {
  NumberInputProps as ReactAdminNumberInputProps,
  NumberInput as ReactAdminNumberInput,
  required as requiredValidate
} from "react-admin";
import { getAsArray, notNull } from "@laba/ts-common";

export interface NumberInputProps extends ReactAdminNumberInputProps {
  required?: boolean;
}
export const NumberInput = ({
  validate,
  required,
  ...props
}: NumberInputProps): ReactElement => {
  const validateArray = getAsArray(validate).filter(notNull);
  if (required) validateArray.push(requiredValidate());
  return (
    <ReactAdminNumberInput
      fullWidth
      variant="outlined"
      validate={validateArray}
      {...props}
    />
  );
};
