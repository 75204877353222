import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  CodeSystemKey,
  ResourceType,
  CodeSystemConceptKey
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";

export const tk = tkCP.adminPage[ResourceType.CodeSystem];
export const CodeSystemConceptContent: FC = () => {
  const { t } = useI18n();
  return (
    <ArrayInput source={CodeSystemKey.concept} label={t(tk.fields.conceptList)}>
      <TextInput
        source={CodeSystemConceptKey.code}
        label={t(tk.fields.conceptCode)}
      />
      <TextInput
        source={CodeSystemConceptKey.display}
        label={t(tk.fields.conceptDisplay)}
      />
    </ArrayInput>
  );
};
