import { ResourceType } from "model/primitives/resourceModel";
import { OrganizationType } from "model/resource/entities/organization/organization";
import { Account } from "../account";

export const accountMock1: Account = {
  resourceType: ResourceType.Account,
  creationDate: "2023-12-22T23:59:59.999-03",
  id: "23",
  lastEditDate: "2023-12-22T23:59:59.999-03",
  payer: {
    type: OrganizationType.Payer,
    resourceType: ResourceType.Organization,
    name: "payer2"
  }
};

export const accountMock2: Account = {
  resourceType: ResourceType.Account,
  creationDate: "2023-12-22T23:59:59.999-03",
  id: "25",
  lastEditDate: "2023-12-22T23:59:59.999-03",
  payer: {
    type: OrganizationType.Payer,
    resourceType: ResourceType.Organization,
    name: "payer3"
  }
};
