export type ModelId = string;

export type OpenCode<T extends string> = T | string;

export interface Model {
  id?: ModelId;
}

export const isModel = (model: unknown): model is Model => {
  if (typeof model !== "object" || model === null) return false;
  return (model as Model).id !== undefined;
};
export const modelHasThisId = (model: Model, id?: ModelId): boolean =>
  model.id !== undefined && model.id === id;
export const modelsHaveSameId = (model?: Model, otherModel?: Model): boolean =>
  model !== undefined && modelHasThisId(model, otherModel?.id);
