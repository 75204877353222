import {
  List as ReactAdminList,
  ListProps as ReactAdminListProps
} from "react-admin";
import React from "react";
import { ReactElement, ReactElementOrNull, useI18n } from "@laba/react-common";
import {
  defaultPaginationPerPage,
  ListPagination
} from "components/generic/ListPagination/ListPagination";
import { Model } from "@laba/nexup-api";
import {
  getFilters,
  GetFiltersProps
} from "components/generic/ListFilters/ListFilters";
import { useSelector } from "react-redux";
import { isLogIn } from "store/session/selectors";
import {
  ActionMode,
  ResourceActions,
  ResourceActionsProps
} from "components/generic/ResourceAction/ResourceAction";
import { Navigate } from "react-router-dom";
import { RaResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { DefaultMeta } from "providers/dataProvider/resourceProvider/utils/resourceProviderTypes";

export type ListProps<T extends Model> = ReactAdminListProps &
  Omit<ResourceActionsProps<T>, "resource" | "mode"> &
  GetFiltersProps & {
    resource: RaResourceType;
    extraButtons?: ReactElement;
    meta?: DefaultMeta;
  };

export const List = <T extends Model>(
  props: ListProps<T>
): ReactElementOrNull => {
  const { t } = useI18n();
  // this resolve a posible react admin bug. When using redux selector hook on List page component while logout
  // there is a loop between login and list page. This cut the loop not rendering the list element when not loging
  // in but calling Navigate to redirect to login in that case.
  const isLogin = useSelector(isLogIn);
  if (!isLogin) return <Navigate to="/login" replace />;

  const {
    jsonToModel,
    modelToJson,
    exportedFileName,
    searchSource,
    extraFilters,
    extraButtons,
    meta,
    importElementPreprocess,
    ...listPros
  } = props;
  const actionsProps = {
    jsonToModel,
    modelToJson,
    exportedFileName,
    extraButtons,
    importElementPreprocess,
    mode: ActionMode.List,
    resource: props.resource
  };
  const filtersProps = { searchSource, extraFilters };
  return (
    <ReactAdminList
      pagination={<ListPagination />}
      perPage={defaultPaginationPerPage}
      actions={<ResourceActions<T> {...actionsProps} />}
      filters={getFilters(filtersProps, t)}
      queryOptions={{
        meta: { ...meta }
      }}
      {...listPros}
    />
  );
};
