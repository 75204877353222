import { ResizeObserver } from "@juggle/resize-observer";
import useReactUseMeasure, { Options, RectReadOnly } from "react-use-measure";
import { Optional } from "@laba/ts-common";

type HTMLOrSVGElement = HTMLElement | SVGElement;
export type UseGetElementSizeRef = (element: HTMLOrSVGElement | null) => void;

type UseGetElementSizeReturnData = [
  Optional<RectReadOnly>,
  UseGetElementSizeRef
];

export const useGetElementSize = (
  options?: Options
): UseGetElementSizeReturnData => {
  const [ref, bounds] = useReactUseMeasure({
    ...options,
    polyfill: ResizeObserver
  });

  return [bounds, ref];
};
