import { PropsWithChildren, ReactElement } from "@laba/react-common";
import React from "react";
import {
  ArrayInput as ReactAdminArrayInput,
  SimpleFormIterator
} from "react-admin";

export interface ArrayInputProps<T = unknown> {
  source: string;
  label?: string;
  disableAdd?: boolean;
  disableRemove?: boolean;
  disableReordering?: boolean;
  defaultValue?: T[];
}

// there is an error when removing component https://github.com/marmelab/react-admin/pull/7404
export const ArrayInput = <T,>({
  children,
  source,
  label,
  disableAdd,
  disableRemove,
  disableReordering,
  defaultValue,
  ...props
}: PropsWithChildren<ArrayInputProps<T>>): ReactElement => {
  return (
    <ReactAdminArrayInput
      {...props}
      defaultValue={defaultValue}
      variant="outlined"
      source={source}
      label={label}
    >
      <SimpleFormIterator
        disableAdd={disableAdd}
        disableRemove={disableRemove}
        disableReordering={disableReordering}
        TransitionProps={{ enter: false, exit: false }}
        fullWidth
      >
        {children}
      </SimpleFormIterator>
    </ReactAdminArrayInput>
  );
};
