import {
  ResourceType,
  getHealthcareServiceList,
  getHealthcareService,
  updateHealthcareService,
  createHealthcareService,
  HealthcareServiceListQueryParams,
  HealthcareServiceListQueryParamsKey,
  getModelReferenceId
} from "@laba/nexup-api";
import {
  getEnumOrderParam,
  getKeyTargetParam,
  resourceProviderGetter
} from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { workspaceOrganizationSelector } from "store/workspace/selectors";
import { store } from "store/store";

export const healthcareServiceProvider = resourceProviderGetter(
  ResourceType.HealthcareService,
  getEnumOrderParam({}),
  getKeyTargetParam<HealthcareServiceListQueryParams>(
    HealthcareServiceListQueryParamsKey
  ),
  getHealthcareServiceList,
  getHealthcareService,
  updateHealthcareService,
  createHealthcareService,
  undefined,
  () => ({
    organization: getModelReferenceId(
      workspaceOrganizationSelector(store.getState())
    )
  })
);
