import {
  createStyle,
  FlexAlignItems,
  FlexJustifyContent,
  flexRowMixin,
  pxToRem,
  StyleVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor } from "model/themeUtils";

export const usePatientIdentificationDataContainerStyles = createStyle(
  (theme: Theme) => ({
    desktopRoot: {
      ...flexRowMixin({
        justifyContent: FlexJustifyContent.FlexStart,
        alignItems: FlexAlignItems.Center,
        gap: 16
      })
    },
    mobileRoot: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.FlexStart,
        gap: 8
      })
    },
    profilePicture: {
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Main
      )
    },
    grayDarkColor: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    titleContentData: {
      maxWidth: pxToRem(176),
      textAlign: "start"
    },
    mobileTitleContentData: {
      textAlign: "start"
    }
  }),
  "PatientIdentificationDataContainer"
);
