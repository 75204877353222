import { ContactPointType } from "model/primitives/contactPoint";
import { ResourceType } from "model/primitives/resourceModel";
import { KnownCodeSystemSystem } from "model/resource/entities/codeSystem";
import {
  LocationStatus,
  LocationType
} from "model/resource/entities/location/location";
import {
  OrganizationHospitalizationType,
  OrganizationType
} from "model/resource/entities/organization/organization";
import { AllergyStatus } from "model/resource/medical/allergyIntolerance/allergyIntolerance";
import {
  ConditionCategory,
  ConditionClinical
} from "model/resource/medical/condition/condition";
import { PatientClassificationSC } from "model/resource/medical/condition/patientClassification";
import {
  EncounterClass,
  EncounterStatus,
  KnownEncounterService,
  RoleCodes
} from "model/resource/entities/encounter/encounter";
import {
  KnownMeasureObservationCode,
  KnownPatientTagCodes,
  ObservationCategory,
  ObservationStatus
} from "model/resource/medical/observation/observation";
import { KnownPatientMeasureUnits } from "model/resource/medical/observation/patientMeasure";
import { KnownDiagnosisRole } from "model/resource/medical/condition/diagnosis";
import { KnownIdentifierSystem } from "model/primitives/identifier";
import {
  PatientContainerWithExtraData,
  PatientPrescriptionStatus
} from "../patient/patientContainerWithExtraData";
import {
  Gender,
  KnownCitizenship,
  MaritalStatus,
  ValidationStatus
} from "../person";

export const patientContainerWithExtraDataMock: PatientContainerWithExtraData =
  {
    prescriptionStatus: PatientPrescriptionStatus.Active,
    lastEvolutionEditDate: "2022-02-07T10:14:36.000-03",
    lastPrescriptionEditDate: "2022-02-07T10:14:36.000-03",
    tagList: [
      {
        resourceType: ResourceType.PatientTag,
        status: ObservationStatus.Final,
        type: KnownMeasureObservationCode.Unknown,
        category: ObservationCategory.Tag,
        value: KnownPatientTagCodes.Arm,
        startDateTime: "2022-02-03T10:14:36.000-03",
        endDateTime: "2022-02-07T10:14:36.000-03"
      },
      {
        resourceType: ResourceType.PatientTag,
        status: ObservationStatus.Final,
        type: KnownMeasureObservationCode.Unknown,
        category: ObservationCategory.Tag,
        value: KnownPatientTagCodes.Arm,
        startDateTime: "2022-02-03T10:14:36.000-03",
        endDateTime: "2022-02-07T10:14:36.000-03"
      },
      {
        resourceType: ResourceType.PatientTag,
        status: ObservationStatus.Final,
        type: KnownMeasureObservationCode.Unknown,
        category: ObservationCategory.Tag,
        value: KnownPatientTagCodes.Arm,
        startDateTime: "2022-02-03T10:14:36.000-03",
        endDateTime: "2022-02-07T10:14:36.000-03"
      },
      {
        resourceType: ResourceType.PatientTag,
        status: ObservationStatus.Final,
        type: KnownMeasureObservationCode.Unknown,
        category: ObservationCategory.Tag,
        value: KnownPatientTagCodes.Arm,
        startDateTime: "2022-02-03T10:14:36.000-03",
        endDateTime: "2022-02-07T10:14:36.000-03"
      },
      {
        resourceType: ResourceType.PatientTag,
        status: ObservationStatus.Final,
        type: KnownMeasureObservationCode.Unknown,
        category: ObservationCategory.Tag,
        value: KnownPatientTagCodes.Arm,
        startDateTime: "2022-02-03T10:14:36.000-03",
        endDateTime: "2022-02-07T10:14:36.000-03"
      },
      {
        resourceType: ResourceType.PatientTag,
        status: ObservationStatus.Final,
        type: KnownMeasureObservationCode.Unknown,
        category: ObservationCategory.Tag,
        value: KnownPatientTagCodes.Arm,
        startDateTime: "2022-02-03T10:14:36.000-03",
        endDateTime: "2022-02-07T10:14:36.000-03"
      }
    ],
    allergyIntoleranceList: [
      {
        status: AllergyStatus.Active,
        resourceType: ResourceType.AllergyIntolerance,
        elementName: "Alergia al Polen",
        element: "1342K8P97"
      }
    ],
    measurementList: [
      {
        resourceType: ResourceType.PatientMeasure,
        type: KnownMeasureObservationCode.Weight,
        category: ObservationCategory.Measure,
        value: { quantity: 70, unit: KnownPatientMeasureUnits.kg },
        status: ObservationStatus.Final,
        effectiveDateTime: "2022-02-07T10:14:36.000-03"
      },
      {
        resourceType: ResourceType.PatientMeasure,
        type: KnownMeasureObservationCode.Height,
        category: ObservationCategory.Measure,
        value: { quantity: 1.7, unit: KnownPatientMeasureUnits.m },
        status: ObservationStatus.Final,
        effectiveDateTime: "2022-02-07T10:14:36.000-03"
      },
      {
        resourceType: ResourceType.PatientMeasure,
        type: KnownMeasureObservationCode.Bmi,
        category: ObservationCategory.Measure,
        value: { quantity: 15, unit: KnownPatientMeasureUnits.kg_m2 },
        status: ObservationStatus.Final,
        effectiveDateTime: "2022-02-07T10:14:36.000-03"
      },
      {
        resourceType: ResourceType.PatientMeasure,
        type: KnownMeasureObservationCode.CreatinineClearance,
        category: ObservationCategory.Measure,
        value: { quantity: 0.7 },
        status: ObservationStatus.Final,
        effectiveDateTime: "2022-02-07T10:14:36.000-03"
      }
    ],
    hospitalization: {
      healthcareService: "",
      resourceType: ResourceType.Hospitalization,
      id: "1",
      patient: "29",
      currentBed: {
        id: "unNexupId",
        bed: {
          children: undefined,
          resourceType: ResourceType.Bed,
          id: "1",
          name: "101A",
          description: "Catamarca 101A",
          status: LocationStatus.Active,
          type: LocationType.Bed,
          roomName: "roomName",
          roomId: "roomId",
          buildingName: "Catamarca"
        }
      },
      practitionerTeam: [
        {
          role: {
            system: KnownCodeSystemSystem.EncounterPractitionerType,
            code: RoleCodes.GeneralPractitioner
          },
          practitioner: {
            resourceType: ResourceType.Practitioner,
            id: "1",
            validationStatus: ValidationStatus.Permanent,
            organization: ["1"],
            personalData: {
              firstName: "Primer Nombre",
              otherName: "Segundo Nombre",
              lastName: "Apellido",
              motherLastName: "Apellido materno",
              birthDate: "1993-01-02",
              gender: Gender.Male,
              identifierList: [
                {
                  resourceType: ResourceType.Identifier,
                  value: "88",
                  system: KnownIdentifierSystem.Dni
                }
              ],
              maritalStatus: MaritalStatus.Married,
              citizenship: KnownCitizenship.Argentinian
            }
          }
        }
      ],
      startDate: "2020-07-14",
      finishDate: "2021-01-02",
      service: KnownEncounterService.Acute,
      status: EncounterStatus.InProgress,
      class: EncounterClass.Inpatient,
      diagnosis: [
        {
          condition: {
            resourceType: ResourceType.Condition,
            clinicalStatus: ConditionClinical.Active,
            code: {
              code: PatientClassificationSC.A,
              name: PatientClassificationSC.A,
              system: KnownCodeSystemSystem.PatientClassificationSC
            },
            category: ConditionCategory.PatientClassification
          },
          use: KnownDiagnosisRole.MedicalAdmissionDiagnosis
        }
      ]
    },
    patient: {
      id: "65",
      personalData: {
        firstName: "Lionel",
        otherName: "Andres",
        lastName: "Messi",
        motherLastName: "Apellido materno",
        birthDate: "1993-01-02",
        gender: Gender.Male,
        identifierList: [
          {
            resourceType: ResourceType.Identifier,
            value: "88",
            system: KnownIdentifierSystem.Dni
          }
        ],
        maritalStatus: MaritalStatus.Married,
        citizenship: KnownCitizenship.Argentinian
      },
      contactData: {
        phoneList: [
          {
            resourceType: ResourceType.Phone,
            id: "10",
            type: ContactPointType.Home,
            value: "4680-2345"
          },
          {
            resourceType: ResourceType.Phone,
            id: "11",
            type: ContactPointType.Mobile,
            value: "11-4455667788"
          }
        ],
        addressList: [
          {
            resourceType: ResourceType.Address,
            id: "10",
            street: "Street",
            streetNumber: "Street Number",
            directions: "Directions",
            city: "City",
            state: "State",
            country: "Argentina",
            postalCode: "1085"
          }
        ]
      },
      resourceType: ResourceType.Patient,
      validationStatus: ValidationStatus.Permanent,
      organization: {
        resourceType: ResourceType.Organization,
        type: OrganizationType.Payer,
        name: "Organization",
        id: "2"
      },
      patientData: {
        deathDate: "2015-02-14",
        personInCharge: {
          firstName: "Contact Primer Nombre",
          lastName: "Contact Apellido",
          relationship: "Contact relationship",
          address: "Contact line 1",
          city: "Contact city",
          postalCode: "1416",
          birthDate: "16-01-1996",
          phoneHome: "4680-2345",
          emailHome: "home@fakeemail.fake",
          workPhone: "1111111"
        },
        healthcarePayer: {
          provider: {
            name: "OSDE",
            resourceType: ResourceType.Organization,
            type: OrganizationType.Provider,
            hospitalizationType: OrganizationHospitalizationType.Mixed
          },
          plan: "OSDE 310",
          credentialNumber: "15456895"
        }
      }
    },
    managedGroup: [
      {
        id: "15",
        personalData: {
          firstName: "Keith",
          lastName: "Richards",
          birthDate: "1953-01-02",
          gender: Gender.Male,
          identifierList: [
            {
              resourceType: ResourceType.Identifier,
              value: "88",
              system: KnownIdentifierSystem.Dni
            }
          ],
          maritalStatus: MaritalStatus.Married,
          citizenship: KnownCitizenship.Brazilians
        },
        contactData: {
          phoneList: [
            {
              resourceType: ResourceType.Phone,
              id: "10",
              type: ContactPointType.Home,
              value: "4680-2345"
            },
            {
              resourceType: ResourceType.Phone,
              id: "11",
              type: ContactPointType.Mobile,
              value: "11-4455667788"
            }
          ],
          addressList: [
            {
              resourceType: ResourceType.Address,
              id: "10",
              street: "Street",
              streetNumber: "Street Number",
              directions: "Sticky Fingers",
              city: "City",
              state: "State",
              country: "Inglaterra",
              postalCode: "1085"
            }
          ]
        },
        resourceType: ResourceType.Patient,
        validationStatus: ValidationStatus.Permanent,
        organization: {
          resourceType: ResourceType.Organization,
          type: OrganizationType.Payer,
          name: "Organization",
          id: "2"
        },
        patientData: {
          deathDate: "2015-02-14",
          personInCharge: {
            firstName: "Mick",
            lastName: "Jagger",
            relationship: "Bro",
            address: "Exiled from main street",
            city: "Contact city",
            postalCode: "1416",
            birthDate: "16-01-1996",
            phoneHome: "4680-2345",
            emailHome: "brownsuggar@fakeemail.fake",
            workPhone: "1111111"
          },
          healthcarePayer: {
            provider: {
              name: "OSDE",
              resourceType: ResourceType.Organization,
              type: OrganizationType.Provider,
              hospitalizationType: OrganizationHospitalizationType.Mixed
            },
            plan: "OSDE 310",
            credentialNumber: "15456895"
          }
        }
      },
      {
        id: "16",
        personalData: {
          firstName: "Carlos",
          lastName: "Jimenez",
          motherLastName: "Ruffino",
          birthDate: "1957-01-02",
          gender: Gender.Male,
          identifierList: [
            {
              resourceType: ResourceType.Identifier,
              value: "88",
              system: KnownIdentifierSystem.Dni
            }
          ],
          maritalStatus: MaritalStatus.Married,
          citizenship: KnownCitizenship.Argentinian
        },
        contactData: {
          phoneList: [
            {
              resourceType: ResourceType.Phone,
              id: "10",
              type: ContactPointType.Home,
              value: "4680-2345"
            },
            {
              resourceType: ResourceType.Phone,
              id: "11",
              type: ContactPointType.Mobile,
              value: "11-4455667788"
            }
          ],
          addressList: [
            {
              resourceType: ResourceType.Address,
              id: "10",
              street: "Street",
              streetNumber: "Street Number",
              directions: "Sticky Fingers",
              city: "City",
              state: "State",
              country: "Inglaterra",
              postalCode: "1085"
            }
          ]
        },
        resourceType: ResourceType.Patient,
        validationStatus: ValidationStatus.Permanent,
        organization: {
          resourceType: ResourceType.Organization,
          type: OrganizationType.Payer,
          name: "Organization",
          id: "2"
        },
        patientData: {
          deathDate: "2015-02-14",
          personInCharge: {
            firstName: "Ulises",
            lastName: "Bueno",
            relationship: "Compas",
            address: "El federal 555",
            city: "Contact city",
            postalCode: "1416",
            birthDate: "16-01-1996",
            phoneHome: "4680-2345",
            emailHome: "ulisesbueno@fakeemail.fake",
            workPhone: "1111111"
          },
          healthcarePayer: {
            provider: {
              name: "OSDE",
              resourceType: ResourceType.Organization,
              type: OrganizationType.Provider,
              hospitalizationType: OrganizationHospitalizationType.Mixed
            },
            plan: "OSDE 310",
            credentialNumber: "15456895"
          }
        }
      },
      {
        id: "17",
        personalData: {
          firstName: "Diego",
          lastName: "Maradona",
          birthDate: "1957-01-02",
          gender: Gender.Male,
          identifierList: [
            {
              resourceType: ResourceType.Identifier,
              value: "88",
              system: KnownIdentifierSystem.Dni
            }
          ],
          maritalStatus: MaritalStatus.Married,
          citizenship: KnownCitizenship.Argentinian
        },
        contactData: {
          phoneList: [
            {
              resourceType: ResourceType.Phone,
              id: "10",
              type: ContactPointType.Home,
              value: "4680-2345"
            },
            {
              resourceType: ResourceType.Phone,
              id: "11",
              type: ContactPointType.Mobile,
              value: "11-4455667788"
            }
          ],
          addressList: [
            {
              resourceType: ResourceType.Address,
              id: "10",
              street: "Street",
              streetNumber: "Street Number",
              directions: "Segurola y la havana",
              city: "City",
              state: "State",
              country: "Argentina",
              postalCode: "10"
            }
          ]
        },
        resourceType: ResourceType.Patient,
        validationStatus: ValidationStatus.Permanent,
        organization: {
          resourceType: ResourceType.Organization,
          type: OrganizationType.Payer,
          name: "Organization",
          id: "2"
        },
        patientData: {
          deathDate: "2015-02-14",
          personInCharge: {
            firstName: "Guillermo",
            lastName: "Coppola",
            relationship: "Compas",
            address: "address",
            city: "Contact city",
            postalCode: "1416",
            birthDate: "16-01-1996",
            phoneHome: "4680-2345",
            emailHome: "guillotecoppola@fakeemail.fake",
            workPhone: "1111111"
          },
          healthcarePayer: {
            provider: {
              name: "OSDE",
              resourceType: ResourceType.Organization,
              type: OrganizationType.Provider,
              hospitalizationType: OrganizationHospitalizationType.Mixed
            },
            plan: "OSDE 310",
            credentialNumber: "15456895"
          }
        }
      }
    ]
  };
