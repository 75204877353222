import { FC, stopPropagAndPrevDef, StyleVariant } from "@laba/react-common";
import React, { useState } from "react";
import { useMuiTheme } from "model/useMuiTheme";
import { Dialog, ThemeProvider } from "@material-ui/core";
import { FileBlobItem } from "components/file";
import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import Swiper from "swiper";
import { Keyboard, Navigation } from "swiper/modules";
import { FilePreviewViewer } from "components/file/FileListPreviewPopup/FilePreviewViewer/FilePreviewViewer";
import { FilePreviewDialogButtonContainer } from "components/file/FileListPreviewPopup/FilePreviewDialogButtonContainer/FilePreviewDialogButtonContainer";
import { FilePreviewArrowIcon } from "components/file/FileListPreviewPopup/FilePreviewArrowIcon/FilePreviewArrowIcon";
import { ArrowLeftIcon } from "@material-ui/pickers/_shared/icons/ArrowLeftIcon";
import { ArrowRightIcon } from "@material-ui/pickers/_shared/icons/ArrowRightIcon";
import { Noop, Optional } from "@laba/ts-common";
import { useFileListPreviewPopupClassNames } from "components/file/FileListPreviewPopup/useFileListPreviewPopupStyles";
import { NavigationBlocker } from "components/utils/NavigatorBlocker";

// to use this input you need to this css import in your final project
// import "swiper/css";
// import "swiper/css/navigation";

export interface FileListPreviewPopupProps {
  isOpen?: boolean;
  blobItemList?: FileBlobItem[];
  onDownloadButtonClicked?: (item: FileBlobItem) => void;
  onCloseButtonClicked?: Noop;
  isMobile?: boolean;
  invalidFormatTitle?: string;
  invalidFormatDescription?: string;
  initialItemIndex?: number;
  disableBackdropClick?: boolean;
}

const getItemIndexText = (index: number, total: number): Optional<string> => {
  if (total === 0) return undefined;
  return `${index + 1}/${total}`;
};

export const FileListPreviewPopup: FC<FileListPreviewPopupProps> = ({
  blobItemList,
  onCloseButtonClicked,
  onDownloadButtonClicked,
  isOpen = false,
  isMobile,
  invalidFormatTitle,
  invalidFormatDescription,
  initialItemIndex,
  disableBackdropClick = false
}) => {
  const theme = useMuiTheme(StyleVariant.Primary);

  const [currentItemIndex, setCurrentItemIndex] = useState(
    initialItemIndex ?? 0
  );

  const [swiper, setSwiper] = useState<Optional<Swiper>>();

  const onRightArrowClicked = () => {
    if (swiper) {
      swiper.slideNext();
      setCurrentItemIndex(currentItemIndex + 1);
    }
  };
  const onLeftArrowClicked = () => {
    if (swiper) {
      swiper.slidePrev();
      setCurrentItemIndex(currentItemIndex - 1);
    }
  };

  const onSlideChange = (sw: Swiper) => {
    setCurrentItemIndex(sw.realIndex);
  };

  const onDownloadItemClicked = () => {
    const item = blobItemList?.[currentItemIndex];
    item && onDownloadButtonClicked?.(item);
  };

  const fileListSize = blobItemList?.length ?? 0;
  const showLeftArrow = currentItemIndex !== 0;
  const showRightArrow = currentItemIndex !== fileListSize - 1;

  const classes = useFileListPreviewPopupClassNames({
    isMobile,
    showLeftArrow,
    showRightArrow
  });

  const itemNumberText = getItemIndexText(currentItemIndex, fileListSize);

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        maxWidth={false}
        open={isOpen}
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            if (!disableBackdropClick) onCloseButtonClicked?.();
          } else {
            onCloseButtonClicked?.();
          }
        }}
        onClick={stopPropagAndPrevDef}
        fullWidth
        classes={{
          paper: classes.paper,
          paperScrollPaper: classes.paperScrollPaper
        }}
        className={classes.dialog}
      >
        <div className={classes.container}>
          <FilePreviewDialogButtonContainer
            className={classes.buttonContainer}
            itemNumberText={itemNumberText}
            onDownloadButtonClicked={onDownloadItemClicked}
            onCloseButtonClicked={onCloseButtonClicked}
          />
          <div className={classes.contentContainer}>
            {showLeftArrow ? (
              <FilePreviewArrowIcon
                className={classes.leftArrow}
                onClick={onLeftArrowClicked}
                ArrowIcon={ArrowLeftIcon}
                isMobile={isMobile}
              />
            ) : (
              <div className={classes.leftArrow} />
            )}
            <SwiperComponent
              className={classes.swiper}
              modules={[Navigation, Keyboard]}
              loop={false}
              autoplay={false}
              onSwiper={setSwiper}
              onSlideChange={onSlideChange}
              initialSlide={initialItemIndex}
              grabCursor={!isMobile}
              keyboard={{ enabled: !isMobile, onlyInViewport: true }}
            >
              {blobItemList?.map((item, index) => {
                return (
                  <SwiperSlide
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >
                    <FilePreviewViewer
                      className={classes.slide}
                      file={item}
                      isMobile={isMobile}
                      invalidFormatDescription={invalidFormatDescription}
                      invalidFormatTitle={invalidFormatTitle}
                    />
                  </SwiperSlide>
                );
              })}
            </SwiperComponent>
            {showRightArrow ? (
              <FilePreviewArrowIcon
                className={classes.rightArrow}
                ArrowIcon={ArrowRightIcon}
                onClick={onRightArrowClicked}
                isMobile={isMobile}
              />
            ) : (
              <div className={classes.rightArrow} />
            )}
          </div>
        </div>
      </Dialog>
      {isOpen && <NavigationBlocker onClose={onCloseButtonClicked} />}
    </ThemeProvider>
  );
};
