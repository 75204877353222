import { ApiRequestFileResponse, ApiRequestResponse } from "request/types";
import {
  getApiUrl,
  request,
  requestAnon,
  requestMultipart
} from "request/nexupRequest";
import {
  ContentTypeHeader,
  FileFormDataKey,
  HttpMethod,
  RequestResponse,
  UploadFileMetadata,
  UploadFileResponse
} from "@laba/ts-common";

const fileBasePath = "/files";

export const uploadFileRequest = async (
  file: File,
  metadata?: UploadFileMetadata
): Promise<ApiRequestResponse<UploadFileResponse>> => {
  const data = new FormData();
  data.append(FileFormDataKey.File, file, file.name);
  if (metadata !== undefined) {
    const metadataBlob = new Blob([JSON.stringify(metadata)], {
      type: ContentTypeHeader.Json
    });
    data.append(FileFormDataKey.FileData, metadataBlob);
  }
  return requestMultipart({
    method: HttpMethod.POST,
    url: getApiUrl(fileBasePath),
    data
  });
};

export const downloadFileRequest = async (
  url: string
): Promise<ApiRequestFileResponse> =>
  request({
    method: HttpMethod.GET,
    url,
    responseType: "blob"
  });

export const downloadFileRequestById = async (
  fileId: string
): Promise<ApiRequestFileResponse> =>
  downloadFileRequest(getApiUrl(`${fileBasePath}/${fileId}`));

export const downloadExternalFileRequest = async (
  url: string
): Promise<ApiRequestFileResponse> =>
  requestAnon({
    baseURL: url,
    method: HttpMethod.GET,
    responseType: "blob"
  });

export const downloadExternalFileRequestStreamBuffer = async (
  url: string
): Promise<RequestResponse<Buffer>> =>
  requestAnon({
    baseURL: url,
    method: HttpMethod.GET,
    responseType: "arraybuffer"
  });
