import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  Questionnaire,
  QuestionnaireCardKey,
  QuestionnaireEnableWhenBehavior,
  QuestionnaireFieldKey,
  QuestionnaireFieldType,
  QuestionnaireItemEnableWhen,
  QuestionnaireKey,
  ResourceType
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { FormDataConsumer } from "components/generic/FormDataConsumer/FormDataConsumer";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { QuestionnaireItemEnableWhenComponent } from "./common/QuestionnaireItemEnableWhenComponent/QuestionnaireItemEnableWhenComponent";

export const tk = tkCP.adminPage[ResourceType.Questionnaire];
export const QuestionnaireFieldsContent: FC = () => {
  const { t } = useI18n();
  return (
    <ArrayInput source={QuestionnaireKey.cards} label={t(tk.fields.cardList)}>
      <TextInput
        source={QuestionnaireCardKey.linkId}
        label={t(tk.fields.cardLinkId)}
        required
      />
      <TextInput
        source={QuestionnaireCardKey.title}
        label={t(tk.fields.cardTitle)}
      />
      <EnumSelectorInput
        source={QuestionnaireCardKey.enableBehavior}
        label={t(tk.fields.enableBehavior)}
        enumValue={QuestionnaireEnableWhenBehavior}
      />
      <ArrayInput
        source={QuestionnaireCardKey.enableWhen}
        label={t(tk.fields.enableWhen)}
      >
        <FormDataConsumer<Questionnaire, QuestionnaireItemEnableWhen>>
          {({
            getSource: getItemEnableWhenSource,
            formData,
            scopedFormData
          }) => {
            return (
              <QuestionnaireItemEnableWhenComponent
                getSource={getItemEnableWhenSource}
                questionnaire={formData}
                qItemEnableWhen={scopedFormData}
              />
            );
          }}
        </FormDataConsumer>
      </ArrayInput>
      <ArrayInput
        source={QuestionnaireCardKey.fields}
        label={t(tk.fields.cardFieldList)}
        // TODO HIS-17295 revisar logica de defaultValue en arrayInput
        defaultValue={[]}
      >
        <TextInput
          source={QuestionnaireFieldKey.linkId}
          label={t(tk.fields.cardLinkId)}
          required
        />
        <TextInput
          source={QuestionnaireFieldKey.title}
          label={t(tk.fields.fieldTitle)}
        />
        <EnumSelectorInput
          source={QuestionnaireFieldKey.fieldType}
          label={t(tk.fields.fieldType)}
          enumValue={QuestionnaireFieldType}
          required
        />
      </ArrayInput>
    </ArrayInput>
  );
};
