import React from "react";
import { FC } from "@laba/react-common";
import { Create } from "components/generic/Create/Create";
import {
  QuestionnaireForm,
  questionnaireJsonToModel,
  questionnaireTransform
} from "components/pages/NexupAdmin/resources/questionnaire/QuestionnaireForm";
import { ResourceType } from "@laba/nexup-api";

export const QuestionnaireCreate: FC = () => {
  return (
    <Create
      resourceTransform={questionnaireTransform}
      resource={ResourceType.Questionnaire}
      jsonToModel={questionnaireJsonToModel}
    >
      <QuestionnaireForm />
    </Create>
  );
};
