import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  Layout,
  Menu,
  useResourceDefinitions,
  LayoutProps,
  usePermissions
} from "react-admin";
import {
  AdminPanelPermissions,
  AdminPanelSpecialPermissions,
  hasPermission
} from "models/permissions/adminPanelPermissions";
import { customConfigList } from "components/pages/NexupAdmin/resourceConfig";

const CustomMenu: FC = () => {
  const { t } = useI18n();
  const resources = useResourceDefinitions();

  const { permissions } = usePermissions<AdminPanelPermissions[]>();
  const showAllResource =
    hasPermission(AdminPanelSpecialPermissions.Admin, permissions) ||
    hasPermission(AdminPanelSpecialPermissions.AllResources, permissions);
  const customList = customConfigList
    .filter(
      value =>
        showAllResource ||
        value.permissionList.some(resourcePermission =>
          hasPermission(resourcePermission, permissions)
        )
    )
    .map(config => {
      const path = `/${config.path}`;
      return (
        <Menu.Item
          key={path}
          to={path}
          primaryText={t(config.titleKey)}
          leftIcon={<config.icon />}
        />
      );
    });
  return (
    <Menu>
      <Menu.DashboardItem />
      {customList}
      {Object.keys(resources).map(name => (
        <Menu.ResourceItem key={name} name={name} />
      ))}
    </Menu>
  );
};
export const CustomLayout: FC<LayoutProps> = props => (
  <Layout {...props} menu={CustomMenu} />
);
