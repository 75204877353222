import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  HospitalizationKey,
  ModelReference,
  Organization,
  ResourceType,
  PatientListQueryParamsKey,
  getModelReferenceId,
  EncounterParticipantKey,
  Practitioner,
  RoleCodes
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { useWatchResourceForm } from "components/hook/useResourceContext";

import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { practitionerOptionText } from "components/generic/ReferenceResourceInput/optionText";

export const tk = tkCP.adminPage[ResourceType.Hospitalization];
export const HospitalizationPractitionerContent: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const hospitalizationOrganization = useWatchResourceForm<
    ModelReference<Organization>
  >(HospitalizationKey.organization);
  const organizationId =
    getModelReferenceId(hospitalizationOrganization) || workspaceOrganizationId;
  return (
    <ArrayInput
      label={t(tk.fields.practitionerTeam)}
      source={HospitalizationKey.practitionerTeam}
    >
      <ReferenceResourceInput<Practitioner>
        source={EncounterParticipantKey.practitioner}
        label={t(tk.fields.practitionerTeamPractitioner)}
        resourceType={ResourceType.Practitioner}
        optionTextKey={practitionerOptionText}
        extraFilters={{
          [PatientListQueryParamsKey.organization]: organizationId
        }}
      />
      <EnumSelectorInput
        source={EncounterParticipantKey.role.code}
        label={t(tk.fields.practitionerTeamRole)}
        enumValue={RoleCodes}
        withCreate
      />
    </ArrayInput>
  );
};
