import { ResourceType } from "model/primitives/resourceModel";
import {
  Invoice,
  InvoiceStatus,
  InvoiceType
} from "model/resource/finance/invoice";
import { OrganizationType } from "model/resource/entities/organization/organization";
import { HospitalizationType } from "api/medical/hospitalization";
import { LocationStatus } from "model/resource/entities/location/location";
import { getPractitionerMock } from "model/resource/person/__mocks__/practitioner";

export const invoiceMock1: Invoice = {
  id: "123123123123123",
  resourceType: ResourceType.Invoice,
  statusReason: "Prefacturado",
  status: InvoiceStatus.Balanced,
  period: {
    start: "2023-12-22T23:59:59.999-03",
    end: "2023-08-22T23:59:59.999-03"
  },
  debt: { value: 300, currency: "ARS" },
  type: InvoiceType.Patient,
  total: { value: 300, currency: "ARS" },
  account: {
    resourceType: ResourceType.Account,
    payer: {
      type: OrganizationType.Payer,
      resourceType: ResourceType.Organization,
      name: "payer1"
    }
  },
  invoiceDate: "2023-12-22T23:59:59.999-03",
  hospitalizationType: HospitalizationType.Hospitalization,
  taxType: "IVA exento",
  location: [
    {
      location: {
        resourceType: ResourceType.Location,
        name: "location1",
        status: LocationStatus.Active
      }
    }
  ],
  plan: ["plan1", "plan2"],
  originalPractitioner: getPractitionerMock(),
  lastEditor: getPractitionerMock(),
  lastEditDate: "2023-07-01T15:00:00.000-03",
  creationDate: "2023-07-01T15:00:00.000-03"
};

export const invoiceMock2: Invoice = {
  id: "123123123123122",
  resourceType: ResourceType.Invoice,
  status: InvoiceStatus.Balanced,
  statusReason: "Facturado",
  period: {
    start: "2023-12-22T23:59:59.999-03",
    end: "2023-08-22T23:59:59.999-03"
  },
  total: { value: 400, currency: "ARS" },
  debt: { value: 300, currency: "ARS" },
  type: InvoiceType.Payer,
  account: {
    resourceType: ResourceType.Account,
    payer: {
      type: OrganizationType.Payer,
      resourceType: ResourceType.Organization,
      name: "payer2"
    }
  },
  invoiceDate: "2023-12-22T23:59:59.999-03",
  hospitalizationType: HospitalizationType.Hospitalization,
  taxType: "IVA exento",
  location: [
    {
      location: {
        resourceType: ResourceType.Location,
        name: "location2",
        status: LocationStatus.Active
      }
    }
  ],
  plan: ["plan1", "plan2"]
};

export const invoiceMock3: Invoice = {
  id: "123123123123127",
  resourceType: ResourceType.Invoice,
  status: InvoiceStatus.Balanced,
  statusReason: "Pagado Parcialmente",
  period: {
    start: "2023-12-22T23:59:59.999-03",
    end: "2023-08-22T23:59:59.999-03"
  },
  total: { value: 500, currency: "ARS" },
  debt: { value: 300, currency: "ARS" },
  type: InvoiceType.Patient,
  account: {
    resourceType: ResourceType.Account,
    payer: {
      type: OrganizationType.Payer,
      resourceType: ResourceType.Organization,
      name: "payer2"
    }
  },
  invoiceDate: "2023-12-22T23:59:59.999-03",
  hospitalizationType: HospitalizationType.Hospitalization,
  taxType: "IVA gravado",
  location: [
    {
      location: {
        resourceType: ResourceType.Location,
        name: "location2",
        status: LocationStatus.Active
      }
    }
  ],
  preInvoiceFile: {
    resourceType: ResourceType.Attachment,
    url: "https://development.nexup.com.ar/api/files/89350b6a4cf84832bf183d86a26715c7",
    name: "mock-preinvoicefile"
  }
};

export const mockInvoiceList = [invoiceMock1, invoiceMock2, invoiceMock3];
