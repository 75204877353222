import { FCC, getClassName } from "@laba/react-common";
import React from "react";
import { ButtonBase } from "components/buttons/ButtonBase/ButtonBase";
import { ExpandLess, ExpandMore } from "components/icons";
import { useDropdownHeaderStyles } from "components/headers/DropdownHeader/useDropdownHeaderStyles";
import { Noop } from "@laba/ts-common";

export interface DropdownHeaderProps {
  className?: string;
  isOpen?: boolean;
  onClick?: Noop;
}

export const DropdownHeader: FCC<DropdownHeaderProps> = ({
  className,
  onClick,
  isOpen = true,
  children
}) => {
  const classes = useDropdownHeaderStyles();
  return (
    <ButtonBase
      className={getClassName(classes.container, className)}
      onClick={onClick}
      disableRipple
    >
      {isOpen ? (
        <ExpandLess className={classes.startIcon} />
      ) : (
        <ExpandMore className={classes.startIcon} />
      )}
      {children}
    </ButtonBase>
  );
};
