import React, { useEffect, useRef } from "react";
import { FC, getClassName } from "@laba/react-common";

export interface IframeProps {
  className?: string;
  id?: string;
  title?: string;
  source?: string;
  frameBorder?: boolean;
  scrolling?: boolean;
  allowFullScreen?: boolean;
}

export enum ScrollingValue {
  YES = "yes",
  NO = "no",
  AUTO = "auto"
}

const getScrollingIFrameValue = (scrolling?: boolean): ScrollingValue => {
  if (scrolling === undefined) {
    return ScrollingValue.AUTO;
  }
  if (scrolling) {
    return ScrollingValue.YES;
  }
  return ScrollingValue.NO;
};

export const IFrame: FC<IframeProps> = ({
  className,
  id,
  title,
  source,
  allowFullScreen,
  scrolling,
  frameBorder = false
}) => {
  const containerRef = useRef(null);
  const frameBorderValue = frameBorder ? "1" : "0";

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (containerRef.current && source) {
      const iframe = document.createElement("iframe");
      iframe.className = getClassName(className);
      iframe.style.height = "100%";
      iframe.style.width = "100%";
      iframe.id = id ?? "";
      iframe.title = title ?? "";
      iframe.src = source;
      iframe.frameBorder = frameBorderValue;
      iframe.scrolling = getScrollingIFrameValue(scrolling);
      iframe.allowFullscreen = allowFullScreen ?? false;
      iframe.setAttribute("credentialless", "true");
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      containerRef.current.innerHTML = "";
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      containerRef.current.appendChild(iframe);
    }
  }, [
    className,
    id,
    title,
    source,
    scrolling,
    allowFullScreen,
    frameBorderValue
  ]);

  return source ? <div className={className} ref={containerRef} /> : null;
};
