import {
  Observation,
  ObservationCategory,
  OdontogramTypeCodes
} from "model/resource/medical/observation/observation";
import { ResourceType } from "model/primitives/resourceModel";
import { ApiDate, getKeyObj, OpenCode } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { OdontogramToothCodeType, OdontogramToothSurface } from "./codes";

export enum OdontogramProvisionType {
  Preexisting = "Preexisting",
  Current = "Current"
}

export enum KnownOdontogramToothDiagnostic {
  RootCanal = "RootCanal",
  CurableCavities = "CurableCavities",
  IncurableCavities = "IncurableCavities",
  Extraction = "Extraction",
  MissingTooth = "MissingTooth",
  AmalgamFilling = "AmalgamFilling",
  SilicateFilling = "SilicateFilling",
  AcrylicCompositeSealing = "AcrylicCompositeSealing",
  Paradentosis = "Paradentosis",
  DentalCrown = "DentalCrown",
  Pivot = "Pivot",
  Incrustation = "Incrustation",
  Bridge = "Bridge",
  RemovableProsthesis = "RemovableProsthesis",
  Orthodontics = "Orthodontics",
  Implant = "Implant"
}

export type OdontogramDiagnostic = OpenCode<KnownOdontogramToothDiagnostic>;

export interface OdontogramComponent {
  provisionType?: OdontogramProvisionType;
  diagnostic?: OdontogramDiagnostic[];
  registrationDate?: ApiDate;
  tooth?: OdontogramToothCodeType[];
  surface?: OdontogramToothSurface[];
  observation?: string;
}

export interface Odontogram
  extends Observation<
    ResourceType.Odontogram,
    ObservationCategory.Odontogram,
    OdontogramTypeCodes
  > {
  components?: OdontogramComponent[];
}

export const OdontogramKey = getKeyObj<Odontogram>(
  createHydratedMock<Odontogram>()
);

export const OdontogramComponentKey = getKeyObj<OdontogramComponent>(
  createHydratedMock<OdontogramComponent>()
);
