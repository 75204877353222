import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";
import { FC } from "@laba/react-common";

export const NexupLogo: FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 97.038 30.871">
      <g transform="translate(0 0)">
        <g transform="translate(0 5.961)">
          <path
            fill="#747474"
            d="M71.319,101.85a5.653,5.653,0,0,0-4.6,2.267l-.187.236v-2.074H61.79v16.95h4.739v-9.6a3.268,3.268,0,0,1,3.274-3.405,3.149,3.149,0,0,1,2.324.979,3.5,3.5,0,0,1,.915,2.427v9.6H77.78V108.312A6.121,6.121,0,0,0,75.9,103.7,6.261,6.261,0,0,0,71.319,101.85Z"
            transform="translate(-61.79 -101.85)"
          />
        </g>
        <g transform="translate(18.899 5.961)">
          <path
            fill="#747474"
            d="M162.923,101.85a8.649,8.649,0,0,0-6.427,2.577,9.019,9.019,0,0,0,.064,12.688,9.211,9.211,0,0,0,6.626,2.544,9.722,9.722,0,0,0,4.847-1.237,8.574,8.574,0,0,0,3.21-3.241l-4.587-1.044a4.276,4.276,0,0,1-3.438,1.506,4.375,4.375,0,0,1-4.291-3.077l-.043-.135h12.829a7.76,7.76,0,0,0,.176-1.676,8.8,8.8,0,0,0-8.967-8.9Zm-4.006,7.129.043-.137a4.191,4.191,0,0,1,3.961-2.977,4.373,4.373,0,0,1,4.125,2.974l.051.142h-8.181Z"
            transform="translate(-153.92 -101.85)"
          />
        </g>
        <g transform="translate(79.22 5.857)">
          <path
            fill="#747474"
            d="M463.446,103.914a7.621,7.621,0,0,0-10.526-.862v-1.284h-4.95v24.586h4.95v-8.88a8.006,8.006,0,0,0,4.718,1.883,7.517,7.517,0,0,0,5.807-2.574,9.366,9.366,0,0,0,2.343-6.435A9.227,9.227,0,0,0,463.446,103.914Zm-3.959,9.371a3.486,3.486,0,0,1-2.673,1.188,4.14,4.14,0,1,1,3.795-4.092A4.006,4.006,0,0,1,459.486,113.285Z"
            transform="translate(-447.97 -101.34)"
          />
        </g>
        <g transform="translate(39.319 5.553)">
          <path
            fill="#747474"
            d="M255.595,107.85a9.748,9.748,0,0,1,4.232-2.177c-1.175-.632-1.836-1.934-1.836-4.622l.043-1.19s-4.583,0-4.558,0a8.356,8.356,0,0,0-.01.981C253.465,101.466,253.342,105.185,255.595,107.85Z"
            transform="translate(-253.464 -99.86)"
          />
        </g>
        <g transform="translate(49.317 14.758)">
          <path
            fill="#747474"
            d="M306.715,144.73a8.8,8.8,0,0,1-4.515,1.992c1.522.765,2.053,2.41,2.053,5.1l-.012.964s4.671-.035,4.7-.023a12.4,12.4,0,0,0-.359-4.351A10.972,10.972,0,0,0,306.715,144.73Z"
            transform="translate(-302.2 -144.73)"
          />
        </g>
        <g transform="translate(51.649)">
          <path
            fill="#7487f2"
            d="M326.321,79.264A6.475,6.475,0,0,0,313.57,77.68H318.9a1.843,1.843,0,0,1,2.788,1.555v7.65H326.3Z"
            transform="translate(-313.57 -72.79)"
          />
        </g>
        <g transform="translate(59.771 6.22)">
          <path
            fill="#747474"
            d="M364.242,112.534a3.246,3.246,0,0,1-3.251,3.383,3.13,3.13,0,0,1-2.31-.972,3.478,3.478,0,0,1-.909-2.41l0-.886H353.16v2.187a6.084,6.084,0,0,0,1.813,4.521,6.184,6.184,0,0,0,4.521,1.813,5.542,5.542,0,0,0,4.511-2.224l.234-.3v2.1h4.611V103.11h-4.611v9.424Z"
            transform="translate(-353.16 -103.11)"
          />
        </g>
        <g transform="translate(39.256 5.551)">
          <path
            fill="#7487f2"
            d="M265.355,100.642h.006l.041,1.245c0,2.788-1.561,4-4.183,4.156a9.039,9.039,0,0,0-5.629,2.421,8.856,8.856,0,0,0-2.051,3.84,14.639,14.639,0,0,0-.322,4.782c.031-.012,4.7.023,4.7.023v-1.017c0-3.84,1.114-5.36,4.3-5.539a7.853,7.853,0,0,0,7.707-8.238l.059-1.674a1.845,1.845,0,0,1,.242-.79h-4.808A6.384,6.384,0,0,0,265.355,100.642Z"
            transform="translate(-253.157 -99.85)"
          />
        </g>
      </g>
    </SvgIcon>
  );
};
