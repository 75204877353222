import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { ModelId } from "model/primitives/model/model";
import {
  Medication,
  MedicationGenericType,
  MedicationStatus
} from "model/resource/prescription/medication/medication";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { getKeyObj, HttpMethod, KeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { Code } from "model/resource/entities/codeSystem";
import { OrganizationHierarchySearchMode } from "model/resource/entities/organization/organization";

export interface MedicationQueryParams {
  withSubstance?: boolean;
  withMedication?: boolean;
}

export interface MedicationListQueryParams
  extends ListQueryParams,
    MedicationQueryParams {
  organization?: ModelId;
  content?: string;
  status?: MedicationStatus;
  substance?: ModelId;
  characteristic?: Code[];
  hisCode?: string;
  snomedCode?: string;
  form?: string;
  functionCode?: string;
  presentationUnit?: string;
  route?: string;
  genericType?: MedicationGenericType;
  instanceOf?: ModelId;
  organizationHierarchyMode?: OrganizationHierarchySearchMode;
}

export const MedicationListQueryParamsKey: KeyObj<MedicationListQueryParams> =
  getKeyObj(createHydratedMock<MedicationListQueryParams>());

const medicationBasePath = "/medication";

export const getMedicationList = (
  params: MedicationListQueryParams
): Promise<ApiPageRequestResponse<Medication>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(medicationBasePath),
    params
  });

export const getMedication = (
  id: ModelId,
  params?: MedicationQueryParams
): Promise<ApiRequestResponse<Medication>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${medicationBasePath}/${id}`),
    params
  });

export const createMedication = (
  data: Medication
): Promise<ApiRequestResponse<Medication>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(medicationBasePath),
    data
  });

export const updateMedication = (
  data: Medication
): Promise<ApiRequestResponse<Medication>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${medicationBasePath}/${data.id}`),
    data
  });
