import React from "react";
import { FC, IconC, StyleVariant } from "@laba/react-common";
import {
  Divider,
  DividerOrientation,
  DividerSize
} from "components/divider/Divider";
import { useUserMenuDataStyle } from "./userMenuDataStyle";

interface Props {
  userFullName: string;
  userExtraData?: string;
  hide?: boolean;
  showProfileDataOnDropdown?: boolean;
  styleVariant: StyleVariant;
  withRightIcon?: boolean;
  RightIcon?: IconC;
}

export const UserMenuData: FC<Props> = ({
  userFullName,
  userExtraData,
  hide = false,
  showProfileDataOnDropdown,
  styleVariant,
  withRightIcon = false,
  RightIcon
}) => {
  const classes = useUserMenuDataStyle({
    styleVariant,
    showProfileDataOnDropdown
  });

  return !hide ? (
    <div className={classes.rootWithDivider}>
      <div className={classes.root}>
        <div className={classes.textWrapper}>
          <p className={classes.usernameText} title={userFullName}>
            {userFullName}
          </p>
          {userExtraData !== undefined ? (
            <p className={classes.userExtraData} title={userExtraData}>
              {userExtraData}
            </p>
          ) : null}
        </div>
        {withRightIcon && RightIcon && <RightIcon />}
      </div>
      {showProfileDataOnDropdown && (
        <Divider
          size={DividerSize.Medium}
          orientation={DividerOrientation.Horizontal}
        />
      )}
    </div>
  ) : null;
};
