import { FC, getClassName, TextVariant } from "@laba/react-common";
import { InfoOutlinedIcon } from "components/icons";
import { UrlLink } from "components/link";
import { OnClickUrlAction } from "components/link/UrlLink/utils";
import React from "react";
import { Noop, TextAlign } from "@laba/ts-common";
import { NexupMarkdown } from "../NexupMarkdown/NexupMarkdown";
import { useFormWarningInfoFieldStyles } from "./FormWarningInfoFieldStyle";
import { FormWarningInfoFieldVariant } from "./types";

export interface FormWarningInfoFieldProps {
  className?: string;
  text?: string;
  onClick?: Noop;
  buttonText?: string;
  showButtonText?: boolean;
  multiline?: boolean;
  isMobile?: boolean;
  variant?: FormWarningInfoFieldVariant;
  showIcon?: boolean;
  textVariant?: TextVariant;
  textAlign?: TextAlign;
}

export const FormWarningInfoField: FC<FormWarningInfoFieldProps> = ({
  className,
  text,
  onClick,
  buttonText,
  showButtonText,
  multiline = false,
  isMobile,
  variant = FormWarningInfoFieldVariant.Warning,
  showIcon = true,
  textVariant = TextVariant.Body2,
  textAlign = TextAlign.Left
}) => {
  const classes = useFormWarningInfoFieldStyles({
    isMobile,
    variant,
    textAlign
  });
  return (
    <div className={getClassName(className, classes.root)}>
      {showIcon && <InfoOutlinedIcon className={classes.icon} />}
      <div className={classes.textAndLinkWrapper}>
        <NexupMarkdown
          className={classes.text}
          defaultVariant={textVariant}
          text={text}
          title={text}
          maxLines={isMobile || multiline ? undefined : 1}
        />
        {showButtonText && (
          <UrlLink
            className={classes.urlLink}
            displayText={buttonText}
            onClickUrlAction={OnClickUrlAction.CustomClick}
            onClick={onClick}
          />
        )}
      </div>
    </div>
  );
};
