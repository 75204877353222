import {
  borderMixinRadiusAll,
  createStyle,
  paddingMixin
} from "@laba/react-common";
import { RadiusVariant, Theme } from "model/theme";
import { getThemeRadius } from "model/themeUtils";
import { TailPosition } from "components/chat/Chat/ChatMessages/ChatMessage/ChatBubble/ChatBubble";
import { NexupColor } from "model/nexupColor";
import { MessageOrigin } from "components/chat/Chat/ChatMessages/ChatMessage/ChatMessageComponent";

export interface ChatBubbleStyleProps {
  tailPosition: TailPosition;
  messageOrigin: MessageOrigin;
}

export const useChatBubbleStyle = createStyle(
  (theme: Theme) => ({
    root: (props: ChatBubbleStyleProps) => ({
      ...paddingMixin({
        all: 8
      }),
      ...borderMixinRadiusAll(getThemeRadius(theme, RadiusVariant.Small)),
      // TODO: background color must come from defaultTheme
      backgroundColor:
        props.messageOrigin === MessageOrigin.Sent
          ? NexupColor.LightPurple
          : NexupColor.Background,
      position: "relative"
    }),
    tail: {
      position: "absolute",
      bottom: 0
    },
    tailLeft: {
      left: -3
    },
    tailRight: {
      right: -3
    }
  }),
  "ChatBubble"
);
