import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  FlexJustifyContent,
  flexRowMixin,
  marginMixin,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

export const useToAddProblemListStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...marginMixin({ top: 8 }),
      ...flexColumnMixin({ gap: 4 })
    },
    noResultsText: {
      ...themeTextMixin(theme, TextVariant.Body2),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    showMoreProblems: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.FlexStart
      })
    },
    showMoreProblemsCount: {
      ...themeTextMixin(theme, TextVariant.Body2),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "ToAddProblemList"
);
