import { ModelReference } from "model/primitives/modelReference/modelReference";
import { Quantity } from "model/primitives/quantity";
import { ResourceType } from "model/primitives/resourceModel";
import { getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { PatientMeasure } from "./patientMeasure";
import { MeasurementGroupDefinition } from "./measurementGroupDefinition";
import {
  Observation,
  ObservationCategory,
  ObservationCode
} from "./observation";

export interface Member {
  linkId: string;
  measurement: ModelReference<PatientMeasure>;
}

export interface Component {
  linkId: string;
  type: ObservationCode;
  value?: Quantity;
  range?: string;
}

export interface MeasurementGroup
  extends Observation<
    ResourceType.MeasurementGroup,
    ObservationCategory.MeasurementGroup
  > {
  definition: ModelReference<MeasurementGroupDefinition>;
  members?: Member[];
  components?: Component[];
}

export enum FluidBalanceStatistics {
  PartialInputBalance = "partialInputBalance",
  PartialOutputBalance = "partialOutputBalance",
  PartialBalance = "partialBalance",
  DayBalance = "dayBalance",
  Diuresis = "diuresis"
}

export type FluidBalanceStatisticsRecord = Record<
  FluidBalanceStatistics,
  number
>;

export const MeasurementGroupMemberParamsKey = getKeyObj(
  createHydratedMock<Member>()
);

export const MeasurementGroupComponentParamsKey = getKeyObj(
  createHydratedMock<Component>()
);
