import React, { MutableRefObject } from "react";
import { FCC, getClassName, HTMLDivElementOrNull } from "@laba/react-common";
import { BaseIconButton } from "components/buttons/BaseIconButton/BaseIconButton";
import { ChevronLeftIcon } from "components/icons";
import { NexupLogo } from "components/svg/NexupLogo";
import { Skeleton } from "components/skeleton/Skeleton/Skeleton";
import { useAppHeaderWithoutDrawerStyles } from "./useAppHeaderWithoutDrawerStyles";

export interface AppHeaderWithoutDrawerProps {
  className?: string;
  title?: string;
  headerRef?: MutableRefObject<HTMLDivElementOrNull>;
  showLogo?: boolean;
  isMobile: boolean;
  onGoBack: () => void;
  wrapChildrenIfIsMobile?: boolean;
  isLoading?: boolean;
}

export const AppHeaderWithoutDrawer: FCC<AppHeaderWithoutDrawerProps> = ({
  className,
  children,
  title,
  headerRef,
  showLogo = true,
  isMobile,
  onGoBack,
  wrapChildrenIfIsMobile = false,
  isLoading = false
}) => {
  const classes = useAppHeaderWithoutDrawerStyles({
    isMobile,
    wrapChildrenIfIsMobile,
    hasChildren: Boolean(children),
    showLogo
  });
  return (
    <div className={getClassName(classes.root, className)} ref={headerRef}>
      <div className={classes.firstRow}>
        <div className={classes.containerIcon}>
          <BaseIconButton Icon={ChevronLeftIcon} onClick={onGoBack} />
          {showLogo && <NexupLogo className={classes.textIcon} />}
        </div>
        {isLoading ? (
          <Skeleton width={300} height={20} />
        ) : (
          <p className={classes.title} title={title}>
            {title}
          </p>
        )}
      </div>
      {children}
    </div>
  );
};
