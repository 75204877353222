import React from "react";
import { FC } from "@laba/react-common";
import { Popup } from "components/popup/Popup/Popup";
import { PatientDetailData } from "components/headers/PatientHeader/PatientHeader";
import { PatientDetailMobilePopupContent } from "components/headers/PatientHeader/PatientDetailMobilePopup/PatientDetailMobilePopupContent/PatientDetailMobilePopupContent";
import { PatientAllergiesProps } from "components/headers";
import { TagFlatData } from "components/tags";
import { Noop } from "@laba/ts-common";
import { PatientDetailMobilePopupHeader } from "./PatientDetailMobilePopupHeader/PatientDetailMobilePopupHeader";
import { usePatientDetailMobilePopupStyles } from "./usePatientDetailMobilePopupStyles";

export interface PatientDetailMobilePopupProps extends PatientAllergiesProps {
  isOpen?: boolean;
  onEditClick?: Noop;
  isEditable?: boolean;
  onClose?: Noop;
  firstName?: string;
  lastName?: string;
  patientDetailData?: PatientDetailData[];
  tags?: TagFlatData[];
  showAllergies?: boolean;
}

export const PatientDetailMobilePopup: FC<PatientDetailMobilePopupProps> = ({
  isOpen,
  onEditClick,
  onClose,
  isEditable,
  firstName,
  lastName,
  patientDetailData,
  tags,
  showAllergies,
  ...rest
}) => {
  const classes = usePatientDetailMobilePopupStyles();

  return (
    <Popup
      isMobile
      open={isOpen}
      childrenClassName={classes.popupChildren}
      PopupHeader={
        <PatientDetailMobilePopupHeader
          onEditClick={onEditClick}
          onClose={onClose}
          isEditable={isEditable}
          firstName={firstName}
          lastName={lastName}
        />
      }
    >
      <PatientDetailMobilePopupContent
        patientDetailData={patientDetailData}
        tags={tags}
        showAllergies={showAllergies}
        {...rest}
      />
    </Popup>
  );
};
