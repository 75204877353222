import { Property } from "csstype";
import { isEmpty } from "lodash-es";
import { Dimension, getDimensionPropertyValue } from "model/style/dimension";
import { Optional } from "model/types";

export enum GridJustifyItems {
  Start = "start",
  End = "end",
  Center = "center",
  Stretch = "stretch"
}

export enum GridAlignItems {
  Start = "start",
  End = "end",
  Center = "center",
  Stretch = "stretch"
}

export enum GridJustifyContent {
  Start = "start",
  End = "end",
  Center = "center",
  Stretch = "stretch",
  SpaceBetween = "space-between",
  SpaceAround = "space-around",
  SpaceEvenly = "space-evenly"
}

export enum GridAlignContent {
  Start = "start",
  End = "end",
  Center = "center",
  Stretch = "stretch",
  SpaceBetween = "space-between",
  SpaceAround = "space-around",
  SpaceEvenly = "space-evenly"
}

export enum GridAutoFlow {
  Row = "row",
  Column = "column",
  Dense = "dense"
}

export interface GridContainerResult {
  display?: Property.Display;
  "grid-template-columns"?: Property.GridTemplateColumns;
  "grid-template-rows"?: Property.GridTemplateRows;
  "grid-template-areas"?: Property.GridTemplateAreas;
  "column-gap"?: Property.ColumnGap;
  "row-gap"?: Property.RowGap;
  "justify-items"?: Property.JustifyItems;
  "align-items"?: Property.AlignItems;
  "justify-content"?: Property.JustifyContent;
  "align-content"?: Property.AlignContent;
  "grid-auto-columns"?: Property.GridAutoColumns;
  "grid-auto-rows"?: Property.GridAutoRows;
  "grid-auto-flow"?: Property.GridAutoFlow;
}

export interface GridItemResult {
  "grid-column"?: Property.GridColumn;
  "grid-row"?: Property.GridRow;
  "grid-area"?: Property.GridArea;
  "justify-self"?: Property.JustifySelf;
  "align-self"?: Property.AlignSelf;
}

export type GridArea = string;

export interface GridContainerConfig {
  templateColumn?: Dimension[];
  templateRow?: Dimension[];
  templateAreas?: GridArea[][];
  gap?: Dimension;
  rowGap?: Dimension;
  columnGap?: Dimension;
  justifyItems?: GridJustifyItems;
  alignItems?: GridAlignItems;
  justifyContent?: GridJustifyContent;
  alignContent?: GridAlignContent;
  autoColumn?: Dimension[];
  autoRow?: Dimension[];
  autoFlow?: GridAutoFlow;
}

const undefinedIfEmpty = (content?: string) =>
  isEmpty(content) ? undefined : content;

export const gridContainerMixin = (
  config: GridContainerConfig
): GridContainerResult => {
  const safeConfig: GridContainerConfig = {
    justifyItems: GridJustifyItems.Stretch,
    alignItems: GridAlignItems.Stretch,
    justifyContent: GridJustifyContent.Stretch,
    alignContent: GridAlignContent.Stretch,
    autoFlow: GridAutoFlow.Row,
    ...config
  };
  return {
    display: "grid",
    "grid-template-columns": undefinedIfEmpty(
      safeConfig.templateColumn?.map(getDimensionPropertyValue).join(" ")
    ),
    "grid-template-rows": undefinedIfEmpty(
      safeConfig.templateRow?.map(getDimensionPropertyValue).join(" ")
    ),
    "grid-template-areas": undefinedIfEmpty(
      safeConfig.templateAreas
        ?.map(value => `"${value.map(getDimensionPropertyValue).join(" ")}"`)
        .join("\n")
    ),
    "column-gap": getDimensionPropertyValue(
      safeConfig.columnGap ?? safeConfig.gap
    ),
    "row-gap": getDimensionPropertyValue(safeConfig.rowGap ?? safeConfig.gap),
    "justify-items": safeConfig.justifyItems,
    "align-items": safeConfig.alignItems,
    "justify-content": safeConfig.justifyContent,
    "align-content": safeConfig.alignContent,
    "grid-auto-columns": undefinedIfEmpty(
      safeConfig.autoColumn?.map(getDimensionPropertyValue)?.join(" ")
    ),
    "grid-auto-rows": undefinedIfEmpty(
      safeConfig.autoRow?.map(getDimensionPropertyValue).join(" ")
    ),
    "grid-auto-flow": safeConfig.autoFlow
  };
};

export interface GridItemConfig {
  columnStart?: number;
  columnEnd?: number;
  columnSpan?: number;
  rowStart?: number;
  rowEnd?: number;
  rowSpan?: number;
  area?: GridArea;
  justify?: GridJustifyItems;
  align?: GridAlignItems;
}

const getGridDimensionProperty = (
  start?: number,
  end?: number,
  span?: number
): Optional<string> => {
  if (start == null) {
    if (span !== undefined) {
      return `span ${span}`;
    }
    return undefined;
  }
  if (end != null) return `${start} / ${end}`;
  if (span != null) return `${start} / span ${span}`;
  return `${start}`;
};

export const gridItemMixin = (config: GridItemConfig): GridItemResult => {
  const safeConfig: GridItemConfig = {
    ...config
  };
  const baseResult: GridItemResult = {
    "justify-self": safeConfig.justify,
    "align-self": safeConfig.align
  };
  if (!isEmpty(safeConfig.area)) {
    return {
      ...baseResult,
      "grid-area": safeConfig.area
    };
  }
  return {
    ...baseResult,
    "grid-column": getGridDimensionProperty(
      safeConfig.columnStart,
      safeConfig.columnEnd,
      safeConfig.columnSpan
    ),
    "grid-row": getGridDimensionProperty(
      safeConfig.rowStart,
      safeConfig.rowEnd,
      safeConfig.rowSpan
    )
  };
};
