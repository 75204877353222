import React from "react";
import { SaveButton, Toolbar, ToolbarProps } from "react-admin";
import { ReactElement, useI18n } from "@laba/react-common";
import { Button } from "@mui/material";
import { Noop } from "@laba/ts-common";
import { CloseIcon } from "@laba/nexup-components";
import { tkCC } from "translation/i18n";

export interface ResourceFormToolbarProps extends ToolbarProps {
  onCancelButtonClicked?: Noop;
}

const tk = tkCC.inputs.resourceFormToolbar;
export const ResourceFormToolbar = (
  props: ResourceFormToolbarProps
): ReactElement => {
  const { t } = useI18n();
  return (
    <Toolbar {...props}>
      <SaveButton alwaysEnable />
      {props.onCancelButtonClicked && (
        <Button
          onClick={() => props.onCancelButtonClicked?.()}
          startIcon={<CloseIcon />}
        >
          {t(tk.cancel)}
        </Button>
      )}
    </Toolbar>
  );
};
