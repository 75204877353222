interface ScrollableBackgroundReturnType {
  overflowY: "auto";
  width: "100%";
  height: "100%";
  scrollBehavior: "smooth";
}

export const scrollableBackgroundMixin = (): ScrollableBackgroundReturnType => {
  return {
    overflowY: "auto",
    width: "100%",
    height: "100%",
    scrollBehavior: "smooth"
  };
};
