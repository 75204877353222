import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  CodeSystemGroup,
  FluidBalanceCategory,
  MeasurementConfigKey,
  ModelId,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { TextInput } from "components/generic/TextInput/TextInput";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { NumberInput } from "components/generic/NumberInput/TextInput";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";

export const tk = tkCP.adminPage[ResourceType.MeasurementGroupDefinition];

export interface MeasurementGroupDefinitionFieldFieldsProps {
  getSource?: (source: string) => string;
  withLinkId?: boolean;
  withProps?: boolean;
  measurementTypeSystem?: CodeSystemGroup;
  unitSystem?: CodeSystemGroup;
  organizationId?: ModelId;
}

export const MeasurementGroupDefinitionMeasurementFields: FC<
  MeasurementGroupDefinitionFieldFieldsProps
> = ({
  getSource = value => value,
  withLinkId = true,
  withProps = true,
  measurementTypeSystem,
  unitSystem,
  organizationId
}) => {
  const { t } = useI18n();
  return (
    <>
      <TextInput
        source={getSource(MeasurementConfigKey.title)}
        label={t(tk.fields.measurementsTitle)}
        required
      />
      {withProps && (
        <>
          {withLinkId && (
            <TextInput
              source={getSource(MeasurementConfigKey.linkId)}
              label={t(tk.fields.measurementsLinkId)}
              required
            />
          )}
          <CodeSystemSelectorInput
            source={getSource(MeasurementConfigKey.type)}
            label={t(tk.fields.measurementsType)}
            codeSystem={measurementTypeSystem}
            organizationId={organizationId}
            withCreate
            required
          />
          <TextInput
            source={getSource(MeasurementConfigKey.range)}
            label={t(tk.fields.measurementsRange)}
          />
          <ArrayInput
            source={getSource(MeasurementConfigKey.units)}
            label={t(tk.fields.measurementsUnits)}
          >
            <CodeSystemSelectorInput
              source=""
              label={t(tk.fields.measurementsUnitsElement)}
              codeSystem={unitSystem}
              organizationId={organizationId}
              withCreate
              required
            />
          </ArrayInput>
          <NumberInput
            source={getSource(MeasurementConfigKey.minValue)}
            label={t(tk.fields.measurementsMinValue)}
          />
          <NumberInput
            source={getSource(MeasurementConfigKey.maxValue)}
            label={t(tk.fields.measurementsMaxValue)}
          />
          <NumberInput
            source={getSource(MeasurementConfigKey.step)}
            label={t(tk.fields.measurementsStep)}
          />
          <BooleanInput
            source={getSource(MeasurementConfigKey.required)}
            label={t(tk.fields.measurementsRequired)}
          />
          <BooleanInput
            source={getSource(MeasurementConfigKey.isComponent)}
            label={t(tk.fields.measurementsIsComponent)}
          />
          <EnumSelectorInput
            source={getSource(MeasurementConfigKey.fluidBalanceCategory)}
            label={t(tk.fields.measurementsFluidBalanceCategory)}
            enumValue={FluidBalanceCategory}
          />
        </>
      )}
    </>
  );
};
