import { FC } from "@laba/react-common";
import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export const RicardaIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      width="100"
      height="102"
      viewBox="0 0 100 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M59.9014 32.2879C59.658 40.2592 60.7034 49.8618 56.7471 57.1437C53.6513 62.8395 47.7934 66.3858 41.8498 68.4947C28.782 73.1315 11.0143 72.5096 3.097 59.2841C-0.0572939 53.9984 0.131958 43.5126 6.28734 40.1285C9.44164 38.4027 13.0466 38.2224 16.5433 37.56C21.1306 36.6949 24.5642 34.4057 26.957 30.3953C31.6389 22.5546 31.2829 16.5254 32.7294 7.76997C33.0042 6.14776 33.5089 4.5661 35.2303 3.30889C36.7846 1.99382 38.6681 1.1276 40.6782 0.803479C42.4138 0.20152 44.2713 0.0374487 46.0855 0.32583C49.9563 1.02879 53.4305 4.91758 56.2784 7.38695C59.1263 9.85631 60.388 12.3707 60.334 16.2235C60.3024 21.5813 60.0636 26.9346 59.9014 32.2879Z"
        fill="#4F4F4F"
      />
      <path
        d="M60.5978 36.1099C60.3544 28.7829 59.9624 21.4785 59.6199 14.1605C59.6199 14.1154 59.5523 14.1109 59.5523 14.1605C59.656 20.1897 59.683 26.2144 59.8678 32.2436C60.048 38.1512 60.6383 44.2119 60.3184 50.1195C59.5613 63.372 45.5427 67.725 34.336 66.2199C33.8268 66.1523 33.7772 66.9724 34.2954 67.022C44.1729 68.0359 56.2403 65.553 60.048 55.0222C62.1163 49.2588 60.8005 42.1076 60.5978 36.1099Z"
        fill="#4F4F4F"
      />
      <path
        d="M33.4543 7.12539C33.4491 7.09992 33.4353 7.07703 33.4151 7.06058C33.395 7.04414 33.3698 7.03516 33.3439 7.03516C33.3179 7.03516 33.2927 7.04414 33.2726 7.06058C33.2524 7.07703 33.2386 7.09992 33.2334 7.12539C32.3322 14.3803 31.9943 22.8969 27.3755 28.9441C25.0912 31.9014 21.9674 34.1002 18.4128 35.2527C15.5964 36.1539 12.69 36.0323 9.78802 36.3792C-1.54942 37.7311 -2.82916 52.8041 4.86732 59.3065C5.02503 59.4417 5.26836 59.1713 5.11065 59.0226C-0.256162 54.0343 -1.51337 45.6123 3.61011 39.8895C7.71971 35.2978 14.7853 37.3931 20.0845 35.1491C31.066 30.4987 32.873 17.7103 33.4543 7.12539Z"
        fill="#4F4F4F"
      />
      <path
        d="M34.6209 38.0514C22.8463 49.9881 8.03465 75.1008 10.7924 80.1882C13.8701 85.8615 30.2679 69.1257 30.2048 68.1479C30.1553 67.4088 35.9276 52.1556 40.3031 41.0614C44.6786 29.9673 37.5363 35.0818 34.6209 38.0514Z"
        fill="#FFB7B4"
      />
      <path
        d="M30.3129 68.1473C30.8176 66.5161 38.6673 46.4052 40.9114 40.0425C43.1554 33.6799 41.0736 31.7152 34.6659 37.5416C28.2581 43.3681 14.208 62.8256 11.0041 73.1401C7.91293 83.0897 13.0364 83.0897 19.7956 78.5024C25.3292 74.7668 30.3129 68.1473 30.3129 68.1473Z"
        fill="#7487F2"
      />
      <path
        d="M26.8343 79.3688L25.6582 86.4209L57.5476 86.4073C54.5285 62.4707 63.0366 44.8968 54.6282 36.0467C52.4562 33.7531 46.5982 30.6123 42.5427 33.5458C38.2438 36.7001 28.0194 52.395 26.8523 54.923C24.5452 59.9067 28.5241 66.8552 28.5241 66.8552L26.8343 79.3688Z"
        fill="#FFB7B4"
      />
      <path
        d="M26.7687 76.5L28.5241 66.8596C28.5241 66.8596 24.5452 59.9111 26.8524 54.9228C28.0195 52.3949 38.2439 36.7 42.5427 33.5457C46.5983 30.6167 52.4562 33.753 54.6282 36.0511C61.9101 43.7116 66.1874 57.2813 69.0758 76.5H26.7687Z"
        fill="#7487F2"
      />
      <path
        d="M26.8344 79.3692C27.0597 77.2783 27.285 75.1829 27.5508 73.0966C27.8167 71.0103 28.0465 68.9149 28.3259 66.8286L28.3665 66.9773C27.7682 66.1257 27.2764 65.2041 26.902 64.233C26.5179 63.2703 26.2399 62.2685 26.0728 61.2455C25.9107 60.219 25.8653 59.1774 25.9377 58.1407C26.0546 57.1057 26.3123 56.0915 26.7037 55.1261C27.4827 53.2314 28.4504 51.4198 29.5921 49.7188C30.7065 48.0045 31.9223 46.3583 33.2331 44.7891C32.0209 46.4293 30.8854 48.1281 29.8219 49.863C28.7499 51.5812 27.8316 53.3907 27.0777 55.2703C26.7072 56.2016 26.4676 57.1797 26.3657 58.1768C26.3094 59.1781 26.3592 60.1826 26.5144 61.1734C26.6674 62.1677 26.9288 63.1424 27.294 64.0798C27.6458 65.0196 28.1119 65.9126 28.6819 66.7385L28.727 66.806V66.8827C28.4476 68.969 28.1186 71.0463 27.8257 73.1327C27.5328 75.219 27.1723 77.2918 26.8344 79.3692Z"
        fill="#4F4F4F"
      />
      <path
        d="M29.8223 49.7087C29.846 49.4283 29.902 49.1517 29.989 48.8841C30.0712 48.6172 30.169 48.3554 30.2819 48.1C30.3945 47.8477 30.5117 47.5953 30.6469 47.352C30.7815 47.1066 30.9321 46.8703 31.0975 46.6445C30.9263 47.1808 30.7055 47.6854 30.4892 48.1901C30.2729 48.6948 30.0341 49.1905 29.8223 49.7087Z"
        fill="#4F4F4F"
      />
      <path
        d="M10.7928 80.1702C13.4424 83.9644 20.6567 84.6763 38.6587 88.2137C41.1461 88.7003 49.3022 72.0501 46.6841 71.3968C43.3676 70.5676 30.3224 68.0712 29.3445 68.0081C21.1028 67.4809 8.99481 77.6017 10.7928 80.1702Z"
        fill="#FFB7B4"
      />
      <path
        d="M43.0835 70.5742C43.0835 70.5742 50.7123 71.7233 54.529 73.5708C59.6435 76.0402 63.0411 80.2804 64.3569 82.1325C67.1057 86.0213 71.0621 97.3181 68.0024 99.026C65.2401 100.572 61.7794 88.8196 61.7794 88.8196C61.7794 88.8196 67.2859 100.22 63.6134 101.468C59.9409 102.716 56.6965 88.8511 56.6965 88.8511C56.6965 88.8511 62.1039 100.689 58.1024 101.378C55.0878 101.901 51.4423 89.5 51.4423 89.5C51.4423 89.5 56.0071 99.7109 52.9294 100.378C49.5272 101.139 45.4492 89.874 45.4492 89.874C37.3381 90.257 33.3187 87.1207 30.7231 84.5523C30.0968 83.9394 43.0835 70.5742 43.0835 70.5742Z"
        fill="#FFB7B4"
      />
      <path
        d="M60.117 84.7618C60.811 86.0776 61.5274 87.4024 62.0997 88.7768C62.672 90.1512 63.2037 91.5435 63.6498 92.963C64.2718 94.8083 64.677 96.7198 64.8575 98.6587C64.2807 95.2431 62.2935 90.2954 61.7032 88.921C61.1129 87.5466 60.6217 86.1497 60.0449 84.7798C60.0584 84.7438 60.117 84.7303 60.117 84.7618Z"
        fill="#4F4F4F"
      />
      <path
        d="M55.129 86.7498C55.9446 88.4711 56.8188 90.1474 57.5578 91.9003C58.2968 93.6532 58.8376 95.37 59.4504 97.1634C59.5 97.2986 59.4098 97.2806 59.3558 97.1409C58.6708 95.3745 57.9003 93.7973 57.1973 92.0354C56.4944 90.2735 55.8455 88.4936 55.0884 86.7678C55.0749 86.7407 55.12 86.7182 55.129 86.7498Z"
        fill="#4F4F4F"
      />
      <path
        d="M50.5354 87.6867C51.8483 90.2928 52.8888 93.0275 53.6402 95.8473C53.6852 95.9825 53.5681 95.9464 53.5185 95.8067C52.5362 93.076 51.7881 90.9266 50.4453 87.7362C50.4228 87.6867 50.5039 87.6191 50.5354 87.6867Z"
        fill="#4F4F4F"
      />
      <path
        d="M44.6745 70.5724L28.903 88.1463C28.903 88.1463 12.9558 84.4828 10.6081 80.3957C9.06251 77.692 21.0669 66.8007 30.1287 68.0714L44.6745 70.5724Z"
        fill="#7487F2"
      />
      <path
        d="M19.3237 70.9158C20.7837 69.8568 24.8978 67.3875 30.1384 68.0453C30.1835 68.0453 30.188 68.1265 30.1384 68.122C26.1821 68.0093 20.8423 70.0776 19.3237 70.9788C19.3192 71.0014 19.2877 70.9473 19.3237 70.9158Z"
        fill="#4F4F4F"
      />
      <path
        d="M24.5938 85.1911C26.2251 83.1679 27.9599 81.2348 29.7038 79.3061C31.4477 77.3775 33.187 75.4399 34.9805 73.5518C35.9899 72.4838 36.9992 71.4114 38.0492 70.3975C38.0762 70.3705 38.1258 70.3975 38.1032 70.4426C36.4765 72.4613 34.7417 74.408 33.0158 76.3456C31.29 78.2833 29.5326 80.2119 27.7662 82.1225C26.7658 83.1994 25.7474 84.2584 24.6975 85.2768C24.6434 85.3263 24.5488 85.2497 24.5938 85.1911Z"
        fill="#4F4F4F"
      />
      <path
        d="M44.7062 70.3433C43.7374 70.052 42.7498 69.8277 41.7502 69.6718C40.7544 69.4826 39.754 69.3158 38.7581 69.1536C36.7709 68.8337 32.5937 67.9325 28.9212 67.9415C28.8807 67.9415 29.088 68.1397 29.1285 68.1397C30.8769 68.0451 43.404 70.537 44.4494 70.6091C44.4075 70.6757 44.3698 70.745 44.3367 70.8164C44.3367 70.8705 44.3683 70.9336 44.4224 70.8975C44.553 70.8119 44.6432 70.7037 44.7648 70.6091C44.7881 70.5921 44.806 70.5686 44.8162 70.5416C44.8264 70.5146 44.8285 70.4852 44.8223 70.4571C44.8161 70.4289 44.8018 70.4031 44.7812 70.3829C44.7606 70.3627 44.7345 70.3489 44.7062 70.3433Z"
        fill="#4F4F4F"
      />
      <path
        d="M57.5034 37.15C72.572 50.2944 84.4231 66.5346 83.639 70.5946C82.2196 78.1559 72.2836 78.629 70.7605 76.9933C64.6592 70.3828 58.2199 57.5763 51.2444 43.2874C45.0124 30.517 52.5422 32.8286 57.5034 37.15Z"
        fill="#7487F2"
      />
      <path
        d="M68.2717 74.0421C67.1677 72.3298 66.1267 70.5633 65.0723 68.815C64.0179 67.0666 62.9724 65.3092 61.945 63.5428C59.9173 60.0505 58.0337 56.4817 56.2178 52.8768C55.1904 50.8355 54.1449 48.8212 53.1671 46.7394C53.1356 46.6718 53.0274 46.7394 53.0499 46.789C53.9962 49.1923 55.0146 51.5565 56.1051 53.8816L56.06 53.8231C55.8167 53.4941 55.5734 53.1652 55.3346 52.8317C54.8614 52.1738 54.4333 51.5024 53.9827 50.813C53.9512 50.7589 53.8656 50.813 53.8971 50.867C54.3845 51.8048 54.9356 52.7082 55.5463 53.5707C55.8448 53.9739 56.1668 54.3591 56.5106 54.7243L56.5422 54.7513C57.0424 55.7877 57.5516 56.8196 58.0878 57.8425C59.9581 61.4759 62.0095 65.0131 64.2342 68.441C64.865 69.4008 65.5004 70.3561 66.1628 71.2979C66.8252 72.2396 67.5146 73.1499 68.2176 74.0781C68.2266 74.1367 68.3077 74.1007 68.2717 74.0421Z"
        fill="#4F4F4F"
      />
      <path
        d="M73.2565 75.5288C70.3996 71.4328 71.8325 63.2857 74.9868 43.729C75.4374 41.0253 95.9584 40.8136 95.643 43.729C95.2464 47.397 91.5874 61.8933 90.6366 64.0066C85.9638 74.4609 75.1896 78.3091 73.2565 75.5288Z"
        fill="#FFB7B4"
      />
      <path
        d="M94.9771 46.2525C95.8287 44.0625 99.7446 31.923 99.9338 28.8453C100.123 25.7676 100.056 16.868 97.7123 16.4805C95.3691 16.0929 95.1844 18.6975 95.1844 18.6975C95.1844 18.6975 94.558 14.9439 92.1653 14.7636C89.7725 14.5834 89.6148 18.6524 89.6148 18.6524C89.6148 18.6524 88.691 14.8447 86.2893 14.8402C83.8875 14.8357 84.4508 18.9453 84.4508 18.9453C84.4508 18.9453 83.7658 15.426 81.7876 16.2416C79.4084 17.233 81.4001 23.5821 80.927 26.9617C80.8188 27.7323 77.6465 42.3727 77.6465 42.3727L94.9771 46.2525Z"
        fill="#FFB7B4"
      />
      <path
        d="M84.3875 18.5944C85.843 23.2673 85.6718 25.5609 84.4281 30.2113C84.4056 30.3059 84.5363 30.36 84.5678 30.2698C86.199 25.8223 86.8209 22.9744 84.6128 18.5133C84.5047 18.2925 84.32 18.3736 84.3875 18.5944Z"
        fill="#4F4F4F"
      />
      <path
        d="M89.5011 18.2835C90.587 23.2673 90.4248 25.7592 89.5011 30.7295C89.5011 30.7971 89.6092 30.8286 89.6272 30.7565C90.9791 25.962 91.5243 23.0961 89.7849 18.27C89.6813 18.0492 89.4515 18.0582 89.5011 18.2835Z"
        fill="#4F4F4F"
      />
      <path
        d="M95.1381 18.9455C95.8411 23.8482 95.778 26.6059 94.2369 31.2923C94.2279 31.315 94.2284 31.3403 94.2381 31.3627C94.2478 31.3851 94.266 31.4027 94.2887 31.4117C94.3114 31.4207 94.3368 31.4203 94.3591 31.4105C94.3815 31.4008 94.3992 31.3826 94.4081 31.3599C96.4404 26.7141 96.544 23.2984 95.2823 18.941C95.2012 18.6436 95.0931 18.6391 95.1381 18.9455Z"
        fill="#4F4F4F"
      />
      <path
        d="M74.6887 46.205C74.6887 46.205 73.062 40.2974 72.9223 37.5397C72.7826 34.7819 76.0361 24.3322 78.3297 24.9225C81.2992 25.666 80.6098 32.0737 79.8618 35.1649C79.8618 35.1649 86.7291 36.8773 87.2293 41.5817C87.7295 46.2861 74.6887 46.205 74.6887 46.205Z"
        fill="#FFB7B4"
      />
      <path
        d="M79.0891 25.3749C81.3061 28.002 80.0625 31.9989 79.5397 35.0541C79.5299 35.0987 79.531 35.1451 79.5429 35.1892C79.5548 35.2333 79.5772 35.2739 79.6081 35.3076C79.6391 35.3412 79.6777 35.3668 79.7208 35.3823C79.7638 35.3978 79.8099 35.4027 79.8552 35.3966C82.685 36.2978 86.4161 38.4112 87.3354 41.4077C87.3413 41.4238 87.3527 41.4372 87.3676 41.4456C87.3825 41.4539 87.3999 41.4568 87.4167 41.4535C87.4335 41.4503 87.4485 41.4412 87.4592 41.4278C87.4699 41.4145 87.4755 41.3978 87.475 41.3807C86.9253 38.0281 83.4195 35.6579 80.2652 34.8648C80.8285 31.7376 81.901 27.655 79.0891 25.3524C79.0936 25.3389 79.0711 25.3569 79.0891 25.3749Z"
        fill="#4F4F4F"
      />
      <path
        d="M95.9677 50.3535L72.1527 57.1127C72.1527 57.1127 67.4033 72.722 70.4224 76.6378C73.6533 80.824 88.6452 74.3352 91.6283 65.2148L95.9677 50.3535Z"
        fill="#7487F2"
      />
      <path
        d="M72.9316 62.526C75.5902 61.9222 78.2128 61.1742 80.8354 60.4262C83.458 59.6781 86.085 58.9301 88.6896 58.1145C90.1586 57.6639 91.6321 57.2133 93.0876 56.6861C93.1281 56.6861 93.1146 56.605 93.0876 56.614C90.4335 57.2133 87.8019 57.9658 85.1838 58.6823C82.5657 59.3988 79.9297 60.1783 77.3161 60.9624C75.8426 61.413 74.3736 61.8636 72.9181 62.3909C72.8325 62.4179 72.855 62.5441 72.9316 62.526Z"
        fill="#4F4F4F"
      />
      <path
        d="M73.0008 56.8516C72.6313 56.8876 72.2618 56.9642 71.8968 57.0363C71.869 57.0443 71.8436 57.0593 71.8231 57.0798C71.8026 57.1003 71.7877 57.1256 71.7797 57.1535C71.3741 58.5414 71.0857 59.9698 70.7973 61.3847C70.5089 62.7997 70.2611 64.1785 70.0313 65.5845C69.9051 66.337 69.797 67.0985 69.7113 67.8375C69.2516 68.7569 68.9717 69.7556 68.8867 70.78C68.8867 70.792 68.8915 70.8034 68.8999 70.8119C68.9084 70.8203 68.9198 70.8251 68.9318 70.8251C68.9437 70.8251 68.9552 70.8203 68.9636 70.8119C68.9721 70.8034 68.9768 70.792 68.9768 70.78C69.0897 69.871 69.3247 68.9812 69.6753 68.1349C69.4476 69.99 69.4978 71.8687 69.824 73.709C69.3734 70.9603 70.0493 68.0944 70.4999 65.3907C70.7252 64.0389 70.9821 62.687 71.2479 61.3352C71.5138 59.9833 71.8247 58.654 72.0726 57.3067C72.388 57.185 72.7079 57.0679 73.0098 56.9237C73.015 56.9188 73.0189 56.9128 73.0213 56.9061C73.0237 56.8995 73.0245 56.8923 73.0236 56.8853C73.0227 56.8783 73.0202 56.8716 73.0162 56.8657C73.0123 56.8599 73.007 56.855 73.0008 56.8516Z"
        fill="#4F4F4F"
      />
      <path
        d="M48.8519 33.6346C48.6401 34.4006 44.7559 39.2898 41.223 38.9924C40.074 38.8933 40.3218 32.5892 40.3218 32.5892L40.2362 32.1386L39.0195 25.5641L46.8557 20.9273L47.9867 20.3145C47.9867 20.3145 48.203 22.1169 48.4374 24.4105C48.4374 24.5142 48.4599 24.6178 48.4689 24.7305C48.4779 24.8431 48.505 24.9828 48.514 25.1C48.559 25.4379 48.5951 25.7849 48.6311 26.1319C48.6582 26.3932 48.6807 26.6501 48.6987 26.925C48.7167 27.1998 48.7438 27.4747 48.7663 27.7496C48.9278 29.7071 48.9564 31.6732 48.8519 33.6346Z"
        fill="#FFB7B4"
      />
      <path
        d="M48.4476 24.397C47.7199 26.2036 46.6078 27.8302 45.1885 29.1639C43.7692 30.4976 42.0767 31.5065 40.2284 32.1205L39.0117 25.5461L46.8569 20.9273L47.9879 20.3145C47.9879 20.3145 48.2042 22.1259 48.4476 24.397Z"
        fill="#4F4F4F"
      />
      <path
        d="M30.7419 14.9092C29.5298 26.5846 36.3881 28.6304 38.907 28.8782C41.1917 29.1125 49.0053 29.6217 51.4161 18.1311C53.8269 6.64044 48.78 2.39116 43.5799 1.57555C38.3798 0.759937 31.9541 3.2338 30.7419 14.9092Z"
        fill="#FFB7B4"
      />
      <path
        d="M34.676 12.1957C34.9448 12.1778 35.2125 12.1462 35.4781 12.1011C35.7658 12.107 36.0476 12.0183 36.2801 11.8487C36.3442 11.7816 36.3839 11.695 36.3928 11.6027C36.4018 11.5104 36.3796 11.4177 36.3297 11.3395C36.2069 11.2026 36.0502 11.1006 35.8753 11.0436C35.7005 10.9866 35.5137 10.9768 35.3339 11.0151C34.956 11.0358 34.5994 11.1964 34.3335 11.4657C34.2816 11.5314 34.2489 11.6102 34.2393 11.6934C34.2297 11.7766 34.2434 11.8608 34.279 11.9366C34.3146 12.0124 34.3705 12.0768 34.4407 12.1226C34.5108 12.1683 34.5923 12.1937 34.676 12.1957Z"
        fill="#4F4F4F"
      />
      <path
        d="M43.0477 12.1317C42.7837 12.0718 42.5234 11.9966 42.2681 11.9064C41.9833 11.8649 41.7203 11.7302 41.5201 11.5234C41.4696 11.4471 41.4457 11.3562 41.4522 11.2649C41.4587 11.1736 41.4953 11.087 41.5562 11.0187C41.6984 10.9011 41.8695 10.8237 42.0517 10.7944C42.2339 10.7651 42.4206 10.785 42.5926 10.852C42.9581 10.9358 43.2794 11.1526 43.4938 11.4603C43.5355 11.5329 43.5561 11.6156 43.5533 11.6993C43.5506 11.7829 43.5246 11.8642 43.4783 11.9339C43.4319 12.0036 43.3671 12.059 43.2911 12.0939C43.215 12.1289 43.1308 12.142 43.0477 12.1317Z"
        fill="#4F4F4F"
      />
      <path
        d="M40.9625 15.6388C40.9625 15.6117 41.0211 15.6838 41.0166 15.7154C40.9129 16.5084 40.931 17.4367 41.6249 17.7702C41.6249 17.7702 41.6249 17.8197 41.6249 17.8152C40.7417 17.6034 40.7417 16.3733 40.9625 15.6388Z"
        fill="#4F4F4F"
      />
      <path
        d="M41.7559 14.8771C43.0447 14.9086 42.8914 17.4816 41.6973 17.4591C40.5032 17.4366 40.6744 14.8546 41.7559 14.8771Z"
        fill="#4F4F4F"
      />
      <path
        d="M42.2495 15.0438C42.4658 15.2015 42.6731 15.4719 42.939 15.5169C43.2048 15.562 43.5067 15.3096 43.7275 15.0663C43.7501 15.0663 43.7681 15.0663 43.7681 15.0979C43.7455 15.5935 43.5293 16.0892 42.993 16.1298C42.4568 16.1703 42.1864 15.7152 42.0918 15.2285C42.0918 15.1114 42.1639 14.9807 42.2495 15.0438Z"
        fill="#4F4F4F"
      />
      <path
        d="M36.0988 15.0711C36.0988 15.0396 36.0312 15.1026 36.0312 15.1342C36.0041 15.9363 35.8374 16.8465 35.0984 17.0628C35.0714 17.0628 35.0714 17.1079 35.0984 17.1079C35.9906 17.0223 36.1979 15.8146 36.0988 15.0711Z"
        fill="#4F4F4F"
      />
      <path
        d="M35.4417 14.1697C34.1665 13.9849 33.8916 16.5534 35.0722 16.7246C36.2528 16.8959 36.5142 14.3274 35.4417 14.1697Z"
        fill="#4F4F4F"
      />
      <path
        d="M34.8827 14.2811C34.6619 14.3938 34.4321 14.6191 34.1933 14.6101C33.9544 14.601 33.7427 14.2991 33.5804 14.0153C33.5804 13.9837 33.5444 14.0153 33.5399 14.0378C33.4723 14.5245 33.5804 15.0562 34.0401 15.2004C34.4997 15.3446 34.8241 14.9435 34.9909 14.4704C35.0314 14.3803 34.9773 14.2361 34.8827 14.2811Z"
        fill="#4F4F4F"
      />
      <path
        d="M39.6991 21.8553C39.5054 22.0401 39.3116 22.306 39.0187 22.306C38.7213 22.2987 38.429 22.2264 38.1625 22.0942C38.1572 22.0931 38.1516 22.0935 38.1464 22.0953C38.1412 22.097 38.1366 22.1001 38.133 22.1042C38.1293 22.1083 38.1268 22.1132 38.1257 22.1186C38.1246 22.1239 38.1248 22.1295 38.1265 22.1347C38.2376 22.2948 38.3904 22.4214 38.5683 22.5008C38.7463 22.5802 38.9425 22.6094 39.1359 22.5853C39.3 22.5459 39.4485 22.4583 39.5625 22.3339C39.6766 22.2095 39.7508 22.0538 39.7757 21.8869C39.7893 21.8283 39.7262 21.8283 39.6991 21.8553Z"
        fill="#4F4F4F"
      />
      <path
        d="M36.5673 18.7395C36.5673 18.7395 36.3194 19.9201 36.2789 20.4834C36.2789 20.533 36.4096 20.569 36.5988 20.5961C36.5988 20.5961 36.5988 20.5961 36.6213 20.5961C37.0973 20.7186 37.5979 20.7073 38.0678 20.5634C38.5377 20.4195 38.9588 20.1485 39.2845 19.7804C39.3295 19.7309 39.2619 19.6678 39.2079 19.6993C38.4567 20.1372 37.5847 20.322 36.7205 20.2266C36.6889 20.0688 37.2117 18.2213 37.126 18.2078C36.6864 18.2143 36.2498 18.281 35.8283 18.4061C36.2789 15.9367 37.1125 13.553 37.5181 11.0881C37.524 11.0696 37.5224 11.0494 37.5135 11.0321C37.5047 11.0148 37.4893 11.0017 37.4708 10.9957C37.4522 10.9898 37.4321 10.9914 37.4148 11.0003C37.3974 11.0091 37.3844 11.0245 37.3784 11.043C36.3194 13.4719 35.7472 16.3062 35.2199 18.8477C35.1478 19.1631 36.3375 18.8206 36.5673 18.7395Z"
        fill="#4F4F4F"
      />
      <path
        d="M36.793 20.387C37.0709 20.855 37.4652 21.2431 37.9375 21.5135C38.199 21.6553 38.4992 21.7091 38.7937 21.6668C39.4381 21.5766 39.5192 21.0089 39.4606 20.5042C39.4286 20.2377 39.365 19.9761 39.2713 19.7246C38.5371 20.2042 37.6686 20.4363 36.793 20.387Z"
        fill="#4F4F4F"
      />
      <path
        d="M37.9375 21.514C38.199 21.6558 38.4992 21.7095 38.7937 21.6672C39.438 21.5771 39.5192 21.0093 39.4606 20.5046C39.1304 20.4786 38.8011 20.5638 38.5251 20.7467C38.249 20.9297 38.0422 21.1998 37.9375 21.514Z"
        fill="#FB7878"
      />
      <path
        d="M50.9285 17.4538C48.2248 17.2601 48.6079 13.0198 48.6079 13.0198C48.6079 13.0198 45.5797 12.4926 45.7645 7.55385C45.7645 7.55385 44.0522 10.4062 39.8705 10.6676C38.9692 10.7262 42.1235 8.69841 42.1235 8.69841C42.1235 8.69841 37.8968 11.4652 31.4755 9.72131C31.0249 9.59964 33.0257 2.92605 38.3339 0.997424C43.6421 -0.931203 48.1437 0.78563 51.0412 4.27338C51.0412 4.27338 54.6461 6.31016 54.7768 9.89704C54.9074 13.4839 51.974 17.5304 50.9285 17.4538Z"
        fill="#4F4F4F"
      />
      <path
        d="M53.0067 15.6517C53.9021 14.1763 54.4214 12.5035 54.5188 10.7804C54.6162 9.05735 54.2889 7.33669 53.5655 5.76976C51.0961 0.263263 42.237 -1.92221 36.3926 1.9756C36.3926 1.9756 46.4413 0.17314 49.8389 4.70631C53.2365 9.23949 53.0067 15.6517 53.0067 15.6517Z"
        fill="#90F6C7"
      />
      <path
        d="M49.4061 18.5866C49.4061 18.5866 52.0287 14.9817 53.6734 15.8829C55.3181 16.7842 53.3084 22.1059 51.3437 22.7953C51.0983 22.9019 50.8338 22.9576 50.5662 22.9593C50.2987 22.9609 50.0335 22.9083 49.7868 22.8047C49.5401 22.7012 49.3169 22.5487 49.1306 22.3566C48.9444 22.1645 48.799 21.9366 48.7031 21.6868L49.4061 18.5866Z"
        fill="#FFB7B4"
      />
      <path
        d="M53.0603 17.4043C53.0919 17.4043 53.0964 17.4449 53.0603 17.4584C51.6815 17.9856 50.9425 19.2608 50.4152 20.5496C50.8298 20.0539 51.3886 19.7835 52.06 20.2792C52.096 20.3063 52.06 20.3603 52.0194 20.3513C51.7799 20.27 51.521 20.2654 51.2787 20.338C51.0364 20.4107 50.8228 20.5571 50.6676 20.7569C50.4116 21.0905 50.1912 21.45 50.0097 21.8293C49.9421 21.951 49.7213 21.8834 49.7709 21.7437V21.7122C49.7844 19.9683 51.1813 17.5485 53.0603 17.4043Z"
        fill="#4F4F4F"
      />
    </SvgIcon>
  );
};
