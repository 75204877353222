import { CodeSystemGroup } from "../codeSystem";

export const codeSystemGroupListMock: CodeSystemGroup[] = [
  {
    system:
      "http://platform.lab-a.com.ar/fhir/StructureDefinition/procedure-report-status-reason-test",
    concept: [
      {
        code: "estado 1",
        display: "display estado 1",
        property: [
          {
            use: "ProcedureReportStatus",
            code: "partial"
          },
          {
            use: "Color",
            code: "#00FF00"
          }
        ]
      },
      {
        code: "estado 2",
        display: "display estado 2 alternativo",
        property: [
          {
            use: "ProcedureReportStatus",
            code: "partial"
          },
          {
            use: "Color",
            code: "#00FFFF"
          }
        ]
      },
      {
        code: "estado 3",
        display: "display estado 3 alternativo",
        property: [
          {
            use: "ProcedureReportStatus",
            code: "partial"
          },
          {
            use: "Color",
            code: "#8F00FF"
          }
        ]
      }
    ]
  },
  {
    system: "performerRoleCodeSystemMock",
    concept: [
      {
        code: "sirugan",
        display: "cirujano",
        property: []
      },
      {
        code: "nursery",
        display: "enfermero",
        property: []
      }
    ]
  }
];
