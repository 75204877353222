import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  FlexJustifyContent,
  flexRowMixin,
  hoverMixin,
  paddingMixin,
  Theme
} from "@laba/react-common";

const msToCssSeconds = (ms: number): string => `${ms / 1000}s`;
interface Props {
  isVisible: boolean;
  transitionMsDuration: number;
}
/**
 * @param isVisible - if false, overlay will use hover to determine visibility
 */
export const useVideoControlsOverlayStyles = createStyle(
  (_theme: Theme) => ({
    root: ({ isVisible, transitionMsDuration }: Props) => ({
      ...flexColumnMixin({ justifyContent: FlexJustifyContent.FlexEnd }),
      opacity: isVisible ? 1 : 0,
      transition: `opacity ${msToCssSeconds(transitionMsDuration)}`
    }),
    desktopRoot: () => ({
      ...hoverMixin({
        opacity: 1
      })
    }),
    buttonsContainer: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.FlexEnd,
        justifyContent: FlexJustifyContent.Center,
        gap: 32,
        wrapContent: true
      }),
      background:
        "linear-gradient(180deg, rgba(79, 79, 79, 0) 0%, rgba(79, 79, 79, 0.4) 46.95%, rgba(79, 79, 79, 0.8) 98.03%);",
      backgroundBlendMode: "multiply",
      overflowY: "auto"
    },
    buttonsContainerDesktop: {
      ...paddingMixin({ bottom: 24 })
    },
    buttonsContainerMobile: {
      ...paddingMixin({ bottom: 72 })
    }
  }),
  "VideoControlsOverlay"
);
