import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  Appointment,
  AppointmentDefinition,
  AppointmentDefinitionListQueryParamsKey,
  AppointmentKey,
  AppointmentListQueryParamsKey,
  AppointmentStatus,
  getFullNameFromPerson,
  HealthcareService,
  HealthcareServiceKey,
  HealthcareServiceListQueryParamsKey,
  OrganizationCombinedType,
  OrganizationListQueryParamsKey,
  Patient,
  PatientListQueryParamsKey,
  Practitioner,
  PractitionerListQueryParamsKey,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import { TextField } from "components/generic/TextField/TextField";
import { ReferenceResourceField } from "components/generic/ReferenceResourceField/ReferenceResourceField";
import { List } from "components/generic/List/List";
import { Datagrid } from "components/generic/Datagrid/Datagrid";
import { appointmentJsonToModel } from "components/pages/NexupAdmin/resources/appointment/AppointmentForm";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { FunctionField } from "components/generic/FunctionField/FunctionField";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { DateTimeInput } from "components/generic/DateTimeInput/DateTimeInput";
import { DateField } from "components/generic/DateField/DateField";
import {
  appointmentDefinitionOptionText,
  healthcareServiceOptionText,
  patientOptionText,
  practitionerOptionText
} from "components/generic/ReferenceResourceInput/optionText";
import { useListExportedFileName } from "components/hook/useExportedFileName";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

const tk = tkCP.adminPage[ResourceType.Appointment];

export const AppointmentList: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const exportedFileName = useListExportedFileName(t(tk.title));
  return (
    <List
      resource={ResourceType.Appointment}
      exportedFileName={exportedFileName}
      jsonToModel={appointmentJsonToModel}
      extraFilters={[
        workspaceOrganizationId === undefined ? (
          <OrganizationReferenceInput
            label={t(tk.filters.organization)}
            source={AppointmentListQueryParamsKey.organization}
            alwaysOn
            type={OrganizationCombinedType.ProviderAndGroup}
            extraFilters={{
              [OrganizationListQueryParamsKey.active]: true
            }}
          />
        ) : undefined,
        <ReferenceResourceInput<Practitioner>
          resourceType={ResourceType.Practitioner}
          label={t(tk.filters.practitioner)}
          source={AppointmentListQueryParamsKey.practitioner}
          optionTextKey={practitionerOptionText}
          alwaysOn
          extraFilters={{
            [PractitionerListQueryParamsKey.organization]:
              workspaceOrganizationId
          }}
        />,
        <ReferenceResourceInput<Patient>
          resourceType={ResourceType.Patient}
          label={t(tk.filters.patient)}
          source={AppointmentListQueryParamsKey.patient}
          optionTextKey={patientOptionText}
          alwaysOn
          extraFilters={{
            [PatientListQueryParamsKey.organization]: workspaceOrganizationId
          }}
        />,
        <EnumSelectorInput
          source={AppointmentListQueryParamsKey.status}
          label={t(tk.filters.status)}
          enumValue={AppointmentStatus}
          alwaysOn
          resettable
        />,
        <DateTimeInput
          source={AppointmentListQueryParamsKey.startStartDate}
          label={t(tk.filters.startStartDate)}
          dateFormat="yyyy-LL-dd'T'HH:mm:ss.SSSZZ"
        />,
        <DateTimeInput
          source={AppointmentListQueryParamsKey.endStartDate}
          label={t(tk.filters.endStartDate)}
          dateFormat="yyyy-LL-dd'T'HH:mm:ss.SSSZZ"
        />,
        <ReferenceResourceInput<HealthcareService>
          resourceType={ResourceType.HealthcareService}
          label={t(tk.filters.healthcareService)}
          source={AppointmentListQueryParamsKey.healthcareService}
          optionTextKey={healthcareServiceOptionText}
          extraFilters={{
            [HealthcareServiceListQueryParamsKey.organization]:
              workspaceOrganizationId
          }}
        />,
        <ReferenceResourceInput<AppointmentDefinition>
          resourceType={ResourceType.AppointmentDefinition}
          label={t(tk.filters.appointmentDefinition)}
          source={AppointmentListQueryParamsKey.appointmentDefinition}
          optionTextKey={appointmentDefinitionOptionText}
          extraFilters={{
            [AppointmentDefinitionListQueryParamsKey.organization]:
              workspaceOrganizationId
          }}
        />
      ]}
    >
      <Datagrid>
        <TextField source={AppointmentKey.id} label={t(tk.fields.id)} />
        <ReferenceResourceField<Practitioner>
          resourceType={ResourceType.Practitioner}
          label={t(tk.fields.practitioner)}
          source={AppointmentKey.practitioner}
          optionTextKey={getFullNameFromPerson}
        />
        <ReferenceResourceField<Patient>
          resourceType={ResourceType.Patient}
          label={t(tk.fields.patient)}
          source={AppointmentKey.patient}
          optionTextKey={getFullNameFromPerson}
        />
        <TextField source={AppointmentKey.status} label={t(tk.fields.status)} />
        <ReferenceResourceField<HealthcareService>
          resourceType={ResourceType.HealthcareService}
          label={t(tk.fields.healthcareService)}
          source={AppointmentKey.healthcareService}
          optionTextKey={HealthcareServiceKey.name}
        />
        <DateField
          source={AppointmentKey.startDate}
          label={t(tk.fields.startDate)}
          showTime
        />
        <TextField
          source={AppointmentKey.minutesDuration}
          label={t(tk.fields.minutesDuration)}
        />
        <FunctionField<Appointment>
          label={t(tk.fields.overbooked)}
          render={appointment =>
            appointment?.overbooked ? t(tk.fields.overbooked) : ""
          }
        />
      </Datagrid>
    </List>
  );
};
