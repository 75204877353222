import { DateTime as LuxonDateTime, Settings } from "luxon";
import { ApiDate, ApiHour } from "date/apiDate";
import { Optional } from "model/types";
import { Zone } from "date/zone";
import { getTimeOfDayFromTimeString } from "date/utils";
import { head, isUndefined } from "lodash-es";

Settings.defaultLocale = "es-AR";

const timezoneToUTCTimezone = (timezone?: string): Optional<string> => {
  if (!timezone) return undefined;
  if (!timezone.includes(":")) return timezone;
  const firstPart = head(timezone.split(":"));
  if (firstPart === undefined) return;
  return `UTC${firstPart}`;
};

const fromApiDate = (
  date: ApiDate,
  useStringZone = false,
  fixedZone: Optional<Zone> = undefined
): DateTime =>
  LuxonDateTime.fromISO(date, { setZone: useStringZone, zone: fixedZone });

const fromApiDateWithTimeZone = (date: ApiDate, timeZone: string): DateTime =>
  LuxonDateTime.fromISO(date, {
    zone: timezoneToUTCTimezone(timeZone)
  });

const fromApiHour = (hour: ApiHour, date: DateTime): DateTime => {
  const tod = getTimeOfDayFromTimeString(hour);
  return date.set({ hour: tod.hour, minute: tod.minute, second: tod.second });
};

const fromApiDateOrUndefined = (
  date?: ApiDate,
  useStringZone = false,
  fixedZone: Optional<Zone> = undefined
): Optional<DateTime> => {
  return date ? fromApiDate(date, useStringZone, fixedZone) : undefined;
};

const fromApiHourOrUndefined = (
  hour?: ApiHour,
  date?: DateTime
): Optional<DateTime> => {
  return !isUndefined(hour) && !isUndefined(date)
    ? fromApiHour(hour, date)
    : undefined;
};

export class DateTime extends LuxonDateTime {
  static fromApiDate: (
    date: ApiDate,
    useStringZone?: boolean,
    fixedZone?: Zone
  ) => DateTime = fromApiDate;

  static fromApiDateWithTimeZone: (
    date: ApiDate,
    timeZone: string
  ) => DateTime = fromApiDateWithTimeZone;

  static fromApiHour: (hour: ApiHour, date: DateTime) => DateTime = fromApiHour;

  static fromApiDateOrUndefined: (
    date?: ApiDate,
    useStringZone?: boolean,
    fixedZone?: Zone
  ) => Optional<DateTime> = fromApiDateOrUndefined;

  static fromApiHourOrUndefined: (
    hour?: ApiHour,
    date?: DateTime
  ) => Optional<DateTime> = fromApiHourOrUndefined;
}
