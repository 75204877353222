import React from "react";
import { FC, StyleVariant, useI18n } from "@laba/react-common";
import { OrganizationKey, ResourceType } from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { ColorInput } from "components/generic/ColorInput/ColorInput";
import { Labeled } from "react-admin";

export const tk = tkCP.adminPage[ResourceType.Organization];
export interface OrganizationWhiteLabelThemeStyleContentProps {
  styleVariant: StyleVariant;
  label: string;
}
export const OrganizationWhiteLabelThemeStyleContent: FC<
  OrganizationWhiteLabelThemeStyleContentProps
> = ({ styleVariant, label }) => {
  const { t } = useI18n();
  return (
    <Labeled label={label} fullWidth>
      <>
        <ColorInput
          source={
            OrganizationKey.whiteLabelConfig.theme.style[styleVariant].color
              .Background
          }
          label={t(tk.fields.whiteLabelConfigThemeStyleBackground)}
        />
        <ColorInput
          source={
            OrganizationKey.whiteLabelConfig.theme.style[styleVariant].color
              .Main
          }
          label={t(tk.fields.whiteLabelConfigThemeStyleMain)}
        />
        <ColorInput
          source={
            OrganizationKey.whiteLabelConfig.theme.style[styleVariant].color
              .Light
          }
          label={t(tk.fields.whiteLabelConfigThemeStyleLight)}
        />
        <ColorInput
          source={
            OrganizationKey.whiteLabelConfig.theme.style[styleVariant].color
              .Dark
          }
          label={t(tk.fields.whiteLabelConfigThemeStyleDark)}
        />
        <ColorInput
          source={
            OrganizationKey.whiteLabelConfig.theme.style[styleVariant].color
              .Contrast
          }
          label={t(tk.fields.whiteLabelConfigThemeStyleContrast)}
        />
        <ColorInput
          source={
            OrganizationKey.whiteLabelConfig.theme.style[styleVariant].color
              .GrayDark
          }
          label={t(tk.fields.whiteLabelConfigThemeStyleGrayDark)}
        />
        <ColorInput
          source={
            OrganizationKey.whiteLabelConfig.theme.style[styleVariant].color
              .GrayMedium
          }
          label={t(tk.fields.whiteLabelConfigThemeStyleGrayMedium)}
        />
        <ColorInput
          source={
            OrganizationKey.whiteLabelConfig.theme.style[styleVariant].color
              .GrayLight
          }
          label={t(tk.fields.whiteLabelConfigThemeStyleGrayLight)}
        />
        <ColorInput
          source={
            OrganizationKey.whiteLabelConfig.theme.style[styleVariant].state
              .disabled
          }
          label={t(tk.fields.whiteLabelConfigThemeStyleDisabled)}
        />
        <ColorInput
          source={
            OrganizationKey.whiteLabelConfig.theme.style[styleVariant].state
              .disabledBackground
          }
          label={t(tk.fields.whiteLabelConfigThemeStyleDisabledBackground)}
        />
      </>
    </Labeled>
  );
};
