import { resourceConceptProviderGetter } from "providers/dataProvider/resourceProvider/utils/resourceConceptProviderGetter";
import {
  getModelReferenceId,
  KnownCodeSystemSystem,
  conceptProcedureBatteryConceptMapper,
  procedureBatteryConceptMapper
} from "@laba/nexup-api";
import { workspaceOrganizationSelector } from "store/workspace/selectors";
import { store } from "store/store";
import { ConceptResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";

export const batteryProcedureConceptProvider = resourceConceptProviderGetter(
  ConceptResourceType.ProcedureBatteryConcept,
  KnownCodeSystemSystem.BatteryProcedureConcept,
  procedureBatteryConceptMapper,
  conceptProcedureBatteryConceptMapper,
  () => ({
    organization: getModelReferenceId(
      workspaceOrganizationSelector(store.getState())
    )
  })
);
