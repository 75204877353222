import {
  ConditionListQueryParams,
  createCondition,
  getCondition,
  getConditionList,
  updateCondition
} from "api/medical/condition";
import {
  Condition,
  ConditionCategory
} from "model/resource/medical/condition/condition";
import { BackendError } from "model/resource/error/error";
import { RequestResponse } from "@laba/ts-common";
import { ApiPageRequestResponse } from "request/types";

export interface PatientProblemListParams extends ConditionListQueryParams {
  category: ConditionCategory.PatientProblem;
}

export const getPatientProblem = getCondition;

export const getPatientProblemList = (
  queryParams: PatientProblemListParams
): Promise<ApiPageRequestResponse<Condition>> => getConditionList(queryParams);

export const updatePatientProblem = (
  data: Condition
): Promise<RequestResponse<Condition, BackendError>> => updateCondition(data);

export const createPatientProblem = (
  data: Condition
): Promise<RequestResponse<Condition, BackendError>> => createCondition(data);
