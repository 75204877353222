import React from "react";
import { FC, getClassName, ThemeStyleColorVariant } from "@laba/react-common";
import { OdontogramGridItemConfig } from "components/odontogram/OdontogramGrid/utils";
import { Divider } from "components/divider/Divider";
import { gridItemMapper } from "components/odontogram/OdontogramGrid/gridItemMapper";
import { useMobileOdontogramGridStylesClassNames } from "./useMobileOdontogramGridStyles";

export interface MobileOdontogramGridProps {
  className?: string;
  firstGroup: OdontogramGridItemConfig[];
  secondGroup: OdontogramGridItemConfig[];
  thirdGroup: OdontogramGridItemConfig[];
  fourthGroup: OdontogramGridItemConfig[];
  isChild?: boolean;
  onClickItem?: (tooth: string) => void;
}

export const MobileOdontogramGrid: FC<MobileOdontogramGridProps> = ({
  className,
  firstGroup,
  secondGroup,
  thirdGroup,
  fourthGroup,
  isChild = false,
  onClickItem
}) => {
  const classes = useMobileOdontogramGridStylesClassNames({ isChild });
  return (
    <div className={getClassName(className, classes.root)}>
      <div className={classes.row}>
        {firstGroup.map(item => gridItemMapper(item, onClickItem))}
      </div>
      <Divider colorVariant={ThemeStyleColorVariant.GrayDark} dashed />
      <div className={classes.row}>
        {secondGroup.map(item => gridItemMapper(item, onClickItem))}
      </div>
      <Divider colorVariant={ThemeStyleColorVariant.GrayDark} />
      <div className={classes.row}>
        {thirdGroup.map(item => gridItemMapper(item, onClickItem))}
      </div>
      <Divider colorVariant={ThemeStyleColorVariant.GrayDark} dashed />
      <div className={classes.row}>
        {fourthGroup.map(item => gridItemMapper(item, onClickItem))}
      </div>
    </div>
  );
};
