import {
  borderMixin,
  createStyle,
  FlexAlignItems,
  flexRowMixin,
  marginMixin,
  pxToRem,
  RadiusVariant,
  StyleVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeRadius, getThemeStyleColor } from "model/themeUtils";

export const useQuantitySelectorStyle = createStyle(
  (theme: Theme) => ({
    quantityContainer: {
      ...borderMixin({
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.GrayLight
        ),
        radius: { all: getThemeRadius(theme, RadiusVariant.Small) },
        side: { all: 1 },
        style: "solid"
      }),
      ...flexRowMixin({ alignItems: FlexAlignItems.Center }),
      height: pxToRem(34),
      minWidth: pxToRem(56),
      width: "fit-content"
    },
    quantityButton: {
      ...marginMixin({ leftRight: 4 }),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    input: {
      width: "fit-content",
      maxWidth: pxToRem(32),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "QuantitySelector"
);
