import React, { useCallback, useState } from "react";
import {
  BeforeInstallPromptEvent,
  FC,
  getClassName,
  StyleVariant,
  TypeVariant
} from "@laba/react-common";
import { Noop } from "@laba/ts-common";
import { useInstallButtonStyles } from "components/buttons/InstallButton/useInstallButtonStyles";
import { DefaultButton } from "components/buttons/DefaultButton/DefaultButton";
import { CloseIcon, PushPin } from "components/icons";
import { Dialog } from "components/popup/Dialog/Dialog";
import { IOsShareIcon } from "components/svg/IOsShareIcon";
import { BaseIconButton } from "../BaseIconButton/BaseIconButton";

export interface InstallButtonProps {
  className?: string;
  isMobile: boolean;
  cancelText: string;
  acceptText: string;
  text: string;
  installEvent?: BeforeInstallPromptEvent;
  showInstallAppButton?: boolean;
  closeInstallAppButton?: Noop;
  isiOSDevice?: boolean;
  iOSDialogButtonText?: string;
  iOSDialogTitle?: string;
  iOSDialogBeforeIconText?: string;
  iOSDialogAfterIconText?: string;
}

// The browser needs to implement 'beforeinstallprompt' event to make this button to work

export const InstallButton: FC<InstallButtonProps> = ({
  className,
  isMobile,
  cancelText,
  acceptText,
  text,
  installEvent,
  isiOSDevice,
  showInstallAppButton,
  closeInstallAppButton,
  iOSDialogButtonText,
  iOSDialogTitle,
  iOSDialogBeforeIconText,
  iOSDialogAfterIconText
}) => {
  const [iOSPopupOpen, setIOSPopupOpen] = useState(false);
  const classes = useInstallButtonStyles({ isMobile, isiOSDevice });

  const open = !isiOSDevice
    ? installEvent !== undefined && showInstallAppButton
    : showInstallAppButton;

  const installHandler = useCallback(async () => {
    if (isiOSDevice) return setIOSPopupOpen(true);
    if (installEvent === undefined) return;
    installEvent.prompt();
    await installEvent.userChoice;
    closeInstallAppButton?.();
  }, [installEvent, closeInstallAppButton, isiOSDevice]);

  const onClose = () => closeInstallAppButton?.();

  const onCloseDialog = () => {
    setIOSPopupOpen(false);
    closeInstallAppButton?.();
  };

  return open ? (
    <div className={getClassName(className, classes.root)}>
      <div className={classes.closeIconAndTextContainer}>
        <BaseIconButton
          Icon={CloseIcon}
          onClick={onClose}
          className={classes.iconButton}
          title={cancelText}
        />
        <p className={classes.text}>{text}</p>
      </div>
      <DefaultButton
        className={classes.acceptButton}
        style={StyleVariant.Primary}
        text={acceptText}
        type={TypeVariant.Inverted}
        onClick={installHandler}
        title={acceptText}
      />
      <Dialog
        isMobile={isMobile}
        open={iOSPopupOpen}
        onClose={onCloseDialog}
        primaryButtonText={iOSDialogButtonText}
        onPrimaryButtonClick={onCloseDialog}
        IconTitle={PushPin}
        textTitle={iOSDialogTitle}
        textSubtitle={
          <p className={classes.textSubtitle}>
            {iOSDialogBeforeIconText}
            <IOsShareIcon className={classes.shareIcon} />
            {iOSDialogAfterIconText}
          </p>
        }
      />
    </div>
  ) : null;
};
