import React, { useCallback, useMemo, useState } from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  ResourceType,
  QuestionnaireKey,
  QuestionnaireCard,
  QuestionnaireCardKey
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { Autocomplete, AutocompleteOptionConfig } from "@laba/nexup-components";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { QuestionnaireFieldFields } from "components/pages/NexupAdmin/resources/questionnaire/QuestionnaireFieldFields";
import { notUndefined } from "@laba/ts-common";

export const tk = tkCP.adminPage[ResourceType.Questionnaire];

export const QuestionnaireFieldElementContent: FC = () => {
  const { t } = useI18n();
  const cardList = useWatchResourceForm<QuestionnaireCard[]>(
    QuestionnaireKey.cards
  );
  const fieldCardList = useMemo(
    () =>
      cardList?.flatMap((card, cardIndex) =>
        card.fields.map((field, fieldIndex) => ({
          linkId: field.linkId,
          field,
          card,
          cardIndex,
          fieldIndex
        }))
      ) || [],
    [cardList]
  );
  const [toEditElement, setToEditElement] = useState(fieldCardList[0]);
  const fieldOptionList = useMemo<
    AutocompleteOptionConfig<typeof toEditElement>[]
  >(
    () =>
      fieldCardList.map(value => {
        const text = `${value.linkId} (${[value.card.title, value.field.title]
          .filter(notUndefined)
          .join("/")})`;
        return {
          value,
          text,
          title: text
        };
      }),
    [fieldCardList]
  );
  const getElementSource = useCallback(
    (source: string) =>
      [
        QuestionnaireKey.cards,
        String(toEditElement?.cardIndex),
        QuestionnaireCardKey.fields,
        String(toEditElement?.fieldIndex),
        source
      ].join("."),
    [toEditElement]
  );
  return (
    <>
      <Autocomplete<typeof toEditElement>
        fullWidth
        value={toEditElement}
        onChange={async value => {
          // set value to undefined for 1 render (await) to dismount and remount properties array input
          await setToEditElement(undefined);
          setToEditElement(value);
        }}
        options={fieldOptionList}
        compareValues={(v1, v2) => v1?.linkId === v2?.linkId}
        clearText={t(tk.autocompleteClearText)}
        closeText={t(tk.autocompleteCloseText)}
        loadingText={t(tk.autocompleteLoadingText)}
        noOptionsText={t(tk.autocompleteNoOptionsText)}
        openText={t(tk.autocompleteOpenText)}
        label={t(tk.autocompleteTitle)}
      />
      {toEditElement !== undefined && (
        <QuestionnaireFieldFields
          fieldType={toEditElement.field.fieldType}
          getSource={getElementSource}
        />
      )}
    </>
  );
};
