import {
  borderMixin,
  createStyle,
  hoverMixin,
  marginMixin,
  pxToRem
} from "@laba/react-common";
import { RadiusVariant, Theme } from "model/theme";
import { ElevationVariant, StyleVariant } from "model/themeVariant";
import {
  getBorderColorByType,
  getGenericButtonBackgroundColor,
  getGenericButtonColor,
  getTypeFromInvertedType,
  getTypeInvertedVariantHoverBackgroundColor,
  TypeInvertedVariant
} from "components/utils/getGenericButtonColors";
import {
  getThemeRadius,
  getThemeStyleState,
  themeElevationMixin
} from "model/themeUtils";

export interface FloatingIconButtonStyleProps {
  style: StyleVariant;
  type: TypeInvertedVariant;
  stackingOrder: number;
  disabled: boolean;
  fixedStyleDisabled: boolean;
  radiusVariant?: RadiusVariant;
}

const FAB_HEIGHT = 48;
const FAB_BOTTOM_POSITION = 32;
const FAB_MARGIN_BETWEEN = 16;

const getButtonPosition = (stackOrder: number): number => {
  return (
    stackOrder * FAB_MARGIN_BETWEEN +
    FAB_BOTTOM_POSITION +
    stackOrder * FAB_HEIGHT
  );
};

export const useFloatingActionButtonStyle = createStyle(
  (theme: Theme) => ({
    root: (props: FloatingIconButtonStyleProps) => ({
      ...themeElevationMixin(
        theme,
        props.type === TypeInvertedVariant.Outlined
          ? ElevationVariant.Elevation0
          : ElevationVariant.Elevation24
      ),
      ...borderMixin({
        style:
          props.type === TypeInvertedVariant.Outlined ? "solid" : undefined,
        color: getBorderColorByType(
          props.type,
          props.style,
          props.disabled,
          theme
        ),
        side: { all: 1 },
        radius: {
          all: props.radiusVariant && getThemeRadius(theme, props.radiusVariant)
        }
      }),
      ...hoverMixin({
        ...borderMixin({
          color: getGenericButtonColor(theme, {
            style: props.style,
            type: getTypeFromInvertedType(props.type)
          })
        }),
        // TODO HIS-7465: make mixin to "desactivate" hover on mobile
        backgroundColor: getTypeInvertedVariantHoverBackgroundColor(
          theme,
          props.style,
          props.type
        ),
        "@media (hover: none)": {
          backgroundColor: getGenericButtonBackgroundColor(theme, {
            style: props.style,
            typeInvertedVariant: props.type,
            type: getTypeFromInvertedType(props.type),
            disabled: props.disabled
          })
        }
      }),
      backgroundColor: getGenericButtonBackgroundColor(theme, {
        style: props.style,
        type: getTypeFromInvertedType(props.type),
        disabled: props.disabled
      }),
      color: getGenericButtonColor(theme, {
        style: props.style,
        type: getTypeFromInvertedType(props.type)
      }),
      height: pxToRem(FAB_HEIGHT),
      position: props.fixedStyleDisabled ? undefined : "fixed",
      bottom: props.fixedStyleDisabled
        ? undefined
        : pxToRem(getButtonPosition(props.stackingOrder)),
      right: props.fixedStyleDisabled ? undefined : pxToRem(32),
      "&:disabled": {
        ...borderMixin({
          side: {
            all: props.type === TypeInvertedVariant.Outlined ? pxToRem(2) : 0
          },
          color: getThemeStyleState(theme, StyleVariant.Primary).disabled
        }),
        backgroundColor: getGenericButtonBackgroundColor(theme, {
          style: props.style,
          type: getTypeFromInvertedType(props.type),
          disabled: props.disabled
        }),
        color: getGenericButtonColor(theme, {
          style: props.style,
          type: getTypeFromInvertedType(props.type),
          disabled: props.disabled
        })
      }
    }),
    icon: {
      ...marginMixin({
        right: pxToRem(8)
      })
    }
  }),
  "FloatingActionButton"
);
