import React from "react";
import { FC } from "@laba/react-common";
import { Create } from "components/generic/Create/Create";
import {
  MedicalDeviceForm,
  medicalDeviceJsonToModel,
  medicalDeviceTransform
} from "components/pages/NexupAdmin/resources/medicalDevice/MedicalDeviceForm";
import { ResourceType } from "@laba/nexup-api";

export const MedicalDeviceCreate: FC = () => {
  return (
    <Create
      resourceTransform={medicalDeviceTransform}
      resource={ResourceType.MedicalDevice}
      jsonToModel={medicalDeviceJsonToModel}
    >
      <MedicalDeviceForm />
    </Create>
  );
};
