import {
  createStyle,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

interface Props {
  textVariant?: TextVariant;
  colorVariant?: ThemeStyleColorVariant;
  styleVariant?: StyleVariant;
}

export const useGenericFieldWrapperTitleComponentStyles = createStyle(
  (theme: Theme) => ({
    text: (props: Props) => ({
      ...themeTextMixin(theme, props.textVariant ?? TextVariant.Body2, 1),
      color: getThemeStyleColor(
        theme,
        props.styleVariant ?? StyleVariant.Primary,
        props.colorVariant ?? ThemeStyleColorVariant.Main
      )
    })
  }),
  "GenericFieldWrapperTitleComponent"
);
