import { InvoiceStatus } from "model/resource";

export const getInvoiceValidInvoiceStatus = (
  invoiceStatus?: InvoiceStatus
): InvoiceStatus[] => {
  if (invoiceStatus !== undefined && invoiceStatus !== InvoiceStatus.Draft) {
    return [
      InvoiceStatus.Balanced,
      InvoiceStatus.Cancelled,
      InvoiceStatus.Issued
    ];
  }
  return [
    InvoiceStatus.Draft,
    InvoiceStatus.Balanced,
    InvoiceStatus.Cancelled,
    InvoiceStatus.Issued
  ];
};
