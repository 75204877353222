import { useEffectSelective } from "hooks/useEffectSelective";

export const useEffectAsync = (
  fn: () => Promise<void>,
  functionalDeps: unknown[] = []
): void => {
  useEffectSelective(
    () => {
      fn();
    },
    functionalDeps,
    [fn]
  );
};

export const useEffectSelectiveAsync = (
  fn: () => Promise<void>,
  functionalDeps: unknown[] = [],
  ignoreDeps: unknown[] = []
): void => {
  useEffectSelective(
    () => {
      fn();
    },
    functionalDeps,
    [fn, ...ignoreDeps]
  );
};
