import { isEqual } from "lodash-es";
import { CountryAreaPhoneCode } from "./countryAreaPhoneCode";
import { fixPhoneNumberOrUndefined } from "./fixPhone";

const getFixedPhoneNumberIgnoringInternalCode = (
  phoneNumber: string,
  countryCode?: CountryAreaPhoneCode
) => fixPhoneNumberOrUndefined(phoneNumber, countryCode, true);

export const arePhoneNumbersEqual = (
  phoneNumber1?: string,
  phoneNumber2?: string,
  countryCode?: CountryAreaPhoneCode
): boolean => {
  const fixedPhoneNumber1 =
    phoneNumber1 &&
    getFixedPhoneNumberIgnoringInternalCode(phoneNumber1, countryCode);
  const fixedPhoneNumber2 =
    phoneNumber2 &&
    getFixedPhoneNumberIgnoringInternalCode(phoneNumber2, countryCode);

  return isEqual(fixedPhoneNumber1, fixedPhoneNumber2);
};
