import { HttpMethod } from "@laba/ts-common";
import { ApiRequestResponse } from "request/types";
import { getIntegrationApiUrl, requestMultipart } from "request/nexupRequest";

export const dosubaDirectoryRequest = async (
  file: File
): Promise<ApiRequestResponse<unknown>> => {
  const data = new FormData();
  data.append("directory", file, file.name);
  return requestMultipart({
    method: HttpMethod.POST,
    url: getIntegrationApiUrl("dosuba/directory"),
    data
  });
};

export const dosubaDirectoryPharmaRequest = async (
  file: File
): Promise<ApiRequestResponse<unknown>> => {
  const data = new FormData();
  data.append("directoryPharmacy", file, file.name);
  return requestMultipart({
    method: HttpMethod.POST,
    url: getIntegrationApiUrl("dosuba/directory"),
    data
  });
};

export const dosubaPatientRequest = async (
  file: File
): Promise<ApiRequestResponse<unknown>> => {
  const data = new FormData();
  data.append("patientRegistry", file, file.name);
  return requestMultipart({
    method: HttpMethod.POST,
    url: getIntegrationApiUrl("dosuba/patient-registry"),
    data
  });
};
