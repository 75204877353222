export const location = {
  title: "Lugares",
  filters: {
    organization: "Organizacion",
    category: "Categoria",
    partOf: "Parte de",
    status: "Estado",
    hisCode: "Codigo",
    physicalType: "Tipo Fisico",
    directoryType: "Tipo de cartilla"
  },
  fields: {
    id: "Id",
    organization: "Organizacion",
    name: "Nombre",
    description: "Descripcion / Nombre Completo",
    status: "Estado",
    partOf: "Parte de",
    category: "Categoria",
    type: "Tipo Fisico",
    hisCode: "Codigo",
    photo: "Imagen",

    reportConfig: "Configuracion de reportes",

    directoryType: "tipo de directorio",
    payerPlanList: "Planes",
    payerPlanListElement: "Plan",
    payerSpecialityList: "Especialidades",
    payerSpecialityListElement: "Especialidad",
    phoneType: "Tipo de telefono",
    phoneValue: "Numero de telefono",
    phoneRole: "Rol de telefono",
    emailType: "Tipo de email",
    emailValue: "Numero de email",
    emailRole: "Rol de email",
    website: "Página Web",

    latitudeLongitude: "Direccion",
    addressStreet: "Calle",
    addressStreetNumber: "Numero",
    addressDirections: "Instrucciones",
    addressCity: "Ciudad",
    addressState: "Povincia",
    addressCountry: "Pais",
    addressPostalCode: "Codigo postal",

    hoursOfOperation: "Horarios",
    hoursOfOperationDaysOfWeek: "Dias de la semana",
    hoursOfOperationDaysOfWeekElement: "Dia",
    hoursOfOperationAllDay: "Todo el dia",
    hoursOfOperationOpeningTime: "Hora de apertura (HH:MM:SS)",
    hoursOfOperationClosingTime: "Hora de cierre (HH:MM:SS)",
    availabilityExceptions: "Horarios extras"
  },
  tabs: {
    location: "Lugar",
    reports: "Reportes",
    directory: "Directorio",
    directoryHours: "Directorio Horarios",
    directoryPosition: "Directorio Lugar"
  }
};
