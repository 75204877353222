import {
  ElevationVariant,
  StyleVariant,
  TextVariant,
  TypeVariant
} from "model/themeVariant";
import {
  getThemeStyleColor,
  getThemeStyleState,
  themeElevationMixin,
  themeTextMixin
} from "model/themeUtils";
import { Theme, ThemeStyleColorVariant } from "model/theme";

import {
  borderMixin,
  borderMixinRadiusAll,
  Color,
  createStyle,
  FlexJustifyContent,
  flexRowMixin,
  getZIndexValue,
  hoverMixin,
  marginMixin,
  paddingMixin,
  pxToRem,
  ZIndexVariant
} from "@laba/react-common";

export interface AutocompleteInputStyleProps {
  disabled: boolean;
  hasError: boolean;
  fullWidth: boolean;
  variant: TypeVariant;
}

export interface AutocompleteStyleProps {
  endIconShouldNotRotate: boolean;
  hasError: boolean;
  showClearable: boolean;
  withoutOptionClassName: boolean;
}

interface AutocompleteStyleClassNames {
  input?: string;
  option?: string;
  popper?: string;
  paper?: string;
  listbox?: string;
  clearIndicator?: string;
  popupIndicator?: string;
  popupIndicatorOpen?: string;
}

const getTextColor = (
  theme: Theme,
  props: AutocompleteInputStyleProps
): Color => {
  if (props.disabled) {
    return getThemeStyleState(theme, StyleVariant.Primary).disabled;
  }
  if (props.hasError) {
    return getThemeStyleColor(theme, StyleVariant.Danger);
  }
  return getThemeStyleColor(
    theme,
    StyleVariant.Primary,
    ThemeStyleColorVariant.GrayMedium
  );
};

const useAutocompleteStaticStyle = createStyle(
  (theme: Theme) => ({
    input: {
      ...themeTextMixin(theme, TextVariant.Body2, 1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayMedium
      )
    },
    option: {
      ...hoverMixin({
        backgroundColor: getThemeStyleState(theme, StyleVariant.Primary).hover
      }),
      ...paddingMixin({ topBottom: pxToRem(15) }),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      ),
      minHeight: pxToRem(48)
    },
    unstyledOption: {
      ...paddingMixin({ all: 0 }),
      minHeight: 0
    },
    popper: {
      ...marginMixin({ top: 8 }),
      zIndex: getZIndexValue(ZIndexVariant.PopoverElevation)
    },
    paper: {
      ...marginMixin({ topBottom: 0 }),
      ...themeElevationMixin(theme, ElevationVariant.Elevation24),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    },
    listbox: {
      ...paddingMixin({ topBottom: 0 })
    }
  }),
  "AutocompleteStaticStyle"
);

const useAutocompleteDynamicStyle = createStyle(
  (theme: Theme) => ({
    clearIndicator: (props: AutocompleteStyleProps) => ({
      visibility: props.showClearable ? "visible" : undefined,
      color: props.hasError
        ? getThemeStyleColor(theme, StyleVariant.Danger)
        : getThemeStyleColor(
            theme,
            StyleVariant.Primary,
            ThemeStyleColorVariant.GrayDark
          )
    }),
    popupIndicator: (props: AutocompleteStyleProps) => ({
      color: props.hasError
        ? getThemeStyleColor(theme, StyleVariant.Danger)
        : getThemeStyleColor(
            theme,
            StyleVariant.Primary,
            ThemeStyleColorVariant.GrayDark
          )
    }),
    popupIndicatorOpen: (props: AutocompleteStyleProps) => ({
      transform: props.endIconShouldNotRotate ? "none" : "rotate(180deg)"
    })
  }),
  "AutocompleteDynamicStyle"
);

export const useAutocompleteStyles = (
  props: AutocompleteStyleProps
): AutocompleteStyleClassNames => {
  const staticClasses = useAutocompleteStaticStyle();
  const dynamicClasses = useAutocompleteDynamicStyle(props);

  return {
    input: staticClasses.input,
    option: props.withoutOptionClassName
      ? staticClasses.unstyledOption
      : staticClasses.option,
    popper: staticClasses.popper,
    paper: staticClasses.paper,
    listbox: staticClasses.listbox,
    clearIndicator: dynamicClasses.clearIndicator,
    popupIndicator: dynamicClasses.popupIndicator,
    popupIndicatorOpen: dynamicClasses.popupIndicatorOpen
  };
};

export const defaultAutocompleteWidth = pxToRem(323);

export const useAutocompleteInputStyle = createStyle(
  (theme: Theme) => ({
    root: (props: AutocompleteInputStyleProps) => ({
      ...borderMixinRadiusAll(pxToRem(2)),
      height: pxToRem(40),
      width: props.fullWidth ? "100%" : defaultAutocompleteWidth,
      backgroundColor:
        props.variant === TypeVariant.Contained
          ? getThemeStyleColor(
              theme,
              StyleVariant.Primary,
              ThemeStyleColorVariant.Contrast
            )
          : undefined
    }),

    focused: (props: AutocompleteInputStyleProps) => ({
      "&$focused $notchedOutline": {
        ...borderMixin({
          side: {
            all: props.variant === TypeVariant.Contained ? 0 : pxToRem(1)
          }
        })
      }
    }),

    input: (props: AutocompleteInputStyleProps) => ({
      color: getTextColor(theme, props)
    }),
    notchedOutline: (props: AutocompleteInputStyleProps) => ({
      ...borderMixin({
        color: props.disabled
          ? getThemeStyleState(theme, StyleVariant.Primary).disabled
          : getThemeStyleColor(
              theme,
              StyleVariant.Primary,
              ThemeStyleColorVariant.GrayMedium
            ),
        side: {
          all: props.variant === TypeVariant.Contained ? 0 : undefined
        },
        style: "solid"
      })
    })
  }),
  "AutocompleteInput"
);

export const useAutocompleteInputLabelStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...themeTextMixin(theme, TextVariant.Body2, 1)
    }
  }),
  "AutocompleteInputLabel"
);

export const useAutocompleteFormHelperTextStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...marginMixin({ leftRight: pxToRem(14) }),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "AutocompleteFormHelperText"
);

export const useAutocompleteEndIconStyle = createStyle(
  (theme: Theme) => ({
    root: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    positionStart: {
      ...marginMixin({
        left: 12,
        right: 8
      })
    }
  }),
  "AutocompleteEndIcon"
);

export const useCustomClickeableOptionStyle = createStyle(
  (_theme: Theme) => ({
    option: {
      ...flexRowMixin({ justifyContent: FlexJustifyContent.FlexStart }),
      ...paddingMixin({ leftRight: 16 }),
      width: "100%"
    },
    noOptions: {
      ...flexRowMixin({ justifyContent: FlexJustifyContent.FlexStart }),
      ...paddingMixin({ all: 16 }),
      width: "100%"
    }
  }),
  "CustomClickeableOption"
);
