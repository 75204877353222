import { appointmentBasePath } from "api/appointment";
import {
  getFrontPatientPortalPublicApiUrl,
  requestAnon
} from "request/nexupRequest";
import { ApiRequestResponse } from "request/types";
import { HttpMethod } from "@laba/ts-common";
import { PublicAppointmentData } from "model/resource";

export const createPatientPublicAppointmentData = (
  data: PublicAppointmentData
): Promise<ApiRequestResponse<PublicAppointmentData>> =>
  requestAnon({
    method: HttpMethod.POST,
    url: getFrontPatientPortalPublicApiUrl(appointmentBasePath),
    data
  });
