import {
  createStyle,
  GridAutoFlow,
  gridContainerMixin,
  gridItemMixin,
  Theme
} from "@laba/react-common";

export const useDetailCheckboxGroupStyle = createStyle(
  (_theme: Theme) => ({
    root: {
      ...gridContainerMixin({
        templateColumn: ["repeat(2, minmax(0, 1fr))"],
        autoFlow: GridAutoFlow.Row,
        gap: 12
      })
    },
    lastChild: {
      ...gridItemMixin({
        columnSpan: 2
      })
    }
  }),
  "DetailCheckboxGroup"
);
