import React, { useRef } from "react";
import { FCC, ReactElementOrNull } from "@laba/react-common";
import { usePopupState } from "material-ui-popup-state/hooks";
import MaterialHoverPopover from "material-ui-popup-state/HoverPopover";
import { PopoverPosition } from "@material-ui/core";
import { Noop } from "@laba/ts-common";
import { isNil, isUndefined } from "lodash-es";
import {
  PopoverOriginHorizontalVariant,
  PopoverOriginVerticalVariant
} from "../Popover/Popover";
import { useHoverPopoverStyles } from "./useHoverPopoverStyles";

interface PopoverOrigin {
  vertical: PopoverOriginVerticalVariant | number;
  horizontal: PopoverOriginHorizontalVariant | number;
}

export interface HoverPopoverProps {
  className?: string;
  PopoverComponent: ReactElementOrNull;
  anchorOrigin?: PopoverOrigin;
  anchorPosition?: PopoverPosition;
  transformOrigin?: PopoverOrigin;
  hidePopoverOnBlur?: boolean;
  popoverDelayMs?: number;
  onClick?: Noop;
  disabled?: boolean;
}

export const HoverPopover: FCC<HoverPopoverProps> = ({
  className,
  PopoverComponent,
  children,
  anchorOrigin = {
    vertical: PopoverOriginVerticalVariant.Bottom,
    horizontal: PopoverOriginHorizontalVariant.Left
  },
  transformOrigin = {
    vertical: PopoverOriginVerticalVariant.Top,
    horizontal: PopoverOriginHorizontalVariant.Left
  },
  hidePopoverOnBlur,
  popoverDelayMs,
  onClick,
  disabled
}) => {
  const classes = useHoverPopoverStyles();
  const popupState = usePopupState({
    variant: "popover",
    popupId: "nexupHoverPopover"
  });
  const divRef = useRef<HTMLDivElement | null>(null);
  const timerRef = useRef<number | null>(null);

  const clear = () => {
    timerRef.current && window.clearTimeout(timerRef.current);
    popupState.setOpen(false);
  };

  return (
    <div
      className={className}
      ref={divRef}
      onMouseEnter={() => {
        if (disabled) return;
        if (isUndefined(popoverDelayMs)) {
          popupState.setOpen(true);
        } else {
          timerRef.current = window.setTimeout(
            () => popupState.setOpen(true),
            popoverDelayMs
          );
        }
      }}
      onMouseLeave={() => {
        clear();
      }}
      onBlur={() => {
        if (hidePopoverOnBlur) {
          clear();
        }
      }}
    >
      {children}
      {isNil(PopoverComponent) ? null : (
        <MaterialHoverPopover
          open={!disabled && popupState.isOpen}
          anchorEl={divRef.current}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          onClick={onClick}
          className={onClick ? classes.pointerCursor : undefined}
          disableAutoFocus
          disableEnforceFocus
        >
          {PopoverComponent}
        </MaterialHoverPopover>
      )}
    </div>
  );
};
