import { CommonStateReturnSelector } from "state/types";
import { CancelElementState } from "state/commonCancelElementState/getCancelElementState";
import { Optional } from "@laba/ts-common";

export interface ReturnCancelElementSelectors<Element, State> {
  cancelElementSelector: CommonStateReturnSelector<Optional<Element>, State>;
  isCancelConfirmPopupSelectorOpen: CommonStateReturnSelector<boolean, State>;
  isCancellingElementSelector: CommonStateReturnSelector<boolean, State>;
}

export const getCancelElementStateSelectors = <Element, State>(
  stateElementListGetter: (state: State) => CancelElementState<Element>
): ReturnCancelElementSelectors<Element, State> => {
  const cancelElementSelector = (state: State): Optional<Element> => {
    return stateElementListGetter(state).selectedElement;
  };

  const isCancelConfirmPopupSelectorOpen = (state: State): boolean => {
    return stateElementListGetter(state).isCancelConfirmElementPopupOpen;
  };

  const isCancellingElementSelector = (state: State): boolean => {
    return stateElementListGetter(state).isCancellingElement;
  };

  return {
    isCancelConfirmPopupSelectorOpen,
    cancelElementSelector,
    isCancellingElementSelector
  };
};
