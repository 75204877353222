import React from "react";
import { FC } from "@laba/react-common";
import { Create } from "components/generic/Create/Create";
import {
  MeasurementGroupDefinitionForm,
  measurementGroupDefinitionJsonToModel,
  measurementGroupDefinitionTransform
} from "components/pages/NexupAdmin/resources/measurementGroupDefinition/MeasurementGroupDefinitionForm";
import { ResourceType } from "@laba/nexup-api";

export const MeasurementGroupDefinitionCreate: FC = () => {
  return (
    <Create
      resourceTransform={measurementGroupDefinitionTransform}
      resource={ResourceType.MeasurementGroupDefinition}
      jsonToModel={measurementGroupDefinitionJsonToModel}
    >
      <MeasurementGroupDefinitionForm />
    </Create>
  );
};
