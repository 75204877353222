import React from "react";
import { ResourceType, ScheduleDefinition } from "@laba/nexup-api";
import { FC } from "@laba/react-common";
import {
  ScheduleDefinitionForm,
  scheduleDefinitionJsonToModel,
  scheduleDefinitionTransform
} from "components/pages/NexupAdmin/resources/scheduleDefinition/ScheduleDefinitionForm";
import { useElementExportedFileName } from "components/hook/useExportedFileName";
import { Edit } from "components/generic/Edit/Edit";

export const ScheduleDefinitionEdit: FC = () => {
  const exportedFileName = useElementExportedFileName<ScheduleDefinition>(
    resource => resource.name
  );
  return (
    <Edit
      resourceTransform={scheduleDefinitionTransform}
      resource={ResourceType.ScheduleDefinition}
      jsonToModel={scheduleDefinitionJsonToModel}
      exportedFileName={exportedFileName}
    >
      <ScheduleDefinitionForm />
    </Edit>
  );
};
