import { ResourceModel, ResourceType } from "model/primitives/resourceModel";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { OpenCode } from "model/primitives/model/model";
import { PhoneConfig } from "model/primitives/phoneConfig";
import { EmailConfig } from "model/primitives/emailConfig";
import { CodeSystemSystem } from "model/resource/entities/codeSystem";
import { getKeyObj, KeyObj, Optional } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { PermissionConfig } from "model/primitives/resorcePermissions/resourcePermissions";
import { Organization } from "../entities/organization/organization";

export enum PublicationStatus {
  Active = "active",
  Retired = "retired"
}

export enum ActivityDefinitionKind {
  Appointment = "Appointment",
  Observation = "Observation",
  MedicalRequest = "MedicalRequest",
  DiagnosticReport = "DiagnosticReport",
  Schedule = "Schedule"
}

export enum ActivityDefinitionConfigurationCodeSystemCode {
  StatusReason = "StatusReason",
  ProcedureCode = "ProcedureCode",
  Category = "Category",
  PerformerRole = "PerformerRole",
  AppointmentTag = "AppointmentTag"
}

export type ConfigurationCodeSystemCode =
  OpenCode<ActivityDefinitionConfigurationCodeSystemCode>;

export interface ActivityDefinitionConfigurationCodeSystem {
  code: ConfigurationCodeSystemCode;
  url: string;
}

export const ActivityDefinitionConfigurationCodeSystemKey =
  getKeyObj<ActivityDefinitionConfigurationCodeSystem>(
    createHydratedMock<ActivityDefinitionConfigurationCodeSystem>()
  );

export enum ActivityDefinitionTemplateCode {
  PatientAppointmentAccessLink = "PatientAppointmentAccessLink",
  ProcedureReportTitle = "ProcedureReportTitle",
  MainText = "MainText",
  SupplementaryText = "SupplementaryText",
  ReportText = "ReportText",
  PrescriptionText = "PrescriptionText",
  PrescriptionPatientInstruction = "PrescriptionPatientInstruction",
  PrescriptionFooterLeftText = "PrescriptionFooterLeftText",
  PrescriptionFooterCenterText = "PrescriptionFooterCenterText",
  PrescriptionQRContent = "PrescriptionQRContent"
}

export interface ActivityDefinitionTemplate {
  code: ActivityDefinitionTemplateCode;
  template: string;
}

export interface ActivityDefinition<
  RType extends ResourceType,
  Kind extends ActivityDefinitionKind = ActivityDefinitionKind
> extends ResourceModel<RType> {
  name?: string;
  type?: string;
  organization?: ModelReference<Organization>;
  status?: PublicationStatus;
  kind?: Kind;
  configurationCodeSystem?: ActivityDefinitionConfigurationCodeSystem[];
  phoneConfig?: PhoneConfig[];
  emailConfig?: EmailConfig[];
  templateList?: ActivityDefinitionTemplate[];
  default?: boolean;
  permissionConfig?: PermissionConfig;
}

export const getConfigurationCodeSystemFromActivityDefinition = <
  RType extends ResourceType
>(
  definition: ActivityDefinition<RType>,
  code: ActivityDefinitionConfigurationCodeSystemCode
): Optional<CodeSystemSystem> => {
  return definition.configurationCodeSystem?.find(
    confCodeSystem => confCodeSystem.code === code
  )?.url;
};

export const ActivityDefinitionKey: KeyObj<ActivityDefinition<ResourceType>> =
  getKeyObj(createHydratedMock<ActivityDefinition<ResourceType>>());

export const ActivityDefinitionTemplateKey = getKeyObj(
  createHydratedMock<ActivityDefinitionTemplate>()
);
