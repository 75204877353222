import React from "react";
import {
  Edit as ReactAdminEdit,
  EditProps as ReactAdminEditProps
} from "react-admin";
import { ReactElement, ReactElementOrNull } from "@laba/react-common";
import { Model } from "@laba/nexup-api";
import {
  ActionMode,
  ResourceActions,
  ResourceActionsProps
} from "components/generic/ResourceAction/ResourceAction";
import {
  ResourceTransform,
  showOnErrorAlert
} from "components/generic/Create/Create";
import { RaResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { DefaultMeta } from "providers/dataProvider/resourceProvider/utils/resourceProviderTypes";

export type EditProps<T extends Model> = Omit<
  ReactAdminEditProps,
  "transform"
> & {
  resourceTransform?: ResourceTransform<T>;
  children: ReactElement;
  resource: RaResourceType;
  extraButtons?: ReactElement;
  meta?: DefaultMeta;
} & Omit<ResourceActionsProps<T>, "resource" | "mode">;

const getMutationOptions = <T extends Model = Model>(
  mutationOption?: EditProps<T>["mutationOptions"],
  meta?: DefaultMeta
): EditProps<T>["mutationOptions"] => ({
  ...mutationOption,
  meta: { ...mutationOption?.meta, ...meta },
  onError: (error: unknown, ...rest): void => {
    showOnErrorAlert(error);
    mutationOption?.onError?.(error, ...rest);
  }
});

export const Edit = <T extends Model>(
  props: EditProps<T>
): ReactElementOrNull => {
  const {
    jsonToModel,
    modelToJson,
    exportedFileName,
    resourceTransform,
    mutationOptions,
    extraButtons,
    meta,
    importElementPreprocess,
    ...editProps
  } = props;
  const actionsProps = {
    jsonToModel,
    modelToJson,
    exportedFileName,
    extraButtons,
    importElementPreprocess,
    mode: ActionMode.Edition,
    resource: props.resource
  };
  return (
    <ReactAdminEdit
      actions={<ResourceActions<T> {...actionsProps} />}
      transform={resourceTransform}
      mutationMode="pessimistic"
      mutationOptions={getMutationOptions<T>(mutationOptions, meta)}
      queryOptions={{ meta: { ...meta } }}
      {...editProps}
    />
  );
};
