export const medication = {
  title: "Medicamentos",
  filters: {
    organization: "Organizacion",
    hisCode: "Codigo",
    genericType: "Tipo generico",
    status: "Estado",

    instanceOf: "Instancia de",
    substance: "Ingrediente",
    characteristic: "Caracteristica",
    form: "Forma farmaceutica",
    functionCode: "Codigo funcional",
    presentationUnit: "Unidad Presentacional",
    route: "Via",
    snomedCode: "Codigo snomed"
  },
  fields: {
    id: "Id",
    organization: "Organizacion",
    name: "Nombre",
    description: "Descripcion (nombre medico)",
    alternativeName: "Nombres alternativos",
    alternativeNameElement: "Nombre alternativo",
    snomedCode: "Codigo snomed",
    status: "Estado",
    identifier: "Identificadores",
    identifierSystem: "Tipo",
    identifierValue: "Valor",
    identifierHisCode: "Codigo",
    functionCode: "Codigo funcional (ej: codigo ATC)",
    genericType: "Tipo de generico",
    instanceOf: "Instancia de (generico asociado)",
    presentationList: "Presentaciones Comerciales",
    presentationListElement: "Presentacion Comercial",
    dilutionRequired: "Requiere dilución",
    endDateRequired: "Requiere fecha fin",
    minUnits: "Unidades minimas",
    maxUnits: "Unidades máximas",

    routeList: "Vias",
    routeListElement: "Via",
    form: "Forma farmaceutica",
    presentationUnit: "Unidad presentacional",
    presentationUnitAmountValue: "Unidad presentacional cantidad",
    presentationUnitAmountUnit: "Unidad presentacional unidad",
    presentationUnitFractions:
      "Unidad presentacional fraccionado (cantidad de partes en la que es divisible)",
    healthcareUnits: "Unidades asistenciales",
    healthcareUnitValue:
      "Cantidad por unidad presentacional (si una pastilla tienen 100 mg va 100)",
    healthcareUnitUnit: "Unidad (si una pastilla tienen 100 mg va mg)",
    disabled: "Deshabilitado",
    characteristicList: "Caracteristicas",
    characteristicListElement: "Caracteristica",

    ingredient: "Ingredientes",
    ingredientSubstance: "Ingrediente",
    ingredientAmountValue: "Cantidad por unidad presentacional",
    ingredientAmountUnit: "Unidad",

    identifierNull: "-"
  },
  tabs: {
    main: "Medicamento",
    medicalInformation: "Informacion medica",
    ingredient: "Ingredientes"
  }
};
