import { Day, ResourceType } from "model/primitives";
import { KnownConceptPropertyUse } from "model/resource/entities";
import { KnownScheduleType } from "model/resource/schedule";
import { PublicScheduleData } from "../publicSchedule";

export const publicScheduleMock: PublicScheduleData = {
  resourceType: ResourceType.PublicSchedule,
  name: "Estudio Blabla",
  location: {
    id: "1",
    name: "Sede Cabildo",
    organization: "10",
    resourceType: ResourceType.PublicLocation
  },
  speciality: [
    {
      code: "Clínica Médica",
      display: "Clínica Médica",
      property: [
        {
          use: KnownConceptPropertyUse.Color,
          code: "#64D6A5"
        }
      ],
      identifier: [],
      designation: []
    }
  ],
  id: "1",
  photo: {
    name: "profile",
    resourceType: ResourceType.Attachment,
    url: "https://development.nexup.com.ar/api/files/signed/63469260da7a4d97b7849b79f5e1b751?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJuZXh1cCIsInJlc291cmNlX3R5cGUiOiJGaWxlIiwicmVzb3VyY2VfaWQiOiI2MzQ2OTI2MGRhN2E0ZDk3Yjc4NDliNzlmNWUxYjc1MSJ9.T3sB98LX91PYtFS6D1CKwRgdZs3S7XqQ-nBqA8I1Iu4"
  },
  organization: {
    resourceType: ResourceType.PublicOrganization,
    name: "Santa Catalina",
    phone: [
      {
        id: "1",
        value: "54 3517078248",
        resourceType: ResourceType.Phone
      }
    ],
    address: {
      country: "Argentina",
      city: "Córdoba",
      postalCode: "5014",
      street: "Jose Patiño 3286",
      streetNumber: "123",
      state: "Arg",
      resourceType: ResourceType.Address
    },
    email: [
      {
        resourceType: ResourceType.Email,
        value: "clinicasantacatalina@gmail.com"
      }
    ],
    website: "santacatalina.com.ar",
    notes:
      "Algun texto largo que hace referencia a las notes de la organization"
  },
  availableTime: [
    {
      daysOfWeek: [Day.Monday, Day.Tuesday, Day.Friday],
      startTime: "06:00:00",
      endTime: "16:00:00"
    }
  ],
  medicalPracticeList: [],
  performerList: [
    {
      resourceType: ResourceType.PublicPractitioner,
      firstName: "Ignacio",
      lastName: "Pedrosa",
      profilePictureUrl:
        "https://img.freepik.com/free-photo/doctor-offering-medical-teleconsultation_23-2149329007.jpg"
    },
    {
      firstName: "Rodrigo",
      lastName: "Odz",
      resourceType: ResourceType.PublicPractitioner
    }
  ],
  type: KnownScheduleType.VideoChat
};
