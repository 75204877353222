import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  PatientForm,
  patientJsonToModel,
  patientTransform
} from "components/pages/NexupAdmin/resources/patient/PatientForm";
import { Edit } from "components/generic/Edit/Edit";
import { Patient, ResourceType } from "@laba/nexup-api";
import { useElementExportedFileName } from "components/hook/useExportedFileName";
import { tkCP } from "translation/i18n";

const tk = tkCP.adminPage[ResourceType.Patient];

export const PatientEdit: FC = () => {
  const { t } = useI18n();
  const exportedFileName = useElementExportedFileName<Patient>(() =>
    t(tk.title)
  );
  return (
    <Edit
      resourceTransform={patientTransform}
      resource={ResourceType.Patient}
      jsonToModel={patientJsonToModel}
      exportedFileName={exportedFileName}
    >
      <PatientForm />
    </Edit>
  );
};
