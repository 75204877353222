import {
  borderMixin,
  Color,
  createStyle,
  ElevationVariant,
  FlexAlignItems,
  flexRowMixin,
  getClassName,
  marginMixin,
  paddingMixin,
  StyleVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor, themeElevationMixin } from "model/themeUtils";

export const useAccordionComponentListItemStaticStyles = createStyle(
  (theme: Theme) => {
    return {
      rootChildren: {
        ...paddingMixin({ leftRight: 16, topBottom: 12 }),
        ...flexRowMixin({ gap: 16, alignItems: FlexAlignItems.Center }),
        backgroundColor: getThemeStyleColor(
          theme,
          StyleVariant.BlackWhite,
          ThemeStyleColorVariant.Contrast
        ),

        width: "100%"
      },
      root: {
        ...themeElevationMixin(theme, ElevationVariant.Elevation24)
      },
      arrowButton: {
        ...marginMixin({ left: "auto" })
      }
    };
  },
  "AccordionComponentListItem"
);

export const useAccordionComponentListItemDynamicStyles = createStyle(
  (_theme: Theme) => {
    return {
      border: (props: AccordionComponentListItemDynamicStylesProps) => ({
        ...borderMixin({
          style: "solid",
          color: props.borderColor,
          side: { bottom: 0, top: 0, right: 0, left: 8 }
        })
      })
    };
  },
  "AccordionComponentListItem"
);

interface AccordionComponentListItemDynamicStylesProps {
  borderColor?: Color;
}

export interface AccordionComponentListItemDynamicStylesReturn {
  root: string;
  rootChildren: string;
  arrowButton: string;
}

export const useAccordionComponentListItemStyles = (
  props: AccordionComponentListItemDynamicStylesProps
): AccordionComponentListItemDynamicStylesReturn => {
  const classes = useAccordionComponentListItemStaticStyles();
  const dynamicClasses = useAccordionComponentListItemDynamicStyles(props);
  return {
    root: classes.root,
    rootChildren: getClassName(
      classes.rootChildren,
      props.borderColor ? dynamicClasses.border : undefined
    ),
    arrowButton: classes.arrowButton
  };
};
