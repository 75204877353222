import React from "react";
import { FC, ReactElementOrNull, useI18n } from "@laba/react-common";
import {
  CodeSystemGroup,
  ConsumptionQuestionnaireHideFieldPropertyValue,
  EncounterClass,
  EncounterStatus,
  getModelReferenceId,
  KnownCodeSystemSystem,
  KnownConceptPropertyUse,
  KnownDiagnosisRole,
  MedicationGenericType,
  ModelId,
  ModelReference,
  Organization,
  ProcedureQuestionnaireHideFieldPropertyValue,
  Questionnaire,
  QuestionnaireDateSpecificValues,
  QuestionnaireEnableWhenBehavior,
  QuestionnaireFieldKey,
  QuestionnaireFieldPropertyBase,
  QuestionnaireFieldPropertyKey,
  QuestionnaireFieldPropertyType,
  QuestionnaireFieldSize,
  QuestionnaireFieldType,
  QuestionnaireFieldValueKey,
  QuestionnaireFieldValuePropertyHospitalizationUseAction,
  QuestionnaireItemEnableWhen,
  QuestionnaireKey,
  ResourceType,
  SearchableListSelectedOptionTypeResponsePropertyValue
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { TextInput } from "components/generic/TextInput/TextInput";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { DateInput } from "components/generic/DateInput/DateInput";
import { DateTimeInput } from "components/generic/DateTimeInput/DateTimeInput";
import { NumberInput } from "components/generic/NumberInput/TextInput";
import { FormDataConsumer } from "components/generic/FormDataConsumer/FormDataConsumer";
import { ColorInput } from "components/generic/ColorInput/ColorInput";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { QuestionnaireItemEnableWhenComponent } from "./common/QuestionnaireItemEnableWhenComponent/QuestionnaireItemEnableWhenComponent";

export const tk = tkCP.adminPage[ResourceType.Questionnaire];

interface InitialValueProps {
  fieldType?: QuestionnaireFieldType;
  getSource: (source: string) => string;
}

const InitialValue = ({
  fieldType,
  getSource
}: InitialValueProps): ReactElementOrNull => {
  const { t } = useI18n();
  switch (fieldType) {
    case QuestionnaireFieldType.TextField:
      return (
        <TextInput
          source={getSource(QuestionnaireFieldValueKey.valueString)}
          label={t(tk.fields.fieldInitialValueElement)}
          multiline
        />
      );
    case QuestionnaireFieldType.SearchableList:
    case QuestionnaireFieldType.Selector:
      return (
        <TextInput
          source={getSource(QuestionnaireFieldValueKey.valueCoding)}
          label={t(tk.fields.fieldInitialValueElement)}
        />
      );
    case QuestionnaireFieldType.Checkbox:
      return (
        <BooleanInput
          source={getSource(QuestionnaireFieldValueKey.valueBoolean)}
          label={t(tk.fields.fieldInitialValueElement)}
        />
      );
    case QuestionnaireFieldType.Date:
      return (
        <>
          <DateInput
            source={getSource(QuestionnaireFieldValueKey.valueDate)}
            label={t(tk.fields.fieldInitialValueElementDate)}
          />
          <EnumSelectorInput
            source={getSource(QuestionnaireFieldValueKey.valueCoding)}
            label={t(tk.fields.fieldInitialValueElementDateCode)}
            enumValue={QuestionnaireDateSpecificValues}
          />
        </>
      );
    case QuestionnaireFieldType.Datetime:
      return (
        <>
          <DateTimeInput
            source={getSource(QuestionnaireFieldValueKey.valueDateTime)}
            label={t(tk.fields.fieldInitialValueElementDate)}
          />
          <EnumSelectorInput
            source={getSource(QuestionnaireFieldValueKey.valueCoding)}
            label={t(tk.fields.fieldInitialValueElementDateCode)}
            enumValue={QuestionnaireDateSpecificValues}
          />
        </>
      );
    case QuestionnaireFieldType.Numeric:
      return (
        <>
          <NumberInput
            source={getSource(
              QuestionnaireFieldValueKey.valueQuantity.quantity
            )}
            label={t(tk.fields.fieldInitialValueElementNumericValue)}
          />
          <TextInput
            source={getSource(QuestionnaireFieldValueKey.valueQuantity.unit)}
            label={t(tk.fields.fieldInitialValueElementNumericUnit)}
          />
        </>
      );
    case QuestionnaireFieldType.ScaleScore:
      return (
        <>
          <NumberInput
            source={getSource(QuestionnaireFieldValueKey.valueDecimal)}
            label={t(tk.fields.fieldInitialValueElementScaleScoreValue)}
          />
          <TextInput
            source={getSource(QuestionnaireFieldValueKey.valueString)}
            label={t(tk.fields.fieldInitialValueElementScaleScoreComment)}
          />
        </>
      );
    case QuestionnaireFieldType.Odontogram:
    case QuestionnaireFieldType.FileList:
    case QuestionnaireFieldType.AllergyList:
    case QuestionnaireFieldType.Measurement:
    case QuestionnaireFieldType.ProblemList:
    case QuestionnaireFieldType.EncounterSelector:
    case QuestionnaireFieldType.Whitespace:
    case QuestionnaireFieldType.DisplayText:
    case QuestionnaireFieldType.CategoryResult:
    default:
      return null;
  }
};

const getFieldTypePropertyTypeList = (
  fieldType?: QuestionnaireFieldType
): QuestionnaireFieldPropertyType[] => {
  switch (fieldType) {
    case QuestionnaireFieldType.Whitespace:
      return [QuestionnaireFieldPropertyType.NumLines];
    case QuestionnaireFieldType.DisplayText:
      return [QuestionnaireFieldPropertyType.Text];
    case QuestionnaireFieldType.TextField:
      return [
        QuestionnaireFieldPropertyType.SingleLine,
        QuestionnaireFieldPropertyType.Placeholder
      ];
    case QuestionnaireFieldType.Selector:
      return [
        QuestionnaireFieldPropertyType.Option,
        QuestionnaireFieldPropertyType.Placeholder,
        QuestionnaireFieldPropertyType.OptionSystem
      ];
    case QuestionnaireFieldType.Checkbox:
      return [QuestionnaireFieldPropertyType.Label];

    case QuestionnaireFieldType.Numeric:
      return [
        QuestionnaireFieldPropertyType.Unit,
        QuestionnaireFieldPropertyType.MinValue,
        QuestionnaireFieldPropertyType.MaxValue,
        QuestionnaireFieldPropertyType.Step,
        QuestionnaireFieldPropertyType.Placeholder,
        QuestionnaireFieldPropertyType.UnitSystem
      ];
    case QuestionnaireFieldType.Date:
      return [
        QuestionnaireFieldPropertyType.MinDate,
        QuestionnaireFieldPropertyType.MaxDate,
        QuestionnaireFieldPropertyType.Placeholder,
        QuestionnaireFieldPropertyType.IsEffectiveDate
      ];
    case QuestionnaireFieldType.Datetime:
      return [
        QuestionnaireFieldPropertyType.MinDate,
        QuestionnaireFieldPropertyType.MaxDate,
        QuestionnaireFieldPropertyType.Placeholder,
        QuestionnaireFieldPropertyType.IsEffectiveDate
      ];
    case QuestionnaireFieldType.SearchableList:
      return [
        QuestionnaireFieldPropertyType.Option,
        QuestionnaireFieldPropertyType.MinElements,
        QuestionnaireFieldPropertyType.MaxElements,
        QuestionnaireFieldPropertyType.OptionSystem,
        QuestionnaireFieldPropertyType.CreateOption,
        QuestionnaireFieldPropertyType.CreateOptionPermission,
        QuestionnaireFieldPropertyType.CreateOptionPropertyUse,
        QuestionnaireFieldPropertyType.SelectedOptionType
      ];
    case QuestionnaireFieldType.FileList:
      return [
        QuestionnaireFieldPropertyType.MinElements,
        QuestionnaireFieldPropertyType.MaxElements,
        QuestionnaireFieldPropertyType.FileType,
        QuestionnaireFieldPropertyType.MaxFileSize
      ];
    case QuestionnaireFieldType.ScaleScore:
      return [
        QuestionnaireFieldPropertyType.Score,
        QuestionnaireFieldPropertyType.ScoreColor,
        QuestionnaireFieldPropertyType.Placeholder
      ];
    case QuestionnaireFieldType.AllergyList:
      return [];
    case QuestionnaireFieldType.ProblemList:
      return [
        QuestionnaireFieldPropertyType.MinElements,
        QuestionnaireFieldPropertyType.MaxElements,
        QuestionnaireFieldPropertyType.CreateProblem,
        QuestionnaireFieldPropertyType.SolveProblem,
        QuestionnaireFieldPropertyType.RelateProblem,
        QuestionnaireFieldPropertyType.RelateSolvedProblem,
        QuestionnaireFieldPropertyType.HospitalizationUse,
        QuestionnaireFieldPropertyType.HospitalizationUseAction,
        QuestionnaireFieldPropertyType.ConditionSystem
      ];
    case QuestionnaireFieldType.CategoryResult:
      return [
        QuestionnaireFieldPropertyType.ItemLinkId,
        QuestionnaireFieldPropertyType.Precision,
        QuestionnaireFieldPropertyType.ScoreRangeMax,
        QuestionnaireFieldPropertyType.ScoreColor
      ];
    case QuestionnaireFieldType.Measurement:
      return [
        QuestionnaireFieldPropertyType.Unit,
        QuestionnaireFieldPropertyType.Type,
        QuestionnaireFieldPropertyType.MinValue,
        QuestionnaireFieldPropertyType.MaxValue,
        QuestionnaireFieldPropertyType.Step,
        QuestionnaireFieldPropertyType.UnitSystem
      ];
    case QuestionnaireFieldType.EncounterSelector:
      return [
        QuestionnaireFieldPropertyType.Class,
        QuestionnaireFieldPropertyType.Status,
        QuestionnaireFieldPropertyType.TimeWindow,
        QuestionnaireFieldPropertyType.ResponseEncounter,
        QuestionnaireFieldPropertyType.CurrentPractitioner
      ];
    case QuestionnaireFieldType.ProcedureReport:
      return [
        QuestionnaireFieldPropertyType.Definition,
        QuestionnaireFieldPropertyType.Category,
        QuestionnaireFieldPropertyType.HideField,
        QuestionnaireFieldPropertyType.TimeWindow,
        QuestionnaireFieldPropertyType.StatusReason
      ];
    case QuestionnaireFieldType.DeviceList:
      return [
        QuestionnaireFieldPropertyType.MinElements,
        QuestionnaireFieldPropertyType.MaxElements,
        QuestionnaireFieldPropertyType.RecommendedTag,
        QuestionnaireFieldPropertyType.FilterTag
      ];

    case QuestionnaireFieldType.MedicationList:
      return [
        QuestionnaireFieldPropertyType.MinElements,
        QuestionnaireFieldPropertyType.MaxElements,
        QuestionnaireFieldPropertyType.Characteristic,
        QuestionnaireFieldPropertyType.GenericType,
        QuestionnaireFieldPropertyType.Unit,
        QuestionnaireFieldPropertyType.UnitSystem
      ];
    case QuestionnaireFieldType.Consumption:
      return [
        QuestionnaireFieldPropertyType.MinElements,
        QuestionnaireFieldPropertyType.MaxElements,
        QuestionnaireFieldPropertyType.TimeWindow,
        QuestionnaireFieldPropertyType.Category,
        QuestionnaireFieldPropertyType.SubCategory,
        QuestionnaireFieldPropertyType.Tag,
        QuestionnaireFieldPropertyType.HideField
      ];
    case QuestionnaireFieldType.Odontogram:
      return [];
  }
  return Object.values(QuestionnaireFieldPropertyType);
};

type HideFieldPropertyValue =
  | ProcedureQuestionnaireHideFieldPropertyValue
  | ConsumptionQuestionnaireHideFieldPropertyValue;

const getFieldTypeHideFieldPropertyList = (
  fieldType?: QuestionnaireFieldType
): HideFieldPropertyValue[] => {
  switch (fieldType) {
    case QuestionnaireFieldType.ProcedureReport:
      return Object.values(ProcedureQuestionnaireHideFieldPropertyValue);
    case QuestionnaireFieldType.Consumption:
      return Object.values(ConsumptionQuestionnaireHideFieldPropertyValue);
  }
  return [];
};

interface PropertyValueProps {
  propertyType?: QuestionnaireFieldPropertyType;
  getSource: (source: string) => string;
  systemCodeSystem?: CodeSystemGroup;
  unitCodeSystem?: CodeSystemGroup;
  measurementTypeCodeSystem?: CodeSystemGroup;
  procedureReportCategoryCodeSystem?: CodeSystemGroup;
  procedureReportStatusReasonCodeSystem?: CodeSystemGroup;
  deviceTagCodeSystem?: CodeSystemGroup;
  medicationCharacteristicCodeSystem?: CodeSystemGroup;
  organizationId?: ModelId;
  fieldType?: QuestionnaireFieldType;
}

const PropertyValue = ({
  propertyType,
  getSource,
  systemCodeSystem,
  unitCodeSystem,
  measurementTypeCodeSystem,
  procedureReportCategoryCodeSystem,
  procedureReportStatusReasonCodeSystem,
  deviceTagCodeSystem,
  medicationCharacteristicCodeSystem,
  organizationId,
  fieldType
}: PropertyValueProps): ReactElementOrNull => {
  const { t } = useI18n();
  const source = getSource(QuestionnaireFieldPropertyKey.value);
  switch (propertyType) {
    case QuestionnaireFieldPropertyType.CreateProblem:
    case QuestionnaireFieldPropertyType.RelateProblem:
    case QuestionnaireFieldPropertyType.RelateSolvedProblem:
    case QuestionnaireFieldPropertyType.SingleLine:
    case QuestionnaireFieldPropertyType.SolveProblem:
    case QuestionnaireFieldPropertyType.ResponseEncounter:
    case QuestionnaireFieldPropertyType.CurrentPractitioner:
    case QuestionnaireFieldPropertyType.CreateOption:
    case QuestionnaireFieldPropertyType.IsEffectiveDate:
      return (
        <BooleanInput<string>
          source={source}
          label={t(tk.fields.fieldPropertyValueEnable)}
          format={formValue => formValue === "true"}
          parse={selectValue => (selectValue === true ? "true" : "false")}
        />
      );
    case QuestionnaireFieldPropertyType.FileType:
      return (
        <TextInput
          source={source}
          label={t(tk.fields.fieldPropertyValueFieldType)}
        />
      );
    case QuestionnaireFieldPropertyType.MaxDate:
    case QuestionnaireFieldPropertyType.MinDate:
      return (
        <>
          <DateInput
            source={source}
            label={t(tk.fields.fieldPropertyValueDate)}
          />
          <EnumSelectorInput
            source={source}
            label={t(tk.fields.fieldPropertyValueDateCode)}
            enumValue={QuestionnaireDateSpecificValues}
          />
        </>
      );
    case QuestionnaireFieldPropertyType.MaxElements:
    case QuestionnaireFieldPropertyType.MaxFileSize:
    case QuestionnaireFieldPropertyType.MaxValue:
    case QuestionnaireFieldPropertyType.MinElements:
    case QuestionnaireFieldPropertyType.MinValue:
    case QuestionnaireFieldPropertyType.NumLines:
    case QuestionnaireFieldPropertyType.Precision:
    case QuestionnaireFieldPropertyType.Score:
    case QuestionnaireFieldPropertyType.ScoreRangeMax:
    case QuestionnaireFieldPropertyType.Step:
      return (
        <NumberInput
          source={source}
          label={t(tk.fields.fieldPropertyValue)}
          min={0}
          step={1}
        />
      );
    case QuestionnaireFieldPropertyType.TimeWindow:
      return (
        <NumberInput
          source={source}
          label={t(tk.fields.fieldPropertyValueWindowMseg)}
          min={0}
          step={1}
        />
      );
    case QuestionnaireFieldPropertyType.Category:
      return (
        <CodeSystemSelectorInput
          source={source}
          label={t(tk.fields.fieldPropertyValue)}
          codeSystem={procedureReportCategoryCodeSystem}
          organizationId={organizationId}
          withCreate
        />
      );
    case QuestionnaireFieldPropertyType.StatusReason:
      return (
        <CodeSystemSelectorInput
          source={source}
          label={t(tk.fields.fieldPropertyValue)}
          codeSystem={procedureReportStatusReasonCodeSystem}
          organizationId={organizationId}
          withCreate
        />
      );
    case QuestionnaireFieldPropertyType.HideField:
      return (
        <EnumSelectorInput
          source={source}
          label={t(tk.fields.fieldPropertyValue)}
          enumValue={getFieldTypeHideFieldPropertyList(fieldType)}
        />
      );
    case QuestionnaireFieldPropertyType.GenericType:
      return (
        <EnumSelectorInput
          source={source}
          label={t(tk.fields.fieldPropertyValue)}
          enumValue={MedicationGenericType}
        />
      );
    case QuestionnaireFieldPropertyType.Characteristic:
      return (
        <CodeSystemSelectorInput
          source={source}
          label={t(tk.fields.fieldPropertyValue)}
          codeSystem={medicationCharacteristicCodeSystem}
          organizationId={organizationId}
          withCreate
        />
      );
    case QuestionnaireFieldPropertyType.FilterTag:
    case QuestionnaireFieldPropertyType.RecommendedTag:
      return (
        <CodeSystemSelectorInput
          source={source}
          label={t(tk.fields.fieldPropertyValue)}
          codeSystem={deviceTagCodeSystem}
          organizationId={organizationId}
          withCreate
        />
      );
    case QuestionnaireFieldPropertyType.ScoreColor:
      return (
        <ColorInput source={source} label={t(tk.fields.fieldPropertyValue)} />
      );
    case QuestionnaireFieldPropertyType.OptionSystem:
    case QuestionnaireFieldPropertyType.UnitSystem:
    case QuestionnaireFieldPropertyType.ConditionSystem:
      return (
        <CodeSystemSelectorInput
          source={source}
          label={t(tk.fields.fieldPropertyValue)}
          codeSystem={systemCodeSystem}
          organizationId={organizationId}
          withCreate
        />
      );
    case QuestionnaireFieldPropertyType.Unit:
      return (
        <CodeSystemSelectorInput
          source={source}
          label={t(tk.fields.fieldPropertyValue)}
          codeSystem={unitCodeSystem}
          organizationId={organizationId}
          withCreate
        />
      );
    case QuestionnaireFieldPropertyType.Type:
      return (
        <CodeSystemSelectorInput
          source={source}
          label={t(tk.fields.fieldPropertyValue)}
          codeSystem={measurementTypeCodeSystem}
          organizationId={organizationId}
          withCreate
        />
      );
    case QuestionnaireFieldPropertyType.Class:
      return (
        <EnumSelectorInput
          source={source}
          label={t(tk.fields.fieldPropertyValue)}
          enumValue={EncounterClass}
        />
      );
    case QuestionnaireFieldPropertyType.Status:
      return (
        <EnumSelectorInput
          source={source}
          label={t(tk.fields.fieldPropertyValue)}
          enumValue={EncounterStatus}
        />
      );
    case QuestionnaireFieldPropertyType.HospitalizationUse:
      return (
        <EnumSelectorInput
          source={source}
          label={t(tk.fields.fieldPropertyValue)}
          enumValue={KnownDiagnosisRole}
          withCreate
        />
      );
    case QuestionnaireFieldPropertyType.HospitalizationUseAction:
      return (
        <EnumSelectorInput
          source={source}
          label={t(tk.fields.fieldPropertyValue)}
          enumValue={QuestionnaireFieldValuePropertyHospitalizationUseAction}
          withCreate
        />
      );
    case QuestionnaireFieldPropertyType.Text:
    case QuestionnaireFieldPropertyType.Placeholder:
    case QuestionnaireFieldPropertyType.Label:
      return (
        <TextInput
          source={source}
          label={t(tk.fields.fieldPropertyValue)}
          multiline
        />
      );
    case QuestionnaireFieldPropertyType.SelectedOptionType:
      return (
        <EnumSelectorInput
          source={source}
          label={t(tk.fields.fieldPropertyValue)}
          enumValue={SearchableListSelectedOptionTypeResponsePropertyValue}
        />
      );
    case QuestionnaireFieldPropertyType.CreateOptionPropertyUse:
      return (
        <EnumSelectorInput
          source={source}
          label={t(tk.fields.fieldPropertyValue)}
          enumValue={KnownConceptPropertyUse}
        />
      );
    case QuestionnaireFieldPropertyType.ItemLinkId:
    case QuestionnaireFieldPropertyType.Option:
    default:
      return (
        <TextInput source={source} label={t(tk.fields.fieldPropertyValue)} />
      );
  }
};

export interface QuestionnaireFieldFieldsProps {
  fieldType?: QuestionnaireFieldType;
  getSource?: (source: string) => string;
}

export const QuestionnaireFieldFields: FC<QuestionnaireFieldFieldsProps> = ({
  getSource = value => value,
  fieldType
}) => {
  const { t } = useI18n();
  const organization = useWatchResourceForm<ModelReference<Organization>>(
    QuestionnaireKey.organization
  );
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organizationId =
    getModelReferenceId(organization) || workspaceOrganizationId;
  const systemSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.CodeSystemSystem,
    organizationId
  );
  const unitCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicationUnit,
    organizationId
  );
  const measurementTypeCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.ObservationCode,
    organizationId
  );
  const procedureReportCategoryCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.ProcedureReportCategory,
    organizationId
  );
  const procedureReportStatusReasonCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.ProcedureReportStatusReason,
    organizationId
  );
  const deviceTagCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicalDeviceTag,
    organizationId
  );
  const medicationCharacteristicCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.MedicationCharacteristic,
    organizationId
  );

  return (
    <>
      <TextInput
        source={getSource(QuestionnaireFieldKey.linkId)}
        label={t(tk.fields.fieldLinkId)}
        required
      />
      <TextInput
        source={getSource(QuestionnaireFieldKey.title)}
        label={t(tk.fields.fieldTitle)}
      />
      <EnumSelectorInput
        source={getSource(QuestionnaireFieldKey.fieldType)}
        label={t(tk.fields.fieldType)}
        enumValue={QuestionnaireFieldType}
        required
      />
      <EnumSelectorInput
        source={getSource(QuestionnaireFieldKey.size)}
        label={t(tk.fields.fieldSize)}
        enumValue={QuestionnaireFieldSize}
        required
      />
      <EnumSelectorInput
        source={getSource(QuestionnaireFieldKey.detailSize)}
        label={t(tk.fields.fieldDetailSize)}
        enumValue={QuestionnaireFieldSize}
      />
      <EnumSelectorInput
        source={getSource(QuestionnaireFieldKey.reportSize)}
        label={t(tk.fields.fieldReportSize)}
        enumValue={QuestionnaireFieldSize}
      />
      <EnumSelectorInput
        source={getSource(QuestionnaireFieldKey.enableBehavior)}
        label={t(tk.fields.enableBehavior)}
        enumValue={QuestionnaireEnableWhenBehavior}
      />
      <ArrayInput
        source={getSource(QuestionnaireFieldKey.enableWhen)}
        label={t(tk.fields.enableWhen)}
      >
        <FormDataConsumer<Questionnaire, QuestionnaireItemEnableWhen>>
          {({
            getSource: getItemEnableWhenSource,
            formData,
            scopedFormData
          }) => {
            return (
              <QuestionnaireItemEnableWhenComponent
                getSource={getItemEnableWhenSource}
                questionnaire={formData}
                qItemEnableWhen={scopedFormData}
              />
            );
          }}
        </FormDataConsumer>
      </ArrayInput>
      <BooleanInput
        source={getSource(QuestionnaireFieldKey.required)}
        label={t(tk.fields.fieldRequired)}
      />
      <ArrayInput
        source={getSource(QuestionnaireFieldKey.property)}
        label={t(tk.fields.fieldPropertyList)}
      >
        <EnumSelectorInput
          source={QuestionnaireFieldPropertyKey.type}
          label={t(tk.fields.fieldPropertyType)}
          enumValue={QuestionnaireFieldPropertyType}
          optionValue={getFieldTypePropertyTypeList(fieldType)}
          withCreate
        />
        <FormDataConsumer<
          Questionnaire,
          QuestionnaireFieldPropertyBase<QuestionnaireFieldPropertyType>
        >>
          {({ getSource: getPropertySource, scopedFormData: property }) => {
            return (
              <PropertyValue
                propertyType={property?.type}
                getSource={getPropertySource}
                systemCodeSystem={systemSystem}
                unitCodeSystem={unitCodeSystem}
                procedureReportCategoryCodeSystem={
                  procedureReportCategoryCodeSystem
                }
                measurementTypeCodeSystem={measurementTypeCodeSystem}
                procedureReportStatusReasonCodeSystem={
                  procedureReportStatusReasonCodeSystem
                }
                deviceTagCodeSystem={deviceTagCodeSystem}
                medicationCharacteristicCodeSystem={
                  medicationCharacteristicCodeSystem
                }
                organizationId={organizationId}
                fieldType={fieldType}
              />
            );
          }}
        </FormDataConsumer>
      </ArrayInput>
      <ArrayInput
        source={getSource(QuestionnaireFieldKey.initialValue)}
        label={t(tk.fields.fieldInitialValue)}
      >
        <FormDataConsumer>
          {({ getSource: getInitialValueSource }) => {
            return (
              <InitialValue
                fieldType={fieldType}
                getSource={getInitialValueSource}
              />
            );
          }}
        </FormDataConsumer>
      </ArrayInput>
    </>
  );
};
