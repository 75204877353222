import { KnownCodeSystemSystem, ModelId } from "@laba/nexup-api";
import { ReactElement, useI18n } from "@laba/react-common";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import React from "react";
import { tkCC } from "translation/i18n";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";

export interface PermissionSpecialityListInputProps {
  source: string;
  label: string;
  organizationId?: ModelId;
}

const tk = tkCC.inputs.permissionConfigInput;

export const PermissionSpecialityListInput = ({
  source,
  label,
  organizationId
}: PermissionSpecialityListInputProps): ReactElement => {
  const { t } = useI18n();
  const specialityCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.OrganizationSpeciality,
    organizationId
  );
  return (
    <ArrayInput source={source} label={label}>
      <CodeSystemSelectorInput
        source=""
        label={t(tk.appConfigPermission)}
        codeSystem={specialityCodeSystem}
        organizationId={organizationId}
        withCreate
      />
    </ArrayInput>
  );
};
