import {
  Organization,
  OrganizationCombinedType,
  OrganizationListQueryParamsKey,
  OrganizationType,
  ResourceType
} from "@laba/nexup-api";
import React from "react";
import { FC } from "@laba/react-common";
import { Optional } from "@laba/ts-common";
import { ReferenceResourceInput } from "../ReferenceResourceInput/ReferenceResourceInput";
import { organizationOptionText } from "../ReferenceResourceInput/optionText";

export interface OrganizationReferenceInputProps {
  source: string;
  label: string;
  type?: OrganizationCombinedType;
  extraFilters?: Record<string, Optional<string | boolean | string[]>>;
  defaultValue?: string;
  disabled?: boolean;
  required?: boolean;
  alwaysOn?: boolean;
}

const getTypeFilter = (
  type?: OrganizationCombinedType
): Optional<OrganizationType[]> => {
  if (!type) return undefined;
  switch (type) {
    case OrganizationCombinedType.Payer:
      return [OrganizationType.Payer];
    case OrganizationCombinedType.Provider:
      return [OrganizationType.Provider];
    case OrganizationCombinedType.Group:
      return [OrganizationType.Group];
    case OrganizationCombinedType.ProviderAndGroup:
      return [OrganizationType.Provider, OrganizationType.Group];
  }
};

export const OrganizationReferenceInput: FC<
  OrganizationReferenceInputProps
> = ({
  source,
  label,
  type,
  extraFilters,
  defaultValue,
  required,
  disabled,
  alwaysOn
}) => {
  const combinedType = getTypeFilter(type);
  const typeFilter = combinedType
    ? { [OrganizationListQueryParamsKey.type]: combinedType }
    : undefined;
  return (
    <ReferenceResourceInput<Organization>
      source={source}
      label={label}
      resourceType={ResourceType.Organization}
      optionTextKey={organizationOptionText}
      extraFilters={{
        ...typeFilter,
        ...extraFilters
      }}
      required={required}
      defaultValue={defaultValue}
      disabled={disabled}
      alwaysOn={alwaysOn}
    />
  );
};
