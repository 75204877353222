import React from "react";
import { FC, IconC } from "@laba/react-common";
import { Badge } from "components/Badge/Badge";
import { isNil } from "lodash-es";
import { useMenuDefaultOptionStyles } from "./useMenuDefaultOptionStyles";

interface Props {
  text: string;
  textClassName?: string;
  Icon?: IconC;
  pendingActions?: number;
}

export const MenuDefaultOption: FC<Props> = ({
  text,
  textClassName,
  Icon,
  pendingActions
}) => {
  const classes = useMenuDefaultOptionStyles();
  const hasPendingActions = !isNil(pendingActions) && pendingActions > 0;

  return (
    <div className={classes.root}>
      <div className={classes.leftContainer}>
        {Icon && <Icon />}
        <span className={textClassName}>{text}</span>
      </div>
      {hasPendingActions && <Badge badgeContent={pendingActions} />}
    </div>
  );
};
