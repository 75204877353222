import React from "react";
import { Skeleton as MuiSkeleton } from "@material-ui/lab";
import { FC, pxToRem } from "@laba/react-common";

export enum SkeletonAnimationVariant {
  Pulse = "pulse",
  Wave = "wave"
}

export enum SkeletonVariant {
  Text = "text",
  Rect = "rect",
  Circle = "circle"
}

export interface SkeletonProps {
  animation?: SkeletonAnimationVariant;
  className?: string;
  height?: number;
  variant?: SkeletonVariant;
  width?: number;
  fullWidth?: boolean;
}

export const Skeleton: FC<SkeletonProps> = ({
  className,
  height,
  width,
  animation = SkeletonAnimationVariant.Pulse,
  variant = SkeletonVariant.Rect,
  fullWidth
}) => {
  return (
    <MuiSkeleton
      animation={animation}
      className={className}
      height={height && pxToRem(height)}
      variant={variant}
      width={fullWidth ? "100%" : width && pxToRem(width)}
    />
  );
};
