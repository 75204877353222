import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  flexItemMixin,
  FlexJustifyContent,
  flexRowMixin,
  getClassName,
  marginMixin,
  pxToRem,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

export interface DrawerOptionRowStyleProps {
  activeOption: boolean;
}

export interface DrawerOptionRowStyleClassNames {
  itemRoot: string;
  itemContainer: string;
  icon: string;
  endContainer: string;
  defaultCursor: string;
  optionText: string;
  arrowIconContainer: string;
  routeContainer: string;
  routeText: string;
  routeSelected: string;
}

const useDrawerOptionRowStyle = createStyle(
  (theme: Theme) => ({
    itemRoot: {
      ...flexColumnMixin({})
    },
    itemContainer: {
      ...flexRowMixin({
        justifyContent: FlexJustifyContent.FlexStart,
        alignItems: FlexAlignItems.Center
      }),
      height: pxToRem(40),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    },
    icon: {
      width: pxToRem(24),
      height: pxToRem(24)
    },
    iconColorActive: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Main
      )
    },
    iconColorInactive: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    endContainer: {
      ...flexRowMixin({
        justifyContent: FlexJustifyContent.FlexStart,
        alignItems: FlexAlignItems.Center
      }),
      ...flexItemMixin({
        grow: 1
      })
    },
    defaultCursor: { cursor: "default" },
    optionText: {
      ...themeTextMixin(theme, TextVariant.Body2, 1),
      ...marginMixin({ left: 10 })
    },
    optionTextActive: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Main
      )
    },
    optionTextInactive: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    arrowIconContainer: {
      ...marginMixin({
        left: "auto"
      })
    },
    arrowIconContainerActive: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Main
      )
    },
    arrowIconContainerInactive: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    routeContainer: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.FlexStart
      }),
      ...marginMixin({ left: 24 }),
      height: pxToRem(32),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    },
    routeText: {
      ...marginMixin({ left: 10 }),
      ...themeTextMixin(theme, TextVariant.Body2, 1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    routeSelected: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Main
      )
    }
  }),
  "DrawerOptionRow"
);

export const useDrawerOptionRowStyleClassNames = (
  props: DrawerOptionRowStyleProps
): DrawerOptionRowStyleClassNames => {
  const classes = useDrawerOptionRowStyle();

  return {
    itemRoot: classes.itemRoot,
    itemContainer: classes.itemContainer,
    icon: getClassName(
      classes.icon,
      props.activeOption ? classes.iconColorActive : classes.iconColorInactive
    ),
    endContainer: classes.endContainer,
    defaultCursor: classes.defaultCursor,
    optionText: getClassName(
      classes.optionText,
      props.activeOption ? classes.optionTextActive : classes.optionTextInactive
    ),
    arrowIconContainer: getClassName(
      classes.arrowIconContainer,
      props.activeOption
        ? classes.arrowIconContainerActive
        : classes.arrowIconContainerInactive
    ),
    routeContainer: classes.routeContainer,
    routeText: classes.routeText,
    routeSelected: classes.routeSelected
  };
};
