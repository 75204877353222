import React from "react";
import { FC } from "@laba/react-common";
import { Edit } from "components/generic/Edit/Edit";
import {
  AppointmentDefinitionForm,
  appointmentDefinitionJsonToModel,
  appointmentDefinitionTransform
} from "components/pages/NexupAdmin/resources/appointmentDefinition/AppointmentDefinitionForm";
import { AppointmentDefinition, ResourceType } from "@laba/nexup-api";
import { useElementExportedFileName } from "components/hook/useExportedFileName";

export const AppointmentDefinitionEdit: FC = () => {
  const exportedFileName = useElementExportedFileName<AppointmentDefinition>(
    resource => resource.name
  );
  return (
    <Edit
      resourceTransform={appointmentDefinitionTransform}
      resource={ResourceType.AppointmentDefinition}
      jsonToModel={appointmentDefinitionJsonToModel}
      exportedFileName={exportedFileName}
    >
      <AppointmentDefinitionForm />
    </Edit>
  );
};
