import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  HealthcareService,
  HealthcareServiceKey,
  HealthcareServiceListQueryParamsKey,
  HealthcareServiceType,
  Location,
  LocationListQueryParamsKey,
  OrganizationCombinedType,
  OrganizationKey,
  OrganizationListQueryParamsKey,
  Practitioner,
  PractitionerListQueryParamsKey,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { TextField } from "components/generic/TextField/TextField";
import { FunctionField } from "components/generic/FunctionField/FunctionField";
import { ReferenceResourceField } from "components/generic/ReferenceResourceField/ReferenceResourceField";
import { List } from "components/generic/List/List";
import { Datagrid } from "components/generic/Datagrid/Datagrid";
import { healthcareServiceJsonToModel } from "components/pages/NexupAdmin/resources/healthcareService/HealthcareServiceForm";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import {
  locationOptionText,
  practitionerOptionText
} from "components/generic/ReferenceResourceInput/optionText";
import { useListExportedFileName } from "components/hook/useExportedFileName";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

const tk = tkCP.adminPage[ResourceType.HealthcareService];

export const HealthcareServiceList: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const exportedFileName = useListExportedFileName(t(tk.title));

  return (
    <List
      resource={ResourceType.HealthcareService}
      exportedFileName={exportedFileName}
      jsonToModel={healthcareServiceJsonToModel}
      extraFilters={[
        workspaceOrganizationId === undefined ? (
          <OrganizationReferenceInput
            label={t(tk.filters.organization)}
            source={HealthcareServiceListQueryParamsKey.organization}
            alwaysOn
            type={OrganizationCombinedType.ProviderAndGroup}
            extraFilters={{
              [OrganizationListQueryParamsKey.active]: true
            }}
          />
        ) : undefined,
        <ReferenceResourceInput<Location>
          resourceType={ResourceType.Location}
          label={t(tk.filters.location)}
          source={HealthcareServiceListQueryParamsKey.location}
          optionTextKey={locationOptionText}
          extraFilters={{
            [LocationListQueryParamsKey.organization]: workspaceOrganizationId
          }}
          alwaysOn
        />,
        <ReferenceResourceInput<Practitioner>
          resourceType={ResourceType.Practitioner}
          label={t(tk.filters.practitioner)}
          source={HealthcareServiceListQueryParamsKey.practitioner}
          optionTextKey={practitionerOptionText}
          alwaysOn
          extraFilters={{
            [PractitionerListQueryParamsKey.organization]:
              workspaceOrganizationId
          }}
        />,
        <EnumSelectorInput
          source={HealthcareServiceListQueryParamsKey.type}
          label={t(tk.filters.type)}
          enumValue={HealthcareServiceType}
          alwaysOn
          resettable
        />
      ]}
    >
      <Datagrid>
        <TextField source={HealthcareServiceKey.id} label={t(tk.fields.id)} />
        <TextField
          source={HealthcareServiceKey.name}
          label={t(tk.fields.name)}
        />
        <ReferenceResourceField
          resourceType={ResourceType.Organization}
          label={t(tk.fields.organization)}
          source={HealthcareServiceKey.organization}
          optionTextKey={OrganizationKey.name}
        />
        <TextField
          source={HealthcareServiceKey.type}
          label={t(tk.fields.type)}
        />
        <TextField
          source={HealthcareServiceKey.category}
          label={t(tk.fields.category)}
        />
        <FunctionField<HealthcareService>
          label={t(tk.fields.active)}
          render={service =>
            service?.active ? t(tk.fields.isActive) : t(tk.fields.isNotActive)
          }
        />
      </Datagrid>
    </List>
  );
};
