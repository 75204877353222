import { useListExportedFileName } from "components/hook/useExportedFileName";
import { List } from "components/generic/List/List";
import { codeSystemJsonToModel } from "components/pages/NexupAdmin/resources/codeSystem/CodeSystemForm";
import { Datagrid } from "components/generic/Datagrid/Datagrid";
import { TextField } from "components/generic/TextField/TextField";
import React, { FC } from "react";
import { ConceptResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import {
  KnownCodeSystemSystem,
  ProcedureCodeConceptFilterKey,
  ProcedureCodeConceptKey
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { useI18n } from "@laba/react-common";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useSelector } from "react-redux";

const tk = tkCP.adminPage[ConceptResourceType.ProcedureCodeConcept];

export const ProcedureCodeConceptList: FC = () => {
  const exportedFileName = useListExportedFileName(tk.title);
  const { t } = useI18n();

  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);

  const medicalAreaCodeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.CodeSystemConceptMedicalArea,
    workspaceOrganizationId
  );

  return (
    <List
      resource={ConceptResourceType.ProcedureCodeConcept}
      exportedFileName={exportedFileName}
      jsonToModel={codeSystemJsonToModel}
      filterDefaultValues={{
        [ProcedureCodeConceptFilterKey.active]: true
      }}
      extraFilters={[
        <CodeSystemSelectorInput
          source={ProcedureCodeConceptFilterKey.medicalArea}
          label={t(tk.fields.medicalAreaCode)}
          codeSystem={medicalAreaCodeSystem}
          organizationId={workspaceOrganizationId}
          alwaysOn
          resettable
        />,
        <BooleanInput
          source={ProcedureCodeConceptFilterKey.active}
          label={t(tk.fields.active)}
          alwaysOn
        />
      ]}
    >
      <Datagrid>
        <TextField
          source={ProcedureCodeConceptKey.preferredTerm}
          label={t(tk.fields.preferredTerm)}
        />
        <TextField
          source={ProcedureCodeConceptKey.medicalArea}
          label={t(tk.fields.medicalAreaCode)}
        />
        <TextField
          source={ProcedureCodeConceptKey.active}
          label={t(tk.fields.active)}
        />
      </Datagrid>
    </List>
  );
};
