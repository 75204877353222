import FormData from "form-data";

export enum FileFormDataKey {
  File = "file",
  FileData = "fileData"
}

export interface UploadFileMetadata {
  organization?: string;
  tag?: string;
  public?: boolean;
}

export interface UploadFileResponse {
  fileId: string;
  url: string;
}

export interface FilePayload {
  key?: string;
  path?: string;
  buffer?: Buffer;
  contentType?: string;
  filename?: string;
  unlinkAfterSend?: boolean;
}

export type NodeFormData = FormData;
export const getNodeFormData = (): NodeFormData => {
  return new FormData();
};
