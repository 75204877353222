import { isEmpty } from "lodash-es";
import { matchPath } from "react-router-dom";
import { RouterLocation } from "routing/models/routerLocation";
import { GetRoutePathParams } from "routing/utils";
import { RouteAndModule } from "./routeAndModule";
import { RouteDefinition } from "./routeDefinition";
import { DefaultRouteModule, RouteModuleData } from "./routeModule";
import {
  getModuleRouteDefinitionPath,
  getModuleRoutePath,
  ModuleRouterMap
} from "./utils";

export const getRouteAndModulePath = <
  M extends DefaultRouteModule = DefaultRouteModule,
  T extends string = string,
  C = unknown
>(
  routeAndModule: RouteAndModule<M, T, C>,
  moduleRouterMap: ModuleRouterMap<M>,
  params?: GetRoutePathParams,
  hash?: string
): string | undefined =>
  getModuleRoutePath<M>(
    routeAndModule.module.module,
    routeAndModule.route.route,
    moduleRouterMap,
    params,
    hash
  );

export const getSelectedRoute =
  <
    M extends DefaultRouteModule = DefaultRouteModule,
    T extends string = string,
    C = unknown
  >(
    moduleList: RouteModuleData<M, T, C>[],
    moduleRouterMap: ModuleRouterMap<M>
  ) =>
  (location: RouterLocation): RouteAndModule<M, T, C> | undefined => {
    // eslint-disable-next-line no-restricted-syntax
    for (const module of moduleList) {
      // eslint-disable-next-line no-restricted-syntax
      for (const route of module.routeList) {
        const routePath = getModuleRouteDefinitionPath<M, T>(
          module.module,
          route.route,
          moduleRouterMap
        );
        const match = matchPath(location.pathname, {
          path: routePath,
          exact: true
        });
        if (match != null) {
          return {
            module,
            route
          };
        }
      }
    }
    return undefined;
  };

const moduleIsEnabledInConfig = <C = unknown>(
  isEnableFunc?: (configObject?: C) => boolean,
  config?: C
): boolean => isEnableFunc?.(config) ?? false;

const moduleIsEnabled =
  <
    M extends DefaultRouteModule = DefaultRouteModule,
    T extends string = string,
    C = unknown
  >(
    onlyMenu = false,
    configObject?: C
  ) =>
  (module: RouteModuleData<M, T, C>) => {
    const isEnabled = moduleIsEnabledInConfig<C>(
      module.isEnabled,
      configObject
    );
    if (onlyMenu) {
      return isEnabled && (module.isMenuModule || false);
    }
    return isEnabled;
  };

const routeIsEnabled =
  <T extends string = string, C = unknown>(
    onlyMenu = false,
    configObject?: C
  ) =>
  (route: RouteDefinition<T, C>) => {
    const isEnabled = moduleIsEnabledInConfig<C>(route.isEnabled, configObject);
    if (onlyMenu) {
      return isEnabled && (route.isMenuRoute || false);
    }
    return isEnabled;
  };

const moduleToModuleWithEnabledRoutes =
  <
    M extends DefaultRouteModule = DefaultRouteModule,
    T extends string = string,
    C = unknown
  >(
    onlyMenu = false,
    configObject?: C
  ) =>
  (module: RouteModuleData<M, T, C>) => ({
    ...module,
    routeList: module.routeList.filter(routeIsEnabled(onlyMenu, configObject))
  });

export const filterModuleList = <
  M extends DefaultRouteModule = DefaultRouteModule,
  T extends string = string,
  C = unknown
>(
  moduleList: RouteModuleData<M, T, C>[],
  onlyMenu = false,
  configObject?: C
): RouteModuleData<M, T, C>[] =>
  moduleList
    .filter(moduleIsEnabled(onlyMenu, configObject))
    .map(moduleToModuleWithEnabledRoutes(onlyMenu, configObject));

export const getFirstMenuRouteDefinitionPath = <
  M extends DefaultRouteModule = DefaultRouteModule,
  T extends string = string,
  C = unknown
>(
  moduleList: RouteModuleData<M, T, C>[],
  moduleRouterMap: ModuleRouterMap<M>,
  configObject?: C
): string | undefined => {
  const menuModuleList = filterModuleList<M, T, C>(
    moduleList,
    undefined,
    configObject
  );

  const moduleListNotEmpty = menuModuleList.filter(m => !isEmpty(m.routeList));
  const module = moduleListNotEmpty.find(m =>
    m.routeList.some(r => r.isMenuRoute)
  );
  const route = module?.routeList[0];
  if (module !== undefined && route !== undefined) {
    return getModuleRoutePath(module.module, route.route, moduleRouterMap);
  }
  return undefined;
};
