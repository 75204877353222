import { ReactElement } from "@laba/react-common";
import { ReferenceField } from "react-admin";
import React from "react";
import { TextField } from "components/generic/TextField/TextField";
import { RaResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { Model } from "@laba/nexup-api";
import { FunctionField } from "components/generic/FunctionField/FunctionField";
import { RaResource } from "providers/dataProvider/resourceProvider/utils/resourceProviderTypes";

export interface ReferenceResourceFieldProps<T extends Model = Model> {
  source: string;
  label: string;
  resourceType: RaResourceType;
  optionTextKey: string | ((record?: RaResource<T>) => string);
  sortable: boolean;
}

export const ReferenceResourceField = <T extends Model = Model>({
  source,
  label,
  resourceType,
  optionTextKey
}: ReferenceResourceFieldProps<T>): ReactElement => {
  return (
    <ReferenceField label={label} source={source} reference={resourceType}>
      {typeof optionTextKey === "string" ? (
        <TextField source={optionTextKey} />
      ) : (
        <FunctionField<T> render={optionTextKey} />
      )}
    </ReferenceField>
  );
};

// this is a bad practice but needed for react-admin datagrid to don't show sort arrow by default.
ReferenceResourceField.defaultProps = {
  sortable: false
};
