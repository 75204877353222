import { getKeyObj, KeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { ModelReference } from "../../primitives/modelReference/modelReference";
import { Organization } from "../entities/organization/organization";
import { ResourceModel, ResourceType } from "../../primitives/resourceModel";

export interface MedicationSubstance
  extends ResourceModel<ResourceType.MedicationSubstance> {
  code?: string;
  name?: string;
  organization?: ModelReference<Organization>;
}

export const MedicationSubstanceKey: KeyObj<MedicationSubstance> = getKeyObj(
  createHydratedMock<MedicationSubstance>()
);
