import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  CodeSystem,
  CodeSystemKey,
  CodeSystemStatus,
  getModelReferenceId,
  KnownCodeSystemSystem,
  ModelReference,
  OrganizationCombinedType,
  OrganizationListQueryParamsKey,
  ResourceType
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

export const tk = tkCP.adminPage[ResourceType.CodeSystem];
export const CodeSystemMainContent: FC = () => {
  const { t } = useI18n();

  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organization = useWatchResourceForm<ModelReference<CodeSystem>>(
    CodeSystemKey.organization
  );
  const organizationId =
    getModelReferenceId(organization) || workspaceOrganizationId;
  const codeSystemSystemSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.CodeSystemSystem,
    organizationId
  );

  return (
    <>
      <TextInput disabled source={CodeSystemKey.id} label={t(tk.fields.id)} />
      <OrganizationReferenceInput
        source={CodeSystemKey.organization}
        label={t(tk.fields.organization)}
        type={OrganizationCombinedType.ProviderAndGroup}
        extraFilters={{
          [OrganizationListQueryParamsKey.active]: true
        }}
        defaultValue={workspaceOrganizationId}
        disabled={workspaceOrganizationId !== undefined}
      />
      <CodeSystemSelectorInput
        source={CodeSystemKey.system}
        label={t(tk.fields.system)}
        codeSystem={codeSystemSystemSystem}
        organizationId={organizationId}
        withCreate
      />
      <EnumSelectorInput
        source={CodeSystemKey.status}
        label={t(tk.fields.status)}
        enumValue={CodeSystemStatus}
      />
      <BooleanInput source={CodeSystemKey.public} label={t(tk.fields.public)} />
    </>
  );
};
