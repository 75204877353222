import {
  borderMixinRadiusAll,
  createStyle,
  FlexAlignItems,
  flexItemMixin,
  FlexJustifyContent,
  flexRowMixin,
  getClassName,
  paddingMixin,
  pxToRem,
  StyleVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeHeaderRadius, getThemeStyleColor } from "model/themeUtils";

interface DateNavigatorHeaderDynamicStylesProps {
  isMobile?: boolean;
}
interface DateNavigatorHeaderClassNames {
  root: string;
  arrowsWrapper: string;
  arrowsAndTagWrapper: string;
  dateModifier: string;
  datePicker: string;
  tag: string;
}

const useDateNavigatorHeaderStaticStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexRowMixin({
        gap: 16,
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.SpaceBetween
      }),
      ...borderMixinRadiusAll(getThemeHeaderRadius(theme)),
      ...paddingMixin({ leftRight: 16, topBottom: 12 }),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      ),
      width: "100%",
      height: pxToRem(48)
    },
    arrowsWrapper: {
      ...flexRowMixin({})
    },
    arrowsAndTagWrapper: {
      ...flexRowMixin({ gap: 16 }),
      ...flexItemMixin({ shrink: 0 })
    },
    tag: { ...flexItemMixin({ shrink: 0 }) },
    datePicker: {
      ...flexRowMixin({ justifyContent: FlexJustifyContent.FlexStart })
    },
    fixedDatePickerWidth: { width: pxToRem(256) }
  }),
  "DateNavigatorHeaderStatic"
);

const useDateNavigatorHeaderDynamicStyles = createStyle(
  (_theme: Theme) => ({
    dateModifier: (props: DateNavigatorHeaderDynamicStylesProps) => ({
      ...flexRowMixin({
        gap: 16,
        alignItems: FlexAlignItems.Center,
        justifyContent: props.isMobile
          ? FlexJustifyContent.SpaceBetween
          : FlexJustifyContent.FlexStart
      }),
      ...flexItemMixin({ grow: 1, shrink: 1, basis: "0%" })
    })
  }),
  "DateNavigatorHeaderDynamic"
);

export const useDateNavigatorHeaderStyles = (
  props: DateNavigatorHeaderDynamicStylesProps
): DateNavigatorHeaderClassNames => {
  const staticClasses = useDateNavigatorHeaderStaticStyles();
  const dynamicClasses = useDateNavigatorHeaderDynamicStyles(props);

  return {
    root: staticClasses.root,
    arrowsWrapper: staticClasses.arrowsWrapper,
    arrowsAndTagWrapper: staticClasses.arrowsAndTagWrapper,
    dateModifier: dynamicClasses.dateModifier,
    tag: staticClasses.tag,
    datePicker: getClassName(
      staticClasses.datePicker,
      props.isMobile ? undefined : staticClasses.fixedDatePickerWidth
    )
  };
};
