import {
  ResourceType,
  getHospitalizationList,
  getHospitalization,
  updateHospitalization,
  createHospitalization,
  HospitalizationListQueryParams,
  HospitalizationListQueryParamsKey,
  getModelReferenceId
} from "@laba/nexup-api";
import {
  getEnumOrderParam,
  getKeyTargetParam,
  resourceProviderGetter
} from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { workspaceOrganizationSelector } from "store/workspace/selectors";
import { store } from "store/store";

export const hospitalizationProvider = resourceProviderGetter(
  ResourceType.Hospitalization,
  getEnumOrderParam({}),
  getKeyTargetParam<HospitalizationListQueryParams>(
    HospitalizationListQueryParamsKey
  ),
  getHospitalizationList,
  getHospitalization,
  updateHospitalization,
  createHospitalization,
  undefined,
  () => ({
    organization: getModelReferenceId(
      workspaceOrganizationSelector(store.getState())
    )
  })
);
