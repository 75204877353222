import React from "react";
import { FC } from "@laba/react-common";
import { EditIcon, CloseIcon } from "components/icons";
import { BaseIconButton } from "components/buttons/BaseIconButton/BaseIconButton";
import { Noop } from "@laba/ts-common";
import { usePatientDetailMobilePopupHeaderStyles } from "./usePatientDetailMobilePopupHeaderStyles";
import { PatientIdentificationDataContainer } from "../../PatientIdentificationDataContainer/PatientIdentificationDataContainer";

export interface PatientDetailMobilePopupHeaderProps {
  onEditClick?: Noop;
  onClose?: Noop;
  firstName?: string;
  lastName?: string;
  isEditable?: boolean;
}

export const PatientDetailMobilePopupHeader: FC<
  PatientDetailMobilePopupHeaderProps
> = ({ onEditClick, onClose, isEditable, firstName, lastName }) => {
  const classes = usePatientDetailMobilePopupHeaderStyles();
  return (
    <div className={classes.root}>
      <PatientIdentificationDataContainer
        className={classes.patientIdentificationDataContainer}
        isMobile
        firstName={firstName}
        lastName={lastName}
        multiline
      />
      <div className={classes.buttonsContainer}>
        {isEditable && <BaseIconButton Icon={EditIcon} onClick={onEditClick} />}
        <BaseIconButton Icon={CloseIcon} onClick={onClose} />
      </div>
    </div>
  );
};
