export const whatsappNotification = {
  title: "Whatsapps",
  fields: {
    id: "Id",
    version: "Version",
    message: "Mensaje",
    receiver: "Destinatario",
    externalId: "Id externo",
    messageType: "Tipo de mensaje",
    status: "Estado",
    notification: "Notificación",
    creationDate: "Fecha de creacion",
    lastUpdatedDate: "Fecha de última edición",

    history: "Historico"
  },
  tabs: {
    main: "Whatsapp",
    history: "Historial"
  },
  filters: {
    id: "Id",
    notification: "Notificación",
    externalId: "Id externo",
    status: "Estado",
    messageType: "Tipo de mensaje"
  }
};
