import { ButtonBase, CircularProgress } from "@material-ui/core";
import React from "react";
import {
  InsertDriveFile as FileIcon,
  GetApp as DownloadIcon
} from "@material-ui/icons";
import { useTheme } from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { getThemeStyleColor } from "model/themeUtils";
import { StyleVariant } from "model/themeVariant";
import { useAttachmentButtonLayoutStyles } from "./useAttachmentButtonLayoutStyles";

interface Props {
  attatchmentName?: string;
  withSpinner?: boolean;
}

type ButtonBaseProps = React.ComponentProps<typeof ButtonBase> & Props;

const spinnerSize = 20;

export const AttachmentButtonLayout: React.FC<ButtonBaseProps> = ({
  attatchmentName,
  withSpinner = false,
  ...rest
}) => {
  const theme = useTheme<Theme>();
  const primaryMainColor = getThemeStyleColor(
    theme,
    StyleVariant.Primary,
    ThemeStyleColorVariant.Main
  );
  const primaryGrayLightColor = getThemeStyleColor(
    theme,
    StyleVariant.Primary,
    ThemeStyleColorVariant.GrayLight
  );
  const classes = useAttachmentButtonLayoutStyles();
  return (
    <ButtonBase focusRipple className={classes.attachmentButton} {...rest}>
      <div className={classes.nameSection}>
        <FileIcon htmlColor={primaryMainColor} fontSize="small" />
        <span className={classes.text}>{attatchmentName}</span>
      </div>
      <div className={classes.iconSection}>
        {withSpinner ? (
          <CircularProgress
            size={spinnerSize}
            className={classes.circularProgress}
          />
        ) : (
          <DownloadIcon htmlColor={primaryGrayLightColor} fontSize="small" />
        )}
      </div>
    </ButtonBase>
  );
};
