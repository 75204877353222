import {
  createStyle,
  flexColumnMixin,
  StyleVariant,
  TextVariant,
  ThemeStyleColorVariant,
  pxToRem,
  getClassName,
  borderMixinRadiusAll
} from "@laba/react-common";
import { Theme } from "model/theme";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

interface StepStyleClassNames {
  container: string;
  text: string;
  stepSegment: string;
}

interface StepStyleProps {
  isActive?: boolean;
}

const useStepStaticStaticStyles = createStyle(
  (theme: Theme) => ({
    container: {
      ...flexColumnMixin({
        gap: 4
      })
    },
    stepText: {
      ...themeTextMixin(theme, TextVariant.Overline)
    },
    activeStepText: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Dark
      )
    },
    inactiveStepText: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    stepSegment: {
      ...borderMixinRadiusAll(pxToRem(2)),
      width: "100%",
      height: pxToRem(7)
    },
    activeStepSegment: {
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Main
      )
    },
    inactiveStepSegment: {
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayLight
      )
    }
  }),
  "StepStatic"
);

export const useStepStyles = (props: StepStyleProps): StepStyleClassNames => {
  const { isActive } = props;
  const staticStyles = useStepStaticStaticStyles();

  return {
    container: staticStyles.container,
    text: getClassName(
      staticStyles.stepText,
      isActive ? staticStyles.activeStepText : staticStyles.inactiveStepText
    ),
    stepSegment: getClassName(
      staticStyles.stepSegment,
      isActive
        ? staticStyles.activeStepSegment
        : staticStyles.inactiveStepSegment
    )
  };
};
