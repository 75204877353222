import {
  borderMixinRadiusAll,
  createStyle,
  ElevationVariant,
  FlexAlignItems,
  flexRowMixin,
  getZIndexValue,
  marginMixin,
  paddingMixin,
  pxToRem,
  StyleVariant,
  Theme,
  ThemeStyleColorVariant,
  ZIndexVariant
} from "@laba/react-common";
import {
  getThemeHeaderRadius,
  getThemeStyleColor,
  themeElevationMixin
} from "model/themeUtils";

export const useDropdownHeaderStyles = createStyle(
  (theme: Theme) => ({
    container: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        gap: 4
      }),
      ...borderMixinRadiusAll(getThemeHeaderRadius(theme)),
      ...themeElevationMixin(theme, ElevationVariant.Elevation1),
      ...paddingMixin({ leftRight: 16, topBottom: 4 }),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      ),
      zIndex: getZIndexValue(ZIndexVariant.ListItemElevation),
      height: pxToRem(32),
      width: "100%"
    },
    startIcon: {
      ...marginMixin({ right: 4 }),
      height: pxToRem(24),
      width: pxToRem(24),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "DropdownHeader"
);
