import React from "react";
import { FC } from "@laba/react-common";
import { Edit } from "components/generic/Edit/Edit";
import {
  LocationForm,
  locationJsonToModel,
  locationTransform
} from "components/pages/NexupAdmin/resources/location/LocationForm";
import { Location, ResourceType } from "@laba/nexup-api";
import { useElementExportedFileName } from "components/hook/useExportedFileName";

export const LocationEdit: FC = () => {
  const exportedFileName = useElementExportedFileName<Location>(
    resource => resource.name
  );
  return (
    <Edit
      resourceTransform={locationTransform}
      resource={ResourceType.Location}
      jsonToModel={locationJsonToModel}
      exportedFileName={exportedFileName}
    >
      <LocationForm />
    </Edit>
  );
};
